<div class="content p-24 tbl">
    <ngx-loading class="ngxLoader" 
        [show]="loading"
        [config]="{ backdropBorderRadius: '3px' }"
    ></ngx-loading>
    <h1 class="m-0">FHA Consultant Agreement</h1>
    <a
        class="addBorrow"
        href="javascript:void(0)"
        routerLink="/property-details/{{ globalPropertyId }}/1"
    >
        Back to Property details</a
    >
    <br />
    <div class="container-login100">
        <mat-card class="my-form">
            <form
                [formGroup]="add_conAgreement"
                class="example-form"
                (ngSubmit)="SaveRecord()"
            >
                <mat-card-content>
                    <mat-form-field>
                        <span class="mat-form-field-label-wrapper"
                            ><!----><label
                                class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                >Property</label
                            ></span
                        >
                        <mat-select
                            formControlName="property"
                            [disabled]="true"
                        >
                            <mat-option value="">Select</mat-option>
                            <mat-option
                                *ngFor="let property of propertyList"
                                value="{{ property.Id }}"
                            >
                                {{
                                    property?.InspectionAddress?.address1
                                        ? property?.InspectionAddress
                                              ?.address1 + ", "
                                        : ""
                                }}
                                {{
                                    property?.InspectionAddress?.address2
                                        ? property?.InspectionAddress
                                              ?.address2 + ", "
                                        : ""
                                }}
                                {{
                                    property?.InspectionAddress?.city
                                        ? property?.InspectionAddress?.city +
                                          ", "
                                        : ""
                                }}
                                {{
                                    property?.InspectionAddress?.state
                                        ? property?.InspectionAddress?.state +
                                          ", "
                                        : ""
                                }}
                                {{ property?.InspectionAddress?.zipcode }}
                            </mat-option>
                        </mat-select>
                        <div class="error" *ngIf="(!add_conAgreement.controls['property'].valid && add_conAgreement.controls['property'].touched) || (add_conAgreement.controls['property'].invalid && submitted)">
                            Select property 
                        </div>
                    </mat-form-field>
                    <table>
                        <tbody>
                            <!-- <tr>
              <td>
                  <mat-form-field class="example-full-width">
                      <input matInput placeholder="Title*" formControlName="title" name="title">
                    </mat-form-field>
              </td>
              <td>
                  
              </td>
            </tr> -->
                            <tr>
                                <td>
                                    <mat-form-field>
                                        <span
                                            class="mat-form-field-label-wrapper"
                                            ><!----><label
                                                class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                >Consultant fee</label
                                            ></span
                                        >
                                        <input
                                            matInput
                                            formControlName="consultantFee"
                                            type="number"
                                            class="example-right-align"
                                        />
                                        <span matPrefix>$&nbsp;</span>
                                        <span matSuffix>.00</span>
                                    </mat-form-field>
                                </td>
                                <td>
                                    <mat-form-field>
                                        <span
                                            class="mat-form-field-label-wrapper"
                                            ><!----><label
                                                class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                >SIGNATURE DATE</label
                                            ></span
                                        >
                                        <input
                                            matInput
                                            [matDatepicker]="picker"
                                            formControlName="signatureDate"
                                        />
                                        <mat-datepicker-toggle
                                            matSuffix
                                            [for]="picker"
                                        ></mat-datepicker-toggle>
                                        <mat-datepicker
                                            #picker
                                        ></mat-datepicker>
                                        <div class="error" *ngIf="(!add_conAgreement.controls['signatureDate'].valid && add_conAgreement.controls['signatureDate'].touched) || (add_conAgreement.controls['signatureDate'].invalid && submitted)">
                                            Select Signature Date
                                        </div>
                                    </mat-form-field>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <mat-form-field>
                                        <span
                                            class="mat-form-field-label-wrapper"
                                            ><!----><label
                                                class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                >Feasibility Study</label
                                            ></span
                                        >
                                        <input
                                            matInput
                                            formControlName="feasibilityStudy"
                                            type="number"
                                            class="example-right-align"
                                        />
                                        <span matPrefix>$&nbsp;</span>
                                        <span matSuffix>.00</span>
                                    </mat-form-field>
                                </td>
                                <td>&nbsp;</td>
                            </tr>
                            <tr>
                                <td colspan="2">
                                    <h3>Invoice Information</h3>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <mat-form-field>
                                        <span
                                            class="mat-form-field-label-wrapper"
                                            ><!----><label
                                                class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                >Mileage Fee Collected for
                                                intial Visit</label
                                            ></span
                                        >
                                        <input
                                            matInput
                                            formControlName="mileageFeeForVisit"
                                            type="number"
                                            class="example-right-align"
                                        />
                                        <span matPrefix>$&nbsp;</span>
                                        <span matSuffix>.00</span>
                                    </mat-form-field>
                                </td>
                                <td>
                                    <mat-form-field>
                                        <span
                                            class="mat-form-field-label-wrapper"
                                            ><!----><label
                                                class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                >Invoice Date</label
                                            ></span
                                        >
                                        <input
                                            matInput
                                            [matDatepicker]="picker1"
                                            formControlName="invoiceDate"
                                            ng-required="false"
                                            ng-model="myinvoiceDate"
                                        />
                                        <mat-datepicker-toggle
                                            matSuffix
                                            [for]="picker1"
                                        ></mat-datepicker-toggle>
                                        <mat-datepicker
                                            #picker1
                                        ></mat-datepicker>
                                    </mat-form-field>
                                </td>
                            </tr>
                            <tr>
                                <td style="vertical-align: top">
                                    <mat-checkbox
                                        class="mt-24"
                                        [checked]="notPaidInFullChecked != true"
                                        (change)="
                                            setNotPaidInFullChecked(false)
                                        "
                                        formControlName="invoicePaidToDate"
                                    >
                                        Invoice Paid To Date
                                    </mat-checkbox>
                                </td>
                                <td style="vertical-align: top">
                                    <div>
                                        <mat-checkbox
                                            class="mt-24"
                                            [checked]="
                                                notPaidInFullChecked == true
                                            "
                                            formControlName="notPaidInFull"
                                            (change)="
                                                setNotPaidInFullChecked(
                                                    $event.checked
                                                )
                                            "
                                        >
                                            Not Paid in Full
                                        </mat-checkbox>
                                    </div>
                                    <div
                                        style="margin-top: 20px"
                                        *ngIf="notPaidInFullChecked"
                                    >
                                        <mat-form-field>
                                            <span
                                                class="mat-form-field-label-wrapper"
                                                ><!----><label
                                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                    >Amount Paid To Date</label
                                                ></span
                                            >
                                            <input
                                                matInput
                                                formControlName="amountPaidToDate"
                                                type="number"
                                                class="example-right-align"
                                            />
                                            <span matPrefix>$&nbsp;</span>
                                            <span matSuffix>.00</span>
                                        </mat-form-field>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2">
                                    <a
                                        href="javascript:void(0)"
                                        *ngIf="agreementId != null"
                                        (click)="printInvoicePdf()"
                                        >Print Invoice PDF</a
                                    >
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </mat-card-content>
                <mat-card-actions>
                    <button mat-raised-button type="submit" color="primary">
                        Save</button
                    >&nbsp; &nbsp;&nbsp;
                    <!-- <button mat-raised-button type="button" color="info">Save as draft</button>&nbsp; &nbsp;&nbsp;
       <button mat-raised-button type="button" color="info">Preview</button> -->
                </mat-card-actions>
            </form>
        </mat-card>
    </div>
</div>
