import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatRadioModule } from "@angular/material/radio";
import { MatTableModule } from "@angular/material/table";
import { MatTabsModule } from "@angular/material/tabs";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatTooltipModule } from "@angular/material/tooltip";
import { AuthGuardService as AuthGard } from "../service/auth/auth-guard.service";
import { TranslateModule } from "@ngx-translate/core";
import { NgxLoadingModule } from "ngx-loading";
import { FuseSharedModule } from "@fuse/shared.module";
import {
    PropertyInputBidComponent,
    SafePipe,
} from "./property-input-bid.component";

const routes = [
    {
        path: "property-input-bid/:propertyId",
        component: PropertyInputBidComponent,
        canActivate: [AuthGard],
    },
];

@NgModule({
    declarations: [PropertyInputBidComponent, SafePipe],
    imports: [
        RouterModule.forChild(routes),
        TranslateModule,
        NgxLoadingModule.forRoot({}),
        FuseSharedModule,
        MatButtonModule,
        MatCardModule,
        MatFormFieldModule,
        MatInputModule,
        MatTableModule,
        MatPaginatorModule,
        MatIconModule,
        MatTabsModule,
        MatProgressBarModule,
        MatRadioModule,
        MatCheckboxModule,
        MatExpansionModule,
        MatTooltipModule,
    ],
    exports: [PropertyInputBidComponent],
})
export class PropertyInputBidModule {}
