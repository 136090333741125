export const locale = {
    lang: "tr",
    data: {
        NAV: {
            APPLICATIONS: "Programlar",
            DASHBOARD: {
                TITLE: "Dashboard",
                BADGE: "15",
            },
        },
    },
};
