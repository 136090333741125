<div class="content p-24 tbl">
 <ngx-loading  class="ngxLoader" [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>
    <h1 class="m-0">System Settings</h1>
     <!-- <a *ngIf="isList" class="addBorrow" href="javascript:void(0)" (click)="isList=false;isAdd=true;isEdit=false;formReset()"><mat-icon _ngcontent-c102="" class="secondary-text s-22 mat-icon material-icons mat-icon-no-color" role="img" aria-hidden="true">add_circle</mat-icon> Add New Loan Officer</a>
    <a *ngIf="isAdd || isEdit" class="addBorrow" href="javascript:void(0)" (click)="isAdd=false;isList=true;isEdit=false"><mat-icon _ngcontent-c102="" class="secondary-text s-22 mat-icon material-icons mat-icon-no-color" role="img" aria-hidden="true">view_list</mat-icon> List Loan Officer</a> -->

<!-- <mat-form-field>
  <input [hidden]="!isList" matInput (keyup)="applyFilter($event.target)" placeholder="Filter">
</mat-form-field> -->
<br>
<mat-accordion class="example-headers-align" multi>
  <form [formGroup]="EmailSetting" class="example-form" (ngSubmit)="SaveEmailSetting()">
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title>
          COMPANY DETAILS
        </mat-panel-title>
      </mat-expansion-panel-header>

      <table>
          <tbody>
            <tr>
              <td>
                  <mat-form-field>
                    <span class="mat-form-field-label-wrapper"><!----><label class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"   >Company Name</label></span>
                      <input matInput formControlName="CompanyName">
                    </mat-form-field>
              </td>
              <td>
                  <mat-form-field>
                    <span class="mat-form-field-label-wrapper"><!----><label class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"   >Company Phone Number</label></span>
                      <input matInput   (keyup)="keyDownPhoneFormat($event)" (change)="changePhoneFormat($event)" formControlName="CompanyPhone">
                  </mat-form-field>
              </td>
            </tr>
          </tbody>
        </table>

      <!-- <label>Copyright Message</label>
      <ckeditor matNativeControl name="editor1" formControlName="editorValue" skin="moono-lisa" language="en" ></ckeditor>
       -->
    </mat-expansion-panel>

    <br>

    <!-- <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Company Logo
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p>Upload the header image with logo and right side details of size 720x97px. Size is a must criteria.</p>
      <strong>Company Logo</strong><br>
      <br>
      <input type="file" style="padding:0px; margin:0px;" placeholder="Company Phone Number">
    </mat-expansion-panel> -->

   
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          MILEAGE SETTINGS
        </mat-panel-title>
      </mat-expansion-panel-header>

      <table>
          <tbody>
            <tr>
              <td colspan="2">
                  <mat-form-field>
                    <span class="mat-form-field-label-wrapper"><!----><label class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"   >Office Location</label></span>
                      <input matInput formControlName="MileageSettingOfficeLocation">
                    </mat-form-field>
              </td>
            </tr>
            <tr>
              <td >
                  <strong>203K Mileage Settings</strong>
              </td>
              <td >
                <strong>HomeStyle Mileage Settings</strong>
            </td>
            </tr>
            <tr>
              <td>
                <mat-form-field>
                  <span class="mat-form-field-label-wrapper"><!----><label class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"   >IRS Per Mile Rate</label></span>
                    <input matInput formControlName="MileageSettingIRSPerMileRate">
                  </mat-form-field>
            </td>
            <td>
              <mat-form-field>
                <span class="mat-form-field-label-wrapper"><!----><label class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"   >IRS Per Mile Rate</label></span>
                  <input matInput formControlName="MileageSettingHomeStyleIRSPerMileRate">
                </mat-form-field>
          </td>
            </tr>
            <tr>
              <td>
                  <mat-form-field>
                    <span class="mat-form-field-label-wrapper"><!----><label class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"   >Minimum distance away from office (Miles)</label></span>
                      <input matInput formControlName="MileageSettingMilesthatSubtracted">
                    </mat-form-field>
              </td>
              <td>
                <mat-form-field>
                <span class="mat-form-field-label-wrapper"><!----><label class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"   >Minimum distance away from office (Miles)</label></span>
                  <input matInput formControlName="MileageSettingHomeStyleMilesthatSubtracted">
                </mat-form-field></td>
            </tr>
            
          </tbody>
        </table>
    </mat-expansion-panel>

    <br>

    

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          DOCUSIGN API SETTINGS
        </mat-panel-title>
      </mat-expansion-panel-header>

      <table>
          <tbody>
            <tr>
              <td>
                  <mat-form-field>
                    <span class="mat-form-field-label-wrapper"><!----><label class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"   >Servrer</label></span>
                      <input matInput  formControlName="DocSignAPIServrer">
                    </mat-form-field>
              </td>
              <td>
                  <mat-form-field>
                    <span class="mat-form-field-label-wrapper"><!----><label class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"   >Api Path</label></span>
                      <input matInput formControlName="DocSignAPIApiPath">
                    </mat-form-field>
              </td>
            </tr>
            <tr>
              <td>
                  <mat-form-field>
                    <span class="mat-form-field-label-wrapper"><!----><label class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"   >Redirect URI</label></span>
                      <input matInput  formControlName="DocSignAPIRedirectURI">
                    </mat-form-field>
              </td>
              <td>
                <mat-form-field>
                  <span class="mat-form-field-label-wrapper"><!----><label class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"   >Integration Key</label></span>
                  <input matInput formControlName="DocSignAPIIntegrationKey">
                </mat-form-field>
              </td>
            </tr>
            <tr>
              <td>
                  <mat-form-field>
                    <span class="mat-form-field-label-wrapper"><!----><label class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"   >User UID</label></span>
                      <input matInput formControlName="DocSignAPIUserUID">
                    </mat-form-field>
              </td>
              <td>
                <mat-form-field>
                  <span class="mat-form-field-label-wrapper"><!----><label class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"   >Private Key</label></span>
                  <input matInput formControlName="DocSignAPIPrivateKey">
                </mat-form-field>
              </td>
            </tr>
            <tr>
              <td>
                  <mat-form-field>
                    <span class="mat-form-field-label-wrapper"><!----><label class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"   >Client Id</label></span>
                      <input matInput formControlName="DocSignAPIClientId">
                    </mat-form-field>
              </td>
              <td>
               
              </td>
            </tr>
          </tbody>
        </table>
      
      
      
    </mat-expansion-panel>
    <br>
    <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            Google Key
          </mat-panel-title>
        </mat-expansion-panel-header>
  
        <table>
            <tbody>
              <tr>
                <td>
                    <mat-form-field>
                      <span class="mat-form-field-label-wrapper"><!----><label class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"   >Google Key</label></span>
                        <input matInput formControlName="GoogleKey">
                      </mat-form-field>
                </td>
                
              </tr>
            </tbody>
          </table>
        
        
        
      </mat-expansion-panel>
      <br />
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            S3 Bucket section
          </mat-panel-title>
        </mat-expansion-panel-header>
  
        <table>
            <tbody>
              <tr>
                <td>
                    <mat-form-field>
                      <span class="mat-form-field-label-wrapper"><!----><label class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"   >AWS Config Region</label></span>
                        <input matInput formControlName="AwsConfigRegion">
                      </mat-form-field>
                </td>
                
              </tr>
              <tr>
                <td>
                    <mat-form-field>
                      <span class="mat-form-field-label-wrapper"><!----><label class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"   >AWS S3 Bucket Name</label></span>
                        <input matInput formControlName="AwsS3BucketName">
                      </mat-form-field>
                </td>
                
              </tr>
              <tr>
                <td>
                    <h3>Access Key Id</h3><br/>
                    <mat-form-field>
                      <span class="mat-form-field-label-wrapper"><!----><label class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"   >Aws Access Key Id</label></span>
                        <input type="password" matInput *ngIf="userDetail.UserType=='Administrator'" formControlName="AwsAccessKeyId">
                        <input type="password" matInput *ngIf="userDetail.UserType!='Administrator'" readonly formControlName="AwsAccessKeyId">
                      </mat-form-field>
                </td>
                
              </tr>
              <tr>
                <td>
                    <h3>Secret Access Key</h3><br/>
                    <mat-form-field>
                      <span class="mat-form-field-label-wrapper"><!----><label class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"   >Aws Secret Access Key</label></span>
                        <input type="password" matInput *ngIf="userDetail.UserType=='Administrator'" formControlName="AwsSecretAccessKey">
                        <input type="password" matInput *ngIf="userDetail.UserType!='Administrator'" readonly formControlName="AwsSecretAccessKey">
                      </mat-form-field>
                </td>
                
              </tr>
            </tbody>
          </table>
        
        
        
      </mat-expansion-panel>
      
<br/>
    <mat-card-actions>
      <button mat-raised-button type="submit" color="primary">Save configuration</button>
    </mat-card-actions>
  </form>
</mat-accordion>
</div>