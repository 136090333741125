<div class="content p-24 tbl">
    <ngx-loading class="ngxLoader" 
        [show]="loading"
        [config]="{ backdropBorderRadius: '3px' }"
    ></ngx-loading>
    <h1 class="m-0" *ngIf="isFinalSOR == false">
        Specification Of Repair Construction Detail -
        {{
            propertyCDetails?.InspectionAddress?.address1
                ? propertyCDetails?.InspectionAddress?.address1 + ", "
                : ""
        }}
        {{
            propertyCDetails?.InspectionAddress?.address2
                ? propertyCDetails?.InspectionAddress?.address2 + ", "
                : ""
        }}
        {{
            propertyCDetails?.InspectionAddress?.city
                ? propertyCDetails?.InspectionAddress?.city + ", "
                : ""
        }}
        {{
            propertyCDetails?.InspectionAddress?.state
                ? propertyCDetails?.InspectionAddress?.state + ", "
                : ""
        }}
        {{ propertyCDetails?.InspectionAddress?.zipcode }}
    </h1>
    <h1 class="m-0" *ngIf="isFinalSOR == true">
        Final Specification Of Repair Construction Detail -
        {{
            propertyCDetails?.InspectionAddress?.address1
                ? propertyCDetails?.InspectionAddress?.address1 + ", "
                : ""
        }}
        {{
            propertyCDetails?.InspectionAddress?.address2
                ? propertyCDetails?.InspectionAddress?.address2 + ", "
                : ""
        }}
        {{
            propertyCDetails?.InspectionAddress?.city
                ? propertyCDetails?.InspectionAddress?.city + ", "
                : ""
        }}
        {{
            propertyCDetails?.InspectionAddress?.state
                ? propertyCDetails?.InspectionAddress?.state + ", "
                : ""
        }}
        {{ propertyCDetails?.InspectionAddress?.zipcode }}
    </h1>
    <br />
    <!-- <a *ngIf="isList" class="addBorrow" href="javascript:void(0)" (click)="isList=false;isAdd=true;isEdit=false;formReset()"><mat-icon _ngcontent-c102="" class="secondary-text s-22 mat-icon material-icons mat-icon-no-color" role="img" aria-hidden="true">add_circle</mat-icon> Add New Borrower</a> -->
    <a
        class="addBorrow"
        href="javascript:void(0)"
        routerLink="/property-details/{{ RepairPropertyId }}/1"
    >
        Back to Property</a
    >
    <!-- <mat-form-field>
  <input [hidden]="!isList" matInput (keyup)="applyFilter($event.target)" placeholder="Filter">
</mat-form-field> -->
    <div [hidden]="!isList">
        <mat-card class="my-form">
            <a
                class="sendToBorrow"
                href="javascript:void(0);"
                *ngIf="isSORExist == true && isFinalSOR == false"
                (click)="
                    sorEmailToBorrower(
                        propertyDetails.Id,
                        borrowerDetails.EmailId,
                        borrowerDetails.FirstName,
                        borrowerDetails.LastName
                    )
                "
                >Send to Borrower</a
            >
            <a
                class="sendToBorrow"
                href="javascript:void(0);"
                *ngIf="
                    isSORExist == true &&
                    isFinalSOR == false &&
                    isResend == true
                "
                (click)="
                    sorResendEmailToBorrower(
                        propertyDetails.Id,
                        borrowerDetails.EmailId,
                        borrowerDetails.FirstName,
                        borrowerDetails.LastName
                    )
                "
                >Resend to Borrower</a
            >
            <a
                class="sendToBorrow"
                href="javascript:void(0);"
                *ngIf="isSORExist == true && isFinalSOR == true"
                (click)="finalSorEmailToBorrower(propertyDetails.Id)"
                >Send to Lender</a
            >
            <a
                class="sendToBorrow"
                *ngIf="isSORExist != true"
                href="javascript:void(0)"
            >
                Send to Borrower</a
            >

            <form
                style="margin-top: 10px"
                [formGroup]="RepairItemForm"
                class="example-form"
                (ngSubmit)="SaveAllowableFeesItem()"
            >
                <mat-card-header>
                    <mat-card-title></mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <mat-card>
                        <mat-card-header>
                            <mat-card-title></mat-card-title>
                        </mat-card-header>

                        <div>
                            <div>
                                <div>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <mat-form-field>
                                                        <span
                                                            class="mat-form-field-label-wrapper"
                                                            ><!----><label
                                                                class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                                >Occupied During
                                                                Construction</label
                                                            ></span
                                                        >
                                                        <select
                                                            matNativeControl
                                                            formControlName="OccupiedDuringConstruction"
                                                        >
                                                            <option value="">
                                                                -None-
                                                            </option>
                                                            <option
                                                                value="Occupied"
                                                            >
                                                                Occupied
                                                            </option>
                                                            <option
                                                                value="Not Occupied"
                                                            >
                                                                Not Occupied
                                                            </option>
                                                            <option
                                                                value="Habitable in 1 Mo."
                                                            >
                                                                Habitable in 1
                                                                Mo.
                                                            </option>
                                                            <option
                                                                value="Habitable in 2 Mo."
                                                            >
                                                                Habitable in 2
                                                                Mo.
                                                            </option>
                                                            <option
                                                                value="Habitable in 3 Mo."
                                                            >
                                                                Habitable in 3
                                                                Mo.
                                                            </option>
                                                            <option
                                                                value="Habitable in 4 Mo."
                                                            >
                                                                Habitable in 4
                                                                Mo.
                                                            </option>
                                                            <option
                                                                value="Habitable in 5 Mo."
                                                            >
                                                                Habitable in 5
                                                                Mo.
                                                            </option>
                                                            <option
                                                                value="Habitable in 6 Mo."
                                                            >
                                                                Habitable in 6
                                                                Mo.
                                                            </option>
                                                        </select>
                                                    </mat-form-field>
                                                </td>
                                                <td>
                                                    <!-- <mat-form-field class="example-full-width">
                            <input matInput placeholder="Borrower Phone" formControlName="BorrowerPhone" name="Location">
                          </mat-form-field> -->
                                                    <mat-form-field
                                                        class="example-full-width"
                                                    >
                                                        <span
                                                            class="mat-form-field-label-wrapper"
                                                            ><!----><label
                                                                class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                                >Contingency
                                                                Reserve
                                                                Percentage</label
                                                            ></span
                                                        >
                                                        <input
                                                            matInput
                                                            formControlName="ContingencyReservePercentage"
                                                            name="RepairItemName"
                                                        />
                                                    </mat-form-field>
                                                </td>
                                                <td>
                                                    <!-- <mat-form-field class="example-full-width">
                            <input matInput placeholder="FHA Case #" formControlName="FHACase" name="RepairItemName">
                          </mat-form-field> -->
                                                    <mat-form-field
                                                        class="example-full-width"
                                                    >
                                                        <span
                                                            class="mat-form-field-label-wrapper"
                                                            ><!----><label
                                                                class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                                >Months To
                                                                Completion</label
                                                            ></span
                                                        >
                                                        <input
                                                            matInput
                                                            formControlName="MonthToCompletion"
                                                            name="MonthToCompletion"
                                                        />
                                                    </mat-form-field>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <mat-form-field
                                                        class="example-full-width"
                                                    >
                                                        <span
                                                            class="mat-form-field-label-wrapper"
                                                            ><!----><label
                                                                class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                                >Loan No.</label
                                                            ></span
                                                        >
                                                        <input
                                                            matInput
                                                            formControlName="LoanNo"
                                                            name="LoanNo"
                                                        />
                                                    </mat-form-field>
                                                </td>
                                                <td>
                                                    <mat-form-field
                                                        class="propertyInspectionDate"
                                                    >
                                                        <span
                                                            class="mat-form-field-label-wrapper"
                                                            ><!----><label
                                                                class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                                >Inspection
                                                                Date</label
                                                            ></span
                                                        >
                                                        <input
                                                            matInput
                                                            [matDatepicker]="
                                                                dp3
                                                            "
                                                            (focus)="dp3.open()"
                                                            formControlName="InspectionDate"
                                                            name="InspectionDate"
                                                        />
                                                        <mat-datepicker-toggle
                                                            matSuffix
                                                            [for]="dp3"
                                                        ></mat-datepicker-toggle>
                                                        <mat-datepicker
                                                            #dp3
                                                            disabled="false"
                                                        ></mat-datepicker>
                                                    </mat-form-field>
                                                </td>
                                                <td></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                    <!-- <mat-form-field class="example-full-width">
                    <textarea matInput placeholder="Property Inspection Address" formControlName="InspectionAddress" name="Summary"></textarea>
                  </mat-form-field> -->
                                </div>
                                <br />
                                <br />
                            </div>
                        </div>
                        <mat-expansion-panel
                            (opened)="isAllowfeeSorExpand = true"
                            (closed)="isAllowfeeSorExpand = false"
                            [expanded]="isAllowfeeSorExpand"
                        >
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    Allowable Fees & Recap Totals
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <table>
                                <thead>
                                    <th>Select</th>
                                    <th>Fees Type</th>
                                    <th>Note</th>
                                    <th>Fees</th>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td align="center">
                                            <mat-checkbox
                                                formControlName="AllowableFeesDrawStatus"
                                            ></mat-checkbox>
                                        </td>
                                        <td align="center">Draw Fee</td>
                                        <td align="center">
                                            <mat-form-field
                                                style="
                                                    width: 100px;
                                                    margin: 0 10px 0 0px;
                                                "
                                            >
                                                <span
                                                    class="mat-form-field-label-wrapper"
                                                    ><!----><label
                                                        class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                        ># of Draws</label
                                                    ></span
                                                >
                                                <select
                                                    matNativeControl
                                                    (change)="
                                                        setMileageValueFromDraw();
                                                        setRoundMileageValueFromDraw()
                                                    "
                                                    formControlName="AllowableFeesDrawNote"
                                                    readonly="{{
                                                        !RepairItemForm.value[
                                                            'AllowableFeesDrawStatus'
                                                        ]
                                                    }}"
                                                >
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                    <option value="4">4</option>
                                                    <option value="5">5</option>
                                                    <option value="6">6</option>
                                                    <option value="7">7</option>
                                                    <option value="8">8</option>
                                                    <option value="9">9</option>
                                                    <option value="10">
                                                        10
                                                    </option>
                                                </select>
                                            </mat-form-field>
                                            Inspection(s)
                                        </td>
                                        <td align="center">
                                            <mat-form-field
                                                style="
                                                    width: 100px;
                                                    margin: 0 10px 0 0px;
                                                "
                                            >
                                                <input
                                                    matInput
                                                    placeholder=""
                                                    (blur)="setDrawFeeValue()"
                                                    formControlName="AllowableFeesDrawFees"
                                                    readonly="{{
                                                        !RepairItemForm.value[
                                                            'AllowableFeesDrawStatus'
                                                        ]
                                                    }}"
                                                />
                                            </mat-form-field>
                                            <mat-form-field
                                                style="width: 100px"
                                            >
                                                <input
                                                    matInput
                                                    placeholder=""
                                                    formControlName="AllowableFeesDrawFeesDis"
                                                    readonly="true"
                                                />
                                            </mat-form-field>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td align="center">
                                            <mat-checkbox
                                                formControlName="AllowableFeesMileageStatus"
                                            ></mat-checkbox>
                                        </td>
                                        <td align="center">Mileage</td>
                                        <td align="center">
                                            <mat-form-field>
                                                <input
                                                    matInput
                                                    placeholder=" "
                                                    formControlName="AllowableFeesMileageNote"
                                                    readonly="true"
                                                />
                                            </mat-form-field>
                                        </td>
                                        <td align="center">
                                            <mat-form-field>
                                                <input
                                                    matInput
                                                    placeholder=" "
                                                    formControlName="AllowableFeesMileageFees"
                                                    readonly="true"
                                                />
                                            </mat-form-field>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td align="center">
                                            <mat-checkbox
                                                formControlName="AllowableFeesRoundMileageStatus"
                                            ></mat-checkbox>
                                        </td>
                                        <td align="center">Rounded Mileage</td>
                                        <td align="center">
                                            <mat-form-field>
                                                <input
                                                    matInput
                                                    placeholder=" "
                                                    formControlName="AllowableFeesRoundMileageNote"
                                                    readonly="true"
                                                />
                                            </mat-form-field>
                                        </td>
                                        <td align="center">
                                            <mat-form-field>
                                                <input
                                                    matInput
                                                    placeholder=" "
                                                    formControlName="AllowableFeesRoundMileageFees"
                                                    readonly="true"
                                                />
                                            </mat-form-field>
                                        </td>
                                    </tr>
                                    <ng-container formArrayName="AllowableFees">
                                        <ng-container
                                            *ngFor="
                                                let AllowableFeesItemDetail of RepairItemForm.get(
                                                    'AllowableFees'
                                                )['controls'];
                                                let i = index
                                            "
                                        >
                                            <tr [formGroupName]="i">
                                                <td align="center">
                                                    <mat-checkbox
                                                        formControlName="Status"
                                                    ></mat-checkbox>
                                                </td>
                                                <td align="center">
                                                    {{
                                                        RepairItemForm.value[
                                                            "AllowableFees"
                                                        ][i].Title
                                                    }}
                                                </td>
                                                <td align="center">
                                                    <mat-form-field>
                                                        <input
                                                            matInput
                                                            placeholder=" "
                                                            formControlName="Notes"
                                                            readonly="{{
                                                                !RepairItemForm
                                                                    .value[
                                                                    'AllowableFees'
                                                                ][i].Status
                                                            }}"
                                                        />
                                                    </mat-form-field>
                                                </td>
                                                <td align="center">
                                                    <mat-form-field>
                                                        <input
                                                            matInput
                                                            placeholder=" "
                                                            formControlName="Fees"
                                                            readonly="{{
                                                                !RepairItemForm
                                                                    .value[
                                                                    'AllowableFees'
                                                                ][i].Status
                                                            }}"
                                                        />
                                                    </mat-form-field>
                                                </td>
                                            </tr>
                                        </ng-container>
                                    </ng-container>
                                </tbody>
                            </table>
                        </mat-expansion-panel>
                        <br />
                        <mat-expansion-panel
                            *ngIf="isFinalSOR == true"
                            (opened)="isFinalSorExpand = true"
                            (closed)="isFinalSorExpand = false"
                            [expanded]="isFinalSorExpand"
                        >
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    Permit Cost Type
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <table>
                                <thead>
                                    <th align="left">Select</th>
                                    <th>Permit Type</th>
                                    <th>Permit Cost</th>
                                </thead>
                                <tbody *ngIf="formStatus">
                                    <ng-container
                                        *ngFor="
                                            let AllowableFeesItemDetail of RepairItemForm.get(
                                                'PermitCost'
                                            )['controls'];
                                            let i = index
                                        "
                                        formArrayName="PermitCost"
                                    >
                                        <tr [formGroupName]="i">
                                            <td align="left">
                                                <mat-checkbox
                                                    formControlName="Status"
                                                ></mat-checkbox>
                                            </td>
                                            <td align="center">
                                                {{
                                                    RepairItemForm.value[
                                                        "PermitCost"
                                                    ][i].PermitType
                                                }}
                                            </td>
                                            <td align="center">
                                                <mat-form-field>
                                                    <input
                                                        matInput
                                                        placeholder=" "
                                                        formControlName="PermitCost"
                                                    />
                                                </mat-form-field>
                                            </td>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                        </mat-expansion-panel>
                        <mat-form-field
                            style="margin-top: 40px"
                            class="example-full-width"
                        >
                            <span class="mat-form-field-label-wrapper"
                                ><!----><label
                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                    >Consultant Comment</label
                                ></span
                            >
                            <textarea
                                matInput
                                class="ConsultantCommentTextarea"
                                formControlName="ConsultantComment"
                                name="Summary"
                            ></textarea>
                        </mat-form-field>
                        <button
                            mat-raised-button
                            type="submit"
                            color="primary"
                            class="btn-blue"
                        >
                            Save
                        </button>
                    </mat-card>
                </mat-card-content>
            </form>
        </mat-card>
    </div>
    <br /><br />
    <button
        [hidden]="!isList"
        type="button"
        color="primary"
        (click)="showAddActivityForm()"
        class="btn-blue addComponentFromLibBtn"
    >
        Add Component from library
    </button>
    <br /><br />
    <div class="mat-elevation-z8" [hidden]="!isList">
        <table mat-table [dataSource]="dataSource" matSort>
            <!-- ID Column -->
            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    S. No.
                </th>
                <td mat-cell *matCellDef="let row">
                    <span *ngIf="row.Operation != 'Inactive'">{{
                        row.id
                    }}</span>
                    <span *ngIf="row.Operation == 'Inactive'">#</span>
                </td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="RepairType">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Repair Type
                </th>
                <td mat-cell *matCellDef="let row">{{ row.RepairType }}</td>
            </ng-container>
            <ng-container matColumnDef="RepairTotal">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Repair Total
                </th>
                <td mat-cell *matCellDef="let row">
                    {{ row.RepairTotal | number : "1.2-2" }}
                </td>
            </ng-container>
            <!-- Progress Column -->
            <ng-container matColumnDef="Operation">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Operation
                </th>
                <td mat-cell *matCellDef="let row">
                    <a
                        href="javascript:void(0)"
                        *ngIf="row.Operation != 'Inactive'"
                        (click)="UpdateRepair(row.RepairType)"
                    >
                        Add/Edit {{ row.RepairType }}
                    </a>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr
                mat-row
                *matRowDef="let row; columns: displayedColumns"
                style="cursor: pointer"
            ></tr>
        </table>

        <mat-paginator
            (page)="nextPage($event)"
            [pageSizeOptions]="[50, 100]"
        ></mat-paginator>
    </div>
    <div
        class="mat-elevation-z8 addConstructionItemContainer"
        *ngIf="isAdd || isEdit"
    >
        <div class="container-login100">
            <mat-card class="my-form">
                <form
                    [formGroup]="RepairItemForm"
                    class="example-form"
                    (ngSubmit)="SaveConstructionItem()"
                >
                    <mat-card-header>
                        <mat-card-title></mat-card-title>
                    </mat-card-header>
                    <mat-card-content>
                        <mat-form-field>
                            <span class="mat-form-field-label-wrapper"
                                ><!----><label
                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                    >Construction Item</label
                                ></span
                            >
                            <select
                                matNativeControl
                                formControlName="SendRepairType"
                                [disabled]="true"
                            >
                                <option value="">Select</option>
                                <option
                                    *ngFor="let value of userList.Items"
                                    value="{{ value.RepairType }}"
                                >
                                    {{ value.id }} {{ value.RepairType }}
                                </option>
                            </select>
                        </mat-form-field>
                        <mat-card>
                            <mat-card-header>
                                <mat-card-title
                                    >Repair Item Details</mat-card-title
                                >
                            </mat-card-header>
                            <div formArrayName="RepairItemDetails">
                                <div
                                    *ngFor="
                                        let RepairItemDetail of RepairItemForm.get(
                                            'RepairItemDetails'
                                        )['controls'];
                                        let i = index
                                    "
                                >
                                    <div [formGroupName]="i">
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <mat-form-field
                                                            class="example-full-width"
                                                        >
                                                            <span
                                                                class="mat-form-field-label-wrapper"
                                                                ><!----><label
                                                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                                    >Repair Item
                                                                    Name</label
                                                                ></span
                                                            >
                                                            <input
                                                                matInput
                                                                formControlName="RepairItemName"
                                                                name="RepairItemName"
                                                            />
                                                        </mat-form-field>
                                                    </td>
                                                    <td>
                                                        <mat-form-field
                                                            class="example-full-width"
                                                        >
                                                            <span
                                                                class="mat-form-field-label-wrapper"
                                                                ><!----><label
                                                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                                    >Location</label
                                                                ></span
                                                            >

                                                            <input
                                                                matInput
                                                                formControlName="Location"
                                                                name="Location"
                                                            />
                                                        </mat-form-field>
                                                    </td>
                                                    <td>
                                                        <mat-form-field>
                                                            <span
                                                                class="mat-form-field-label-wrapper"
                                                                ><!----><label
                                                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                                    >Level</label
                                                                ></span
                                                            >
                                                            <select
                                                                matNativeControl
                                                                formControlName="Level"
                                                            >
                                                                <option
                                                                    value=""
                                                                ></option>
                                                                <option
                                                                    value="M"
                                                                >
                                                                    M =
                                                                    Mandatory By
                                                                    HUD
                                                                </option>
                                                                <option
                                                                    value="R"
                                                                >
                                                                    R =
                                                                    Recommended
                                                                    By
                                                                    Consultant/Other
                                                                </option>
                                                                <option
                                                                    value="D"
                                                                >
                                                                    D = Desired
                                                                    By Homeowner
                                                                </option>
                                                            </select>
                                                        </mat-form-field>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <h2
                                                            style="
                                                                font-size: 16px;
                                                                margin: 5px;
                                                            "
                                                        >
                                                            <Strong
                                                                >Material/LS</Strong
                                                            >
                                                        </h2>
                                                    </td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <mat-form-field>
                                                            <span
                                                                class="mat-form-field-label-wrapper"
                                                                ><!----><label
                                                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                                    >UNIT</label
                                                                ></span
                                                            >
                                                            <select
                                                                matNativeControl
                                                                formControlName="MaterialUnit"
                                                            >
                                                                <option
                                                                    value="EA"
                                                                >
                                                                    Each = EA
                                                                </option>
                                                                <option
                                                                    value="LF"
                                                                >
                                                                    Linear foot
                                                                    = LF
                                                                </option>
                                                                <option
                                                                    value="SF"
                                                                >
                                                                    Square Foot
                                                                    = SF
                                                                </option>
                                                                <option
                                                                    value="LS"
                                                                >
                                                                    Lump Sum =
                                                                    LS
                                                                </option>
                                                                <option
                                                                    value="SY"
                                                                >
                                                                    Square Yard
                                                                    = SY
                                                                </option>
                                                                <option
                                                                    value="*"
                                                                >
                                                                    * = Required
                                                                </option>
                                                            </select>
                                                        </mat-form-field>
                                                    </td>
                                                    <td>
                                                        <mat-form-field
                                                            class="example-full-width"
                                                        >
                                                            <span
                                                                class="mat-form-field-label-wrapper"
                                                                ><!----><label
                                                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                                    >Quantity</label
                                                                ></span
                                                            >
                                                            <input
                                                                matInput
                                                                formControlName="MaterialQuantity"
                                                                name="Quantity"
                                                                type="number"
                                                                class="example-right-align"
                                                            />
                                                        </mat-form-field>
                                                    </td>
                                                    <td>
                                                        <mat-form-field
                                                            class="example-full-width"
                                                        >
                                                            <!-- <input matInput placeholder="Unit Cost" formControlName="MaterialUnitCost" name="UnitCost"> -->
                                                            <span
                                                                class="mat-form-field-label-wrapper"
                                                                ><!----><label
                                                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                                    >Unit
                                                                    Cost</label
                                                                ></span
                                                            >
                                                            <input
                                                                matInput
                                                                formControlName="MaterialUnitCost"
                                                                name="UnitCost"
                                                                type="number"
                                                                class="example-right-align"
                                                            />
                                                        </mat-form-field>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <h2
                                                            style="
                                                                font-size: 16px;
                                                                margin: 5px;
                                                            "
                                                        >
                                                            <Strong
                                                                >Labor</Strong
                                                            >
                                                        </h2>
                                                    </td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <mat-form-field>
                                                            <span
                                                                class="mat-form-field-label-wrapper"
                                                                ><!----><label
                                                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                                    >UNIT</label
                                                                ></span
                                                            >
                                                            <select
                                                                matNativeControl
                                                                formControlName="LaborUnit"
                                                            >
                                                                <option
                                                                    value="EA"
                                                                >
                                                                    Each = EA
                                                                </option>
                                                                <option
                                                                    value="LF"
                                                                >
                                                                    Linear foot
                                                                    = LF
                                                                </option>
                                                                <option
                                                                    value="SF"
                                                                >
                                                                    Square Foot
                                                                    = SF
                                                                </option>
                                                                <option
                                                                    value="LS"
                                                                >
                                                                    Lump Sum =
                                                                    LS
                                                                </option>
                                                                <option
                                                                    value="SY"
                                                                >
                                                                    Square Yard
                                                                    = SY
                                                                </option>
                                                                <option
                                                                    value="*"
                                                                >
                                                                    * = Required
                                                                </option>
                                                            </select>
                                                        </mat-form-field>
                                                    </td>
                                                    <td>
                                                        <mat-form-field
                                                            class="example-full-width"
                                                        >
                                                            <span
                                                                class="mat-form-field-label-wrapper"
                                                                ><!----><label
                                                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                                    >Quantity</label
                                                                ></span
                                                            >
                                                            <input
                                                                matInput
                                                                formControlName="LaborQuantity"
                                                                name="Quantity"
                                                                type="number"
                                                                class="example-right-align"
                                                            />
                                                        </mat-form-field>
                                                    </td>
                                                    <td>
                                                        <mat-form-field
                                                            class="example-full-width"
                                                        >
                                                            <!-- <input matInput placeholder="Unit Cost" formControlName="LaborUnitCost" name="UnitCost"> -->
                                                            <span
                                                                class="mat-form-field-label-wrapper"
                                                                ><!----><label
                                                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                                    >Unit
                                                                    Cost</label
                                                                ></span
                                                            >
                                                            <input
                                                                matInput
                                                                formControlName="LaborUnitCost"
                                                                name="UnitCost"
                                                                type="number"
                                                                class="example-right-align"
                                                            />
                                                        </mat-form-field>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <br />
                                        <mat-form-field
                                            class="example-full-width"
                                        >
                                            <span
                                                class="mat-form-field-label-wrapper"
                                                ><!----><label
                                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                    >Summary</label
                                                ></span
                                            >
                                            <textarea
                                                matInput
                                                rows="8"
                                                formControlName="Summary"
                                                name="Summary"
                                            ></textarea>
                                        </mat-form-field>

                                        <button
                                            style="
                                                float: right;
                                                margin: 0px 5px;
                                                cursor: pointer;
                                            "
                                            (click)="deleteRepairItems(i)"
                                        >
                                            Remove
                                        </button>
                                        <a
                                            class="btnTypeA"
                                            (click)="addItemToLibrary(i)"
                                            >Add Item To Library</a
                                        >
                                    </div>
                                    <br />
                                    <br />
                                </div>
                                <button
                                    type="button"
                                    (click)="addRepairItems()"
                                >
                                    Add another item
                                </button>
                            </div>
                        </mat-card>
                    </mat-card-content>
                    <mat-card-actions>
                        <button
                            mat-raised-button
                            type="submit"
                            color="primary"
                            class="btn-blue"
                        >
                            {{ isEdit ? "Save" : "Save" }}</button
                        >&nbsp; &nbsp;&nbsp;
                        <button
                            mat-raised-button
                            (click)="RepairList()"
                            type="button"
                            color="info"
                        >
                            Back
                        </button>
                    </mat-card-actions>
                </form>
            </mat-card>
        </div>
    </div>

    <div
        class="container-login10011 addActivityGroupContainer mat-elevation-z8"
        *ngIf="isActAdd || isActEdit"
    >
        <div class="container-login100">
            <mat-card class="my-form">
                <form
                    [formGroup]="RepairActivityItemForm"
                    class="example-form"
                    (ngSubmit)="SaveActivityItem()"
                >
                    <mat-card-header>
                        <mat-card-title
                            >Report Component Quick Add</mat-card-title
                        >
                    </mat-card-header>
                    <mat-card-content>
                        <table>
                            <tbody>
                                <tr>
                                    <td class="groupName_FilterSec">
                                        <mat-form-field
                                            class="example-full-width"
                                        >
                                            <mat-label
                                                >Filter Items By</mat-label
                                            >
                                            <mat-select
                                                class="groupNameField"
                                                (selectionChange)="
                                                    filterRC($event)
                                                "
                                            >
                                                <mat-option
                                                    value="individual-report-components"
                                                    >Individual Report
                                                    Components</mat-option
                                                >
                                                <mat-option
                                                    value="construction-activity-groups"
                                                    >Construction Activity
                                                    Groups</mat-option
                                                >
                                            </mat-select>
                                        </mat-form-field>

                                        <mat-form-field
                                            class="example-full-width"
                                        >
                                            <mat-label
                                                >Search Components</mat-label
                                            >
                                            <input
                                                matInput
                                                class="groupNameField"
                                                name="searchComponent"
                                                (keyup)="
                                                    filterComponents($event)
                                                "
                                            />
                                        </mat-form-field>
                                    </td>
                                    <td>&nbsp;</td>
                                    <td>
                                        Filter items by construction activity
                                        groups or individual report components
                                    </td>
                                </tr>
                                <tr class="textAlignCenter">
                                    <td><span>Report Components</span></td>
                                    <td>&nbsp;</td>
                                    <td>
                                        <span> Components Added to Draft</span>
                                    </td>
                                </tr>
                                <tr class="componentListBox">
                                    <td>
                                        <ul
                                            *ngIf="
                                                selectedFilterVal ==
                                                'individual-report-components'
                                            "
                                        >
                                            <li
                                                *ngFor="
                                                    let repairComponentItem of repairComponentList;
                                                    let i = index
                                                "
                                            >
                                                <ng-container
                                                    *ngFor="
                                                        let comItem of userList.Items;
                                                        let k = index
                                                    "
                                                >
                                                    <div
                                                        *ngIf="
                                                            comItem.RepairType ==
                                                            repairComponentItem.RepairType
                                                        "
                                                    >
                                                        <div
                                                            class="actionBtn"
                                                            (click)="
                                                                expandActivityComList(
                                                                    i
                                                                )
                                                            "
                                                        >
                                                            <a
                                                                class="expandIcon"
                                                                >+</a
                                                            ><span
                                                                >{{
                                                                    comItem.id
                                                                }}.&nbsp;
                                                                {{
                                                                    repairComponentItem.RepairType
                                                                }}
                                                            </span>
                                                        </div>

                                                        <ul
                                                            class="activityComList"
                                                            *ngIf="
                                                                i ==
                                                                isShowComItem
                                                            "
                                                        >
                                                            <ng-container
                                                                *ngFor="
                                                                    let componentItem of repairComponentItem.RepairItemDetails;
                                                                    let k = index
                                                                "
                                                            >
                                                                <li
                                                                    class="activityComItemName"
                                                                >
                                                                    <input
                                                                        type="checkbox"
                                                                        name="reportCompName[]"
                                                                        [checked]="
                                                                            tempOnlyComp.indexOf(
                                                                                repairComponentItem.RepairType +
                                                                                    '_' +
                                                                                    componentItem.RepairItemName
                                                                            ) >
                                                                            -1
                                                                        "
                                                                        (click)="
                                                                            toggleSelection(
                                                                                comItem.id,
                                                                                repairComponentItem.RepairType,
                                                                                repairComponentItem.RepairType,
                                                                                componentItem.RepairItemName
                                                                            )
                                                                        "
                                                                        value="{{
                                                                            componentItem.RepairItemName
                                                                        }}"
                                                                    />
                                                                    {{
                                                                        componentItem.RepairItemName
                                                                    }}
                                                                </li>
                                                            </ng-container>
                                                        </ul>
                                                    </div>
                                                </ng-container>
                                            </li>
                                        </ul>
                                        <ul
                                            *ngIf="
                                                selectedFilterVal ==
                                                'construction-activity-groups'
                                            "
                                        >
                                            <li
                                                *ngFor="
                                                    let repairComponentItem of repairComponentList;
                                                    let i = index
                                                "
                                            >
                                                <div
                                                    class="actionBtn"
                                                    (click)="
                                                        expandActivityComList(i)
                                                    "
                                                >
                                                    <a class="expandIcon">+</a
                                                    >&nbsp;<input
                                                        type="checkbox"
                                                        name="selectedGroupName[]"
                                                        [checked]="
                                                            tempOnlyType.indexOf(
                                                                repairComponentItem.RepairItemName
                                                            ) > -1
                                                        "
                                                        (click)="
                                                            toggleforAllSelection(
                                                                i + 1,
                                                                repairComponentItem.RepairItemName
                                                            )
                                                        "
                                                        value="{{
                                                            repairComponentItem.RepairItemName
                                                        }}"
                                                    />&nbsp;<span
                                                        >{{ i + 1 }}.&nbsp;
                                                        {{
                                                            repairComponentItem.RepairItemName
                                                        }}
                                                    </span>
                                                </div>

                                                <ul
                                                    class="activityComList"
                                                    *ngIf="i == isShowComItem"
                                                >
                                                    <ng-container
                                                        *ngFor="
                                                            let componentItem of repairComponentItem.ReportComponent;
                                                            let k = index
                                                        "
                                                    >
                                                        <ng-container
                                                            *ngFor="
                                                                let selItem of componentItem.RepairItemDetails;
                                                                let l = index
                                                            "
                                                        >
                                                            <li
                                                                class="activityComItemName"
                                                            >
                                                                <input
                                                                    type="checkbox"
                                                                    name="reportCompName[]"
                                                                    [checked]="
                                                                        tempOnlyComp.indexOf(
                                                                            repairComponentItem.RepairItemName +
                                                                                '_' +
                                                                                selItem.RepairItemName
                                                                        ) > -1
                                                                    "
                                                                    (click)="
                                                                        toggleSelection(
                                                                            i +
                                                                                1,
                                                                            componentItem.RepairType,
                                                                            repairComponentItem.RepairItemName,
                                                                            selItem.RepairItemName
                                                                        )
                                                                    "
                                                                    value="{{
                                                                        selItem.RepairItemName
                                                                    }}"
                                                                />
                                                                {{
                                                                    selItem.RepairItemName
                                                                }}
                                                            </li>
                                                        </ng-container>
                                                    </ng-container>
                                                </ul>
                                            </li>
                                        </ul>
                                    </td>
                                    <td>
                                        <a
                                            class="moveLeftToRight"
                                            (click)="addInSelectedList()"
                                        >
                                            &gt; </a
                                        ><br />
                                        <a
                                            class="moveRightToleft"
                                            (click)="removeFromSelectedList()"
                                        >
                                            &lt;
                                        </a>
                                    </td>
                                    <td>
                                        <ul>
                                            <li
                                                *ngFor="
                                                    let selectedComponent of selectedComponentListForGroup;
                                                    let i = index
                                                "
                                            >
                                                <div
                                                    class="actionBtn"
                                                    (click)="
                                                        expandSelectedActivityComList(
                                                            i
                                                        )
                                                    "
                                                >
                                                    <a class="expandIcon">+</a
                                                    ><span
                                                        >{{
                                                            selectedComponent.index
                                                        }}.&nbsp;
                                                        {{
                                                            selectedComponent.RepairType
                                                        }}
                                                    </span>
                                                </div>

                                                <ul
                                                    class="activitySelectComList"
                                                    *ngIf="
                                                        i ==
                                                        isShowSelectedComItem
                                                    "
                                                >
                                                    <ng-container
                                                        *ngFor="
                                                            let componentItem of selectedComponent.RepairItemDetails;
                                                            let k = index
                                                        "
                                                    >
                                                        <li
                                                            class="activityComItemName"
                                                        >
                                                            <span
                                                                ><input
                                                                    type="checkbox"
                                                                    name="activeReportCompName[]"
                                                                    [checked]="
                                                                        tempDeSelection.indexOf(
                                                                            componentItem.RepairItemName
                                                                        ) > -1
                                                                    "
                                                                    (click)="
                                                                        toggleDeSelection(
                                                                            i,
                                                                            selectedComponent.RepairType,
                                                                            componentItem.RepairItemName
                                                                        )
                                                                    "
                                                                    value="{{
                                                                        componentItem.RepairItemName
                                                                    }}"
                                                                />
                                                                {{
                                                                    componentItem.RepairItemName
                                                                }}
                                                            </span>
                                                        </li>
                                                    </ng-container>
                                                </ul>
                                            </li>
                                        </ul>
                                    </td>
                                </tr>

                                <tr>
                                    <td colspan="3">
                                        <div class="popupSaveBackBtn">
                                            <button
                                                mat-raised-button
                                                type="submit"
                                                color="primary"
                                            >
                                                {{
                                                    isEdit ? "Save" : "Save"
                                                }}</button
                                            >&nbsp; &nbsp;&nbsp;
                                            <button
                                                mat-raised-button
                                                (click)="backFromLibraryPopup()"
                                                type="button"
                                                color="info"
                                            >
                                                Back
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </mat-card-content>
                </form>
            </mat-card>
        </div>
    </div>
</div>
