import { Component, OnInit, ViewChild } from "@angular/core";
import { FormGroup, Validators, FormControl } from "@angular/forms";
import { MatAccordion } from "@angular/material/expansion";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { DataSource } from "@angular/cdk/table";
import { BorrowerService } from "../borrower/borrower.service";
import { SessionService } from "../service/session.service";

export interface UserData {
    id: string;
    name: string;
    email: string;
    lastLogin: Date;
}

/** Constants used to fill up our data base. */
const COLORS: string[] = [
    "maroon",
    "red",
    "orange",
    "yellow",
    "olive",
    "green",
    "purple",
    "fuchsia",
    "lime",
    "teal",
    "aqua",
    "blue",
    "navy",
    "black",
    "gray",
];
const NAMES: string[] = [
    "Maia",
    "Asher",
    "Olivia",
    "Atticus",
    "Amelia",
    "Jack",
    "Charlotte",
    "Theodore",
    "Isla",
    "Oliver",
    "Isabella",
    "Jasper",
    "Cora",
    "Levi",
    "Violet",
    "Arthur",
    "Mia",
    "Thomas",
    "Elizabeth",
];

/**
 * @title Data table with sorting, pagination, and filtering.
 */

@Component({
    selector: "app-consultant-liability-form-setting",
    templateUrl: "./form-setting.component.html",
    styleUrls: ["./form-setting.component.scss"],
})
export class FormSettingComponent implements OnInit {
    displayedColumns: string[] = ["id", "fname", "lname", "email", "lstLogin"];
    dataSource: MatTableDataSource<UserData>;
    mobnumPattern = "^(d{3})sd{3}-d{4}";
    EmailSetting = new FormGroup({
        ReplyToEmail: new FormControl(null),
        id: new FormControl(null),
        NewUserRegistrationBCC: new FormControl(null),
        NewUserRegistrationSubject: new FormControl(null, [Validators.maxLength(200)]),
        NewUserRegistrationBody: new FormControl(null),
        BorrowerAboutRegistrationBCC: new FormControl(null),
        BorrowerAboutRegistrationSubject: new FormControl(null, [Validators.maxLength(200)]),
        BorrowerAboutRegistrationBody: new FormControl(null),
        GCAboutRegistrationBCC: new FormControl(null),
        GCAboutRegistrationSubject: new FormControl(null, [Validators.maxLength(200)]),
        GCAboutRegistrationBody: new FormControl(null),
        LOAboutRegistrationBCC: new FormControl(null),
        LOAboutRegistrationSubject: new FormControl(null, [Validators.maxLength(200)]),
        LOAboutRegistrationBody: new FormControl(null),
        DAAboutRegistrationBCC: new FormControl(null),
        DAAboutRegistrationSubject: new FormControl(null, [Validators.maxLength(200)]),
        DAAboutRegistrationBody: new FormControl(null),
        UsersAboutPropertyCreationMailTo: new FormControl(null),
        UsersAboutPropertyCreationSubject: new FormControl(null, [Validators.maxLength(200)]),
        UsersAboutPropertyCreationBody: new FormControl(null),
        BOLOCOAboutNewPropertyWithPurchaseMailTo: new FormControl(null),
        BOLOCOAboutNewPropertyWithPurchaseSubject: new FormControl(null, [Validators.maxLength(200)]),
        BOLOCOAboutNewPropertyWithPurchaseBody: new FormControl(null),
        RealtorAboutPropertyAssignedMailTo: new FormControl(null),
        RealtorAboutPropertyAssignedSubject: new FormControl(null, [Validators.maxLength(200)]),
        RealtorAboutPropertyAssignedBody: new FormControl(null),
        COLOBOWhenGCBiddingDueDateAreNoFilledMailTo: new FormControl(null),
        COLOBOWhenGCBiddingDueDateAreNoFilledSubject: new FormControl(null, [Validators.maxLength(200)]),
        COLOBOWhenGCBiddingDueDateAreNoFilledBody: new FormControl(null),
        BOForApprovalOfSpecificationOfRepairMailTo: new FormControl(null),
        BOForApprovalOfSpecificationOfRepairSubject: new FormControl(null, [Validators.maxLength(200)]),
        BOForApprovalOfSpecificationOfRepairBody: new FormControl(null),
        BOForApprovalOfEditSpecificationOfRepairMailTo: new FormControl(null),
        BOForApprovalOfEditSpecificationOfRepairSubject: new FormControl(null, [Validators.maxLength(200)]),
        BOForApprovalOfEditSpecificationOfRepairBody: new FormControl(null),
        LOForApprovalOfSpecificationOfRepairMailTo: new FormControl(null),
        LOForApprovalOfSpecificationOfRepairSubject: new FormControl(null, [Validators.maxLength(200)]),
        LOForApprovalOfSpecificationOfRepairBody: new FormControl(null),
        COLOWhenSpecificationOfRepairAcceptedMailTo: new FormControl(null),
        COLOWhenSpecificationOfRepairAcceptedSubject: new FormControl(null, [Validators.maxLength(200)]),
        COLOWhenSpecificationOfRepairAcceptedBody: new FormControl(null),
        COLOBOGCWhenBORProcessStartMailTo: new FormControl(null),
        COLOBOGCWhenBORProcessStartSubject: new FormControl(null, [Validators.maxLength(200)]),
        COLOBOGCWhenBORProcessStartBody: new FormControl(null),
        WiningGCMailTo: new FormControl(null),
        WiningGCSubject: new FormControl(null, [Validators.maxLength(200)]),
        WiningGCBody: new FormControl(null),
        NonSelectedGCMailTo: new FormControl(null),
        NonSelectedGCSubject: new FormControl(null, [Validators.maxLength(200)]),
        NonSelectedGCBody: new FormControl(null),
        COBOLORealtorWhenGCProposalACCEPTEDMailTo: new FormControl(null),
        COBOLORealtorWhenGCProposalACCEPTEDSubject: new FormControl(null, [Validators.maxLength(200)]),
        COBOLORealtorWhenGCProposalACCEPTEDBody: new FormControl(null),
        UsersWhenBOAcceptBIDONREPAIRMailTo: new FormControl(null),
        UsersWhenBOAcceptBIDONREPAIRSubject: new FormControl(null, [Validators.maxLength(200)]),
        UsersWhenBOAcceptBIDONREPAIRBody: new FormControl(null),
        GCWhenInvitedForBiddingMailTo: new FormControl(null),
        GCWhenInvitedForBiddingSubject: new FormControl(null, [Validators.maxLength(200)]),
        GCWhenInvitedForBiddingBody: new FormControl(null),
        GCWhenInvitedForRevisedBiddingMailTo: new FormControl(null),
        GCWhenInvitedForRevisedBiddingSubject: new FormControl(null, [Validators.maxLength(200)]),
        GCWhenInvitedForRevisedBiddingBody: new FormControl(null),
        GCBOLOREBeforeBiddingEndMailTo: new FormControl(null),
        GCBOLOREBeforeBiddingEndSubject: new FormControl(null, [Validators.maxLength(200)]),
        GCBOLOREBeforeBiddingEndBody: new FormControl(null),
        GCBOAfterBiddingEndMailTo: new FormControl(null),
        GCBOAfterBiddingEndSubject: new FormControl(null, [Validators.maxLength(200)]),
        GCBOAfterBiddingEndBody: new FormControl(null),
        COBOGCAfterGCSubmitBIDMailTo: new FormControl(null),
        COBOGCAfterGCSubmitBIDSubject: new FormControl(null, [Validators.maxLength(200)]),
        COBOGCAfterGCSubmitBIDBody: new FormControl(null),
        COBOLOGCAfterFinalSORCreationMailTo: new FormControl(null),
        COBOLOGCAfterFinalSORCreationSubject: new FormControl(null, [Validators.maxLength(200)]),
        COBOLOGCAfterFinalSORCreationBody: new FormControl(null),
        COForRivisionOfSORMailTo: new FormControl(null),
        COForRivisionOfSORSubject: new FormControl(null, [Validators.maxLength(200)]),
        COForRivisionOfSORBody: new FormControl(null),
        COForRivisionRequestOfSORByBOMailTo: new FormControl(null),
        COForRivisionRequestOfSORByBOSubject: new FormControl(null, [Validators.maxLength(200)]),
        COForRivisionRequestOfSORByBOBody: new FormControl(null),
        GCCOForRevisionRequestOfBORByBOMailTo: new FormControl(null),
        GCCOForRevisionRequestOfBORByBOSubject: new FormControl(null, [Validators.maxLength(200)]),
        GCCOForRevisionRequestOfBORByBOBody: new FormControl(null),
        GCBOForRevisionRequestOfBORByCOMailTo: new FormControl(null),
        GCBOForRevisionRequestOfBORByCOSubject: new FormControl(null, [Validators.maxLength(200)]),
        GCBOForRevisionRequestOfBORByCOBody: new FormControl(null),
        COBOLORLAboutGCOPTOutFromBidProcessMailTo: new FormControl(null),
        COBOLORLAboutGCOPTOutFromBidProcessSubject: new FormControl(null, [Validators.maxLength(200)]),
        COBOLORLAboutGCOPTOutFromBidProcessBody: new FormControl(null),
        COBOLOGCAboutBidProcessResetMailTo: new FormControl(null),
        COBOLOGCAboutBidProcessResetSubject: new FormControl(null, [Validators.maxLength(200)]),
        COBOLOGCAboutBidProcessResetBody: new FormControl(null),
        AboutGCBlankBidFormMailTo: new FormControl(null),
        AboutGCBlankBidFormSubject: new FormControl(null, [Validators.maxLength(200)]),
        AboutGCBlankBidFormBody: new FormControl(null),
        BOAboutInvoiceAndSendInvoicePDFMailTo: new FormControl(null),
        BOAboutInvoiceAndSendInvoicePDFSubject: new FormControl(null, [Validators.maxLength(200)]),
        BOAboutInvoiceAndSendInvoicePDFBody: new FormControl(null),
        LOAboutInvoiceAndSendInvoicePDFMailTo: new FormControl(null),
        LOAboutInvoiceAndSendInvoicePDFSubject: new FormControl(null, [Validators.maxLength(200)]),
        LOAboutInvoiceAndSendInvoicePDFBody: new FormControl(null),
        BOAboutSendPDFAttachmentMailTo: new FormControl(null),
        BOAboutSendPDFAttachmentSubject: new FormControl(null, [Validators.maxLength(200)]),
        BOAboutSendPDFAttachmentBody: new FormControl(null),
        COWhenBOGCFillThiredColumnInInitialDrawRequestMailTo: new FormControl(
            null
        ),
        COWhenBOGCFillThiredColumnInInitialDrawRequestSubject: new FormControl(
            null, [Validators.maxLength(200)]
        ),
        COWhenBOGCFillThiredColumnInInitialDrawRequestBody: new FormControl(
            null
        ),
        BOForApprovalInitialDrawFormSentByGCMailTo: new FormControl(null),
        BOForApprovalInitialDrawFormSentByGCSubject: new FormControl(null, [Validators.maxLength(200)]),
        BOForApprovalInitialDrawFormSentByGCBody: new FormControl(null),
        DrawRequestedForInspectionMailTo: new FormControl(null),
        DrawRequestedForInspectionSubject: new FormControl(null, [Validators.maxLength(200)]),
        DrawRequestedForInspectionBody: new FormControl(null),
        GCForApprovalInitialDrawFormSentByBOMailTo: new FormControl(null),
        GCForApprovalInitialDrawFormSentByBOSubject: new FormControl(null, [Validators.maxLength(200)]),
        GCForApprovalInitialDrawFormSentByBOBody: new FormControl(null),
        BOAboutPostClosingProcessMailTo: new FormControl(null),
        BOAboutPostClosingProcessSubject: new FormControl(null, [Validators.maxLength(200)]),
        BOAboutPostClosingProcessBody: new FormControl(null),
        ChangeOrderFormEmailMailTo: new FormControl(null),
        ChangeOrderFormEmailSubject: new FormControl(null, [Validators.maxLength(200)]),
        ChangeOrderFormEmailBody: new FormControl(null),
        BOWhenGCSendReviseRequestFromInitialDrawRequestFormMailTo:
            new FormControl(null),
        BOWhenGCSendReviseRequestFromInitialDrawRequestFormSubject:
            new FormControl(null, [Validators.maxLength(200)]),
        BOWhenGCSendReviseRequestFromInitialDrawRequestFormBody:
            new FormControl(null),
        DrawAdministratorAboutPropertyAssignedMailTo: new FormControl(null),
        DrawAdministratorAboutPropertyAssignedSubject: new FormControl(null, [Validators.maxLength(200)]),
        DrawAdministratorAboutPropertyAssignedBody: new FormControl(null),
        GCWhenBOSendReviseRequestFromInitialDrawRequestFormMailTo:
            new FormControl(null),
        GCWhenBOSendReviseRequestFromInitialDrawRequestFormSubject:
            new FormControl(null, [Validators.maxLength(200)]),
        GCWhenBOSendReviseRequestFromInitialDrawRequestFormBody:
            new FormControl(null),
        GCAfterDrawFormRevisedByBOMailTo: new FormControl(null),
        GCAfterDrawFormRevisedByBOSubject: new FormControl(null, [Validators.maxLength(200)]),
        GCAfterDrawFormRevisedByBOBody: new FormControl(null),
        BOAfterDrawFormRevisedByGCMailTo: new FormControl(null),
        BOAfterDrawFormRevisedByGCSubject: new FormControl(null, [Validators.maxLength(200)]),
        BOAfterDrawFormRevisedByGCBody: new FormControl(null),
        COBOGCWhenInitialDrawRequestFormIsRevisedAndAcceptedMailTo:
            new FormControl(null),
        COBOGCWhenInitialDrawRequestFormIsRevisedAndAcceptedSubject:
            new FormControl(null, [Validators.maxLength(200)]),
        COBOGCWhenInitialDrawRequestFormIsRevisedAndAcceptedBody:
            new FormControl(null),
        DrawReminderRemindBoAboutDraw: new FormControl(null),
        DrawReminderRemindDays: new FormControl(null),
        DrawReminderMailTo: new FormControl(null),
        DrawReminderSubject: new FormControl(null, [Validators.maxLength(200)]),
        DrawReminderBody: new FormControl(null),
        SORIsCompletedRemindLOLPAboutSORCompleted: new FormControl(null),
        SORIsCompletedRemindDays: new FormControl(null),
        SORIsCompletedMailTo: new FormControl(null),
        SORIsCompletedSubject: new FormControl(null, [Validators.maxLength(200)]),
        SORIsCompletedBody: new FormControl(null),
        ReminderForNotifyBOForApprovalOfSORReminderForNotifyBOForApprovalOfSOR:
            new FormControl(null),
        ReminderForNotifyBOForApprovalOfSORRemindDays: new FormControl(null),
        ReminderForNotifyBOForApprovalOfSORMailTo: new FormControl(null),
        ReminderForNotifyBOForApprovalOfSORSubject: new FormControl(null, [Validators.maxLength(200)]),
        ReminderForNotifyBOForApprovalOfSORBody: new FormControl(null),
        EmailSettingAPIKey: new FormControl(null),
        EmailSettingDomain: new FormControl(null),
        EmailSettingFromEmail: new FormControl(null),
        MileageSettingOfficeLocation: new FormControl(null),
        MileageSettingMilesthatSubtracted: new FormControl(null),
        MileageSettingHomeStyleMilesthatSubtracted: new FormControl(null),
        MileageSettingIRSPerMileRate: new FormControl(null),
        MileageSettingHomeStyleIRSPerMileRate: new FormControl(null),
        DocSignAPIServrer: new FormControl(null),
        DocSignAPIApiPath: new FormControl(null),
        DocSignAPIRedirectURI: new FormControl(null),
        DocSignAPIIntegrationKey: new FormControl(null),
        DocSignAPIUserUID: new FormControl(null),
        DocSignAPIPrivateKey: new FormControl(null),
        DocSignAPIClientId: new FormControl(null),
        CompanyName: new FormControl(null),
        CompanyPhone: new FormControl(null),
        CompanyLogo: new FormControl(null),
        CopyrightMessage: new FormControl(null),
        GoogleKey: new FormControl(null),
        AwsConfigRegion: new FormControl(null),
        AwsS3BucketName: new FormControl(null),
        AwsAccessKeyId: new FormControl(null),
        AwsSecretAccessKey: new FormControl(null),
        ConsultantFee: new FormControl(null),
        PerChangeOrder: new FormControl(null),
        ScopeofWorkChangeMin: new FormControl(null),
        ConsultantFeeReimbursed: new FormControl(null),
        DrawRequestMin: new FormControl(null),
        ScopeofWorkChangeMax: new FormControl(null),
        EstimateBelow100k: new FormControl(null),
        DrawRequestMax: new FormControl(null),
        MultiFamilyDwelling: new FormControl(null),
        EstimateBetween100k150k: new FormControl(null),
        SelfHelpDraw: new FormControl(null),
        WorkPlan: new FormControl(null),
        EstimateAbove150k: new FormControl(null),
        CreatedBy: new FormControl(null),
        CreatedDate: new FormControl(null),
        UpdateDate: new FormControl(null),
        UpdatedBy: new FormControl(null),
    });

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatAccordion) accordion: MatAccordion;
    isList: boolean = true;
    isAdd: boolean = false;
    isEdit: boolean = false;
    submitted: boolean = false;
    favoriteSeason: string;
    userDetail: any = {};
    userList: any = {};
    EmailSettingData: any = {};
    loading = false;
    status: any = ["Blocked", "Active"];
    roles: any = [
        { Key: "1", Value: "authenticated user" },
        { Key: "2", Value: "Administrator" },
        { Key: "3", Value: "Consultants" },
        { Key: "4", Value: "General Contractors" },
        { Key: "5", Value: "Borrower" },
        { Key: "6", Value: "Loan Officer" },
        { key: "7", Value: "Loan Processor" },
        { key: "8", Value: " Notify user of new account" },
    ];
    constructor(
        private _bservice: BorrowerService,
        private sessionService: SessionService
    ) {}

    ngOnInit() {
        if (this.sessionService.get("userInfo") != undefined) {
            this.userDetail = JSON.parse(this.sessionService.get("userInfo"));
        }
        this.GetEmailSetting();
    }

    keyDownPhoneFormat($event) {
        var key = $event.keyCode;

        if (key == 91 || (15 < key && key < 19) || (37 <= key && key <= 40)) {
            return;
        }
        this.changePhoneFormat($event);
    }

    changePhoneFormat($event) {
        var getVal = $event.target.value;
        var tel = getVal.replace(/[^0-9]/g, "");
        $event.target.value = this.changeFilter(tel);
    }
    changeFilter(tel) {
        if (!tel) {
            return "";
        }

        var value = tel.toString().trim().replace(/^\+/, "");

        if (value.match(/[^0-9]/)) {
            return tel;
        }

        var country, city, number;

        switch (value.length) {
            case 1:
            case 2:
            case 3:
                city = value;
                break;

            default:
                city = value.slice(0, 3);
                number = value.slice(3);
        }

        if (number) {
            if (number.length > 3) {
                number = number.slice(0, 3) + "-" + number.slice(3, 7);
            } else {
                number = number;
            }

            return ("(" + city + ") " + number).trim();
        } else {
            return "(" + city;
        }
    }
    applyFilter(filterValue) {
        const target = filterValue as HTMLInputElement;
        const targetValue = target.value;
        this.dataSource.filter = targetValue.trim().toLowerCase();
        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    }

    showAddForm() {
        this.isAdd = true;
        this.isList = false;
    }

    loanOfficerList() {
        this.isEdit = false;
        this.isList = true;
    }

    SaveEmailSetting() {
        if (this.EmailSetting.invalid) {
            return;
        } else {
            this.submitted = false;
            var formData = this.EmailSetting.value;

            var data = {
                Id: formData.id,
                ReplyToEmail: formData.ReplyToEmail,
                NewAccountEmails: {
                    NewUserRegistration: {
                        BCC: formData.NewUserRegistrationBCC,
                        Subject: formData.NewUserRegistrationSubject,
                        Body: formData.NewUserRegistrationBody,
                    },
                    NewBorrowerRegistration: {
                        BCC: formData.BorrowerAboutRegistrationBCC,
                        Subject: formData.BorrowerAboutRegistrationSubject,
                        Body: formData.BorrowerAboutRegistrationBody,
                    },
                    NewGCRegistration: {
                        BCC: formData.GCAboutRegistrationBCC,
                        Subject: formData.GCAboutRegistrationSubject,
                        Body: formData.GCAboutRegistrationBody,
                    },
                    NewLORegistration: {
                        BCC: formData.LOAboutRegistrationBCC,
                        Subject: formData.LOAboutRegistrationSubject,
                        Body: formData.LOAboutRegistrationBody,
                    },
                    NewDARegistration: {
                        BCC: formData.DAAboutRegistrationBCC,
                        Subject: formData.DAAboutRegistrationSubject,
                        Body: formData.DAAboutRegistrationBody,
                    },
                },
                PreClosingEmails: {
                    FileCreated: {
                        UsersAboutPropertyCreation: {
                            MailTo: formData.UsersAboutPropertyCreationMailTo,
                            Subject: formData.UsersAboutPropertyCreationSubject,
                            Body: formData.UsersAboutPropertyCreationBody,
                        },
                        BOLOCOAboutNewPropertyWithPurchase: {
                            MailTo: formData.BOLOCOAboutNewPropertyWithPurchaseMailTo,
                            Subject:
                                formData.BOLOCOAboutNewPropertyWithPurchaseSubject,
                            Body: formData.BOLOCOAboutNewPropertyWithPurchaseBody,
                        },
                        RealtorAboutPropertyAssigned: {
                            MailTo: formData.RealtorAboutPropertyAssignedMailTo,
                            Subject:
                                formData.RealtorAboutPropertyAssignedSubject,
                            Body: formData.RealtorAboutPropertyAssignedBody,
                        },
                    },
                    InitialInspectionCcompleted: {
                        COLOBOWhenGCBiddingDueDateAreNoFilled: {
                            MailTo: formData.COLOBOWhenGCBiddingDueDateAreNoFilledMailTo,
                            Subject:
                                formData.COLOBOWhenGCBiddingDueDateAreNoFilledSubject,
                            Body: formData.COLOBOWhenGCBiddingDueDateAreNoFilledBody,
                        },
                    },
                    DraftSORSentToBO: {
                        BOForApprovalOfSpecificationOfRepair: {
                            MailTo: formData.BOForApprovalOfSpecificationOfRepairMailTo,
                            Subject:
                                formData.BOForApprovalOfSpecificationOfRepairSubject,
                            Body: formData.BOForApprovalOfSpecificationOfRepairBody,
                        },
                        BOForApprovalOfEditSpecificationOfRepair: {
                            MailTo: formData.BOForApprovalOfEditSpecificationOfRepairMailTo,
                            Subject:
                                formData.BOForApprovalOfEditSpecificationOfRepairSubject,
                            Body: formData.BOForApprovalOfEditSpecificationOfRepairBody,
                        },
                    },
                    BIDProcessStarted: {
                        LOForApprovalOfSpecificationOfRepair: {
                            MailTo: formData.LOForApprovalOfSpecificationOfRepairMailTo,
                            Subject:
                                formData.LOForApprovalOfSpecificationOfRepairSubject,
                            Body: formData.LOForApprovalOfSpecificationOfRepairBody,
                        },
                        COLOWhenSpecificationOfRepairAccepted: {
                            MailTo: formData.COLOWhenSpecificationOfRepairAcceptedMailTo,
                            Subject:
                                formData.COLOWhenSpecificationOfRepairAcceptedSubject,
                            Body: formData.COLOWhenSpecificationOfRepairAcceptedBody,
                        },
                        COLOBOGCWhenBORProcessStart: {
                            MailTo: formData.COLOBOGCWhenBORProcessStartMailTo,
                            Subject:
                                formData.COLOBOGCWhenBORProcessStartSubject,
                            Body: formData.COLOBOGCWhenBORProcessStartBody,
                        },
                        WiningGC: {
                            MailTo: formData.WiningGCMailTo,
                            Subject: formData.WiningGCSubject,
                            Body: formData.WiningGCBody,
                        },
                        NonSelectedGC: {
                            MailTo: formData.NonSelectedGCMailTo,
                            Subject: formData.NonSelectedGCSubject,
                            Body: formData.NonSelectedGCBody,
                        },
                        COBOLORealtorWhenGCProposalACCEPTED: {
                            MailTo: formData.COBOLORealtorWhenGCProposalACCEPTEDMailTo,
                            Subject:
                                formData.COBOLORealtorWhenGCProposalACCEPTEDSubject,
                            Body: formData.COBOLORealtorWhenGCProposalACCEPTEDBody,
                        },
                        UsersWhenBOAcceptBIDONREPAIR: {
                            MailTo: formData.UsersWhenBOAcceptBIDONREPAIRMailTo,
                            Subject:
                                formData.UsersWhenBOAcceptBIDONREPAIRSubject,
                            Body: formData.UsersWhenBOAcceptBIDONREPAIRBody,
                        },
                        GCWhenInvitedForBidding: {
                            MailTo: formData.GCWhenInvitedForBiddingMailTo,
                            Subject: formData.GCWhenInvitedForBiddingSubject,
                            Body: formData.GCWhenInvitedForBiddingBody,
                        },
                        GCWhenInvitedForRevisedBidding: {
                            MailTo: formData.GCWhenInvitedForRevisedBiddingMailTo,
                            Subject:
                                formData.GCWhenInvitedForRevisedBiddingSubject,
                            Body: formData.GCWhenInvitedForRevisedBiddingBody,
                        },
                        GCBOLOREBeforeBiddingEnd: {
                            MailTo: formData.GCBOLOREBeforeBiddingEndMailTo,
                            Subject: formData.GCBOLOREBeforeBiddingEndSubject,
                            Body: formData.GCBOLOREBeforeBiddingEndBody,
                        },
                        GCBOAfterBiddingEnd: {
                            MailTo: formData.GCBOAfterBiddingEndMailTo,
                            Subject: formData.GCBOAfterBiddingEndSubject,
                            Body: formData.GCBOAfterBiddingEndBody,
                        },
                        COBOGCAfterGCSubmitBID: {
                            MailTo: formData.COBOGCAfterGCSubmitBIDMailTo,
                            Subject: formData.COBOGCAfterGCSubmitBIDSubject,
                            Body: formData.COBOGCAfterGCSubmitBIDBody,
                        },
                        COBOLOGCAfterFinalSORCreation: {
                            MailTo: formData.COBOLOGCAfterFinalSORCreationMailTo,
                            Subject:
                                formData.COBOLOGCAfterFinalSORCreationSubject,
                            Body: formData.COBOLOGCAfterFinalSORCreationBody,
                        },
                    },
                    ReviewBID: {
                        COForRivisionOfSOR: {
                            MailTo: formData.COForRivisionOfSORMailTo,
                            Subject: formData.COForRivisionOfSORSubject,
                            Body: formData.COForRivisionOfSORBody,
                        },
                        COForRivisionRequestOfSORByBO: {
                            MailTo: formData.COForRivisionRequestOfSORByBOMailTo,
                            Subject:
                                formData.COForRivisionRequestOfSORByBOSubject,
                            Body: formData.COForRivisionRequestOfSORByBOBody,
                        },
                        GCCOForRevisionRequestOfBORByBO: {
                            MailTo: formData.GCCOForRevisionRequestOfBORByBOMailTo,
                            Subject:
                                formData.GCCOForRevisionRequestOfBORByBOSubject,
                            Body: formData.GCCOForRevisionRequestOfBORByBOBody,
                        },
                        GCBOForRevisionRequestOfBORByCO: {
                            MailTo: formData.GCBOForRevisionRequestOfBORByCOMailTo,
                            Subject:
                                formData.GCBOForRevisionRequestOfBORByCOSubject,
                            Body: formData.GCBOForRevisionRequestOfBORByCOBody,
                        },
                        COBOLORLAboutGCOPTOutFromBidProcess: {
                            MailTo: formData.COBOLORLAboutGCOPTOutFromBidProcessMailTo,
                            Subject:
                                formData.COBOLORLAboutGCOPTOutFromBidProcessSubject,
                            Body: formData.COBOLORLAboutGCOPTOutFromBidProcessBody,
                        },
                        COBOLOGCAboutBidProcessReset: {
                            MailTo: formData.COBOLOGCAboutBidProcessResetMailTo,
                            Subject:
                                formData.COBOLOGCAboutBidProcessResetSubject,
                            Body: formData.COBOLOGCAboutBidProcessResetBody,
                        },
                        AboutGCBlankBidForm: {
                            MailTo: formData.AboutGCBlankBidFormMailTo,
                            Subject: formData.AboutGCBlankBidFormSubject,
                            Body: formData.AboutGCBlankBidFormBody,
                        },
                    },
                    InvoiceNotification: {
                        BOAboutInvoiceAndSendInvoicePDF: {
                            MailTo: formData.BOAboutInvoiceAndSendInvoicePDFMailTo,
                            Subject:
                                formData.BOAboutInvoiceAndSendInvoicePDFSubject,
                            Body: formData.BOAboutInvoiceAndSendInvoicePDFBody,
                        },
                        LOAboutInvoiceAndSendInvoicePDF: {
                            MailTo: formData.LOAboutInvoiceAndSendInvoicePDFMailTo,
                            Subject:
                                formData.LOAboutInvoiceAndSendInvoicePDFSubject,
                            Body: formData.LOAboutInvoiceAndSendInvoicePDFBody,
                        },
                        BOAboutSendPDFAttachment: {
                            MailTo: formData.BOAboutSendPDFAttachmentMailTo,
                            Subject: formData.BOAboutSendPDFAttachmentSubject,
                            Body: formData.BOAboutSendPDFAttachmentBody,
                        },
                    },
                },
                PostClosingEmails: {
                    InitialDraw: {
                        COWhenBOGCFillThiredColumnInInitialDrawRequest: {
                            MailTo: formData.COWhenBOGCFillThiredColumnInInitialDrawRequestMailTo,
                            Subject:
                                formData.COWhenBOGCFillThiredColumnInInitialDrawRequestSubject,
                            Body: formData.COWhenBOGCFillThiredColumnInInitialDrawRequestBody,
                        },
                        BOForApprovalInitialDrawFormSentByGC: {
                            MailTo: formData.BOForApprovalInitialDrawFormSentByGCMailTo,
                            Subject:
                                formData.BOForApprovalInitialDrawFormSentByGCSubject,
                            Body: formData.BOForApprovalInitialDrawFormSentByGCBody,
                        },
                        DrawRequestedForInspection: {
                            MailTo: formData.DrawRequestedForInspectionMailTo,
                            Subject: formData.DrawRequestedForInspectionSubject,
                            Body: formData.DrawRequestedForInspectionBody,
                        },
                        GCForApprovalInitialDrawFormSentByBO: {
                            MailTo: formData.GCForApprovalInitialDrawFormSentByBOMailTo,
                            Subject:
                                formData.GCForApprovalInitialDrawFormSentByBOSubject,
                            Body: formData.GCForApprovalInitialDrawFormSentByBOBody,
                        },
                        BOAboutPostClosingProcess: {
                            MailTo: formData.BOAboutPostClosingProcessMailTo,
                            Subject: formData.BOAboutPostClosingProcessSubject,
                            Body: formData.BOAboutPostClosingProcessBody,
                        },
                        ChangeOrderFormEmail: {
                            MailTo: formData.ChangeOrderFormEmailMailTo,
                            Subject: formData.ChangeOrderFormEmailSubject,
                            Body: formData.ChangeOrderFormEmailBody,
                        },
                    },
                    ReviewDraw: {
                        BOWhenGCSendReviseRequestFromInitialDrawRequestForm: {
                            MailTo: formData.BOWhenGCSendReviseRequestFromInitialDrawRequestFormMailTo,
                            Subject:
                                formData.BOWhenGCSendReviseRequestFromInitialDrawRequestFormSubject,
                            Body: formData.BOWhenGCSendReviseRequestFromInitialDrawRequestFormBody,
                        },
                        GCWhenBOSendReviseRequestFromInitialDrawRequestForm: {
                            MailTo: formData.GCWhenBOSendReviseRequestFromInitialDrawRequestFormMailTo,
                            Subject:
                                formData.GCWhenBOSendReviseRequestFromInitialDrawRequestFormSubject,
                            Body: formData.GCWhenBOSendReviseRequestFromInitialDrawRequestFormBody,
                        },
                        GCAfterDrawFormRevisedByBO: {
                            MailTo: formData.GCAfterDrawFormRevisedByBOMailTo,
                            Subject: formData.GCAfterDrawFormRevisedByBOSubject,
                            Body: formData.GCAfterDrawFormRevisedByBOBody,
                        },
                        BOAfterDrawFormRevisedByGC: {
                            MailTo: formData.BOAfterDrawFormRevisedByGCMailTo,
                            Subject: formData.BOAfterDrawFormRevisedByGCSubject,
                            Body: formData.BOAfterDrawFormRevisedByGCBody,
                        },
                        COBOGCWhenInitialDrawRequestFormIsRevisedAndAccepted: {
                            MailTo: formData.COBOGCWhenInitialDrawRequestFormIsRevisedAndAcceptedMailTo,
                            Subject:
                                formData.COBOGCWhenInitialDrawRequestFormIsRevisedAndAcceptedSubject,
                            Body: formData.COBOGCWhenInitialDrawRequestFormIsRevisedAndAcceptedBody,
                        },
                    },
                    NotifyDrawAdministrator: {
                        DrawAdministratorAboutPropertyAssigned: {
                            MailTo: formData.DrawAdministratorAboutPropertyAssignedMailTo,
                            Subject:
                                formData.DrawAdministratorAboutPropertyAssignedSubject,
                            Body: formData.DrawAdministratorAboutPropertyAssignedBody,
                        },
                    },
                },
                AutoEmails: {
                    DrawReminder: {
                        RemindBoAboutDraw:
                            formData.DrawReminderRemindBoAboutDraw,
                        RemindDays: formData.DrawReminderRemindDays,
                        MailTo: formData.DrawReminderMailTo,
                        Subject: formData.DrawReminderSubject,
                        Body: formData.DrawReminderBody,
                    },
                    SORIsCompleted: {
                        RemindLOLPAboutSORCompleted:
                            formData.SORIsCompletedRemindLOLPAboutSORCompleted,
                        RemindDays: formData.SORIsCompletedRemindDays,
                        MailTo: formData.SORIsCompletedMailTo,
                        Subject: formData.SORIsCompletedSubject,
                        Body: formData.SORIsCompletedBody,
                    },
                    ReminderForNotifyBOForApprovalOfSOR: {
                        ReminderForNotifyBOForApprovalOfSOR:
                            formData.ReminderForNotifyBOForApprovalOfSORReminderForNotifyBOForApprovalOfSOR,
                        RemindDays:
                            formData.ReminderForNotifyBOForApprovalOfSORRemindDays,
                        MailTo: formData.ReminderForNotifyBOForApprovalOfSORMailTo,
                        Subject:
                            formData.ReminderForNotifyBOForApprovalOfSORSubject,
                        Body: formData.ReminderForNotifyBOForApprovalOfSORBody,
                    },
                },
                EmailSetting: {
                    APIKey: formData.EmailSettingAPIKey,
                    Domain: formData.EmailSettingDomain,
                    FromEmail: formData.EmailSettingFromEmail,
                },
                MileageSetting: {
                    OfficeLocation: formData.MileageSettingOfficeLocation,
                    MilesthatSubtracted:
                        formData.MileageSettingMilesthatSubtracted,
                    IRSPerMileRate: formData.MileageSettingIRSPerMileRate,
                    HomeStyleMilesthatSubtracted:
                        formData.MileageSettingHomeStyleMilesthatSubtracted,
                    HomeStyleIRSPerMileRate:
                        formData.MileageSettingHomeStyleIRSPerMileRate,
                },
                DocSignAPI: {
                    Servrer: formData.DocSignAPIServrer,
                    ApiPath: formData.DocSignAPIApiPath,
                    RedirectURI: formData.DocSignAPIRedirectURI,
                    IntegrationKey: formData.DocSignAPIIntegrationKey,
                    UserUID: formData.DocSignAPIUserUID,
                    PrivateKey: formData.DocSignAPIPrivateKey,
                    ClientId: formData.DocSignAPIClientId,
                },
                CompanyName: formData.CompanyName,
                CompanyPhone: formData.CompanyPhone,
                CompanyLogo: formData.CompanyLogo,
                GoogleKey: formData.GoogleKey,
                AwsConfigRegion: formData.AwsConfigRegion,
                AwsS3BucketName: formData.AwsS3BucketName,
                AwsAccessKeyId: formData.AwsAccessKeyId,
                AwsSecretAccessKey: formData.AwsSecretAccessKey,
                ConsultantLiabilityFormSetting: {
                    ConsultantFee: formData.ConsultantFee,
                    PerChangeOrder: formData.PerChangeOrder,
                    ScopeofWorkChangeMin: formData.ScopeofWorkChangeMin,
                    ConsultantFeeReimbursed: formData.ConsultantFeeReimbursed,
                    DrawRequestMin: formData.DrawRequestMin,
                    ScopeofWorkChangeMax: formData.ScopeofWorkChangeMax,
                    EstimateBelow100k: formData.EstimateBelow100k,
                    DrawRequestMax: formData.DrawRequestMax,
                    MultiFamilyDwelling: formData.MultiFamilyDwelling,
                    EstimateBetween100k150k: formData.EstimateBetween100k150k,
                    SelfHelpDraw: formData.SelfHelpDraw,
                    WorkPlan: formData.WorkPlan,
                    EstimateAbove150k: formData.EstimateAbove150k,
                },
                CopyrightMessage: formData.CopyrightMessage,
                CreatedBy: "CreatedBy",
                CreatedDate: "CreatedDate",
                UpdateDate: "UpdateDate",
                UpdatedBy: "UpdatedBy",
            };

            this.loading = true;

            this._bservice.AddSetting(data).subscribe(
                (response) => {
                    this.sessionService.notifier.notify(
                        "success",
                        "Setting updated successfully."
                    );
                    this.isList = true;
                    this.isAdd = false;
                    this.GetEmailSetting();
                    this.loading = false;
                },
                (error) => {
                    this.sessionService.notifier.notify(
                        "error",
                        error.error.message
                    );
                    this.loading = false;
                }
            );
        }
        window.scroll(0, 0);
    }

    nextPage(event: PageEvent) {}

    GetEmailSetting() {
        this.loading = true;
        var data = {
            EmailId: "",
            PageSize: 100000,
            LastEvaluatedKey: 0,
            UserType: this.sessionService.userType[4].Value,
        };
        this._bservice.GetSetting(data).subscribe(
            (response) => {
                if (response != null) {
                    this.EmailSettingData = response;
                    this.EmailSetting.setValue({
                        id: this.EmailSettingData.Id
                            ? this.EmailSettingData.Id
                            : "",
                        ReplyToEmail: this.EmailSettingData.ReplyToEmail
                            ? this.EmailSettingData.ReplyToEmail
                            : "",
                        NewUserRegistrationBCC: this.EmailSettingData
                            .NewAccountEmails.NewUserRegistration.BCC
                            ? this.EmailSettingData.NewAccountEmails
                                  .NewUserRegistration.BCC
                            : "",
                        NewUserRegistrationSubject: this.EmailSettingData
                            .NewAccountEmails.NewUserRegistration.Subject
                            ? this.EmailSettingData.NewAccountEmails
                                  .NewUserRegistration.Subject
                            : "",
                        NewUserRegistrationBody:
                            this.EmailSettingData.NewAccountEmails
                                .NewUserRegistration.Body,
                        BorrowerAboutRegistrationBCC: this.EmailSettingData
                            .NewAccountEmails.NewBorrowerRegistration.BCC
                            ? this.EmailSettingData.NewAccountEmails
                                  .NewBorrowerRegistration.BCC
                            : "",
                        BorrowerAboutRegistrationSubject: this.EmailSettingData
                            .NewAccountEmails.NewBorrowerRegistration.Subject
                            ? this.EmailSettingData.NewAccountEmails
                                  .NewBorrowerRegistration.Subject
                            : "",
                        BorrowerAboutRegistrationBody:
                            this.EmailSettingData.NewAccountEmails
                                .NewBorrowerRegistration.Body,
                        GCAboutRegistrationBCC: this.EmailSettingData
                            .NewAccountEmails.NewGCRegistration.BCC
                            ? this.EmailSettingData.NewAccountEmails
                                  .NewGCRegistration.BCC
                            : "",
                        GCAboutRegistrationSubject: this.EmailSettingData
                            .NewAccountEmails.NewGCRegistration.Subject
                            ? this.EmailSettingData.NewAccountEmails
                                  .NewGCRegistration.Subject
                            : "",
                        GCAboutRegistrationBody:
                            this.EmailSettingData.NewAccountEmails
                                .NewGCRegistration.Body,
                        LOAboutRegistrationBCC: this.EmailSettingData
                            .NewAccountEmails.NewLORegistration.BCC
                            ? this.EmailSettingData.NewAccountEmails
                                  .NewLORegistration.BCC
                            : "",
                        LOAboutRegistrationSubject: this.EmailSettingData
                            .NewAccountEmails.NewLORegistration.Subject
                            ? this.EmailSettingData.NewAccountEmails
                                  .NewLORegistration.Subject
                            : "",
                        LOAboutRegistrationBody:
                            this.EmailSettingData.NewAccountEmails
                                .NewLORegistration.Body,
                        DAAboutRegistrationBCC: this.EmailSettingData
                            .NewAccountEmails.NewDARegistration.BCC
                            ? this.EmailSettingData.NewAccountEmails
                                  .NewDARegistration.BCC
                            : "",
                        DAAboutRegistrationSubject: this.EmailSettingData
                            .NewAccountEmails.NewDARegistration.Subject
                            ? this.EmailSettingData.NewAccountEmails
                                  .NewDARegistration.Subject
                            : "",
                        DAAboutRegistrationBody:
                            this.EmailSettingData.NewAccountEmails
                                .NewDARegistration.Body,
                        UsersAboutPropertyCreationMailTo: this.EmailSettingData
                            .PreClosingEmails.FileCreated
                            .UsersAboutPropertyCreation.MailTo
                            ? this.EmailSettingData.PreClosingEmails.FileCreated
                                  .UsersAboutPropertyCreation.MailTo
                            : "",
                        UsersAboutPropertyCreationSubject: this.EmailSettingData
                            .PreClosingEmails.FileCreated
                            .UsersAboutPropertyCreation.Subject
                            ? this.EmailSettingData.PreClosingEmails.FileCreated
                                  .UsersAboutPropertyCreation.Subject
                            : "",
                        UsersAboutPropertyCreationBody:
                            this.EmailSettingData.PreClosingEmails.FileCreated
                                .UsersAboutPropertyCreation.Body,
                        BOLOCOAboutNewPropertyWithPurchaseMailTo: this
                            .EmailSettingData.PreClosingEmails.FileCreated
                            .BOLOCOAboutNewPropertyWithPurchase.MailTo
                            ? this.EmailSettingData.PreClosingEmails.FileCreated
                                  .BOLOCOAboutNewPropertyWithPurchase.MailTo
                            : "",
                        BOLOCOAboutNewPropertyWithPurchaseSubject: this
                            .EmailSettingData.PreClosingEmails.FileCreated
                            .BOLOCOAboutNewPropertyWithPurchase.Subject
                            ? this.EmailSettingData.PreClosingEmails.FileCreated
                                  .BOLOCOAboutNewPropertyWithPurchase.Subject
                            : "",
                        BOLOCOAboutNewPropertyWithPurchaseBody:
                            this.EmailSettingData.PreClosingEmails.FileCreated
                                .BOLOCOAboutNewPropertyWithPurchase.Body,
                        RealtorAboutPropertyAssignedMailTo: this
                            .EmailSettingData.PreClosingEmails.FileCreated
                            .RealtorAboutPropertyAssigned.MailTo
                            ? this.EmailSettingData.PreClosingEmails.FileCreated
                                  .RealtorAboutPropertyAssigned.MailTo
                            : "",
                        RealtorAboutPropertyAssignedSubject: this
                            .EmailSettingData.PreClosingEmails.FileCreated
                            .RealtorAboutPropertyAssigned.Subject
                            ? this.EmailSettingData.PreClosingEmails.FileCreated
                                  .RealtorAboutPropertyAssigned.Subject
                            : "",
                        RealtorAboutPropertyAssignedBody:
                            this.EmailSettingData.PreClosingEmails.FileCreated
                                .RealtorAboutPropertyAssigned.Body,
                        COLOBOWhenGCBiddingDueDateAreNoFilledMailTo: this
                            .EmailSettingData.PreClosingEmails
                            .InitialInspectionCcompleted
                            .COLOBOWhenGCBiddingDueDateAreNoFilled.MailTo
                            ? this.EmailSettingData.PreClosingEmails
                                  .InitialInspectionCcompleted
                                  .COLOBOWhenGCBiddingDueDateAreNoFilled.MailTo
                            : "",
                        COLOBOWhenGCBiddingDueDateAreNoFilledSubject: this
                            .EmailSettingData.PreClosingEmails
                            .InitialInspectionCcompleted
                            .COLOBOWhenGCBiddingDueDateAreNoFilled.Subject
                            ? this.EmailSettingData.PreClosingEmails
                                  .InitialInspectionCcompleted
                                  .COLOBOWhenGCBiddingDueDateAreNoFilled.Subject
                            : "",
                        COLOBOWhenGCBiddingDueDateAreNoFilledBody:
                            this.EmailSettingData.PreClosingEmails
                                .InitialInspectionCcompleted
                                .COLOBOWhenGCBiddingDueDateAreNoFilled.Body,
                        BOForApprovalOfSpecificationOfRepairMailTo: this
                            .EmailSettingData.PreClosingEmails.DraftSORSentToBO
                            .BOForApprovalOfSpecificationOfRepair.MailTo
                            ? this.EmailSettingData.PreClosingEmails
                                  .DraftSORSentToBO
                                  .BOForApprovalOfSpecificationOfRepair.MailTo
                            : "",
                        BOForApprovalOfSpecificationOfRepairSubject: this
                            .EmailSettingData.PreClosingEmails.DraftSORSentToBO
                            .BOForApprovalOfSpecificationOfRepair.Subject
                            ? this.EmailSettingData.PreClosingEmails
                                  .DraftSORSentToBO
                                  .BOForApprovalOfSpecificationOfRepair.Subject
                            : "",
                        BOForApprovalOfSpecificationOfRepairBody:
                            this.EmailSettingData.PreClosingEmails
                                .DraftSORSentToBO
                                .BOForApprovalOfSpecificationOfRepair.Body,
                        BOForApprovalOfEditSpecificationOfRepairMailTo: this
                            .EmailSettingData.PreClosingEmails.DraftSORSentToBO
                            .BOForApprovalOfEditSpecificationOfRepair.MailTo
                            ? this.EmailSettingData.PreClosingEmails
                                  .DraftSORSentToBO
                                  .BOForApprovalOfEditSpecificationOfRepair
                                  .MailTo
                            : "",
                        BOForApprovalOfEditSpecificationOfRepairSubject: this
                            .EmailSettingData.PreClosingEmails.DraftSORSentToBO
                            .BOForApprovalOfEditSpecificationOfRepair.Subject
                            ? this.EmailSettingData.PreClosingEmails
                                  .DraftSORSentToBO
                                  .BOForApprovalOfEditSpecificationOfRepair
                                  .Subject
                            : "",
                        BOForApprovalOfEditSpecificationOfRepairBody:
                            this.EmailSettingData.PreClosingEmails
                                .DraftSORSentToBO
                                .BOForApprovalOfEditSpecificationOfRepair.Body,
                        LOForApprovalOfSpecificationOfRepairMailTo: this
                            .EmailSettingData.PreClosingEmails.BIDProcessStarted
                            .LOForApprovalOfSpecificationOfRepair.MailTo
                            ? this.EmailSettingData.PreClosingEmails
                                  .BIDProcessStarted
                                  .LOForApprovalOfSpecificationOfRepair.MailTo
                            : "",
                        LOForApprovalOfSpecificationOfRepairSubject: this
                            .EmailSettingData.PreClosingEmails.BIDProcessStarted
                            .LOForApprovalOfSpecificationOfRepair.Subject
                            ? this.EmailSettingData.PreClosingEmails
                                  .BIDProcessStarted
                                  .LOForApprovalOfSpecificationOfRepair.Subject
                            : "",
                        LOForApprovalOfSpecificationOfRepairBody:
                            this.EmailSettingData.PreClosingEmails
                                .BIDProcessStarted
                                .LOForApprovalOfSpecificationOfRepair.Body,
                        COLOWhenSpecificationOfRepairAcceptedMailTo: this
                            .EmailSettingData.PreClosingEmails.BIDProcessStarted
                            .COLOWhenSpecificationOfRepairAccepted.MailTo
                            ? this.EmailSettingData.PreClosingEmails
                                  .BIDProcessStarted
                                  .COLOWhenSpecificationOfRepairAccepted.MailTo
                            : "",
                        COLOWhenSpecificationOfRepairAcceptedSubject: this
                            .EmailSettingData.PreClosingEmails.BIDProcessStarted
                            .COLOWhenSpecificationOfRepairAccepted.Subject
                            ? this.EmailSettingData.PreClosingEmails
                                  .BIDProcessStarted
                                  .COLOWhenSpecificationOfRepairAccepted.Subject
                            : "",
                        COLOWhenSpecificationOfRepairAcceptedBody:
                            this.EmailSettingData.PreClosingEmails
                                .BIDProcessStarted
                                .COLOWhenSpecificationOfRepairAccepted.Body,
                        COLOBOGCWhenBORProcessStartMailTo: this.EmailSettingData
                            .PreClosingEmails.BIDProcessStarted
                            .COLOBOGCWhenBORProcessStart.MailTo
                            ? this.EmailSettingData.PreClosingEmails
                                  .BIDProcessStarted.COLOBOGCWhenBORProcessStart
                                  .MailTo
                            : "",
                        COLOBOGCWhenBORProcessStartSubject: this
                            .EmailSettingData.PreClosingEmails.BIDProcessStarted
                            .COLOBOGCWhenBORProcessStart.Subject
                            ? this.EmailSettingData.PreClosingEmails
                                  .BIDProcessStarted.COLOBOGCWhenBORProcessStart
                                  .Subject
                            : "",
                        COLOBOGCWhenBORProcessStartBody:
                            this.EmailSettingData.PreClosingEmails
                                .BIDProcessStarted.COLOBOGCWhenBORProcessStart
                                .Body,
                        WiningGCMailTo: this.EmailSettingData.PreClosingEmails
                            .BIDProcessStarted.WiningGC.MailTo
                            ? this.EmailSettingData.PreClosingEmails
                                  .BIDProcessStarted.WiningGC.MailTo
                            : "",
                        WiningGCSubject: this.EmailSettingData.PreClosingEmails
                            .BIDProcessStarted.WiningGC.Subject
                            ? this.EmailSettingData.PreClosingEmails
                                  .BIDProcessStarted.WiningGC.Subject
                            : "",
                        WiningGCBody:
                            this.EmailSettingData.PreClosingEmails
                                .BIDProcessStarted.WiningGC.Body,
                        NonSelectedGCMailTo: this.EmailSettingData
                            .PreClosingEmails.BIDProcessStarted.NonSelectedGC
                            .MailTo
                            ? this.EmailSettingData.PreClosingEmails
                                  .BIDProcessStarted.NonSelectedGC.MailTo
                            : "",
                        NonSelectedGCSubject: this.EmailSettingData
                            .PreClosingEmails.BIDProcessStarted.NonSelectedGC
                            .Subject
                            ? this.EmailSettingData.PreClosingEmails
                                  .BIDProcessStarted.NonSelectedGC.Subject
                            : "",
                        NonSelectedGCBody:
                            this.EmailSettingData.PreClosingEmails
                                .BIDProcessStarted.NonSelectedGC.Body,
                        COBOLORealtorWhenGCProposalACCEPTEDMailTo: this
                            .EmailSettingData.PreClosingEmails.BIDProcessStarted
                            .COBOLORealtorWhenGCProposalACCEPTED.MailTo
                            ? this.EmailSettingData.PreClosingEmails
                                  .BIDProcessStarted
                                  .COBOLORealtorWhenGCProposalACCEPTED.MailTo
                            : "",
                        COBOLORealtorWhenGCProposalACCEPTEDSubject: this
                            .EmailSettingData.PreClosingEmails.BIDProcessStarted
                            .COBOLORealtorWhenGCProposalACCEPTED.Subject
                            ? this.EmailSettingData.PreClosingEmails
                                  .BIDProcessStarted
                                  .COBOLORealtorWhenGCProposalACCEPTED.Subject
                            : "",
                        COBOLORealtorWhenGCProposalACCEPTEDBody:
                            this.EmailSettingData.PreClosingEmails
                                .BIDProcessStarted
                                .COBOLORealtorWhenGCProposalACCEPTED.Body,
                        UsersWhenBOAcceptBIDONREPAIRMailTo: this
                            .EmailSettingData.PreClosingEmails.BIDProcessStarted
                            .UsersWhenBOAcceptBIDONREPAIR.MailTo
                            ? this.EmailSettingData.PreClosingEmails
                                  .BIDProcessStarted
                                  .UsersWhenBOAcceptBIDONREPAIR.MailTo
                            : "",
                        UsersWhenBOAcceptBIDONREPAIRSubject: this
                            .EmailSettingData.PreClosingEmails.BIDProcessStarted
                            .UsersWhenBOAcceptBIDONREPAIR.Subject
                            ? this.EmailSettingData.PreClosingEmails
                                  .BIDProcessStarted
                                  .UsersWhenBOAcceptBIDONREPAIR.Subject
                            : "",
                        UsersWhenBOAcceptBIDONREPAIRBody:
                            this.EmailSettingData.PreClosingEmails
                                .BIDProcessStarted.UsersWhenBOAcceptBIDONREPAIR
                                .Body,
                        GCWhenInvitedForBiddingMailTo: this.EmailSettingData
                            .PreClosingEmails.BIDProcessStarted
                            .GCWhenInvitedForBidding.MailTo
                            ? this.EmailSettingData.PreClosingEmails
                                  .BIDProcessStarted.GCWhenInvitedForBidding
                                  .MailTo
                            : "",
                        GCWhenInvitedForBiddingSubject: this.EmailSettingData
                            .PreClosingEmails.BIDProcessStarted
                            .GCWhenInvitedForBidding.Subject
                            ? this.EmailSettingData.PreClosingEmails
                                  .BIDProcessStarted.GCWhenInvitedForBidding
                                  .Subject
                            : "",
                        GCWhenInvitedForBiddingBody:
                            this.EmailSettingData.PreClosingEmails
                                .BIDProcessStarted.GCWhenInvitedForBidding.Body,
                        GCWhenInvitedForRevisedBiddingMailTo: this
                            .EmailSettingData.PreClosingEmails.BIDProcessStarted
                            .GCWhenInvitedForRevisedBidding.MailTo
                            ? this.EmailSettingData.PreClosingEmails
                                  .BIDProcessStarted
                                  .GCWhenInvitedForRevisedBidding.MailTo
                            : "",
                        GCWhenInvitedForRevisedBiddingSubject: this
                            .EmailSettingData.PreClosingEmails.BIDProcessStarted
                            .GCWhenInvitedForRevisedBidding.Subject
                            ? this.EmailSettingData.PreClosingEmails
                                  .BIDProcessStarted
                                  .GCWhenInvitedForRevisedBidding.Subject
                            : "",
                        GCWhenInvitedForRevisedBiddingBody:
                            this.EmailSettingData.PreClosingEmails
                                .BIDProcessStarted
                                .GCWhenInvitedForRevisedBidding.Body,
                        GCBOLOREBeforeBiddingEndMailTo: this.EmailSettingData
                            .PreClosingEmails.BIDProcessStarted
                            .GCBOLOREBeforeBiddingEnd.MailTo
                            ? this.EmailSettingData.PreClosingEmails
                                  .BIDProcessStarted.GCBOLOREBeforeBiddingEnd
                                  .MailTo
                            : "",
                        GCBOLOREBeforeBiddingEndSubject: this.EmailSettingData
                            .PreClosingEmails.BIDProcessStarted
                            .GCBOLOREBeforeBiddingEnd.Subject
                            ? this.EmailSettingData.PreClosingEmails
                                  .BIDProcessStarted.GCBOLOREBeforeBiddingEnd
                                  .Subject
                            : "",
                        GCBOLOREBeforeBiddingEndBody:
                            this.EmailSettingData.PreClosingEmails
                                .BIDProcessStarted.GCBOLOREBeforeBiddingEnd
                                .Body,
                        GCBOAfterBiddingEndMailTo: this.EmailSettingData
                            .PreClosingEmails.BIDProcessStarted
                            .GCBOAfterBiddingEnd.MailTo
                            ? this.EmailSettingData.PreClosingEmails
                                  .BIDProcessStarted.GCBOAfterBiddingEnd.MailTo
                            : "",
                        GCBOAfterBiddingEndSubject: this.EmailSettingData
                            .PreClosingEmails.BIDProcessStarted
                            .GCBOAfterBiddingEnd.Subject
                            ? this.EmailSettingData.PreClosingEmails
                                  .BIDProcessStarted.GCBOAfterBiddingEnd.Subject
                            : "",
                        GCBOAfterBiddingEndBody:
                            this.EmailSettingData.PreClosingEmails
                                .BIDProcessStarted.GCBOAfterBiddingEnd.Body,
                        COBOGCAfterGCSubmitBIDMailTo: this.EmailSettingData
                            .PreClosingEmails.BIDProcessStarted
                            .COBOGCAfterGCSubmitBID.MailTo
                            ? this.EmailSettingData.PreClosingEmails
                                  .BIDProcessStarted.COBOGCAfterGCSubmitBID
                                  .MailTo
                            : "",
                        COBOGCAfterGCSubmitBIDSubject: this.EmailSettingData
                            .PreClosingEmails.BIDProcessStarted
                            .COBOGCAfterGCSubmitBID.Subject
                            ? this.EmailSettingData.PreClosingEmails
                                  .BIDProcessStarted.COBOGCAfterGCSubmitBID
                                  .Subject
                            : "",
                        COBOGCAfterGCSubmitBIDBody:
                            this.EmailSettingData.PreClosingEmails
                                .BIDProcessStarted.COBOGCAfterGCSubmitBID.Body,
                        COBOLOGCAfterFinalSORCreationMailTo: this
                            .EmailSettingData.PreClosingEmails.BIDProcessStarted
                            .COBOLOGCAfterFinalSORCreation.MailTo
                            ? this.EmailSettingData.PreClosingEmails
                                  .BIDProcessStarted
                                  .COBOLOGCAfterFinalSORCreation.MailTo
                            : "",
                        COBOLOGCAfterFinalSORCreationSubject: this
                            .EmailSettingData.PreClosingEmails.BIDProcessStarted
                            .COBOLOGCAfterFinalSORCreation.Subject
                            ? this.EmailSettingData.PreClosingEmails
                                  .BIDProcessStarted
                                  .COBOLOGCAfterFinalSORCreation.Subject
                            : "",
                        COBOLOGCAfterFinalSORCreationBody:
                            this.EmailSettingData.PreClosingEmails
                                .BIDProcessStarted.COBOLOGCAfterFinalSORCreation
                                .Body,
                        COForRivisionOfSORMailTo: this.EmailSettingData
                            .PreClosingEmails.ReviewBID.COForRivisionOfSOR
                            .MailTo
                            ? this.EmailSettingData.PreClosingEmails.ReviewBID
                                  .COForRivisionOfSOR.MailTo
                            : "",
                        COForRivisionOfSORSubject: this.EmailSettingData
                            .PreClosingEmails.ReviewBID.COForRivisionOfSOR
                            .Subject
                            ? this.EmailSettingData.PreClosingEmails.ReviewBID
                                  .COForRivisionOfSOR.Subject
                            : "",
                        COForRivisionOfSORBody:
                            this.EmailSettingData.PreClosingEmails.ReviewBID
                                .COForRivisionOfSOR.Body,
                        COForRivisionRequestOfSORByBOMailTo: this
                            .EmailSettingData.PreClosingEmails.ReviewBID
                            .COForRivisionRequestOfSORByBO.MailTo
                            ? this.EmailSettingData.PreClosingEmails.ReviewBID
                                  .COForRivisionRequestOfSORByBO.MailTo
                            : "",
                        COForRivisionRequestOfSORByBOSubject: this
                            .EmailSettingData.PreClosingEmails.ReviewBID
                            .COForRivisionRequestOfSORByBO.Subject
                            ? this.EmailSettingData.PreClosingEmails.ReviewBID
                                  .COForRivisionRequestOfSORByBO.Subject
                            : "",
                        COForRivisionRequestOfSORByBOBody:
                            this.EmailSettingData.PreClosingEmails.ReviewBID
                                .COForRivisionRequestOfSORByBO.Body,
                        GCCOForRevisionRequestOfBORByBOMailTo: this
                            .EmailSettingData.PreClosingEmails.ReviewBID
                            .GCCOForRevisionRequestOfBORByBO.MailTo
                            ? this.EmailSettingData.PreClosingEmails.ReviewBID
                                  .GCCOForRevisionRequestOfBORByBO.MailTo
                            : "",
                        GCCOForRevisionRequestOfBORByBOSubject: this
                            .EmailSettingData.PreClosingEmails.ReviewBID
                            .GCCOForRevisionRequestOfBORByBO.Subject
                            ? this.EmailSettingData.PreClosingEmails.ReviewBID
                                  .GCCOForRevisionRequestOfBORByBO.Subject
                            : "",
                        GCCOForRevisionRequestOfBORByBOBody:
                            this.EmailSettingData.PreClosingEmails.ReviewBID
                                .GCCOForRevisionRequestOfBORByBO.Body,
                        GCBOForRevisionRequestOfBORByCOMailTo: this
                            .EmailSettingData.PreClosingEmails.ReviewBID
                            .GCBOForRevisionRequestOfBORByCO.MailTo
                            ? this.EmailSettingData.PreClosingEmails.ReviewBID
                                  .GCBOForRevisionRequestOfBORByCO.MailTo
                            : "",
                        GCBOForRevisionRequestOfBORByCOSubject: this
                            .EmailSettingData.PreClosingEmails.ReviewBID
                            .GCBOForRevisionRequestOfBORByCO.Subject
                            ? this.EmailSettingData.PreClosingEmails.ReviewBID
                                  .GCBOForRevisionRequestOfBORByCO.Subject
                            : "",
                        GCBOForRevisionRequestOfBORByCOBody:
                            this.EmailSettingData.PreClosingEmails.ReviewBID
                                .GCBOForRevisionRequestOfBORByCO.Body,
                        COBOLORLAboutGCOPTOutFromBidProcessMailTo: this
                            .EmailSettingData.PreClosingEmails.ReviewBID
                            .COBOLORLAboutGCOPTOutFromBidProcess.MailTo
                            ? this.EmailSettingData.PreClosingEmails.ReviewBID
                                  .COBOLORLAboutGCOPTOutFromBidProcess.MailTo
                            : "",
                        COBOLORLAboutGCOPTOutFromBidProcessSubject: this
                            .EmailSettingData.PreClosingEmails.ReviewBID
                            .COBOLORLAboutGCOPTOutFromBidProcess.Subject
                            ? this.EmailSettingData.PreClosingEmails.ReviewBID
                                  .COBOLORLAboutGCOPTOutFromBidProcess.Subject
                            : "",
                        COBOLORLAboutGCOPTOutFromBidProcessBody:
                            this.EmailSettingData.PreClosingEmails.ReviewBID
                                .COBOLORLAboutGCOPTOutFromBidProcess.Body,
                        COBOLOGCAboutBidProcessResetMailTo: this
                            .EmailSettingData.PreClosingEmails.ReviewBID
                            .COBOLOGCAboutBidProcessReset.MailTo
                            ? this.EmailSettingData.PreClosingEmails.ReviewBID
                                  .COBOLOGCAboutBidProcessReset.MailTo
                            : "",
                        COBOLOGCAboutBidProcessResetSubject: this
                            .EmailSettingData.PreClosingEmails.ReviewBID
                            .COBOLOGCAboutBidProcessReset.Subject
                            ? this.EmailSettingData.PreClosingEmails.ReviewBID
                                  .COBOLOGCAboutBidProcessReset.Subject
                            : "",
                        COBOLOGCAboutBidProcessResetBody:
                            this.EmailSettingData.PreClosingEmails.ReviewBID
                                .COBOLOGCAboutBidProcessReset.Body,
                        AboutGCBlankBidFormMailTo: this.EmailSettingData
                            .PreClosingEmails.ReviewBID.AboutGCBlankBidForm
                            .MailTo
                            ? this.EmailSettingData.PreClosingEmails.ReviewBID
                                  .AboutGCBlankBidForm.MailTo
                            : "",
                        AboutGCBlankBidFormSubject: this.EmailSettingData
                            .PreClosingEmails.ReviewBID.AboutGCBlankBidForm
                            .Subject
                            ? this.EmailSettingData.PreClosingEmails.ReviewBID
                                  .AboutGCBlankBidForm.Subject
                            : "",
                        AboutGCBlankBidFormBody:
                            this.EmailSettingData.PreClosingEmails.ReviewBID
                                .AboutGCBlankBidForm.Body,
                        BOAboutInvoiceAndSendInvoicePDFMailTo: this
                            .EmailSettingData.PreClosingEmails
                            .InvoiceNotification.BOAboutInvoiceAndSendInvoicePDF
                            .MailTo
                            ? this.EmailSettingData.PreClosingEmails
                                  .InvoiceNotification
                                  .BOAboutInvoiceAndSendInvoicePDF.MailTo
                            : "",
                        BOAboutInvoiceAndSendInvoicePDFSubject: this
                            .EmailSettingData.PreClosingEmails
                            .InvoiceNotification.BOAboutInvoiceAndSendInvoicePDF
                            .Subject
                            ? this.EmailSettingData.PreClosingEmails
                                  .InvoiceNotification
                                  .BOAboutInvoiceAndSendInvoicePDF.Subject
                            : "",
                        BOAboutInvoiceAndSendInvoicePDFBody:
                            this.EmailSettingData.PreClosingEmails
                                .InvoiceNotification
                                .BOAboutInvoiceAndSendInvoicePDF.Body,
                        LOAboutInvoiceAndSendInvoicePDFMailTo: this
                            .EmailSettingData.PreClosingEmails
                            .InvoiceNotification.LOAboutInvoiceAndSendInvoicePDF
                            .MailTo
                            ? this.EmailSettingData.PreClosingEmails
                                  .InvoiceNotification
                                  .LOAboutInvoiceAndSendInvoicePDF.MailTo
                            : "",
                        LOAboutInvoiceAndSendInvoicePDFSubject: this
                            .EmailSettingData.PreClosingEmails
                            .InvoiceNotification.LOAboutInvoiceAndSendInvoicePDF
                            .Subject
                            ? this.EmailSettingData.PreClosingEmails
                                  .InvoiceNotification
                                  .LOAboutInvoiceAndSendInvoicePDF.Subject
                            : "",
                        LOAboutInvoiceAndSendInvoicePDFBody:
                            this.EmailSettingData.PreClosingEmails
                                .InvoiceNotification
                                .LOAboutInvoiceAndSendInvoicePDF.Body,
                        BOAboutSendPDFAttachmentMailTo: this.EmailSettingData
                            .PreClosingEmails.InvoiceNotification
                            .BOAboutSendPDFAttachment.MailTo
                            ? this.EmailSettingData.PreClosingEmails
                                  .InvoiceNotification.BOAboutSendPDFAttachment
                                  .MailTo
                            : "",
                        BOAboutSendPDFAttachmentSubject: this.EmailSettingData
                            .PreClosingEmails.InvoiceNotification
                            .BOAboutSendPDFAttachment.Subject
                            ? this.EmailSettingData.PreClosingEmails
                                  .InvoiceNotification.BOAboutSendPDFAttachment
                                  .Subject
                            : "",
                        BOAboutSendPDFAttachmentBody:
                            this.EmailSettingData.PreClosingEmails
                                .InvoiceNotification.BOAboutSendPDFAttachment
                                .Body,
                        COWhenBOGCFillThiredColumnInInitialDrawRequestMailTo:
                            this.EmailSettingData.PostClosingEmails.InitialDraw
                                .COWhenBOGCFillThiredColumnInInitialDrawRequest
                                .MailTo
                                ? this.EmailSettingData.PostClosingEmails
                                      .InitialDraw
                                      .COWhenBOGCFillThiredColumnInInitialDrawRequest
                                      .MailTo
                                : "",
                        COWhenBOGCFillThiredColumnInInitialDrawRequestSubject:
                            this.EmailSettingData.PostClosingEmails.InitialDraw
                                .COWhenBOGCFillThiredColumnInInitialDrawRequest
                                .Subject
                                ? this.EmailSettingData.PostClosingEmails
                                      .InitialDraw
                                      .COWhenBOGCFillThiredColumnInInitialDrawRequest
                                      .Subject
                                : "",
                        COWhenBOGCFillThiredColumnInInitialDrawRequestBody:
                            this.EmailSettingData.PostClosingEmails.InitialDraw
                                .COWhenBOGCFillThiredColumnInInitialDrawRequest
                                .Body,
                        BOForApprovalInitialDrawFormSentByGCMailTo: this
                            .EmailSettingData.PostClosingEmails.InitialDraw
                            .BOForApprovalInitialDrawFormSentByGC.MailTo
                            ? this.EmailSettingData.PostClosingEmails
                                  .InitialDraw
                                  .BOForApprovalInitialDrawFormSentByGC.MailTo
                            : "",
                        BOForApprovalInitialDrawFormSentByGCSubject: this
                            .EmailSettingData.PostClosingEmails.InitialDraw
                            .BOForApprovalInitialDrawFormSentByGC.Subject
                            ? this.EmailSettingData.PostClosingEmails
                                  .InitialDraw
                                  .BOForApprovalInitialDrawFormSentByGC.Subject
                            : "",
                        BOForApprovalInitialDrawFormSentByGCBody:
                            this.EmailSettingData.PostClosingEmails.InitialDraw
                                .BOForApprovalInitialDrawFormSentByGC.Body,
                        DrawRequestedForInspectionMailTo: this.EmailSettingData
                            .PostClosingEmails.InitialDraw
                            .DrawRequestedForInspection.MailTo
                            ? this.EmailSettingData.PostClosingEmails
                                  .InitialDraw.DrawRequestedForInspection.MailTo
                            : "",
                        DrawRequestedForInspectionSubject: this.EmailSettingData
                            .PostClosingEmails.InitialDraw
                            .DrawRequestedForInspection.Subject
                            ? this.EmailSettingData.PostClosingEmails
                                  .InitialDraw.DrawRequestedForInspection
                                  .Subject
                            : "",
                        DrawRequestedForInspectionBody:
                            this.EmailSettingData.PostClosingEmails.InitialDraw
                                .DrawRequestedForInspection.Body,
                        GCForApprovalInitialDrawFormSentByBOMailTo: this
                            .EmailSettingData.PostClosingEmails.InitialDraw
                            .GCForApprovalInitialDrawFormSentByBO.MailTo
                            ? this.EmailSettingData.PostClosingEmails
                                  .InitialDraw
                                  .GCForApprovalInitialDrawFormSentByBO.MailTo
                            : "",
                        GCForApprovalInitialDrawFormSentByBOSubject: this
                            .EmailSettingData.PostClosingEmails.InitialDraw
                            .GCForApprovalInitialDrawFormSentByBO.Subject
                            ? this.EmailSettingData.PostClosingEmails
                                  .InitialDraw
                                  .GCForApprovalInitialDrawFormSentByBO.Subject
                            : "",
                        GCForApprovalInitialDrawFormSentByBOBody:
                            this.EmailSettingData.PostClosingEmails.InitialDraw
                                .GCForApprovalInitialDrawFormSentByBO.Body,
                        BOAboutPostClosingProcessMailTo: this.EmailSettingData
                            .PostClosingEmails.InitialDraw
                            .BOAboutPostClosingProcess.MailTo
                            ? this.EmailSettingData.PostClosingEmails
                                  .InitialDraw.BOAboutPostClosingProcess.MailTo
                            : "",
                        BOAboutPostClosingProcessSubject: this.EmailSettingData
                            .PostClosingEmails.InitialDraw
                            .BOAboutPostClosingProcess.Subject
                            ? this.EmailSettingData.PostClosingEmails
                                  .InitialDraw.BOAboutPostClosingProcess.Subject
                            : "",
                        BOAboutPostClosingProcessBody:
                            this.EmailSettingData.PostClosingEmails.InitialDraw
                                .BOAboutPostClosingProcess.Body,
                        ChangeOrderFormEmailMailTo: this.EmailSettingData
                            .PostClosingEmails.InitialDraw.ChangeOrderFormEmail
                            .MailTo
                            ? this.EmailSettingData.PostClosingEmails
                                  .InitialDraw.ChangeOrderFormEmail.MailTo
                            : "",
                        ChangeOrderFormEmailSubject: this.EmailSettingData
                            .PostClosingEmails.InitialDraw.ChangeOrderFormEmail
                            .Subject
                            ? this.EmailSettingData.PostClosingEmails
                                  .InitialDraw.ChangeOrderFormEmail.Subject
                            : "",
                        ChangeOrderFormEmailBody:
                            this.EmailSettingData.PostClosingEmails.InitialDraw
                                .ChangeOrderFormEmail.Body,
                        BOWhenGCSendReviseRequestFromInitialDrawRequestFormMailTo:
                            this.EmailSettingData.PostClosingEmails.ReviewDraw
                                .BOWhenGCSendReviseRequestFromInitialDrawRequestForm
                                .MailTo
                                ? this.EmailSettingData.PostClosingEmails
                                      .ReviewDraw
                                      .BOWhenGCSendReviseRequestFromInitialDrawRequestForm
                                      .MailTo
                                : "",
                        BOWhenGCSendReviseRequestFromInitialDrawRequestFormSubject:
                            this.EmailSettingData.PostClosingEmails.ReviewDraw
                                .BOWhenGCSendReviseRequestFromInitialDrawRequestForm
                                .Subject
                                ? this.EmailSettingData.PostClosingEmails
                                      .ReviewDraw
                                      .BOWhenGCSendReviseRequestFromInitialDrawRequestForm
                                      .Subject
                                : "",
                        BOWhenGCSendReviseRequestFromInitialDrawRequestFormBody:
                            this.EmailSettingData.PostClosingEmails.ReviewDraw
                                .BOWhenGCSendReviseRequestFromInitialDrawRequestForm
                                .Body,
                        DrawAdministratorAboutPropertyAssignedMailTo: this
                            .EmailSettingData.PostClosingEmails
                            .NotifyDrawAdministrator
                            .DrawAdministratorAboutPropertyAssigned.MailTo
                            ? this.EmailSettingData.PostClosingEmails
                                  .NotifyDrawAdministrator
                                  .DrawAdministratorAboutPropertyAssigned.MailTo
                            : "",
                        DrawAdministratorAboutPropertyAssignedSubject: this
                            .EmailSettingData.PostClosingEmails
                            .NotifyDrawAdministrator
                            .DrawAdministratorAboutPropertyAssigned.Subject
                            ? this.EmailSettingData.PostClosingEmails
                                  .NotifyDrawAdministrator
                                  .DrawAdministratorAboutPropertyAssigned
                                  .Subject
                            : "",
                        DrawAdministratorAboutPropertyAssignedBody:
                            this.EmailSettingData.PostClosingEmails
                                .NotifyDrawAdministrator
                                .DrawAdministratorAboutPropertyAssigned.Body,
                        GCWhenBOSendReviseRequestFromInitialDrawRequestFormMailTo:
                            this.EmailSettingData.PostClosingEmails.ReviewDraw
                                .GCWhenBOSendReviseRequestFromInitialDrawRequestForm
                                .MailTo
                                ? this.EmailSettingData.PostClosingEmails
                                      .ReviewDraw
                                      .GCWhenBOSendReviseRequestFromInitialDrawRequestForm
                                      .MailTo
                                : "",
                        GCWhenBOSendReviseRequestFromInitialDrawRequestFormSubject:
                            this.EmailSettingData.PostClosingEmails.ReviewDraw
                                .GCWhenBOSendReviseRequestFromInitialDrawRequestForm
                                .Subject
                                ? this.EmailSettingData.PostClosingEmails
                                      .ReviewDraw
                                      .GCWhenBOSendReviseRequestFromInitialDrawRequestForm
                                      .Subject
                                : "",
                        GCWhenBOSendReviseRequestFromInitialDrawRequestFormBody:
                            this.EmailSettingData.PostClosingEmails.ReviewDraw
                                .GCWhenBOSendReviseRequestFromInitialDrawRequestForm
                                .Body,
                        GCAfterDrawFormRevisedByBOMailTo: this.EmailSettingData
                            .PostClosingEmails.ReviewDraw
                            .GCAfterDrawFormRevisedByBO.MailTo
                            ? this.EmailSettingData.PostClosingEmails.ReviewDraw
                                  .GCAfterDrawFormRevisedByBO.MailTo
                            : "",
                        GCAfterDrawFormRevisedByBOSubject: this.EmailSettingData
                            .PostClosingEmails.ReviewDraw
                            .GCAfterDrawFormRevisedByBO.Subject
                            ? this.EmailSettingData.PostClosingEmails.ReviewDraw
                                  .GCAfterDrawFormRevisedByBO.Subject
                            : "",
                        GCAfterDrawFormRevisedByBOBody:
                            this.EmailSettingData.PostClosingEmails.ReviewDraw
                                .GCAfterDrawFormRevisedByBO.Body,
                        BOAfterDrawFormRevisedByGCMailTo: this.EmailSettingData
                            .PostClosingEmails.ReviewDraw
                            .BOAfterDrawFormRevisedByGC.MailTo
                            ? this.EmailSettingData.PostClosingEmails.ReviewDraw
                                  .BOAfterDrawFormRevisedByGC.MailTo
                            : "",
                        BOAfterDrawFormRevisedByGCSubject: this.EmailSettingData
                            .PostClosingEmails.ReviewDraw
                            .BOAfterDrawFormRevisedByGC.Subject
                            ? this.EmailSettingData.PostClosingEmails.ReviewDraw
                                  .BOAfterDrawFormRevisedByGC.Subject
                            : "",
                        BOAfterDrawFormRevisedByGCBody:
                            this.EmailSettingData.PostClosingEmails.ReviewDraw
                                .BOAfterDrawFormRevisedByGC.Body,
                        COBOGCWhenInitialDrawRequestFormIsRevisedAndAcceptedMailTo:
                            this.EmailSettingData.PostClosingEmails.ReviewDraw
                                .COBOGCWhenInitialDrawRequestFormIsRevisedAndAccepted
                                .MailTo
                                ? this.EmailSettingData.PostClosingEmails
                                      .ReviewDraw
                                      .COBOGCWhenInitialDrawRequestFormIsRevisedAndAccepted
                                      .MailTo
                                : "",
                        COBOGCWhenInitialDrawRequestFormIsRevisedAndAcceptedSubject:
                            this.EmailSettingData.PostClosingEmails.ReviewDraw
                                .COBOGCWhenInitialDrawRequestFormIsRevisedAndAccepted
                                .Subject
                                ? this.EmailSettingData.PostClosingEmails
                                      .ReviewDraw
                                      .COBOGCWhenInitialDrawRequestFormIsRevisedAndAccepted
                                      .Subject
                                : "",
                        COBOGCWhenInitialDrawRequestFormIsRevisedAndAcceptedBody:
                            this.EmailSettingData.PostClosingEmails.ReviewDraw
                                .COBOGCWhenInitialDrawRequestFormIsRevisedAndAccepted
                                .Body,
                        DrawReminderRemindBoAboutDraw: this.EmailSettingData
                            .AutoEmails.DrawReminder.RemindBoAboutDraw
                            ? this.EmailSettingData.AutoEmails.DrawReminder
                                  .RemindBoAboutDraw
                            : "",
                        DrawReminderRemindDays: this.EmailSettingData.AutoEmails
                            .DrawReminder.RemindDays
                            ? this.EmailSettingData.AutoEmails.DrawReminder
                                  .RemindDays
                            : "",
                        DrawReminderMailTo: this.EmailSettingData.AutoEmails
                            .DrawReminder.MailTo
                            ? this.EmailSettingData.AutoEmails.DrawReminder
                                  .MailTo
                            : "",
                        DrawReminderSubject: this.EmailSettingData.AutoEmails
                            .DrawReminder.Subject
                            ? this.EmailSettingData.AutoEmails.DrawReminder
                                  .Subject
                            : "",
                        DrawReminderBody:
                            this.EmailSettingData.AutoEmails.DrawReminder.Body,
                        SORIsCompletedRemindLOLPAboutSORCompleted: this
                            .EmailSettingData.AutoEmails.SORIsCompleted
                            .RemindLOLPAboutSORCompleted
                            ? this.EmailSettingData.AutoEmails.SORIsCompleted
                                  .RemindLOLPAboutSORCompleted
                            : "",
                        SORIsCompletedRemindDays: this.EmailSettingData
                            .AutoEmails.SORIsCompleted.RemindDays
                            ? this.EmailSettingData.AutoEmails.SORIsCompleted
                                  .RemindDays
                            : "",
                        SORIsCompletedMailTo: this.EmailSettingData.AutoEmails
                            .SORIsCompleted.MailTo
                            ? this.EmailSettingData.AutoEmails.SORIsCompleted
                                  .MailTo
                            : "",
                        SORIsCompletedSubject: this.EmailSettingData.AutoEmails
                            .SORIsCompleted.Subject
                            ? this.EmailSettingData.AutoEmails.SORIsCompleted
                                  .Subject
                            : "",
                        SORIsCompletedBody:
                            this.EmailSettingData.AutoEmails.SORIsCompleted
                                .Body,
                        ReminderForNotifyBOForApprovalOfSORReminderForNotifyBOForApprovalOfSOR:
                            this.EmailSettingData.AutoEmails
                                .ReminderForNotifyBOForApprovalOfSOR
                                .ReminderForNotifyBOForApprovalOfSOR
                                ? this.EmailSettingData.AutoEmails
                                      .ReminderForNotifyBOForApprovalOfSOR
                                      .ReminderForNotifyBOForApprovalOfSOR
                                : "",
                        ReminderForNotifyBOForApprovalOfSORRemindDays: this
                            .EmailSettingData.AutoEmails
                            .ReminderForNotifyBOForApprovalOfSOR.RemindDays
                            ? this.EmailSettingData.AutoEmails
                                  .ReminderForNotifyBOForApprovalOfSOR
                                  .RemindDays
                            : "",
                        ReminderForNotifyBOForApprovalOfSORMailTo: this
                            .EmailSettingData.AutoEmails
                            .ReminderForNotifyBOForApprovalOfSOR.MailTo
                            ? this.EmailSettingData.AutoEmails
                                  .ReminderForNotifyBOForApprovalOfSOR.MailTo
                            : "",
                        ReminderForNotifyBOForApprovalOfSORSubject: this
                            .EmailSettingData.AutoEmails
                            .ReminderForNotifyBOForApprovalOfSOR.Subject
                            ? this.EmailSettingData.AutoEmails
                                  .ReminderForNotifyBOForApprovalOfSOR.Subject
                            : "",
                        ReminderForNotifyBOForApprovalOfSORBody:
                            this.EmailSettingData.AutoEmails
                                .ReminderForNotifyBOForApprovalOfSOR.Body,
                        EmailSettingAPIKey: this.EmailSettingData.EmailSetting
                            .APIKey
                            ? this.EmailSettingData.EmailSetting.APIKey
                            : "",
                        EmailSettingDomain: this.EmailSettingData.EmailSetting
                            .Domain
                            ? this.EmailSettingData.EmailSetting.Domain
                            : "",
                        EmailSettingFromEmail: this.EmailSettingData
                            .EmailSetting.FromEmail
                            ? this.EmailSettingData.EmailSetting.FromEmail
                            : "",
                        MileageSettingOfficeLocation: this.EmailSettingData
                            .MileageSetting.OfficeLocation
                            ? this.EmailSettingData.MileageSetting
                                  .OfficeLocation
                            : "",
                        MileageSettingMilesthatSubtracted: this.EmailSettingData
                            .MileageSetting.MilesthatSubtracted
                            ? this.EmailSettingData.MileageSetting
                                  .MilesthatSubtracted
                            : "",
                        MileageSettingHomeStyleMilesthatSubtracted: this
                            .EmailSettingData.MileageSetting
                            .HomeStyleMilesthatSubtracted
                            ? this.EmailSettingData.MileageSetting
                                  .HomeStyleMilesthatSubtracted
                            : "",
                        MileageSettingIRSPerMileRate: this.EmailSettingData
                            .MileageSetting.IRSPerMileRate
                            ? this.EmailSettingData.MileageSetting
                                  .IRSPerMileRate
                            : "",
                        MileageSettingHomeStyleIRSPerMileRate: this
                            .EmailSettingData.MileageSetting
                            .HomeStyleIRSPerMileRate
                            ? this.EmailSettingData.MileageSetting
                                  .HomeStyleIRSPerMileRate
                            : "",
                        DocSignAPIServrer: this.EmailSettingData.DocSignAPI
                            .Servrer
                            ? this.EmailSettingData.DocSignAPI.Servrer
                            : "",
                        DocSignAPIApiPath: this.EmailSettingData.DocSignAPI
                            .ApiPath
                            ? this.EmailSettingData.DocSignAPI.ApiPath
                            : "",
                        DocSignAPIRedirectURI: this.EmailSettingData.DocSignAPI
                            .RedirectURI
                            ? this.EmailSettingData.DocSignAPI.RedirectURI
                            : "",
                        DocSignAPIIntegrationKey: this.EmailSettingData
                            .DocSignAPI.IntegrationKey
                            ? this.EmailSettingData.DocSignAPI.IntegrationKey
                            : "",
                        DocSignAPIUserUID: this.EmailSettingData.DocSignAPI
                            .UserUID
                            ? this.EmailSettingData.DocSignAPI.UserUID
                            : "",
                        DocSignAPIPrivateKey: this.EmailSettingData.DocSignAPI
                            .PrivateKey
                            ? this.EmailSettingData.DocSignAPI.PrivateKey
                            : "",
                        DocSignAPIClientId: this.EmailSettingData.DocSignAPI
                            .ClientId
                            ? this.EmailSettingData.DocSignAPI.ClientId
                            : "",
                        CompanyName: this.EmailSettingData.CompanyName
                            ? this.EmailSettingData.CompanyName
                            : "",
                        CompanyPhone: this.EmailSettingData.CompanyPhone
                            ? this.EmailSettingData.CompanyPhone
                            : "",
                        CompanyLogo: this.EmailSettingData.CompanyLogo
                            ? this.EmailSettingData.CompanyLogo
                            : "",
                        CopyrightMessage: this.EmailSettingData.CopyrightMessage
                            ? this.EmailSettingData.CopyrightMessage
                            : "",
                        GoogleKey: this.EmailSettingData.GoogleKey
                            ? this.EmailSettingData.GoogleKey
                            : "",
                        AwsConfigRegion: this.EmailSettingData.AwsConfigRegion
                            ? this.EmailSettingData.AwsConfigRegion
                            : "",
                        AwsS3BucketName: this.EmailSettingData.AwsS3BucketName
                            ? this.EmailSettingData.AwsS3BucketName
                            : "",
                        AwsAccessKeyId: this.EmailSettingData.AwsAccessKeyId
                            ? this.EmailSettingData.AwsAccessKeyId
                            : "",
                        AwsSecretAccessKey: this.EmailSettingData
                            .AwsSecretAccessKey
                            ? this.EmailSettingData.AwsSecretAccessKey
                            : "",
                        ConsultantFee: this.EmailSettingData
                            .ConsultantLiabilityFormSetting.ConsultantFee
                            ? this.EmailSettingData
                                  .ConsultantLiabilityFormSetting.ConsultantFee
                            : "",
                        PerChangeOrder: this.EmailSettingData
                            .ConsultantLiabilityFormSetting.PerChangeOrder
                            ? this.EmailSettingData
                                  .ConsultantLiabilityFormSetting.PerChangeOrder
                            : "",
                        ScopeofWorkChangeMin: this.EmailSettingData
                            .ConsultantLiabilityFormSetting.ScopeofWorkChangeMin
                            ? this.EmailSettingData
                                  .ConsultantLiabilityFormSetting
                                  .ScopeofWorkChangeMin
                            : "",
                        ConsultantFeeReimbursed: this.EmailSettingData
                            .ConsultantLiabilityFormSetting
                            .ConsultantFeeReimbursed
                            ? this.EmailSettingData
                                  .ConsultantLiabilityFormSetting
                                  .ConsultantFeeReimbursed
                            : "",
                        DrawRequestMin: this.EmailSettingData
                            .ConsultantLiabilityFormSetting.DrawRequestMin
                            ? this.EmailSettingData
                                  .ConsultantLiabilityFormSetting.DrawRequestMin
                            : "",
                        ScopeofWorkChangeMax: this.EmailSettingData
                            .ConsultantLiabilityFormSetting.ScopeofWorkChangeMax
                            ? this.EmailSettingData
                                  .ConsultantLiabilityFormSetting
                                  .ScopeofWorkChangeMax
                            : "",
                        EstimateBelow100k: this.EmailSettingData
                            .ConsultantLiabilityFormSetting.EstimateBelow100k
                            ? this.EmailSettingData
                                  .ConsultantLiabilityFormSetting
                                  .EstimateBelow100k
                            : "",
                        DrawRequestMax: this.EmailSettingData
                            .ConsultantLiabilityFormSetting.DrawRequestMax
                            ? this.EmailSettingData
                                  .ConsultantLiabilityFormSetting.DrawRequestMax
                            : "",
                        MultiFamilyDwelling: this.EmailSettingData
                            .ConsultantLiabilityFormSetting.MultiFamilyDwelling
                            ? this.EmailSettingData
                                  .ConsultantLiabilityFormSetting
                                  .MultiFamilyDwelling
                            : "",
                        EstimateBetween100k150k: this.EmailSettingData
                            .ConsultantLiabilityFormSetting
                            .EstimateBetween100k150k
                            ? this.EmailSettingData
                                  .ConsultantLiabilityFormSetting
                                  .EstimateBetween100k150k
                            : "",
                        SelfHelpDraw: this.EmailSettingData
                            .ConsultantLiabilityFormSetting.SelfHelpDraw
                            ? this.EmailSettingData
                                  .ConsultantLiabilityFormSetting.SelfHelpDraw
                            : "",
                        WorkPlan: this.EmailSettingData
                            .ConsultantLiabilityFormSetting.WorkPlan
                            ? this.EmailSettingData
                                  .ConsultantLiabilityFormSetting.WorkPlan
                            : "",
                        EstimateAbove150k: this.EmailSettingData
                            .ConsultantLiabilityFormSetting.EstimateAbove150k
                            ? this.EmailSettingData
                                  .ConsultantLiabilityFormSetting
                                  .EstimateAbove150k
                            : "",
                        CreatedBy: this.EmailSettingData.CreatedBy
                            ? this.EmailSettingData.CreatedBy
                            : "",
                        CreatedDate: this.EmailSettingData.CreatedDate
                            ? this.EmailSettingData.CreatedDate
                            : "",
                        UpdateDate: this.EmailSettingData.UpdateDate
                            ? this.EmailSettingData.UpdateDate
                            : "",
                        UpdatedBy: this.EmailSettingData.UpdatedBy
                            ? this.EmailSettingData.UpdatedBy
                            : "",
                    });
                }
                this.loading = false;
            },
            (error) => {}
        );
    }
}
