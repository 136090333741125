<div class="content p-24 tbl">
    <ngx-loading class="ngxLoader" 
        [show]="loading"
        [config]="{ backdropBorderRadius: '3px' }"
    ></ngx-loading>
    <h1 class="m-0">Submitted Bids</h1>
    <!-- <a *ngIf="isList" class="addBorrow" href="javascript:void(0)" (click)="isList=false;isAdd=true;isEdit=false;formReset()"><mat-icon _ngcontent-c102="" class="secondary-text s-22 mat-icon material-icons mat-icon-no-color" role="img" aria-hidden="true">add_circle</mat-icon> Add New Borrower</a> -->
    <a
        class="addBorrow"
        *ngIf="
            userDetail.UserType != 'Loan Processor' &&
            userDetail.UserType != 'Loan Officer'
        "
        href="javascript:void(0)"
        routerLink="/properties"
    >
        Back to Property</a
    >
    <a
        class="addBorrow"
        *ngIf="
            userDetail.UserType == 'Loan Processor' ||
            userDetail.UserType == 'Loan Officer'
        "
        href="javascript:void(0)"
        routerLink="/property-details/{{ RepairPropertyId }}/1"
    >
        Back to Property</a
    >

    <!-- <mat-form-field>
  <input [hidden]="!isList" matInput (keyup)="applyFilter($event.target)" placeholder="Filter">
</mat-form-field> -->
    <br />
    <br />

    <div>
        <mat-card class="my-form">
            <mat-card-header>
                <mat-card-title></mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <table class="data-list-table">
                    <thead>
                        <th style="width: 200px">Category</th>
                        <th
                            style="text-align: center"
                            *ngFor="let Heading of SubmitBidHeaderList"
                        >
                            <span
                                >{{ Heading.name }} <br />
                                <a
                                    *ngIf="Heading.id != ''"
                                    (click)="getBorPDF(Heading.id)"
                                >
                                    (click here for PDF of BOR)</a
                                >
                                <a
                                    *ngIf="Heading.id == ''"
                                    (click)="getSORPDF()"
                                >
                                    (click here for PDF of SOR)</a
                                >
                            </span>
                        </th>
                    </thead>
                    <tbody>
                        <ng-container>
                            <ng-container>
                                <tr
                                    *ngFor="
                                        let BidData of submittedBidList;
                                        let i = index
                                    "
                                >
                                    <td>
                                        <span *ngIf="i != 35 && i != 36"
                                            >{{ BidData.id }}.
                                            {{ BidData.RepairType }}</span
                                        >
                                        <ng-container
                                            *ngIf="
                                                userDetail.UserType !=
                                                    'Loan Processor' &&
                                                userDetail.UserType !=
                                                    'Loan Officer'
                                            "
                                        >
                                            <strong
                                                *ngIf="i == 35 || i == 36"
                                                >{{
                                                    BidData.RepairType
                                                }}</strong
                                            >
                                        </ng-container>
                                        <ng-container
                                            *ngIf="
                                                userDetail.UserType ==
                                                    'Loan Processor' ||
                                                userDetail.UserType ==
                                                    'Loan Officer'
                                            "
                                        >
                                            <strong *ngIf="i == 35">{{
                                                BidData.RepairType
                                            }}</strong>
                                        </ng-container>
                                    </td>
                                    <ng-container
                                        *ngFor="
                                            let RepairTotal of BidData.RepairTotal;
                                            let j = index
                                        "
                                        style="padding: 15px 0 0 0"
                                    >
                                        <td
                                            style="text-align: center"
                                            *ngIf="
                                                activeGCResList.indexOf(
                                                    submittedBidList[36]
                                                        .RepairTotal[j]
                                                ) > -1 ||
                                                submittedBidList[36]
                                                    .RepairTotal[j] == 0
                                            "
                                        >
                                            <span
                                                *ngIf="
                                                    i != 35 &&
                                                    i != 36 &&
                                                    RepairTotal != 0
                                                "
                                                >${{
                                                    RepairTotal
                                                        | number : "1.2-2"
                                                }}</span
                                            >
                                            <span
                                                *ngIf="
                                                    i != 35 &&
                                                    i != 36 &&
                                                    RepairTotal == 0
                                                "
                                                >${{
                                                    RepairTotal
                                                        | number : "1.2-2"
                                                }}</span
                                            >
                                            <strong
                                                *ngIf="
                                                    i == 35 && RepairTotal != 0
                                                "
                                                >${{
                                                    RepairTotal
                                                        | number : "1.2-2"
                                                }}</strong
                                            >
                                            <strong
                                                *ngIf="
                                                    i == 35 && RepairTotal == 0
                                                "
                                                >${{
                                                    RepairTotal
                                                        | number : "1.2-2"
                                                }}</strong
                                            >
                                            <ng-container
                                                *ngIf="
                                                    userDetail.UserType !=
                                                        'Loan Processor' &&
                                                    userDetail.UserType !=
                                                        'Loan Officer'
                                                "
                                            >
                                                <strong
                                                    *ngIf="
                                                        i == 36 &&
                                                        j != 0 &&
                                                        BidData.StatusId == ''
                                                    "
                                                    ><a
                                                        (click)="
                                                            acceptBid(
                                                                RepairTotal
                                                            )
                                                        "
                                                        >Accept </a
                                                    >|
                                                    <a
                                                        (click)="
                                                            reviseBid(
                                                                RepairTotal
                                                            )
                                                        "
                                                    >
                                                        Revise</a
                                                    >
                                                </strong>

                                                <strong
                                                    *ngIf="
                                                        i == 36 &&
                                                        j != 0 &&
                                                        BidData.StatusId !=
                                                            '' &&
                                                        BidData.StatusId ==
                                                            RepairTotal
                                                    "
                                                >
                                                    You have accepted a Bid on
                                                    Repairs.<br />
                                                    The selected bid is being
                                                    reviewed <br />by your
                                                    renovation consultant.
                                                </strong>
                                            </ng-container>
                                        </td>
                                    </ng-container>
                                </tr>
                            </ng-container>
                        </ng-container>
                    </tbody>
                </table>
            </mat-card-content>
            <mat-card-actions> </mat-card-actions>
        </mat-card>
        <br />
        <br />
        <mat-card
            class="my-form"
            style="overflow: hidden"
            *ngIf="reviseComment"
        >
            <form
                [formGroup]="review_status"
                class="example-form"
                (ngSubmit)="bidReviseStatus()"
            >
                <mat-card-header> </mat-card-header>

                <mat-card-content>
                    <div class="pdf-left">
                        <mat-form-field class="example-full-width">
                            <span class="mat-form-field-label-wrapper"
                                ><!----><label
                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                    >Revision Comment</label
                                ></span
                            >
                            <textarea
                                matInput
                                #input2
                                formControlName="Comment"
                                name="Comment"
                            ></textarea>

                            <div
                                            class="error"
                                            *ngIf="
                                                (!review_status.controls[
                                                    'Comment'
                                                ].valid &&
                                                review_status.controls[
                                                        'Comment'
                                                    ].touched) ||
                                                (review_status.controls[
                                                    'Comment'
                                                ].invalid &&
                                                    submitted)
                                            "
                                        >
                                            Enter valid comment
                                        </div>
                        </mat-form-field>
                    </div>
                </mat-card-content>
                <mat-card-actions>
                    <button mat-raised-button type="submit" color="primary">
                        Submit</button
                    >&nbsp; &nbsp;&nbsp;
                    <button
                        mat-raised-button
                        type="button"
                        (click)="cancelReviseBid()"
                        color="primary"
                    >
                        Cancel
                    </button>
                </mat-card-actions>
            </form>
        </mat-card>
    </div>
</div>
