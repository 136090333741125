import {
    Component,
    OnInit,
    ViewChild,
    Pipe,
    PipeTransform,
    Inject,
} from "@angular/core";
import {
    FormGroup,
    Validators,
    FormControl,
    FormArray,
    FormBuilder,
} from "@angular/forms";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { DomSanitizer } from "@angular/platform-browser";
import { BorrowerService } from "../borrower/borrower.service";
import { SessionService } from "../service/session.service";
import { ActivatedRoute, Router } from "@angular/router";
import { PropertyService } from "../property/property.service";
import { ConstructionItemsLibraryService } from "../construction-items-library/construction-items-library.service";

export interface UserData {
    id: string;
    name: string;
    email: string;
    lastLogin: Date;
}

/** Constants used to fill up our data base. */
const COLORS: string[] = [
    "maroon",
    "red",
    "orange",
    "yellow",
    "olive",
    "green",
    "purple",
    "fuchsia",
    "lime",
    "teal",
    "aqua",
    "blue",
    "navy",
    "black",
    "gray",
];
const NAMES: string[] = [
    "Maia",
    "Asher",
    "Olivia",
    "Atticus",
    "Amelia",
    "Jack",
    "Charlotte",
    "Theodore",
    "Isla",
    "Oliver",
    "Isabella",
    "Jasper",
    "Cora",
    "Levi",
    "Violet",
    "Arthur",
    "Mia",
    "Thomas",
    "Elizabeth",
];

/**
 * @title Data table with sorting, pagination, and filtering.
 */

@Component({
    selector: "app-property-input-bid",
    templateUrl: "./property-input-bid.component.html",
    styleUrls: ["./property-input-bid.component.scss"],
})
export class PropertyInputBidComponent implements OnInit {
    displayedColumns: string[] = [
        "id",
        "RepairType",
        "RepairTotal",
        "Operation",
    ];
    baseurl = this.sessionService.endpoint;
    dataSource: MatTableDataSource<UserData>;
    mobnumPattern = "^(d{3})sd{3}-d{4}";

    public RepairItemForm: FormGroup;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild("searchComponentField") searchComponentField;
    repairFormControls: any = [];
    currentClick = "";
    repairDetailsList: any = [];
    currentPropertyDetails: any = [];
    isList: boolean = true;
    searchInputDisabled = false;
    progressValue: number = 0;
    isAdd: boolean = false;
    isEdit: boolean = false;
    submitted: boolean = false;
    isPlusShow: boolean = false;
    isAllexpand: boolean = false;
    isMinusShow: boolean = false;
    isAllExpandClass: any = "";
    isSubTabContentShow: boolean = false;
    favoriteSeason: string;
    userList: any = {};
    pdfData: any;
    userDetail: any = {};
    RepairPropertyId: any;
    loading = false;
    selectedIndex: any = 0;
    GCBidData: any;
    formStatus = false;
    allowableFeesData: any = {};
    permitCostTypeData: any = {};
    checkRepairItem: any = false;
    checkConstructionsDetails = false;
    propertyRepairConstructionDetail: any;
    getRepairConstructionList: any = {};
    coRepairContructionList: any = {};
    permitCostonReset: any = [];
    myValidator: any = [];
    myAsyncValidator: any = [];
    allRepairItem: any = [];
    itemComplete: number = 0;
    mandatoryFirstItem: any = null;
    recommendedFirstItem: any = null;
    desiredFirstItem: any = null;
    additionalDesiredFirstItem: any = null;
    mandatoryFirstItemRepairName: any = null;
    mandatoryFirstItemLocation: any = null;
    recommendedFirstItemRepairName: any = null;
    recommendedFirstItemLocation: any = null;
    desiredFirstItemRepairName: any = null;
    desiredFirstItemLocation: any = null;
    additionalDesiredFirstItemRepairName: any = null;
    additionalDesiredFirstItemLocation: any = null;
    previousItemType: any = "";
    previousItemName: any = "";
    previousItemLocation: any = "";
    nextItemType: any = "";
    nextItemName: any = "";
    searchResult: any = [];
    totalSorItems: any = 0;
    pdfdataShow = false;
    saveBtnImagePath: any = "assets/icons/pngIcon/buttonImage.png";
    status: any = ["Blocked", "Active"];
    roles: any = [
        { Key: "1", Value: "authenticated user" },
        { Key: "2", Value: "Administrator" },
        { Key: "3", Value: "Consultants" },
        { Key: "4", Value: "General Contractors" },
        { Key: "5", Value: "Borrower" },
        { Key: "6", Value: "Loan Officer" },
        { key: "7", Value: "Loan Processor" },
        { key: "8", Value: " Notify user of new account" },
        { key: "9", Value: "Draw Administrator" },
    ];
    constructor(
        private _bservice: BorrowerService,
        @Inject(ActivatedRoute) private route: ActivatedRoute,
        @Inject(DomSanitizer) private sanitizer: DomSanitizer,
        private _ciservice: ConstructionItemsLibraryService,
        @Inject(Router) private router: Router,
        private sessionService: SessionService,
        private propertyService: PropertyService,
        @Inject(FormBuilder) private _fb: FormBuilder
    ) {
        // Create 100 users
        // Assign the data to the data source for the table to render
    }

    ngOnInit() {
        var propertyId = this.route.snapshot.paramMap.get("propertyId");
        this.RepairItemForm = this._fb.group({
            SendRepairType: new FormControl(null),
            OccupiedDuringConstruction: new FormControl(null),
            BorrowerPhone: new FormControl(null),
            FHACase: new FormControl(null),
            ConsultantsComment: new FormControl(null),
            ContingencyReservePercentage: new FormControl(null),
            MonthToCompletion: new FormControl(null),
            LoanNo: new FormControl(null),
            InspectionAddress: new FormControl(null),
            id: new FormControl(null),
            PermitCost: this._fb.array([]),
            RepairItemDetails: this._fb.array([this.initRepairItem()]),
        });

        this.RepairPropertyId = propertyId;
        this.getRepairItem(this.RepairPropertyId);

        this.isPlusShow = true;
        this.getPropertyDetails(propertyId);

        this.repairFormControls = Array.of(this.RepairItemForm.controls);
        if (this.sessionService.get("userInfo") != undefined) {
            this.userDetail = JSON.parse(this.sessionService.get("userInfo"));
        }
    }

    CalculateProgressItem(items, totalItems) {
        this.itemComplete = items;
        this.progressValue = (this.itemComplete / totalItems) * 100;
    }
    expandFunction(curClick: string) {
        this.isPlusShow = false;
        this.isAllexpand = false;
        this.isMinusShow = true;
        this.isSubTabContentShow = true;
        this.currentClick = curClick;
    }
    closedFunction() {
        this.isPlusShow = true;
        this.isMinusShow = false;
        this.isSubTabContentShow = false;
        this.currentClick = "";
    }
    getPropertyDetails(propertyId) {
        this.loading = true;
        this.propertyService.GetPropertyDetails(propertyId).subscribe(
            (presponse) => {
                if (presponse != null) {
                    this.currentPropertyDetails = presponse;
                    var ciData = { UserType: "Consultants" };
                    this._ciservice
                        .getConsultantConstructionItems(ciData)
                        .subscribe(
                            (ciresponse) => {
                                if (
                                    ciresponse != null &&
                                    ciresponse.Items.length > 0
                                ) {
                                    this.repairDetailsList =
                                        ciresponse.Items[0].ReportComponent;
                                    this.loading = false;
                                }
                            },
                            (error) => {
                                this.sessionService.notifier.notify(
                                    "error",
                                    error.error.message
                                );
                                this.searchInputDisabled = false;
                                this.loading = false;
                            }
                        );
                }
            },
            (error) => {
                this.sessionService.notifier.notify(
                    "error",
                    error.error.message
                );
                this.loading = false;
            }
        );
    }
    gotoTab(index: any, type = "", itemName = "", level = "") {
        this.selectedIndex = index;
        if (type) {
            this.UpdateRepair(type, itemName, level);
        }
    }
    applyFilter(filterValue) {
        const target = filterValue as HTMLInputElement;
        const targetValue = target.value;
        this.dataSource.filter = targetValue.trim().toLowerCase();

        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    }
    forValValidator(RepairDetails, event: any, type) {
        let val = event.target.value;
        if (val != "" && val != null && val != undefined) {
            if (RepairDetails.controls.Level.value == "M") {
                if (!isNaN(parseInt(val)) && val > 0) {
                    return true;
                } else {
                    this.sessionService.notifier.notify(
                        "error",
                        "Please enter numberic value in Material (Quantity & Unit Cost) and Labor (Quantity & Unit Cost). Value should be greater than 0."
                    );
                    return false;
                }
            } else {
                if (type == "MaterialQuantity" || type == "LaborQuantity") {
                    if (!isNaN(parseInt(val)) && val > 0) {
                        return true;
                    } else {
                        this.sessionService.notifier.notify(
                            "error",
                            "Please enter numberic value. Quantity Value should be greater than 0."
                        );
                        return false;
                    }
                } else {
                    if (!isNaN(parseInt(val))) {
                        return true;
                    } else {
                        this.sessionService.notifier.notify(
                            "error",
                            "Please enter numberic value."
                        );
                        return false;
                    }
                }
            }
        } else {
            return true;
        }
    }
    initRepairItem() {
        // initialize our address
        return this._fb.group({
            RepairItemName: [{ value: "", disabled: false }],
            Location: [{ value: "", disabled: false }],
            Level: [{ value: "M", disabled: false }],
            MaterialUnit: ["EA"],
            MaterialQuantity: [""],
            MaterialUnitCost: [""],
            LaborUnit: ["EA"],
            LaborQuantity: [""],
            LaborUnitCost: [""],
            Summary: [""],
        });
    }

    initAdditionalRepairItem() {
        // initialize our address
        return this._fb.group({
            RepairItemName: [{ value: "", disabled: false }],
            Location: [{ value: "", disabled: false }],
            Level: [{ value: "D", disabled: false }],
            MaterialUnit: ["EA"],
            MaterialQuantity: [""],
            MaterialUnitCost: [""],
            LaborUnit: ["EA"],
            LaborQuantity: [""],
            LaborUnitCost: [""],
            Summary: [""],
        });
    }

    addAdditionalRepairItems() {
        // add address to the list
        const control = <FormArray>(
            this.RepairItemForm.controls["RepairItemDetails"]
        );
        control.push(this.initAdditionalRepairItem());
    }
    deleteRepairItems(index) {
        const control = <FormArray>(
            this.RepairItemForm.controls["RepairItemDetails"]
        );
        control.removeAt(index);
    }
    UpdateRepair(type, itemName, level, location = "") {
        this.isAllexpand = false;
        this.isAllExpandClass = "";
        if (type == "" || type == null || type == undefined) {
            const repaircontrol = <FormArray>(
                this.RepairItemForm.controls["RepairItemDetails"]
            );

            for (let i = repaircontrol.length - 1; i >= 0; i--) {
                repaircontrol.removeAt(i);
            }
            return;
        }
        let repairItems: any = [];
        this.permitCostonReset = this.RepairItemForm.value.PermitCost;
        let setItem = this.RepairItemForm.value;
        this.formReset();

        this.RepairItemForm.controls["SendRepairType"].setValue(type);
        this.RepairItemForm.controls["SendRepairType"].disable();
        const repaircontrol = <FormArray>(
            this.RepairItemForm.controls["RepairItemDetails"]
        );

        for (let i = repaircontrol.length - 1; i >= 0; i--) {
            repaircontrol.removeAt(i);
            if (i == 0) {
                repaircontrol.push(this.initRepairItem());
            }
        }
        const setcontrol = <FormArray>(
            this.RepairItemForm.controls["PermitCost"]
        );

        for (let i = setcontrol.length - 1; i >= 0; i--) {
            setcontrol.removeAt(i);
        }

        if (this.permitCostonReset.length > 0) {
            for (let i = 0; i < this.permitCostonReset.length; i++) {
                setcontrol.push(
                    this._fb.group({
                        PermitType: [this.permitCostonReset[i].PermitType],
                        PermitCost: [this.permitCostonReset[i].PermitCost],
                        Status: [this.permitCostonReset[i].Status],
                    })
                );
                this.formStatus = true;
            }
        } else {
            for (let j = 0; j < this.permitCostTypeData.length; j++) {
                setcontrol.push(this._fb.group(this.permitCostTypeData[j]));
                this.formStatus = true;
            }
        }

        if (this.GCBidData != null) {
            if (this.GCBidData.Constructions.length > 0) {
                var checkType = true;
                for (let i = 0; i < this.GCBidData.Constructions.length; i++) {
                    if (this.GCBidData.Constructions[i].RepairType == type) {
                        const control = <FormArray>(
                            this.RepairItemForm.controls["RepairItemDetails"]
                        );
                        for (let i = control.length; i >= 0; i--) {
                            control.removeAt(i);
                        }
                        for (
                            let j = 0;
                            j <
                            this.GCBidData.Constructions[i].RepairItemDetails
                                .length;
                            j++
                        ) {
                            if (
                                location != null &&
                                location != "" &&
                                location != undefined
                            ) {
                                if (
                                    this.GCBidData.Constructions[i]
                                        .RepairItemDetails[j].Location ==
                                        location &&
                                    this.GCBidData.Constructions[i]
                                        .RepairItemDetails[j].Level == level &&
                                    this.GCBidData.Constructions[i]
                                        .RepairItemDetails[j].RepairItemName ==
                                        itemName
                                ) {
                                    checkType = false;
                                    var item =
                                        this.GCBidData.Constructions[i]
                                            .RepairItemDetails[j];
                                    control.push(this.initGCUpdateItem(item));
                                }
                            } else {
                                if (
                                    this.GCBidData.Constructions[i]
                                        .RepairItemDetails[j].Level == level &&
                                    this.GCBidData.Constructions[i]
                                        .RepairItemDetails[j].RepairItemName ==
                                        itemName
                                ) {
                                    checkType = false;
                                    var item =
                                        this.GCBidData.Constructions[i]
                                            .RepairItemDetails[j];
                                    control.push(this.initGCUpdateItem(item));
                                }
                            }
                        }
                    }
                }
                if (checkType) {
                    if (this.getRepairConstructionList.length > 0) {
                        this.getRepairConstructionList.forEach((element) => {
                            if (element.PropertyId == this.RepairPropertyId) {
                                this.propertyRepairConstructionDetail = element;

                                for (
                                    let i = 0;
                                    i < element.Constructions.length;
                                    i++
                                ) {
                                    if (
                                        element.Constructions[i].RepairType ==
                                        type
                                    ) {
                                        if (
                                            element.Constructions[i]
                                                .RepairItemDetails.length > 0
                                        ) {
                                            const control = <FormArray>(
                                                this.RepairItemForm.controls[
                                                    "RepairItemDetails"
                                                ]
                                            );
                                            for (
                                                let i = control.length;
                                                i >= 0;
                                                i--
                                            ) {
                                                control.removeAt(i);
                                            }

                                            for (
                                                let j = 0;
                                                j <
                                                element.Constructions[i]
                                                    .RepairItemDetails.length;
                                                j++
                                            ) {
                                                if (
                                                    location != null &&
                                                    location != "" &&
                                                    location != undefined
                                                ) {
                                                    if (
                                                        element.Constructions[i]
                                                            .RepairItemDetails[
                                                            j
                                                        ].Location ==
                                                            location &&
                                                        element.Constructions[i]
                                                            .RepairItemDetails[
                                                            j
                                                        ].Level == level &&
                                                        element.Constructions[i]
                                                            .RepairItemDetails[
                                                            j
                                                        ].RepairItemName ==
                                                            itemName
                                                    ) {
                                                        var item =
                                                            element
                                                                .Constructions[
                                                                i
                                                            ].RepairItemDetails[
                                                                j
                                                            ];
                                                        control.push(
                                                            this.initupdateItem(
                                                                item
                                                            )
                                                        );
                                                    }
                                                } else {
                                                    if (
                                                        element.Constructions[i]
                                                            .RepairItemDetails[
                                                            j
                                                        ].Level == level &&
                                                        element.Constructions[i]
                                                            .RepairItemDetails[
                                                            j
                                                        ].RepairItemName ==
                                                            itemName
                                                    ) {
                                                        var item =
                                                            element
                                                                .Constructions[
                                                                i
                                                            ].RepairItemDetails[
                                                                j
                                                            ];
                                                        control.push(
                                                            this.initupdateItem(
                                                                item
                                                            )
                                                        );
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        });
                    }
                }
            } else {
                if (this.getRepairConstructionList.length > 0) {
                    this.getRepairConstructionList.forEach((element) => {
                        if (element.PropertyId == this.RepairPropertyId) {
                            this.propertyRepairConstructionDetail = element;

                            for (
                                let i = 0;
                                i < element.Constructions.length;
                                i++
                            ) {
                                if (
                                    element.Constructions[i].RepairType == type
                                ) {
                                    if (
                                        element.Constructions[i]
                                            .RepairItemDetails.length > 0
                                    ) {
                                        const control = <FormArray>(
                                            this.RepairItemForm.controls[
                                                "RepairItemDetails"
                                            ]
                                        );
                                        for (
                                            let i = control.length;
                                            i >= 0;
                                            i--
                                        ) {
                                            control.removeAt(i);
                                        }

                                        for (
                                            let j = 0;
                                            j <
                                            element.Constructions[i]
                                                .RepairItemDetails.length;
                                            j++
                                        ) {
                                            if (
                                                location != null &&
                                                location != "" &&
                                                location != undefined
                                            ) {
                                                if (
                                                    element.Constructions[i]
                                                        .RepairItemDetails[j]
                                                        .Location == location &&
                                                    element.Constructions[i]
                                                        .RepairItemDetails[j]
                                                        .Level == level &&
                                                    element.Constructions[i]
                                                        .RepairItemDetails[j]
                                                        .RepairItemName ==
                                                        itemName
                                                ) {
                                                    var item =
                                                        element.Constructions[i]
                                                            .RepairItemDetails[
                                                            j
                                                        ];
                                                    control.push(
                                                        this.initupdateItem(
                                                            item
                                                        )
                                                    );
                                                }
                                            } else {
                                                if (
                                                    element.Constructions[i]
                                                        .RepairItemDetails[j]
                                                        .Level == level &&
                                                    element.Constructions[i]
                                                        .RepairItemDetails[j]
                                                        .RepairItemName ==
                                                        itemName
                                                ) {
                                                    var item =
                                                        element.Constructions[i]
                                                            .RepairItemDetails[
                                                            j
                                                        ];
                                                    control.push(
                                                        this.initupdateItem(
                                                            item
                                                        )
                                                    );
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    });
                }
            }
        } else {
            if (this.getRepairConstructionList.length > 0) {
                this.getRepairConstructionList.forEach((element) => {
                    if (element.PropertyId == this.RepairPropertyId) {
                        this.propertyRepairConstructionDetail = element;

                        for (let i = 0; i < element.Constructions.length; i++) {
                            if (element.Constructions[i].RepairType == type) {
                                if (
                                    element.Constructions[i].RepairItemDetails
                                        .length > 0
                                ) {
                                    const control = <FormArray>(
                                        this.RepairItemForm.controls[
                                            "RepairItemDetails"
                                        ]
                                    );
                                    for (let i = control.length; i >= 0; i--) {
                                        control.removeAt(i);
                                    }

                                    for (
                                        let j = 0;
                                        j <
                                        element.Constructions[i]
                                            .RepairItemDetails.length;
                                        j++
                                    ) {
                                        if (
                                            location != null &&
                                            location != "" &&
                                            location != undefined
                                        ) {
                                            if (
                                                element.Constructions[i]
                                                    .RepairItemDetails[j]
                                                    .Location == location &&
                                                element.Constructions[i]
                                                    .RepairItemDetails[j]
                                                    .Level == level &&
                                                element.Constructions[i]
                                                    .RepairItemDetails[j]
                                                    .RepairItemName == itemName
                                            ) {
                                                var item =
                                                    element.Constructions[i]
                                                        .RepairItemDetails[j];
                                                control.push(
                                                    this.initupdateItem(item)
                                                );
                                            }
                                        } else {
                                            if (
                                                element.Constructions[i]
                                                    .RepairItemDetails[j]
                                                    .Level == level &&
                                                element.Constructions[i]
                                                    .RepairItemDetails[j]
                                                    .RepairItemName == itemName
                                            ) {
                                                var item =
                                                    element.Constructions[i]
                                                        .RepairItemDetails[j];
                                                control.push(
                                                    this.initupdateItem(item)
                                                );
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                });
            }
        }

        this.getRepairConstructionList.forEach((element) => {
            if (element.PropertyId == this.RepairPropertyId) {
                this.propertyRepairConstructionDetail = element;
                for (let i = 0; i < element.Constructions.length; i++) {
                    if (type == element.Constructions[i].RepairType) {
                        for (
                            let j = 0;
                            j <
                            element.Constructions[i].RepairItemDetails.length;
                            j++
                        ) {
                            for (
                                let k = 0;
                                k <
                                this.coRepairContructionList[0].Constructions
                                    .length;
                                k++
                            ) {
                                if (
                                    type ==
                                    this.coRepairContructionList[0]
                                        .Constructions[k].RepairType
                                ) {
                                    if (this.GCBidData != null) {
                                        if (
                                            this.GCBidData.Constructions
                                                .length > 0
                                        ) {
                                            var checkType = true;
                                            for (
                                                let l = 0;
                                                l <
                                                this.GCBidData.Constructions
                                                    .length;
                                                l++
                                            ) {
                                                if (
                                                    type ==
                                                    this.GCBidData
                                                        .Constructions[l]
                                                        .RepairType
                                                ) {
                                                    const control = <FormArray>(
                                                        this.RepairItemForm
                                                            .controls[
                                                            "RepairItemDetails"
                                                        ]
                                                    );
                                                    for (
                                                        let m = control.length;
                                                        m >= 0;
                                                        m--
                                                    ) {
                                                        control.removeAt(m);
                                                    }
                                                    for (
                                                        let n = 0;
                                                        n <
                                                        this.GCBidData
                                                            .Constructions[l]
                                                            .RepairItemDetails
                                                            .length;
                                                        n++
                                                    ) {
                                                        if (
                                                            location != null &&
                                                            location != "" &&
                                                            location !=
                                                                undefined
                                                        ) {
                                                            if (
                                                                this.GCBidData
                                                                    .Constructions[
                                                                    l
                                                                ]
                                                                    .RepairItemDetails[
                                                                    n
                                                                ].Location ==
                                                                    location &&
                                                                this.GCBidData
                                                                    .Constructions[
                                                                    l
                                                                ]
                                                                    .RepairItemDetails[
                                                                    n
                                                                ].Level ==
                                                                    level &&
                                                                this.GCBidData
                                                                    .Constructions[
                                                                    l
                                                                ]
                                                                    .RepairItemDetails[
                                                                    n
                                                                ]
                                                                    .RepairItemName ==
                                                                    itemName
                                                            ) {
                                                                var item =
                                                                    this
                                                                        .GCBidData
                                                                        .Constructions[
                                                                        l
                                                                    ]
                                                                        .RepairItemDetails[
                                                                        n
                                                                    ];
                                                                checkType =
                                                                    false;

                                                                control.push(
                                                                    this.initCOUpdateItem(
                                                                        item
                                                                    )
                                                                );
                                                            }
                                                        } else {
                                                            if (
                                                                this.GCBidData
                                                                    .Constructions[
                                                                    l
                                                                ]
                                                                    .RepairItemDetails[
                                                                    n
                                                                ].Level ==
                                                                    level &&
                                                                this.GCBidData
                                                                    .Constructions[
                                                                    l
                                                                ]
                                                                    .RepairItemDetails[
                                                                    n
                                                                ]
                                                                    .RepairItemName ==
                                                                    itemName
                                                            ) {
                                                                var item =
                                                                    this
                                                                        .GCBidData
                                                                        .Constructions[
                                                                        l
                                                                    ]
                                                                        .RepairItemDetails[
                                                                        n
                                                                    ];
                                                                checkType =
                                                                    false;

                                                                control.push(
                                                                    this.initCOUpdateItem(
                                                                        item
                                                                    )
                                                                );
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                            if (checkType) {
                                                if (
                                                    this
                                                        .getRepairConstructionList
                                                        .length > 0
                                                ) {
                                                    this.getRepairConstructionList.forEach(
                                                        (element) => {
                                                            if (
                                                                element.PropertyId ==
                                                                this
                                                                    .RepairPropertyId
                                                            ) {
                                                                this.propertyRepairConstructionDetail =
                                                                    element;

                                                                for (
                                                                    let i = 0;
                                                                    i <
                                                                    element
                                                                        .Constructions
                                                                        .length;
                                                                    i++
                                                                ) {
                                                                    if (
                                                                        element
                                                                            .Constructions[
                                                                            i
                                                                        ]
                                                                            .RepairType ==
                                                                        type
                                                                    ) {
                                                                        if (
                                                                            element
                                                                                .Constructions[
                                                                                i
                                                                            ]
                                                                                .RepairItemDetails
                                                                                .length >
                                                                            0
                                                                        ) {
                                                                            const control =
                                                                                <
                                                                                    FormArray
                                                                                >this
                                                                                    .RepairItemForm
                                                                                    .controls[
                                                                                    "RepairItemDetails"
                                                                                ];
                                                                            for (
                                                                                let i =
                                                                                    control.length;
                                                                                i >=
                                                                                0;
                                                                                i--
                                                                            ) {
                                                                                control.removeAt(
                                                                                    i
                                                                                );
                                                                            }

                                                                            for (
                                                                                let j = 0;
                                                                                j <
                                                                                element
                                                                                    .Constructions[
                                                                                    i
                                                                                ]
                                                                                    .RepairItemDetails
                                                                                    .length;
                                                                                j++
                                                                            ) {
                                                                                if (
                                                                                    location !=
                                                                                        null &&
                                                                                    location !=
                                                                                        "" &&
                                                                                    location !=
                                                                                        undefined
                                                                                ) {
                                                                                    if (
                                                                                        element
                                                                                            .Constructions[
                                                                                            i
                                                                                        ]
                                                                                            .RepairItemDetails[
                                                                                            j
                                                                                        ]
                                                                                            .Location ==
                                                                                            location &&
                                                                                        element
                                                                                            .Constructions[
                                                                                            i
                                                                                        ]
                                                                                            .RepairItemDetails[
                                                                                            j
                                                                                        ]
                                                                                            .Level ==
                                                                                            level &&
                                                                                        element
                                                                                            .Constructions[
                                                                                            i
                                                                                        ]
                                                                                            .RepairItemDetails[
                                                                                            j
                                                                                        ]
                                                                                            .RepairItemName ==
                                                                                            itemName
                                                                                    ) {
                                                                                        var item =
                                                                                            element
                                                                                                .Constructions[
                                                                                                i
                                                                                            ]
                                                                                                .RepairItemDetails[
                                                                                                j
                                                                                            ];
                                                                                        control.push(
                                                                                            this.initupdateItem(
                                                                                                item
                                                                                            )
                                                                                        );
                                                                                    }
                                                                                } else {
                                                                                    if (
                                                                                        element
                                                                                            .Constructions[
                                                                                            i
                                                                                        ]
                                                                                            .RepairItemDetails[
                                                                                            j
                                                                                        ]
                                                                                            .Level ==
                                                                                            level &&
                                                                                        element
                                                                                            .Constructions[
                                                                                            i
                                                                                        ]
                                                                                            .RepairItemDetails[
                                                                                            j
                                                                                        ]
                                                                                            .RepairItemName ==
                                                                                            itemName
                                                                                    ) {
                                                                                        var item =
                                                                                            element
                                                                                                .Constructions[
                                                                                                i
                                                                                            ]
                                                                                                .RepairItemDetails[
                                                                                                j
                                                                                            ];
                                                                                        control.push(
                                                                                            this.initupdateItem(
                                                                                                item
                                                                                            )
                                                                                        );
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    );
                                                }
                                            }
                                        } else {
                                            const control = <FormArray>(
                                                this.RepairItemForm.controls[
                                                    "RepairItemDetails"
                                                ]
                                            );
                                            for (
                                                let m = control.length;
                                                m >= 0;
                                                m--
                                            ) {
                                                control.removeAt(m);
                                            }
                                            for (
                                                let n = 0;
                                                n <
                                                this.coRepairContructionList[0]
                                                    .Constructions[k]
                                                    .RepairItemDetails.length;
                                                n++
                                            ) {
                                                if (
                                                    location != null &&
                                                    location != "" &&
                                                    location != undefined
                                                ) {
                                                    if (
                                                        this
                                                            .coRepairContructionList[0]
                                                            .Constructions[k]
                                                            .RepairItemDetails[
                                                            n
                                                        ].Location ==
                                                            location &&
                                                        this
                                                            .coRepairContructionList[0]
                                                            .Constructions[k]
                                                            .RepairItemDetails[
                                                            n
                                                        ].Level == level &&
                                                        this
                                                            .coRepairContructionList[0]
                                                            .Constructions[k]
                                                            .RepairItemDetails[
                                                            n
                                                        ].RepairItemName ==
                                                            itemName
                                                    ) {
                                                        var item =
                                                            this
                                                                .coRepairContructionList[0]
                                                                .Constructions[
                                                                k
                                                            ].RepairItemDetails[
                                                                n
                                                            ];

                                                        control.push(
                                                            this.initupdateItem(
                                                                item
                                                            )
                                                        );
                                                    }
                                                } else {
                                                    if (
                                                        this
                                                            .coRepairContructionList[0]
                                                            .Constructions[k]
                                                            .RepairItemDetails[
                                                            n
                                                        ].Level == level &&
                                                        this
                                                            .coRepairContructionList[0]
                                                            .Constructions[k]
                                                            .RepairItemDetails[
                                                            n
                                                        ].RepairItemName ==
                                                            itemName
                                                    ) {
                                                        var item =
                                                            this
                                                                .coRepairContructionList[0]
                                                                .Constructions[
                                                                k
                                                            ].RepairItemDetails[
                                                                n
                                                            ];

                                                        control.push(
                                                            this.initupdateItem(
                                                                item
                                                            )
                                                        );
                                                    }
                                                }
                                            }
                                        }
                                    } else {
                                        const control = <FormArray>(
                                            this.RepairItemForm.controls[
                                                "RepairItemDetails"
                                            ]
                                        );
                                        for (
                                            let m = control.length;
                                            m >= 0;
                                            m--
                                        ) {
                                            control.removeAt(m);
                                        }
                                        for (
                                            let n = 0;
                                            n <
                                            this.coRepairContructionList[0]
                                                .Constructions[k]
                                                .RepairItemDetails.length;
                                            n++
                                        ) {
                                            if (
                                                location != null &&
                                                location != "" &&
                                                location != undefined
                                            ) {
                                                if (
                                                    this
                                                        .coRepairContructionList[0]
                                                        .Constructions[k]
                                                        .RepairItemDetails[n]
                                                        .Location == location &&
                                                    this
                                                        .coRepairContructionList[0]
                                                        .Constructions[k]
                                                        .RepairItemDetails[n]
                                                        .Level == level &&
                                                    this
                                                        .coRepairContructionList[0]
                                                        .Constructions[k]
                                                        .RepairItemDetails[n]
                                                        .RepairItemName ==
                                                        itemName
                                                ) {
                                                    var item =
                                                        this
                                                            .coRepairContructionList[0]
                                                            .Constructions[k]
                                                            .RepairItemDetails[
                                                            n
                                                        ];

                                                    control.push(
                                                        this.initupdateItem(
                                                            item
                                                        )
                                                    );
                                                }
                                            } else {
                                                if (
                                                    this
                                                        .coRepairContructionList[0]
                                                        .Constructions[k]
                                                        .RepairItemDetails[n]
                                                        .Level == level &&
                                                    this
                                                        .coRepairContructionList[0]
                                                        .Constructions[k]
                                                        .RepairItemDetails[n]
                                                        .RepairItemName ==
                                                        itemName
                                                ) {
                                                    var item =
                                                        this
                                                            .coRepairContructionList[0]
                                                            .Constructions[k]
                                                            .RepairItemDetails[
                                                            n
                                                        ];

                                                    control.push(
                                                        this.initupdateItem(
                                                            item
                                                        )
                                                    );
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        });

        this.isList = false;

        let allRepairItems = {
            Masonry: 1,
            Siding: 2,
            "Gutters/Downspouts": 3,
            Roof: 4,
            Shutters: 5,
            Exteriors: 6,
            Walks: 7,
            Driveways: 8,
            "Painting (Ext)": 9,
            Caulking: 10,
            Fencing: 11,
            Grading: 12,
            Windows: 13,
            Weatherstrip: 14,
            "Doors (Ext)	": 15,
            "Doors (Int)	": 16,
            "Partition Wall": 17,
            "Plaster/Drywall": 18,
            Decorating: 19,
            "Wood Trim	": 20,
            Stairs: 21,
            Closets: 22,
            "Wood Floors": 23,
            "Finished Floors": 24,
            "Ceramic Tile": 25,
            "Bath Accesories": 26,
            Plumbing: 27,
            Electrical: 28,
            Heating: 29,
            Insulation: 30,
            Cabinetry: 31,
            Appliances: 32,
            Basements: 33,
            Cleanup: 34,
            Miscellaneous: 35,
        };
        let a: any = [];
        let b: any = [];
        let c: any = [];
        let d: any = [];

        repairItems = this.coRepairContructionList[0].Constructions;
        for (let index = 0; index < repairItems.length; index++) {
            for (
                let index1 = 0;
                index1 < repairItems[index].RepairItemDetails.length;
                index1++
            ) {
                if (repairItems[index].RepairItemDetails[index1].Level == "M") {
                    let aRepairtDetails = [];
                    aRepairtDetails.push(
                        repairItems[index].RepairItemDetails[index1]
                    );
                    let aData = {
                        Id: allRepairItems[repairItems[index].RepairType],
                        RepairType: repairItems[index].RepairType,
                        RepairItemDetails: aRepairtDetails,
                    };
                    a.push(aData);
                }
                if (repairItems[index].RepairItemDetails[index1].Level == "R") {
                    let bRepairtDetails = [];
                    bRepairtDetails.push(
                        repairItems[index].RepairItemDetails[index1]
                    );

                    let bData = {
                        Id: allRepairItems[repairItems[index].RepairType],
                        RepairType: repairItems[index].RepairType,
                        RepairItemDetails: bRepairtDetails,
                    };
                    b.push(bData);
                }
                if (
                    repairItems[index].RepairItemDetails[index1].Level == "D" &&
                    repairItems[index].RepairItemDetails[index1].isAdditional !=
                        true
                ) {
                    let cRepairtDetails = [];
                    cRepairtDetails.push(
                        repairItems[index].RepairItemDetails[index1]
                    );
                    let cData = {
                        Id: allRepairItems[repairItems[index].RepairType],
                        RepairType: repairItems[index].RepairType,
                        RepairItemDetails: cRepairtDetails,
                    };
                    c.push(cData);
                }
                if (
                    repairItems[index].RepairItemDetails[index1].Level == "D" &&
                    repairItems[index].RepairItemDetails[index1].isAdditional ==
                        true
                ) {
                    let cRepairtDetails = [];
                    cRepairtDetails.push(
                        repairItems[index].RepairItemDetails[index1]
                    );
                    let dData = {
                        Id: allRepairItems[repairItems[index].RepairType],
                        RepairType: repairItems[index].RepairType,
                        RepairItemDetails: cRepairtDetails,
                    };
                    c.push(dData);
                }
            }
        }
        let currentRepairItems: any = [];
        if (level == "M") {
            currentRepairItems = a;
        }
        if (level == "R") {
            currentRepairItems = b;
        }
        if (level == "D") {
            currentRepairItems = c;
        }

        currentRepairItems.sort(function (a, b) {
            return a.Id - b.Id;
        });

        let n = currentRepairItems.length - 1;

        let currentIndex: any;
        let i = 0;
        for (let x = 0; x < currentRepairItems.length; x++) {
            if (currentRepairItems[x].RepairType == type) {
                for (
                    let y = 0;
                    y < currentRepairItems[x].RepairItemDetails.length;
                    y++
                ) {
                    if (
                        currentRepairItems[x].RepairItemDetails[y]
                            .RepairItemName == itemName
                    ) {
                        currentIndex = i;
                        break;
                    }
                }
            }
            i++;
        }

        if (currentIndex != n) {
            for (
                let y = 0;
                y <
                currentRepairItems[currentIndex + 1].RepairItemDetails.length;
                y++
            ) {
                this.nextItemName =
                    currentRepairItems[currentIndex + 1].RepairItemDetails[
                        y
                    ].RepairItemName;
                this.nextItemType =
                    currentRepairItems[currentIndex + 1].RepairType;
            }
        }

        if (currentIndex != 0) {
            for (
                let y = 0;
                y <
                currentRepairItems[currentIndex - 1].RepairItemDetails.length;
                y++
            ) {
                this.previousItemName =
                    currentRepairItems[currentIndex - 1].RepairItemDetails[
                        y
                    ].RepairItemName;
                this.previousItemLocation =
                    currentRepairItems[currentIndex - 1].RepairItemDetails[
                        y
                    ].Location;
                this.previousItemType =
                    currentRepairItems[currentIndex - 1].RepairType;
            }
        }
        if (currentIndex == n) {
            this.nextItemType = "";
        }
        if (currentIndex == 0) {
            this.previousItemType = "";
        }
    }

    addRepairItems() {
        // add address to the list
        const control = <FormArray>(
            this.RepairItemForm.controls["RepairItemDetails"]
        );
        control.push(this.initRepairItem());
    }

    initGCUpdateItem(item) {
        return this._fb.group({
            RepairItemName: item.RepairItemName
                ? [{ value: item.RepairItemName, disabled: false }]
                : [{ disabled: false }],
            Location: item.Location
                ? [{ value: item.Location, disabled: false }]
                : [{ disabled: false }],
            Level: item.Level
                ? [{ value: item.Level, disabled: false }]
                : [{ disabled: false }],
            MaterialUnit: [item.MaterialUnit],
            MaterialQuantity: [item.MaterialQuantity],
            MaterialUnitCost: [item.MaterialUnitCost],
            LaborUnit: [item.LaborUnit],
            LaborQuantity: [item.LaborQuantity],
            LaborUnitCost: [item.LaborUnitCost],
            Summary: [item.Summary],
        });
    }
    initCOUpdateItem(item) {
        return this._fb.group({
            RepairItemName: item.RepairItemName
                ? [{ value: item.RepairItemName, disabled: true }]
                : [{ disabled: false }],
            Location: item.Location
                ? [{ value: item.Location, disabled: true }]
                : [{ disabled: false }],
            Level: item.Level
                ? [{ value: item.Level, disabled: true }]
                : [{ disabled: false }],
            MaterialUnit: [item.MaterialUnit],
            MaterialQuantity: [item.MaterialQuantity],
            MaterialUnitCost: [item.MaterialUnitCost],
            LaborUnit: [item.LaborUnit],
            LaborQuantity: [item.LaborQuantity],
            LaborUnitCost: [item.LaborUnitCost],
            Summary: [item.Summary],
        });
    }
    initupdateItem(item) {
        // initialize our address
        if (item.Level == "M") {
            return this._fb.group({
                RepairItemName: item.RepairItemName
                    ? [{ value: item.RepairItemName, disabled: true }]
                    : [{ disabled: false }],
                Location: item.Location
                    ? [{ value: item.Location, disabled: true }]
                    : [{ disabled: false }],
                Level: item.Level
                    ? [{ value: item.Level, disabled: true }]
                    : [{ disabled: false }],
                MaterialUnit: [item.MaterialUnit],
                MaterialQuantity: [item.MaterialQuantity, Validators.required],
                MaterialUnitCost: [null, Validators.required],
                LaborUnit: [item.LaborUnit],
                LaborQuantity: [item.LaborQuantity, Validators.required],
                LaborUnitCost: [null, Validators.required],
                Summary: [item.Summary],
            });
        } else {
            return this._fb.group({
                RepairItemName: item.RepairItemName
                    ? [{ value: item.RepairItemName, disabled: true }]
                    : [{ disabled: false }],
                Location: item.Location
                    ? [{ value: item.Location, disabled: true }]
                    : [{ disabled: false }],
                Level: item.Level
                    ? [{ value: item.Level, disabled: true }]
                    : [{ disabled: false }],
                MaterialUnit: [item.MaterialUnit],
                MaterialQuantity: [item.MaterialQuantity],
                MaterialUnitCost: [null],
                LaborUnit: [item.LaborUnit],
                LaborQuantity: [item.LaborQuantity],
                LaborUnitCost: [null],
                Summary: [item.Summary],
            });
        }
    }

    RepairList() {
        this.isList = true;

        const control = <FormArray>(
            this.RepairItemForm.controls["RepairItemDetails"]
        );

        for (let j = control.length; j >= 0; j--) {
            control.removeAt(j);
            if (j == 0) {
                control.push(this.initRepairItem());
            }
        }
    }
    refreshSearchComponents() {
        this.searchResult = [];
        this.searchComponentField.nativeElement.value = "";
    }
    showAddForm() {
        this.isAdd = true;
        this.isList = false;
    }

    SaveAllowableFeesItem() {
        this.submitted = true;
        if (this.RepairItemForm.invalid) {
            return;
        } else {
            this.loading = true;
            this.submitted = false;
            this.propertyRepairConstructionDetail = "";
            var formData = this.RepairItemForm.value;
            if (this.getRepairConstructionList.length > 0) {
                this.getRepairConstructionList.forEach((element) => {
                    if (element.PropertyId == this.RepairPropertyId) {
                        this.propertyRepairConstructionDetail = element;
                    }
                });
            } else {
                this.propertyRepairConstructionDetail = {
                    PropertyId: 1,
                    Title: "Title",
                    OccupiedDuringConstruction: "",
                    BorrowerName: "",
                    InspectionDate: "",
                    InspectionAddress: "",
                    BorrowerPhone: "",
                    FHACase: "",
                    LoanNo: "",
                    Constructions: [],
                    ContingencyReservePercentage: "",
                    AllowableFees: "",
                    ConsultantsComment: "",
                    DateofFinalAcceptance: "",
                    ConsultantSignature: "",
                    ConsultantSignDate: "",
                    Consultant: "",
                    IdNo: "",
                    ApplicantsSignAndDate: "",
                    ContractorSignature: "",
                    ContractorsSignatureDate: "",
                    Approved: "",
                    ApprovedDate: "",
                    MonthToCompletion: "",
                    SorStatus: "",
                    MailSend: "",
                    MailSendDate: "",
                    RevisedStatus: "",
                    IsFinal: "",
                    CreatedBy: "CreatedBy",
                    CreatedDate: "CreatedDate",
                    UpdateDate: "UpdateDate",
                    UpdatedBy: "UpdatedBy",
                };
            }

            if (this.propertyRepairConstructionDetail.Id) {
                var propertyData = this.propertyRepairConstructionDetail;
                propertyData.PropertyId = this.RepairPropertyId;

                propertyData.ConsultantsComment = formData.ConsultantsComment;

                this._bservice.UpdateSOR(propertyData).subscribe(
                    (response) => {
                        this.sessionService.notifier.notify(
                            "success",
                            "Specification Of Repair Construction Detail update successfully."
                        );
                        this.isList = true;
                        this.isAdd = false;

                        this.getRepairItem(this.RepairPropertyId);
                        this.loading = false;
                    },
                    (error) => {
                        this.sessionService.notifier.notify(
                            "error",
                            error.error.message
                        );
                        this.loading = false;
                    }
                );
            } else {
                var propertyData = this.propertyRepairConstructionDetail;

                propertyData.PropertyId = this.RepairPropertyId;

                propertyData.ConsultantsComment = formData.ConsultantsComment;

                this._bservice.AddSOR(propertyData).subscribe(
                    (response) => {
                        var propertyStatusData = {
                            Id: parseInt(this.RepairPropertyId),
                            Status: "Initial Inspection Completed",
                            CreatedBy: this.userDetail.EmailId,
                            UpdatedBy: this.userDetail.EmailId,
                        };
                        this._bservice
                            .UpdatePropertyStatus(propertyStatusData)
                            .subscribe(
                                (response) => {
                                    this.sessionService.notifier.notify(
                                        "success",
                                        "Specification Of Repair Construction Detail added successfully."
                                    );
                                    this.isList = true;
                                    this.isAdd = false;
                                    this.getRepairItem(this.RepairPropertyId);
                                    this.loading = false;
                                },
                                (error) => {
                                    this.sessionService.notifier.notify(
                                        "error",
                                        error.error.message
                                    );
                                    this.loading = false;
                                }
                            );
                    },
                    (error) => {
                        this.sessionService.notifier.notify(
                            "error",
                            error.error.message
                        );
                        this.loading = false;
                    }
                );
            }
        }
    }

    SaveConstructionItem(
        nexttabIndex = "",
        nextItemType = "",
        nextItemName = "",
        type = ""
    ) {
        this.submitted = true;
        if (this.RepairItemForm.invalid) {
            this.sessionService.notifier.notify(
                "error",
                "Something went wrong."
            );
            this.loading = false;
            return;
        } else {
            this.loading = true;
            this.submitted = false;
            this.propertyRepairConstructionDetail = "";
            var formData = this.RepairItemForm.getRawValue();

            if (this.GCBidData != null && this.GCBidData != "") {
                this.propertyRepairConstructionDetail = this.GCBidData;
            } else {
                this.propertyRepairConstructionDetail = {
                    PropertyId: null,
                    GeneralContractorId: null,
                    PermitCost: [],
                    Constructions: [],
                    Status: "Pending",
                    ConsultantsComment: "",
                    AllowableFees: "AllowableFees",
                    GCSignature: "GCSignature",
                    DateSign: "DateSign",
                    Print1: "Print1",
                    Print2: "Print2",
                    ReviseFlag: "ReviseFlag",
                    ReviseComment: "ReviseComment",
                    GCRejected: "GCRejected",
                    ParticipateOn: "ParticipateOn",
                    CreatedBy: "CreatedBy",
                    CreatedDate: "CreatedDate",
                    UpdateDate: "UpdateDate",
                    UpdatedBy: "UpdatedBy",
                };
            }

            if (this.propertyRepairConstructionDetail.Id) {
                var propertyData = this.propertyRepairConstructionDetail;
                let filledRepairItem = [];
                for (let i = 0; i < formData.RepairItemDetails.length; i++) {
                    if (
                        formData.RepairItemDetails[i].RepairItemName != "" &&
                        formData.RepairItemDetails[i].RepairItemName != null &&
                        formData.RepairItemDetails[i].Location != "" &&
                        formData.RepairItemDetails[i].Location != null &&
                        formData.RepairItemDetails[i].Level != "" &&
                        formData.RepairItemDetails[i].Level != null &&
                        formData.RepairItemDetails[i].MaterialUnit != "" &&
                        formData.RepairItemDetails[i].MaterialUnit != null &&
                        formData.RepairItemDetails[i].MaterialQuantity != "" &&
                        formData.RepairItemDetails[i].MaterialQuantity !=
                            null &&
                        formData.RepairItemDetails[i].MaterialUnitCost != "" &&
                        formData.RepairItemDetails[i].MaterialUnitCost !=
                            null &&
                        formData.RepairItemDetails[i].LaborUnit != "" &&
                        formData.RepairItemDetails[i].LaborUnit != null &&
                        formData.RepairItemDetails[i].LaborQuantity != "" &&
                        formData.RepairItemDetails[i].LaborQuantity != null &&
                        formData.RepairItemDetails[i].LaborUnitCost != "" &&
                        formData.RepairItemDetails[i].LaborUnitCost != null
                    ) {
                        if (formData.RepairItemDetails[i].Level == "M") {
                            if (
                                !isNaN(
                                    parseInt(
                                        formData.RepairItemDetails[i]
                                            .MaterialQuantity
                                    )
                                ) &&
                                parseInt(
                                    formData.RepairItemDetails[i]
                                        .MaterialQuantity
                                ) > 0 &&
                                !isNaN(
                                    parseInt(
                                        formData.RepairItemDetails[i]
                                            .LaborQuantity
                                    )
                                ) &&
                                parseInt(
                                    formData.RepairItemDetails[i].LaborQuantity
                                ) > 0 &&
                                !isNaN(
                                    formData.RepairItemDetails[i]
                                        .MaterialUnitCost
                                ) &&
                                !isNaN(
                                    formData.RepairItemDetails[i].LaborUnitCost
                                ) &&
                                formData.RepairItemDetails[i].MaterialUnitCost >
                                    0 &&
                                formData.RepairItemDetails[i].LaborUnitCost > 0
                            ) {
                                if (
                                    formData.RepairItemDetails[i].Summary !=
                                        "" &&
                                    formData.RepairItemDetails[i].Summary !=
                                        null &&
                                    formData.RepairItemDetails[i].Summary !=
                                        undefined
                                ) {
                                    filledRepairItem.push(
                                        formData.RepairItemDetails[i]
                                    );
                                } else {
                                    this.sessionService.notifier.notify(
                                        "error",
                                        "Please enter Item summary."
                                    );
                                    this.loading = false;
                                    return;
                                }
                            } else {
                                this.sessionService.notifier.notify(
                                    "error",
                                    "Please enter numberic value in Material (Quantity & Unit Cost) and Labor (Quantity & Unit Cost). Value should be greater than 0."
                                );
                                this.loading = false;
                                return;
                            }
                        } else {
                            if (
                                !isNaN(
                                    parseInt(
                                        formData.RepairItemDetails[i]
                                            .MaterialQuantity
                                    )
                                ) &&
                                parseInt(
                                    formData.RepairItemDetails[i]
                                        .MaterialQuantity
                                ) > 0 &&
                                !isNaN(
                                    parseInt(
                                        formData.RepairItemDetails[i]
                                            .LaborQuantity
                                    )
                                ) &&
                                parseInt(
                                    formData.RepairItemDetails[i].LaborQuantity
                                ) > 0 &&
                                !isNaN(
                                    formData.RepairItemDetails[i]
                                        .MaterialUnitCost
                                ) &&
                                !isNaN(
                                    formData.RepairItemDetails[i].LaborUnitCost
                                )
                            ) {
                                if (
                                    formData.RepairItemDetails[i].Summary !=
                                        "" &&
                                    formData.RepairItemDetails[i].Summary !=
                                        null &&
                                    formData.RepairItemDetails[i].Summary !=
                                        undefined
                                ) {
                                    filledRepairItem.push(
                                        formData.RepairItemDetails[i]
                                    );
                                } else {
                                    this.sessionService.notifier.notify(
                                        "error",
                                        "Please enter Item summary."
                                    );
                                    this.loading = false;
                                    return;
                                }
                            } else {
                                this.sessionService.notifier.notify(
                                    "error",
                                    "Please enter numberic value in Material (Quantity & Unit Cost) and Labor (Quantity & Unit Cost). Value should be greater than 0."
                                );
                                this.loading = false;
                                return;
                            }
                        }
                    }
                }
                if (
                    filledRepairItem.length > 0 &&
                    filledRepairItem.length == formData.RepairItemDetails.length
                ) {
                    var data = {
                        RepairType: formData.SendRepairType,
                        RepairItemDetails: filledRepairItem,
                    };

                    this.checkConstructionsDetails = false;
                    this.checkRepairItem = false;
                    if (propertyData.Constructions.length > 0) {
                        for (
                            let i = 0;
                            i < propertyData.Constructions.length;
                            i++
                        ) {
                            if (
                                propertyData.Constructions[i].RepairType ==
                                formData.SendRepairType
                            ) {
                                let propertyDataLength =
                                    propertyData.Constructions[i]
                                        .RepairItemDetails.length;

                                for (let l = 0; l < propertyDataLength; l++) {
                                    for (
                                        let k = 0;
                                        k < formData.RepairItemDetails.length;
                                        k++
                                    ) {
                                        if (
                                            propertyData.Constructions[i]
                                                .RepairItemDetails[l]
                                                .RepairItemName ==
                                                formData.RepairItemDetails[k]
                                                    .RepairItemName &&
                                            propertyData.Constructions[i]
                                                .RepairItemDetails[l].Level ==
                                                formData.RepairItemDetails[k]
                                                    .Level &&
                                            propertyData.Constructions[i]
                                                .RepairItemDetails[l]
                                                .Location ==
                                                formData.RepairItemDetails[k]
                                                    .Location
                                        ) {
                                            propertyData.Constructions[
                                                i
                                            ].RepairItemDetails[l] =
                                                formData.RepairItemDetails[k];
                                            this.checkRepairItem = true;
                                        }
                                    }
                                }
                                if (this.checkRepairItem == false) {
                                    for (
                                        let k1 = 0;
                                        k1 < formData.RepairItemDetails.length;
                                        k1++
                                    ) {
                                        propertyData.Constructions[
                                            i
                                        ].RepairItemDetails.push(
                                            formData.RepairItemDetails[k1]
                                        );
                                    }
                                }

                                this.checkConstructionsDetails = true;
                            }
                        }
                    }
                    if (this.checkConstructionsDetails == false) {
                        propertyData.Constructions.push(data);
                    }
                } else {
                    this.sessionService.notifier.notify(
                        "error",
                        "Please fill all the information of (Repair Item Name, Location, Level, Unit, Unit Quantity, Unit Cost etc )."
                    );
                    this.loading = false;
                    return;
                }

                propertyData.PropertyId = this.RepairPropertyId;

                propertyData.GeneralContractorId = this.userDetail.Id;

                propertyData.ConsultantsComment = formData.ConsultantsComment;

                propertyData.propertyStatus = "";

                this._bservice.UpdateSORForGC(propertyData).subscribe(
                    (response) => {
                        this.sessionService.notifier.notify(
                            "success",
                            "Repair on Bid updated successfully."
                        );

                        this.isList = true;
                        this.isAdd = false;

                        this.permitCostonReset = [];
                        this.getRepairItem(this.RepairPropertyId);
                        this.UpdateRepair(nextItemType, nextItemName, type);
                        if (nexttabIndex) {
                            this.selectedIndex = nexttabIndex;
                        }
                        this.loading = false;
                    },
                    (error) => {
                        this.sessionService.notifier.notify(
                            "error",
                            error.error.message
                        );
                        this.loading = false;
                    }
                );
            } else {
                let filledRepairItem = [];
                var propertyData = this.propertyRepairConstructionDetail;
                propertyData.PropertyId = this.RepairPropertyId;
                for (let i = 0; i < formData.RepairItemDetails.length; i++) {
                    if (
                        formData.RepairItemDetails[i].RepairItemName != "" &&
                        formData.RepairItemDetails[i].RepairItemName != null &&
                        formData.RepairItemDetails[i].Location != "" &&
                        formData.RepairItemDetails[i].Location != null &&
                        formData.RepairItemDetails[i].Level != "" &&
                        formData.RepairItemDetails[i].Level != null &&
                        formData.RepairItemDetails[i].MaterialUnit != "" &&
                        formData.RepairItemDetails[i].MaterialUnit != null &&
                        formData.RepairItemDetails[i].MaterialQuantity != "" &&
                        formData.RepairItemDetails[i].MaterialQuantity !=
                            null &&
                        formData.RepairItemDetails[i].MaterialUnitCost != "" &&
                        formData.RepairItemDetails[i].MaterialUnitCost !=
                            null &&
                        formData.RepairItemDetails[i].LaborUnit != "" &&
                        formData.RepairItemDetails[i].LaborUnit != null &&
                        formData.RepairItemDetails[i].LaborQuantity != "" &&
                        formData.RepairItemDetails[i].LaborQuantity != null &&
                        formData.RepairItemDetails[i].LaborUnitCost != "" &&
                        formData.RepairItemDetails[i].LaborUnitCost != null
                    ) {
                        if (formData.RepairItemDetails[i].Level == "M") {
                            if (
                                !isNaN(
                                    parseInt(
                                        formData.RepairItemDetails[i]
                                            .MaterialQuantity
                                    )
                                ) &&
                                parseInt(
                                    formData.RepairItemDetails[i]
                                        .MaterialQuantity
                                ) > 0 &&
                                !isNaN(
                                    parseInt(
                                        formData.RepairItemDetails[i]
                                            .LaborQuantity
                                    )
                                ) &&
                                parseInt(
                                    formData.RepairItemDetails[i].LaborQuantity
                                ) > 0 &&
                                !isNaN(
                                    formData.RepairItemDetails[i]
                                        .MaterialUnitCost
                                ) &&
                                !isNaN(
                                    formData.RepairItemDetails[i].LaborUnitCost
                                ) &&
                                parseInt(
                                    formData.RepairItemDetails[i]
                                        .MaterialUnitCost
                                ) > 0 &&
                                parseInt(
                                    formData.RepairItemDetails[i].LaborUnitCost
                                ) > 0
                            ) {
                                if (
                                    formData.RepairItemDetails[i].Summary !=
                                        "" &&
                                    formData.RepairItemDetails[i].Summary !=
                                        null &&
                                    formData.RepairItemDetails[i].Summary !=
                                        undefined
                                ) {
                                    filledRepairItem.push(
                                        formData.RepairItemDetails[i]
                                    );
                                } else {
                                    this.sessionService.notifier.notify(
                                        "error",
                                        "Please enter Item summary."
                                    );
                                    this.loading = false;
                                    return;
                                }
                            } else {
                                this.sessionService.notifier.notify(
                                    "error",
                                    "Please enter numberic value in Material (Quantity & Unit Cost) and Labor (Quantity & Unit Cost). Value should be greater than 0."
                                );
                                this.loading = false;
                                return;
                            }
                        } else {
                            if (
                                !isNaN(
                                    parseInt(
                                        formData.RepairItemDetails[i]
                                            .MaterialQuantity
                                    )
                                ) &&
                                parseInt(
                                    formData.RepairItemDetails[i]
                                        .MaterialQuantity
                                ) > 0 &&
                                !isNaN(
                                    parseInt(
                                        formData.RepairItemDetails[i]
                                            .LaborQuantity
                                    )
                                ) &&
                                parseInt(
                                    formData.RepairItemDetails[i].LaborQuantity
                                ) > 0 &&
                                !isNaN(
                                    formData.RepairItemDetails[i]
                                        .MaterialUnitCost
                                ) &&
                                !isNaN(
                                    formData.RepairItemDetails[i].LaborUnitCost
                                )
                            ) {
                                if (
                                    formData.RepairItemDetails[i].Summary !=
                                        "" &&
                                    formData.RepairItemDetails[i].Summary !=
                                        null &&
                                    formData.RepairItemDetails[i].Summary !=
                                        undefined
                                ) {
                                    filledRepairItem.push(
                                        formData.RepairItemDetails[i]
                                    );
                                } else {
                                    this.sessionService.notifier.notify(
                                        "error",
                                        "Please enter Item summary."
                                    );
                                    this.loading = false;
                                    return;
                                }
                            } else {
                                this.sessionService.notifier.notify(
                                    "error",
                                    "Please enter numberic value in Material (Quantity & Unit Cost) and Labor (Quantity & Unit Cost). Value should be greater than 0."
                                );
                                this.loading = false;
                                return;
                            }
                        }
                    }
                }
                if (
                    filledRepairItem.length > 0 &&
                    filledRepairItem.length == formData.RepairItemDetails.length
                ) {
                    var data = {
                        RepairType: formData.SendRepairType,
                        RepairItemDetails: filledRepairItem,
                    };
                    propertyData.Constructions.push(data);
                } else {
                    this.sessionService.notifier.notify(
                        "error",
                        "Please fill all the information of (Repair Item Name, Location, Level, Unit, Unit Quantity, Unit Cost etc )."
                    );
                    this.loading = false;
                    return;
                }

                var anyFilled = "no";
                for (let i = 0; i < propertyData.Constructions.length; i++) {
                    let filled = [];
                    for (
                        let j = 0;
                        j <
                        propertyData.Constructions[i].RepairItemDetails.length;
                        j++
                    ) {
                        if (
                            propertyData.Constructions[i].RepairItemDetails[j]
                                .RepairItemName != "" &&
                            propertyData.Constructions[i].RepairItemDetails[0]
                                .RepairItemName != null &&
                            propertyData.Constructions[i].RepairItemDetails[0]
                                .Location != "" &&
                            propertyData.Constructions[i].RepairItemDetails[0]
                                .Location != null &&
                            propertyData.Constructions[i].RepairItemDetails[0]
                                .Level != "" &&
                            propertyData.Constructions[i].RepairItemDetails[0]
                                .Level != null &&
                            propertyData.Constructions[i].RepairItemDetails[0]
                                .MaterialUnit != "" &&
                            propertyData.Constructions[i].RepairItemDetails[0]
                                .MaterialUnit != null &&
                            propertyData.Constructions[i].RepairItemDetails[0]
                                .MaterialQuantity != "" &&
                            propertyData.Constructions[i].RepairItemDetails[0]
                                .MaterialQuantity != null &&
                            propertyData.Constructions[i].RepairItemDetails[0]
                                .MaterialUnitCost != "" &&
                            propertyData.Constructions[i].RepairItemDetails[0]
                                .MaterialUnitCost != null &&
                            propertyData.Constructions[i].RepairItemDetails[0]
                                .LaborUnit != "" &&
                            propertyData.Constructions[i].RepairItemDetails[0]
                                .LaborUnit != null &&
                            propertyData.Constructions[i].RepairItemDetails[0]
                                .LaborQuantity != "" &&
                            propertyData.Constructions[i].RepairItemDetails[0]
                                .LaborQuantity != null &&
                            propertyData.Constructions[i].RepairItemDetails[0]
                                .LaborUnitCost != "" &&
                            propertyData.Constructions[i].RepairItemDetails[0]
                                .LaborUnitCost != null
                        ) {
                            filled.push("yes");
                        }
                    }
                    if (
                        filled.length ==
                        propertyData.Constructions[i].RepairItemDetails.length
                    ) {
                        anyFilled = "yes";
                    }
                }

                propertyData.PermitCost = this.permitCostonReset;

                propertyData.GeneralContractorId = this.userDetail.Id;

                propertyData.ConsultantsComment = formData.ConsultantsComment;

                if (anyFilled != "yes") {
                    this.sessionService.notifier.notify(
                        "error",
                        "Please fill all the information of (Repair Item Name, Location, Level, Unit, Unit Quantity, Unit Cost etc )."
                    );
                    this.loading = false;
                    return;
                }
                this._bservice.AddSORForGC(propertyData).subscribe(
                    (response) => {
                        this.sessionService.notifier.notify(
                            "success",
                            "Repair on Bid added successfully."
                        );

                        this.isList = true;
                        this.isAdd = false;
                        this.permitCostonReset = [];

                        this.getRepairItem(this.RepairPropertyId);
                        this.UpdateRepair(nextItemType, nextItemName, type);
                        if (nexttabIndex) {
                            this.selectedIndex = nexttabIndex;
                        }

                        this.loading = false;
                    },
                    (error) => {
                        this.sessionService.notifier.notify(
                            "error",
                            error.error.message
                        );
                        this.loading = false;
                    }
                );
            }
        }
    }

    filterReportComponents(current) {
        let searchValue = current.target.value;
        if (searchValue.length < 3) {
            return false;
        }

        this.loading = true;

        let tempList = [];
        this.searchResult = [];
        if (
            searchValue != "" &&
            searchValue != null &&
            searchValue != undefined
        ) {
            let OldAllVal = this.repairDetailsList;
            for (let i = 0; i < OldAllVal.length; i++) {
                for (
                    let j = 0;
                    j < OldAllVal[i].RepairItemDetails.length;
                    j++
                ) {
                    var indx = OldAllVal[i].RepairItemDetails[
                        j
                    ].RepairItemName.toLowerCase().indexOf(
                        searchValue.toLowerCase()
                    );
                    if (indx > -1) {
                        let temp = {
                            RepairType: OldAllVal[i].RepairType,
                            RepairDetails: OldAllVal[i].RepairItemDetails[j],
                        };
                        tempList.push(temp);
                    }
                }
            }

            let resultType = [];
            for (let l = 0; l < tempList.length; l++) {
                var indxx = resultType.indexOf(tempList[l].RepairType.trim());
                if (indxx > -1) {
                    for (let k = 0; k < this.searchResult.length; k++) {
                        if (
                            tempList[l].RepairType.trim().toLowerCase() ==
                            this.searchResult[k].RepairType.trim().toLowerCase()
                        ) {
                            this.searchResult[k].RepairItemDetails.push(
                                tempList[l].RepairDetails
                            );
                        }
                    }
                } else {
                    let tempRepairDetails = [];
                    tempRepairDetails.push(tempList[l].RepairDetails);
                    let temp = {
                        RepairType: tempList[l].RepairType,
                        RepairItemDetails: tempRepairDetails,
                    };
                    this.searchResult.push(temp);
                    resultType.push(tempList[l].RepairType.trim());
                }
            }

            this.loading = false;
        } else {
            tempList = [];
            this.searchResult = [];

            this.loading = false;
        }
    }

    addItemToAdditional(type) {
        this.isAdd = true;
        const control = <FormArray>(
            this.RepairItemForm.controls["RepairItemDetails"]
        );

        for (let j = control.length; j >= 0; j--) {
            control.removeAt(j);
            if (j == 0) {
                control.push(this.initAdditionalRepairItem());
            }
        }

        this.RepairItemForm.controls["SendRepairType"].setValue(type);
        this.RepairItemForm.controls["SendRepairType"].disable();
    }
    backFromAddItemSection() {
        this.isAdd = false;
    }
    SaveAdditionalConstructionItem(anydes = "") {
        this.submitted = true;
        if (this.RepairItemForm.invalid) {
            this.sessionService.notifier.notify(
                "error",
                "Something went wrong."
            );
            this.loading = false;
            return;
        } else {
            this.loading = true;
            this.submitted = false;
            this.propertyRepairConstructionDetail = "";
            var formData = this.RepairItemForm.getRawValue();

            if (this.GCBidData != null && this.GCBidData != "") {
                this.propertyRepairConstructionDetail = this.GCBidData;
            } else {
                this.propertyRepairConstructionDetail = {
                    PropertyId: null,
                    GeneralContractorId: null,
                    PermitCost: [],
                    Constructions: [],
                    Status: "Pending",
                    ConsultantsComment: "",
                    AllowableFees: "AllowableFees",
                    GCSignature: "GCSignature",
                    DateSign: "DateSign",
                    Print1: "Print1",
                    Print2: "Print2",
                    ReviseFlag: "ReviseFlag",
                    ReviseComment: "ReviseComment",
                    GCRejected: "GCRejected",
                    ParticipateOn: "ParticipateOn",
                    CreatedBy: "CreatedBy",
                    CreatedDate: "CreatedDate",
                    UpdateDate: "UpdateDate",
                    UpdatedBy: "UpdatedBy",
                };
            }

            if (this.propertyRepairConstructionDetail.Id) {
                var propertyData = this.propertyRepairConstructionDetail;
                let filledRepairItem = [];

                for (let i = 0; i < formData.RepairItemDetails.length; i++) {
                    if (
                        formData.RepairItemDetails[i].RepairItemName != "" &&
                        formData.RepairItemDetails[i].RepairItemName != null &&
                        formData.RepairItemDetails[i].Location != "" &&
                        formData.RepairItemDetails[i].Location != null &&
                        formData.RepairItemDetails[i].Level != "" &&
                        formData.RepairItemDetails[i].Level != null &&
                        formData.RepairItemDetails[i].MaterialUnit != "" &&
                        formData.RepairItemDetails[i].MaterialUnit != null &&
                        formData.RepairItemDetails[i].MaterialQuantity != "" &&
                        formData.RepairItemDetails[i].MaterialQuantity !=
                            null &&
                        formData.RepairItemDetails[i].MaterialUnitCost != "" &&
                        formData.RepairItemDetails[i].MaterialUnitCost !=
                            null &&
                        formData.RepairItemDetails[i].LaborUnit != "" &&
                        formData.RepairItemDetails[i].LaborUnit != null &&
                        formData.RepairItemDetails[i].LaborQuantity != "" &&
                        formData.RepairItemDetails[i].LaborQuantity != null &&
                        formData.RepairItemDetails[i].LaborUnitCost != "" &&
                        formData.RepairItemDetails[i].LaborUnitCost != null
                    ) {
                        if (formData.RepairItemDetails[i].Level == "M") {
                            if (
                                !isNaN(
                                    parseInt(
                                        formData.RepairItemDetails[i]
                                            .MaterialQuantity
                                    )
                                ) &&
                                parseInt(
                                    formData.RepairItemDetails[i]
                                        .MaterialQuantity
                                ) > 0 &&
                                !isNaN(
                                    parseInt(
                                        formData.RepairItemDetails[i]
                                            .LaborQuantity
                                    )
                                ) &&
                                parseInt(
                                    formData.RepairItemDetails[i].LaborQuantity
                                ) > 0 &&
                                !isNaN(
                                    formData.RepairItemDetails[i]
                                        .MaterialUnitCost
                                ) &&
                                !isNaN(
                                    formData.RepairItemDetails[i].LaborUnitCost
                                ) &&
                                parseInt(
                                    formData.RepairItemDetails[i]
                                        .MaterialUnitCost
                                ) > 0 &&
                                parseInt(
                                    formData.RepairItemDetails[i].LaborUnitCost
                                ) > 0
                            ) {
                                if (
                                    formData.RepairItemDetails[i].Summary !=
                                        "" &&
                                    formData.RepairItemDetails[i].Summary !=
                                        null &&
                                    formData.RepairItemDetails[i].Summary !=
                                        undefined
                                ) {
                                    formData.RepairItemDetails[i].isAdditional =
                                        true;
                                    filledRepairItem.push(
                                        formData.RepairItemDetails[i]
                                    );
                                } else {
                                    this.sessionService.notifier.notify(
                                        "error",
                                        "Please enter Item summary."
                                    );
                                    this.loading = false;
                                    return;
                                }
                            } else {
                                this.sessionService.notifier.notify(
                                    "error",
                                    "Please enter numberic value in Material (Quantity & Unit Cost) and Labor (Quantity & Unit Cost). Value should be greater than 0."
                                );
                                this.loading = false;
                                return;
                            }
                        } else {
                            if (
                                !isNaN(
                                    parseInt(
                                        formData.RepairItemDetails[i]
                                            .MaterialQuantity
                                    )
                                ) &&
                                parseInt(
                                    formData.RepairItemDetails[i]
                                        .MaterialQuantity
                                ) > 0 &&
                                !isNaN(
                                    parseInt(
                                        formData.RepairItemDetails[i]
                                            .LaborQuantity
                                    )
                                ) &&
                                parseInt(
                                    formData.RepairItemDetails[i].LaborQuantity
                                ) > 0 &&
                                !isNaN(
                                    formData.RepairItemDetails[i]
                                        .MaterialUnitCost
                                ) &&
                                !isNaN(
                                    formData.RepairItemDetails[i].LaborUnitCost
                                )
                            ) {
                                if (
                                    formData.RepairItemDetails[i].Summary !=
                                        "" &&
                                    formData.RepairItemDetails[i].Summary !=
                                        null &&
                                    formData.RepairItemDetails[i].Summary !=
                                        undefined
                                ) {
                                    formData.RepairItemDetails[i].isAdditional =
                                        true;
                                    filledRepairItem.push(
                                        formData.RepairItemDetails[i]
                                    );
                                } else {
                                    this.sessionService.notifier.notify(
                                        "error",
                                        "Please enter Item summary."
                                    );
                                    this.loading = false;
                                    return;
                                }
                            } else {
                                this.sessionService.notifier.notify(
                                    "error",
                                    "Please enter numberic value in Material (Quantity & Unit Cost) and Labor (Quantity & Unit Cost). Value should be greater than 0."
                                );
                                this.loading = false;
                                return;
                            }
                        }
                    } else {
                        this.sessionService.notifier.notify(
                            "error",
                            "Please fill all the information of (Repair Item Name, Location, Level, Unit, Unit Quantity, Unit Cost etc )."
                        );
                        this.loading = false;
                        return;
                    }
                }

                if (
                    filledRepairItem.length > 0 &&
                    filledRepairItem.length == formData.RepairItemDetails.length
                ) {
                    var data = {
                        RepairType: formData.SendRepairType,
                        RepairItemDetails: filledRepairItem,
                    };

                    this.checkConstructionsDetails = false;
                    this.checkRepairItem = false;
                    if (propertyData.Constructions.length > 0) {
                        for (
                            let i = 0;
                            i < propertyData.Constructions.length;
                            i++
                        ) {
                            if (
                                propertyData.Constructions[i].RepairType ==
                                formData.SendRepairType
                            ) {
                                let propertyDataLength =
                                    propertyData.Constructions[i]
                                        .RepairItemDetails.length;

                                for (let l = 0; l < propertyDataLength; l++) {
                                    for (
                                        let k = 0;
                                        k < formData.RepairItemDetails.length;
                                        k++
                                    ) {
                                        if (
                                            propertyData.Constructions[i]
                                                .RepairItemDetails[l]
                                                .RepairItemName ==
                                                formData.RepairItemDetails[k]
                                                    .RepairItemName &&
                                            propertyData.Constructions[i]
                                                .RepairItemDetails[l].Level ==
                                                formData.RepairItemDetails[k]
                                                    .Level &&
                                            propertyData.Constructions[i]
                                                .RepairItemDetails[l]
                                                .Location ==
                                                formData.RepairItemDetails[k]
                                                    .Location
                                        ) {
                                            propertyData.Constructions[
                                                i
                                            ].RepairItemDetails[l] =
                                                formData.RepairItemDetails[k];
                                            this.checkRepairItem = true;
                                        }
                                    }
                                }
                                if (this.checkRepairItem == false) {
                                    for (
                                        let k1 = 0;
                                        k1 < formData.RepairItemDetails.length;
                                        k1++
                                    ) {
                                        propertyData.Constructions[
                                            i
                                        ].RepairItemDetails.push(
                                            formData.RepairItemDetails[k1]
                                        );
                                    }
                                }

                                this.checkConstructionsDetails = true;
                            }
                        }
                    }
                    if (this.checkConstructionsDetails == false) {
                        propertyData.Constructions.push(data);
                    }
                } else if (anydes) {
                } else {
                    this.sessionService.notifier.notify(
                        "error",
                        "Please fill all the information of (Repair Item Name, Location, Level, Unit, Unit Quantity, Unit Cost etc )."
                    );
                    this.loading = false;
                    return;
                }

                propertyData.PropertyId = this.RepairPropertyId;

                propertyData.GeneralContractorId = this.userDetail.Id;

                propertyData.ConsultantsComment = formData.ConsultantsComment;

                propertyData.propertyStatus = "";

                this._bservice.UpdateSORForGC(propertyData).subscribe(
                    (response) => {
                        this.sessionService.notifier.notify(
                            "success",
                            "Repair on Bid updated successfully."
                        );

                        this.isList = true;
                        this.isAdd = false;

                        this.permitCostonReset = [];
                        this.getRepairItem(this.RepairPropertyId);

                        this.loading = false;
                    },
                    (error) => {
                        this.sessionService.notifier.notify(
                            "error",
                            error.error.message
                        );
                        this.loading = false;
                    }
                );
            } else {
                let filledRepairItem = [];
                var propertyData = this.propertyRepairConstructionDetail;
                propertyData.PropertyId = this.RepairPropertyId;
                for (let i = 0; i < formData.RepairItemDetails.length; i++) {
                    if (
                        formData.RepairItemDetails[i].RepairItemName != "" &&
                        formData.RepairItemDetails[i].RepairItemName != null &&
                        formData.RepairItemDetails[i].Location != "" &&
                        formData.RepairItemDetails[i].Location != null &&
                        formData.RepairItemDetails[i].Level != "" &&
                        formData.RepairItemDetails[i].Level != null &&
                        formData.RepairItemDetails[i].MaterialUnit != "" &&
                        formData.RepairItemDetails[i].MaterialUnit != null &&
                        formData.RepairItemDetails[i].MaterialQuantity != "" &&
                        formData.RepairItemDetails[i].MaterialQuantity !=
                            null &&
                        formData.RepairItemDetails[i].MaterialUnitCost != "" &&
                        formData.RepairItemDetails[i].MaterialUnitCost !=
                            null &&
                        formData.RepairItemDetails[i].LaborUnit != "" &&
                        formData.RepairItemDetails[i].LaborUnit != null &&
                        formData.RepairItemDetails[i].LaborQuantity != "" &&
                        formData.RepairItemDetails[i].LaborQuantity != null &&
                        formData.RepairItemDetails[i].LaborUnitCost != "" &&
                        formData.RepairItemDetails[i].LaborUnitCost != null
                    ) {
                        if (formData.RepairItemDetails[i].Level == "M") {
                            if (
                                !isNaN(
                                    parseInt(
                                        formData.RepairItemDetails[i]
                                            .MaterialQuantity
                                    )
                                ) &&
                                parseInt(
                                    formData.RepairItemDetails[i]
                                        .MaterialQuantity
                                ) > 0 &&
                                !isNaN(
                                    parseInt(
                                        formData.RepairItemDetails[i]
                                            .LaborQuantity
                                    )
                                ) &&
                                parseInt(
                                    formData.RepairItemDetails[i].LaborQuantity
                                ) > 0 &&
                                !isNaN(
                                    formData.RepairItemDetails[i]
                                        .MaterialUnitCost
                                ) &&
                                !isNaN(
                                    formData.RepairItemDetails[i].LaborUnitCost
                                ) &&
                                parseInt(
                                    formData.RepairItemDetails[i]
                                        .MaterialUnitCost
                                ) > 0 &&
                                parseInt(
                                    formData.RepairItemDetails[i].LaborUnitCost
                                ) > 0
                            ) {
                                if (
                                    formData.RepairItemDetails[i].Summary !=
                                        "" &&
                                    formData.RepairItemDetails[i].Summary !=
                                        null &&
                                    formData.RepairItemDetails[i].Summary !=
                                        undefined
                                ) {
                                    formData.RepairItemDetails[i].isAdditional =
                                        true;
                                    filledRepairItem.push(
                                        formData.RepairItemDetails[i]
                                    );
                                } else {
                                    this.sessionService.notifier.notify(
                                        "error",
                                        "Please enter Item summary."
                                    );
                                    this.loading = false;
                                    return;
                                }
                            } else {
                                this.sessionService.notifier.notify(
                                    "error",
                                    "Please enter numberic value in Material (Quantity & Unit Cost) and Labor (Quantity & Unit Cost). Value should be greater than 0."
                                );
                                this.loading = false;
                                return;
                            }
                        } else {
                            if (
                                !isNaN(
                                    parseInt(
                                        formData.RepairItemDetails[i]
                                            .MaterialQuantity
                                    )
                                ) &&
                                parseInt(
                                    formData.RepairItemDetails[i]
                                        .MaterialQuantity
                                ) > 0 &&
                                !isNaN(
                                    parseInt(
                                        formData.RepairItemDetails[i]
                                            .LaborQuantity
                                    )
                                ) &&
                                parseInt(
                                    formData.RepairItemDetails[i].LaborQuantity
                                ) > 0 &&
                                !isNaN(
                                    formData.RepairItemDetails[i]
                                        .MaterialUnitCost
                                ) &&
                                !isNaN(
                                    formData.RepairItemDetails[i].LaborUnitCost
                                )
                            ) {
                                if (
                                    formData.RepairItemDetails[i].Summary !=
                                        "" &&
                                    formData.RepairItemDetails[i].Summary !=
                                        null &&
                                    formData.RepairItemDetails[i].Summary !=
                                        undefined
                                ) {
                                    formData.RepairItemDetails[i].isAdditional =
                                        true;
                                    filledRepairItem.push(
                                        formData.RepairItemDetails[i]
                                    );
                                } else {
                                    this.sessionService.notifier.notify(
                                        "error",
                                        "Please enter Item summary."
                                    );
                                    this.loading = false;
                                    return;
                                }
                            } else {
                                this.sessionService.notifier.notify(
                                    "error",
                                    "Please enter numberic value in Material (Quantity & Unit Cost) and Labor (Quantity & Unit Cost). Value should be greater than 0."
                                );
                                this.loading = false;
                                return;
                            }
                        }
                    } else {
                        this.sessionService.notifier.notify(
                            "error",
                            "Please fill all the information of (Repair Item Name, Location, Level, Unit, Unit Quantity, Unit Cost etc )."
                        );
                        this.loading = false;
                        return;
                    }
                }
                if (
                    filledRepairItem.length > 0 &&
                    filledRepairItem.length == formData.RepairItemDetails.length
                ) {
                    var data = {
                        RepairType: formData.SendRepairType,
                        RepairItemDetails: filledRepairItem,
                    };
                    propertyData.Constructions.push(data);
                } else if (anydes) {
                } else {
                    this.sessionService.notifier.notify(
                        "error",
                        "Please fill all the information of (Repair Item Name, Location, Level, Unit, Unit Quantity, Unit Cost etc )."
                    );
                    this.loading = false;
                    return;
                }

                var anyFilled = "no";
                for (let i = 0; i < propertyData.Constructions.length; i++) {
                    let filled = [];
                    for (
                        let j = 0;
                        j <
                        propertyData.Constructions[i].RepairItemDetails.length;
                        j++
                    ) {
                        if (
                            propertyData.Constructions[i].RepairItemDetails[j]
                                .RepairItemName != "" &&
                            propertyData.Constructions[i].RepairItemDetails[0]
                                .RepairItemName != null &&
                            propertyData.Constructions[i].RepairItemDetails[0]
                                .Location != "" &&
                            propertyData.Constructions[i].RepairItemDetails[0]
                                .Location != null &&
                            propertyData.Constructions[i].RepairItemDetails[0]
                                .Level != "" &&
                            propertyData.Constructions[i].RepairItemDetails[0]
                                .Level != null &&
                            propertyData.Constructions[i].RepairItemDetails[0]
                                .MaterialUnit != "" &&
                            propertyData.Constructions[i].RepairItemDetails[0]
                                .MaterialUnit != null &&
                            propertyData.Constructions[i].RepairItemDetails[0]
                                .MaterialQuantity != "" &&
                            propertyData.Constructions[i].RepairItemDetails[0]
                                .MaterialQuantity != null &&
                            propertyData.Constructions[i].RepairItemDetails[0]
                                .MaterialUnitCost != "" &&
                            propertyData.Constructions[i].RepairItemDetails[0]
                                .MaterialUnitCost != null &&
                            propertyData.Constructions[i].RepairItemDetails[0]
                                .LaborUnit != "" &&
                            propertyData.Constructions[i].RepairItemDetails[0]
                                .LaborUnit != null &&
                            propertyData.Constructions[i].RepairItemDetails[0]
                                .LaborQuantity != "" &&
                            propertyData.Constructions[i].RepairItemDetails[0]
                                .LaborQuantity != null &&
                            propertyData.Constructions[i].RepairItemDetails[0]
                                .LaborUnitCost != "" &&
                            propertyData.Constructions[i].RepairItemDetails[0]
                                .LaborUnitCost != null
                        ) {
                            filled.push("yes");
                        }
                    }
                    if (
                        filled.length ==
                        propertyData.Constructions[i].RepairItemDetails.length
                    ) {
                        anyFilled = "yes";
                    }
                }

                propertyData.PermitCost = this.permitCostonReset;

                propertyData.GeneralContractorId = this.userDetail.Id;

                propertyData.ConsultantsComment = formData.ConsultantsComment;

                if (anyFilled != "yes") {
                    this.sessionService.notifier.notify(
                        "error",
                        "Please fill all the information of (Repair Item Name, Location, Level, Unit, Unit Quantity, Unit Cost etc )."
                    );
                    this.loading = false;
                    return;
                }
                this._bservice.AddSORForGC(propertyData).subscribe(
                    (response) => {
                        this.sessionService.notifier.notify(
                            "success",
                            "Repair on Bid added successfully."
                        );

                        this.isList = true;
                        this.isAdd = false;
                        this.permitCostonReset = [];

                        this.getRepairItem(this.RepairPropertyId);

                        this.loading = false;
                    },
                    (error) => {
                        this.sessionService.notifier.notify(
                            "error",
                            error.error.message
                        );
                        this.loading = false;
                    }
                );
            }
        }
    }
    submitInputBid() {
        this.submitted = true;
        if (this.RepairItemForm.invalid) {
            return;
        } else {
            var oldSor = this.getRepairConstructionList[0].Constructions;

            this.propertyRepairConstructionDetail = "";
            var formData = this.RepairItemForm.getRawValue();
            if (oldSor.length > 0) {
                for (let i = 0; i < oldSor.length; i++) {
                    for (
                        let k = 0;
                        k < oldSor[i].RepairItemDetails.length;
                        k++
                    ) {
                        if (oldSor[i].RepairItemDetails[k].Level == "M") {
                            var checkRepairType = true;
                            if (
                                this.GCBidData != null &&
                                this.GCBidData != ""
                            ) {
                                if (this.GCBidData.Constructions.length > 0) {
                                    for (
                                        let j = 0;
                                        j < this.GCBidData.Constructions.length;
                                        j++
                                    ) {
                                        if (
                                            this.GCBidData.Constructions[j]
                                                .RepairType ==
                                            oldSor[i].RepairType
                                        ) {
                                            checkRepairType = false;
                                            var CheckRepairLevel = true;
                                            for (
                                                let m = 0;
                                                m <
                                                this.GCBidData.Constructions[j]
                                                    .RepairItemDetails.length;
                                                m++
                                            ) {
                                                if (
                                                    this.GCBidData
                                                        .Constructions[j]
                                                        .RepairItemDetails[m]
                                                        .Level == "M"
                                                ) {
                                                    CheckRepairLevel = false;
                                                }
                                            }
                                            if (CheckRepairLevel) {
                                                this.sessionService.notifier.notify(
                                                    "error",
                                                    'Please fill ("' +
                                                        oldSor[i].RepairType +
                                                        '") construction components mandatory items.'
                                                );
                                                return;
                                            }
                                        }
                                    }
                                } else {
                                    this.sessionService.notifier.notify(
                                        "error",
                                        'Please fill ("' +
                                            oldSor[i].RepairType +
                                            '") construction components mandatory items.'
                                    );
                                    return;
                                }
                            } else {
                                this.sessionService.notifier.notify(
                                    "error",
                                    'Please fill ("' +
                                        oldSor[i].RepairType +
                                        '") construction components mandatory items.'
                                );
                                return;
                            }
                            if (checkRepairType) {
                                this.sessionService.notifier.notify(
                                    "error",
                                    'Please fill ("' +
                                        oldSor[i].RepairType +
                                        '") construction components mandatory items.'
                                );
                                return;
                            }
                        } else {
                            var checkRepairType = true;

                            if (
                                this.GCBidData != null &&
                                this.GCBidData != ""
                            ) {
                                if (this.GCBidData.Constructions.length > 0) {
                                    for (
                                        let j = 0;
                                        j < this.GCBidData.Constructions.length;
                                        j++
                                    ) {
                                        if (
                                            this.GCBidData.Constructions[j]
                                                .RepairType ==
                                            oldSor[i].RepairType
                                        ) {
                                            checkRepairType = false;
                                            var CheckRepairLevel = true;
                                            for (
                                                let m = 0;
                                                m <
                                                this.GCBidData.Constructions[j]
                                                    .RepairItemDetails.length;
                                                m++
                                            ) {
                                                if (
                                                    this.GCBidData
                                                        .Constructions[j]
                                                        .RepairItemDetails[m]
                                                        .Level ==
                                                    oldSor[i].RepairItemDetails[
                                                        k
                                                    ].Level
                                                ) {
                                                    CheckRepairLevel = false;
                                                }
                                            }
                                            if (CheckRepairLevel) {
                                                this.sessionService.notifier.notify(
                                                    "error",
                                                    'You have not entered estimates for ("' +
                                                        oldSor[i].RepairType +
                                                        '") items recognized by consultant. If non mandatory item is no longer desired by borrower then please enter "0" for unit cost.'
                                                );
                                                return;
                                            }
                                        }
                                    }
                                } else {
                                    this.sessionService.notifier.notify(
                                        "error",
                                        'You have not entered estimates for ("' +
                                            oldSor[i].RepairType +
                                            '") items recognized by consultant. If non mandatory item is no longer desired by borrower then please enter "0" for unit cost.'
                                    );
                                    return;
                                }
                            } else {
                                this.sessionService.notifier.notify(
                                    "error",
                                    'You have not entered estimates for ("' +
                                        oldSor[i].RepairType +
                                        '") items recognized by consultant. If non mandatory item is no longer desired by borrower then please enter "0" for unit cost.'
                                );
                                return;
                            }
                            if (checkRepairType) {
                                this.sessionService.notifier.notify(
                                    "error",
                                    'You have not entered estimates for ("' +
                                        oldSor[i].RepairType +
                                        '") items recognized by consultant. If non mandatory item is no longer desired by borrower then please enter "0" for unit cost.'
                                );
                                return;
                            }
                        }
                    }
                }
            }

            var r = confirm(
                "Once you submit this form, you won't be able to go back and make changes"
            );

            if (r == true) {
                this.loading = true;
                this.submitted = false;
                if (this.GCBidData != null && this.GCBidData != "") {
                    this.GCBidData.ReviseComment = "ReviseComment";
                    this.GCBidData.ReviseFlag = "ReviseFlag";
                    this.propertyRepairConstructionDetail = this.GCBidData;
                } else {
                    this.propertyRepairConstructionDetail = {
                        PropertyId: null,
                        GeneralContractorId: null,
                        PermitCost: [],
                        Constructions: [],
                        Status: "Pending",
                        ConsultantsComment: "",
                        AllowableFees: "AllowableFees",
                        GCSignature: "GCSignature",
                        DateSign: "DateSign",
                        Print1: "Print1",
                        Print2: "Print2",
                        ReviseFlag: "ReviseFlag",
                        ReviseComment: "ReviseComment",
                        GCRejected: "GCRejected",
                        ParticipateOn: "ParticipateOn",
                        CreatedBy: "CreatedBy",
                        CreatedDate: "CreatedDate",
                        UpdateDate: "UpdateDate",
                        UpdatedBy: "UpdatedBy",
                    };
                }

                if (this.propertyRepairConstructionDetail.Id) {
                    var propertyData = this.propertyRepairConstructionDetail;

                    propertyData.PropertyId = this.RepairPropertyId;
                    propertyData.GeneralContractorId = this.userDetail.Id;

                    propertyData.ConsultantsComment =
                        formData.ConsultantsComment;

                    propertyData.Status = "Send For Review";
                    if (propertyData.ReviseFlag == true) {
                        propertyData.propertyStatus = "send revise bid";
                    } else {
                        propertyData.propertyStatus = "send for review";
                    }

                    propertyData.PermitCost = formData.PermitCost;

                    this._bservice.UpdateSORForGC(propertyData).subscribe(
                        (response) => {
                            this.propertyService
                                .GetPropertyDetails(this.RepairPropertyId)
                                .subscribe(
                                    (presponse) => {
                                        if (presponse != null) {
                                            if (
                                                presponse.SubmittedBids ==
                                                    undefined ||
                                                presponse.SubmittedBids == "" ||
                                                presponse.SubmittedBids == null
                                            ) {
                                                presponse.SubmittedBids = [];
                                                presponse.SubmittedBids.push(
                                                    this.userDetail.Id
                                                );
                                            } else {
                                                let alreadyExistBid = false;
                                                for (
                                                    let i = 0;
                                                    i <
                                                    presponse.SubmittedBids
                                                        .length;
                                                    i++
                                                ) {
                                                    if (
                                                        presponse.SubmittedBids[
                                                            i
                                                        ] == this.userDetail.Id
                                                    ) {
                                                        alreadyExistBid = true;
                                                        break;
                                                    }
                                                }
                                                if (alreadyExistBid == false) {
                                                    presponse.SubmittedBids.push(
                                                        this.userDetail.Id
                                                    );
                                                }
                                            }
                                            presponse.Status =
                                                "Bid Process Started";
                                            this.propertyService
                                                .UpdatePropertyBidStatus(
                                                    presponse
                                                )
                                                .subscribe(
                                                    (response) => {
                                                        this.sessionService.notifier.notify(
                                                            "success",
                                                            "Repair on Bid submited successfully."
                                                        );
                                                        var data = {
                                                            Id: this
                                                                .RepairPropertyId,
                                                            BorrowerActiveStep: 5,
                                                        };
                                                        this.propertyService
                                                            .saveBorrowerActiveStep(
                                                                data
                                                            )
                                                            .subscribe(
                                                                (response) => {
                                                                    this.isList =
                                                                        true;
                                                                    this.isAdd =
                                                                        false;

                                                                    this.permitCostonReset =
                                                                        [];
                                                                    this.getRepairItem(
                                                                        this
                                                                            .RepairPropertyId
                                                                    );
                                                                    this.router.navigate(
                                                                        [
                                                                            "/properties",
                                                                        ]
                                                                    );
                                                                    this.loading =
                                                                        false;
                                                                }
                                                            );
                                                    },
                                                    (error) => {
                                                        this.sessionService.notifier.notify(
                                                            "error",
                                                            error.error.message
                                                        );
                                                        this.loading = false;
                                                    }
                                                );
                                        }
                                    },
                                    (error) => {
                                        this.sessionService.notifier.notify(
                                            "error",
                                            error.error.message
                                        );
                                        this.loading = false;
                                    }
                                );
                        },
                        (error) => {
                            this.sessionService.notifier.notify(
                                "error",
                                error.error.message
                            );
                            this.loading = false;
                        }
                    );
                } else {
                    var propertyData = this.propertyRepairConstructionDetail;
                    propertyData.PropertyId = this.RepairPropertyId;
                    propertyData.PermitCost = formData.PermitCost;

                    if (
                        this.propertyRepairConstructionDetail.Constructions
                            .length < 1
                    ) {
                        this.sessionService.notifier.notify(
                            "error",
                            "Please fill any items."
                        );
                        this.loading = false;
                        return;
                    }

                    propertyData.GeneralContractorId = this.userDetail.Id;

                    propertyData.ConsultantsComment =
                        formData.ConsultantsComment;

                    propertyData.Status = "Send For Review";
                    this._bservice.AddSORForGC(propertyData).subscribe(
                        (response) => {
                            this.propertyService
                                .GetPropertyDetails(this.RepairPropertyId)
                                .subscribe(
                                    (presponse) => {
                                        if (presponse != null) {
                                            this.propertyService
                                                .UpdatePropertyBidStatus(
                                                    presponse
                                                )
                                                .subscribe(
                                                    (response) => {
                                                        this.sessionService.notifier.notify(
                                                            "success",
                                                            "Repair on Bid submited successfully."
                                                        );
                                                        this.isList = true;
                                                        this.isAdd = false;

                                                        this.permitCostonReset =
                                                            [];
                                                        this.getRepairItem(
                                                            this
                                                                .RepairPropertyId
                                                        );
                                                        this.router.navigate([
                                                            "/properties",
                                                        ]);
                                                        this.loading = false;
                                                    },
                                                    (error) => {
                                                        this.sessionService.notifier.notify(
                                                            "error",
                                                            error.error.message
                                                        );
                                                        this.loading = false;
                                                    }
                                                );
                                        }
                                    },
                                    (error) => {
                                        this.sessionService.notifier.notify(
                                            "error",
                                            error.error.message
                                        );
                                        this.loading = false;
                                    }
                                );
                        },
                        (error) => {
                            this.sessionService.notifier.notify(
                                "error",
                                error.error.message
                            );
                            this.loading = false;
                        }
                    );
                }
            } else {
                return;
            }
        }
    }

    nextPage(event: PageEvent) {}

    getPermitCostTypeData() {
        this.formStatus = false;
        this.permitCostTypeData = [
            {
                PermitType: ["General Building Permit"],
                PermitCost: [""],
                Status: [false],
            },
            {
                PermitType: ["HVAC"],
                PermitCost: [""],
                Status: [false],
            },
            {
                PermitType: ["Roofing"],
                PermitCost: [""],
                Status: [false],
            },
            {
                PermitType: ["Electrical"],
                PermitCost: [""],
                Status: [false],
            },
            {
                PermitType: ["Plumbing"],
                PermitCost: [""],
                Status: [false],
            },
            {
                PermitType: ["Other"],
                PermitCost: [""],
                Status: [false],
            },
        ];
        const control = <FormArray>this.RepairItemForm.controls["PermitCost"];

        for (let i = control.length - 1; i >= 0; i--) {
            control.removeAt(i);
        }

        if (this.permitCostonReset.length > 0) {
            for (let i = 0; i < this.permitCostonReset.length; i++) {
                control.push(
                    this._fb.group({
                        PermitType: [this.permitCostonReset[i].PermitType],
                        PermitCost: [this.permitCostonReset[i].PermitCost],
                        Status: [this.permitCostonReset[i].Status],
                    })
                );
                this.formStatus = true;
            }
        } else {
            for (let j = 0; j < this.permitCostTypeData.length; j++) {
                control.push(this._fb.group(this.permitCostTypeData[j]));
                this.formStatus = true;
            }
        }
    }

    initAllowableFeesItem(data) {
        // initialize our address
        var status = true;
        if (data.Status != undefined) {
            status = data.Status;
        } else {
            status = false;
        }
        return this._fb.group({
            Id: [data.Id],
            Title: [data.Title],
            Notes: [data.Notes],
            Fees: [data.Fees],
            Status: [status],
        });
    }

    addAllowableFeesItems(data) {
        // add address to the list
        const control = <FormArray>(
            this.RepairItemForm.controls["AllowableFees"]
        );
        control.push(this.initAllowableFeesItem(data));
    }
    goNextItem(nextItemType, nextItemName, type) {
        let nexttabIndex = "";
        this.SaveConstructionItem(
            nexttabIndex,
            nextItemType,
            nextItemName,
            type
        );
    }
    saveAdditionalItem() {
        this.SaveAdditionalConstructionItem();
    }
    goNextTab(nexttabIndex, nextItemType = "", nextItemName = "", type = "") {
        this.SaveConstructionItem(
            nexttabIndex,
            nextItemType,
            nextItemName,
            type
        );
    }
    printCurrentBid() {
        var data = {
            PropertyId: parseInt(this.RepairPropertyId),
            GeneralContractorId: this.userDetail.Id,
        };

        this.propertyService.GetBORPDF(data).subscribe(
            (response) => {
                if (response) {
                    this.pdfdataShow = true;
                    this.pdfData =
                        this.sanitizer.bypassSecurityTrustResourceUrl(
                            this.baseurl + response
                        );

                    this.loading = false;
                }
            },
            (error) => {
                this.sessionService.notifier.notify(
                    "error",
                    error.error.message
                );
                this.loading = false;
            }
        );
    }
    goNextToAddTab(nexttabIndex, anydes = "") {
        this.selectedIndex = nexttabIndex;
        if (anydes) {
            this.SaveAdditionalConstructionItem(anydes);
        } else {
            this.SaveAdditionalConstructionItem();
        }
    }
    goPreviousTab(
        previoustabIndex,
        nextItemType = "",
        itemName = "",
        type = ""
    ) {
        this.UpdateRepair(nextItemType, itemName, type);
        this.selectedIndex = previoustabIndex;
    }
    GetBlankBORPdf(propertyId) {
        var data = {
            PropertyId: propertyId,
            GeneralContractorId: 0,
        };

        this.propertyService.GetBlankBORPDF(data).subscribe(
            (response) => {
                window.open(this.baseurl + response.PdfPath, "_blank");
                this.loading = false;
            },
            (error) => {
                this.sessionService.notifier.notify(
                    "error",
                    error.error.message
                );
                this.loading = false;
            }
        );
    }
    reviewEstimateGenerate() {
        this.printCurrentBid();

        this.isPlusShow = false;
        this.isMinusShow = true;
        this.isSubTabContentShow = true;
        this.isAllexpand = true;
        this.selectedIndex = 6;
        this.isAllExpandClass = "allExpandTabs";
    }
    removeAllExpand(index) {
        this.isPlusShow = true;
        this.isMinusShow = false;
        this.isSubTabContentShow = false;
        this.isAllexpand = false;
        this.selectedIndex = index;
        this.isAllExpandClass = "";
    }
    getRepairItem(RepairPropertyId) {
        this.loading = true;
        var data = {
            EmailId: "",
            PropertyId: RepairPropertyId,
            PageSize: 100000,
            LastEvaluatedKey: 0,
            UserType: this.sessionService.userType[1].Value,
        };
        this.mandatoryFirstItem = "";
        this.desiredFirstItem = "";
        this.additionalDesiredFirstItem = "";
        this.recommendedFirstItem = "";
        this.mandatoryFirstItemRepairName = "";
        this.desiredFirstItemRepairName = "";
        this.additionalDesiredFirstItemRepairName = "";
        this.recommendedFirstItemRepairName = "";
        this.mandatoryFirstItemLocation = "";
        this.desiredFirstItemLocation = "";
        this.additionalDesiredFirstItemLocation = "";
        this.recommendedFirstItemLocation = "";
        this._bservice.GetSorDetails(data).subscribe(
            (response) => {
                if (response != null && response.Items.length > 0) {
                    this.getRepairConstructionList = response.Items;

                    if (this.getRepairConstructionList.length > 0) {
                        this.coRepairContructionList =
                            this.getRepairConstructionList;

                        this.userList.Items = [];
                        this.userList.Count = 36;
                        this.userList.ScannedCount = 36;
                        this.userList.TotalRecord = 36;
                        this.userList.Items = [
                            {
                                id: 1,
                                RepairType: "Masonry",
                                RepairItemDetails: [],
                                RepairTotal: "0.00",
                                Operation: "Active",
                                style: "",
                            },
                            {
                                id: 2,
                                RepairType: "Siding",
                                RepairItemDetails: [],
                                RepairTotal: "0.00",
                                Operation: "Active",
                                style: "",
                            },
                            {
                                id: 3,
                                RepairType: "Gutters/Downspouts",
                                RepairItemDetails: [],
                                RepairTotal: "0.00",
                                Operation: "Active",
                                style: "",
                            },
                            {
                                id: 4,
                                RepairType: "Roof",
                                RepairItemDetails: [],
                                RepairTotal: "0.00",
                                Operation: "Active",
                                style: "",
                            },
                            {
                                id: 5,
                                RepairType: "Shutters",
                                RepairItemDetails: [],
                                RepairTotal: "0.00",
                                Operation: "Active",
                                style: "",
                            },
                            {
                                id: 6,
                                RepairType: "Exteriors",
                                RepairItemDetails: [],
                                RepairTotal: "0.00",
                                Operation: "Active",
                                style: "",
                            },
                            {
                                id: 7,
                                RepairType: "Walks",
                                RepairItemDetails: [],
                                RepairTotal: "0.00",
                                Operation: "Active",
                                style: "",
                            },
                            {
                                id: 8,
                                RepairType: "Driveways",
                                RepairItemDetails: [],
                                RepairTotal: "0.00",
                                Operation: "Active",
                                style: "",
                            },
                            {
                                id: 9,
                                RepairType: "Painting (Ext)",
                                RepairItemDetails: [],
                                RepairTotal: "0.00",
                                Operation: "Active",
                                style: "",
                            },
                            {
                                id: 10,
                                RepairType: "Caulking",
                                RepairItemDetails: [],
                                RepairTotal: "0.00",
                                Operation: "Active",
                                style: "",
                            },
                            {
                                id: 11,
                                RepairType: "Fencing",
                                RepairItemDetails: [],
                                RepairTotal: "0.00",
                                Operation: "Active",
                                style: "",
                            },
                            {
                                id: 12,
                                RepairType: "Grading",
                                RepairItemDetails: [],
                                RepairTotal: "0.00",
                                Operation: "Active",
                                style: "",
                            },
                            {
                                id: 13,
                                RepairType: "Windows",
                                RepairItemDetails: [],
                                RepairTotal: "0.00",
                                Operation: "Active",
                                style: "",
                            },
                            {
                                id: 14,
                                RepairType: "Weatherstrip",
                                RepairItemDetails: [],
                                RepairTotal: "0.00",
                                Operation: "Active",
                                style: "",
                            },
                            {
                                id: 15,
                                RepairType: "Doors (Ext)	",
                                RepairItemDetails: [],
                                RepairTotal: "0.00",
                                Operation: "Active",
                                style: "",
                            },
                            {
                                id: 16,
                                RepairType: "Doors (Int)	",
                                RepairItemDetails: [],
                                RepairTotal: "0.00",
                                Operation: "Active",
                                style: "",
                            },
                            {
                                id: 17,
                                RepairType: "Partition Wall",
                                RepairItemDetails: [],
                                RepairTotal: "0.00",
                                Operation: "Active",
                                style: "",
                            },
                            {
                                id: 18,
                                RepairType: "Plaster/Drywall",
                                RepairItemDetails: [],
                                RepairTotal: "0.00",
                                Operation: "Active",
                                style: "",
                            },
                            {
                                id: 19,
                                RepairType: "Decorating",
                                RepairItemDetails: [],
                                RepairTotal: "0.00",
                                Operation: "Active",
                                style: "",
                            },
                            {
                                id: 20,
                                RepairType: "Wood Trim	",
                                RepairItemDetails: [],
                                RepairTotal: "0.00",
                                Operation: "Active",
                                style: "",
                            },
                            {
                                id: 21,
                                RepairType: "Stairs",
                                RepairItemDetails: [],
                                RepairTotal: "0.00",
                                Operation: "Active",
                                style: "",
                            },
                            {
                                id: 22,
                                RepairType: "Closets",
                                RepairItemDetails: [],
                                RepairTotal: "0.00",
                                Operation: "Active",
                                style: "",
                            },
                            {
                                id: 23,
                                RepairType: "Wood Floors",
                                RepairItemDetails: [],
                                RepairTotal: "0.00",
                                Operation: "Active",
                                style: "",
                            },
                            {
                                id: 24,
                                RepairType: "Finished Floors",
                                RepairItemDetails: [],
                                RepairTotal: "0.00",
                                Operation: "Active",
                                style: "",
                            },
                            {
                                id: 25,
                                RepairType: "Ceramic Tile",
                                RepairItemDetails: [],
                                RepairTotal: "0.00",
                                Operation: "Active",
                                style: "",
                            },
                            {
                                id: 26,
                                RepairType: "Bath Accesories",
                                RepairItemDetails: [],
                                RepairTotal: "0.00",
                                Operation: "Active",
                                style: "",
                            },
                            {
                                id: 27,
                                RepairType: "Plumbing",
                                RepairItemDetails: [],
                                RepairTotal: "0.00",
                                Operation: "Active",
                                style: "",
                            },
                            {
                                id: 28,
                                RepairType: "Electrical",
                                RepairItemDetails: [],
                                RepairTotal: "0.00",
                                Operation: "Active",
                                style: "",
                            },
                            {
                                id: 29,
                                RepairType: "Heating",
                                RepairItemDetails: [],
                                RepairTotal: "0.00",
                                Operation: "Active",
                                style: "",
                            },
                            {
                                id: 30,
                                RepairType: "Insulation",
                                RepairItemDetails: [],
                                RepairTotal: "0.00",
                                Operation: "Active",
                                style: "",
                            },
                            {
                                id: 31,
                                RepairType: "Cabinetry",
                                RepairItemDetails: [],
                                RepairTotal: "0.00",
                                Operation: "Active",
                                style: "",
                            },
                            {
                                id: 32,
                                RepairType: "Appliances",
                                RepairItemDetails: [],
                                RepairTotal: "0.00",
                                Operation: "Active",
                                style: "",
                            },
                            {
                                id: 33,
                                RepairType: "Basements",
                                RepairItemDetails: [],
                                RepairTotal: "0.00",
                                Operation: "Active",
                                style: "",
                            },
                            {
                                id: 34,
                                RepairType: "Cleanup",
                                RepairItemDetails: [],
                                RepairTotal: "0.00",
                                Operation: "Active",
                                style: "",
                            },
                            {
                                id: 35,
                                RepairType: "Miscellaneous",
                                RepairItemDetails: [],
                                RepairTotal: "0.00",
                                Operation: "Active",
                                style: "",
                            },
                            {
                                id: 36,
                                RepairType: "Grand Total",
                                RepairItemDetails: [],
                                RepairTotal: "0.00",
                                Operation: "Inactive",
                                style: "",
                            },
                        ];

                        var setItem = this.getRepairConstructionList[0];
                        var GCSORdata = {};

                        GCSORdata = {
                            PropertyId: RepairPropertyId,
                            GeneralContractorId: this.userDetail.Id,
                        };

                        this._bservice.GetGCSorDetails(GCSORdata).subscribe(
                            (GCresponse) => {
                                if (
                                    GCresponse != null &&
                                    GCresponse.Items.length > 0
                                ) {
                                    this.GCBidData = GCresponse.Items[0];
                                    var setItemGC = GCresponse.Items[0];

                                    if (setItemGC.Constructions.length > 0) {
                                        var totalCost = 0;

                                        for (
                                            let i = 0;
                                            i < setItemGC.Constructions.length;
                                            i++
                                        ) {
                                            if (
                                                setItemGC.Constructions.length >
                                                0
                                            ) {
                                                for (
                                                    let j = 0;
                                                    j <
                                                    this.userList.Items.length;
                                                    j++
                                                ) {
                                                    if (
                                                        this.userList.Items[j]
                                                            .RepairType ==
                                                        setItemGC.Constructions[
                                                            i
                                                        ].RepairType
                                                    ) {
                                                        var subTotalCost = 0;
                                                        this.userList.Items[
                                                            j
                                                        ].style = "bold";

                                                        for (
                                                            let k = 0;
                                                            k <
                                                            setItemGC
                                                                .Constructions[
                                                                i
                                                            ].RepairItemDetails
                                                                .length;
                                                            k++
                                                        ) {
                                                            this.userList.Items[
                                                                j
                                                            ].RepairItemDetails.push(
                                                                setItemGC
                                                                    .Constructions[
                                                                    i
                                                                ]
                                                                    .RepairItemDetails[
                                                                    k
                                                                ]
                                                            );
                                                            if (
                                                                setItemGC
                                                                    .Constructions[
                                                                    i
                                                                ]
                                                                    .RepairItemDetails[
                                                                    k
                                                                ].Level == "M"
                                                            ) {
                                                                if (
                                                                    this
                                                                        .mandatoryFirstItem ==
                                                                        null ||
                                                                    this
                                                                        .mandatoryFirstItem ==
                                                                        "" ||
                                                                    this
                                                                        .mandatoryFirstItem ==
                                                                        undefined
                                                                ) {
                                                                    this.mandatoryFirstItem =
                                                                        setItemGC.Constructions[
                                                                            i
                                                                        ].RepairType;
                                                                    this.mandatoryFirstItemRepairName =
                                                                        setItemGC.Constructions[
                                                                            i
                                                                        ].RepairItemDetails[
                                                                            k
                                                                        ].RepairItemName;
                                                                    this.mandatoryFirstItemLocation =
                                                                        setItemGC.Constructions[
                                                                            i
                                                                        ].RepairItemDetails[
                                                                            k
                                                                        ].Location;
                                                                }
                                                            }
                                                            if (
                                                                setItemGC
                                                                    .Constructions[
                                                                    i
                                                                ]
                                                                    .RepairItemDetails[
                                                                    k
                                                                ].Level == "R"
                                                            ) {
                                                                if (
                                                                    this
                                                                        .recommendedFirstItem ==
                                                                        null ||
                                                                    this
                                                                        .recommendedFirstItem ==
                                                                        "" ||
                                                                    this
                                                                        .recommendedFirstItem ==
                                                                        undefined
                                                                ) {
                                                                    this.recommendedFirstItem =
                                                                        setItemGC.Constructions[
                                                                            i
                                                                        ].RepairType;
                                                                    this.recommendedFirstItemRepairName =
                                                                        setItemGC.Constructions[
                                                                            i
                                                                        ].RepairItemDetails[
                                                                            k
                                                                        ].RepairItemName;
                                                                    this.recommendedFirstItemLocation =
                                                                        setItemGC.Constructions[
                                                                            i
                                                                        ].RepairItemDetails[
                                                                            k
                                                                        ].Location;
                                                                }
                                                            }
                                                            if (
                                                                setItemGC
                                                                    .Constructions[
                                                                    i
                                                                ]
                                                                    .RepairItemDetails[
                                                                    k
                                                                ].Level ==
                                                                    "D" &&
                                                                setItemGC
                                                                    .Constructions[
                                                                    i
                                                                ]
                                                                    .RepairItemDetails[
                                                                    k
                                                                ]
                                                                    .isAdditional !=
                                                                    true
                                                            ) {
                                                                if (
                                                                    this
                                                                        .desiredFirstItem ==
                                                                        null ||
                                                                    this
                                                                        .desiredFirstItem ==
                                                                        "" ||
                                                                    this
                                                                        .desiredFirstItem ==
                                                                        undefined
                                                                ) {
                                                                    this.desiredFirstItem =
                                                                        setItemGC.Constructions[
                                                                            i
                                                                        ].RepairType;
                                                                    this.desiredFirstItemRepairName =
                                                                        setItemGC.Constructions[
                                                                            i
                                                                        ].RepairItemDetails[
                                                                            k
                                                                        ].RepairItemName;
                                                                    this.desiredFirstItemLocation =
                                                                        setItemGC.Constructions[
                                                                            i
                                                                        ].RepairItemDetails[
                                                                            k
                                                                        ].Location;
                                                                }
                                                            }
                                                            if (
                                                                setItemGC
                                                                    .Constructions[
                                                                    i
                                                                ]
                                                                    .RepairItemDetails[
                                                                    k
                                                                ].Level ==
                                                                    "D" &&
                                                                setItemGC
                                                                    .Constructions[
                                                                    i
                                                                ]
                                                                    .RepairItemDetails[
                                                                    k
                                                                ]
                                                                    .isAdditional ==
                                                                    true
                                                            ) {
                                                                if (
                                                                    this
                                                                        .additionalDesiredFirstItem ==
                                                                        null ||
                                                                    this
                                                                        .additionalDesiredFirstItem ==
                                                                        "" ||
                                                                    this
                                                                        .additionalDesiredFirstItem ==
                                                                        undefined
                                                                ) {
                                                                    this.additionalDesiredFirstItem =
                                                                        setItemGC.Constructions[
                                                                            i
                                                                        ].RepairType;
                                                                    this.additionalDesiredFirstItemRepairName =
                                                                        setItemGC.Constructions[
                                                                            i
                                                                        ].RepairItemDetails[
                                                                            k
                                                                        ].RepairItemName;
                                                                    this.additionalDesiredFirstItemLocation =
                                                                        setItemGC.Constructions[
                                                                            i
                                                                        ].RepairItemDetails[
                                                                            k
                                                                        ].Location;
                                                                }
                                                            }

                                                            if (
                                                                setItemGC
                                                                    .Constructions[
                                                                    i
                                                                ]
                                                                    .RepairItemDetails[
                                                                    k
                                                                ]
                                                                    .LaborUnitCost !=
                                                                    "" &&
                                                                setItemGC
                                                                    .Constructions[
                                                                    i
                                                                ]
                                                                    .RepairItemDetails[
                                                                    k
                                                                ]
                                                                    .LaborUnitCost !=
                                                                    null &&
                                                                setItemGC
                                                                    .Constructions[
                                                                    i
                                                                ]
                                                                    .RepairItemDetails[
                                                                    k
                                                                ]
                                                                    .LaborUnitCost !=
                                                                    undefined &&
                                                                setItemGC
                                                                    .Constructions[
                                                                    i
                                                                ]
                                                                    .RepairItemDetails[
                                                                    k
                                                                ]
                                                                    .LaborQuantity !=
                                                                    "" &&
                                                                setItemGC
                                                                    .Constructions[
                                                                    i
                                                                ]
                                                                    .RepairItemDetails[
                                                                    k
                                                                ]
                                                                    .LaborQuantity !=
                                                                    null &&
                                                                setItemGC
                                                                    .Constructions[
                                                                    i
                                                                ]
                                                                    .RepairItemDetails[
                                                                    k
                                                                ]
                                                                    .LaborQuantity !=
                                                                    undefined &&
                                                                setItemGC
                                                                    .Constructions[
                                                                    i
                                                                ]
                                                                    .RepairItemDetails[
                                                                    k
                                                                ]
                                                                    .MaterialUnitCost !=
                                                                    "" &&
                                                                setItemGC
                                                                    .Constructions[
                                                                    i
                                                                ]
                                                                    .RepairItemDetails[
                                                                    k
                                                                ]
                                                                    .MaterialUnitCost !=
                                                                    null &&
                                                                setItemGC
                                                                    .Constructions[
                                                                    i
                                                                ]
                                                                    .RepairItemDetails[
                                                                    k
                                                                ]
                                                                    .MaterialUnitCost !=
                                                                    undefined &&
                                                                setItemGC
                                                                    .Constructions[
                                                                    i
                                                                ]
                                                                    .RepairItemDetails[
                                                                    k
                                                                ]
                                                                    .MaterialQuantity !=
                                                                    "" &&
                                                                setItemGC
                                                                    .Constructions[
                                                                    i
                                                                ]
                                                                    .RepairItemDetails[
                                                                    k
                                                                ]
                                                                    .MaterialQuantity !=
                                                                    null &&
                                                                setItemGC
                                                                    .Constructions[
                                                                    i
                                                                ]
                                                                    .RepairItemDetails[
                                                                    k
                                                                ]
                                                                    .MaterialQuantity !=
                                                                    undefined
                                                            ) {
                                                                subTotalCost +=
                                                                    parseFloat(
                                                                        setItemGC
                                                                            .Constructions[
                                                                            i
                                                                        ]
                                                                            .RepairItemDetails[
                                                                            k
                                                                        ]
                                                                            .LaborUnitCost
                                                                    ) *
                                                                        parseFloat(
                                                                            setItemGC
                                                                                .Constructions[
                                                                                i
                                                                            ]
                                                                                .RepairItemDetails[
                                                                                k
                                                                            ]
                                                                                .LaborQuantity
                                                                        ) +
                                                                    parseFloat(
                                                                        setItemGC
                                                                            .Constructions[
                                                                            i
                                                                        ]
                                                                            .RepairItemDetails[
                                                                            k
                                                                        ]
                                                                            .MaterialUnitCost
                                                                    ) *
                                                                        parseFloat(
                                                                            setItemGC
                                                                                .Constructions[
                                                                                i
                                                                            ]
                                                                                .RepairItemDetails[
                                                                                k
                                                                            ]
                                                                                .MaterialQuantity
                                                                        );
                                                            }
                                                            this.userList.Items[
                                                                j
                                                            ].RepairItemDetails[
                                                                k
                                                            ].isItemCompleted =
                                                                true;
                                                        }
                                                        this.userList.Items[
                                                            j
                                                        ].RepairTotal =
                                                            subTotalCost;
                                                        totalCost +=
                                                            subTotalCost;
                                                    }
                                                }
                                            }
                                        }

                                        let nItems = 0;
                                        for (
                                            let i = 0;
                                            i < setItem.Constructions.length;
                                            i++
                                        ) {
                                            for (
                                                let j = 0;
                                                j < this.userList.Items.length;
                                                j++
                                            ) {
                                                if (
                                                    this.userList.Items[j]
                                                        .RepairType ==
                                                    setItem.Constructions[i]
                                                        .RepairType
                                                ) {
                                                    this.userList.Items[
                                                        j
                                                    ].style = "bold";
                                                    for (
                                                        let k = 0;
                                                        k <
                                                        setItem.Constructions[i]
                                                            .RepairItemDetails
                                                            .length;
                                                        k++
                                                    ) {
                                                        let flag = false;
                                                        for (
                                                            let l = 0;
                                                            l <
                                                            this.userList.Items[
                                                                j
                                                            ].RepairItemDetails
                                                                .length;
                                                            l++
                                                        ) {
                                                            let a =
                                                                setItem
                                                                    .Constructions[
                                                                    i
                                                                ]
                                                                    .RepairItemDetails[
                                                                    k
                                                                ]
                                                                    .RepairItemName;
                                                            let b =
                                                                this.userList
                                                                    .Items[j]
                                                                    .RepairItemDetails[
                                                                    l
                                                                ]
                                                                    .RepairItemName;

                                                            let c =
                                                                setItem
                                                                    .Constructions[
                                                                    i
                                                                ]
                                                                    .RepairItemDetails[
                                                                    k
                                                                ].Level;
                                                            let d =
                                                                this.userList
                                                                    .Items[j]
                                                                    .RepairItemDetails[
                                                                    l
                                                                ].Level;
                                                            let e =
                                                                setItem
                                                                    .Constructions[
                                                                    i
                                                                ]
                                                                    .RepairItemDetails[
                                                                    k
                                                                ].Location;
                                                            let f =
                                                                this.userList
                                                                    .Items[j]
                                                                    .RepairItemDetails[
                                                                    l
                                                                ].Location;

                                                            if (
                                                                a == b &&
                                                                c == d &&
                                                                e == f
                                                            ) {
                                                                flag = true;
                                                            }
                                                        }
                                                        if (flag == false) {
                                                            this.userList.Items[
                                                                j
                                                            ].RepairItemDetails.push(
                                                                setItem
                                                                    .Constructions[
                                                                    i
                                                                ]
                                                                    .RepairItemDetails[
                                                                    k
                                                                ]
                                                            );
                                                        }
                                                        if (
                                                            setItem
                                                                .Constructions[
                                                                i
                                                            ].RepairItemDetails[
                                                                k
                                                            ].Level == "M" ||
                                                            setItem
                                                                .Constructions[
                                                                i
                                                            ].RepairItemDetails[
                                                                k
                                                            ].Level == "D" ||
                                                            setItem
                                                                .Constructions[
                                                                i
                                                            ].RepairItemDetails[
                                                                k
                                                            ].Level == "R"
                                                        ) {
                                                            nItems++;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                        let totalComplete = 0;
                                        for (
                                            let n = 0;
                                            n < setItemGC.Constructions.length;
                                            n++
                                        ) {
                                            for (
                                                let m = 0;
                                                m <
                                                setItemGC.Constructions[n]
                                                    .RepairItemDetails.length;
                                                m++
                                            ) {
                                                if (
                                                    setItemGC.Constructions[n]
                                                        .RepairItemDetails[m]
                                                        .isAdditional != true
                                                ) {
                                                    totalComplete++;
                                                }
                                            }
                                        }
                                        this.totalSorItems = nItems;
                                        this.CalculateProgressItem(
                                            totalComplete,
                                            nItems
                                        );
                                        this.userList.Items[35].RepairTotal =
                                            totalCost;
                                    }

                                    this.permitCostTypeData =
                                        setItemGC.PermitCost;

                                    const control = <FormArray>(
                                        this.RepairItemForm.controls[
                                            "PermitCost"
                                        ]
                                    );

                                    for (
                                        let i = control.length - 1;
                                        i >= 0;
                                        i--
                                    ) {
                                        control.removeAt(i);
                                    }

                                    for (
                                        let j = 0;
                                        j < this.permitCostTypeData.length;
                                        j++
                                    ) {
                                        control.push(
                                            this._fb.group({
                                                PermitType: [
                                                    this.permitCostTypeData[j]
                                                        .PermitType,
                                                ],
                                                PermitCost: [
                                                    this.permitCostTypeData[j]
                                                        .PermitCost,
                                                ],
                                                Status: [
                                                    this.permitCostTypeData[j]
                                                        .Status,
                                                ],
                                            })
                                        );
                                        this.formStatus = true;
                                    }

                                    for (
                                        let n = 0;
                                        n < this.userList.Items.length;
                                        n++
                                    ) {
                                        for (
                                            let m = 0;
                                            m <
                                            this.userList.Items[n]
                                                .RepairItemDetails.length;
                                            m++
                                        ) {
                                            if (
                                                this.userList.Items[n]
                                                    .RepairItemDetails[m]
                                                    .Level == "M"
                                            ) {
                                                this.userList.Items[
                                                    n
                                                ].RepairItemDetails[
                                                    m
                                                ].Mandatory = true;
                                                if (
                                                    this.mandatoryFirstItem ==
                                                        "" ||
                                                    this.mandatoryFirstItem ==
                                                        null ||
                                                    this.mandatoryFirstItem ==
                                                        undefined
                                                ) {
                                                    this.mandatoryFirstItem =
                                                        this.userList.Items[
                                                            n
                                                        ].RepairType;
                                                    this.mandatoryFirstItemRepairName =
                                                        this.userList.Items[
                                                            n
                                                        ].RepairItemDetails[
                                                            m
                                                        ].RepairItemName;
                                                    this.mandatoryFirstItemLocation =
                                                        this.userList.Items[
                                                            n
                                                        ].RepairItemDetails[
                                                            m
                                                        ].Location;
                                                }
                                            }
                                            if (
                                                this.userList.Items[n]
                                                    .RepairItemDetails[m]
                                                    .Level == "D" &&
                                                this.userList.Items[n]
                                                    .RepairItemDetails[m]
                                                    .isAdditional != true
                                            ) {
                                                this.userList.Items[
                                                    n
                                                ].RepairItemDetails[m].Desired =
                                                    true;
                                                this.userList.Items[
                                                    n
                                                ].RepairItemDetails[
                                                    m
                                                ].isAdditional = false;
                                                if (
                                                    this.desiredFirstItem ==
                                                        "" ||
                                                    this.desiredFirstItem ==
                                                        null ||
                                                    this.desiredFirstItem ==
                                                        undefined
                                                ) {
                                                    this.desiredFirstItem =
                                                        this.userList.Items[
                                                            n
                                                        ].RepairType;
                                                    this.desiredFirstItemRepairName =
                                                        this.userList.Items[
                                                            n
                                                        ].RepairItemDetails[
                                                            m
                                                        ].RepairItemName;
                                                    this.desiredFirstItemLocation =
                                                        this.userList.Items[
                                                            n
                                                        ].RepairItemDetails[
                                                            m
                                                        ].Location;
                                                }
                                            }
                                            if (
                                                this.userList.Items[n]
                                                    .RepairItemDetails[m]
                                                    .Level == "D" &&
                                                this.userList.Items[n]
                                                    .RepairItemDetails[m]
                                                    .isAdditional == true
                                            ) {
                                                this.userList.Items[
                                                    n
                                                ].RepairItemDetails[m].Desired =
                                                    true;
                                                this.userList.Items[
                                                    n
                                                ].RepairItemDetails[
                                                    m
                                                ].isAdditional = true;
                                                if (
                                                    this
                                                        .additionalDesiredFirstItem ==
                                                        "" ||
                                                    this
                                                        .additionalDesiredFirstItem ==
                                                        null ||
                                                    this
                                                        .additionalDesiredFirstItem ==
                                                        undefined
                                                ) {
                                                    this.additionalDesiredFirstItem =
                                                        this.userList.Items[
                                                            n
                                                        ].RepairType;
                                                    this.additionalDesiredFirstItemRepairName =
                                                        this.userList.Items[
                                                            n
                                                        ].RepairItemDetails[
                                                            m
                                                        ].RepairItemName;
                                                    this.additionalDesiredFirstItemLocation =
                                                        this.userList.Items[
                                                            n
                                                        ].RepairItemDetails[
                                                            m
                                                        ].Location;
                                                }
                                            }

                                            if (
                                                this.userList.Items[n]
                                                    .RepairItemDetails[m]
                                                    .Level == "R"
                                            ) {
                                                this.userList.Items[
                                                    n
                                                ].RepairItemDetails[
                                                    m
                                                ].Recommended = true;
                                                if (
                                                    this.recommendedFirstItem ==
                                                        "" ||
                                                    this.recommendedFirstItem ==
                                                        null ||
                                                    this.recommendedFirstItem ==
                                                        undefined
                                                ) {
                                                    this.recommendedFirstItem =
                                                        this.userList.Items[
                                                            n
                                                        ].RepairType;
                                                    this.recommendedFirstItemRepairName =
                                                        this.userList.Items[
                                                            n
                                                        ].RepairItemDetails[
                                                            m
                                                        ].RepairItemName;
                                                    this.recommendedFirstItemLocation =
                                                        this.userList.Items[
                                                            n
                                                        ].RepairItemDetails[
                                                            m
                                                        ].Location;
                                                }
                                            }
                                        }
                                    }

                                    this.RepairItemForm.controls[
                                        "ConsultantsComment"
                                    ].setValue(setItem.ConsultantComment);
                                    this.matchSORItemsWithGCItems(
                                        this.userList.Items,
                                        setItem
                                    );
                                } else {
                                    this.getPermitCostTypeData();
                                    if (setItem.Constructions.length > 0) {
                                        var totalCost = 0;
                                        for (
                                            let i = 0;
                                            i < setItem.Constructions.length;
                                            i++
                                        ) {
                                            if (
                                                setItem.Constructions.length > 0
                                            ) {
                                                for (
                                                    let j = 0;
                                                    j <
                                                    this.userList.Items.length;
                                                    j++
                                                ) {
                                                    if (
                                                        this.userList.Items[j]
                                                            .RepairType ==
                                                        setItem.Constructions[i]
                                                            .RepairType
                                                    ) {
                                                        var subTotalCost = 0;
                                                        this.userList.Items[
                                                            j
                                                        ].style = "bold";
                                                        for (
                                                            let k = 0;
                                                            k <
                                                            setItem
                                                                .Constructions[
                                                                i
                                                            ].RepairItemDetails
                                                                .length;
                                                            k++
                                                        ) {
                                                            let flag = false;
                                                            for (
                                                                let l = 0;
                                                                l <
                                                                this.userList
                                                                    .Items[j]
                                                                    .RepairItemDetails
                                                                    .length;
                                                                l++
                                                            ) {
                                                                let a =
                                                                    setItem
                                                                        .Constructions[
                                                                        i
                                                                    ]
                                                                        .RepairItemDetails[
                                                                        k
                                                                    ]
                                                                        .RepairItemName;
                                                                let b =
                                                                    this
                                                                        .userList
                                                                        .Items[
                                                                        j
                                                                    ]
                                                                        .RepairItemDetails[
                                                                        l
                                                                    ]
                                                                        .RepairItemName;
                                                                let c =
                                                                    setItem
                                                                        .Constructions[
                                                                        i
                                                                    ]
                                                                        .RepairItemDetails[
                                                                        k
                                                                    ].Level;
                                                                let d =
                                                                    this
                                                                        .userList
                                                                        .Items[
                                                                        j
                                                                    ]
                                                                        .RepairItemDetails[
                                                                        l
                                                                    ].Level;
                                                                let e =
                                                                    setItem
                                                                        .Constructions[
                                                                        i
                                                                    ]
                                                                        .RepairItemDetails[
                                                                        k
                                                                    ].Location;
                                                                let f =
                                                                    this
                                                                        .userList
                                                                        .Items[
                                                                        j
                                                                    ]
                                                                        .RepairItemDetails[
                                                                        l
                                                                    ].Location;
                                                                if (
                                                                    a == b &&
                                                                    c == d &&
                                                                    e == f
                                                                ) {
                                                                    flag = true;
                                                                }
                                                            }
                                                            if (flag == false) {
                                                                this.userList.Items[
                                                                    j
                                                                ].RepairItemDetails.push(
                                                                    setItem
                                                                        .Constructions[
                                                                        i
                                                                    ]
                                                                        .RepairItemDetails[
                                                                        k
                                                                    ]
                                                                );
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                        this.userList.Items[35].RepairTotal = 0.0;
                                    }

                                    for (
                                        let n = 0;
                                        n < this.userList.Items.length;
                                        n++
                                    ) {
                                        for (
                                            let m = 0;
                                            m <
                                            this.userList.Items[n]
                                                .RepairItemDetails.length;
                                            m++
                                        ) {
                                            if (
                                                this.userList.Items[n]
                                                    .RepairItemDetails[m]
                                                    .Level == "M"
                                            ) {
                                                this.userList.Items[
                                                    n
                                                ].RepairItemDetails[
                                                    m
                                                ].Mandatory = true;
                                                if (
                                                    this.mandatoryFirstItem ==
                                                        "" ||
                                                    this.mandatoryFirstItem ==
                                                        null ||
                                                    this.mandatoryFirstItem ==
                                                        undefined
                                                ) {
                                                    this.mandatoryFirstItem =
                                                        this.userList.Items[
                                                            n
                                                        ].RepairType;
                                                    this.mandatoryFirstItemRepairName =
                                                        this.userList.Items[
                                                            n
                                                        ].RepairItemDetails[
                                                            m
                                                        ].RepairItemName;
                                                    this.mandatoryFirstItemLocation =
                                                        this.userList.Items[
                                                            n
                                                        ].RepairItemDetails[
                                                            m
                                                        ].Location;
                                                }
                                            }
                                            if (
                                                this.userList.Items[n]
                                                    .RepairItemDetails[m]
                                                    .Level == "D" &&
                                                this.userList.Items[n]
                                                    .RepairItemDetails[m]
                                                    .isAdditional != true
                                            ) {
                                                this.userList.Items[
                                                    n
                                                ].RepairItemDetails[m].Desired =
                                                    true;
                                                this.userList.Items[
                                                    n
                                                ].RepairItemDetails[
                                                    m
                                                ].isAdditional = false;
                                                if (
                                                    this.desiredFirstItem ==
                                                        "" ||
                                                    this.desiredFirstItem ==
                                                        null ||
                                                    this.desiredFirstItem ==
                                                        undefined
                                                ) {
                                                    this.desiredFirstItem =
                                                        this.userList.Items[
                                                            n
                                                        ].RepairType;
                                                    this.desiredFirstItemRepairName =
                                                        this.userList.Items[
                                                            n
                                                        ].RepairItemDetails[
                                                            m
                                                        ].RepairItemName;
                                                    this.desiredFirstItemLocation =
                                                        this.userList.Items[
                                                            n
                                                        ].RepairItemDetails[
                                                            m
                                                        ].Location;
                                                }
                                            }
                                            if (
                                                this.userList.Items[n]
                                                    .RepairItemDetails[m]
                                                    .Level == "D" &&
                                                this.userList.Items[n]
                                                    .RepairItemDetails[m]
                                                    .isAdditional == true
                                            ) {
                                                this.userList.Items[
                                                    n
                                                ].RepairItemDetails[m].Desired =
                                                    true;
                                                this.userList.Items[
                                                    n
                                                ].RepairItemDetails[
                                                    m
                                                ].isAdditional = true;
                                                if (
                                                    this
                                                        .additionalDesiredFirstItem ==
                                                        "" ||
                                                    this
                                                        .additionalDesiredFirstItem ==
                                                        null ||
                                                    this
                                                        .additionalDesiredFirstItem ==
                                                        undefined
                                                ) {
                                                    this.additionalDesiredFirstItem =
                                                        this.userList.Items[
                                                            n
                                                        ].RepairType;
                                                    this.additionalDesiredFirstItemRepairName =
                                                        this.userList.Items[
                                                            n
                                                        ].RepairItemDetails[
                                                            m
                                                        ].RepairItemName;
                                                    this.additionalDesiredFirstItemLocation =
                                                        this.userList.Items[
                                                            n
                                                        ].RepairItemDetails[
                                                            m
                                                        ].Location;
                                                }
                                            }

                                            if (
                                                this.userList.Items[n]
                                                    .RepairItemDetails[m]
                                                    .Level == "R"
                                            ) {
                                                this.userList.Items[
                                                    n
                                                ].RepairItemDetails[
                                                    m
                                                ].Recommended = true;
                                                if (
                                                    this.recommendedFirstItem ==
                                                        "" ||
                                                    this.recommendedFirstItem ==
                                                        null ||
                                                    this.recommendedFirstItem ==
                                                        undefined
                                                ) {
                                                    this.recommendedFirstItem =
                                                        this.userList.Items[
                                                            n
                                                        ].RepairType;
                                                    this.recommendedFirstItemRepairName =
                                                        this.userList.Items[
                                                            n
                                                        ].RepairItemDetails[
                                                            m
                                                        ].RepairItemName;
                                                    this.recommendedFirstItemLocation =
                                                        this.userList.Items[
                                                            n
                                                        ].RepairItemDetails[
                                                            m
                                                        ].Location;
                                                }
                                            }
                                        }
                                    }

                                    this.RepairItemForm.controls[
                                        "ConsultantsComment"
                                    ].setValue(setItem.ConsultantComment);
                                    this.matchSORItemsWithGCItems(
                                        this.userList.Items,
                                        setItem
                                    );
                                }
                            },
                            (error) => {}
                        );
                    }
                }
            },
            (error) => {}
        );
    }
    matchSORItemsWithGCItems(GCItems, COItems) {
        for (let j = 0; j < GCItems.length; j++) {
            for (let i = 0; i < COItems.Constructions.length; i++) {
                if (
                    GCItems[j].RepairType == COItems.Constructions[i].RepairType
                ) {
                    for (
                        let l = 0;
                        l < GCItems[j].RepairItemDetails.length;
                        l++
                    ) {
                        let flag = false;

                        for (
                            let k = 0;
                            k <
                            COItems.Constructions[i].RepairItemDetails.length;
                            k++
                        ) {
                            let coItemName =
                                COItems.Constructions[i].RepairItemDetails[k]
                                    .RepairItemName;
                            let gcItemName =
                                GCItems[j].RepairItemDetails[l].RepairItemName;
                            let coItemLevel =
                                COItems.Constructions[i].RepairItemDetails[k]
                                    .Level;
                            let gcItemLevel =
                                GCItems[j].RepairItemDetails[l].Level;
                            let coItemLocation =
                                COItems.Constructions[i].RepairItemDetails[k]
                                    .Location;
                            let gcItemLocation =
                                GCItems[j].RepairItemDetails[l].Location;

                            let coItemMUnit =
                                COItems.Constructions[i].RepairItemDetails[k]
                                    .MaterialUnit;
                            let gcItemMUnit =
                                GCItems[j].RepairItemDetails[l].MaterialUnit;
                            let coItemMQuantity =
                                COItems.Constructions[i].RepairItemDetails[k]
                                    .MaterialQuantity;
                            let gcItemMQuantity =
                                GCItems[j].RepairItemDetails[l]
                                    .MaterialQuantity;
                            let coItemLUnit =
                                COItems.Constructions[i].RepairItemDetails[k]
                                    .LaborUnit;
                            let gcItemLUnit =
                                GCItems[j].RepairItemDetails[l].LaborUnit;
                            let coItemLQuantity =
                                COItems.Constructions[i].RepairItemDetails[k]
                                    .LaborQuantity;
                            let gcItemLQuantity =
                                GCItems[j].RepairItemDetails[l].LaborQuantity;
                            if (
                                coItemName == gcItemName &&
                                coItemLevel == gcItemLevel &&
                                coItemLocation == gcItemLocation &&
                                coItemMUnit == gcItemMUnit &&
                                coItemMQuantity == gcItemMQuantity &&
                                coItemLUnit == gcItemLUnit &&
                                coItemLQuantity == gcItemLQuantity
                            ) {
                                flag = true;
                            }
                        }

                        if (flag == false) {
                            const index = GCItems[j].RepairItemDetails.indexOf(
                                GCItems[j].RepairItemDetails[l]
                            );
                            if (index > -1) {
                                GCItems[j].RepairItemDetails.splice(index, 1);
                            }
                        }
                    }
                }
            }
        }

        const users = Array.from({ length: GCItems.Count }, (_, k) =>
            createNewUser(k, GCItems)
        );
        this.dataSource = new MatTableDataSource(users);
        this.allRepairItem = GCItems;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.loading = false;
    }

    formReset() {
        this.RepairItemForm.reset();
    }
}

@Pipe({ name: "safe" })
export class SafePipe implements PipeTransform {
    constructor(@Inject(DomSanitizer) private sanitizer: DomSanitizer) {}
    transform(url: string) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }
}

/** Builds and returns a new User. */
function createNewUser(id: number, userList): UserData {
    const name =
        NAMES[Math.round(Math.random() * (NAMES.length - 1))] +
        " " +
        NAMES[Math.round(Math.random() * (NAMES.length - 1))].charAt(0) +
        ".";
    if (userList.Items[id] != undefined) {
        userList.Items[id].id = id + 1;
        return userList.Items[id];
    }
}
