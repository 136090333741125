<ng-template #customNotification let-notificationData="notification">
    <ng-container type="notificationData.type">
        <div [innerHTML]="notificationData.message"></div>
    </ng-container>
</ng-template>
<div class="content p-24 tbl" *ngIf="userDetail.UserType == 'Borrower'">
    <h1 class="m-0" *ngIf="propertyDetailsShow">
        Specification Of Repair Review Form
    </h1>
    <!-- <a *ngIf="isList" class="addBorrow" href="javascript:void(0)" (click)="isList=false;isAdd=true"><mat-icon _ngcontent-c102="" class="secondary-text s-22 mat-icon material-icons mat-icon-no-color" role="img" aria-hidden="true">add_circle</mat-icon> Add New Property</a>
    <a *ngIf="isAdd" class="addBorrow" href="javascript:void(0)" (click)="isAdd=false;isList=true"><mat-icon _ngcontent-c102="" class="secondary-text s-22 mat-icon material-icons mat-icon-no-color" role="img" aria-hidden="true">view_list</mat-icon> List Property</a> -->
    <a
        class="addBorrow"
        href="javascript:void(0)"
        routerLink="/property-details/{{ propertyDetails.Id }}"
    >
        Back to Property</a
    >
    <br />
    <a
        class="addBorrow"
        href="javascript:void(0)"
        routerLink="/general-contractor-info/{{ propertyDetails.Id }}"
        >Add Contractor</a
    >
    <br />

    <div class="container-login100">
        <mat-card class="my-form" style="overflow: hidden">
            <form
                [formGroup]="sor_review_status"
                class="example-form"
                (ngSubmit)="SorReviewStatus()"
            >
                <mat-card-header> </mat-card-header>

                <mat-card-content>
                    <div class="pdf-left">
                        <mat-radio-group
                            class="example-radio-group"
                            formControlName="statusType"
                            name="statusType"
                        >
                            <label>
                                <b
                                    >Please carefully review the form before
                                    making a selection.</b
                                >
                            </label>
                            <mat-radio-button
                                class="example-radio-button"
                                *ngFor="let item of reviewOption"
                                [value]="item.id"
                            >
                                {{ item.value }}
                            </mat-radio-button>
                        </mat-radio-group>
                        <div
                                            class="error"
                                            *ngIf="
                                                (!sor_review_status.controls[
                                                    'statusType'
                                                ].valid &&
                                                sor_review_status.controls[
                                                        'statusType'
                                                    ].touched) ||
                                                (sor_review_status.controls[
                                                    'statusType'
                                                ].invalid &&
                                                    submitted)
                                            "
                                        >
                                            Select status type
                                        </div>
                        <br />
                        <mat-form-field
                            class="example-full-width"
                            *ngIf="
                                sor_review_status.value.statusType == 'Revise'
                            "
                        >
                            <span class="mat-form-field-label-wrapper"
                                ><!----><label
                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                    >Revisions</label
                                ></span
                            >
                            <textarea
                                matInput
                                formControlName="Revisions"
                                name="Revisions"
                            ></textarea>
                        </mat-form-field>
                        <br />
                        <mat-card-actions>
                            <button
                                mat-raised-button
                                type="submit"
                                color="primary"
                            >
                                Submit</button
                            ><br /><br />
                            <a
                                href="javascript:void(0)"
                                (click)="sorDownloadPdf()"
                                >Click for print pdf</a
                            >
                        </mat-card-actions>
                    </div>
                    <div class="pdf-right" *ngIf="pdfdataShow">
                        <!-- <pdf-viewer [src]="sorViewPdf()" [render-text]="true" style="display: block;"></pdf-viewer> -->
                        <!-- <iframe [src]="sorViewPdf(pdfData)" width="500" height="600" type='application/pdf'></iframe> -->
                        <embed
                            [src]="pdfData"
                            id="pdfViewer"
                            type="application/pdf"
                            width="100%"
                            height="500px"
                        />
                        <!-- <iframe width="100%" height="600" [src]="sorViewPdf(pdfData)"></iframe> -->
                        <!-- <object data="{{pdfData}}" type="application/pdf">
                <embed src="{{pdfData}}" type="application/pdf" />
            </object>  -->
                    </div>
                </mat-card-content>
            </form>
        </mat-card>
        <br />
        <br />
        <br />
    </div>
</div>
<div
    class="content p-24 tbl"
    *ngIf="userDetail.UserType == 'General Contractors'"
>
    <h1 class="m-0" *ngIf="propertyDetailsShow">
        GC to view the consultants blank BOR form
    </h1>
    <!-- <a *ngIf="isList" class="addBorrow" href="javascript:void(0)" (click)="isList=false;isAdd=true"><mat-icon _ngcontent-c102="" class="secondary-text s-22 mat-icon material-icons mat-icon-no-color" role="img" aria-hidden="true">add_circle</mat-icon> Add New Property</a>
      <a *ngIf="isAdd" class="addBorrow" href="javascript:void(0)" (click)="isAdd=false;isList=true"><mat-icon _ngcontent-c102="" class="secondary-text s-22 mat-icon material-icons mat-icon-no-color" role="img" aria-hidden="true">view_list</mat-icon> List Property</a> -->
    <a class="addBorrow" href="javascript:void(0)" routerLink="/properties">
        Back to Property</a
    >
    <br />

    <div class="container-login100">
        <mat-card class="my-form" style="overflow: hidden">
            <form
                [formGroup]="sor_review_status"
                class="example-form"
                (ngSubmit)="SorReviewStatusByGC(propertyDetails)"
            >
                <mat-card-header> </mat-card-header>

                <mat-card-content>
                    <div class="pdf-left">
                        <mat-radio-group
                            style="height: 1px"
                            class="example-radio-group"
                            formControlName="statusType"
                            name="statusType"
                        >
                            <mat-radio-button
                                style="visibility: hidden; font-size: 1px"
                                checked
                                class="example-radio-button"
                                *ngFor="let item of reviewOptionForGC"
                                [value]="item.id"
                            >
                                {{ item.value }}
                            </mat-radio-button>
                        </mat-radio-group>

                        <mat-card-actions class="m-x-0">
                            <button
                                mat-raised-button
                                type="submit"
                                color="primary"
                            >
                                Continue to Estimate</button
                            ><br /><br />
                            <a
                                href="javascript:void(0)"
                                (click)="blankBorDownloadPdf()"
                                >Click for print pdf</a
                            >
                        </mat-card-actions>
                    </div>
                    <div class="pdf-right" *ngIf="pdfdataShow">
                        <!-- <pdf-viewer [src]="sorViewPdf()" [render-text]="true" style="display: block;"></pdf-viewer> -->
                        <!-- <iframe [src]="sorViewPdf(pdfData)" width="500" height="600" type='application/pdf'></iframe> -->
                        <embed
                            [src]="pdfData"
                            id="pdfViewer"
                            type="application/pdf"
                            width="100%"
                            height="500px"
                        />
                        <!-- <iframe width="100%" height="600" [src]="sorViewPdf(pdfData)"></iframe> -->
                        <!-- <object data="{{pdfData}}" type="application/pdf">
                  <embed src="{{pdfData}}" type="application/pdf" />
              </object>  -->
                    </div>
                </mat-card-content>
            </form>
        </mat-card>
        <br />
        <br />
        <br />
    </div>
</div>
