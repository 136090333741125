<div class="page-layout blank p-24" fusePerfectScrollbar>
    <h2>Welcome to the A.M. Renovation Consulting Xchange.</h2>
    <br /><br />

    <!-- <div class="container-login100">
        <h2>Masquerade</h2>
        <div *ngIf="userDetail.UserType == 'Administrator'">
                <input type="text" style="width: 200px;height: 43px;border: 2px solid #bbb;" placeholder="Email"
                [(ngModel)]="LoginEmail" name="Email">
        
            <button mat-raised-button type="button" style="height: 43px;" (click)="Masquerade()" color="primary">Go</button>
        </div>
        
        <br>
        <p *ngIf="masqueradeUserInfo!=''">You are masquerading as {{userDetail.EmailId}}.</p> 
        <p *ngIf="masqueradeUserInfo!=''">Quick switches:</p> 
        <a *ngIf="masqueradeUserInfo!=''" href="javascript:void(0);" (click)="MasqueradeReverse()">Switch back</a> 
            
    </div> -->
</div>
