import { Component, OnInit, ViewChild, Inject } from "@angular/core";
import { FormGroup, Validators, FormControl } from "@angular/forms";
import { MatDialog, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { DataSource } from "@angular/cdk/table";
import { ActivatedRoute, Router } from "@angular/router";
import { BorrowerService } from "../borrower/borrower.service";
import { SessionService } from "../service/session.service";
import * as AWS from "aws-sdk/global";
import * as S3 from "aws-sdk/clients/s3";

export interface UserData {
    id: string;
    name: string;
    email: string;
    lastLogin: Date;
}

export interface DialogData {
    imgUrl: string;
}

/** Constants used to fill up our data base. */
const COLORS: string[] = [
    "maroon",
    "red",
    "orange",
    "yellow",
    "olive",
    "green",
    "purple",
    "fuchsia",
    "lime",
    "teal",
    "aqua",
    "blue",
    "navy",
    "black",
    "gray",
];
const NAMES: string[] = [
    "Maia",
    "Asher",
    "Olivia",
    "Atticus",
    "Amelia",
    "Jack",
    "Charlotte",
    "Theodore",
    "Isla",
    "Oliver",
    "Isabella",
    "Jasper",
    "Cora",
    "Levi",
    "Violet",
    "Arthur",
    "Mia",
    "Thomas",
    "Elizabeth",
];

/**
 * @title Data table with sorting, pagination, and filtering.
 */

@Component({
    selector: "app-property-gallery-listing",
    templateUrl: "./property-gallery-listing.component.html",
    styleUrls: ["./property-gallery-listing.component.scss"],
})
export class PropertyGalleryListingComponent implements OnInit {
    displayedColumns: string[] = [
        "ActionName",
        "AnonymousUser",
        "AuthenticatedUser",
        "Administrator",
        "Consultants",
        "GeneralContractors",
        "Borrower",
        "LoanOfficer",
        "LoanProcessor",
    ];
    dataSource: MatTableDataSource<UserData>;
    mobnumPattern = "^(d{3})sd{3}-d{4}";

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    isList: boolean = true;
    isAdd: boolean = false;
    isEdit: boolean = false;
    settings: any;
    submitted: boolean = false;
    favoriteSeason: string;
    userDetail: any = {};
    userList: any = {};
    propertyId: any = 0;
    loading = false;
    s3ImagePath: string;
    s3CoverImagePath: string;
    selectedFile: any;
    coverFileName: any;
    showCoverFile: any;
    propertyImageDataId = null;
    tempFileName: string;
    propertyImagesData: any[] = [];
    roles: any = [
        { Key: "1", Value: "authenticated user" },
        { Key: "2", Value: "Administrator" },
        { Key: "3", Value: "Consultants" },
        { Key: "4", Value: "General Contractors" },
        { Key: "5", Value: "Borrower" },
        { Key: "6", Value: "Loan Officer" },
        { key: "7", Value: "Loan Processor" },
        { key: "8", Value: " Notify user of new account" },
        { key: "9", Value: "Draw Administrator" },
    ];
    constructor(
        @Inject(ActivatedRoute) private route: ActivatedRoute,
        private _bservice: BorrowerService,
        private sessionService: SessionService,
        public dialog: MatDialog
    ) {}

    ngOnInit() {
        var propertyId = this.route.snapshot.paramMap.get("propertyId");
        this.propertyId = propertyId;

        var data = {
            EmailId: "",
            PageSize: 100000,
            LastEvaluatedKey: 0,
            UserType: this.sessionService.userType[4].Value,
        };
        this._bservice.GetSetting(data).subscribe((response) => {
            if (response != null) {
                this.settings = response;
            }
        });

        if (this.sessionService.get("userInfo") != undefined) {
            this.userDetail = JSON.parse(this.sessionService.get("userInfo"));
        }
        this.getPropertyImage(propertyId);
    }

    applyFilter(filterValue: string) {
        this.dataSource.filter = filterValue.trim().toLowerCase();

        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    }

    handleInputChange(e: any) {
        var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];

        var pattern = /image-*/;
        var reader = new FileReader();

        //Validation
        if (!file.type.match(pattern)) {
            return false;
        }

        if (file.size > 1000000) {
            return false;
        }

        this.selectedFile = file;

        reader.onload = this._handleReaderLoaded.bind(this);
        reader.readAsDataURL(file);
    }

    _handleReaderLoaded(e: any) {
        var reader = e.target;

        this.tempFileName = reader.result;
    }

    saveToS3(fileInput: any) {
        const file = fileInput;
        const bucketName = this.settings.AwsS3BucketName
            ? this.settings.AwsS3BucketName
            : "";
        const accessKeyId = this.settings.AwsAccessKeyId
            ? this.settings.AwsAccessKeyId
            : "";
        const secretAccessKey = this.settings.AwsSecretAccessKey
            ? this.settings.AwsSecretAccessKey
            : "";
        const region = this.settings.AwsConfigRegion
            ? this.settings.AwsConfigRegion
            : "";

        const contentType = file.type;
        const bucket = new S3({
            accessKeyId: accessKeyId,
            secretAccessKey: secretAccessKey,
            region: region,
        });

        var millis = Date.now();
        const propertyid = 1;
        const fileName =
            "image-" + millis + "." + file.name.split(".").pop().toLowerCase();

        const params = {
            Bucket: bucketName,
            Key: "property-images/" + propertyid + "/" + fileName,
            Body: file,
            StorageClass: "STANDARD",
            ACL: "bucket-owner-full-control",
            ContentType: contentType,
        };
        const _this = this;
        bucket.upload(params, function (err: any, data: any) {
            if (err) {
                alert(
                    "There was an error uploading your file: " +
                        JSON.stringify(err)
                );
                console.log("There was an error uploading your file: ", err);
                return false;
            } else {
                _this.s3ImagePath = data.Location;

                if (_this.propertyImagesData == undefined) {
                    _this.propertyImagesData = [];
                    _this.propertyImagesData.push(_this.s3ImagePath);
                } else {
                    _this.propertyImagesData.push(_this.s3ImagePath);
                }

                _this.loading = false;
                return true;
            }
        });
    }

    onSubmit() {
        this.loading = true;
        this.submitted = true;

        if (this.selectedFile != null) {
            //Save to S3
            this.saveToS3(this.selectedFile);
        } else {
            alert("Please Select File !");
            this.loading = false;
        }
    }

    removeImage(index) {
        this.propertyImagesData.splice(index, 1);
    }

    coverHandleInputChange(e: any) {
        var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];

        var pattern = /image-*/;
        var reader = new FileReader();

        if (!file.type.match(pattern)) {
            return false;
        }

        if (file.size > 1000000) {
            return false;
        }

        this.coverFileName = file;

        reader.onload = this._handleCoverReaderLoaded.bind(this);
        reader.readAsDataURL(file);
    }

    _handleCoverReaderLoaded(e: any) {
        var reader = e.target;

        this.tempFileName = reader.result;
    }

    saveTocoverS3(fileInput: any) {
        const file = fileInput;
        const bucketName = this.settings.AwsS3BucketName;
        const accessKeyId = this.settings.AwsAccessKeyId;
        const secretAccessKey = this.settings.AwsSecretAccessKey;
        const region = this.settings.AwsConfigRegion;

        const contentType = file.type;
        const bucket = new S3({
            accessKeyId: accessKeyId,
            secretAccessKey: secretAccessKey,
            region: region,
        });

        var millis = Date.now();
        const propertyid = 1;
        const fileName =
            "image-" + millis + "." + file.name.split(".").pop().toLowerCase();

        const params = {
            Bucket: bucketName,
            Key: "property-images/" + propertyid + "/" + fileName,
            Body: file,
            StorageClass: "STANDARD",
            ACL: "bucket-owner-full-control",
            ContentType: contentType,
        };
        const _this = this;
        bucket.upload(params, function (err: any, data: any) {
            if (err) {
                alert(
                    "There was an error uploading your file: " +
                        JSON.stringify(err)
                );
                console.log("There was an error uploading your file: ", err);
                return false;
            } else {
                _this.s3CoverImagePath = data.Location;

                _this.showCoverFile = _this.s3CoverImagePath;

                _this.loading = false;
                return true;
            }
        });
    }

    onCoverSubmit() {
        this.loading = true;
        this.submitted = true;

        if (this.coverFileName != null) {
            //Save to S3
            this.saveTocoverS3(this.coverFileName);
        } else {
            alert("Please Select File !");
            this.loading = false;
        }
    }

    removeCoverImage(index) {
        this.showCoverFile = null;
    }

    getPropertyImage(propertyId) {
        var data = { PropertyId: propertyId };
        this._bservice.GetPropertyImagesList(data).subscribe(
            (response) => {
                if (response != null) {
                    if (response.Items.length > 0) {
                        var imageData = response.Items[0];
                        this.propertyImagesData = imageData.Images;
                        this.showCoverFile = imageData.CoverPhoto;
                        this.propertyImageDataId = imageData.Id;
                    } else {
                        this.propertyImageDataId = null;
                    }
                }
            },
            (error) => {}
        );
    }

    saveRecord() {
        this.loading = true;
        if (this.propertyImageDataId == null) {
            if (
                this.propertyImagesData.length > 0 ||
                this.showCoverFile != null
            ) {
                var currentdate = new Date();
                var data = {
                    PropertyId: this.propertyId,
                    CoverPhoto: this.showCoverFile,
                    Images: this.propertyImagesData,
                    CreatedBy: this.userDetail.EmailId,
                    CreatedDate: currentdate,
                    UpdateDate: currentdate,
                    UpdatedBy: this.userDetail.EmailId,
                };

                this._bservice.AddPropertyImage(data).subscribe(
                    (response) => {
                        this.sessionService.notifier.notify(
                            "success",
                            "Property Gallery added successfully."
                        );
                        this.getPropertyImage(this.propertyId);
                        this.loading = false;
                    },
                    (error) => {
                        this.sessionService.notifier.notify(
                            "error",
                            error.error.message
                        );
                        this.loading = false;
                    }
                );
            } else {
                this.loading = false;
                alert("Please Select at least one Image!");
            }
        } else {
            if (
                this.propertyImagesData.length > 0 ||
                this.showCoverFile != null
            ) {
                var currentdate = new Date();
                var udata = {
                    Id: this.propertyImageDataId,
                    PropertyId: this.propertyId,
                    CoverPhoto: this.showCoverFile,
                    Images: this.propertyImagesData,
                    CreatedBy: this.userDetail.EmailId,
                    CreatedDate: currentdate,
                    UpdateDate: currentdate,
                    UpdatedBy: this.userDetail.EmailId,
                };

                this._bservice.UpdatePropertyImage(udata).subscribe(
                    (response) => {
                        this.sessionService.notifier.notify(
                            "success",
                            "Property Gallery Updated successfully."
                        );
                        this.getPropertyImage(this.propertyId);
                        this.loading = false;
                    },
                    (error) => {
                        this.sessionService.notifier.notify(
                            "error",
                            error.error.message
                        );
                        this.loading = false;
                    }
                );
            } else {
                alert("Please Select at least one Image!");
            }
        }
    }

    imgPopup(url) {
        const dialogRef = this.dialog.open(DialogImageDialog, {
            data: {
                imgUrl: url,
            },
        });
    }
}

@Component({
    selector: "dialog-content-example-dialog",
    templateUrl: "imgpopup.html",
})
export class DialogImageDialog {
    constructor(
        @Inject(MAT_DIALOG_DATA) public imgData: DialogData,
        @Inject(Router) private router: Router
    ) {}
}

/** Builds and returns a new User. */
