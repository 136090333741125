<div class="content p-24 tbl">
    <ngx-loading class="ngxLoader" 
        [show]="loading"
        [config]="{ backdropBorderRadius: '3px' }"
    ></ngx-loading>
    <h1 class="m-0">Non-Home Inspection Disclosure</h1>
    <br />
    <div class="container-login100">
        <mat-card class="my-form">
            <form
                [formGroup]="add_home_ins_disclosure"
                class="example-form"
                (ngSubmit)="SaveRecord()"
            >
                <mat-card-content>
                    <mat-form-field class="example-full-width">
                        <input
                            matInput
                            placeholder="Title*"
                            formControlName="title"
                            name="title"
                        />
                        <div
                        class="error"
                        *ngIf="
                            (!add_home_ins_disclosure.controls[
                                'title'
                            ].valid &&
                            add_home_ins_disclosure
                                    .controls[
                                    'title'
                                ].touched) ||
                            (add_home_ins_disclosure.controls[
                                'title'
                            ].invalid &&
                                submitted)
                        "
                    >
                       Enter valid title.
                    </div>
                    </mat-form-field>

                    <mat-form-field>
                        <span class="mat-form-field-label-wrapper"
                            ><!----><label
                                class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                >Property</label
                            ></span
                        >
                        <mat-select formControlName="property">
                            <mat-option value="">Select</mat-option>
                            <mat-option
                                *ngFor="let property of propertyList"
                                value="{{ property.Id }}"
                            >
                                {{
                                    property?.InspectionAddress?.address1
                                        ? property?.InspectionAddress
                                              ?.address1 + ", "
                                        : ""
                                }}
                                {{
                                    property?.InspectionAddress?.address2
                                        ? property?.InspectionAddress
                                              ?.address2 + ", "
                                        : ""
                                }}
                                {{
                                    property?.InspectionAddress?.city
                                        ? property?.InspectionAddress?.city +
                                          ", "
                                        : ""
                                }}
                                {{
                                    property?.InspectionAddress?.state
                                        ? property?.InspectionAddress?.state +
                                          ", "
                                        : ""
                                }}
                                {{ property?.InspectionAddress?.zipcode }}
                            </mat-option>
                        </mat-select>
                        <div
                        class="error"
                        *ngIf="
                            (!add_home_ins_disclosure.controls[
                                'property'
                            ].valid &&
                            add_home_ins_disclosure
                                    .controls[
                                    'property'
                                ].touched) ||
                            (add_home_ins_disclosure.controls[
                                'property'
                            ].invalid &&
                                submitted)
                        "
                    >
                       Select Property
                    </div>
                    </mat-form-field>
                </mat-card-content>
                <mat-card-actions>
                    <button mat-raised-button type="submit" color="primary">
                        Save</button
                    >&nbsp; &nbsp;&nbsp;
                    <button mat-raised-button type="button" color="info">
                        Save as draft</button
                    >&nbsp; &nbsp;&nbsp;
                    <button mat-raised-button type="button" color="info">
                        Preview
                    </button>
                </mat-card-actions>
            </form>
        </mat-card>
    </div>
</div>
