<div class="content p-24 tbl">
    <ngx-loading class="ngxLoader" 
        [show]="loading"
        [config]="{ backdropBorderRadius: '3px' }"
    ></ngx-loading>
    <h1 class="m-0">Initial Draw Request</h1>
    <!-- <a *ngIf="isList" class="addBorrow" href="javascript:void(0)" (click)="isList=false;isAdd=true;isEdit=false;formReset()"><mat-icon _ngcontent-c102="" class="secondary-text s-22 mat-icon material-icons mat-icon-no-color" role="img" aria-hidden="true">add_circle</mat-icon> Add New Borrower</a> -->
    <!-- <a class="addBorrow" href="javascript:void(0)" routerLink="/properties"> Back to Property</a> -->
    <!-- <mat-form-field>
  <input [hidden]="!isList" matInput (keyup)="applyFilter($event.target)" placeholder="Filter">
</mat-form-field> -->

    <br />
    <br />
    <p>
        Please reference the Specification of Repair form
        <a href="javascript:void(0);" (click)="GetFinalSORPDF()">(Link)</a> to
        property fill out the values for Column 3.
    </p>

    <div>
        <mat-card class="my-form">
            <form
                [formGroup]="DrawRequestForm"
                class="example-form"
                (ngSubmit)="SaveDrawRequest()"
            >
                <strong
                    *ngIf="oldDrawStatus == 'revised'"
                    style="text-decoration: underline"
                    >Draw Status :</strong
                ><br />
                <p *ngIf="oldDrawStatus == 'revised'">Revision Requested</p>
                <input
                    style="padding-left: 5px; visibility: hidden"
                    formControlName="OldDrawStatus"
                />
                <br /><br />
                <ng-container *ngIf="oldDrawStatus == 'revised'"
                    ><strong style="text-decoration: underline"
                        >Borrowers Revision:</strong
                    ><br />{{ borrowerComment }}</ng-container
                >
                <mat-card-header>
                    <mat-card-title></mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <table class="drawComponentListTable">
                        <thead>
                            <th align="left" width="15%">Construction Item</th>
                            <th align="left" width="15%">
                                Total Escrow <br />Col. 1
                            </th>
                            <th align="left" width="17%">
                                Previous Draw Totals<br />
                                Col. 2
                            </th>
                            <th align="left" width="6%">%</th>
                            <th align="left" width="17%">
                                Request for This Draw <br />Col. 3
                            </th>
                            <th align="left" width="6%">%</th>
                            <th align="left" width="17%">
                                Adjusted Amounts <br />Col. 4
                            </th>
                            <th align="left" width="7%">%</th>
                        </thead>
                        <tbody>
                            <ng-container formArrayName="Constructions">
                                <ng-container
                                    *ngFor="
                                        let DrawRequestDetail of DrawRequestForm.get(
                                            'Constructions'
                                        )['controls'];
                                        let i = index
                                    "
                                >
                                    <tr [formGroupName]="i">
                                        <td>
                                            <span *ngIf="i != 35"
                                                >{{ i + 1 }}.
                                                {{
                                                    DrawRequestForm.value[
                                                        "Constructions"
                                                    ][i].RepairType
                                                }}</span
                                            >
                                            <strong *ngIf="i == 35">{{
                                                DrawRequestForm.value[
                                                    "Constructions"
                                                ][i].RepairType
                                            }}</strong>
                                        </td>
                                        <td align="center">
                                            <span
                                                *ngIf="
                                                    i != 35 &&
                                                    DrawRequestForm.value[
                                                        'Constructions'
                                                    ][i].TotalAmount == 0
                                                "
                                                >&nbsp;</span
                                            >
                                            <span
                                                *ngIf="
                                                    i != 35 &&
                                                    DrawRequestForm.value[
                                                        'Constructions'
                                                    ][i].TotalAmount != 0
                                                "
                                                >${{
                                                    DrawRequestForm.value[
                                                        "Constructions"
                                                    ][i].TotalAmount
                                                        | number : "1.2-2"
                                                }}</span
                                            >
                                            <strong
                                                *ngIf="
                                                    i == 35 &&
                                                    DrawRequestForm.value[
                                                        'Constructions'
                                                    ][i].TotalAmount == 0
                                                "
                                                >&nbsp;</strong
                                            >
                                            <strong
                                                *ngIf="
                                                    i == 35 &&
                                                    DrawRequestForm.value[
                                                        'Constructions'
                                                    ][i].TotalAmount != 0
                                                "
                                                >${{
                                                    DrawRequestForm.value[
                                                        "Constructions"
                                                    ][i].TotalAmount
                                                        | number : "1.2-2"
                                                }}</strong
                                            >
                                        </td>
                                        <td align="center">
                                            <span
                                                *ngIf="
                                                    i != 35 &&
                                                    DrawRequestForm.value[
                                                        'Constructions'
                                                    ][i].GCPreviewsAmount == 0
                                                "
                                                >&nbsp;</span
                                            >
                                            <span
                                                *ngIf="
                                                    i != 35 &&
                                                    DrawRequestForm.value[
                                                        'Constructions'
                                                    ][i].GCPreviewsAmount != 0
                                                "
                                                >${{
                                                    DrawRequestForm.value[
                                                        "Constructions"
                                                    ][i].GCPreviewsAmount
                                                        | number : "1.2-2"
                                                }}</span
                                            >
                                            <strong
                                                *ngIf="
                                                    i == 35 &&
                                                    DrawRequestForm.value[
                                                        'Constructions'
                                                    ][i].GCPreviewsAmount == 0
                                                "
                                                >&nbsp;</strong
                                            >
                                            <strong
                                                *ngIf="
                                                    i == 35 &&
                                                    DrawRequestForm.value[
                                                        'Constructions'
                                                    ][i].GCPreviewsAmount != 0
                                                "
                                                >${{
                                                    DrawRequestForm.value[
                                                        "Constructions"
                                                    ][i].GCPreviewsAmount
                                                        | number : "1.2-2"
                                                }}</strong
                                            >
                                        </td>
                                        <td align="center">
                                            <ng-container *ngIf="i != 35">
                                                <span
                                                    *ngIf="
                                                        DrawRequestForm.value[
                                                            'Constructions'
                                                        ][i].GCPreviewsAmount ==
                                                        0
                                                    "
                                                    >&nbsp;</span
                                                >
                                                <span
                                                    *ngIf="
                                                        DrawRequestForm.value[
                                                            'Constructions'
                                                        ][i].GCPreviewsAmount !=
                                                        0
                                                    "
                                                    >{{
                                                        (DrawRequestForm.value[
                                                            "Constructions"
                                                        ][i].GCPreviewsAmount *
                                                            100) /
                                                            DrawRequestForm
                                                                .value[
                                                                "Constructions"
                                                            ][i].TotalAmount
                                                            | number : "1.2-2"
                                                    }}%</span
                                                >
                                            </ng-container>
                                            <ng-container *ngIf="i == 35">
                                                <strong
                                                    *ngIf="
                                                        DrawRequestForm.value[
                                                            'Constructions'
                                                        ][i].GCPreviewsAmount ==
                                                        0
                                                    "
                                                    >&nbsp;</strong
                                                >
                                                <strong
                                                    *ngIf="
                                                        DrawRequestForm.value[
                                                            'Constructions'
                                                        ][i].GCPreviewsAmount !=
                                                        0
                                                    "
                                                    >{{
                                                        (DrawRequestForm.value[
                                                            "Constructions"
                                                        ][i].GCPreviewsAmount *
                                                            100) /
                                                            DrawRequestForm
                                                                .value[
                                                                "Constructions"
                                                            ][i].TotalAmount
                                                            | number : "1.2-2"
                                                    }}%</strong
                                                >
                                            </ng-container>
                                        </td>
                                        <td
                                            class="GCAmountInputField"
                                            align="center"
                                        >
                                            <span
                                                *ngIf="
                                                    i != 35 &&
                                                    DrawRequestForm.value[
                                                        'Constructions'
                                                    ][i].TotalAmount == 0
                                                "
                                                >&nbsp;</span
                                            >

                                            <ng-container
                                                *ngIf="
                                                    i != 35 &&
                                                    DrawRequestForm.value[
                                                        'Constructions'
                                                    ][i].TotalAmount != 0
                                                "
                                            >
                                                <label
                                                    style="
                                                        float: left;
                                                        margin-left: 8%;
                                                    "
                                                    *ngIf="
                                                        DrawRequestForm.value[
                                                            'Constructions'
                                                        ][i].TotalAmount != 0 &&
                                                        DrawRequestForm.value[
                                                            'Constructions'
                                                        ][i].GCAmount == ''
                                                    "
                                                    >Enter Requested
                                                    Value</label
                                                >
                                                <br />$<input
                                                    type="text"
                                                    *ngIf="
                                                        DrawRequestForm.value[
                                                            'Constructions'
                                                        ][i].TotalAmount != 0
                                                    "
                                                    (blur)="onBlurGCAmount(i)"
                                                    (focus)="removeZeroVal(i)"
                                                    (keyup)="onKeyUpGCAmount(i)"
                                                    placeholder="Enter Requested Value"
                                                    formControlName="GCAmount"
                                                />
                                            </ng-container>

                                            <span
                                                *ngIf="
                                                    i == 35 &&
                                                    DrawRequestForm.value[
                                                        'Constructions'
                                                    ][i].TotalAmount == 0
                                                "
                                                >&nbsp;</span
                                            >
                                            <mat-form-field
                                                *ngIf="
                                                    i == 35 &&
                                                    DrawRequestForm.value[
                                                        'Constructions'
                                                    ][i].TotalAmount != 0
                                                "
                                            >
                                                $<input
                                                    *ngIf="
                                                        DrawRequestForm.value[
                                                            'Constructions'
                                                        ][i].TotalAmount != 0
                                                    "
                                                    matInput
                                                    formControlName="TotalGCAmount"
                                                    readonly="true"
                                                />
                                            </mat-form-field>
                                        </td>
                                        <td
                                            class="GCAmountInputPerField"
                                            align="center"
                                        >
                                            <ng-container *ngIf="i != 35">
                                                <span
                                                    *ngIf="
                                                        DrawRequestForm.value[
                                                            'Constructions'
                                                        ][i].TotalAmount == 0
                                                    "
                                                    >&nbsp;</span
                                                >
                                                <span
                                                    *ngIf="
                                                        DrawRequestForm.value[
                                                            'Constructions'
                                                        ][i].TotalAmount != 0
                                                    "
                                                    >{{
                                                        (DrawRequestForm.value[
                                                            "Constructions"
                                                        ][i].GCAmount *
                                                            100) /
                                                            DrawRequestForm
                                                                .value[
                                                                "Constructions"
                                                            ][i].TotalAmount
                                                            | number : "1.2-2"
                                                    }}%</span
                                                >
                                            </ng-container>
                                            <ng-container *ngIf="i == 35">
                                                <span
                                                    *ngIf="
                                                        DrawRequestForm.value[
                                                            'Constructions'
                                                        ][i].TotalAmount == 0
                                                    "
                                                    >&nbsp;</span
                                                >
                                                <input
                                                    style="width: 80px"
                                                    *ngIf="
                                                        DrawRequestForm.value[
                                                            'Constructions'
                                                        ][i].TotalAmount != 0
                                                    "
                                                    matInput
                                                    formControlName="TotalGCPercent"
                                                    readonly="true"
                                                />%
                                            </ng-container>
                                        </td>
                                        <td align="center">
                                            <span *ngIf="i != 35">&nbsp;</span>
                                            <strong *ngIf="i == 35"
                                                >&nbsp;</strong
                                            >
                                        </td>
                                        <td align="center">
                                            <span *ngIf="i != 35">&nbsp;</span>
                                            <strong *ngIf="i == 35"
                                                >&nbsp;</strong
                                            >
                                        </td>
                                    </tr>
                                </ng-container>
                            </ng-container>
                        </tbody>
                    </table>
                </mat-card-content>
                <mat-card-actions class="matActions">
                    <p *ngIf="drawRequestBOAction == 'Accept'">
                        By Proceeding with this submittal, you understand,
                        acknowledge and accept that the site review conducted by
                        A.M. Renovation Consulting is for the sole purpose of
                        confirming that work associated with the funds requested
                        in Column 3 has been completed. You further understand,
                        acknowledge and accept that A.M. Renovation Consulting
                        (including its officers, employees and representatives)
                        <strong> is not associated</strong> with the city or
                        town, and
                        <strong>will not inspect the property</strong> for
                        compliance with applicable building codes.
                    </p>
                    <br />
                    <div class="btnSection">
                    <button
                        mat-raised-button
                        *ngIf="drawRequestBOAction == 'Accept'"
                        type="submit"
                        color="primary"
                    >
                        Send
                    </button>
                    <button
                        mat-raised-button
                        *ngIf="drawRequestBOAction == 'Accept'"
                        type="button"
                        (click)="cancelAction()"
                        color="primary"
                    >
                        Cancel
                    </button>
                    <button
                        mat-raised-button
                        type="button"
                        *ngIf="drawRequestBOAction != 'Accept'"
                        (click)="changeAction('Accept')"
                        color="primary"
                    >
                        Submit
                    </button>
                </div>
                </mat-card-actions>
            </form>
        </mat-card>
    </div>
</div>
