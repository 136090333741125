import { FuseNavigation } from "@fuse/types";

export const navigation: FuseNavigation[] = [
    {
        id: "applications",
        title: "",
        translate: "NAV.APPLICATIONS",
        type: "group",
        children: [
            {
                id: "Dashboard",
                title: "Dashboard",
                translate: "NAV.DASHBOARD.TITLE",
                type: "item",
                icon: "dashboard",
                url: "/dashboard",
            },
        ],
    },
    {
        id: "Users",
        title: "Users",
        type: "collapsable",
        icon: "account_box",
        children: [
            {
                id: "Borrower",
                title: "Borrower",
                type: "item",
                url: "/Borrower",
            },
            {
                id: "Consultant",
                title: "Consultant",
                type: "item",
                url: "/consultants",
            },
            {
                id: "GC",
                title: "General Contractor",
                type: "item",
                url: "/Contractor",
            },
            {
                id: "loanOfficer",
                title: "Loan Officer",
                type: "item",
                url: "/loan-officer",
            },
            {
                id: "loanProcessor",
                title: "Loan Processor",
                type: "item",
                url: "/loan-processor",
            },
            {
                id: "drawAdministrator",
                title: "Draw Administrator",
                type: "item",
                url: "/draw-administrator",
            },
            {
                id: "realtor",
                title: "Realtor",
                type: "item",
                url: "/realtor",
            },
            {
                id: "lender",
                title: "Lender",
                type: "item",
                url: "/lender",
            },
        ],
    },
    {
        id: "Properties",
        title: "Properties",
        type: "item",
        icon: "home",
        url: "/properties",
    },
    {
        id: "ConstructionItemsLibrary",
        title: "Construction Items Library",
        type: "item",
        icon: "subject",
        url: "/construction-items-library",
    },
    {
        id: "AllowableFees",
        title: "Allowable Fees",
        type: "item",
        icon: "payments",
        url: "/allowable-fees",
    },
    {
        id: "Settings",
        title: "Settings",
        type: "collapsable",
        icon: "settings",
        children: [
            {
                id: "ApplicationConfig",
                title: "Application",
                type: "collapsable",
                children: [
                    {
                        id: "Email",
                        title: "Email Settings",
                        type: "item",
                        url: "/emails",
                    },
                    {
                        id: "FormSetting",
                        title: "Consultant Liability Fields",
                        type: "item",
                        url: "/consultant-liability-form-setting",
                    },
                    {
                        id: "Config",
                        title: "System Settings",
                        type: "item",
                        url: "/203k-config",
                    },
                ],
            },
            {
                id: "People",
                title: "Roles & Permissions",
                type: "collapsable",
                children: [
                    {
                        id: "Permission",
                        title: "Permission",
                        type: "item",
                        url: "/permission",
                    },
                    {
                        id: "Role",
                        title: "Roles",
                        type: "item",
                        url: "/roles",
                    },
                ],
            },
        ],
    },
];
