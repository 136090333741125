import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { SessionService } from "../service/session.service";

var AWS = require("aws-sdk");
var uuid = require("uuid");

@Injectable({
    providedIn: "root",
})
export class PropertyService {
    constructor(
        private http: HttpClient,
        private sessionService: SessionService
    ) {}
    baseurl = this.sessionService.endpoint;
    AddBorrower(data) {
        return this.http.post<any>(this.baseurl + "users/create", data, {
            headers: this.sessionService.setTokenHeader(),
        });
    }
    signedDocError(data) {
        return this.http.post<any>(
            this.baseurl + "signed-envelope/signeddocerror",
            data,
            { headers: this.sessionService.setTokenHeader() }
        );
    }
    saveBorrowerActiveStep(data) {
        return this.http.post<any>(
            this.baseurl + "property/borrowerStepUpdate",
            data,
            { headers: this.sessionService.setTokenHeader() }
        );
    }
    UpdateBorrower(data) {
        return this.http.put<any>(this.baseurl + "lenders", data, {
            headers: this.sessionService.setTokenHeader(),
        });
    }

    AddConsultantLiability(data) {
        return this.http.post<any>(this.baseurl + "consultantliability", data, {
            headers: this.sessionService.setTokenHeader(),
        });
    }

    UpdateConsultantLiability(data) {
        return this.http.put<any>(this.baseurl + "consultantliability", data, {
            headers: this.sessionService.setTokenHeader(),
        });
    }

    AddConsultantAgreement(data) {
        return this.http.post<any>(
            this.baseurl + "fhaconsultantagreement",
            data,
            { headers: this.sessionService.setTokenHeader() }
        );
    }

    UpdateConsultantAgreement(data) {
        return this.http.put<any>(
            this.baseurl + "fhaconsultantagreement",
            data,
            { headers: this.sessionService.setTokenHeader() }
        );
    }

    AddHomeInsDisclosure(data) {
        return this.http.post<any>(
            this.baseurl + "fhaconsultantagreement",
            data,
            { headers: this.sessionService.setTokenHeader() }
        );
    }

    UpdateHomeInsDisclosure(data) {
        return this.http.put<any>(
            this.baseurl + "fhaconsultantagreement",
            data,
            { headers: this.sessionService.setTokenHeader() }
        );
    }

    GetUserList(data) {
        return this.http.post<any>(this.baseurl + "lenders/list", data, {
            headers: this.sessionService.setTokenHeader(),
        });
    }

    GetPropertyList(data) {
        return this.http.post<any>(this.baseurl + "property/list", data, {
            headers: this.sessionService.setTokenHeader(),
        });
    }

    GetPropertyDetails(PID) {
        return this.http.get<any>(this.baseurl + "property/" + PID, {
            headers: this.sessionService.setTokenHeader(),
        });
    }

    GetBlankDrawFormPdf(data) {
        return this.http.post<any>(
            this.baseurl + "initialdraw/blankdrawformpdf",
            data,
            { headers: this.sessionService.setTokenHeader() }
        );
    }
    AddProperty(data) {
        return this.http.post<any>(this.baseurl + "property", data, {
            headers: this.sessionService.setTokenHeader(),
        });
    }

    sendSorEmail(data) {
        return this.http.post<any>(this.baseurl + "sor/sendforreview", data, {
            headers: this.sessionService.setTokenHeader(),
        });
    }

    sorAcceptedByBo(data) {
        return this.http.post<any>(this.baseurl + "sor/soracceptedbybo", data, {
            headers: this.sessionService.setTokenHeader(),
        });
    }

    UpdateProperty(data) {
        return this.http.put<any>(this.baseurl + "property", data, {
            headers: this.sessionService.setTokenHeader(),
        });
    }

    UpdatePropertyBidStatus(data) {
        return this.http.put<any>(
            this.baseurl + "property/updatebidstatus",
            data,
            { headers: this.sessionService.setTokenHeader() }
        );
    }

    GetLoanOfficerUserList(data) {
        return this.http.post<any>(this.baseurl + "users/list", data, {
            headers: this.sessionService.setTokenHeader(),
        });
    }

    GetLoanProcessorUserList(data) {
        return this.http.post<any>(this.baseurl + "users/list", data, {
            headers: this.sessionService.setTokenHeader(),
        });
    }

    GetConsultentUserList(data) {
        return this.http.post<any>(this.baseurl + "users/list", data, {
            headers: this.sessionService.setTokenHeader(),
        });
    }

    GetContractorUserList(data) {
        return this.http.post<any>(this.baseurl + "users/list", data, {
            headers: this.sessionService.setTokenHeader(),
        });
    }

    GetBorrowerUserList(data) {
        return this.http.post<any>(this.baseurl + "users/list", data, {
            headers: this.sessionService.setTokenHeader(),
        });
    }

    GetUserDetailsList(userId) {
        return this.http.get<any>(this.baseurl + "users/" + userId, {
            headers: this.sessionService.setTokenHeader(),
        });
    }

    GetLenderList(data) {
        return this.http.post<any>(this.baseurl + "lenders/list", data, {
            headers: this.sessionService.setTokenHeader(),
        });
    }

    DeleteUser(userid) {
        return this.http.delete<any>(this.baseurl + "lenders/" + userid, {
            headers: this.sessionService.setTokenHeader(),
        });
    }

    UpdatePropertyStatus(data) {
        return this.http.put<any>(
            this.baseurl + "property/updatestatus",
            data,
            { headers: this.sessionService.setTokenHeader() }
        );
    }

    UpdatePropertyGoogleAlbum(data) {
        return this.http.put<any>(
            this.baseurl + "property/updatepropertygooglealbum",
            data,
            { headers: this.sessionService.setTokenHeader() }
        );
    }

    SendReviseBidMail(data) {
        return this.http.post<any>(
            this.baseurl + "property/sendrevisebidmail",
            data,
            { headers: this.sessionService.setTokenHeader() }
        );
    }

    SendAssignmentMailToGc(data) {
        return this.http.post<any>(
            this.baseurl + "property/sendassignmentmailtogc",
            data,
            { headers: this.sessionService.setTokenHeader() }
        );
    }

    SendAssignmentMailToDa(data) {
        return this.http.post<any>(
            this.baseurl + "property/sendassignmentmailtoda",
            data,
            { headers: this.sessionService.setTokenHeader() }
        );
    }

    GetSORPDF(data) {
        return this.http.post<any>(this.baseurl + "sor/generatepdf", data, {
            headers: this.sessionService.setTokenHeader(),
        });
    }

    SendSORForDocsign(data) {
        return this.http.get<any>(
            this.baseurl + "property/sendinitialpackagedocsign/" + data,
            { headers: this.sessionService.setTokenHeader() }
        );
    }

    SendFinalSORDocsign(data) {
        return this.http.get<any>(
            this.baseurl + "property/sendfinalpackagedocsign/" + data,
            { headers: this.sessionService.setTokenHeader() }
        );
    }
    PrintFinalSOR(data) {
        return this.http.get<any>(
            this.baseurl + "property/printfinalpackage/" + data,
            { headers: this.sessionService.setTokenHeader() }
        );
    }
    GetBORPDF(data) {
        return this.http.post<any>(
            this.baseurl + "bidonrepair/generatborpdf",
            data,
            { headers: this.sessionService.setTokenHeader() }
        );
    }
    GetPermitCertifications(data) {
        return this.http.get<any>(
            this.baseurl + "permitsCertifications/" + data,
            { headers: this.sessionService.setTokenHeader() }
        );
    }
    GetPermitCertificationsPDF(data) {
        return this.http.post<any>(
            this.baseurl + "permitsCertifications/generatepdf",
            data,
            { headers: this.sessionService.setTokenHeader() }
        );
    }
    AddPermitCertifications(data) {
        return this.http.post<any>(
            this.baseurl + "permitsCertifications/",
            data,
            { headers: this.sessionService.setTokenHeader() }
        );
    }
    UpdatePermitCertifications(data) {
        return this.http.put<any>(
            this.baseurl + "permitsCertifications/",
            data,
            { headers: this.sessionService.setTokenHeader() }
        );
    }
    GetBlankBORPDF(data) {
        return this.http.post<any>(
            this.baseurl + "bidonrepair/generatblankborpdf",
            data,
            { headers: this.sessionService.setTokenHeader() }
        );
    }
    downloadDrawRequestPDF(data) {
        return this.http.post<any>(
            this.baseurl + "initialdraw/generatedrawpdf",
            data,
            { headers: this.sessionService.setTokenHeader() }
        );
    }

    GetLenderDetailsList(data) {
        return this.http.get<any>(this.baseurl + "lenders/" + data, {
            headers: this.sessionService.setTokenHeader(),
        });
    }

    downloadZip(data) {
        return this.http.get<any>(
            this.baseurl + "property/downloadzip/" + data,
            { headers: this.sessionService.setTokenHeader() }
        );
    }

    downloadNHID(data) {
        return this.http.get<any>(
            this.baseurl + "property/downloadNHID/" + data,
            { headers: this.sessionService.setTokenHeader() }
        );
    }
    sendNonHomeToBO(data) {
        return this.http.get<any>(
            this.baseurl + "property/sendNonHomeToBO/" + data,
            { headers: this.sessionService.setTokenHeader() }
        );
    }

    downloadPDFCL(data) {
        return this.http.get<any>(
            this.baseurl + "property/generateConsultantLiabilityPdf/" + data,
            { headers: this.sessionService.setTokenHeader() }
        );
    }
    downloadPDFCA(data) {
        return this.http.get<any>(
            this.baseurl + "property/generateConsultantAgreementPdf/" + data,
            { headers: this.sessionService.setTokenHeader() }
        );
    }
    downloadIdentityOfInterest(data) {
        return this.http.get<any>(
            this.baseurl + "property/generateIdentityOfInterestPdf/" + data,
            { headers: this.sessionService.setTokenHeader() }
        );
    }
    downloadCertificationLetter(data) {
        return this.http.get<any>(
            this.baseurl + "property/generateCertificationLetterPdf/" + data,
            { headers: this.sessionService.setTokenHeader() }
        );
    }
    GetFhaConsultantAgreementDetails(data) {
        return this.http.post<any>(
            this.baseurl + "fhaconsultantagreement/list",
            data,
            { headers: this.sessionService.setTokenHeader() }
        );
    }
    printInvoiceDetails(data) {
        return this.http.post<any>(
            this.baseurl + "fhaconsultantagreement/printinvoicepdf",
            data,
            { headers: this.sessionService.setTokenHeader() }
        );
    }
    GetFhaConsultantLiabilityDetails(data) {
        return this.http.post<any>(
            this.baseurl + "consultantliability/list",
            data,
            { headers: this.sessionService.setTokenHeader() }
        );
    }

    GetDrawRequestList(data) {
        return this.http.post<any>(this.baseurl + "initialdraw/list", data, {
            headers: this.sessionService.setTokenHeader(),
        });
    }

    DrawRequestPDF(data) {
        return this.http.post<any>(
            this.baseurl + "initialdraw/drawrequestpdf",
            data,
            { headers: this.sessionService.setTokenHeader() }
        );
    }
    DrawRequestDelete(data) {
        return this.http.delete<any>(this.baseurl + "initialdraw/" + data, {
            headers: this.sessionService.setTokenHeader(),
        });
    }
    getDrawRequestById(data) {
        return this.http.get<any>(this.baseurl + "initialdraw/" + data, {
            headers: this.sessionService.setTokenHeader(),
        });
    }
    UpdatePropertyDraw(data) {
        return this.http.put<any>(
            this.baseurl + "property/updateinitialdraw",
            data,
            { headers: this.sessionService.setTokenHeader() }
        );
    }
    getSignedDraw(data) {
        return this.http.post<any>(
            this.baseurl + "signed-envelope/getDrawDoc",
            data,
            { headers: this.sessionService.setTokenHeader() }
        );
    }

    deleteSignedDraw(data) {
        return this.http.delete<any>(this.baseurl + "signed-envelope/" + data, {
            headers: this.sessionService.setTokenHeader(),
        });
    }
    SavePropertyDocument(data) {
        return this.http.post<any>(this.baseurl + "documents", data, {
            headers: this.sessionService.setTokenHeader(),
        });
    }

    GetPropertyDocument(data) {
        return this.http.post<any>(this.baseurl + "documents/list", data, {
            headers: this.sessionService.setTokenHeader(),
        });
    }
    DeletePropertyDocument(id) {
        return this.http.delete<any>(this.baseurl + "documents/" + id, {
            headers: this.sessionService.setTokenHeader(),
        });
    }
    GetPropertyDistance(data) {
        return this.http.post<any>(
            this.baseurl + "property/getdistance",
            data,
            { headers: this.sessionService.setTokenHeader() }
        );
    }

    GetUserByEmail(email) {
        return this.http.get<any>(this.baseurl + "users/email/" + email, {
            headers: this.sessionService.setTokenHeader(),
        });
    }

    GetPropertySignEnvelopesList(propertyId) {
        return this.http.get<any>(
            this.baseurl + "signed-envelope/list/" + propertyId,
            { headers: this.sessionService.setTokenHeader() }
        );
    }

    GetCompletedsignedEnvelope(data) {
        return this.http.post<any>(
            this.baseurl + "signed-envelope/completedenvelope",
            data,
            { headers: this.sessionService.setTokenHeader() }
        );
    }
    removeSignEnvelopeById(docId) {
        return this.http.delete<any>(
            this.baseurl + "signed-envelope/" + docId,
            { headers: this.sessionService.setTokenHeader() }
        );
    }
    GetPropertySignEnvelopeById(envelopeId) {
        return this.http.get<any>(
            this.baseurl + "signed-envelope/envelope/" + envelopeId,
            { headers: this.sessionService.setTokenHeader() }
        );
    }
    GetPropertySignDocById(docId) {
        return this.http.get<any>(this.baseurl + "signed-envelope/" + docId, {
            headers: this.sessionService.setTokenHeader(),
        });
    }
    updatePropertySignEnvelopes(data) {
        return this.http.put<any>(
            this.baseurl + "signed-envelope/update",
            data,
            { headers: this.sessionService.setTokenHeader() }
        );
    }
}
