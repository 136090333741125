<div class="content p-24 tbl">
    <ngx-loading class="ngxLoader" 
        [show]="loading"
        [config]="{ backdropBorderRadius: '3px' }"
    ></ngx-loading>
    <h1 class="m-0">Edit Property</h1>

    <!-- <a *ngIf="isList && userDetail.UserType != 'Borrower'" class="addBorrow" href="javascript:void(0)" (click)="isList=false;isAdd=true;isEdit=false;formReset()">
    <mat-icon _ngcontent-c102="" class="secondary-text s-22 mat-icon material-icons mat-icon-no-color" role="img" aria-hidden="true">add_circle</mat-icon> Add New Property</a>
  <a *ngIf="isAdd" class="addBorrow" href="javascript:void(0)" (click)="isAdd=false;isList=true;isEdit=false">
    <mat-icon _ngcontent-c102="" class="secondary-text s-22 mat-icon material-icons mat-icon-no-color" role="img" aria-hidden="true">view_list</mat-icon> List Property</a> -->
    <!-- <mat-form-field>
    <input [hidden]="!isList" matInput (keyup)="applyFilter($event.target)" placeholder="Filter">
  </mat-form-field> -->
    <a
        class="addBorrow"
        href="javascript:void(0)"
        routerLink="/property-details/{{ propertyId }}"
    >
        Back</a
    >
    <br />
    <div class="mat-elevation-z8" *ngIf="isEdit">
        <div class="container-login100">
            <mat-card class="my-form">
                <form
                    [formGroup]="add_property"
                    class="example-form"
                    (ngSubmit)="UpdateRecord()"
                >
                    <mat-card-header>
                        <mat-card-title>Edit Property</mat-card-title>
                    </mat-card-header>
                    <mat-card-content>
                        <mat-card>
                            <mat-card-header>
                                <mat-card-title
                                    >Property Address</mat-card-title
                                >
                            </mat-card-header>
                            <mat-card-content>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <mat-form-field
                                                    class="example-full-width"
                                                >
                                                    <span
                                                        class="mat-form-field-label-wrapper"
                                                        ><!----><label
                                                            class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                            >Address 1*
                                                        </label></span
                                                    >
                                                    <input
                                                        matInput
                                                        ngx-google-places-autocomplete
                                                        autocomplete="new-password"
                                                        (onAddressChange)="
                                                            AddressChange(
                                                                $event
                                                            )
                                                        "
                                                        formControlName="address1"
                                                        name="address1"
                                                    />
                                                    <!--    <input matInput placeholder="Address 1 *" formControlName="address1" name="add1"> -->
                                                    <div
                                                    class="error"
                                                    *ngIf="
                                                        (!add_property.controls[
                                                            'address1'
                                                        ].valid &&
                                                        add_property
                                                                .controls[
                                                                'address1'
                                                            ].touched) ||
                                                        (add_property.controls[
                                                            'address1'
                                                        ].invalid &&
                                                            submitted)
                                                    "
                                                >
                                                   Enter valid address1
                                                </div>
                                                </mat-form-field>
                                            </td>
                                            <td>
                                                <mat-form-field
                                                    class="example-full-width"
                                                >
                                                    <span
                                                        class="mat-form-field-label-wrapper"
                                                        ><!----><label
                                                            class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                            >Address 2
                                                        </label></span
                                                    >
                                                    <input
                                                        matInput
                                                        autocomplete="new-password"
                                                        formControlName="address2"
                                                        name="add2"
                                                    />
                                                </mat-form-field>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <mat-form-field
                                                    class="example-full-width"
                                                >
                                                    <span
                                                        class="mat-form-field-label-wrapper"
                                                        ><!----><label
                                                            class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                            >City *
                                                        </label></span
                                                    >
                                                    <input
                                                        matInput
                                                        autocomplete="new-password"
                                                        formControlName="city"
                                                        name="city"
                                                    />
                                                    <div
                                    class="error"
                                    *ngIf="
                                        (!add_property.controls[
                                            'city'
                                        ].valid &&
                                        add_property
                                                .controls[
                                                'city'
                                            ].touched) ||
                                        (add_property.controls[
                                            'city'
                                        ].invalid &&
                                            submitted)
                                    "
                                >
                                   Enter valid city
                                </div>
                                                </mat-form-field>
                                            </td>
                                            <td>
                                                <mat-form-field
                                                    class="example-full-width"
                                                >
                                                    <span
                                                        class="mat-form-field-label-wrapper"
                                                        ><!----><label
                                                            class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                            >State *
                                                        </label></span
                                                    >
                                                    <input
                                                        matInput
                                                        autocomplete="new-password"
                                                        formControlName="state"
                                                        name="state"
                                                    />
                                                    <div
                                    class="error"
                                    *ngIf="
                                        (!add_property.controls[
                                            'state'
                                        ].valid &&
                                        add_property
                                                .controls[
                                                'state'
                                            ].touched) ||
                                        (add_property.controls[
                                            'state'
                                        ].invalid &&
                                            submitted)
                                    "
                                >
                                   Enter valid state
                                </div>
                                                </mat-form-field>
                                            </td>
                                            <td>
                                                <mat-form-field
                                                    class="example-full-width"
                                                >
                                                    <span
                                                        class="mat-form-field-label-wrapper"
                                                        ><!----><label
                                                            class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                            >Zip code
                                                        </label></span
                                                    >
                                                    <input
                                                        matInput
                                                        autocomplete="new-password"
                                                        formControlName="zipcode"
                                                        name="zip"
                                                    />
                                                    <div
                                    class="error"
                                    *ngIf="
                                        (!add_property.controls[
                                            'zipcode'
                                        ].valid &&
                                        add_property
                                                .controls[
                                                'zipcode'
                                            ].touched) ||
                                        (add_property.controls[
                                            'zipcode'
                                        ].invalid &&
                                            submitted)
                                    "
                                >
                                   Enter valid zipcode
                                </div>
                                                </mat-form-field>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </mat-card-content>
                        </mat-card>
                        <br />
                        <mat-card>
                            <mat-card-header>
                                <mat-card-title>Borrower</mat-card-title>
                            </mat-card-header>
                            <mat-card-content>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <mat-form-field
                                                    class="example-full-width"
                                                >
                                                    <span
                                                        class="mat-form-field-label-wrapper"
                                                        ><!----><label
                                                            class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                            >E-mail *</label
                                                        ></span
                                                    >
                                                    <input
                                                        matInput
                                                        formControlName="email"
                                                        (blur)="
                                                            onBlurBorrower()
                                                        "
                                                        name="username"
                                                    />
                                                    <div
                                    class="error"
                                    *ngIf="
                                        (!add_property.controls[
                                            'email'
                                        ].valid &&
                                        add_property
                                                .controls[
                                                'email'
                                            ].touched) ||
                                        (add_property.controls[
                                            'email'
                                        ].invalid &&
                                            submitted)
                                    "
                                >
                                   Enter valid email
                                </div>
                                                </mat-form-field>
                                            </td>
                                            <td>
                                                <mat-form-field
                                                    class="example-full-width"
                                                >
                                                    <span
                                                        class="mat-form-field-label-wrapper"
                                                        ><!----><label
                                                            class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                            >First Name *</label
                                                        ></span
                                                    >
                                                    <input
                                                        matInput
                                                        formControlName="fname"
                                                        name="fname"
                                                    />
                                                    <div
                                    class="error"
                                    *ngIf="
                                        (!add_property.controls[
                                            'fname'
                                        ].valid &&
                                        add_property
                                                .controls[
                                                'fname'
                                            ].touched) ||
                                        (add_property.controls[
                                            'fname'
                                        ].invalid &&
                                            submitted)
                                    "
                                >
                                   Enter valid first name
                                </div>
                                                </mat-form-field>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <mat-form-field
                                                    class="example-full-width"
                                                >
                                                    <span
                                                        class="mat-form-field-label-wrapper"
                                                        ><!----><label
                                                            class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                            >Last Name *</label
                                                        ></span
                                                    >
                                                    <input
                                                        matInput
                                                        formControlName="lname"
                                                        name="lname"
                                                    />
                                                    <div
                                    class="error"
                                    *ngIf="
                                        (!add_property.controls[
                                            'lname'
                                        ].valid &&
                                        add_property
                                                .controls[
                                                'lname'
                                            ].touched) ||
                                        (add_property.controls[
                                            'lname'
                                        ].invalid &&
                                            submitted)
                                    "
                                >
                                   Enter valid last name
                                </div>
                                                </mat-form-field>
                                            </td>
                                            <td>
                                                <mat-form-field
                                                    class="example-full-width"
                                                >
                                                    <span
                                                        class="mat-form-field-label-wrapper"
                                                        ><!----><label
                                                            class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                            >Phone Number</label
                                                        ></span
                                                    >
                                                    <input
                                                        matInput
                                                        [mask]="maskPhoneFormat" 
                                                        placeholder="(000) 000-0000"
                                                        (keyup)="
                                                            keyDownPhoneFormat(
                                                                $event
                                                            )
                                                        "
                                                        (change)="
                                                            changePhoneFormat(
                                                                $event
                                                            )
                                                        "
                                                        formControlName="phone_no"
                                                        name="phone_no"
                                                    />
                                                    <div
                                                    class="error"
                                                    *ngIf="
                                                        (!add_property.controls[
                                                            'phone_no'
                                                        ].valid &&
                                                        add_property
                                                                .controls[
                                                                'phone_no'
                                                            ].touched) ||
                                                        (add_property.controls[
                                                            'phone_no'
                                                        ].invalid &&
                                                            submitted)
                                                    "
                                                >
                                                <div class="error" *ngIf="add_property?.controls['phone_no']?.errors?.mask">
                                                    Enter valid phone no.
                                                   </div>
                                                </div>
                                                </mat-form-field>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <!-- <button (click)="showAdditionalBorrower()">
                  <span *ngIf="displayAddBorrower"> Hide Additional Borrowers</span>
                  <span *ngIf="!displayAddBorrower"> Add Additional Borrowers</span>
                </button> -->
                            </mat-card-content>
                        </mat-card>
                        <br />
                        <br />

                        <!-- <mat-card *ngIf="displayAddBorrower">
              <mat-card-header>
                <mat-card-title>Additional Borrowers</mat-card-title>
              </mat-card-header>
              <mat-card-content>
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <mat-form-field class="example-full-width">
                          <input matInput placeholder="E-mail *" formControlName="addBemail" name="addBemail">
                        </mat-form-field>
                      </td>
                      <td>
                        <mat-form-field class="example-full-width">
                          <input matInput placeholder="First Name *" formControlName="addBfname" name="addBfname">
                        </mat-form-field>
                      </td>
                      <td>
                        <mat-form-field class="example-full-width">
                          <input matInput placeholder="Last Name *" formControlName="addBlname" name="addBlname">
                        </mat-form-field>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colspan="3">
                        <button type="button" (click)="addBorrower()">Save</button> &nbsp;&nbsp;&nbsp;
                        <button type="button" (click)="resetBorrower()">Reset</button>
                      </td>
                    </tr>
                  </tfoot>
                </table>
                <br>
                <br>
                <div class="mat-elevation-z8" *ngIf="additional_borrower.length>0">
                  <table>
                    <thead>
                      <tr>
                        <th>
                          Email
                        </th>
                        <th>
                          First Name
                        </th>
                        <th>
                          Last Name
                        </th>
                        <th>
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let borrower of additional_borrower ;let i=index">
                        <td>
                          {{borrower.Email}}
                        </td>
                        <td>
                          {{borrower.FirstName}}
                        </td>
                        <td>
                          {{borrower.LastName}}
                        </td>
                        <td>
                          <button (click)="removeBorrower(borrower)">Remove</button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </mat-card-content>
            </mat-card> -->

                        <mat-card
                            *ngIf="
                                add_property.value['transactionType'] ==
                                'Purchase'
                            "
                        >
                            <mat-card-header>
                                <mat-card-title>Realtor</mat-card-title>
                            </mat-card-header>
                            <mat-card-content>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <mat-form-field
                                                    class="example-full-width"
                                                >
                                                    <span
                                                        class="mat-form-field-label-wrapper"
                                                        ><!----><label
                                                            class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                            >E-mail</label
                                                        ></span
                                                    >
                                                    <input
                                                        matInput
                                                        formControlName="RTemail"
                                                        (blur)="onBlurRealtor()"
                                                        name="RTemail"
                                                    />
                                                    <div class="error" *ngIf="(!add_property.controls.RTemail.valid && add_property.controls.RTemail.touched) || (add_property.controls.RTemail.invalid && submitted)">
                            Enter valid email.
                          </div>
                                                </mat-form-field>
                                            </td>
                                            <td>
                                                <mat-form-field
                                                    class="example-full-width"
                                                >
                                                    <span
                                                        class="mat-form-field-label-wrapper"
                                                        ><!----><label
                                                            class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                            >First Name</label
                                                        ></span
                                                    >
                                                    <input
                                                        matInput
                                                        formControlName="RTfname"
                                                        name="RTfname"
                                                    />
                                                    <div class="error" *ngIf="(!add_property.controls.RTfname.valid && add_property.controls.RTfname.touched) || (add_property.controls.RTfname.invalid && submitted)">
                              Enter first name.
              
                            </div>
                                                </mat-form-field>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <mat-form-field
                                                    class="example-full-width"
                                                >
                                                    <span
                                                        class="mat-form-field-label-wrapper"
                                                        ><!----><label
                                                            class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                            >Last Name</label
                                                        ></span
                                                    >
                                                    <input
                                                        matInput
                                                        formControlName="RTlname"
                                                        name="RTlname"
                                                    />
                                                    <div class="error" *ngIf="(!add_property.controls.RTlname.valid && add_property.controls.RTlname.touched) || (add_property.controls.RTlname.invalid && submitted)">
                              Enter last name.
              
                            </div>
                                                </mat-form-field>
                                            </td>
                                            <td>
                                                <mat-form-field
                                                    class="example-full-width"
                                                >
                                                    <span
                                                        class="mat-form-field-label-wrapper"
                                                        ><!----><label
                                                            class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                            >Phone Number</label
                                                        ></span
                                                    >
                                                    <input
                                                        matInput
                                                        [mask]="maskPhoneFormat" 
                                                        placeholder="(000) 000-0000"
                                                        (keyup)="
                                                            keyDownPhoneFormat(
                                                                $event
                                                            )
                                                        "
                                                        (change)="
                                                            changePhoneFormat(
                                                                $event
                                                            )
                                                        "
                                                        formControlName="RTphone_no"
                                                        name="RTphone_no"
                                                    />
                                                    <div
                                                    class="error"
                                                    *ngIf="
                                                        (!add_property.controls[
                                                            'RTphone_no'
                                                        ].valid &&
                                                        add_property
                                                                .controls[
                                                                'RTphone_no'
                                                            ].touched) ||
                                                        (add_property.controls[
                                                            'RTphone_no'
                                                        ].invalid &&
                                                            submitted)
                                                    "
                                                >
                                                <div class="error" *ngIf="add_property?.controls['RTphone_no']?.errors?.mask">
                                                   Enter valid realtor phone no
                                                   </div>
                                                </div>
                                                </mat-form-field>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </mat-card-content>
                        </mat-card>
                        <br />
                        <br />
                        <mat-card
                            *ngIf="
                                add_property.value['loanProcessor'] == 'other'
                            "
                        >
                            <mat-card-header>
                                <mat-card-title>Loan Processors</mat-card-title>
                            </mat-card-header>
                            <mat-card-content>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <mat-form-field
                                                    class="example-full-width"
                                                >
                                                    <span
                                                        class="mat-form-field-label-wrapper"
                                                        ><!----><label
                                                            class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                            >E-mail</label
                                                        ></span
                                                    >
                                                    <input
                                                        matInput
                                                        (blur)="
                                                            onBlurLoanProcessor()
                                                        "
                                                        formControlName="LPemail"
                                                        name="LPemail"
                                                        required
                                                    />
                                                    <div class="error" *ngIf="(!add_property.controls.LPemail.valid && add_property.controls.LPemail.touched) || (add_property.controls.LPemail.invalid && submitted)">
                            Enter valid email.
                          </div>
                                                </mat-form-field>
                                            </td>
                                            <td>
                                                <mat-form-field
                                                    class="example-full-width"
                                                >
                                                    <span
                                                        class="mat-form-field-label-wrapper"
                                                        ><!----><label
                                                            class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                            >First Name</label
                                                        ></span
                                                    >
                                                    <input
                                                        matInput
                                                        formControlName="LPfname"
                                                        name="LPfname"
                                                        required
                                                    />
                                                    <div class="error" *ngIf="(!add_property.controls.LPfname.valid && add_property.controls.LPfname.touched) || (add_property.controls.LPfname.invalid && submitted)">
                              Enter first name.
              
                            </div>
                                                </mat-form-field>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <mat-form-field
                                                    class="example-full-width"
                                                >
                                                    <span
                                                        class="mat-form-field-label-wrapper"
                                                        ><!----><label
                                                            class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                            >Last Name</label
                                                        ></span
                                                    >
                                                    <input
                                                        matInput
                                                        formControlName="LPlname"
                                                        name="LPlname"
                                                    />
                                                    <div class="error" *ngIf="(!add_property.controls.LPlname.valid && add_property.controls.LPlname.touched) || (add_property.controls.LPlname.invalid && submitted)">
                              Enter last name.
              
                            </div>
                                                </mat-form-field>
                                            </td>
                                            <td>
                                                <mat-form-field
                                                    class="example-full-width"
                                                >
                                                    <span
                                                        class="mat-form-field-label-wrapper"
                                                        ><!----><label
                                                            class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                            >Phone Number</label
                                                        ></span
                                                    >
                                                    <input
                                                        matInput
                                                        [mask]="maskPhoneFormat" 
                                                        placeholder="(000) 000-0000"
                                                        (keyup)="
                                                            keyDownPhoneFormat(
                                                                $event
                                                            )
                                                        "
                                                        (change)="
                                                            changePhoneFormat(
                                                                $event
                                                            )
                                                        "
                                                        formControlName="LPphone_no"
                                                        name="LPphone_no"
                                                    />
                                                    <div
                                                    class="error"
                                                    *ngIf="
                                                        (!add_property.controls[
                                                            'LPphone_no'
                                                        ].valid &&
                                                        add_property
                                                                .controls[
                                                                'LPphone_no'
                                                            ].touched) ||
                                                        (add_property.controls[
                                                            'LPphone_no'
                                                        ].invalid &&
                                                            submitted)
                                                    "
                                                >
                                                <div class="error" *ngIf="add_property?.controls['LPphone_no']?.errors?.mask">
                                                   Enter valid loan processor phone no
                                                   </div>
                                                </div>
                                                </mat-form-field>
                                            </td>
                                        </tr>

                                        <!-- <tr>
                      <td>
                        <mat-form-field class="example-full-width">
                          <input matInput type="password" placeholder="Password" formControlName="LPpassword" name="LPpassword" required>
                         
                        </mat-form-field>
                      </td>
                      <td>

                      </td>
                    </tr> -->
                                    </tbody>
                                </table>
                            </mat-card-content>
                        </mat-card>

                        <mat-card
                            *ngIf="add_property.value['loanOfficer'] == 'other'"
                        >
                            <mat-card-header>
                                <mat-card-title>Loan Officer</mat-card-title>
                            </mat-card-header>
                            <mat-card-content>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <mat-form-field
                                                    class="example-full-width"
                                                >
                                                    <span
                                                        class="mat-form-field-label-wrapper"
                                                        ><!----><label
                                                            class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                            >E-mail
                                                        </label></span
                                                    >
                                                    <input
                                                        matInput
                                                        (blur)="
                                                            onBlurLoanOfficer()
                                                        "
                                                        formControlName="LOemail"
                                                        name="LOemail"
                                                        required
                                                    />
                                                    <div class="error" *ngIf="(!add_property.controls.LOemail.valid && add_property.controls.LOemail.touched) || (add_property.controls.LOemail.invalid && submitted)">
                            Enter valid email.
                          </div>
                                                </mat-form-field>
                                            </td>
                                            <td>
                                                <mat-form-field
                                                    class="example-full-width"
                                                >
                                                    <span
                                                        class="mat-form-field-label-wrapper"
                                                        ><!----><label
                                                            class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                            >First Name</label
                                                        ></span
                                                    >
                                                    <input
                                                        matInput
                                                        formControlName="LOfname"
                                                        name="LOfname"
                                                        required
                                                    />
                                                    <div class="error" *ngIf="(!add_property.controls.LOfname.valid && add_property.controls.LOfname.touched) || (add_property.controls.LOfname.invalid && submitted)">
                              Enter first name.
              
                            </div>
                                                </mat-form-field>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <mat-form-field
                                                    class="example-full-width"
                                                >
                                                    <span
                                                        class="mat-form-field-label-wrapper"
                                                        ><!----><label
                                                            class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                            >Last Name</label
                                                        ></span
                                                    >
                                                    <input
                                                        matInput
                                                        formControlName="LOlname"
                                                        name="LOlname"
                                                    />
                                                    <div class="error" *ngIf="(!add_property.controls.LOlname.valid && add_property.controls.LOlname.touched) || (add_property.controls.LOlname.invalid && submitted)">
                              Enter last name.
              
                            </div>
                                                </mat-form-field>
                                            </td>
                                            <td>
                                                <mat-form-field
                                                    class="example-full-width"
                                                >
                                                    <span
                                                        class="mat-form-field-label-wrapper"
                                                        ><!----><label
                                                            class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                            >Phone Number
                                                        </label></span
                                                    >
                                                    <input
                                                        matInput
                                                        [mask]="maskPhoneFormat" 
                                                        placeholder="(000) 000-0000"
                                                        (keyup)="
                                                            keyDownPhoneFormat(
                                                                $event
                                                            )
                                                        "
                                                        (change)="
                                                            changePhoneFormat(
                                                                $event
                                                            )
                                                        "
                                                        formControlName="LOphone_no"
                                                        name="LOphone_no"
                                                    />
                                                    <div
                                                    class="error"
                                                    *ngIf="
                                                        (!add_property.controls[
                                                            'LOphone_no'
                                                        ].valid &&
                                                        add_property
                                                                .controls[
                                                                'LOphone_no'
                                                            ].touched) ||
                                                        (add_property.controls[
                                                            'LOphone_no'
                                                        ].invalid &&
                                                            submitted)
                                                    "
                                                >
                                                <div class="error" *ngIf="add_property?.controls['LOphone_no']?.errors?.mask">
                                                   Enter valid loan officer phone no
                                                   </div>
                                                </div>
                                                </mat-form-field>
                                            </td>
                                        </tr>

                                        <!-- <tr>
                      <td>
                        <mat-form-field class="example-full-width">
                          <input matInput type="password" placeholder="Password" formControlName="LOpassword" name="LOpassword" required>
                        </mat-form-field>
                      </td>
                      <td>

                      </td>
                    </tr> -->
                                    </tbody>
                                </table>
                            </mat-card-content>
                        </mat-card>

                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <mat-form-field
                                            class="example-full-width"
                                        >
                                            <span
                                                class="mat-form-field-label-wrapper"
                                                ><!----><label
                                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                    >Lender *</label
                                                ></span
                                            >
                                            <mat-select
                                                formControlName="lender"
                                                (selectionChange)="filterLOLP()"
                                            >
                                                <mat-option value=""
                                                    >Select</mat-option
                                                >
                                                <mat-option
                                                    *ngFor="
                                                        let lender_value of lender_list
                                                    "
                                                    value="{{
                                                        lender_value.Id
                                                    }}"
                                                    >{{
                                                        lender_value.Name
                                                    }}</mat-option
                                                >
                                            </mat-select>
                                            <div
                                            class="error"
                                            *ngIf="
                                                (!add_property.controls[
                                                    'lender'
                                                ].valid &&
                                                add_property
                                                        .controls[
                                                        'lender'
                                                    ].touched) ||
                                                (add_property.controls[
                                                    'lender'
                                                ].invalid &&
                                                    submitted)
                                            "
                                        >
                                          Select lender
                                        </div>
                                        </mat-form-field>
                                    </td>
                                    <td></td>
                                </tr>
                                <tr
                                    *ngIf="
                                        add_property.value['lender'] != '' &&
                                        add_property.value['lender'] != null
                                    "
                                >
                                    <td>
                                        <mat-form-field>
                                            <span
                                                class="mat-form-field-label-wrapper"
                                                ><!----><label
                                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                    >Loan Officer *</label
                                                ></span
                                            >
                                            <mat-select
                                                formControlName="loanOfficer"
                                            >
                                                <mat-option value=""
                                                    >Select</mat-option
                                                >
                                                <mat-option
                                                    *ngFor="
                                                        let loan_officer_value of filter_loan_officer_list
                                                    "
                                                    value="{{
                                                        loan_officer_value.Id
                                                    }}"
                                                    >{{
                                                        loan_officer_value.FirstName
                                                    }}
                                                    {{
                                                        loan_officer_value.LastName
                                                    }}</mat-option
                                                >
                                                <mat-option value="other"
                                                    >Other</mat-option
                                                >
                                            </mat-select>
                                            <div
                                            class="error"
                                            *ngIf="
                                                (!add_property.controls[
                                                    'loanOfficer'
                                                ].valid &&
                                                add_property
                                                        .controls[
                                                        'loanOfficer'
                                                    ].touched) ||
                                                (add_property.controls[
                                                    'loanOfficer'
                                                ].invalid &&
                                                    submitted)
                                            "
                                        >
                                          Select loan officer
                                        </div>
                                        </mat-form-field>
                                    </td>
                                    <td>
                                        <mat-form-field
                                            class="example-full-width"
                                        >
                                            <span
                                                class="mat-form-field-label-wrapper"
                                                ><!----><label
                                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                    >Loan Processors</label
                                                ></span
                                            >
                                            <mat-select
                                                multiple
                                                formControlName="loanProcessor"
                                            >
                                                <mat-option
                                                    (onSelectionChange)="
                                                        onLoanProcessorSelection(
                                                            $event
                                                        )
                                                    "
                                                    class="notCheckbox"
                                                    value=""
                                                    >Select</mat-option
                                                >
                                                <mat-option
                                                    (onSelectionChange)="
                                                        onLoanProcessorSelection(
                                                            $event
                                                        )
                                                    "
                                                    *ngFor="
                                                        let loan_processor_value of filter_loan_processor_list
                                                    "
                                                    value="{{
                                                        loan_processor_value.Id
                                                    }}"
                                                    >{{
                                                        loan_processor_value.FirstName
                                                    }}
                                                    {{
                                                        loan_processor_value.LastName
                                                    }}</mat-option
                                                >
                                                <mat-option
                                                    (onSelectionChange)="
                                                        onLoanProcessorSelection(
                                                            $event
                                                        )
                                                    "
                                                    class="notCheckbox"
                                                    value="other"
                                                    >Other</mat-option
                                                >
                                            </mat-select>
                                            <div
                                            class="error"
                                            *ngIf="
                                                (!add_property.controls[
                                                    'loanProcessor'
                                                ].valid &&
                                                add_property
                                                        .controls[
                                                        'loanProcessor'
                                                    ].touched) ||
                                                (add_property.controls[
                                                    'loanProcessor'
                                                ].invalid &&
                                                    submitted)
                                            "
                                        >
                                          Select loan processor
                                        </div>
                                        </mat-form-field>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <br />

                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <mat-form-field
                                            class="example-full-width"
                                        >
                                            <span
                                                class="mat-form-field-label-wrapper"
                                                ><!----><label
                                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                    >FHA Case No</label
                                                ></span
                                            >
                                            <input
                                                matInput
                                                formControlName="fHACaseNo"
                                                name="fHACaseNo"
                                            />
                                            <div
                                            class="error"
                                            *ngIf="
                                                (!add_property.controls[
                                                    'fHACaseNo'
                                                ].valid &&
                                                add_property
                                                        .controls[
                                                        'fHACaseNo'
                                                    ].touched) ||
                                                (add_property.controls[
                                                    'fHACaseNo'
                                                ].invalid &&
                                                    submitted)
                                            "
                                        >
                                         Enter valid fHACaseNo
                                        </div>
                                        </mat-form-field>
                                    </td>
                                    <td>
                                        <mat-form-field>
                                            <span
                                                class="mat-form-field-label-wrapper"
                                                ><!----><label
                                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                    >Loan Type *</label
                                                ></span
                                            >
                                            <mat-select
                                                formControlName="loanType"
                                            >
                                                <mat-option value="203K"
                                                    >203K</mat-option
                                                >
                                                <mat-option value="Homestyle"
                                                    >Homestyle</mat-option
                                                >
                                            </mat-select>
                                            <div
                                            class="error"
                                            *ngIf="
                                                (!add_property.controls[
                                                    'loanType'
                                                ].valid &&
                                                add_property
                                                        .controls[
                                                        'loanType'
                                                    ].touched) ||
                                                (add_property.controls[
                                                    'loanType'
                                                ].invalid &&
                                                    submitted)
                                            "
                                        >
                                         Select Loan Type
                                        </div>
                                        </mat-form-field>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <mat-form-field>
                                            <span
                                                class="mat-form-field-label-wrapper"
                                                ><!----><label
                                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                    >Consultant *
                                                </label></span
                                            >
                                            <mat-select
                                                formControlName="consultant"
                                            >
                                                <mat-option value=""
                                                    >Select</mat-option
                                                >
                                                <mat-option
                                                    *ngFor="
                                                        let consultent_value of consultent_list
                                                    "
                                                    value="{{
                                                        consultent_value.Id
                                                    }}"
                                                    >{{
                                                        consultent_value.FirstName
                                                    }}
                                                    {{
                                                        consultent_value.LastName
                                                    }}</mat-option
                                                >
                                            </mat-select>
                                            <div
                                            class="error"
                                            *ngIf="
                                                (!add_property.controls[
                                                    'consultant'
                                                ].valid &&
                                                add_property
                                                        .controls[
                                                        'consultant'
                                                    ].touched) ||
                                                (add_property.controls[
                                                    'consultant'
                                                ].invalid &&
                                                    submitted)
                                            "
                                        >
                                         Select Consultant
                                        </div>
                                        </mat-form-field>
                                    </td>
                                    <td>
                                        <mat-form-field>
                                            <span
                                                class="mat-form-field-label-wrapper"
                                                ><!----><label
                                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                    >Transaction Type
                                                </label></span
                                            >
                                            <mat-select
                                                formControlName="transactionType"
                                            >
                                                <mat-option value="Purchase"
                                                    >Purchase</mat-option
                                                >
                                                <mat-option value="Refinance"
                                                    >Refinance</mat-option
                                                >
                                            </mat-select>
                                        </mat-form-field>
                                    </td>
                                </tr>
                                <!-- <tr>
                  <td>
                      <mat-form-field class="example-full-width">
                          <mat-select placeholder="Agreement Signed By LO" formControlName="agreementSignedByBo">
                            <mat-option value="">None</mat-option>
                            <mat-option value="no">No</mat-option>
                            <mat-option value="yes">Yes</mat-option>
                          </mat-select>
                        </mat-form-field>
                  </td>
                  <td>
                    
                    <mat-form-field class="example-full-width">
                      <input matInput placeholder="Latitude" formControlName="latitude" name="latitude">
                    </mat-form-field>
                  </td>
                </tr> -->
                                <!-- <tr>
                  <td>
                    <mat-form-field class="example-full-width">
                      <input matInput placeholder="Longitude" formControlName="longitude" name="longitude">
                    </mat-form-field>
                  </td>
                  <td>

                  </td>
                </tr> -->
                                <tr>
                                    <td>
                                        <mat-form-field
                                            class="example-full-width"
                                        >
                                            <span
                                                class="mat-form-field-label-wrapper"
                                                ><!----><label
                                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                    >Loan No</label
                                                ></span
                                            >
                                            <input
                                                matInput
                                                formControlName="LoanNo"
                                                name="LoanNo"
                                            />
                                            <div
                                            class="error"
                                            *ngIf="
                                                (!add_property.controls[
                                                    'LoanNo'
                                                ].valid &&
                                                add_property
                                                        .controls[
                                                        'LoanNo'
                                                    ].touched) ||
                                                (add_property.controls[
                                                    'LoanNo'
                                                ].invalid &&
                                                    submitted)
                                            "
                                        >
                                        Enter valid Loan No
                                        </div>
                                        </mat-form-field>
                                    </td>
                                    <td>&nbsp;</td>
                                </tr>
                            </tbody>
                        </table>

                        <br />
                        <br />
                        <mat-card *ngIf="invitedGeneralContractors.length > 0">
                            <mat-card-header>
                                <mat-card-title
                                    >General Contractor</mat-card-title
                                >
                            </mat-card-header>
                            <mat-card-content>
                                <table class="invitedGClist_Edit">
                                    <tbody>
                                        <!--  <tr>
                      <td>
                        <mat-form-field class="example-full-width">
                          <mat-select multiple placeholder="General Contractors" formControlName="InvitedGeneralContractors">
                            <mat-option (onSelectionChange)="onGCSelection($event)" class="notCheckbox">Select</mat-option>
                            <mat-option (onSelectionChange)="onGCSelection($event)" *ngFor="let gc_value of invitedGeneralContractors" value="{{gc_value.Id}}">{{gc_value.FirstName}} {{gc_value.LastName}}</mat-option>
                            <mat-option (onSelectionChange)="onGCSelection($event)" class="notCheckbox"  value="other">Other</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </td>
                      <td>
                      </td>
                    </tr> -->

                                        <tr
                                            *ngFor="
                                                let gc_value of invitedGeneralContractors;
                                                let i = index
                                            "
                                        >
                                            <td>
                                                <mat-checkbox
                                                    class="example-radio-group"
                                                    formControlName="InvitedGeneralContractors"
                                                    (change)="
                                                        onGCSelection($event)
                                                    "
                                                    value="{{ gc_value.Id }}"
                                                    [checked]="true"
                                                ></mat-checkbox>
                                            </td>
                                            <td>
                                                {{ gc_value.EmailId }}
                                            </td>
                                            <td>
                                                {{ gc_value.FirstName }}
                                            </td>
                                            <td>
                                                {{ gc_value.LastName }}
                                            </td>
                                            <td>
                                                {{ gc_value.Phone }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </mat-card-content>
                        </mat-card>

                        <mat-card *ngIf="invitedGeneralContractors.length == 0">
                            <mat-card-header>
                                <mat-card-title
                                    >General Contractor</mat-card-title
                                >
                            </mat-card-header>
                            <mat-card-content>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <mat-form-field
                                                    class="example-full-width"
                                                >
                                                    <span
                                                        class="mat-form-field-label-wrapper"
                                                        ><!----><label
                                                            class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                            >E-mail</label
                                                        ></span
                                                    >
                                                    <input
                                                        matInput
                                                        formControlName="gcemail"
                                                        (blur)="
                                                            onBlurGenralContractor()
                                                        "
                                                        name="gcusername"
                                                    />
                                                    <div
                                                        class="error"
                                                        *ngIf="
                                                            (!add_property
                                                                .controls[
                                                                'gcemail'
                                                            ].valid &&
                                                                add_property
                                                                    .controls[
                                                                    'gcemail'
                                                                ].touched) ||
                                                            (add_property
                                                                .controls[
                                                                'gcemail'
                                                            ].invalid &&
                                                                submitted)
                                                        "
                                                    >
                                                        Enter valid email.
                                                    </div>
                                                </mat-form-field>
                                            </td>
                                            <td>
                                                <mat-form-field
                                                    class="example-full-width"
                                                >
                                                    <span
                                                        class="mat-form-field-label-wrapper"
                                                        ><!----><label
                                                            class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                            >First Name</label
                                                        ></span
                                                    >
                                                    <input
                                                        matInput
                                                        formControlName="gcfname"
                                                        name="gcfname"
                                                    />
                                                    <div
                                                        class="error"
                                                        *ngIf="
                                                            (!add_property
                                                                .controls[
                                                                'gcfname'
                                                            ].valid &&
                                                                add_property
                                                                    .controls[
                                                                    'gcfname'
                                                                ].touched) ||
                                                            (add_property
                                                                .controls[
                                                                'gcfname'
                                                            ].invalid &&
                                                                submitted)
                                                        "
                                                    >
                                                        Enter first name.
                                                    </div>
                                                </mat-form-field>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <mat-form-field
                                                    class="example-full-width"
                                                >
                                                    <span
                                                        class="mat-form-field-label-wrapper"
                                                        ><!----><label
                                                            class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                            >Last Name</label
                                                        ></span
                                                    >
                                                    <input
                                                        matInput
                                                        formControlName="gclname"
                                                        name="gclname"
                                                    />
                                                    <div
                                                        class="error"
                                                        *ngIf="
                                                            (!add_property
                                                                .controls[
                                                                'gclname'
                                                            ].valid &&
                                                                add_property
                                                                    .controls[
                                                                    'gclname'
                                                                ].touched) ||
                                                            (add_property
                                                                .controls[
                                                                'gclname'
                                                            ].invalid &&
                                                                submitted)
                                                        "
                                                    >
                                                        Enter last name.
                                                    </div>
                                                </mat-form-field>
                                            </td>
                                            <td>
                                                <mat-form-field
                                                    class="example-full-width"
                                                >
                                                    <span
                                                        class="mat-form-field-label-wrapper"
                                                        ><!----><label
                                                            class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                            >Phone Number
                                                        </label></span
                                                    >
                                                    <input
                                                        matInput
                                                        [mask]="maskPhoneFormat" 
                                                        placeholder="(000) 000-0000"
                                                        formControlName="gcphone_no"
                                                        name="gcphone_no"
                                                    />
                                                    <div
                                            class="error"
                                            *ngIf="
                                                (!add_property.controls[
                                                    'gcphone_no'
                                                ].valid &&
                                                add_property
                                                        .controls[
                                                        'gcphone_no'
                                                    ].touched) ||
                                                (add_property.controls[
                                                    'gcphone_no'
                                                ].invalid &&
                                                    submitted)
                                            "
                                        >
                                        <div class="error" *ngIf="add_property?.controls['gcphone_no']?.errors?.mask">
                                        Enter valid GC phone no
                                        </div>
                                        </div>
                                                </mat-form-field>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </mat-card-content>
                        </mat-card>
                        <br />
                        <br />
                        <ng-container *ngIf="PropertyStatusChecked == true">
                            <label>Property Status is Active : </label>
                            <mat-slide-toggle
                                color="primary"
                                (change)="changePropertyStatus($event)"
                                [checked]="PropertyStatusChecked"
                            ></mat-slide-toggle>
                        </ng-container>
                        <ng-container *ngIf="PropertyStatusChecked != true">
                            <label>Property Status is Inactive : </label>
                            <mat-slide-toggle
                                color="primary"
                                (change)="changePropertyStatus($event)"
                                [checked]="PropertyStatusChecked"
                            ></mat-slide-toggle>
                        </ng-container>
                        <br />
                        <ng-container *ngIf="PropertyCompletedChecked == true">
                            <label>Property is Completed : </label>
                            <mat-checkbox
                                class="example-radio-group"
                                (change)="changePropertyStatusCompleted($event)"
                                [checked]="PropertyCompletedChecked"
                            ></mat-checkbox>
                        </ng-container>
                        <ng-container *ngIf="PropertyCompletedChecked != true">
                            <label>Property is Incomplete : </label>
                            <mat-checkbox
                                class="example-radio-group"
                                (change)="changePropertyStatusCompleted($event)"
                                [checked]="PropertyCompletedChecked"
                            ></mat-checkbox>
                        </ng-container>
                    </mat-card-content>
                    <mat-card-actions>
                        <button mat-raised-button type="submit" color="primary">
                            Save
                        </button>
                    </mat-card-actions>
                </form>
            </mat-card>
        </div>
    </div>
</div>
