import {
    Component,
    OnInit,
    ViewChild,
    ElementRef,
    Pipe,
    PipeTransform,
    Inject,
} from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { FormGroup, Validators, FormControl } from "@angular/forms";
import { MatDialog, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { DataSource } from "@angular/cdk/table";
import { NotifierService } from "angular-notifier";
import { PropertyService } from "../property/property.service";
import { SessionService } from "../service/session.service";
// import * as jsPDF from 'jspdf';
import { BorrowerService } from "../borrower/borrower.service";
export interface UserData {
    id: string;
    name: string;
    email: string;
    lastLogin: Date;
}
export interface DialogData {
    propertyId: string;
}
@Component({
    selector: "app-specification-repair-review",
    templateUrl: "./specification-repair-review.component.html",
    styleUrls: ["./specification-repair-review.component.scss"],
})
export class SpecificationRepairReviewComponent implements OnInit {
    public editorValue: string = "";
    baseurl = this.sessionService.endpoint;
    displayedColumns: string[] = ["id", "name", "email", "lstLogin"];
    dataSource: MatTableDataSource<UserData>;
    mobnumPattern = "^(d{3})sd{3}-d{4}";
    sor_review_status = new FormGroup({
        statusType: new FormControl("Accept", [Validators.required]),
        Revisions: new FormControl(null),
    });
    @ViewChild("customNotification") customNotificationTmpl;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild("content") content: ElementRef;
    @ViewChild("ConsultantAgreement") ConsultantAgreement: ElementRef;
    @ViewChild("ConsultantLiability") ConsultantLiability: ElementRef;
    isList: boolean = true;
    notifier: any;
    isAdd: boolean = false;
    isEdit: boolean = false;
    submitted: boolean = false;
    favoriteSeason: string;
    loading = false;
    propertyDetails: any = {};
    borrowerDetails: any = {};
    consultantDetails: any = {};
    LODetails: any = {};
    RealtorDetails: any = {};
    LenderDetails: any = {};
    fhaconsultantagreement: any = {};
    fhaliabilityagreement: any = {};
    pdfData: any;
    pdfdataShow = false;
    pdfData1: any;
    propertyId: any;

    userDetail: any = {};
    propertyDetailsShow: boolean = false;
    reviewOption: any = [
        { id: "Bid Process Started", value: "Accept" },
        { id: "Revise", value: "Revise Draft" },
    ];
    reviewOptionForGC: any = [{ id: "Reviewed", value: "Reviewed" }];

    constructor(
        @Inject(ActivatedRoute) private route: ActivatedRoute,
        public dialog: MatDialog,
        @Inject(Router) private router: Router,
        private _bservice: PropertyService,
        private sessionService: SessionService,
        private borrowerService: BorrowerService,
        @Inject(DomSanitizer) private sanitizer: DomSanitizer,
        private notifierService: NotifierService
    ) {
        this.notifier = notifierService;
    }

    ngOnInit() {
        var propertyId = this.route.snapshot.paramMap.get("propertyId");
        this.propertyId = propertyId;
        if (this.sessionService.get("userInfo") != undefined) {
            this.userDetail = JSON.parse(this.sessionService.get("userInfo"));
        }
        this.getPropertyDetails(propertyId);
        this.GetSORPDF(propertyId);
        this.getFhaConsultantAgreementDetails(propertyId);
        this.getFhaConsultantLiabilityDetails(propertyId);
    }
    showNotification(nType, nMessage) {
        this.notifier.show({
            message: nMessage,
            type: nType,
            template: this.customNotificationTmpl,
        });
    }
    SorReviewStatus() {
        this.submitted = true;
        if (this.sor_review_status.invalid) {
            return;
        } else {
            this.submitted = false;
            var formData = this.sor_review_status.value;
            if (formData.statusType == "Bid Process Started") {
                if (
                    (this.propertyDetails.InvitedGeneralContractors != null &&
                        this.propertyDetails.InvitedGeneralContractors !=
                            undefined &&
                        this.propertyDetails.InvitedGeneralContractors.length >
                            0 &&
                        this.propertyDetails.InvitedGeneralContractors[0] !=
                            null &&
                        this.propertyDetails.InvitedGeneralContractors[0] !=
                            undefined &&
                        this.propertyDetails.InvitedGeneralContractors[0] !=
                            "") ||
                    (this.propertyDetails.GeneralContractorId != undefined &&
                        this.propertyDetails.GeneralContractorId != null)
                ) {
                    var propertyStatusData = {
                        Id: parseInt(
                            this.route.snapshot.paramMap.get("propertyId")
                        ),
                        Status: formData.statusType,
                        CreatedBy: this.userDetail.EmailId,
                        UpdatedBy: this.userDetail.EmailId,
                        ConsultantId: this.propertyDetails.ConsultantId,
                        Comment: formData.Revisions,
                    };

                    this._bservice
                        .UpdatePropertyStatus(propertyStatusData)
                        .subscribe(
                            (response) => {
                                var data = {
                                    EmailId: "",
                                    PropertyId: parseInt(
                                        this.route.snapshot.paramMap.get(
                                            "propertyId"
                                        )
                                    ),
                                    PageSize: 100000,
                                    LastEvaluatedKey: 0,
                                    UserType:
                                        this.sessionService.userType[1].Value,
                                };
                                this.borrowerService
                                    .GetSorDetails(data)
                                    .subscribe((response) => {
                                        if (
                                            response != null &&
                                            response.Items.length > 0
                                        ) {
                                            var today = new Date();
                                            let propertyData: any;
                                            if (
                                                this.propertyDetails
                                                    .SubmittedBids.length > 0
                                            ) {
                                                propertyData = {
                                                    PropertyId: parseInt(
                                                        this.route.snapshot.paramMap.get(
                                                            "propertyId"
                                                        )
                                                    ),
                                                    Id: parseInt(
                                                        response.Items[0].Id
                                                    ),
                                                    Approved: true,
                                                    ApprovedDate: today,
                                                    revisedStatus: true,
                                                };
                                            } else {
                                                propertyData = {
                                                    PropertyId: parseInt(
                                                        this.route.snapshot.paramMap.get(
                                                            "propertyId"
                                                        )
                                                    ),
                                                    Id: parseInt(
                                                        response.Items[0].Id
                                                    ),
                                                    Approved: true,
                                                    ApprovedDate: today,
                                                    revisedStatus: false,
                                                };
                                            }

                                            this._bservice
                                                .sorAcceptedByBo(propertyData)
                                                .subscribe((response) => {
                                                    var data = {
                                                        Id: this.propertyId,
                                                        BorrowerActiveStep: 5,
                                                    };
                                                    this._bservice
                                                        .saveBorrowerActiveStep(
                                                            data
                                                        )
                                                        .subscribe(
                                                            (response) => {
                                                                this.sessionService.notifier.notify(
                                                                    "success",
                                                                    "Status updated."
                                                                );
                                                                this.router.navigate(
                                                                    [
                                                                        "/property-details",
                                                                        this
                                                                            .propertyId,
                                                                    ]
                                                                );
                                                            }
                                                        );
                                                });
                                        } else {
                                            var data = {
                                                Id: this.propertyId,
                                                BorrowerActiveStep: 5,
                                            };
                                            this._bservice
                                                .saveBorrowerActiveStep(data)
                                                .subscribe((response) => {
                                                    this.sessionService.notifier.notify(
                                                        "success",
                                                        "Status updated."
                                                    );
                                                    this.router.navigate([
                                                        "/property-details",
                                                        this.propertyId,
                                                    ]);
                                                });
                                        }
                                    });
                            },
                            (error) => {
                                this.sessionService.notifier.notify(
                                    "error",
                                    error.error.message
                                );
                                this.loading = false;
                            }
                        );
                } else {
                    this.showNotification(
                        "error",
                        'Please invite a general contractor. <a class="addBorrow" href="' +
                            window.location.origin +
                            "/general-contractor-info/" +
                            this.propertyId +
                            '" >Add Contractor</a>.'
                    );
                }
            } else {
                var propertyStatusData = {
                    Id: parseInt(
                        this.route.snapshot.paramMap.get("propertyId")
                    ),
                    Status: formData.statusType,
                    CreatedBy: this.userDetail.EmailId,
                    UpdatedBy: this.userDetail.EmailId,
                    ConsultantId: this.propertyDetails.ConsultantId,
                    Comment: formData.Revisions,
                };
                this._bservice
                    .UpdatePropertyStatus(propertyStatusData)
                    .subscribe(
                        (response) => {
                            this.sessionService.notifier.notify(
                                "success",
                                "Status updated."
                            );
                            this.router.navigate([
                                "/property-details",
                                this.propertyId,
                            ]);
                        },
                        (error) => {
                            this.sessionService.notifier.notify(
                                "error",
                                error.error.message
                            );
                            this.loading = false;
                        }
                    );
            }
        }
    }

    SorReviewStatusByGC(propertyDetails) {
        var formData = this.sor_review_status.value;

        if (formData.statusType == "Accept") {
            if (
                propertyDetails.GeneralContractorBids == undefined ||
                propertyDetails.GeneralContractorBids == ""
            ) {
                propertyDetails.GeneralContractorBids = [];
                propertyDetails.GeneralContractorBids.push(this.userDetail.Id);
            } else {
                propertyDetails.GeneralContractorBids.push(this.userDetail.Id);
            }
            propertyDetails.BidStatus = "yes";
            propertyDetails.BiddingProcessStart = "yes";
            propertyDetails.GCName =
                this.userDetail.FirstName + " " + this.userDetail.LastName;
            this._bservice.UpdatePropertyBidStatus(propertyDetails).subscribe(
                (response) => {
                    this.sessionService.notifier.notify(
                        "success",
                        "You are reviewed SOR of this property."
                    );
                    this.isList = true;
                    this.isAdd = false;
                    this.isEdit = false;
                    const container = document.querySelector("#container-3");
                    container.scrollTop = 0;

                    this.openDialog(propertyDetails.Id);
                    this.loading = false;
                },
                (error) => {
                    this.sessionService.notifier.notify(
                        "error",
                        error.error.message
                    );
                    this.loading = false;
                }
            );
        } else {
            this.router.navigate(["/properties"]);
        }
    }

    openDialog(id) {
        const dialogRef = this.dialog.open(DialogContentExampleDialog, {
            data: {
                propertyId: id,
            },
        });
    }

    getPropertyDetails(propertyId) {
        this.loading = true;

        this._bservice.GetPropertyDetails(propertyId).subscribe(
            (response) => {
                if (response != null) {
                    this.propertyDetails = response;
                    this.propertyDetailsShow = true;

                    this.loading = false;
                    this._bservice
                        .GetUserDetailsList(response.BorrowerId)
                        .subscribe(
                            (borrowerResponse) => {
                                if (borrowerResponse != null) {
                                    this.borrowerDetails = borrowerResponse;

                                    this._bservice
                                        .GetUserDetailsList(
                                            response.ConsultantId
                                        )
                                        .subscribe(
                                            (consultantResponse) => {
                                                if (
                                                    consultantResponse != null
                                                ) {
                                                    this.consultantDetails =
                                                        consultantResponse;

                                                    this._bservice
                                                        .GetUserDetailsList(
                                                            response.LoanOfficerId
                                                        )
                                                        .subscribe(
                                                            (LOResponse) => {
                                                                if (
                                                                    LOResponse !=
                                                                    null
                                                                ) {
                                                                    this.LODetails =
                                                                        LOResponse;

                                                                    this.loading =
                                                                        false;
                                                                }
                                                            },
                                                            (error) => {}
                                                        );
                                                }
                                            },
                                            (error) => {}
                                        );
                                }
                            },
                            (error) => {}
                        );

                    this._bservice
                        .GetUserDetailsList(response.RealtorId)
                        .subscribe(
                            (RealtorResponse) => {
                                if (RealtorResponse != null) {
                                    this.RealtorDetails = RealtorResponse;

                                    this.loading = false;
                                }
                            },
                            (error) => {}
                        );

                    this._bservice
                        .GetLenderDetailsList(response.LenderId)
                        .subscribe(
                            (LenderResponse) => {
                                if (LenderResponse != null) {
                                    this.LenderDetails = LenderResponse;

                                    this.loading = false;
                                }
                            },
                            (error) => {}
                        );
                }
            },
            (error) => {}
        );
    }

    getFhaConsultantAgreementDetails(propertyId) {
        var data = {
            PropertyId: propertyId,
        };
        this._bservice.GetFhaConsultantAgreementDetails(data).subscribe(
            (response) => {
                if (response.Items.length > 0) {
                    this.fhaconsultantagreement = response.Items[0];
                } else {
                    this.fhaconsultantagreement = {
                        ConsultantFee: 500,
                        CreatedBy: "admin@gmail.com",
                        CreatedDate: "2019-05-03T17:32:05.690Z",
                        Id: 7,
                        PropertyId: "18",
                        SignatureDateFHA: "4/26/2019",
                        Title: "  Agreement",
                        UpdateDate: "2019-05-03T17:32:05.690Z",
                        UpdatedBy: "admin@gmail.com",
                    };
                }
            },
            (error) => {
                this.sessionService.notifier.notify(
                    "error",
                    error.error.message
                );
                this.loading = false;
            }
        );
    }

    getFhaConsultantLiabilityDetails(propertyId) {
        var data = {
            PropertyId: propertyId,
        };
        this._bservice.GetFhaConsultantLiabilityDetails(data).subscribe(
            (response) => {
                if (response.Items.length > 0) {
                    this.fhaliabilityagreement = response.Items[0];
                } else {
                    this.fhaliabilityagreement = {
                        AgreementDate: "4/26/2019",
                        ChangeOrder: 204,
                        ChangeScopeWorkMax: 209,
                        ChangeScopeWorkMin: 208,
                        ConsultantFee: 500,
                        ConsultantFeeReimbursed: 200,
                        CreatedBy: "admin@gmail.com",
                        CreatedDate: "2019-05-03T17:58:01.691Z",
                        DrawRequestsMax: 206,
                        DrawRequestsMin: 205,
                        DrawingBelow100k: 201,
                        DrawingBetween100k150k: 202,
                        DrawingOver150k: 203,
                        Id: 6,
                        MultiFamilyDwelling: 300,
                        PropertyId: "18",
                        SelfHelpDraw: 207,
                        Title: " ",
                        UpdateDate: "2019-05-03T17:58:01.691Z",
                        UpdatedBy: "admin@gmail.com",
                        WorkPlan: 301,
                    };
                }
            },
            (error) => {
                this.sessionService.notifier.notify(
                    "error",
                    error.error.message
                );
                this.loading = false;
            }
        );
    }

    // downloadPDF () {
    //   let doc = new jsPDF();
    //   let specialElementHandlers = {
    //     "#editor": function (element, renderer) {
    //       return true;
    //     }
    //   };

    //   let content = this.content.nativeElement;
    //   doc.fromHTML(content.innerHTML, 15, 15, {
    //     'width': 190,
    //     'elementHandlers': specialElementHandlers
    //   });

    // }

    sorEmailToBorrower(
        Propertyid,
        borrowerEmail,
        borrowerFirstTName,
        borrowerLastTName
    ) {
        var data = {
            EmailId: "",
            PropertyId: Propertyid,
            PageSize: 100000,
            LastEvaluatedKey: 0,
            UserType: this.sessionService.userType[1].Value,
        };
        this.borrowerService.GetSorDetails(data).subscribe((response) => {
            if (response != null && response.Items.length > 0) {
                var data = {
                    PropertyId: Propertyid,
                    Id: response.Items[0].Id,
                    SorStatus: "sendForReview",
                    borrowerEmail: borrowerEmail,
                    borrowerName: borrowerFirstTName + " " + borrowerLastTName,
                };
                this._bservice.sendSorEmail(data).subscribe(
                    (response) => {
                        var propertyStatusData = {
                            Id: this.route.snapshot.paramMap.get("propertyId"),
                            Status: "DRAFT SOR sent to Borrower",
                            CreatedBy: this.userDetail.EmailId,
                            UpdatedBy: this.userDetail.EmailId,
                        };
                        this._bservice
                            .UpdatePropertyStatus(propertyStatusData)
                            .subscribe(
                                (response) => {
                                    this.sessionService.notifier.notify(
                                        "success",
                                        "Send Email to Borrower successfully."
                                    );
                                },
                                (error) => {
                                    this.sessionService.notifier.notify(
                                        "error",
                                        error.error.message
                                    );
                                    this.loading = false;
                                }
                            );
                    },
                    (error) => {
                        this.sessionService.notifier.notify(
                            "error",
                            error.error.message
                        );
                        this.loading = false;
                    }
                );
            }
        });
    }

    GetSORPDF(Propertyid) {
        var data = {
            PropertyId: Propertyid,
            GeneralContractorId: this.userDetail.Id,
        };

        if (this.userDetail.UserType == "General Contractors") {
            this._bservice.GetBlankBORPDF(data).subscribe(
                (response) => {
                    this.pdfdataShow = true;
                    this.pdfData =
                        this.sanitizer.bypassSecurityTrustResourceUrl(
                            this.baseurl + response.PdfPath
                        );
                    this.pdfData1 = this.baseurl + response.PdfPath;
                    this.loading = false;
                },
                (error) => {
                    this.sessionService.notifier.notify(
                        "error",
                        error.error.message
                    );
                    this.loading = false;
                }
            );
        } else {
            this._bservice.GetSORPDF(data).subscribe(
                (response) => {
                    this.pdfdataShow = true;
                    this.pdfData =
                        this.sanitizer.bypassSecurityTrustResourceUrl(
                            this.baseurl + response.PdfPath
                        );
                    this.pdfData1 = this.baseurl + response.PdfPath;
                    this.loading = false;
                },
                (error) => {
                    this.sessionService.notifier.notify(
                        "error",
                        error.error.message
                    );
                    this.loading = false;
                }
            );
        }
    }

    sorViewPdf(pdfData) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(this.pdfData);
    }

    sorDownloadPdf() {
        window.open(this.pdfData1, "_blank");
    }

    blankBorDownloadPdf() {
        window.open(this.pdfData1, "_blank");
    }

    printPdf(url) {
        window.open(url, "_blank");
    }
}

@Pipe({ name: "safe" })
export class SafePipe implements PipeTransform {
    constructor(@Inject(DomSanitizer) private sanitizer: DomSanitizer) {}
    transform(url: string) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }
}

@Component({
    selector: "dialog-content-example-dialog",
    templateUrl: "popup.html",
})
export class DialogContentExampleDialog {
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
        @Inject(Router) private router: Router
    ) {}
    redirectOnBid() {
        this.router.navigate(["/property-input-bid", this.data.propertyId]);
    }
}
