<div class="content p-24 tbl">
    <ngx-loading class="ngxLoader" 
        [show]="loading"
        [config]="{ backdropBorderRadius: '3px' }"
    ></ngx-loading>
    <h1 class="m-0">Manage Draw Administrator</h1>
    <a
        *ngIf="isList"
        class="addBorrow"
        href="javascript:void(0)"
        (click)="isList = false; isAdd = true"
        ><mat-icon
            _ngcontent-c102=""
            class="secondary-text s-22 mat-icon material-icons mat-icon-no-color"
            role="img"
            aria-hidden="true"
            >add_circle</mat-icon
        >
        Add New Draw Administrator</a
    >
    <a
        *ngIf="isAdd"
        class="addBorrow"
        href="javascript:void(0)"
        (click)="isAdd = false; isList = true"
        ><mat-icon
            _ngcontent-c102=""
            class="secondary-text s-22 mat-icon material-icons mat-icon-no-color"
            role="img"
            aria-hidden="true"
            >view_list</mat-icon
        >
        List Draw Administrator</a
    >
    <a
        *ngIf="isList"
        class="addBorrow"
        href="javascript:void(0)"
        (click)="isList = false; isAdd = true; isEdit = false; formReset()"
        ><mat-icon
            _ngcontent-c102=""
            class="secondary-text s-22 mat-icon material-icons mat-icon-no-color"
            role="img"
            aria-hidden="true"
            >add_circle</mat-icon
        >
        Add New Draw Administrator</a
    >
    <a
        *ngIf="isAdd || isEdit"
        class="addBorrow"
        href="javascript:void(0)"
        (click)="isAdd = false; isList = true; isEdit = false"
        ><mat-icon
            _ngcontent-c102=""
            class="secondary-text s-22 mat-icon material-icons mat-icon-no-color"
            role="img"
            aria-hidden="true"
            >view_list</mat-icon
        >
        List Draw Administrator</a
    >
    <mat-form-field>
        <span class="mat-form-field-label-wrapper"
            ><!----><label
                class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                >Filter</label
            ></span
        >
        <input
            [hidden]="!isList"
            matInput
            (keyup)="applyFilter($event.target)"
        />
    </mat-form-field>

    <div class="mat-elevation-z8" [hidden]="!isList">
        <table mat-table [dataSource]="dataSource" matSort>
            <!-- ID Column -->
            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
                <td mat-cell *matCellDef="let row">{{ row.id }}</td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="fname">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    First Name
                </th>
                <td mat-cell *matCellDef="let row">{{ row.FirstName }}</td>
            </ng-container>
            <ng-container matColumnDef="lname">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Last Name
                </th>
                <td mat-cell *matCellDef="let row">{{ row.LastName }}</td>
            </ng-container>

            <!-- Progress Column -->
            <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    E-mail
                </th>
                <td mat-cell *matCellDef="let row">{{ row.EmailId }}</td>
            </ng-container>

            <!-- Color Column -->
            <ng-container matColumnDef="lstLogin">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Last login
                </th>
                <td
                    mat-cell
                    *matCellDef="let row"
                    [style.color]="row.lastLogin"
                >
                    {{ row.UpdateDate | date : "medium" }}
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr
                mat-row
                *matRowDef="let row; columns: displayedColumns"
                style="cursor: pointer"
                (click)="EditUser(row)"
            ></tr>
        </table>

        <mat-paginator [pageSizeOptions]="[20, 50, 100]"></mat-paginator>
    </div>
    <div class="mat-elevation-z8" *ngIf="isAdd || isEdit">
        <div class="container-login100">
            <mat-card class="my-form">
                <form
                    [formGroup]="add_drawAdministrator"
                    class="example-form"
                    (ngSubmit)="SaveRecord()"
                >
                    <mat-card-header>
                        <mat-card-title
                            >{{ isEdit ? "Edit" : "Add" }} Draw
                            Administrator</mat-card-title
                        >
                    </mat-card-header>
                    <mat-card-content>
                        <mat-form-field class="example-full-width">
                            <span class="mat-form-field-label-wrapper"
                                ><!----><label
                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                    >E-mail *</label
                                ></span
                            >
                            <input
                                matInput
                                formControlName="email"
                                name="username"
                            />
                            <div
                                class="error"
                                *ngIf="
                                    (!add_drawAdministrator.controls['email']
                                        .valid &&
                                        add_drawAdministrator.controls['email']
                                            .touched) ||
                                    (add_drawAdministrator.controls['email']
                                        .invalid &&
                                        submitted)
                                "
                            >
                                Enter valid email.
                            </div>
                        </mat-form-field>
                        <table>
                            <tbody>
                                <tr *ngIf="!isEdit">
                                    <td>
                                        <mat-form-field
                                            *ngIf="!isEdit"
                                            class="example-full-width"
                                        >
                                            <span
                                                class="mat-form-field-label-wrapper"
                                                ><!----><label
                                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                    >Password *</label
                                                ></span
                                            >
                                            <input
                                                matInput
                                                (keyup)="ValidatePartnerPass()"
                                                formControlName="password"
                                                type="password"
                                                name="password"
                                            />
                                            <div
                                                class="error"
                                                *ngIf="
                                                    (!add_drawAdministrator
                                                        .controls['password']
                                                        .valid &&
                                                        add_drawAdministrator
                                                            .controls[
                                                            'password'
                                                        ].touched) ||
                                                    (add_drawAdministrator
                                                        .controls['password']
                                                        .invalid &&
                                                        submitted)
                                                "
                                            >
                                                Enter password.
                                            </div>
                                        </mat-form-field>
                                    </td>
                                    <td>
                                        <mat-form-field
                                            *ngIf="!isEdit"
                                            class="example-full-width"
                                        >
                                            <span
                                                class="mat-form-field-label-wrapper"
                                                ><!----><label
                                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                    >Confirm Password *</label
                                                ></span
                                            >
                                            <input
                                                matInput
                                                (keyup)="ValidatePartnerPass()"
                                                formControlName="confirmPassword"
                                                type="password"
                                                name="Confirmpassword"
                                            />
                                            <div
                                                class="error"
                                                *ngIf="
                                                    (!add_drawAdministrator
                                                        .controls[
                                                        'confirmPassword'
                                                    ].valid &&
                                                        add_drawAdministrator
                                                            .controls[
                                                            'confirmPassword'
                                                        ].touched) ||
                                                    (add_drawAdministrator
                                                        .controls[
                                                        'confirmPassword'
                                                    ].invalid &&
                                                        submitted)
                                                "
                                            >
                                                Confirm password not matched.
                                            </div>
                                        </mat-form-field>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <mat-form-field
                                            class="example-full-width"
                                        >
                                            <span
                                                class="mat-form-field-label-wrapper"
                                                ><!----><label
                                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                    >First Name *</label
                                                ></span
                                            >
                                            <input
                                                matInput
                                                formControlName="fname"
                                                name="fname"
                                            />
                                            <div
                                                class="error"
                                                *ngIf="
                                                    (!add_drawAdministrator
                                                        .controls['fname']
                                                        .valid &&
                                                        add_drawAdministrator
                                                            .controls['fname']
                                                            .touched) ||
                                                    (add_drawAdministrator
                                                        .controls['fname']
                                                        .invalid &&
                                                        submitted)
                                                "
                                            >
                                                Enter first name.
                                            </div>
                                        </mat-form-field>
                                    </td>
                                    <td>
                                        <mat-form-field
                                            class="example-full-width"
                                        >
                                            <span
                                                class="mat-form-field-label-wrapper"
                                                ><!----><label
                                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                    >Last Name *</label
                                                ></span
                                            >
                                            <input
                                                matInput
                                                formControlName="lname"
                                                name="lname"
                                            />
                                            <div
                                                class="error"
                                                *ngIf="
                                                    (!add_drawAdministrator
                                                        .controls['lname']
                                                        .valid &&
                                                        add_drawAdministrator
                                                            .controls['lname']
                                                            .touched) ||
                                                    (add_drawAdministrator
                                                        .controls['lname']
                                                        .invalid &&
                                                        submitted)
                                                "
                                            >
                                                Enter last name.
                                            </div>
                                        </mat-form-field>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <mat-form-field
                                            class="example-full-width"
                                        >
                                            <span
                                                class="mat-form-field-label-wrapper"
                                                ><!----><label
                                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                    >Phone Number</label
                                                ></span
                                            >
                                            <input
                                                matInput
                                                [mask]="maskPhoneFormat" 
                                                placeholder="(000) 000-0000"
                                                (keyup)="
                                                    keyDownPhoneFormat($event)
                                                "
                                                (change)="
                                                    changePhoneFormat($event)
                                                "
                                                formControlName="phone_no"
                                                name="phone_no"
                                            />
                                            <div
                                                class="error"
                                                *ngIf="
                                                    (!add_drawAdministrator
                                                        .controls['phone_no']
                                                        .valid &&
                                                        add_drawAdministrator
                                                            .controls[
                                                            'phone_no'
                                                        ].touched) ||
                                                    (add_drawAdministrator
                                                        .controls['phone_no']
                                                        .invalid &&
                                                        submitted)
                                                "
                                            >
                                            <div class="error" *ngIf="add_drawAdministrator?.controls['phone_no']?.errors?.mask">
                                                Enter valid phone no.
                                               </div>
                                            </div>
                                        </mat-form-field>
                                    </td>
                                    <td>
                                        <mat-radio-group
                                            class="example-radio-group"
                                            formControlName="statusType"
                                            name="statusType"
                                        >
                                            <label><b>Status</b></label>
                                            <mat-radio-button
                                                class="example-radio-button"
                                                *ngFor="let item of status"
                                                [value]="item"
                                            >
                                                {{ item }}
                                            </mat-radio-button>
                                        </mat-radio-group>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <!--        
 <section *ngIf="false" class="example-full-width">
   <label><b>Roles</b></label>
   <div *ngFor="let role of roles; let i = index">
      <mat-checkbox class="example-radio-group">{{role.Value}}</mat-checkbox>
   </div>
    </section> -->

                        <mat-card>
                            <mat-card-header>
                                <mat-card-title>Address</mat-card-title>
                            </mat-card-header>
                            <mat-card-content>
                                <!-- <mat-form-field>
                      <mat-select placeholder="Country" formControlName="country"
                                  >
                        <mat-option value="">None</mat-option>
                        <mat-option value="us">United State</mat-option>
                    
                      </mat-select>
                    </mat-form-field> -->
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <mat-form-field
                                                    class="example-full-width"
                                                >
                                                    <span
                                                        class="mat-form-field-label-wrapper"
                                                        ><!----><label
                                                            class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                            >Address 1 *</label
                                                        ></span
                                                    >
                                                    <input
                                                        matInput
                                                        ngx-google-places-autocomplete
                                                        autocomplete="new-password"
                                                        (onAddressChange)="
                                                            AddressChange(
                                                                $event
                                                            )
                                                        "
                                                        formControlName="address1"
                                                        name="add1"
                                                    />
                                                    <!-- <input matInput placeholder="Address 1" formControlName="address1" name="add1"> -->
                                                </mat-form-field>
                                            </td>
                                            <td>
                                                <mat-form-field
                                                    class="example-full-width"
                                                >
                                                    <span
                                                        class="mat-form-field-label-wrapper"
                                                        ><!----><label
                                                            class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                            >Address 2</label
                                                        ></span
                                                    >
                                                    <input
                                                        matInput
                                                        autocomplete="new-password"
                                                        formControlName="address2"
                                                        name="add2"
                                                    />
                                                </mat-form-field>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <mat-form-field
                                                    class="example-full-width"
                                                >
                                                    <span
                                                        class="mat-form-field-label-wrapper"
                                                        ><!----><label
                                                            class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                            >City</label
                                                        ></span
                                                    >
                                                    <input
                                                        matInput
                                                        autocomplete="new-password"
                                                        formControlName="city"
                                                        name="city"
                                                    />
                                                </mat-form-field>
                                            </td>
                                            <td>
                                                <mat-form-field
                                                    class="example-full-width"
                                                >
                                                    <span
                                                        class="mat-form-field-label-wrapper"
                                                        ><!----><label
                                                            class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                            >State</label
                                                        ></span
                                                    >
                                                    <input
                                                        matInput
                                                        autocomplete="new-password"
                                                        formControlName="state"
                                                        name="state"
                                                    />
                                                </mat-form-field>
                                            </td>
                                            <td>
                                                <mat-form-field
                                                    class="example-full-width"
                                                >
                                                    <span
                                                        class="mat-form-field-label-wrapper"
                                                        ><!----><label
                                                            class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                            >Zip code</label
                                                        ></span
                                                    >
                                                    <input
                                                        matInput
                                                        autocomplete="new-password"
                                                        formControlName="zipcode"
                                                        name="zip"
                                                    />
                                                    <div class="error" *ngIf="(!add_drawAdministrator.controls['zipcode'].valid && add_drawAdministrator.controls['zipcode'].touched) || (add_drawAdministrator.controls['zipcode'].invalid && submitted)">
                                                        Enter valid zipcode
                                                      </div>
                                                </mat-form-field>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </mat-card-content>
                        </mat-card>
                        <br />
                        <mat-form-field>
                            <span
                                class="select-dropdown mat-form-field-label-wrapper"
                                ><!----><label
                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                    >--Select lender-- *</label
                                ></span
                            >
                            <mat-select
                                class="select-dropdowns-content"
                                formControlName="lender"
                            >
                                <mat-option value="">Select</mat-option>
                                <mat-option
                                    *ngFor="let lender_value of lender_list"
                                    value="{{ lender_value.Id }}"
                                    >{{ lender_value.Name }}</mat-option
                                >
                            </mat-select>
                        </mat-form-field>
                    </mat-card-content>
                    <mat-card-actions>
                        <button mat-raised-button type="submit" color="primary">
                            {{ isEdit ? "Save" : "Save" }}</button
                        >&nbsp; &nbsp;&nbsp;
                        <button
                            mat-raised-button
                            *ngIf="
                                userDetail.UserType == 'Administrator' && isEdit
                            "
                            (click)="deleteUser(add_drawAdministrator.value.id)"
                            type="button"
                            color="info"
                        >
                            Delete</button
                        >&nbsp; &nbsp;&nbsp;
                        <button
                            mat-raised-button
                            (click)="drawAdministratorList()"
                            type="button"
                            color="info"
                        >
                            Back
                        </button>
                    </mat-card-actions>
                </form>
            </mat-card>
        </div>
    </div>
</div>
