import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatRadioModule } from "@angular/material/radio";
import { MatTableModule } from "@angular/material/table";
import { AuthGuardService as AuthGard } from "../service/auth/auth-guard.service";
import { TranslateModule } from "@ngx-translate/core";
import { NgxLoadingModule } from "ngx-loading";
import { FuseSharedModule } from "@fuse/shared.module";
import { SubmittedBidsComponent } from "./submitted-bids.component";
import { GooglePlaceModule } from "@yuvarajv/ngx-google-places-autocomplete";

const routes = [
    {
        path: "submitted-bids/:propertyId",
        component: SubmittedBidsComponent,
        canActivate: [AuthGard],
    },
];

@NgModule({
    declarations: [SubmittedBidsComponent],
    imports: [
        RouterModule.forChild(routes),
        GooglePlaceModule,
        TranslateModule,
        NgxLoadingModule.forRoot({}),
        FuseSharedModule,
        MatButtonModule,
        MatCardModule,
        MatFormFieldModule,
        MatInputModule,
        MatTableModule,
        MatPaginatorModule,
        MatIconModule,
        MatRadioModule,
        MatCheckboxModule,
        MatExpansionModule,
    ],
    exports: [SubmittedBidsComponent],
})
export class SubmittedBidsModule {}
