<div class="content p-24 tbl">
    <ngx-loading class="ngxLoader" 
        [show]="loading"
        [config]="{ backdropBorderRadius: '3px' }"
    ></ngx-loading>
    <h1 class="m-0">Property Locations & Repair Item Information</h1>
    <br />
    <div class="container-login100">
        <mat-card class="my-form">
            <form
                [formGroup]="add_loanOfficer"
                class="example-form"
                (ngSubmit)="SaveRecord()"
            >
                <mat-card-content>
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    <mat-form-field class="example-full-width">
                                        <span
                                            class="mat-form-field-label-wrapper"
                                            ><!----><label
                                                class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                >Title*</label
                                            ></span
                                        >
                                        <input
                                            matInput
                                            formControlName="email"
                                            name="username"
                                        />
                                        <div
                                    class="error"
                                    *ngIf="
                                        (!add_loanOfficer.controls[
                                            'email'
                                        ].valid &&
                                        add_loanOfficer
                                                .controls[
                                                'email'
                                            ].touched) ||
                                        (add_loanOfficer.controls[
                                            'email'
                                        ].invalid &&
                                            submitted)
                                    "
                                >
                                   Enter valid title
                                </div>
                                    </mat-form-field>
                                </td>
                                <td>
                                    <mat-form-field class="example-full-width">
                                        <span
                                            class="mat-form-field-label-wrapper"
                                            ><!----><label
                                                class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                >"Borower Name
                                            </label></span
                                        >
                                        <input
                                            matInput
                                            formControlName="Borower"
                                            name="username"
                                        />
                                    </mat-form-field>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <label>Property Inspection Address</label>
                    <ckeditor
                        matNativeControl
                        name="editor1"
                        formControlName="editorValue"
                        skin="moono-lisa"
                        language="en"
                    ></ckeditor>
                    <br />
                    <mat-form-field class="example-full-width">
                        <span class="mat-form-field-label-wrapper"
                            ><!----><label
                                class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                >Borower Name</label
                            ></span
                        >
                        <input
                            matInput
                            formControlName="Borower"
                            name="username"
                        />
                    </mat-form-field>

                    <mat-expansion-panel [expanded]="true">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                REPAIR ITEM DETAIL
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <mat-form-field>
                            <span class="mat-form-field-label-wrapper"
                                ><!----><label
                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                    >Repair Type</label
                                ></span
                            >
                            <mat-select formControlName="RepairType">
                                <mat-option value="">Select</mat-option>
                                <mat-option value="GC">Appliances</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-expansion-panel [expanded]="true">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    REPAIR LOCATIONS
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <table>
                                <tbody>
                                    <tr>
                                        <td>
                                            <mat-form-field>
                                                <span
                                                    class="mat-form-field-label-wrapper"
                                                    ><!----><label
                                                        class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                        >Location</label
                                                    ></span
                                                >
                                                <input matInput />
                                            </mat-form-field>
                                        </td>
                                        <td>
                                            <mat-form-field>
                                                <span
                                                    class="mat-form-field-label-wrapper"
                                                    ><!----><label
                                                        class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                        >Repair Item Name</label
                                                    ></span
                                                >
                                                <input matInput />
                                            </mat-form-field>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <mat-form-field>
                                <span class="mat-form-field-label-wrapper"
                                    ><!----><label
                                        class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                        >Repair Item Information</label
                                    ></span
                                >
                                <input matInput />
                            </mat-form-field>

                            <label>PICTURE</label><br />
                            <input type="file" /><br />
                            <p>
                                Files must be less than
                                <strong>100 MB.</strong> <br />
                                Allowed file types:
                                <strong>png gif jpg jpeg.</strong>
                            </p>

                            <button
                                mat-raised-button
                                type="button"
                                color="info"
                            >
                                Remove
                            </button>
                        </mat-expansion-panel>
                        <br />
                        <button mat-raised-button type="button" color="info">
                            Add another item</button
                        ><br /><br />
                        <button mat-raised-button type="button" color="info">
                            Remove
                        </button>
                    </mat-expansion-panel>
                    <br />
                    <button mat-raised-button type="button" color="info">
                        Add another item</button
                    ><br /><br />

                    <mat-form-field>
                        <span class="mat-form-field-label-wrapper"
                            ><!----><label
                                class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                >Property</label
                            ></span
                        >
                        <mat-select formControlName="Property">
                            <mat-option value="">Select</mat-option>
                            <mat-option value="RMS"
                                >1 City Hall Square , Boston, Massachusetts,
                                02201</mat-option
                            >
                        </mat-select>
                    </mat-form-field>
                </mat-card-content>
                <mat-card-actions>
                    <button mat-raised-button type="submit" color="primary">
                        Save</button
                    >&nbsp; &nbsp;&nbsp;
                    <button mat-raised-button type="button" color="info">
                        Save as draft</button
                    >&nbsp; &nbsp;&nbsp;
                    <button mat-raised-button type="button" color="info">
                        Preview
                    </button>
                </mat-card-actions>
            </form>
        </mat-card>
    </div>
</div>
