import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { Platform } from "@angular/cdk/platform";
import { TranslateService } from "@ngx-translate/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { FuseConfigService } from "@fuse/services/config.service";
import { FuseNavigationService } from "@fuse/components/navigation/navigation.service";
import { FuseSidebarService } from "@fuse/components/sidebar/sidebar.service";
import { FuseSplashScreenService } from "@fuse/services/splash-screen.service";
import { FuseTranslationLoaderService } from "@fuse/services/translation-loader.service";
import { CronJob } from "cron";
import { map, catchError } from "rxjs/operators";
import { Observable, throwError } from "rxjs";
import * as S3 from "aws-sdk/clients/s3";
import { BorrowerService } from "./main/borrower/borrower.service";
import { PropertyService } from "./main/property/property.service";
import { navigation } from "app/navigation/navigation";
import { locale as navigationEnglish } from "app/navigation/i18n/en";
import { locale as navigationTurkish } from "app/navigation/i18n/tr";
import { SessionService } from "app/main/service/session.service";
import { Router, ActivatedRoute } from "@angular/router";

import { SafeResourceUrl } from "@angular/platform-browser";
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Component({
    selector: "app",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy {
    baseurl = "";
    fuseConfig: any;
    navigation: any;
    userDetail: any;
    settings: any;
    cronJob: CronJob;
    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {DOCUMENT} document
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseNavigationService} _fuseNavigationService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {FuseSplashScreenService} _fuseSplashScreenService
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     * @param {Platform} _platform
     * @param {TranslateService} _translateService
     */
    constructor(
        @Inject(DOCUMENT) private document: any,
        private _fuseConfigService: FuseConfigService,
        private _fuseNavigationService: FuseNavigationService,
        private _fuseSidebarService: FuseSidebarService,
        private _fuseSplashScreenService: FuseSplashScreenService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _translateService: TranslateService,
        private _platform: Platform,
        private sessionService: SessionService,
        private router: Router,
        private route: ActivatedRoute,
        private _bservice: PropertyService,
        private _boservice: BorrowerService,
        private http: HttpClient
    ) {
        // this.cronJob = new CronJob('30 * * * * *', async () => {
        //   try {
        //      this.cronFunction();

        //   } catch (e) {
        //     console.error(e);
        //   }
        // });

        // Start job
        // if (!this.cronJob.running) {
        //   this.cronJob.start();
        // }

        this.router.events.subscribe((e: any) => {
            console.log("Router event:", e);
        });

        // Get default navigation
        this.navigation = navigation;

        // Register the navigation to the service
        this._fuseNavigationService.register("main", this.navigation);
        // Set the main navigation as our current navigation
        this._fuseNavigationService.setCurrentNavigation("main");

        // Check Navigation According User

        if (
            this.sessionService.get("userInfo") != undefined &&
            this.sessionService.get("userInfo") != null &&
            this.sessionService.get("userInfo") != ""
        ) {
            this.userDetail = JSON.parse(this.sessionService.get("userInfo"));
            if (this.sessionService.get("masqueradeUserInfo") != undefined) {
                var masqueradeUserInfo = JSON.parse(
                    this.sessionService.get("masqueradeUserInfo")
                );
            }
            if (this.userDetail.UserType == "Borrower") {
                this._fuseNavigationService.updateNavigationItem("Users", {
                    hidden: true,
                });
                if (masqueradeUserInfo != "") {
                    this._fuseNavigationService.updateNavigationItem(
                        "Dashboard",
                        {
                            hidden: false,
                        }
                    );
                } else {
                    this._fuseNavigationService.updateNavigationItem(
                        "Dashboard",
                        {
                            hidden: true,
                        }
                    );
                }
                this._fuseNavigationService.updateNavigationItem("Settings", {
                    hidden: true,
                });
                this._fuseNavigationService.updateNavigationItem(
                    "AllowableFees",
                    {
                        hidden: true,
                    }
                );
                this._fuseNavigationService.updateNavigationItem(
                    "ConstructionItemsLibrary",
                    {
                        hidden: true,
                    }
                );
            }

            if (
                this.userDetail.UserType == "Administrator" ||
                this.userDetail.UserType == "Consultants"
            ) {
                //this.router.navigate(['/dashboard']);
                this._fuseNavigationService.updateNavigationItem("Users", {
                    hidden: false,
                });
                this._fuseNavigationService.updateNavigationItem(
                    "ConstructionItemsLibrary",
                    {
                        hidden: false,
                    }
                );
                this._fuseNavigationService.updateNavigationItem(
                    "AllowableFees",
                    {
                        hidden: false,
                    }
                );
                this._fuseNavigationService.updateNavigationItem("Settings", {
                    hidden: false,
                });
            } else {
                this._fuseNavigationService.updateNavigationItem("Users", {
                    hidden: true,
                });
                this._fuseNavigationService.updateNavigationItem(
                    "ConstructionItemsLibrary",
                    {
                        hidden: true,
                    }
                );
                if (masqueradeUserInfo != "") {
                    this._fuseNavigationService.updateNavigationItem(
                        "Dashboard",
                        {
                            hidden: false,
                        }
                    );
                } else {
                    this._fuseNavigationService.updateNavigationItem(
                        "Dashboard",
                        {
                            hidden: true,
                        }
                    );
                }
                this._fuseNavigationService.updateNavigationItem(
                    "AllowableFees",
                    {
                        hidden: true,
                    }
                );
                this._fuseNavigationService.updateNavigationItem("Settings", {
                    hidden: true,
                });
            }
        }

        // Add languages
        this._translateService.addLangs(["en", "tr"]);

        // Set the default language
        this._translateService.setDefaultLang("en");

        // Set the navigation translations
        this._fuseTranslationLoaderService.loadTranslations(
            navigationEnglish,
            navigationTurkish
        );

        // Use a language
        this._translateService.use("en");

        /**
         * ----------------------------------------------------------------------------------------------------
         * ngxTranslate Fix Start
         * ----------------------------------------------------------------------------------------------------
         */

        /**
         * If you are using a language other than the default one, i.e. Turkish in this case,
         * you may encounter an issue where some of the components are not actually being
         * translated when your app first initialized.
         *
         * This is related to ngxTranslate module and below there is a temporary fix while we
         * are moving the multi language implementation over to the Angular's core language
         * service.
         **/

        // Set the default language to 'en' and then back to 'tr'.
        // '.use' cannot be used here as ngxTranslate won't switch to a language that's already
        // been selected and there is no way to force it, so we overcome the issue by switching
        // the default language back and forth.
        /**
         setTimeout(() => {
            this._translateService.setDefaultLang('en');
            this._translateService.setDefaultLang('tr');
         });
         */

        /**
         * ----------------------------------------------------------------------------------------------------
         * ngxTranslate Fix End
         * ----------------------------------------------------------------------------------------------------
         */

        // Add is-mobile class to the body if the platform is mobile
        if (this._platform.ANDROID || this._platform.IOS) {
            this.document.body.classList.add("is-mobile");
        }

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.baseurl = this.sessionService.endpoint;
        // Subscribe to config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config) => {
                this.fuseConfig = config;

                // Boxed
                if (this.fuseConfig.layout.width == "boxed") {
                    this.document.body.classList.add("boxed");
                } else {
                    this.document.body.classList.remove("boxed");
                }

                // Color theme - Use normal for loop for IE11 compatibility
                for (let i = 0; i < this.document.body.classList.length; i++) {
                    const className = this.document.body.classList[i];

                    if (className.startsWith("theme-")) {
                        this.document.body.classList.remove(className);
                    }
                }

                this.document.body.classList.add(this.fuseConfig.colorTheme);
            });
    }
    cronFunction() {
        var data = {
            EmailId: "",
            PageSize: 100000,
            LastEvaluatedKey: 0,
            UserType: this.sessionService.userType[1].Value,
        };

        this._boservice.GetSetting(data).subscribe((response) => {
            if (response != null) {
                this.settings = response;
            }
        });
        this.GetPropertyList();
    }
    GetPropertyList() {
        var data = {
            EmailId: "",
            PageSize: 100000,
            LastEvaluatedKey: 0,
            UserType: this.sessionService.userType[1].Value,
        };

        this._bservice.GetPropertyList(data).subscribe(
            (response) => {
                if (response != null) {
                    var propertyResult = response;

                    propertyResult.Items.sort(function (a, b) {
                        return a.Id < b.Id ? 1 : -1;
                    });
                    for (let i = 0; i < propertyResult.Items.length; i++) {
                        setTimeout(() => {
                            this.getSignedEnvelopes(propertyResult.Items[i].Id);
                        }, i * 500);
                    }
                }
            },
            (error) => {}
        );
    }
    getSignedEnvelopes(propertyId) {
        this._bservice.GetPropertySignEnvelopesList(propertyId).subscribe(
            (response) => {
                if (response != null) {
                    if (response.Items.length > 0) {
                        response.Items.sort(function (a, b) {
                            return a.Id - b.Id;
                        });

                        let isAlreadyDocIdPropIdEnvType = [];
                        for (let i = 0; i < response.Items.length; i++) {
                            if (
                                isAlreadyDocIdPropIdEnvType.indexOf(
                                    response.Items[i].PropertyId +
                                        "_" +
                                        response.Items[i].DocumentId +
                                        "_" +
                                        response.Items[i].EnvelopeType +
                                        "_" +
                                        response.Items[i].DocumentName.replace(
                                            " ",
                                            "_"
                                        )
                                ) !== -1
                            ) {
                                continue;
                            }

                            if (
                                response.Items[i].EnvelopeStatus == "completed"
                            ) {
                                isAlreadyDocIdPropIdEnvType.push(
                                    response.Items[i].PropertyId +
                                        "_" +
                                        response.Items[i].DocumentId +
                                        "_" +
                                        response.Items[i].EnvelopeType +
                                        "_" +
                                        response.Items[i].DocumentName.replace(
                                            " ",
                                            "_"
                                        )
                                );

                                if (response.Items[i].UploadedStatus != 1) {
                                    let envCreatedTime =
                                        response.Items[i].UpdateDate;
                                    let envTimeArray =
                                        envCreatedTime.split(".");
                                    let envIndex = envTimeArray[0];
                                    let envTime = envIndex.replace("T", " ");
                                    envTime = new Date(envTime).getTime();
                                    var today = new Date();
                                    let currentTime = new Date(
                                        new Date().setDate(today.getDate() - 30)
                                    ).getTime();

                                    if (currentTime < envTime) {
                                        setTimeout(() => {
                                            let shouldOpen = false;
                                            this.generateCompletedSignedEnvelope(
                                                response.Items[i],
                                                shouldOpen,
                                                propertyId
                                            );
                                        }, i * 500);
                                    }
                                }
                            } else {
                                isAlreadyDocIdPropIdEnvType.push(
                                    response.Items[i].PropertyId +
                                        "_" +
                                        response.Items[i].DocumentId +
                                        "_" +
                                        response.Items[i].EnvelopeType +
                                        "_" +
                                        response.Items[i].DocumentName.replace(
                                            " ",
                                            "_"
                                        )
                                );
                                let envCreatedTime =
                                    response.Items[i].UpdateDate;
                                let envTimeArray = envCreatedTime.split(".");
                                let envIndex = envTimeArray[0];
                                let envTime = envIndex.replace("T", " ");

                                envTime = new Date(envTime).getTime();

                                var today = new Date();

                                let prevDate = new Date(
                                    new Date().setDate(today.getDate() - 30)
                                );
                                let currentTime = new Date(prevDate).getTime();

                                if (currentTime < envTime) {
                                    setTimeout(() => {
                                        this._bservice
                                            .GetCompletedsignedEnvelope(
                                                response.Items[i]
                                            )
                                            .subscribe((response) => {});
                                    }, i * 500);
                                }
                            }
                        }
                    }
                }
            },
            (error) => {}
        );
    }

    generateCompletedSignedEnvelope(envelopeData, shouldOpen, propertyId) {
        this._bservice.GetPropertySignDocById(envelopeData.Id).subscribe(
            (response) => {
                if (response != null) {
                    var responseData = response;
                    var url = encodeURI(
                        this.baseurl +
                            "pdf-files/" +
                            envelopeData.PropertyId +
                            "_" +
                            envelopeData.EnvelopeId +
                            "_" +
                            envelopeData.DocumentName +
                            ".pdf"
                    ).replace("#", "%23");

                    let tempFileName = url.split("/");
                    let length = tempFileName.length;
                    const fileName1 = tempFileName[length - 1];
                    let fileName1Array = fileName1.split("_");
                    let length1 = fileName1Array.length;
                    const fileName = fileName1Array[length1 - 1];
                    let EnvelopeUrl =
                        "property-signed-envelope/" +
                        envelopeData.PropertyId +
                        "/envelopes/" +
                        envelopeData.EnvelopeType +
                        "/" +
                        fileName;

                    this.get_s3_signed_url(
                        EnvelopeUrl,
                        shouldOpen,
                        envelopeData,
                        propertyId
                    );
                }
            },
            (error) => {}
        );
    }

    get_s3_signed_url(resource, shouldOpen, envData, propertyId) {
        const bucketName = this.settings.AwsS3BucketName
            ? this.settings.AwsS3BucketName
            : "";
        const accessKeyId = this.settings.AwsAccessKeyId
            ? this.settings.AwsAccessKeyId
            : "";
        const secretAccessKey = this.settings.AwsSecretAccessKey
            ? this.settings.AwsSecretAccessKey
            : "";
        const region = this.settings.AwsConfigRegion
            ? this.settings.AwsConfigRegion
            : "";

        const bucket = new S3({
            accessKeyId: accessKeyId,
            secretAccessKey: secretAccessKey,
            region: region,
        });
        const params = {
            Bucket: bucketName,
            Key: resource,
        };
        const _this = this;

        bucket.getSignedUrl(
            "getObject",
            params,
            function (err: any, link: any) {
                if (err) {
                    if (shouldOpen) {
                        var url = encodeURI(
                            _this.baseurl +
                                "pdf-files/" +
                                envData.PropertyId +
                                "_" +
                                envData.EnvelopeId +
                                "_" +
                                envData.DocumentName +
                                ".pdf"
                        ).replace("#", "%23");
                        _this.http.get(url, { responseType: "blob" }).subscribe(
                            (response) => {
                                if (response.type === "application/pdf") {
                                    _this.getDoc(url).subscribe(
                                        (x) => {
                                            let fileInput: any = x;

                                            _this.saveToSignedEnvelopesInS3(
                                                envData,
                                                fileInput,
                                                url,
                                                shouldOpen,
                                                propertyId
                                            );
                                        },
                                        (error) => {}
                                    );
                                } else {
                                    _this.sessionService.notifier.notify(
                                        "error",
                                        "You can contact with site administrator. This file (Envelope ID : " +
                                            envData.EnvelopeId +
                                            " ) does not exist."
                                    );
                                    let param = {
                                        envelopUrl: link,
                                        envId: envData.EnvelopeId,
                                        DocumentName: envData.DocumentName,
                                    };
                                    _this._bservice
                                        .signedDocError(param)
                                        .subscribe(
                                            (response) => {
                                                if (response == "success") {
                                                }
                                            },
                                            (error) => {
                                                _this.sessionService.notifier.notify(
                                                    "error",
                                                    error.error.message
                                                );
                                            }
                                        );
                                }
                            },
                            (error) => {
                                if (error.status === 200) {
                                    _this.getDoc(url).subscribe(
                                        (x) => {
                                            let fileInput: any = x;

                                            _this.saveToSignedEnvelopesInS3(
                                                envData,
                                                fileInput,
                                                url,
                                                shouldOpen,
                                                propertyId
                                            );
                                        },
                                        (error) => {}
                                    );
                                } else if (error.status === 404) {
                                    _this.sessionService.notifier.notify(
                                        "error",
                                        "You can contact with site administrator. This file (Envelope ID : " +
                                            envData.EnvelopeId +
                                            " ) does not exist."
                                    );
                                    let param = {
                                        envelopUrl: link,
                                        envId: envData.EnvelopeId,
                                        DocumentName: envData.DocumentName,
                                    };
                                    _this._bservice
                                        .signedDocError(param)
                                        .subscribe(
                                            (response) => {
                                                if (response == "success") {
                                                }
                                            },
                                            (error) => {
                                                _this.sessionService.notifier.notify(
                                                    "error",
                                                    error.error.message
                                                );
                                            }
                                        );
                                } else {
                                    console.error(
                                        "An error occurred while checking the URL:",
                                        error
                                    );
                                }
                            }
                        );

                        alert(
                            "There was an error uploading your file: " +
                                JSON.stringify(err)
                        );
                        console.log(
                            "There was an error uploading your file: ",
                            err
                        );
                    }
                    return false;
                } else {
                    if (shouldOpen) {
                        _this.http
                            .get(link, { responseType: "blob" })
                            .subscribe(
                                (response) => {
                                    if (response.type === "application/pdf") {
                                        window.open(link, "_blank");
                                    } else {
                                        _this._bservice
                                            .GetCompletedsignedEnvelope(envData)
                                            .subscribe(
                                                (response) => {
                                                    setTimeout(() => {
                                                        var url = encodeURI(
                                                            _this.baseurl +
                                                                "pdf-files/" +
                                                                envData.PropertyId +
                                                                "_" +
                                                                envData.EnvelopeId +
                                                                "_" +
                                                                envData.DocumentName +
                                                                ".pdf"
                                                        ).replace("#", "%23");

                                                        _this.http
                                                            .get(url, {
                                                                responseType:
                                                                    "blob",
                                                            })
                                                            .subscribe(
                                                                (response) => {
                                                                    if (
                                                                        response.type ===
                                                                        "application/pdf"
                                                                    ) {
                                                                        _this
                                                                            .getDoc(
                                                                                url
                                                                            )
                                                                            .subscribe(
                                                                                (
                                                                                    x
                                                                                ) => {
                                                                                    let fileInput: any =
                                                                                        x;

                                                                                    _this.saveToSignedEnvelopesInS3(
                                                                                        envData,
                                                                                        fileInput,
                                                                                        url,
                                                                                        shouldOpen,
                                                                                        propertyId
                                                                                    );
                                                                                },
                                                                                (
                                                                                    error
                                                                                ) => {}
                                                                            );
                                                                    } else {
                                                                    }
                                                                },
                                                                (error) => {
                                                                    if (
                                                                        error.status ===
                                                                        200
                                                                    ) {
                                                                        _this
                                                                            .getDoc(
                                                                                url
                                                                            )
                                                                            .subscribe(
                                                                                (
                                                                                    x
                                                                                ) => {
                                                                                    let fileInput: any =
                                                                                        x;

                                                                                    _this.saveToSignedEnvelopesInS3(
                                                                                        envData,
                                                                                        fileInput,
                                                                                        url,
                                                                                        shouldOpen,
                                                                                        propertyId
                                                                                    );
                                                                                },
                                                                                (
                                                                                    error
                                                                                ) => {}
                                                                            );
                                                                    } else if (
                                                                        error.status ===
                                                                        404
                                                                    ) {
                                                                    } else {
                                                                        console.error(
                                                                            "An error occurred while checking the URL:",
                                                                            error
                                                                        );
                                                                    }
                                                                }
                                                            );
                                                    }, 2500);
                                                },
                                                (error) => {}
                                            );
                                    }
                                },
                                (error) => {
                                    if (error.status === 200) {
                                        _this.getDoc(url).subscribe(
                                            (x) => {
                                                let fileInput: any = x;

                                                _this.saveToSignedEnvelopesInS3(
                                                    envData,
                                                    fileInput,
                                                    url,
                                                    shouldOpen,
                                                    propertyId
                                                );
                                            },
                                            (error) => {}
                                        );
                                    } else if (error.status === 404) {
                                        _this._bservice
                                            .GetCompletedsignedEnvelope(envData)
                                            .subscribe(
                                                (response) => {
                                                    setTimeout(() => {
                                                        var url = encodeURI(
                                                            _this.baseurl +
                                                                "pdf-files/" +
                                                                envData.PropertyId +
                                                                "_" +
                                                                envData.EnvelopeId +
                                                                "_" +
                                                                envData.DocumentName +
                                                                ".pdf"
                                                        ).replace("#", "%23");

                                                        _this.http
                                                            .get(url, {
                                                                responseType:
                                                                    "blob",
                                                            })
                                                            .subscribe(
                                                                (response) => {
                                                                    if (
                                                                        response.type ===
                                                                        "application/pdf"
                                                                    ) {
                                                                        _this
                                                                            .getDoc(
                                                                                url
                                                                            )
                                                                            .subscribe(
                                                                                (
                                                                                    x
                                                                                ) => {
                                                                                    let fileInput: any =
                                                                                        x;

                                                                                    _this.saveToSignedEnvelopesInS3(
                                                                                        envData,
                                                                                        fileInput,
                                                                                        url,
                                                                                        shouldOpen,
                                                                                        propertyId
                                                                                    );
                                                                                },
                                                                                (
                                                                                    error
                                                                                ) => {}
                                                                            );
                                                                    } else {
                                                                    }
                                                                },
                                                                (error) => {
                                                                    if (
                                                                        error.status ===
                                                                        200
                                                                    ) {
                                                                        _this
                                                                            .getDoc(
                                                                                url
                                                                            )
                                                                            .subscribe(
                                                                                (
                                                                                    x
                                                                                ) => {
                                                                                    let fileInput: any =
                                                                                        x;

                                                                                    _this.saveToSignedEnvelopesInS3(
                                                                                        envData,
                                                                                        fileInput,
                                                                                        url,
                                                                                        shouldOpen,
                                                                                        propertyId
                                                                                    );
                                                                                },
                                                                                (
                                                                                    error
                                                                                ) => {}
                                                                            );
                                                                    } else if (
                                                                        error.status ===
                                                                        404
                                                                    ) {
                                                                    } else {
                                                                        console.error(
                                                                            "An error occurred while checking the URL:",
                                                                            error
                                                                        );
                                                                    }
                                                                }
                                                            );
                                                    }, 2500);
                                                },
                                                (error) => {}
                                            );
                                    } else {
                                        console.error(
                                            "An error occurred while checking the URL:",
                                            error
                                        );
                                    }
                                }
                            );
                    } else {
                        if (envData.UploadedStatus != 1) {
                            _this._bservice
                                .GetCompletedsignedEnvelope(envData)
                                .subscribe(
                                    (response) => {
                                        setTimeout(() => {
                                            var url = encodeURI(
                                                _this.baseurl +
                                                    "pdf-files/" +
                                                    envData.PropertyId +
                                                    "_" +
                                                    envData.EnvelopeId +
                                                    "_" +
                                                    envData.DocumentName +
                                                    ".pdf"
                                            ).replace("#", "%23");

                                            _this.http
                                                .get(url, {
                                                    responseType: "blob",
                                                })
                                                .subscribe(
                                                    (response) => {
                                                        if (
                                                            response.type ===
                                                            "application/pdf"
                                                        ) {
                                                            _this
                                                                .getDoc(url)
                                                                .subscribe(
                                                                    (x) => {
                                                                        let fileInput: any =
                                                                            x;
                                                                        _this.saveToSignedEnvelopesInS3(
                                                                            envData,
                                                                            fileInput,
                                                                            url,
                                                                            shouldOpen,
                                                                            propertyId
                                                                        );
                                                                    },
                                                                    (
                                                                        error
                                                                    ) => {}
                                                                );
                                                        } else {
                                                            _this._bservice
                                                                .GetCompletedsignedEnvelope(
                                                                    envData
                                                                )
                                                                .subscribe(
                                                                    (
                                                                        response
                                                                    ) => {
                                                                        setTimeout(
                                                                            () => {
                                                                                _this.http
                                                                                    .get(
                                                                                        url,
                                                                                        {
                                                                                            responseType:
                                                                                                "blob",
                                                                                        }
                                                                                    )
                                                                                    .subscribe(
                                                                                        (
                                                                                            response
                                                                                        ) => {
                                                                                            if (
                                                                                                response.type ===
                                                                                                "application/pdf"
                                                                                            ) {
                                                                                                _this
                                                                                                    .getDoc(
                                                                                                        url
                                                                                                    )
                                                                                                    .subscribe(
                                                                                                        (
                                                                                                            x
                                                                                                        ) => {
                                                                                                            let fileInput: any =
                                                                                                                x;

                                                                                                            _this.saveToSignedEnvelopesInS3(
                                                                                                                envData,
                                                                                                                fileInput,
                                                                                                                url,
                                                                                                                shouldOpen,
                                                                                                                propertyId
                                                                                                            );
                                                                                                        },
                                                                                                        (
                                                                                                            error
                                                                                                        ) => {}
                                                                                                    );
                                                                                            } else {
                                                                                            }
                                                                                        },
                                                                                        (
                                                                                            error
                                                                                        ) => {
                                                                                            if (
                                                                                                error.status ===
                                                                                                200
                                                                                            ) {
                                                                                                _this
                                                                                                    .getDoc(
                                                                                                        url
                                                                                                    )
                                                                                                    .subscribe(
                                                                                                        (
                                                                                                            x
                                                                                                        ) => {
                                                                                                            let fileInput: any =
                                                                                                                x;
                                                                                                            _this.saveToSignedEnvelopesInS3(
                                                                                                                envData,
                                                                                                                fileInput,
                                                                                                                url,
                                                                                                                shouldOpen,
                                                                                                                propertyId
                                                                                                            );
                                                                                                        },
                                                                                                        (
                                                                                                            error
                                                                                                        ) => {}
                                                                                                    );
                                                                                            } else if (
                                                                                                error.status ===
                                                                                                404
                                                                                            ) {
                                                                                            } else {
                                                                                                console.error(
                                                                                                    "An error occurred while checking the URL:",
                                                                                                    error
                                                                                                );
                                                                                            }
                                                                                        }
                                                                                    );
                                                                            },
                                                                            2500
                                                                        );
                                                                    },
                                                                    (
                                                                        error
                                                                    ) => {}
                                                                );
                                                        }
                                                    },
                                                    (error) => {
                                                        if (
                                                            error.status === 200
                                                        ) {
                                                            _this
                                                                .getDoc(url)
                                                                .subscribe(
                                                                    (x) => {
                                                                        let fileInput: any =
                                                                            x;
                                                                        _this.saveToSignedEnvelopesInS3(
                                                                            envData,
                                                                            fileInput,
                                                                            url,
                                                                            shouldOpen,
                                                                            propertyId
                                                                        );
                                                                    },
                                                                    (
                                                                        error
                                                                    ) => {}
                                                                );
                                                        } else if (
                                                            error.status === 404
                                                        ) {
                                                            _this._bservice
                                                                .GetCompletedsignedEnvelope(
                                                                    envData
                                                                )
                                                                .subscribe(
                                                                    (
                                                                        response
                                                                    ) => {
                                                                        setTimeout(
                                                                            () => {
                                                                                _this.http
                                                                                    .get(
                                                                                        url,
                                                                                        {
                                                                                            responseType:
                                                                                                "blob",
                                                                                        }
                                                                                    )
                                                                                    .subscribe(
                                                                                        (
                                                                                            response
                                                                                        ) => {
                                                                                            if (
                                                                                                response.type ===
                                                                                                "application/pdf"
                                                                                            ) {
                                                                                                _this
                                                                                                    .getDoc(
                                                                                                        url
                                                                                                    )
                                                                                                    .subscribe(
                                                                                                        (
                                                                                                            x
                                                                                                        ) => {
                                                                                                            let fileInput: any =
                                                                                                                x;

                                                                                                            _this.saveToSignedEnvelopesInS3(
                                                                                                                envData,
                                                                                                                fileInput,
                                                                                                                url,
                                                                                                                shouldOpen,
                                                                                                                propertyId
                                                                                                            );
                                                                                                        },
                                                                                                        (
                                                                                                            error
                                                                                                        ) => {}
                                                                                                    );
                                                                                            } else {
                                                                                            }
                                                                                        },
                                                                                        (
                                                                                            error
                                                                                        ) => {
                                                                                            if (
                                                                                                error.status ===
                                                                                                200
                                                                                            ) {
                                                                                                _this
                                                                                                    .getDoc(
                                                                                                        url
                                                                                                    )
                                                                                                    .subscribe(
                                                                                                        (
                                                                                                            x
                                                                                                        ) => {
                                                                                                            let fileInput: any =
                                                                                                                x;
                                                                                                            _this.saveToSignedEnvelopesInS3(
                                                                                                                envData,
                                                                                                                fileInput,
                                                                                                                url,
                                                                                                                shouldOpen,
                                                                                                                propertyId
                                                                                                            );
                                                                                                        },
                                                                                                        (
                                                                                                            error
                                                                                                        ) => {}
                                                                                                    );
                                                                                            } else if (
                                                                                                error.status ===
                                                                                                404
                                                                                            ) {
                                                                                            } else {
                                                                                                console.error(
                                                                                                    "An error occurred while checking the URL:",
                                                                                                    error
                                                                                                );
                                                                                            }
                                                                                        }
                                                                                    );
                                                                            },
                                                                            2500
                                                                        );
                                                                    },
                                                                    (
                                                                        error
                                                                    ) => {}
                                                                );
                                                        } else {
                                                            console.error(
                                                                "An error occurred while checking the URL:",
                                                                error
                                                            );
                                                        }
                                                    }
                                                );
                                        }, 2500);
                                    },
                                    (error) => {}
                                );
                        }
                    }
                }
            }
        );
    }

    public getDoc(url: string): Observable<SafeResourceUrl> {
        const headers = new HttpHeaders().set(
            "Access-Control-Allow-Origin",
            "*"
        );
        return this.http.get(url, { responseType: "blob", headers }).pipe(
            map((x) => {
                return x;
            })
        );
    }

    saveToSignedEnvelopesInS3(
        envelopeData,
        fileInput: any,
        fileUrl,
        shouldOpen,
        propertyId
    ) {
        const bucketName = this.settings.AwsS3BucketName
            ? this.settings.AwsS3BucketName
            : "";
        const accessKeyId = this.settings.AwsAccessKeyId
            ? this.settings.AwsAccessKeyId
            : "";
        const secretAccessKey = this.settings.AwsSecretAccessKey
            ? this.settings.AwsSecretAccessKey
            : "";
        const region = this.settings.AwsConfigRegion
            ? this.settings.AwsConfigRegion
            : "";

        const bucket = new S3({
            accessKeyId: accessKeyId,
            secretAccessKey: secretAccessKey,
            region: region,
        });

        let tempFileName = fileUrl.split("/");
        let length = tempFileName.length;
        const propertyid = propertyId;
        const fileName1 = tempFileName[length - 1];
        let fileName1Array = fileName1.split("_");
        let length1 = fileName1Array.length;
        const fileName = fileName1Array[length1 - 1];

        const params = {
            Bucket: bucketName,
            Key:
                "property-signed-envelope/" +
                propertyid +
                "/envelopes/" +
                envelopeData.EnvelopeType +
                "/" +
                fileName,
            Body: fileInput,
            StorageClass: "STANDARD",
            ACL: "bucket-owner-full-control",
            ContentType: "application/pdf",
        };
        const _this = this;

        bucket.upload(params, function (err: any, data: any) {
            if (err) {
                alert(
                    "There was an error uploading your file: " +
                        JSON.stringify(err)
                );
                console.log("There was an error uploading your file: ", err);
                return false;
            } else {
                _this.updateSignedDocument(
                    envelopeData,
                    fileName,
                    fileUrl,
                    shouldOpen,
                    propertyId
                );
                return true;
            }
        });
    }

    updateSignedDocument(
        envelopeData,
        fileName,
        fileUrl,
        shouldOpen,
        propertyId
    ) {
        const bucketName = this.settings.AwsS3BucketName
            ? this.settings.AwsS3BucketName
            : "";
        const region = this.settings.AwsConfigRegion
            ? this.settings.AwsConfigRegion
            : "";
        let EnvelopeUrl =
            "property-signed-envelope/" +
            envelopeData.PropertyId +
            "/envelopes/" +
            envelopeData.EnvelopeType +
            "/" +
            fileName;
        envelopeData.UploadedStatus = 1;
        envelopeData.EnvelopeUrl = EnvelopeUrl;

        this._bservice.updatePropertySignEnvelopes(envelopeData).subscribe(
            (response) => {
                this.get_s3_signed_url(
                    EnvelopeUrl,
                    shouldOpen,
                    envelopeData,
                    propertyId
                );
            },
            (error) => {}
        );
    }
    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }
}
