<mat-dialog-content class="mat-typography">
    <strong
        >The bid on repair form on the following page should ONLY be completed
        by the GENERAL CONTRACTOR who will be filling in All components noted by
        the consultant which will include all pricing provided by the
        subcontractors (electrician, plumbler, septic,etc). <br /><br />
        Individual subcontractors should not be filling in this form<br />
        Please Provide breakdown for material & labor & blend into each line
        item your overhead & profit.</strong
    >
    <br /><br />
</mat-dialog-content>
<mat-dialog-actions align="center">
    <button class="popupLeftBtn" mat-button mat-dialog-close>Cancel</button>
    <button
        class="popupNextBtn"
        mat-button
        [mat-dialog-close]="true"
        (click)="redirectOnBid()"
        cdkFocusInitial
    >
        Ok
    </button>
</mat-dialog-actions>
