<div class="content p-24 tbl">
    <ngx-loading class="ngxLoader" 
        [show]="loading"
        [config]="{ backdropBorderRadius: '3px' }"
    ></ngx-loading>
    <h1 class="m-0">Permit Certification</h1>
    <br />
    <a
        class="addBorrow"
        href="javascript:void(0)"
        routerLink="/property-details/{{ propertyId }}/1"
        >Back to Property</a
    >
    <div class="container-login100">
        <mat-card class="my-form">
            <form
                [formGroup]="add_permitcertificate"
                class="example-form"
                (ngSubmit)="SaveRecord()"
            >
                <mat-card-content>
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    <label>
                                        <strong
                                            >Permit Required Prior To First
                                            Draw</strong
                                        >
                                    </label>
                                </td>
                                <td>
                                    <label>
                                        <strong
                                            >Certification Required After Work
                                            Complete</strong
                                        >
                                    </label>
                                </td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>
                                    <mat-checkbox
                                        [checked]="
                                            isChecked_FirstDraw_GeneralBuildingPermit
                                        "
                                        (change)="
                                            isChecked_FirstDraw_GeneralBuildingPermit =
                                                $event.checked
                                        "
                                        formControlName="FirstDraw_GeneralBuildingPermit"
                                    >
                                        General Building Permit</mat-checkbox
                                    >
                                </td>
                                <td>
                                    <mat-checkbox
                                        [checked]="
                                            isChecked_WorkComplete_GeneralBuildingPermit
                                        "
                                        (change)="
                                            isChecked_WorkComplete_GeneralBuildingPermit =
                                                $event.checked
                                        "
                                        formControlName="WorkComplete_GeneralBuildingPermit"
                                    >
                                        General Building Permit</mat-checkbox
                                    >
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <mat-checkbox
                                        [checked]="isChecked_FirstDraw_HVAC"
                                        (change)="
                                            isChecked_FirstDraw_HVAC =
                                                $event.checked
                                        "
                                        formControlName="FirstDraw_HVAC"
                                    >
                                        HVAC</mat-checkbox
                                    >
                                </td>
                                <td>
                                    <mat-checkbox
                                        [checked]="isChecked_WorkComplete_HVAC"
                                        (change)="
                                            isChecked_WorkComplete_HVAC =
                                                $event.checked
                                        "
                                        formControlName="WorkComplete_HVAC"
                                    >
                                        HVAC</mat-checkbox
                                    >
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <mat-checkbox
                                        [checked]="isChecked_FirstDraw_Roofing"
                                        (change)="
                                            isChecked_FirstDraw_Roofing =
                                                $event.checked
                                        "
                                        formControlName="FirstDraw_Roofing"
                                    >
                                        Roofing</mat-checkbox
                                    >
                                </td>
                                <td>
                                    <mat-checkbox
                                        [checked]="
                                            isChecked_WorkComplete_Roofing
                                        "
                                        (change)="
                                            isChecked_WorkComplete_Roofing =
                                                $event.checked
                                        "
                                        formControlName="WorkComplete_Roofing"
                                    >
                                        Roofing</mat-checkbox
                                    >
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <mat-checkbox
                                        [checked]="isChecked_FirstDraw_Well"
                                        (change)="
                                            isChecked_FirstDraw_Well =
                                                $event.checked
                                        "
                                        formControlName="FirstDraw_Well"
                                    >
                                        Well</mat-checkbox
                                    >
                                </td>

                                <td>
                                    <mat-checkbox
                                        [checked]="isChecked_WorkComplete_Well"
                                        (change)="
                                            isChecked_WorkComplete_Well =
                                                $event.checked
                                        "
                                        formControlName="WorkComplete_Well"
                                    >
                                        Well</mat-checkbox
                                    >
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <mat-checkbox
                                        [checked]="
                                            isChecked_FirstDraw_Electrical
                                        "
                                        (change)="
                                            isChecked_FirstDraw_Electrical =
                                                $event.checked
                                        "
                                        formControlName="FirstDraw_Electrical"
                                    >
                                        Electrical</mat-checkbox
                                    >
                                </td>
                                <td>
                                    <mat-checkbox
                                        [checked]="
                                            isChecked_WorkComplete_Electrical
                                        "
                                        (change)="
                                            isChecked_WorkComplete_Electrical =
                                                $event.checked
                                        "
                                        formControlName="WorkComplete_Electrical"
                                    >
                                        Electrical</mat-checkbox
                                    >
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <mat-checkbox
                                        [checked]="isChecked_FirstDraw_Plumbing"
                                        (change)="
                                            isChecked_FirstDraw_Plumbing =
                                                $event.checked
                                        "
                                        formControlName="FirstDraw_Plumbing"
                                    >
                                        Plumbing</mat-checkbox
                                    >
                                </td>
                                <td>
                                    <mat-checkbox
                                        [checked]="
                                            isChecked_WorkComplete_Plumbing
                                        "
                                        (change)="
                                            isChecked_WorkComplete_Plumbing =
                                                $event.checked
                                        "
                                        formControlName="WorkComplete_Plumbing"
                                    >
                                        Plumbing</mat-checkbox
                                    >
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <mat-checkbox
                                        [checked]="isChecked_FirstDraw_Septic"
                                        (change)="
                                            isChecked_FirstDraw_Septic =
                                                $event.checked
                                        "
                                        formControlName="FirstDraw_Septic"
                                    >
                                        Septic</mat-checkbox
                                    >
                                </td>
                                <td>
                                    <mat-checkbox
                                        [checked]="
                                            isChecked_WorkComplete_Septic
                                        "
                                        (change)="
                                            isChecked_WorkComplete_Septic =
                                                $event.checked
                                        "
                                        formControlName="WorkComplete_Septic"
                                    >
                                        Septic</mat-checkbox
                                    >
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <mat-checkbox
                                        [checked]="
                                            isChecked_FirstDraw_LeadAsbestos
                                        "
                                        (change)="
                                            isChecked_FirstDraw_LeadAsbestos =
                                                $event.checked
                                        "
                                        formControlName="FirstDraw_LeadAsbestos"
                                    >
                                        Lead Asbestos</mat-checkbox
                                    >
                                </td>
                                <td>
                                    <mat-checkbox
                                        [checked]="
                                            isChecked_WorkComplete_LeadAsbestos
                                        "
                                        (change)="
                                            isChecked_WorkComplete_LeadAsbestos =
                                                $event.checked
                                        "
                                        formControlName="WorkComplete_LeadAsbestos"
                                    >
                                        Lead Asbestos</mat-checkbox
                                    >
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <mat-checkbox
                                        [checked]="isChecked_FirstDraw_Termite"
                                        (change)="
                                            isChecked_FirstDraw_Termite =
                                                $event.checked
                                        "
                                        formControlName="FirstDraw_Termite"
                                    >
                                        Termite (Clearance)</mat-checkbox
                                    >
                                </td>
                                <td>
                                    <mat-checkbox
                                        [checked]="
                                            isChecked_WorkComplete_Termite
                                        "
                                        (change)="
                                            isChecked_WorkComplete_Termite =
                                                $event.checked
                                        "
                                        formControlName="WorkComplete_Termite"
                                    >
                                        Termite (Clearance)</mat-checkbox
                                    >
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <mat-checkbox
                                        [checked]="isChecked_FirstDraw_Other"
                                        (change)="
                                            isChecked_FirstDraw_Other =
                                                $event.checked
                                        "
                                        formControlName="FirstDraw_Other"
                                    >
                                        Other</mat-checkbox
                                    >
                                </td>
                                <td>
                                    <mat-checkbox
                                        [checked]="isChecked_WorkComplete_Other"
                                        (change)="
                                            isChecked_WorkComplete_Other =
                                                $event.checked
                                        "
                                        formControlName="WorkComplete_Other"
                                    >
                                        Other</mat-checkbox
                                    >
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <mat-checkbox
                                        [checked]="
                                            isChecked_FirstDraw_NotRequiredProject
                                        "
                                        (change)="
                                            isChecked_FirstDraw_NotRequiredProject =
                                                $event.checked
                                        "
                                        formControlName="FirstDraw_NotRequiredProject"
                                    >
                                        Not Required for the
                                        Project</mat-checkbox
                                    >
                                </td>
                                <td>
                                    <mat-checkbox
                                        [checked]="
                                            isChecked_WorkComplete_NotRequiredProject
                                        "
                                        (change)="
                                            isChecked_WorkComplete_NotRequiredProject =
                                                $event.checked
                                        "
                                        formControlName="WorkComplete_NotRequiredProject"
                                    >
                                        Not Required for the
                                        Project</mat-checkbox
                                    >
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label>
                                        <strong>Cost of Permit Fees</strong>
                                    </label>
                                </td>
                                <td>
                                    <label>
                                        <strong
                                            >Lead-Based Paint: Copy of
                                            Certification is required
                                        </strong>
                                    </label>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <mat-checkbox
                                        [checked]="
                                            isChecked_CostPermitFee_IncludeTotalAmount
                                        "
                                        (change)="
                                            isChecked_CostPermitFee_IncludeTotalAmount =
                                                $event.checked
                                        "
                                        formControlName="CostPermitFee_IncludeTotalAmount"
                                    >
                                        Cost of Permit Fee is Included in total
                                        amount of the project</mat-checkbox
                                    >
                                </td>
                                <td>
                                    <mat-checkbox
                                        [checked]="
                                            isChecked_LeadBased_ContractorLBPceritified
                                        "
                                        (change)="
                                            isChecked_LeadBased_ContractorLBPceritified =
                                                $event.checked
                                        "
                                        formControlName="LeadBased_ContractorLBPceritified"
                                    >
                                        Contractor is LBP
                                        certified</mat-checkbox
                                    >
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <mat-checkbox
                                        [checked]="
                                            isChecked_CostPermitFee_NotIncludedTotalAmount
                                        "
                                        (change)="
                                            isChecked_CostPermitFee_NotIncludedTotalAmount =
                                                $event.checked
                                        "
                                        formControlName="CostPermitFee_NotIncludedTotalAmount"
                                    >
                                        Cost of Permit Fee is Not Included in
                                        total amount of the
                                        project</mat-checkbox
                                    >
                                </td>
                                <td>
                                    <mat-checkbox
                                        [checked]="
                                            isChecked_LeadBased_EmployLBPceritified
                                        "
                                        (change)="
                                            isChecked_LeadBased_EmployLBPceritified =
                                                $event.checked
                                        "
                                        formControlName="LeadBased_EmployLBPceritified"
                                    >
                                        Contractor will employ an LBP-certified
                                        painter</mat-checkbox
                                    >
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <br />
                </mat-card-content>
                <mat-card-actions>
                    <button mat-raised-button type="submit" color="primary">
                        Save</button
                    >&nbsp; &nbsp;&nbsp;
                    <a
                        class="previewPdfBtn"
                        href="javascript:void(0);"
                        (click)="GetPermitsCertificationPdf()"
                        >Preview Pdf</a
                    >
                </mat-card-actions>
            </form>
        </mat-card>
    </div>
</div>
