<div class="container-login100">
    <ngx-loading class="ngxLoader" 
        [show]="loading"
        [config]="{ backdropBorderRadius: '3px' }"
    ></ngx-loading>
    <mat-card class="my-form" *ngIf="loginformshow == true">
        <mat-card-header>
            <mat-card-title>Renovation Exchange</mat-card-title>
        </mat-card-header>
        <form
            class="example-form"
            [formGroup]="login_form"
            class="login-form"
            (ngSubmit)="login()"
        >
            <mat-card-content>
                <table class="example-full-width" cellspacing="0">
                    <tr>
                        <td>
                            <mat-form-field class="example-full-width">
                                <span class="mat-form-field-label-wrapper"
                                    ><!----><label
                                        class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                        >E-mail or Username</label
                                    ></span
                                >
                                <input
                                    matInput
                                    formControlName="username"
                                    name="username"
                                />
                                <div
                                class="error"
                                *ngIf="
                                    (!login_form.controls[
                                        'username'
                                    ].valid &&
                                    login_form
                                            .controls[
                                            'username'
                                        ].touched) ||
                                    (login_form.controls[
                                        'username'
                                    ].invalid &&
                                        submitted)
                                "
                            >
                               Enter valid email or Username.
                            </div>
                            </mat-form-field>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <mat-form-field class="example-full-width">
                                <span class="mat-form-field-label-wrapper"
                                    ><!----><label
                                        class="mat-form-field-label ng-tns-c35-18 ng-star-inserted"
                                        >Password</label
                                    ></span
                                >
                                <input
                                    matInput
                                    formControlName="password"
                                    type="password"
                                    name="password"
                                />
                                <div
                                class="error"
                                *ngIf="
                                    (!login_form.controls[
                                        'password'
                                    ].valid &&
                                    login_form
                                            .controls[
                                            'password'
                                        ].touched) ||
                                    (login_form.controls[
                                        'password'
                                    ].invalid &&
                                        submitted)
                                "
                            >
                               Enter valid password.
                            </div>
                            </mat-form-field>
                        </td>
                    </tr>
                </table>
            </mat-card-content>
            <mat-card-actions>
                <button mat-raised-button type="submit" color="primary">
                    Login</button
                >&nbsp;
                <a (click)="showForgotForm()">Forgot Password</a>
            </mat-card-actions>
        </form>
    </mat-card>
    <mat-card class="my-form" *ngIf="forgotformshow == true">
        <mat-card-header>
            <mat-card-title>Renovation Exchange</mat-card-title>
        </mat-card-header>
        <form
            class="example-form"
            [formGroup]="forgot_form"
            class="login-form"
            (ngSubmit)="forgotpassword()"
        >
            <mat-card-content>
                <table class="example-full-width" cellspacing="0">
                    <tr>
                        <td>
                            <mat-form-field class="example-full-width">
                                <span class="mat-form-field-label-wrapper"
                                    ><!----><label
                                        class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                        >E-mail</label
                                    ></span
                                >
                                <input
                                    matInput
                                    formControlName="email"
                                    name="email"
                                />
                            </mat-form-field>
                        </td>
                    </tr>
                </table>
            </mat-card-content>
            <mat-card-actions>
                <button mat-raised-button type="submit" color="primary">
                    Send Mail</button
                >&nbsp;
                <a (click)="showLoginForm()">Login</a>
            </mat-card-actions>
        </form>
    </mat-card>
</div>
