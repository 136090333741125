import { Component, OnInit, ViewChild } from "@angular/core";
import { FormGroup, Validators, FormControl } from "@angular/forms";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { DataSource } from "@angular/cdk/table";
import { PropertyService } from "../property/property.service";
import { SessionService } from "../service/session.service";

import { Router, ActivatedRoute } from "@angular/router";
export interface UserData {
    id: string;
    name: string;
    email: string;
    lastLogin: Date;
}

/** Constants used to fill up our data base. */
const COLORS: string[] = [
    "maroon",
    "red",
    "orange",
    "yellow",
    "olive",
    "green",
    "purple",
    "fuchsia",
    "lime",
    "teal",
    "aqua",
    "blue",
    "navy",
    "black",
    "gray",
];
const NAMES: string[] = [
    "Maia",
    "Asher",
    "Olivia",
    "Atticus",
    "Amelia",
    "Jack",
    "Charlotte",
    "Theodore",
    "Isla",
    "Oliver",
    "Isabella",
    "Jasper",
    "Cora",
    "Levi",
    "Violet",
    "Arthur",
    "Mia",
    "Thomas",
    "Elizabeth",
];

/**
 * @title Data table with sorting, pagination, and filtering.
 */

@Component({
    selector: "app-permits-and-certifications-required",
    templateUrl: "./permits-and-certifications-required.component.html",
    styleUrls: ["./permits-and-certifications-required.component.scss"],
})
export class PermitsAndCertificationsRequiredComponent implements OnInit {
    baseurl = this.sessionService.endpoint;
    displayedColumns: string[] = [
        "ActionName",
        "AnonymousUser",
        "AuthenticatedUser",
        "Administrator",
        "Consultants",
        "GeneralContractors",
        "Borrower",
        "LoanOfficer",
        "LoanProcessor",
    ];
    dataSource: MatTableDataSource<UserData>;
    mobnumPattern = "^(d{3})sd{3}-d{4}";
    add_permitcertificate = new FormGroup({
        FirstDraw_GeneralBuildingPermit: new FormControl(null),
        FirstDraw_HVAC: new FormControl(null),
        FirstDraw_Roofing: new FormControl(null),
        FirstDraw_Well: new FormControl(null),
        FirstDraw_Electrical: new FormControl(null),
        FirstDraw_Plumbing: new FormControl(null),
        FirstDraw_Septic: new FormControl(null),
        FirstDraw_LeadAsbestos: new FormControl(null),
        FirstDraw_Termite: new FormControl(null),
        FirstDraw_Other: new FormControl(null),
        FirstDraw_NotRequiredProject: new FormControl(null),
        WorkComplete_GeneralBuildingPermit: new FormControl(null),
        WorkComplete_HVAC: new FormControl(null),
        WorkComplete_Roofing: new FormControl(null),
        WorkComplete_Well: new FormControl(null),
        WorkComplete_Electrical: new FormControl(null),
        WorkComplete_Plumbing: new FormControl(null),
        WorkComplete_Septic: new FormControl(null),
        WorkComplete_LeadAsbestos: new FormControl(null),
        WorkComplete_Termite: new FormControl(null),
        WorkComplete_Other: new FormControl(null),
        WorkComplete_NotRequiredProject: new FormControl(null),
        CostPermitFee_IncludeTotalAmount: new FormControl(null),
        CostPermitFee_NotIncludedTotalAmount: new FormControl(null),
        LeadBased_ContractorLBPceritified: new FormControl(null),
        LeadBased_EmployLBPceritified: new FormControl(null),
        id: new FormControl(null),
    });

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    isList: boolean = true;
    isAdd: boolean = false;
    isEdit: boolean = false;
    submitted: boolean = false;
    favoriteSeason: string;
    userDetail: any = {};
    userList: any = {};
    loading = false;
    isChecked_FirstDraw_GeneralBuildingPermit: boolean = false;
    isChecked_FirstDraw_HVAC: boolean = false;
    isChecked_FirstDraw_Roofing: boolean = false;
    isChecked_FirstDraw_Well: boolean = false;
    isChecked_FirstDraw_Electrical: boolean = false;
    isChecked_FirstDraw_Plumbing: boolean = false;
    isChecked_FirstDraw_Septic: boolean = false;
    isChecked_FirstDraw_LeadAsbestos: boolean = false;
    isChecked_FirstDraw_Termite: boolean = false;
    isChecked_FirstDraw_Other: boolean = false;
    isChecked_FirstDraw_NotRequiredProject: boolean = false;
    isChecked_WorkComplete_GeneralBuildingPermit: boolean = false;
    isChecked_WorkComplete_HVAC: boolean = false;
    isChecked_WorkComplete_Roofing: boolean = false;
    isChecked_WorkComplete_Well: boolean = false;
    isChecked_WorkComplete_Electrical: boolean = false;
    isChecked_WorkComplete_Plumbing: boolean = false;
    isChecked_WorkComplete_Septic: boolean = false;
    isChecked_WorkComplete_LeadAsbestos: boolean = false;
    isChecked_WorkComplete_Termite: boolean = false;
    isChecked_WorkComplete_Other: boolean = false;
    isChecked_WorkComplete_NotRequiredProject: boolean = false;
    isChecked_CostPermitFee_IncludeTotalAmount: boolean = false;
    isChecked_CostPermitFee_NotIncludedTotalAmount: boolean = false;
    isChecked_LeadBased_ContractorLBPceritified: boolean = false;
    isChecked_LeadBased_EmployLBPceritified: boolean = false;
    permitId: any = "";
    propertyId: any;
    pdfData: any = "";
    formattedaddress = "";
    options = {
        componentRestrictions: {
            country: ["US"],
        },
    };
    status: any = ["Blocked", "Active"];
    roles: any = [
        { Key: "1", Value: "authenticated user" },
        { Key: "2", Value: "Administrator" },
        { Key: "3", Value: "Consultants" },
        { Key: "4", Value: "General Contractors" },
        { Key: "5", Value: "Borrower" },
        { Key: "6", Value: "Loan Officer" },
        { key: "7", Value: "Loan Processor" },
        { key: "8", Value: " Notify user of new account" },
        { key: "9", Value: "Draw Administrator" },
    ];
    constructor(
        private _pservice: PropertyService,
        private sessionService: SessionService,
        private router: Router,
        private route: ActivatedRoute
    ) {}

    ngOnInit() {
        this.propertyId = this.route.snapshot.paramMap.get("propertyId");

        if (this.sessionService.get("userInfo") != undefined) {
            this.userDetail = JSON.parse(this.sessionService.get("userInfo"));
        }

        this.GetPermitsAndCertifications();
    }

    SaveRecord() {
        if (this.add_permitcertificate.invalid) {
            return;
        } else {
            this.submitted = false;
            var formData = this.add_permitcertificate.value;

            if (formData.id == null && !this.isEdit) {
                this.loading = true;
                var data = {
                    propertyId: this.propertyId,
                    FirstDraw_GeneralBuildingPermit:
                        formData.FirstDraw_GeneralBuildingPermit,
                    FirstDraw_HVAC: formData.FirstDraw_HVAC,
                    FirstDraw_Roofing: formData.FirstDraw_Roofing,
                    FirstDraw_Well: formData.FirstDraw_Well,
                    FirstDraw_Electrical: formData.FirstDraw_Electrical,
                    FirstDraw_Plumbing: formData.FirstDraw_Plumbing,
                    FirstDraw_Septic: formData.FirstDraw_Septic,
                    FirstDraw_LeadAsbestos: formData.FirstDraw_LeadAsbestos,
                    FirstDraw_Termite: formData.FirstDraw_Termite,
                    FirstDraw_Other: formData.FirstDraw_Other,
                    FirstDraw_NotRequiredProject:
                        formData.FirstDraw_NotRequiredProject,
                    WorkComplete_GeneralBuildingPermit:
                        formData.WorkComplete_GeneralBuildingPermit,
                    WorkComplete_HVAC: formData.WorkComplete_HVAC,
                    WorkComplete_Roofing: formData.WorkComplete_Roofing,
                    WorkComplete_Well: formData.WorkComplete_Well,
                    WorkComplete_Electrical: formData.WorkComplete_Electrical,
                    WorkComplete_Plumbing: formData.WorkComplete_Plumbing,
                    WorkComplete_Septic: formData.WorkComplete_Septic,
                    WorkComplete_LeadAsbestos:
                        formData.WorkComplete_LeadAsbestos,
                    WorkComplete_Termite: formData.WorkComplete_Termite,
                    WorkComplete_Other: formData.WorkComplete_Other,
                    WorkComplete_NotRequiredProject:
                        formData.WorkComplete_NotRequiredProject,
                    CostPermitFee_IncludeTotalAmount:
                        formData.CostPermitFee_IncludeTotalAmount,
                    CostPermitFee_NotIncludedTotalAmount:
                        formData.CostPermitFee_NotIncludedTotalAmount,
                    LeadBased_ContractorLBPceritified:
                        formData.LeadBased_ContractorLBPceritified,
                    LeadBased_EmployLBPceritified:
                        formData.LeadBased_EmployLBPceritified,
                };

                this._pservice.AddPermitCertifications(data).subscribe(
                    (response) => {
                        this.sessionService.notifier.notify(
                            "success",
                            "Permits and Certifications added successfully."
                        );
                        this.isList = true;
                        this.isAdd = false;
                        this.GetPermitsAndCertifications();
                        this.loading = false;
                        setTimeout(() => {
                            this.router.navigate([
                                "/property-details/" + this.propertyId + "/1",
                            ]);
                        }, 1500);
                    },
                    (error) => {
                        this.sessionService.notifier.notify(
                            "error",
                            error.error.message
                        );
                        this.loading = false;
                    }
                );
            } else {
                this.loading = true;

                var data1 = {
                    propertyId: this.propertyId,
                    FirstDraw_GeneralBuildingPermit:
                        this.isChecked_FirstDraw_GeneralBuildingPermit,
                    FirstDraw_HVAC: this.isChecked_FirstDraw_HVAC,
                    FirstDraw_Roofing: this.isChecked_FirstDraw_Roofing,
                    FirstDraw_Well: this.isChecked_FirstDraw_Well,
                    FirstDraw_Electrical: this.isChecked_FirstDraw_Electrical,
                    FirstDraw_Plumbing: this.isChecked_FirstDraw_Plumbing,
                    FirstDraw_Septic: this.isChecked_FirstDraw_Septic,
                    FirstDraw_LeadAsbestos:
                        this.isChecked_FirstDraw_LeadAsbestos,
                    FirstDraw_Termite: this.isChecked_FirstDraw_Termite,
                    FirstDraw_Other: this.isChecked_FirstDraw_Other,
                    FirstDraw_NotRequiredProject:
                        this.isChecked_FirstDraw_NotRequiredProject,
                    WorkComplete_GeneralBuildingPermit:
                        this.isChecked_WorkComplete_GeneralBuildingPermit,
                    WorkComplete_HVAC: this.isChecked_WorkComplete_HVAC,
                    WorkComplete_Roofing: this.isChecked_WorkComplete_Roofing,
                    WorkComplete_Well: this.isChecked_WorkComplete_Well,
                    WorkComplete_Electrical:
                        this.isChecked_WorkComplete_Electrical,
                    WorkComplete_Plumbing: this.isChecked_WorkComplete_Plumbing,
                    WorkComplete_Septic: this.isChecked_WorkComplete_Septic,
                    WorkComplete_LeadAsbestos:
                        this.isChecked_WorkComplete_LeadAsbestos,
                    WorkComplete_Termite: this.isChecked_WorkComplete_Termite,
                    WorkComplete_Other: this.isChecked_WorkComplete_Other,
                    WorkComplete_NotRequiredProject:
                        this.isChecked_WorkComplete_NotRequiredProject,
                    CostPermitFee_IncludeTotalAmount:
                        this.isChecked_CostPermitFee_IncludeTotalAmount,
                    CostPermitFee_NotIncludedTotalAmount:
                        this.isChecked_CostPermitFee_NotIncludedTotalAmount,
                    LeadBased_ContractorLBPceritified:
                        this.isChecked_LeadBased_ContractorLBPceritified,
                    LeadBased_EmployLBPceritified:
                        this.isChecked_LeadBased_EmployLBPceritified,
                    Id: this.permitId,
                };

                this._pservice.UpdatePermitCertifications(data1).subscribe(
                    (response) => {
                        this.sessionService.notifier.notify(
                            "success",
                            "Permits and Certifications updated successfully."
                        );

                        this.isList = true;
                        this.isAdd = false;
                        this.isEdit = false;
                        this.GetPermitsAndCertifications();
                        this.loading = false;
                        setTimeout(() => {
                            this.router.navigate([
                                "/property-details/" + this.propertyId + "/1",
                            ]);
                        }, 1500);
                    },
                    (error) => {
                        this.sessionService.notifier.notify(
                            "error",
                            error.error.message
                        );
                        this.loading = false;
                    }
                );
            }
        }
    }
    GetPermitsCertificationPdf() {
        this.loading = true;
        var pData = { PropertyId: this.propertyId };
        this._pservice
            .GetPermitCertificationsPDF(pData)
            .subscribe((response) => {
                if (response != null) {
                    window.open(this.baseurl + response, "_blank");
                } else {
                    alert("Pdf Not available.");
                }
                this.loading = false;
            });
    }
    GetPermitsAndCertifications() {
        this._pservice
            .GetPermitCertifications(this.propertyId)
            .subscribe((response) => {
                if (response != null) {
                    this.isEdit = true;
                    this.add_permitcertificate.controls["id"].setValue(
                        response.Id
                    );
                    this.permitId = response.Id;
                    this.isChecked_FirstDraw_GeneralBuildingPermit =
                        response.FirstDraw_GeneralBuildingPermit;
                    this.isChecked_FirstDraw_HVAC = response.FirstDraw_HVAC;
                    this.isChecked_FirstDraw_Roofing =
                        response.FirstDraw_Roofing;
                    this.isChecked_FirstDraw_Well = response.FirstDraw_Well;
                    this.isChecked_FirstDraw_Electrical =
                        response.FirstDraw_Electrical;
                    this.isChecked_FirstDraw_Plumbing =
                        response.FirstDraw_Plumbing;
                    this.isChecked_FirstDraw_Septic = response.FirstDraw_Septic;
                    this.isChecked_FirstDraw_LeadAsbestos =
                        response.FirstDraw_LeadAsbestos;
                    this.isChecked_FirstDraw_Termite =
                        response.FirstDraw_Termite;
                    this.isChecked_FirstDraw_Other = response.FirstDraw_Other;
                    this.isChecked_FirstDraw_NotRequiredProject =
                        response.FirstDraw_NotRequiredProject;
                    this.isChecked_WorkComplete_GeneralBuildingPermit =
                        response.WorkComplete_GeneralBuildingPermit;
                    this.isChecked_WorkComplete_HVAC =
                        response.WorkComplete_HVAC;
                    this.isChecked_WorkComplete_Roofing =
                        response.WorkComplete_Roofing;
                    this.isChecked_WorkComplete_Well =
                        response.WorkComplete_Well;
                    this.isChecked_WorkComplete_Electrical =
                        response.WorkComplete_Electrical;
                    this.isChecked_WorkComplete_Plumbing =
                        response.WorkComplete_Plumbing;
                    this.isChecked_WorkComplete_Septic =
                        response.WorkComplete_Septic;
                    this.isChecked_WorkComplete_LeadAsbestos =
                        response.WorkComplete_LeadAsbestos;
                    this.isChecked_WorkComplete_Termite =
                        response.WorkComplete_Termite;
                    this.isChecked_WorkComplete_Other =
                        response.WorkComplete_Other;
                    this.isChecked_WorkComplete_NotRequiredProject =
                        response.WorkComplete_NotRequiredProject;
                    this.isChecked_CostPermitFee_IncludeTotalAmount =
                        response.CostPermitFee_IncludeTotalAmount;
                    this.isChecked_CostPermitFee_NotIncludedTotalAmount =
                        response.CostPermitFee_NotIncludedTotalAmount;
                    this.isChecked_LeadBased_ContractorLBPceritified =
                        response.LeadBased_ContractorLBPceritified;
                    this.isChecked_LeadBased_EmployLBPceritified =
                        response.LeadBased_EmployLBPceritified;
                }
            });
    }

    formReset() {
        this.add_permitcertificate.reset();
    }
}
