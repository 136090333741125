<div class="content p-24 tbl">
    <ngx-loading class="ngxLoader" 
        [show]="loading"
        [config]="{ backdropBorderRadius: '3px' }"
    ></ngx-loading>
    <h1 class="m-0">Roles</h1>
    <div style="margin: -34px 0 0 0">
        <a
            class="addBorrow"
            href="javascript:void(0)"
            style="margin: 0px 0px 0 10px"
            ><button mat-raised-button type="button" color="primary">
                Roles
            </button></a
        >
        <a class="addBorrow" routerLink="/permission" style="margin: 0px"
            ><button mat-raised-button type="button">Permissions</button></a
        >
    </div>
    <br />
    <!-- <mat-form-field>
  <input [hidden]="!isList" matInput (keyup)="applyFilter($event.target)" placeholder="Filter">
</mat-form-field> -->
    <p style="margin: 35px 0 20px 0; display: block">
        Roles allow you to fine tune the security and administration of Drupal.
        A role defines a group of users that have certain privileges as defined
        on the permissions page. Examples of roles include: anonymous user,
        authenticated user, moderator, administrator and so on. In this area you
        will define the names and order of the roles on your site. It is
        recommended to order your roles from least permissive (anonymous user)
        to most permissive (administrator). To delete a role choose "edit role".
    </p>

    <p>By default, Drupal comes with two user roles:</p>
    <div>
        <ul>
            <li>
                Anonymous user: this role is used for users that don't have a
                user account or that are not authenticated.
            </li>
            <li>
                Authenticated user: this role is automatically granted to all
                logged in users.
            </li>
        </ul>
    </div>

    <form [formGroup]="add_loanOfficer" class="example-form">
        <mat-card style="margin: 0 0 20px 0px">
            <mat-card-content>
                <mat-form-field class="example-full-width">
                    <span class="mat-form-field-label-wrapper"
                        ><!----><label
                            class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                            >Name *</label
                        ></span
                    >
                    <input matInput formControlName="city" name="city" />
                </mat-form-field>
                <button mat-raised-button type="button" color="info">
                    Add Role
                </button>
            </mat-card-content>
        </mat-card>
    </form>

    <div class="mat-elevation-z8" [hidden]="!isList">
        <table mat-table [dataSource]="dataSource" matSort>
            <!-- ID Column -->
            <ng-container matColumnDef="RoleName">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                    style="font-size: 12px"
                >
                    NAME
                </th>
                <td mat-cell *matCellDef="let row">
                    {{ row.RoleName }}
                    <span *ngIf="row.status == 'locked'"
                        >({{ row.status }})</span
                    >
                </td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="Operations">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                    style="font-size: 12px; text-align: center"
                >
                    OPERATIONS
                </th>
                <td mat-cell *matCellDef="let row" style="text-align: center">
                    <a href="" style="margin: 0 20px 0 0px">Edit Role</a>
                    <a href="">Edit Permissions</a>
                </td>
            </ng-container>
            <!-- Color Column -->
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr
                mat-row
                *matRowDef="let row; columns: displayedColumns"
                style="cursor: pointer"
            ></tr>
        </table>
        <!-- <button style="margin:20px;" mat-raised-button type="button" color="info">Save Order</button> -->
    </div>
</div>
