<div class="content p-24 tbl">
    <ngx-loading class="ngxLoader" 
        [show]="loading"
        [config]="{ backdropBorderRadius: '3px' }"
    ></ngx-loading>
    <h1 class="m-0">Manage Allowable Fees</h1>
    <a
        *ngIf="isList"
        class="addBorrow"
        href="javascript:void(0)"
        (click)="isList = false; isAdd = true; isEdit = false; formReset()"
        ><mat-icon
            _ngcontent-c102=""
            class="secondary-text s-22 mat-icon material-icons mat-icon-no-color"
            role="img"
            aria-hidden="true"
            >add_circle</mat-icon
        >
        Add Allowable Fees</a
    >
    <a
        *ngIf="isAdd || isEdit"
        class="addBorrow"
        href="javascript:void(0)"
        (click)="isAdd = false; isList = true; isEdit = false"
        ><mat-icon
            _ngcontent-c102=""
            class="secondary-text s-22 mat-icon material-icons mat-icon-no-color"
            role="img"
            aria-hidden="true"
            >view_list</mat-icon
        >
        List Allowable Fees</a
    >
    <mat-form-field>
        <span class="mat-form-field-label-wrapper"
            ><!----><label
                class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                >Filter</label
            ></span
        >
        <input
            [hidden]="!isList"
            matInput
            (keyup)="applyFilter($event.target)"
        />
    </mat-form-field>

    <div class="mat-elevation-z8" [hidden]="!isList">
        <table mat-table [dataSource]="dataSource" matSort>
            <!-- ID Column -->
            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
                <td mat-cell *matCellDef="let row">{{ row.id }}</td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="title">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Title</th>
                <td mat-cell *matCellDef="let row">{{ row.Title }}</td>
            </ng-container>
            <ng-container matColumnDef="note">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Note</th>
                <td mat-cell *matCellDef="let row">{{ row.Notes }}</td>
            </ng-container>
            <!-- Progress Column -->
            <ng-container matColumnDef="fees">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Fees</th>
                <td mat-cell *matCellDef="let row">{{ row.Fees }}</td>
            </ng-container>

            <!-- <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Actions </th>
    </ng-container> -->

            <!-- Color Column -->
            <!-- <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Last login </th>
      <td mat-cell *matCellDef="let row" [style.color]="row.lastLogin"> {{row.UpdateDate | date :'medium'}} </td>
    </ng-container> -->

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr
                mat-row
                *matRowDef="let row; columns: displayedColumns"
                style="cursor: pointer"
                (click)="EditUser(row)"
            ></tr>
        </table>

        <mat-paginator
            (page)="nextPage($event)"
            [pageSizeOptions]="[20, 50, 100]"
        ></mat-paginator>
    </div>
    <div class="mat-elevation-z8" *ngIf="isAdd || isEdit">
        <div class="container-login100">
            <mat-card class="my-form">
                <form
                    [formGroup]="add_Borrower"
                    class="example-form"
                    (ngSubmit)="SaveBorrower()"
                >
                    <mat-card-header>
                        <mat-card-title
                            >{{ isEdit ? "Edit" : "Add" }} Allowable
                            Fees</mat-card-title
                        >
                    </mat-card-header>
                    <mat-card-content>
                        <mat-form-field class="example-full-width">
                            <span class="mat-form-field-label-wrapper"
                                ><!----><label
                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                    >Title *</label
                                ></span
                            >
                            <input
                                matInput
                                formControlName="title"
                                name="title"
                            />
                            <div class="error" *ngIf="(!add_Borrower.controls['title'].valid && add_Borrower.controls['title'].touched) || (add_Borrower.controls['title'].invalid && submitted)">
                                Enter valid title                
                              </div>
                        </mat-form-field>

                        <mat-form-field class="example-full-width">
                            <span class="mat-form-field-label-wrapper"
                                ><!----><label
                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                    >Note</label
                                ></span
                            >
                            <input
                                matInput
                                formControlName="note"
                                name="note"
                            />
                            <div class="error" *ngIf="(!add_Borrower.controls['note'].valid && add_Borrower.controls['note'].touched) || (add_Borrower.controls['note'].invalid && submitted)">
                                Please limit your input to 1000 characters or less.                
                              </div>
                        </mat-form-field>
                        <mat-form-field>
                            <span class="mat-form-field-label-wrapper"
                                ><!----><label
                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                    >Fee</label
                                ></span
                            >
                            <input
                                matInput
                                formControlName="fee"
                                type="number"
                                class="example-right-align"
                            />
                            <span matPrefix>$&nbsp;</span>
                            <span matSuffix>.00</span>
                        </mat-form-field>
                    </mat-card-content>
                    <mat-card-actions>
                        <button mat-raised-button type="submit" color="primary">
                            {{ isEdit ? "Save" : "Save" }}</button
                        >&nbsp; &nbsp;&nbsp;
                        <button
                            mat-raised-button
                            *ngIf="isDelete"
                            (click)="deleteAllowableFees(add_Borrower.value.id)"
                            type="button"
                            color="warn"
                        >
                            Delete</button
                        >&nbsp; &nbsp;&nbsp;
                        <button
                            mat-raised-button
                            (click)="borrowerList()"
                            type="button"
                            color="info"
                        >
                            Back
                        </button>
                    </mat-card-actions>
                </form>
            </mat-card>
        </div>
    </div>
</div>
