<div class="content p-24 tbl">
    <ngx-loading class="ngxLoader" 
        [show]="loading"
        [config]="{ backdropBorderRadius: '3px' }"
    ></ngx-loading>
    <h1 class="m-0">Cover Sheet</h1>
    <br />
    <div class="container-login100">
        <mat-card class="my-form">
            <form
                [formGroup]="add_loanOfficer"
                class="example-form"
                (ngSubmit)="SaveRecord()"
            >
                <mat-card-content>
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    <mat-form-field class="example-full-width">
                                        <span
                                            class="mat-form-field-label-wrapper"
                                            ><!----><label
                                                class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                >Title*</label
                                            ></span
                                        >
                                        <input
                                            matInput
                                            formControlName="email"
                                            name="username"
                                        />
                                    </mat-form-field>
                                </td>
                                <td>
                                    <mat-form-field>
                                        <span
                                            class="mat-form-field-label-wrapper"
                                            ><!----><label
                                                class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                >Property</label
                                            ></span
                                        >
                                        <mat-select formControlName="Property">
                                            <mat-option value=""
                                                >Select</mat-option
                                            >
                                            <mat-option value="RMS"
                                                >1 City Hall Square , Boston,
                                                Massachusetts, 02201</mat-option
                                            >
                                        </mat-select>
                                    </mat-form-field>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <mat-form-field class="example-full-width">
                                        <span
                                            class="mat-form-field-label-wrapper"
                                            ><!----><label
                                                class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                >Prepared By</label
                                            ></span
                                        >
                                        <input
                                            matInput
                                            formControlName="PreparedBy"
                                            name="username"
                                        />
                                    </mat-form-field>
                                </td>
                                <td>
                                    <mat-form-field class="example-full-width">
                                        <span
                                            class="mat-form-field-label-wrapper"
                                            ><!----><label
                                                class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                >Email ID</label
                                            ></span
                                        >
                                        <input
                                            matInput
                                            formControlName="EmailID"
                                            name="username"
                                        />
                                    </mat-form-field>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title> CLIENT </mat-panel-title>
                        </mat-expansion-panel-header>

                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <mat-form-field>
                                            <span
                                                class="mat-form-field-label-wrapper"
                                                ><!----><label
                                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                    >Client's Name</label
                                                ></span
                                            >
                                            <input matInput />
                                        </mat-form-field>
                                    </td>
                                    <td>
                                        <mat-form-field>
                                            <span
                                                class="mat-form-field-label-wrapper"
                                                ><!----><label
                                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                    >Client's Phone</label
                                                ></span
                                            >
                                            <input
                                                matInput
                                                (keyup)="
                                                    keyDownPhoneFormat($event)
                                                "
                                                (change)="
                                                    changePhoneFormat($event)
                                                "
                                            />
                                        </mat-form-field>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <mat-form-field>
                                            <span
                                                class="mat-form-field-label-wrapper"
                                                ><!----><label
                                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                    >Client's Email</label
                                                ></span
                                            >
                                            <input matInput />
                                        </mat-form-field>
                                    </td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>

                        <label
                            ><strong>Property Inspection Address</strong>
                        </label>
                        <ckeditor
                            matNativeControl
                            name="editor1"
                            formControlName="editorValue"
                            skin="moono-lisa"
                            language="en"
                        ></ckeditor>
                    </mat-expansion-panel>
                    <br />
                    <mat-form-field class="example-full-width">
                        <span class="mat-form-field-label-wrapper"
                            ><!----><label
                                class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                >Ext</label
                            ></span
                        >
                        <input matInput formControlName="Ext" name="username" />
                    </mat-form-field>

                    <label> <strong>Cover Sheet Image</strong> </label><br />
                    <input type="file" /><br />
                    <p>
                        Files must be less than <strong>100 MB.</strong> <br />
                        Allowed file types: <strong>png gif jpg jpeg.</strong>
                    </p>
                </mat-card-content>
                <mat-card-actions>
                    <button mat-raised-button type="submit" color="primary">
                        Save</button
                    >&nbsp; &nbsp;&nbsp;
                    <button mat-raised-button type="button" color="info">
                        Save as draft</button
                    >&nbsp; &nbsp;&nbsp;
                    <button mat-raised-button type="button" color="info">
                        Preview
                    </button>
                </mat-card-actions>
            </form>
        </mat-card>
    </div>
</div>
