import {Component, OnInit, ViewChild} from '@angular/core';
import { FormGroup,Validators,FormControl} from "@angular/forms";
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DataSource } from '@angular/cdk/table';
import {BorrowerService } from '../borrower/borrower.service';
import { SessionService } from '../service/session.service';

import { Router, ActivatedRoute } from '@angular/router';

export interface UserData {
  id: string;
  name: string;
  email: string;
  lastLogin: Date
}

/** Constants used to fill up our data base. */
const COLORS: string[] = ['maroon', 'red', 'orange', 'yellow', 'olive', 'green', 'purple',
  'fuchsia', 'lime', 'teal', 'aqua', 'blue', 'navy', 'black', 'gray'];
const NAMES: string[] = ['Maia', 'Asher', 'Olivia', 'Atticus', 'Amelia', 'Jack',
  'Charlotte', 'Theodore', 'Isla', 'Oliver', 'Isabella', 'Jasper',
  'Cora', 'Levi', 'Violet', 'Arthur', 'Mia', 'Thomas', 'Elizabeth'];

/**
 * @title Data table with sorting, pagination, and filtering.
 */

@Component({
  selector: 'app-change-order',
  templateUrl: './change-order.component.html',
  styleUrls: ['./change-order.component.scss']
})
export class ChangeOrderComponent implements OnInit {

 displayedColumns: string[] = ['ActionName','AnonymousUser', 
 'AuthenticatedUser', 'Administrator', 'Consultants','GeneralContractors','Borrower',
 'LoanOfficer','LoanProcessor'];
  dataSource: MatTableDataSource<UserData>;
  mobnumPattern = "^\(\d{3}\)\s\d{3}-\d{4}";
    add_loanOfficer=new FormGroup({
    email: new FormControl(null, [Validators.required, Validators.email,Validators.maxLength(40),Validators.minLength(5)]),
    fname: new FormControl(null, [Validators.required,Validators.maxLength(30),Validators.minLength(3)]),
    lname: new FormControl(null, [Validators.required,Validators.maxLength(30),Validators.minLength(3)]),
    phone_no: new FormControl(null,[Validators.pattern(this.mobnumPattern), Validators.maxLength(15),Validators.minLength(8)]),
    statusType:new FormControl('Active',[Validators.required]),
    password: new FormControl(null, [Validators.required, Validators.maxLength(15),Validators.minLength(4)]),
    confirmPassword: new FormControl(null, [Validators.required, Validators.maxLength(15),Validators.minLength(4)]),
    country:new FormControl('us'),
    city:new FormControl(null),
    state:new FormControl(null),
    zipcode: new FormControl(null, [Validators.maxLength(10),Validators.minLength(3)]),
    address1:new FormControl(null),
    address2:new FormControl(null),
    lender:new FormControl(null,[Validators.required]),
    id:new FormControl(null)
  })

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  isList:boolean=true;
  isAdd:boolean=false;
  isEdit:boolean=false;
  submitted:boolean=false;
  favoriteSeason: string;
  userDetail:any={};
  userList:any={};
  loading=false;
  formattedaddress="";
  options={ 
    componentRestrictions:{ 
      country:["US"] 
    } 
  }  
  status:any=['Blocked','Active'];
  roles:any=[{Key:'1',Value:'authenticated user'},{Key:'2',Value:'Administrator'},{Key:'3',Value:'Consultants'},{Key:'4',Value:'General Contractors'},{Key:'5',Value:'Borrower'},
  {Key:'6',Value:'Loan Officer'},{key:'7',Value:'Loan Processor'},{key:'8',Value:' Notify user of new account'}, {key : '9', Value : 'Draw Administrator'}];
  constructor(private _bservice:BorrowerService,private sessionService:SessionService,private router: Router,
    private route: ActivatedRoute) {
   
  }

  ngOnInit() {
      if(this.sessionService.get('userInfo')!=undefined)
    {
      this.userDetail=JSON.parse(this.sessionService.get('userInfo'));
    }
    this.GetUserList();
  }

  

  public AddressChange(address: any) { 
    let addressFielsLength = address.address_components.length;
    let address1='';
    
    let city='';
    let state='';
    let zipcode='';

          for(let i=0; i < addressFielsLength; i++)
          {
            if(address.address_components[i].types[0] == 'street_number')
          {
            address1= address.address_components[i].long_name;
            
          }


          if(address.address_components[i].types[0] == 'route')
          {
            address1=  address1 +' '+ address.address_components[i].long_name;
            
          }
         
          
          if(address.address_components[i].types[0] == 'sublocality_level_1' || address.address_components[i].types[0] == 'locality' || address.address_components[i].types[0] == 'neighborhood')
          {
           
            city=address.address_components[i].long_name;
            
          }
          if(address.address_components[i].types[0] == 'administrative_area_level_1')
          {
            
            state=address.address_components[i].short_name;
            
          }
           if(address.address_components[i].types[0] == 'postal_code')
          {
           
            zipcode=address.address_components[i].long_name;
           
          }


          }
        


         

          

         
          this.add_loanOfficer.controls['address1'].setValue(address1);
          
          this.add_loanOfficer.controls['city'].setValue(city);
          this.add_loanOfficer.controls['state'].setValue(state);
          this.add_loanOfficer.controls['zipcode'].setValue(zipcode);
    
  } 
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  showAddForm(){
    this.isAdd=true;
    this.isList=false;
  }

  loanOfficerList(){
    this.isEdit=false;
    this.isList=true;
  }

    ValidatePartnerPass()
  {

    let password = this.add_loanOfficer.controls.password.value;
    let repeatPassword = this.add_loanOfficer.controls.confirmPassword.value;


    if (repeatPassword !== password) {
     
      this.add_loanOfficer.controls["confirmPassword"].setErrors({'incorrect': true});

    }
    else {
      
      this.add_loanOfficer.controls["confirmPassword"].setErrors(null);

    }
  }
  SaveRecord(){
     
    if(this.add_loanOfficer.invalid)
    {
      return;
    }
    else{
       this.submitted=false;  
      var formData=this.add_loanOfficer.value; 
    
       if(formData.id==null && !this.isEdit)
      {
        this.loading=true;
       var data={"EmailId":formData.email,"Password":formData.password,"UserType":this.sessionService.userType[4].Value,"FirstName":formData.fname,
      "LastName":formData.lname,"Status":formData.statusType,"Phone":formData.phone_no,"Address1":formData.address1,"Addresss2":formData.address2,
      "City":formData.city,"State":formData.state,"Zip":formData.zipcode,"Country":formData.country,
      "CreatedBy":this.userDetail.EmailId,"Lender":formData.lender}
      this._bservice.AddBorrower(data).subscribe(response=>{
          this.sessionService.notifier.notify('success','Loan officer added successfully.');
          this.isList=true;
          this.isAdd=false;
           this.GetUserList();
            this.loading=false;
      },error=>{
          this.sessionService.notifier.notify('error',error.error.message);
           this.loading=false;
      })
      }
      else{
         this.loading=true;
         var data1={"EmailId":formData.email,"Password":formData.password,"UserType":this.sessionService.userType[4].Value,"FirstName":formData.fname,
      "LastName":formData.lname,"Status":formData.statusType,"Phone":formData.phone_no,"Address1":formData.address1,"Addresss2":formData.address2,
      "City":formData.city,"State":formData.state,"Zip":formData.zipcode,"Country":formData.country,
      "CreatedBy":this.userDetail.EmailId,"Lender":formData.lender,"Id":formData.id}
      this._bservice.UpdateBorrower(data1).subscribe(response=>{
          this.sessionService.notifier.notify('success','Loan officer updated successfully.');
        
          this.isList=true;
          this.isAdd=false;
          this.isEdit=false;
            this.formReset();
            this.GetUserList();
             this.loading=false;
      },error=>{
          this.sessionService.notifier.notify('error',error.error.message);
           this.loading=false;
      })
      } 
    }
    window.scroll(0,0);
  }

   nextPage(event:PageEvent)
  {
     
  }

  GetUserList(){
     
     this.loading=true;
    var data={
        "EmailId":"",
        "PageSize": 100000,
        "LastEvaluatedKey":0,
        "UserType":this.sessionService.userType[4].Value
        }
      this._bservice.GetUserList(data).subscribe(response=>{
           
          if(response!=null)
          {
            var setPermissionData = {
              "Count":40,
              "ScannedCount":40,
              "TotalRecord":40,
              "Items":[
                {
                    "IsHeading":false,
                    "ActionName":"Lender: Create new content",
                    "Descriptions":"",
                    "AnonymousUser":true,
                    "AuthenticatedUser":true,
                    "Administrator":true,
                    "Consultants":true,
                    "GeneralContractors":true,
                    "Borrower":true,
                    "LoanOfficer":true,
                    "LoanProcessor":true,
                    "DrawAdministrator":true,
                },
                {
                    "IsHeading":false,
                    "ActionName":"Lender: Edit own content",
                    "Descriptions":"",
                    "AnonymousUser":true,
                    "AuthenticatedUser":true,
                    "Administrator":true,
                    "Consultants":true,
                    "GeneralContractors":true,
                    "Borrower":true,
                    "LoanOfficer":true,
                    "LoanProcessor":true,
                    "DrawAdministrator":true,
                },
                {
                    "IsHeading":false,
                    "ActionName":"Lender: Edit any content",
                    "Descriptions":"",
                    "AnonymousUser":true,
                    "AuthenticatedUser":true,
                    "Administrator":true,
                    "Consultants":true,
                    "GeneralContractors":true,
                    "Borrower":true,
                    "LoanOfficer":true,
                    "LoanProcessor":true,
                    "DrawAdministrator":true,
                },
                {
                    "IsHeading":false,
                    "ActionName":"Lender: Delete own content",
                    "Descriptions":"",
                    "AnonymousUser":true,
                    "AuthenticatedUser":true,
                    "Administrator":true,
                    "Consultants":true,
                    "GeneralContractors":true,
                    "Borrower":true,
                    "LoanOfficer":true,
                    "LoanProcessor":true,
                    "DrawAdministrator":true,
                },
                {
                    "IsHeading":false,
                    "ActionName":"Lender: Delete any content",
                    "Descriptions":"",
                    "AnonymousUser":true,
                    "AuthenticatedUser":true,
                    "Administrator":true,
                    "Consultants":true,
                    "GeneralContractors":true,
                    "Borrower":true,
                    "LoanOfficer":true,
                    "LoanProcessor":true,
                    "DrawAdministrator":true,
                },
                {
                    "IsHeading":false,
                    "ActionName":"Loan Officer: Create new content",
                    "Descriptions":"",
                    "AnonymousUser":true,
                    "AuthenticatedUser":true,
                    "Administrator":true,
                    "Consultants":true,
                    "GeneralContractors":true,
                    "Borrower":true,
                    "LoanOfficer":true,
                    "LoanProcessor":true,
                    "DrawAdministrator":true,
                },
                {
                    "IsHeading":false,
                    "ActionName":"Loan Officer: Edit own content",
                    "Descriptions":"",
                    "AnonymousUser":true,
                    "AuthenticatedUser":true,
                    "Administrator":true,
                    "Consultants":true,
                    "GeneralContractors":true,
                    "Borrower":true,
                    "LoanOfficer":true,
                    "LoanProcessor":true,
                    "DrawAdministrator":true,
                },
                {
                    "IsHeading":false,
                    "ActionName":"Loan Officer: Edit any content",
                    "Descriptions":"",
                    "AnonymousUser":true,
                    "AuthenticatedUser":true,
                    "Administrator":true,
                    "Consultants":true,
                    "GeneralContractors":true,
                    "Borrower":true,
                    "LoanOfficer":true,
                    "LoanProcessor":true,
                    "DrawAdministrator":true,
                },
                {
                    "IsHeading":false,
                    "ActionName":"Loan Officer: Delete own content",
                    "Descriptions":"",
                    "AnonymousUser":true,
                    "AuthenticatedUser":true,
                    "Administrator":true,
                    "Consultants":true,
                    "GeneralContractors":true,
                    "Borrower":true,
                    "LoanOfficer":true,
                    "LoanProcessor":true,
                    "DrawAdministrator":true,
                },
                {
                    "IsHeading":false,
                    "ActionName":"Loan Officer: Delete any content",
                    "Descriptions":"",
                    "AnonymousUser":true,
                    "AuthenticatedUser":true,
                    "Administrator":true,
                    "Consultants":true,
                    "GeneralContractors":true,
                    "Borrower":true,
                    "LoanOfficer":true,
                    "LoanProcessor":true,
                    "DrawAdministrator":true,
                },
                {
                    "IsHeading":false,
                    "ActionName":"Property: Create new content",
                    "Descriptions":"",
                    "AnonymousUser":true,
                    "AuthenticatedUser":true,
                    "Administrator":true,
                    "Consultants":true,
                    "GeneralContractors":true,
                    "Borrower":true,
                    "LoanOfficer":true,
                    "LoanProcessor":true,
                    "DrawAdministrator":true,
                },
                {
                    "IsHeading":false,
                    "ActionName":"Property: Edit own content",
                    "Descriptions":"",
                    "AnonymousUser":true,
                    "AuthenticatedUser":true,
                    "Administrator":true,
                    "Consultants":true,
                    "GeneralContractors":true,
                    "Borrower":true,
                    "LoanOfficer":true,
                    "LoanProcessor":true,
                    "DrawAdministrator":true,
                },
                {
                    "IsHeading":false,
                    "ActionName":"Property: Edit any content",
                    "Descriptions":"",
                    "AnonymousUser":true,
                    "AuthenticatedUser":true,
                    "Administrator":true,
                    "Consultants":true,
                    "GeneralContractors":true,
                    "Borrower":true,
                    "LoanOfficer":true,
                    "LoanProcessor":true,
                    "DrawAdministrator":true,
                },
                {
                    "IsHeading":false,
                    "ActionName":"Property: Delete own content",
                    "Descriptions":"",
                    "AnonymousUser":true,
                    "AuthenticatedUser":true,
                    "Administrator":true,
                    "Consultants":true,
                    "GeneralContractors":true,
                    "Borrower":true,
                    "LoanOfficer":true,
                    "LoanProcessor":true,
                    "DrawAdministrator":true,
                },
                {
                    "IsHeading":false,
                    "ActionName":"Property: Delete any content",
                    "Descriptions":"",
                    "AnonymousUser":true,
                    "AuthenticatedUser":true,
                    "Administrator":true,
                    "Consultants":true,
                    "GeneralContractors":true,
                    "Borrower":true,
                    "LoanOfficer":true,
                    "LoanProcessor":true,
                    "DrawAdministrator":true,
                },
                {
                    "IsHeading":false,
                    "ActionName":"Realtor: Create new content",
                    "Descriptions":"",
                    "AnonymousUser":true,
                    "AuthenticatedUser":true,
                    "Administrator":true,
                    "Consultants":true,
                    "GeneralContractors":true,
                    "Borrower":true,
                    "LoanOfficer":true,
                    "LoanProcessor":true,
                    "DrawAdministrator":true,
                },
                {
                    "IsHeading":false,
                    "ActionName":"Realtor: Edit own content",
                    "Descriptions":"",
                    "AnonymousUser":true,
                    "AuthenticatedUser":true,
                    "Administrator":true,
                    "Consultants":true,
                    "GeneralContractors":true,
                    "Borrower":true,
                    "LoanOfficer":true,
                    "LoanProcessor":true,
                    "DrawAdministrator":true,
                },
                {
                    "IsHeading":false,
                    "ActionName":"Realtor: Edit any content",
                    "Descriptions":"",
                    "AnonymousUser":true,
                    "AuthenticatedUser":true,
                    "Administrator":true,
                    "Consultants":true,
                    "GeneralContractors":true,
                    "Borrower":true,
                    "LoanOfficer":true,
                    "LoanProcessor":true,
                    "DrawAdministrator":true,
                },
                {
                    "IsHeading":false,
                    "ActionName":"Realtor: Delete own content",
                    "Descriptions":"",
                    "AnonymousUser":true,
                    "AuthenticatedUser":true,
                    "Administrator":true,
                    "Consultants":true,
                    "GeneralContractors":true,
                    "Borrower":true,
                    "LoanOfficer":true,
                    "LoanProcessor":true,
                    "DrawAdministrator":true,
                },
                {
                    "IsHeading":false,
                    "ActionName":"Realtor: Delete any content",
                    "Descriptions":"",
                    "AnonymousUser":true,
                    "AuthenticatedUser":true,
                    "Administrator":true,
                    "Consultants":true,
                    "GeneralContractors":true,
                    "Borrower":true,
                    "LoanOfficer":true,
                    "LoanProcessor":true,
                    "DrawAdministrator":true,
                },
                {
                    "IsHeading":false,
                    "ActionName":"Borrower: Edit own profile",
                    "Descriptions":"",
                    "AnonymousUser":true,
                    "AuthenticatedUser":true,
                    "Administrator":true,
                    "Consultants":true,
                    "GeneralContractors":true,
                    "Borrower":true,
                    "LoanOfficer":true,
                    "LoanProcessor":true,
                    "DrawAdministrator":true,
                },
                {
                    "IsHeading":false,
                    "ActionName":"Borrower: Edit any profile",
                    "Descriptions":"",
                    "AnonymousUser":true,
                    "AuthenticatedUser":true,
                    "Administrator":true,
                    "Consultants":true,
                    "GeneralContractors":true,
                    "Borrower":true,
                    "LoanOfficer":true,
                    "LoanProcessor":true,
                    "DrawAdministrator":true,
                },
                {
                    "IsHeading":false,
                    "ActionName":"Borrower: View own profile",
                    "Descriptions":"",
                    "AnonymousUser":true,
                    "AuthenticatedUser":true,
                    "Administrator":true,
                    "Consultants":true,
                    "GeneralContractors":true,
                    "Borrower":true,
                    "LoanOfficer":true,
                    "LoanProcessor":true,
                    "DrawAdministrator":true,
                },
                {
                    "IsHeading":false,
                    "ActionName":"Borrower: View any profile",
                    "Descriptions":"",
                    "AnonymousUser":true,
                    "AuthenticatedUser":true,
                    "Administrator":true,
                    "Consultants":true,
                    "GeneralContractors":true,
                    "Borrower":true,
                    "LoanOfficer":true,
                    "LoanProcessor":true,
                    "DrawAdministrator":true,
                },
                {
                    "IsHeading":false,
                    "ActionName":"Consultants: Edit own profile",
                    "Descriptions":"",
                    "AnonymousUser":true,
                    "AuthenticatedUser":true,
                    "Administrator":true,
                    "Consultants":true,
                    "GeneralContractors":true,
                    "Borrower":true,
                    "LoanOfficer":true,
                    "LoanProcessor":true,
                    "DrawAdministrator":true,
                },
                {
                    "IsHeading":false,
                    "ActionName":"Consultants: Edit any profile",
                    "Descriptions":"",
                    "AnonymousUser":true,
                    "AuthenticatedUser":true,
                    "Administrator":true,
                    "Consultants":true,
                    "GeneralContractors":true,
                    "Borrower":true,
                    "LoanOfficer":true,
                    "LoanProcessor":true,
                    "DrawAdministrator":true,
                },
                {
                    "IsHeading":false,
                    "ActionName":"Consultants: View own profile",
                    "Descriptions":"",
                    "AnonymousUser":true,
                    "AuthenticatedUser":true,
                    "Administrator":true,
                    "Consultants":true,
                    "GeneralContractors":true,
                    "Borrower":true,
                    "LoanOfficer":true,
                    "LoanProcessor":true,
                    "DrawAdministrator":true,
                },
                {
                    "IsHeading":false,
                    "ActionName":"Consultants: View any profile",
                    "Descriptions":"",
                    "AnonymousUser":true,
                    "AuthenticatedUser":true,
                    "Administrator":true,
                    "Consultants":true,
                    "GeneralContractors":true,
                    "Borrower":true,
                    "LoanOfficer":true,
                    "LoanProcessor":true,
                    "DrawAdministrator":true,
                },
                {
                    "IsHeading":false,
                    "ActionName":"Administrator: Edit own profile",
                    "Descriptions":"",
                    "AnonymousUser":true,
                    "AuthenticatedUser":true,
                    "Administrator":true,
                    "Consultants":true,
                    "GeneralContractors":true,
                    "Borrower":true,
                    "LoanOfficer":true,
                    "LoanProcessor":true,
                    "DrawAdministrator":true,
                },
                {
                    "IsHeading":false,
                    "ActionName":"Administrator: Edit any profile",
                    "Descriptions":"",
                    "AnonymousUser":true,
                    "AuthenticatedUser":true,
                    "Administrator":true,
                    "Consultants":true,
                    "GeneralContractors":true,
                    "Borrower":true,
                    "LoanOfficer":true,
                    "LoanProcessor":true,
                    "DrawAdministrator":true,
                },
                {
                    "IsHeading":false,
                    "ActionName":"Administrator: View own profile",
                    "Descriptions":"",
                    "AnonymousUser":true,
                    "AuthenticatedUser":true,
                    "Administrator":true,
                    "Consultants":true,
                    "GeneralContractors":true,
                    "Borrower":true,
                    "LoanOfficer":true,
                    "LoanProcessor":true,
                    "DrawAdministrator":true,
                },
                {
                    "IsHeading":false,
                    "ActionName":"Administrator: View any profile",
                    "Descriptions":"",
                    "AnonymousUser":true,
                    "AuthenticatedUser":true,
                    "Administrator":true,
                    "Consultants":true,
                    "GeneralContractors":true,
                    "Borrower":true,
                    "LoanOfficer":true,
                    "LoanProcessor":true,
                    "DrawAdministrator":true,
                },
                {
                    "IsHeading":false,
                    "ActionName":"Loan Officer: Edit own profile",
                    "Descriptions":"",
                    "AnonymousUser":true,
                    "AuthenticatedUser":true,
                    "Administrator":true,
                    "Consultants":true,
                    "GeneralContractors":true,
                    "Borrower":true,
                    "LoanOfficer":true,
                    "LoanProcessor":true,
                    "DrawAdministrator":true,
                },
                {
                    "IsHeading":false,
                    "ActionName":"Loan Officer: Edit any profile",
                    "Descriptions":"",
                    "AnonymousUser":true,
                    "AuthenticatedUser":true,
                    "Administrator":true,
                    "Consultants":true,
                    "GeneralContractors":true,
                    "Borrower":true,
                    "LoanOfficer":true,
                    "LoanProcessor":true,
                    "DrawAdministrator":true,
                },
                {
                    "IsHeading":false,
                    "ActionName":"Loan Officer: View own profile",
                    "Descriptions":"",
                    "AnonymousUser":true,
                    "AuthenticatedUser":true,
                    "Administrator":true,
                    "Consultants":true,
                    "GeneralContractors":true,
                    "Borrower":true,
                    "LoanOfficer":true,
                    "LoanProcessor":true,
                    "DrawAdministrator":true,
                },
                {
                    "IsHeading":false,
                    "ActionName":"Loan Officer: View any profile",
                    "Descriptions":"",
                    "AnonymousUser":true,
                    "AuthenticatedUser":true,
                    "Administrator":true,
                    "Consultants":true,
                    "GeneralContractors":true,
                    "Borrower":true,
                    "LoanOfficer":true,
                    "LoanProcessor":true,
                    "DrawAdministrator":true,
                },
                {
                    "IsHeading":false,
                    "ActionName":"Loan Processor: Edit own profile",
                    "Descriptions":"",
                    "AnonymousUser":true,
                    "AuthenticatedUser":true,
                    "Administrator":true,
                    "Consultants":true,
                    "GeneralContractors":true,
                    "Borrower":true,
                    "LoanOfficer":true,
                    "LoanProcessor":true,
                    "DrawAdministrator":true,
                },
                {
                    "IsHeading":false,
                    "ActionName":"Loan Processor: Edit any profile",
                    "Descriptions":"",
                    "AnonymousUser":true,
                    "AuthenticatedUser":true,
                    "Administrator":true,
                    "Consultants":true,
                    "GeneralContractors":true,
                    "Borrower":true,
                    "LoanOfficer":true,
                    "LoanProcessor":true,
                    "DrawAdministrator":true,
                },
                {
                    "IsHeading":false,
                    "ActionName":"Loan Processor: View own profile",
                    "Descriptions":"",
                    "AnonymousUser":true,
                    "AuthenticatedUser":true,
                    "Administrator":true,
                    "Consultants":true,
                    "GeneralContractors":true,
                    "Borrower":true,
                    "LoanOfficer":true,
                    "LoanProcessor":true,
                    "DrawAdministrator":true,
                },
                {
                    "IsHeading":false,
                    "ActionName":"Loan Processor: View any profile",
                    "Descriptions":"",
                    "AnonymousUser":true,
                    "AuthenticatedUser":true,
                    "Administrator":true,
                    "Consultants":true,
                    "GeneralContractors":true,
                    "Borrower":true,
                    "LoanOfficer":true,
                    "LoanProcessor":true,
                    "DrawAdministrator":true,
                },
                {
                    "IsHeading":false,
                    "ActionName":"Draw Administrator: Edit own profile",
                    "Descriptions":"",
                    "AnonymousUser":true,
                    "AuthenticatedUser":true,
                    "Administrator":true,
                    "Consultants":true,
                    "GeneralContractors":true,
                    "Borrower":true,
                    "LoanOfficer":true,
                    "LoanProcessor":true,
                    "DrawAdministrator":true,
                },
                {
                    "IsHeading":false,
                    "ActionName":"Draw Administrator: Edit any profile",
                    "Descriptions":"",
                    "AnonymousUser":true,
                    "AuthenticatedUser":true,
                    "Administrator":true,
                    "Consultants":true,
                    "GeneralContractors":true,
                    "Borrower":true,
                    "LoanOfficer":true,
                    "LoanProcessor":true,
                    "DrawAdministrator":true,
                },
                {
                    "IsHeading":false,
                    "ActionName":"Draw Administrator: View own profile",
                    "Descriptions":"",
                    "AnonymousUser":true,
                    "AuthenticatedUser":true,
                    "Administrator":true,
                    "Consultants":true,
                    "GeneralContractors":true,
                    "Borrower":true,
                    "LoanOfficer":true,
                    "LoanProcessor":true,
                    "DrawAdministrator":true,
                },
                {
                    "IsHeading":false,
                    "ActionName":"Draw Administrator: View any profile",
                    "Descriptions":"",
                    "AnonymousUser":true,
                    "AuthenticatedUser":true,
                    "Administrator":true,
                    "Consultants":true,
                    "GeneralContractors":true,
                    "Borrower":true,
                    "LoanOfficer":true,
                    "LoanProcessor":true,
                    "DrawAdministrator":true,
                }
            ]
            };
          this.userList=setPermissionData;
           const users = Array.from({length: this.userList.Count}, (_, k) => createNewUser(k,this.userList));
      this.dataSource = new MatTableDataSource(users);
       this.dataSource.paginator = this.paginator;
       this.dataSource.sort = this.sort;
        this.loading=false;
          }
         
      },error=>{

      })
  }

  deleteUser(userid){
    
    if(confirm("Are you sure to delete loan officer")) {
      this._bservice.DeleteUser(userid).subscribe(response=>{
          this.sessionService.notifier.notify('success','Loan officer deleted successfully.');
        
          this.isList=true;
          this.isAdd=false;
          this.isEdit=false;
            this.formReset();
            this.GetUserList();
            this.loading=false;
      },error=>{
          this.sessionService.notifier.notify('error',error.error.message);
          this.loading=false;
      })
      window.scroll(0,0);
    }
  }


  EditUser(item){
     
    this.isEdit=true;
    this.isList=false;
      this.add_loanOfficer.setValue({
         
        email:item.EmailId,
        fname:item.FirstName,
        lname:item.LastName,
        phone_no:item.Phone==undefined ? null : item.Phone,
        statusType:item.Status,
         
        password:item.Password,
        confirmPassword:item.Password,
        id:item.Id,
       lender:item.Lender==undefined ? '' : item.Lender,
        country:item.Country==undefined ? '' : item.Country,
        city:item.City,
        state:item.State,
        zipcode:item.Zip,
        address2:item.Addresss2==undefined ? '' : item.Addresss2,
        address1:item.Address1,
        
   
      });
      
  }

  formReset(){
      this.add_loanOfficer.reset();
      
      this.add_loanOfficer.controls['statusType'].setValue('Active');
    }
}

/** Builds and returns a new User. */
function createNewUser(id: number,userList): UserData {
   
  const name =
      NAMES[Math.round(Math.random() * (NAMES.length - 1))] + ' ' +
      NAMES[Math.round(Math.random() * (NAMES.length - 1))].charAt(0) + '.';
if(userList.Items[id]!=undefined)
{
  userList.Items[id].id=id+1;
 return userList.Items[id];
}
 
}


