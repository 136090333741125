import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatTableModule } from "@angular/material/table";
import { MatTabsModule } from "@angular/material/tabs";
import { AuthGuardService as AuthGard } from "../service/auth/auth-guard.service";
import { TranslateModule } from "@ngx-translate/core";
import { NgxLoadingModule } from "ngx-loading";
import { FuseSharedModule } from "@fuse/shared.module";
import { ConstructionItemsLibraryComponent } from "./construction-items-library.component";

const routes = [
    {
        path: "construction-items-library",
        component: ConstructionItemsLibraryComponent,
        canActivate: [AuthGard],
    },
];

@NgModule({
    declarations: [ConstructionItemsLibraryComponent],
    imports: [
        RouterModule.forChild(routes),

        TranslateModule,
        NgxLoadingModule.forRoot({}),
        FuseSharedModule,
        MatButtonModule,
        MatCardModule,
        MatFormFieldModule,
        MatInputModule,
        MatTableModule,
        MatPaginatorModule,
        MatIconModule,
        MatRadioModule,
        MatTabsModule,
        MatSelectModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatExpansionModule,
    ],
    exports: [ConstructionItemsLibraryComponent],
})
export class ConstructionItemsLibraryModule {}
