import { Component, OnInit } from "@angular/core";
import { FormGroup, Validators, FormControl } from "@angular/forms";
import { Router } from "@angular/router";
import { LoginService } from "./login.service";
import { SessionService } from "../service/session.service";
import { FuseNavigationService } from "@fuse/components/navigation/navigation.service";

@Component({
    selector: "app-login",
    templateUrl: "./login.component.html",
    styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
    login_form = new FormGroup({
        username: new FormControl(null, [
            Validators.required,
            Validators.email,
            Validators.maxLength(40),
            Validators.minLength(5)
        ]),
        password: new FormControl(null, [Validators.required, Validators.maxLength(15),Validators.minLength(4)]),
    });
    forgot_form = new FormGroup({
        email: new FormControl(null, [Validators.required, Validators.email,Validators.maxLength(40),Validators.minLength(5)]),
    });
    isError = -1;
    message = "";
    loading = false;

    submitted: boolean = false;
    loginformshow = false;
    forgotformshow = false;

    constructor(
        private router: Router,
        private _loginService: LoginService,
        private sessionService: SessionService,
        private _fuseNavigationService: FuseNavigationService
    ) {}

    ngOnInit() {
        localStorage.clear();
        this.showLoginForm();
    }
    showLoginForm() {
        this.loginformshow = true;
        this.forgotformshow = false;
    }
    showForgotForm() {
        this.forgotformshow = true;
        this.loginformshow = false;
    }

    login() {
        let userData = {};
        if (this.login_form.invalid) {
            return;
        } else {
            this.loading = true;
            this.submitted = false;
            var result = this.login_form.value;
            userData = {
                EmailId: result.username,
                Password: result.password,
            };
            this._loginService.login(userData).subscribe(
                (data) => {
                    this.loading = false;
                    if (data.error) {
                    } else {
                        localStorage.setItem("userName", "temp");
                        this.sessionService.set("token", data.token);
                        this.sessionService.set(
                            "userInfo",
                            JSON.stringify(data.user)
                        );
                        this.message = "Login Successfully.";
                        this.isError = 0;
                        setTimeout(() => {
                            this.message = "";
                            this.isError = -1;
                        }, 1000);
                        this.sessionService.notifier.notify(
                            "success",
                            "Login Successfully"
                        );

                        if (
                            data.user.UserType == "Administrator" ||
                            data.user.UserType == "Consultants"
                        ) {
                            this.router.navigate(["/dashboard"]);
                            this._fuseNavigationService.updateNavigationItem(
                                "Users",
                                {
                                    hidden: false,
                                }
                            );
                            this._fuseNavigationService.updateNavigationItem(
                                "AllowableFees",
                                {
                                    hidden: false,
                                }
                            );
                            this._fuseNavigationService.updateNavigationItem(
                                "Settings",
                                {
                                    hidden: false,
                                }
                            );
                            this._fuseNavigationService.updateNavigationItem(
                                "ConstructionItemsLibrary",
                                {
                                    hidden: false,
                                }
                            );
                        } else {
                            this._fuseNavigationService.updateNavigationItem(
                                "Users",
                                {
                                    hidden: true,
                                }
                            );
                            this._fuseNavigationService.updateNavigationItem(
                                "Dashboard",
                                {
                                    hidden: true,
                                }
                            );
                            this._fuseNavigationService.updateNavigationItem(
                                "AllowableFees",
                                {
                                    hidden: true,
                                }
                            );
                            this._fuseNavigationService.updateNavigationItem(
                                "Settings",
                                {
                                    hidden: true,
                                }
                            );
                            this._fuseNavigationService.updateNavigationItem(
                                "ConstructionItemsLibrary",
                                {
                                    hidden: true,
                                }
                            );

                            this.router.navigate(["/properties"]);
                        }
                    }
                },
                (error) => {
                    this.loading = false;
                    this.sessionService.notifier.notify(
                        "error",
                        "Invalid Credentials"
                    );
                    this.message = error.error.message;

                    this.isError = 1;
                    setTimeout(() => {
                        this.message = "";
                        this.isError = -1;
                    }, 1000);
                }
            );
        }
    }

    forgotpassword() {
        let userData = {};
        if (this.forgot_form.invalid) {
            return;
        } else {
            this.loading = true;
            var result = this.forgot_form.value;
            userData = {
                EmailId: result.email,
            };

            this._loginService.forgotpassword(userData).subscribe(
                (data) => {
                    this.loading = false;
                    if (data.error) {
                        this.sessionService.notifier.notify(
                            "error",
                            data.error
                        );
                    } else {
                        this.sessionService.notifier.notify(
                            "success",
                            "Mail has been sent successfully. Please check your email inbox."
                        );
                    }
                },
                (error) => {
                    this.loading = false;
                    this.sessionService.notifier.notify(
                        "error",
                        "Email does not exist."
                    );
                }
            );
        }
    }
}
