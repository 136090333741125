<div class="content p-24 tbl">
    <ngx-loading  class="ngxLoader" 
        [show]="loading"
        [config]="{ backdropBorderRadius: '3px' }"
    ></ngx-loading>
    <h1 class="m-0" *ngIf="propertyDetailsShow" style="float: left">
        {{
            propertyDetails?.InspectionAddress?.address1
                ? propertyDetails?.InspectionAddress?.address1 + ", "
                : ""
        }}{{
            propertyDetails?.InspectionAddress?.address2
                ? propertyDetails?.InspectionAddress?.address2 + ", "
                : ""
        }}{{
            propertyDetails?.InspectionAddress?.city
                ? propertyDetails?.InspectionAddress?.city + ", "
                : ""
        }}
        {{
            propertyDetails?.InspectionAddress?.state
                ? propertyDetails?.InspectionAddress?.state + ", "
                : ""
        }}
        {{ propertyDetails?.InspectionAddress?.zipcode }}
    </h1>
    <br />
    <!--<a *ngIf="isList" class="addBorrow" href="javascript:void(0)" (click)="isList=false;isAdd=true">
    <mat-icon _ngcontent-c102="" class="secondary-text s-22 mat-icon material-icons mat-icon-no-color" role="img" aria-hidden="true">add_circle</mat-icon> Edit Property</a>
    <a *ngIf="isAdd" class="addBorrow" href="javascript:void(0)" (click)="isAdd=false;isList=true"><mat-icon _ngcontent-c102="" class="secondary-text s-22 mat-icon material-icons mat-icon-no-color" role="img" aria-hidden="true">view_list</mat-icon> List Property</a> -->
    <a
        class="getDocuSignDocsBtn"
        (click)="reloadPage()"
        *ngIf="hideBackPropBtn != true && userDetail.UserType != 'Borrower'"
        href="javascript:void(0)"
    >
        Refresh Signed Documents</a
    >
    <a
        class="addBorrow"
        *ngIf="hideBackPropBtn != true && userDetail.UserType != 'Borrower'"
        href="javascript:void(0)"
        routerLink="/properties"
    >
        Back to Property</a
    >
    <a
        class="addBorrow"
        *ngIf="userDetail.UserType != 'Borrower'"
        href="javascript:void(0)"
        routerLink="/edit-property/{{ propertyDetails.Id }}"
    >
        Edit Property</a
    >
    <!-- <a class="addBorrow" href="javascript:void(0)" (click)="editProperty()"> Edit Property</a> -->

    <mat-tab-group
        *ngIf="userDetail.UserType != 'Borrower'"
        mat-align-tabs="start"
        [(selectedIndex)]="selectedIndex"
        style="clear: both"
        class="custome-detail"
    >
        <mat-tab *ngIf="userDetail.UserType != 'Borrower'">
            <ng-template mat-tab-label>
                <div (click)="getSignedEnvelopes(propertyDetails.Id)">
                    Property Details
                </div>
            </ng-template>
            <br />

            <mat-card>
                <table class="table-list-bx">
                    <thead>
                        <th align="left">Property Inspection Address</th>
                    </thead>
                    <tbody>
                        <tr>
                            <td align="left" *ngIf="propertyDetailsShow">
                                {{
                                    propertyDetails?.InspectionAddress?.address1
                                        ? propertyDetails?.InspectionAddress
                                              ?.address1 + ", "
                                        : ""
                                }}
                                {{
                                    propertyDetails?.InspectionAddress?.address2
                                        ? propertyDetails?.InspectionAddress
                                              ?.address2 + ", "
                                        : ""
                                }}
                                {{
                                    propertyDetails?.InspectionAddress?.city
                                        ? propertyDetails?.InspectionAddress
                                              ?.city + ", "
                                        : ""
                                }}
                                {{
                                    propertyDetails?.InspectionAddress?.state
                                        ? propertyDetails?.InspectionAddress
                                              ?.state + ", "
                                        : ""
                                }}
                                {{
                                    propertyDetails?.InspectionAddress?.zipcode
                                }}
                            </td>
                        </tr>
                    </tbody>
                </table>

                <table class="table-list-bx">
                    <thead>
                        <th align="left">Loan Type</th>
                    </thead>
                    <tbody>
                        <tr>
                            <td align="left">{{ propertyDetails.LoanType }}</td>
                        </tr>
                    </tbody>
                </table>
                <table class="table-list-bx">
                    <thead>
                        <th align="left">FHA Case No</th>
                    </thead>
                    <tbody>
                        <tr>
                            <td align="left">
                                {{ propertyDetails.FHACaseNo }}
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table *ngIf="LoanNumber" class="table-list-bx">
                    <thead>
                        <th align="left">Loan No</th>
                    </thead>
                    <tbody>
                        <tr>
                            <td align="left">{{ LoanNumber }}</td>
                        </tr>
                    </tbody>
                </table>
                <table>
                    <thead>
                        <th align="left">Transaction Type</th>
                    </thead>
                    <tbody>
                        <tr>
                            <td align="left">
                                {{ propertyDetails.TransactionType }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </mat-card>
            <br />

            <mat-card>
                <div
                    *ngIf="borrowerDetails.Id"
                    class="borrowerDetailsBox DetailsBox"
                >
                    <h3 class="mt-0">
                        <strong>Borrower</strong>
                    </h3>
                    <div class="big-table">
                        <table class="grey-head-table">
                            <thead>
                                <th class="border-bottom" align="left">Name</th>
                                <th class="border-bottom" align="left">
                                    Email
                                </th>
                                <th class="border-bottom" align="left">
                                    Phone
                                </th>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        {{ borrowerDetails.FirstName }}
                                        {{ borrowerDetails.LastName }}
                                    </td>
                                    <td>{{ borrowerDetails.EmailId }}</td>
                                    <td>{{ borrowerDetails.Phone }}</td>
                                </tr>
                                <tr
                                    *ngFor="
                                        let Details of propertyDetails.Borrowers
                                    "
                                >
                                    <td>
                                        {{ Details.FirstName }}
                                        {{ Details.LastName }}
                                    </td>
                                    <td>{{ Details.Email }}</td>
                                    <td>--</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div
                    *ngIf="consultantDetails.Id"
                    class="consultantDetailsBox DetailsBox"
                >
                    <h3>
                        <strong>Consultant</strong>
                    </h3>
                    <div class="big-table">
                        <table class="grey-head-table">
                            <thead>
                                <th class="border-bottom" align="left">Name</th>
                                <th class="border-bottom" align="left">
                                    Email
                                </th>
                                <th class="border-bottom" align="left">
                                    Phone
                                </th>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        {{ consultantDetails.FirstName }}
                                        {{ consultantDetails.LastName }}
                                    </td>
                                    <td>{{ consultantDetails.EmailId }}</td>
                                    <td>{{ consultantDetails.Phone }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div
                    *ngIf="GCDetailsList.length > 0"
                    class="contratorDetailsBox DetailsBox"
                >
                    <h3 class="mt-0">
                        <strong>General Contractors</strong>
                    </h3>
                    <div class="big-table">
                        <table class="grey-head-table">
                            <thead>
                                <th class="border-bottom" align="left">Name</th>
                                <th class="border-bottom" align="left">
                                    Email
                                </th>
                                <th class="border-bottom" align="left">
                                    Phone
                                </th>
                            </thead>
                            <tbody>
                                <tr *ngFor="let gcDetails of GCDetailsList">
                                    <td>
                                        {{ gcDetails.FirstName }}
                                        {{ gcDetails.LastName }}
                                    </td>
                                    <td>{{ gcDetails.EmailId }}</td>
                                    <td>{{ gcDetails.Phone }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div *ngIf="LODetails.Id" class="loDetailsBox DetailsBox">
                    <h3>
                        <strong>Loan Officer</strong>
                    </h3>
                    <div class="big-table">
                        <table class="grey-head-table">
                            <thead>
                                <th class="border-bottom" align="left">Name</th>
                                <th class="border-bottom" align="left">
                                    Email
                                </th>
                                <th class="border-bottom" align="left">
                                    Phone
                                </th>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        {{ LODetails.FirstName }}
                                        {{ LODetails.LastName }}
                                    </td>
                                    <td>{{ LODetails.EmailId }}</td>
                                    <td>{{ LODetails.Phone }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div
                    *ngIf="LoanProcessorDetails.length > 0"
                    class="lpDetailsBox DetailsBox"
                >
                    <h3>
                        <strong>Loan Processor</strong>
                    </h3>
                    <div class="big-table">
                        <table class="grey-head-table">
                            <thead>
                                <th class="border-bottom">Name</th>
                                <th class="border-bottom">Email</th>
                                <th class="border-bottom">Phone</th>
                            </thead>
                            <tbody>
                                <tr
                                    *ngFor="
                                        let LoanProcessorDetail of LoanProcessorDetails;
                                        detailsBox
                                    "
                                >
                                    <td>
                                        {{ LoanProcessorDetail.FirstName }}
                                        {{ LoanProcessorDetail.LastName }}
                                    </td>
                                    <td>{{ LoanProcessorDetail.EmailId }}</td>
                                    <td>{{ LoanProcessorDetail.Phone }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div *ngIf="DADetails.Id" class="daDetailsBox DetailsBox">
                    <h3>
                        <strong>Loan Processor</strong>
                    </h3>
                    <div class="big-table">
                        <table class="grey-head-table">
                            <thead>
                                <th class="border-bottom">Name</th>
                                <th class="border-bottom">Email</th>
                                <th class="border-bottom">Phone</th>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        {{ DADetails.FirstName }}
                                        {{ DADetails.LastName }}
                                    </td>
                                    <td>{{ DADetails.EmailId }}</td>
                                    <td>{{ DADetails.Phone }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div
                    *ngIf="RealtorDetails.Id"
                    class="RealtorDetailsBox DetailsBox"
                >
                    <h3>
                        <strong>Realtor</strong>
                    </h3>
                    <div class="big-table">
                        <table class="grey-head-table">
                            <thead>
                                <th class="border-bottom">Name</th>
                                <th class="border-bottom">Email</th>
                                <th class="border-bottom">Phone</th>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        {{ RealtorDetails.FirstName }}
                                        {{ RealtorDetails.LastName }}
                                    </td>
                                    <td>{{ RealtorDetails.EmailId }}</td>
                                    <td>{{ RealtorDetails.Phone }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div
                    *ngIf="LenderDetails.Id"
                    class="LenderDetailsBox DetailsBox"
                >
                    <h3>
                        <strong>Lender</strong>
                    </h3>
                    <div class="big-table">
                        <table class="grey-head-table mb-0">
                            <thead>
                                <th class="border-bottom">Name</th>
                                <!-- <th class="border-bottom">Email</th> -->
                                <th class="border-bottom">Phone</th>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{{ LenderDetails.Name }}</td>
                                    <!-- <td >{{LenderDetails.EmailId}}</td> -->
                                    <td>{{ LenderDetails.Phone }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </mat-card>
        </mat-tab>

        <mat-tab
            *ngIf="
                userDetail.UserType != 'Borrower' &&
                userDetail.UserType != 'Loan Processor' &&
                userDetail.UserType != 'Draw Administrator' &&
                userDetail.UserType != 'Loan Officer'
            "
        >
            <ng-template mat-tab-label>
                <div (click)="getSignedEnvelopes(propertyDetails.Id)">
                    Pre-Closing
                </div>
            </ng-template>

            <div class="profileDetailsSidebar white-50">
                <div class="side-list">
                    <h2 class="uploadFormLabel">Property Photos</h2>
                    <a routerLink="/property-gallery/{{ propertyDetails.Id }}"
                        >Add Property Photos / Edit Album</a
                    >
                    <ul>
                        <li>
                            <div class="ngcontainer">
                                <span
                                    *ngIf="
                                        !isArray(propertyDetails.GoogleAlbum) ||
                                        propertyDetails.GoogleAlbum == null ||
                                        propertyDetails.GoogleAlbum.length == 0
                                    "
                                    ><br />No Album Available</span
                                >
                                <div
                                    *ngFor="
                                        let Album of propertyDetails.GoogleAlbum;
                                        let i = index
                                    "
                                >
                                    <div
                                        *ngIf="
                                            Album.AlbumType == 'propertyPhotos'
                                        "
                                    >
                                        <strong
                                            >{{ Album.AlbumTitle }} :
                                        </strong>
                                        <span>
                                            <a
                                                *ngIf="
                                                    propertyDetails.GoogleAlbum !=
                                                    null
                                                "
                                                href="{{ Album.GoogleAlbum }}"
                                                target="_blank"
                                                >View</a
                                            >
                                        </span>
                                        |
                                        <span>
                                            <a
                                                routerLink="/property-gallery/{{
                                                    propertyDetails.Id
                                                }}/0/{{ i }}"
                                                >Edit</a
                                            >
                                        </span>
                                        |
                                        <span>
                                            <a
                                                href="javascript:void(0)"
                                                (click)="
                                                    removeAlbum(
                                                        propertyDetails.Id,
                                                        i
                                                    )
                                                "
                                                >Delete</a
                                            >
                                        </span>
                                        <br />
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="side-list">
                    <h2>Consultant Generated Forms</h2>
                    <ul>
                        <li>
                            <div class="ngcontainer">
                                <a
                                    *ngIf="
                                        propertyDetails.AcceptedProposal !=
                                            '' &&
                                        propertyDetails.AcceptedProposal !=
                                            null &&
                                        propertyDetails.AcceptedProposal !=
                                            undefined
                                    "
                                    href="javascript:void(0);"
                                    (click)="restartEstimateProcess()"
                                    >Restart Estimate Process</a
                                >
                                <h4 class="type-title">
                                    Draft Specifications of Repairs
                                </h4>
                                <div class="links-to-pdf-and-edit">
                                    <span>
                                        <a
                                            href="javascript:void(0);"
                                            (click)="GetSORPDF()"
                                            >Print PDF</a
                                        >
                                    </span>
                                    <br />
                                    <span>
                                        <a
                                            *ngIf="sorDetails.MailSend != true"
                                            href="javascript:void(0);"
                                            routerLink="/construction-detail/{{
                                                propertyDetails.Id
                                            }}"
                                            >Contruction Details (Add/Edit)</a
                                        >
                                        <a
                                            *ngIf="sorDetails.MailSend == true"
                                            href="javascript:void(0);"
                                            (click)="editDraftSOR()"
                                            >Edit Draft SOR</a
                                        >
                                    </span>
                                    <br />
                                    <span>
                                        <a
                                            *ngIf="sorDetails.MailSend != true"
                                            href="javascript:void(0);"
                                            (click)="
                                                sorEmailToBorrower(
                                                    propertyDetails.Id,
                                                    borrowerDetails.EmailId,
                                                    borrowerDetails.FirstName,
                                                    borrowerDetails.LastName
                                                )
                                            "
                                            data="19890"
                                            class="agrmnt-signed-by-bo"
                                            >Send to Borrower</a
                                        >
                                        <a
                                            *ngIf="sorDetails.MailSend == true"
                                            href="javascript:void(0);"
                                            (click)="
                                                sorResendEmailToBorrower(
                                                    propertyDetails.Id,
                                                    borrowerDetails.EmailId,
                                                    borrowerDetails.FirstName,
                                                    borrowerDetails.LastName
                                                )
                                            "
                                            data="19890"
                                            class="agrmnt-signed-by-bo"
                                            >Resend to Borrower</a
                                        >
                                    </span>
                                    <br />
                                    <span *ngIf="sorDetails.MailSend == true"
                                        >{{
                                            sorDetails.MailSendDate
                                                | date : "M/d/y h:mm"
                                        }}
                                        - DRAFT SOR Sent to Borrower</span
                                    >
                                    <span
                                        *ngIf="
                                            propertyDetails.Status == 'Revise'
                                        "
                                        ><br />{{
                                            sorDetails.ApprovedDate
                                                | date : "M/d/y h:mm"
                                        }}
                                        - DRAFT SOR Revised By "{{
                                            borrowerDetails.FirstName
                                        }}
                                        {{ borrowerDetails.LastName }}"</span
                                    >
                                    <span *ngIf="sorDetails.Approved == true"
                                        ><br />{{
                                            sorDetails.ApprovedDate
                                                | date : "M/d/y h:mm"
                                        }}
                                        - DRAFT SOR Approved By "{{
                                            borrowerDetails.FirstName
                                        }}
                                        {{ borrowerDetails.LastName }}"
                                    </span>
                                </div>
                            </div>
                        </li>
                        <li
                            *ngIf="
                                propertyDetails.Status ==
                                    'Pre Closing Completed' ||
                                propertyDetails.Status ==
                                    'Final Draw Completed' ||
                                propertyDetails.Status ==
                                    'Draw request started' ||
                                propertyDetails.Status == 'Post closing started'
                            "
                        >
                            <div class="ngcontainer">
                                <h4 class="type-title">
                                    Final Specifications of Repairs
                                </h4>
                                <div
                                    class="links-to-pdf-and-edit"
                                    style="margin: 0px 0 5px 0"
                                >
                                    <a
                                        href="javascript:void(0);"
                                        class="agrmnt-signed-by-bo"
                                        (click)="
                                            editFinalSOR(
                                                propertyDetails.Id,
                                                propertyDetails.AcceptedProposal
                                            )
                                        "
                                        >Edit</a
                                    >
                                    |
                                    <a
                                        href="javascript:void(0);"
                                        (click)="
                                            printFinalSOR(propertyDetails.Id)
                                        "
                                        data="19890"
                                        class="agrmnt-signed-by-bo"
                                        >Print PDF</a
                                    >
                                    <a
                                        href="javascript:void(0);"
                                        *ngIf="
                                            sorDetails.FinalMailSendDate == ''
                                        "
                                        (click)="
                                            generateFinalSOR(propertyDetails.Id)
                                        "
                                        data="19890"
                                        class="agrmnt-signed-by-bo"
                                        ><br />Send Final SOR Package to BO and
                                        CO for Digital Signature</a
                                    >
                                    <a
                                        href="javascript:void(0);"
                                        *ngIf="
                                            sorDetails.FinalMailSendDate !=
                                                '' &&
                                            sorDetails.FinalMailSendDate != null
                                        "
                                        (click)="
                                            generateFinalSOR(propertyDetails.Id)
                                        "
                                        data="19890"
                                        class="agrmnt-signed-by-bo"
                                        ><br />Resend Final SOR Package to BO
                                        and CO for Digital Signature</a
                                    >
                                    <span
                                        *ngIf="
                                            sorDetails.FinalMailSendDate !=
                                                '' &&
                                            sorDetails.FinalMailSendDate != null
                                        "
                                        ><br />{{
                                            sorDetails.FinalMailSendDate
                                                | date : "M/d/y h:mm"
                                        }}
                                        - Final SOR Submitted</span
                                    >

                                    <br />
                                    <ng-container
                                        *ngFor="
                                            let envelopeItem of signedEnvelopesList;
                                            let i = index
                                        "
                                    >
                                        <div
                                            class="signedDocList"
                                            *ngIf="
                                                envelopeItem.EnvelopeType ==
                                                    'final_package' &&
                                                envelopeItem.DocumentName ==
                                                    'Specification of Repair' &&
                                                envelopeItem.UploadedStatus == 1
                                            "
                                        >
                                            <a
                                                href="javascript:void(0)"
                                                class="RemoveSignedLink"
                                                *ngIf="
                                                    envelopeItem.EnvelopeType ==
                                                        'final_package' &&
                                                    envelopeItem.UploadedStatus ==
                                                        1
                                                "
                                                (click)="
                                                    deleteSignedDoc(
                                                        envelopeItem.DocumentName,
                                                        propertyDetails.Id
                                                    )
                                                "
                                                >X</a
                                            >
                                            <a
                                                href="javascript:void(0)"
                                                class="signedLink"
                                                *ngIf="
                                                    envelopeItem.EnvelopeType ==
                                                        'final_package' &&
                                                    envelopeItem.DocumentName ==
                                                        'Specification of Repair' &&
                                                    envelopeItem.UploadedStatus ==
                                                        1
                                                "
                                                (click)="
                                                    generateCompletedSignedEnvelope(
                                                        envelopeItem,
                                                        true
                                                    )
                                                "
                                                >Signed Final
                                                {{
                                                    envelopeItem.DocumentName
                                                }}.pdf</a
                                            >
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>

                <div class="side-list">
                    <h2>Contractor Estimates</h2>
                    <ng-container>
                        <p>
                            Estimate Status Table below will show when estimates
                            have been submitted and if they have been reviewed
                            by the borrower. If estimate have been submitted but
                            not reviewed, please contact the borrower for
                            status.
                        </p>
                        <p>
                            <strong>Not Reviewed</strong> - Borrower has not
                            reviewed estimate
                        </p>
                        <p>
                            <strong>Accepted</strong> - Borrower has accepted
                            the estimate which will be used for the Final
                            Specification of Repair Report
                        </p>
                    </ng-container>

                    <ul>
                        <li>
                            <p style="clear: both">
                                <a
                                    *ngIf="submittedBid > 0"
                                    routerLink="/gc-submitted-bids/{{
                                        propertyDetails.Id
                                    }}"
                                    >View Contractor Submitted Bids</a
                                >
                                <a
                                    *ngIf="
                                        propertyDetails.AcceptedProposal !=
                                            null &&
                                        propertyDetails.AcceptedProposal != ''
                                    "
                                    (click)="
                                        getBorPDF(
                                            propertyDetails.Id,
                                            propertyDetails.AcceptedProposal
                                        )
                                    "
                                    ><br />View Contractor Accepted Bid</a
                                >
                                <a
                                    *ngIf="submittedBid == 0"
                                    href="javascript:void(0)"
                                    ><br />No Contractor Submited Bids</a
                                >
                                <br />
                                <ng-container
                                    *ngIf="
                                        propertyDetails.GeneralContractorId ==
                                            null &&
                                        propertyDetails.InvitedGeneralContractors ==
                                            null &&
                                        propertyDetails?.SubmittedBids
                                            ?.length == 0 &&
                                        submittedBid == 0
                                    "
                                >
                                    <ng-container *ngIf="loadingGC != true">
                                        <table
                                            class="gcListReviewTableNew"
                                            mat-table
                                            [dataSource]="dataSource"
                                            matSort
                                        >
                                            <ng-container
                                                matColumnDef="FirstName"
                                            >
                                                <th
                                                    mat-header-cell
                                                    *matHeaderCellDef
                                                    mat-sort-header
                                                >
                                                    Contractor Name
                                                </th>
                                            </ng-container>
                                            <ng-container
                                                matColumnDef="submissionStatus"
                                            >
                                                <th
                                                    mat-header-cell
                                                    *matHeaderCellDef
                                                    mat-sort-header
                                                >
                                                    Submission Status
                                                </th>
                                            </ng-container>
                                            <ng-container
                                                matColumnDef="boReviewStatus"
                                            >
                                                <th
                                                    mat-header-cell
                                                    *matHeaderCellDef
                                                    mat-sort-header
                                                >
                                                    BO Review Status
                                                </th>
                                            </ng-container>
                                            <ng-container
                                                matColumnDef="coReviewStatus"
                                            >
                                                <th
                                                    mat-header-cell
                                                    *matHeaderCellDef
                                                    mat-sort-header
                                                >
                                                    CO Review Status
                                                </th>
                                            </ng-container>
                                            <ng-container matColumnDef="nodata">
                                                <td
                                                    mat-footer-row
                                                    *matFooterCellDef
                                                    [colSpan]="
                                                        displayedColumns.length
                                                    "
                                                    style="text-align: center"
                                                >
                                                    No Contractors Listed
                                                </td>
                                            </ng-container>

                                            <tr
                                                mat-header-row
                                                *matHeaderRowDef="
                                                    displayedColumns3
                                                "
                                            ></tr>
                                            <tr
                                                mat-row
                                                *matRowDef="
                                                    let row;
                                                    columns: displayedColumns3
                                                "
                                                style="cursor: pointer"
                                            ></tr>

                                            <tr
                                                mat-footer-row
                                                *matFooterRowDef="['nodata']"
                                            ></tr>
                                        </table>
                                        <br />
                                    </ng-container>
                                    <div class="gcTableListLoadingSectionNew">
                                        <ngx-loading  class="ngxLoader" 
                                            [show]="loadingGC"
                                            [config]="{
                                                backdropBorderRadius: '3px'
                                            }"
                                        ></ngx-loading>
                                    </div>
                                </ng-container>
                                <ng-container
                                    *ngIf="
                                        (propertyDetails.GeneralContractorId !=
                                            null ||
                                            propertyDetails.InvitedGeneralContractors !=
                                                null) &&
                                        submittedBid == 0
                                    "
                                >
                                    <ng-container *ngIf="loadingGC != true">
                                        <table
                                            class="gcListReviewTableNew"
                                            mat-table
                                            [dataSource]="dataSource"
                                            matSort
                                        >
                                            <ng-container
                                                matColumnDef="FirstName"
                                            >
                                                <th
                                                    mat-header-cell
                                                    *matHeaderCellDef
                                                    mat-sort-header
                                                >
                                                    Contractor Name
                                                </th>
                                                <td
                                                    mat-cell
                                                    *matCellDef="let row"
                                                >
                                                    {{ row.FirstName }}
                                                    {{ row.LastName }}
                                                </td>
                                            </ng-container>

                                            <ng-container
                                                matColumnDef="submissionStatus"
                                            >
                                                <th
                                                    mat-header-cell
                                                    *matHeaderCellDef
                                                    mat-sort-header
                                                >
                                                    Submission Status
                                                </th>
                                                <td
                                                    mat-cell
                                                    *matCellDef="let row"
                                                >
                                                    Not Submitted
                                                </td>
                                            </ng-container>

                                            <ng-container
                                                matColumnDef="boReviewStatus"
                                            >
                                                <th
                                                    mat-header-cell
                                                    *matHeaderCellDef
                                                    mat-sort-header
                                                >
                                                    BO Review Status
                                                </th>
                                                <td
                                                    mat-cell
                                                    *matCellDef="let row"
                                                >
                                                    Not Reviewed
                                                </td>
                                            </ng-container>
                                            <ng-container
                                                matColumnDef="coReviewStatus"
                                            >
                                                <th
                                                    mat-header-cell
                                                    *matHeaderCellDef
                                                    mat-sort-header
                                                >
                                                    CO Review Status
                                                </th>
                                                <td
                                                    mat-cell
                                                    *matCellDef="let row"
                                                >
                                                    Not Reviewed
                                                </td>
                                            </ng-container>

                                            <tr
                                                mat-header-row
                                                *matHeaderRowDef="
                                                    displayedColumns3
                                                "
                                            ></tr>
                                            <tr
                                                mat-row
                                                *matRowDef="
                                                    let row;
                                                    columns: displayedColumns3
                                                "
                                                style="cursor: pointer"
                                            ></tr>
                                        </table>
                                        <br />
                                    </ng-container>
                                    <div class="gcTableListLoadingSectionNew">
                                        <ngx-loading class="ngxLoader" 
                                            [show]="loadingGC"
                                            [config]="{
                                                backdropBorderRadius: '3px'
                                            }"
                                        ></ngx-loading>
                                    </div>
                                </ng-container>
                                <ng-container
                                    *ngIf="
                                        (propertyDetails.GeneralContractorId !=
                                            null ||
                                            propertyDetails.InvitedGeneralContractors !=
                                                null) &&
                                        submittedBid != 0
                                    "
                                >
                                    <ng-container *ngIf="loadingGC != true">
                                        <table
                                            class="gcListReviewTableNew"
                                            mat-table
                                            [dataSource]="dataSource"
                                            matSort
                                        >
                                            <ng-container
                                                matColumnDef="FirstName"
                                            >
                                                <th
                                                    mat-header-cell
                                                    *matHeaderCellDef
                                                    mat-sort-header
                                                >
                                                    Contractor Name
                                                </th>
                                                <td
                                                    mat-cell
                                                    *matCellDef="let row"
                                                >
                                                    {{ row.FirstName }}
                                                    {{ row.LastName }}
                                                </td>
                                            </ng-container>

                                            <ng-container
                                                matColumnDef="submissionStatus"
                                            >
                                                <th
                                                    mat-header-cell
                                                    *matHeaderCellDef
                                                    mat-sort-header
                                                >
                                                    Estimate Status
                                                </th>
                                                <td
                                                    mat-cell
                                                    *matCellDef="let row"
                                                >
                                                    {{ row.submissionStatus }}
                                                </td>
                                            </ng-container>

                                            <ng-container
                                                matColumnDef="boReviewStatus"
                                            >
                                                <th
                                                    mat-header-cell
                                                    *matHeaderCellDef
                                                    mat-sort-header
                                                >
                                                    BO Review Status
                                                </th>
                                                <td
                                                    mat-cell
                                                    *matCellDef="let row"
                                                >
                                                    {{ row.boReviewStatus }}
                                                </td>
                                            </ng-container>
                                            <ng-container
                                                matColumnDef="coReviewStatus"
                                            >
                                                <th
                                                    mat-header-cell
                                                    *matHeaderCellDef
                                                    mat-sort-header
                                                >
                                                    CO Review Status
                                                </th>
                                                <td
                                                    mat-cell
                                                    *matCellDef="let row"
                                                >
                                                    {{ row.coReviewStatus }}
                                                </td>
                                            </ng-container>
                                            <tr
                                                mat-header-row
                                                *matHeaderRowDef="
                                                    displayedColumns3
                                                "
                                            ></tr>
                                            <tr
                                                mat-row
                                                *matRowDef="
                                                    let row;
                                                    columns: displayedColumns3
                                                "
                                                style="cursor: pointer"
                                            ></tr>
                                        </table>
                                        <br />
                                    </ng-container>
                                    <div class="gcTableListLoadingSectionNew">
                                        <ngx-loading class="ngxLoader" 
                                            [show]="loadingGC"
                                            [config]="{
                                                backdropBorderRadius: '3px'
                                            }"
                                        ></ngx-loading>
                                    </div>
                                </ng-container>

                                <ng-container
                                    *ngFor="
                                        let envelopeItem of signedEnvelopesList;
                                        let i = index
                                    "
                                >
                                    <div
                                        class="signedDocList"
                                        *ngIf="
                                            envelopeItem.EnvelopeType ==
                                                'bid_on_repair' &&
                                            envelopeItem.UploadedStatus == 1
                                        "
                                    >
                                        <a
                                            href="javascript:void(0)"
                                            class="RemoveSignedLink"
                                            *ngIf="
                                                envelopeItem.EnvelopeType ==
                                                    'bid_on_repair' &&
                                                envelopeItem.UploadedStatus == 1
                                            "
                                            (click)="
                                                deleteSignedDoc(
                                                    envelopeItem.DocumentName,
                                                    propertyDetails.Id
                                                )
                                            "
                                            >X</a
                                        >
                                        <a
                                            href="javascript:void(0)"
                                            class="signedLink"
                                            *ngIf="
                                                envelopeItem.EnvelopeType ==
                                                    'bid_on_repair' &&
                                                envelopeItem.UploadedStatus ==
                                                    1 &&
                                                propertyDetails.AcceptedProposal !=
                                                    '' &&
                                                propertyDetails.AcceptedProposal !=
                                                    null &&
                                                propertyDetails.AcceptedProposal !=
                                                    undefined
                                            "
                                            (click)="
                                                generateCompletedSignedEnvelope(
                                                    envelopeItem,
                                                    true
                                                )
                                            "
                                            >Signed
                                            {{
                                                envelopeItem.DocumentName
                                            }}.pdf</a
                                        >
                                    </div>
                                </ng-container>
                            </p>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="profileDetailsSidebar">
                <div class="side-list">
                    <h2>Documents for Signature</h2>
                    <ul>
                        <li>
                            <div class="ngcontainer">
                                <h4 class="type-title">Initial Package</h4>
                                <h5
                                    class="type-title"
                                    style="font-size: 16px; margin: 0px 0 5px 0"
                                >
                                    Non-Home Inspection Disclosure
                                </h5>
                                <div
                                    class="links-to-pdf-and-edit"
                                    style="margin: 0px 0 5px 0"
                                >
                                    <span>
                                        <a
                                            href="javascript:void(0);"
                                            (click)="downloadPDFNHID()"
                                            >Print PDF</a
                                        >
                                    </span>
                                    |
                                    <span>
                                        <a
                                            href="javascript:void(0);"
                                            (click)="sendNonHomeToBO()"
                                            >Send to BO</a
                                        >
                                    </span>
                                </div>

                                <h5
                                    class="type-title"
                                    style="font-size: 16px; margin: 0px 0 5px 0"
                                >
                                    Consultant Agreement
                                </h5>
                                <div
                                    class="links-to-pdf-and-edit"
                                    style="margin: 0px 0 5px 0"
                                >
                                    <span>
                                        <a
                                            href="javascript:void(0);"
                                            (click)="downloadPDFCA()"
                                            >Print PDF</a
                                        >
                                    </span>
                                    |
                                    <span>
                                        <a
                                            routerLink="/fha-consultant-agreement/{{
                                                propertyDetails.Id
                                            }}"
                                            >Edit</a
                                        >
                                    </span>
                                </div>
                                <h5
                                    class="type-title"
                                    style="font-size: 16px; margin: 0px 0 5px 0"
                                >
                                    Consultant Liability
                                </h5>
                                <div
                                    class="links-to-pdf-and-edit"
                                    style="margin: 0px 0 5px 0"
                                >
                                    <span>
                                        <a
                                            href="javascript:void(0);"
                                            (click)="downloadPDFCL()"
                                            >Print PDF</a
                                        >
                                    </span>
                                    |
                                    <span>
                                        <a
                                            routerLink="/consultant-liability/{{
                                                propertyDetails.Id
                                            }}"
                                            >Edit</a
                                        >
                                    </span>
                                </div>
                                <div class="links-to-pdf-and-edit">
                                    <span>
                                        <a
                                            *ngIf="
                                                propertyDetails.InitialPackageDate ==
                                                    'InitialPackageDate' ||
                                                propertyDetails.InitialPackageDate ==
                                                    '' ||
                                                propertyDetails.InitialPackageDate ==
                                                    undefined
                                            "
                                            (click)="
                                                sendForDigitalSignature(
                                                    propertyDetails.Id
                                                )
                                            "
                                            >Send Initial Package to BO for
                                            Digital Signature</a
                                        >
                                        <a
                                            *ngIf="
                                                propertyDetails.InitialPackageDate !=
                                                    'InitialPackageDate' &&
                                                propertyDetails.InitialPackageDate !=
                                                    '' &&
                                                propertyDetails.InitialPackageDate !=
                                                    undefined
                                            "
                                            (click)="
                                                sendForDigitalSignature(
                                                    propertyDetails.Id
                                                )
                                            "
                                            >Resend Initial Package to BO for
                                            Digital Signature</a
                                        >
                                        <span
                                            *ngIf="
                                                propertyDetails.InitialPackageDate !=
                                                    'InitialPackageDate' &&
                                                propertyDetails.InitialPackageDate !=
                                                    '' &&
                                                propertyDetails.InitialPackageDate !=
                                                    undefined
                                            "
                                            ><br />{{
                                                propertyDetails.InitialPackageDate
                                                    | date : "M/d/y h:mm"
                                            }}
                                            - Initial Package Submitted</span
                                        >
                                    </span>

                                    <!-- <br>
                <span *ngIf="propertyDetails.InitialPackageDate != 'InitialPackageDate' && propertyDetails.InitialPackageDate != '' && propertyDetails.InitialPackageDate != undefined" >Package Sent on {{propertyDetails.InitialPackageDate | date: 'MM/dd/yyyy' }} </span> -->
                                    <br /><br />
                                    <ng-container
                                        *ngFor="
                                            let envelopeItem of signedEnvelopesList;
                                            let i = index
                                        "
                                    >
                                        <div
                                            class="signedDocList"
                                            *ngIf="
                                                envelopeItem.EnvelopeType ==
                                                    'non_home' &&
                                                envelopeItem.UploadedStatus == 1
                                            "
                                        >
                                            <a
                                                href="javascript:void(0)"
                                                class="RemoveSignedLink"
                                                *ngIf="
                                                    envelopeItem.EnvelopeType ==
                                                        'non_home' &&
                                                    envelopeItem.UploadedStatus ==
                                                        1
                                                "
                                                (click)="
                                                    deleteSignedDoc(
                                                        envelopeItem.DocumentName,
                                                        propertyDetails.Id
                                                    )
                                                "
                                                >X</a
                                            >
                                            <a
                                                href="javascript:void(0)"
                                                class="signedLink"
                                                *ngIf="
                                                    envelopeItem.EnvelopeType ==
                                                        'non_home' &&
                                                    envelopeItem.UploadedStatus ==
                                                        1
                                                "
                                                (click)="
                                                    generateCompletedSignedEnvelope(
                                                        envelopeItem,
                                                        true
                                                    )
                                                "
                                                >Signed
                                                {{
                                                    envelopeItem.DocumentName
                                                }}.pdf</a
                                            >
                                        </div>
                                    </ng-container>
                                    <ng-container
                                        *ngFor="
                                            let envelopeItem of signedEnvelopesList;
                                            let i = index
                                        "
                                    >
                                        <div
                                            class="signedDocList"
                                            *ngIf="
                                                envelopeItem.EnvelopeType ==
                                                    'initial_package' &&
                                                envelopeItem.UploadedStatus == 1
                                            "
                                        >
                                            <a
                                                href="javascript:void(0)"
                                                class="RemoveSignedLink"
                                                *ngIf="
                                                    envelopeItem.EnvelopeType ==
                                                        'initial_package' &&
                                                    envelopeItem.UploadedStatus ==
                                                        1
                                                "
                                                (click)="
                                                    deleteSignedDoc(
                                                        envelopeItem.DocumentName,
                                                        propertyDetails.Id
                                                    )
                                                "
                                                >X</a
                                            >
                                            <a
                                                href="javascript:void(0)"
                                                class="signedLink"
                                                *ngIf="
                                                    envelopeItem.EnvelopeType ==
                                                        'initial_package' &&
                                                    envelopeItem.UploadedStatus ==
                                                        1
                                                "
                                                (click)="
                                                    generateCompletedSignedEnvelope(
                                                        envelopeItem,
                                                        true
                                                    )
                                                "
                                                >Signed
                                                {{
                                                    envelopeItem.DocumentName
                                                }}.pdf</a
                                            >
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </li>
                        <li
                            *ngIf="
                                propertyDetails.Status ==
                                    'Pre Closing Completed' ||
                                propertyDetails.Status ==
                                    'Final Draw Completed' ||
                                propertyDetails.Status ==
                                    'Draw request started' ||
                                propertyDetails.Status == 'Post closing started'
                            "
                        >
                            <div class="ngcontainer">
                                <h4 class="type-title">Final Package</h4>

                                <h5
                                    class="type-title"
                                    style="font-size: 16px; margin: 0px 0 5px 0"
                                >
                                    Consultant Identity of Interest
                                </h5>
                                <div
                                    class="links-to-pdf-and-edit"
                                    style="margin: 0px 0 5px 0"
                                >
                                    <span>
                                        <a
                                            href="javascript:void(0);"
                                            (click)="
                                                downloadIdentityOfInterest()
                                            "
                                            >Print PDF</a
                                        >
                                    </span>
                                </div>
                                <h5
                                    class="type-title"
                                    style="font-size: 16px; margin: 0px 0 5px 0"
                                >
                                    Certification Letter
                                </h5>
                                <div
                                    class="links-to-pdf-and-edit"
                                    style="margin: 0px 0 5px 0"
                                >
                                    <span>
                                        <a
                                            href="javascript:void(0);"
                                            (click)="
                                                downloadCertificationLetter()
                                            "
                                            >Print PDF</a
                                        >
                                    </span>
                                </div>
                                <h5
                                    class="type-title"
                                    style="font-size: 16px; margin: 0px 0 5px 0"
                                >
                                    Permits Certifications
                                </h5>
                                <div
                                    class="links-to-pdf-and-edit"
                                    style="margin: 0px 0 5px 0"
                                >
                                    <span>
                                        <a
                                            href="javascript:void(0);"
                                            (click)="
                                                GetPermitsCertificationPdf(
                                                    propertyDetails.Id
                                                )
                                            "
                                            >Print PDF</a
                                        >
                                    </span>
                                    |
                                    <span>
                                        <a
                                            routerLink="/permits-and-certifications-required/{{
                                                propertyDetails.Id
                                            }}"
                                            >Edit</a
                                        >
                                    </span>
                                </div>
                                <h5
                                    class="type-title"
                                    style="font-size: 16px; margin: 0px 0 5px 0"
                                >
                                    Draw Request Form
                                </h5>
                                <div
                                    class="links-to-pdf-and-edit"
                                    style="margin: 0px 0 5px 0"
                                >
                                    <span>
                                        <a
                                            href="javascript:void(0);"
                                            (click)="
                                                blankDrawFormPdf(
                                                    propertyDetails.Id
                                                )
                                            "
                                            >Blank Draw Form Pdf</a
                                        >
                                    </span>
                                </div>
                                <span>
                                    <a
                                        href="javascript:void(0);"
                                        *ngIf="
                                            sorDetails.FinalMailSendDate == ''
                                        "
                                        (click)="
                                            generateFinalSOR(propertyDetails.Id)
                                        "
                                        data="19890"
                                        class="agrmnt-signed-by-bo"
                                        ><br />Send Final SOR Package to Bo and
                                        CO for Digital Signature</a
                                    >
                                    <a
                                        href="javascript:void(0);"
                                        *ngIf="
                                            sorDetails.FinalMailSendDate !=
                                                '' &&
                                            sorDetails.FinalMailSendDate != null
                                        "
                                        (click)="
                                            generateFinalSOR(propertyDetails.Id)
                                        "
                                        data="19890"
                                        class="agrmnt-signed-by-bo"
                                        ><br />Resend Final SOR Package to Bo
                                        and CO for Digital Signature</a
                                    >
                                    <span
                                        *ngIf="
                                            sorDetails.FinalMailSendDate !=
                                                '' &&
                                            sorDetails.FinalMailSendDate != null
                                        "
                                        ><br />{{
                                            sorDetails.FinalMailSendDate
                                                | date : "M/d/y h:mm"
                                        }}
                                        - Final SOR Submitted</span
                                    >
                                </span>
                                <br /><br />
                                <ng-container
                                    *ngFor="
                                        let envelopeItem of signedEnvelopesList;
                                        let i = index
                                    "
                                >
                                    <div
                                        class="signedDocList"
                                        *ngIf="
                                            envelopeItem.EnvelopeType ==
                                                'final_package' &&
                                            envelopeItem.DocumentName !=
                                                'Specification of Repair' &&
                                            envelopeItem.DocumentName !=
                                                'Invoice' &&
                                            envelopeItem.UploadedStatus == 1
                                        "
                                    >
                                        <a
                                            href="javascript:void(0)"
                                            class="RemoveSignedLink"
                                            *ngIf="
                                                envelopeItem.EnvelopeType ==
                                                    'final_package' &&
                                                envelopeItem.DocumentName !=
                                                    'Specification of Repair' &&
                                                envelopeItem.DocumentName !=
                                                    'Invoice' &&
                                                envelopeItem.UploadedStatus == 1
                                            "
                                            (click)="
                                                deleteSignedDoc(
                                                    envelopeItem.DocumentName,
                                                    propertyDetails.Id
                                                )
                                            "
                                            >X</a
                                        >
                                        <a
                                            href="javascript:void(0)"
                                            class="signedLink"
                                            *ngIf="
                                                envelopeItem.EnvelopeType ==
                                                    'final_package' &&
                                                envelopeItem.DocumentName !=
                                                    'Specification of Repair' &&
                                                envelopeItem.DocumentName !=
                                                    'Invoice' &&
                                                envelopeItem.UploadedStatus == 1
                                            "
                                            (click)="
                                                generateCompletedSignedEnvelope(
                                                    envelopeItem,
                                                    true
                                                )
                                            "
                                            >Signed
                                            {{
                                                envelopeItem.DocumentName
                                            }}.pdf</a
                                        >
                                    </div>
                                    <div
                                        class="signedDocList"
                                        *ngIf="
                                            envelopeItem.EnvelopeType ==
                                                'final_package' &&
                                            envelopeItem.DocumentName ==
                                                'Invoice' &&
                                            envelopeItem.UploadedStatus == 1
                                        "
                                    >
                                        <a
                                            href="javascript:void(0)"
                                            class="RemoveSignedLink"
                                            *ngIf="
                                                envelopeItem.EnvelopeType ==
                                                    'final_package' &&
                                                envelopeItem.DocumentName ==
                                                    'Invoice' &&
                                                envelopeItem.UploadedStatus == 1
                                            "
                                            (click)="
                                                deleteSignedDoc(
                                                    envelopeItem.DocumentName,
                                                    propertyDetails.Id
                                                )
                                            "
                                            >X</a
                                        >
                                        <a
                                            href="javascript:void(0)"
                                            class="signedLink"
                                            *ngIf="
                                                envelopeItem.EnvelopeType ==
                                                    'final_package' &&
                                                envelopeItem.DocumentName ==
                                                    'Invoice' &&
                                                envelopeItem.UploadedStatus == 1
                                            "
                                            (click)="
                                                generateCompletedSignedEnvelope(
                                                    envelopeItem,
                                                    true
                                                )
                                            "
                                            >{{
                                                envelopeItem.DocumentName
                                            }}.pdf</a
                                        >
                                    </div>
                                </ng-container>
                            </div>
                        </li>
                    </ul>

                    <ng-container>
                        <form enctype="multipart/form-data">
                            <label class="uploadFormLabel"
                                >Upload Signed Docs & Construction Plans
                            </label>
                            <input
                                name="file"
                                hidden
                                type="file"
                                multiple="multiple"
                                #uploader
                                style="
                                    border: 1px solid;
                                    margin: 0px 10px 0 0;
                                    padding: 6px 0px 6px 0px;
                                "
                                (change)="handleInputChange($event)"
                            />
                            <button
                                (click)="uploader.click()"
                                class="uploadBtn"
                                color="warn"
                                style="
                                    font-size: 30px;
                                    margin: 3px 0 0 5px;
                                    font-weight: bold;
                                "
                            >
                                +
                            </button>
                        </form>
                    </ng-container>

                    <br />
                    <p
                        *ngFor="
                            let DocumentDetails of propertyDocumentList;
                            let i = index
                        "
                        style="clear: both; padding-left: 50px"
                    >
                        <a
                            *ngIf="
                                DocumentDetails.Title != null &&
                                this.userDetail.Id == DocumentDetails.CreatedBy
                            "
                            (click)="deleteUploadedFile(DocumentDetails.Id)"
                            class="removeIcon"
                            ><img
                                [src]="deleteImagePath"
                                alt="Delete Uploaded File" /></a
                        >&nbsp;&nbsp;<a
                            *ngIf="DocumentDetails.Title != null"
                            (click)="
                                downloadPropertyDoc(DocumentDetails.FilePath)
                            "
                            >{{ DocumentDetails.Title }}</a
                        >
                        <a
                            *ngIf="
                                DocumentDetails.Title == null &&
                                this.userDetail.Id == DocumentDetails.CreatedBy
                            "
                            (click)="deleteUploadedFile(DocumentDetails.Id)"
                            class="removeIcon"
                            ><img
                                [src]="deleteImagePath"
                                alt="Delete Uploaded File" /></a
                        >&nbsp;&nbsp;<a
                            *ngIf="DocumentDetails.Title == null"
                            (click)="
                                downloadPropertyDoc(DocumentDetails.FilePath)
                            "
                            >Download Document {{ i + 1 }}</a
                        >
                    </p>
                </div>
            </div>
        </mat-tab>

        <mat-tab
            *ngIf="
                userDetail.UserType == 'Loan Processor' ||
                userDetail.UserType == 'Loan Officer'
            "
        >
            <ng-template mat-tab-label>
                <div (click)="getSignedEnvelopes(propertyDetails.Id)">
                    Pre-Closing
                </div>
            </ng-template>
            <div class="profileDetailsSidebar">
                <div class="side-list">
                    <h2 class="uploadFormLabel">Property Photos</h2>
                    <ul>
                        <li>
                            <div class="ngcontainer">
                                <span
                                    *ngIf="
                                        !isArray(propertyDetails.GoogleAlbum) ||
                                        propertyDetails.GoogleAlbum == null ||
                                        propertyDetails.GoogleAlbum.length == 0
                                    "
                                    ><br />No Album Available</span
                                >
                                <div
                                    *ngFor="
                                        let Album of propertyDetails.GoogleAlbum;
                                        let i = index
                                    "
                                >
                                    <div
                                        *ngIf="
                                            Album.AlbumType == 'propertyPhotos'
                                        "
                                    >
                                        <strong
                                            >{{ Album.AlbumTitle }} :
                                        </strong>
                                        <span>
                                            <a
                                                *ngIf="
                                                    propertyDetails.GoogleAlbum !=
                                                    null
                                                "
                                                href="{{ Album.GoogleAlbum }}"
                                                target="_blank"
                                                >View</a
                                            >
                                        </span>
                                        <br />
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="side-list">
                    <h2>Consultant Generated Forms</h2>
                    <ul>
                        <li>
                            <div class="ngcontainer">
                                <h4 class="type-title">
                                    Draft Specifications of Repairs
                                </h4>
                                <div class="links-to-pdf-and-edit">
                                    <span
                                        *ngIf="propertyDetails.sorExist == true"
                                    >
                                        <a
                                            href="javascript:void(0);"
                                            (click)="GetSORPDF()"
                                            >Print PDF</a
                                        >
                                    </span>
                                    <span
                                        *ngIf="
                                            propertyDetails.sorExist == false
                                        "
                                        >Draft SOR in Progress</span
                                    >
                                    <br />

                                    <!-- <span>
                 <a href="javascript:void(0);" (click)="sorEmailToBorrower(propertyDetails.Id,borrowerDetails.EmailId,borrowerDetails.FirstName,borrowerDetails.LastName);"
                   data="19890" class="agrmnt-signed-by-bo">Send to Borrower</a>
               </span>
               <br> -->
                                    <span *ngIf="sorDetails.MailSend == true"
                                        >{{
                                            sorDetails.MailSendDate
                                                | date : "M/d/y h:mm"
                                        }}
                                        - DRAFT SOR Sent to Borrower</span
                                    >
                                    <span
                                        *ngIf="
                                            propertyDetails.Status == 'Revise'
                                        "
                                        ><br />{{
                                            sorDetails.ApprovedDate
                                                | date : "M/d/y h:mm"
                                        }}
                                        - DRAFT SOR Revised By "{{
                                            borrowerDetails.FirstName
                                        }}
                                        {{ borrowerDetails.LastName }}"</span
                                    >
                                    <span *ngIf="sorDetails.Approved == true"
                                        ><br />{{
                                            sorDetails.ApprovedDate
                                                | date : "M/d/y h:mm"
                                        }}
                                        - DRAFT SOR Approved By "{{
                                            borrowerDetails.FirstName
                                        }}
                                        {{ borrowerDetails.LastName }}"
                                    </span>
                                </div>
                            </div>
                        </li>

                        <li
                            *ngIf="
                                sorDetails.FinalMailSendDate != '' &&
                                sorDetails.FinalMailSendDate != null &&
                                (propertyDetails.Status ==
                                    'Pre Closing Completed' ||
                                    propertyDetails.Status ==
                                        'Final Draw Completed' ||
                                    propertyDetails.Status ==
                                        'Draw request started' ||
                                    propertyDetails.Status ==
                                        'Post closing started')
                            "
                        >
                            <div class="ngcontainer">
                                <h4 class="type-title">
                                    Final Specifications of Repairs
                                </h4>
                                <div
                                    class="links-to-pdf-and-edit"
                                    style="margin: 0px 0 5px 0"
                                >
                                    <a
                                        *ngIf="
                                            sorDetails.FinalMailSendDate !=
                                                '' &&
                                            sorDetails.FinalMailSendDate != null
                                        "
                                        href="javascript:void(0);"
                                        (click)="
                                            printFinalSOR(propertyDetails.Id)
                                        "
                                        data="19890"
                                        class="agrmnt-signed-by-bo"
                                        >Print PDF</a
                                    ><br />
                                    <span
                                        *ngIf="
                                            sorDetails.FinalMailSendDate !=
                                                '' &&
                                            sorDetails.FinalMailSendDate != null
                                        "
                                        >{{
                                            sorDetails.FinalMailSendDate
                                                | date : "M/d/y h:mm"
                                        }}
                                        - Final SOR Submitted</span
                                    >

                                    <br />
                                    <ng-container
                                        *ngFor="
                                            let envelopeItem of signedEnvelopesList;
                                            let i = index
                                        "
                                    >
                                        <div
                                            class="signedDocList"
                                            *ngIf="
                                                envelopeItem.EnvelopeType ==
                                                    'final_package' &&
                                                envelopeItem.DocumentName ==
                                                    'Specification of Repair' &&
                                                envelopeItem.UploadedStatus == 1
                                            "
                                        >
                                            <a
                                                href="javascript:void(0)"
                                                class="signedLink"
                                                *ngIf="
                                                    envelopeItem.EnvelopeType ==
                                                        'final_package' &&
                                                    envelopeItem.DocumentName ==
                                                        'Specification of Repair' &&
                                                    envelopeItem.UploadedStatus ==
                                                        1
                                                "
                                                (click)="
                                                    generateCompletedSignedEnvelope(
                                                        envelopeItem,
                                                        true
                                                    )
                                                "
                                                >Signed Final
                                                {{
                                                    envelopeItem.DocumentName
                                                }}.pdf</a
                                            >
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>

                <div class="side-list">
                    <h2>Contractor Estimates</h2>
                    <ng-container>
                        <p>
                            Estimate Status Table below will show when estimates
                            have been submitted and if they have been reviewed
                            by the borrower. If estimate have been submitted but
                            not reviewed, please contact the borrower for
                            status.
                        </p>
                        <p>
                            <strong>Not Reviewed</strong> - Borrower has not
                            reviewed estimate
                        </p>
                        <p>
                            <strong>Accepted</strong> - Borrower has accepted
                            the estimate which will be used for the Final
                            Specification of Repair Report
                        </p>
                    </ng-container>
                    <ul>
                        <li>
                            <a
                                *ngIf="submittedBid > 0"
                                routerLink="/gc-submitted-bids/{{
                                    propertyDetails.Id
                                }}"
                                >View Contractor Submitted Bids</a
                            >
                            <a
                                *ngIf="
                                    propertyDetails.AcceptedProposal != null &&
                                    propertyDetails.AcceptedProposal != ''
                                "
                                (click)="
                                    getBorPDF(
                                        propertyDetails.Id,
                                        propertyDetails.AcceptedProposal
                                    )
                                "
                                ><br />View Contractor Accepted Bid</a
                            >
                            <a
                                *ngIf="submittedBid == 0"
                                href="javascript:void(0)"
                                >No Contractor Submited Bids</a
                            >
                            <br />
                            <ng-container
                                *ngIf="
                                    propertyDetails.GeneralContractorId ==
                                        null &&
                                    propertyDetails.InvitedGeneralContractors ==
                                        null &&
                                    propertyDetails.SubmittedBids.length == 0 &&
                                    submittedBid == 0
                                "
                            >
                                <ng-container *ngIf="loadingGC != true">
                                    <table
                                        class="gcListReviewTableNew"
                                        mat-table
                                        [dataSource]="dataSource"
                                        matSort
                                    >
                                        <ng-container matColumnDef="FirstName">
                                            <th
                                                mat-header-cell
                                                *matHeaderCellDef
                                                mat-sort-header
                                            >
                                                Contractor Name
                                            </th>
                                        </ng-container>
                                        <ng-container
                                            matColumnDef="submissionStatus"
                                        >
                                            <th
                                                mat-header-cell
                                                *matHeaderCellDef
                                                mat-sort-header
                                            >
                                                Submission Status
                                            </th>
                                        </ng-container>
                                        <ng-container
                                            matColumnDef="boReviewStatus"
                                        >
                                            <th
                                                mat-header-cell
                                                *matHeaderCellDef
                                                mat-sort-header
                                            >
                                                BO Review Status
                                            </th>
                                        </ng-container>
                                        <ng-container
                                            matColumnDef="coReviewStatus"
                                        >
                                            <th
                                                mat-header-cell
                                                *matHeaderCellDef
                                                mat-sort-header
                                            >
                                                CO Review Status
                                            </th>
                                        </ng-container>
                                        <ng-container matColumnDef="nodata">
                                            <td
                                                mat-footer-row
                                                *matFooterCellDef
                                                [colSpan]="
                                                    displayedColumns.length
                                                "
                                                style="text-align: center"
                                            >
                                                No Contractors Listed
                                            </td>
                                        </ng-container>

                                        <tr
                                            mat-header-row
                                            *matHeaderRowDef="displayedColumns3"
                                        ></tr>
                                        <tr
                                            mat-row
                                            *matRowDef="
                                                let row;
                                                columns: displayedColumns3
                                            "
                                            style="cursor: pointer"
                                        ></tr>

                                        <tr
                                            mat-footer-row
                                            *matFooterRowDef="['nodata']"
                                        ></tr>
                                    </table>
                                    <br />
                                </ng-container>
                                <div class="gcTableListLoadingSectionNew">
                                    <ngx-loading class="ngxLoader" 
                                        [show]="loadingGC"
                                        [config]="{
                                            backdropBorderRadius: '3px'
                                        }"
                                    ></ngx-loading>
                                </div>
                            </ng-container>
                            <ng-container
                                *ngIf="
                                    (propertyDetails.GeneralContractorId !=
                                        null ||
                                        propertyDetails.InvitedGeneralContractors !=
                                            null) &&
                                    submittedBid == 0
                                "
                            >
                                <ng-container *ngIf="loadingGC != true">
                                    <table
                                        class="gcListReviewTableNew"
                                        mat-table
                                        [dataSource]="dataSource"
                                        matSort
                                    >
                                        <ng-container matColumnDef="FirstName">
                                            <th
                                                mat-header-cell
                                                *matHeaderCellDef
                                                mat-sort-header
                                            >
                                                Contractor Name
                                            </th>
                                            <td mat-cell *matCellDef="let row">
                                                {{ row.FirstName }}
                                                {{ row.LastName }}
                                            </td>
                                        </ng-container>

                                        <ng-container
                                            matColumnDef="submissionStatus"
                                        >
                                            <th
                                                mat-header-cell
                                                *matHeaderCellDef
                                                mat-sort-header
                                            >
                                                Submission Status
                                            </th>
                                            <td mat-cell *matCellDef="let row">
                                                Not Submitted
                                            </td>
                                        </ng-container>

                                        <ng-container
                                            matColumnDef="boReviewStatus"
                                        >
                                            <th
                                                mat-header-cell
                                                *matHeaderCellDef
                                                mat-sort-header
                                            >
                                                BO Review Status
                                            </th>
                                            <td mat-cell *matCellDef="let row">
                                                Not Reviewed
                                            </td>
                                        </ng-container>
                                        <ng-container
                                            matColumnDef="coReviewStatus"
                                        >
                                            <th
                                                mat-header-cell
                                                *matHeaderCellDef
                                                mat-sort-header
                                            >
                                                CO Review Status
                                            </th>
                                            <td mat-cell *matCellDef="let row">
                                                Not Reviewed
                                            </td>
                                        </ng-container>
                                        <tr
                                            mat-header-row
                                            *matHeaderRowDef="displayedColumns3"
                                        ></tr>
                                        <tr
                                            mat-row
                                            *matRowDef="
                                                let row;
                                                columns: displayedColumns3
                                            "
                                            style="cursor: pointer"
                                        ></tr>
                                    </table>
                                    <br />
                                </ng-container>
                                <div class="gcTableListLoadingSectionNew">
                                    <ngx-loading class="ngxLoader" 
                                        [show]="loadingGC"
                                        [config]="{
                                            backdropBorderRadius: '3px'
                                        }"
                                    ></ngx-loading>
                                </div>
                            </ng-container>
                            <ng-container
                                *ngIf="
                                    (propertyDetails.GeneralContractorId !=
                                        null ||
                                        propertyDetails.InvitedGeneralContractors !=
                                            null ||
                                        GCDetailsList.length > 0) &&
                                    submittedBid != 0
                                "
                            >
                                <ng-container *ngIf="loadingGC != true">
                                    <table
                                        class="gcListReviewTableNew"
                                        mat-table
                                        [dataSource]="dataSource"
                                        matSort
                                    >
                                        <ng-container matColumnDef="FirstName">
                                            <th
                                                mat-header-cell
                                                *matHeaderCellDef
                                                mat-sort-header
                                            >
                                                Contractor Name
                                            </th>
                                            <td mat-cell *matCellDef="let row">
                                                {{ row.FirstName }}
                                                {{ row.LastName }}
                                            </td>
                                        </ng-container>

                                        <ng-container
                                            matColumnDef="submissionStatus"
                                        >
                                            <th
                                                mat-header-cell
                                                *matHeaderCellDef
                                                mat-sort-header
                                            >
                                                Estimate Status
                                            </th>
                                            <td mat-cell *matCellDef="let row">
                                                {{ row.submissionStatus }}
                                            </td>
                                        </ng-container>

                                        <ng-container
                                            matColumnDef="boReviewStatus"
                                        >
                                            <th
                                                mat-header-cell
                                                *matHeaderCellDef
                                                mat-sort-header
                                            >
                                                BO Review Status
                                            </th>
                                            <td mat-cell *matCellDef="let row">
                                                {{ row.boReviewStatus }}
                                            </td>
                                        </ng-container>
                                        <ng-container
                                            matColumnDef="coReviewStatus"
                                        >
                                            <th
                                                mat-header-cell
                                                *matHeaderCellDef
                                                mat-sort-header
                                            >
                                                CO Review Status
                                            </th>
                                            <td mat-cell *matCellDef="let row">
                                                {{ row.coReviewStatus }}
                                            </td>
                                        </ng-container>
                                        <tr
                                            mat-header-row
                                            *matHeaderRowDef="displayedColumns3"
                                        ></tr>
                                        <tr
                                            mat-row
                                            *matRowDef="
                                                let row;
                                                columns: displayedColumns3
                                            "
                                            style="cursor: pointer"
                                        ></tr>
                                    </table>
                                    <br />
                                </ng-container>
                                <div class="gcTableListLoadingSectionNew">
                                    <ngx-loading class="ngxLoader" 
                                        [show]="loadingGC"
                                        [config]="{
                                            backdropBorderRadius: '3px'
                                        }"
                                    ></ngx-loading>
                                </div>
                            </ng-container>
                        </li>
                        <br />
                        <ng-container
                            *ngFor="
                                let envelopeItem of signedEnvelopesList;
                                let i = index
                            "
                        >
                            <div
                                class="signedDocList"
                                *ngIf="
                                    envelopeItem.EnvelopeType ==
                                        'bid_on_repair' &&
                                    envelopeItem.UploadedStatus == 1
                                "
                            >
                                <a
                                    href="javascript:void(0)"
                                    class="signedLink"
                                    *ngIf="
                                        envelopeItem.EnvelopeType ==
                                            'bid_on_repair' &&
                                        envelopeItem.UploadedStatus == 1
                                    "
                                    (click)="
                                        generateCompletedSignedEnvelope(
                                            envelopeItem,
                                            true
                                        )
                                    "
                                    >Signed
                                    {{ envelopeItem.DocumentName }}.pdf</a
                                >
                            </div>
                        </ng-container>
                    </ul>
                </div>
            </div>
            <div class="profileDetailsSidebar">
                <div class="side-list">
                    <h2>Documents for Signature</h2>
                    <ul>
                        <li>
                            <div class="ngcontainer">
                                <h4 class="type-title">Initial Package</h4>
                                <h5
                                    class="type-title"
                                    style="font-size: 16px; margin: 0px 0 5px 0"
                                >
                                    Non-Home Inspection Disclosure
                                </h5>
                                <div
                                    class="links-to-pdf-and-edit"
                                    style="margin: 0px 0 5px 0"
                                >
                                    <span>
                                        <a
                                            href="javascript:void(0);"
                                            (click)="downloadPDFNHID()"
                                            >Print PDF</a
                                        >
                                    </span>
                                    <!-- | <span>
                 <a routerLink="/fha-consultant-agreement/{{propertyDetails.Id}}">Edit</a>
               </span> -->
                                </div>

                                <h5
                                    class="type-title"
                                    style="font-size: 16px; margin: 0px 0 5px 0"
                                >
                                    Consultant Agreement
                                </h5>
                                <div
                                    class="links-to-pdf-and-edit"
                                    style="margin: 0px 0 5px 0"
                                >
                                    <span>
                                        <a
                                            href="javascript:void(0);"
                                            (click)="downloadPDFCA()"
                                            >Print PDF</a
                                        >
                                    </span>
                                    <!-- | <span>
                 <a routerLink="/fha-consultant-agreement/{{propertyDetails.Id}}">Edit</a>
               </span> -->
                                </div>
                                <h5
                                    class="type-title"
                                    style="font-size: 16px; margin: 0px 0 5px 0"
                                >
                                    Consultant Liability
                                </h5>
                                <div
                                    class="links-to-pdf-and-edit"
                                    style="margin: 0px 0 5px 0"
                                >
                                    <span>
                                        <a
                                            href="javascript:void(0);"
                                            (click)="downloadPDFCL()"
                                            >Print PDF</a
                                        >
                                    </span>
                                    <!-- | <span>

                 <a routerLink="/consultant-liability/{{propertyDetails.Id}}">Edit</a>

               </span> -->
                                </div>
                                <h5
                                    class="type-title"
                                    style="
                                        font-size: 16px;
                                        margin: 0px 5px 0 0;
                                        float: left;
                                        width: auto;
                                        font-weight: bold;
                                        text-decoration: underline;
                                    "
                                >
                                    Signed Documents
                                </h5>
                                <span
                                    style="
                                        font-size: 14px;
                                        text-decoration: none !important;
                                        font-weight: normal;
                                    "
                                >
                                    (Intial package DocuSign documents will
                                    appear below once Completed)</span
                                >
                                <div class="links-to-pdf-and-edit">
                                    <br />
                                    <ng-container
                                        *ngFor="
                                            let envelopeItem of signedEnvelopesList;
                                            let i = index
                                        "
                                    >
                                        <div
                                            class="signedDocList"
                                            *ngIf="
                                                envelopeItem.EnvelopeType ==
                                                    'non_home' &&
                                                envelopeItem.UploadedStatus == 1
                                            "
                                        >
                                            <a
                                                href="javascript:void(0)"
                                                class="signedLink"
                                                *ngIf="
                                                    envelopeItem.EnvelopeType ==
                                                        'non_home' &&
                                                    envelopeItem.UploadedStatus ==
                                                        1
                                                "
                                                (click)="
                                                    generateCompletedSignedEnvelope(
                                                        envelopeItem,
                                                        true
                                                    )
                                                "
                                                >Signed
                                                {{
                                                    envelopeItem.DocumentName
                                                }}.pdf<br
                                            /></a>
                                        </div>
                                    </ng-container>
                                    <ng-container
                                        *ngFor="
                                            let envelopeItem of signedEnvelopesList;
                                            let i = index
                                        "
                                    >
                                        <div
                                            class="signedDocList"
                                            *ngIf="
                                                envelopeItem.EnvelopeType ==
                                                    'initial_package' &&
                                                envelopeItem.UploadedStatus == 1
                                            "
                                        >
                                            <a
                                                href="javascript:void(0)"
                                                class="signedLink"
                                                *ngIf="
                                                    envelopeItem.EnvelopeType ==
                                                        'initial_package' &&
                                                    envelopeItem.UploadedStatus ==
                                                        1
                                                "
                                                (click)="
                                                    generateCompletedSignedEnvelope(
                                                        envelopeItem,
                                                        true
                                                    )
                                                "
                                                >Signed
                                                {{
                                                    envelopeItem.DocumentName
                                                }}.pdf<br
                                            /></a>
                                        </div>
                                    </ng-container>
                                    <!-- <br>
               <span *ngIf="propertyDetails.InitialPackageDate != 'InitialPackageDate' && propertyDetails.InitialPackageDate != '' && propertyDetails.InitialPackageDate != undefined" >Package Sent on {{propertyDetails.InitialPackageDate | date: 'MM/dd/yyyy' }} </span> -->
                                </div>
                            </div>
                        </li>
                        <li
                            *ngIf="
                                sorDetails.FinalMailSendDate != '' &&
                                sorDetails.FinalMailSendDate != null &&
                                (propertyDetails.Status ==
                                    'Pre Closing Completed' ||
                                    propertyDetails.Status ==
                                        'Final Draw Completed' ||
                                    propertyDetails.Status ==
                                        'Draw request started' ||
                                    propertyDetails.Status ==
                                        'Post closing started')
                            "
                        >
                            <div class="ngcontainer">
                                <h4 class="type-title">Final Package</h4>

                                <h5
                                    class="type-title"
                                    style="font-size: 16px; margin: 0px 0 5px 0"
                                >
                                    Consultant Identity of Interest
                                </h5>
                                <div
                                    class="links-to-pdf-and-edit"
                                    style="margin: 0px 0 5px 0"
                                >
                                    <span>
                                        <a
                                            href="javascript:void(0);"
                                            (click)="
                                                downloadIdentityOfInterest()
                                            "
                                            >Print PDF</a
                                        >
                                    </span>
                                </div>
                                <h5
                                    class="type-title"
                                    style="font-size: 16px; margin: 0px 0 5px 0"
                                >
                                    Certification Letter
                                </h5>
                                <div
                                    class="links-to-pdf-and-edit"
                                    style="margin: 0px 0 5px 0"
                                >
                                    <span>
                                        <a
                                            href="javascript:void(0);"
                                            (click)="
                                                downloadCertificationLetter()
                                            "
                                            >Print PDF</a
                                        >
                                    </span>
                                </div>
                                <h5
                                    class="type-title"
                                    style="font-size: 16px; margin: 0px 0 5px 0"
                                >
                                    Permits Certifications
                                </h5>
                                <div
                                    class="links-to-pdf-and-edit"
                                    style="margin: 0px 0 5px 0"
                                >
                                    <span>
                                        <a
                                            href="javascript:void(0);"
                                            (click)="
                                                GetPermitsCertificationPdf(
                                                    propertyDetails.Id
                                                )
                                            "
                                            >Print PDF</a
                                        >
                                    </span>
                                    <!-- | <span>
                 <a routerLink="/permits-and-certifications-required">Edit</a>
               </span> -->
                                </div>
                                <h5
                                    class="type-title"
                                    style="font-size: 16px; margin: 0px 0 5px 0"
                                >
                                    Draw Request Form
                                </h5>
                                <div
                                    class="links-to-pdf-and-edit"
                                    style="margin: 0px 0 5px 0"
                                >
                                    <span>
                                        <a
                                            href="javascript:void(0);"
                                            (click)="
                                                blankDrawFormPdf(
                                                    propertyDetails.Id
                                                )
                                            "
                                            >Blank Draw Form Pdf</a
                                        >
                                    </span>
                                    <!-- | <span>
                 <a routerLink="/permits-and-certifications-required">Edit</a>
               </span> -->
                                </div>
                                <br /><br />
                                <h5
                                    *ngIf="
                                        sorDetails.FinalMailSendDate != '' &&
                                        sorDetails.FinalMailSendDate != null
                                    "
                                    class="type-title"
                                    style="
                                        font-size: 16px;
                                        margin: 0px 5px 0 0;
                                        float: left;
                                        width: auto;
                                        font-weight: bold;
                                        text-decoration: underline;
                                    "
                                >
                                    Signed Documents
                                </h5>
                                <span
                                    style="
                                        font-size: 14px;
                                        font-weight: normal;
                                        text-decoration: none !important;
                                    "
                                >
                                    (Final package DocuSign documents will
                                    appear below once Completed)</span
                                >
                                <ng-container
                                    *ngFor="
                                        let envelopeItem of signedEnvelopesList;
                                        let i = index
                                    "
                                >
                                    <div
                                        class="signedDocList"
                                        *ngIf="
                                            envelopeItem.EnvelopeType ==
                                                'final_package' &&
                                            envelopeItem.DocumentName !=
                                                'Specification of Repair' &&
                                            envelopeItem.DocumentName !=
                                                'Invoice' &&
                                            envelopeItem.UploadedStatus == 1
                                        "
                                    >
                                        <a
                                            href="javascript:void(0)"
                                            class="signedLink"
                                            *ngIf="
                                                envelopeItem.EnvelopeType ==
                                                    'final_package' &&
                                                envelopeItem.DocumentName !=
                                                    'Specification of Repair' &&
                                                envelopeItem.DocumentName !=
                                                    'Invoice' &&
                                                envelopeItem.UploadedStatus == 1
                                            "
                                            (click)="
                                                generateCompletedSignedEnvelope(
                                                    envelopeItem,
                                                    true
                                                )
                                            "
                                            >Signed
                                            {{
                                                envelopeItem.DocumentName
                                            }}.pdf</a
                                        >
                                    </div>
                                    <div
                                        class="signedDocList"
                                        *ngIf="
                                            envelopeItem.EnvelopeType ==
                                                'final_package' &&
                                            envelopeItem.DocumentName ==
                                                'Invoice' &&
                                            envelopeItem.UploadedStatus == 1
                                        "
                                    >
                                        <a
                                            href="javascript:void(0)"
                                            class="signedLink"
                                            *ngIf="
                                                envelopeItem.EnvelopeType ==
                                                    'final_package' &&
                                                envelopeItem.DocumentName ==
                                                    'Invoice' &&
                                                envelopeItem.UploadedStatus == 1
                                            "
                                            (click)="
                                                generateCompletedSignedEnvelope(
                                                    envelopeItem,
                                                    true
                                                )
                                            "
                                            >{{ envelopeItem.DocumentName }}.pdf
                                        </a>
                                    </div>
                                </ng-container>
                            </div>
                        </li>
                    </ul>

                    <ng-container>
                        <form enctype="multipart/form-data">
                            <label class="uploadFormLabel"
                                >Upload Signed Docs & Construction Plans
                            </label>
                            <input
                                name="file"
                                hidden
                                type="file"
                                multiple="multiple"
                                #uploader
                                style="
                                    border: 1px solid;
                                    margin: 0px 10px 0 0;
                                    padding: 6px 0px 6px 0px;
                                "
                                (change)="handleInputChange($event)"
                            />
                            <button
                                (click)="uploader.click()"
                                class="uploadBtn"
                                color="warn"
                                style="
                                    font-size: 30px;
                                    margin: 3px 0 0 5px;
                                    font-weight: bold;
                                "
                            >
                                +
                            </button>
                        </form>
                    </ng-container>

                    <br />
                    <p
                        *ngFor="
                            let DocumentDetails of propertyDocumentList;
                            let i = index
                        "
                        style="clear: both; padding-left: 50px"
                    >
                        <a
                            *ngIf="
                                DocumentDetails.Title != null &&
                                this.userDetail.Id == DocumentDetails.CreatedBy
                            "
                            (click)="deleteUploadedFile(DocumentDetails.Id)"
                            class="removeIcon"
                            ><img
                                [src]="deleteImagePath"
                                alt="Delete Uploaded File" /></a
                        >&nbsp;&nbsp;<a
                            *ngIf="DocumentDetails.Title != null"
                            (click)="
                                downloadPropertyDoc(DocumentDetails.FilePath)
                            "
                            >{{ DocumentDetails.Title }}</a
                        >
                        <a
                            *ngIf="
                                DocumentDetails.Title == null &&
                                this.userDetail.Id == DocumentDetails.CreatedBy
                            "
                            (click)="deleteUploadedFile(DocumentDetails.Id)"
                            class="removeIcon"
                            ><img
                                [src]="deleteImagePath"
                                alt="Delete Uploaded File" /></a
                        >&nbsp;&nbsp;<a
                            *ngIf="DocumentDetails.Title == null"
                            (click)="
                                downloadPropertyDoc(DocumentDetails.FilePath)
                            "
                            >Download Document {{ i + 1 }}</a
                        >
                    </p>
                </div>
            </div>
        </mat-tab>

        <mat-tab
            *ngIf="
                userDetail.UserType != 'Borrower' &&
                userDetail.UserType != 'Loan Processor' &&
                userDetail.UserType != 'Draw Administrator' &&
                userDetail.UserType != 'Loan Officer'
            "
        >
            <ng-template mat-tab-label>
                <div (click)="getSignedEnvelopes(propertyDetails.Id)">
                    Post-Closing
                </div>
            </ng-template>
            <div class="profileDetailsSidebar">
                <div class="side-list">
                    <span
                        *ngIf="
                            sorDetails.FinalMailSendDate != '' &&
                            sorDetails.FinalMailSendDate != null &&
                            (propertyDetails.StartPostClosingDate == '' ||
                                propertyDetails.StartPostClosingDate == null ||
                                propertyDetails.StartPostClosingDate ==
                                    undefined)
                        "
                        ><br /><a
                            href="javascript:void(0);"
                            (click)="
                                startPostClosingProcess(propertyDetails.Id)
                            "
                            >Start Post Closing Process</a
                        ></span
                    >
                    <span
                        *ngIf="
                            propertyDetails.StartPostClosingDate != '' &&
                            propertyDetails.StartPostClosingDate != null &&
                            propertyDetails.StartPostClosingDate != undefined
                        "
                        ><br />Post Closing Process Started<br />
                        On :
                        {{
                            propertyDetails.StartPostClosingDate
                                | date : "M/d/y h:mm"
                        }}</span
                    >
                </div>
                <div class="side-list">
                    <h2 class="uploadFormLabel">Draw Inspection Photos</h2>
                    <a routerLink="/property-gallery/{{ propertyDetails.Id }}/1"
                        >Add Draw Inspection Photos / Edit Album</a
                    >
                    <ul>
                        <li>
                            <div class="ngcontainer">
                                <span
                                    *ngIf="
                                        !isArray(propertyDetails.GoogleAlbum) ||
                                        propertyDetails.GoogleAlbum == null ||
                                        propertyDetails.GoogleAlbum.length == 0
                                    "
                                    ><br />No Album Available</span
                                >
                                <div
                                    *ngFor="
                                        let Album of propertyDetails.GoogleAlbum;
                                        let i = index
                                    "
                                >
                                    <div
                                        *ngIf="
                                            Album.AlbumType ==
                                            'drawInspectionPhotos'
                                        "
                                    >
                                        <strong
                                            >{{ Album.AlbumTitle }} :
                                        </strong>
                                        <span>
                                            <a
                                                *ngIf="
                                                    propertyDetails.GoogleAlbum !=
                                                    null
                                                "
                                                href="{{ Album.GoogleAlbum }}"
                                                target="_blank"
                                                >View</a
                                            >
                                        </span>
                                        |
                                        <span>
                                            <a
                                                routerLink="/property-gallery/{{
                                                    propertyDetails.Id
                                                }}/1/{{ i }}"
                                                >Edit</a
                                            >
                                        </span>
                                        |
                                        <span>
                                            <a
                                                href="javascript:void(0)"
                                                (click)="
                                                    removeAlbum(
                                                        propertyDetails.Id,
                                                        i
                                                    )
                                                "
                                                >Delete</a
                                            >
                                        </span>
                                        <br />
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="side-list">
                    <h2>Draw inspection Forms</h2>

                    <!-- <a *ngIf="DrawRequestList.length!=0" href="javascript:void(0);" (click)="showAdditionalDrawFunction()" data="19890" class="agrmnt-signed-by-bo">Click Here for Additional Form as Needed </a> -->
                    <ul
                        *ngIf="
                            propertyDetails.Status == 'Draw request started' ||
                            propertyDetails.Status == 'Final Draw Completed' ||
                            propertyDetails.Status == 'Post closing started'
                        "
                    >
                        <li
                            *ngIf="
                                propertyDetails.Status ==
                                    'Draw request started' ||
                                propertyDetails.Status ==
                                    'Final Draw Completed' ||
                                propertyDetails.Status == 'Post closing started'
                            "
                        >
                            <div
                                class="ngcontainer links-to-pdf-and-edit"
                                style="margin: 0px 0 5px 0"
                            >
                                <span>
                                    <a
                                        href="javascript:void(0);"
                                        (click)="
                                            blankDrawFormPdf(propertyDetails.Id)
                                        "
                                        >Blank Draw Form</a
                                    ><br />
                                    <!-- <a href="javascript:void(0);" (click)="drawRequestPDF(propertyDetails.Id);">Print PDF</a>  -->
                                </span>
                            </div>
                        </li>

                        <li
                            *ngFor="
                                let DrawDetails of DrawRequestList;
                                let i = index
                            "
                        >
                            <div class="ngcontainer">
                                <h4 class="type-title">
                                    Draw #{{ i + 1 }}
                                    <span
                                        *ngIf="
                                            DrawDetails.ConsultantRequestDate !=
                                                '' &&
                                            DrawDetails.ConsultantRequestDate !=
                                                null &&
                                            DrawDetails.ConsultantRequestDate !=
                                                undefined
                                        "
                                        >-
                                        {{
                                            DrawDetails.ConsultantRequestDate
                                        }}</span
                                    >
                                </h4>
                                <div
                                    class="links-to-pdf-and-edit"
                                    style="margin: 0px 0 5px 0"
                                >
                                    <div>
                                        Requested By:
                                        <span
                                            *ngIf="
                                                DrawDetails.DrawStatus !=
                                                    'approved' ||
                                                DrawDetails.DrawStatus ==
                                                    'approved'
                                            "
                                        >
                                            {{
                                                DrawDetails.createdByUserType
                                            }}
                                            on
                                            {{
                                                DrawDetails.NewGCRequestDate
                                            }}</span
                                        ><br />
                                    </div>
                                    <div>
                                        Accepted By:
                                        <span
                                            *ngIf="
                                                DrawDetails.DrawStatus ==
                                                'approved'
                                            "
                                        >
                                            {{
                                                DrawDetails.approvedByUserType
                                            }}</span
                                        ><br />
                                    </div>
                                    <br />
                                    <span>
                                        <a
                                            *ngIf="
                                                DrawDetails.DrawStatus ==
                                                'approved'
                                            "
                                            routerLink="/initial-draw-request-co/{{
                                                propertyDetails.Id
                                            }}/{{ DrawDetails.Id }}"
                                            >Review Draw
                                        </a>
                                        <span
                                            *ngIf="
                                                DrawDetails.DrawStatus ==
                                                'approved'
                                            "
                                        >
                                            |
                                        </span>
                                        <a
                                            style="margin: 0 0 0 10px"
                                            (click)="
                                                printDrawRequest(DrawDetails.Id)
                                            "
                                            >Print PDF
                                        </a>
                                    </span>
                                    <br />
                                    <a
                                        style="margin: 0 0 0 10px"
                                        *ngIf="
                                            DrawDetails.DrawStatus == 'approved'
                                        "
                                        (click)="
                                            sendDrawRequestDocSign(
                                                i + 1,
                                                DrawDetails.Id
                                            )
                                        "
                                        >Resend Draw to BO and GC for Digital
                                        Signature</a
                                    ><br />
                                    <span
                                        *ngIf="
                                            DrawDetails.DrawStatus ==
                                                'approved' &&
                                            DrawDetails.ConsultantSendSignDate !=
                                                '' &&
                                            DrawDetails.ConsultantSendSignDate !=
                                                null &&
                                            DrawDetails.ConsultantSendSignDate !=
                                                undefined
                                        "
                                    >
                                        {{
                                            DrawDetails.ConsultantSendSignDate
                                                | date : "M/d/y h:mm"
                                        }}
                                        - Draw Submission Date</span
                                    >

                                    <br /><a
                                        style="margin: 0 0 0 10px"
                                        *ngIf="i + 1 == DrawRequestList.length"
                                        (click)="
                                            deleteDrawRequest(
                                                DrawDetails.Id,
                                                'Draw Request #' + (i + 1),
                                                propertyDetails.Id
                                            )
                                        "
                                        >Delete Draw</a
                                    >
                                </div>
                            </div>
                        </li>
                    </ul>
                    <br /><br />
                    <ng-container
                        *ngFor="
                            let envelopeItem of signedEnvelopesList;
                            let i = index
                        "
                    >
                        <div
                            class="signedDocList"
                            *ngIf="
                                envelopeItem.EnvelopeType == 'initial_draw' &&
                                envelopeItem.UploadedStatus == 1
                            "
                        >
                            <a
                                href="javascript:void(0)"
                                class="RemoveSignedLink"
                                *ngIf="
                                    envelopeItem.EnvelopeType ==
                                        'initial_draw' &&
                                    envelopeItem.UploadedStatus == 1
                                "
                                (click)="
                                    deleteSignedDoc(
                                        envelopeItem.DocumentName,
                                        propertyDetails.Id
                                    )
                                "
                                >X</a
                            >
                            <a
                                href="javascript:void(0)"
                                class="signedLink"
                                *ngIf="
                                    envelopeItem.EnvelopeType ==
                                        'initial_draw' &&
                                    envelopeItem.UploadedStatus == 1
                                "
                                (click)="
                                    generateCompletedSignedEnvelope(
                                        envelopeItem,
                                        true
                                    )
                                "
                                >Signed {{ envelopeItem.DocumentName }}.pdf</a
                            >
                        </div>
                    </ng-container>
                </div>
            </div>
            <div class="profileDetailsSidebar">
                <div class="side-list">
                    <h2>Draw Administrator Details</h2>
                    <a
                        href="javascript:void(0);"
                        (click)="showAddFormDAFunction('')"
                        >Add Draw Administrator</a
                    >
                    <ul>
                        <li *ngIf="DADetails.Id">
                            <div class="ngcontainer">
                                <span *ngIf="DADetails.FirstName">
                                    Name :
                                    <strong
                                        >{{ DADetails.FirstName }}
                                        {{ DADetails.LastName }} <br /></strong
                                ></span>
                                <span *ngIf="DADetails.EmailId"
                                    >Email :
                                    <strong
                                        >{{ DADetails.EmailId }} <br /></strong
                                ></span>
                                <span *ngIf="DADetails.Phone"
                                    >Phone :
                                    <strong
                                        >{{ DADetails.Phone }} <br
                                    /></strong>
                                </span>
                                <span *ngIf="DADetails.Lender"
                                    >Lender :
                                    <strong
                                        >{{ DADetails.Lender }} <br
                                    /></strong>
                                </span>
                                <span *ngIf="DADetails.Id"
                                    ><a
                                        href="javascript:void(0)"
                                        (click)="
                                            showAddFormDAFunction(DADetails.Id)
                                        "
                                        ><strong>Edit</strong></a
                                    >
                                    |
                                    <a
                                        href="javascript:void(0)"
                                        (click)="removeDAFromProperty()"
                                        ><strong>Remove</strong></a
                                    >
                                    <br
                                /></span>
                            </div>
                        </li>
                        <li
                            *ngIf="
                                DADetails.Id == null ||
                                DADetails.Id == undefined ||
                                DADetails.Id == ''
                            "
                        >
                            <div class="ngcontainer">
                                Draw Administrator Not Found.
                            </div>
                        </li>
                    </ul>
                    <br /><br />
                    <mat-card
                        style="width: 100%; clear: both"
                        *ngIf="showAddFormDA"
                    >
                        <form
                            [formGroup]="add_drawAdministrator"
                            class="addDrawAdminForm example-form"
                            (ngSubmit)="SaveDrawAdministratorRecord()"
                        >
                            <mat-card-header>
                                <mat-card-title
                                    >Draw Administrator</mat-card-title
                                >
                            </mat-card-header>
                            <mat-card-content>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td colspan="2">
                                                <mat-form-field>
                                                    <span
                                                        class="mat-form-field-label-wrapper"
                                                        ><!----><label
                                                            class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                            >--Select lender--
                                                        </label></span
                                                    >
                                                    <mat-select
                                                        [disabled]="
                                                            lenderDrawAdminControlDisable
                                                        "
                                                        formControlName="lender"
                                                        (selectionChange)="
                                                            filterDA()
                                                        "
                                                    >
                                                        <span
                                                            class="mat-form-field-label-wrapper"
                                                            ><!----><label
                                                                class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                                >Type to search
                                                                ...</label
                                                            ></span
                                                        >
                                                        <input
                                                            style="
                                                                border: 2px
                                                                    solid
                                                                    #797f8e;
                                                                border-radius: 5px;
                                                                width: 100%;
                                                                height: 40px;
                                                            "
                                                            (keyup)="
                                                                onFilterLenders(
                                                                    $event.target
                                                                )
                                                            "
                                                        />
                                                        <mat-option
                                                            *ngFor="
                                                                let lender_value of lender_filter_list
                                                            "
                                                            value="{{
                                                                lender_value.Id
                                                            }}"
                                                            >{{
                                                                lender_value.Name
                                                            }}</mat-option
                                                        >
                                                    </mat-select>

                                                    <div
                                    class="error"
                                    *ngIf="
                                        (!add_drawAdministrator.controls[
                                            'lender'
                                        ].valid &&
                                        add_drawAdministrator
                                                .controls[
                                                'lender'
                                            ].touched) ||
                                        (add_drawAdministrator.controls[
                                            'lender'
                                        ].invalid &&
                                            submitted)
                                    "
                                >
                                   select lender
                                </div>
                                                </mat-form-field>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="2">
                                                <mat-form-field>
                                                    <span
                                                        class="mat-form-field-label-wrapper"
                                                        ><!----><label
                                                            class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                            >--Select Draw
                                                            Administrator--</label
                                                        ></span
                                                    >
                                                    <mat-select
                                                        [ngModel]="DADetails.Id"
                                                        formControlName="drawAdministrator"
                                                    >
                                                        <mat-option value=""
                                                            >Select</mat-option
                                                        >
                                                        <mat-option
                                                            *ngFor="
                                                                let da_value of this
                                                                    .filter_draw_administrator_list
                                                            "
                                                            value="{{
                                                                da_value.Id
                                                            }}"
                                                            >{{
                                                                da_value.FirstName
                                                            }}
                                                            {{
                                                                da_value.LastName
                                                            }}
                                                        </mat-option>
                                                        <mat-option
                                                            value="other"
                                                            >Other</mat-option
                                                        >
                                                    </mat-select>
                                                    <div
                                    class="error"
                                    *ngIf="
                                        (!add_drawAdministrator.controls[
                                            'drawAdministrator'
                                        ].valid &&
                                        add_drawAdministrator
                                                .controls[
                                                'drawAdministrator'
                                            ].touched) ||
                                        (add_drawAdministrator.controls[
                                            'drawAdministrator'
                                        ].invalid &&
                                            submitted)
                                    "
                                >
                                   Select Draw Administrator
                                </div>
                                                </mat-form-field>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table
                                    *ngIf="
                                        add_drawAdministrator.value[
                                            'drawAdministrator'
                                        ] == 'other'
                                    "
                                >
                                    <tbody>
                                        <tr>
                                            <td>
                                                <span
                                                    class="mat-form-field-label-wrapper"
                                                    ><!----><label
                                                        class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                        >E-mail</label
                                                    ></span
                                                >
                                                <mat-form-field
                                                    class="example-full-width"
                                                >
                                                    <input
                                                        matInput
                                                        formControlName="daemail"
                                                        (blur)="
                                                            onBlurDrawAdministrator()
                                                        "
                                                        name="dausername"
                                                    />
                                                    <div
                                                        class="error"
                                                        *ngIf="
                                                            (!add_drawAdministrator
                                                                .controls[
                                                                'daemail'
                                                            ].valid &&
                                                                add_drawAdministrator
                                                                    .controls[
                                                                    'daemail'
                                                                ].touched) ||
                                                            (add_drawAdministrator
                                                                .controls[
                                                                'daemail'
                                                            ].invalid &&
                                                                submitted)
                                                        "
                                                    >
                                                        Enter valid email.
                                                    </div>
                                                </mat-form-field>
                                            </td>
                                            <td>
                                                <mat-form-field
                                                    class="example-full-width"
                                                >
                                                    <span
                                                        class="mat-form-field-label-wrapper"
                                                        ><!----><label
                                                            class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                            >First Name</label
                                                        ></span
                                                    >
                                                    <input
                                                        matInput
                                                        formControlName="dafname"
                                                        name="dafname"
                                                    />
                                                    <div
                                                        class="error"
                                                        *ngIf="
                                                            (!add_drawAdministrator
                                                                .controls[
                                                                'dafname'
                                                            ].valid &&
                                                                add_drawAdministrator
                                                                    .controls[
                                                                    'dafname'
                                                                ].touched) ||
                                                            (add_drawAdministrator
                                                                .controls[
                                                                'dafname'
                                                            ].invalid &&
                                                                submitted)
                                                        "
                                                    >
                                                        Enter first name.
                                                    </div>
                                                </mat-form-field>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <mat-form-field
                                                    class="example-full-width"
                                                >
                                                    <span
                                                        class="mat-form-field-label-wrapper"
                                                        ><!----><label
                                                            class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                            >Last Name</label
                                                        ></span
                                                    >
                                                    <input
                                                        matInput
                                                        formControlName="dalname"
                                                        name="dalname"
                                                    />
                                                    <div
                                                        class="error"
                                                        *ngIf="
                                                            (!add_drawAdministrator
                                                                .controls[
                                                                'dalname'
                                                            ].valid &&
                                                                add_drawAdministrator
                                                                    .controls[
                                                                    'dalname'
                                                                ].touched) ||
                                                            (add_drawAdministrator
                                                                .controls[
                                                                'dalname'
                                                            ].invalid &&
                                                                submitted)
                                                        "
                                                    >
                                                        Enter last name.
                                                    </div>
                                                </mat-form-field>
                                            </td>
                                            <td>
                                                <mat-form-field
                                                    class="example-full-width"
                                                >
                                                    <span
                                                        class="mat-form-field-label-wrapper"
                                                        ><!----><label
                                                            class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                            >Phone Number</label
                                                        ></span
                                                    >
                                                    <input
                                                        matInput
                                                        [mask]="maskPhoneFormat" 
                                                        placeholder="(000) 000-0000"
                                                        (keyup)="
                                                            keyDownPhoneFormat(
                                                                $event
                                                            )
                                                        "
                                                        (change)="
                                                            changePhoneFormat(
                                                                $event
                                                            )
                                                        "
                                                        formControlName="daphone_no"
                                                        name="daphone_no"
                                                    />
                                                    <div
                                    class="error"
                                    *ngIf="
                                        (!add_drawAdministrator.controls[
                                            'daphone_no'
                                        ].valid &&
                                        add_drawAdministrator
                                                .controls[
                                                'daphone_no'
                                            ].touched) ||
                                        (add_drawAdministrator.controls[
                                            'daphone_no'
                                        ].invalid &&
                                            submitted)
                                    "
                                >
                                <div class="error" *ngIf="add_drawAdministrator?.controls['daphone_no']?.errors?.mask">
                                    Enter valid phone no.
                                   </div>
                                </div>
                                                </mat-form-field>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </mat-card-content>
                            <mat-card-actions>
                                <button
                                    mat-raised-button
                                    type="submit"
                                    color="primary"
                                >
                                    Save
                                </button>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <button
                                    mat-raised-button
                                    type="reset"
                                    color="primary"
                                >
                                    Reset</button
                                >&nbsp;&nbsp;&nbsp;&nbsp;
                                <button
                                    mat-raised-button
                                    type="reset"
                                    (click)="hideAddDAForm()"
                                    color="primary"
                                >
                                    Close
                                </button>
                            </mat-card-actions>
                        </form>
                    </mat-card>
                </div>
                <div class="side-list">
                    <h2>Final Specification of Repair</h2>
                    <ul>
                        <li
                            *ngIf="
                                propertyDetails.Status ==
                                    'Pre Closing Completed' ||
                                propertyDetails.Status ==
                                    'Final Draw Completed' ||
                                propertyDetails.Status ==
                                    'Draw request started' ||
                                propertyDetails.Status == 'Post closing started'
                            "
                        >
                            <div class="ngcontainer">
                                <a
                                    href="javascript:void(0);"
                                    *ngIf="
                                        sorDetails.FinalMailSendDate != '' &&
                                        sorDetails.FinalMailSendDate != null
                                    "
                                    (click)="printFinalSOR(propertyDetails.Id)"
                                    data="19890"
                                    class="agrmnt-signed-by-bo"
                                    >View Final Specification of Repair</a
                                >
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="side-list">
                    <h2>Change Order Form</h2>
                    <ul>
                        <li>
                            <div class="ngcontainer">
                                <div
                                    class="links-to-pdf-and-edit"
                                    style="margin: 0px 0 5px 0"
                                >
                                    <span>
                                        <a
                                            *ngIf="
                                                propertyDetails.LoanType ==
                                                '203K'
                                            "
                                            href="javascript:void(0);"
                                            (click)="
                                                printPdf('assets/pdf/92577.pdf')
                                            "
                                            style="margin: 0 0 0 10px"
                                            >Print PDF
                                        </a>
                                        <a
                                            *ngIf="
                                                propertyDetails.LoanType ==
                                                'Homestyle'
                                            "
                                            href="javascript:void(0);"
                                            (click)="
                                                printPdf(
                                                    'assets/pdf/Homestyle-Change-Order-Form.pdf'
                                                )
                                            "
                                            style="margin: 0 0 0 10px"
                                            >Print PDF
                                        </a>
                                    </span>
                                    <br />
                                </div>
                            </div>
                        </li>
                    </ul>

                    <ng-container
                        *ngIf="
                            userDetail.UserType == 'Administrator' ||
                            userDetail.UserType == 'Consultants'
                        "
                    >
                        <form enctype="multipart/form-data">
                            <label class="uploadFormLabel"
                                >Upload Draw & Change Order forms
                            </label>
                            <input
                                name="file"
                                hidden
                                type="file"
                                multiple="multiple"
                                #uploader
                                style="
                                    border: 1px solid;
                                    margin: 0px 10px 0 0;
                                    padding: 6px 0px 6px 0px;
                                "
                                (change)="handleInputChange($event)"
                            />
                            <button
                                (click)="
                                    DrawOrChangeOrderUpload(); uploader.click()
                                "
                                class="uploadBtn"
                                color="warn "
                                style="
                                    font-size: 30px;
                                    margin: 3px 0 0 5px;
                                    font-weight: bold;
                                "
                            >
                                +
                            </button>
                        </form>
                    </ng-container>

                    <br />
                    <p
                        *ngFor="
                            let DocumentDetails of propertyDrawDocumentList;
                            let i = index
                        "
                        style="clear: both; padding-left: 50px"
                    >
                        <a
                            *ngIf="
                                DocumentDetails.Title != null &&
                                this.userDetail.Id == DocumentDetails.CreatedBy
                            "
                            (click)="deleteUploadedFile(DocumentDetails.Id)"
                            class="removeIcon"
                            ><img
                                [src]="deleteImagePath"
                                alt="Delete Uploaded File" /></a
                        >&nbsp;&nbsp;<a
                            *ngIf="DocumentDetails.Title != null"
                            (click)="
                                downloadPropertyDoc(DocumentDetails.FilePath)
                            "
                            >{{ DocumentDetails.Title }}</a
                        >
                        <a
                            *ngIf="
                                DocumentDetails.Title == null &&
                                this.userDetail.Id == DocumentDetails.CreatedBy
                            "
                            (click)="deleteUploadedFile(DocumentDetails.Id)"
                            class="removeIcon"
                            ><img
                                [src]="deleteImagePath"
                                alt="Delete Uploaded File" /></a
                        >&nbsp;&nbsp;<a
                            *ngIf="DocumentDetails.Title == null"
                            (click)="
                                downloadPropertyDoc(DocumentDetails.FilePath)
                            "
                            >Download Document {{ i + 1 }}</a
                        >
                    </p>
                </div>
            </div>
        </mat-tab>

        <mat-tab
            *ngIf="
                userDetail.UserType == 'Loan Processor' ||
                userDetail.UserType == 'Draw Administrator' ||
                userDetail.UserType == 'Loan Officer'
            "
        >
            <ng-template mat-tab-label>
                <div (click)="getSignedEnvelopes(propertyDetails.Id)">
                    Post-Closing
                </div>
            </ng-template>
            <div class="profileDetailsSidebar">
                <div class="side-list">
                    <span
                        *ngIf="
                            propertyDetails.StartPostClosingDate != '' &&
                            propertyDetails.StartPostClosingDate != null &&
                            propertyDetails.StartPostClosingDate != undefined
                        "
                        ><br />Start Post Closing Process <br />
                        On :
                        {{
                            propertyDetails.StartPostClosingDate
                                | date : "M/d/y h:mm"
                        }}</span
                    >
                </div>
                <div class="side-list">
                    <h2 class="uploadFormLabel">Draw Inspection Photos</h2>

                    <ul>
                        <li>
                            <div class="ngcontainer">
                                <span
                                    *ngIf="
                                        !isArray(propertyDetails.GoogleAlbum) ||
                                        propertyDetails.GoogleAlbum == null ||
                                        propertyDetails.GoogleAlbum.length == 0
                                    "
                                    ><br />No Album Available</span
                                >
                                <div
                                    *ngFor="
                                        let Album of propertyDetails.GoogleAlbum;
                                        let i = index
                                    "
                                >
                                    <div
                                        *ngIf="
                                            Album.AlbumType ==
                                            'drawInspectionPhotos'
                                        "
                                    >
                                        <strong
                                            >{{ Album.AlbumTitle }} :
                                        </strong>
                                        <span>
                                            <a
                                                *ngIf="
                                                    propertyDetails.GoogleAlbum !=
                                                    null
                                                "
                                                href="{{ Album.GoogleAlbum }}"
                                                target="_blank"
                                                >View</a
                                            >
                                        </span>
                                        <br />
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="side-list">
                    <h2>Draw inspection Process</h2>
                    <ul
                        *ngIf="
                            propertyDetails.Status == 'Draw request started' ||
                            propertyDetails.Status == 'Final Draw Completed' ||
                            propertyDetails.Status == 'Post closing started'
                        "
                    >
                        <li
                            *ngIf="
                                propertyDetails.Status ==
                                    'Draw request started' ||
                                propertyDetails.Status ==
                                    'Final Draw Completed' ||
                                propertyDetails.Status == 'Post closing started'
                            "
                        >
                            <div
                                class="ngcontainer links-to-pdf-and-edit"
                                style="margin: 0px 0 5px 0"
                            >
                                <span>
                                    <a
                                        href="javascript:void(0);"
                                        (click)="
                                            blankDrawFormPdf(propertyDetails.Id)
                                        "
                                        >Blank Draw Form</a
                                    ><br />
                                    <a
                                        href="javascript:void(0);"
                                        *ngIf="
                                            userDetail.UserType ==
                                                'Loan Processor' ||
                                            userDetail.UserType ==
                                                'Loan Officer'
                                        "
                                        (click)="
                                            drawRequestPDF(propertyDetails.Id)
                                        "
                                        >Print PDF</a
                                    >
                                </span>
                            </div>
                        </li>
                        <li
                            *ngFor="
                                let DrawDetails of DrawRequestList;
                                let i = index
                            "
                        >
                            <div
                                class="ngcontainer"
                                *ngIf="DrawDetails.DrawStatus == 'approved'"
                            >
                                <h4 class="type-title">
                                    Draw #{{ i + 1 }} -
                                    {{ DrawDetails.ConsultantRequestDate }}
                                </h4>
                                <div
                                    class="links-to-pdf-and-edit"
                                    style="margin: 0px 0 5px 0"
                                >
                                    <span>
                                        <a
                                            style="margin: 0 0 0 10px"
                                            *ngIf="
                                                DrawDetails.ConsultantSendSignDate !=
                                                    '' &&
                                                DrawDetails.ConsultantSendSignDate !=
                                                    null &&
                                                DrawDetails.ConsultantSendSignDate !=
                                                    undefined &&
                                                DrawDetails.DrawStatus ==
                                                    'approved'
                                            "
                                            (click)="
                                                printDrawRequest(DrawDetails.Id)
                                            "
                                            >Print PDF
                                        </a>
                                    </span>
                                    <br />
                                    <!-- <a style="margin:0 0 0 10px" *ngIf="DrawDetails.DrawStatus=='approved'" (click)="sendDrawRequestDocSign(DrawDetails.Id)"> Send Draw to BO for Digital Signature</a> -->
                                    <span
                                        *ngIf="
                                            DrawDetails.ConsultantSendSignDate !=
                                                '' &&
                                            DrawDetails.ConsultantSendSignDate !=
                                                null &&
                                            DrawDetails.ConsultantSendSignDate !=
                                                undefined &&
                                            DrawDetails.DrawStatus == 'approved'
                                        "
                                    >
                                        {{
                                            DrawDetails.ConsultantSendSignDate
                                                | date : "M/d/y h:mm"
                                        }}
                                        - Draw Submission Date</span
                                    >
                                </div>
                            </div>
                        </li>
                    </ul>

                    <br /><br />
                    <ng-container
                        *ngFor="
                            let envelopeItem of signedEnvelopesList;
                            let i = index
                        "
                    >
                        <div
                            class="signedDocList"
                            *ngIf="
                                envelopeItem.EnvelopeType == 'initial_draw' &&
                                envelopeItem.UploadedStatus == 1
                            "
                        >
                            <a
                                href="javascript:void(0)"
                                class="signedLink"
                                *ngIf="
                                    envelopeItem.EnvelopeType ==
                                        'initial_draw' &&
                                    envelopeItem.UploadedStatus == 1
                                "
                                (click)="
                                    generateCompletedSignedEnvelope(
                                        envelopeItem,
                                        true
                                    )
                                "
                                >Signed {{ envelopeItem.DocumentName }}.pdf</a
                            >
                        </div>
                    </ng-container>
                </div>
            </div>
            <div class="profileDetailsSidebar">
                <div class="side-list">
                    <h2>Draw Administrator Details</h2>
                    <a
                        *ngIf="userDetail.UserType == 'Draw Administrator'"
                        href="javascript:void(0);"
                        (click)="showAddFormDAFunction('')"
                        >Add Draw Administrator</a
                    >

                    <ul>
                        <li *ngIf="DADetails.Id">
                            <div class="ngcontainer">
                                <span *ngIf="DADetails.FirstName">
                                    Name :
                                    <strong
                                        >{{ DADetails.FirstName }}
                                        {{ DADetails.LastName }} <br /></strong
                                ></span>
                                <span *ngIf="DADetails.EmailId"
                                    >Email :
                                    <strong
                                        >{{ DADetails.EmailId }} <br /></strong
                                ></span>
                                <span *ngIf="DADetails.Phone"
                                    >Phone :
                                    <strong
                                        >{{ DADetails.Phone }} <br
                                    /></strong>
                                </span>
                                <span *ngIf="DADetails.Lender"
                                    >Lender :
                                    <strong
                                        >{{ DADetails.Lender }} <br
                                    /></strong>
                                </span>
                                <span *ngIf="DADetails.Id"
                                    ><a
                                        href="javascript:void(0)"
                                        (click)="
                                            showAddFormDAFunction(DADetails.Id)
                                        "
                                        ><strong>Edit</strong></a
                                    >
                                    |
                                    <a
                                        href="javascript:void(0)"
                                        (click)="removeDAFromProperty()"
                                        ><strong>Remove</strong></a
                                    >
                                    <br
                                /></span>
                            </div>
                        </li>
                    </ul>
                    <br /><br />
                    <mat-card
                        style="width: 100%; clear: both"
                        *ngIf="showAddFormDA"
                    >
                        <form
                            [formGroup]="add_drawAdministrator"
                            class="addDrawAdminForm example-form"
                            (ngSubmit)="SaveDrawAdministratorRecord()"
                        >
                            <mat-card-header>
                                <mat-card-title
                                    >Draw Administrator</mat-card-title
                                >
                            </mat-card-header>
                            <mat-card-content>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td colspan="2">
                                                <mat-form-field>
                                                    <span
                                                        class="mat-form-field-label-wrapper"
                                                        ><!----><label
                                                            class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                            >--Select lender--
                                                        </label></span
                                                    >
                                                    <mat-select
                                                        [ngModel]="
                                                            DADetails.LenderId
                                                        "
                                                        [disabled]="true"
                                                        formControlName="lender"
                                                        (selectionChange)="
                                                            filterDA()
                                                        "
                                                    >
                                                        <span
                                                            class="mat-form-field-label-wrapper"
                                                            ><!----><label
                                                                class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                                >Type to search
                                                                ...</label
                                                            ></span
                                                        >
                                                        <input
                                                            style="
                                                                border: 2px
                                                                    solid
                                                                    #797f8e;
                                                                border-radius: 5px;
                                                                height: 40px;
                                                                width: 100%;
                                                            "
                                                            (keyup)="
                                                                onFilterLenders(
                                                                    $event.target
                                                                )
                                                            "
                                                        />
                                                        <mat-option
                                                            *ngFor="
                                                                let lender_value of lender_filter_list
                                                            "
                                                            value="{{
                                                                lender_value.Id
                                                            }}"
                                                            >{{
                                                                lender_value.Name
                                                            }}</mat-option
                                                        >
                                                    </mat-select>
                                                </mat-form-field>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="2">
                                                <mat-form-field>
                                                    <span
                                                        class="mat-form-field-label-wrapper"
                                                        ><!----><label
                                                            class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                            >--Select Draw
                                                            Administrator--
                                                        </label></span
                                                    >
                                                    <mat-select
                                                        [ngModel]="DADetails.Id"
                                                        formControlName="drawAdministrator"
                                                    >
                                                        <mat-option value=""
                                                            >Select</mat-option
                                                        >
                                                        <mat-option
                                                            *ngFor="
                                                                let da_value of this
                                                                    .filter_draw_administrator_list
                                                            "
                                                            value="{{
                                                                da_value.Id
                                                            }}"
                                                            >{{
                                                                da_value.FirstName
                                                            }}
                                                            {{
                                                                da_value.LastName
                                                            }}
                                                        </mat-option>
                                                        <mat-option
                                                            value="other"
                                                            >Other</mat-option
                                                        >
                                                    </mat-select>
                                                </mat-form-field>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                                <table
                                    *ngIf="
                                        add_drawAdministrator.value[
                                            'drawAdministrator'
                                        ] == 'other'
                                    "
                                >
                                    <tbody>
                                        <tr>
                                            <td>
                                                <mat-form-field
                                                    class="example-full-width"
                                                >
                                                    <span
                                                        class="mat-form-field-label-wrapper"
                                                        ><!----><label
                                                            class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                            >E-mail</label
                                                        ></span
                                                    >
                                                    <input
                                                        matInput
                                                        formControlName="daemail"
                                                        (blur)="
                                                            onBlurDrawAdministrator()
                                                        "
                                                        name="dausername"
                                                        required
                                                    />
                                                    <div
                                                        class="error"
                                                        *ngIf="
                                                            (!add_drawAdministrator
                                                                .controls[
                                                                'daemail'
                                                            ].valid &&
                                                                add_drawAdministrator
                                                                    .controls[
                                                                    'daemail'
                                                                ].touched) ||
                                                            (add_drawAdministrator
                                                                .controls[
                                                                'daemail'
                                                            ].invalid &&
                                                                submitted)
                                                        "
                                                    >
                                                        Enter valid email.
                                                    </div>
                                                </mat-form-field>
                                            </td>
                                            <td>
                                                <mat-form-field
                                                    class="example-full-width"
                                                >
                                                    <span
                                                        class="mat-form-field-label-wrapper"
                                                        ><!----><label
                                                            class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                            >First Name</label
                                                        ></span
                                                    >
                                                    <input
                                                        matInput
                                                        formControlName="dafname"
                                                        name="dafname"
                                                        required
                                                    />
                                                    <div
                                                        class="error"
                                                        *ngIf="
                                                            (!add_drawAdministrator
                                                                .controls[
                                                                'dafname'
                                                            ].valid &&
                                                                add_drawAdministrator
                                                                    .controls[
                                                                    'dafname'
                                                                ].touched) ||
                                                            (add_drawAdministrator
                                                                .controls[
                                                                'dafname'
                                                            ].invalid &&
                                                                submitted)
                                                        "
                                                    >
                                                        Enter first name.
                                                    </div>
                                                </mat-form-field>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <mat-form-field
                                                    class="example-full-width"
                                                >
                                                    <span
                                                        class="mat-form-field-label-wrapper"
                                                        ><!----><label
                                                            class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                            >Last Name</label
                                                        ></span
                                                    >
                                                    <input
                                                        matInput
                                                        formControlName="dalname"
                                                        name="dalname"
                                                    />
                                                    <div
                                                        class="error"
                                                        *ngIf="
                                                            (!add_drawAdministrator
                                                                .controls[
                                                                'dalname'
                                                            ].valid &&
                                                                add_drawAdministrator
                                                                    .controls[
                                                                    'dalname'
                                                                ].touched) ||
                                                            (add_drawAdministrator
                                                                .controls[
                                                                'dalname'
                                                            ].invalid &&
                                                                submitted)
                                                        "
                                                    >
                                                        Enter last name.
                                                    </div>
                                                </mat-form-field>
                                            </td>
                                            <td>
                                                <mat-form-field
                                                    class="example-full-width"
                                                >
                                                    <span
                                                        class="mat-form-field-label-wrapper"
                                                        ><!----><label
                                                            class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                            >Phone Number</label
                                                        ></span
                                                    >
                                                    <input
                                                        matInput
                                                        (keyup)="
                                                            keyDownPhoneFormat(
                                                                $event
                                                            )
                                                        "
                                                        (change)="
                                                            changePhoneFormat(
                                                                $event
                                                            )
                                                        "
                                                        formControlName="daphone_no"
                                                        name="daphone_no"
                                                    />
                                                </mat-form-field>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </mat-card-content>
                            <mat-card-actions>
                                <button
                                    mat-raised-button
                                    type="submit"
                                    color="primary"
                                >
                                    Save
                                </button>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <button
                                    mat-raised-button
                                    type="reset"
                                    color="primary"
                                >
                                    Reset</button
                                >&nbsp;&nbsp;&nbsp;&nbsp;
                                <button
                                    mat-raised-button
                                    type="reset"
                                    (click)="hideAddDAForm()"
                                    color="primary"
                                >
                                    Close
                                </button>
                            </mat-card-actions>
                        </form>
                    </mat-card>
                </div>
                <div class="side-list">
                    <h2>Final Specification of Repair</h2>
                    <ul>
                        <li
                            *ngIf="
                                propertyDetails.Status ==
                                    'Pre Closing Completed' ||
                                propertyDetails.Status ==
                                    'Final Draw Completed' ||
                                propertyDetails.Status ==
                                    'Draw request started' ||
                                propertyDetails.Status == 'Post closing started'
                            "
                        >
                            <div class="ngcontainer">
                                <a
                                    href="javascript:void(0);"
                                    *ngIf="
                                        sorDetails.FinalMailSendDate != '' &&
                                        sorDetails.FinalMailSendDate != null
                                    "
                                    (click)="printFinalSOR(propertyDetails.Id)"
                                    data="19890"
                                    class="agrmnt-signed-by-bo"
                                    >View Final Specification of Repair</a
                                >
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="side-list">
                    <h2>Change Order Form</h2>
                    <ul>
                        <li>
                            <div class="ngcontainer">
                                <div
                                    class="links-to-pdf-and-edit"
                                    style="margin: 0px 0 5px 0"
                                >
                                    <span>
                                        <a
                                            href="javascript:void(0);"
                                            (click)="
                                                printPdf('assets/pdf/92577.pdf')
                                            "
                                            style="margin: 0 0 0 10px"
                                            >Print PDF
                                        </a>
                                    </span>
                                    <br />
                                </div>
                            </div>
                        </li>
                    </ul>

                    <label class="uploadFormLabel"
                        >Upload Draw & Change Order forms
                    </label>
                    <br />
                    <p
                        *ngFor="
                            let DocumentDetails of propertyDrawDocumentList;
                            let i = index
                        "
                        style="clear: both; padding-left: 50px"
                    >
                        <a
                            *ngIf="DocumentDetails.Title != null"
                            (click)="
                                downloadPropertyDoc(DocumentDetails.FilePath)
                            "
                            >{{ DocumentDetails.Title }}</a
                        >
                        <a
                            *ngIf="DocumentDetails.Title == null"
                            (click)="
                                downloadPropertyDoc(DocumentDetails.FilePath)
                            "
                            >Download Document {{ i + 1 }}</a
                        >
                    </p>
                </div>
            </div>
        </mat-tab>
    </mat-tab-group>

    <div *ngIf="userDetail.UserType == 'Borrower'">
        <mat-tab-group
            mat-align-tabs="start"
            [(selectedIndex)]="selectedBorrowerIndex"
            style="clear: both"
            class="borrower-property-detail"
        >
            <mat-tab [disabled]="!boTab_0.Active">
                <ng-template mat-tab-label>
                    <div
                        *ngIf="boTab_0.tabStatus == 'Active'"
                        class="mainmenu_tabContent step_1 status_active"
                    >
                        <span class="labelnumbering">1</span>
                        <span class="labelText">Introduction</span>
                    </div>
                    <div
                        *ngIf="boTab_0.tabStatus == 'Completed'"
                        class="mainmenu_tabContent step_1 status_completed"
                    >
                        <span class="labelnumbering">1</span>
                        <span class="labelText">Introduction</span>
                    </div>
                    <div
                        *ngIf="boTab_0.tabStatus == 'Pending'"
                        class="mainmenu_tabContent step_1 status_pending"
                    >
                        <span class="labelnumbering">1</span>
                        <span class="labelText">Introduction</span>
                    </div>
                </ng-template>

                <mat-card (scroll)="onScroll($event)">
                    <h3 #headerTopSection id="headerTopSection">
                        Renovation Loan Process
                    </h3>
                    <div class="introTabHeaderLists">
                        <div class="introTabHeaderListsContent listsection_1">
                            <div
                                class="introTabHeaderIconsContainer"
                                (click)="scrollSmooth('section1')"
                            >
                                <img
                                    src="assets/icons/pngIcon/calanderIcon.png"
                                    matTooltip="Meet Your Renovation Consultant"
                                    style="margin-top: 0px"
                                />
                            </div>
                            <div class="introTabHeaderContentContainer">
                                <p>
                                    Meet the consultant at the property being
                                    renovated to discuss your project’s scope of
                                    work to include your desired improvements
                                    along with any required HUD or Fannie Mae
                                    work.
                                </p>
                            </div>
                            <div class="introTabHeaderArrowContainer">
                                <span
                                    class="contentSectionWithArrow"
                                    (click)="scrollSmooth('section1')"
                                    >Meet Your Renovation<br />Consultant</span
                                >
                            </div>
                        </div>
                        <div class="introTabHeaderListsContent listsection_2">
                            <div
                                class="introTabHeaderIconsContainer"
                                (click)="scrollSmooth('section2')"
                            >
                                <img
                                    src="assets/icons/pngIcon/mapIcon.png"
                                    matTooltip="Add A Contractor"
                                    style="margin-top: 0px"
                                />
                            </div>
                            <div class="introTabHeaderContentContainer">
                                <p>
                                    Find a contractor who is willing to provide
                                    an estimate for the project. The contractor
                                    needs to be licensed and insured. Lender
                                    approves the contractor. Contractor should
                                    be made aware of the 203k or Homestyle
                                    Renovation Loan requirements.
                                </p>
                            </div>
                            <div class="introTabHeaderArrowContainer">
                                <span
                                    class="contentSectionWithArrow"
                                    (click)="scrollSmooth('section2')"
                                    >Add A Contractor</span
                                >
                            </div>
                        </div>
                        <div class="introTabHeaderListsContent listsection_3">
                            <div
                                class="introTabHeaderIconsContainer"
                                (click)="scrollSmooth('section3')"
                            >
                                <img
                                    src="assets/icons/pngIcon/fileSearchIcon.png"
                                    matTooltip="Review Your Project Scope of Work"
                                    style="margin-top: 0px"
                                />
                            </div>
                            <div class="introTabHeaderContentContainer">
                                <p>
                                    After the site visit the consultant will
                                    prepare a renovation report for your review
                                    detailing the scope of work discussed. This
                                    report will include the consultant’s
                                    pricing, as required by the lender. These
                                    costs will NOT be used to finance your loan.
                                </p>
                            </div>
                            <div class="introTabHeaderArrowContainer">
                                <span
                                    class="contentSectionWithArrow"
                                    (click)="scrollSmooth('section3')"
                                    >Review Your Project<br />Scope of
                                    Work</span
                                >
                            </div>
                        </div>
                        <div class="introTabHeaderListsContent listsection_4">
                            <div
                                class="introTabHeaderIconsContainer"
                                (click)="scrollSmooth('section4')"
                            >
                                <img
                                    src="assets/icons/pngIcon/fileListIcon.png"
                                    matTooltip="Accept Your Project Estimate"
                                    style="margin-top: 0px"
                                />
                            </div>
                            <div class="introTabHeaderContentContainer">
                                <p>
                                    The contractor will have access to our
                                    portal where they will be able to submit
                                    their estimate using the scope of work
                                    prepared by the consultant. This estimate
                                    once accepted by you will be reviewed by the
                                    consultant.
                                </p>
                            </div>
                            <div class="introTabHeaderArrowContainer">
                                <span
                                    class="contentSectionWithArrow"
                                    (click)="scrollSmooth('section4')"
                                    >Accept Your Project<br />Estimate</span
                                >
                            </div>
                        </div>
                        <div class="introTabHeaderListsContent listsection_5">
                            <div
                                class="introTabHeaderIconsContainer"
                                (click)="scrollSmooth('section5')"
                            >
                                <img
                                    src="assets/icons/pngIcon/handshakeIcon.png"
                                    matTooltip="Final Renovation Report"
                                    style="margin-top: 0px"
                                />
                            </div>
                            <div class="introTabHeaderContentContainer">
                                <p>
                                    After the consultant reviews your accepted
                                    contractor’s estimate, they will prepare a
                                    Final Specification of Repair Renovation
                                    Report which your lender will need to close
                                    on your loan. This report will be based on
                                    the contractor’s accepted estimate.
                                </p>
                            </div>
                            <div class="introTabHeaderArrowContainer">
                                <span
                                    class="contentSectionWithArrow"
                                    (click)="scrollSmooth('section5')"
                                    >Final Renovation Report</span
                                >
                            </div>
                        </div>
                        <div class="introTabHeaderListsContent listsection_6">
                            <div
                                class="introTabHeaderIconsContainer"
                                (click)="scrollSmooth('section6')"
                            >
                                <img
                                    src="assets/icons/pngIcon/currencyIcon.png"
                                    matTooltip="Start Your Construction Project"
                                    style="margin-top: 0px"
                                />
                            </div>
                            <div class="introTabHeaderContentContainer">
                                <p>
                                    There is limited upfront money for your
                                    contractor to start the project. Every 30
                                    days or less you or your contractor will
                                    request a draw for the consultant to release
                                    funds. Funds will be released for work that
                                    has been performed when the consultant
                                    visits the site.
                                </p>
                            </div>
                            <div class="introTabHeaderArrowContainer">
                                <span
                                    class="contentSectionWithArrow"
                                    (click)="scrollSmooth('section6')"
                                    >Start Your Construction<br />Project
                                </span>
                            </div>
                        </div>
                    </div>
                    <h3>Welcome to A.M. Renovation Consulting Xchange!</h3>

                    <p>
                        Please take a moment to review the information below as
                        it will be important to have a successful renovation
                        process!!
                    </p>
                    <p>
                        A.M. Renovation Consulting has been selected by your
                        lender as the renovation consultant to assist you in
                        establishing your project’s scope of work!
                    </p>
                    <p>
                        This platform is going to walk you through the
                        renovation consulting process from the initial meeting
                        with your consultant to releasing funds for your
                        construction project. You will see at the top of the
                        screen there are numbered steps (tabs) that you will
                        complete throughout this process. You will always have
                        the ability to go back to completed tabs or this
                        introduction tab to learn more about the renovation loan
                        process.
                    </p>
                    <ul class="welcomeTabwelcomeSection_ul">
                        <li>
                            <span class="activeStepCircle">#</span> Active Step
                        </li>
                        <li>
                            <span class="completeStepCircle">#</span> Completed
                            Step
                        </li>
                        <li>
                            <span class="notCompleteStepCircle">#</span> Not Yet
                            Completed Step
                        </li>
                        <li>
                            <span class="pendingStepCircle">#</span> Pending
                            Step
                        </li>
                    </ul>
                    <p>
                        You will be receiving email correspondence throughout
                        this entire renovation process, so it is important that
                        you view any and all emails from:
                    </p>
                    <h4>
                        <a
                            class="welcomeTabwelcomeSectionMailLink"
                            href="mailto:admin@renovationexchange.net"
                            >admin@renovationexchange.net</a
                        >
                    </h4>
                    <p>
                        Within this platform you will be able to review
                        documents, accept documents, add contractors, and
                        request funds. As these actions are completed, documents
                        will be sent via email for your signature, so it is
                        important to be routinely checking your email to help
                        streamline the renovation process.
                    </p>
                    <p>
                        Below is information you should review prior to meeting
                        with your consultant since it will help familiarize
                        yourself with the Renovation Loan Process. The process
                        is shown in the graphic above which can also be used to
                        navigate to the various sections.
                    </p>

                    <div #section1 id="section1" class="section_div">
                        <span
                            ><img
                                src="assets/icons/pngIcon/calanderIcon.png"
                                matTooltip="Meet Your Renovation Consultant"
                                style="margin-top: 0px"
                        /></span>
                        <p>Meet Your Renovation Consultant</p>
                    </div>
                    <p>
                        A.M. Renovation Consulting will be contacting you to
                        schedule a site visit. You are not required to reach out
                        to the consultant to schedule an inspection but need to
                        be available via email or telephone to schedule a time
                        that works for both of you.
                    </p>
                    <ul>
                        <li>
                            Please allow up to 2 hours to meet with your
                            consultant at the property.
                        </li>
                        <li>
                            Please bring payment to the site visit as it is
                            collected at the time of your meeting.
                        </li>
                        <li>
                            The consultant will identify any mandatory FHA or
                            Fannie Mae property requirements.
                        </li>
                        <li>
                            The consultant will discuss with you your desired
                            renovation project
                        </li>
                    </ul>
                    <p>
                        One week following the site visit with the renovation
                        Consultant a Draft Feasibility Specifications of Repair
                        Renovation Report (Draft Renovation Report) will be
                        submitted for your review and acceptance.
                    </p>
                    <p>An example report can be seen using the link below.</p>
                    <a href="javascrip:void(0)"
                        >VIEW A DRAFT FEASIBILITY SPECIFICATION OF REPAIR
                        RENOVATION REPORT</a
                    >
                    <p>
                        The report will capture the scope of the project
                        containing both the required and desired work in the
                        format required by the lender into 35 construction
                        components.
                    </p>
                    <ul>
                        <li>
                            The report will include the consultant’s pricing for
                            the construction components identified for the
                            project.
                        </li>
                        <li>
                            These costs will NOT be shared with your general
                            contractor, who will independently arrive at their
                            own costs for the project.
                        </li>
                        <li>
                            The Draft Renovation Report in no way represents a
                            home inspection, nor should it be used in lieu of
                            one. We are NOT home inspectors.
                        </li>
                    </ul>
                    <div #section2 id="section2" class="section_div">
                        <span
                            ><img
                                src="assets/icons/pngIcon/mapIcon.png"
                                matTooltip="Add A Contractor"
                                style="margin-top: 0px"
                        /></span>
                        <p>Add A Contractor</p>
                    </div>
                    <strong style="text-decoration: underline"
                        >You MUST hire a contractor to close on your renovation
                        loan.</strong
                    >
                    <p>
                        The consultant will send you the Draft Renovation Report
                        within 7 days from your site visit. The Draft Renovation
                        Report is the platform for the contractor(s) to input
                        their estimates online.
                    </p>
                    <p>
                        Do NOT provide the contractor(s) the Draft Renovation
                        Report. The Scope of Work form will be available to you
                        without the consultant pricing.
                    </p>
                    <p>
                        Pricing in the accepted Draft Specification of Repair
                        Report is never visible to any contractors.
                    </p>
                    <p style="text-decoration: underline">
                        Steps for you to take:
                    </p>
                    <ol>
                        <li>
                            Find a contractor(s) interested in submitting
                            estimate for your project.
                        </li>
                        <li>
                            Inform all interested contractors that there is
                            LIMITED upfront money provided from your lender
                            (please check with your lender what they may allow).
                        </li>
                        <li>
                            Bring a copy of the Scope of Work form when meeting
                            with a contractor so they can understand the format
                            of the estimate they will be submitting.
                        </li>
                        <li>
                            Add the interested contractors to your Renovation
                            Exchange portal with an option to add multiple
                            contractors.
                        </li>
                    </ol>

                    <p style="text-decoration: underline">
                        Contractors added to your portal:
                    </p>
                    <ul>
                        <li>
                            Given access to their own A.M. Renovation Exchange
                            portal.
                        </li>
                        <li>
                            Receive automatic notifications throughout the
                            renovation process.
                        </li>
                        <li>Input and submit project estimates.</li>
                        <li>
                            No access to view competing contractor estimates
                        </li>
                    </ul>

                    <p style="text-decoration: underline">
                        A.M. Renovation Consulting does
                        <strong>NOT</strong> provide:
                    </p>
                    <ul>
                        <li>contractor referrals,</li>
                        <li>contractor evaluations,</li>
                        <li>contractor feedback,</li>
                        <li>contractor negotiations</li>
                        <li>handle contractor disputes.</li>
                    </ul>

                    <p>
                        A.M. Renovation Consulting is not affiliated with any
                        contractor and does not endorse any contractor or their
                        services. A.M. Renovation Consulting NEVER acts as a
                        project manager. <br />
                        A.M. Renovation Consulting NEVER performs home
                        inspections.
                    </p>

                    <div #section3 id="section3" class="section_div">
                        <span
                            ><img
                                src="assets/icons/pngIcon/fileSearchIcon.png"
                                matTooltip="Review Your Draft Feasibility Report"
                                style="margin-top: 0px"
                        /></span>
                        <p>Review Your Draft Feasibility Report</p>
                    </div>

                    <p>
                        After meeting your consultant on the project site, a
                        Draft Renovation Report will be prepared by the
                        consultant which you will receive within 7 business
                        days. The Draft Renovation Report will:
                    </p>

                    <ul>
                        <li>
                            Captures the scope of the project in a format
                            required by your lender.
                        </li>
                        <li>
                            Incorporates both your desired and required
                            renovations identified by the consultant for your
                            203k or Homestyle renovation loan.
                        </li>
                        <li>
                            Breaks down the project into 35 construction
                            components and includes a description of the work.
                        </li>
                    </ul>

                    <p>
                        When reviewing the Draft Renovation Report provided by
                        your consultant please keep in mind that;
                    </p>
                    <ul>
                        <li>
                            Changes to your scope of work should
                            <strong style="text-decoration: underline"
                                >NOT</strong
                            >
                            be based on the consultant's pricing.
                        </li>
                        <li>
                            Pricing provided by your consultant is required by
                            the lender and WILL NOT be used for financing your
                            project.
                        </li>
                        <li>
                            If you agree with the information presented accept
                            the report. If not, submit revisions to the
                            Consultant.
                        </li>
                        <li>
                            To accept the report, you will be required to add a
                            contractor.
                        </li>
                    </ul>
                    <p>
                        The accepted report will be in the format that will be
                        used by the contractor(s) when submitting their
                        estimates. The contractor does
                        <strong style="text-decoration: underline">NOT</strong>
                        see the consultant’s pricing and this information should
                        not be shared with them.
                    </p>

                    <div #section4 id="section4" class="section_div">
                        <span
                            ><img
                                src="assets/icons/pngIcon/fileListIcon.png"
                                matTooltip="Review Contractor Estimates"
                                style="margin-top: 0px"
                        /></span>
                        <p>Review Contractor Estimates</p>
                    </div>
                    <p>
                        You have now reached the point where the contractor(s)
                        has been invited by you to submit an estimate on the
                        scope of work as discussed by you and the consultant.
                        The accepted Draft Renovation Report will be used as the
                        basis for their estimate. Please note that;
                    </p>
                    <ul>
                        <li>
                            The contractor is required to provide their own
                            pricing for the project.
                        </li>
                        <li>
                            The online estimate should only be completed by the
                            general contractor and should not be inputted by
                            individual subcontractors (plumbers, electricians,
                            etc.).
                        </li>
                        <li>
                            Your contractor’s estimate will be in the same
                            format used for the Draft Renovation Report.
                        </li>
                        <li>
                            If you have added numerous contractors to submit an
                            estimate you will be able to make a side-by-side
                            comparison of their costs when you review.
                        </li>
                        <li>
                            The contractor has the ability to add construction
                            items to the scope of work or remove
                            <span
                                style="
                                    font-weight: bold;
                                    text-decoration: underline;
                                "
                                >non mandatory</span
                            >
                            items from the scope of work if that was what was
                            discussed between you and the contractor.
                        </li>
                        <li>
                            The consultant
                            <span
                                style="
                                    font-weight: bold;
                                    text-decoration: underline;
                                "
                                >DOES NOT</span
                            >
                            negotiate contractor’s pricing.
                        </li>
                    </ul>
                    <p>
                        Occasionally subcontractors (septic installer or mold
                        remediation, etc.) not working under the direction or
                        control of the general contractor are included as part
                        of your renovation. Their costs
                        <span
                            style="
                                font-weight: bold;
                                text-decoration: underline;
                            "
                            >MUST</span
                        >
                        be included within the contractor’s estimate. If you
                        notice a part of the scope of work is not included
                        within the estimate, then please submit a revision to
                        your contractor. Although you may have multiple
                        subcontractors (most lenders allow max of 3) approved by
                        the lender there is only one online estimate which
                        results in one renovation report for your closing.
                    </p>
                    <p>
                        Review and accept the estimate on this platform if you
                        feel it includes the desired scope of work for your
                        renovation project. The accepted estimate will be;
                    </p>
                    <ul>
                        <li>
                            The cost and the scope of the project which will be
                            financed by your lender.
                        </li>
                        <li>
                            Reviewed by the consultant to ensure that the scope
                            of work was properly presented in the required
                            format.
                        </li>
                    </ul>
                    <p>
                        When the consultant has reviewed and accepted the
                        contractor’s estimate, they will prepare a final
                        renovation report also known as the Final Specification
                        of Repair Renovation Report. This report is required to
                        close on your renovation loan.
                    </p>

                    <div #section5 id="section5" class="section_div">
                        <span
                            ><img
                                src="assets/icons/pngIcon/handshakeIcon.png"
                                matTooltip="Final Renovation Report"
                                style="margin-top: 0px"
                        /></span>
                        <p>Final Renovation Report</p>
                    </div>
                    <p>
                        Your consultant will review your accepted estimate to
                        ensure the scope of work is properly presented in the
                        required format. The consultant will then produce a
                        Final Renovation Report (Final Specification of Repair
                        Report).
                    </p>
                    <ul>
                        <li>
                            The report will be submitted and utilized by the
                            lender to finance your renovation project.
                        </li>
                        <li>
                            The lender will provide the report to the appraiser
                            in order to establish a valuation of the property
                            based on the scope of work.
                        </li>
                    </ul>
                    <p>
                        Contact your lender for additional information to close
                        on your loan.
                    </p>
                    <strong
                        >CHECK WITH YOUR LOAN OFFICER TO SEE IF THEY ALLOW A
                        MATERIAL DRAW PRIOR TO STARTING YOUR PROJECT!</strong
                    >
                    <p>
                        To prepare for starting your renovation project, review
                        some of the information that you will need after you
                        close and when the draw process begins. Your lender will
                        assign a Draw Administrator who will be the point of
                        contact throughout the Draw Process.
                    </p>
                    <p>
                        Below is an overview of the draw process and how you
                        request construction funds during your project.
                    </p>
                    <ul>
                        <li>
                            Lender requires
                            <strong style="text-decoration: underline"
                                >ALL</strong
                            >
                            permits be pulled prior to scheduling the first
                            draw.
                        </li>
                        <li>
                            Check with your lender if they allow a material draw
                            prior to starting your project.
                        </li>
                        <li>
                            Check with your lender on what components they may
                            allow deposits - cabinets, windows, etc.
                        </li>
                        <li>
                            Your lender requires construction draw site visits
                            <strong style="text-decoration: underline"
                                >EVERY 30 DAYS OR LESS.</strong
                            >
                        </li>
                        <li>
                            Draw checks are processed by lenders and typically
                            arrive in 3-7 days (confirm with your lender).
                            Checks are typically two party (borrower and
                            contractor) and delivered to the borrower. Please
                            confirm with the lender.
                        </li>
                        <li>
                            There is a 10% holdback on funds drawn. This
                            holdback is released a few weeks after the final
                            draw site visit.
                        </li>
                        <li>
                            You and/or your contractor will request funds based
                            on the portion of work that has been done in
                            accordance with the Final Renovation Report.
                        </li>
                        <li>
                            You should always meet your contractor onsite to
                            review work on funds requested prior to consultant’s
                            visit.
                        </li>
                        <li>
                            When a Draw Request is submitted, both you and your
                            contractor will be required to accept the draw prior
                            to the Consultant visiting the site.
                        </li>
                        <li>
                            The consultant will schedule a property visit to
                            verify the work listed on the Draw Request has been
                            completed.
                        </li>
                        <li>
                            Draw Request funds are only released for completed
                            work at time of consultant’s property visit. Any
                            work listed on the draw that is not completed will
                            be adjusted accordingly by the consultant.
                        </li>
                    </ul>

                    <div #section6 id="section6" class="section_div">
                        <span
                            ><img
                                src="assets/icons/pngIcon/currencyIcon.png"
                                matTooltip="Start Construction Project"
                                style="margin-top: 0px"
                        /></span>
                        <p>Start Construction Project</p>
                    </div>
                    <p>Congratulation on closing your renovation loan!</p>
                    <p>
                        It is time to start your construction project. The Final
                        Specification of Repair Report that was used as the
                        scope of work to finance your loan will be utilized
                        throughout the construction project. Please review the
                        following information as it will be important throughout
                        the project.
                    </p>

                    <ul>
                        <li>
                            <span
                                style="
                                    font-weight: bold;
                                    text-decoration: underline;
                                "
                                >ALL</span
                            >
                            permits must be pulled prior to scheduling the first
                            draw request.
                        </li>
                        <li>
                            Lender holds the construction funds in escrow for
                            the duration of the project.
                        </li>
                        <li>
                            Check with your lender if they allow a material draw
                            prior to starting your project
                        </li>
                        <li>
                            Contact your lender on what components they may
                            allow deposits - cabinets, windows, etc.
                        </li>
                        <li>
                            Your lender requires construction draw site visits
                            <span
                                style="
                                    font-weight: bold;
                                    text-decoration: underline;
                                "
                                >EVERY 30 DAYS OR LESS</span
                            >
                        </li>
                        <li>
                            Checks for construction draws are processed by
                            lenders and typically arrive in 3-7 days (confirm
                            with your lender). Checks are typically two party
                            (borrower and contractor) and delivered to the
                            borrower.
                            <span style="font-weight: bold"
                                >Please confirm with your lender.</span
                            >
                        </li>
                        <li>
                            There is a 10% holdback for every construction draw.
                            This holdback is released a few weeks after the
                            final site visit.
                        </li>
                        <li>
                            You and/or your contractor will request funds based
                            on the portion of work that has been done in
                            accordance with the Final Renovation Report.
                        </li>
                        <li>
                            The consultant will schedule a property visit to
                            verify and photograph the work listed on the Draw
                            Request.
                        </li>
                        <li>
                            Prior to the consultant's site visit you should
                            always meet your contractor onsite to review work on
                            funds requested
                        </li>
                        <li>
                            Draw Requests funds are only released for completed
                            work at time of consultant’s property site visit.
                        </li>
                        <li>
                            Draw Request forms require signatures from all
                            parties in order to be processed by the lender.
                            Borrowers will be asked to sign first, then
                            contractor and lastly consultant.
                        </li>
                        <li>
                            A draw should never be signed unless the borrower is
                            satisfied with the work completed. Signing of the
                            draw is acceptance of the work.
                        </li>
                        <li>
                            As previously stated A.M. Renovation Consulting
                            <span
                                style="
                                    font-weight: bold;
                                    text-decoration: underline;
                                "
                                >DOES NOT</span
                            >
                            act as a project manager.
                        </li>
                    </ul>
                    <a
                        href="javascript:void(0);"
                        (click)="goBackToTop('headerTopSection')"
                        class="goToTopBtn"
                        >&#8682;</a
                    >
                    <a
                        href="javascript:void(0);"
                        (click)="markActiveBorrowerStep(2)"
                        class="letsStartedBtn"
                        >Let's Get Started!</a
                    ><br />
                </mat-card>
            </mat-tab>

            <mat-tab [disabled]="!boTab_1.Active">
                <ng-template mat-tab-label>
                    <div
                        *ngIf="boTab_1.tabStatus == 'Active'"
                        class="mainmenu_tabContent step_2 status_active"
                    >
                        <span class="labelnumbering">2</span>
                        <span class="labelText">Meet Your Consultant</span>
                    </div>
                    <div
                        *ngIf="boTab_1.tabStatus == 'Completed'"
                        class="mainmenu_tabContent step_2 status_completed"
                    >
                        <span class="labelnumbering">2</span>
                        <span class="labelText">Meet Your Consultant</span>
                    </div>
                    <div
                        *ngIf="boTab_1.tabStatus == 'Pending'"
                        class="mainmenu_tabContent step_2 status_pending"
                    >
                        <span class="labelnumbering">2</span>
                        <span class="labelText">Meet Your Consultant</span>
                    </div>
                    <div
                        *ngIf="boTab_1.tabStatus == 'Not Completed'"
                        class="mainmenu_tabContent step_2 status_notCompleted"
                    >
                        <span class="labelnumbering">2</span>
                        <span class="labelText">Meet Your Consultant</span>
                    </div>
                </ng-template>

                <mat-card>
                    <h3>Meet Your Renovation Consultant</h3>
                    <p>
                        A.M. Renovation Consulting will be contacting you to
                        schedule a site visit. You are not required to reach out
                        to the consultant to schedule an inspection but need to
                        be available via email or telephone to schedule a time
                        that works for both of you.
                    </p>
                    <ul>
                        <li>
                            Please allow up to 2 hours to meet with your
                            consultant at the property.
                        </li>
                        <li>
                            Please bring payment to the site visit as it is
                            collected at the time of your meeting.
                        </li>
                        <li>
                            The consultant will identify any mandatory FHA or
                            Fannie Mae property requirements.
                        </li>
                        <li>
                            The consultant will discuss with you your desired
                            renovation project
                        </li>
                    </ul>
                    <p>
                        One week following the site visit with the renovation
                        Consultant a Draft Feasibility Specifications of Repair
                        Renovation Report (Draft Renovation Report) will be
                        submitted for your review and acceptance.
                    </p>
                    <p>An example report can be seen using the link below.</p>
                    <a href="javascrip:void(0)"
                        >VIEW A DRAFT FEASIBILITY SPECIFICATION OF REPAIR
                        RENOVATION REPORT</a
                    >
                    <p>
                        The report will capture the scope of the project
                        containing both the required and desired work in the
                        format required by the lender into 35 construction
                        components.
                    </p>
                    <ul>
                        <li>
                            The report will include the consultant’s pricing for
                            the construction components identified for the
                            project.
                        </li>
                        <li>
                            These costs will NOT be shared with your general
                            contractor, who will independently arrive at their
                            own costs for the project.
                        </li>
                        <li>
                            The Draft Renovation Report in no way represents a
                            home inspection, nor should it be used in lieu of
                            one. We are NOT home inspectors.
                        </li>
                    </ul>
                    <br /><strong
                        >If you have met your consultant and would like to
                        complete this step use the button below.</strong
                    >
                    <br /><br /><a
                        href="javascript:void(0);"
                        (click)="markActiveBorrowerStep(3)"
                        class="markCompletedBtn"
                        >I Have Met My Consultant</a
                    >
                    <a
                        href="javascript:void(0);"
                        (click)="markActiveBorrowerStep(3)"
                        class="letsStartedBtn"
                        >Add a Contractor!</a
                    >
                </mat-card>
            </mat-tab>

            <mat-tab [disabled]="!boTab_2.Active">
                <ng-template mat-tab-label>
                    <div
                        *ngIf="boTab_2.tabStatus == 'Active'"
                        class="mainmenu_tabContent step_3 status_active"
                    >
                        <span class="labelnumbering">3</span>
                        <span class="labelText">Add a Contractor</span>
                    </div>
                    <div
                        *ngIf="boTab_2.tabStatus == 'Completed'"
                        class="mainmenu_tabContent step_3 status_completed"
                    >
                        <span class="labelnumbering">3</span>
                        <span class="labelText">Add a Contractor</span>
                    </div>
                    <div
                        *ngIf="boTab_2.tabStatus == 'Pending'"
                        class="mainmenu_tabContent step_3 status_pending"
                    >
                        <span class="labelnumbering">3</span>
                        <span class="labelText">Add a Contractor</span>
                    </div>
                    <div
                        *ngIf="boTab_2.tabStatus == 'Not Completed'"
                        class="mainmenu_tabContent step_3 status_notCompleted"
                    >
                        <span class="labelnumbering">3</span>
                        <span class="labelText">Add a Contractor</span>
                    </div>
                </ng-template>

                <mat-card>
                    <h3>Add A Contractor!</h3>
                    <div class="addGCleftSection">
                        <strong style="text-decoration: underline"
                            >You MUST hire a contractor to close on your
                            renovation loan.</strong
                        >
                        <p>
                            The consultant will send you the Draft Renovation
                            Report within 7 days from your site visit. The Draft
                            Renovation Report is the platform for the
                            contractor(s) to input their estimates online.
                        </p>
                        <p>
                            Do NOT provide the contractor(s) the Draft
                            Renovation Report. The Scope of Work form will be
                            available to you without the consultant pricing.
                        </p>
                        <p>
                            Pricing in the accepted Draft Specification of
                            Repair Report is never visible to any contractors.
                        </p>
                        <p style="text-decoration: underline">
                            Steps for you to take:
                        </p>
                        <ol>
                            <li>
                                Find a contractor(s) interested in submitting
                                estimate for your project.
                            </li>
                            <li>
                                Inform all interested contractors that there is
                                LIMITED upfront money provided from your lender
                                (please check with your lender what they may
                                allow).
                            </li>
                            <li>
                                Bring a copy of the Scope of Work form when
                                meeting with a contractor so they can understand
                                the format of the estimate they will be
                                submitting.
                            </li>
                            <li>
                                Add the interested contractors to your
                                Renovation Exchange portal with an option to add
                                multiple contractors.
                            </li>
                        </ol>

                        <p style="text-decoration: underline">
                            Contractors added to your portal:
                        </p>
                        <ul>
                            <li>
                                Given access to their own A.M. Renovation
                                Exchange portal.
                            </li>
                            <li>
                                Receive automatic notifications throughout the
                                renovation process.
                            </li>
                            <li>Input and submit project estimates.</li>
                            <li>
                                No access to view competing contractor estimates
                            </li>
                        </ul>

                        <p style="text-decoration: underline">
                            A.M. Renovation Consulting does
                            <Strong>NOT</Strong> provide:
                        </p>
                        <ul>
                            <li>contractor referrals,</li>
                            <li>contractor evaluations,</li>
                            <li>contractor feedback,</li>
                            <li>contractor negotiations</li>
                            <li>handle contractor disputes.</li>
                        </ul>

                        <p>
                            A.M. Renovation Consulting is not affiliated with
                            any contractor and does not endorse any contractor
                            or their services. A.M. Renovation Consulting NEVER
                            acts as a project manager. A.M. Renovation
                            Consulting NEVER performs home inspections.
                        </p>
                    </div>
                    <div class="addGCrightSection">
                        <a
                            href="javascript:void(0);"
                            routerLink="/general-contractor-info/{{
                                propertyDetails.Id
                            }}"
                            class="addContractorBtn"
                            >Add A Contractor</a
                        >
                        <a
                            href="javascript:void(0);"
                            *ngIf="
                                propertyDetails.Status !=
                                'Property File created'
                            "
                            (click)="markActiveBorrowerStep(4)"
                            class="reviewDraftBtn"
                            >Review Draft Report</a
                        ><br />

                        <strong style="float: left; clear: both"
                            >Contractors Added to Bidding Process</strong
                        >

                        <table
                            class="gcListTable"
                            mat-table
                            [dataSource]="dataSource"
                            matSort
                        >
                            <ng-container matColumnDef="FirstName">
                                <th
                                    mat-header-cell
                                    *matHeaderCellDef
                                    mat-sort-header
                                >
                                    Contractor Name
                                </th>
                                <td mat-cell *matCellDef="let row">
                                    {{ row.FirstName }} {{ row.LastName }}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="email">
                                <th
                                    mat-header-cell
                                    *matHeaderCellDef
                                    mat-sort-header
                                >
                                    Email Address
                                </th>
                                <td mat-cell *matCellDef="let row">
                                    {{ row.EmailId }}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="phone">
                                <th
                                    mat-header-cell
                                    *matHeaderCellDef
                                    mat-sort-header
                                >
                                    Phone Number
                                </th>
                                <td mat-cell *matCellDef="let row">
                                    {{ row.Phone }}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="lstLogin">
                                <th
                                    mat-header-cell
                                    *matHeaderCellDef
                                    mat-sort-header
                                >
                                    &nbsp;
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let row"
                                    [style.color]="row.lastLogin"
                                >
                                    <a
                                        *ngIf="
                                            propertyDetails.Status !=
                                                'Pre Closing Completed' &&
                                            propertyDetails.Status !=
                                                'Final Draw Completed' &&
                                            propertyDetails.Status !=
                                                'Draw request started' &&
                                            propertyDetails.Status !=
                                                'Post closing started'
                                        "
                                        (click)="deleteUser(row.Id)"
                                        href="javascript:void(0)"
                                        class="removeGCLink"
                                        ><span class="crossBtn">X</span></a
                                    >
                                </td>
                            </ng-container>

                            <tr
                                mat-header-row
                                *matHeaderRowDef="displayedColumns1"
                            ></tr>
                            <tr
                                mat-row
                                *matRowDef="let row; columns: displayedColumns1"
                                style="cursor: pointer"
                            ></tr>
                        </table>
                        <p
                            class="nullInvitedGC"
                            *ngIf="
                                propertyDetails.InvitedGeneralContractors ==
                                    null ||
                                propertyDetails.InvitedGeneralContractors ==
                                    '' ||
                                propertyDetails.InvitedGeneralContractors ==
                                    undefined
                            "
                        >
                            No Contractors Listed
                        </p>
                    </div>
                </mat-card>
            </mat-tab>

            <mat-tab [disabled]="!boTab_3.Active">
                <ng-template mat-tab-label>
                    <div
                        *ngIf="boTab_3.tabStatus == 'Active'"
                        class="mainmenu_tabContent step_4 status_active"
                    >
                        <span class="labelnumbering">4</span>
                        <span class="labelText">Review Draft Report </span>
                    </div>

                    <div
                        *ngIf="boTab_3.tabStatus == 'Not Completed'"
                        class="mainmenu_tabContent step_4 status_notCompleted"
                    >
                        <span class="labelnumbering">4</span>
                        <span class="labelText">Review Draft Report </span>
                    </div>

                    <div
                        *ngIf="boTab_3.tabStatus == 'Completed'"
                        class="mainmenu_tabContent step_4 status_completed"
                    >
                        <span class="labelnumbering">4</span>
                        <span class="labelText">Review Draft Report </span>
                    </div>

                    <div
                        *ngIf="boTab_3.tabStatus == 'Pending'"
                        class="mainmenu_tabContent step_4 status_pending"
                    >
                        <span class="labelnumbering">4</span>
                        <span class="labelText">Review Draft Report </span>
                    </div>
                </ng-template>

                <mat-card>
                    <h3>Review Your Draft Specification of Repair Report</h3>

                    <p>
                        After meeting your consultant on the project site, the
                        Draft Renovation Report (Draft Specification of Repair
                        Report ) will be prepared by the consultant which you
                        will receive within 7 business days. The Draft
                        Renovation Report will:
                    </p>

                    <ul>
                        <li>
                            Capture the scope of the project in a format
                            required by your lender.
                        </li>
                        <li>
                            Incorporate both your desired and required
                            renovations identified by the consultant for your
                            203k or Homestyle renovation loan.
                        </li>
                        <li>
                            Break down the project into 35 construction
                            components and includes a description of the work.
                        </li>
                    </ul>

                    <p>
                        When reviewing the Draft Renovation Report provided by
                        your consultant please keep in mind that:
                    </p>
                    <ul>
                        <li>
                            Changes to your scope of work should
                            <strong style="text-decoration: underline"
                                >NOT</strong
                            >
                            be based on the consultant's pricing.
                        </li>
                        <li>
                            Pricing provided by your consultant is required by
                            the lender and WILL NOT be used for financing your
                            project.
                        </li>
                        <li>
                            If you agree with the information presented accept
                            the report. If not, submit revisions to the
                            Consultant.
                        </li>
                        <li>
                            To accept the report, you will be required to add a
                            contractor.
                        </li>
                    </ul>
                    <p>
                        The accepted report will be in the format that will be
                        used by the contractor(s) when submitting their
                        estimates. The contractor does
                        <strong style="text-decoration: underline">NOT</strong>
                        see the consultant’s pricing and this information should
                        not be shared with them.
                    </p>
                    <br /><a
                        href="javascript:void(0);"
                        routerLink="/specification-repair-review/{{
                            propertyDetails.Id
                        }}"
                        *ngIf="
                            propertyDetails.Status ==
                                'DRAFT SOR Sent to Borrower' ||
                            propertyDetails.Status == 'Revise'
                        "
                        class="addContractorBtn"
                        >Review / Accept Draft Report</a
                    >
                    <br />
                    <a
                        style="float: left; clear: both"
                        (click)="GetSORPDF()"
                        href="javascript:void(0);"
                        *ngIf="
                            propertyDetails.Status != 'Property File created' &&
                            propertyDetails.Status !=
                                'DRAFT SOR Sent to Borrower' &&
                            propertyDetails.Status != 'Revise'
                        "
                        >ACCEPTED DRAFT SPECIFICATION REPORT</a
                    >
                    <br />
                    <p>
                        If you have not already met the contractor and plan to
                        meet with them to discuss the project, print the below
                        document which will be the template used for their
                        estimate. This document is considered a Scope of Work
                        Form which has the consultant's construction
                        descriptions but excludes their pricing. Remember, there
                        is no need to show your contractor the consultant’s
                        estimates.
                    </p>
                    <a
                        href="javascript:void(0)"
                        (click)="GetBlankBORPdf(propertyDetails.Id)"
                        >SCOPE OF WORK FORM</a
                    >
                    <p
                        *ngIf="
                            propertyDetails.Status != 'Property File created' &&
                            propertyDetails.Status !=
                                'DRAFT SOR Sent to Borrower' &&
                            propertyDetails.Status != 'Revise'
                        "
                    >
                        The Draft Renovation Report that you have accepted will
                        now be used as the scope of work for your contractor.
                        There are no further actions on this platform for you at
                        this time. The contractor will be receiving an email
                        notification that will provide them login credentials to
                        a similar portal where they will be able to provide an
                        estimate using the accepted Draft Renovation Report as a
                        template.
                    </p>
                    <a
                        href="javascript:void(0);"
                        *ngIf="
                            propertyDetails.Status != 'Property File created' &&
                            propertyDetails.Status !=
                                'DRAFT SOR Sent to Borrower' &&
                            propertyDetails.Status != 'Revise'
                        "
                        (click)="markActiveBorrowerStep(5)"
                        class="letsStartedBtn"
                        >Review Contractor Estimates</a
                    >
                </mat-card>
            </mat-tab>

            <mat-tab [disabled]="!boTab_4.Active">
                <ng-template mat-tab-label>
                    <div
                        *ngIf="boTab_4.tabStatus == 'Active'"
                        class="mainmenu_tabContent step_5 status_active"
                    >
                        <span class="labelnumbering">5</span>
                        <span class="labelText">Review Estimates</span>
                    </div>
                    <div
                        *ngIf="boTab_4.tabStatus == 'Not Completed'"
                        class="mainmenu_tabContent step_5 status_notCompleted"
                    >
                        <span class="labelnumbering">5</span>
                        <span class="labelText">Review Estimates</span>
                    </div>
                    <div
                        *ngIf="boTab_4.tabStatus == 'Completed'"
                        class="mainmenu_tabContent step_5 status_completed"
                    >
                        <span class="labelnumbering">5</span>
                        <span class="labelText">Review Estimates</span>
                    </div>
                    <div
                        *ngIf="boTab_4.tabStatus == 'Pending'"
                        class="mainmenu_tabContent step_5 status_pending"
                    >
                        <span class="labelnumbering">5</span>
                        <span class="labelText">Review Estimates</span>
                    </div>
                </ng-template>

                <mat-card>
                    <h3>Review Your Contractor(s) Estimate</h3>
                    <p>
                        You have now reached the point where the contractor(s)
                        has been invited by you to submit an estimate on the
                        scope of work as discussed by you and the consultant.
                        The accepted Draft Renovation Report will be used as the
                        basis for their estimate. Please note that;
                    </p>
                    <ul>
                        <li>
                            The contractor is required to provide their own
                            pricing for the project.
                        </li>
                        <li>
                            The online estimate should only be completed by the
                            general contractor and should not be inputted by
                            individual subcontractors (plumbers, electricians,
                            etc.).
                        </li>
                        <li>
                            Your contractor’s estimate will be in the same
                            format used for the Draft Renovation Report.
                        </li>
                        <li>
                            If you have added numerous contractors to submit an
                            estimate you will be able to make a side-by-side
                            comparison of their costs when you review.
                        </li>
                        <li>
                            The contractor has the ability to add construction
                            items to the scope of work or remove
                            <span
                                style="
                                    font-weight: bold;
                                    text-decoration: underline;
                                "
                                >non mandatory</span
                            >
                            items as discussed between you and the contractor.
                        </li>
                        <li>
                            The consultant
                            <span
                                style="
                                    font-weight: bold;
                                    text-decoration: underline;
                                "
                                >DOES NOT</span
                            >
                            negotiate contractor’s pricing.
                        </li>
                    </ul>
                    <p>
                        Occasionally subcontractors (septic installer or mold
                        remediation, etc.) not working under the direction or
                        control of the general contractor are included as part
                        of your renovation. Their costs
                        <span
                            style="
                                font-weight: bold;
                                text-decoration: underline;
                            "
                            >MUST</span
                        >
                        be included within the contractor’s estimate. If you
                        notice a part of the scope of work is not included
                        within the estimate, then please submit a revision to
                        your contractor. Although you may have multiple
                        subcontractors (most lenders allow max of 3) approved by
                        the lender there is only one online estimate which
                        results in one renovation report for your closing.
                    </p>
                    <p>
                        Review and accept the estimate on this platform if you
                        feel it includes the desired scope of work for your
                        renovation project. The accepted estimate will be;
                    </p>
                    <ul>
                        <li>
                            The cost and the scope of the project which will be
                            financed by your lender.
                        </li>
                        <li>
                            Reviewed by the consultant to ensure that the scope
                            of work was properly presented in the required
                            format.
                        </li>
                    </ul>
                    <p>
                        When the consultant has reviewed and accepted the
                        contractor’s estimate, they then will prepare a final
                        renovation report also known as the Final Specification
                        of Repair Renovation Report. This report is required to
                        close on your renovation loan.
                    </p>
                    <a
                        *ngIf="
                            submittedBid > 0 &&
                            (propertyDetails.Status == 'Review Bid' ||
                                propertyDetails.Status ==
                                    'Bid Process Started' ||
                                propertyDetails.Status == 'Send For Review' ||
                                propertyDetails.Status ==
                                    'Initial Inspection Completed')
                        "
                        href="javascript:void(0);"
                        routerLink="/submitted-bids/{{ propertyDetails.Id }}"
                        class="addContractorBtn"
                        >Review Contractor Estimates</a
                    ><br />

                    <table
                        class="gcListReviewTable"
                        mat-table
                        [dataSource]="dataSource"
                        matSort
                    >
                        <ng-container matColumnDef="FirstName">
                            <th
                                mat-header-cell
                                *matHeaderCellDef
                                mat-sort-header
                            >
                                Contractor Name
                            </th>
                            <td mat-cell *matCellDef="let row">
                                {{ row.FirstName }} {{ row.LastName }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="email">
                            <th
                                mat-header-cell
                                *matHeaderCellDef
                                mat-sort-header
                            >
                                Email Address
                            </th>
                            <td mat-cell *matCellDef="let row">
                                {{ row.EmailId }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="phone">
                            <th
                                mat-header-cell
                                *matHeaderCellDef
                                mat-sort-header
                            >
                                Phone Number
                            </th>
                            <td mat-cell *matCellDef="let row">
                                {{ row.Phone }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="estimateStatus">
                            <th
                                mat-header-cell
                                *matHeaderCellDef
                                mat-sort-header
                            >
                                Estimate Status
                            </th>
                            <td mat-cell *matCellDef="let row">
                                {{ row.estimateStatus }}
                            </td>
                        </ng-container>

                        <tr
                            mat-header-row
                            *matHeaderRowDef="displayedColumns2"
                        ></tr>
                        <tr
                            mat-row
                            *matRowDef="let row; columns: displayedColumns2"
                            style="cursor: pointer"
                        ></tr>
                    </table>
                    <div class="gcTableListLoadingSection">
                        <ngx-loading class="ngxLoader" 
                            [show]="loadingGC"
                            [config]="{ backdropBorderRadius: '3px' }"
                        ></ngx-loading>
                    </div>
                    <!-- <a href="javascript:void(0);"  *ngIf="propertyDetails.AcceptedProposal!=null" (click)="markActiveBorrowerStep(6)" class="letsStartedBtn">Go to Final Renovation Report</a> 
            -->
                </mat-card>
            </mat-tab>

            <mat-tab [disabled]="!boTab_5.Active">
                <ng-template mat-tab-label>
                    <div
                        *ngIf="boTab_5.tabStatus == 'Active'"
                        class="mainmenu_tabContent step_6 status_active"
                    >
                        <span class="labelnumbering">6</span>
                        <span class="labelText">Final Renovation Report</span>
                    </div>
                    <div
                        *ngIf="boTab_5.tabStatus == 'Completed'"
                        class="mainmenu_tabContent step_6 status_completed"
                    >
                        <span class="labelnumbering">6</span>
                        <span class="labelText">Final Renovation Report</span>
                    </div>
                    <div
                        *ngIf="boTab_5.tabStatus == 'Not Completed'"
                        class="mainmenu_tabContent step_6 status_notCompleted"
                    >
                        <span class="labelnumbering">6</span>
                        <span class="labelText">Final Renovation Report</span>
                    </div>
                </ng-template>

                <mat-card>
                    <h3>Final Renovation Report</h3>
                    <p>
                        Your consultant will review your accepted estimate to
                        ensure the scope of work is properly presented in the
                        required format. The consultant will then produce a
                        Final Renovation Report (Final Specification of Repair
                        Report).
                    </p>
                    <ul>
                        <li>
                            The report will be submitted and utilized by the
                            lender to finance your renovation project.
                        </li>
                        <li>
                            The lender will provide the report to the appraiser
                            in order to establish a valuation of the property
                            based on the scope of work.
                        </li>
                    </ul>
                    <p>
                        Contact your lender for additional information to close
                        on your loan.
                    </p>
                    <strong
                        >CHECK WITH YOUR LOAN OFFICER TO SEE IF THEY ALLOW A
                        MATERIAL DRAW PRIOR TO STARTING YOUR PROJECT!
                    </strong>
                    <p>
                        To prepare for starting your renovation project, review
                        some of the information that you will need after you
                        close and when the draw process begins. Your lender will
                        assign a Draw Administrator who will be the point of
                        contact throughout the Draw Process.
                    </p>
                    <p>
                        Below is an overview of the draw process and how you
                        request construction funds during your project.
                    </p>
                    <ul>
                        <li>
                            Lender requires
                            <strong style="text-decoration: underline"
                                >ALL</strong
                            >
                            permits be pulled prior to scheduling the first
                            draw.
                        </li>
                        <li>
                            Check with your lender if they allow a material draw
                            prior to starting your project.
                        </li>
                        <li>
                            Check with your lender on what components they may
                            allow deposits - cabinets, windows, etc.
                        </li>
                        <li>
                            Your lender requires construction draw site visits
                            <strong style="text-decoration: underline"
                                >EVERY 30 DAYS OR LESS</strong
                            >
                        </li>
                        <li>
                            Draw checks are processed by lenders and typically
                            arrive in 3-7 days (confirm with your lender).
                            Checks are typically two party (borrower and
                            contractor) and delivered to the borrower. Please
                            confirm with the lender.
                        </li>
                        <li>
                            There is a 10% holdback on funds drawn. This
                            holdback is released a few weeks after the final
                            draw site visit.
                        </li>
                        <li>
                            You and/or your contractor will request funds based
                            on the portion of work that has been done in
                            accordance with the Final Renovation Report.
                        </li>
                        <li>
                            You should always meet your contractor onsite to
                            review work on funds requested prior to consultant’s
                            visit.
                        </li>
                        <li>
                            When a Draw Request is submitted, both you and your
                            contractor will be required to accept the draw prior
                            to the Consultant visiting the site.
                        </li>
                        <li>
                            The consultant will schedule a property visit to
                            verify the work listed on the Draw Request has been
                            completed.
                        </li>
                        <li>
                            Draw Request funds are only released for completed
                            work at time of consultant’s property visit. Any
                            work listed on the draw that is not completed will
                            be adjusted accordingly by the consultant.
                        </li>
                    </ul>
                    <!-- <a href="javascript:void(0);"   (click)="markActiveBorrowerStep(7)" class="letsStartedBtn">Go to Start Construction Project</a> -->
                </mat-card>
            </mat-tab>

            <mat-tab [disabled]="!boTab_6.Active">
                <ng-template mat-tab-label>
                    <div
                        *ngIf="boTab_6.tabStatus == 'Active'"
                        class="mainmenu_tabContent step_7 status_active"
                    >
                        <span class="labelnumbering">7</span>
                        <span class="labelText"
                            >Start Construction Project</span
                        >
                    </div>

                    <div
                        *ngIf="boTab_6.tabStatus == 'Completed'"
                        class="mainmenu_tabContent step_7 status_completed"
                    >
                        <span class="labelnumbering">7</span>
                        <span class="labelText"
                            >Start Construction Project</span
                        >
                    </div>

                    <div
                        *ngIf="boTab_6.tabStatus == 'Not Completed'"
                        class="mainmenu_tabContent step_7 status_notCompleted"
                    >
                        <span class="labelnumbering">7</span>
                        <span class="labelText"
                            >Start Construction Project</span
                        >
                    </div>
                </ng-template>

                <mat-card>
                    <div class="startConstructionProject_Left">
                        <h3>Start Construction Project/Draw Request</h3>
                        <p>
                            Congratulations on closing on your loan! You will
                            now be able to start your construction project.
                        </p>
                        <p>
                            Steps Required for Scheduling Your Draw Inspection
                            and Releasing Funds:
                        </p>

                        <span class="ul_title">1. Submit Permits</span>
                        <ul>
                            <li>
                                Permits as listed on the last page of the Final
                                Renovation Report must be submitted to your
                                lender’s draw administrator / lender contact
                                <strong style="font-style: italic"
                                    >before</strong
                                >
                                any draw can be scheduled.
                            </li>
                        </ul>
                        <span class="ul_title">2. Complete Draw Request </span>
                        <ul>
                            <li>
                                Borrower or Contractor must complete the draw
                                request form using the “<span class="text_blue"
                                    >Draw Request Form</span
                                >”
                            </li>
                            <li>
                                Fill in Column 3 for portion of work that has
                                been completed or anticipated to be completed by
                                the time of the scheduled draw as described on
                                the Specifications of Repairs.
                            </li>
                            <li>
                                Fill in dollar amount requested, not percentage.
                                Please use whole numbers, without commas or
                                dollar signs.
                            </li>
                        </ul>

                        <span class="ul_title">3. Schedule Draw</span>
                        <ul>
                            <li>
                                You must request Draw 1 no later than 30 days
                                after closing. Thereafter, a 30-day maximum
                                between Draws.
                            </li>
                            <li>
                                Site visits occur during the workday. In order
                                to allow for quick and easy scheduling, you may
                                opt to install a lock box (recommended) which
                                will allow for the consultant to visit the site
                                without the need of you being there.
                            </li>
                        </ul>

                        <span class="ul_title">4. Acceptance Required</span>
                        <ul>
                            <li>
                                If contractor completes Step 3, borrower must
                                review and accept or revise. Will see “<span
                                    class="text_blue"
                                    >Draw ## - Requested by GC</span
                                >” under the
                                <strong>Current Draws</strong> section in the
                                Draw Process.
                            </li>
                            <li>
                                If borrower completes Step 3, contractor must
                                review and accept or revise. Will see “<span
                                    class="text_blue"
                                    >Draw ## - Requested by Me</span
                                >” under the
                                <strong>Current Draws</strong> section in the
                                Draw Process.
                            </li>
                        </ul>
                        <p>Upon completion of the above steps:</p>
                        <p>
                            You will be notified by email as to when our site
                            visit will take place. Site visits typically occur
                            within 7 days from the time of your online request.
                            The Consultant will visit the property and confirms
                            the work listed in Column 3 on the draw request
                            form. AM utilizes digital signatures (most lenders
                            accept except M&T Bank). Once all signatures are
                            gathered paperwork is submitted to the lender for
                            release of funds.
                            <strong style="text-decoration: underline"
                                >PLEASE CONTACT YOUR LENDER REGARDING STATUS OF
                                PAYMENT.</strong
                            >
                        </p>
                    </div>
                    <div class="startConstructionProject_Right">
                        <strong
                            style="
                                float: left;
                                clear: both;
                                margin-bottom: 10px;
                                width: 100%;
                            "
                            >Draw Process</strong
                        >
                        <div class="startConstructionProject_RightInner">
                            <ng-container
                                *ngIf="
                                    propertyDetails.InitialDraws == undefined ||
                                    propertyDetails.InitialDraws == null
                                "
                            >
                                <p>
                                    No draws have been requested by the Borrower
                                    or the General Contractor. If you would like
                                    to request a Draw please use the Draw
                                    Request Form above.
                                </p>
                            </ng-container>
                            <ng-container
                                *ngIf="
                                    propertyDetails.InitialDraws != undefined &&
                                    propertyDetails.InitialDraws.length > 0
                                "
                            >
                                <p>
                                    Draws for your review and draws you have
                                    requested will be in the current draw
                                    section below. Once the draw inspection is
                                    completed by your consultant it will appear
                                    in the previous draw section.
                                </p>
                            </ng-container>

                            <strong> Forms </strong>
                            <a
                                *ngIf="
                                    propertyDetails.Status ==
                                        'Pre Closing Completed' ||
                                    propertyDetails.Status ==
                                        'Final Draw Completed' ||
                                    propertyDetails.Status ==
                                        'Draw request started' ||
                                    propertyDetails.Status ==
                                        'Post closing started'
                                "
                                (click)="printFinalSOR(propertyDetails.Id)"
                            >
                                <br />
                                Final Specification of Repairs
                            </a>
                            <a
                                href="javascript:void(0);"
                                (click)="printPdf('assets/pdf/92577.pdf')"
                                *ngIf="
                                    propertyDetails.Status ==
                                        'Bid Process Started' ||
                                    propertyDetails.Status ==
                                        'Final Draw Completed' ||
                                    propertyDetails.Status ==
                                        'Pre Closing Completed' ||
                                    propertyDetails.Status ==
                                        'Draw request started' ||
                                    propertyDetails.Status ==
                                        'Post closing started'
                                "
                            >
                                <br />
                                Change Order Form
                            </a>

                            <br /><strong>
                                <br />
                                Use Below Form to Request a New Draw
                            </strong>
                            <a
                                routerLink="/initial-draw-request/{{
                                    propertyDetails.Id
                                }}"
                                *ngIf="
                                    propertyDetails.InitialDraws == undefined ||
                                    propertyDetails.InitialDraws == null ||
                                    propertyDetails.InitialDraws == ''
                                "
                            >
                                <br />Draw request form
                            </a>
                            <a
                                routerLink="/initial-draw-request/{{
                                    propertyDetails.Id
                                }}"
                                *ngIf="
                                    propertyDetails.InitialDraws != null &&
                                    propertyDetails.InitialDraws != undefined &&
                                    propertyDetails.InitialDraws != '' &&
                                    propertyDetails.InitialDraws[
                                        propertyDetails.InitialDraws.length - 1
                                    ].Status == 'approved' &&
                                    propertyDetails.InitialDraws[
                                        propertyDetails.InitialDraws.length - 1
                                    ].ConsultantSendSignDate != null &&
                                    propertyDetails.InitialDraws[
                                        propertyDetails.InitialDraws.length - 1
                                    ].ConsultantSendSignDate != undefined &&
                                    propertyDetails.InitialDraws[
                                        propertyDetails.InitialDraws.length - 1
                                    ].ConsultantSendSignDate != ''
                                "
                            >
                                <br />Draw request form
                            </a>

                            <a
                                (click)="notAllowDraw()"
                                *ngIf="
                                    CurrentDrawShow == true &&
                                    propertyDetails.InitialDraws != undefined &&
                                    propertyDetails.InitialDraws.length > 0
                                "
                            >
                                <br />Draw request form
                            </a>
                            <br />

                            <ng-container
                                *ngIf="
                                    propertyDetails.InitialDraws != undefined &&
                                    propertyDetails.InitialDraws.length > 0
                                "
                            >
                                <br /><strong> Draw Status </strong> <br />
                                <p
                                    *ngIf="
                                        propertyDetails.InitialDraws[
                                            propertyDetails.InitialDraws
                                                .length - 1
                                        ].Status == 'pending' &&
                                        propertyDetails.InitialDraws[
                                            propertyDetails.InitialDraws
                                                .length - 1
                                        ].UpdatedBy != userDetail.Id
                                    "
                                >
                                    Draw has been submitted for review - Use
                                    current draw section to review
                                </p>
                                <p
                                    *ngIf="
                                        propertyDetails.InitialDraws[
                                            propertyDetails.InitialDraws
                                                .length - 1
                                        ].Status == 'revised' &&
                                        propertyDetails.InitialDraws[
                                            propertyDetails.InitialDraws
                                                .length - 1
                                        ].UpdatedBy != userDetail.Id
                                    "
                                >
                                    Draw revision has been requested - Use
                                    current draw section to review
                                </p>
                                <p
                                    *ngIf="
                                        propertyDetails.InitialDraws[
                                            propertyDetails.InitialDraws
                                                .length - 1
                                        ].Status == 'revised' &&
                                        propertyDetails.InitialDraws[
                                            propertyDetails.InitialDraws
                                                .length - 1
                                        ].UpdatedBy == userDetail.Id
                                    "
                                >
                                    Draw revision has been requested - No action
                                    at this time
                                </p>
                                <p
                                    *ngIf="
                                        propertyDetails.InitialDraws[
                                            propertyDetails.InitialDraws
                                                .length - 1
                                        ].Status == 'pending' &&
                                        propertyDetails.InitialDraws[
                                            propertyDetails.InitialDraws
                                                .length - 1
                                        ].UpdatedBy == userDetail.Id
                                    "
                                >
                                    Draw has been submitted for review - No
                                    action at this time
                                </p>
                                <p
                                    *ngIf="
                                        propertyDetails.InitialDraws[
                                            propertyDetails.InitialDraws
                                                .length - 1
                                        ].Status == 'approved' &&
                                        propertyDetails.InitialDraws[
                                            propertyDetails.InitialDraws
                                                .length - 1
                                        ].UpdatedBy !=
                                            propertyDetails.ConsultantId
                                    "
                                >
                                    Draw has been submitted to Consultant for
                                    review - No action at this time
                                </p>

                                <p
                                    *ngIf="
                                        propertyDetails.InitialDraws[
                                            propertyDetails.InitialDraws
                                                .length - 1
                                        ].Status == 'approved' &&
                                        propertyDetails.InitialDraws[
                                            propertyDetails.InitialDraws
                                                .length - 1
                                        ].UpdatedBy ==
                                            propertyDetails.ConsultantId
                                    "
                                >
                                    Draw has been performed by Consultant - View
                                    draw in Previous Draw Section
                                </p>
                                <strong><br />Current Draws<br /></strong>
                                <a
                                    *ngIf="
                                        CurrentDrawShow == true &&
                                        propertyDetails.InitialDraws !=
                                            undefined &&
                                        propertyDetails.InitialDraws.length > 0
                                    "
                                    (click)="
                                        printDrawRequest(
                                            propertyDetails.InitialDraws[
                                                propertyDetails.InitialDraws
                                                    .length - 1
                                            ].DrawId
                                        )
                                    "
                                >
                                    Print current draw form<br
                                /></a>
                                <strong *ngIf="CurrentDrawShow == false"
                                    >There are no current draws <br
                                /></strong>

                                <ng-container
                                    *ngFor="
                                        let DrawDetails of propertyDetails.InitialDraws;
                                        let i = index
                                    "
                                >
                                    <a
                                        *ngIf="
                                            DrawDetails.Status == 'pending' &&
                                            propertyDetails.InitialDraws[i]
                                                .UpdatedBy == userDetail.Id
                                        "
                                        routerLink="/initial-draw-request/{{
                                            propertyDetails.Id
                                        }}"
                                        >Draw #{{ i + 1 }} - Requested by Me ({{
                                            DrawDetails.GCRequestDate
                                                | date : "M/d/y h:mm"
                                        }}) <br
                                    /></a>
                                    <a
                                        *ngIf="
                                            DrawDetails.Status == 'revised' &&
                                            propertyDetails.InitialDraws[i]
                                                .UpdatedBy == userDetail.Id
                                        "
                                        routerLink="/initial-draw-request-bo/{{
                                            propertyDetails.Id
                                        }}/{{ DrawDetails.DrawId }}"
                                        >Draw #{{ i + 1 }} - Revision Requested
                                        by Me ({{
                                            DrawDetails.GCRequestDate
                                                | date : "M/d/y h:mm"
                                        }}) <br
                                    /></a>
                                </ng-container>
                                <ng-container
                                    *ngFor="
                                        let DrawDetails of propertyDetails.InitialDraws;
                                        let i = index
                                    "
                                >
                                    <a
                                        *ngIf="
                                            DrawDetails.Status == 'pending' &&
                                            propertyDetails.InitialDraws[i]
                                                .UpdatedBy != userDetail.Id
                                        "
                                        routerLink="/initial-draw-request-bo/{{
                                            propertyDetails.Id
                                        }}/{{ DrawDetails.DrawId }}"
                                        >Draw #{{ i + 1 }} - Requested by GC ({{
                                            DrawDetails.GCRequestDate
                                                | date : "M/d/y h:mm"
                                        }})<br
                                    /></a>
                                    <a
                                        *ngIf="
                                            DrawDetails.Status == 'revised' &&
                                            propertyDetails.InitialDraws[i]
                                                .UpdatedBy != userDetail.Id
                                        "
                                        routerLink="/initial-draw-request/{{
                                            propertyDetails.Id
                                        }}"
                                        >Draw #{{ i + 1 }} - Revision Requested
                                        by GC ({{
                                            DrawDetails.GCRequestDate
                                                | date : "M/d/y h:mm"
                                        }})<br
                                    /></a>
                                </ng-container>
                                <strong *ngIf="PreviousDrawShow == true"
                                    ><br />Previous Draws<br
                                /></strong>
                                <ng-container
                                    *ngFor="
                                        let InitialDraw of propertyDetails.InitialDraws;
                                        let i = index
                                    "
                                >
                                    <a
                                        *ngIf="
                                            InitialDraw.Status == 'approved' &&
                                            InitialDraw.ConsultantSendSignDate !=
                                                null &&
                                            InitialDraw.ConsultantSendSignDate !=
                                                undefined &&
                                            InitialDraw.ConsultantSendSignDate !=
                                                ''
                                        "
                                        (click)="
                                            printDrawRequest(InitialDraw.DrawId)
                                        "
                                    >
                                        Draw #{{ i + 1 }} - Inspected on
                                        {{
                                            InitialDraw.ConsultantRequestDate
                                        }}
                                        Submitted on
                                        {{
                                            InitialDraw.ConsultantSendSignDate
                                                | date : "M/d/y"
                                        }}
                                        <br
                                    /></a>
                                </ng-container>
                            </ng-container>
                        </div>
                    </div>
                </mat-card>
            </mat-tab>
        </mat-tab-group>
    </div>

    <!--<div class="profileDetailsSidebar" > -->
    <!-- <div class="center" style="padding:0 0 0 38px;" ><h2><strong>Edit/PDF links</strong></h2></div>  -->

    <!--  <div class="side-list">
          <ul>

            <li *ngIf="propertyDetails.Status=='Property File created'">
              <h4 class="type-title">Schedule Inspection -
                <span >In Progress</span>
              </h4>
              <div class="links-to-pdf-and-edit">
                <span>Arrange Inspection date with consultant</span>
                <br>
                <span>Meet consultant at property</span>
                <br>
                <span>Discuss construction "Need", "Want", and "Like to have"</span>
                <br>
                <span>Await initial "Specification of Repair Draft"</span>
                <br>
              </div>
            </li>

            <li style="opacity:0.5;" *ngIf="propertyDetails.Status!='Property File created'">
              <h4 class="type-title">Schedule Inspection -
                <span  *ngIf="propertyDetails.Status!='Property File created'">Completed</span>
              </h4>
              <div class="links-to-pdf-and-edit">
                <span>Arrange Inspection date with consultant</span>
                <br>
                <span>Meet consultant at property</span>
                <br>
                <span>Discuss construction "Need", "Want", and "Like to have"</span>
                <br>
                <span>Await initial "Specification of Repair Draft"</span>
                <br>
              </div>
            </li>


            <li *ngIf="propertyDetails.Status=='DRAFT SOR Sent to Borrower' || propertyDetails.Status=='Revise'">
              <h4 class="type-title">Review Specification Of Repair DRAFT -
                <span>Started</span>
                
              </h4>
              <div class="links-to-pdf-and-edit">
                <span>
                  <a href="javascript:void(0);" routerLink="/specification-repair-review/{{propertyDetails.Id}}">Draft Specifications of Repairs</a> - Review "Need" (Required), "Want" and "Like to have"
                  construction items and costs</span>
                <br>
                <span>
                  <a href="javascript:void(0);" routerLink="/general-contractor-info/{{propertyDetails.Id}}">Add/Remove General Contractor</a> (Once you enter the General Contractor contact information,
                  they will receive login information to access the system and place Bids online)</span>

              </div>
            </li>

            <li style="opacity:0.5;" *ngIf="(propertyDetails.Status=='Bid Process Started' || propertyDetails.Status=='Property File created') ">
              <h4 class="type-title">Review Specification Of Repair DRAFT -
                <span *ngIf="propertyDetails.Status=='Property File created'">Not Started</span>
                <span *ngIf="propertyDetails.Status!='Property File created' && propertyDetails.Status!='DRAFT SOR Sent to Borrower'">Completed</span>
              </h4>
              <div class="links-to-pdf-and-edit">
                <span *ngIf="propertyDetails.Status=='Property File created'">
                  <a href="javascript:void(0);">
                    Draft Specifications of Repairs
                  </a>
                   - Review "Need" (Required), "Want" and "Like to have"construction items and costs
                </span>
                <br>
                <span *ngIf="propertyDetails.Status=='Property File created'">
                  <a href="javascript:void(0);">
                    Add/Remove General Contractor
                  </a> 
                   (Once you enter the General Contractor contact information,
                  they will receive login information to access the system and place Bids online)
                </span>
                <span *ngIf="propertyDetails.Status!='Property File created' && propertyDetails.Status!='DRAFT SOR Sent to Borrower'">
                  <a href="javascript:void(0);" routerLink="/specification-repair-review/{{propertyDetails.Id}}">
                    Draft Specifications of Repairs
                  </a>
                   - Review "Need" (Required), "Want" and "Like to have"construction items and costs
                </span>
                <br>
                <span *ngIf="propertyDetails.Status!='Property File created' && propertyDetails.Status!='DRAFT SOR Sent to Borrower'">
                  <a href="javascript:void(0);" routerLink="/general-contractor-info/{{propertyDetails.Id}}">
                    Add/Remove General Contractor
                  </a> 
                   (Once you enter the General Contractor contact information,
                  they will receive login information to access the system and place Bids online)
                </span>

              </div>
            </li>
           
            <li *ngIf="submittedBid==0 && propertyDetails.Status!='Property File created' && propertyDetails.Status!='DRAFT SOR Sent to Borrower' && ((propertyDetails.GeneralContractorId !='' && propertyDetails.GeneralContractorId != null) || (propertyDetails.InvitedGeneralContractors!=null && propertyDetails.InvitedGeneralContractors.length != 0))">
              <h4 class="type-title">Bid Process -
                <span>Started </span>
              </h4>
              <div class="links-to-pdf-and-edit">
                <span>Meet general contractor at property</span>
                <br>
                <span>Please print and bring copy of consultants <a href="javascript:void(0)" (click)="GetBlankBORPdf(propertyDetails.Id)" >Scope of Work</a></span><br />
                <span>Ask general contractor to fill "Bid on Repairs" form online</span><br> -->
    <!-- <a (click)="resendBiddingRequest()">Resend Bidding Request</a><br>-->
    <!-- <a *ngIf="submittedBid > 0" routerLink="/gc-submitted-bids/{{propertyDetails.Id}}">View Submitted Bids</a> -->

    <!--</div>
            </li>
            
            <li *ngIf="submittedBid==0  &&  propertyDetails.Status!='Property File created' && propertyDetails.Status!='DRAFT SOR Sent to Borrower' && (propertyDetails.InvitedGeneralContractors==null ||  propertyDetails.InvitedGeneralContractors.length == 0) && (propertyDetails.GeneralContractorId=='' || propertyDetails.GeneralContractorId == null ) ">
                <h4 class="type-title">Bid Process -
                  <span>Add Contractor</span>
                </h4>
                <div class="links-to-pdf-and-edit">
                    <span>
                        <a href="javascript:void(0);" routerLink="/general-contractor-info/{{propertyDetails.Id}}">
                          Add/Remove General Contractor
                        </a> 
                         (Once you enter the General Contractor contact information,
                        they will receive login information to access the system and place Bids online)
                      </span>
                </div>
              </li>
            <li style="opacity:0.5;" *ngIf="submittedBid > 0 || propertyDetails.Status!='Bid Process Started' || propertyDetails.Status=='Property File created' || propertyDetails.Status=='DRAFT SOR Sent to Borrower'">
              <h4 class="type-title">Bid Process  -
                <span *ngIf="propertyDetails.Status=='Property File created' || propertyDetails.Status=='DRAFT SOR Sent to Borrower'">Not Started</span>
                <span *ngIf="submittedBid > 0 || (propertyDetails.Status!='Property File created' && propertyDetails.Status!='DRAFT SOR Sent to Borrower')">Completed</span>
              </h4>
              <div class="links-to-pdf-and-edit">
                <span>Meet general contractor at property</span>
                <br> -->
    <!-- <span>Please print and bring copy of consultants <a href="javascript:void(0)" (click)="GetBlankBORPdf(propertyDetails.Id)" >Scope of Work</a></span><br /> -->
    <!--<span>Ask general contractor to fill "Bid on Repairs" form online</span><br> -->
    <!-- <a (click)="resendBiddingRequest()">Resend Bidding Request</a><br>-->
    <!-- <a *ngIf="submittedBid > 0" routerLink="/gc-submitted-bids/{{propertyDetails.Id}}">View Submitted Bids</a>
                <span *ngIf="submittedBid == 0" >No bid submitted</span> -->
    <!-- </div>
            </li>
            
            <li *ngIf="submittedBid > 0 &&  ( propertyDetails.Status!='Review Bid' && propertyDetails.Status != 'Final Draw Completed'   && propertyDetails.Status!='Draw request started' && (propertyDetails.Status=='Bid Process Started' || propertyDetails.Status=='Send For Review' || propertyDetails.Status=='Initial Inspection Completed' ))">
              <h4 class="type-title">Review Contractor Bid(s) - 
                <span>Started </span>
              </h4>
              <div class="links-to-pdf-and-edit">
                <span ><a href="javascript:void(0);" routerLink="/submitted-bids/{{propertyDetails.Id}}">Review General Contractor Bids</a></span>
                
                <br>
                <span>Await final Pre-Closing Report</span>

              </div>
            </li>
           
            
            <li style="opacity:0.5;" *ngIf="submittedBid == 0 || propertyDetails.Status=='Property File created' || propertyDetails.Status=='DRAFT SOR Sent to Borrower' ">
              <h4 class="type-title">Review Contractor Bid(s) -
                <span>Not Started</span>
              </h4>
              <div class="links-to-pdf-and-edit">
                
                <span  >No bid submitted</span>
                <br>
                <span>Await final Pre-Closing Report</span>

              </div>
            </li>
            <li style="opacity:0.5;" *ngIf="submittedBid > 0 && (propertyDetails.Status=='Pre Closing Completed'  || propertyDetails.Status == 'Final Draw Completed'  ||  propertyDetails.Status=='Draw request started' || propertyDetails.Status=='Review Bid')">
                <h4 class="type-title">Review Contractor Bid(s) -
                  <span>Completed</span>
                </h4>
                <div class="links-to-pdf-and-edit">
                  
                 
                  <span>Await final Pre-Closing Report</span>
  
                </div>
              </li> -->

    <!-- <li style="opacity:0.5;"> -->
    <!--<li style="opacity:0.5;" *ngIf="(propertyDetails.Status!='Draw request started'  && propertyDetails.Status != 'Final Draw Completed' && propertyDetails.Status!='Pre Closing Completed' )"> 
              <h4 class="type-title">Final Pre-Closing Report -
                <span>Not Started</span>
              </h4>
              <div class="links-to-pdf-and-edit">
                <span>Specification of Repair Final</span>
                <br>
                <span>Consultant to Submit Final SOR</span><br>
                <span>Borrower and General contractor should sign all required docs</span>
                <span>Send all signed document to lender</span>

              </div>
            </li>
            <li  *ngIf="(propertyDetails.Status=='Pre Closing Completed' )"> 
                <h4 class="type-title">Final Pre-Closing Report -
                  <span >Started</span>
                  
                </h4>
                <div class="links-to-pdf-and-edit">
                  <span>Specification of Repair Final</span>
                  <br>
                  <span>Consultant to Submit Final SOR</span><br>
                  <span>Borrower and General contractor should sign all required docs</span>
                  <span>Send all signed document to lender</span>
  
                </div>
              </li>
              <li  style="opacity:0.5;" *ngIf="(propertyDetails.Status=='Draw request started' || propertyDetails.Status == 'Final Draw Completed'  || propertyDetails.Status=='Post closing started')"> 
                <h4 class="type-title">Final Pre-Closing Report -
                 
                  <span >Completed</span>
                </h4>
                <div class="links-to-pdf-and-edit">
                  <span>Specification of Repair Final</span>
                  <br>
                  <span>Consultant to Submit Final SOR</span><br>
                  <span>Borrower and General contractor should sign all required docs</span>
                  <span>Send all signed document to lender</span>
  
                </div>
              </li>
            <li style="opacity:0.5;" *ngIf="(propertyDetails.Status!='Draw request started' && propertyDetails.Status != 'Final Draw Completed'  && propertyDetails.Status!='Post closing started')">
              <h4 class="type-title">Post Closing -
                <span>Not Started</span>
              </h4>
              <div class="links-to-pdf-and-edit">
                <span  *ngIf="propertyDetails.Status == 'Pre Closing Completed' || propertyDetails.Status == 'Final Draw Completed'  || propertyDetails.Status == 'Draw request started' || propertyDetails.Status == 'Post closing started' " >
                  Final Specification of Repairs <br>
                </span>
                <span  *ngIf="(propertyDetails.Status == 'Pre Closing Completed' || propertyDetails.Status == 'Draw request started' || propertyDetails.Status == 'Post closing started')  && (propertyDetails.InitialDraws == undefined || propertyDetails.InitialDraws == null || (propertyDetails.InitialDraws.length>0 && propertyDetails.InitialDraws[0].UpdatedBy == userDetail.Id))">
                  Draw Request - Fill this form to request funds<br>
                </span>
               
                <a  href="javascript:void(0);" (click)="printPdf('assets/pdf/92577.pdf');"  *ngIf="(propertyDetails.Status == 'Bid Process Started' || propertyDetails.Status == 'Final Draw Completed'  || propertyDetails.Status == 'Pre Closing Completed' || propertyDetails.Status == 'Draw request started' || propertyDetails.Status == 'Post closing started')">
                  Change Order Form<br />
                </a>
                <br />

              </div>
            </li>
            <li *ngIf="(propertyDetails.Status=='Draw request started' || propertyDetails.Status == 'Final Draw Completed'  || propertyDetails.Status=='Post closing started')">
                <h4 class="type-title">Post Closing -
                  <span>Started</span>
                </h4>
                <div class="links-to-pdf-and-edit">
                  <a  *ngIf="propertyDetails.Status == 'Pre Closing Completed' || propertyDetails.Status == 'Final Draw Completed'  || propertyDetails.Status == 'Draw request started' || propertyDetails.Status == 'Post closing started' " (click)="printFinalSOR(propertyDetails.Id);">
                    Final Specification of Repairs <br>
                  </a>
                  <a href="javascript:void(0);" (click)="printPdf('assets/pdf/92577.pdf');" *ngIf="(propertyDetails.Status == 'Bid Process Started' || propertyDetails.Status == 'Final Draw Completed'  || propertyDetails.Status == 'Pre Closing Completed' || propertyDetails.Status == 'Draw request started' || propertyDetails.Status == 'Post closing started')">
                    Change Order Form
                  </a><br />
                  <a routerLink="/initial-draw-request/{{propertyDetails.Id}}" *ngIf="propertyDetails.InitialDraws == undefined || propertyDetails.InitialDraws == null || (propertyDetails.InitialDraws.length>0)">
                    Fill Draw request form<br>
                  </a><br />
                  
                  <ng-container *ngIf="propertyDetails.InitialDraws != undefined && propertyDetails.InitialDraws.length>0" >
                    <strong class="textLeftAlign"  ><br />Current Draws<br /></strong>
                    <a class="textLeftAlign" *ngIf="CurrentDrawShow==true && propertyDetails.InitialDraws != undefined && propertyDetails.InitialDraws.length>0 " (click)="printDrawRequest(propertyDetails.InitialDraws[propertyDetails.InitialDraws.length - 1].DrawId)"> Print current draw form<br></a> 
                <strong class="textLeftAlign" *ngIf="CurrentDrawShow==false" >There are no current draws - Use the form above to request funds<br /></strong>
                
              <ng-container *ngFor="let DrawDetails of propertyDetails.InitialDraws;let i=index"  >
                 
                  <a class="textLeftAlign" *ngIf="(DrawDetails.Status == 'pending' ) && propertyDetails.InitialDraws[i].UpdatedBy == userDetail.Id" routerLink="/initial-draw-request/{{propertyDetails.Id}}/{{DrawDetails.DrawId}}">Draw #{{i+1}} - Requested by Me ({{DrawDetails.GCRequestDate | date: 'M/d/y h:mm'}})  <br></a> 
                  <a class="textLeftAlign" *ngIf="(DrawDetails.Status == 'revised' ) && propertyDetails.InitialDraws[i].UpdatedBy == userDetail.Id" routerLink="/initial-draw-request/{{propertyDetails.Id}}">Revised Draw #{{i+1}} - Requested by Me ({{DrawDetails.GCRequestDate | date: 'M/d/y h:mm'}})  <br></a> 
               
              </ng-container>
              <ng-container *ngFor="let DrawDetails of propertyDetails.InitialDraws;let i=index"  >
                
               <a class="textLeftAlign" *ngIf="(DrawDetails.Status == 'pending') && propertyDetails.InitialDraws[i].UpdatedBy != userDetail.Id" routerLink="/initial-draw-request-bo/{{propertyDetails.Id}}/{{DrawDetails.DrawId}}">Draw #{{i+1}}  - Requested by GC ({{DrawDetails.GCRequestDate | date: 'M/d/y h:mm'}})<br></a>
   
                </ng-container>
                <strong class="botextLeftAlign" *ngIf="PreviousDrawShow==true" ><br />Previous Draws<br /></strong>
                <ng-container *ngFor="let InitialDraw of propertyDetails.InitialDraws; let i=index" >
                    <a class="botextLeftAlign" *ngIf="InitialDraw.Status == 'approved'  && InitialDraw.ConsultantSendSignDate !=null && InitialDraw.ConsultantSendSignDate!=undefined && InitialDraw.ConsultantSendSignDate!=''" (click)="printDrawRequest(InitialDraw.DrawId)"> Draw #{{i+1}} -  Inspected on {{InitialDraw.ConsultantRequestDate}} Submitted on {{InitialDraw.ConsultantSendSignDate | date: 'M/d/y'}} <br></a>
                </ng-container> 
                  </ng-container>
  
                </div>
              </li>
          </ul>
        </div>
      </div>-->

    <div id="content" #content style="display: none">
        <span
            style="
                text-align: center;
                font-weight: bold;
                width: 100%;
                font-size: 20px;
                border-bottom: 1px solid #000000;
            "
            >FHA 203(k) HUD-Approved Consultant</span
        >
        <br /><span
            style="
                text-align: center;
                font-weight: bold;
                width: 100%;
                font-size: 18px;
                padding-bottom: 30px;
            "
            >Non-Home Inspection Disclosure</span
        >

        <div style="padding-bottom: 10px">
            <span
                >Date:
                <strong>
                    {{
                        propertyDetails.CreatedDate | date : "MM/dd/yyyy"
                    }}</strong
                >
            </span>
        </div>
        <div style="padding-bottom: 10px" *ngIf="borrowerDetails.Id">
            <span
                >Borrower Name:
                <strong>
                    {{ borrowerDetails.FirstName }}
                    {{ borrowerDetails.LastName }}</strong
                >
            </span>
        </div>
        <div style="padding-bottom: 10px" *ngIf="propertyDetailsShow">
            <span
                >Property Address:
                <strong>
                    {{
                        propertyDetails?.InspectionAddress?.address1
                            ? propertyDetails?.InspectionAddress?.address1 +
                              ", "
                            : ""
                    }}
                    {{
                        propertyDetails?.InspectionAddress?.address2
                            ? propertyDetails?.InspectionAddress?.address2 +
                              ", "
                            : ""
                    }}
                    {{
                        propertyDetails?.InspectionAddress?.city
                            ? propertyDetails?.InspectionAddress?.city + ", "
                            : ""
                    }}
                    {{
                        propertyDetails?.InspectionAddress?.state
                            ? propertyDetails?.InspectionAddress?.state + ", "
                            : ""
                    }}
                    {{ propertyDetails?.InspectionAddress?.zipcode }}
                </strong>
            </span>
        </div>
        <div style="padding-bottom: 10px" *ngIf="consultantDetails.Id">
            <span
                >Consultant Name:
                <strong>
                    {{ consultantDetails.FirstName }}
                    {{ consultantDetails.LastName }}</strong
                >
            </span>
        </div>
        <div style="padding-bottom: 30px" *ngIf="consultantDetails.Id">
            <span
                >Consultant ID:
                <strong> {{ consultantDetails.Id }}</strong>
            </span>
        </div>

        <div style="padding-bottom: 20px">
            <p>
                The undersigned is a HUD-approved Consultant. My role is to
                inspect the property and prepare a work write-up that specifies
                the type of repair and the cost of each work item to be used in
                an FHA 203(k) renovation loan.
                <strong
                    >In my role as a Consultant on this project, I am not acting
                    as a Home Inspector.
                </strong>
            </p>
        </div>
        <div style="padding-bottom: 30px">
            <p>
                FHA does not perform Home Inspections. Home Inspections give the
                buyer more detailed information about the overall condition of
                the home prior to purchase. This can include evaluating the
                physical condition and mechanical systems, identifying items
                that need to be repaired or replaced, etc. For details on a Home
                Inspection, please refer to form
                <a href="">HUD-92564-CN.</a> While Home Inspections are
                recommended, FHA does not require them.
            </p>
        </div>

        <div
            style="
                border-color: #000000;
                border-width: 1px;
                width: 100%;
                padding-bottom: 20px;
            "
        >
            <span
                >Consultant Signature: --------------------------<span
                    style="color: #fff"
                    >--------------------</span
                ></span
            >
            <span>CO Sign Date: --------------------------</span>
        </div>

        <div
            style="
                border-color: #000000;
                border-width: 1px;
                width: 100%;
                padding-bottom: 20px;
            "
        >
            <span
                >Borrower Signature: ----------------------------<span
                    style="color: #fff"
                    >-------------------</span
                ></span
            >
            <span>BO Sign Date: --------------------------</span>
        </div>
    </div>

    <div class="Consultant-data">
        <table
            id="ConsultantLiability"
            #ConsultantLiability
            style="display: none; font-weight: bold"
        >
            <tr>
                <td>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                </td>
            </tr>

            <tr>
                <td>
                    <div style="width: 150px">&nbsp;</div>
                </td>
                <td style="text-align: right; border-bottom: #000 solid 2px">
                    The leader in 203k renovation
                    <br />
                    consuling in new england
                    <br />
                    <strong>admin@amconsulte.com</strong>
                </td>
            </tr>

            <tr>
                <td>
                    <h3
                        style="
                            text-align: center;
                            font-size: 28px;
                            border-bottom: #000 solid 2px;
                        "
                    >
                        <strong>
                            Consultant's & Client's Limitation Of Liability
                            Agreement</strong
                        >
                    </h3>
                </td>
            </tr>

            <tr>
                <td align="right">
                    <label>Loan Number:</label>
                    <input
                        type="text"
                        style="border-bottom: #000 solid 1px; background: none"
                    />
                </td>
            </tr>

            <tr>
                <td style="font-weight: normal">
                    <p>
                        This Agreement is made on
                        <strong>{{
                            fhaliabilityagreement.AgreementDate
                        }}</strong>
                        between the Consultant and Borrower/Client. The Client
                        hereby desires to engage the Consultant to perform
                        services pertinent to the implementation of Fannie Maes
                        Homestyle Renovation or HUD's Mortgage Insurance Program
                        as it pertains to the architectural concerns for the
                        Property
                    </p>
                </td>
            </tr>

            <tr>
                <td *ngIf="propertyDetailsShow">
                    <p>Property Address:</p>
                    <p>
                        {{
                            propertyDetails?.InspectionAddress?.address1
                                ? propertyDetails?.InspectionAddress?.address1 +
                                  ", "
                                : ""
                        }}
                        {{
                            propertyDetails?.InspectionAddress?.address2
                                ? propertyDetails?.InspectionAddress?.address2 +
                                  ", "
                                : ""
                        }}
                    </p>
                    <p>
                        {{
                            propertyDetails?.InspectionAddress?.city
                                ? propertyDetails?.InspectionAddress?.city +
                                  ", "
                                : ""
                        }}
                        {{
                            propertyDetails?.InspectionAddress?.state
                                ? propertyDetails?.InspectionAddress?.state +
                                  ", "
                                : ""
                        }}
                        {{ propertyDetails?.InspectionAddress?.zipcode }}
                    </p>
                </td>
            </tr>

            <tr>
                <td align="center">
                    <p *ngIf="borrowerDetails.Id">
                        Borrower/Client: {{ borrowerDetails.FirstName }}
                        {{ borrowerDetails.LastName }}
                    </p>
                    <p *ngIf="consultantDetails.Id">
                        Consultant: {{ consultantDetails.FirstName }}
                        {{ consultantDetails.LastName }}
                    </p>
                    <p *ngIf="propertyDetailsShow">
                        {{
                            propertyDetails?.InspectionAddress?.address1
                                ? propertyDetails?.InspectionAddress?.address1 +
                                  ", "
                                : ""
                        }}
                        {{
                            propertyDetails?.InspectionAddress?.address2
                                ? propertyDetails?.InspectionAddress?.address2 +
                                  ", "
                                : ""
                        }}
                    </p>
                    <p *ngIf="propertyDetailsShow">
                        {{ propertyDetails?.InspectionAddress?.zipcode }}
                    </p>
                </td>
            </tr>

            <tr>
                <td>
                    <h3
                        style="
                            text-align: center;
                            font-size: 24px;
                            border-top: #000 solid 2px;
                        "
                    >
                        <strong>
                            It is mutually understood and agreed to as
                            follows</strong
                        >
                    </h3>
                </td>
            </tr>

            <tr>
                <td>
                    <h3>
                        <strong> Walk Through</strong>
                    </h3>
                    <div>
                        The consultant will accompany the Client or his/her
                        agent during a walkthrough of the subject property
                        during which the property will be analyzed for
                        compliance with the 203(k) Rehabilitation Mortgage
                        Insurance Program.
                    </div>

                    <div>
                        The consultant will recommend repairs and modifications
                        that in his/her opinion will be necessary to comply with
                        the 203(k) and Homestyle program requirements. The
                        Consultant will assist the Client or his/her agent in
                        the preparation of the Work Write Up that describes the
                        proposed rehabilitation and the HUD required form for
                        the Draw Request.
                    </div>
                </td>
            </tr>

            <tr>
                <td>
                    <h3>
                        <strong>Inspections</strong>
                    </h3>
                    <p>
                        The Consultant will incorporate and attach all FHA
                        required inspections to be performed on this property,
                        including those to be provided by the client prior to
                        submitting the final report. This may include, but is
                        not limited to, the mechanical, engineering, termite
                        report, mold, and any home or building inspection
                        reports, and local government inspection reports that
                        may be applicable. All forms to be prepared (Work Write
                        Up) by the Consultant will be submitted within five
                        working days of the initial inspection.
                    </p>
                </td>
            </tr>

            <tr>
                <td>
                    <h3>
                        <strong>Agreement Duration</strong>
                    </h3>
                    <p>
                        The Consultant will perform this one time service
                        described herein required to close the loan. Post
                        closing, the Consultant will provide all draw
                        inspections as required by the Lender. Please note draw
                        inspection fees are not included in the Consultant Fee.
                    </p>
                </td>
            </tr>

            <tr>
                <td>
                    <h3>
                        <strong>Indemnification</strong>
                    </h3>
                    <p>
                        The Client or his/her agent agrees to indemnify, defend
                        and hold harmless the Consultant, his employees, and
                        agents of and from all claims, actions, demands for
                        damages, liability and cost attendant to defending
                        against the same brought or made for or on account of
                        any injuries or damages received or sustained by any
                        person or persons or property, arising out of or
                        occasioned by the acts of the Consultant, or his agents
                        or employees, except in cases of willful misconduct or
                        gross negligence of the Consultant, or his agents or
                        employees, for the work performed by the Consultant
                        during terms of the agreement and thereafter. Further,
                        the Consultant charged with the enforcement of the FHA
                        Guidelines, while acting for the jurisdiction in good
                        faith and without malice in the discharge of the duties
                        required by those Guidelines or other pertinent laws or
                        ordinances, shall not thereby be rendered liable
                        personally and is hereby relieved from personal
                        liability for any damage accruing to persons or property
                        as a result of any act or by reason of an act or
                        omission in the discharge of official duties. The Client
                        or his/her agents further agree that any and all claims
                        brought against the consultant for any reason shall not
                        exceed the fee charge for the services rendered.
                    </p>
                </td>
            </tr>

            <tr>
                <td>
                    <h3>
                        <strong>Consultant Fee</strong>
                    </h3>
                    <p>
                        The fee of *${{
                            fhaliabilityagreement.ConsultantFee
                        }}
                        is charged for the services described in this agreement
                        prior to commencement of the services described in
                        paragraph one (1) herein, the Client will pay the
                        Consultant fee in full at the time of inspection unless
                        otherwise agreed to in
                    </p>
                </td>
            </tr>

            <tr>
                <td>
                    <label>Initials: ---------------</label>
                </td>
            </tr>

            <tr>
                <td align="right">
                    <label>Loan Number: ---------------</label>
                </td>
            </tr>

            <tr>
                <td>
                    <p>
                        writing prior to the initial inspection of the premises.
                        Consultant Fees may be reimbursed up to ${{
                            fhaliabilityagreement.ConsultantFeeReimbursed
                        }}
                        to the borrower at closing from the loan proceeds at the
                        discretion of the lender.
                    </p>
                </td>
            </tr>

            <tr>
                <td>
                    <p>
                        *** Special Notice *** The local HUD office does not
                        warrant, accept liability or responsibility for the
                        competence of the Consultant or the quality of the work
                        product the consultant may perform for the Borrower(s).
                    </p>
                </td>
            </tr>

            <tr>
                <td>
                    <h3>
                        <strong>Additional Provisions</strong>
                    </h3>
                    <p>
                        A) It shall be understood by the parties that any
                        Architectural Exhibits attached to this Write Up, if
                        any, do not include Certified Architectural or Civil
                        Engineering Drawings as may be required for building
                        permit(s). If such drawing(s) become necessary they will
                        be the responsibility of the Borrower(s) or the
                        contractor. In the event that the subject property has a
                        private potable water or sanitation system, all required
                        tests or certifications as may be required shall be the
                        responsibility of the Borrower(s). All such reports
                        shall be submitted to the Consultant for Borrower(s)
                        review prior to completion of this report.
                    </p>

                    <p>
                        A) It shall be understood by the parties that any
                        Architectural Exhibits attached to this Write Up, if
                        any, do not include Certified Architectural or Civil
                        Engineering Drawings as may be required for building
                        permit(s). If such drawing(s) become necessary they will
                        be the responsibility of the Borrower(s) or the
                        contractor. In the event that the subject property has a
                        private potable water or sanitation system, all required
                        tests or certifications as may be required shall be the
                        responsibility of the Borrower(s). All such reports
                        shall be submitted to the Consultant for Borrower(s)
                        review prior to completion of this report.
                    </p>

                    <p>
                        B) Definitions of Terms as follows: 1) Serviceable: easy
                        to service, repairable instead of disposable, in
                        condition for use; 2) Adequate: equal to some
                        requirement, proportionate, correspondent, fully
                        sufficient; 3) Repair as Noted: to replace and/or repair
                        the item described; 4) Mandatory: work required for an
                        FHA health or safety item needing remediation or any
                        residual work thereon; 5) Requested: additional item
                        (non-requirement) requested by the Borrower(s); 6)
                        Recommended: replace/repair of any item with less than
                        an assumed three year life span; 7) Recommend further
                        review of repairs needed by a qualified licensed
                        contractor or specialty tradesman dealing with that item
                        or system: applies to further evaluation of any
                        construction Work category requiring a special permit,
                        certification, license or diploma that is not an
                        architect or engineer; 8) Recommend evaluation by a
                        licensed structural engineer / geotechnical engineer:
                        applies to deficient items that may be hidden or buried
                        conditions that must be quantified prior to mitigation;
                        9) Not visible at the time of inspection: at the time of
                        inspection the item was obscured by ice, snow,
                        vegetation or debris, safety issue preventing further
                        inspection; 10) Mandatory: work required to meet FHA
                        guidelines; 11) Recommended: work on items that are
                        approaching there mechanical limits or end of useful
                        life expectancy; Desired: Items requested by the
                        purchase that are not required to meet FHA Guidelines.
                    </p>

                    <p>
                        *C) The fee charged for this Work Write-Up is based on
                        the clients pre-determined valuation of work to be done
                        prior to a site visit by the Consultant and based on the
                        FHA Fee Guideline for Consultant Services; see
                        additional fee schedule previously submitted to the
                        Borrower(s) and attached to this agreement. Any
                        additional Consultant fees that may be applicable
                        subsequent to the Work Write-Up shall be in accordance
                        with those applicable fees and paid prior to submission
                        of any final report as may be required. There are no
                        refunds of consultants fees on loans that do not close
                        for any reason. Submission to the Lender of the
                        completed Work Write Up completes the contract between
                        the HUD Consultant and the Borrower(s).
                    </p>

                    <p>
                        D) This Consultant Inspection is a limited visual
                        inspection of certain readily accessible systems and
                        components using normal operating controls of the home
                        to identify any system or component which may be
                        significantly deficient at the time of inspection. The
                        scope of inspection is limited to the items within the
                        report pages.
                    </p>

                    <p>
                        E) Any area which is not exposed to view, is concealed,
                        is inaccessible because of soils, walls, floors,
                        carpets, ceilings, ceiling tiles, furnishings, stored
                        items, or other things is not included in this
                        inspection. The inspection does not include any
                        destructive testing or dismantling. Client agrees to
                        assume all risk for all conditions which are concealed
                        from view at the time of inspection. This is not a home
                        warranty, guarantee, insurance policy or substitute for
                        real estate disclosures which may be required by law.
                        Should the consultant exceed any particular requirement,
                        he shall not be obligated to exceed the requirements in
                        other areas.
                    </p>

                    <p>
                        Determining the presence of asbestos or hazardous
                        materials is beyond the scope of the inspection.
                        Further, the Consultant does not determine the
                        effectiveness of any system installed to control or
                        remove suspected hazardous substances.
                    </p>
                </td>
            </tr>

            <tr>
                <td>
                    <label>Initials: --------------- </label>
                </td>
            </tr>

            <tr>
                <td align="right">
                    <label>Loan Number: ---------------</label>
                </td>
            </tr>

            <tr>
                <td>
                    <p>
                        Whether or not they are concealed, the following
                        exclusions are outside the scope of this inspection:
                        Building code or zoning ordinance violations; Private
                        water or private sewage systems; Saunas, steam baths,
                        whirlpools, or fixtures and equipment; Radio-controlled
                        devices, automatic gates, elevators, lifts, dumbwaiters
                        and thermostatic or time controls; water
                        softener/purifier systems, sprinkler systems, or solar
                        heating systems; Furnace heat exchangers, freestanding
                        appliances, security alarms or personal property;
                        Geological stability or soils conditions; Engineering
                        analysis; Termites, pests or other wood destroying
                        organisms; Asbestos, radon, formaldehyde, lead, water or
                        air quality, electromagnetic radiation or any
                        environmental hazards; Any kinds of molds or mildew;
                        pools or pool related equipment, spas bodies and
                        underground piping;
                    </p>

                    <p>
                        F) Notice to Third Party Purchasers or users: Receipt of
                        the subject report by any purchasers of this property or
                        users other than the party(ies) identified on this
                        Agreement is not authorized by the Consultant and shall
                        not be used or disseminated, distributed or copied for
                        any other purpose and shall be strictly prohibited; this
                        shall include but not be limited to any Architectural
                        Exhibits, estimates, reports, photos, inspections and
                        opinions prepared by this consultant whether in part or
                        in entirety.
                    </p>

                    <p>
                        G) It shall be the responsibility of the Borrower(s)(s)
                        to: a) verify the issuance of a Building Permit or lack
                        thereof for any and all previous remodels or renovations
                        on the property that may have been required at the time
                        of such construction and compliance with such provisions
                        if any; b) obtain a municipal building compliance
                        inspection if one is required prior to the transfer of
                        the property.
                    </p>

                    <p>
                        H) The owner will provide to the Contractor, prior to
                        the start of work, all selections or control budgets for
                        any items which are not categorized in the Consultants
                        Report, all others shall be considered as "match
                        existing" unless otherwise noted; It shall be the
                        responsibility of the owner to coordinate his efforts
                        with his parties; The Consultant is not the General
                        Contractor nor the Project Manager and is not
                        responsible for the means, methods, or techniques used
                        on or during the project and shall not be involved in
                        selecting products or materials unless otherwise noted
                        in this report; The Consultant will not be responsible
                        for terms, errors or omissions on any proposal,
                        agreement, contract, or otherwise between the
                        Borrower(s) and the Contractor(s).
                    </p>

                    <p>
                        I) Mandatory Inspections: FHA Guidelines mandate that
                        the Work must begin within 30 days of closing and the
                        Work cannot be suspended for a period longer than 30
                        days. In order to verify this FHA Guideline compliance
                        and for this consultant to provide such assurance to the
                        lender it is recommended that your project be inspected
                        every 30 days to verify progress. Example: Your loan
                        closed on 1/1 therefore an inspection is required by
                        this consultant on or before 2/1.
                    </p>

                    <p>
                        J) Note: The Consultants Inspection is performed to
                        identify those items that are required for a property to
                        comply with HUDs Minimum Property Requirements as
                        defined in HUD Handbook 4000.1. Unless otherwise
                        indicated by the Consultant, this inspection is NOT
                        PERFORMED ACCORDING TO THE STANDARDS OF PRACTICE OF A
                        HOME INSPECTION NOR SHOULD IT BE VIEWED AS ANY FORM OF
                        CERTIFICATION BY A LICENSED PROFESSIONAL. THESE
                        ADDITIONAL SERVICES MAY BE BENEFICIAL FOR THE BORROWER.
                        The borrower may wish to consider the above recommended
                        inspections to further protect their interests beyond
                        scope of the inspection.
                    </p>

                    <p>
                        K) Architectural Exhibits may be required by the
                        Consultant for inclusion into the final Report,
                        (referred to by HUD as Architectural Exhibits) and may
                        include but not limited to: interior floor plans,
                        *design drawings, kitchen cabinet elevations, Plat of
                        Survey, site plan, radon inspection, termite
                        inspections, mold inspections, asbestos inspections,
                        zoning certifications, city code compliance inspections,
                        plumbing riser diagrams, heat loss calculations for new
                        heating systems, etc. The Borrower(s) may provide their
                        own exhibits. Please note, submission of such exhibits
                        is time sensitive.
                    </p>

                    <p>
                        *Design Drawings: interior floor plans and kitchen
                        cabinet elevations must display accurate dimensions.
                        Such designs must include detailed as-built dimensions
                        in addition to the new design.
                    </p>
                </td>
            </tr>

            <tr>
                <td>
                    <label>Initials -------------</label>
                </td>
            </tr>

            <tr>
                <td align="right">
                    <label>Loan Number: -------------</label>
                </td>
            </tr>
            <tr>
                <td>
                    <p>
                        L) 1) Change Order preparation during the project may be
                        billed @ ${{ fhaliabilityagreement.ChangeOrder }} or 3%,
                        whichever is greater) per Change Order; All fees will be
                        billed to the Borrower(s) at completion of each prepared
                        Change Order. Some Lenders may pay these fees from
                        contingency.
                    </p>

                    <p>
                        2) Draw Requests: are billed directly to the buyer at
                        each draw interval @ ${{
                            fhaliabilityagreement.DrawRequestsMin
                        }}
                        to ${{
                            fhaliabilityagreement.DrawRequestsMax
                        }}
                        depending on the scope of the project and specific
                        detail required by each Lender;(HUD will allow up to 5
                        draws initially; any unused inspection fees will be
                        credited to the Borrower(s)).
                    </p>

                    <p>
                        3) Change in Scope of Work requested after the initial
                        Specification of Repairs (SOR) is completed is billed @
                        ${{ fhaliabilityagreement.ChangeScopeWorkMin }} to ${{
                            fhaliabilityagreement.ChangeScopeWorkMax
                        }}; or after initial report is submitted. All fees are
                        paid by the purchaser prior to submission of the final
                        report.
                    </p>

                    <p>
                        4) Additional $25 per unit is charged for multi-family
                        dwellings per HUD guidelines.
                    </p>

                    <p>
                        5) *Mileage is included and based in IRS mileage rate
                        for calendar year (round trip); All fees are included in
                        the draws and paid from escrow during the course of the
                        project.
                    </p>

                    <p>
                        6) Work Plan (${{ fhaliabilityagreement.WorkPlan }} )
                        This is a categorical list of the Work Items taken from
                        the Consultants Cost Estimate broken down into labor and
                        materials for each Work Item; may also be known as the
                        Contractors Bid. HUD requires this be provided by the
                        Owner, however, the Consultant may prepare this Work
                        Plan for the Contractor(s) who then submit it to the
                        Owner.
                    </p>

                    <p>
                        The Borrower(s) is/are responsible for any additional
                        testing services or certifications that may be required
                        by the Consultants Report for the purpose of this
                        rehabilitation loan.
                    </p>
                </td>
            </tr>

            <tr>
                <td>
                    <label>Initials</label>
                    <input
                        type="text"
                        style="border-bottom: #000 solid 1px; background: none"
                    />
                </td>
            </tr>

            <tr>
                <td>
                    <h3
                        style="
                            text-align: center;
                            font-size: 20px;
                            border-top: #000 solid 2px;
                        "
                    >
                        <strong
                            >ACCEPTANCE AND UNDERSTANDING OF THIS AGREEMENT ARE
                            HEREBY ACKNOWLEDGED:</strong
                        >
                    </h3>
                </td>
            </tr>

            <tr>
                <td>
                    <p>
                        <strong>Borrower Signature ------------------</strong>
                    </p>
                </td>
            </tr>

            <tr>
                <td>
                    <p>
                        <strong>BO Sign Date ------------------</strong>
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>
                        <strong>Print Name ------------------</strong>
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>
                        <strong>Consultant Signature ------------------</strong>
                    </p>
                </td>
            </tr>

            <tr>
                <td>
                    <p>
                        <strong>CO Sign Date ------------------</strong>
                    </p>
                </td>
            </tr>

            <tr>
                <td>
                    <p>
                        <strong>Print Name ------------------</strong>
                    </p>
                </td>
            </tr>
        </table>

        <table
            id="IdentityOfInterest"
            #IdentityOfInterest
            style="display: none; font-weight: bold"
        >
            <tr>
                <td>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                </td>
            </tr>

            <tr>
                <td>
                    <div style="width: 150px">&nbsp;</div>
                </td>
                <td style="text-align: right; border-bottom: #000 solid 2px">
                    The leader in 203k renovation
                    <br />
                    consuling in new england
                    <br />
                    <strong>admin@amconsulte.com</strong>
                </td>
            </tr>

            <tr>
                <td>
                    <h3
                        style="
                            text-align: center;
                            font-size: 28px;
                            border-bottom: #000 solid 2px;
                        "
                    >
                        <strong>
                            Consultant's Identity-Of-Interest
                            Certification</strong
                        >
                    </h3>
                </td>
            </tr>

            <tr>
                <td align="center">
                    <p *ngIf="borrowerDetails.Id">
                        Borrower's Name(s): {{ borrowerDetails.FirstName }}
                        {{ borrowerDetails.LastName }}
                    </p>
                    <p *ngIf="propertyDetailsShow">
                        Property Address:
                        {{
                            propertyDetails?.InspectionAddress?.address1
                                ? propertyDetails?.InspectionAddress?.address1 +
                                  ", "
                                : ""
                        }}
                        {{
                            propertyDetails?.InspectionAddress?.address2
                                ? propertyDetails?.InspectionAddress?.address2 +
                                  ", "
                                : ""
                        }}
                        {{
                            propertyDetails?.InspectionAddress?.city
                                ? propertyDetails?.InspectionAddress?.city +
                                  ", "
                                : ""
                        }}
                        {{
                            propertyDetails?.InspectionAddress?.state
                                ? propertyDetails?.InspectionAddress?.state +
                                  ", "
                                : ""
                        }}
                        {{ propertyDetails?.InspectionAddress?.zipcode }}
                    </p>
                    <p *ngIf="propertyDetailsShow">
                        FHA Case No: {{ propertyDetails.FHACaseNo }}
                    </p>
                </td>
            </tr>

            <tr>
                <td>
                    <p>
                        "I hereby certify that I have carefully inspected this
                        property for compliance with the general acceptability
                        requirements (including health and safety) in Handbook
                        4000.1. I have required as neccessary and reviewed the
                        architectural exhibits, including any applicable
                        engineering and termite reports, and the estimated
                        rehabilitation cost and they are acceptable for the
                        rehabilitation of the property. I have no personal
                        interest, present or prospective, in this property,
                        applicant or proceeds of the mortgage. I also certify
                        that I have no identity-of-interest or conflict-of
                        interest with the borrower, seller, lender, realtor,
                        appraiser, plan reviewer, contractor or subcontractor.
                        To the best of my knowledge, I have reported all items
                        requiring correction and that the rehabilitation
                        proposal now meets all HUD requirements for
                        Rehabilitation Mortgage Insurance."
                    </p>
                </td>
            </tr>

            <tr>
                <td>
                    <p>
                        <strong>
                            Warning: HUD will prosecute false claims and
                            statements. Conviction may result in criminal and/or
                            civil penalties.
                        </strong>
                    </p>
                </td>
            </tr>

            <tr>
                <td>
                    <p>(18 U.S.C. 1001, 1010, 1012; 31 U.S.C 3729, 3802).</p>
                </td>
            </tr>

            <tr>
                <td>
                    <p>
                        <strong
                            >Consultant/Plan Reviewer's Signature:
                            ------------------</strong
                        >
                    </p>
                </td>
            </tr>

            <tr>
                <td>
                    <p>
                        <strong>Consultant: ------------------</strong>
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>
                        <strong>Date: ------------------</strong>
                    </p>
                </td>
            </tr>
            <br />
            <br />
            <br />
            <br />
            <br />
        </table>

        <table
            id="CertificationLetter"
            #CertificationLetter
            style="display: none; font-weight: bold"
        >
            <tr>
                <td>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                </td>
            </tr>

            <tr>
                <td>
                    <div style="width: 150px">&nbsp;</div>
                </td>
                <td style="text-align: right; border-bottom: #000 solid 2px">
                    The leader in 203k renovation
                    <br />
                    consuling in new england
                    <br />
                    <strong>admin@amconsulte.com</strong>
                </td>
            </tr>

            <tr>
                <td>
                    <h3
                        style="
                            text-align: center;
                            font-size: 28px;
                            border-bottom: #000 solid 2px;
                        "
                    >
                        <strong> Certification Letter</strong>
                    </h3>
                    <h4
                        style="
                            text-align: center;
                            font-size: 16px;
                            border-bottom: #000 solid 2px;
                        "
                    >
                        <strong> HUD 203k</strong>
                    </h4>
                </td>
            </tr>

            <tr>
                <td align="center">
                    <p *ngIf="borrowerDetails.Id">Date:</p>
                    <p *ngIf="borrowerDetails.Id">
                        Buyer's Name: {{ borrowerDetails.FirstName }}
                        {{ borrowerDetails.LastName }}
                    </p>
                    <p *ngIf="propertyDetailsShow">
                        Address:
                        {{
                            propertyDetails?.InspectionAddress?.address1
                                ? propertyDetails?.InspectionAddress?.address1 +
                                  ", "
                                : ""
                        }}
                        {{
                            propertyDetails?.InspectionAddress?.address2
                                ? propertyDetails?.InspectionAddress?.address2 +
                                  ", "
                                : ""
                        }}
                    </p>
                    <p *ngIf="propertyDetailsShow">
                        {{
                            propertyDetails?.InspectionAddress?.city
                                ? propertyDetails?.InspectionAddress?.city +
                                  ", "
                                : ""
                        }}
                        {{
                            propertyDetails?.InspectionAddress?.state
                                ? propertyDetails?.InspectionAddress?.state +
                                  ", "
                                : ""
                        }}
                        {{ propertyDetails?.InspectionAddress?.zipcode }}
                    </p>
                    <p *ngIf="borrowerDetails.Id">
                        Phone Number: {{ borrowerDetails.Phone }}
                    </p>
                    <p *ngIf="propertyDetailsShow">
                        FHA Case Number: {{ propertyDetails.FHACaseNo }}
                    </p>
                </td>
            </tr>
            <br />
            <tr>
                <td>
                    <p>
                        I hereby certify that I have carefully inspected the
                        following property at:
                        {{
                            propertyDetails?.InspectionAddress?.address1
                                ? propertyDetails?.InspectionAddress?.address1 +
                                  ", "
                                : ""
                        }}
                        {{
                            propertyDetails?.InspectionAddress?.address2
                                ? propertyDetails?.InspectionAddress?.address2 +
                                  ", "
                                : ""
                        }}
                        {{
                            propertyDetails?.InspectionAddress?.city
                                ? propertyDetails?.InspectionAddress?.city +
                                  ", "
                                : ""
                        }}
                        {{
                            propertyDetails?.InspectionAddress?.state
                                ? propertyDetails?.InspectionAddress?.state +
                                  ", "
                                : ""
                        }}
                        {{ propertyDetails?.InspectionAddress?.zipcode }}
                        <!-- {{(propertyDetails?.InspectionAddress?.address1)?propertyDetails?.InspectionAddress?.address1+', ':''}} {{(propertyDetails?.InspectionAddress?.address2)?propertyDetails?.InspectionAddress?.address2+', ':''}}
            {{propertyDetails?.InspectionAddress?.zipcode}} -->
                    </p>
                    <p>
                        for compliance with the general acceptability
                        requirements (including health and safety) in Handbook
                        4905.1 or its successors.
                    </p>
                    <p>
                        I have reviewed the attached architectural exhibits and
                        the estimated rehabilitation costs of this property, and
                        have determined them to be acceptable.
                    </p>
                    <p>
                        I have no personal interest, present or prospective, in
                        the property, applicant, or proceeds of the mortgage.
                    </p>
                    <p>
                        To the best of my knowledge I have reported all items
                        requiring correction and that the rehabilitation
                        proposal now meets all HUD requirements for 203(k)
                        Rehabilitation Mortgage Insurance (Handbook 4240.4 or
                        its successors).
                    </p>
                </td>
            </tr>

            <tr>
                <td>
                    <p>
                        <strong>Date: ------------------</strong>
                    </p>
                </td>
            </tr>

            <tr>
                <td>
                    <p>
                        <strong>HUD Consultant: ------------------</strong>
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>
                        <strong>ID Number: ------------------</strong>
                    </p>
                </td>
            </tr>
        </table>

        <!-- 
   <tr>
      <td>
     <table><tr>

      <td align="center">
        <img width="250" src="assets/images/logos/am_logo.jpg">
       </td>
  
       <td align="center">
        <div>The leader in 203k renovation</div>
        <div>consuling in new england</div>
        <div><strong>admin@amconsulte.com</strong></div>
       </td>
  
     </tr></table>
    </td>
    </tr> -->

        <table
            id="ConsultantAgreement"
            #ConsultantAgreement
            style="display: none"
        >
            <tr>
                <td>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                </td>
            </tr>

            <tr>
                <td>
                    <div style="width: 150px">&nbsp;</div>
                </td>
                <td style="text-align: right; border-bottom: #000 solid 2px">
                    The leader in 203k renovation
                    <br />
                    consuling in new england
                    <br />
                    <strong>admin@amconsulte.com</strong>
                </td>
            </tr>

            <tr>
                <td>
                    <h3
                        style="
                            text-align: center;
                            font-size: 24px;
                            border-top: #000 solid 2px;
                        "
                    >
                        <strong> FHA/FNMA CONSULTANT AGREEMENT</strong>
                    </h3>
                </td>
            </tr>

            <tr>
                <td>
                    <p>
                        This agreement is made on
                        <span>{{
                            fhaconsultantagreement.SignatureDateFHA
                        }}</span>
                        between
                        <span *ngIf="borrowerDetails.Id"
                            >{{ borrowerDetails.FirstName }}
                            {{ borrowerDetails.LastName }}
                        </span>
                        (the Borrower) and {{ consultantDetails.FirstName }}
                        {{ consultantDetails.LastName }} (the Consultant) in
                        connection with the Borrowers application for an
                        acquisition and renovation loan from Finance of America
                        (the Lender) pursuant to HUDs 203(k) Rehabilitation
                        Mortgage Insurance Program (the "Program") with respect
                        to the Property known as
                        <span *ngIf="propertyDetailsShow"
                            >{{
                                propertyDetails?.InspectionAddress?.address1
                                    ? propertyDetails?.InspectionAddress
                                          ?.address1 + ", "
                                    : ""
                            }}
                            {{
                                propertyDetails?.InspectionAddress?.address2
                                    ? propertyDetails?.InspectionAddress
                                          ?.address2 + ", "
                                    : ""
                            }}
                            {{
                                propertyDetails?.InspectionAddress?.city
                                    ? propertyDetails?.InspectionAddress?.city +
                                      ", "
                                    : ""
                            }}
                            {{
                                propertyDetails?.InspectionAddress?.state
                                    ? propertyDetails?.InspectionAddress
                                          ?.state + ", "
                                    : ""
                            }}
                            {{ propertyDetails?.InspectionAddress?.zipcode }}
                        </span>
                        (the Property).
                    </p>

                    <p>
                        Borrower acknowledges and understands that the Lender
                        has selected the Consultant in accordance with HUD
                        regulations; that the Consultant is a HUD certified
                        203(k) Consultant; and that the Consultants services
                        hereunder are performed exclusively on behalf of the
                        Lender to assure that the proposed rehabilitation of the
                        Property addresses health and safety concerns to at
                        least minimum Federal Housing Administration standards
                        after the proposed repairs are complete. Borrower
                        further acknowledges, agrees and understands that
                        Borrower is solely responsible for contracting with a
                        general contractor and obtaining all necessary permits
                        and approvals and for notifying and requesting of
                        appropriate authorities necessary inspections and
                        obtaining necessary and/or appropriate certifications
                        ensuring that the Property meets all applicable local
                        zoning, health and safety codes, regulations and
                        standards.
                    </p>

                    <p>
                        In connection with the Borrowers application, the
                        Borrower hereby engages the Consultant to assist with
                        the preparation of the Borrowers Work Write-Up and Cost
                        Estimate that are required by the Lender in connection
                        with underwriting a loan pursuant to HUDs Section 203(k)
                        Conventional Rehabilitation Mortgage Insurance Program
                        as it pertains to the architectural concerns for the
                        Property.
                    </p>

                    <p>It is mutually understood and agreed as follows:</p>
                </td>
            </tr>

            <tr>
                <td>
                    <h3>
                        <strong>1. INITIAL WALK THROUGH:</strong>
                    </h3>

                    <p>
                        a) The Consultant will accompany the Borrower (or the
                        Borrowers agent and/or contractor) during a walk-through
                        of the Property, during which the Property will be
                        initially analyzed for compliance with the 203(k)
                        Rehabilitation Mortgage Insurance Program. The
                        Consultant will then recommend repairs and modifications
                        that in the Consultants opinion will be necessary to
                        comply with the 203(k) Program requirements.
                        Additionally, the Consultant will recommend repairs and
                        modifications that in the Consultants opinion would
                        enhance the Property without being required by the
                        Program. Finally, the Consultant will consider any
                        additional items desired by the Borrower. The Consultant
                        will then assist the Borrower in identifying the items,
                        which will be included in and define the Work Write-up.
                        <strong>(NOTE:</strong> The Work Write-up must include
                        those items in the Consultants opinion will be necessary
                        to comply with the 203(k) Program requirements.)
                    </p>

                    <p>
                        b) The Walk-through and Work Write-up shall be limited
                        to readily accessible areas of the Property and to
                        visual observations of apparent and readily observable
                        conditions existing at the time of the Walk-through
                        only. Latent and concealed defects and deficiencies are
                        excluded from the Work Write-up. Dismantling and/or
                        extensive inspection of internal components of any
                        appliance or system including heaters and heat
                        exchangers is beyond the scope of the Walk-through and
                        Work Write-up. Accordingly, any conditions or defects
                        that are discovered or revealed during or as a result of
                        the rehabilitation of the Property shall be the
                        responsibility of the Borrower to rectify, either
                        through the Borrowers own funds or, if approved by the
                        Lender, through Change Orders submitted by the Borrower
                        to the Lender.
                    </p>

                    <p>
                        c) The initial Walk-through and Work Write-up is not a
                        compliance inspection or certification for past or
                        present governmental codes or regulations of any kind.
                        The Work Write-up does not address and is not intended
                        to address the possible presence of or danger from any
                        potentially harmful substances and environmental
                        hazards, including but not limited to radon, gas, lead
                        paint, asbestos, urea formaldehyde, toxic or flammable
                        chemicals and water and airborne hazards, or the
                        condition of or compliance of swimming pools, wells,
                        septic systems, central vacuum systems, water softeners,
                        sprinkler systems, and fire equipment with local or
                        state codes, ordinances of regulations of any kind.
                    </p>

                    <p>
                        d)
                        <strong
                            >The initial Walk-through and the Work Write-up are
                            not a substitute for a professional Home Inspection,
                            which may be desirable to protect the Borrowers
                            interests.</strong
                        >
                        Further, any specialist inspections required by the
                        Consultant to determine the condition of building
                        elements such as, but not limited to, mechanical
                        inspections, engineering inspections, health and safety
                        inspections, wood boring insect inspections, lead paint
                        inspections, and septic system inspections will be
                        obtained and paid for by the Borrower and provided to
                        the Consultant.
                    </p>
                </td>
            </tr>

            <tr>
                <td>
                    <h3>
                        <strong>2 THE WORK WRITE UP:</strong>
                    </h3>

                    <p>
                        <strong>After the Initial Walk-Through, the</strong>
                        Consultant will assist the Borrower with the preparation
                        of the Work Write-up and Cost Estimate describing the
                        proposed rehabilitation and its projected expense and
                        with the HUD required Draw Request summarizing the HUD
                        required rehabilitation categories and line item cost
                        estimates. The Consultant will assure that the Work
                        Write-up, Cost Estimates and the Draw Request are
                        properly prepared in accordance with HUD requirements.
                    </p>

                    <p>
                        b) The Cost Write-Up and Cost Estimate must include
                        repairs and improvements addressing health and safety
                        concerns, and these items cannot be eliminated from the
                        list of required repairs. The Borrower agrees,
                        acknowledges and understands that the repairs and
                        improvements contained in the Work Write-Up and Cost
                        Estimate, or any Change Orders thereto cannot exceed the
                        Program limits, and that they may have to put more money
                        out of their pocket into the project or reduce the
                        amount of improvements that they desire in order to stay
                        within Program limits and complete rehabilitation of the
                        Property. <strong>(NOTE:</strong> The Consultant assumes
                        no responsibility for changes made to the Work Write-up
                        without his/her subsequent input and written approval).
                    </p>

                    <p>
                        c) The Consultant shall use cost estimates that are
                        reasonable for the area in which the Property is
                        located. However, it is the Borrower's sole
                        responsibility to select, negotiate and contract with
                        general contractor(s) to ensure that the work performed
                        or to be performed on the Property is completed within
                        the cost estimates, to ensure that all necessary
                        permits, approvals and certifications are obtained and
                        that all work and materials to be furnished are
                        completed according to the state Building Code, as same
                        may from time to time be amended.
                    </p>

                    <p>
                        d) The Consultant shall not be liable for any cost
                        overruns or for additional work required to bring the
                        Property into compliance with any state or local
                        ordinances, regulations, standards or laws or to keep
                        the Property and rehabilitation thereof within Program
                        limits.
                    </p>

                    <p>
                        e) The Consultant will prepare and present to the
                        Borrower for his acceptance, and then to the Lender's
                        Underwriter copies of the Work Write-up and Cost
                        Estimates along with an initial Draw Request. The
                        Consultant will also submit to the Lender any
                        architectural drawings or inspection reports obtained by
                        the Borrower
                    </p>

                    <p>
                        f) New Construction additions and major planning changes
                        require architectural drawings that must be obtained and
                        paid for by the Borrower and reviewed by the Consultant
                        for acceptability. To the extent required, the Borrower
                        shall provide such plans to the Consultant, who shall
                        submit them to the Lender's Underwriter together with
                        the other documents referenced in subparagraph a) above.
                    </p>
                </td>
            </tr>

            <tr>
                <td>
                    <h3>
                        <strong>3 AGREEMENT DURATION:</strong>
                    </h3>

                    <p>
                        The Consultant will perform the services described
                        herein from the date of this Agreement to the date of
                        acceptance by the Borrower of the completed Work
                        Write-up, Cost Estimates and Initial Draw Request. As a
                        professional courtesy, the Consultant will remain
                        available to the Borrower during the underwriting
                        process by the Lender to clarify the submitted
                        documentation as requested by the Lender's Underwriter.
                    </p>
                </td>
            </tr>

            <tr>
                <td>
                    <h3>
                        <strong>4. CONSULTANT FEE(S):</strong>
                    </h3>

                    <p>
                        a) Prior to commencement of the services described
                        herein the Borrower will pay the Consultant a
                        non-refundable retainer fee of ${{
                            fhaconsultantagreement.ConsultantFee
                        }}. Any remaining fee is due and payable upon completion
                        of the Work Write-up and Cost Estimate. (With paid
                        receipts, the Borrower may be eligible to have this
                        expense fully or partially credited to their allowable
                        financeable closing costs.) Contact loan officer for
                        additional information.
                    </p>
                </td>
            </tr>

            <tr>
                <td>
                    <h3>
                        <strong>5. DRAW INSPECTIONS. </strong>
                    </h3>

                    <p>
                        a) The Consultant may be chosen by the Lender to serve
                        as its inspector post-closing during the rehabilitation
                        period in connection with the disbursement of funds from
                        the escrow account maintained by the Lender. Inspection
                        fees that apply to these postclosing compliance
                        inspections are separate and additional to this
                        Agreement and will be billed directly to the Lender as
                        these inspections occur, although they may be ultimately
                        passed by the Lender to the Borrower for payment as part
                        of the loan.
                    </p>

                    <p>
                        b) The Borrower understands, acknowledges and agrees
                        that to the extent that the Consultant acts as Draw
                        Inspector for the Lender, the Consultant is working for
                        and acting solely on behalf and as agent of the Lender,
                        to protect its interests as Mortgagee, and not for the
                        Borrower.
                    </p>

                    <p>
                        c) Should the Consultant be selected as Draw Inspector
                        for the Lender, the Borrower shall be required to sign
                        each and every Draw Request (in the form annexed hereto
                        as Exhibit A), acknowledging that all work performed by
                        the Borrower's general and subcontractors has been
                        performed to the Borrower's satisfaction and in a good
                        and workmanlike manner, and expressly releasing the
                        Consultant from any liability to the Borrower for
                        approving the Borrower's Draw Request to the Lender.
                    </p>
                </td>
            </tr>

            <tr>
                <td>
                    <h3>
                        <strong
                            >6. UNCONDITIONAL RELEASE AND LIMITATION OF
                            LIABILITY:</strong
                        >
                    </h3>

                    <p>
                        It is understood and agreed that the Consultant is not
                        an insurer and that the Walk-through, Work Write-up and
                        Cost Estimate are not intended as, nor are to be
                        construed as a guarantee or warranty of the adequacy,
                        performance or condition of any structure, item or
                        system at the Property or the ultimate cost of
                        repairing, replacing or renovating same. The Borrower
                        hereby releases and exempts A.M. Renovation Consulting
                        and its officers, agents, employees, successors and
                        assigns of and from all liability and responsibility for
                        the cost of repairing or replacing any latent,
                        undisclosed, unobservable, and/or unreported defect or
                        deficiency and for any consequential damage arising
                        there from or in connection therewith, as well as with
                        respect to all Property damage or personal injury of any
                        nature.In the event that the Consultant, its agents,
                        officers, employees, successors or assigns are found
                        liable due to breach of contract, breach of warranty,
                        negligence, negligent misrepresentation, negligent
                        hiring or any other theory of liability, then the
                        liability of the Consultant shall be limited to a sum
                        equal to the amount of the fee paid by the Borrower for
                        the services described herein.
                    </p>
                </td>
            </tr>

            <tr>
                <td>
                    <h3>
                        <strong>7. INDEMNIFICATION.</strong>
                    </h3>

                    <p>
                        In connection with the work performed by the Consultant
                        under this Agreement, including, without limitation, in
                        connection with the Initial Walk-Through, Work Write-Up,
                        Cost-Estimate, and post-closing Draw Inspections, during
                        the duration of this Agreement or thereafter, the
                        Borrower indemnifies, defends and holds harmless the
                        Consultant, its officers, agents, and employees (the
                        "Consultant") of and from all claims, actions, demands,
                        damages, judgments, and liability, including reasonable
                        attorney's fees and costs incurred by the Consultant in
                        defending against the same, brought or made or obtained
                        by any person or persons, including without limitation
                        the Borrower, for or on account of any injuries or
                        damages received or claimed to be received or sustained
                        arising out of or occasioned by the acts of the
                        Consultant, except in cases of gross negligence of the
                        Consultant, its agents or employees.
                    </p>
                </td>
            </tr>

            <tr>
                <td>
                    <h2 style="text-decoration: underline">
                        <strong
                            >ACCEPTANCE AND UNDERSTANDING OF THIS AGREEMENT ARE
                            HEREBY ACKNOWLEDGED:
                        </strong>
                    </h2>
                </td>
            </tr>

            <tr>
                <td>
                    <p>Borrower Signature ------------------</p>
                </td>
            </tr>

            <tr>
                <td>
                    <p>BO Sign Date ------------------</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>Mailing Address: ------------------</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>Date: ------------------</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>Consultant Signature ------------------</p>
                </td>
            </tr>

            <tr>
                <td>
                    <p>CO Sign Date ------------------</p>
                </td>
            </tr>

            <tr>
                <td>
                    <p *ngIf="consultantDetails.Id">
                        Print Name: {{ consultantDetails.FirstName }}
                        {{ consultantDetails.LastName }}
                    </p>
                </td>
            </tr>
        </table>
    </div>
</div>
