<div class="content p-24 tbl">
    <ngx-loading class="ngxLoader" 
        [show]="loading"
        [config]="{ backdropBorderRadius: '3px' }"
    ></ngx-loading>
    <h1 class="m-0">Emails</h1>
    <!-- <a *ngIf="isList" class="addBorrow" href="javascript:void(0)" (click)="isList=false;isAdd=true;isEdit=false;formReset()"><mat-icon _ngcontent-c102="" class="secondary-text s-22 mat-icon material-icons mat-icon-no-color" role="img" aria-hidden="true">add_circle</mat-icon> Add New Loan Officer</a>
       <a *ngIf="isAdd || isEdit" class="addBorrow" href="javascript:void(0)" (click)="isAdd=false;isList=true;isEdit=false"><mat-icon _ngcontent-c102="" class="secondary-text s-22 mat-icon material-icons mat-icon-no-color" role="img" aria-hidden="true">view_list</mat-icon> List Loan Officer</a> -->
    <!-- <mat-form-field>
     <input [hidden]="!isList" matInput (keyup)="applyFilter($event.target)" placeholder="Filter">
   </mat-form-field> -->
    <br />
    <mat-accordion class="example-headers-align" multi>
        <form
            [formGroup]="EmailSetting"
            class="example-form"
            (ngSubmit)="SaveEmailSetting()"
        >
            <mat-expansion-panel [expanded]="true">
                <mat-expansion-panel-header>
                    <mat-panel-title> GLOBAL EMAIL SETTINGS </mat-panel-title>
                </mat-expansion-panel-header>
                <mat-form-field>
                    <span class="mat-form-field-label-wrapper"
                        ><!----><label
                            class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                            >Reply To</label
                        ></span
                    >
                    <input matInput formControlName="ReplyToEmail" />
                </mat-form-field>
                <p>
                    Please fill Reply To email address.(Ex:example@domain.com)
                </p>
            </mat-expansion-panel>
            <br />
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        NEW ACCOUNT EMAIL TEMPLATES
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <!--<mat-expansion-panel>
               <mat-expansion-panel-header>
                   <mat-panel-title>
                       NOTIFY USER ABOUT REGISTRATION
                   </mat-panel-title>
               </mat-expansion-panel-header>
               <mat-form-field>
                   <input matInput formControlName="NewUserRegistrationBCC" placeholder="BCC Email">
               </mat-form-field>
               <p>Ex: test@xyz.com</p>
               <mat-form-field>
                   <input matInput placeholder="Mail Subject" formControlName="NewUserRegistrationSubject">
               </mat-form-field>
               <p>Tokens which can be used in (Mail Subject and Mail Body).<br>
                [comment],[property:LOEmail],[property:COEmail],[property:BOEmail], [property:RealtorEmail], [property:LPEmails], [property:GCEmail], [property:InvitedGCEmails],[property:SelectedGCEmail],[property:NotSelectedGCEmails],[property:propertyName],[property:propertyAddress],[property:consultantName],[property:borrowerName],[property:contractorName],[property:loanOfficerName],[property:realtorName],[property:siteLink],[property:borrowerLastName],[user:name],[user:password],[user:mail],[site:name],[site:link]</p>
               <label>Mail Body</label>
               <ckeditor [config]="{removeButtons : 'About,Source,Save,NewPage,Preview,Print,Find,Form,Checkbox,Radio,TextField,,Textarea,Select,Button,ImageButton,HiddenField,Language,Flash'}" matNativeControl name="editor1" formControlName="NewUserRegistrationBody" skin="moono-lisa" language="en" ></ckeditor>
           </mat-expansion-panel>
           <br> -->
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            NOTIFY BORROWER ABOUT REGISTRATION
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <mat-form-field>
                        <span class="mat-form-field-label-wrapper"
                            ><!----><label
                                class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                >BCC Email</label
                            ></span
                        >
                        <input
                            matInput
                            formControlName="BorrowerAboutRegistrationBCC"
                        />
                    </mat-form-field>
                    <p>Ex: test@xyz.com</p>
                    <mat-form-field>
                        <span class="mat-form-field-label-wrapper"
                            ><!----><label
                                class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                >Mail Subject</label
                            ></span
                        >
                        <input
                            matInput
                            formControlName="BorrowerAboutRegistrationSubject"
                        />
                        <div class="error" *ngIf="(!EmailSetting.controls['BorrowerAboutRegistrationSubject'].valid && EmailSetting.controls['BorrowerAboutRegistrationSubject'].touched) || (EmailSetting.controls['BorrowerAboutRegistrationSubject'].invalid && submitted)">
                            Please limit your input to 200 characters or less. 
                          </div>
                    </mat-form-field>
                    <p>
                        Tokens which can be used in (Mail Subject and Mail
                        Body).<br />
                        [user:name],[user:password],[user:mail],[site:name],[site:link]
                    </p>
                    <label>Mail Body</label>
                    <ckeditor
                        matNativeControl
                        name="editor1"
                        formControlName="BorrowerAboutRegistrationBody"
                        skin="moono-lisa"
                        language="en"
                    ></ckeditor>
                </mat-expansion-panel>
                <br />
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            NOTIFY GENERAL CONTRACTOR ABOUT REGISTRATION
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <mat-form-field>
                        <span class="mat-form-field-label-wrapper"
                            ><!----><label
                                class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                >BCC Email</label
                            ></span
                        >
                        <input
                            matInput
                            formControlName="GCAboutRegistrationBCC"
                        />
                    </mat-form-field>
                    <p>Ex: test@xyz.com</p>
                    <mat-form-field>
                        <span class="mat-form-field-label-wrapper"
                            ><!----><label
                                class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                >Mail Subject</label
                            ></span
                        >
                        <input
                            matInput
                            formControlName="GCAboutRegistrationSubject"
                        />
                        <div class="error" *ngIf="(!EmailSetting.controls['GCAboutRegistrationSubject'].valid && EmailSetting.controls['GCAboutRegistrationSubject'].touched) || (EmailSetting.controls['GCAboutRegistrationSubject'].invalid && submitted)">
                            Please limit your input to 200 characters or less. 
                          </div>
                    </mat-form-field>
                    <p>
                        Tokens which can be used in (Mail Subject and Mail
                        Body).<br />
                        [user:name],[user:password],[user:mail],[site:name],[site:link]
                    </p>
                    <label>Mail Body</label>
                    <ckeditor
                        matNativeControl
                        name="editor1"
                        formControlName="GCAboutRegistrationBody"
                        skin="moono-lisa"
                        language="en"
                    ></ckeditor>
                </mat-expansion-panel>
                <br />
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            NOTIFY LOAN OFFICER ABOUT REGISTRATION
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <mat-form-field>
                        <span class="mat-form-field-label-wrapper"
                            ><!----><label
                                class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                >BCC Email</label
                            ></span
                        >
                        <input
                            matInput
                            formControlName="LOAboutRegistrationBCC"
                        />
                    </mat-form-field>
                    <p>Ex: test@xyz.com</p>
                    <mat-form-field>
                        <span class="mat-form-field-label-wrapper"
                            ><!----><label
                                class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                >Mail Subject</label
                            ></span
                        >
                        <input
                            matInput
                            formControlName="LOAboutRegistrationSubject"
                        />
                        <div class="error" *ngIf="(!EmailSetting.controls['LOAboutRegistrationSubject'].valid && EmailSetting.controls['LOAboutRegistrationSubject'].touched) || (EmailSetting.controls['LOAboutRegistrationSubject'].invalid && submitted)">
                            Please limit your input to 200 characters or less. 
                          </div>
                    </mat-form-field>
                    <p>
                        Tokens which can be used in (Mail Subject and Mail
                        Body).<br />
                        [user:name],[user:password],[user:mail],[site:name],[site:link]
                    </p>
                    <label>Mail Body</label>
                    <ckeditor
                        matNativeControl
                        name="editor1"
                        formControlName="LOAboutRegistrationBody"
                        skin="moono-lisa"
                        language="en"
                    ></ckeditor>
                </mat-expansion-panel>
                <br />
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            NOTIFY DRAW ADMINISTRATOR ABOUT REGISTRATION
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <mat-form-field>
                        <span class="mat-form-field-label-wrapper"
                            ><!----><label
                                class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                >BCC Email</label
                            ></span
                        >
                        <input
                            matInput
                            formControlName="DAAboutRegistrationBCC"
                        />
                    </mat-form-field>
                    <p>Ex: test@xyz.com</p>
                    <mat-form-field>
                        <span class="mat-form-field-label-wrapper"
                            ><!----><label
                                class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                >Mail Subject</label
                            ></span
                        >
                        <input
                            matInput
                            formControlName="DAAboutRegistrationSubject"
                        />
                        <div class="error" *ngIf="(!EmailSetting.controls['DAAboutRegistrationSubject'].valid && EmailSetting.controls['DAAboutRegistrationSubject'].touched) || (EmailSetting.controls['DAAboutRegistrationSubject'].invalid && submitted)">
                            Please limit your input to 200 characters or less. 
                          </div>
                    </mat-form-field>
                    <p>
                        Tokens which can be used in (Mail Subject and Mail
                        Body).<br />
                        [user:name],[user:password],[user:mail],[site:name],[site:link]
                    </p>
                    <label>Mail Body</label>
                    <ckeditor
                        matNativeControl
                        name="editor1"
                        formControlName="DAAboutRegistrationBody"
                        skin="moono-lisa"
                        language="en"
                    ></ckeditor>
                </mat-expansion-panel>
            </mat-expansion-panel>
            <br />
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title> PRE-CLOSING EMAILS </mat-panel-title>
                </mat-expansion-panel-header>
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            PROPERTY FILE CREATED
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                RENOVATION PROJECT CREATED - REFINANCE
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <mat-form-field>
                            <span class="mat-form-field-label-wrapper"
                                ><!----><label
                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                    >Mail To</label
                                ></span
                            >
                            <input
                                matInput
                                formControlName="UsersAboutPropertyCreationMailTo"
                            />
                        </mat-form-field>
                        <p>
                            Please use below tokens. Multiple token should be
                            seperated by comma (,).<br />
                            [property:LOEmail],[property:COEmail],[property:BOEmail],[property:RealtorEmail],[property:LPEmails],[property:GCEmail],[property:InvitedGCEmails],[property:SelectedGCEmail],[property:NotSelectedGCEmails]
                        </p>
                        <mat-form-field>
                            <span class="mat-form-field-label-wrapper"
                                ><!----><label
                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                    >Subject</label
                                ></span
                            >
                            <input
                                matInput
                                formControlName="UsersAboutPropertyCreationSubject"
                            />
                            <div class="error" *ngIf="(!EmailSetting.controls['UsersAboutPropertyCreationSubject'].valid && EmailSetting.controls['UsersAboutPropertyCreationSubject'].touched) || (EmailSetting.controls['UsersAboutPropertyCreationSubject'].invalid && submitted)">
                                Please limit your input to 200 characters or less. 
                              </div>
                        </mat-form-field>
                        <p>
                            Tokens which can be used in (Mail Subject and Mail
                            Body).<br />
                            [comment],[property:LOEmail],[property:COEmail],[property:BOEmail],
                            [property:RealtorEmail],
                            [property:LPEmails],[property:LPNames],
                            [property:GCEmail],
                            [property:InvitedGCEmails],[property:SelectedGCEmail],[property:NotSelectedGCEmails],[property:propertyName],[property:propertyAddress],[property:consultantName],[property:borrowerName],[property:contractorName],[property:loanOfficerName],[property:realtorName],[property:siteLink],[property:borrowerLastName],[user:name],[user:password],[user:mail],[site:name],[site:link]
                        </p>
                        <label>Mail Body</label>
                        <ckeditor
                            matNativeControl
                            name="editor1"
                            formControlName="UsersAboutPropertyCreationBody"
                            skin="moono-lisa"
                            language="en"
                        ></ckeditor>
                    </mat-expansion-panel>
                    <br />
                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                RENOVATION PROJECT CREATED - PURCHASE
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <mat-form-field>
                            <span class="mat-form-field-label-wrapper"
                                ><!----><label
                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                    >Mail To</label
                                ></span
                            >
                            <input
                                matInput
                                formControlName="BOLOCOAboutNewPropertyWithPurchaseMailTo"
                            />
                        </mat-form-field>
                        <p>
                            Please use below tokens. Multiple token should be
                            seperated by comma (,).<br />
                            [property:LOEmail],[property:COEmail],[property:BOEmail],[property:RealtorEmail],[property:LPEmails],[property:GCEmail],[property:InvitedGCEmails],[property:SelectedGCEmail],[property:NotSelectedGCEmails]
                        </p>
                        <mat-form-field>
                            <span class="mat-form-field-label-wrapper"
                                ><!----><label
                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                    >Mail Subject</label
                                ></span
                            >
                            <input
                                matInput
                                formControlName="BOLOCOAboutNewPropertyWithPurchaseSubject"
                            />
                            <div class="error" *ngIf="(!EmailSetting.controls['BOLOCOAboutNewPropertyWithPurchaseSubject'].valid && EmailSetting.controls['BOLOCOAboutNewPropertyWithPurchaseSubject'].touched) || (EmailSetting.controls['BOLOCOAboutNewPropertyWithPurchaseSubject'].invalid && submitted)">
                                Please limit your input to 200 characters or less. 
                              </div>
                        </mat-form-field>
                        <p>
                            Tokens which can be used in (Mail Subject and Mail
                            Body).<br />
                            [comment],[property:LOEmail],[property:COEmail],[property:BOEmail],
                            [property:RealtorEmail], [property:LPEmails],
                            [property:LPNames],[property:GCEmail],
                            [property:InvitedGCEmails],[property:SelectedGCEmail],[property:NotSelectedGCEmails],[property:propertyName],[property:propertyAddress],[property:consultantName],[property:borrowerName],[property:contractorName],[property:loanOfficerName],[property:realtorName],[property:siteLink],[property:borrowerLastName],[user:name],[user:password],[user:mail],[site:name],[site:link]
                        </p>
                        <label>Mail Body</label>
                        <ckeditor
                            matNativeControl
                            name="editor1"
                            formControlName="BOLOCOAboutNewPropertyWithPurchaseBody"
                            skin="moono-lisa"
                            language="en"
                        ></ckeditor>
                    </mat-expansion-panel>
                    <br />
                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                NOTIFY RL ABOUT PROPERTY ASSIGNED
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <mat-form-field>
                            <span class="mat-form-field-label-wrapper"
                                ><!----><label
                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                    >Mail To</label
                                ></span
                            >
                            <input
                                matInput
                                formControlName="RealtorAboutPropertyAssignedMailTo"
                            />
                        </mat-form-field>
                        <p>
                            Please use below tokens. Multiple token should be
                            seperated by comma (,).<br />
                            [property:LOEmail],[property:COEmail],[property:BOEmail],[property:RealtorEmail],[property:LPEmails],[property:GCEmail],[property:InvitedGCEmails],[property:SelectedGCEmail],[property:NotSelectedGCEmails]
                        </p>
                        <mat-form-field>
                            <span class="mat-form-field-label-wrapper"
                                ><!----><label
                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                    >Mail Subject</label
                                ></span
                            >
                            <input
                                matInput
                                formControlName="RealtorAboutPropertyAssignedSubject"
                            />
                                <div class="error" *ngIf="(!EmailSetting.controls['RealtorAboutPropertyAssignedSubject'].valid && EmailSetting.controls['RealtorAboutPropertyAssignedSubject'].touched) || (EmailSetting.controls['RealtorAboutPropertyAssignedSubject'].invalid && submitted)">
                                    Please limit your input to 200 characters or less. 
                                </div>
                        </mat-form-field>
                        <p>
                            Tokens which can be used in (Mail Subject and Mail
                            Body).<br />
                            [comment],[property:LOEmail],[property:COEmail],[property:BOEmail],
                            [property:RealtorEmail],
                            [property:LPEmails],[property:LPNames],[property:GCEmail],
                            [property:InvitedGCEmails],[property:SelectedGCEmail],[property:NotSelectedGCEmails],[property:propertyName],[property:propertyAddress],[property:consultantName],[property:borrowerName],[property:contractorName],[property:loanOfficerName],[property:realtorName],[property:siteLink],[property:borrowerLastName],[user:name],[user:password],[user:mail],[site:name],[site:link]
                        </p>
                        <label>Mail Body</label>
                        <ckeditor
                            matNativeControl
                            name="editor1"
                            formControlName="RealtorAboutPropertyAssignedBody"
                            skin="moono-lisa"
                            language="en"
                        ></ckeditor>
                    </mat-expansion-panel>
                </mat-expansion-panel>
                <br />
                <!--<mat-expansion-panel>
               <mat-expansion-panel-header>
                   <mat-panel-title>
                       INITIAL INSPECTION COMPLETED
                   </mat-panel-title>
               </mat-expansion-panel-header>
               <mat-expansion-panel>
                   <mat-expansion-panel-header>
                       <mat-panel-title>
                           NOTIFY CONSULTANT/ LOAN OFFICER/ BORROWER WHEN GC AND/OR BIDDING DUE DATE ARE NO FILLED
                       </mat-panel-title>
                   </mat-expansion-panel-header>
                   <mat-form-field>
                       <input matInput placeholder="Mail To" formControlName="COLOBOWhenGCBiddingDueDateAreNoFilledMailTo">
                   </mat-form-field>
                   <p>Please use below tokens. Multiple token should be seperated by comma (,).<br>
                   [property:LOEmail],[property:COEmail],[property:BOEmail],[property:RealtorEmail],[property:LPEmails],[property:GCEmail],[property:InvitedGCEmails],[property:SelectedGCEmail],[property:NotSelectedGCEmails]</p>
                   <mat-form-field>
                   <input matInput placeholder="Mail Subject" formControlName="COLOBOWhenGCBiddingDueDateAreNoFilledSubject">
                   </mat-form-field>
                   <p>Tokens which can be used in (Mail Subject and Mail Body).<br>
                   [comment],[property:LOEmail],[property:COEmail],[property:BOEmail], [property:RealtorEmail], [property:LPEmails], [property:GCEmail], [property:InvitedGCEmails],[property:SelectedGCEmail],[property:NotSelectedGCEmails],[property:propertyName],[property:propertyAddress],[property:consultantName],[property:borrowerName],[property:contractorName],[property:loanOfficerName],[property:realtorName],[property:siteLink],[property:borrowerLastName],[user:name],[user:password],[user:mail],[site:name],[site:link]</p>
                   <label>Mail Body</label>
                   <ckeditor [config]="{removeButtons : 'About,Source,Save,NewPage,Preview,Print,Find,Form,Checkbox,Radio,TextField,,Textarea,Select,Button,ImageButton,HiddenField,Language,Flash'}" matNativeControl name="editor1" formControlName="COLOBOWhenGCBiddingDueDateAreNoFilledBody" skin="moono-lisa" language="en" ></ckeditor>
               </mat-expansion-panel>
           </mat-expansion-panel>
           <br> -->
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            DRAFT SOR SENT TO BORROWER
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                NOTIFY BORROWER FOR APPROVAL OF DRAFT SOR
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <mat-form-field>
                            <span class="mat-form-field-label-wrapper"
                                ><!----><label
                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                    >Mail To</label
                                ></span
                            >
                            <input
                                matInput
                                formControlName="BOForApprovalOfSpecificationOfRepairMailTo"
                            />
                        </mat-form-field>
                        <p>
                            Please use below tokens. Multiple token should be
                            seperated by comma (,).<br />
                            [property:LOEmail],[property:COEmail],[property:BOEmail],[property:RealtorEmail],[property:LPEmails],[property:GCEmail],[property:InvitedGCEmails],[property:SelectedGCEmail],[property:NotSelectedGCEmails]
                        </p>
                        <mat-form-field>
                            <span class="mat-form-field-label-wrapper"
                                ><!----><label
                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                    >Mail Subject</label
                                ></span
                            >
                            <input
                                matInput
                                formControlName="BOForApprovalOfSpecificationOfRepairSubject"
                            />
                            <div class="error" *ngIf="(!EmailSetting.controls['BOForApprovalOfSpecificationOfRepairSubject'].valid && EmailSetting.controls['BOForApprovalOfSpecificationOfRepairSubject'].touched) || (EmailSetting.controls['BOForApprovalOfSpecificationOfRepairSubject'].invalid && submitted)">
                                Please limit your input to 200 characters or less. 
                            </div>
                        </mat-form-field>
                        <p>
                            Tokens which can be used in (Mail Subject and Mail
                            Body).<br />
                            [comment],[property:LOEmail],[property:COEmail],[property:BOEmail],
                            [property:RealtorEmail],
                            [property:LPEmails],[property:LPNames],
                            [property:GCEmail],
                            [property:InvitedGCEmails],[property:SelectedGCEmail],[property:NotSelectedGCEmails],[property:propertyName],[property:propertyAddress],[property:consultantName],[property:borrowerName],[property:contractorName],[property:loanOfficerName],[property:realtorName],[property:siteLink],[property:borrowerLastName],[user:name],[user:password],[user:mail],[site:name],[site:link]
                        </p>
                        <label>Mail Body</label>
                        <ckeditor
                            matNativeControl
                            name="editor1"
                            formControlName="BOForApprovalOfSpecificationOfRepairBody"
                            skin="moono-lisa"
                            language="en"
                        ></ckeditor>
                    </mat-expansion-panel>
                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                NOTIFY BORROWER FOR APPROVAL EDITS OF DRAFT SOR
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <mat-form-field>
                            <span class="mat-form-field-label-wrapper"
                                ><!----><label
                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                    >Mail To</label
                                ></span
                            >
                            <input
                                matInput
                                formControlName="BOForApprovalOfEditSpecificationOfRepairMailTo"
                            />
                        </mat-form-field>
                        <p>
                            Please use below tokens. Multiple token should be
                            seperated by comma (,).<br />
                            [property:LOEmail],[property:COEmail],[property:BOEmail],[property:RealtorEmail],[property:LPEmails],[property:GCEmail],[property:InvitedGCEmails],[property:SelectedGCEmail],[property:NotSelectedGCEmails]
                        </p>
                        <mat-form-field>
                            <span class="mat-form-field-label-wrapper"
                                ><!----><label
                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                    >Mail Subject</label
                                ></span
                            >
                            <input
                                matInput
                                formControlName="BOForApprovalOfEditSpecificationOfRepairSubject"
                            />
                            <div class="error" *ngIf="(!EmailSetting.controls['BOForApprovalOfEditSpecificationOfRepairSubject'].valid && EmailSetting.controls['BOForApprovalOfEditSpecificationOfRepairSubject'].touched) || (EmailSetting.controls['BOForApprovalOfEditSpecificationOfRepairSubject'].invalid && submitted)">
                                Please limit your input to 200 characters or less. 
                            </div>
                        </mat-form-field>
                        <p>
                            Tokens which can be used in (Mail Subject and Mail
                            Body).<br />
                            [comment],[property:LOEmail],[property:COEmail],[property:BOEmail],
                            [property:RealtorEmail],
                            [property:LPEmails],[property:LPNames],
                            [property:GCEmail],
                            [property:InvitedGCEmails],[property:SelectedGCEmail],[property:NotSelectedGCEmails],[property:propertyName],[property:propertyAddress],[property:consultantName],[property:borrowerName],[property:contractorName],[property:loanOfficerName],[property:realtorName],[property:siteLink],[property:borrowerLastName],[user:name],[user:password],[user:mail],[site:name],[site:link]
                        </p>
                        <label>Mail Body</label>
                        <ckeditor
                            matNativeControl
                            name="editor1"
                            formControlName="BOForApprovalOfEditSpecificationOfRepairBody"
                            skin="moono-lisa"
                            language="en"
                        ></ckeditor>
                    </mat-expansion-panel>
                </mat-expansion-panel>
                <br />
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            ESTIMATE PROCESS STARTED
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <!--<mat-expansion-panel>
                   <mat-expansion-panel-header>
                       <mat-panel-title>
                           NOTIFY LOAN OFFICER FOR APPROVAL OF SPECIFICATION OF REPAIR
                       </mat-panel-title>
                   </mat-expansion-panel-header>
                   <mat-form-field>
                       <input matInput placeholder="Mail To" formControlName="LOForApprovalOfSpecificationOfRepairMailTo">
                   </mat-form-field>
                   <p>Please use below tokens. Multiple token should be seperated by comma (,).<br>
                       [property:LOEmail],[property:COEmail],[property:BOEmail],[property:RealtorEmail],[property:LPEmails],[property:GCEmail],[property:InvitedGCEmails],[property:SelectedGCEmail],[property:NotSelectedGCEmails]</p>
                   <mat-form-field>
                       <input matInput placeholder="Mail Subject" formControlName="LOForApprovalOfSpecificationOfRepairSubject">
                   </mat-form-field>
                   <p>Tokens which can be used in (Mail Subject and Mail Body).<br>
                       [comment],[property:LOEmail],[property:COEmail],[property:BOEmail], [property:RealtorEmail], [property:LPEmails], [property:GCEmail], [property:InvitedGCEmails],[property:SelectedGCEmail],[property:NotSelectedGCEmails],[property:propertyName],[property:propertyAddress],[property:consultantName],[property:borrowerName],[property:contractorName],[property:loanOfficerName],[property:realtorName],[property:siteLink],[property:borrowerLastName],[user:name],[user:password],[user:mail],[site:name],[site:link]</p>
                       <label>Mail Body</label>
                       <ckeditor [config]="{removeButtons : 'About,Source,Save,NewPage,Preview,Print,Find,Form,Checkbox,Radio,TextField,,Textarea,Select,Button,ImageButton,HiddenField,Language,Flash'}" matNativeControl name="editor1" formControlName="LOForApprovalOfSpecificationOfRepairBody" skin="moono-lisa" language="en" ></ckeditor>
               </mat-expansion-panel>
               <br> -->
                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                DRAFT SOR ACCEPTED BY BO
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <mat-form-field>
                            <span class="mat-form-field-label-wrapper"
                                ><!----><label
                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                    >Mail To</label
                                ></span
                            >
                            <input
                                matInput
                                formControlName="COLOWhenSpecificationOfRepairAcceptedMailTo"
                            />
                        </mat-form-field>
                        <p>
                            Please use below tokens. Multiple token should be
                            seperated by comma (,).<br />
                            [property:LOEmail],[property:COEmail],[property:BOEmail],[property:RealtorEmail],[property:LPEmails],[property:GCEmail],[property:InvitedGCEmails],[property:SelectedGCEmail],[property:NotSelectedGCEmails]
                        </p>
                        <mat-form-field>
                            <span class="mat-form-field-label-wrapper"
                                ><!----><label
                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                    >Mail Subject</label
                                ></span
                            >
                            <input
                                matInput
                                formControlName="COLOWhenSpecificationOfRepairAcceptedSubject"
                            />
                            <div class="error" *ngIf="(!EmailSetting.controls['COLOWhenSpecificationOfRepairAcceptedSubject'].valid && EmailSetting.controls['COLOWhenSpecificationOfRepairAcceptedSubject'].touched) || (EmailSetting.controls['COLOWhenSpecificationOfRepairAcceptedSubject'].invalid && submitted)">
                                Please limit your input to 200 characters or less. 
                            </div>
                        </mat-form-field>
                        <p>
                            Tokens which can be used in (Mail Subject and Mail
                            Body).<br />
                            [comment],[property:LOEmail],[property:COEmail],[property:BOEmail],
                            [property:RealtorEmail],
                            [property:LPEmails],[property:LPNames],
                            [property:GCEmail],
                            [property:InvitedGCEmails],[property:SelectedGCEmail],[property:NotSelectedGCEmails],[property:propertyName],[property:propertyAddress],[property:consultantName],[property:borrowerName],[property:contractorName],[property:loanOfficerName],[property:realtorName],[property:siteLink],[property:borrowerLastName],[user:name],[user:password],[user:mail],[site:name],[site:link]
                        </p>
                        <label>Mail Body</label>
                        <ckeditor
                            matNativeControl
                            name="editor1"
                            formControlName="COLOWhenSpecificationOfRepairAcceptedBody"
                            skin="moono-lisa"
                            language="en"
                        ></ckeditor>
                    </mat-expansion-panel>
                    <br />
                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                NOTIFY USERS A GC HAS BEEN ADDED TO THE PROJECT
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <mat-form-field>
                            <span class="mat-form-field-label-wrapper"
                                ><!----><label
                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                    >Mail To</label
                                ></span
                            >
                            <input
                                matInput
                                formControlName="COLOBOGCWhenBORProcessStartMailTo"
                            />
                        </mat-form-field>
                        <p>
                            Please use below tokens. Multiple token should be
                            seperated by comma (,).<br />
                            [property:LOEmail],[property:COEmail],[property:BOEmail],[property:RealtorEmail],[property:LPEmails],[property:GCEmail],[property:InvitedGCEmails],[property:SelectedGCEmail],[property:NotSelectedGCEmails]
                        </p>
                        <mat-form-field>
                            <span class="mat-form-field-label-wrapper"
                                ><!----><label
                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                    >Mail Subject</label
                                ></span
                            >
                            <input
                                matInput
                                formControlName="COLOBOGCWhenBORProcessStartSubject"
                            />
                            <div class="error" *ngIf="(!EmailSetting.controls['COLOBOGCWhenBORProcessStartSubject'].valid && EmailSetting.controls['COLOBOGCWhenBORProcessStartSubject'].touched) || (EmailSetting.controls['COLOBOGCWhenBORProcessStartSubject'].invalid && submitted)">
                                Please limit your input to 200 characters or less. 
                            </div>
                        </mat-form-field>
                        <p>
                            Tokens which can be used in (Mail Subject and Mail
                            Body).<br />
                            [comment],[property:LOEmail],[property:COEmail],[property:BOEmail],
                            [property:RealtorEmail], [property:LPEmails],
                            [property:LPNames],[property:GCEmail],
                            [property:InvitedGCEmails],[property:SelectedGCEmail],[property:NotSelectedGCEmails],[property:propertyName],[property:propertyAddress],[property:consultantName],[property:borrowerName],[property:contractorName],[property:loanOfficerName],[property:realtorName],[property:siteLink],[property:borrowerLastName],[user:name],[user:password],[user:mail],[site:name],[site:link]
                        </p>

                        <label>Mail Body</label>
                        <ckeditor
                            matNativeControl
                            name="editor1"
                            formControlName="COLOBOGCWhenBORProcessStartBody"
                            skin="moono-lisa"
                            language="en"
                        ></ckeditor>
                    </mat-expansion-panel>
                    <br />
                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                GC INVITED TO SUBMIT AN ESTIMATE
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <mat-form-field>
                            <span class="mat-form-field-label-wrapper"
                                ><!----><label
                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                    >Mail To</label
                                ></span
                            >
                            <input
                                matInput
                                formControlName="GCWhenInvitedForBiddingMailTo"
                            />
                        </mat-form-field>
                        <p>
                            Please use below tokens. Multiple token should be
                            seperated by comma (,).<br />
                            [property:LOEmail],[property:COEmail],[property:BOEmail],[property:RealtorEmail],[property:LPEmails],[property:GCEmail],[property:InvitedGCEmails],[property:SelectedGCEmail],[property:NotSelectedGCEmails]
                        </p>

                        <mat-form-field>
                            <span class="mat-form-field-label-wrapper"
                                ><!----><label
                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                    >Mail Subject</label
                                ></span
                            >
                            <input
                                matInput
                                formControlName="GCWhenInvitedForBiddingSubject"
                            />
                            <div class="error" *ngIf="(!EmailSetting.controls['GCWhenInvitedForBiddingSubject'].valid && EmailSetting.controls['GCWhenInvitedForBiddingSubject'].touched) || (EmailSetting.controls['GCWhenInvitedForBiddingSubject'].invalid && submitted)">
                                Please limit your input to 200 characters or less. 
                            </div>
                        </mat-form-field>
                        <p>
                            Tokens which can be used in (Mail Subject and Mail
                            Body).<br />
                            [comment],[property:LOEmail],[property:COEmail],[property:BOEmail],
                            [property:RealtorEmail], [property:LPEmails],
                            [property:LPNames],[property:GCEmail],
                            [property:InvitedGCEmails],[property:SelectedGCEmail],[property:NotSelectedGCEmails],[property:propertyName],[property:propertyAddress],[property:consultantName],[property:borrowerName],[property:contractorName],[property:loanOfficerName],[property:realtorName],[property:siteLink],[property:borrowerLastName],[user:name],[user:password],[user:mail],[site:name],[site:link]
                        </p>

                        <label>Mail Body</label>
                        <ckeditor
                            matNativeControl
                            name="editor1"
                            formControlName="GCWhenInvitedForBiddingBody"
                            skin="moono-lisa"
                            language="en"
                        ></ckeditor>
                    </mat-expansion-panel>
                    <br />
                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                GC INVITED TO SUBMIT REVISED ESTIMATE
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <mat-form-field>
                            <span class="mat-form-field-label-wrapper"
                                ><!----><label
                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                    >Mail To</label
                                ></span
                            >
                            <input
                                matInput
                                formControlName="GCWhenInvitedForRevisedBiddingMailTo"
                            />
                        </mat-form-field>
                        <p>
                            Please use below tokens. Multiple token should be
                            seperated by comma (,).<br />
                            [property:LOEmail],[property:COEmail],[property:BOEmail],[property:RealtorEmail],[property:LPEmails],[property:GCEmail],[property:InvitedGCEmails],[property:SelectedGCEmail],[property:NotSelectedGCEmails]
                        </p>

                        <mat-form-field>
                            <span class="mat-form-field-label-wrapper"
                                ><!----><label
                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                    >Mail Subject</label
                                ></span
                            >
                            <input
                                matInput
                                formControlName="GCWhenInvitedForRevisedBiddingSubject"
                            />
                            <div class="error" *ngIf="(!EmailSetting.controls['GCWhenInvitedForRevisedBiddingSubject'].valid && EmailSetting.controls['GCWhenInvitedForRevisedBiddingSubject'].touched) || (EmailSetting.controls['GCWhenInvitedForRevisedBiddingSubject'].invalid && submitted)">
                                Please limit your input to 200 characters or less. 
                            </div>
                        </mat-form-field>
                        <p>
                            Tokens which can be used in (Mail Subject and Mail
                            Body).<br />
                            [comment],[property:LOEmail],[property:COEmail],[property:BOEmail],
                            [property:RealtorEmail], [property:LPEmails],
                            [property:LPNames],[property:GCEmail],
                            [property:InvitedGCEmails],[property:SelectedGCEmail],[property:NotSelectedGCEmails],[property:propertyName],[property:propertyAddress],[property:consultantName],[property:borrowerName],[property:contractorName],[property:loanOfficerName],[property:realtorName],[property:siteLink],[property:borrowerLastName],[user:name],[user:password],[user:mail],[site:name],[site:link]
                        </p>

                        <label>Mail Body</label>
                        <ckeditor
                            matNativeControl
                            name="editor1"
                            formControlName="GCWhenInvitedForRevisedBiddingBody"
                            skin="moono-lisa"
                            language="en"
                        ></ckeditor>
                    </mat-expansion-panel>
                    <br />
                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                GC OPTED OUT FROM ESTIMATE PROCESS
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <mat-form-field>
                            <span class="mat-form-field-label-wrapper"
                                ><!----><label
                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                    >Mail To</label
                                ></span
                            >
                            <input
                                matInput
                                formControlName="GCBOLOREBeforeBiddingEndMailTo"
                            />
                        </mat-form-field>
                        <p>
                            Please use below tokens. Multiple token should be
                            seperated by comma (,).<br />
                            [property:LOEmail],[property:COEmail],[property:BOEmail],[property:RealtorEmail],[property:LPEmails],[property:GCEmail],[property:InvitedGCEmails],[property:SelectedGCEmail],[property:NotSelectedGCEmails]
                        </p>
                        <mat-form-field>
                            <span class="mat-form-field-label-wrapper"
                                ><!----><label
                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                    >Mail Subject</label
                                ></span
                            >
                            <input
                                matInput
                                formControlName="GCBOLOREBeforeBiddingEndSubject"
                            />
                            <div class="error" *ngIf="(!EmailSetting.controls['GCBOLOREBeforeBiddingEndSubject'].valid && EmailSetting.controls['GCBOLOREBeforeBiddingEndSubject'].touched) || (EmailSetting.controls['GCBOLOREBeforeBiddingEndSubject'].invalid && submitted)">
                                Please limit your input to 200 characters or less. 
                            </div>
                        </mat-form-field>
                        <p>
                            Tokens which can be used in (Mail Subject and Mail
                            Body).<br />
                            [comment],[property:LOEmail],[property:COEmail],[property:BOEmail],
                            [property:RealtorEmail], [property:LPEmails],
                            [property:LPNames],[property:GCEmail],
                            [property:InvitedGCEmails],[property:SelectedGCEmail],[property:NotSelectedGCEmails],[property:propertyName],[property:propertyAddress],[property:consultantName],[property:borrowerName],[property:contractorName],[property:loanOfficerName],[property:realtorName],[property:siteLink],[property:borrowerLastName],[user:name],[user:password],[user:mail],[site:name],[site:link]
                        </p>

                        <label>Mail Body</label>
                        <ckeditor
                            matNativeControl
                            name="editor1"
                            formControlName="GCBOLOREBeforeBiddingEndBody"
                            skin="moono-lisa"
                            language="en"
                        ></ckeditor>
                    </mat-expansion-panel>
                    <br />
                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                ESTIMATE ACCEPTED BY BO
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <mat-form-field>
                            <span class="mat-form-field-label-wrapper"
                                ><!----><label
                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                    >Mail To</label
                                ></span
                            >
                            <input
                                matInput
                                formControlName="UsersWhenBOAcceptBIDONREPAIRMailTo"
                            />
                        </mat-form-field>
                        <p>
                            Please use below tokens. Multiple token should be
                            seperated by comma (,).<br />
                            [property:LOEmail],[property:COEmail],[property:BOEmail],[property:RealtorEmail],[property:LPEmails],[property:GCEmail],[property:InvitedGCEmails],[property:SelectedGCEmail],[property:NotSelectedGCEmails]
                        </p>
                        <mat-form-field>
                            <span class="mat-form-field-label-wrapper"
                                ><!----><label
                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                    >Mail Subject</label
                                ></span
                            >
                            <input
                                matInput
                                formControlName="UsersWhenBOAcceptBIDONREPAIRSubject"
                            />`
                            <div class="error" *ngIf="(!EmailSetting.controls['UsersWhenBOAcceptBIDONREPAIRSubject'].valid && EmailSetting.controls['UsersWhenBOAcceptBIDONREPAIRSubject'].touched) || (EmailSetting.controls['UsersWhenBOAcceptBIDONREPAIRSubject'].invalid && submitted)">
                                Please limit your input to 200 characters or less. 
                            </div>
                        </mat-form-field>
                        <p>
                            Tokens which can be used in (Mail Subject and Mail
                            Body).<br />
                            [comment],[property:LOEmail],[property:COEmail],[property:BOEmail],
                            [property:RealtorEmail],
                            [property:LPEmails],[property:LPNames],
                            [property:GCEmail],
                            [property:InvitedGCEmails],[property:SelectedGCEmail],[property:NotSelectedGCEmails],[property:propertyName],[property:propertyAddress],[property:consultantName],[property:borrowerName],[property:contractorName],[property:loanOfficerName],[property:realtorName],[property:siteLink],[property:borrowerLastName],[user:name],[user:password],[user:mail],[site:name],[site:link]
                        </p>

                        <label>Mail Body</label>
                        <ckeditor
                            matNativeControl
                            name="editor1"
                            formControlName="UsersWhenBOAcceptBIDONREPAIRBody"
                            skin="moono-lisa"
                            language="en"
                        ></ckeditor>
                    </mat-expansion-panel>
                    <br />
                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                NOTIFY WINNING GC
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <mat-form-field>
                            <span class="mat-form-field-label-wrapper"
                                ><!----><label
                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                    >Mail To</label
                                ></span
                            >
                            <input matInput formControlName="WiningGCMailTo" />
                        </mat-form-field>
                        <p>
                            Please use below tokens. Multiple token should be
                            seperated by comma (,).<br />
                            [property:LOEmail],[property:COEmail],[property:BOEmail],[property:RealtorEmail],[property:LPEmails],[property:GCEmail],[property:InvitedGCEmails],[property:SelectedGCEmail],[property:NotSelectedGCEmails]
                        </p>

                        <mat-form-field>
                            <span class="mat-form-field-label-wrapper"
                                ><!----><label
                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                    >Mail Subject</label
                                ></span
                            >
                            <input matInput formControlName="WiningGCSubject" />
                            <div class="error" *ngIf="(!EmailSetting.controls['WiningGCSubject'].valid && EmailSetting.controls['WiningGCSubject'].touched) || (EmailSetting.controls['WiningGCSubject'].invalid && submitted)">
                                Please limit your input to 200 characters or less. 
                            </div>
                        </mat-form-field>
                        <p>
                            Tokens which can be used in (Mail Subject and Mail
                            Body).<br />
                            [comment],[property:LOEmail],[property:COEmail],[property:BOEmail],
                            [property:RealtorEmail],
                            [property:LPEmails],[property:LPNames],
                            [property:GCEmail],
                            [property:InvitedGCEmails],[property:SelectedGCEmail],[property:NotSelectedGCEmails],[property:propertyName],[property:propertyAddress],[property:consultantName],[property:borrowerName],[property:contractorName],[property:loanOfficerName],[property:realtorName],[property:siteLink],[property:borrowerLastName],[user:name],[user:password],[user:mail],[site:name],[site:link]
                        </p>

                        <label>Mail Body</label>
                        <ckeditor
                            matNativeControl
                            name="editor1"
                            formControlName="WiningGCBody"
                            skin="moono-lisa"
                            language="en"
                        ></ckeditor>
                    </mat-expansion-panel>
                    <br />
                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                NOTIFY NON SELECTED GC
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <mat-form-field>
                            <span class="mat-form-field-label-wrapper"
                                ><!----><label
                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                    >Mail To</label
                                ></span
                            >
                            <input
                                matInput
                                formControlName="NonSelectedGCMailTo"
                            />
                        </mat-form-field>
                        <p>
                            Please use below tokens. Multiple token should be
                            seperated by comma (,).<br />
                            [property:LOEmail],[property:COEmail],[property:BOEmail],[property:RealtorEmail],[property:LPEmails],[property:GCEmail],[property:InvitedGCEmails],[property:SelectedGCEmail],[property:NotSelectedGCEmails]
                        </p>
                        <mat-form-field>
                            <span class="mat-form-field-label-wrapper"
                                ><!----><label
                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                    >Mail Subject</label
                                ></span
                            >
                            <input
                                matInput
                                formControlName="NonSelectedGCSubject"
                            />
                            <div class="error" *ngIf="(!EmailSetting.controls['NonSelectedGCSubject'].valid && EmailSetting.controls['NonSelectedGCSubject'].touched) || (EmailSetting.controls['NonSelectedGCSubject'].invalid && submitted)">
                                Please limit your input to 200 characters or less. 
                            </div>
                        </mat-form-field>
                        <p>
                            Tokens which can be used in (Mail Subject and Mail
                            Body).<br />
                            [comment],[property:LOEmail],[property:COEmail],[property:BOEmail],
                            [property:RealtorEmail], [property:LPEmails],
                            [property:LPNames],[property:GCEmail],
                            [property:InvitedGCEmails],[property:SelectedGCEmail],[property:NotSelectedGCEmails],[property:propertyName],[property:propertyAddress],[property:consultantName],[property:borrowerName],[property:contractorName],[property:loanOfficerName],[property:realtorName],[property:siteLink],[property:borrowerLastName],[user:name],[user:password],[user:mail],[site:name],[site:link]
                        </p>
                        <label>Mail Body</label>
                        <ckeditor
                            matNativeControl
                            name="editor1"
                            formControlName="NonSelectedGCBody"
                            skin="moono-lisa"
                            language="en"
                        ></ckeditor>
                    </mat-expansion-panel>
                    <br />
                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                ESTIMATE PROPOSAL APPROVED BY CO
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <mat-form-field>
                            <span class="mat-form-field-label-wrapper"
                                ><!----><label
                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                    >Mail To</label
                                ></span
                            >
                            <input
                                matInput
                                formControlName="COBOLORealtorWhenGCProposalACCEPTEDMailTo"
                            />
                        </mat-form-field>
                        <p>
                            Please use below tokens. Multiple token should be
                            seperated by comma (,).<br />
                            [property:LOEmail],[property:COEmail],[property:BOEmail],[property:RealtorEmail],[property:LPEmails],[property:GCEmail],[property:InvitedGCEmails],[property:SelectedGCEmail],[property:NotSelectedGCEmails]
                        </p>
                        <mat-form-field>
                            <span class="mat-form-field-label-wrapper"
                                ><!----><label
                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                    >Mail Subject</label
                                ></span
                            >
                            <input
                                matInput
                                formControlName="COBOLORealtorWhenGCProposalACCEPTEDSubject"
                            />
                            <div class="error" *ngIf="(!EmailSetting.controls['COBOLORealtorWhenGCProposalACCEPTEDSubject'].valid && EmailSetting.controls['COBOLORealtorWhenGCProposalACCEPTEDSubject'].touched) || (EmailSetting.controls['COBOLORealtorWhenGCProposalACCEPTEDSubject'].invalid && submitted)">
                                Please limit your input to 200 characters or less. 
                            </div>
                        </mat-form-field>
                        <p>
                            Tokens which can be used in (Mail Subject and Mail
                            Body).<br />
                            [comment],[property:LOEmail],[property:COEmail],[property:BOEmail],
                            [property:RealtorEmail],
                            [property:LPEmails],[property:LPNames],
                            [property:GCEmail],
                            [property:InvitedGCEmails],[property:SelectedGCEmail],[property:NotSelectedGCEmails],[property:propertyName],[property:propertyAddress],[property:consultantName],[property:borrowerName],[property:contractorName],[property:loanOfficerName],[property:realtorName],[property:siteLink],[property:borrowerLastName],[user:name],[user:password],[user:mail],[site:name],[site:link]
                        </p>
                        <label>Mail Body</label>
                        <ckeditor
                            matNativeControl
                            name="editor1"
                            formControlName="COBOLORealtorWhenGCProposalACCEPTEDBody"
                            skin="moono-lisa"
                            language="en"
                        ></ckeditor>
                    </mat-expansion-panel>
                    <!--<br>
               <mat-expansion-panel>
                   <mat-expansion-panel-header>
                       <mat-panel-title>
                           NOTIFY CONTRACTOR/ BORROWER AFTER BIDDING END
                       </mat-panel-title>
                   </mat-expansion-panel-header>
                   <mat-form-field>
                       <input matInput placeholder="Mail To" formControlName="GCBOAfterBiddingEndMailTo">
                   </mat-form-field>
                   <p>Please use below tokens. Multiple token should be seperated by comma (,).<br>
                       [property:LOEmail],[property:COEmail],[property:BOEmail],[property:RealtorEmail],[property:LPEmails],[property:GCEmail],[property:InvitedGCEmails],[property:SelectedGCEmail],[property:NotSelectedGCEmails]</p>
                   <mat-form-field>
                       <input matInput placeholder="Mail Subject" formControlName="GCBOAfterBiddingEndSubject">
                   </mat-form-field>
                   <p>Tokens which can be used in (Mail Subject and Mail Body).<br>
                       [comment],[property:LOEmail],[property:COEmail],[property:BOEmail], [property:RealtorEmail], [property:LPEmails], [property:GCEmail], [property:InvitedGCEmails],[property:SelectedGCEmail],[property:NotSelectedGCEmails],[property:propertyName],[property:propertyAddress],[property:consultantName],[property:borrowerName],[property:contractorName],[property:loanOfficerName],[property:realtorName],[property:siteLink],[property:borrowerLastName],[user:name],[user:password],[user:mail],[site:name],[site:link]</p>
                   <label>Mail Body</label>
                   <ckeditor [config]="{removeButtons : 'About,Source,Save,NewPage,Preview,Print,Find,Form,Checkbox,Radio,TextField,,Textarea,Select,Button,ImageButton,HiddenField,Language,Flash'}" matNativeControl name="editor1" formControlName="GCBOAfterBiddingEndBody" skin="moono-lisa" language="en" ></ckeditor>
               </mat-expansion-panel> -->
                    <br />
                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                GC SUBMITTED BOR TO BO FOR REVIEW
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <mat-form-field>
                            <span class="mat-form-field-label-wrapper"
                                ><!----><label
                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                    >Mail To</label
                                ></span
                            >
                            <input
                                matInput
                                formControlName="COBOGCAfterGCSubmitBIDMailTo"
                            />
                        </mat-form-field>
                        <p>
                            Please use below tokens. Multiple token should be
                            seperated by comma (,).<br />
                            [property:LOEmail],[property:COEmail],[property:BOEmail],[property:RealtorEmail],[property:LPEmails],[property:GCEmail],[property:InvitedGCEmails],[property:SelectedGCEmail],[property:NotSelectedGCEmails]
                        </p>
                        <mat-form-field>
                            <span class="mat-form-field-label-wrapper"
                                ><!----><label
                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                    >Mail Subject</label
                                ></span
                            >
                            <input
                                matInput
                                formControlName="COBOGCAfterGCSubmitBIDSubject"
                            />
                            <div class="error" *ngIf="(!EmailSetting.controls['COBOGCAfterGCSubmitBIDSubject'].valid && EmailSetting.controls['COBOGCAfterGCSubmitBIDSubject'].touched) || (EmailSetting.controls['COBOGCAfterGCSubmitBIDSubject'].invalid && submitted)">
                                Please limit your input to 200 characters or less. 
                            </div>
                        </mat-form-field>
                        <p>
                            Tokens which can be used in (Mail Subject and Mail
                            Body).<br />
                            [comment],[property:LOEmail],[property:COEmail],[property:BOEmail],
                            [property:RealtorEmail], [property:LPEmails],
                            [property:LPNames],[property:GCEmail],
                            [property:InvitedGCEmails],[property:SelectedGCEmail],[property:NotSelectedGCEmails],[property:propertyName],[property:propertyAddress],[property:consultantName],[property:borrowerName],[property:contractorName],[property:loanOfficerName],[property:realtorName],[property:siteLink],[property:borrowerLastName],[user:name],[user:password],[user:mail],[site:name],[site:link]
                        </p>
                        <label>Mail Body</label>
                        <ckeditor
                            matNativeControl
                            name="editor1"
                            formControlName="COBOGCAfterGCSubmitBIDBody"
                            skin="moono-lisa"
                            language="en"
                        ></ckeditor>
                    </mat-expansion-panel>
                    <br />
                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                FINAL SOR SENT TO BO AND GC
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <mat-form-field>
                            <span class="mat-form-field-label-wrapper"
                                ><!----><label
                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                    >Mail To</label
                                ></span
                            >
                            <input
                                matInput
                                formControlName="COBOLOGCAfterFinalSORCreationMailTo"
                            />
                        </mat-form-field>
                        <p>
                            Please use below tokens. Multiple token should be
                            seperated by comma (,).<br />
                            [property:LOEmail],[property:COEmail],[property:BOEmail],[property:RealtorEmail],[property:LPEmails],[property:GCEmail],[property:InvitedGCEmails],[property:SelectedGCEmail],[property:NotSelectedGCEmails]
                        </p>
                        <mat-form-field>
                            <span class="mat-form-field-label-wrapper"
                                ><!----><label
                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                    >Mail Subject</label
                                ></span
                            >
                            <input
                                matInput
                                formControlName="COBOLOGCAfterFinalSORCreationSubject"
                            />
                            <div class="error" *ngIf="(!EmailSetting.controls['COBOLOGCAfterFinalSORCreationSubject'].valid && EmailSetting.controls['COBOLOGCAfterFinalSORCreationSubject'].touched) || (EmailSetting.controls['COBOLOGCAfterFinalSORCreationSubject'].invalid && submitted)">
                                Please limit your input to 200 characters or less. 
                            </div>
                        </mat-form-field>
                        <p>
                            Tokens which can be used in (Mail Subject and Mail
                            Body).<br />
                            [comment],[property:LOEmail],[property:COEmail],[property:BOEmail],
                            [property:RealtorEmail], [property:LPEmails],
                            [property:LPNames],[property:GCEmail],
                            [property:InvitedGCEmails],[property:SelectedGCEmail],[property:NotSelectedGCEmails],[property:propertyName],[property:propertyAddress],[property:consultantName],[property:borrowerName],[property:contractorName],[property:loanOfficerName],[property:realtorName],[property:siteLink],[property:borrowerLastName],[user:name],[user:password],[user:mail],[site:name],[site:link]
                        </p>
                        <label>Mail Body</label>
                        <ckeditor
                            matNativeControl
                            name="editor1"
                            formControlName="COBOLOGCAfterFinalSORCreationBody"
                            skin="moono-lisa"
                            language="en"
                        ></ckeditor>
                    </mat-expansion-panel>
                </mat-expansion-panel>
                <br />
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title> REVIEW ESTIMATE </mat-panel-title>
                    </mat-expansion-panel-header>
                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                DRAFT SOR REVISIONS REQUESTED
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <mat-form-field>
                            <span class="mat-form-field-label-wrapper"
                                ><!----><label
                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                    >Mail To</label
                                ></span
                            >
                            <input
                                matInput
                                formControlName="COForRivisionOfSORMailTo"
                            />
                        </mat-form-field>
                        <p>
                            Please use below tokens. Multiple token should be
                            seperated by comma (,).<br />
                            [property:LOEmail],[property:COEmail],[property:BOEmail],[property:RealtorEmail],[property:LPEmails],[property:GCEmail],[property:InvitedGCEmails],[property:SelectedGCEmail],[property:NotSelectedGCEmails]
                        </p>
                        <mat-form-field>
                            <span class="mat-form-field-label-wrapper"
                                ><!----><label
                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                    >Mail Subject</label
                                ></span
                            >
                            <input
                                matInput
                                formControlName="COForRivisionOfSORSubject"
                            />
                            <div class="error" *ngIf="(!EmailSetting.controls['COForRivisionOfSORSubject'].valid && EmailSetting.controls['COForRivisionOfSORSubject'].touched) || (EmailSetting.controls['COForRivisionOfSORSubject'].invalid && submitted)">
                                Please limit your input to 200 characters or less. 
                            </div>
                        </mat-form-field>
                        <p>
                            Tokens which can be used in (Mail Subject and Mail
                            Body).<br />
                            [comment],[property:LOEmail],[property:COEmail],[property:BOEmail],
                            [property:RealtorEmail],
                            [property:LPEmails],[property:LPNames],
                            [property:GCEmail],
                            [property:InvitedGCEmails],[property:SelectedGCEmail],[property:NotSelectedGCEmails],[property:propertyName],[property:propertyAddress],[property:consultantName],[property:borrowerName],[property:contractorName],[property:loanOfficerName],[property:realtorName],[property:siteLink],[property:borrowerLastName],[user:name],[user:password],[user:mail],[site:name],[site:link]
                        </p>
                        <label>Mail Body</label>
                        <ckeditor
                            matNativeControl
                            name="editor1"
                            formControlName="COForRivisionOfSORBody"
                            skin="moono-lisa"
                            language="en"
                        ></ckeditor>
                    </mat-expansion-panel>
                    <!--<br>
               <mat-expansion-panel>
                   <mat-expansion-panel-header>
                       <mat-panel-title>
                           NOTIFY CONSULTANT FOR REVISION REQUEST OF SPECIFICATION OF REPAIR BY BORROWER
                       </mat-panel-title>
                   </mat-expansion-panel-header>
                   <mat-form-field>
                       <input matInput placeholder="Mail To" formControlName="COForRivisionRequestOfSORByBOMailTo">
                   </mat-form-field>
                   <p>Please use below tokens. Multiple token should be seperated by comma (,).<br>
                       [property:LOEmail],[property:COEmail],[property:BOEmail],[property:RealtorEmail],[property:LPEmails],[property:GCEmail],[property:InvitedGCEmails],[property:SelectedGCEmail],[property:NotSelectedGCEmails]</p>
                   <mat-form-field>
                       <input matInput placeholder="Mail Subject" formControlName="COForRivisionRequestOfSORByBOSubject">
                   </mat-form-field>
                   <p>Tokens which can be used in (Mail Subject and Mail Body).<br>
                       [comment],[property:LOEmail],[property:COEmail],[property:BOEmail], [property:RealtorEmail], [property:LPEmails], [property:GCEmail], [property:InvitedGCEmails],[property:SelectedGCEmail],[property:NotSelectedGCEmails],[property:propertyName],[property:propertyAddress],[property:consultantName],[property:borrowerName],[property:contractorName],[property:loanOfficerName],[property:realtorName],[property:siteLink],[property:borrowerLastName],[user:name],[user:password],[user:mail],[site:name],[site:link]</p>
                   <label>Mail Body</label>
                   <ckeditor [config]="{removeButtons : 'About,Source,Save,NewPage,Preview,Print,Find,Form,Checkbox,Radio,TextField,,Textarea,Select,Button,ImageButton,HiddenField,Language,Flash'}" matNativeControl name="editor1" formControlName="COForRivisionRequestOfSORByBOBody" skin="moono-lisa" language="en" ></ckeditor>
               </mat-expansion-panel> -->
                    <br />
                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                BOR REVISIONS REQUESTED BY BO
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <mat-form-field>
                            <span class="mat-form-field-label-wrapper"
                                ><!----><label
                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                    >Mail To</label
                                ></span
                            >
                            <input
                                matInput
                                formControlName="GCCOForRevisionRequestOfBORByBOMailTo"
                            />
                        </mat-form-field>
                        <p>
                            Please use below tokens. Multiple token should be
                            seperated by comma (,).<br />
                            [property:LOEmail],[property:COEmail],[property:BOEmail],[property:RealtorEmail],[property:LPEmails],[property:GCEmail],[property:InvitedGCEmails],[property:SelectedGCEmail],[property:NotSelectedGCEmails]
                        </p>

                        <mat-form-field>
                            <span class="mat-form-field-label-wrapper"
                                ><!----><label
                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                    >Mail Subject</label
                                ></span
                            >
                            <input
                                matInput
                                formControlName="GCCOForRevisionRequestOfBORByBOSubject"
                            />
                            <div class="error" *ngIf="(!EmailSetting.controls['GCCOForRevisionRequestOfBORByBOSubject'].valid && EmailSetting.controls['GCCOForRevisionRequestOfBORByBOSubject'].touched) || (EmailSetting.controls['GCCOForRevisionRequestOfBORByBOSubject'].invalid && submitted)">
                                Please limit your input to 200 characters or less. 
                            </div>
                        </mat-form-field>
                        <p>
                            Tokens which can be used in (Mail Subject and Mail
                            Body).<br />
                            [comment],[property:LOEmail],[property:COEmail],[property:BOEmail],
                            [property:RealtorEmail], [property:LPEmails],
                            [property:LPNames],[property:GCEmail],
                            [property:InvitedGCEmails],[property:SelectedGCEmail],[property:NotSelectedGCEmails],[property:propertyName],[property:propertyAddress],[property:consultantName],[property:borrowerName],[property:contractorName],[property:loanOfficerName],[property:realtorName],[property:siteLink],[property:borrowerLastName],[user:name],[user:password],[user:mail],[site:name],[site:link]
                        </p>

                        <label>Mail Body</label>
                        <ckeditor
                            matNativeControl
                            name="editor1"
                            formControlName="GCCOForRevisionRequestOfBORByBOBody"
                            skin="moono-lisa"
                            language="en"
                        ></ckeditor>
                    </mat-expansion-panel>
                    <br />
                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                BOR REVISIONS REQUESTED BY CONSULTANT
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <mat-form-field>
                            <span class="mat-form-field-label-wrapper"
                                ><!----><label
                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                    >Mail To</label
                                ></span
                            >
                            <input
                                matInput
                                formControlName="GCBOForRevisionRequestOfBORByCOMailTo"
                            />
                        </mat-form-field>
                        <p>
                            Please use below tokens. Multiple token should be
                            seperated by comma (,).<br />
                            [property:LOEmail],[property:COEmail],[property:BOEmail],[property:RealtorEmail],[property:LPEmails],[property:GCEmail],[property:InvitedGCEmails],[property:SelectedGCEmail],[property:NotSelectedGCEmails]
                        </p>
                        <mat-form-field>
                            <span class="mat-form-field-label-wrapper"
                                ><!----><label
                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                    >Mail Subject</label
                                ></span
                            >
                            <input
                                matInput
                                formControlName="GCBOForRevisionRequestOfBORByCOSubject"
                            />
                            <div class="error" *ngIf="(!EmailSetting.controls['GCBOForRevisionRequestOfBORByCOSubject'].valid && EmailSetting.controls['GCBOForRevisionRequestOfBORByCOSubject'].touched) || (EmailSetting.controls['GCBOForRevisionRequestOfBORByCOSubject'].invalid && submitted)">
                                Please limit your input to 200 characters or less. 
                            </div>
                        </mat-form-field>
                        <p>
                            Tokens which can be used in (Mail Subject and Mail
                            Body).<br />
                            [comment],[property:LOEmail],[property:COEmail],[property:BOEmail],
                            [property:RealtorEmail], [property:LPEmails],
                            [property:LPNames],[property:GCEmail],
                            [property:InvitedGCEmails],[property:SelectedGCEmail],[property:NotSelectedGCEmails],[property:propertyName],[property:propertyAddress],[property:consultantName],[property:borrowerName],[property:contractorName],[property:loanOfficerName],[property:realtorName],[property:siteLink],[property:borrowerLastName],[user:name],[user:password],[user:mail],[site:name],[site:link]
                        </p>
                        <label>Mail Body</label>
                        <ckeditor
                            matNativeControl
                            name="editor1"
                            formControlName="GCBOForRevisionRequestOfBORByCOBody"
                            skin="moono-lisa"
                            language="en"
                        ></ckeditor>
                    </mat-expansion-panel>
                    <!--<br>
               <mat-expansion-panel>
                   <mat-expansion-panel-header>
                       <mat-panel-title>
                           GENERAL CONTRACTOR OPTED OUT FROM ESTIMATE PROCESS
                       </mat-panel-title>
                   </mat-expansion-panel-header>
                   <mat-form-field>
                       <input matInput placeholder="Mail To" formControlName="COBOLORLAboutGCOPTOutFromBidProcessMailTo">
                   </mat-form-field>
                   <p>Please use below tokens. Multiple token should be seperated by comma (,).<br>
                       [property:LOEmail],[property:COEmail],[property:BOEmail],[property:RealtorEmail],[property:LPEmails],[property:GCEmail],[property:InvitedGCEmails],[property:SelectedGCEmail],[property:NotSelectedGCEmails]</p>
                   <mat-form-field>
                       <input matInput placeholder="Mail Subject" formControlName="COBOLORLAboutGCOPTOutFromBidProcessSubject">
                   </mat-form-field>
                   <p>Tokens which can be used in (Mail Subject and Mail Body).<br>
                       [comment],[property:LOEmail],[property:COEmail],[property:BOEmail], [property:RealtorEmail], [property:LPEmails], [property:GCEmail], [property:InvitedGCEmails],[property:SelectedGCEmail],[property:NotSelectedGCEmails],[property:propertyName],[property:propertyAddress],[property:consultantName],[property:borrowerName],[property:contractorName],[property:loanOfficerName],[property:realtorName],[property:siteLink],[property:borrowerLastName],[user:name],[user:password],[user:mail],[site:name],[site:link]</p>
                   <label>Mail Body</label>
                   <ckeditor [config]="{removeButtons : 'About,Source,Save,NewPage,Preview,Print,Find,Form,Checkbox,Radio,TextField,,Textarea,Select,Button,ImageButton,HiddenField,Language,Flash'}" matNativeControl name="editor1" formControlName="COBOLORLAboutGCOPTOutFromBidProcessBody" skin="moono-lisa" language="en" ></ckeditor>
               </mat-expansion-panel> -->
                    <!--<br>
               <mat-expansion-panel>
                   <mat-expansion-panel-header>
                       <mat-panel-title>
                           SHOW NOTIFY CO BO LO AND GC ABOUT BID PROCESS RESET
                       </mat-panel-title>
                   </mat-expansion-panel-header>
                   <mat-form-field>
                       <input matInput placeholder="Mail To" formControlName="COBOLOGCAboutBidProcessResetMailTo">
                   </mat-form-field>
                   <p>Please use below tokens. Multiple token should be seperated by comma (,).<br>
                       [property:LOEmail],[property:COEmail],[property:BOEmail],[property:RealtorEmail],[property:LPEmails],[property:GCEmail],[property:InvitedGCEmails],[property:SelectedGCEmail],[property:NotSelectedGCEmails]</p>
                   <mat-form-field>
                       <input matInput placeholder="Mail Subject" formControlName="COBOLOGCAboutBidProcessResetSubject">
                   </mat-form-field>
                   <p>Tokens which can be used in (Mail Subject and Mail Body).<br>
                       [comment],[property:LOEmail],[property:COEmail],[property:BOEmail], [property:RealtorEmail], [property:LPEmails], [property:GCEmail], [property:InvitedGCEmails],[property:SelectedGCEmail],[property:NotSelectedGCEmails],[property:propertyName],[property:propertyAddress],[property:consultantName],[property:borrowerName],[property:contractorName],[property:loanOfficerName],[property:realtorName],[property:siteLink],[property:borrowerLastName],[user:name],[user:password],[user:mail],[site:name],[site:link]</p>
                   <label>Mail Body</label>
                   <ckeditor [config]="{removeButtons : 'About,Source,Save,NewPage,Preview,Print,Find,Form,Checkbox,Radio,TextField,,Textarea,Select,Button,ImageButton,HiddenField,Language,Flash'}" matNativeControl name="editor1" formControlName="COBOLOGCAboutBidProcessResetBody" skin="moono-lisa" language="en" ></ckeditor>
               </mat-expansion-panel> -->
                    <!--<br>
               <mat-expansion-panel>
                   <mat-expansion-panel-header>
                       <mat-panel-title>
                           NOTIFY ABOUT GC BLANK BID FORM
                       </mat-panel-title>
                   </mat-expansion-panel-header>
                   <mat-form-field>
                       <input matInput placeholder="Mail To" formControlName="AboutGCBlankBidFormMailTo">
                   </mat-form-field>
                   <p>Please use below tokens. Multiple token should be seperated by comma (,).<br>
                       [property:LOEmail],[property:COEmail],[property:BOEmail],[property:RealtorEmail],[property:LPEmails],[property:GCEmail],[property:InvitedGCEmails],[property:SelectedGCEmail],[property:NotSelectedGCEmails]</p>
                   <mat-form-field>
                       <input matInput placeholder="Mail Subject" formControlName="AboutGCBlankBidFormSubject">
                   </mat-form-field>
                   <p>Tokens which can be used in (Mail Subject and Mail Body).<br>
                       [comment],[property:LOEmail],[property:COEmail],[property:BOEmail], [property:RealtorEmail], [property:LPEmails], [property:GCEmail], [property:InvitedGCEmails],[property:SelectedGCEmail],[property:NotSelectedGCEmails],[property:propertyName],[property:propertyAddress],[property:consultantName],[property:borrowerName],[property:contractorName],[property:loanOfficerName],[property:realtorName],[property:siteLink],[property:borrowerLastName],[user:name],[user:password],[user:mail],[site:name],[site:link]</p>
                   <label>Mail Body</label>
                   <ckeditor [config]="{removeButtons : 'About,Source,Save,NewPage,Preview,Print,Find,Form,Checkbox,Radio,TextField,,Textarea,Select,Button,ImageButton,HiddenField,Language,Flash'}" matNativeControl name="editor1" formControlName="AboutGCBlankBidFormBody" skin="moono-lisa" language="en" ></ckeditor>
               </mat-expansion-panel> -->
                </mat-expansion-panel>
                <br />
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            INVOICE NOTIFICATION
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                NOTIFY USERS ABOUT INVOICE
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <mat-form-field>
                            <span class="mat-form-field-label-wrapper"
                                ><!----><label
                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                    >Mail To</label
                                ></span
                            >
                            <input
                                matInput
                                formControlName="BOAboutInvoiceAndSendInvoicePDFMailTo"
                            />
                        </mat-form-field>
                        <p>
                            Please use below tokens. Multiple token should be
                            seperated by comma (,).<br />
                            [property:LOEmail],[property:COEmail],[property:BOEmail],[property:RealtorEmail],[property:LPEmails],[property:GCEmail],[property:InvitedGCEmails],[property:SelectedGCEmail],[property:NotSelectedGCEmails]
                        </p>
                        <mat-form-field>
                            <span class="mat-form-field-label-wrapper"
                                ><!----><label
                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                    >Mail Subject</label
                                ></span
                            >
                            <input
                                matInput
                                formControlName="BOAboutInvoiceAndSendInvoicePDFSubject"
                            />
                            <div class="error" *ngIf="(!EmailSetting.controls['BOAboutInvoiceAndSendInvoicePDFSubject'].valid && EmailSetting.controls['BOAboutInvoiceAndSendInvoicePDFSubject'].touched) || (EmailSetting.controls['BOAboutInvoiceAndSendInvoicePDFSubject'].invalid && submitted)">
                                Please limit your input to 200 characters or less. 
                            </div>
                        </mat-form-field>
                        <p>
                            Tokens which can be used in (Mail Subject and Mail
                            Body).<br />
                            [comment],[property:LOEmail],[property:COEmail],[property:BOEmail],
                            [property:RealtorEmail], [property:LPEmails],
                            [property:LPNames],[property:GCEmail],
                            [property:InvitedGCEmails],[property:SelectedGCEmail],[property:NotSelectedGCEmails],[property:propertyName],[property:propertyAddress],[property:consultantName],[property:borrowerName],[property:contractorName],[property:loanOfficerName],[property:realtorName],[property:siteLink],[property:borrowerLastName],[user:name],[user:password],[user:mail],[site:name],[site:link]
                        </p>
                        <label>Mail Body</label>
                        <ckeditor
                            matNativeControl
                            name="editor1"
                            formControlName="BOAboutInvoiceAndSendInvoicePDFBody"
                            skin="moono-lisa"
                            language="en"
                        ></ckeditor>
                    </mat-expansion-panel>
                    <!--<br>
               <mat-expansion-panel>
                   <mat-expansion-panel-header>
                       <mat-panel-title>
                           NOTIFY LOAN OFFICER ABOUT INVOICE AND SEND INVOICE PDF
                       </mat-panel-title>
                   </mat-expansion-panel-header>
                   <mat-form-field>
                       <input matInput placeholder="Mail To" formControlName="LOAboutInvoiceAndSendInvoicePDFMailTo">
                   </mat-form-field>
                   <p>Please use below tokens. Multiple token should be seperated by comma (,).<br>
                       [property:LOEmail],[property:COEmail],[property:BOEmail],[property:RealtorEmail],[property:LPEmails],[property:GCEmail],[property:InvitedGCEmails],[property:SelectedGCEmail],[property:NotSelectedGCEmails]</p>
                   <mat-form-field>
                       <input matInput placeholder="Mail Subject" formControlName="LOAboutInvoiceAndSendInvoicePDFSubject">
                   </mat-form-field>
                   <p>Tokens which can be used in (Mail Subject and Mail Body).<br>
                       [comment],[property:LOEmail],[property:COEmail],[property:BOEmail], [property:RealtorEmail], [property:LPEmails], [property:GCEmail], [property:InvitedGCEmails],[property:SelectedGCEmail],[property:NotSelectedGCEmails],[property:propertyName],[property:propertyAddress],[property:consultantName],[property:borrowerName],[property:contractorName],[property:loanOfficerName],[property:realtorName],[property:siteLink],[property:borrowerLastName],[user:name],[user:password],[user:mail],[site:name],[site:link]</p>
                   <label>Mail Body</label>
                   <ckeditor [config]="{removeButtons : 'About,Source,Save,NewPage,Preview,Print,Find,Form,Checkbox,Radio,TextField,,Textarea,Select,Button,ImageButton,HiddenField,Language,Flash'}" matNativeControl name="editor1" formControlName="LOAboutInvoiceAndSendInvoicePDFBody" skin="moono-lisa" language="en" ></ckeditor>
               </mat-expansion-panel> -->
                    <!--<br>
               <mat-expansion-panel>
                   <mat-expansion-panel-header>
                       <mat-panel-title>
                           NOTIFY BO ABOUT SEND PDF ATTACHMENT
                       </mat-panel-title>
                   </mat-expansion-panel-header>
                   <mat-form-field>
                       <input matInput placeholder="Mail To" formControlName="BOAboutSendPDFAttachmentMailTo">
                   </mat-form-field>
                   <p>Please use below tokens. Multiple token should be seperated by comma (,).<br>
                       [property:LOEmail],[property:COEmail],[property:BOEmail],[property:RealtorEmail],[property:LPEmails],[property:GCEmail],[property:InvitedGCEmails],[property:SelectedGCEmail],[property:NotSelectedGCEmails]</p>
                   <mat-form-field>
                       <input matInput placeholder="Mail Subject" formControlName="BOAboutSendPDFAttachmentSubject">
                   </mat-form-field>
                   <p>Tokens which can be used in (Mail Subject and Mail Body).<br>
                       [comment],[property:LOEmail],[property:COEmail],[property:BOEmail], [property:RealtorEmail], [property:LPEmails], [property:GCEmail], [property:InvitedGCEmails],[property:SelectedGCEmail],[property:NotSelectedGCEmails],[property:propertyName],[property:propertyAddress],[property:consultantName],[property:borrowerName],[property:contractorName],[property:loanOfficerName],[property:realtorName],[property:siteLink],[property:borrowerLastName],[user:name],[user:password],[user:mail],[site:name],[site:link]</p>
                   <label>Mail Body</label>
                   <ckeditor [config]="{removeButtons : 'About,Source,Save,NewPage,Preview,Print,Find,Form,Checkbox,Radio,TextField,,Textarea,Select,Button,ImageButton,HiddenField,Language,Flash'}" matNativeControl name="editor1" formControlName="BOAboutSendPDFAttachmentBody" skin="moono-lisa" language="en" ></ckeditor>
               </mat-expansion-panel> -->
                </mat-expansion-panel>
            </mat-expansion-panel>
            <br />
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        POST-CLOSING EMAIL TEMPLATES
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title> INITIAL DRAW </mat-panel-title>
                    </mat-expansion-panel-header>
                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                POST CLOSING PROCESS STARTED
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <mat-form-field>
                            <span class="mat-form-field-label-wrapper"
                                ><!----><label
                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                    >Mail To</label
                                ></span
                            >
                            <input
                                matInput
                                formControlName="BOAboutPostClosingProcessMailTo"
                            />
                        </mat-form-field>
                        <p>
                            Please use below tokens. Multiple token should be
                            seperated by comma (,).<br />
                            [property:LOEmail],[property:COEmail],[property:BOEmail],[property:RealtorEmail],[property:LPEmails],[property:GCEmail],[property:DAEmail],[property:InvitedGCEmails],[property:SelectedGCEmail],[property:NotSelectedGCEmails]
                        </p>
                        <mat-form-field>
                            <span class="mat-form-field-label-wrapper"
                                ><!----><label
                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                    >Mail Subject</label
                                ></span
                            >
                            <input
                                matInput
                                formControlName="BOAboutPostClosingProcessSubject"
                            />
                            <div class="error" *ngIf="(!EmailSetting.controls['BOAboutPostClosingProcessSubject'].valid && EmailSetting.controls['BOAboutPostClosingProcessSubject'].touched) || (EmailSetting.controls['BOAboutPostClosingProcessSubject'].invalid && submitted)">
                                Please limit your input to 200 characters or less. 
                            </div>
                        </mat-form-field>
                        <p>
                            Tokens which can be used in (Mail Subject and Mail
                            Body).<br />
                            [comment],[property:LOEmail],[property:COEmail],[property:BOEmail],
                            [property:RealtorEmail], [property:LPEmails],
                            [property:LPNames],[property:GCEmail],[property:DAEmail],
                            [property:InvitedGCEmails],[property:SelectedGCEmail],[property:NotSelectedGCEmails],[property:propertyName],[property:propertyAddress],[property:consultantName],[property:borrowerName],[property:contractorName],[property:drawAdministratorName],[property:loanOfficerName],[property:realtorName],[property:siteLink],[property:borrowerLastName],[user:name],[user:password],[user:mail],[site:name],[site:link]
                        </p>

                        <label>Mail Body</label>
                        <ckeditor
                            matNativeControl
                            name="editor1"
                            formControlName="BOAboutPostClosingProcessBody"
                            skin="moono-lisa"
                            language="en"
                        ></ckeditor>
                    </mat-expansion-panel>
                    <br />
                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                DRAW INITIATED BY GENERAL CONTRACTOR
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <mat-form-field>
                            <span class="mat-form-field-label-wrapper"
                                ><!----><label
                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                    >Mail To</label
                                ></span
                            >
                            <input
                                matInput
                                formControlName="COWhenBOGCFillThiredColumnInInitialDrawRequestMailTo"
                            />
                        </mat-form-field>
                        <p>
                            Please use below tokens. Multiple token should be
                            seperated by comma (,).<br />
                            [property:LOEmail],[property:COEmail],[property:BOEmail],[property:RealtorEmail],[property:LPEmails],[property:GCEmail],[property:DAEmail],[property:InvitedGCEmails],[property:SelectedGCEmail],[property:NotSelectedGCEmails]
                        </p>
                        <mat-form-field>
                            <span class="mat-form-field-label-wrapper"
                                ><!----><label
                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                    >Mail Subject</label
                                ></span
                            >
                            <input
                                matInput
                                formControlName="COWhenBOGCFillThiredColumnInInitialDrawRequestSubject"
                            />
                            <div class="error" *ngIf="(!EmailSetting.controls['COWhenBOGCFillThiredColumnInInitialDrawRequestSubject'].valid && EmailSetting.controls['COWhenBOGCFillThiredColumnInInitialDrawRequestSubject'].touched) || (EmailSetting.controls['COWhenBOGCFillThiredColumnInInitialDrawRequestSubject'].invalid && submitted)">
                                Please limit your input to 200 characters or less. 
                            </div>
                        </mat-form-field>
                        <p>
                            Tokens which can be used in (Mail Subject and Mail
                            Body).<br />
                            [comment],[property:LOEmail],[property:COEmail],[property:BOEmail],
                            [property:RealtorEmail],
                            [property:LPEmails],[property:LPNames],
                            [property:GCEmail],[property:DAEmail],
                            [property:InvitedGCEmails],[property:SelectedGCEmail],[property:NotSelectedGCEmails],[property:propertyName],[property:propertyAddress],[property:consultantName],[property:borrowerName],[property:contractorName],[property:drawAdministratorName],[property:loanOfficerName],[property:realtorName],[property:siteLink],[property:borrowerLastName],[user:name],[user:password],[user:mail],[site:name],[site:link],[property:DrawNumber]
                        </p>
                        <label>Mail Body</label>
                        <ckeditor
                            matNativeControl
                            name="editor1"
                            formControlName="COWhenBOGCFillThiredColumnInInitialDrawRequestBody"
                            skin="moono-lisa"
                            language="en"
                        ></ckeditor>
                    </mat-expansion-panel>
                    <br />
                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                DRAW INITIATED BY BORROWER
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <mat-form-field>
                            <span class="mat-form-field-label-wrapper"
                                ><!----><label
                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                    >Mail To</label
                                ></span
                            >
                            <input
                                matInput
                                formControlName="BOForApprovalInitialDrawFormSentByGCMailTo"
                            />
                        </mat-form-field>
                        <p>
                            Please use below tokens. Multiple token should be
                            seperated by comma (,).<br />
                            [property:LOEmail],[property:COEmail],[property:BOEmail],[property:RealtorEmail],[property:LPEmails],[property:GCEmail],[property:DAEmail],[property:InvitedGCEmails],[property:SelectedGCEmail],[property:NotSelectedGCEmails]
                        </p>
                        <mat-form-field>
                            <span class="mat-form-field-label-wrapper"
                                ><!----><label
                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                    >Mail Subject</label
                                ></span
                            >
                            <input
                                matInput
                                formControlName="BOForApprovalInitialDrawFormSentByGCSubject"
                            />
                            <div class="error" *ngIf="(!EmailSetting.controls['BOForApprovalInitialDrawFormSentByGCSubject'].valid && EmailSetting.controls['BOForApprovalInitialDrawFormSentByGCSubject'].touched) || (EmailSetting.controls['BOForApprovalInitialDrawFormSentByGCSubject'].invalid && submitted)">
                                Please limit your input to 200 characters or less. 
                            </div>
                        </mat-form-field>
                        <p>
                            Tokens which can be used in (Mail Subject and Mail
                            Body).<br />
                            [comment],[property:LOEmail],[property:COEmail],[property:BOEmail],
                            [property:RealtorEmail], [property:LPEmails],
                            [property:LPNames],[property:GCEmail],[property:DAEmail],
                            [property:InvitedGCEmails],[property:SelectedGCEmail],[property:NotSelectedGCEmails],[property:propertyName],[property:propertyAddress],[property:consultantName],[property:borrowerName],[property:contractorName],[property:drawAdministratorName],[property:loanOfficerName],[property:realtorName],[property:siteLink],[property:borrowerLastName],[user:name],[user:password],[user:mail],[site:name],[site:link],[property:DrawNumber]
                        </p>
                        <label>Mail Body</label>
                        <ckeditor
                            matNativeControl
                            name="editor1"
                            formControlName="BOForApprovalInitialDrawFormSentByGCBody"
                            skin="moono-lisa"
                            language="en"
                        ></ckeditor>
                    </mat-expansion-panel>
                    <br />
                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                DRAW REQUESTED FOR INSPECTION
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <mat-form-field>
                            <span class="mat-form-field-label-wrapper"
                                ><!----><label
                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                    >Mail To</label
                                ></span
                            >
                            <input
                                matInput
                                formControlName="DrawRequestedForInspectionMailTo"
                            />
                        </mat-form-field>
                        <p>
                            Please use below tokens. Multiple token should be
                            seperated by comma (,).<br />
                            [property:LOEmail],[property:COEmail],[property:BOEmail],[property:RealtorEmail],[property:LPEmails],[property:GCEmail],[property:DAEmail],[property:InvitedGCEmails],[property:SelectedGCEmail],[property:NotSelectedGCEmails]
                        </p>
                        <mat-form-field>
                            <span class="mat-form-field-label-wrapper"
                                ><!----><label
                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                    >Mail Subject</label
                                ></span
                            >
                            <input
                                matInput
                                formControlName="DrawRequestedForInspectionSubject"
                            />

                            <div class="error" *ngIf="(!EmailSetting.controls['DrawRequestedForInspectionSubject'].valid && EmailSetting.controls['DrawRequestedForInspectionSubject'].touched) || (EmailSetting.controls['DrawRequestedForInspectionSubject'].invalid && submitted)">
                                Please limit your input to 200 characters or less. 
                            </div>
                        </mat-form-field>
                        <p>
                            Tokens which can be used in (Mail Subject and Mail
                            Body).<br />
                            [comment],[property:LOEmail],[property:COEmail],[property:BOEmail],
                            [property:RealtorEmail], [property:LPEmails],
                            [property:LPNames],[property:GCEmail],[property:DAEmail],
                            [property:InvitedGCEmails],[property:SelectedGCEmail],[property:NotSelectedGCEmails],[property:propertyName],[property:propertyAddress],[property:consultantName],[property:drawAdministratorName],[property:borrowerName],[property:contractorName],[property:loanOfficerName],[property:realtorName],[property:siteLink],[property:borrowerLastName],[user:name],[user:password],[user:mail],[site:name],[site:link],[property:DrawNumber]
                        </p>
                        <label>Mail Body</label>
                        <ckeditor
                            matNativeControl
                            name="editor1"
                            formControlName="DrawRequestedForInspectionBody"
                            skin="moono-lisa"
                            language="en"
                        ></ckeditor>
                    </mat-expansion-panel>
                    <!--<br>
               <mat-expansion-panel>
                   <mat-expansion-panel-header>
                       <mat-panel-title>
                           NOTIFY GENERAL CONTRACTOR FOR APPROVAL INITAL DRAW FORM SENT BY BORROWER
                       </mat-panel-title>
                   </mat-expansion-panel-header>
                   <mat-form-field>
                       <input matInput placeholder="Mail To" formControlName="GCForApprovalInitialDrawFormSentByBOMailTo">
                   </mat-form-field>
                   <p>Please use below tokens. Multiple token should be seperated by comma (,).<br>
                       [property:LOEmail],[property:COEmail],[property:BOEmail],[property:RealtorEmail],[property:LPEmails],[property:GCEmail],[property:InvitedGCEmails],[property:SelectedGCEmail],[property:NotSelectedGCEmails]</p>
                   <mat-form-field>
                       <input matInput placeholder="Mail Subject" formControlName="GCForApprovalInitialDrawFormSentByBOSubject">
                   </mat-form-field>
                   <p>Tokens which can be used in (Mail Subject and Mail Body).<br>
                       [comment],[property:LOEmail],[property:COEmail],[property:BOEmail], [property:RealtorEmail], [property:LPEmails], [property:GCEmail], [property:InvitedGCEmails],[property:SelectedGCEmail],[property:NotSelectedGCEmails],[property:propertyName],[property:propertyAddress],[property:consultantName],[property:borrowerName],[property:contractorName],[property:loanOfficerName],[property:realtorName],[property:siteLink],[property:borrowerLastName],[user:name],[user:password],[user:mail],[site:name],[site:link]</p>
                   <label>Mail Body</label>
                   <ckeditor [config]="{removeButtons : 'About,Source,Save,NewPage,Preview,Print,Find,Form,Checkbox,Radio,TextField,,Textarea,Select,Button,ImageButton,HiddenField,Language,Flash'}" matNativeControl name="editor1" formControlName="GCForApprovalInitialDrawFormSentByBOBody" skin="moono-lisa" language="en" ></ckeditor>
               </mat-expansion-panel> -->
                    <br />
                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                CHANGE ORDER FORM EMAIL
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <mat-form-field>
                            <span class="mat-form-field-label-wrapper"
                                ><!----><label
                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                    >Mail To</label
                                ></span
                            >
                            <input
                                matInput
                                formControlName="ChangeOrderFormEmailMailTo"
                            />
                        </mat-form-field>
                        <p>
                            Please use below tokens. Multiple token should be
                            seperated by comma (,).<br />
                            [property:LOEmail],[property:COEmail],[property:BOEmail],[property:RealtorEmail],[property:LPEmails],[property:GCEmail],[property:DAEmail],[property:InvitedGCEmails],[property:SelectedGCEmail],[property:NotSelectedGCEmails]
                        </p>
                        <mat-form-field>
                            <span class="mat-form-field-label-wrapper"
                                ><!----><label
                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                    >Mail Subject</label
                                ></span
                            >
                            <input
                                matInput
                                formControlName="ChangeOrderFormEmailSubject"
                            />

                             <div class="error" *ngIf="(!EmailSetting.controls['ChangeOrderFormEmailSubject'].valid && EmailSetting.controls['ChangeOrderFormEmailSubject'].touched) || (EmailSetting.controls['ChangeOrderFormEmailSubject'].invalid && submitted)">
                                Please limit your input to 200 characters or less. 
                            </div>
                        </mat-form-field>
                        <p>
                            Tokens which can be used in (Mail Subject and Mail
                            Body).<br />
                            [comment],[property:LOEmail],[property:COEmail],[property:BOEmail],
                            [property:RealtorEmail], [property:LPEmails],
                            [property:LPNames],[property:GCEmail],[property:DAEmail],
                            [property:InvitedGCEmails],[property:SelectedGCEmail],[property:NotSelectedGCEmails],[property:propertyName],[property:propertyAddress],[property:consultantName],[property:drawAdministratorName],[property:borrowerName],[property:contractorName],[property:loanOfficerName],[property:realtorName],[property:siteLink],[property:borrowerLastName],[user:name],[user:password],[user:mail],[site:name],[site:link]
                        </p>
                        <label>Mail Body</label>
                        <ckeditor
                            matNativeControl
                            name="editor1"
                            formControlName="ChangeOrderFormEmailBody"
                            skin="moono-lisa"
                            language="en"
                        ></ckeditor>
                    </mat-expansion-panel>
                </mat-expansion-panel>
                <br />
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title> REVIEW DRAW </mat-panel-title>
                    </mat-expansion-panel-header>
                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                DRAW REVISIONS REQUESTED
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <mat-form-field>
                            <span class="mat-form-field-label-wrapper"
                                ><!----><label
                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                    >Mail To</label
                                ></span
                            >
                            <input
                                matInput
                                formControlName="BOWhenGCSendReviseRequestFromInitialDrawRequestFormMailTo"
                            />
                        </mat-form-field>
                        <p>
                            Please use below tokens. Multiple token should be
                            seperated by comma (,).<br />
                            [property:LOEmail],[property:COEmail],[property:BOEmail],[property:RealtorEmail],[property:LPEmails],[property:GCEmail],[property:DAEmail],[property:InvitedGCEmails],[property:SelectedGCEmail],[property:NotSelectedGCEmails]
                        </p>
                        <mat-form-field>
                            <span class="mat-form-field-label-wrapper"
                                ><!----><label
                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                    >Mail Subject</label
                                ></span
                            >
                            <input
                                matInput
                                formControlName="BOWhenGCSendReviseRequestFromInitialDrawRequestFormSubject"
                            />

                            <div class="error" *ngIf="(!EmailSetting.controls['BOWhenGCSendReviseRequestFromInitialDrawRequestFormSubject'].valid && EmailSetting.controls['BOWhenGCSendReviseRequestFromInitialDrawRequestFormSubject'].touched) || (EmailSetting.controls['BOWhenGCSendReviseRequestFromInitialDrawRequestFormSubject'].invalid && submitted)">
                                Please limit your input to 200 characters or less. 
                            </div>
                        </mat-form-field>
                        <p>
                            Tokens which can be used in (Mail Subject and Mail
                            Body).<br />
                            [comment],[property:LOEmail],[property:COEmail],[property:BOEmail],
                            [property:RealtorEmail], [property:LPEmails],
                            [property:LPNames],[property:GCEmail],[property:DAEmail],
                            [property:InvitedGCEmails],[property:SelectedGCEmail],[property:NotSelectedGCEmails],[property:propertyName],[property:propertyAddress],[property:consultantName],[property:drawAdministratorName],[property:borrowerName],[property:contractorName],[property:loanOfficerName],[property:realtorName],[property:siteLink],[property:borrowerLastName],[user:name],[user:password],[user:mail],[site:name],[site:link],[property:DrawNumber]
                        </p>
                        <label>Mail Body</label>
                        <ckeditor
                            matNativeControl
                            name="editor1"
                            formControlName="BOWhenGCSendReviseRequestFromInitialDrawRequestFormBody"
                            skin="moono-lisa"
                            language="en"
                        ></ckeditor>
                    </mat-expansion-panel>
                    <!--<br>
               <mat-expansion-panel>
                   <mat-expansion-panel-header>
                       <mat-panel-title>
                           NOTIFY GENERAL CONTRACTOR WHEN BORROWER SEND REVISE REQUEST FROM INITIAL DRAW REQUEST FORM
                       </mat-panel-title>
                   </mat-expansion-panel-header>
                   <mat-form-field>
                       <input matInput placeholder="Mail To" formControlName="GCWhenBOSendReviseRequestFromInitialDrawRequestFormMailTo">
                   </mat-form-field>
                   <p>Please use below tokens. Multiple token should be seperated by comma (,).<br>
                       [property:LOEmail],[property:COEmail],[property:BOEmail],[property:RealtorEmail],[property:LPEmails],[property:GCEmail],[property:InvitedGCEmails],[property:SelectedGCEmail],[property:NotSelectedGCEmails]</p>
                   <mat-form-field>
                       <input matInput placeholder="Mail Subject" formControlName="GCWhenBOSendReviseRequestFromInitialDrawRequestFormSubject">
                   </mat-form-field>
                   <p>Tokens which can be used in (Mail Subject and Mail Body).<br>
                       [comment],[property:LOEmail],[property:COEmail],[property:BOEmail], [property:RealtorEmail], [property:LPEmails], [property:GCEmail], [property:InvitedGCEmails],[property:SelectedGCEmail],[property:NotSelectedGCEmails],[property:propertyName],[property:propertyAddress],[property:consultantName],[property:borrowerName],[property:contractorName],[property:loanOfficerName],[property:realtorName],[property:siteLink],[property:borrowerLastName],[user:name],[user:password],[user:mail],[site:name],[site:link]</p>
                   <label>Mail Body</label>
                   <ckeditor [config]="{removeButtons : 'About,Source,Save,NewPage,Preview,Print,Find,Form,Checkbox,Radio,TextField,,Textarea,Select,Button,ImageButton,HiddenField,Language,Flash'}" matNativeControl name="editor1" formControlName="GCWhenBOSendReviseRequestFromInitialDrawRequestFormBody" skin="moono-lisa" language="en" ></ckeditor>
               </mat-expansion-panel>-->
                    <br />
                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                DRAW REVISIONS SUBMITTED
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <mat-form-field>
                            <span class="mat-form-field-label-wrapper"
                                ><!----><label
                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                    >Mail To</label
                                ></span
                            >
                            <input
                                matInput
                                formControlName="GCAfterDrawFormRevisedByBOMailTo"
                            />
                        </mat-form-field>
                        <p>
                            Please use below tokens. Multiple token should be
                            seperated by comma (,).<br />
                            [property:LOEmail],[property:COEmail],[property:BOEmail],[property:RealtorEmail],[property:LPEmails],[property:GCEmail],[property:DAEmail],[property:InvitedGCEmails],[property:SelectedGCEmail],[property:NotSelectedGCEmails]
                        </p>
                        <mat-form-field>
                            <span class="mat-form-field-label-wrapper"
                                ><!----><label
                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                    >Mail Subject</label
                                ></span
                            >
                            <input
                                matInput
                                formControlName="GCAfterDrawFormRevisedByBOSubject"
                            />
                            <div class="error" *ngIf="(!EmailSetting.controls['GCAfterDrawFormRevisedByBOSubject'].valid && EmailSetting.controls['GCAfterDrawFormRevisedByBOSubject'].touched) || (EmailSetting.controls['GCAfterDrawFormRevisedByBOSubject'].invalid && submitted)">
                                Please limit your input to 200 characters or less. 
                            </div>
                        </mat-form-field>
                        <p>
                            Tokens which can be used in (Mail Subject and Mail
                            Body).<br />
                            [comment],[property:LOEmail],[property:COEmail],[property:BOEmail],
                            [property:RealtorEmail], [property:LPEmails],
                            [property:LPNames],[property:GCEmail],[property:DAEmail],
                            [property:InvitedGCEmails],[property:SelectedGCEmail],[property:NotSelectedGCEmails],[property:propertyName],[property:propertyAddress],[property:consultantName],[property:drawAdministratorName],[property:borrowerName],[property:contractorName],[property:loanOfficerName],[property:realtorName],[property:siteLink],[property:borrowerLastName],[user:name],[user:password],[user:mail],[site:name],[site:link],[property:DrawNumber]
                        </p>
                        <label>Mail Body</label>
                        <ckeditor
                            matNativeControl
                            name="editor1"
                            formControlName="GCAfterDrawFormRevisedByBOBody"
                            skin="moono-lisa"
                            language="en"
                        ></ckeditor>
                    </mat-expansion-panel>
                    <!--<br>
               <mat-expansion-panel>
                   <mat-expansion-panel-header>
                       <mat-panel-title>
                           NOTIFY BORROWER AFTER DRAW FORM REVISED BY GENERAL CONTRACTOR
                       </mat-panel-title>
                   </mat-expansion-panel-header>
                   <mat-form-field>
                       <input matInput placeholder="Mail To" formControlName="BOAfterDrawFormRevisedByGCMailTo">
                   </mat-form-field>
                   <p>Please use below tokens. Multiple token should be seperated by comma (,).<br>
                       [property:LOEmail],[property:COEmail],[property:BOEmail],[property:RealtorEmail],[property:LPEmails],[property:GCEmail],[property:InvitedGCEmails],[property:SelectedGCEmail],[property:NotSelectedGCEmails]</p>
                   <mat-form-field>
                       <input matInput placeholder="Mail Subject" formControlName="BOAfterDrawFormRevisedByGCSubject">
                   </mat-form-field>
                   <p>Tokens which can be used in (Mail Subject and Mail Body).<br>
                       [comment],[property:LOEmail],[property:COEmail],[property:BOEmail], [property:RealtorEmail], [property:LPEmails], [property:GCEmail], [property:InvitedGCEmails],[property:SelectedGCEmail],[property:NotSelectedGCEmails],[property:propertyName],[property:propertyAddress],[property:consultantName],[property:borrowerName],[property:contractorName],[property:loanOfficerName],[property:realtorName],[property:siteLink],[property:borrowerLastName],[user:name],[user:password],[user:mail],[site:name],[site:link]</p>
                   <label>Mail Body</label>
                   <ckeditor [config]="{removeButtons : 'About,Source,Save,NewPage,Preview,Print,Find,Form,Checkbox,Radio,TextField,,Textarea,Select,Button,ImageButton,HiddenField,Language,Flash'}" matNativeControl name="editor1" formControlName="BOAfterDrawFormRevisedByGCBody" skin="moono-lisa" language="en" ></ckeditor>
               </mat-expansion-panel>-->
                    <br />
                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title> DRAW ACCEPTED </mat-panel-title>
                        </mat-expansion-panel-header>
                        <mat-form-field>
                            <span class="mat-form-field-label-wrapper"
                                ><!----><label
                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                    >Mail To</label
                                ></span
                            >
                            <input
                                matInput
                                formControlName="COBOGCWhenInitialDrawRequestFormIsRevisedAndAcceptedMailTo"
                            />
                        </mat-form-field>
                        <p>
                            Please use below tokens. Multiple token should be
                            seperated by comma (,).<br />
                            [property:LOEmail],[property:COEmail],[property:BOEmail],[property:RealtorEmail],[property:LPEmails],[property:GCEmail],[property:DAEmail],[property:InvitedGCEmails],[property:SelectedGCEmail],[property:NotSelectedGCEmails]
                        </p>
                        <mat-form-field>
                            <span class="mat-form-field-label-wrapper"
                                ><!----><label
                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                    >Mail Subject</label
                                ></span
                            >
                            <input
                                matInput
                                formControlName="COBOGCWhenInitialDrawRequestFormIsRevisedAndAcceptedSubject"
                            />

                            <div class="error" *ngIf="(!EmailSetting.controls['COBOGCWhenInitialDrawRequestFormIsRevisedAndAcceptedSubject'].valid && EmailSetting.controls['COBOGCWhenInitialDrawRequestFormIsRevisedAndAcceptedSubject'].touched) || (EmailSetting.controls['COBOGCWhenInitialDrawRequestFormIsRevisedAndAcceptedSubject'].invalid && submitted)">
                                Please limit your input to 200 characters or less. 
                            </div>
                        </mat-form-field>
                        <p>
                            Tokens which can be used in (Mail Subject and Mail
                            Body).<br />
                            [comment],[property:LOEmail],[property:COEmail],[property:BOEmail],
                            [property:RealtorEmail], [property:LPEmails],
                            [property:LPNames],[property:GCEmail],[property:DAEmail],
                            [property:InvitedGCEmails],[property:SelectedGCEmail],[property:NotSelectedGCEmails],[property:propertyName],[property:propertyAddress],[property:consultantName],[property:drawAdministratorName],[property:borrowerName],[property:contractorName],[property:loanOfficerName],[property:realtorName],[property:siteLink],[property:borrowerLastName],[user:name],[user:password],[user:mail],[site:name],[site:link],[property:DrawNumber]
                        </p>
                        <label>Mail Body</label>
                        <ckeditor
                            matNativeControl
                            name="editor1"
                            formControlName="COBOGCWhenInitialDrawRequestFormIsRevisedAndAcceptedBody"
                            skin="moono-lisa"
                            language="en"
                        ></ckeditor>
                    </mat-expansion-panel>
                </mat-expansion-panel>
                <br />
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            NOTIFY DRAW ADMINISTRATOR ABOUT PROPERTY ASSIGNED
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <mat-form-field>
                        <span class="mat-form-field-label-wrapper"
                            ><!----><label
                                class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                >Mail To</label
                            ></span
                        >
                        <input
                            matInput
                            formControlName="DrawAdministratorAboutPropertyAssignedMailTo"
                        />
                    </mat-form-field>
                    <p>
                        Please use below tokens. Multiple token should be
                        seperated by comma (,).<br />
                        [property:LOEmail],[property:COEmail],[property:BOEmail],[property:RealtorEmail],[property:LPEmails],[property:GCEmail],[property:DAEmail],[property:InvitedGCEmails],[property:SelectedGCEmail],[property:NotSelectedGCEmails]
                    </p>
                    <mat-form-field>
                        <span class="mat-form-field-label-wrapper"
                            ><!----><label
                                class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                >Mail Subject</label
                            ></span
                        >
                        <input
                            matInput
                            formControlName="DrawAdministratorAboutPropertyAssignedSubject"
                        />
                        <div class="error" *ngIf="(!EmailSetting.controls['DrawAdministratorAboutPropertyAssignedSubject'].valid && EmailSetting.controls['DrawAdministratorAboutPropertyAssignedSubject'].touched) || (EmailSetting.controls['DrawAdministratorAboutPropertyAssignedSubject'].invalid && submitted)">
                            Please limit your input to 200 characters or less. 
                        </div>
                    </mat-form-field>
                    <p>
                        Tokens which can be used in (Mail Subject and Mail
                        Body).<br />
                        [comment],[property:LOEmail],[property:COEmail],[property:BOEmail],
                        [property:RealtorEmail],
                        [property:LPEmails],[property:LPNames],
                        [property:GCEmail],[property:DAEmail],
                        [property:InvitedGCEmails],[property:SelectedGCEmail],[property:NotSelectedGCEmails],[property:propertyName],[property:propertyAddress],[property:consultantName],[property:borrowerName],[property:contractorName],[property:loanOfficerName],[property:realtorName],[property:siteLink],[property:borrowerLastName],[user:name],[user:password],[user:mail],[site:name],[site:link]
                    </p>
                    <label>Mail Body</label>
                    <ckeditor
                        matNativeControl
                        name="editor1"
                        formControlName="DrawAdministratorAboutPropertyAssignedBody"
                        skin="moono-lisa"
                        language="en"
                    ></ckeditor>
                </mat-expansion-panel>
            </mat-expansion-panel>
            <br />
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title> AUTO EMAILS </mat-panel-title>
                </mat-expansion-panel-header>
                <a href="javascript:void(0);">Manage Email Queue</a>
                <br />
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title> DRAW REMINDER </mat-panel-title>
                    </mat-expansion-panel-header>
                    <mat-checkbox
                        formControlName="DrawReminderRemindBoAboutDraw"
                        >Remind Borrower About Draw</mat-checkbox
                    >
                    <mat-form-field>
                        <span class="mat-form-field-label-wrapper"
                            ><!----><label
                                class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                >Remind After(days)</label
                            ></span
                        >
                        <input
                            matInput
                            formControlName="DrawReminderRemindDays"
                        />
                    </mat-form-field>
                    <p>Remind after days the latest draw is completed</p>
                    <mat-form-field>
                        <span class="mat-form-field-label-wrapper"
                            ><!----><label
                                class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                >Mail To</label
                            ></span
                        >
                        <input matInput formControlName="DrawReminderMailTo" />
                    </mat-form-field>
                    <p>
                        Please use below tokens. Multiple token should be
                        seperated by comma (,).<br />
                        [property:LOEmail],[property:COEmail],[property:BOEmail],[property:RealtorEmail],[property:LPEmails],[property:GCEmail],[property:DAEmail],[property:InvitedGCEmails],[property:SelectedGCEmail],[property:NotSelectedGCEmails]
                    </p>
                    <mat-form-field>
                        <span class="mat-form-field-label-wrapper"
                            ><!----><label
                                class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                >Mail Subject</label
                            ></span
                        >
                        <input matInput formControlName="DrawReminderSubject" />
                        <div class="error" *ngIf="(!EmailSetting.controls['DrawReminderSubject'].valid && EmailSetting.controls['DrawReminderSubject'].touched) || (EmailSetting.controls['DrawReminderSubject'].invalid && submitted)">
                            Please limit your input to 200 characters or less. 
                        </div>
                    </mat-form-field>
                    <p>
                        Tokens which can be used in (Mail Subject and Mail
                        Body).<br />
                        [comment],[property:LOEmail],[property:COEmail],[property:BOEmail],
                        [property:RealtorEmail], [property:LPEmails],
                        [property:LPNames],[property:GCEmail],[property:DAEmail],
                        [property:InvitedGCEmails],[property:SelectedGCEmail],[property:NotSelectedGCEmails],[property:propertyName],[property:propertyAddress],[property:consultantName],[property:drawAdministratorName],[property:borrowerName],[property:contractorName],[property:loanOfficerName],[property:realtorName],[property:siteLink],[property:borrowerLastName],[user:name],[user:password],[user:mail],[site:name],[site:link],[property:DrawNumber]
                    </p>
                    <label>Mail Body</label>
                    <ckeditor
                        matNativeControl
                        name="editor1"
                        formControlName="DrawReminderBody"
                        skin="moono-lisa"
                        language="en"
                    ></ckeditor>
                </mat-expansion-panel>
                <!--<br>
           <mat-expansion-panel>
               <mat-expansion-panel-header>
                   <mat-panel-title>
                       SOR IS COMPLETED
                   </mat-panel-title>
               </mat-expansion-panel-header>
               <mat-checkbox formControlName="SORIsCompletedRemindLOLPAboutSORCompleted">Remind LO/LP About SOR Completed</mat-checkbox>
               <mat-form-field>
                   <input matInput placeholder="Remind After(days)" formControlName="SORIsCompletedRemindDays">
               </mat-form-field>
               <p>Remind after days the SOR is completed</p>
               <mat-form-field>
                   <input matInput placeholder="Mail To" formControlName="SORIsCompletedMailTo">
               </mat-form-field>
               <p>Please use below tokens. Multiple token should be seperated by comma (,).<br>
                  [property:LOEmail],[property:COEmail],[property:BOEmail],[property:RealtorEmail],[property:LPEmails],[property:GCEmail],[property:InvitedGCEmails],[property:SelectedGCEmail],[property:NotSelectedGCEmails]</p>
               <mat-form-field>
                   <input matInput placeholder="Mail Subject" formControlName="SORIsCompletedSubject">
               </mat-form-field>
               <p>Tokens which can be used in (Mail Subject and Mail Body).<br>
                   [comment],[property:LOEmail],[property:COEmail],[property:BOEmail], [property:RealtorEmail], [property:LPEmails], [property:GCEmail], [property:InvitedGCEmails],[property:SelectedGCEmail],[property:NotSelectedGCEmails],[property:propertyName],[property:propertyAddress],[property:consultantName],[property:borrowerName],[property:contractorName],[property:loanOfficerName],[property:realtorName],[property:siteLink],[property:borrowerLastName],[user:name],[user:password],[user:mail],[site:name],[site:link]</p>
               <label>Mail Body</label>
               <ckeditor [config]="{removeButtons : 'About,Source,Save,NewPage,Preview,Print,Find,Form,Checkbox,Radio,TextField,,Textarea,Select,Button,ImageButton,HiddenField,Language,Flash'}" matNativeControl name="editor1" formControlName="SORIsCompletedBody" skin="moono-lisa" language="en" ></ckeditor>
           </mat-expansion-panel> -->
                <br />
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            DRAFT SOR APPROVAL REMINDER
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <mat-checkbox
                        formControlName="ReminderForNotifyBOForApprovalOfSORReminderForNotifyBOForApprovalOfSOR"
                        >Reminder For "NOTIFY BORROWER FOR APPROVAL OF
                        SPECIFICATION OF REPAIR</mat-checkbox
                    >
                    <mat-form-field>
                        <span class="mat-form-field-label-wrapper"
                            ><!----><label
                                class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                >Remind After(days)</label
                            ></span
                        >
                        <input
                            matInput
                            formControlName="ReminderForNotifyBOForApprovalOfSORRemindDays"
                        />
                    </mat-form-field>
                    <p>Remind after days the latest draw is completed</p>
                    <mat-form-field>
                        <span class="mat-form-field-label-wrapper"
                            ><!----><label
                                class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                >Mail To</label
                            ></span
                        >
                        <input
                            matInput
                            formControlName="ReminderForNotifyBOForApprovalOfSORMailTo"
                        />
                    </mat-form-field>
                    <p>
                        Please use below tokens. Multiple token should be
                        seperated by comma (,).<br />
                        [property:LOEmail],[property:COEmail],[property:BOEmail],[property:RealtorEmail],[property:LPEmails],[property:GCEmail],[property:InvitedGCEmails],[property:SelectedGCEmail],[property:NotSelectedGCEmails]
                    </p>
                    <mat-form-field>
                        <span class="mat-form-field-label-wrapper"
                            ><!----><label
                                class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                >Mail Subject</label
                            ></span
                        >
                        <input
                            matInput
                            formControlName="ReminderForNotifyBOForApprovalOfSORSubject"
                        />
                        <div class="error" *ngIf="(!EmailSetting.controls['ReminderForNotifyBOForApprovalOfSORSubject'].valid && EmailSetting.controls['ReminderForNotifyBOForApprovalOfSORSubject'].touched) || (EmailSetting.controls['ReminderForNotifyBOForApprovalOfSORSubject'].invalid && submitted)">
                            Please limit your input to 200 characters or less. 
                        </div>
                    </mat-form-field>
                    <p>
                        Tokens which can be used in (Mail Subject and Mail
                        Body).<br />
                        [comment],[property:LOEmail],[property:COEmail],[property:BOEmail],
                        [property:RealtorEmail], [property:LPEmails],
                        [property:LPNames],[property:GCEmail],
                        [property:InvitedGCEmails],[property:SelectedGCEmail],[property:NotSelectedGCEmails],[property:propertyName],[property:propertyAddress],[property:consultantName],[property:borrowerName],[property:contractorName],[property:loanOfficerName],[property:realtorName],[property:siteLink],[property:borrowerLastName],[user:name],[user:password],[user:mail],[site:name],[site:link]
                    </p>
                    <label>Mail Body</label>
                    <ckeditor
                        matNativeControl
                        name="editor1"
                        formControlName="ReminderForNotifyBOForApprovalOfSORBody"
                        skin="moono-lisa"
                        language="en"
                    ></ckeditor>
                </mat-expansion-panel>
            </mat-expansion-panel>
            <br />
            <br />
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title> EMAIL SETTING </mat-panel-title>
                </mat-expansion-panel-header>
                <table>
                    <tbody>
                        <tr>
                            <td>
                                <mat-form-field>
                                    <span class="mat-form-field-label-wrapper"
                                        ><!----><label
                                            class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                            >API Key</label
                                        ></span
                                    >
                                    <input
                                        matInput
                                        formControlName="EmailSettingAPIKey"
                                    />
                                </mat-form-field>
                            </td>
                            <td>
                                <mat-form-field>
                                    <span class="mat-form-field-label-wrapper"
                                        ><!----><label
                                            class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                            >Domain</label
                                        ></span
                                    >
                                    <input
                                        matInput
                                        formControlName="EmailSettingDomain"
                                    />
                                </mat-form-field>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <mat-form-field>
                                    <span class="mat-form-field-label-wrapper"
                                        ><!----><label
                                            class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                            >From Email</label
                                        ></span
                                    >
                                    <input
                                        matInput
                                        formControlName="EmailSettingFromEmail"
                                    />
                                </mat-form-field>
                            </td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </mat-expansion-panel>
            <br />
            <mat-card-actions>
                <button mat-raised-button type="submit" color="primary">
                    Save configuration
                </button>
            </mat-card-actions>
        </form>
    </mat-accordion>
</div>
