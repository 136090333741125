import { Injectable } from "@angular/core";
import { NotifierService } from "angular-notifier";
import { HttpHeaders } from "@angular/common/http";
import { environment } from "../../../environments/environment";
@Injectable({
    providedIn: "root",
})
export class SessionService {
    notifier: NotifierService;
    userDetail: any = {};
    // endpoint = 'http://18.223.109.119/RenovationExchangeAPI/';
     endpoint = 'https://apidev.renovationexchange.net/';
    // endpoint = 'http://localhost:4000/';
    // endpoint = 'https://api.renovationexchange.net/';
    // Use for local development, if you are running node in localhost
    //endpoint = environment.apiurl;

    userType = [
        { Key: "1", Value: "Borrower" },
        { Key: "2", Value: "Administrator" },
        { Key: "3", Value: "Consultants" },
        { Key: "4", Value: "General Contractors" },
        { Key: "5", Value: "Loan Officer" },
        { key: "6", Value: "Loan Processor" },
        { key: "7", Value: "Realtor" },
        { key: "8", Value: "Draw Administrator" },
    ];

    onlyThisProperty = false;

    constructor(notifierService: NotifierService) {
        this.notifier = notifierService;
    }

    set(key, value) {
        return localStorage.setItem(key, value);
    }
    get(key) {
        return localStorage.getItem(key);
    }
    destroy(key) {
        return localStorage.removeItem(key);
    }
    isLoggedIn(key) {
        if (
            localStorage.getItem(key) != null &&
            localStorage.getItem(key) !== undefined &&
            localStorage.getItem(key) !== ""
        ) {
            return true;
        } else {
            return false;
        }
    }
    getToken() {
        return this.get("token");
    }
    setTokenHeader() {
        const headers = new HttpHeaders({
            // 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.get("token"),
        });
        return headers;
    }
    getUserDetail() {
        if (this.get("userInfo") !== undefined) {
            this.userDetail = JSON.parse(this.get("userInfo"));
        }
    }
}
