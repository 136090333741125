<div class="content p-24 tbl">
    <ngx-loading class="ngxLoader" 
        [show]="loading"
        [config]="{ backdropBorderRadius: '3px' }"
    ></ngx-loading>
    <h1 class="m-0">Lender Management</h1>
    <a
        *ngIf="isList"
        class="addBorrow"
        href="javascript:void(0)"
        (click)="isList = false; isAdd = true; isEdit = false; formReset()"
        ><mat-icon
            _ngcontent-c102=""
            class="secondary-text s-22 mat-icon material-icons mat-icon-no-color"
            role="img"
            aria-hidden="true"
            >add_circle</mat-icon
        >
        Add New Lender</a
    >
    <a
        *ngIf="isAdd || isEdit"
        class="addBorrow"
        href="javascript:void(0)"
        (click)="isAdd = false; isList = true; isEdit = false"
        ><mat-icon
            _ngcontent-c102=""
            class="secondary-text s-22 mat-icon material-icons mat-icon-no-color"
            role="img"
            aria-hidden="true"
            >view_list</mat-icon
        >
        List Lender</a
    >

    <mat-form-field>
        <span class="mat-form-field-label-wrapper"
            ><!----><label
                class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                >Filter</label
            ></span
        >
        <input
            [hidden]="!isList"
            matInput
            (keyup)="applyFilter($event.target)"
        />
    </mat-form-field>

    <div class="mat-elevation-z8" [hidden]="!isList">
        <table mat-table [dataSource]="dataSource" matSort>
            <!-- ID Column -->
            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
                <td mat-cell *matCellDef="let row">{{ row.id }}</td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="fname">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Lender's Name
                </th>
                <td mat-cell *matCellDef="let row">{{ row.Name }}</td>
            </ng-container>
            <!-- <ng-container matColumnDef="lname">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Last Name </th>
      <td mat-cell *matCellDef="let row"> {{row.LastName}} </td>
    </ng-container> -->
            <!-- Progress Column -->
            <!-- <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> E-mail </th>
      <td mat-cell *matCellDef="let row">  {{row.EmailId}} </td>
    </ng-container> -->

            <!-- Color Column -->
            <ng-container matColumnDef="lstLogin">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Updated Date
                </th>
                <td
                    mat-cell
                    *matCellDef="let row"
                    [style.color]="row.lastLogin"
                >
                    {{ row.UpdateDate | date : "medium" }}
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr
                mat-row
                *matRowDef="let row; columns: displayedColumns"
                style="cursor: pointer"
                (click)="EditUser(row)"
            ></tr>
        </table>

        <mat-paginator [pageSizeOptions]="[20, 50, 100]"></mat-paginator>
    </div>
    <div class="mat-elevation-z8" *ngIf="isAdd || isEdit">
        <div class="container-login100">
            <mat-card class="my-form">
                <form
                    [formGroup]="add_loanOfficer"
                    class="example-form"
                    (ngSubmit)="SaveRecord()"
                >
                    <mat-card-header>
                        <mat-card-title
                            >{{
                                isEdit ? "Edit" : "Create"
                            }}
                            Lender</mat-card-title
                        >
                    </mat-card-header>
                    <mat-card-content>
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <mat-form-field
                                            class="example-full-width"
                                        >
                                            <span
                                                class="mat-form-field-label-wrapper"
                                                ><!----><label
                                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                    >Lender Name *</label
                                                ></span
                                            >
                                            <input
                                                matInput
                                                formControlName="fname"
                                                name="fname"
                                            />
                                            <div
                                                class="error"
                                                *ngIf="
                                                    (!add_loanOfficer.controls[
                                                        'fname'
                                                    ].valid &&
                                                        add_loanOfficer
                                                            .controls['fname']
                                                            .touched) ||
                                                    (add_loanOfficer.controls[
                                                        'fname'
                                                    ].invalid &&
                                                        submitted)
                                                "
                                            >
                                                Enter lender name.
                                            </div>
                                        </mat-form-field>
                                    </td>
                                    <!-- <td>
                  <mat-form-field class="example-full-width">
                      <input matInput placeholder="E-mail *" formControlName="email" name="username">
                      <div class="error" *ngIf="(!add_loanOfficer.controls['email'].valid && add_loanOfficer.controls['email'].touched) || (add_loanOfficer.controls['email'].invalid && submitted)">
                        Enter valid email.
                      </div>
                    </mat-form-field>
              </td> -->
                                    <td>
                                        <mat-form-field
                                            class="example-full-width"
                                        >
                                            <span
                                                class="mat-form-field-label-wrapper"
                                                ><!----><label
                                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                    >Phone Number *</label
                                                ></span
                                            >
                                            <input
                                                matInput
                                                [mask]="maskPhoneFormat" 
                                                placeholder="(000) 000-0000"
                                                (keyup)="
                                                    keyDownPhoneFormat($event)
                                                "
                                                (change)="
                                                    changePhoneFormat($event)
                                                "
                                                formControlName="phone_no"
                                                name="phone_no"
                                            />
                                            <div
                                                class="error"
                                                *ngIf="
                                                    (!add_loanOfficer.controls[
                                                        'phone_no'
                                                    ].valid &&
                                                        add_loanOfficer
                                                            .controls[
                                                            'phone_no'
                                                        ].touched) ||
                                                    (add_loanOfficer.controls[
                                                        'phone_no'
                                                    ].invalid &&
                                                        submitted)
                                                "
                                            >
                                            <div class="error" *ngIf="add_loanOfficer?.controls['phone_no']?.errors?.mask">
                                                Enter valid phone no.
                                               </div>
                                            </div>
                                        </mat-form-field>
                                    </td>
                                </tr>
                                <!-- <tr>
             
              <td>
                   <mat-form-field>
                      <mat-select placeholder="Loan Officer *" formControlName="loan_officer"
                                  >
                        <mat-option value="">Select</mat-option>
                        <mat-option *ngFor="let loan_officer_value of loan_officer_list" value="{{loan_officer_value.Id}}">{{loan_officer_value.FirstName}} {{loan_officer_value.LastName}}</mat-option>
                    
                      </mat-select>
                    </mat-form-field> -->

                                <!-- </td>
            </tr> -->
                            </tbody>
                        </table>

                        <mat-radio-group
                            class="example-radio-group"
                            formControlName="statusType"
                            name="statusType"
                        >
                            <label><b>Status</b></label>
                            <mat-radio-button
                                class="example-radio-button"
                                *ngFor="let item of status"
                                [value]="item"
                            >
                                {{ item }}
                            </mat-radio-button>
                        </mat-radio-group>
                        <div
                            class="error"
                            *ngIf="
                                (!add_loanOfficer.controls['statusType']
                                    .valid &&
                                    add_loanOfficer.controls['statusType']
                                        .touched) ||
                                (add_loanOfficer.controls['statusType']
                                    .invalid &&
                                    submitted)
                            "
                        >
                            Select status.
                        </div>
                        <section *ngIf="false" class="example-full-width">
                            <label><b>Roles</b></label>
                            <div *ngFor="let role of roles; let i = index">
                                <mat-checkbox class="example-radio-group">{{
                                    role.Value
                                }}</mat-checkbox>
                            </div>
                        </section>
                        <br />
                        <mat-card>
                            <mat-card-header>
                                <mat-card-title>Address</mat-card-title>
                            </mat-card-header>
                            <mat-card-content>
                                <!-- <mat-form-field>
                      <mat-select placeholder="Country" formControlName="country"
                                  >
                        <mat-option value="">None</mat-option>
                        <mat-option value="us">United State</mat-option>
                    
                      </mat-select>
                    </mat-form-field> -->
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <mat-form-field
                                                    class="example-full-width"
                                                >
                                                    <span
                                                        class="mat-form-field-label-wrapper"
                                                        ><!----><label
                                                            class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                            >Address 1</label
                                                        ></span
                                                    >
                                                    <input
                                                        matInput
                                                        ngx-google-places-autocomplete
                                                        autocomplete="new-password"
                                                        (onAddressChange)="
                                                            AddressChange(
                                                                $event
                                                            )
                                                        "
                                                        formControlName="address1"
                                                        name="add1"
                                                    />
                                                    <!-- <input matInput placeholder="Address 1 " formControlName="address1" name="add1"> -->
                                                </mat-form-field>
                                            </td>
                                            <td>
                                                <mat-form-field
                                                    class="example-full-width"
                                                >
                                                    <span
                                                        class="mat-form-field-label-wrapper"
                                                        ><!----><label
                                                            class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                            >Address 2</label
                                                        ></span
                                                    >
                                                    <input
                                                        matInput
                                                        autocomplete="new-password"
                                                        formControlName="address2"
                                                        name="add2"
                                                    />
                                                </mat-form-field>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <mat-form-field
                                                    class="example-full-width"
                                                >
                                                    <span
                                                        class="mat-form-field-label-wrapper"
                                                        ><!----><label
                                                            class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                            >City</label
                                                        ></span
                                                    >
                                                    <input
                                                        matInput
                                                        autocomplete="new-password"
                                                        formControlName="city"
                                                        name="city"
                                                    />
                                                </mat-form-field>
                                            </td>
                                            <td>
                                                <mat-form-field
                                                    class="example-full-width"
                                                >
                                                    <span
                                                        class="mat-form-field-label-wrapper"
                                                        ><!----><label
                                                            class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                            >State</label
                                                        ></span
                                                    >
                                                    <input
                                                        matInput
                                                        autocomplete="new-password"
                                                        formControlName="state"
                                                        name="state"
                                                    />
                                                </mat-form-field>
                                            </td>
                                            <td>
                                                <mat-form-field
                                                    class="example-full-width"
                                                >
                                                    <span
                                                        class="mat-form-field-label-wrapper"
                                                        ><!----><label
                                                            class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                            >Zip code</label
                                                        ></span
                                                    >
                                                    <input
                                                        matInput
                                                        autocomplete="new-password"
                                                        formControlName="zipcode"
                                                        name="zip"
                                                    />
                                                    <div
                                                    class="error"
                                                    *ngIf="
                                                        (!add_loanOfficer.controls[
                                                            'zipcode'
                                                        ].valid &&
                                                        add_loanOfficer.controls[
                                                                'zipcode'
                                                            ].touched) ||
                                                        (add_loanOfficer.controls[
                                                            'zipcode'
                                                        ].invalid &&
                                                            submitted)
                                                    "
                                                >
                                                    Enter valid zipcode
                                                </div>
                                                </mat-form-field>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </mat-card-content>
                        </mat-card>
                        <!-- <br>
       <mat-form-field>
                      <mat-select placeholder="--Select a value-- *" formControlName="lender"
                                  >
                        <mat-option value="">None</mat-option>
                        <mat-option value="RMS">RMS Mortgage</mat-option>
                    
                      </mat-select>
                    </mat-form-field> -->
                    </mat-card-content>
                    <mat-card-actions>
                        <button mat-raised-button type="submit" color="primary">
                            {{ isEdit ? "Save" : "Save" }}</button
                        >&nbsp; &nbsp;&nbsp;
                        <button
                            mat-raised-button
                            *ngIf="
                                userDetail.UserType == 'Administrator' && isEdit
                            "
                            (click)="deleteUser(add_loanOfficer.value.id)"
                            type="button"
                            color="info"
                        >
                            Delete</button
                        >&nbsp; &nbsp;&nbsp;
                        <button
                            mat-raised-button
                            (click)="loanOfficerList()"
                            type="button"
                            color="info"
                        >
                            Back
                        </button>
                    </mat-card-actions>
                </form>
            </mat-card>
        </div>
    </div>
</div>
