import { Component, OnInit, ViewChild, Inject } from "@angular/core";
import {
    FormGroup,
    Validators,
    FormControl,
    ReactiveFormsModule,
    FormArray,
    FormBuilder,
} from "@angular/forms";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { DataSource } from "@angular/cdk/table";
import { BorrowerService } from "../borrower/borrower.service";
import { SessionService } from "../service/session.service";
import { ActivatedRoute, Router } from "@angular/router";
import { PropertyService } from "../property/property.service";
import * as moment from "moment";

export interface UserData {
    id: string;
    name: string;
    email: string;
    lastLogin: Date;
}

/** Constants used to fill up our data base. */
const COLORS: string[] = [
    "maroon",
    "red",
    "orange",
    "yellow",
    "olive",
    "green",
    "purple",
    "fuchsia",
    "lime",
    "teal",
    "aqua",
    "blue",
    "navy",
    "black",
    "gray",
];
const NAMES: string[] = [
    "Maia",
    "Asher",
    "Olivia",
    "Atticus",
    "Amelia",
    "Jack",
    "Charlotte",
    "Theodore",
    "Isla",
    "Oliver",
    "Isabella",
    "Jasper",
    "Cora",
    "Levi",
    "Violet",
    "Arthur",
    "Mia",
    "Thomas",
    "Elizabeth",
];

/**
 * @title Data table with sorting, pagination, and filtering.
 */

@Component({
    selector: "app-initial-draw-request-co",
    templateUrl: "./initial-draw-request-co.component.html",
    styleUrls: ["./initial-draw-request-co.component.scss"],
})
export class InitialDrawRequestCoComponent implements OnInit {
    displayedColumns: string[] = [
        "id",
        "RepairType",
        "RepairTotal",
        "Operation",
    ];
    baseurl = this.sessionService.endpoint;
    dataSource: MatTableDataSource<UserData>;
    mobnumPattern = "^(d{3})sd{3}-d{4}";

    public DrawRequestForm: FormGroup;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    isList: boolean = true;
    isAdd: boolean = false;
    isEdit: boolean = false;
    isFinalDraw: boolean = false;
    isSubmitted: boolean = false;
    submitted: boolean = false;
    favoriteSeason: string;
    userList: any = {};
    submittedBidList: any = {};
    userDetail: any = {};
    RepairPropertyId: any;
    loading = false;
    GCBidData: any;
    pdfData: any;
    formStatus = false;
    SubmitBidHeaderList: any = [];
    allowableFeesData: any = {};
    permitCostTypeData: any = {};
    checkConstructionsDetails = false;
    propertyRepairConstructionDetail: any;
    getRepairConstructionList: any = {};
    permitCostonReset: any = [];
    drawRequestId: any;
    DrawRequestresponse: any = {};
    drawRequestBOAction: "";
    ImprovementsRevealsData: any = [];
    ExplainationStatementsData: any = [];
    InspectByData: any = [];
    ExplainationCommentsData: any = [];
    status: any = ["Blocked", "Active"];
    roles: any = [
        { Key: "1", Value: "authenticated user" },
        { Key: "2", Value: "Administrator" },
        { Key: "3", Value: "Consultants" },
        { Key: "4", Value: "General Contractors" },
        { Key: "5", Value: "Borrower" },
        { Key: "6", Value: "Loan Officer" },
        { key: "7", Value: "Loan Processor" },
        { key: "8", Value: " Notify user of new account" },
        { key: "9", Value: "Draw Administrator" },
    ];
    constructor(
        private _bservice: BorrowerService,
        @Inject(ActivatedRoute) private route: ActivatedRoute,
        @Inject(Router) private router: Router,
        private sessionService: SessionService,
        private propertyService: PropertyService,
        @Inject(FormBuilder) private _fb: FormBuilder
    ) {
        // Create 100 users
        // Assign the data to the data source for the table to render
    }

    ngOnInit() {
        var propertyId = this.route.snapshot.paramMap.get("propertyId");
        var drawRequestId = this.route.snapshot.paramMap.get("drawRequestId");
        this.RepairPropertyId = propertyId;
        this.drawRequestId = drawRequestId;
        this.getDrawRequestData(this.RepairPropertyId);
        this.GetDrawRequest();

        if (this.sessionService.get("userInfo") != undefined) {
            this.userDetail = JSON.parse(this.sessionService.get("userInfo"));
        }

        this.DrawRequestForm = this._fb.group({
            PropertyId: new FormControl(propertyId),
            GeneralContractorId: new FormControl(this.userDetail.Id),
            ConsultantComment: new FormControl(null),
            BorrowerComment: new FormControl(null),
            GCRequestDate: new FormControl(null),
            ConsultantRequestDate: new FormControl(null),
            DrawStatus: new FormControl("pending"),
            CreatedBy: new FormControl(null),
            CreatedDate: new FormControl(null),
            UpdateDate: new FormControl(null),
            UpdatedBy: new FormControl(null),
            Id: new FormControl(null),
            TotalCOAmount: new FormControl(null),
            TotalCOPercent: new FormControl(null),
            TotalGCAmount: new FormControl(null),
            TotalGCPercent: new FormControl(null),
            ContractorFurnishedSec: new FormControl("See SOR"),
            Constructions: this._fb.array([]),
            ImprovementsReveals: this._fb.array([]),
            ExplainationComments: this._fb.array([]),
            ExplainationStatements: this._fb.array([]),
            InspectBy: this._fb.array([]),
        });
        this.initImprovementsRevealsItem();
        this.initExplainationStatementsItem();
        this.initInspectByItem();
        this.initExplainationCommentsItem();
    }
    initImprovementsRevealsItem() {
        this.ImprovementsRevealsData = [
            {
                Title: ["Unable To Make Inspection. (Explained Below)"],
                Status: [false],
            },
            {
                Title: ["Correction essential as explained below."],
                Status: [false],
            },
            {
                Title: ["No noncompliance observed"],
                Status: [false],
            },
            {
                Title: ["Acceptable variations as described below"],
                Status: [false],
            },
            {
                Title: ["On-site improvements acceptably completed"],
                Status: [false],
            },
            {
                Title: ["will examine at next inspection"],
                Status: [false],
            },
            {
                Title: ["Do not conceal until reinspected"],
                Status: [false],
            },
        ];
        const control = <FormArray>(
            this.DrawRequestForm.controls["ImprovementsReveals"]
        );

        for (let i = control.length - 1; i >= 0; i--) {
            control.removeAt(i);
        }

        for (let j = 0; j < this.ImprovementsRevealsData.length; j++) {
            control.push(this._fb.group(this.ImprovementsRevealsData[j]));
        }
    }
    initExplainationStatementsItem() {
        this.ExplainationStatementsData = [
            {
                Title: ["Draw Inspection"],
                Status: [false],
            },
            {
                Title: ["Contingency Reserve Inspection"],
                Status: [false],
            },
            {
                Title: ["Final Inspection"],
                Status: [false],
            },
            {
                Title: ["Change Order"],
                Status: [false],
            },
            {
                Title: ["Other (explain)"],
                Status: [false],
            },
        ];
        const control = <FormArray>(
            this.DrawRequestForm.controls["ExplainationStatements"]
        );

        for (let i = control.length - 1; i >= 0; i--) {
            control.removeAt(i);
        }

        for (let j = 0; j < this.ExplainationStatementsData.length; j++) {
            control.push(this._fb.group(this.ExplainationStatementsData[j]));
        }
    }
    initInspectByItem() {
        this.InspectByData = [
            {
                Title: ["Consultant/Inspector"],
                Status: [false],
            },
            {
                Title: ["Fee Inspector"],
                Status: [false],
            },
            {
                Title: ["DE Staff Inspector"],
                Status: [false],
            },
        ];
        const control = <FormArray>this.DrawRequestForm.controls["InspectBy"];

        for (let i = control.length - 1; i >= 0; i--) {
            control.removeAt(i);
        }

        for (let j = 0; j < this.InspectByData.length; j++) {
            control.push(this._fb.group(this.InspectByData[j]));
        }
    }
    initExplainationCommentsItem() {
        this.ExplainationCommentsData = [
            {
                StatementNumber: [""],
                StatementDescription: [""],
            },
        ];
        const control = <FormArray>(
            this.DrawRequestForm.controls["ExplainationComments"]
        );

        for (let i = control.length - 1; i >= 0; i--) {
            control.removeAt(i);
        }

        for (let j = 0; j < this.ExplainationCommentsData.length; j++) {
            this.ExplainationCommentsData[j].IsShow = true;
            control.push(this._fb.group(this.ExplainationCommentsData[j]));
        }
    }
    applyFilter(filterValue) {
        const target = filterValue as HTMLInputElement;
        const targetValue = target.value;
        this.dataSource.filter = targetValue.trim().toLowerCase();

        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    }

    showAddForm() {
        this.isAdd = true;
        this.isList = false;
    }

    getHeaderList(GCResList) {
        var data = {
            EmailId: "",
            PageSize: 100000,
            LastEvaluatedKey: 0,
            UserType: this.sessionService.userType[3].Value,
        };
        this.SubmitBidHeaderList = [];
        this.SubmitBidHeaderList.push({ id: "", name: "SOR DRAFT" });
        this._bservice.GetUserList(data).subscribe(
            (response) => {
                if (response != null) {
                    response.Items;
                    for (let i = 0; i < GCResList.length; i++) {
                        let GC = response.Items.filter((item) => {
                            return GCResList[i].GeneralContractorId == item.Id;
                        });

                        if (GCResList[i].Status != "Pending") {
                            this.SubmitBidHeaderList.push({
                                id: GC[0].Id,
                                name: GC[0].FirstName + " " + GC[0].LastName,
                            });
                        }
                    }
                }
            },
            (error) => {}
        );
    }

    getBorPDF(gcId) {
        var data = {
            PropertyId: parseInt(this.RepairPropertyId),
            GeneralContractorId: gcId,
        };

        this.propertyService.GetBORPDF(data).subscribe(
            (response) => {
                this.pdfData = this.baseurl + response;
                window.open(this.pdfData, "_blank");
                if (response) {
                }
            },
            (error) => {
                this.sessionService.notifier.notify(
                    "error",
                    error.error.message
                );
                this.loading = false;
            }
        );
    }

    getDrawRequestData(RepairPropertyId) {
        this.loading = true;
        this.propertyService
            .GetPropertyDetails(RepairPropertyId)
            .subscribe((presponse) => {
                if (presponse != null && presponse.AcceptedProposal != null) {
                    var GCSORdata = {
                        PropertyId: RepairPropertyId,
                        GeneralContractorId: presponse.AcceptedProposal,
                    };
                    this._bservice
                        .GetGCSorDetails(GCSORdata)
                        .subscribe((GCresponse) => {
                            let BorItem = GCresponse.Items[0].Constructions;

                            var drawdata = {
                                PropertyId: RepairPropertyId,
                            };
                            this._bservice
                                .GetDrawRequestList(drawdata)
                                .subscribe((DrawRequestresponse) => {
                                    const initialDraw =
                                        DrawRequestresponse.Items;

                                    const constructionRepairItems = [
                                        {
                                            id: 1,
                                            RepairType: "Masonry",
                                            RepairTotal: "0.00",
                                            Operation: "Active",
                                        },
                                        {
                                            id: 2,
                                            RepairType: "Siding",
                                            RepairTotal: "0.00",
                                            Operation: "Active",
                                        },
                                        {
                                            id: 3,
                                            RepairType: "Gutters/Downspouts",
                                            RepairTotal: "0.00",
                                            Operation: "Active",
                                        },
                                        {
                                            id: 4,
                                            RepairType: "Roof",
                                            RepairTotal: "0.00",
                                            Operation: "Active",
                                        },
                                        {
                                            id: 5,
                                            RepairType: "Shutters",
                                            RepairTotal: "0.00",
                                            Operation: "Active",
                                        },
                                        {
                                            id: 6,
                                            RepairType: "Exteriors",
                                            RepairTotal: "0.00",
                                            Operation: "Active",
                                        },
                                        {
                                            id: 7,
                                            RepairType: "Walks",
                                            RepairTotal: "0.00",
                                            Operation: "Active",
                                        },
                                        {
                                            id: 8,
                                            RepairType: "Driveways",
                                            RepairTotal: "0.00",
                                            Operation: "Active",
                                        },
                                        {
                                            id: 9,
                                            RepairType: "Painting (Ext)",
                                            RepairTotal: "0.00",
                                            Operation: "Active",
                                        },
                                        {
                                            id: 10,
                                            RepairType: "Caulking",
                                            RepairTotal: "0.00",
                                            Operation: "Active",
                                        },
                                        {
                                            id: 11,
                                            RepairType: "Fencing",
                                            RepairTotal: "0.00",
                                            Operation: "Active",
                                        },
                                        {
                                            id: 12,
                                            RepairType: "Grading",
                                            RepairTotal: "0.00",
                                            Operation: "Active",
                                        },
                                        {
                                            id: 13,
                                            RepairType: "Windows",
                                            RepairTotal: "0.00",
                                            Operation: "Active",
                                        },
                                        {
                                            id: 14,
                                            RepairType: "Weatherstrip",
                                            RepairTotal: "0.00",
                                            Operation: "Active",
                                        },
                                        {
                                            id: 15,
                                            RepairType: "Doors (Ext)	",
                                            RepairTotal: "0.00",
                                            Operation: "Active",
                                        },
                                        {
                                            id: 16,
                                            RepairType: "Doors (Int)	",
                                            RepairTotal: "0.00",
                                            Operation: "Active",
                                        },
                                        {
                                            id: 17,
                                            RepairType: "Partition Wall",
                                            RepairTotal: "0.00",
                                            Operation: "Active",
                                        },
                                        {
                                            id: 18,
                                            RepairType: "Plaster/Drywall",
                                            RepairTotal: "0.00",
                                            Operation: "Active",
                                        },
                                        {
                                            id: 19,
                                            RepairType: "Decorating",
                                            RepairTotal: "0.00",
                                            Operation: "Active",
                                        },
                                        {
                                            id: 20,
                                            RepairType: "Wood Trim	",
                                            RepairTotal: "0.00",
                                            Operation: "Active",
                                        },
                                        {
                                            id: 21,
                                            RepairType: "Stairs",
                                            RepairTotal: "0.00",
                                            Operation: "Active",
                                        },
                                        {
                                            id: 22,
                                            RepairType: "Closets",
                                            RepairTotal: "0.00",
                                            Operation: "Active",
                                        },
                                        {
                                            id: 23,
                                            RepairType: "Wood Floors",
                                            RepairTotal: "0.00",
                                            Operation: "Active",
                                        },
                                        {
                                            id: 24,
                                            RepairType: "Finished Floors",
                                            RepairTotal: "0.00",
                                            Operation: "Active",
                                        },
                                        {
                                            id: 25,
                                            RepairType: "Ceramic Tile",
                                            RepairTotal: "0.00",
                                            Operation: "Active",
                                        },
                                        {
                                            id: 26,
                                            RepairType: "Bath Accesories",
                                            RepairTotal: "0.00",
                                            Operation: "Active",
                                        },
                                        {
                                            id: 27,
                                            RepairType: "Plumbing",
                                            RepairTotal: "0.00",
                                            Operation: "Active",
                                        },
                                        {
                                            id: 28,
                                            RepairType: "Electrical",
                                            RepairTotal: "0.00",
                                            Operation: "Active",
                                        },
                                        {
                                            id: 29,
                                            RepairType: "Heating",
                                            RepairTotal: "0.00",
                                            Operation: "Active",
                                        },
                                        {
                                            id: 30,
                                            RepairType: "Insulation",
                                            RepairTotal: "0.00",
                                            Operation: "Active",
                                        },
                                        {
                                            id: 31,
                                            RepairType: "Cabinetry",
                                            RepairTotal: "0.00",
                                            Operation: "Active",
                                        },
                                        {
                                            id: 32,
                                            RepairType: "Appliances",
                                            RepairTotal: "0.00",
                                            Operation: "Active",
                                        },
                                        {
                                            id: 33,
                                            RepairType: "Basements",
                                            RepairTotal: "0.00",
                                            Operation: "Active",
                                        },
                                        {
                                            id: 34,
                                            RepairType: "Cleanup",
                                            RepairTotal: "0.00",
                                            Operation: "Active",
                                        },
                                        {
                                            id: 35,
                                            RepairType: "Miscellaneous",
                                            RepairTotal: "0.00",
                                            Operation: "Active",
                                        },
                                        {
                                            id: 36,
                                            RepairType: "Grand Total",
                                            RepairTotal: "0.00",
                                            Operation: "Inactive",
                                        },
                                    ];

                                    const formcontrol = <FormArray>(
                                        this.DrawRequestForm.controls[
                                            "Constructions"
                                        ]
                                    );
                                    var grandTotal = 0;
                                    var preGrandTotal = 0;
                                    var gcGrandTotal = 0;
                                    let gcTotalAmount: any = 0.0;
                                    constructionRepairItems.map((mainItem) => {
                                        let tableItem = {
                                            RepairType: "",
                                            TotalAmount: 0,
                                            TotalCOAmount: 0,
                                            TotalCOPercent: 0,
                                            TotalGCAmount: 0,
                                            TotalGCPercent: 0,
                                            GCPreviewsAmount: 0,
                                            GCAmount: 0,
                                            ConsultantAmount: 0,
                                        };
                                        tableItem.RepairType =
                                            mainItem.RepairType;

                                        ///BOR
                                        let borFiltered = BorItem.filter(
                                            (fItem) => {
                                                return (
                                                    fItem.RepairType ==
                                                    mainItem.RepairType
                                                );
                                            }
                                        );

                                        let totalAmount = 0;
                                        borFiltered.map((borItem) => {
                                            borItem.RepairItemDetails.map(
                                                (repairItem) => {
                                                    totalAmount =
                                                        totalAmount +
                                                        parseFloat(
                                                            repairItem.LaborQuantity
                                                        ) *
                                                            parseFloat(
                                                                repairItem.LaborUnitCost
                                                            ) +
                                                        +(
                                                            parseFloat(
                                                                repairItem.MaterialQuantity
                                                            ) *
                                                            parseFloat(
                                                                repairItem.MaterialUnitCost
                                                            )
                                                        );
                                                }
                                            );
                                        });

                                        tableItem.TotalAmount = totalAmount;
                                        grandTotal = grandTotal + totalAmount;

                                        //initial Draw approved
                                        let previewsAmount = 0;
                                        let consultantAmount = 0;

                                        var initialDrawApproved =
                                            initialDraw.filter((itemDraw) => {
                                                return (
                                                    itemDraw.DrawStatus ==
                                                        "approved" &&
                                                    itemDraw.Id !=
                                                        this.drawRequestId &&
                                                    itemDraw.Id <
                                                        this.drawRequestId
                                                );
                                            });

                                        initialDrawApproved.map((drawItem) => {
                                            let drawConstruction =
                                                drawItem.Constructions.filter(
                                                    (drawConstruction) => {
                                                        return (
                                                            drawConstruction.RepairType ==
                                                            mainItem.RepairType
                                                        );
                                                    }
                                                );
                                            if (drawConstruction.length > 0) {
                                                if (
                                                    drawConstruction[0]
                                                        .ConsultantAmount >= 0
                                                ) {
                                                    previewsAmount =
                                                        previewsAmount +
                                                        parseFloat(
                                                            drawConstruction[0]
                                                                .ConsultantAmount
                                                        );
                                                    consultantAmount =
                                                        consultantAmount +
                                                        parseFloat(
                                                            drawConstruction[0]
                                                                .ConsultantAmount
                                                        );
                                                } else {
                                                    previewsAmount =
                                                        previewsAmount +
                                                        parseFloat(
                                                            drawConstruction[0]
                                                                .GCAmount
                                                        );
                                                    consultantAmount =
                                                        consultantAmount +
                                                        parseFloat(
                                                            drawConstruction[0]
                                                                .GCAmount
                                                        );
                                                }
                                            }
                                        });

                                        //initial Draw Current
                                        let GCAmountCurrent = 0;
                                        let consultantAmountCurrent: any = 0;
                                        let drawApproved = false;

                                        var initialDrawApproved =
                                            initialDraw.filter((itemDraw) => {
                                                return (
                                                    itemDraw.Id ==
                                                    this.drawRequestId
                                                );
                                            });

                                        initialDrawApproved.map((drawItem) => {
                                            if (
                                                drawItem.ConsultantRequestDate !=
                                                null
                                            ) {
                                                drawApproved = true;
                                            }
                                            let drawConstruction =
                                                drawItem.Constructions.filter(
                                                    (drawConstruction) => {
                                                        return (
                                                            drawConstruction.RepairType ==
                                                            mainItem.RepairType
                                                        );
                                                    }
                                                );
                                            if (drawConstruction.length > 0) {
                                                gcTotalAmount += parseFloat(
                                                    drawConstruction[0].GCAmount
                                                );
                                                GCAmountCurrent =
                                                    GCAmountCurrent +
                                                    parseFloat(
                                                        drawConstruction[0]
                                                            .GCAmount
                                                    );
                                                consultantAmountCurrent =
                                                    consultantAmountCurrent +
                                                    parseFloat(
                                                        drawConstruction[0]
                                                            .ConsultantAmount
                                                    );
                                            }
                                        });

                                        tableItem.GCPreviewsAmount =
                                            previewsAmount;
                                        preGrandTotal =
                                            preGrandTotal + previewsAmount;
                                        //initial Draw Pending

                                        var initialDrawPending =
                                            initialDraw.filter((itemDraw) => {
                                                return (
                                                    itemDraw.DrawStatus ==
                                                        "pending" ||
                                                    itemDraw.DrawStatus ==
                                                        "revised" ||
                                                    itemDraw.DrawStatus == null
                                                );
                                            });

                                        let gcAmount = 0;

                                        initialDrawPending.map((drawItem) => {
                                            if (
                                                drawItem.ConsultantRequestDate !=
                                                null
                                            ) {
                                                drawApproved = true;
                                            }
                                            this.DrawRequestForm.controls[
                                                "Id"
                                            ].setValue(drawItem.Id);
                                            let drawConstruction =
                                                drawItem.Constructions.filter(
                                                    (drawConstruction) => {
                                                        return (
                                                            drawConstruction.RepairType ==
                                                            mainItem.RepairType
                                                        );
                                                    }
                                                );
                                            if (drawConstruction.length > 0) {
                                                gcAmount =
                                                    gcAmount +
                                                    parseFloat(
                                                        drawConstruction[0]
                                                            .GCAmount
                                                    );
                                            }
                                        });

                                        if (drawApproved == false) {
                                            consultantAmountCurrent = -1;
                                        }

                                        tableItem.GCAmount = GCAmountCurrent;
                                        tableItem.ConsultantAmount =
                                            consultantAmountCurrent;
                                        gcGrandTotal =
                                            gcGrandTotal + consultantAmount;
                                        if (
                                            tableItem.TotalAmount != 0 &&
                                            tableItem.ConsultantAmount >= 0
                                        ) {
                                            formcontrol.push(
                                                this._fb.group({
                                                    RepairType: [
                                                        tableItem.RepairType,
                                                    ],
                                                    TotalAmount: [
                                                        tableItem.TotalAmount,
                                                    ],
                                                    TotalGCAmount: [
                                                        tableItem.TotalGCAmount,
                                                    ],
                                                    TotalGCPercent: [
                                                        tableItem.TotalGCPercent,
                                                    ],
                                                    TotalCOAmount: [
                                                        tableItem.TotalCOAmount,
                                                    ],
                                                    TotalCOPercent: [
                                                        tableItem.TotalCOPercent,
                                                    ],
                                                    GCPreviewsAmount: [
                                                        tableItem.GCPreviewsAmount,
                                                    ],
                                                    GCAmount: [
                                                        tableItem.GCAmount,
                                                    ],
                                                    ConsultantAmount: [
                                                        tableItem.ConsultantAmount,
                                                    ],
                                                })
                                            );
                                        } else {
                                            formcontrol.push(
                                                this._fb.group({
                                                    RepairType: [
                                                        tableItem.RepairType,
                                                    ],
                                                    TotalAmount: [
                                                        tableItem.TotalAmount,
                                                    ],
                                                    TotalGCAmount: [
                                                        tableItem.TotalGCAmount,
                                                    ],
                                                    TotalGCPercent: [
                                                        tableItem.TotalGCPercent,
                                                    ],
                                                    TotalCOAmount: [
                                                        tableItem.TotalCOAmount,
                                                    ],
                                                    TotalCOPercent: [
                                                        tableItem.TotalCOPercent,
                                                    ],
                                                    GCPreviewsAmount: [
                                                        tableItem.GCPreviewsAmount,
                                                    ],
                                                    GCAmount: [
                                                        tableItem.GCAmount,
                                                    ],
                                                    ConsultantAmount: [""],
                                                })
                                            );
                                        }
                                    });
                                    let gcTotalPercent: any = 0;
                                    gcTotalPercent =
                                        (gcTotalAmount * 100) / grandTotal;

                                    this.DrawRequestForm.controls[
                                        "Constructions"
                                    ]["controls"][35].controls[
                                        "TotalAmount"
                                    ].setValue(grandTotal);
                                    this.DrawRequestForm.controls[
                                        "Constructions"
                                    ]["controls"][35].controls[
                                        "GCPreviewsAmount"
                                    ].setValue(preGrandTotal);
                                    this.DrawRequestForm.controls[
                                        "Constructions"
                                    ]["controls"][35].controls[
                                        "ConsultantAmount"
                                    ].setValue(gcGrandTotal);
                                    this.DrawRequestForm.controls[
                                        "Constructions"
                                    ]["controls"][35].controls[
                                        "TotalGCAmount"
                                    ].setValue(
                                        parseFloat(gcTotalAmount).toFixed(2)
                                    );
                                    this.DrawRequestForm.controls[
                                        "Constructions"
                                    ]["controls"][35].controls[
                                        "TotalCOAmount"
                                    ].setValue(0.0);

                                    this.DrawRequestForm.controls[
                                        "Constructions"
                                    ]["controls"][35].controls[
                                        "TotalGCPercent"
                                    ].setValue(
                                        parseFloat(gcTotalPercent).toFixed(2)
                                    );
                                    this.onBlurCOAmount();

                                    this.loading = false;
                                });
                        });
                }
            });
    }
    GetFinalSORPDF() {
        this.loading = true;
        var data = {
            PropertyId: this.RepairPropertyId,
            Status: "Final",
        };
        this.propertyService.GetSORPDF(data).subscribe(
            (response) => {
                let url = this.baseurl + response.PdfPath;
                this.loading = false;
                window.open(url, "_blank");
            },
            (error) => {
                this.sessionService.notifier.notify(
                    "error",
                    error.error.message
                );
                this.loading = false;
            }
        );
    }
    GetDrawRequest() {
        this._bservice.GetDrawRequestById(this.drawRequestId).subscribe(
            (DrawRequestresponse) => {
                this.DrawRequestresponse = DrawRequestresponse;
                if (
                    DrawRequestresponse.ExplainationComments != null &&
                    DrawRequestresponse.ExplainationComments != undefined
                ) {
                    const control = <FormArray>(
                        this.DrawRequestForm.controls["ExplainationComments"]
                    );

                    for (let i = control.length - 1; i >= 0; i--) {
                        control.removeAt(i);
                    }

                    this.ExplainationCommentsData =
                        DrawRequestresponse.ExplainationComments;
                    for (
                        let j = 0;
                        j < this.ExplainationCommentsData.length;
                        j++
                    ) {
                        this.ExplainationCommentsData[j].IsShow = true;
                        control.push(
                            this._fb.group(this.ExplainationCommentsData[j])
                        );
                    }
                }
                if (
                    DrawRequestresponse.InspectBy != null &&
                    DrawRequestresponse.InspectBy != undefined
                ) {
                    const control = <FormArray>(
                        this.DrawRequestForm.controls["InspectBy"]
                    );

                    for (let i = control.length - 1; i >= 0; i--) {
                        control.removeAt(i);
                    }

                    this.InspectByData = DrawRequestresponse.InspectBy;
                    for (let j = 0; j < this.InspectByData.length; j++) {
                        control.push(this._fb.group(this.InspectByData[j]));
                    }
                }
                if (
                    DrawRequestresponse.ExplainationStatements != null &&
                    DrawRequestresponse.ExplainationStatements != undefined
                ) {
                    const control = <FormArray>(
                        this.DrawRequestForm.controls["ExplainationStatements"]
                    );

                    for (let i = control.length - 1; i >= 0; i--) {
                        control.removeAt(i);
                    }

                    this.ExplainationStatementsData =
                        DrawRequestresponse.ExplainationStatements;
                    for (
                        let j = 0;
                        j < this.ExplainationStatementsData.length;
                        j++
                    ) {
                        control.push(
                            this._fb.group(this.ExplainationStatementsData[j])
                        );
                    }
                }

                if (
                    DrawRequestresponse.ImprovementsReveals != null &&
                    DrawRequestresponse.ImprovementsReveals != undefined
                ) {
                    const control = <FormArray>(
                        this.DrawRequestForm.controls["ImprovementsReveals"]
                    );

                    for (let i = control.length - 1; i >= 0; i--) {
                        control.removeAt(i);
                    }

                    this.ImprovementsRevealsData =
                        DrawRequestresponse.ImprovementsReveals;
                    for (
                        let j = 0;
                        j < this.ImprovementsRevealsData.length;
                        j++
                    ) {
                        control.push(
                            this._fb.group(this.ImprovementsRevealsData[j])
                        );
                    }
                }
                this.DrawRequestForm.controls[
                    "ContractorFurnishedSec"
                ].setValue(DrawRequestresponse.ContractorFurnishedSec);
                if (DrawRequestresponse.ConsultantRequestDate != null) {
                    this.DrawRequestForm.controls[
                        "ConsultantRequestDate"
                    ].setValue(
                        moment(DrawRequestresponse.ConsultantRequestDate)
                    );
                }
            },
            (error) => {
                this.sessionService.notifier.notify(
                    "error",
                    error.error.message
                );
                this.loading = false;
            }
        );
    }

    onKeyUpCOAmount(index) {
        let totalAM =
            this.DrawRequestForm.controls["Constructions"]["controls"][index]
                .controls.GCAmount.value;
        let myVal =
            this.DrawRequestForm.controls["Constructions"]["controls"][index]
                .controls.ConsultantAmount.value;
        if (isNaN(myVal)) {
            alert("Value must be a number or blank.");
            return;
        } else {
            if (parseFloat(totalAM) >= parseFloat(myVal)) {
                return;
            } else {
                alert(
                    "Please enter your value without symbols and commas. The total of column #4 should be less than value shown in column #3."
                );
                return;
            }
        }
    }
    onBlurCOAmount() {
        let grandTotalNew: any = 0.0;
        let totalAmount: any = 0;
        let gcTotalPercent: any = 0;

        for (
            let i = 0;
            i <
            this.DrawRequestForm.controls["Constructions"]["controls"].length;
            i++
        ) {
            if (i != 35) {
                let ConsultantAmount: any = 0;
                if (
                    this.DrawRequestForm.controls["Constructions"]["controls"][
                        i
                    ].controls.ConsultantAmount.value != "" &&
                    !isNaN(
                        this.DrawRequestForm.controls["Constructions"][
                            "controls"
                        ][i].controls.ConsultantAmount.value
                    )
                ) {
                    grandTotalNew += parseFloat(
                        this.DrawRequestForm.controls["Constructions"][
                            "controls"
                        ][i].controls.ConsultantAmount.value
                    );
                } else {
                    grandTotalNew += parseFloat(ConsultantAmount);
                }
            }
        }
        totalAmount =
            this.DrawRequestForm.controls["Constructions"]["controls"][35]
                .controls.TotalAmount.value;

        gcTotalPercent = (grandTotalNew * 100) / totalAmount;

        this.DrawRequestForm.controls["Constructions"]["controls"][35].controls[
            "TotalCOAmount"
        ].setValue(parseFloat(grandTotalNew).toFixed(2));

        this.DrawRequestForm.controls["Constructions"]["controls"][35].controls[
            "TotalCOPercent"
        ].setValue(parseFloat(gcTotalPercent).toFixed(2));
    }
    SaveDrawRequest(index) {
        if (this.DrawRequestForm.invalid) {
            return;
        } else {
            var formData = this.DrawRequestForm.value;

            this._bservice.GetDrawRequestById(this.drawRequestId).subscribe(
                (DrawRequestresponse) => {
                    for (
                        let i = 0;
                        i < DrawRequestresponse.Constructions.length;
                        i++
                    ) {
                        for (
                            let j = 0;
                            j < formData.Constructions.length;
                            j++
                        ) {
                            if (
                                isNaN(
                                    formData.Constructions[j].ConsultantAmount
                                )
                            ) {
                                alert(
                                    "Value must be a number or blank for Repair Type : '" +
                                        formData.Constructions[j].RepairType +
                                        "'"
                                );
                                return;
                            }

                            if (
                                formData.Constructions[j].ConsultantAmount == ""
                            ) {
                                formData.Constructions[j].ConsultantAmount = -1;
                            }

                            if (
                                DrawRequestresponse.Constructions[i]
                                    .RepairType ==
                                formData.Constructions[j].RepairType
                            ) {
                                if (
                                    parseFloat(
                                        formData.Constructions[j].GCAmount
                                    ) <
                                    parseFloat(
                                        formData.Constructions[j]
                                            .ConsultantAmount
                                    )
                                ) {
                                    alert(
                                        "Please enter your value without symbols and commas. The total of column #4 should be less than value shown in column #3."
                                    );
                                    return;
                                } else if (
                                    formData.Constructions[j].ConsultantAmount <
                                    0
                                ) {
                                    DrawRequestresponse.Constructions[
                                        i
                                    ].ConsultantAmount = parseFloat(
                                        formData.Constructions[j].GCAmount
                                    ).toFixed(2);
                                } else {
                                    DrawRequestresponse.Constructions[
                                        i
                                    ].ConsultantAmount = parseFloat(
                                        formData.Constructions[j]
                                            .ConsultantAmount
                                    ).toFixed(2);
                                }
                            }
                        }
                    }

                    if (formData.ConsultantRequestDate) {
                        if (formData.ConsultantRequestDate._i) {
                            var agreementDate = "";
                            if (
                                formData.ConsultantRequestDate._i.year != "" &&
                                formData.ConsultantRequestDate._i.year !=
                                    null &&
                                formData.ConsultantRequestDate._i.year !=
                                    undefined
                            ) {
                                agreementDate =
                                    formData.ConsultantRequestDate._i.month +
                                    1 +
                                    "/" +
                                    formData.ConsultantRequestDate._i.date +
                                    "/" +
                                    formData.ConsultantRequestDate._i.year;
                            } else {
                                agreementDate =
                                    formData.ConsultantRequestDate._i;
                            }
                        } else {
                            alert("Please choose the inspection date !!");
                            return;
                        }
                    } else {
                        alert("Please choose the inspection date !!");
                        return;
                    }

                    var ImprovementsReveals = [];

                    for (
                        let i = 0;
                        i < formData.ImprovementsReveals.length;
                        i++
                    ) {
                        ImprovementsReveals.push({
                            Title: formData.ImprovementsReveals[i].Title,
                            Status: formData.ImprovementsReveals[i].Status,
                        });
                    }
                    DrawRequestresponse.ImprovementsReveals =
                        ImprovementsReveals;

                    var ExplainationStatements = [];

                    for (
                        let i = 0;
                        i < formData.ExplainationStatements.length;
                        i++
                    ) {
                        ExplainationStatements.push({
                            Title: formData.ExplainationStatements[i].Title,
                            Status: formData.ExplainationStatements[i].Status,
                        });
                        if (
                            formData.ExplainationStatements[i].Title ==
                                "Final Inspection" &&
                            formData.ExplainationStatements[i].Status == true
                        ) {
                            this.isFinalDraw = true;
                        }
                    }

                    DrawRequestresponse.ExplainationStatements =
                        ExplainationStatements;

                    var InspectBy = [];

                    for (let i = 0; i < formData.InspectBy.length; i++) {
                        InspectBy.push({
                            Title: formData.InspectBy[i].Title,
                            Status: formData.InspectBy[i].Status,
                        });
                    }
                    DrawRequestresponse.InspectBy = InspectBy;

                    var ExplainationComments = [];
                    for (
                        let i = 0;
                        i < formData.ExplainationComments.length;
                        i++
                    ) {
                        ExplainationComments.push({
                            StatementNumber:
                                formData.ExplainationComments[i]
                                    .StatementNumber,
                            StatementDescription: formData.ExplainationComments[
                                i
                            ].StatementDescription.replace("\n", "\\n"),
                        });
                    }
                    DrawRequestresponse.ExplainationComments =
                        ExplainationComments;

                    DrawRequestresponse.ConsultantRequestDate = agreementDate;

                    DrawRequestresponse.UpdatedBy = this.userDetail.Id;
                    if (formData.ContractorFurnishedSec) {
                        DrawRequestresponse.ContractorFurnishedSec =
                            formData.ContractorFurnishedSec;
                    } else {
                        DrawRequestresponse.ContractorFurnishedSec = "See SOR";
                    }

                    this._bservice
                        .UpdateDrawRequest(DrawRequestresponse)
                        .subscribe(
                            (response) => {
                                if (response != null) {
                                    if (index == "yes") {
                                        this.isSubmitted = true;
                                        this.submitDrawRequest(false);
                                    } else {
                                        this.sessionService.notifier.notify(
                                            "success",
                                            "Draw Request Save successfully."
                                        );
                                        this.loading = false;
                                    }
                                }
                            },
                            (error) => {
                                this.sessionService.notifier.notify(
                                    "error",
                                    error.error.message
                                );
                                this.loading = false;
                            }
                        );
                },
                (error) => {
                    this.sessionService.notifier.notify(
                        "error",
                        error.error.message
                    );
                    this.loading = false;
                }
            );
        }
    }

    removeExplainationComment(index) {
        const control = <FormArray>(
            this.DrawRequestForm.controls["ExplainationComments"]
        );

        control.value[index].IsShow = false;
    }
    addNewExplainationComment() {
        this.ExplainationCommentsData = [
            {
                StatementNumber: [""],
                StatementDescription: [""],
            },
        ];
        const control = <FormArray>(
            this.DrawRequestForm.controls["ExplainationComments"]
        );

        for (let j = 0; j < this.ExplainationCommentsData.length; j++) {
            this.ExplainationCommentsData[j].IsShow = true;
            control.push(this._fb.group(this.ExplainationCommentsData[j]));
        }
    }
    submitDrawRequest(index) {
        if (index == "yes") {
            this.SaveDrawRequest(index);
        }

        if (this.isSubmitted == true) {
            this.loading = true;
            this._bservice.GetDrawRequestById(this.drawRequestId).subscribe(
                (DrawRequestresponse) => {
                    var DrawEmailsData = {
                        PropertyId: this.RepairPropertyId,
                        Status: DrawRequestresponse.DrawStatus,
                        UserId: this.userDetail.Id,
                        SendBy: this.userDetail.UserType,
                        DrawId: this.drawRequestId,
                    };
                    this._bservice.DrawEmails(DrawEmailsData).subscribe(
                        (response) => {
                            this.loading = true;
                            var data = {
                                DrawId: this.drawRequestId,
                                SendDocSign: true,
                            };
                            this.propertyService.DrawRequestPDF(data).subscribe(
                                (response) => {
                                    this.loading = true;
                                    if (response.PdfPath == "success") {
                                        if (this.isFinalDraw == true) {
                                            var propertyStatusData = {
                                                Id: parseInt(
                                                    this.RepairPropertyId
                                                ),
                                                Status: "Final Draw Completed",
                                                CreatedBy:
                                                    this.userDetail.EmailId,
                                                UpdatedBy: this.userDetail.Id,
                                            };
                                            this.propertyService
                                                .UpdatePropertyStatus(
                                                    propertyStatusData
                                                )
                                                .subscribe(
                                                    (response) => {
                                                        this.propertyService
                                                            .GetPropertyDetails(
                                                                this
                                                                    .RepairPropertyId
                                                            )
                                                            .subscribe(
                                                                (
                                                                    proResponse
                                                                ) => {
                                                                    if (
                                                                        proResponse.InitialDraws !=
                                                                        undefined
                                                                    ) {
                                                                        for (
                                                                            let i = 0;
                                                                            i <
                                                                            proResponse
                                                                                .InitialDraws
                                                                                .length;
                                                                            i++
                                                                        ) {
                                                                            if (
                                                                                proResponse
                                                                                    .InitialDraws[
                                                                                    i
                                                                                ]
                                                                                    .DrawId ==
                                                                                this
                                                                                    .drawRequestId
                                                                            ) {
                                                                                proResponse.InitialDraws[
                                                                                    i
                                                                                ].UpdatedBy =
                                                                                    this.userDetail.Id;
                                                                            }
                                                                        }
                                                                    }

                                                                    var propertyData =
                                                                        {
                                                                            Id: proResponse.Id,
                                                                            InitialDraws:
                                                                                proResponse.InitialDraws,
                                                                            UpdateDate:
                                                                                "today",
                                                                            UpdatedBy:
                                                                                this
                                                                                    .userDetail
                                                                                    .Id,
                                                                        };

                                                                    this.propertyService
                                                                        .UpdatePropertyDraw(
                                                                            propertyData
                                                                        )
                                                                        .subscribe(
                                                                            (
                                                                                response
                                                                            ) => {
                                                                                this.sessionService.notifier.notify(
                                                                                    "success",
                                                                                    "Draw Request Successfully Send For Document Sign !!"
                                                                                );
                                                                                this.router.navigate(
                                                                                    [
                                                                                        "/property-details/" +
                                                                                            this
                                                                                                .RepairPropertyId +
                                                                                            "/2",
                                                                                    ]
                                                                                );
                                                                            },
                                                                            (
                                                                                error
                                                                            ) => {
                                                                                this.sessionService.notifier.notify(
                                                                                    "error",
                                                                                    error
                                                                                        .error
                                                                                        .message
                                                                                );
                                                                                this.loading =
                                                                                    false;
                                                                            }
                                                                        );
                                                                },
                                                                (error) => {
                                                                    this.sessionService.notifier.notify(
                                                                        "error",
                                                                        error
                                                                            .error
                                                                            .message
                                                                    );
                                                                    this.loading =
                                                                        false;
                                                                }
                                                            );
                                                    },
                                                    (error) => {
                                                        this.sessionService.notifier.notify(
                                                            "error",
                                                            error.error.message
                                                        );
                                                        this.loading = false;
                                                    }
                                                );
                                        } else {
                                            this.propertyService
                                                .GetPropertyDetails(
                                                    this.RepairPropertyId
                                                )
                                                .subscribe(
                                                    (proResponse) => {
                                                        if (
                                                            proResponse.InitialDraws !=
                                                            undefined
                                                        ) {
                                                            for (
                                                                let i = 0;
                                                                i <
                                                                proResponse
                                                                    .InitialDraws
                                                                    .length;
                                                                i++
                                                            ) {
                                                                if (
                                                                    proResponse
                                                                        .InitialDraws[
                                                                        i
                                                                    ].DrawId ==
                                                                    this
                                                                        .drawRequestId
                                                                ) {
                                                                    proResponse.InitialDraws[
                                                                        i
                                                                    ].UpdatedBy =
                                                                        this.userDetail.Id;
                                                                }
                                                            }
                                                        }

                                                        var propertyData = {
                                                            Id: proResponse.Id,
                                                            InitialDraws:
                                                                proResponse.InitialDraws,
                                                            UpdateDate: "today",
                                                            UpdatedBy:
                                                                this.userDetail
                                                                    .Id,
                                                        };

                                                        this.propertyService
                                                            .UpdatePropertyDraw(
                                                                propertyData
                                                            )
                                                            .subscribe(
                                                                (response) => {
                                                                    this.sessionService.notifier.notify(
                                                                        "success",
                                                                        "Draw Request Successfully Send For Document Sign !!"
                                                                    );
                                                                    this.router.navigate(
                                                                        [
                                                                            "/property-details/" +
                                                                                this
                                                                                    .RepairPropertyId +
                                                                                "/2",
                                                                        ]
                                                                    );
                                                                },
                                                                (error) => {
                                                                    this.sessionService.notifier.notify(
                                                                        "error",
                                                                        error
                                                                            .error
                                                                            .message
                                                                    );
                                                                    this.loading =
                                                                        false;
                                                                }
                                                            );
                                                    },
                                                    (error) => {
                                                        this.sessionService.notifier.notify(
                                                            "error",
                                                            error.error.message
                                                        );
                                                        this.loading = false;
                                                    }
                                                );
                                        }
                                    }
                                    this.loading = true;
                                },
                                (error) => {
                                    this.sessionService.notifier.notify(
                                        "error",
                                        error.error.message
                                    );
                                    this.loading = false;
                                }
                            );
                        },
                        (error) => {
                            this.sessionService.notifier.notify(
                                "error",
                                error.error.message
                            );
                            this.loading = false;
                        }
                    );
                },
                (error) => {
                    this.sessionService.notifier.notify(
                        "error",
                        error.error.message
                    );
                    this.loading = false;
                }
            );
        }
        this.loading = false;
    }

    acceptBid(bidId) {
        this._bservice.GetGCSorDetailsbyId(bidId).subscribe(
            (GCresponse) => {
                if (GCresponse != null) {
                    GCresponse.Status = "Accepted";
                    this._bservice.UpdateSORForGC(GCresponse).subscribe(
                        (response) => {
                            var propertyStatusData = {
                                Id: parseInt(this.RepairPropertyId),
                                Status: "Review Bid",
                                CreatedBy: this.userDetail.EmailId,
                                UpdatedBy: this.userDetail.EmailId,
                            };
                            this.propertyService
                                .UpdatePropertyStatus(propertyStatusData)
                                .subscribe(
                                    (response) => {
                                        this.sessionService.notifier.notify(
                                            "success",
                                            "Send Bid For Consultant Review Successfully."
                                        );
                                        this.getDrawRequestData(
                                            this.RepairPropertyId
                                        );
                                    },
                                    (error) => {
                                        this.sessionService.notifier.notify(
                                            "error",
                                            error.error.message
                                        );
                                        this.loading = false;
                                    }
                                );
                        },
                        (error) => {
                            this.sessionService.notifier.notify(
                                "error",
                                error.error.message
                            );
                            this.loading = false;
                        }
                    );
                }
            },
            (error) => {}
        );
    }

    reviseBid(bidId) {}

    changeAction(action) {
        this.drawRequestBOAction = action;
    }

    cancelAction() {
        this.drawRequestBOAction = "";
    }
}

/** Builds and returns a new User. */
function createNewUser(id: number, userList): UserData {
    const name =
        NAMES[Math.round(Math.random() * (NAMES.length - 1))] +
        " " +
        NAMES[Math.round(Math.random() * (NAMES.length - 1))].charAt(0) +
        ".";
    if (userList.Items[id] != undefined) {
        userList.Items[id].id = id + 1;
        return userList.Items[id];
    }
}
