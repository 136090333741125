import { Component, OnInit, ViewChild } from "@angular/core";
import { FormGroup, Validators, FormControl } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { DataSource } from "@angular/cdk/table";
import { BorrowerService } from "../borrower/borrower.service";
import { SessionService } from "../service/session.service";
import { ConsultantLiabilityService } from "./consultant-liability.service";
import { PropertyService } from "../property/property.service";
import * as moment from "moment";

export interface UserData {
    id: string;
    name: string;
    email: string;
    lastLogin: Date;
}

/** Constants used to fill up our data base. */
const COLORS: string[] = [
    "maroon",
    "red",
    "orange",
    "yellow",
    "olive",
    "green",
    "purple",
    "fuchsia",
    "lime",
    "teal",
    "aqua",
    "blue",
    "navy",
    "black",
    "gray",
];
const NAMES: string[] = [
    "Maia",
    "Asher",
    "Olivia",
    "Atticus",
    "Amelia",
    "Jack",
    "Charlotte",
    "Theodore",
    "Isla",
    "Oliver",
    "Isabella",
    "Jasper",
    "Cora",
    "Levi",
    "Violet",
    "Arthur",
    "Mia",
    "Thomas",
    "Elizabeth",
];

/**
 * @title Data table with sorting, pagination, and filtering.
 */

@Component({
    selector: "app-consultant-liability",
    templateUrl: "./consultant-liability.component.html",
    styleUrls: ["./consultant-liability.component.scss"],
})
export class ConsultantLiabilityComponent implements OnInit {
    displayedColumns: string[] = [
        "ActionName",
        "AnonymousUser",
        "AuthenticatedUser",
        "Administrator",
        "Consultants",
        "GeneralContractors",
        "Borrower",
        "LoanOfficer",
        "LoanProcessor",
    ];
    dataSource: MatTableDataSource<UserData>;
    mobnumPattern = "^(d{3})sd{3}-d{4}";
    add_consultant_liability = new FormGroup({
        property: new FormControl(null, [Validators.required]),
        agreementDate: new FormControl(null),
        consultantFee: new FormControl(null),
        consutantFeeReimbursed: new FormControl(null),
        drawingBelow: new FormControl(null),
        drawingBetween: new FormControl(null),
        drawingOver: new FormControl(null),
        changeOrder: new FormControl(null),
        drawRequestsMin: new FormControl(null),
        drawRequestsMax: new FormControl(null),
        selfHelpDraw: new FormControl(null),
        changeScopeOfWorkMin: new FormControl(null),
        changeScopeOfWorkMax: new FormControl(null),
        multiFamilyDwelling: new FormControl(null),
        workPlan: new FormControl(null),
        id: new FormControl(null),
    });

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    isList: boolean = true;
    isAdd: boolean = false;
    isEdit: boolean = false;
    submitted: boolean = false;
    favoriteSeason: string;
    userDetail: any = {};
    userList: any = {};
    propertyList: any = {};
    propertyDetailsData: any = {};
    defaultConsultantLiabilityFee: any = {};
    propertyListStatus = false;
    loading = false;
    status: any = ["Blocked", "Active"];
    roles: any = [
        { Key: "1", Value: "authenticated user" },
        { Key: "2", Value: "Administrator" },
        { Key: "3", Value: "Consultants" },
        { Key: "4", Value: "General Contractors" },
        { Key: "5", Value: "Borrower" },
        { Key: "6", Value: "Loan Officer" },
        { key: "7", Value: "Loan Processor" },
        { key: "8", Value: " Notify user of new account" },
        { key: "9", Value: "Draw Administrator" },
    ];
    constructor(
        private route: ActivatedRoute,
        private _bservice: BorrowerService,
        private sessionService: SessionService,
        private propertyService: PropertyService
    ) {}

    ngOnInit() {
        if (this.sessionService.get("userInfo") != undefined) {
            this.userDetail = JSON.parse(this.sessionService.get("userInfo"));
        }
        this.isAdd = true;
        this.isList = false;
        this.isEdit = false;
        var propertyId = this.route.snapshot.paramMap.get("propertyId");
        this.add_consultant_liability.controls["property"].setValue(propertyId);
        var data = {
            EmailId: "",
            PageSize: 100000,
            LastEvaluatedKey: 0,
            UserType: this.sessionService.userType[4].Value,
        };
        this._bservice.GetSetting(data).subscribe((response) => {
            if (response != null) {
                this.defaultConsultantLiabilityFee = response;
                this.GetConsultantLiability(propertyId);
                this.getPropertyDetails(propertyId);
                this.GetPropertyList();
            }
        });
    }

    applyFilter(filterValue: string) {
        this.dataSource.filter = filterValue.trim().toLowerCase();

        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    }

    SaveRecord() {
        if (this.add_consultant_liability.value.agreementDate._i) {
            var agreementDate =
                this.add_consultant_liability.value.agreementDate._i.month +
                1 +
                "/" +
                this.add_consultant_liability.value.agreementDate._i.date +
                "/" +
                this.add_consultant_liability.value.agreementDate._i.year;
        } else {
            var agreementDate = "5/26/2019";
        }

        if (this.add_consultant_liability.invalid) {
            return;
        } else {
            this.submitted = false;
            var formData = this.add_consultant_liability.getRawValue();
            var currentDate = new Date();
            if (formData.id == null && !this.isEdit) {
                this.loading = true;
                var data = {
                    PropertyId: formData.property,
                    Title: "Title",
                    AgreementDate: agreementDate,
                    ConsultantFee: formData.consultantFee,
                    ConsultantFeeReimbursed: formData.consutantFeeReimbursed,
                    DrawingBelow100k: formData.drawingBelow,
                    DrawingBetween100k150k: formData.drawingBetween,
                    DrawingOver150k: formData.drawingOver,
                    ChangeOrder: formData.changeOrder,
                    DrawRequestsMin: formData.drawRequestsMin,
                    DrawRequestsMax: formData.drawRequestsMax,
                    SelfHelpDraw: formData.selfHelpDraw,
                    ChangeScopeWorkMin: formData.changeScopeOfWorkMin,
                    ChangeScopeWorkMax: formData.changeScopeOfWorkMax,
                    MultiFamilyDwelling: formData.multiFamilyDwelling,
                    WorkPlan: formData.workPlan,
                    CreatedBy: this.userDetail.EmailId,
                    CreatedDate: currentDate,
                    UpdateDate: currentDate,
                    UpdatedBy: this.userDetail.EmailId,
                };

                this.propertyService.AddConsultantLiability(data).subscribe(
                    (response) => {
                        this.sessionService.notifier.notify(
                            "success",
                            "Consultant liability added successfully."
                        );
                        this.isList = true;
                        this.isAdd = false;

                        this.loading = false;
                    },
                    (error) => {
                        this.sessionService.notifier.notify(
                            "error",
                            error.error.message
                        );
                        this.loading = false;
                    }
                );
            } else {
                this.loading = true;
                var data1 = {
                    Id: formData.id,
                    PropertyId: formData.property,
                    Title: "Title",
                    AgreementDate: agreementDate,
                    ConsultantFee: formData.consultantFee,
                    ConsultantFeeReimbursed: formData.consutantFeeReimbursed,
                    DrawingBelow100k: formData.drawingBelow,
                    DrawingBetween100k150k: formData.drawingBetween,
                    DrawingOver150k: formData.drawingOver,
                    ChangeOrder: formData.changeOrder,
                    DrawRequestsMin: formData.drawRequestsMin,
                    DrawRequestsMax: formData.drawRequestsMax,
                    SelfHelpDraw: formData.selfHelpDraw,
                    ChangeScopeWorkMin: formData.changeScopeOfWorkMin,
                    ChangeScopeWorkMax: formData.changeScopeOfWorkMax,
                    MultiFamilyDwelling: formData.multiFamilyDwelling,
                    WorkPlan: formData.workPlan,
                    CreatedBy: this.userDetail.EmailId,
                    CreatedDate: currentDate,
                    UpdateDate: currentDate,
                    UpdatedBy: this.userDetail.EmailId,
                };
                this.propertyService.UpdateConsultantLiability(data1).subscribe(
                    (response) => {
                        this.sessionService.notifier.notify(
                            "success",
                            "Consultant liability updated successfully."
                        );

                        this.isList = true;
                        this.isAdd = false;
                        this.isEdit = false;

                        this.loading = false;
                    },
                    (error) => {
                        this.sessionService.notifier.notify(
                            "error",
                            error.error.message
                        );
                        this.loading = false;
                    }
                );
            }
        }
        window.scroll(0, 0);
    }

    nextPage(event: PageEvent) {}

    getPropertyDetails(propertyId) {
        this.loading = true;

        this.propertyService.GetPropertyDetails(propertyId).subscribe(
            (response) => {
                if (response != null) {
                    this.propertyDetailsData = response;
                }
            },
            (error) => {}
        );
    }

    GetPropertyList() {
        this.loading = true;
        var data = {
            EmailId: "",
            PageSize: 100000,
            LastEvaluatedKey: 0,
            UserType: this.sessionService.userType[1].Value,
        };
        this.propertyService.GetPropertyList(data).subscribe(
            (response) => {
                if (response != null) {
                    this.propertyList = response.Items;
                    this.propertyListStatus = true;
                    this.loading = false;
                }
            },
            (error) => {}
        );
    }

    GetConsultantLiability(propertyId) {
        this.loading = true;
        var currentDate = new Date();
        var data = {
            PropertyId: propertyId,
        };
        this.propertyService.GetFhaConsultantLiabilityDetails(data).subscribe(
            (response) => {
                if (response.Count > 0) {
                    var setData = response.Items[0];

                    this.add_consultant_liability.setValue({
                        property: propertyId,
                        agreementDate: setData.AgreementDate
                            ? moment(setData.AgreementDate)
                            : "",
                        consultantFee: setData.ConsultantFee
                            ? setData.ConsultantFee
                            : this.defaultConsultantLiabilityFee
                                  .ConsultantLiabilityFormSetting.ConsultantFee,
                        consutantFeeReimbursed: setData.ConsultantFeeReimbursed
                            ? setData.ConsultantFeeReimbursed
                            : this.defaultConsultantLiabilityFee
                                  .ConsultantLiabilityFormSetting
                                  .ConsultantFeeReimbursed,
                        drawingBelow: setData.DrawingBelow100k
                            ? setData.DrawingBelow100k
                            : this.defaultConsultantLiabilityFee
                                  .ConsultantLiabilityFormSetting
                                  .EstimateBelow100k,
                        drawingBetween: setData.DrawingBetween100k150k
                            ? setData.DrawingBetween100k150k
                            : this.defaultConsultantLiabilityFee
                                  .ConsultantLiabilityFormSetting
                                  .EstimateBetween100k150k,
                        drawingOver: setData.DrawingOver150k
                            ? setData.DrawingOver150k
                            : this.defaultConsultantLiabilityFee
                                  .ConsultantLiabilityFormSetting
                                  .EstimateAbove150k,
                        changeOrder: setData.ChangeOrder
                            ? setData.ChangeOrder
                            : this.defaultConsultantLiabilityFee
                                  .ConsultantLiabilityFormSetting
                                  .PerChangeOrder,
                        drawRequestsMin: setData.DrawRequestsMin
                            ? setData.DrawRequestsMin
                            : this.defaultConsultantLiabilityFee
                                  .ConsultantLiabilityFormSetting
                                  .DrawRequestMin,
                        drawRequestsMax: setData.DrawRequestsMax
                            ? setData.DrawRequestsMax
                            : this.defaultConsultantLiabilityFee
                                  .ConsultantLiabilityFormSetting
                                  .DrawRequestMax,
                        selfHelpDraw: setData.SelfHelpDraw
                            ? setData.SelfHelpDraw
                            : this.defaultConsultantLiabilityFee
                                  .ConsultantLiabilityFormSetting.SelfHelpDraw,
                        changeScopeOfWorkMin: setData.ChangeScopeWorkMin
                            ? setData.ChangeScopeWorkMin
                            : this.defaultConsultantLiabilityFee
                                  .ConsultantLiabilityFormSetting
                                  .ScopeofWorkChangeMin,
                        changeScopeOfWorkMax: setData.ChangeScopeWorkMax
                            ? setData.ChangeScopeWorkMax
                            : this.defaultConsultantLiabilityFee
                                  .ConsultantLiabilityFormSetting
                                  .ScopeofWorkChangeMax,
                        multiFamilyDwelling: setData.MultiFamilyDwelling
                            ? setData.MultiFamilyDwelling
                            : this.defaultConsultantLiabilityFee
                                  .ConsultantLiabilityFormSetting
                                  .MultiFamilyDwelling,
                        workPlan: setData.WorkPlan
                            ? setData.WorkPlan
                            : this.defaultConsultantLiabilityFee
                                  .ConsultantLiabilityFormSetting.WorkPlan,
                        id: setData.Id,
                    });
                } else {
                    this.add_consultant_liability.setValue({
                        property: propertyId,
                        agreementDate: moment(currentDate),
                        consultantFee:
                            this.defaultConsultantLiabilityFee
                                .ConsultantLiabilityFormSetting.ConsultantFee,
                        consutantFeeReimbursed:
                            this.defaultConsultantLiabilityFee
                                .ConsultantLiabilityFormSetting
                                .ConsultantFeeReimbursed,
                        drawingBelow:
                            this.defaultConsultantLiabilityFee
                                .ConsultantLiabilityFormSetting
                                .EstimateBelow100k,
                        drawingBetween:
                            this.defaultConsultantLiabilityFee
                                .ConsultantLiabilityFormSetting
                                .EstimateBetween100k150k,
                        drawingOver:
                            this.defaultConsultantLiabilityFee
                                .ConsultantLiabilityFormSetting
                                .EstimateAbove150k,
                        changeOrder:
                            this.defaultConsultantLiabilityFee
                                .ConsultantLiabilityFormSetting.PerChangeOrder,
                        drawRequestsMin:
                            this.defaultConsultantLiabilityFee
                                .ConsultantLiabilityFormSetting.DrawRequestMin,
                        drawRequestsMax:
                            this.defaultConsultantLiabilityFee
                                .ConsultantLiabilityFormSetting.DrawRequestMax,
                        selfHelpDraw:
                            this.defaultConsultantLiabilityFee
                                .ConsultantLiabilityFormSetting.SelfHelpDraw,
                        changeScopeOfWorkMin:
                            this.defaultConsultantLiabilityFee
                                .ConsultantLiabilityFormSetting
                                .ScopeofWorkChangeMin,
                        changeScopeOfWorkMax:
                            this.defaultConsultantLiabilityFee
                                .ConsultantLiabilityFormSetting
                                .ScopeofWorkChangeMax,
                        multiFamilyDwelling:
                            this.defaultConsultantLiabilityFee
                                .ConsultantLiabilityFormSetting
                                .MultiFamilyDwelling,
                        workPlan:
                            this.defaultConsultantLiabilityFee
                                .ConsultantLiabilityFormSetting.WorkPlan,
                        id: null,
                    });
                }
            },
            (error) => {}
        );
    }

    GetUserList() {
        this.loading = true;
        var data = {
            EmailId: "",
            PageSize: 100000,
            LastEvaluatedKey: 0,
            UserType: this.sessionService.userType[4].Value,
        };
        this._bservice.GetUserList(data).subscribe(
            (response) => {
                if (response != null) {
                    var setPermissionData = {
                        Count: 40,
                        ScannedCount: 40,
                        TotalRecord: 40,
                        Items: [],
                    };
                    this.userList = setPermissionData;
                    const users = Array.from(
                        { length: this.userList.Count },
                        (_, k) => createNewUser(k, this.userList)
                    );
                    this.dataSource = new MatTableDataSource(users);
                    this.dataSource.paginator = this.paginator;
                    this.dataSource.sort = this.sort;
                    this.loading = false;
                }
            },
            (error) => {}
        );
    }

    formReset() {
        this.add_consultant_liability.reset();
    }
}

/** Builds and returns a new User. */
function createNewUser(id: number, userList): UserData {
    const name =
        NAMES[Math.round(Math.random() * (NAMES.length - 1))] +
        " " +
        NAMES[Math.round(Math.random() * (NAMES.length - 1))].charAt(0) +
        ".";
    if (userList.Items[id] != undefined) {
        userList.Items[id].id = id + 1;
        return userList.Items[id];
    }
}
