<div class="content p-24 tbl">
    <ngx-loading class="ngxLoader" 
        [show]="loading"
        [config]="{ backdropBorderRadius: '3px' }"
    ></ngx-loading>
    <h1 class="m-0">Property Gallery</h1>
    <a class="addBorrow" href="javascript:void(0)" routerLink="/properties">
        Back to Property</a
    >
    <br />
    <div class="container-login100">
        <mat-card class="my-form" style="overflow: hidden">
            <!-- <table>
      <thead>
        <th style="padding: 0 0 15px;">Selected Files</th>
        <th style="padding: 0 0 15px;">Action</th>
      </thead>
      <tbody>
          <tr *ngFor="let imageData of propertyImagesData; let i = index">
            <td style="padding: 0 0 15px;" align="center">
                <img [src]="imageData" width="120">
            </td>
            <td style="padding: 0 0 15px;" align="center">
                <button [disabled]="loading" style=" border: 1px solid;
                padding: 9px 6px;" (click)="removeImage(i);" type="button" class="btn btn-primary">Remove</button>
            </td>
          </tr>
      </tbody>
    </table> -->

            <div *ngFor="let imageData of propertyImagesData; let i = index">
                <img
                    style="float: left; margin: 0 15px 0 0"
                    [src]="imageData"
                    (click)="imgPopup(imageData)"
                    width="150"
                />
            </div>
        </mat-card>
    </div>
</div>
