<div class="content p-24 tbl">
    <ngx-loading class="ngxLoader" 
        [show]="loading"
        [config]="{ backdropBorderRadius: '3px' }"
    ></ngx-loading>
    <h1 class="m-0">Fill General Contractor Information</h1>

    <div style="float: right">
        <a
            *ngIf="isAdd || isEdit"
            class=""
            href="javascript:void(0)"
            (click)="isAdd = false; isList = true; isEdit = false"
            ><mat-icon
                _ngcontent-c102=""
                class="secondary-text s-22 mat-icon material-icons mat-icon-no-color"
                role="img"
                aria-hidden="true"
                >view_list</mat-icon
            >
            List Contractor</a
        >
        <a class="" href="javascript:void(0)" routerLink="/properties">
            Back to Property</a
        >
    </div>

    <br /><br />
    <div class="mat-elevation-z8" [hidden]="!isList">
        <a
            *ngIf="isList"
            class="addGCBtn"
            href="javascript:void(0)"
            (click)="isList = false; isAdd = true; isEdit = false; formReset()"
            ><mat-icon
                _ngcontent-c102=""
                class="secondary-text s-22 mat-icon material-icons mat-icon-no-color"
                role="img"
                aria-hidden="true"
                >add_circle</mat-icon
            >
            <span>Add New Contractor</span></a
        >

        <table mat-table [dataSource]="dataSource" matSort>
            <!-- ID Column -->
            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
                <td mat-cell *matCellDef="let row">{{ row.id }}</td>
            </ng-container>
            <ng-container matColumnDef="FirstName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    First Name
                </th>
                <td mat-cell *matCellDef="let row">{{ row.FirstName }}</td>
            </ng-container>
            <!-- Name Column -->
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Last Name
                </th>
                <td mat-cell *matCellDef="let row">{{ row.LastName }}</td>
            </ng-container>

            <!-- Progress Column -->
            <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    E-mail
                </th>
                <td mat-cell *matCellDef="let row">{{ row.EmailId }}</td>
            </ng-container>

            <!-- Color Column -->
            <ng-container matColumnDef="lstLogin">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Action
                </th>
                <td
                    mat-cell
                    *matCellDef="let row"
                    [style.color]="row.lastLogin"
                >
                    <button
                        mat-raised-button
                        (click)="deleteUser(row.Id)"
                        type="button"
                        color="info"
                    >
                        Delete
                    </button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr
                mat-row
                *matRowDef="let row; columns: displayedColumns"
                style="cursor: pointer"
            ></tr>
        </table>

        <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
        <button
            *ngIf="propertyDetails.Status != 'Property File created'"
            mat-raised-button
            (click)="SorReviewPage()"
            type="button"
            class="reviewButton"
        >
            Review Draft Feasibilty Report
        </button>
        <button
            *ngIf="
                propertyDetails.Status != 'Property File created' &&
                propertyDetails.InvitedGeneralContractors != null
            "
            mat-raised-button
            (click)="SorReviewStatus()"
            type="button"
            class="draftButton"
        >
            Accept Draft Feasibilty Report
        </button>
        <button
            *ngIf="
                propertyDetails.Status != 'Property File created' &&
                sorDetails.Approved == true
            "
            mat-raised-button
            (click)="SorReviseReviewStatus()"
            type="button"
            class="draftButton"
        >
            Accept Draft Feasibilty Report
        </button>
    </div>
    <div class="mat-elevation-z8" *ngIf="isAdd || isEdit">
        <div class="container-login100">
            <mat-card class="my-form">
                <form
                    [formGroup]="add_contractor"
                    class="example-form"
                    (ngSubmit)="SaveContractor()"
                >
                    <mat-card-header>
                        <mat-card-title>Add Contractor</mat-card-title>
                    </mat-card-header>
                    <mat-card-content>
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <mat-form-field
                                            class="example-full-width"
                                        >
                                            <span
                                                class="mat-form-field-label-wrapper"
                                                ><!----><label
                                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                    >E-mail *</label
                                                ></span
                                            >
                                            <input
                                                matInput
                                                formControlName="email"
                                                (blur)="onBlurFilter()"
                                                name="username"
                                            />
                                            <div
                                                class="error"
                                                *ngIf="
                                                    (!add_contractor.controls[
                                                        'email'
                                                    ].valid &&
                                                        add_contractor.controls[
                                                            'email'
                                                        ].touched) ||
                                                    (add_contractor.controls[
                                                        'email'
                                                    ].invalid &&
                                                        submitted)
                                                "
                                            >
                                                Enter valid email.
                                            </div>
                                        </mat-form-field>
                                    </td>
                                    <td>
                                        <mat-form-field
                                            class="example-full-width"
                                        >
                                            <span
                                                class="mat-form-field-label-wrapper"
                                                ><!----><label
                                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                    >First Name *</label
                                                ></span
                                            >
                                            <input
                                                matInput
                                                formControlName="fname"
                                                name="fname"
                                            />
                                            <div
                                                class="error"
                                                *ngIf="
                                                    (!add_contractor.controls[
                                                        'fname'
                                                    ].valid &&
                                                        add_contractor.controls[
                                                            'fname'
                                                        ].touched) ||
                                                    (add_contractor.controls[
                                                        'fname'
                                                    ].invalid &&
                                                        submitted)
                                                "
                                            >
                                                Enter first name.
                                            </div>
                                        </mat-form-field>
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        <mat-form-field
                                            class="example-full-width"
                                        >
                                            <span
                                                class="mat-form-field-label-wrapper"
                                                ><!----><label
                                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                    >Last Name *</label
                                                ></span
                                            >
                                            <input
                                                matInput
                                                formControlName="lname"
                                                name="lname"
                                            />
                                            <div
                                                class="error"
                                                *ngIf="
                                                    (!add_contractor.controls[
                                                        'lname'
                                                    ].valid &&
                                                        add_contractor.controls[
                                                            'lname'
                                                        ].touched) ||
                                                    (add_contractor.controls[
                                                        'lname'
                                                    ].invalid &&
                                                        submitted)
                                                "
                                            >
                                                Enter last name.
                                            </div>
                                        </mat-form-field>
                                    </td>
                                    <td>
                                        <mat-form-field
                                            class="example-full-width"
                                        >
                                            <span
                                                class="mat-form-field-label-wrapper"
                                                ><!----><label
                                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                    >Phone Number</label
                                                ></span
                                            >
                                            <input
                                                matInput
                                                [mask]="maskPhoneFormat" 
                                                placeholder="(000) 000-0000"
                                                (keyup)="
                                                    keyDownPhoneFormat($event)
                                                "
                                                (change)="
                                                    changePhoneFormat($event)
                                                "
                                                formControlName="phone_no"
                                                name="phone_no"
                                            />
                                            <div
                                                class="error"
                                                *ngIf="
                                                    (!add_contractor.controls[
                                                        'phone_no'
                                                    ].valid &&
                                                        add_contractor.controls[
                                                            'phone_no'
                                                        ].touched) ||
                                                    (add_contractor.controls[
                                                        'phone_no'
                                                    ].invalid &&
                                                        submitted)
                                                "
                                            >
                                            <div class="error" *ngIf="add_contractor?.controls['phone_no']?.errors?.mask">
                                                Enter valid phone no.
                                               </div>
                                            </div>
                                        </mat-form-field>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </mat-card-content>
                    <mat-card-actions>
                        <button mat-raised-button type="submit" color="primary">
                            {{ isEdit ? "Save" : "Save" }}</button
                        >&nbsp; &nbsp;&nbsp;
                        <button
                            mat-raised-button
                            *ngIf="
                                userDetail.UserType == 'Administrator' && isEdit
                            "
                            (click)="deleteUser(add_contractor.value.id)"
                            type="button"
                            color="info"
                        >
                            Delete</button
                        >&nbsp; &nbsp;&nbsp;
                        <button
                            mat-raised-button
                            (click)="contractorList()"
                            type="button"
                            color="info"
                        >
                            Back
                        </button>
                    </mat-card-actions>
                </form>
            </mat-card>
        </div>
    </div>
</div>
