import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatRadioModule } from "@angular/material/radio";
import { MatTableModule } from "@angular/material/table";
import { AuthGuardService as AuthGard } from "../service/auth/auth-guard.service";
import { TranslateModule } from "@ngx-translate/core";
import { NgxLoadingModule } from "ngx-loading";
import { FuseSharedModule } from "@fuse/shared.module";
import { AllowableFeesComponent } from "./allowable-fees.component";

const routes = [
    {
        path: "allowable-fees",
        component: AllowableFeesComponent,
        canActivate: [AuthGard],
    },
];

@NgModule({
    declarations: [AllowableFeesComponent],
    imports: [
        RouterModule.forChild(routes),

        TranslateModule,
        NgxLoadingModule.forRoot({}),
        FuseSharedModule,
        MatButtonModule,
        MatCardModule,
        MatFormFieldModule,
        MatInputModule,
        MatTableModule,
        MatPaginatorModule,
        MatIconModule,
        MatRadioModule,
        MatCheckboxModule,
    ],
    exports: [AllowableFeesComponent],
})
export class AllowableFeesModule {}
