import { Component, OnInit, ViewChild } from "@angular/core";
import { FormGroup, Validators, FormControl } from "@angular/forms";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { DataSource } from "@angular/cdk/table";
import { BorrowerService } from "../borrower/borrower.service";
import { SessionService } from "../service/session.service";
import { NotifierService } from "angular-notifier";
import { Router, ActivatedRoute } from "@angular/router";
import { PropertyService } from "../property/property.service";
export interface UserData {
    id: string;
    name: string;
    email: string;
    lastLogin: Date;
    GCNetwork: string;
    FirstName: string;
}

/** Constants used to fill up our data base. */
const COLORS: string[] = [
    "maroon",
    "red",
    "orange",
    "yellow",
    "olive",
    "green",
    "purple",
    "fuchsia",
    "lime",
    "teal",
    "aqua",
    "blue",
    "navy",
    "black",
    "gray",
];
const NAMES: string[] = [
    "Maia",
    "Asher",
    "Olivia",
    "Atticus",
    "Amelia",
    "Jack",
    "Charlotte",
    "Theodore",
    "Isla",
    "Oliver",
    "Isabella",
    "Jasper",
    "Cora",
    "Levi",
    "Violet",
    "Arthur",
    "Mia",
    "Thomas",
    "Elizabeth",
];

/**
 * @title Data table with sorting, pagination, and filtering.
 */

@Component({
    selector: "app-general-contractor-info",
    templateUrl: "./general-contractor-info.component.html",
    styleUrls: ["./general-contractor-info.component.scss"],
})
export class GeneralContractorInfoComponent implements OnInit {
    displayedColumns: string[] = [
        "id",
        "FirstName",
        "name",
        "email",
        "lstLogin",
    ];
    loading = false;

    dataSource: MatTableDataSource<UserData>;
    mobnumPattern = "^(d{3})sd{3}-d{4}";
    add_contractor = new FormGroup({
        email: new FormControl(null, [Validators.required, Validators.email,Validators.maxLength(40),Validators.minLength(5)]),
        fname: new FormControl(null, [Validators.required,Validators.maxLength(30),Validators.minLength(3)]),
        lname: new FormControl(null, [Validators.required,Validators.maxLength(30),Validators.minLength(3)]),
        // phone_no: new FormControl(null,[Validators.pattern(this.mobnumPattern), Validators.maxLength(15),Validators.minLength(8)]),
        phone_no: new FormControl(null,[]),
        id: new FormControl(null),
        statusType: new FormControl(null),
    });
    @ViewChild("customNotification") customNotificationTmpl;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    notifier: any;
    isList: boolean = true;
    isAdd: boolean = false;
    isEdit: boolean = false;
    submitted: boolean = false;
    favoriteSeason: string;
    userDetail: any = {};
    propertyDetails: any = {};
    sorDetails: any = {};
    userList: any = {};
    GCList: any = {};
    propertyId = "";
    status: any = ["Blocked", "Active"];
    roles: any = [
        { Key: "1", Value: "authenticated user" },
        { Key: "2", Value: "Administrator" },
        { Key: "3", Value: "Consultants" },
        { Key: "4", Value: "General Contractors" },
        { Key: "5", Value: "Borrower" },
        { Key: "6", Value: "Loan Officer" },
        { key: "7", Value: "Loan Processor" },
        { key: "8", Value: " Notify user of new account" },
        { key: "9", Value: "Draw Administrator" },
    ];
    maskPhoneFormat: any = '(000) 000-0000';
    constructor(
        private _bservice: BorrowerService,
        private _pservice: PropertyService,
        private sessionService: SessionService,
        private router: Router,
        private route: ActivatedRoute,
        private notifierService: NotifierService
    ) {
        this.notifier = notifierService;
    }

    ngOnInit() {
        var propertyId = this.route.snapshot.paramMap.get("propertyId");
        this.propertyId = propertyId;
        if (this.sessionService.get("userInfo") != undefined) {
            this.userDetail = JSON.parse(this.sessionService.get("userInfo"));
        }
        this.GetUserList(this.propertyId);
        this.getSorDetails();
    }

    keyDownPhoneFormat($event) {
        var key = $event.keyCode;

        if (key == 91 || (15 < key && key < 19) || (37 <= key && key <= 40)) {
            return;
        }
        this.changePhoneFormat($event);
    }

    changePhoneFormat($event) {
        var getVal = $event.target.value;
        var tel = getVal.replace(/[^0-9]/g, "");
        $event.target.value = this.changeFilter(tel);
    }
    changeFilter(tel) {
        if (!tel) {
            return "";
        }

        var value = tel.toString().trim().replace(/^\+/, "");

        if (value.match(/[^0-9]/)) {
            return tel;
        }

        var country, city, number;

        switch (value.length) {
            case 1:
            case 2:
            case 3:
                city = value;
                break;

            default:
                city = value.slice(0, 3);
                number = value.slice(3);
        }

        if (number) {
            if (number.length > 3) {
                number = number.slice(0, 3) + "-" + number.slice(3, 7);
            } else {
                number = number;
            }

            return ("(" + city + ") " + number).trim();
        } else {
            return "(" + city;
        }
    }

    applyFilter(filterValue: string) {
        this.dataSource.filter = filterValue.trim().toLowerCase();

        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    }

    showAddForm() {
        this.isAdd = true;
        this.isList = false;
    }

    contractorList() {
        this.isAdd = false;
        this.isEdit = false;
        this.isList = true;
    }
    //   ValidatePartnerPass()
    // {

    //   let password = this.add_contractor.controls.password.value;
    //   let repeatPassword = this.add_contractor.controls.confirmPassword.value;

    //   if (repeatPassword !== password) {

    //     this.add_contractor.controls["confirmPassword"].setErrors({'incorrect': true});

    //   }
    //   else {

    //     this.add_contractor.controls["confirmPassword"].setErrors(null);

    //   }
    // }
    SaveContractor() {
        this.loading = true;
        if (this.add_contractor.invalid) {
            return;
        } else {
            this.submitted = false;
            var formData = this.add_contractor.value;
            if (formData.id == null) {
                this.loading = true;
                var sendPassword = this.generatePassword();
                var propertyId = this.propertyDetails.Id;
                var data = {
                    propertyId: propertyId,
                    EmailId: formData.email,
                    Password: sendPassword,
                    UserType: this.sessionService.userType[3].Value,
                    FirstName: formData.fname,
                    LastName: formData.lname,
                    Status: "Active",
                    Phone: formData.phone_no,
                    IDNumber: "",
                    Signature: "",
                    ContractorLicense: "",
                    LeadSafeCertified: "",
                    ServiceAreas: "",
                    GCNetwork: "",
                    CreatedBy: this.userDetail.EmailId,
                    Address1: "",
                    Addresss2: "",
                    City: "",
                    State: "",
                    Zip: "",
                    Country: "",
                };
                this._bservice.AddBorrower(data).subscribe(
                    (response) => {
                        var pdata = this.propertyDetails;
                        if (pdata.InvitedGeneralContractors == null) {
                            pdata.InvitedGeneralContractors = [];
                        }
                        pdata.InvitedGeneralContractors.push(response.Id);
                        this._pservice.UpdateProperty(pdata).subscribe(
                            (response1) => {
                                var dataForMail = {
                                    GeneralContractorId: response.Id,
                                    Id: pdata.Id,
                                    Status: pdata.Status,
                                };
                                this._pservice
                                    .SendAssignmentMailToGc(dataForMail)
                                    .subscribe(
                                        (response) => {},
                                        (error) => {}
                                    );

                                this.sessionService.notifier.notify(
                                    "success",
                                    "General Contractor added successfully."
                                );
                                this.isList = true;
                                this.isAdd = false;
                                this.GetUserList(this.propertyId);
                                this.loading = false;
                            },
                            (error) => {}
                        );
                    },
                    (error) => {
                        this.sessionService.notifier.notify(
                            "error",
                            error.error.message
                        );
                        this.loading = false;
                    }
                );
            } else {
                var pdata = this.propertyDetails;

                if (pdata.InvitedGeneralContractors != null) {
                    if (
                        pdata.InvitedGeneralContractors.indexOf(formData.id) < 0
                    ) {
                        pdata.InvitedGeneralContractors.push(formData.id);
                        this._pservice.UpdateProperty(pdata).subscribe(
                            (response) => {
                                var dataForMail = {
                                    GeneralContractorId: formData.id,
                                    Id: pdata.Id,
                                    Status: pdata.Status,
                                };
                                this._pservice
                                    .SendAssignmentMailToGc(dataForMail)
                                    .subscribe(
                                        (response) => {},
                                        (error) => {}
                                    );

                                this.sessionService.notifier.notify(
                                    "success",
                                    "General Contractor added successfully."
                                );
                                this.isList = true;
                                this.isAdd = false;
                                this.GetUserList(this.propertyId);
                                this.loading = false;
                            },
                            (error) => {}
                        );
                    } else {
                        this.sessionService.notifier.notify(
                            "error",
                            "General Contractor already exists."
                        );
                        this.loading = false;
                    }
                } else {
                    pdata.InvitedGeneralContractors = [];
                    pdata.InvitedGeneralContractors.push(formData.id);
                    this._pservice.UpdateProperty(pdata).subscribe(
                        (response) => {
                            var dataForMail = {
                                GeneralContractorId: formData.id,
                                Id: pdata.Id,
                                Status: pdata.Status,
                            };
                            this._pservice
                                .SendAssignmentMailToGc(dataForMail)
                                .subscribe(
                                    (response) => {},
                                    (error) => {}
                                );

                            this.sessionService.notifier.notify(
                                "success",
                                "General Contractor added successfully."
                            );
                            this.isList = true;
                            this.isAdd = false;
                            this.GetUserList(this.propertyId);
                            this.loading = false;
                        },
                        (error) => {}
                    );
                }
            }
        }
    }
    getSorDetails() {
        var data = {
            EmailId: "",
            PropertyId: this.propertyId,
            PageSize: 100000,
            LastEvaluatedKey: 0,
            UserType: this.sessionService.userType[1].Value,
        };
        this._bservice.GetSorDetails(data).subscribe((response) => {
            if (response != null && response.Items.length > 0) {
                this.sorDetails = response.Items[0];
            }
        });
    }
    SorReviewPage() {
        this.router.navigate(["/specification-repair-review", this.propertyId]);
    }
    SorReviewStatus() {
        this.submitted = false;

        if (
            (this.propertyDetails.InvitedGeneralContractors != null &&
                this.propertyDetails.InvitedGeneralContractors != undefined &&
                this.propertyDetails.InvitedGeneralContractors.length > 0 &&
                this.propertyDetails.InvitedGeneralContractors[0] != null &&
                this.propertyDetails.InvitedGeneralContractors[0] !=
                    undefined &&
                this.propertyDetails.InvitedGeneralContractors[0] != "") ||
            (this.propertyDetails.GeneralContractorId != undefined &&
                this.propertyDetails.GeneralContractorId != null)
        ) {
            var propertyStatusData = {
                Id: parseInt(this.route.snapshot.paramMap.get("propertyId")),
                Status: "Bid Process Started",
                CreatedBy: this.userDetail.EmailId,
                UpdatedBy: this.userDetail.EmailId,
                ConsultantId: this.propertyDetails.ConsultantId,
                Comment: null,
            };

            this._pservice.UpdatePropertyStatus(propertyStatusData).subscribe(
                (response) => {
                    var data = {
                        EmailId: "",
                        PropertyId: parseInt(
                            this.route.snapshot.paramMap.get("propertyId")
                        ),
                        PageSize: 100000,
                        LastEvaluatedKey: 0,
                        UserType: this.sessionService.userType[1].Value,
                    };
                    this._bservice.GetSorDetails(data).subscribe((response) => {
                        if (response != null && response.Items.length > 0) {
                            var today = new Date();
                            var propertyData = {
                                PropertyId: parseInt(
                                    this.route.snapshot.paramMap.get(
                                        "propertyId"
                                    )
                                ),
                                Id: parseInt(response.Items[0].Id),
                                Approved: true,
                                ApprovedDate: today,
                                revisedStatus: false,
                            };

                            this._pservice
                                .sorAcceptedByBo(propertyData)
                                .subscribe((response) => {
                                    var data = {
                                        Id: this.propertyId,
                                        BorrowerActiveStep: 5,
                                    };
                                    this._pservice
                                        .saveBorrowerActiveStep(data)
                                        .subscribe((response) => {
                                            this.sessionService.notifier.notify(
                                                "success",
                                                "Status updated."
                                            );
                                            this.router.navigate([
                                                "/property-details",
                                                this.propertyId,
                                            ]);
                                        });
                                });
                        } else {
                            var data = {
                                Id: this.propertyId,
                                BorrowerActiveStep: 5,
                            };
                            this._pservice
                                .saveBorrowerActiveStep(data)
                                .subscribe((response) => {
                                    this.sessionService.notifier.notify(
                                        "success",
                                        "Status updated."
                                    );
                                    this.router.navigate([
                                        "/property-details",
                                        this.propertyId,
                                    ]);
                                });
                        }
                    });
                },
                (error) => {
                    this.sessionService.notifier.notify(
                        "error",
                        error.error.message
                    );
                    this.loading = false;
                }
            );
        } else {
            this.showNotification("error", "Please add a general contractor.");
        }
    }
    SorReviseReviewStatus() {
        this.submitted = false;

        if (
            (this.propertyDetails.InvitedGeneralContractors != null &&
                this.propertyDetails.InvitedGeneralContractors != undefined &&
                this.propertyDetails.InvitedGeneralContractors.length > 0 &&
                this.propertyDetails.InvitedGeneralContractors[0] != null &&
                this.propertyDetails.InvitedGeneralContractors[0] !=
                    undefined &&
                this.propertyDetails.InvitedGeneralContractors[0] != "") ||
            (this.propertyDetails.GeneralContractorId != undefined &&
                this.propertyDetails.GeneralContractorId != null)
        ) {
            var propertyStatusData = {
                Id: parseInt(this.route.snapshot.paramMap.get("propertyId")),
                Status: "Bid Process Started",
                CreatedBy: this.userDetail.EmailId,
                UpdatedBy: this.userDetail.EmailId,
                ConsultantId: this.propertyDetails.ConsultantId,
                Comment: null,
            };

            this._pservice.UpdatePropertyStatus(propertyStatusData).subscribe(
                (response) => {
                    var data = {
                        EmailId: "",
                        PropertyId: parseInt(
                            this.route.snapshot.paramMap.get("propertyId")
                        ),
                        PageSize: 100000,
                        LastEvaluatedKey: 0,
                        UserType: this.sessionService.userType[1].Value,
                    };
                    this._bservice.GetSorDetails(data).subscribe((response) => {
                        if (response != null && response.Items.length > 0) {
                            var today = new Date();
                            var propertyData = {
                                PropertyId: parseInt(
                                    this.route.snapshot.paramMap.get(
                                        "propertyId"
                                    )
                                ),
                                Id: parseInt(response.Items[0].Id),
                                Approved: true,
                                ApprovedDate: today,
                                revisedStatus: true,
                            };

                            this._pservice
                                .sorAcceptedByBo(propertyData)
                                .subscribe((response) => {
                                    var data = {
                                        Id: this.propertyId,
                                        BorrowerActiveStep: 5,
                                    };
                                    this._pservice
                                        .saveBorrowerActiveStep(data)
                                        .subscribe((response) => {
                                            this.sessionService.notifier.notify(
                                                "success",
                                                "Status updated."
                                            );
                                            this.router.navigate([
                                                "/property-details",
                                                this.propertyId,
                                            ]);
                                        });
                                });
                        } else {
                            var data = {
                                Id: this.propertyId,
                                BorrowerActiveStep: 5,
                            };
                            this._pservice
                                .saveBorrowerActiveStep(data)
                                .subscribe((response) => {
                                    this.sessionService.notifier.notify(
                                        "success",
                                        "Status updated."
                                    );
                                    this.router.navigate([
                                        "/property-details",
                                        this.propertyId,
                                    ]);
                                });
                        }
                    });
                },
                (error) => {
                    this.sessionService.notifier.notify(
                        "error",
                        error.error.message
                    );
                    this.loading = false;
                }
            );
        } else {
            this.showNotification("error", "Please add a general contractor.");
        }
    }
    showNotification(nType, nMessage) {
        this.notifier.show({
            message: nMessage,
            type: nType,
            template: this.customNotificationTmpl,
        });
    }
    nextPage(event: PageEvent) {}
    generatePassword() {
        var password = Math.random().toString(36).slice(-8);
        return password;
    }
    GetUserList(propertyId) {
        this.loading = true;
        var data = {
            EmailId: "",
            PageSize: 100000,
            LastEvaluatedKey: 0,
            UserType: this.sessionService.userType[3].Value,
        };
        this._bservice.GetUserList(data).subscribe(
            (response) => {
                if (response != null) {
                    this.GCList = response.Items;

                    this._pservice.GetPropertyDetails(propertyId).subscribe(
                        (pResponse) => {
                            if (pResponse != null) {
                                var InvitedGeneralContractors =
                                    pResponse.InvitedGeneralContractors;
                                this.propertyDetails = pResponse;
                                if (InvitedGeneralContractors != null) {
                                    if (InvitedGeneralContractors.length > 0) {
                                        let InvitedGC = response.Items.filter(
                                            (item) => {
                                                return (
                                                    InvitedGeneralContractors.indexOf(
                                                        item.Id
                                                    ) > -1
                                                );
                                            }
                                        );

                                        this.userList.Items = InvitedGC;
                                        this.userList.Count = InvitedGC.length;
                                        this.userList.TotalRecord =
                                            InvitedGC.length;
                                        this.userList.ScannedCount =
                                            InvitedGC.length;
                                        this.userList.Items.sort(function (
                                            a,
                                            b
                                        ) {
                                            return a.Id < b.Id ? 1 : -1;
                                        });
                                        const users = Array.from(
                                            { length: this.userList.Count },
                                            (_, k) =>
                                                createNewUser(k, this.userList)
                                        );
                                        this.dataSource =
                                            new MatTableDataSource(users);
                                        this.dataSource.paginator =
                                            this.paginator;

                                        this.loading = false;
                                    }
                                }
                            }
                        },
                        (error) => {}
                    );

                    this.loading = false;
                }
            },
            (error) => {}
        );
    }

    deleteUser(userid) {
        if (confirm("Are you sure to delete contractor")) {
            this.loading = true;

            this._pservice.GetPropertyDetails(this.propertyId).subscribe(
                (pResponse) => {
                    if (pResponse != null) {
                        var InvitedGeneralContractors =
                            pResponse.InvitedGeneralContractors;
                        if (InvitedGeneralContractors != null) {
                            this.propertyDetails = pResponse;
                            if (InvitedGeneralContractors.length > 0) {
                                let newGCList = [];
                                for (
                                    let i = 0;
                                    i < InvitedGeneralContractors.length;
                                    i++
                                ) {
                                    if (
                                        InvitedGeneralContractors[i] != userid
                                    ) {
                                        newGCList.push(
                                            InvitedGeneralContractors[i]
                                        );
                                    }
                                }
                                if (newGCList.length > 0) {
                                    this.propertyDetails.InvitedGeneralContractors =
                                        newGCList;
                                } else {
                                    this.propertyDetails.InvitedGeneralContractors =
                                        null;
                                }

                                this._pservice
                                    .UpdateProperty(this.propertyDetails)
                                    .subscribe(
                                        (response) => {
                                            this.sessionService.notifier.notify(
                                                "success",
                                                "Contractor deleted successfully."
                                            );
                                            this.isList = true;
                                            this.isAdd = false;
                                            this.isEdit = false;
                                            this.formReset();
                                            this.GetUserList(this.propertyId);
                                            this.loading = false;
                                        },
                                        (error) => {}
                                    );
                            }
                        }
                    }
                },
                (error) => {}
            );

            this.loading = false;

            window.scroll(0, 0);
        }
    }

    EditUser(item) {
        this.isEdit = true;
        this.isList = false;
        this.add_contractor.setValue({
            email: item.EmailId,
            fname: item.FirstName,
            lname: item.LastName,
            phone_no: item.Phone == undefined ? null : item.Phone,
            statusType: item.Status,
            // Password:item.Password,
            // confirmPassword:item.Password,
            id: item.Id,
            // license:item.ContractorLicense,
            // leadSafe:item.LeadSafeCertified,
            // areas:item.ServiceAreas==undefined ? '' : item.ServiceAreas,
            // signature:item.Signature==undefined ? '' : item.Signature,
            // idnumber:item.IDNumber==undefined ? '' : item.IDNumber,
            // country:item.Country==undefined ? '' : item.Country,
            // city:item.City==undefined ? '' : item.City,
            // state:item.State==undefined ? '' : item.State,
            // zipcode:item.Zip==undefined ? '' : item.Zip,
            // address2:item.Addresss2==undefined ? '' : item.Addresss2,
            // address1:item.Address1==undefined ? '' : item.Address1,
        });
    }

    onBlurFilter() {
        let filterGC = this.GCList.filter((item) => {
            return (
                this.add_contractor.value.email != null &&
                item.EmailId.toLowerCase() ==
                    this.add_contractor.value.email.toLowerCase()
            );
        });
        if (filterGC.length > 0) {
            this.add_contractor.controls["email"].setValue(filterGC[0].EmailId);
            this.add_contractor.controls["fname"].setValue(
                filterGC[0].FirstName
            );
            this.add_contractor.controls["lname"].setValue(
                filterGC[0].LastName
            );
            this.add_contractor.controls["phone_no"].setValue(
                filterGC[0].Phone
            );
            this.add_contractor.controls["id"].setValue(filterGC[0].Id);
            return;
        } else {
            this.add_contractor.controls["fname"].setValue("");
            this.add_contractor.controls["lname"].setValue("");
            this.add_contractor.controls["phone_no"].setValue("");
            this.add_contractor.controls["id"].setValue(null);
            return;
        }
    }

    formReset() {
        this.add_contractor.reset();
        this.add_contractor.controls["statusType"].setValue("Active");
    }
}

/** Builds and returns a new User. */
function createNewUser(id: number, userList): UserData {
    const name =
        NAMES[Math.round(Math.random() * (NAMES.length - 1))] +
        " " +
        NAMES[Math.round(Math.random() * (NAMES.length - 1))].charAt(0) +
        ".";
    if (userList.Items[id] != undefined) {
        userList.Items[id].id = id + 1;
        return userList.Items[id];
    }
}
