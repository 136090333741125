import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { FormGroup, Validators, FormControl } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { DataSource } from "@angular/cdk/table";
import { BorrowerService } from "../borrower/borrower.service";
import { SessionService } from "../service/session.service";
import { PropertyService } from "../property/property.service";
import * as moment from "moment";

export interface UserData {
    id: string;
    name: string;
    email: string;
    lastLogin: Date;
}

/** Constants used to fill up our data base. */
const COLORS: string[] = [
    "maroon",
    "red",
    "orange",
    "yellow",
    "olive",
    "green",
    "purple",
    "fuchsia",
    "lime",
    "teal",
    "aqua",
    "blue",
    "navy",
    "black",
    "gray",
];
const NAMES: string[] = [
    "Maia",
    "Asher",
    "Olivia",
    "Atticus",
    "Amelia",
    "Jack",
    "Charlotte",
    "Theodore",
    "Isla",
    "Oliver",
    "Isabella",
    "Jasper",
    "Cora",
    "Levi",
    "Violet",
    "Arthur",
    "Mia",
    "Thomas",
    "Elizabeth",
];

/**
 * @title Data table with sorting, pagination, and filtering.
 */

@Component({
    selector: "app-fha-consultant-agreement",
    templateUrl: "./fha-consultant-agreement.component.html",
    styleUrls: ["./fha-consultant-agreement.component.scss"],
})
export class FhaConsultantAgreementComponent implements OnInit {
    baseurl = this.sessionService.endpoint;
    displayedColumns: string[] = [
        "ActionName",
        "AnonymousUser",
        "AuthenticatedUser",
        "Administrator",
        "Consultants",
        "GeneralContractors",
        "Borrower",
        "LoanOfficer",
        "LoanProcessor",
    ];
    dataSource: MatTableDataSource<UserData>;
    mobnumPattern = "^(d{3})sd{3}-d{4}";
    add_conAgreement = new FormGroup({
        property: new FormControl(null, [Validators.required]),
        signatureDate: new FormControl(null, [Validators.required]),
        consultantFee: new FormControl("0"),
        feasibilityStudy: new FormControl("0"),
        mileageFeeForVisit: new FormControl("0"),
        invoiceDate: new FormControl(null),
        invoicePaidToDate: new FormControl(null),
        notPaidInFull: new FormControl(null),
        amountPaidToDate: new FormControl("0"),
        id: new FormControl(null),
    });

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    isList: boolean = true;
    isAdd: boolean = false;
    isEdit: boolean = false;
    notPaidInFullChecked: boolean = false;
    submitted: boolean = false;
    favoriteSeason: string;
    userDetail: any = {};
    userList: any = {};
    globalPropertyId = null;
    propertyList: any = {};
    loading = false;
    agreementId = null;
    myinvoiceDate = null;
    status: any = ["Blocked", "Active"];
    roles: any = [
        { Key: "1", Value: "authenticated user" },
        { Key: "2", Value: "Administrator" },
        { Key: "3", Value: "Consultants" },
        { Key: "4", Value: "General Contractors" },
        { Key: "5", Value: "Borrower" },
        { Key: "6", Value: "Loan Officer" },
        { key: "7", Value: "Loan Processor" },
        { key: "8", Value: " Notify user of new account" },
        { key: "9", Value: "Draw Administrator" },
    ];
    constructor(
        @Inject(ActivatedRoute) private route: ActivatedRoute,
        private _bservice: BorrowerService,
        private sessionService: SessionService,
        private propertyService: PropertyService
    ) {}

    ngOnInit() {
        if (this.sessionService.get("userInfo") != undefined) {
            this.userDetail = JSON.parse(this.sessionService.get("userInfo"));
        }

        var propertyId = this.route.snapshot.paramMap.get("propertyId");
        this.add_conAgreement.controls["property"].setValue(propertyId);
        this.globalPropertyId = propertyId;
        this.isAdd = true;
        this.isList = false;
        this.isEdit = false;
        this.GetPropertyList();
        this.GetConsultantAgreement(propertyId);
    }

    applyFilter(filterValue: string) {
        this.dataSource.filter = filterValue.trim().toLowerCase();

        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    }
    setNotPaidInFullChecked(event) {
        this.notPaidInFullChecked = event;

        if (this.notPaidInFullChecked) {
            this.add_conAgreement.controls["notPaidInFull"].setValue(true);
        } else {
            this.add_conAgreement.controls["notPaidInFull"].setValue(false);
        }
    }
    SaveRecord() {
        if (this.add_conAgreement.invalid) {
            return;
        } else {
            this.submitted = false;
            var formData = this.add_conAgreement.value;
            var currentDate = new Date();
            var SignatureDateFHA = "";
            if (this.add_conAgreement.value.signatureDate) {
                if (this.add_conAgreement.value.signatureDate._i.year) {
                    SignatureDateFHA =
                        this.add_conAgreement.value.signatureDate._i.month +
                        1 +
                        "/" +
                        this.add_conAgreement.value.signatureDate._i.date +
                        "/" +
                        this.add_conAgreement.value.signatureDate._i.year;
                } else {
                    SignatureDateFHA =
                        this.add_conAgreement.value.signatureDate._i;
                }
            }
            var invoiceDate = "";
            if (this.add_conAgreement.value.invoiceDate) {
                if (this.add_conAgreement.value.invoiceDate._i.year) {
                    invoiceDate =
                        this.add_conAgreement.value.invoiceDate._i.month +
                        1 +
                        "/" +
                        this.add_conAgreement.value.invoiceDate._i.date +
                        "/" +
                        this.add_conAgreement.value.invoiceDate._i.year;
                } else {
                    invoiceDate = this.add_conAgreement.value.invoiceDate._i;
                }
            }

            if (formData.id == null && !this.isEdit) {
                this.loading = true;
                var data = {
                    PropertyId: formData.property,
                    Title: "Title",
                    SignatureDateFHA: SignatureDateFHA,
                    ConsultantFee: formData.consultantFee,
                    FeasibilityStudy: formData.feasibilityStudy,
                    InvoiceDate: invoiceDate,
                    MileageFeeForVisit: formData.mileageFeeForVisit,
                    InvoicePaidToDate: formData.invoicePaidToDate,
                    NotPaidInFull: formData.notPaidInFull,
                    AmountPaidToDate: formData.amountPaidToDate,
                    CreatedBy: this.userDetail.EmailId,
                    CreatedDate: currentDate,
                    UpdateDate: currentDate,
                    UpdatedBy: this.userDetail.EmailId,
                };

                this.propertyService.AddConsultantAgreement(data).subscribe(
                    (response) => {
                        this.sessionService.notifier.notify(
                            "success",
                            "Consultant agreement added successfully."
                        );
                        this.GetConsultantAgreement(formData.property);
                        this.isList = true;
                        this.isAdd = false;

                        this.loading = false;
                    },
                    (error) => {
                        this.sessionService.notifier.notify(
                            "error",
                            error.error.message
                        );
                        this.loading = false;
                    }
                );
            } else {
                this.loading = true;
                var data1 = {
                    Id: formData.id,
                    PropertyId: formData.property,
                    Title: "Title",
                    SignatureDateFHA: SignatureDateFHA,
                    ConsultantFee: formData.consultantFee,
                    FeasibilityStudy: formData.feasibilityStudy,
                    InvoiceDate: invoiceDate,
                    MileageFeeForVisit: formData.mileageFeeForVisit,
                    InvoicePaidToDate: formData.invoicePaidToDate,
                    NotPaidInFull: formData.notPaidInFull,
                    AmountPaidToDate: formData.amountPaidToDate,
                    CreatedBy: this.userDetail.EmailId,
                    CreatedDate: currentDate,
                    UpdateDate: currentDate,
                    UpdatedBy: this.userDetail.EmailId,
                };
                this.propertyService.UpdateConsultantAgreement(data1).subscribe(
                    (response) => {
                        this.sessionService.notifier.notify(
                            "success",
                            "Consultant agreement updated successfully."
                        );
                        this.GetConsultantAgreement(formData.property);
                        this.isList = true;
                        this.isAdd = false;
                        this.isEdit = false;
                        this.loading = false;
                    },
                    (error) => {
                        this.sessionService.notifier.notify(
                            "error",
                            error.error.message
                        );
                        this.loading = false;
                    }
                );
            }
        }
        window.scroll(0, 0);
    }

    nextPage(event: PageEvent) {}

    GetPropertyList() {
        this.loading = true;
        var data = {
            EmailId: "",
            PageSize: 100000,
            LastEvaluatedKey: 0,
            UserType: this.sessionService.userType[1].Value,
        };
        this.propertyService.GetPropertyList(data).subscribe(
            (response) => {
                if (response != null) {
                    this.propertyList = response.Items;
                    this.loading = false;
                }
            },
            (error) => {}
        );
    }
    printInvoicePdf() {
        this.loading = true;
        var data = {
            PropertyId: this.globalPropertyId,
        };
        this.propertyService.printInvoiceDetails(data).subscribe(
            (response) => {
                window.open(this.baseurl + response, "_blank");
                this.loading = false;
            },
            (error) => {
                this.sessionService.notifier.notify(
                    "error",
                    error.error.message
                );
                this.loading = false;
            }
        );
    }
    GetConsultantAgreement(propertyId) {
        this.loading = true;
        var currentDate = new Date();
        var data = {
            PropertyId: propertyId,
        };
        this.propertyService.GetFhaConsultantAgreementDetails(data).subscribe(
            (response) => {
                if (response != null) {
                    var setData = response.Items[0];

                    if (setData) {
                        this.notPaidInFullChecked = setData.NotPaidInFull;
                        this.agreementId = setData.Id;
                        this.add_conAgreement.setValue({
                            property: propertyId,
                            signatureDate:
                                setData.SignatureDateFHA != "" &&
                                setData.SignatureDateFHA != null
                                    ? moment(setData.SignatureDateFHA)
                                    : null,
                            consultantFee:
                                setData.ConsultantFee != "" &&
                                setData.ConsultantFee != null
                                    ? setData.ConsultantFee
                                    : "0",
                            feasibilityStudy:
                                setData.FeasibilityStudy != "" &&
                                setData.FeasibilityStudy != null
                                    ? setData.FeasibilityStudy
                                    : "0",
                            invoiceDate:
                                setData.InvoiceDate != "" &&
                                setData.InvoiceDate != null
                                    ? moment(setData.InvoiceDate)
                                    : null,
                            mileageFeeForVisit:
                                setData.MileageFeeForVisit != "" &&
                                setData.MileageFeeForVisit != null
                                    ? setData.MileageFeeForVisit
                                    : "0",
                            invoicePaidToDate: setData.InvoicePaidToDate,
                            notPaidInFull: setData.NotPaidInFull
                                ? setData.NotPaidInFull
                                : false,
                            amountPaidToDate:
                                setData.AmountPaidToDate != "" &&
                                setData.AmountPaidToDate != null
                                    ? setData.AmountPaidToDate
                                    : "0",
                            id: setData.Id,
                        });
                    }
                } else {
                    this.add_conAgreement.setValue({
                        property: new FormControl(null, [Validators.required]),
                        signatureDate: moment(new Date()),
                        consultantFee: "100",
                        feasibilityStudy: "100",
                        invoiceDate: moment(new Date()),
                        mileageFeeForVisit: "100",
                        invoicePaidToDate: false,
                        notPaidInFull: false,
                        amountPaidToDate: "0",
                        id: null,
                    });
                }
            },
            (error) => {}
        );
    }

    GetUserList() {
        this.loading = true;
        var data = {
            EmailId: "",
            PageSize: 100000,
            LastEvaluatedKey: 0,
            UserType: this.sessionService.userType[4].Value,
        };
        this._bservice.GetUserList(data).subscribe(
            (response) => {
                if (response != null) {
                    var setPermissionData = {
                        Count: 40,
                        ScannedCount: 40,
                        TotalRecord: 40,
                        Items: [],
                    };
                    this.userList = setPermissionData;
                    const users = Array.from(
                        { length: this.userList.Count },
                        (_, k) => createNewUser(k, this.userList)
                    );
                    this.dataSource = new MatTableDataSource(users);
                    this.dataSource.paginator = this.paginator;
                    this.dataSource.sort = this.sort;
                    this.loading = false;
                }
            },
            (error) => {}
        );
    }

    formReset() {
        this.add_conAgreement.reset();
    }
}

/** Builds and returns a new User. */
function createNewUser(id: number, userList): UserData {
    const name =
        NAMES[Math.round(Math.random() * (NAMES.length - 1))] +
        " " +
        NAMES[Math.round(Math.random() * (NAMES.length - 1))].charAt(0) +
        ".";
    if (userList.Items[id] != undefined) {
        userList.Items[id].id = id + 1;
        return userList.Items[id];
    }
}
