<div class="content p-24 tbl">
  <ngx-loading  class="ngxLoader" [show]="loading" class="ngxLoader" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>
   <h1 class="m-0">Construction Items Library</h1>
   <!-- <a *ngIf="isList" class="addBorrow" href="javascript:void(0)" (click)="showAddForm()">
    <mat-icon _ngcontent-c102="" class="secondary-text s-22 mat-icon material-icons mat-icon-no-color" role="img" aria-hidden="true">add_circle</mat-icon> Add New Item</a> -->
  <a *ngIf="isAdd" class="addBorrow" href="javascript:void(0)" (click)="ReportComponentList();">
    <mat-icon _ngcontent-c102="" class="secondary-text s-22 mat-icon material-icons mat-icon-no-color" role="img" aria-hidden="true">view_list</mat-icon> List Items</a>
  
 <br>
 <div class="container-login100" [hidden]="!isList">
   <mat-tab-group mat-align-tabs="start" class="activityComponentTabGroup" [(selectedIndex)]="selectedMainIndex"  style="clear: both">
    <mat-tab  >
      <ng-template mat-tab-label >
      <div (click)="changeActiveComponent(0, 0,'Masonry')">
        <span class="labelText">Construction Activity Groups</span>
      </div>
      </ng-template>
      <div class="topComponentForm1">
        <a   class="addNewComponentBtn" (click)="showAddActivityForm()" ><span>+</span> Create a New Construction Activity</a>
        <div class="searchRecord">Search Records <input type="text" class="searchRecordField"   (keyup)="filterActivityGroupList($event);" /> </div>
        
      </div>
      <div class="leftComponentList">
        <mat-card>
          <div class=" componentListDetails container-login100">
            <table class="activityList">
              <thead>
                <tr>
                  <th style="width: 90px; max-width: 90px;">&nbsp;</th>
                  <th style="width: 14%; max-width: 14%;">Activity Group Name</th>
                  <th style="width: 80%; max-width: 80%;">Report Components</th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngIf="activitySearchResult=='' || activitySearchResult==null || activitySearchResult==undefined" >
                <tr *ngFor="let activityItem of activityList; let i=index;">
                  <td><div class="actionBtn"> <a (click)="copyActivityGroup(activityItem.RepairItemName)" class="copyIcon"><img [src]="copyImagePath" alt="Copy Activity Group"/></a><a (click)="editActivityGroup(activityItem.RepairItemName)" class="editIcon"><img [src]="editImagePath" alt="Edit Activity Group" /></a><a (click)="deleteActivityGroup(activityItem.RepairItemName)" class="removeIcon"><img [src]="deleteImagePath" alt="Delete Activity Group" /></a></div></td>
                  <td><span>{{i+1}}.&nbsp;{{activityItem.RepairItemName}}</span></td>
                  <td> <ul class="activityGroupList" style="display: inline-block;">
                    <ng-container *ngFor="let componentItem of activityItem.ReportComponent; let k=index; " >
                      <ng-container *ngFor="let selItem of componentItem.RepairItemDetails; let l=index; " >
                        <li class="activityComponentItemName">
                          <!-- <span (click)="removeCompontFromGroup(activityItem.RepairItemName, selItem.RepairItemName)">X</span> -->
                          {{selItem.RepairItemName}} </li>
                      </ng-container>
                    </ng-container>
                  </ul></td>
                </tr>
              </ng-container>
              <ng-container *ngIf="activitySearchResult!='' && activitySearchResult!=null && activitySearchResult!=undefined" >
                <tr *ngFor="let activityItem of activitySearchResult; let i=index;">
                  <td><div class="actionBtn"> <a (click)="copyActivityGroup(activityItem.RepairItemName)" class="copyIcon"><img [src]="copyImagePath" alt="Copy Activity Group"/></a><a (click)="editActivityGroup(activityItem.RepairItemName)" class="editIcon"><img [src]="editImagePath" alt="Edit Activity Group" /></a><a (click)="deleteActivityGroup(activityItem.RepairItemName)" class="removeIcon"><img [src]="deleteImagePath" alt="Delete Activity Group" /></a></div></td>
                  <td><span>{{i+1}}.&nbsp;{{activityItem.RepairItemName}}</span></td>
                  <td> <ul class="activityGroupList" style="display: inline-block;">
                    <ng-container *ngFor="let componentItem of activityItem.ReportComponent; let k=index; " >
                      <ng-container *ngFor="let selItem of componentItem.RepairItemDetails; let l=index; " >
                        <li class="activityComponentItemName">
                          <!-- <span (click)="removeCompontFromGroup(activityItem.RepairItemName, selItem.RepairItemName)">X</span> -->
                          {{selItem.RepairItemName}} </li>
                      </ng-container>
                    </ng-container>
                  </ul></td>
                </tr>
                </ng-container>
              </tbody>
            </table>

            </div>
            </mat-card>
       
      </div>
     
     </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label >
        <div (click)="changeActiveComponent(1, 0,'Masonry')">
        <span class="labelText">Report Components</span>
      </div>
  
      </ng-template>
      <div class="topComponentForm">
        <a   class="addNewComponentBtn"  (click)="showAddReportComponentForm()"><span>+</span> Create a New Report Component</a>
        <div class="searchRecord">Search Records <input type="text" class="searchRecordField"  (keyup)="filterReportComponentList($event);" name="searchRecordText" /> </div>
        
      </div>
       <div class="leftComponentList">
        
        <mat-tab-group  class="reportCompent_List" mat-align-tabs="start" [(selectedIndex)]="selectedIndex" style="clear: both">
          <ng-container *ngFor="let reportComponent of reportComponentsList; let i=index;" >
            <mat-tab >
              <ng-template mat-tab-label >
                <div *ngIf="isResultEmpty==true">
                  <div *ngIf="reportComponent.isSearchActiveTab==true" class="mainmenu_tabContent isSearchActiveTab"  (click)="changeActiveComponent(1, i,reportComponent.RepairType)">
                
                    <span class="labelText">{{reportComponent.id}}. {{reportComponent.RepairType}}</span>
                    </div>
                    <div  *ngIf="reportComponent.isSearchActiveTab==false || reportComponent.isSearchActiveTab==undefined || reportComponent.isSearchActiveTab==null" class="mainmenu_tabContent"  (click)="changeActiveComponent(1, i,reportComponent.RepairType)">
                    
                      <span class="labelText">{{reportComponent.id}}. {{reportComponent.RepairType}}</span>
                      </div>
                </div>
                <div *ngIf="isResultEmpty!=true" class="searchResultTab" >
                  <div *ngIf="reportComponent.isSearchActiveTab==true" class="mainmenu_tabContent isSearchActiveTab"  (click)="changeActiveComponent(1, i,reportComponent.RepairType)">
                  
                  <span class="labelText">{{reportComponent.id}}. {{reportComponent.RepairType}}</span>
                  </div>
                  <div  *ngIf="reportComponent.isSearchActiveTab==false || reportComponent.isSearchActiveTab==undefined || reportComponent.isSearchActiveTab==null" class="mainmenu_tabContent"  (click)="changeActiveComponent(1, i,reportComponent.RepairType)">
                  
                    <span class="labelText">{{reportComponent.id}}. {{reportComponent.RepairType}}</span>
                    </div>
                  </div>
              </ng-template>
              
              
                
             
          
            <mat-card>
              <div class=" componentListDetails componentList-Details container-login100">
                <mat-accordion *ngIf="isResultEmpty==true" class="example-headers-align isResultEmpty" multi="false">
                <ng-container *ngFor="let repairComponentItem of repairDetailsList; let i=index;" >
                
                  <ng-container *ngIf="repairComponentItem.RepairType==activeReportComponent">
                      <form [formGroup]="RepairItemForm" class="example-form" (ngSubmit)="SaveConstructionItem()">
                         
                        <input  type="hidden"  [value]="repairComponentItem.RepairType"  formControlName="SendRepairType" name="SendRepairType">
                        <div formArrayName="RepairItemDetails">
                          <div *ngFor="let RepairItemDetail of RepairItemForm.get('RepairItemDetails')['controls']; let j=index">
                      <div [formGroupName]="j">
                      <mat-expansion-panel  (opened)="setStep(j)" class="materialUnitField-table" >
                        <mat-expansion-panel-header>
                          <mat-panel-title class="reportComponentTitle"  (click)="expandReportComponentItem(repairComponentItem.RepairType,RepairItemForm.value['RepairItemDetails'][j].RepairItemName)">
                            <span >{{RepairItemForm.value['RepairItemDetails'][j].RepairItemName}}</span>
                            <span> <strong>Material ( Unit: </strong>{{repairComponentItem.RepairItemDetails[j]?.MaterialUnitFull}}<strong> | Cost: </strong>${{RepairItemForm.value['RepairItemDetails'][j]?.MaterialUnitCost}} <strong> )</strong></span>
                           <span><strong>Labor ( Unit: </strong> {{repairComponentItem.RepairItemDetails[j]?.LaborUnitFull}}<strong> | Cost: </strong>${{RepairItemForm.value['RepairItemDetails'][j]?.LaborUnitCost}} <strong> )</strong></span>
                           <div class="actionBtn"> <a (click)="copyReportComponentItem(repairComponentItem.RepairType,RepairItemForm.value['RepairItemDetails'][j].RepairItemName)" class="copyIcon"><img [src]="copyImagePath" alt="Copy Report Component Item"/></a><a (click)="editReportComponentItem(repairComponentItem.RepairType,RepairItemForm.value['RepairItemDetails'][j].RepairItemName)" class="editIcon"><img [src]="editImagePath" alt="Edit Report Component Item" /></a><a (click)="deleteReportComponentItem(repairComponentItem.RepairType,RepairItemForm.value['RepairItemDetails'][j].RepairItemName)" class="removeIcon"><img [src]="deleteImagePath" alt="Delete Report Component Item" /></a></div>
                          </mat-panel-title>
                        </mat-expansion-panel-header>
                        
                           
                            <input  type="hidden"  [value]="RepairItemForm.value['RepairItemDetails'][j].RepairItemName" formControlName="RepairItemName" name="RepairItemName">
                          
                            
                          <table>
                            <tr>
                              <th colspan="2">
                                Material
                              </th>
                              <th colspan="2">
                                Labor
                              </th>
                            </tr>
                            <tr>
                              <td>  <mat-form-field class="materialUnitFieldContainer">
                                <mat-label>
                                  Unit
                                </mat-label>
                               
                                <select matNativeControl  placeholder="UNIT" formControlName="MaterialUnit">
                                  <option value="">-None-</option>
                                  <option value="EA" >Each = EA</option>
                                  <option value="LF"  >Linear foot = LF</option>
                                  <option value="SF" >Square Foot = SF</option>
                                  <option value="LS">Lump Sum = LS</option>
                                  <option value="SY"  >Square Yard = SY</option>
                                  <option value="*"  >* = Required</option>
                                </select>
                              </mat-form-field></td>
                              <td> <mat-form-field class="materialCostFieldContainer">
                                <mat-label>
                                  Unit Cost
                                </mat-label>
                                <span class="input-group-addon">$</span>
                                <input matInput  [value]="RepairItemForm.value['RepairItemDetails'][j].MaterialUnitCost" formControlName="MaterialUnitCost" name="UnitCost" type="number" class="custom-icon-input  example-right-align">
                                    
                              </mat-form-field></td>
                              <td> 
                                 <mat-form-field class="laborUnitFieldContainer">
                                  <mat-label>
                                    Unit
                                  </mat-label>
                                  
                                  <select matNativeControl placeholder="UNIT" formControlName="LaborUnit">
                                    <option value="">-None-</option>
                                    <option value="EA" >Each = EA</option>
                                  <option value="LF"   >Linear foot = LF</option>
                                  <option value="SF" >Square Foot = SF</option>
                                  <option value="LS"  >Lump Sum = LS</option>
                                  <option value="SY" ng-selected="true" >Square Yard = SY</option>
                                  <option value="*"   >* = Required</option>
                                  </select>
                              </mat-form-field></td>
                              <td><mat-form-field class="laborCostFieldContainer">
                                <mat-label>
                                  Unit Cost
                                </mat-label>
                                <span class="input-group-addon ">$</span>
                                <input matInput  [value]="RepairItemForm.value['RepairItemDetails'][j].LaborUnitCost" formControlName="LaborUnitCost" name="UnitCost" type="number" class=" custom-icon-input example-right-align">
                              </mat-form-field></td>
                            </tr>
                            <tr>
                              <th colspan="4">Summary</th>
                            </tr>
                            <tr>
                              <td colspan="4"><mat-form-field class="summaryFieldContainer">
                                
                                <textarea class="ComponentSummaryTextarea" matInput  [value]="RepairItemForm.value['RepairItemDetails'][j].Summary" formControlName="Summary" name="Summary"></textarea>
                              </mat-form-field></td>
                            </tr>
                          </table>
                          <button mat-raised-button type="submit" class="saveBtn">{{isEdit ? 'Save' : 'Save'}} </button>
                          
                      </mat-expansion-panel>
                    </div></div></div>
                  </form>
                   
                  </ng-container>
                </ng-container>
              </mat-accordion>
              <mat-accordion *ngIf="isResultEmpty!=true" class="example-headers-align isResultNotEmpty" multi="false">
                <ng-container *ngFor="let repairComponentItem of searchResult; let i=index;" >
                
                  <ng-container >
                      <form [formGroup]="RepairItemForm" class="example-form" (ngSubmit)="SaveConstructionItem()">
                         
                        <input  type="hidden"  [value]="repairComponentItem.RepairType"  formControlName="SendRepairType" name="SendRepairType">
                        <div formArrayName="RepairItemDetails">
                          <div *ngFor="let RepairItemDetail of repairComponentItem.RepairItemDetails; let j=index">
                      <div [formGroupName]="j">
                      <mat-expansion-panel  (opened)="setStep(j)"  >
                        <mat-expansion-panel-header>
                          <mat-panel-title class="reportComponentTitle" (click)="expandReportComponentItem(repairComponentItem.RepairType,RepairItemDetail.RepairItemName)" >
                            <span >{{RepairItemDetail.RepairItemName}}</span>
                            <span> <strong>Material ( Unit: </strong>{{RepairItemDetail?.MaterialUnitFull}}<strong> | Cost: </strong>${{RepairItemDetail?.MaterialUnitCost}} <strong> )</strong></span>
                           <span><strong>Labor ( Unit: </strong> {{RepairItemDetail?.LaborUnitFull}}<strong> | Cost: </strong>${{RepairItemDetail?.LaborUnitCost}} <strong> )</strong></span>
                           <div class="actionBtn"> <a (click)="copyReportComponentItem(repairComponentItem.RepairType,RepairItemDetail.RepairItemName)" class="copyIcon"><img [src]="copyImagePath" alt="Copy Report Component Item"/></a><a (click)="editReportComponentItem(repairComponentItem.RepairType,RepairItemDetail.RepairItemName)" class="editIcon"><img [src]="editImagePath" alt="Edit Report Component Item" /></a><a (click)="deleteReportComponentItem(repairComponentItem.RepairType,RepairItemDetail.RepairItemName)" class="removeIcon"><img [src]="deleteImagePath" alt="Delete Report Component Item" /></a></div>
                          </mat-panel-title>
                        </mat-expansion-panel-header>
                        
                           
                            <input  type="hidden"  [value]="RepairItemDetail.RepairItemName" formControlName="RepairItemName" name="RepairItemName">
                          
                            
                          <table>
                            <tr>
                              <th colspan="2">
                                Material
                              </th>
                              <th colspan="2">
                                Labor
                              </th>
                            </tr>
                            <tr>
                              <td>  <mat-form-field class="materialUnitFieldContainer">
                                <mat-label>
                                  Unit
                                </mat-label>
                               
                                <select matNativeControl  [value]="RepairItemDetail.MaterialUnit" placeholder="UNIT" formControlName="MaterialUnit">
                                  <option value="">-None-</option>
                                  <option value="EA" >Each = EA</option>
                                  <option value="LF"  >Linear foot = LF</option>
                                  <option value="SF" >Square Foot = SF</option>
                                  <option value="LS">Lump Sum = LS</option>
                                  <option value="SY"  >Square Yard = SY</option>
                                  <option value="*"  >* = Required</option>
                                </select>
                              </mat-form-field></td>
                              <td> <mat-form-field class="materialCostFieldContainer">
                                <mat-label>
                                  Unit Cost
                                </mat-label>
                                <span class="input-group-addon">$</span>
                                <input matInput  [value]="RepairItemDetail.MaterialUnitCost" formControlName="MaterialUnitCost" name="UnitCost" type="number" class="custom-icon-input  example-right-align">
                                    
                              </mat-form-field></td>
                              <td> 
                                 <mat-form-field class="laborUnitFieldContainer">
                                  <mat-label>
                                    Unit
                                  </mat-label>
                                  
                                  <select matNativeControl [value]="RepairItemDetail.LaborUnit" placeholder="UNIT" formControlName="LaborUnit">
                                    <option value="">-None-</option>
                                    <option value="EA" >Each = EA</option>
                                  <option value="LF"   >Linear foot = LF</option>
                                  <option value="SF" >Square Foot = SF</option>
                                  <option value="LS"  >Lump Sum = LS</option>
                                  <option value="SY" ng-selected="true" >Square Yard = SY</option>
                                  <option value="*"   >* = Required</option>
                                  </select>
                              </mat-form-field></td>
                              <td><mat-form-field class="laborCostFieldContainer">
                                <mat-label>
                                  Unit Cost
                                </mat-label>
                                <span class="input-group-addon ">$</span>
                                <input matInput  [value]="RepairItemDetail.LaborUnitCost" formControlName="LaborUnitCost" name="UnitCost" type="number" class=" custom-icon-input example-right-align">
                              </mat-form-field></td>
                            </tr>
                            <tr>
                              <th colspan="4">Summary</th>
                            </tr>
                            <tr>
                              <td colspan="4"><mat-form-field class="summaryFieldContainer">
                                
                                <textarea class="ComponentSummaryTextarea" matInput  [value]="RepairItemDetail.Summary" formControlName="Summary" name="Summary"></textarea>
                              </mat-form-field></td>
                            </tr>
                          </table>
                          <button mat-raised-button type="submit" class="saveBtn">{{isEdit ? 'Save' : 'Save'}} </button>
                          
                      </mat-expansion-panel>
                    </div></div></div>
                  </form>
                   
                  </ng-container>
                </ng-container>
              </mat-accordion>
                </div>
                </mat-card>
          </mat-tab>
          </ng-container>
        </mat-tab-group>
       
      </div>
    
      
    
   </mat-tab>
   
 </mat-tab-group>
   
   </div>
 
   <div class="mat-elevation-z8 addNewComponentFormSection" *ngIf="isAdd || isEdit">

    <div class="container-login100">
      <mat-card class="my-form">
        <form [formGroup]="RepairItemForm" class="example-form" (ngSubmit)="SaveConstructionItem()">
          <mat-card-header>
            <mat-card-title></mat-card-title>
          </mat-card-header>
          <mat-card-content>

            <mat-form-field>
              <span class="mat-form-field-label-wrapper"><!----><label class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"   >Construction Item</label></span>
              <select matNativeControl formControlName="SendRepairType" >
                <option value="">Select</option>
                <option *ngFor="let value of userList.Items" value="{{value.RepairType}}">{{value.id}} {{value.RepairType}}</option>
              </select>
            </mat-form-field>
            <h3>
              <Strong>Repair Item Details</Strong>
            </h3>
            <div formArrayName="RepairItemDetails">
              <div *ngFor="let RepairItemDetail of RepairItemForm.get('RepairItemDetails')['controls']; let i=index">
                <div [formGroupName]="i">
                              
                         
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <mat-form-field class="example-full-width">
                            <span class="mat-form-field-label-wrapper"><!----><label class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"   >Repair Item Name</label></span>
                            <input matInput formControlName="RepairItemName" name="RepairItemName">
                          </mat-form-field>
                        </td>
                        <td>
                         &nbsp;
                        </td>
                      
                      </tr>
                      <tr>
                        <td>
                          <h3>
                            <Strong>Material/LS</Strong>
                          </h3>
                        </td>
                        
                        <td></td>
                      </tr>
                      <tr>
                        <td>
                          <mat-form-field>
                            <span class="mat-form-field-label-wrapper"><!----><label class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"   >UNIT</label></span>
                            <select matNativeControl formControlName="MaterialUnit">
                             <option value="EA">Each = EA</option>                              
                              <option value="LF">Linear foot = LF</option>
                              <option value="SF">Square Foot = SF</option>
                              <option value="LS">Lump Sum = LS</option>
                              <option value="SY">Square Yard = SY</option>
                              <option value="*">* = Required</option>
                            </select>
                          </mat-form-field>
                        </td>
                       
                        <td>
                          <mat-form-field class="example-full-width">
                            <!-- <input matInput placeholder="Unit Cost" formControlName="MaterialUnitCost" name="UnitCost"> -->
                            <span class="mat-form-field-label-wrapper"><!----><label class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"   >Unit Cost</label></span>
                            <input matInput formControlName="MaterialUnitCost" name="UnitCost" type="number" class="example-right-align">
                                
                          </mat-form-field>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <h3>
                            <Strong>Labor</Strong>
                          </h3>
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>
                          <mat-form-field>
                            <span class="mat-form-field-label-wrapper"><!----><label class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"   >UNIT</label></span>
                            <select matNativeControl formControlName="LaborUnit">
                             <option value="EA">Each = EA</option>
                              <option value="LF">Linear foot = LF</option>
                              <option value="SF">Square Foot = SF</option>
                              <option value="LS">Lump Sum = LS</option>
                              <option value="SY">Square Yard = SY</option>
                              <option value="*">* = Required</option>
                            </select>
                          </mat-form-field>
                        </td>
                      
                        <td>
                          <mat-form-field class="example-full-width">
                            <!-- <input matInput placeholder="Unit Cost" formControlName="LaborUnitCost" name="UnitCost"> -->
                            <span class="mat-form-field-label-wrapper"><!----><label class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"   >Unit Cost</label></span>
                            <input matInput formControlName="LaborUnitCost" name="UnitCost" type="number" class="example-right-align">
                          </mat-form-field>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br>
                  <mat-form-field class="example-full-width">
                    <span class="mat-form-field-label-wrapper"><!----><label class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"   >Summary</label></span>
                    <textarea matInput formControlName="Summary" name="Summary"></textarea>
                  </mat-form-field>

                 

                </div>
                <br>
                <br>
              </div>
             
            </div>


          </mat-card-content>
          <mat-card-actions>
            <button mat-raised-button type="submit" class="SaveBtn">{{isEdit ? 'Save' : 'Save'}} </button>&nbsp; &nbsp;&nbsp;
            <button mat-raised-button (click)="ReportComponentList();" type="button" class="backBtn">Back</button>

          </mat-card-actions>
        </form>
      </mat-card>

    </div>
  </div> 
  <div class="container-login10011  addActivityGroupContainer mat-elevation-z8" *ngIf="isActAdd || isActEdit">

    <div class="container-login100">
      <mat-card class="my-form">
        <form [formGroup]="RepairActivityItemForm" class="example-form" (ngSubmit)="SaveActivityItem()">
          <mat-card-header>
            <mat-card-title>Construction Activity Group Creation</mat-card-title>
          </mat-card-header>
          <mat-card-content>

                <table>
                  <tbody>
                    <tr>
                      <td class="groupName_FilterSec" ><mat-form-field class="example-full-width">
                        <mat-label>Group Name</mat-label>
                        <input matInput  class="groupNameField"    formControlName="RepairItemName" >
                      </mat-form-field>
                       <mat-form-field  class="example-full-width">
                      <mat-label>Search Components</mat-label>
                      <input matInput  class="groupNameField" name="searchComponent" (keyup)="filterSearchComponents($event);" >
                      
                    </mat-form-field>
                    </td>
                    <td>&nbsp;</td>
                      <td>Use the report components table to choose
                        desired components to be added to the
                        New Activity Group. Use the arrows to add
                        or remove components as needed.</td>
                    </tr>
                    <tr class="textAlignCenter">
                      <td><span>Report Components</span></td>
                      <td>&nbsp;</td>
                      <td><span> Activity Group</span></td>
                    </tr>
                    <tr class="componentListBox">
                      <td>
                        <ul>
                          <li  *ngFor="let repairComponentItem of repairComponentList; let i=index;"  >
                            <div class="actionBtn" (click)="expandActivityComList(i)"> <a  class="expandIcon">+</a><span>{{repairComponentItem.id}}.&nbsp; {{repairComponentItem.RepairType}} </span></div>
                            
                            <ul class="activityComList" *ngIf="i==isShowComItem">
                              <ng-container *ngFor="let componentItem of repairComponentItem.RepairItemDetails; let k=index; " >
                                  <li class="activityComItemName"><input type="checkbox" name="reportCompName[]"  [checked]="tempSelection.indexOf(componentItem.RepairItemName) > -1" (click)="toggleSelection(repairComponentItem.id,repairComponentItem.RepairType,componentItem.RepairItemName)" value="{{componentItem.RepairItemName}}" /> {{componentItem.RepairItemName}} </li>
                              </ng-container>
                            </ul>
                          </li> 
                          </ul>

                      </td>
                      <td><a class="moveLeftToRight" (click)="addInSelectedList()"> &gt; </a><br/>
                        <a class="moveRightToleft" (click)="removeFromSelectedList()"> &lt; </a> 
                      </td>
                      <td>
                        <ul>
                          <li  *ngFor="let selectedComponent of selectedComponentListForGroup; let i=index;"  >
                            <div class="actionBtn" (click)="expandSelectedActivityComList(i)"> <a  class="expandIcon">+</a><span>{{selectedComponent.id}}.&nbsp; {{selectedComponent.RepairType}} </span></div>
                            
                            <ul class="activitySelectComList" *ngIf="i==isShowSelectedComItem">
                              <ng-container *ngFor="let componentItem of selectedComponent.RepairItemDetails; let k=index; " >
                                  <li class="activityComItemName"  >
                                    <span><input type="checkbox" name="activeReportCompName[]"  [checked]="tempDeSelection.indexOf(componentItem.RepairItemName) > -1" (click)="toggleDeSelection(selectedComponent.id,selectedComponent.RepairType,componentItem.RepairItemName)" value="{{componentItem.RepairItemName}}" /> {{componentItem.RepairItemName}} </span> 
                                  </li>
                                
                              </ng-container>
                            </ul>
                            
                          </li> 
                          </ul>
                      </td>
                     
                    </tr>
                    
                 
                  <tr>
                    
                    <td colspan="3"> <div class="popupSaveBackBtn" ><button mat-raised-button type="submit" color="primary">{{isEdit ? 'Save' : 'Save'}} </button>&nbsp; &nbsp;&nbsp;
                      <button mat-raised-button (click)="ActivityList();" type="button" color="info">Back</button></div></td>
                  </tr>
                  </tbody>
                </table>
          </mat-card-content>
         
        </form>
      </mat-card>

    </div>
  </div> 
 </div>