import {Component, OnInit, ViewChild} from '@angular/core';
import { FormGroup,Validators,FormControl,ReactiveFormsModule,FormArray, FormBuilder} from "@angular/forms";
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DataSource } from '@angular/cdk/table';
import {BorrowerService } from '../borrower/borrower.service';
import {ConstructionItemsLibraryService } from '../construction-items-library/construction-items-library.service';
import { SessionService } from '../service/session.service';
import {AllowableFeesService } from '../allowable-fees/allowable-fees.service';
import { ActivatedRoute,Router} from "@angular/router";
import {PropertyService} from '../property/property.service';
import { parse } from 'url';



export interface UserData {
  id: string;
  name: string;
  email: string;
  lastLogin: Date; 
}

/** Constants used to fill up our data base. */
const COLORS: string[] = ['maroon', 'red', 'orange', 'yellow', 'olive', 'green', 'purple',
  'fuchsia', 'lime', 'teal', 'aqua', 'blue', 'navy', 'black', 'gray'];
const NAMES: string[] = ['Maia', 'Asher', 'Olivia', 'Atticus', 'Amelia', 'Jack',
  'Charlotte', 'Theodore', 'Isla', 'Oliver', 'Isabella', 'Jasper',
  'Cora', 'Levi', 'Violet', 'Arthur', 'Mia', 'Thomas', 'Elizabeth'];

/**
 * @title Data table with sorting, pagination, and filtering.
 */


@Component({
  selector: 'app-construction-detail',
  templateUrl: './construction-detail.component.html',
  styleUrls: ['./construction-detail.component.scss']
})
export class ConstructionDetailComponent implements OnInit {
  displayedColumns: string[] = ['id','RepairType', 'RepairTotal', 'Operation'];
 
  dataSource: MatTableDataSource<UserData>;
   
  mobnumPattern = "^\(\d{3}\)\s\d{3}-\d{4}";

  

  public RepairItemForm: FormGroup;
  RepairActivityItemForm: FormGroup;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  isList:boolean=true;
  isAdd:boolean=false;
  isFinalSOR:boolean=false;
  isEdit:boolean=false;
  isAllowfeeSorExpand:boolean = false;
  isFinalSorExpand:boolean = false;
  submitted:boolean=false;
  isSORExist:boolean=false;
  sorId:any;
  isResend:boolean = false;
  repairDetailsList:any={};
  repairComponentList:any={};
  gcBidId:any;
  favoriteSeason: string;
  userList:any={};
  userDetail:any={};
  GcId:any;
  RepairPropertyId:any;
  loading=false;
  isShowComItem:any;
  isShowSelectedComItem:any;
  activityList:any=[];
  permitCostTypeData:any;
  formStatus= false;
  allowableFeesData:any={};
  isActAdd=false;
  isActEdit=false;
  checkConstructionsDetails=false;
  propertyRepairConstructionDetail:any;
  libraryRepairConstructionDetail:any;
  getRepairConstructionList:any = {};
  getRepairConstructionListNew:any = {};
  allowableFeesReset:any=[];
  selectedComponentListForGroup:any=[];
  permitCostTypeReset:any=[];
  propertyAddress = "";
  mileageDistance:any = 0;
  IRSPerMileRate:any = 0;
  miles:any=0;
  sorDetails: any = {};
  propertyCDetails:any={};
  tempSelection:any=[];
  tempOnlyComp:any=[];
  tempOnlyType:any=[];
  tempDeSelection:any=[];
  borrowerDetails: any = {};
  propertyDetails:any={};
  checkItemName:any=false;
  selectedFilterVal="individual-report-components";
  status:any=['Blocked','Active'];
  roles:any=[{Key:'1',Value:'authenticated user'},{Key:'2',Value:'Administrator'},{Key:'3',Value:'Consultants'},{Key:'4',Value:'General Contractors'},{Key:'5',Value:'Borrower'},
  {Key:'6',Value:'Loan Officer'},{key:'7',Value:'Loan Processor'},{key:'8',Value:' Notify user of new account'}, {key : '9', Value : 'Draw Administrator'}];
  constructor(
    private _bservice:BorrowerService,
    private _afservice: AllowableFeesService,
    private route: ActivatedRoute,
    private _ciservice:ConstructionItemsLibraryService,
    private router: Router,
    private sessionService:SessionService,
    private propertyService:PropertyService,
    private _fb: FormBuilder) {
    // Create 100 users
   

    // Assign the data to the data source for the table to render
   
  }

  ngOnInit() {
    if(this.sessionService.get('userInfo')!=undefined)
    {
      this.userDetail=JSON.parse(this.sessionService.get('userInfo'));
    
    }
    var propertyId =  this.route.snapshot.paramMap.get("propertyId");
    var gcId= this.route.snapshot.paramMap.get("gcId");
    if(gcId)
    {
        this.GcId=gcId;
        this.isFinalSOR=true;
    }
    this.RepairPropertyId = propertyId;
    this.getRepairItem (this.RepairPropertyId);
    this.getLibraryItem();
    this.getpropertyDetails(this.RepairPropertyId);
     
    
    this.RepairItemForm = this._fb.group({
          SendRepairType:new FormControl(null),
          OccupiedDuringConstruction:new FormControl(null),
          BorrowerPhone:new FormControl(null),
          FHACase:new FormControl(null),
          ConsultantComment:new FormControl(null),
          ContingencyReservePercentage:new FormControl(null),
          MonthToCompletion:new FormControl(null),
          LoanNo:new FormControl(null),
          InspectionAddress:new FormControl(null),
          id:new FormControl(null),
          InspectionDate: new FormControl(null),
          AllowableFeesDrawId:new FormControl(0),
          AllowableFeesDrawStatus:new FormControl(false),
          AllowableFeesDrawNote:new FormControl(""),
          AllowableFeesDrawFees:new FormControl(''),
          AllowableFeesDrawFeesDis:new FormControl(""),
          AllowableFeesMileageId:new FormControl(2500),
          AllowableFeesMileageStatus:new FormControl(false),
          AllowableFeesMileageNote:new FormControl(""),
          AllowableFeesMileageFees:new FormControl(""),
          AllowableFeesRoundMileageId:new FormControl(3500),
          AllowableFeesRoundMileageStatus:new FormControl(false),
          AllowableFeesRoundMileageNote:new FormControl(""),
          AllowableFeesRoundMileageFees:new FormControl(""),
          AllowableFees: this._fb.array([]),
          PermitCost: this._fb.array([]),
          RepairItemDetails: this._fb.array([
              this.initRepairItem(),
          ])
    });

    this.RepairActivityItemForm = this._fb.group({
      RepairItemName:new FormControl(null),
      id:new FormControl(null),
      ReportComponent: new FormControl(null)
    });
    
    
    this.getAllowableDrawFees();
   this.getRepairTypeList();


  }
  getAllowableDrawFees()
  {
    const data = {
      'EmailId': '',
      'PageSize': 100000,
      'LastEvaluatedKey': 0,
      'UserType': this.sessionService.userType[0].Value
      };
    this._afservice.AllowableFeesList(data).subscribe(response => {
       
        if (response != null)
        {
          let responseArray = response.Items;
          for(let i=0;i< responseArray.length; i++)
          {
              if(responseArray[i].Title == "Draw Fee")
              {

                this.RepairItemForm.controls['AllowableFeesDrawFees'].setValue(responseArray[i].Fees);
              }
          }
         
        }
    });

  }
  keyDownPhoneFormat($event){

    var key = $event.keyCode;
               
                if (key == 91 || (15 < key && key < 19) || (37 <= key && key <= 40)){
                    return;
                }
    this.changePhoneFormat($event);

  }

changePhoneFormat($event)
{
  var getVal = $event.target.value;
    var tel = getVal.replace(/[^0-9]/g, '');
    $event.target.value=this.changeFilter(tel);
}
  changeFilter(tel){
    
    if (!tel) { return ''; }

    var value = tel.toString().trim().replace(/^\+/, '');

    if (value.match(/[^0-9]/)) {
     
      return tel;
    }

    var country, city, number;

    switch (value.length) {
        case 1:
        case 2:
        case 3:
            city = value;
            break;

        default:
            city = value.slice(0, 3);
            number = value.slice(3);
    }

    if(number){
        if(number.length>3){
            number = number.slice(0, 3) + '-' + number.slice(3,7);
        }
        else{
            number = number;
        }

        return ("(" + city + ") " + number).trim();
    }
    else{
      return "(" + city;
    }

} 

  toggleSelection(index,mainType,type,reportCompName) {
    this.tempOnlyComp=[];
    if(this.tempSelection.length > 0)
    {
      var isCheck=true;
      for(let i=0; i< this.tempSelection.length;i++)
      {
          if(this.tempSelection[i].RepairType==type)
          {
            isCheck=false;
            var isInnerCheck=true;
            if(this.tempSelection[i].RepairItemDetails.length > 0)
            {
              for(let j=0; j< this.tempSelection[i].RepairItemDetails.length; j++)
              {
                  if(this.tempSelection[i].RepairItemDetails[j].RepairItemName==reportCompName)
                  {
                    
                    this.tempSelection[i].RepairItemDetails.splice(j, 1);
                   
                   
                    isInnerCheck=false;  
                  }
              }
              if(this.tempSelection[i].RepairItemDetails.length <=0)
              {
                var indx= this.tempOnlyType.indexOf(type);
                this.tempOnlyType.splice(indx,1);
                this.tempSelection.splice(i, 1);
                
              }
            }
            else
            {
              continue;
            }
            
            if(isInnerCheck==true)
            {

              this.tempSelection[i].RepairItemDetails.push({'Type':mainType,'RepairItemName':reportCompName});
            }
          }
      }
  
      if(isCheck==true)
      {
        let selectedComponent = {
          'index':index,
          'RepairType':type,
          'RepairItemDetails':[{'Type':mainType,'RepairItemName':reportCompName}]
        };
        this.tempSelection.push(selectedComponent);
        this.tempOnlyType.push(type);
      }
  
    }
    else
    {
      let selectedComponent = {
        'index':index,
        'RepairType':type,
        'RepairItemDetails':[{'Type':mainType,'RepairItemName':reportCompName}]
      };
      this.tempSelection.push(selectedComponent);
      this.tempOnlyType.push(type);
    }
    for(let n=0; n< this.tempSelection.length; n++)
    {
      
      for(let m=0; m<this.tempSelection[n].RepairItemDetails.length;m++ )
      {
        if(this.tempOnlyComp.length>0)
        {
          
          for(let o=0; o< this.tempOnlyComp.length;o++)
          {
          var ind = this.tempOnlyComp.indexOf(this.tempSelection[n].RepairType+'_'+this.tempSelection[n].RepairItemDetails[m].RepairItemName);
          if(ind > -1)
          {
            continue;
          }
          else
          {
            this.tempOnlyComp.push(this.tempSelection[n].RepairType+'_'+this.tempSelection[n].RepairItemDetails[m].RepairItemName);
          }
          
          }
        }
        else
        {
          this.tempOnlyComp.push(this.tempSelection[n].RepairType+'_'+this.tempSelection[n].RepairItemDetails[m].RepairItemName);
        }
        
      }
    }
  
  }
  toggleDeSelection(index,type,reportCompName) {
    if(this.tempDeSelection.length > 0)
    {
      var isCheck=true;
      for(let i=0; i< this.tempDeSelection.length;i++)
      {
          if(this.tempDeSelection[i].RepairType==type)
          {
            isCheck=false;
            var isInnerCheck=true;
            for(let j=0;j< this.tempDeSelection[i].RepairItemDetails.length; j++)
            {
                if(this.tempDeSelection[i].RepairItemDetails[j].RepairItemName==reportCompName)
                {
                  
                  this.tempDeSelection[i].RepairItemDetails.splice(j, 1);
                 
                 
                  isInnerCheck=false;  
                }
            }
            if(isInnerCheck==true)
            {
              this.tempDeSelection[i].RepairItemDetails.push({'RepairItemName':reportCompName});
            }
          }
      }
  
      if(isCheck==true)
      {
        let selectedComponent = {
          'index':index,
          'RepairType':type,
          'RepairItemDetails':[{'RepairItemName':reportCompName}]
        };
        this.tempDeSelection.push(selectedComponent);
      }
  
    }
    else
    {
      let selectedComponent = {
        'index':index,
        'RepairType':type,
        'RepairItemDetails':[{'RepairItemName':reportCompName}]
      };
      this.tempDeSelection.push(selectedComponent);
    }
  
  }
  toggleforAllSelection(index,type)
  {
    this.tempOnlyComp=[];
    if(this.tempSelection.length > 0)
    {
      var isCheck=true;
      for(let i=0; i< this.tempSelection.length;i++)
      {
          if(this.tempSelection[i].RepairType==type)
          {
            isCheck=false;
           this.tempSelection.splice(i,1);
           var indx= this.tempOnlyType.indexOf(type);
                this.tempOnlyType.splice(indx,1);
          }
      }
  
      if(isCheck==true)
      {
        let newTemp=[];
        for(let i=0; i< this.repairComponentList.length;i++)
        {
          if(this.repairComponentList[i].RepairItemName==type)
            { 
              for(let j=0; j< this.repairComponentList[i].ReportComponent.length;j++)
              {
                    for(let k=0; k< this.repairComponentList[i].ReportComponent[j].RepairItemDetails.length;k++)
                    {
                      newTemp.push({'Type':this.repairComponentList[i].ReportComponent[j].RepairType,'RepairItemName':this.repairComponentList[i].ReportComponent[j].RepairItemDetails[k].RepairItemName});
                    }


              }
  
  
  
              let selectedComponent = {
                'index':index,
                'RepairType':type,
                'RepairItemDetails':newTemp
              };
              this.tempSelection.push(selectedComponent);
              this.tempOnlyType.push(type);
          
        }
      }
  }
  
    }
    else
    {
      let newTemp=[];
      for(let i=0; i< this.repairComponentList.length;i++)
      {
        if(this.repairComponentList[i].RepairItemName==type)
          { 
            for(let j=0; j< this.repairComponentList[i].ReportComponent.length;j++)
            {
                  for(let k=0; k< this.repairComponentList[i].ReportComponent[j].RepairItemDetails.length;k++)
                  {
                    newTemp.push({'Type':this.repairComponentList[i].ReportComponent[j].RepairType,'RepairItemName':this.repairComponentList[i].ReportComponent[j].RepairItemDetails[k].RepairItemName});
                  }
            }



            let selectedComponent = {
              'index':index,
              'RepairType':type,
              'RepairItemDetails':newTemp
            };
            this.tempSelection.push(selectedComponent);
            this.tempOnlyType.push(type);
      }
    }
    }

    for(let n=0; n< this.tempSelection.length; n++)
    {
      for(let m=0; m<this.tempSelection[n].RepairItemDetails.length;m++ )
      {
        if(this.tempOnlyComp.length>0)
        {
          for(let o=0; o< this.tempOnlyComp.length;o++)
          {
            
          var ind = this.tempOnlyComp.indexOf(this.tempSelection[n].RepairType+'_'+this.tempSelection[n].RepairItemDetails[m].RepairItemName);
          if(ind > -1)
          {
            continue;
          }
          else
          {
            this.tempOnlyComp.push(this.tempSelection[n].RepairType+'_'+this.tempSelection[n].RepairItemDetails[m].RepairItemName);
          }
          
          }
        }
        else
        {
          
          this.tempOnlyComp.push(this.tempSelection[n].RepairType+'_'+this.tempSelection[n].RepairItemDetails[m].RepairItemName);
        }
        
      }
    }
    

  }

  addInSelectedList(){


    if(this.tempSelection.length > 0)
    {
      
      for(let i=0; i< this.tempSelection.length;i++)
      {
        
        var isCheck=true;
        for(let j=0; j< this.selectedComponentListForGroup.length;j++)
        {
         
          if(this.tempSelection[i].RepairType==this.selectedComponentListForGroup[j].RepairType)
          {
            
            isCheck=false;
           if(this.tempSelection[i].RepairItemDetails.length > 0)
           {
            for(let k=0;k< this.tempSelection[i].RepairItemDetails.length; k++)
            {
              
              var isInnerCheck=true;
              for(let l=0; l<this.selectedComponentListForGroup[j].RepairItemDetails.length;l++)
              {
                
                if(this.tempSelection[i].RepairItemDetails[k].RepairItemName==this.selectedComponentListForGroup[j].RepairItemDetails[l].RepairItemName)
                {
                  
                  isInnerCheck=false;  
                }
              }
            if(isInnerCheck==true)
            {
              
              this.selectedComponentListForGroup[j].RepairItemDetails.push(this.tempSelection[i].RepairItemDetails[k]);
            }  
            }
          }
          else
          {
            continue;
          }
            
          }
        }
  
        if(isCheck==true && this.tempSelection[i].RepairItemDetails.length > 0)
        {
         
          this.selectedComponentListForGroup.push(this.tempSelection[i]);
        }
          
      }
  
      
  
    }
  
  this.selectedComponentListForGroup.sort(function (a, b) {
    return a.index > b.index ? 1 : -1;
  });
    if(this.selectedComponentListForGroup.length <= 0)
    {
      this.sessionService.notifier.notify('error', 'Please choose atleast one component.');
    }
    this.tempSelection=[];
    this.expandActivityComList(-1);
      
  
  }
  removeFromSelectedList()
  {
    
    if(this.tempDeSelection.length > 0)
    {
      
      for(let i=0; i< this.tempDeSelection.length;i++)
      {
        
        
        for(let j=0; j< this.selectedComponentListForGroup.length;j++)
        {
         
          if(this.tempDeSelection[i].RepairType==this.selectedComponentListForGroup[j].RepairType)
          {
            
            
           
            for(let k=0;k< this.tempDeSelection[i].RepairItemDetails.length; k++)
            {
              
              
              for(let l=0; l<this.selectedComponentListForGroup[j].RepairItemDetails.length;l++)
              {
                
                if(this.tempDeSelection[i].RepairItemDetails[k].RepairItemName==this.selectedComponentListForGroup[j].RepairItemDetails[l].RepairItemName)
                {
                  
                  this.selectedComponentListForGroup[j].RepairItemDetails.splice(l, 1);
                }
              }
            
            }
            
          }
        }
  
       
          
      }
  
      
  
    }
  
  
    for(let j=0; j< this.selectedComponentListForGroup.length;j++)
    {
          if(this.selectedComponentListForGroup[j].RepairItemDetails.length < 1)
          {
            this.selectedComponentListForGroup.splice(j, 1);
          }
    }
  
  this.selectedComponentListForGroup.sort(function (a, b) {
    return a.index > b.index ? 1 : -1;
  });
    
    this.tempDeSelection=[];
    this.expandSelectedActivityComList(-1);
 
  }

  filterComponents(current)
  {
    this.loading=true;
    let searchValue = current.target.value;
    
    var ciData={"UserType":this.userDetail.UserType};
    this._ciservice.getConsultantConstructionItems(ciData).subscribe(ciresponse=>{
      this.repairComponentList=[];
      let tempListOld = [];
      let tempList=[];
      if(ciresponse!=null && ciresponse.Items.length > 0)
      {
        
        if(searchValue!='' && searchValue!=null && searchValue!=undefined)
        {


          this.repairDetailsList = ciresponse.Items[0].ReportComponent;
          this.activityList = ciresponse.Items[0].ActivityGroup;
              if(this.selectedFilterVal=='individual-report-components')
              {
                let OldAllVal = this.repairDetailsList;
                for(let i=0; i< OldAllVal.length;i++)
                {
                  var isTypeCheck=true;
                  if(tempListOld.length > 0){
                  for(let j=0;j< tempListOld.length; j++)
                  {
                    if(OldAllVal[i].RepairType==tempListOld[j].RepairType)
                    {
                        isTypeCheck = false;
                        for(let k=0;k< OldAllVal[i].RepairItemDetails.length; k++)
                        {
                          var isInnerTypeCheck=true;
                          for(let l=0;l< tempListOld[j].RepairItemDetails.length; l++)
                          {
                              if(OldAllVal[i].RepairItemDetails[k].RepairItemName==tempListOld[j].RepairItemDetails[l].RepairItemName)
                              {
                                isInnerTypeCheck = false;
                              }
                              
                          }

                          if(isInnerTypeCheck==true)
                          {
                            
                            var indx= OldAllVal[i].RepairItemDetails[k].RepairItemName.toLowerCase().indexOf(searchValue.toLowerCase());
                            if(indx > -1)
                            {
                              
                              tempListOld[j].RepairItemDetails.push(OldAllVal[i].RepairItemDetails[k].RepairItemName);
                            }
                          }
                        }

                        
                    }
                    
                    
                }
              }
                if(isTypeCheck==true)
                {
                 
                  for(let k=0;k< OldAllVal[i].RepairItemDetails.length; k++)
                  {
                    
                    var indx= OldAllVal[i].RepairItemDetails[k].RepairItemName.toLowerCase().indexOf(searchValue.toLowerCase());
                    if(indx > -1)
                    {
                      
                      let repairItem ={
                        'RepairType':OldAllVal[i].RepairType,
                        'RepairItemDetails':[{'RepairItemName':OldAllVal[i].RepairItemDetails[k].RepairItemName}]
                      };

                      tempListOld.push(repairItem);
                      
                    }
                  }
                
                }
                }
                
                if(tempListOld.length > 0)
                {
                  let temArray=[];
              for(let i=0;i<tempListOld.length;i++)
              { 
                var indx1= temArray.indexOf(tempListOld[i].RepairType);
                if(indx1 > -1)
                {
                    for(let j=0; j<tempListOld[i].RepairItemDetails.length;j++)
                    {
                      let RepItem = {'RepairItemName':tempListOld[i].RepairItemDetails[j].RepairItemName};
                        tempList[indx1].RepairItemDetails.push(RepItem);
                    }
                }
                else
                {
                      temArray.push(tempListOld[i].RepairType);
                      tempList.push(tempListOld[i]);
                }

              }

                }
                



              }
              else
              {
                let OldAllVal = this.activityList;
                for(let i=0; i< OldAllVal.length;i++)
                {
                  var isTypeCheck=true;
                  for(let j=0;j< tempListOld.length; j++)
                  {
                    if(OldAllVal[i].RepairItemName==tempListOld[j].RepairItemName)
                    {
                      isTypeCheck = false;
                      for(let k=0;k< OldAllVal[i].ReportComponent.length; k++)
                      {
                        var isInnerTypeCheck=true;

                        for(let l=0;l< tempListOld[j].ReportComponent.length; l++)
                          {
                              if(OldAllVal[i].ReportComponent[k].RepairType==tempListOld[j].ReportComponent[l].RepairType)
                              {
                                isInnerTypeCheck=false;
                                for(let m=0; m< OldAllVal[i].ReportComponent[k].RepairItemDetails.length; m++)
                                {
                                  var isInnerInnerTypeCheck=true;
                                  for(let n=0; n< tempListOld[j].ReportComponent[l].RepairItemDetails.length; n++)
                                  {
                                    
                                      if(OldAllVal[i].ReportComponent[k].RepairItemDetails[m].RepairItemName==tempListOld[j].ReportComponent[l].RepairItemDetails[n].RepairItemName)
                                      {
                                        isInnerInnerTypeCheck=false;
                                      }
  
  
                                  }
                                  if(isInnerInnerTypeCheck==true)
                                  {
                                    
                                    var indx= OldAllVal[i].ReportComponent[k].RepairItemDetails[m].RepairItemName.toLowerCase().indexOf(searchValue.toLowerCase());
                                    if(indx > -1)
                                    {
                                     
                                      tempListOld[j].ReportComponent[l].RepairItemDetails.push(OldAllVal[i].ReportComponent[k].RepairItemDetails[m].RepairItemName);
                                    }
                                  }


                                }




                              }


                          }


                          if(isInnerTypeCheck==true)
                          {
                            for(let m=0; m< OldAllVal[i].ReportComponent[k].RepairItemDetails.length; m++)
                            {
                              var indx= OldAllVal[i].ReportComponent[k].RepairItemDetails[m].RepairItemName.toLowerCase().indexOf(searchValue.toLowerCase());
                              if(indx > -1)
                              {
                               
                                let repairItem ={
                                  'index':m,
                                  'RepairType':OldAllVal[i].ReportComponent[k].RepairType,
                                  'RepairItemDetails':[{'RepairItemName':OldAllVal[i].ReportComponent[k].RepairItemDetails[m].RepairItemName}]
                                };
          
                                tempListOld[j].ReportComponent.push(repairItem);
                                
                              }
                            }
                            
                          }

                      }




                    }
                  }
                  if(isTypeCheck==true)
                  {
                    for(let k=0;k< OldAllVal[i].ReportComponent.length; k++)
                      {

                        for(let m=0; m< OldAllVal[i].ReportComponent[k].RepairItemDetails.length; m++)
                        {
                          var indx= OldAllVal[i].ReportComponent[k].RepairItemDetails[m].RepairItemName.toLowerCase().indexOf(searchValue.toLowerCase());
                          if(indx > -1)
                          {
                            
                            let repairItem ={
                              'index':m,
                              'RepairType':OldAllVal[i].ReportComponent[k].RepairType,
                              'RepairItemDetails':[{'RepairItemName':OldAllVal[i].ReportComponent[k].RepairItemDetails[m].RepairItemName}]
                            };
                            let item={
                              "id": i,
                              "RepairItemName": OldAllVal[i].RepairItemName,
                              "ReportComponent":[repairItem]
                            }
                            

                            tempListOld.push(item);
                            
                          }
                          
                        }
                      }
                   
                    
                  }


                  
                } 
               
                let temArray1=[];
                let temArray2=[];
                for(let i=0;i<tempListOld.length;i++)
                { 
                  var indx1= temArray1.indexOf(tempListOld[i].RepairItemName);
                  if(indx1 > -1)
                  {
                      for(let j=0; j<tempListOld[i].ReportComponent.length;j++)
                      {
                        var indx2= temArray2.indexOf(tempListOld[i].ReportComponent[j].RepairType);
                        if(indx2 > -1)
                        {
                          for(let k=0;k<tempListOld[i].ReportComponent[j].RepairItemDetails.length;k++)
                          {
                            let repItem = {"RepairItemName": tempListOld[i].ReportComponent[j].RepairItemDetails[k].RepairItemName}

                            tempList[indx1].ReportComponent[indx2].RepairItemDetails.push(repItem);
                          }
                          
                        }
                        else
                        {
                          temArray2.push(tempListOld[i].ReportComponent[j].RepairType);
                         
                          tempList[indx1].ReportComponent.push(tempListOld[i].ReportComponent[j]);
                        }
                        
                      }
                  }
                  else
                  {
                        temArray1.push(tempListOld[i].RepairItemName);
                        tempList.push(tempListOld[i]);
                  }
  
                }

              }
             
          
      }
      else
      {
        
        this.repairDetailsList = ciresponse.Items[0].ReportComponent;
        this.activityList = ciresponse.Items[0].ActivityGroup;
            if(this.selectedFilterVal=='individual-report-components')
            {
              let OldAllVal = this.repairDetailsList;
              for(let i=0; i< OldAllVal.length;i++)
              {
               
                tempList.push(OldAllVal[i]);
                       
              }
            
            }
            else
            {
              let OldAllVal = this.activityList;
              for(let i=0; i< OldAllVal.length;i++)
              {
               
                tempList.push(OldAllVal[i]);
               
              } 
            }
            
         
      }
      if(this.selectedFilterVal=='individual-report-components')
      {
      for(let k=0; k < tempList.length;k++)
      {
        for(let l=0; l < this.userList.Items.length;l++)
        {
            if(tempList[k].RepairType==this.userList.Items[l].RepairType)
            {
              tempList[k].index = this.userList.Items[l].id;
            }
        }
      }

      this.repairDetailsList.sort(function (a, b) {
        return a.index > b.index ? 1 : -1;
      });
    }
      this.repairComponentList = tempList;

     
      this.loading=false;
      }
      else
      {
        
        this.repairComponentList = tempList;
      
        this.loading=false;
      }
      
    },error=>{
      this.sessionService.notifier.notify('error',error.error.message);
      this.loading=false;
  })

 
  }

  backFromLibraryPopup()
  {
    this.isActAdd = false;
    
  this.isAdd=false;
    this.isList = true;
    this.selectedComponentListForGroup=[];
   
  }
  showAddActivityForm() {
  
    this.isActAdd = true;
    
  this.isAdd=false;
    this.isList = true;
    this.tempOnlyComp=[];
    this.tempOnlyType=[];
    this.tempSelection=[];
    this.tempDeSelection=[];
    this.selectedFilterVal='individual-report-components';
    const controlArray = <FormArray> this.RepairActivityItemForm.get('RepairItemDetails');
  
    
    this.RepairActivityItemForm.controls['RepairItemName'].setValue('');
    var ciData={"UserType":this.userDetail.UserType};
    this._ciservice.getConsultantConstructionItems(ciData).subscribe(ciresponse=>{

      if(ciresponse!=null && ciresponse.Items.length > 0)
      {
        this.repairDetailsList = ciresponse.Items[0].ReportComponent;
        this.activityList = ciresponse.Items[0].ActivityGroup;
          var unitFullName = {
            'EA':"Each = EA",
            'LF':"Linear foot = LF",
            'SF':"Square Foot = SF",
            'LS':"Lump Sum = LS",
            'SY':"Square Yard = SY",
            '*':"* = Required"
          };
          
        for(let i = 0; i<this.repairDetailsList.length;i++ )
        {
          for(let j = 0; j<this.repairDetailsList[i].RepairItemDetails.length;j++ )
          { 
              var mtUnit = this.repairDetailsList[i].RepairItemDetails[j].MaterialUnit;
              var lbUnit = this.repairDetailsList[i].RepairItemDetails[j].LaborUnit;
              this.repairDetailsList[i].RepairItemDetails[j].MaterialUnitFull = unitFullName[mtUnit];
              this.repairDetailsList[i].RepairItemDetails[j].LaborUnitFull = unitFullName[lbUnit];
          }
        }

        for(let k=0; k < this.repairDetailsList.length;k++)
        {
          for(let l=0; l < this.userList.Items.length;l++)
          {
              if(this.repairDetailsList[k].RepairType==this.userList.Items[l].RepairType)
              {
                this.repairDetailsList[k].index = this.userList.Items[l].id;
              }
          }
        }

        this.repairDetailsList.sort(function (a, b) {
          return a.index > b.index ? 1 : -1;
        });
       
       
        this.repairComponentList = this.repairDetailsList;



       
        this.loading=false;
      }
    },error=>{
      this.sessionService.notifier.notify('error',error.error.message);
      this.loading=false;
  })
  
  
  }
  applyFilter(filterValue) {
    const target = filterValue as HTMLInputElement;
    const targetValue = target.value;
    this.dataSource.filter = targetValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
 
  }


  expandActivityComList(index)
{
  if(this.isShowComItem==null || this.isShowComItem!=index)
  {
    this.isShowComItem = index;
  }
  else
  {
    this.isShowComItem = null;
  }

}
expandSelectedActivityComList(index)
{
  if(this.isShowSelectedComItem==null || this.isShowSelectedComItem!=index)
  {
    this.isShowSelectedComItem = index;
  }
  else
  {
    this.isShowSelectedComItem = null;
  }

}
  getLibraryItem()
  {
    var ciData={"UserType":this.userDetail.UserType};
    this._ciservice.getConsultantConstructionItems(ciData).subscribe(ciresponse=>{

      if(ciresponse!=null && ciresponse.Items.length > 0)
      {
        this.repairDetailsList = ciresponse.Items[0].ReportComponent;
        this.activityList = ciresponse.Items[0].ActivityGroup;
          var unitFullName = {
            'EA':"Each = EA",
            'LF':"Linear foot = LF",
            'SF':"Square Foot = SF",
            'LS':"Lump Sum = LS",
            'SY':"Square Yard = SY",
            '*':"* = Required"
          };
          
        for(let i = 0; i<this.repairDetailsList.length;i++ )
        {
          for(let j = 0; j<this.repairDetailsList[i].RepairItemDetails.length;j++ )
          { 
              var mtUnit = this.repairDetailsList[i].RepairItemDetails[j].MaterialUnit;
              var lbUnit = this.repairDetailsList[i].RepairItemDetails[j].LaborUnit;
              this.repairDetailsList[i].RepairItemDetails[j].MaterialUnitFull = unitFullName[mtUnit];
              this.repairDetailsList[i].RepairItemDetails[j].LaborUnitFull = unitFullName[lbUnit];
          }
        }



        this.loading=false;
      }
    },error=>{
      this.sessionService.notifier.notify('error',error.error.message);
      this.loading=false;
  })
  }
  initRepairItem() {
    // initialize our address
    return this._fb.group({
      RepairItemName:[''],
      Location:[''],
      Level:[''],
      MaterialUnit:['EA'],
      MaterialQuantity:['1'],
      MaterialUnitCost:['0'],
      LaborUnit:['EA'],
      LaborQuantity:['1'],
      LaborUnitCost:['0'],
      Summary:['']
    });
  }

  sorEmailToBorrower (Propertyid, borrowerEmail, borrowerFirstTName, borrowerLastTName) {
    
    this.loading = true;
    var data = {
      "PropertyId": Propertyid,
      "Id":this.sorId,
      "SorStatus": "sendForReview",
      "borrowerEmail": borrowerEmail,
      "borrowerName": borrowerFirstTName + ' ' + borrowerLastTName
    };
    var data1={
      "EmailId":"",
      "PropertyId":Propertyid,
      "PageSize":100000,
      "LastEvaluatedKey":0,
      "UserType":this.sessionService.userType[1].Value
      }
    this._bservice.GetSorDetails(data1).subscribe(response=>{
        
        if(response!=null)
        {
          if(response.Items.length >0)
          {
            var repairTypesEmptyLocation  = '';
            for(let i=0; i<response.Items[0].Constructions.length;i++)
            {
                for(let j=0;j<response.Items[0].Constructions[i].RepairItemDetails.length;j++)
                {
                    if(response.Items[0].Constructions[i].RepairItemDetails[j].Location=='' || response.Items[0].Constructions[i].RepairItemDetails[j].Location==null || response.Items[0].Constructions[i].RepairItemDetails[j].Location==undefined)
                    {
                      var indexText = repairTypesEmptyLocation.indexOf(response.Items[0].Constructions[i].RepairType);
                      if(indexText == -1)
                      {
                        if(repairTypesEmptyLocation=='' || repairTypesEmptyLocation==null || repairTypesEmptyLocation==undefined )
                        {
                          repairTypesEmptyLocation = response.Items[0].Constructions[i].RepairType;
                        }
                        else
                        {
                          repairTypesEmptyLocation = repairTypesEmptyLocation +', ' +response.Items[0].Constructions[i].RepairType;
                        }
                        
                      }
                    }
                }
            }
            

            if(repairTypesEmptyLocation!='' && repairTypesEmptyLocation!=null && repairTypesEmptyLocation!=undefined)
            {
              this.sessionService.notifier.notify('error', 'Repair item location field should not be blank. Please enter the location value in Repair Types ('+repairTypesEmptyLocation+').');
              this.loading = false;
            }
            else
            {
              this.propertyService.sendSorEmail(data).subscribe(response => {
                var propertyStatusData = {
                  "Id": parseInt(this.route.snapshot.paramMap.get("propertyId")),
                  "Status": "DRAFT SOR Sent to Borrower",
                  "CreatedBy": this.userDetail.EmailId,
                  "UpdatedBy": this.userDetail.EmailId
                };
                this.propertyService.UpdatePropertyStatus(propertyStatusData).subscribe(response => {
                  this.sessionService.notifier.notify('success', 'Send Email to Borrower successfully.');
                  this.router.navigate(['/property-details',Propertyid,1]);
                  this.loading = false;
                }, error => {
                  this.sessionService.notifier.notify('error', error.error.message);
                  this.loading = false;
                })
          
          
              }, error => {
                this.sessionService.notifier.notify('error', error.error.message);
                this.loading = false;
              })
            }

          }



        }
       
    },error=>{
      
    })

   


  }
  sorResendEmailToBorrower (Propertyid, borrowerEmail, borrowerFirstTName, borrowerLastTName) {
    
    this.loading = true;
    var data = {
      "PropertyId": Propertyid,
      "Id":this.sorId,
      "SorStatus": "sendForReview",
      "resendStatus":true,
      "borrowerEmail": borrowerEmail,
      "borrowerName": borrowerFirstTName + ' ' + borrowerLastTName
    };
    var data1={
      "EmailId":"",
      "PropertyId":Propertyid,
      "PageSize":100000,
      "LastEvaluatedKey":0,
      "UserType":this.sessionService.userType[1].Value
      }
    this._bservice.GetSorDetails(data1).subscribe(response=>{
        
        if(response!=null)
        {
          if(response.Items.length >0)
          {
            var repairTypesEmptyLocation  = '';
            for(let i=0; i<response.Items[0].Constructions.length;i++)
            {
                for(let j=0;j<response.Items[0].Constructions[i].RepairItemDetails.length;j++)
                {
                    if(response.Items[0].Constructions[i].RepairItemDetails[j].Location=='' || response.Items[0].Constructions[i].RepairItemDetails[j].Location==null || response.Items[0].Constructions[i].RepairItemDetails[j].Location==undefined)
                    {
                      var indexText = repairTypesEmptyLocation.indexOf(response.Items[0].Constructions[i].RepairType);
                      if(indexText == -1)
                      {
                        if(repairTypesEmptyLocation=='' || repairTypesEmptyLocation==null || repairTypesEmptyLocation==undefined )
                        {
                          repairTypesEmptyLocation = response.Items[0].Constructions[i].RepairType;
                        }
                        else
                        {
                          repairTypesEmptyLocation = repairTypesEmptyLocation +', ' +response.Items[0].Constructions[i].RepairType;
                        }
                        
                      }
                    }
                }
            }
            

            if(repairTypesEmptyLocation!='' && repairTypesEmptyLocation!=null && repairTypesEmptyLocation!=undefined)
            {
              this.sessionService.notifier.notify('error', 'Repair item location field should not be blank. Please enter the location value in Repair Types ('+repairTypesEmptyLocation+').');
              this.loading = false;
            }
            else
            {
              this.propertyService.sendSorEmail(data).subscribe(response => {
                var propertyStatusData = {
                  "Id": parseInt(this.route.snapshot.paramMap.get("propertyId")),
                  "Status": "DRAFT SOR Sent to Borrower",
                  "CreatedBy": this.userDetail.EmailId,
                  "UpdatedBy": this.userDetail.EmailId
                };
                this.propertyService.UpdatePropertyStatus(propertyStatusData).subscribe(response => {
                  this.sessionService.notifier.notify('success', 'Send Email to Borrower successfully.');
                  this.router.navigate(['/property-details',Propertyid,1]);
                  this.loading = false;
                }, error => {
                  this.sessionService.notifier.notify('error', error.error.message);
                  this.loading = false;
                })
          
          
              }, error => {
                this.sessionService.notifier.notify('error', error.error.message);
                this.loading = false;
              })
            }

          }



        }
       
    },error=>{
      
    })

   


  }
  finalSorEmailToBorrower (Propertyid) {
    
    this.loading = true;
    var data1={
      "EmailId":"",
      "PropertyId":Propertyid,
      "PageSize":100000,
      "LastEvaluatedKey":0,
      "UserType":this.sessionService.userType[1].Value
      }
    this._bservice.GetSorDetails(data1).subscribe(response=>{
         
       
        if(response!=null)
        {
          if(response.Items.length >0)
          {
            var repairTypesEmptyLocation  = '';
            for(let i=0; i<response.Items[0].Constructions.length;i++)
            {
                for(let j=0;j<response.Items[0].Constructions[i].RepairItemDetails.length;j++)
                {
                    if(response.Items[0].Constructions[i].RepairItemDetails[j].Location=='' || response.Items[0].Constructions[i].RepairItemDetails[j].Location==null || response.Items[0].Constructions[i].RepairItemDetails[j].Location==undefined)
                    {
                      var indexText = repairTypesEmptyLocation.indexOf(response.Items[0].Constructions[i].RepairType);
                      if(indexText == -1)
                      {
                        if(repairTypesEmptyLocation=='' || repairTypesEmptyLocation==null || repairTypesEmptyLocation==undefined )
                        {
                          repairTypesEmptyLocation = response.Items[0].Constructions[i].RepairType;
                        }
                        else
                        {
                          repairTypesEmptyLocation = repairTypesEmptyLocation +',' +response.Items[0].Constructions[i].RepairType;
                        }
                        
                      }
                    }
                }
            }


            if(repairTypesEmptyLocation!='' && repairTypesEmptyLocation!=null && repairTypesEmptyLocation!=undefined)
            {
              this.sessionService.notifier.notify('error', 'Repair item location field should not blank. Please enter the location value in these Repair Types ('+repairTypesEmptyLocation+')');
              this.loading = false;
            }
            else
            {
             
                this.propertyService.SendFinalSORDocsign(Propertyid).subscribe(response => {
                            
                  if (response)
                  {

                    let sendForSign = true;
                    var data = {
                      "PropertyId" :  parseInt(Propertyid),
                      "GeneralContractorId" : this.propertyDetails.AcceptedProposal,
                      "sendForSign" : sendForSign
                    };
                    
                   
                    this.propertyService.GetBORPDF(data).subscribe(gresponse => {
                      if (gresponse)
                    {
                      this.sessionService.notifier.notify('success', 'Send final SOR for digital signature successfully.');

                      this.router.navigate(['/property-details',Propertyid,1]);
                      this.loading = false;
                    }
                    }, error => {
                      this.sessionService.notifier.notify('error', error.error.message);
                      this.loading = false;
                    })

                      
                  }

                }, error => {
                  this.sessionService.notifier.notify('error', error.error.message);
                  this.loading = false;
                })
            }

          }



        }
       
    },error=>{
      
    })


  }
  UpdateRepair(type){
      
    this.allowableFeesReset = this.RepairItemForm.value.AllowableFees;
    let setItem = this.RepairItemForm.value;
    
    if(this.isFinalSOR==true)
    { 
        this.permitCostTypeReset = this.RepairItemForm.value.PermitCost;
    }
    this.formReset();
    
    const setcontrol = <FormArray>this.RepairItemForm.controls['AllowableFees'];
    
    for(let i = setcontrol.length-1; i >= 0; i--) {
        setcontrol.removeAt(i)
      }
      if(setItem.AllowableFeesDrawStatus==true){
        var drawValue = {
          "Id": 0,
          "DrawFeesDis": setItem.AllowableFeesDrawFees,
          "Notes": setItem.AllowableFeesDrawNote,
          "Status": true,
          "Title": "Draw Fee",
          "Fees": setItem.AllowableFeesDrawFeesDis,
        };
        setItem.AllowableFees.push(drawValue);
      }
      else
      {
        var drawValue = {
            "Id": 0,
            "DrawFeesDis": setItem.AllowableFeesDrawFees,
            "Notes": setItem.AllowableFeesDrawNote,
            "Status": false,
            "Title": "Draw Fee",
            "Fees": setItem.AllowableFeesDrawFeesDis,
          };
          setItem.AllowableFees.push(drawValue);
      }
      if(setItem.AllowableFeesMileageStatus==true){
        var mileageValue = {
          "Id": 2500,
          "Notes": setItem.AllowableFeesMileageNote,
          "Status": true,
          "Title": "Mileage",
          "Fees": setItem.AllowableFeesMileageFees,
        };
        setItem.AllowableFees.push(mileageValue);
      }
      else
      {
        var mileageValue = {
            "Id": 2500,
            "Notes": setItem.AllowableFeesMileageNote,
            "Status": false,
            "Title": "Mileage",
            "Fees": setItem.AllowableFeesMileageFees,
          };
          setItem.AllowableFees.push(mileageValue);
      }
      if(setItem.AllowableFeesRoundMileageStatus==true){
        var roundMileageValue = {
          "Id": 3500,
          "Notes": setItem.AllowableFeesRoundMileageNote,
          "Status": true,
          "Title": "Rounded Mileage",
          "Fees": setItem.AllowableFeesRoundMileageFees,
        };
        setItem.AllowableFees.push(roundMileageValue);
      }
      else
      {
        var roundMileageValue = {
            "Id": 3500,
            "Notes": setItem.AllowableFeesRoundMileageNote,
            "Status": false,
            "Title": "Rounded Mileage",
            "Fees": setItem.AllowableFeesRoundMileageFees,
          };
          setItem.AllowableFees.push(roundMileageValue);
      }
      this.getAllowableFees(setItem);  

      if(this.isFinalSOR==true)
      {              
                    const permitControl = <FormArray>this.RepairItemForm.controls['PermitCost'];
                    
                    for(let i = permitControl.length-1; i >= 0; i--) {
                        permitControl.removeAt(i)
                    }
                          
                    for(let j=0; j<this.permitCostTypeReset.length;j++){
                        permitControl.push(this._fb.group({
                        PermitType:[this.permitCostTypeReset[j].PermitType],
                        PermitCost:[this.permitCostTypeReset[j].PermitCost],
                        Status:[this.permitCostTypeReset[j].Status]
                      }));
                      
        }

      
    }
                this.RepairItemForm.controls['OccupiedDuringConstruction'].setValue(setItem.OccupiedDuringConstruction);
                this.RepairItemForm.controls['BorrowerPhone'].setValue(setItem.BorrowerPhone);
                this.RepairItemForm.controls['FHACase'].setValue(setItem.FHACase);
                this.RepairItemForm.controls['ContingencyReservePercentage'].setValue(setItem.ContingencyReservePercentage);
                this.RepairItemForm.controls['MonthToCompletion'].setValue(setItem.MonthToCompletion);
                this.RepairItemForm.controls['LoanNo'].setValue(setItem.LoanNo);
                this.RepairItemForm.controls['InspectionDate'].setValue(setItem.InspectionDate);
                this.RepairItemForm.controls['InspectionAddress'].setValue(setItem.InspectionAddress);
                this.RepairItemForm.controls['ConsultantComment'].setValue(setItem.ConsultantComment);
                
                          
     
    const controlArray = <FormArray> this.RepairItemForm.get('RepairItemDetails');
    
    if(controlArray.length > 0)
{
      for(let j=controlArray.length-1; j >=0; j--){
        controlArray.removeAt(j); 
         if(j==0)
         {
           
          controlArray.push(this.initRepairItem());
          
         }
      }

  controlArray.controls[0].get('MaterialUnit').setValue('EA');
  controlArray.controls[0].get('LaborUnit').setValue('EA');
  controlArray.controls[0].get('MaterialQuantity').setValue('1');
  controlArray.controls[0].get('LaborQuantity').setValue('1');
}
else
{
  controlArray.push(this.initRepairItem());
  
}
      

    

                
    

    this.RepairItemForm.controls['SendRepairType'].setValue(type);
    this.RepairItemForm.controls['SendRepairType'].disable();

    if(this.getRepairConstructionList.length > 0){
      this.getRepairConstructionList.forEach(element => {
        if(element.PropertyId == this.RepairPropertyId){
          
          this.propertyRepairConstructionDetail = element;
          

          for(let i=0; i < element.Constructions.length; i++){
            
            if(element.Constructions[i].RepairType == type){
                
              
              if(element.Constructions[i].RepairItemDetails.length >0){
                const control = <FormArray>this.RepairItemForm.controls['RepairItemDetails'];
                for(let j=control.length-1; j >=0; j--){
                    control.removeAt(j); 
                   
                }

                for(let k=0; k < element.Constructions[i].RepairItemDetails.length; k++){
                   
                  var item = element.Constructions[i].RepairItemDetails[k];
                 
                  control.push(this.initupdateItem(item));

                
                }
              }
            }
            
          }
        }
      });
    }

    this.isAdd = true;
    this.isList= true;
    
  }

  addRepairItems() {
      // add address to the list
      const control = <FormArray>this.RepairItemForm.controls['RepairItemDetails'];
      control.push(this.initRepairItem());
  
  }

  deleteRepairItems(index){
   
    const control = <FormArray>this.RepairItemForm.controls['RepairItemDetails'];
    control.removeAt(index);
   
         
   
  }
 
  initupdateItem(item) {
    // initialize our address
    return this._fb.group({
      RepairItemName:[item.RepairItemName],
      Location:[item.Location],
      Level:[item.Level],
      MaterialUnit:[item.MaterialUnit],
      MaterialQuantity:[item.MaterialQuantity],
      MaterialUnitCost:[item.MaterialUnitCost],
      LaborUnit:[item.LaborUnit],
      LaborQuantity:[item.LaborQuantity],
      LaborUnitCost:[item.LaborUnitCost],
      Summary:[item.Summary]
    });
  }

  RepairList(){
     
    this.isAdd=false;
    this.isList=true;
     
    const control = <FormArray>this.RepairItemForm.controls['RepairItemDetails'];

    for(let j=control.length; j >=0; j--){
        control.removeAt(j); 
        if(j==0){
        control.push(this.initRepairItem());
      }
    }
  }

  showAddForm(){
    this.isAdd=true;
    this.isList=false;
  }

  getRepairTypeList(){
    
    this.loading=true;
    
    this.userList.Count = 36;
    this.userList.ScannedCount = 36;
    this.userList.TotalRecord = 36;      
    this.userList.Items=[
      {
        "id":1,
        "RepairType" : "Masonry",
        "RepairTotal" : "0.00",
        "Operation":"Active"
      },
      {
        "id":2,
        "RepairType" : "Siding",
        "RepairTotal" : "0.00",
        "Operation":"Active"
      },
      {
        "id":3,
        "RepairType" : "Gutters/Downspouts",
        "RepairTotal" : "0.00",
        "Operation":"Active"
      },
      {
        "id":4,
        "RepairType" : "Roof",
        "RepairTotal" : "0.00",
        "Operation":"Active"
      },
      {
        "id":5,
        "RepairType" : "Shutters",
        "RepairTotal" : "0.00",
        "Operation":"Active"
      },
      {
        "id":6,
        "RepairType" : "Exteriors",
        "RepairTotal" : "0.00",
        "Operation":"Active"
      },
      {
        "id":7,
        "RepairType" : "Walks",
        "RepairTotal" : "0.00",
        "Operation":"Active"
      },
      {
        "id":8,
        "RepairType" : "Driveways",
        "RepairTotal" : "0.00",
        "Operation":"Active"
      },
      {
        "id":9,
        "RepairType" : "Painting (Ext)",
        "RepairTotal" : "0.00",
        "Operation":"Active"
      },
      {
        "id":10,
        "RepairType" : "Caulking",
        "RepairTotal" : "0.00",
        "Operation":"Active"
      },
      {
        "id":11,
        "RepairType" : "Fencing",
        "RepairTotal" : "0.00",
        "Operation":"Active"
      },
      {
        "id":12,
        "RepairType" : "Grading",
        "RepairTotal" : "0.00",
        "Operation":"Active"
      },
      {
        "id":13,
        "RepairType" : "Windows",
        "RepairTotal" : "0.00",
        "Operation":"Active"
      },
      {
        "id":14,
        "RepairType" : "Weatherstrip",
        "RepairTotal" : "0.00",
        "Operation":"Active"
      },
      {
        "id":15,
        "RepairType" : "Doors (Ext)	",
        "RepairTotal" : "0.00",
        "Operation":"Active"
      },
      {
        "id":16,
        "RepairType" : "Doors (Int)	",
        "RepairTotal" : "0.00",
        "Operation":"Active"
      },
      {
        "id":17,
        "RepairType" : "Partition Wall",
        "RepairTotal" : "0.00",
        "Operation":"Active"
      },
      {
        "id":18,
        "RepairType" : "Plaster/Drywall",
        "RepairTotal" : "0.00",
        "Operation":"Active"
      },
      {
        "id":19,
        "RepairType" : "Decorating",
        "RepairTotal" : "0.00",
        "Operation":"Active"
      },
      {
        "id":20,
        "RepairType" : "Wood Trim	",
        "RepairTotal" : "0.00",
        "Operation":"Active"
      },
      {
        "id":21,
        "RepairType" : "Stairs",
        "RepairTotal" : "0.00",
        "Operation":"Active"
      },
      {
        "id":22,
        "RepairType" : "Closets",
        "RepairTotal" : "0.00",
        "Operation":"Active"
      },
      {
        "id":23,
        "RepairType" : "Wood Floors",
        "RepairTotal" : "0.00",
        "Operation":"Active"
      },
      {
        "id":24,
        "RepairType" : "Finished Floors",
        "RepairTotal" : "0.00",
        "Operation":"Active"
      },
      {
        "id":25,
        "RepairType" : "Ceramic Tile",
        "RepairTotal" : "0.00",
        "Operation":"Active"
      },
      {
        "id":26,
        "RepairType" : "Bath Accesories",
        "RepairTotal" : "0.00",
        "Operation":"Active"
      },
      {
        "id":27,
        "RepairType" : "Plumbing",
        "RepairTotal" : "0.00",
        "Operation":"Active"
      },
      {
        "id":28,
        "RepairType" : "Electrical",
        "RepairTotal" : "0.00",
        "Operation":"Active"
      },
      {
        "id":29,
        "RepairType" : "Heating",
        "RepairTotal" : "0.00",
        "Operation":"Active"
      },
      {
        "id":30,
        "RepairType" : "Insulation",
        "RepairTotal" : "0.00",
        "Operation":"Active"
      },
      {
        "id":31,
        "RepairType" : "Cabinetry",
        "RepairTotal" : "0.00",
        "Operation":"Active"
      },
      {
        "id":32,
        "RepairType" : "Appliances",
        "RepairTotal" : "0.00",
        "Operation":"Active"
      },
      {
        "id":33,
        "RepairType" : "Basements",
        "RepairTotal" : "0.00",
        "Operation":"Active"
      },
      {
        "id":34,
        "RepairType" : "Cleanup",
        "RepairTotal" : "0.00",
        "Operation":"Active"
      },
      {
        "id":35,
        "RepairType" : "Miscellaneous",
        "RepairTotal" : "0.00",
        "Operation":"Active"
      },
      {
        "id":36,
        "RepairType" : "Grand Total",
        "RepairTotal" : "0.00",
        "Operation":"Inactive"
      }
      

  ];

    const users = Array.from({length: this.userList.Count}, (_, k) => createNewUser(k,this.userList));
    this.dataSource = new MatTableDataSource(users);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.loading=false;
          
  }
  filterRC(selectedVal)
  {
    var filterVal = selectedVal.value;
    this.selectedFilterVal = filterVal;
    if(filterVal=='individual-report-components')
    {   
      
      for(let k=0; k < this.repairDetailsList.length;k++)
      {
        for(let l=0; l < this.userList.Items.length;l++)
        {
            if(this.repairDetailsList[k].RepairType==this.userList.Items[l].RepairType)
            {
              this.repairDetailsList[k].index = this.userList.Items[l].id;
            }
        }
      }

      this.repairDetailsList.sort(function (a, b) {
        return a.index > b.index ? 1 : -1;
      });
      this.repairComponentList =this.repairDetailsList;
      
    }
    else if(filterVal=='construction-activity-groups')
    {
     
      this.repairComponentList = this.activityList;
      
    }
    else
    {
      for(let k=0; k < this.repairDetailsList.length;k++)
      {
        for(let l=0; l < this.userList.Items.length;l++)
        {
            if(this.repairDetailsList[k].RepairType==this.userList.Items[l].RepairType)
            {
              this.repairDetailsList[k].index = this.userList.Items[l].id;
            }
        }
      }

      this.repairDetailsList.sort(function (a, b) {
        return a.index > b.index ? 1 : -1;
      });
      this.repairComponentList =this.repairDetailsList;
     
    }
      
  }
  setDrawFeeValue(){
    var formData=this.RepairItemForm.value;
    if(formData.AllowableFeesDrawNote == "" || formData.AllowableFeesDrawNote == null){
      alert("Please select # of draws.");
    }else{
      var drawValue = formData.AllowableFeesDrawNote*formData.AllowableFeesDrawFees;
      this.RepairItemForm.controls['AllowableFeesDrawFeesDis'].setValue([drawValue]);
    }
  }
  
  SaveAllowableFeesItem(){
    
    this.submitted=true;
    if(this.RepairItemForm.invalid)
    {
      return;
    }
    else{

     
      this.loading=true;
      this.submitted=false;
      this.propertyRepairConstructionDetail = "";
      var formData=this.RepairItemForm.value;
      if(this.getRepairConstructionList.length > 0){
        this.getRepairConstructionList.forEach(element => {
          if(element.PropertyId == this.RepairPropertyId){
           
            this.propertyRepairConstructionDetail = element;
          }
        });
      }else{
        this.propertyRepairConstructionDetail = {
                                  "PropertyId": 1,
                                  "Title": "Title",
                                  "OccupiedDuringConstruction": "",
                                  "BorrowerName": "",
                                  "InspectionDate": "",
                                  "InspectionAddress": "",
                                  "BorrowerPhone": "",
                                  "FHACase": "",
                                  "LoanNo": "",
                                  "Constructions": [],
                                  "ContingencyReservePercentage": "",
                                  "AllowableFees": "",
                                  "ConsultantComment": "",
                                  "DateofFinalAcceptance": "",
                                  "ConsultantSignature": "",
                                  "ConsultantSignDate": "",
                                  "Consultant": "",
                                  "IdNo": "",
                                  "ApplicantsSignAndDate": "",
                                  "ContractorSignature": "",
                                  "ContractorsSignatureDate": "",
                                  "Approved": "",
                                  "ApprovedDate": "",
                                  "MonthToCompletion": "",
                                  "SorStatus": "",
                                  "MailSend": "",
                                  "MailSendDate": "",
                                  "RevisedStatus": "",
                                  "IsFinal": "",
                                  "CreatedBy": "CreatedBy",
                                  "CreatedDate": "CreatedDate",
                                  "UpdateDate": "UpdateDate",
                                  "UpdatedBy": "UpdatedBy"
                                  }
      }

      if(this.propertyRepairConstructionDetail.Id){
        
        var propertyData = this.propertyRepairConstructionDetail;
        propertyData.PropertyId = this.RepairPropertyId;
        propertyData.BorrowerPhone = formData.BorrowerPhone;
        propertyData.ContingencyReservePercentage = formData.ContingencyReservePercentage;
        propertyData.FHACase = formData.FHACase;
        propertyData.InspectionAddress = formData.InspectionAddress;
        propertyData.LoanNo = formData.LoanNo;
        propertyData.InspectionDate = formData.InspectionDate;
        propertyData.MonthToCompletion = formData.MonthToCompletion;
        propertyData.OccupiedDuringConstruction = formData.OccupiedDuringConstruction;
         
        propertyData.ConsultantComment = formData.ConsultantComment;
        propertyData.AllowableFees = [];
        for(let i=0; i<formData.AllowableFees.length; i++ ){
            
          if(formData.AllowableFees[i].Status == true){
            propertyData.AllowableFees.push(formData.AllowableFees[i]);
          }
        }
        if(formData.AllowableFeesDrawStatus==true){
          var drawValue = {
            "Id": 0,
            "DrawFeesDis": formData.AllowableFeesDrawFees,
            "Notes": formData.AllowableFeesDrawNote,
            "Status": true,
            "Title": "Draw Fee",
            "Fees": formData.AllowableFeesDrawFeesDis,
          };
          propertyData.AllowableFees.push(drawValue);
        }
        if(formData.AllowableFeesMileageStatus==true){
          var mileageValue = {
            "Id": 2500,
            "Notes": formData.AllowableFeesMileageNote,
            "Status": true,
            "Title": "Mileage",
            "Fees": formData.AllowableFeesMileageFees,
          };
          propertyData.AllowableFees.push(mileageValue);
        }
        if(formData.AllowableFeesRoundMileageStatus==true){
          var roundMileageValue = {
            "Id": 3500,
            "Notes": formData.AllowableFeesRoundMileageNote,
            "Status": true,
            "Title": "Rounded Mileage",
            "Fees": formData.AllowableFeesRoundMileageFees,
          };
          propertyData.AllowableFees.push(roundMileageValue);
        }
        if(this.isFinalSOR==true)
            {
                propertyData.IsFinal=true;
                propertyData.Id=this.sorId;
            }
            else
            {
                propertyData.Id=this.sorId;
            }
        
            if(this.isFinalSOR==true)
            {
              
                propertyData.PermitCost = formData.PermitCost;
                propertyData.Id = this.gcBidId;
               
              
                this._bservice.UpdateSORForGC(propertyData).subscribe(response=>{
               
                this.sessionService.notifier.notify('success','Final Specification Of Repair Construction Detail update successfully.');
                this.isList=true;
                this.isAdd=false;
                this.isAllowfeeSorExpand = false;
                this.isFinalSorExpand = false;
                 
                this.getRepairItem(this.RepairPropertyId);
                this.loading=false;
            },error=>{
                this.sessionService.notifier.notify('error',error.error.message);
                this.loading=false;
            })
            }
            else
            {
              
                this._bservice.UpdateSOR(propertyData).subscribe(response=>{this.updateBidItems();
                this.sessionService.notifier.notify('success','Specification Of Repair Construction Detail update successfully.');
                this.isList=true;
                    this.isAdd=false;
                    this.isAllowfeeSorExpand = false;
                this.isFinalSorExpand = false;
                     
                        this.getRepairItem(this.RepairPropertyId);
                    this.loading=false;
        },error=>{
            this.sessionService.notifier.notify('error',error.error.message);
            this.loading=false;
        })
            }
            
        

      }else{
         
        var propertyData = this.propertyRepairConstructionDetail;
        
       
        propertyData.PropertyId = this.RepairPropertyId;
        propertyData.BorrowerPhone = formData.BorrowerPhone;
        propertyData.ContingencyReservePercentage = formData.ContingencyReservePercentage;
        propertyData.FHACase = formData.FHACase;
        propertyData.InspectionAddress = formData.InspectionAddress;
        propertyData.LoanNo = formData.LoanNo;
        propertyData.InspectionDate = formData.InspectionDate;
        propertyData.MonthToCompletion = formData.MonthToCompletion;
        propertyData.OccupiedDuringConstruction = formData.OccupiedDuringConstruction;
        propertyData.ConsultantComment = formData.ConsultantComment;
        propertyData.AllowableFees = [];
        for(let i=0; i<formData.AllowableFees.length; i++ ){
          if(formData.AllowableFees[i].Status == true){
            propertyData.AllowableFees.push(formData.AllowableFees[i]);
          }
        }

        if(formData.AllowableFeesDrawStatus==true){
          var drawValue = {
            "Id": 0,
            "DrawFeesDis": formData.AllowableFeesDrawFees,
            "Notes": formData.AllowableFeesDrawNote,
            "Status": true,
            "Title": "Draw Fee",
            "Fees": formData.AllowableFeesDrawFeesDis,
          };
          propertyData.AllowableFees.push(drawValue);
        }
        if(formData.AllowableFeesMileageStatus==true){
          var mileageValue = {
            "Id": 2500,
            "Notes": formData.AllowableFeesMileageNote,
            "Status": true,
            "Title": "Mileage",
            "Fees": formData.AllowableFeesMileageFees,
          };
          propertyData.AllowableFees.push(mileageValue);
        }
        if(formData.AllowableFeesRoundMileageStatus==true){
          var roundMileageValue = {
            "Id": 3500,
            "Notes": formData.AllowableFeesRoundMileageNote,
            "Status": true,
            "Title": "Rounded Mileage",
            "Fees": formData.AllowableFeesRoundMileageFees,
          };
          propertyData.AllowableFees.push(roundMileageValue);
        }
       
        this._bservice.AddSOR(propertyData).subscribe(response=>{this.updateBidItems();
          this.sessionService.notifier.notify('success','Specification Of Repair Construction Detail added successfully.');
          this.isList=true;
          this.isAdd=false;
          this.isAllowfeeSorExpand = false;
          this.isFinalSorExpand = false;
          this.getRepairItem(this.RepairPropertyId);
          this.loading=false;
        },error=>{
            this.sessionService.notifier.notify('error',error.error.message);
            this.loading=false;
        })

      }
    }
  }


  
  SaveConstructionItem(){
   
    this.submitted=true;
    this.loading=true;
    if(this.RepairItemForm.invalid)
    {
      return;
    }
    else{
      
     this.submitted=false;
      this.propertyRepairConstructionDetail = "";
         var formData= this.RepairItemForm.getRawValue();
         

         
if(this.getRepairConstructionList.length > 0){
           this.getRepairConstructionList.forEach(element => {
             if(element.PropertyId == this.RepairPropertyId){
               
               this.propertyRepairConstructionDetail = element;
   
             }
           });
         }else{
           
           this.propertyRepairConstructionDetail = {
                                     "PropertyId": 1,
                                     "Title": "Title",
                                     "OccupiedDuringConstruction": "",
                                     "BorrowerName": "",
                                     "InspectionDate": "",
                                     "InspectionAddress": "",
                                     "BorrowerPhone": "",
                                     "FHACase": "",
                                     "LoanNo": "",
                                     "Constructions": [],
                                     "ContingencyReservePercentage": "",
                                     "AllowableFees": "",
                                     "ConsultantComment": "",
                                     "DateofFinalAcceptance": "",
                                     "ConsultantSignature": "",
                                     "ConsultantSignDate": "",
                                     "Consultant": "",
                                     "IdNo": "",
                                     "ApplicantsSignAndDate": "",
                                     "ContractorSignature": "",
                                     "ContractorsSignatureDate": "",
                                     "Approved": "",
                                     "ApprovedDate": "",
                                     "MonthToCompletion": "",
                                     "SorStatus": "",
                                     "MailSend": "",
                                     "MailSendDate": "",
                                     "RevisedStatus": "",
                                     "IsFinal": "",
                                     "CreatedBy": "CreatedBy",
                                     "CreatedDate": "CreatedDate",
                                     "UpdateDate": "UpdateDate",
                                     "UpdatedBy": "UpdatedBy"
                                     }
   
         }
        

          
         if(this.propertyRepairConstructionDetail.Id){
         
          var propertyData = this.propertyRepairConstructionDetail;
       
          let filledRepairItem=[];
          if(formData.RepairItemDetails.length > 0)
          {
            
          for(let i=0; i< formData.RepairItemDetails.length;i++)
          {
            
              if((formData.RepairItemDetails[i].RepairItemName!='' && formData.RepairItemDetails[i].RepairItemName!=null)  && (formData.RepairItemDetails[i].Location !='' && formData.RepairItemDetails[i].Location !=null) && (formData.RepairItemDetails[i].Level !='' && formData.RepairItemDetails[i].Level !=null) && (((formData.RepairItemDetails[i].MaterialUnit !='' && formData.RepairItemDetails[i].MaterialUnit !=null) && (formData.RepairItemDetails[i].MaterialQuantity !='' && formData.RepairItemDetails[i].MaterialQuantity !=null)  && (formData.RepairItemDetails[i].MaterialUnitCost !='' && formData.RepairItemDetails[i].MaterialUnitCost !=null)) && ((formData.RepairItemDetails[i].LaborUnit !='' && formData.RepairItemDetails[i].LaborUnit !=null) && (formData.RepairItemDetails[i].LaborQuantity !='' && formData.RepairItemDetails[i].LaborQuantity !=null) && (formData.RepairItemDetails[i].LaborUnitCost !='' && formData.RepairItemDetails[i].LaborUnitCost !=null))))
              {
                  filledRepairItem.push(formData.RepairItemDetails[i]);
              }
          }
          
          if(filledRepairItem.length == 0)
          {
            this.sessionService.notifier.notify('error','Please fill the information in all field Or You can go back.');
            this.loading=false;
            return true;
          }
          if(filledRepairItem.length > 0)
          {
              var data={"RepairType":formData.SendRepairType,"RepairItemDetails":filledRepairItem};
             
              this.checkConstructionsDetails = false;
              for(let i=0; i < propertyData.Constructions.length; i++){
                  if(propertyData.Constructions[i].RepairType == formData.SendRepairType){
                    propertyData.Constructions[i] = data;
                    this.checkConstructionsDetails = true;
                  }
                }
                if(this.checkConstructionsDetails == false){
                  propertyData.Constructions.push(data);
                }
          }
        }
        else
        {
          var data={"RepairType":formData.SendRepairType,"RepairItemDetails":filledRepairItem};
             
       
              for(let i=0; i < propertyData.Constructions.length; i++){
               
                  if(propertyData.Constructions[i].RepairType == formData.SendRepairType){
                    propertyData.Constructions.splice(i,1);
                  }
                }
                
        
        }
  
       
          propertyData.PropertyId = this.RepairPropertyId;
          propertyData.BorrowerPhone = formData.BorrowerPhone;
          propertyData.ContingencyReservePercentage = formData.ContingencyReservePercentage;
          propertyData.FHACase = formData.FHACase;
          propertyData.InspectionAddress = formData.InspectionAddress;
          propertyData.LoanNo = formData.LoanNo;
          propertyData.InspectionDate = formData.InspectionDate;
          propertyData.MonthToCompletion = formData.MonthToCompletion;
          propertyData.OccupiedDuringConstruction = formData.OccupiedDuringConstruction;
          propertyData.ConsultantComment = formData.ConsultantComment;
          propertyData.AllowableFees = [];
          for(let i=0; i<formData.AllowableFees.length; i++ ){
            if(formData.AllowableFees[i].Status == true){
              propertyData.AllowableFees.push(formData.AllowableFees[i]);
            }
          }
  
          if(formData.AllowableFeesDrawStatus==true){
            var drawValue = {
              "Id": 0,
              "DrawFeesDis": formData.AllowableFeesDrawFees,
              "Notes": formData.AllowableFeesDrawNote,
              "Status": true,
              "Title": "Draw Fee",
              "Fees": formData.AllowableFeesDrawFeesDis,
            };
            propertyData.AllowableFees.push(drawValue);
          }
          if(formData.AllowableFeesMileageStatus==true){
            var mileageValue = {
              "Id": 2500,
              "Notes": formData.AllowableFeesMileageNote,
              "Status": true,
              "Title": "Mileage",
              "Fees": formData.AllowableFeesMileageFees,
            };
            propertyData.AllowableFees.push(mileageValue);
          }
          if(formData.AllowableFeesRoundMileageStatus==true){
            var roundMileageValue = {
              "Id": 3500,
              "Notes": formData.AllowableFeesRoundMileageNote,
              "Status": true,
              "Title": "Rounded Mileage",
              "Fees": formData.AllowableFeesRoundMileageFees,
            };
            propertyData.AllowableFees.push(roundMileageValue);
          }
          
         
          if(this.isFinalSOR==true)
          {
              propertyData.IsFinal=true;
              propertyData.Id=this.sorId;
              
             
          }
          else
          {
              propertyData.Id=this.sorId;
              
          }
  
          
              if(this.isFinalSOR==true)
              {
                  propertyData.PermitCost = formData.PermitCost;
                  propertyData.Id = this.gcBidId;
                  
                 
                  this._bservice.UpdateSORForGC(propertyData).subscribe(response=>{
                 
                      this.sessionService.notifier.notify('success','Final Specification Of Repair Construction Detail update successfully.');
                      this.isList=true;
                      this.isAdd=false;
                      this.isAllowfeeSorExpand = false;
                      this.isFinalSorExpand = false;
                      this.getRepairItem(this.RepairPropertyId);
                      this.loading=false;
              },error=>{
                  this.sessionService.notifier.notify('error',error.error.message);
                  this.loading=false;
              })
              }
              else
              {
                  this._bservice.UpdateSOR(propertyData).subscribe(response=>{this.updateBidItems();
                  this.sessionService.notifier.notify('success','Specification Of Repair Construction Detail update successfully.');
                  this.isList=true;
                  this.isAdd=false;
                  this.isAllowfeeSorExpand = false;
                  this.isFinalSorExpand = false;
                  this.getRepairItem(this.RepairPropertyId);
                  this.loading=false;
                 
                  
                  },error=>{
                      this.sessionService.notifier.notify('error',error.error.message);
                      this.loading=false;
                  })
              }
              
  
        }else{
        
          
          var propertyData = this.propertyRepairConstructionDetail;
          let filledRepairItem=[];
          if(formData.RepairItemDetails.length > 0)
          {
          for(let i=0; i< formData.RepairItemDetails.length;i++)
          {
           
              if((formData.RepairItemDetails[i].RepairItemName!='' && formData.RepairItemDetails[i].RepairItemName!=null)  && (formData.RepairItemDetails[i].Location !='' && formData.RepairItemDetails[i].Location !=null) && (formData.RepairItemDetails[i].Level !='' && formData.RepairItemDetails[i].Level !=null) && (((formData.RepairItemDetails[i].MaterialUnit !='' && formData.RepairItemDetails[i].MaterialUnit !=null) && (formData.RepairItemDetails[i].MaterialQuantity !='' && formData.RepairItemDetails[i].MaterialQuantity !=null)  && (formData.RepairItemDetails[i].MaterialUnitCost !='' && formData.RepairItemDetails[i].MaterialUnitCost !=null)) && ((formData.RepairItemDetails[i].LaborUnit !='' && formData.RepairItemDetails[i].LaborUnit !=null) && (formData.RepairItemDetails[i].LaborQuantity !='' && formData.RepairItemDetails[i].LaborQuantity !=null) && (formData.RepairItemDetails[i].LaborUnitCost !='' && formData.RepairItemDetails[i].LaborUnitCost !=null))))
              {
                  filledRepairItem.push(formData.RepairItemDetails[i]);
              }
          }
          if(filledRepairItem.length == 0)
          {
            this.sessionService.notifier.notify('error','Please file the information in all field Or You can go back.');
            this.loading=false;
            return true;
          }
          if(filledRepairItem.length > 0)
          {
              var data={"RepairType":formData.SendRepairType,"RepairItemDetails":filledRepairItem};
             
              this.checkConstructionsDetails = false;
              for(let i=0; i < propertyData.Constructions.length; i++){
                  if(propertyData.Constructions[i].RepairType == formData.SendRepairType){
                    propertyData.Constructions[i] = data;
                    this.checkConstructionsDetails = true;
                  }
                }
                if(this.checkConstructionsDetails == false){
                  propertyData.Constructions.push(data);
                }
          }
        }
          else
        {
          var data={"RepairType":formData.SendRepairType,"RepairItemDetails":filledRepairItem};
             
                 
          for(let i=0; i < propertyData.Constructions.length; i++){
            if(propertyData.Constructions[i].RepairType == formData.SendRepairType){
              propertyData.Constructions.splice(i,1);
            }
          }
        
        }
          propertyData.PropertyId = this.RepairPropertyId;
          propertyData.PropertyId = this.RepairPropertyId;
          propertyData.BorrowerPhone = formData.BorrowerPhone;
          propertyData.ContingencyReservePercentage = formData.ContingencyReservePercentage;
          propertyData.FHACase = formData.FHACase;
          propertyData.InspectionAddress = formData.InspectionAddress;
          propertyData.LoanNo = formData.LoanNo;
          propertyData.InspectionDate = formData.InspectionDate;
          propertyData.MonthToCompletion = formData.MonthToCompletion;
          propertyData.OccupiedDuringConstruction = formData.OccupiedDuringConstruction;
          propertyData.ConsultantComment = formData.ConsultantComment;
          propertyData.AllowableFees = [];
          for(let i=0; i<formData.AllowableFees.length; i++ ){
            if(formData.AllowableFees[i].Status == true){
              propertyData.AllowableFees.push(formData.AllowableFees[i]);
            }
          }
  
          if(formData.AllowableFeesDrawStatus==true){
            var drawValue = {
              "Id": 0,
              "DrawFeesDis": formData.AllowableFeesDrawFees,
              "Notes": formData.AllowableFeesDrawNote,
              "Status": true,
              "Title": "Draw Fee",
              "Fees": formData.AllowableFeesDrawFeesDis,
            };
            propertyData.AllowableFees.push(drawValue);
          }
          if(formData.AllowableFeesMileageStatus==true){
            var mileageValue = {
              "Id": 2500,
              "Notes": formData.AllowableFeesMileageNote,
              "Status": true,
              "Title": "Mileage",
              "Fees": formData.AllowableFeesMileageFees,
            };
            propertyData.AllowableFees.push(mileageValue);
          }
          if(formData.AllowableFeesRoundMileageStatus==true){
            var roundMileageValue = {
              "Id": 3500,
              "Notes": formData.AllowableFeesRoundMileageNote,
              "Status": true,
              "Title": "Rounded Mileage",
              "Fees": formData.AllowableFeesRoundMileageFees,
            };
            propertyData.AllowableFees.push(roundMileageValue);
          }
         
          
          this._bservice.AddSOR(propertyData).subscribe(response=>{this.updateBidItems();
            this.sessionService.notifier.notify('success','Specification Of Repair Construction Detail added successfully.');
            this.isList=true;
            this.isAdd=false;
            this.isAllowfeeSorExpand = false;
            this.isFinalSorExpand = false;
            this.getRepairItem(this.RepairPropertyId);
            this.loading=false;
            
          },error=>{
              this.sessionService.notifier.notify('error',error.error.message);
              this.loading=false;
          })
  
  
  
        }
    }
    
  }
  SaveActivityItem()
  {
    this.submitted=true;
    if(this.RepairActivityItemForm.invalid)
    {
      return;
    }
    else{
      this.loading=true;
      this.submitted=false;
      this.propertyRepairConstructionDetail = "";
      var formData=this.RepairItemForm.getRawValue();
      if(this.getRepairConstructionList.length > 0){
        this.getRepairConstructionList.forEach(element => {
          if(element.PropertyId == this.RepairPropertyId){
        
            this.propertyRepairConstructionDetail = element;
          }
        });
      }else{

        this.propertyRepairConstructionDetail = {
          "PropertyId": 1,
          "Title": "Title",
          "OccupiedDuringConstruction": "",
          "BorrowerName": "",
          "InspectionDate": "",
          "InspectionAddress": "",
          "BorrowerPhone": "",
          "FHACase": "",
          "LoanNo": "",
          "Constructions": [],
          "ContingencyReservePercentage": "",
          "AllowableFees": "",
          "ConsultantComment": "",
          "DateofFinalAcceptance": "",
          "ConsultantSignature": "",
          "ConsultantSignDate": "",
          "Consultant": "",
          "IdNo": "",
          "ApplicantsSignAndDate": "",
          "ContractorSignature": "",
          "ContractorsSignatureDate": "",
          "Approved": "",
          "ApprovedDate": "",
          "MonthToCompletion": "",
          "SorStatus": "",
          "MailSend": "",
          "MailSendDate": "",
          "RevisedStatus": "",
          "IsFinal": "",
          "CreatedBy": "CreatedBy",
          "CreatedDate": "CreatedDate",
          "UpdateDate": "UpdateDate",
          "UpdatedBy": "UpdatedBy"
          }
        }
          if(this.propertyRepairConstructionDetail.Id){
           
            var propertyData = this.propertyRepairConstructionDetail;
    
            
            let filledRepairData=[];
            
            for(let i=0; i< this.selectedComponentListForGroup.length;i++)
            {
              
              for(let j=0; j< this.selectedComponentListForGroup[i].RepairItemDetails.length;j++)
              {
                 if(filledRepairData.length > 0)
                 {
                   var isCheck=false;
                     for(let m=0;m<filledRepairData.length;m++)
                     {
                         if(filledRepairData[m].RepairType==this.selectedComponentListForGroup[i].RepairItemDetails[j].Type)
                         {
                           isCheck=true;
                           var isCheckNew=false;
                           for(let n=0; n<filledRepairData[m].RepairItemDetails.length;n++)
                           {
                             if(filledRepairData[m].RepairItemDetails[n].RepairItemName==this.selectedComponentListForGroup[i].RepairItemDetails[j].RepairItemName)
                             {
                              isCheckNew=true;
                              break;
                             }

                           }
                           if(isCheckNew==false)
                           {

                            for(let k=0;k<this.repairDetailsList.length;k++)
                            {
                              if(this.repairDetailsList[k].RepairType==this.selectedComponentListForGroup[i].RepairItemDetails[j].Type)
                              {
                                for(let l=0;l<this.repairDetailsList[k].RepairItemDetails.length;l++)
                                {
                                  if(this.selectedComponentListForGroup[i].RepairItemDetails[j].RepairItemName==this.repairDetailsList[k].RepairItemDetails[l].RepairItemName)
                                  {
                                    let itemCurrent= {
                                      'RepairItemName':this.repairDetailsList[k].RepairItemDetails[l].RepairItemName,
                                      'Location':'',
                                      'Level':'',
                                      'MaterialUnit':this.repairDetailsList[k].RepairItemDetails[l].MaterialUnit,
                                      'MaterialQuantity':1,
                                      'MaterialUnitCost':this.repairDetailsList[k].RepairItemDetails[l].MaterialUnitCost,
                                      'LaborUnit':this.repairDetailsList[k].RepairItemDetails[l].LaborUnit,
                                      'LaborQuantity':1,
                                      'LaborUnitCost':this.repairDetailsList[k].RepairItemDetails[l].LaborUnitCost,
                                      'Summary':this.repairDetailsList[k].RepairItemDetails[l].Summary
                                    };
                                    filledRepairData[m].RepairItemDetails.push(itemCurrent);
                                    break;
                                  }
                    
                                }
                    
                                break;
                              }
                    
                            }

                           }
                           
                         }
                        
            
                     }
                    if(isCheck==false)
                    {
                      for(let k=0;k<this.repairDetailsList.length;k++)
                    {
                      if(this.repairDetailsList[k].RepairType==this.selectedComponentListForGroup[i].RepairItemDetails[j].Type)
                      {
                        for(let l=0;l<this.repairDetailsList[k].RepairItemDetails.length;l++)
                        {
                          if(this.selectedComponentListForGroup[i].RepairItemDetails[j].RepairItemName==this.repairDetailsList[k].RepairItemDetails[l].RepairItemName)
                          {
                           
                            let repairItem=[];
                            let itemCurrent= {
                              'RepairItemName':this.repairDetailsList[k].RepairItemDetails[l].RepairItemName,
                              'Location':'',
                              'Level':'',
                              'MaterialUnit':this.repairDetailsList[k].RepairItemDetails[l].MaterialUnit,
                              'MaterialQuantity':1,
                              'MaterialUnitCost':this.repairDetailsList[k].RepairItemDetails[l].MaterialUnitCost,
                              'LaborUnit':this.repairDetailsList[k].RepairItemDetails[l].LaborUnit,
                              'LaborQuantity':1,
                              'LaborUnitCost':this.repairDetailsList[k].RepairItemDetails[l].LaborUnitCost,
                              'Summary':this.repairDetailsList[k].RepairItemDetails[l].Summary
                            };
                                repairItem.push(itemCurrent);
                                let tempVar={
                                  'RepairType':this.repairDetailsList[k].RepairType,
                                  'RepairItemDetails': repairItem,
                                };
                               
                               filledRepairData.push(tempVar);
                            break;
                          }
            
                        }
            
                        break;
                      }
            
                    }
                    }
            
                 }
                 else
                 {
                    for(let k=0;k<this.repairDetailsList.length;k++)
                    {
                      if(this.repairDetailsList[k].RepairType==this.selectedComponentListForGroup[i].RepairItemDetails[j].Type)
                      {
                        for(let l=0;l<this.repairDetailsList[k].RepairItemDetails.length;l++)
                        {
                          if(this.selectedComponentListForGroup[i].RepairItemDetails[j].RepairItemName==this.repairDetailsList[k].RepairItemDetails[l].RepairItemName)
                          {
                           
                            let repairItem=[];
                            let itemCurrent= {
                              'RepairItemName':this.repairDetailsList[k].RepairItemDetails[l].RepairItemName,
                              'Location':'',
                              'Level':'',
                              'MaterialUnit':this.repairDetailsList[k].RepairItemDetails[l].MaterialUnit,
                              'MaterialQuantity':1,
                              'MaterialUnitCost':this.repairDetailsList[k].RepairItemDetails[l].MaterialUnitCost,
                              'LaborUnit':this.repairDetailsList[k].RepairItemDetails[l].LaborUnit,
                              'LaborQuantity':1,
                              'LaborUnitCost':this.repairDetailsList[k].RepairItemDetails[l].LaborUnitCost,
                              'Summary':this.repairDetailsList[k].RepairItemDetails[l].Summary
                            };
                                repairItem.push(itemCurrent);
                                let tempVar={
                                  'RepairType':this.repairDetailsList[k].RepairType,
                                  'RepairItemDetails': repairItem,
                                };
                              
                               filledRepairData.push(tempVar);
                            break;
                          }
            
                        }
            
                        break;
                      }
            
                    }
                 }
              }
            }
        
           
            let propertyDataConstructions=[];
            if(filledRepairData.length > 0)
            {
              for(let n=0; n<filledRepairData.length;n++)
              {
               
                this.checkConstructionsDetails = false;
                propertyDataConstructions = propertyData.Constructions;
                for(let i=0; i < propertyData.Constructions.length; i++){
                    if(propertyData.Constructions[i].RepairType == filledRepairData[n].RepairType){
                      this.checkConstructionsDetails = true;
                      for(let p=0;p<filledRepairData[n].RepairItemDetails.length;p++)
                        {
                          var isCheckProperty=false;
                      for(let r=0;r<propertyData.Constructions[i].RepairItemDetails.length;r++)
                      {
                          if(propertyData.Constructions[i].RepairItemDetails[r].RepairItemName==filledRepairData[n].RepairItemDetails[p].RepairItemName)
                          {
                            isCheckProperty=true;
                           break;
                          }
                         
                         
                        }
                        if(isCheckProperty==false)
                        {
                          propertyDataConstructions[i].RepairItemDetails.push(filledRepairData[n].RepairItemDetails[p]);
                        }
                      }
                      
                      
                    }
                  }
                  if(this.checkConstructionsDetails == false){
                    propertyDataConstructions.push(filledRepairData[n]);
                  }
              }
                
            }

            propertyData.Constructions=propertyDataConstructions;
           
            propertyData.PropertyId = this.RepairPropertyId;
            propertyData.BorrowerPhone = formData.BorrowerPhone;
            propertyData.ContingencyReservePercentage = formData.ContingencyReservePercentage;
            propertyData.FHACase = formData.FHACase;
            propertyData.InspectionAddress = formData.InspectionAddress;
            propertyData.LoanNo = formData.LoanNo;
            propertyData.InspectionDate = formData.InspectionDate;
            propertyData.MonthToCompletion = formData.MonthToCompletion;
            propertyData.OccupiedDuringConstruction = formData.OccupiedDuringConstruction;
            propertyData.ConsultantComment = formData.ConsultantComment;
            propertyData.AllowableFees = [];
            for(let i=0; i<formData.AllowableFees.length; i++ ){
              if(formData.AllowableFees[i].Status == true){
                propertyData.AllowableFees.push(formData.AllowableFees[i]);
              }
            }
    
            if(formData.AllowableFeesDrawStatus==true){
              var drawValue = {
                "Id": 0,
                "DrawFeesDis": formData.AllowableFeesDrawFees,
                "Notes": formData.AllowableFeesDrawNote,
                "Status": true,
                "Title": "Draw Fee",
                "Fees": formData.AllowableFeesDrawFeesDis,
              };
              propertyData.AllowableFees.push(drawValue);
            }
            if(formData.AllowableFeesMileageStatus==true){
              var mileageValue = {
                "Id": 2500,
                "Notes": formData.AllowableFeesMileageNote,
                "Status": true,
                "Title": "Mileage",
                "Fees": formData.AllowableFeesMileageFees,
              };
              propertyData.AllowableFees.push(mileageValue);
            }
            if(formData.AllowableFeesRoundMileageStatus==true){
              var roundMileageValue = {
                "Id": 3500,
                "Notes": formData.AllowableFeesRoundMileageNote,
                "Status": true,
                "Title": "Rounded Mileage",
                "Fees": formData.AllowableFeesRoundMileageFees,
              };
              propertyData.AllowableFees.push(roundMileageValue);
            }
           
          
            if(this.isFinalSOR==true)
            {
                propertyData.IsFinal=true;
                propertyData.Id=this.sorId;
                
               
            }
            else
            {
                propertyData.Id=this.sorId;
                
            }
    
            
                if(this.isFinalSOR==true)
                {
                    propertyData.PermitCost = formData.PermitCost;
                    propertyData.Id = this.gcBidId;
                    
                   
                    this._bservice.UpdateSORForGC(propertyData).subscribe(response=>{
                   
                        this.sessionService.notifier.notify('success','Final Specification Of Repair Construction Detail update successfully.');
                        this.isList=true;
                        this.isAdd=false;
                        this.isAllowfeeSorExpand = false;
                        this.isFinalSorExpand = false;
                        this.isActAdd = false;
                        this.getRepairItem(this.RepairPropertyId);
                        this.loading=false;
                },error=>{
                    this.sessionService.notifier.notify('error',error.error.message);
                    this.loading=false;
                })
                }
                else
                {
                    this._bservice.UpdateSOR(propertyData).subscribe(response=>{
                      this.updateBidItems();
                    this.sessionService.notifier.notify('success','Specification Of Repair Construction Detail update successfully.');
                    this.isList=true;
                    this.isAdd=false;
                    this.isAllowfeeSorExpand = false;
                    this.isFinalSorExpand = false;
                    this.isActAdd = false;
                    this.getRepairItem(this.RepairPropertyId);
                   
                    this.loading=false;
                   
                    
                    },error=>{
                        this.sessionService.notifier.notify('error',error.error.message);
                        this.loading=false;
                    })
                }
                
    
          }else{
        
            var propertyData = this.propertyRepairConstructionDetail;
            
            let filledRepairData=[];
            
            for(let i=0; i< this.selectedComponentListForGroup.length;i++)
            {
              
              for(let j=0; j< this.selectedComponentListForGroup[i].RepairItemDetails.length;j++)
              {
                 if(filledRepairData.length > 0)
                 {
                   var isCheck=false;
                     for(let m=0;m<filledRepairData.length;m++)
                     {
                         if(filledRepairData[m].RepairType==this.selectedComponentListForGroup[i].RepairItemDetails[j].Type)
                         {
                           isCheck=true;
                           var isCheckNew=false;
                           for(let n=0; n<filledRepairData[m].RepairItemDetails.length;n++)
                           {
                             if(filledRepairData[m].RepairItemDetails[n].RepairItemName==this.selectedComponentListForGroup[i].RepairItemDetails[j].RepairItemName)
                             {
                              isCheckNew=true;
                              break;
                             }

                           }
                           if(isCheckNew==false)
                           {

                            for(let k=0;k<this.repairDetailsList.length;k++)
                            {
                              if(this.repairDetailsList[k].RepairType==this.selectedComponentListForGroup[i].RepairItemDetails[j].Type)
                              {
                                for(let l=0;l<this.repairDetailsList[k].RepairItemDetails.length;l++)
                                {
                                  if(this.selectedComponentListForGroup[i].RepairItemDetails[j].RepairItemName==this.repairDetailsList[k].RepairItemDetails[l].RepairItemName)
                                  {
                                    let itemCurrent= {
                                      'RepairItemName':this.repairDetailsList[k].RepairItemDetails[l].RepairItemName,
                                      'Location':'',
                                      'Level':'',
                                      'MaterialUnit':this.repairDetailsList[k].RepairItemDetails[l].MaterialUnit,
                                      'MaterialQuantity':1,
                                      'MaterialUnitCost':this.repairDetailsList[k].RepairItemDetails[l].MaterialUnitCost,
                                      'LaborUnit':this.repairDetailsList[k].RepairItemDetails[l].LaborUnit,
                                      'LaborQuantity':1,
                                      'LaborUnitCost':this.repairDetailsList[k].RepairItemDetails[l].LaborUnitCost,
                                      'Summary':this.repairDetailsList[k].RepairItemDetails[l].Summary
                                    };
                                    filledRepairData[m].RepairItemDetails.push(itemCurrent);
                                    break;
                                  }
                    
                                }
                    
                                break;
                              }
                    
                            }

                           }
                           
                         }
                        
            
                     }
                    if(isCheck==false)
                    {
                      for(let k=0;k<this.repairDetailsList.length;k++)
                    {
                      if(this.repairDetailsList[k].RepairType==this.selectedComponentListForGroup[i].RepairItemDetails[j].Type)
                      {
                        for(let l=0;l<this.repairDetailsList[k].RepairItemDetails.length;l++)
                        {
                          if(this.selectedComponentListForGroup[i].RepairItemDetails[j].RepairItemName==this.repairDetailsList[k].RepairItemDetails[l].RepairItemName)
                          {
                           
                            let repairItem=[];
                            let itemCurrent= {
                              'RepairItemName':this.repairDetailsList[k].RepairItemDetails[l].RepairItemName,
                              'Location':'',
                              'Level':'',
                              'MaterialUnit':this.repairDetailsList[k].RepairItemDetails[l].MaterialUnit,
                              'MaterialQuantity':1,
                              'MaterialUnitCost':this.repairDetailsList[k].RepairItemDetails[l].MaterialUnitCost,
                              'LaborUnit':this.repairDetailsList[k].RepairItemDetails[l].LaborUnit,
                              'LaborQuantity':1,
                              'LaborUnitCost':this.repairDetailsList[k].RepairItemDetails[l].LaborUnitCost,
                              'Summary':this.repairDetailsList[k].RepairItemDetails[l].Summary
                            };
                                repairItem.push(itemCurrent);
                                let tempVar={
                                  'RepairType':this.repairDetailsList[k].RepairType,
                                  'RepairItemDetails': repairItem,
                                };
                               
                               filledRepairData.push(tempVar);
                            break;
                          }
            
                        }
            
                        break;
                      }
            
                    }
                    }
            
                 }
                 else
                 {
                    for(let k=0;k<this.repairDetailsList.length;k++)
                    {
                      if(this.repairDetailsList[k].RepairType==this.selectedComponentListForGroup[i].RepairItemDetails[j].Type)
                      {
                        for(let l=0;l<this.repairDetailsList[k].RepairItemDetails.length;l++)
                        {
                          if(this.selectedComponentListForGroup[i].RepairItemDetails[j].RepairItemName==this.repairDetailsList[k].RepairItemDetails[l].RepairItemName)
                          {
                           
                            let repairItem=[];
                            let itemCurrent= {
                              'RepairItemName':this.repairDetailsList[k].RepairItemDetails[l].RepairItemName,
                              'Location':'',
                              'Level':'',
                              'MaterialUnit':this.repairDetailsList[k].RepairItemDetails[l].MaterialUnit,
                              'MaterialQuantity':1,
                              'MaterialUnitCost':this.repairDetailsList[k].RepairItemDetails[l].MaterialUnitCost,
                              'LaborUnit':this.repairDetailsList[k].RepairItemDetails[l].LaborUnit,
                              'LaborQuantity':1,
                              'LaborUnitCost':this.repairDetailsList[k].RepairItemDetails[l].LaborUnitCost,
                              'Summary':this.repairDetailsList[k].RepairItemDetails[l].Summary
                            };
                                repairItem.push(itemCurrent);
                                let tempVar={
                                  'RepairType':this.repairDetailsList[k].RepairType,
                                  'RepairItemDetails': repairItem,
                                };
                              
                               filledRepairData.push(tempVar);
                            break;
                          }
            
                        }
            
                        break;
                      }
            
                    }
                 }
              }
            }




            let propertyDataConstructions=[];
            if(filledRepairData.length > 0)
            {
              for(let n=0; n<filledRepairData.length;n++)
              {
               
                this.checkConstructionsDetails = false;
                propertyDataConstructions = propertyData.Constructions;
                for(let i=0; i < propertyData.Constructions.length; i++){
                    if(propertyData.Constructions[i].RepairType == filledRepairData[n].RepairType){
                      this.checkConstructionsDetails = true;
                      for(let p=0;p<filledRepairData[n].RepairItemDetails.length;p++)
                        {
                          for(let r=0;r<propertyData.Constructions[i].RepairItemDetails.length;r++)
                          {
                              if(propertyData.Constructions[i].RepairItemDetails[r].RepairItemName==filledRepairData[n].RepairItemDetails[p].RepairItemName)
                              {
                                isCheckProperty=true;
                               break;
                              }
                             
                             
                            }
                            if(isCheckProperty==false)
                            {
                              propertyDataConstructions[i].RepairItemDetails.push(filledRepairData[n].RepairItemDetails[p]);
                            }
                      }
                      
                      
                    }
                  }
                  if(this.checkConstructionsDetails == false){
                    propertyDataConstructions.push(filledRepairData[n]);
                  }
              }
                
            }

            propertyData.Constructions=propertyDataConstructions;

            propertyData.PropertyId = this.RepairPropertyId;
            propertyData.PropertyId = this.RepairPropertyId;
            propertyData.BorrowerPhone = formData.BorrowerPhone;
            propertyData.ContingencyReservePercentage = formData.ContingencyReservePercentage;
            propertyData.FHACase = formData.FHACase;
            propertyData.InspectionAddress = formData.InspectionAddress;
            propertyData.LoanNo = formData.LoanNo;
            propertyData.InspectionDate = formData.InspectionDate;
            propertyData.MonthToCompletion = formData.MonthToCompletion;
            propertyData.OccupiedDuringConstruction = formData.OccupiedDuringConstruction;
            propertyData.ConsultantComment = formData.ConsultantComment;
            propertyData.AllowableFees = [];
            for(let i=0; i<formData.AllowableFees.length; i++ ){
              if(formData.AllowableFees[i].Status == true){
                propertyData.AllowableFees.push(formData.AllowableFees[i]);
              }
            }
    
            if(formData.AllowableFeesDrawStatus==true){
              var drawValue = {
                "Id": 0,
                "DrawFeesDis": formData.AllowableFeesDrawFees,
                "Notes": formData.AllowableFeesDrawNote,
                "Status": true,
                "Title": "Draw Fee",
                "Fees": formData.AllowableFeesDrawFeesDis,
              };
              propertyData.AllowableFees.push(drawValue);
            }
            if(formData.AllowableFeesMileageStatus==true){
              var mileageValue = {
                "Id": 2500,
                "Notes": formData.AllowableFeesMileageNote,
                "Status": true,
                "Title": "Mileage",
                "Fees": formData.AllowableFeesMileageFees,
              };
              propertyData.AllowableFees.push(mileageValue);
            }
            if(formData.AllowableFeesRoundMileageStatus==true){
              var roundMileageValue = {
                "Id": 3500,
                "Notes": formData.AllowableFeesRoundMileageNote,
                "Status": true,
                "Title": "Rounded Mileage",
                "Fees": formData.AllowableFeesRoundMileageFees,
              };
              propertyData.AllowableFees.push(roundMileageValue);
            }
            this._bservice.AddSOR(propertyData).subscribe(response=>{
              this.updateBidItems();
              this.sessionService.notifier.notify('success','Specification Of Repair Construction Detail added successfully.');
              this.isList=true;
              this.isAdd=false;
              this.isAllowfeeSorExpand = false;
              this.isFinalSorExpand = false;
              this.isActAdd = false;
              this.getRepairItem(this.RepairPropertyId);
             
              this.loading=false;
              
            },error=>{
                this.sessionService.notifier.notify('error',error.error.message);
                this.loading=false;
            })
    
    
    
          }
    }
  }

  updateBidItems()
  {
    
    var data={
      "EmailId" : "",
      "PropertyId" : this.RepairPropertyId,
      "PageSize" : 100000,
      "LastEvaluatedKey" : 0,
      "UserType" : this.sessionService.userType[1].Value
      }
      let GCBidList : any;
    this._bservice.GetSorDetails(data).subscribe(response=>{
      
        if(response!=null && response.Items.length > 0)
        {
         
           
            let getRepairConstructionList = response.Items[0];
            var GCSORdata={
              "PropertyId" : this.RepairPropertyId,

            }
          this._bservice.GetGCSorDetails(GCSORdata).subscribe(GCresponse=>{
            if(GCresponse!= null)
            {
            let newResponseArray:any=[];
            let alreadyGc=[];
            for(let i=0; i < this.propertyDetails.InvitedGeneralContractors.length; i++)
            {
              for(let j=0; j < GCresponse.Items.length; j++)
              {
                  if(GCresponse.Items[j].GeneralContractorId==this.propertyDetails.InvitedGeneralContractors[i])
                  {
                    if(alreadyGc.indexOf(GCresponse.Items[j].GeneralContractorId) !==-1)
                    {
                      continue;
                    }
                    newResponseArray.push(GCresponse.Items[j]);
                    alreadyGc.push(GCresponse.Items[j].GeneralContractorId);
                  }
              }
            }
            GCresponse.Items = [];
            for(let i=0;i < newResponseArray.length; i++)
            {
              GCresponse.Items.push(newResponseArray[i]);
            }
            
            GCBidList = GCresponse.Items;
            let COItems = getRepairConstructionList;
            for(let m=0; m < GCBidList.length; m++)
            {
              let GCItems = GCBidList[m].Constructions;
              let GCItemsTemp = GCBidList[m].Constructions;

            for(let j=0; j < GCItems.length; j++)
                {
                
              for(let i=0; i<COItems.Constructions.length; i++ ){
              
                if(GCItems[j].RepairType == COItems.Constructions[i].RepairType){
                 
                for(let l=0; l<GCItems[j].RepairItemDetails.length; l++ )
                    {
                     
                      let flag=false;
                  
                  for(let k=0; k<COItems.Constructions[i].RepairItemDetails.length; k++ ){
                    
                    
                  let coItemName = COItems.Constructions[i].RepairItemDetails[k].RepairItemName;
                  let gcItemName = GCItems[j].RepairItemDetails[l].RepairItemName;
                  let coItemLevel = COItems.Constructions[i].RepairItemDetails[k].Level;
                  let gcItemLevel = GCItems[j].RepairItemDetails[l].Level;
                  let coItemLocation = COItems.Constructions[i].RepairItemDetails[k].Location;
                  let gcItemLocation = GCItems[j].RepairItemDetails[l].Location;

                  let coItemMUnit = COItems.Constructions[i].RepairItemDetails[k].MaterialUnit;
                  let gcItemMUnit = GCItems[j].RepairItemDetails[l].MaterialUnit;
                  let coItemMQuantity = COItems.Constructions[i].RepairItemDetails[k].MaterialQuantity;
                  let gcItemMQuantity = GCItems[j].RepairItemDetails[l].MaterialQuantity;
                  let coItemLUnit = COItems.Constructions[i].RepairItemDetails[k].LaborUnit;
                  let gcItemLUnit = GCItems[j].RepairItemDetails[l].LaborUnit;
                  let coItemLQuantity = COItems.Constructions[i].RepairItemDetails[k].LaborQuantity;
                  let gcItemLQuantity = GCItems[j].RepairItemDetails[l].LaborQuantity;
                  if(coItemName == gcItemName && coItemLevel == gcItemLevel && coItemLocation == gcItemLocation && coItemMUnit == gcItemMUnit && coItemMQuantity == gcItemMQuantity && coItemLUnit == gcItemLUnit && coItemLQuantity == gcItemLQuantity )
                      {
                      
                      flag=true;
                      }


                    }
                    
                    if(flag==false)
                      {
                        
                        const index = GCItemsTemp[j].RepairItemDetails.indexOf(GCItemsTemp[j].RepairItemDetails[l]);
                        
                        if (index > -1) { 
                          GCItemsTemp[j].RepairItemDetails.splice(index, 1); 
                        }
                      
                      }

                      if(GCItemsTemp[j].RepairItemDetails.length < 1)
                      {
                        const indexnew = GCItemsTemp.indexOf(GCItemsTemp[j]);
                       
                        if (indexnew > -1) { 
                          GCItemsTemp.splice(indexnew, 1); 
                        
                        } 
                      }
                     
                  }
                 
                 
                }
                }
                
                
              }
              if(GCItemsTemp.length > 0 )
              {
                GCBidList[m].Constructions = GCItemsTemp;
              }
              else
              {
                GCBidList[m].Constructions = null;
              }
              
                
                this._bservice.UpdateSORForGC(GCBidList[m]).subscribe(response=>{
                 
                }
                );

          }
        }

        });
      }
    });
  }


  addItemToLibrary(index)
  {
    this.submitted=true;
    var formData=this.RepairItemForm.getRawValue();
    var isEmpty=true;
    
    for(let i=0; i< formData.RepairItemDetails.length;i++)
    {
      if(index==i)
      {
        if((formData.RepairItemDetails[i].RepairItemName!='' && formData.RepairItemDetails[i].RepairItemName!=null && formData.RepairItemDetails[i].RepairItemName!=undefined) )
        {
          isEmpty=false;
          
        }
      }
        
    }
    if(isEmpty==true)
    {
      this.sessionService.notifier.notify('error','Please fill the Repair Item Name field');
      return;
    }

    this.loading=true;
    this.submitted=false;
    this.libraryRepairConstructionDetail = "";
      
        this.libraryRepairConstructionDetail = {
                                  "ReportComponent": [],
                                  "ActivityGroup": [],
                                  "UserId":"UserId",
                                  "UserType":"UserType",
                                  "CreatedBy": "CreatedBy",
                                  "CreatedDate": "CreatedDate",
                                  "UpdateDate": "UpdateDate",
                                  "UpdatedBy": "UpdatedBy"
                                  }
    
     var ciData={"UserType":this.userDetail.UserType};
     this._ciservice.getConsultantConstructionItems(ciData).subscribe(ciresponse=>{

      if(ciresponse!=null && ciresponse.Items.length > 0)
      {
        
        this.libraryRepairConstructionDetail=ciresponse.Items[0];
      


      if(this.libraryRepairConstructionDetail.Id){
        
        var propertyData = this.libraryRepairConstructionDetail;
        var formData=this.RepairItemForm.getRawValue();
      
        let filledRepairItem=[];

        if(formData.RepairItemDetails.length > 0)
        {
        for(let i=0; i< formData.RepairItemDetails.length;i++)
        {
            if(index==i)
            {
              if((formData.RepairItemDetails[i].RepairItemName!='' && formData.RepairItemDetails[i].RepairItemName!=null && formData.RepairItemDetails[i].RepairItemName!=undefined)  )
              {
                  filledRepairItem.push(formData.RepairItemDetails[i]);
              }
           }
        }
        if(filledRepairItem.length > 0)
        {

            var data={"RepairType":formData.SendRepairType,"RepairItemDetails":filledRepairItem};
          
            this.checkConstructionsDetails = false;
            this.checkItemName =false;
            var itemLength = propertyData.ReportComponent.length;
            for(let j=0; j< itemLength; j++){
                if(propertyData.ReportComponent[j].RepairType == formData.SendRepairType){

                 let propertyDataLength= propertyData.ReportComponent[j].RepairItemDetails.length;
                  for(let l=0; l < propertyDataLength; l++ )
                  {
                  
                    for(let k=0; k< filledRepairItem.length;k++)
                    {   
                      if(propertyData.ReportComponent[j].RepairItemDetails[l].RepairItemName == filledRepairItem[k].RepairItemName && propertyData.ReportComponent[j].RepairItemDetails[l].Level == filledRepairItem[k].Level && propertyData.ReportComponent[j].RepairItemDetails[l].Location == filledRepairItem[k].Location)
                      {
                       
                        propertyData.ReportComponent[j].RepairItemDetails[l] = filledRepairItem[k];
                        this.checkItemName =true;
                      }
                     

                    }
                  }
                  if(this.checkItemName==false)
                  {
                    for(let k1=0; k1<  filledRepairItem.length;k1++)
                    {   
                      propertyData.ReportComponent[j].RepairItemDetails.push(filledRepairItem[k1]);
                    }
                   
                  }
                  this.checkConstructionsDetails = true;
                  
                }
              }
              if(this.checkConstructionsDetails == false){
                propertyData.ReportComponent.push(data);
              }
        }
      }
      else
      {
        var data={"RepairType":formData.SendRepairType,"RepairItemDetails":filledRepairItem};
        
            propertyData.ReportComponent.push(data);
          
      }

                this._ciservice.UpdateConstructionList(propertyData).subscribe(response=>{
                this.sessionService.notifier.notify('success','Item updated to library successfully.');
                this.loading=false;
                },error=>{
                    this.sessionService.notifier.notify('error',error.error.message);
                    this.loading=false;
                })
          
            

      }
    }
    else{
         
        var propertyData = this.libraryRepairConstructionDetail;
        propertyData.UserId = this.userDetail.Id;

        propertyData.UserType = this.userDetail.UserType;
        let filledRepairItem=[];
        if(formData.RepairItemDetails.length > 0)
        {
        for(let i=0; i< formData.RepairItemDetails.length;i++)
        {
          if(index==i)
          {
            if((formData.RepairItemDetails[i].RepairItemName!='' && formData.RepairItemDetails[i].RepairItemName!=null)  )
            {
                filledRepairItem.push(formData.RepairItemDetails[i]);
            }
         }
        }
        if(filledRepairItem.length > 0)
        {
            var data={"RepairType":formData.SendRepairType,"RepairItemDetails":filledRepairItem};
           
            this.checkConstructionsDetails = false;
            var itemLength = propertyData.ReportComponent.length;
            
            for(let j=0; j < itemLength; j++){
                if(propertyData.ReportComponent[j].RepairType == formData.SendRepairType){
                  propertyData.ReportComponent[j] = data;
                  this.checkConstructionsDetails = true;
                }
              }
              if(this.checkConstructionsDetails == false){
                propertyData.ReportComponent.push(data);
              }
        }
      }
      else
      {
        var data={"RepairType":formData.SendRepairType,"RepairItemDetails":filledRepairItem};
        propertyData.ReportComponent.push(data);
      }
        
        
        this._ciservice.AddConstructionList(propertyData).subscribe(response=>{
          this.sessionService.notifier.notify('success','Item added to library successfully.');
          this.loading=false;
          
        },error=>{
            this.sessionService.notifier.notify('error',error.error.message);
            this.loading=false;
        })



      }
    },error=>{
      this.sessionService.notifier.notify('error',error.error.message);
      this.loading=false;
  })



  }
  nextPage(event:PageEvent)
  {
     
  }

  

  getAllowableFees(constructionData){
    
     this.loading=true;
     this.formStatus = false;
    var data={
        "EmailId":"",
        "PageSize":100000,
        "LastEvaluatedKey":0,
        "UserType":this.sessionService.userType[0].Value
        }
      this._bservice.AllowableFeesList(data).subscribe(response=>{
          
          if(response!=null)
          {
            this.allowableFeesData=response.Items;
          if(this.allowableFeesData.length>0){
            
            if(constructionData != null){
                

              if(constructionData.AllowableFees!= null){
                if(constructionData.AllowableFees.length>0){
              
                  const setcontrol = <FormArray>this.RepairItemForm.controls['AllowableFees'];
               

                  for(let i = setcontrol.length-1; i >= 0; i--) {
                    setcontrol.removeAt(i)
                  }
                  
                  for(let i=0; i<this.allowableFeesData.length; i++ ){
                    var checkexist = false;
                    var sendData = "";
                    for(let j=0; j<constructionData.AllowableFees.length; j++ ){

                      if(constructionData.AllowableFees[j].Id == this.allowableFeesData[i].Id){
                        checkexist = true;
                        sendData = constructionData.AllowableFees[j];
                      }
                    }
                   
                    if(checkexist){
                      this.addAllowableFeesItems(sendData);
                    }else{
                      this.addAllowableFeesItems(this.allowableFeesData[i]);
                    }
                      
                  }

                  for(let j=0; j<constructionData.AllowableFees.length; j++ ){
                    
                    if(constructionData.AllowableFees[j].Id == 0){
                      this.RepairItemForm.controls['AllowableFeesDrawId'].setValue(constructionData.AllowableFees[j].Id);
                      this.RepairItemForm.controls['AllowableFeesDrawStatus'].setValue(constructionData.AllowableFees[j].Status);
                      this.RepairItemForm.controls['AllowableFeesDrawNote'].setValue(constructionData.AllowableFees[j].Notes);
                      this.RepairItemForm.controls['AllowableFeesDrawFees'].setValue(constructionData.AllowableFees[j].DrawFeesDis);
                      this.RepairItemForm.controls['AllowableFeesDrawFeesDis'].setValue(constructionData.AllowableFees[j].Fees);
                    }
                    if(constructionData.AllowableFees[j].Title == "Mileage"){
                       
                      this.RepairItemForm.controls['AllowableFeesMileageId'].setValue(constructionData.AllowableFees[j].Id);
                      this.RepairItemForm.controls['AllowableFeesMileageStatus'].setValue(constructionData.AllowableFees[j].Status);
                      this.RepairItemForm.controls['AllowableFeesMileageNote'].setValue(constructionData.AllowableFees[j].Notes);
                      this.RepairItemForm.controls['AllowableFeesMileageFees'].setValue(constructionData.AllowableFees[j].Fees);
                    
                    }else{
                      if(constructionData.AllowableFees[j].Id == 0){
                       
                        var mileageNoteFeesShow =  this.mileageDistance*this.IRSPerMileRate;
                        var mileageNoteFeesShow2 = mileageNoteFeesShow.toFixed(2);
                        var mileageNoteFees =  this.mileageDistance*this.IRSPerMileRate;
                        mileageNoteFees = mileageNoteFees*constructionData.AllowableFees[j].Notes;
                        let mileageNoteFees2:any;
                        if(this.miles > 0)
                        {
                             mileageNoteFees2 = mileageNoteFees.toFixed(2);
                        }
                        else
                        {
                            mileageNoteFees2 =0.00;
                        }
                        
                      
                        var mileageNote = parseFloat(this.mileageDistance).toFixed(2) +" miles x "+this.IRSPerMileRate+" = "+mileageNoteFeesShow2+" x "+constructionData.AllowableFees[j].Notes;
                        this.RepairItemForm.controls['AllowableFeesMileageId'].setValue(2500);
                        this.RepairItemForm.controls['AllowableFeesMileageStatus'].setValue(false);
                        this.RepairItemForm.controls['AllowableFeesMileageNote'].setValue(mileageNote);
                        this.RepairItemForm.controls['AllowableFeesMileageFees'].setValue(mileageNoteFees2);
                      }else{
                        this.setMileageValue();
                      }
                      
                    }

                    if(constructionData.AllowableFees[j].Title == "Rounded Mileage"){
                       
                      this.RepairItemForm.controls['AllowableFeesRoundMileageId'].setValue(constructionData.AllowableFees[j].Id);
                      this.RepairItemForm.controls['AllowableFeesRoundMileageStatus'].setValue(constructionData.AllowableFees[j].Status);
                      this.RepairItemForm.controls['AllowableFeesRoundMileageNote'].setValue(constructionData.AllowableFees[j].Notes);
                      this.RepairItemForm.controls['AllowableFeesRoundMileageFees'].setValue(constructionData.AllowableFees[j].Fees);
                    }else{
                      if(constructionData.AllowableFees[j].Id == 0){
                        
                        var mileageNoteFeesShow =  this.mileageDistance*this.IRSPerMileRate;
                        var mileageNoteFeesShow2 = mileageNoteFeesShow.toFixed(2);
                        var mileageNoteFees =  this.mileageDistance*this.IRSPerMileRate;
                        mileageNoteFees = mileageNoteFees*constructionData.AllowableFees[j].Notes;
                        let mileageNoteFees3:any;
                        if(this.miles > 0)
                        {
                             mileageNoteFees3 = Math.round(mileageNoteFees);
                        }
                        else
                        {
                            mileageNoteFees3 =0.00;
                        }
                        
                        var mileageNote = parseFloat(this.mileageDistance).toFixed(2) +" miles x "+this.IRSPerMileRate+" = "+mileageNoteFeesShow2+" x "+constructionData.AllowableFees[j].Notes;
                        this.RepairItemForm.controls['AllowableFeesRoundMileageId'].setValue(3500);
                        this.RepairItemForm.controls['AllowableFeesRoundMileageStatus'].setValue(false);
                        this.RepairItemForm.controls['AllowableFeesRoundMileageNote'].setValue(mileageNote);
                        this.RepairItemForm.controls['AllowableFeesRoundMileageFees'].setValue(mileageNoteFees3);
                      }else{
                         
                        this.setRoundMileageValue();
                      }
                      
                    }
                  }
                  
                }
                else{
                   
                      for(let i=0; i<this.allowableFeesData.length; i++ ){
                        
                        this.addAllowableFeesItems(this.allowableFeesData[i]);
                      }
                      this.setMileageValue();
                     
                      this.setRoundMileageValue();
                }
              }else{
                 
                
                for(let i=0; i<this.allowableFeesData.length; i++ ){
                    
                  this.addAllowableFeesItems(this.allowableFeesData[i]);
                }
                this.setMileageValue();
               
                this.setRoundMileageValue();
              }
            }else{
             
              
              for(let i=0; i<this.allowableFeesData.length; i++ ){
                
                this.addAllowableFeesItems(this.allowableFeesData[i]); 
              }
              this.setMileageValue();
              
              this.setRoundMileageValue();
            }
          }
           
          this.loading=false;
          this.formStatus = true;
          }
          this.formStatus = true;
      },error=>{

      })
  }


  setMileageValue(){
    var mileageNoteFeesShow =  this.mileageDistance*this.IRSPerMileRate;
    let mileageNoteFees:any;
    var mileageNoteFeesShow2 = mileageNoteFeesShow.toFixed(2);
    
    if(this.RepairItemForm.controls.AllowableFeesDrawStatus.value)
    {
        let note= this.RepairItemForm.controls.AllowableFeesDrawNote.value;
        if(note!="")
        {
            note=note;
        }
        else
        {
            note=1;
        }
        if(this.miles > 0)
        {
             mileageNoteFees =  ((this.mileageDistance*this.IRSPerMileRate)*note).toFixed(2);
        }
        else
        {
            mileageNoteFees =0.00;
        }
        
        var mileageNote = parseFloat(this.mileageDistance).toFixed(2) +" miles x "+this.IRSPerMileRate+" = "+mileageNoteFeesShow2+" x "+note;
        this.RepairItemForm.controls['AllowableFeesMileageStatus'].setValue(this.RepairItemForm.controls.AllowableFeesMileageStatus.value);
    }
    else
    {
        
        if(this.miles > 0)
        {
             mileageNoteFees =  (this.mileageDistance*this.IRSPerMileRate).toFixed(2) ;
        }
        else
        {
            mileageNoteFees =0.00;
        }

        var mileageNote = parseFloat(this.mileageDistance).toFixed(2) +" miles x "+this.IRSPerMileRate+" = "+mileageNoteFeesShow2+" x 1";
        this.RepairItemForm.controls['AllowableFeesMileageStatus'].setValue(false);
    }
    
    this.RepairItemForm.controls['AllowableFeesMileageId'].setValue(2500);
    
    this.RepairItemForm.controls['AllowableFeesMileageNote'].setValue(mileageNote);
    this.RepairItemForm.controls['AllowableFeesMileageFees'].setValue(mileageNoteFees);
    
  }

  setRoundMileageValue(){
    var mileageNoteFeesShow =  this.mileageDistance*this.IRSPerMileRate;
    var mileageNoteFees3:any;
    var mileageNoteFeesShow2 = mileageNoteFeesShow.toFixed(2);
   
    if(this.RepairItemForm.controls.AllowableFeesDrawStatus.value)
    {
        let note= this.RepairItemForm.controls.AllowableFeesDrawNote.value;
        if(note!="")
        {
            note=note;
        }
        else
        {
            note=1;
        }
        var mileageNoteFees =  (this.mileageDistance*this.IRSPerMileRate)*note;
        if(this.miles > 0)
        {
             mileageNoteFees3 = Math.round(mileageNoteFees);
        }
        else{
            mileageNoteFees3 =0.00;
        }
        
        var mileageNote = parseFloat(this.mileageDistance).toFixed(2)+" miles x "+this.IRSPerMileRate+" = "+mileageNoteFeesShow2+" x "+note;
        this.RepairItemForm.controls['AllowableFeesRoundMileageStatus'].setValue(this.RepairItemForm.controls.AllowableFeesRoundMileageStatus.value);
    
    }
    else
    {
        var mileageNoteFees =  this.mileageDistance*this.IRSPerMileRate;
        if(this.miles > 0)
        {
             mileageNoteFees3 = Math.round(mileageNoteFees);
        }
        else
        {
            mileageNoteFees3 =0.00;
        }
        
        var mileageNote = parseFloat(this.mileageDistance).toFixed(2)+" miles x "+this.IRSPerMileRate+" = "+mileageNoteFeesShow2+" x 1";
        this.RepairItemForm.controls['AllowableFeesRoundMileageStatus'].setValue(false);
    }
   

    this.RepairItemForm.controls['AllowableFeesRoundMileageId'].setValue(3500);
    
    this.RepairItemForm.controls['AllowableFeesRoundMileageNote'].setValue(mileageNote);
    this.RepairItemForm.controls['AllowableFeesRoundMileageFees'].setValue(mileageNoteFees3);
  }



  getPropertyDistance (calback) {
    
    this.propertyService.GetPropertyDetails(this.RepairPropertyId).subscribe(proResponse=>{
 
        if(proResponse!=null)
        {
            const address1=(proResponse.InspectionAddress.address1)? proResponse.InspectionAddress.address1+', ' : '';
            const address2=(proResponse.InspectionAddress.address2)? proResponse.InspectionAddress.address2+', ' : '';
            const city=(proResponse.InspectionAddress.city)? proResponse.InspectionAddress.city+', ' : '';
            const state=(proResponse.InspectionAddress.state)? proResponse.InspectionAddress.state+', ' : '';
            const zipcode=(proResponse.InspectionAddress.zipcode)? proResponse.InspectionAddress.zipcode : '';
          const setAddress =  address1 + address2 + city + state + zipcode;
          const data = {
            "propertyAddress":setAddress,
            "type":proResponse.LoanType
          };

          this.propertyService.GetPropertyDistance(data).subscribe(disResponse=>{
            
              if (disResponse!=null)
              {
                
                let data=[];
                data['distance']=parseFloat(disResponse.KiloMeters).toFixed(2);
                data['singlesidemiles']=parseFloat(disResponse.singlesidemiles).toFixed(2);
                data['miles']=disResponse.miles;
                data['IRSPerMileRate']=disResponse.IRSPerMileRate;
                  calback(data);
              }
          },error=>{
            console.log('Distance Error : '+error.error.message);
          })
        }
      
    },error=>{

    })
    
  }

  

  setMileageValueFromDraw(){
      var formData=this.RepairItemForm.value;
      var mileageNoteFeesShow =  this.mileageDistance*this.IRSPerMileRate;
      var mileageNoteFeesShow2 = mileageNoteFeesShow.toFixed(2);
      var mileageNoteFees =  this.mileageDistance*this.IRSPerMileRate;
      mileageNoteFees = mileageNoteFees*formData.AllowableFeesDrawNote;
      let mileageNoteFees2:any;
      if(this.miles > 0)
      {
         mileageNoteFees2 = mileageNoteFees.toFixed(2);
      }
      else
      {
        mileageNoteFees2 =0.00;
      }
      
      var mileageNote = parseFloat(this.mileageDistance).toFixed(2) +" miles x "+this.IRSPerMileRate+" = "+mileageNoteFeesShow2+" x "+formData.AllowableFeesDrawNote;
      this.RepairItemForm.controls['AllowableFeesMileageId'].setValue(2500);
    
      this.RepairItemForm.controls['AllowableFeesMileageNote'].setValue(mileageNote);
      this.RepairItemForm.controls['AllowableFeesMileageFees'].setValue(mileageNoteFees2);
      var drawValue = formData.AllowableFeesDrawNote*formData.AllowableFeesDrawFees;
      this.RepairItemForm.controls['AllowableFeesDrawFeesDis'].setValue([drawValue]);
  }

  setRoundMileageValueFromDraw(){
      var formData=this.RepairItemForm.value;
      var mileageNoteFeesShow =  this.mileageDistance*this.IRSPerMileRate;
      var mileageNoteFeesShow2 = mileageNoteFeesShow.toFixed(2);
      var mileageNoteFees =  this.mileageDistance*this.IRSPerMileRate;
      mileageNoteFees = mileageNoteFees*formData.AllowableFeesDrawNote;
      let mileageNoteFees2:any;
      if(this.miles > 0)
      {
         mileageNoteFees2 = Math.round(mileageNoteFees);
      }
      else
      {
        mileageNoteFees2 =0.00;
      }
      
      var mileageNote = parseFloat(this.mileageDistance).toFixed(2) +" miles x "+this.IRSPerMileRate+" = "+mileageNoteFeesShow2+" x "+formData.AllowableFeesDrawNote;
      this.RepairItemForm.controls['AllowableFeesRoundMileageId'].setValue(3500);
    
      this.RepairItemForm.controls['AllowableFeesRoundMileageNote'].setValue(mileageNote);
      this.RepairItemForm.controls['AllowableFeesRoundMileageFees'].setValue(mileageNoteFees2);
      var drawValue = formData.AllowableFeesDrawNote*formData.AllowableFeesDrawFees;
      this.RepairItemForm.controls['AllowableFeesDrawFeesDis'].setValue([drawValue]);
  }
  

  initAllowableFeesItem(data) {
    // initialize our address
    var status = true;
    if(data.Status != undefined){
      status = data.Status;
    }else{
      status = false;
    }
    
    return this._fb.group({
      Id:[data.Id],
      Title:[data.Title],
      Notes:[data.Notes],
      Fees:[data.Fees],
      Status:[status]
    });
  }

  addAllowableFeesItems(data) {
    if(data.Title=="Draw Fee")
    {
      return;
    }
      // add address to the list
      const control = <FormArray>this.RepairItemForm.controls['AllowableFees'];
      control.push(this.initAllowableFeesItem(data));
  }


  getpropertyDetails(RepairPropertyId){

    
    this.propertyService.GetPropertyDetails(RepairPropertyId).subscribe(proResponse=>{
       
     
      if(proResponse!=null)
      {
            const address1=(proResponse.InspectionAddress.address1)? proResponse.InspectionAddress.address1+', ' : '';
            const address2=(proResponse.InspectionAddress.address2)? proResponse.InspectionAddress.address2+', ' : '';
            const city=(proResponse.InspectionAddress.city)? proResponse.InspectionAddress.city+', ' : '';
            const state=(proResponse.InspectionAddress.state)? proResponse.InspectionAddress.state+', ' : '';
            const zipcode=(proResponse.InspectionAddress.zipcode)? proResponse.InspectionAddress.zipcode : '';
          const setAddress =  address1 + address2 + city + state + zipcode;

        this.propertyCDetails = proResponse;
        this.propertyDetails = proResponse;
        
      

        this.loading = false;
        if(((proResponse.BorrowerId) != null) && ((proResponse.BorrowerId) != undefined) && ((proResponse.BorrowerId) != '')){
            this.propertyService.GetUserDetailsList(proResponse.BorrowerId).subscribe(borrowerResponse => {

          if (borrowerResponse != null)
          {
            this.borrowerDetails = borrowerResponse;
          }

        }, error => {

        })
      }
      }
    
  },error=>{
  })
  }



  getRepairItem(RepairPropertyId){
     
    this.loading=true;
    var data={
        "EmailId":"",
        "PropertyId":RepairPropertyId,
        "PageSize":100000,
        "LastEvaluatedKey":0,
        "UserType":this.sessionService.userType[1].Value
        }
      this._bservice.GetSorDetails(data).subscribe(response=>{
           
         
          if(response!=null)
          {
            
              this.getRepairConstructionList = response.Items;
            
              if(response.Items.length >0)
              {
                this.isSORExist=true;
                if(response.Items[0].MailSend)
                {
                  this.isResend = true;
                }
                
                this.sorId=response.Items[0].Id;
              }
                
              if(this.getRepairConstructionList.length>0){
                 
                this.userList.Count = 36;
                this.userList.ScannedCount = 36;
                this.userList.TotalRecord = 36;      
                this.userList.Items=[
                  {
                    "id":1,
                    "RepairType" : "Masonry",
                    "RepairTotal" : "0.00",
                    "Operation":"Active"
                  },
                  {
                    "id":2,
                    "RepairType" : "Siding",
                    "RepairTotal" : "0.00",
                    "Operation":"Active"
                  },
                  {
                    "id":3,
                    "RepairType" : "Gutters/Downspouts",
                    "RepairTotal" : "0.00",
                    "Operation":"Active"
                  },
                  {
                    "id":4,
                    "RepairType" : "Roof",
                    "RepairTotal" : "0.00",
                    "Operation":"Active"
                  },
                  {
                    "id":5,
                    "RepairType" : "Shutters",
                    "RepairTotal" : "0.00",
                    "Operation":"Active"
                  },
                  {
                    "id":6,
                    "RepairType" : "Exteriors",
                    "RepairTotal" : "0.00",
                    "Operation":"Active"
                  },
                  {
                    "id":7,
                    "RepairType" : "Walks",
                    "RepairTotal" : "0.00",
                    "Operation":"Active"
                  },
                  {
                    "id":8,
                    "RepairType" : "Driveways",
                    "RepairTotal" : "0.00",
                    "Operation":"Active"
                  },
                  {
                    "id":9,
                    "RepairType" : "Painting (Ext)",
                    "RepairTotal" : "0.00",
                    "Operation":"Active"
                  },
                  {
                    "id":10,
                    "RepairType" : "Caulking",
                    "RepairTotal" : "0.00",
                    "Operation":"Active"
                  },
                  {
                    "id":11,
                    "RepairType" : "Fencing",
                    "RepairTotal" : "0.00",
                    "Operation":"Active"
                  },
                  {
                    "id":12,
                    "RepairType" : "Grading",
                    "RepairTotal" : "0.00",
                    "Operation":"Active"
                  },
                  {
                    "id":13,
                    "RepairType" : "Windows",
                    "RepairTotal" : "0.00",
                    "Operation":"Active"
                  },
                  {
                    "id":14,
                    "RepairType" : "Weatherstrip",
                    "RepairTotal" : "0.00",
                    "Operation":"Active"
                  },
                  {
                    "id":15,
                    "RepairType" : "Doors (Ext)	",
                    "RepairTotal" : "0.00",
                    "Operation":"Active"
                  },
                  {
                    "id":16,
                    "RepairType" : "Doors (Int)	",
                    "RepairTotal" : "0.00",
                    "Operation":"Active"
                  },
                  {
                    "id":17,
                    "RepairType" : "Partition Wall",
                    "RepairTotal" : "0.00",
                    "Operation":"Active"
                  },
                  {
                    "id":18,
                    "RepairType" : "Plaster/Drywall",
                    "RepairTotal" : "0.00",
                    "Operation":"Active"
                  },
                  {
                    "id":19,
                    "RepairType" : "Decorating",
                    "RepairTotal" : "0.00",
                    "Operation":"Active"
                  },
                  {
                    "id":20,
                    "RepairType" : "Wood Trim	",
                    "RepairTotal" : "0.00",
                    "Operation":"Active"
                  },
                  {
                    "id":21,
                    "RepairType" : "Stairs",
                    "RepairTotal" : "0.00",
                    "Operation":"Active"
                  },
                  {
                    "id":22,
                    "RepairType" : "Closets",
                    "RepairTotal" : "0.00",
                    "Operation":"Active"
                  },
                  {
                    "id":23,
                    "RepairType" : "Wood Floors",
                    "RepairTotal" : "0.00",
                    "Operation":"Active"
                  },
                  {
                    "id":24,
                    "RepairType" : "Finished Floors",
                    "RepairTotal" : "0.00",
                    "Operation":"Active"
                  },
                  {
                    "id":25,
                    "RepairType" : "Ceramic Tile",
                    "RepairTotal" : "0.00",
                    "Operation":"Active"
                  },
                  {
                    "id":26,
                    "RepairType" : "Bath Accesories",
                    "RepairTotal" : "0.00",
                    "Operation":"Active"
                  },
                  {
                    "id":27,
                    "RepairType" : "Plumbing",
                    "RepairTotal" : "0.00",
                    "Operation":"Active"
                  },
                  {
                    "id":28,
                    "RepairType" : "Electrical",
                    "RepairTotal" : "0.00",
                    "Operation":"Active"
                  },
                  {
                    "id":29,
                    "RepairType" : "Heating",
                    "RepairTotal" : "0.00",
                    "Operation":"Active"
                  },
                  {
                    "id":30,
                    "RepairType" : "Insulation",
                    "RepairTotal" : "0.00",
                    "Operation":"Active"
                  },
                  {
                    "id":31,
                    "RepairType" : "Cabinetry",
                    "RepairTotal" : "0.00",
                    "Operation":"Active"
                  },
                  {
                    "id":32,
                    "RepairType" : "Appliances",
                    "RepairTotal" : "0.00",
                    "Operation":"Active"
                  },
                  {
                    "id":33,
                    "RepairType" : "Basements",
                    "RepairTotal" : "0.00",
                    "Operation":"Active"
                  },
                  {
                    "id":34,
                    "RepairType" : "Cleanup",
                    "RepairTotal" : "0.00",
                    "Operation":"Active"
                  },
                  {
                    "id":35,
                    "RepairType" : "Miscellaneous",
                    "RepairTotal" : "0.00",
                    "Operation":"Active"
                  },
                  {
                    "id":36,
                    "RepairType" : "Grand Total",
                    "RepairTotal" : "0.00",
                    "Operation":"Inactive"
                  }
                  

              ];

                var setItem = this.getRepairConstructionList[0];
                if(this.isFinalSOR==true)
                {
                    var GCSORdata={};
                
                    GCSORdata={
                        "PropertyId":RepairPropertyId,
                        "GeneralContractorId":this.GcId
                      }
               
                
                    
                this._bservice.GetGCSorDetails(GCSORdata).subscribe(GCresponse=>{
                     
                  if(GCresponse != null && GCresponse.Items.length>0){
                    this.gcBidId = GCresponse.Items[0].Id;
                    this.getRepairConstructionList = GCresponse.Items;
                    var setItemGC = GCresponse.Items[0];

                    var _this = this;
                     _this.getPropertyDistance(function(distance){
                    if(distance == undefined || distance == null)
                    {
                        distance = 0;
                    }
                                     
                  _this.miles = distance['miles'];
                  _this.IRSPerMileRate = distance['IRSPerMileRate'];
                  if(_this.miles > 0)
                  {
                    _this.mileageDistance = distance['distance'];
                  }
                  else
                  {
                    _this.mileageDistance = distance['singlesidemiles'];
                  }
                  
                  if(Array.isArray(setItemGC.AllowableFees))
                  {
                    _this.getAllowableFees(setItemGC);
                  }
                  else
                  {
                    _this.getAllowableFees(setItem);
                  }
                  
                })
               
                this.RepairItemForm.controls['BorrowerPhone'].setValue(setItem.BorrowerPhone);
                this.RepairItemForm.controls['FHACase'].setValue(setItem.FHACase);


                this.RepairItemForm.controls['OccupiedDuringConstruction'].setValue(setItemGC.OccupiedDuringConstruction?setItemGC.OccupiedDuringConstruction:setItem.OccupiedDuringConstruction);
                this.RepairItemForm.controls['ContingencyReservePercentage'].setValue(setItemGC.ContingencyReservePercentage?setItemGC.ContingencyReservePercentage:setItem.ContingencyReservePercentage);
                this.RepairItemForm.controls['MonthToCompletion'].setValue(setItemGC.MonthToCompletion?setItemGC.MonthToCompletion:setItem.MonthToCompletion);
                this.RepairItemForm.controls['LoanNo'].setValue(setItemGC.LoanNo?setItemGC.LoanNo:setItem.LoanNo);
                this.RepairItemForm.controls['ConsultantComment'].setValue(setItemGC.ConsultantComment?setItemGC.ConsultantComment:setItem.ConsultantComment);
                if(setItemGC.InspectionDate !='' && setItemGC.InspectionDate!=null && setItemGC.InspectionDate!=undefined)
                {
                    this.RepairItemForm.controls['InspectionDate'].setValue(setItemGC.InspectionDate);
                }
                else if(setItem.InspectionDate !='' && setItem.InspectionDate!=null && setItem.InspectionDate!=undefined)
                {
                  this.RepairItemForm.controls['InspectionDate'].setValue(setItem.InspectionDate);
                }
                else
                {

                this.propertyService.GetPropertyDetails(RepairPropertyId).subscribe(proResponse=>{
                    
                  if(proResponse!=null)
                  {
                    
                    this.RepairItemForm.controls['InspectionDate'].setValue(proResponse.InspectionDate);
                   
                  }
                
              },error=>{
        
              })
                }
                
                this.RepairItemForm.controls['InspectionAddress'].setValue(setItem.InspectionAddress);
               
                if(setItemGC.Constructions.length>0){
                  let totalCost = 0;
                  for(let i=0; i<setItemGC.Constructions.length; i++ ){
                    if(setItemGC.Constructions.length>0){
                      for(let j=0; j<this.userList.Items.length; j++ ){

                        if(this.userList.Items[j].RepairType == setItemGC.Constructions[i].RepairType){
                          let subTotalCost = 0;
                          for(let k=0; k<setItemGC.Constructions[i].RepairItemDetails.length; k++ ){
                              if((setItemGC.Constructions[i].RepairItemDetails[k].LaborUnitCost!=''&& setItemGC.Constructions[i].RepairItemDetails[k].LaborUnitCost!=null && setItemGC.Constructions[i].RepairItemDetails[k].LaborUnitCost!=undefined) && (setItemGC.Constructions[i].RepairItemDetails[k].LaborQuantity!=''&& setItemGC.Constructions[i].RepairItemDetails[k].LaborQuantity!=null && setItemGC.Constructions[i].RepairItemDetails[k].LaborQuantity!=undefined) && (setItemGC.Constructions[i].RepairItemDetails[k].MaterialUnitCost!=''&& setItemGC.Constructions[i].RepairItemDetails[k].MaterialUnitCost!=null && setItemGC.Constructions[i].RepairItemDetails[k].MaterialUnitCost!=undefined) && (setItemGC.Constructions[i].RepairItemDetails[k].MaterialQuantity!=''&& setItemGC.Constructions[i].RepairItemDetails[k].MaterialQuantity!=null && setItemGC.Constructions[i].RepairItemDetails[k].MaterialQuantity!=undefined))
                                {
                            subTotalCost += (parseFloat(setItemGC.Constructions[i].RepairItemDetails[k].LaborUnitCost)*
                            parseFloat(setItemGC.Constructions[i].RepairItemDetails[k].LaborQuantity))
                            +(parseFloat(setItemGC.Constructions[i].RepairItemDetails[k].MaterialUnitCost)*
                            parseFloat(setItemGC.Constructions[i].RepairItemDetails[k].MaterialQuantity));
                                }
                          }
                          
                          if(subTotalCost==0)
                          {
                           
                            this.userList.Items[j].RepairTotal = '0.00';
                          
                          }
                          else
                          {
                            
                            this.userList.Items[j].RepairTotal = subTotalCost;
                            
                          }
                        
                          totalCost += subTotalCost;
                          
                        }
                      }
                    }else{

                    }
                  }
                
                  if(totalCost==0)
                          {
                            this.userList.Items[35].RepairTotal = '0.00';
                          }
                          else
                          {
                            this.userList.Items[35].RepairTotal = totalCost;
                          }
                 
                }
                
                this.permitCostTypeData = setItemGC.PermitCost;
                    
                    const control = <FormArray>this.RepairItemForm.controls['PermitCost'];
                    
                    for(let i = control.length-1; i >= 0; i--) {
                      control.removeAt(i)
                    }
                          
                    for(let j=0; j<this.permitCostTypeData.length;j++){
                      control.push(this._fb.group({
                        PermitType:[this.permitCostTypeData[j].PermitType],
                        PermitCost:[this.permitCostTypeData[j].PermitCost],
                        Status:[this.permitCostTypeData[j].Status]
                      }));
                      
                    }
                const users = Array.from({length: this.userList.Count}, (_, k) => createNewUser(k,this.userList));
                this.dataSource = new MatTableDataSource(users);
                this.dataSource.paginator = this.paginator;
                this.dataSource.sort = this.sort;
                 this.loading=false;
                  }
                  else
                  {
                    
                    var _this = this;
                    _this.getPropertyDistance(function(distance){
                        if(distance == undefined || distance == null)
                        {
                            distance = 0;
                        }
                        
                       
                        _this.miles = distance['miles'];
                        _this.IRSPerMileRate = distance['IRSPerMileRate'];
                        if(_this.miles > 0)
                        {
                            _this.mileageDistance = distance['distance'];
                        }
                        else
                        {
                            _this.mileageDistance = distance['singlesidemiles'];
                        }
                      _this.getAllowableFees(setItem);
                    })
                    this.RepairItemForm.controls['OccupiedDuringConstruction'].setValue(setItem.OccupiedDuringConstruction);
                    this.RepairItemForm.controls['BorrowerPhone'].setValue(setItem.BorrowerPhone);
                    this.RepairItemForm.controls['FHACase'].setValue(setItem.FHACase);
                    this.RepairItemForm.controls['ContingencyReservePercentage'].setValue(setItem.ContingencyReservePercentage);
                    this.RepairItemForm.controls['MonthToCompletion'].setValue(setItem.MonthToCompletion);
                    this.RepairItemForm.controls['LoanNo'].setValue(setItem.LoanNo);
                    if(setItem.InspectionDate !='' && setItem.InspectionDate!=null && setItem.InspectionDate!=undefined)
                    {
                        this.RepairItemForm.controls['InspectionDate'].setValue(setItem.InspectionDate);
                    }
                    else
                    {
    
                    this.propertyService.GetPropertyDetails(RepairPropertyId).subscribe(proResponse=>{
                        
                      if(proResponse!=null)
                      {
                        
                        this.RepairItemForm.controls['InspectionDate'].setValue(proResponse.InspectionDate);
                       
                      }
                    
                  },error=>{
            
                  })
                    }
                    
                    this.RepairItemForm.controls['InspectionAddress'].setValue(setItem.InspectionAddress);
                    this.RepairItemForm.controls['ConsultantComment'].setValue(setItem.ConsultantComment);
                   
                      var totalCost = 0
                      for(let i=0; i<setItem.Constructions.length; i++ ){
                        
                          for(let j=0; j<this.userList.Items.length; j++ ){
    
                            if(this.userList.Items[j].RepairType == setItem.Constructions[i].RepairType){
                              var subTotalCost = 0;
                              for(let k=0; k<setItem.Constructions[i].RepairItemDetails.length; k++ ){
                                if((setItem.Constructions[i].RepairItemDetails[k].LaborUnitCost!=''&& setItem.Constructions[i].RepairItemDetails[k].LaborUnitCost!=null && setItem.Constructions[i].RepairItemDetails[k].LaborUnitCost!=undefined) && (setItem.Constructions[i].RepairItemDetails[k].LaborQuantity!=''&& setItem.Constructions[i].RepairItemDetails[k].LaborQuantity!=null && setItem.Constructions[i].RepairItemDetails[k].LaborQuantity!=undefined) && (setItem.Constructions[i].RepairItemDetails[k].MaterialUnitCost!=''&& setItem.Constructions[i].RepairItemDetails[k].MaterialUnitCost!=null && setItem.Constructions[i].RepairItemDetails[k].MaterialUnitCost!=undefined) && (setItem.Constructions[i].RepairItemDetails[k].MaterialQuantity!=''&& setItem.Constructions[i].RepairItemDetails[k].MaterialQuantity!=null && setItem.Constructions[i].RepairItemDetails[k].MaterialQuantity!=undefined))
                                {
                                subTotalCost += (parseFloat(setItem.Constructions[i].RepairItemDetails[k].LaborUnitCost)*
                                parseFloat(setItem.Constructions[i].RepairItemDetails[k].LaborQuantity))
                                +(parseFloat(setItem.Constructions[i].RepairItemDetails[k].MaterialUnitCost)*
                                parseFloat(setItem.Constructions[i].RepairItemDetails[k].MaterialQuantity));
                                }
                              }
                              
                              if(subTotalCost==0)
                          {
                            this.userList.Items[j].RepairTotal = '0.00';
                          }
                          else
                          {
                            this.userList.Items[j].RepairTotal = subTotalCost;
                          }
                           
                              totalCost += subTotalCost;
                              
                            }
                          }
                        
                      }
                     
                      if(totalCost==0)
                          {
                            this.userList.Items[35].RepairTotal = '0.00';
                          }
                          else
                          {
                            this.userList.Items[35].RepairTotal = totalCost;
                          }
                      
                    
                    const users = Array.from({length: this.userList.Count}, (_, k) => createNewUser(k,this.userList));
                    this.dataSource = new MatTableDataSource(users);
                    this.dataSource.paginator = this.paginator;
                    this.dataSource.sort = this.sort;
                     this.loading=false;
                  }


                });
                }
                else
                {
                    var _this = this;
                   
                    _this.getPropertyDistance(function(distance){
                        if(distance == undefined || distance == null)
                        {
                            distance = 0;
                        }
                        
                       
                        _this.miles = distance['miles'];
                        _this.IRSPerMileRate = distance['IRSPerMileRate'];
                        if(_this.miles > 0)
                        {
                            _this.mileageDistance = distance['distance'];
                        }
                        else
                        {
                            _this.mileageDistance = distance['singlesidemiles'];
                        }
                      _this.getAllowableFees(setItem);
                    })
                    this.RepairItemForm.controls['OccupiedDuringConstruction'].setValue(setItem.OccupiedDuringConstruction);
                    this.RepairItemForm.controls['BorrowerPhone'].setValue(setItem.BorrowerPhone);
                    this.RepairItemForm.controls['FHACase'].setValue(setItem.FHACase);
                    this.RepairItemForm.controls['ContingencyReservePercentage'].setValue(setItem.ContingencyReservePercentage);
                    this.RepairItemForm.controls['MonthToCompletion'].setValue(setItem.MonthToCompletion);
                    this.RepairItemForm.controls['LoanNo'].setValue(setItem.LoanNo);
                    if(setItem.InspectionDate !='' && setItem.InspectionDate!=null && setItem.InspectionDate!=undefined)
                    {
                        this.RepairItemForm.controls['InspectionDate'].setValue(setItem.InspectionDate);
                    }
                    else
                    {
    
                    this.propertyService.GetPropertyDetails(RepairPropertyId).subscribe(proResponse=>{
                        
                      if(proResponse!=null)
                      {
                        
                        this.RepairItemForm.controls['InspectionDate'].setValue(proResponse.InspectionDate);
                       
                      }
                    
                  },error=>{
            
                  })
                    }
                    
                    this.RepairItemForm.controls['InspectionAddress'].setValue(setItem.InspectionAddress);
                    this.RepairItemForm.controls['ConsultantComment'].setValue(setItem.ConsultantComment);
                    if(setItem.Constructions.length>0){
                      let totalCost = 0
                      for(let i=0; i<setItem.Constructions.length; i++ ){
                        if(setItem.Constructions.length>0){
                          for(let j=0; j<this.userList.Items.length; j++ ){
    
                            if(this.userList.Items[j].RepairType == setItem.Constructions[i].RepairType){
                              let subTotalCost = 0;
                              for(let k=0; k<setItem.Constructions[i].RepairItemDetails.length; k++ ){
                                if((setItem.Constructions[i].RepairItemDetails[k].LaborUnitCost!=''&& setItem.Constructions[i].RepairItemDetails[k].LaborUnitCost!=null && setItem.Constructions[i].RepairItemDetails[k].LaborUnitCost!=undefined) && (setItem.Constructions[i].RepairItemDetails[k].LaborQuantity!=''&& setItem.Constructions[i].RepairItemDetails[k].LaborQuantity!=null && setItem.Constructions[i].RepairItemDetails[k].LaborQuantity!=undefined) && (setItem.Constructions[i].RepairItemDetails[k].MaterialUnitCost!=''&& setItem.Constructions[i].RepairItemDetails[k].MaterialUnitCost!=null && setItem.Constructions[i].RepairItemDetails[k].MaterialUnitCost!=undefined) && (setItem.Constructions[i].RepairItemDetails[k].MaterialQuantity!=''&& setItem.Constructions[i].RepairItemDetails[k].MaterialQuantity!=null && setItem.Constructions[i].RepairItemDetails[k].MaterialQuantity!=undefined))
                                {
                                subTotalCost += (parseFloat(setItem.Constructions[i].RepairItemDetails[k].LaborUnitCost)*
                                parseFloat(setItem.Constructions[i].RepairItemDetails[k].LaborQuantity))
                                +(parseFloat(setItem.Constructions[i].RepairItemDetails[k].MaterialUnitCost)*
                                parseFloat(setItem.Constructions[i].RepairItemDetails[k].MaterialQuantity));
                                }
                              }
                             
                              if(subTotalCost==0)
                          {
                            
                            this.userList.Items[j].RepairTotal = '0.00';
                            
                          }
                          else
                          {
                           
                            this.userList.Items[j].RepairTotal = subTotalCost;
                            
                          }
                              
                              totalCost += subTotalCost;
                              
                            }
                          }
                        }else{
    
                        }
                      }
                     
                      if(totalCost==0)
                      {
                      
                        this.userList.Items[35].RepairTotal = '0.00';
                        
                      }
                      else
                      {
                       
                        this.userList.Items[35].RepairTotal = totalCost;
                        
                      }
                      
                    }
                    const users = Array.from({length: this.userList.Count}, (_, k) => createNewUser(k,this.userList));
                    this.dataSource = new MatTableDataSource(users);
                    this.dataSource.paginator = this.paginator;
                    this.dataSource.sort = this.sort;
                    this.loading=false;
                }
                
                
              }else{
                  
                this.propertyService.GetPropertyDetails(RepairPropertyId).subscribe(proResponse=>{
                    
                  if(proResponse!=null)
                  {
                    const address1=(proResponse.InspectionAddress.address1)? proResponse.InspectionAddress.address1+', ' : '';
                    const address2=(proResponse.InspectionAddress.address2)? proResponse.InspectionAddress.address2+', ' : '';
                    const city=(proResponse.InspectionAddress.city)? proResponse.InspectionAddress.city+', ' : '';
                    const state=(proResponse.InspectionAddress.state)? proResponse.InspectionAddress.state+', ' : '';
                    const zipcode=(proResponse.InspectionAddress.zipcode)? proResponse.InspectionAddress.zipcode : '';
                    const setAddress =  address1 + address2 + city + state + zipcode;

                    this.RepairItemForm.controls['FHACase'].setValue(proResponse.FHACaseNo);
                  
                    this.RepairItemForm.controls['InspectionAddress'].setValue(setAddress);

                    this._bservice.GetBorrowerDetails(proResponse.BorrowerId).subscribe(BOResponse=>{
                      
                   
                      if(BOResponse!=null)
                      {
                        this.RepairItemForm.controls['BorrowerPhone'].setValue(BOResponse.Phone);
                      }
                    
                  },error=>{
            
                  })
                  }
                
              },error=>{
        
              })
             var _this_1=this;
             _this_1.getPropertyDistance(function(distance){
                    if(distance == undefined || distance == null)
                    {
                        distance = 0;
                    }
                    
                   
                   
                    _this_1.miles = distance['miles'];
                    _this_1.IRSPerMileRate = distance['IRSPerMileRate'];
                    if( _this_1.miles > 0)
                        {
                            _this_1.mileageDistance = distance['distance'];
                        }
                        else
                        {
                            _this_1.mileageDistance = distance['singlesidemiles'];
                        }
                    _this_1.getAllowableFees(null);
                })
              
              this.userList.Count = 36;
              this.userList.ScannedCount = 36;
              this.userList.TotalRecord = 36;      
              this.userList.Items=[
                {
                  "id":1,
                  "RepairType" : "Masonry",
                  "RepairTotal" : "0.00",
                  "Operation":"Active"
                },
                {
                  "id":2,
                  "RepairType" : "Siding",
                  "RepairTotal" : "0.00",
                  "Operation":"Active"
                },
                {
                  "id":3,
                  "RepairType" : "Gutters/Downspouts",
                  "RepairTotal" : "0.00",
                  "Operation":"Active"
                },
                {
                  "id":4,
                  "RepairType" : "Roof",
                  "RepairTotal" : "0.00",
                  "Operation":"Active"
                },
                {
                  "id":5,
                  "RepairType" : "Shutters",
                  "RepairTotal" : "0.00",
                  "Operation":"Active"
                },
                {
                  "id":6,
                  "RepairType" : "Exteriors",
                  "RepairTotal" : "0.00",
                  "Operation":"Active"
                },
                {
                  "id":7,
                  "RepairType" : "Walks",
                  "RepairTotal" : "0.00",
                  "Operation":"Active"
                },
                {
                  "id":8,
                  "RepairType" : "Driveways",
                  "RepairTotal" : "0.00",
                  "Operation":"Active"
                },
                {
                  "id":9,
                  "RepairType" : "Painting (Ext)",
                  "RepairTotal" : "0.00",
                  "Operation":"Active"
                },
                {
                  "id":10,
                  "RepairType" : "Caulking",
                  "RepairTotal" : "0.00",
                  "Operation":"Active"
                },
                {
                  "id":11,
                  "RepairType" : "Fencing",
                  "RepairTotal" : "0.00",
                  "Operation":"Active"
                },
                {
                  "id":12,
                  "RepairType" : "Grading",
                  "RepairTotal" : "0.00",
                  "Operation":"Active"
                },
                {
                  "id":13,
                  "RepairType" : "Windows",
                  "RepairTotal" : "0.00",
                  "Operation":"Active"
                },
                {
                  "id":14,
                  "RepairType" : "Weatherstrip",
                  "RepairTotal" : "0.00",
                  "Operation":"Active"
                },
                {
                  "id":15,
                  "RepairType" : "Doors (Ext)	",
                  "RepairTotal" : "0.00",
                  "Operation":"Active"
                },
                {
                  "id":16,
                  "RepairType" : "Doors (Int)	",
                  "RepairTotal" : "0.00",
                  "Operation":"Active"
                },
                {
                  "id":17,
                  "RepairType" : "Partition Wall",
                  "RepairTotal" : "0.00",
                  "Operation":"Active"
                },
                {
                  "id":18,
                  "RepairType" : "Plaster/Drywall",
                  "RepairTotal" : "0.00",
                  "Operation":"Active"
                },
                {
                  "id":19,
                  "RepairType" : "Decorating",
                  "RepairTotal" : "0.00",
                  "Operation":"Active"
                },
                {
                  "id":20,
                  "RepairType" : "Wood Trim	",
                  "RepairTotal" : "0.00",
                  "Operation":"Active"
                },
                {
                  "id":21,
                  "RepairType" : "Stairs",
                  "RepairTotal" : "0.00",
                  "Operation":"Active"
                },
                {
                  "id":22,
                  "RepairType" : "Closets",
                  "RepairTotal" : "0.00",
                  "Operation":"Active"
                },
                {
                  "id":23,
                  "RepairType" : "Wood Floors",
                  "RepairTotal" : "0.00",
                  "Operation":"Active"
                },
                {
                  "id":24,
                  "RepairType" : "Finished Floors",
                  "RepairTotal" : "0.00",
                  "Operation":"Active"
                },
                {
                  "id":25,
                  "RepairType" : "Ceramic Tile",
                  "RepairTotal" : "0.00",
                  "Operation":"Active"
                },
                {
                  "id":26,
                  "RepairType" : "Bath Accesories",
                  "RepairTotal" : "0.00",
                  "Operation":"Active"
                },
                {
                  "id":27,
                  "RepairType" : "Plumbing",
                  "RepairTotal" : "0.00",
                  "Operation":"Active"
                },
                {
                  "id":28,
                  "RepairType" : "Electrical",
                  "RepairTotal" : "0.00",
                  "Operation":"Active"
                },
                {
                  "id":29,
                  "RepairType" : "Heating",
                  "RepairTotal" : "0.00",
                  "Operation":"Active"
                },
                {
                  "id":30,
                  "RepairType" : "Insulation",
                  "RepairTotal" : "0.00",
                  "Operation":"Active"
                },
                {
                  "id":31,
                  "RepairType" : "Cabinetry",
                  "RepairTotal" : "0.00",
                  "Operation":"Active"
                },
                {
                  "id":32,
                  "RepairType" : "Appliances",
                  "RepairTotal" : "0.00",
                  "Operation":"Active"
                },
                {
                  "id":33,
                  "RepairType" : "Basements",
                  "RepairTotal" : "0.00",
                  "Operation":"Active"
                },
                {
                  "id":34,
                  "RepairType" : "Cleanup",
                  "RepairTotal" : "0.00",
                  "Operation":"Active"
                },
                {
                  "id":35,
                  "RepairType" : "Miscellaneous",
                  "RepairTotal" : "0.00",
                  "Operation":"Active"
                },
                {
                  "id":36,
                  "RepairType" : "Grand Total",
                  "RepairTotal" : "0.00",
                  "Operation":"Inactive"
                }
                

            ];

              const users = Array.from({length: this.userList.Count}, (_, k) => createNewUser(k,this.userList));
              this.dataSource = new MatTableDataSource(users);
              this.dataSource.paginator = this.paginator;
              this.dataSource.sort = this.sort;
               this.loading=false;
            }
          }
         
      },error=>{
        
      })
     
  }

  formReset(){
      this.RepairItemForm.reset();
    }



    
}


/** Builds and returns a new User. */
function createNewUser(id: number,userList): UserData {
 
  const name =
      NAMES[Math.round(Math.random() * (NAMES.length - 1))] + ' ' +
      NAMES[Math.round(Math.random() * (NAMES.length - 1))].charAt(0) + '.';
if(userList.Items[id]!=undefined)
{
  userList.Items[id].id=id+1;
 return userList.Items[id];
}
 
}

