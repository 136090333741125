import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { FormGroup, Validators, FormControl } from "@angular/forms";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { DataSource } from "@angular/cdk/table";
import { PropertyService } from "../property/property.service";
import { SessionService } from "../service/session.service";

export interface UserData {
    id: string;
    name: string;
    email: string;
    lastLogin: Date;
}

@Component({
    selector: "app-borrower-dashboard",
    templateUrl: "./borrower-dashboard.component.html",
    styleUrls: ["./borrower-dashboard.component.scss"],
})
export class BorrowerDashboardComponent implements OnInit {
    public editorValue: string = "";
    displayedColumns: string[] = ["id", "name", "email", "lstLogin"];
    dataSource: MatTableDataSource<UserData>;
    mobnumPattern = "^(d{3})sd{3}-d{4}";
    add_property = new FormGroup({});

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    isList: boolean = true;
    isAdd: boolean = false;
    isEdit: boolean = false;
    submitted: boolean = false;
    favoriteSeason: string;
    loading = false;
    propertyDetails: any = {};
    borrowerDetails: any = {};
    consultantDetails: any = {};
    LODetails: any = {};

    constructor(
        private route: ActivatedRoute,
        private _bservice: PropertyService,
        private sessionService: SessionService
    ) {}

    ngOnInit() {
        const propertyId = this.route.snapshot.paramMap.get("propertyId");
        this.getPropertyDetails(propertyId);
    }

    getPropertyDetails(propertyId) {
        this.loading = true;

        this._bservice.GetPropertyDetails(propertyId).subscribe(
            (response) => {
                if (response != null) {
                    this.propertyDetails = response;

                    this._bservice
                        .GetUserDetailsList(response.BorrowerId)
                        .subscribe(
                            (borrowerResponse) => {
                                if (borrowerResponse != null) {
                                    this.borrowerDetails = borrowerResponse;

                                    this._bservice
                                        .GetUserDetailsList(
                                            response.ConsultantId
                                        )
                                        .subscribe(
                                            (consultantResponse) => {
                                                if (
                                                    consultantResponse != null
                                                ) {
                                                    this.consultantDetails =
                                                        consultantResponse;

                                                    this._bservice
                                                        .GetUserDetailsList(
                                                            response.LoanOfficerId
                                                        )
                                                        .subscribe(
                                                            (LOResponse) => {
                                                                if (
                                                                    LOResponse !=
                                                                    null
                                                                ) {
                                                                    this.LODetails =
                                                                        LOResponse;

                                                                    this.loading =
                                                                        false;
                                                                }
                                                            },
                                                            (error) => {}
                                                        );
                                                }
                                            },
                                            (error) => {}
                                        );
                                }
                            },
                            (error) => {}
                        );
                }
            },
            (error) => {}
        );
    }

    printPdf(url) {
        window.open(url, "_blank");
    }
}
