import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { SessionService } from "../service/session.service";

@Injectable({
    providedIn: "root",
})
export class ConstructionItemsLibraryService {
    constructor(
        private http: HttpClient,
        private sessionService: SessionService
    ) {}
    baseurl = this.sessionService.endpoint;

    UpdateConstructionList(data) {
        return this.http.put<any>(
            this.baseurl + "construction-items-library/update",
            data,
            { headers: this.sessionService.setTokenHeader() }
        );
    }
    AddConstructionList(data) {
        return this.http.post<any>(
            this.baseurl + "construction-items-library/create",
            data,
            { headers: this.sessionService.setTokenHeader() }
        );
    }
    getConsultantConstructionItems(data) {
        return this.http.post<any>(
            this.baseurl + "construction-items-library/list",
            data,
            { headers: this.sessionService.setTokenHeader() }
        );
    }

    UpdateActivityGroupItem(data) {
        return this.http.put<any>(
            this.baseurl + "construction-items-library/activity-group/update",
            data,
            { headers: this.sessionService.setTokenHeader() }
        );
    }

    UpdateReportComponentItem(data) {
        return this.http.put<any>(
            this.baseurl + "construction-items-library/report-component/update",
            data,
            { headers: this.sessionService.setTokenHeader() }
        );
    }
}
