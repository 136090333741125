import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { SessionService } from "../service/session.service";

@Injectable({
    providedIn: "root",
})
export class AllowableFeesService {
    constructor(
        private http: HttpClient,
        private sessionService: SessionService
    ) {}
    baseurl = this.sessionService.endpoint;
    AddBorrower(data) {
        return this.http.post<any>(
            this.baseurl + "masters/allowablefees",
            data,
            { headers: this.sessionService.setTokenHeader() }
        );
    }

    UpdateBorrower(data) {
        return this.http.put<any>(
            this.baseurl + "masters/allowablefees",
            data,
            { headers: this.sessionService.setTokenHeader() }
        );
    }

    AllowableFeesList(data) {
        return this.http.post<any>(
            this.baseurl + "masters/allowablefees/list",
            data,
            { headers: this.sessionService.setTokenHeader() }
        );
    }

    DeleteAllowableFees(data) {
        return this.http.delete<any>(
            this.baseurl + "masters/allowablefees/" + data,
            { headers: this.sessionService.setTokenHeader() }
        );
    }

    GetUserByEmail(email) {
        return this.http.get<any>(this.baseurl + "users/email/" + email, {
            headers: this.sessionService.setTokenHeader(),
        });
    }

    GetLenderList(data) {
        return this.http.post<any>(this.baseurl + "lenders/list", data, {
            headers: this.sessionService.setTokenHeader(),
        });
    }

    DeleteUser(userid) {
        return this.http.delete<any>(this.baseurl + "users/" + userid, {
            headers: this.sessionService.setTokenHeader(),
        });
    }

    GetSorDetails(data) {
        return this.http.post<any>(this.baseurl + "sor/list", data, {
            headers: this.sessionService.setTokenHeader(),
        });
    }

    GetEmailSetting(data) {
        return this.http.post<any>(this.baseurl + "settings/smtp/list", data, {
            headers: this.sessionService.setTokenHeader(),
        });
    }

    AddEmailSetting(data) {
        return this.http.post<any>(this.baseurl + "settings/smtp", data, {
            headers: this.sessionService.setTokenHeader(),
        });
    }
    UpdateEmailSetting(data) {
        return this.http.put<any>(this.baseurl + "settings/smtp", data, {
            headers: this.sessionService.setTokenHeader(),
        });
    }

    UpdatePropertyStatus(data) {
        return this.http.put<any>(
            this.baseurl + "property/updatestatus",
            data,
            { headers: this.sessionService.setTokenHeader() }
        );
    }

    AddSOR(data) {
        return this.http.post<any>(this.baseurl + "sor", data, {
            headers: this.sessionService.setTokenHeader(),
        });
    }
    UpdateSOR(data) {
        return this.http.put<any>(this.baseurl + "sor", data, {
            headers: this.sessionService.setTokenHeader(),
        });
    }
}
