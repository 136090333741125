<div class="content p-24 tbl">
    <ngx-loading class="ngxLoader" 
        [show]="loading"
        [config]="{ backdropBorderRadius: '3px' }"
    ></ngx-loading>
    <h1 class="m-0">Change Order</h1>
    <br />
    <div class="container-login100">
        <mat-card class="my-form">
            <form
                [formGroup]="add_loanOfficer"
                class="example-form"
                (ngSubmit)="SaveRecord()"
            >
                <mat-card-content>
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    <mat-form-field class="example-full-width">
                                        <span
                                            class="mat-form-field-label-wrapper"
                                            ><!----><label
                                                class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                >Title*</label
                                            ></span
                                        >
                                        <input
                                            matInput
                                            formControlName="email"
                                            name="username"
                                        />
                                    </mat-form-field>
                                </td>
                                <td>
                                    <mat-form-field class="example-full-width">
                                        <span
                                            class="mat-form-field-label-wrapper"
                                            ><!----><label
                                                class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                >OMB Approval No*</label
                                            ></span
                                        >
                                        <input
                                            matInput
                                            formControlName="OMBApprovalNo"
                                            name="username"
                                        />
                                    </mat-form-field>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <mat-form-field>
                        <span class="mat-form-field-label-wrapper"
                            ><!----><label
                                class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                >EXPIRE DATE</label
                            ></span
                        >
                        <input matInput [matDatepicker]="picker" />
                        <mat-datepicker-toggle
                            matSuffix
                            [for]="picker"
                        ></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>

                    <label><strong>Property Inspection Address</strong> </label>
                    <ckeditor
                        matNativeControl
                        name="editor1"
                        formControlName="editorValue"
                        skin="moono-lisa"
                        language="en"
                    ></ckeditor>

                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    <mat-form-field class="example-full-width">
                                        <span
                                            class="mat-form-field-label-wrapper"
                                            ><!----><label
                                                class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                >Case(s) No.</label
                                            ></span
                                        >
                                        <input
                                            matInput
                                            formControlName="Lender"
                                            name="username"
                                        />
                                    </mat-form-field>
                                </td>
                                <td>
                                    <mat-form-field class="example-full-width">
                                        <span
                                            class="mat-form-field-label-wrapper"
                                            ><!----><label
                                                class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                >Request No.</label
                                            ></span
                                        >
                                        <input
                                            matInput
                                            formControlName="RequestNo"
                                            name="username"
                                        />
                                    </mat-form-field>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <label><strong>Mortgagee's Name & Address</strong> </label>
                    <ckeditor
                        matNativeControl
                        name="editor1"
                        formControlName="LenderAddress"
                        skin="moono-lisa"
                        language="en"
                    ></ckeditor>
                    <br />
                    <label>
                        <strong
                            >DRAWINGS AND SPECIFICATIONS DETAIL</strong
                        > </label
                    ><br /><br />

                    <mat-form-field>
                        <span class="mat-form-field-label-wrapper"
                            ><!----><label
                                class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                >Description</label
                            ></span
                        >
                        <textarea matInput></textarea>
                    </mat-form-field>

                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    <mat-form-field class="example-full-width">
                                        <span
                                            class="mat-form-field-label-wrapper"
                                            ><!----><label
                                                class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                >Builder's Estimate of cost on
                                                each change</label
                                            ></span
                                        >
                                        <input
                                            matInput
                                            formControlName="BuilderEstimateofcost"
                                            name="username"
                                        />
                                    </mat-form-field>
                                </td>
                                <td>
                                    <mat-form-field class="example-full-width">
                                        <span
                                            class="mat-form-field-label-wrapper"
                                            ><!----><label
                                                class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                >HUD/VA Estimate of effect on
                                                cost of each change</label
                                            ></span
                                        >
                                        <input
                                            matInput
                                            formControlName="HUD/VAEstimateofeffectoncost"
                                            name="username"
                                        />
                                    </mat-form-field>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <mat-form-field>
                        <span class="mat-form-field-label-wrapper"
                            ><!----><label
                                class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                >Description</label
                            ></span
                        >
                        <textarea matInput></textarea>
                    </mat-form-field>

                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    <mat-form-field class="example-full-width">
                                        <span
                                            class="mat-form-field-label-wrapper"
                                            ><!----><label
                                                class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                >Builder's Estimate of cost on
                                                each change</label
                                            ></span
                                        >
                                        <input
                                            matInput
                                            formControlName="BuilderEstimateofcost"
                                            name="username"
                                        />
                                    </mat-form-field>
                                </td>
                                <td>
                                    <mat-form-field class="example-full-width">
                                        <span
                                            class="mat-form-field-label-wrapper"
                                            ><!----><label
                                                class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                >HUD/VA Estimate of effect on
                                                cost of each change</label
                                            ></span
                                        >
                                        <input
                                            matInput
                                            formControlName="HUD/VAEstimateofeffectoncost"
                                            name="username"
                                        />
                                    </mat-form-field>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <mat-form-field>
                        <span class="mat-form-field-label-wrapper"
                            ><!----><label
                                class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                >Description</label
                            ></span
                        >
                        <textarea matInput></textarea>
                    </mat-form-field>

                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    <mat-form-field class="example-full-width">
                                        <span
                                            class="mat-form-field-label-wrapper"
                                            ><!----><label
                                                class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                >Builder's Estimate of cost on
                                                each change</label
                                            ></span
                                        >
                                        <input
                                            matInput
                                            formControlName="BuilderEstimateofcost"
                                            name="username"
                                        />
                                    </mat-form-field>
                                </td>
                                <td>
                                    <mat-form-field class="example-full-width">
                                        <span
                                            class="mat-form-field-label-wrapper"
                                            ><!----><label
                                                class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                >HUD/VA Estimate of effect on
                                                cost of each change</label
                                            ></span
                                        >
                                        <input
                                            matInput
                                            formControlName="HUD/VAEstimateofeffectoncost"
                                            name="username"
                                        />
                                    </mat-form-field>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <mat-form-field>
                        <span class="mat-form-field-label-wrapper"
                            ><!----><label
                                class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                >Description</label
                            ></span
                        >
                        <textarea matInput></textarea>
                    </mat-form-field>

                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    <mat-form-field class="example-full-width">
                                        <span
                                            class="mat-form-field-label-wrapper"
                                            ><!----><label
                                                class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                >Builder's Estimate of cost on
                                                each change</label
                                            ></span
                                        >
                                        <input
                                            matInput
                                            formControlName="BuilderEstimateofcost"
                                            name="username"
                                        />
                                    </mat-form-field>
                                </td>
                                <td>
                                    <mat-form-field class="example-full-width">
                                        <span
                                            class="mat-form-field-label-wrapper"
                                            ><!----><label
                                                class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                >HUD/VA Estimate of effect on
                                                cost of each change</label
                                            ></span
                                        >
                                        <input
                                            matInput
                                            formControlName="HUD/VAEstimateofeffectoncost"
                                            name="username"
                                        />
                                    </mat-form-field>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <mat-form-field>
                        <span class="mat-form-field-label-wrapper"
                            ><!----><label
                                class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                >Description</label
                            ></span
                        >
                        <textarea matInput></textarea>
                    </mat-form-field>

                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    <mat-form-field class="example-full-width">
                                        <span
                                            class="mat-form-field-label-wrapper"
                                            ><!----><label
                                                class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                >Builder's Estimate of cost on
                                                each change</label
                                            ></span
                                        >
                                        <input
                                            matInput
                                            formControlName="BuilderEstimateofcost"
                                            name="username"
                                        />
                                    </mat-form-field>
                                </td>
                                <td>
                                    <mat-form-field class="example-full-width">
                                        <span
                                            class="mat-form-field-label-wrapper"
                                            ><!----><label
                                                class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                >HUD/VA Estimate of effect on
                                                cost of each change</label
                                            ></span
                                        >
                                        <input
                                            matInput
                                            formControlName="HUD/VAEstimateofeffectoncost"
                                            name="username"
                                        />
                                    </mat-form-field>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <label> <strong>NAME AND DATE DETAIL</strong> </label
                    ><br /><br />

                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title> BORROWER </mat-panel-title>
                        </mat-expansion-panel-header>

                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <mat-form-field>
                                            <span
                                                class="mat-form-field-label-wrapper"
                                                ><!----><label
                                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                    >Borrower (If known)</label
                                                ></span
                                            >
                                            <input matInput />
                                        </mat-form-field>
                                    </td>
                                    <td>
                                        <mat-form-field>
                                            <span
                                                class="mat-form-field-label-wrapper"
                                                ><!----><label
                                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                    >DATE</label
                                                ></span
                                            >
                                            <input
                                                matInput
                                                [matDatepicker]="picker2"
                                            />
                                            <mat-datepicker-toggle
                                                matSuffix
                                                [for]="picker2"
                                            ></mat-datepicker-toggle>
                                            <mat-datepicker
                                                #picker2
                                            ></mat-datepicker>
                                        </mat-form-field>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </mat-expansion-panel>

                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title> BUILDER </mat-panel-title>
                        </mat-expansion-panel-header>

                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <mat-form-field>
                                            <span
                                                class="mat-form-field-label-wrapper"
                                                ><!----><label
                                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                    >Builder or Sponsor</label
                                                ></span
                                            >
                                            <input matInput />
                                        </mat-form-field>
                                    </td>
                                    <td>
                                        <mat-form-field>
                                            <span
                                                class="mat-form-field-label-wrapper"
                                                ><!----><label
                                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                    >DATE</label
                                                ></span
                                            >
                                            <input
                                                matInput
                                                [matDatepicker]="picker3"
                                            />
                                            <mat-datepicker-toggle
                                                matSuffix
                                                [for]="picker3"
                                            ></mat-datepicker-toggle>
                                            <mat-datepicker
                                                #picker3
                                            ></mat-datepicker>
                                        </mat-form-field>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </mat-expansion-panel>

                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title> MORTGAGEE </mat-panel-title>
                        </mat-expansion-panel-header>

                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <mat-form-field>
                                            <span
                                                class="mat-form-field-label-wrapper"
                                                ><!----><label
                                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                    >For Mortgagee</label
                                                ></span
                                            >
                                            <input matInput />
                                        </mat-form-field>
                                    </td>
                                    <td>
                                        <mat-form-field>
                                            <span
                                                class="mat-form-field-label-wrapper"
                                                ><!----><label
                                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                    >DATE</label
                                                ></span
                                            >
                                            <input
                                                matInput
                                                [matDatepicker]="picker4"
                                            />
                                            <mat-datepicker-toggle
                                                matSuffix
                                                [for]="picker4"
                                            ></mat-datepicker-toggle>
                                            <mat-datepicker
                                                #picker4
                                            ></mat-datepicker>
                                        </mat-form-field>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </mat-expansion-panel>

                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    <label>
                                        <strong
                                            >Determination as to
                                            Acceptability</strong
                                        >
                                    </label>
                                </td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>
                                    <mat-checkbox>
                                        Changes Acceptable</mat-checkbox
                                    >
                                </td>
                                <td>
                                    <mat-checkbox>
                                        Changes Unacceptable</mat-checkbox
                                    >
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <mat-checkbox>
                                        Changes Acceptable, provided (list any
                                        conditions)</mat-checkbox
                                    >
                                </td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>

                    <label
                        ><strong>Changes Acceptable Conditions</strong>
                    </label>
                    <ckeditor
                        matNativeControl
                        name="editor1"
                        formControlName="editorValue"
                        skin="moono-lisa"
                        language="en"
                    ></ckeditor>

                    <br />

                    <mat-form-field class="example-full-width">
                        <span class="mat-form-field-label-wrapper"
                            ><!----><label
                                class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                >value revised to</label
                            ></span
                        >
                        <input
                            matInput
                            formControlName="valuerevisedto"
                            name="username"
                        />
                    </mat-form-field>

                    <label> <strong>Department</strong> </label><br /><br />
                    <mat-checkbox> Department of Veterans Affairs</mat-checkbox>
                    <br /><br />
                    <mat-checkbox> Federal Housing Commissioner</mat-checkbox>
                    <br /><br /><br />

                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    <mat-form-field class="example-full-width">
                                        <span
                                            class="mat-form-field-label-wrapper"
                                            ><!----><label
                                                class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                >Authorized Agent</label
                                            ></span
                                        >
                                        <input
                                            matInput
                                            formControlName="AuthorizedAgent"
                                            name="username"
                                        />
                                    </mat-form-field>
                                </td>
                                <td>
                                    <mat-checkbox> DE</mat-checkbox>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <mat-form-field>
                                        <span
                                            class="mat-form-field-label-wrapper"
                                            ><!----><label
                                                class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                >DATE</label
                                            ></span
                                        >
                                        <input
                                            matInput
                                            [matDatepicker]="picker5"
                                        />

                                        <mat-datepicker-toggle
                                            matSuffix
                                            [for]="picker5"
                                        ></mat-datepicker-toggle>
                                        <mat-datepicker
                                            #picker5
                                        ></mat-datepicker>
                                    </mat-form-field>
                                </td>
                                <td>
                                    <mat-form-field>
                                        <span
                                            class="mat-form-field-label-wrapper"
                                            ><!----><label
                                                class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                >Property</label
                                            ></span
                                        >
                                        <mat-select formControlName="Property">
                                            <mat-option value=""
                                                >Select</mat-option
                                            >
                                            <mat-option value="RMS"
                                                >1 City Hall Square , Boston,
                                                Massachusetts, 02201</mat-option
                                            >
                                        </mat-select>
                                    </mat-form-field>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <label
                        ><strong>Request for Acceptance of Changes</strong>
                    </label>
                    <ckeditor
                        matNativeControl
                        name="editor1"
                        formControlName="editorValue"
                        skin="moono-lisa"
                        language="en"
                    ></ckeditor>
                    <br />

                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    <mat-checkbox> We Request</mat-checkbox>
                                </td>
                                <td>
                                    <mat-checkbox>
                                        We Do Not Request</mat-checkbox
                                    >
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <br />
                </mat-card-content>
                <mat-card-actions>
                    <button mat-raised-button type="submit" color="primary">
                        Save</button
                    >&nbsp; &nbsp;&nbsp;
                    <button mat-raised-button type="button" color="info">
                        Save as draft</button
                    >&nbsp; &nbsp;&nbsp;
                    <button mat-raised-button type="button" color="info">
                        Preview
                    </button>
                </mat-card-actions>
            </form>
        </mat-card>
    </div>
</div>
