<div class="content p-24 tbl">
    <ngx-loading class="ngxLoader" 
        [show]="loading"
        [config]="{ backdropBorderRadius: '3px' }"
    ></ngx-loading>
    <h1 class="m-0">Consultant Liability</h1>
    <a
        class="addBorrow"
        href="javascript:void(0)"
        routerLink="/property-details/{{ propertyDetailsData.Id }}/1"
    >
        Back to Property details</a
    >
    <br />
    <div class="container-login100">
        <mat-card class="my-form">
            <form
                [formGroup]="add_consultant_liability"
                class="example-form"
                (ngSubmit)="SaveRecord()"
            >
                <mat-card-content>
                    <mat-form-field *ngIf="propertyListStatus">
                        <span class="mat-form-field-label-wrapper"
                            ><!----><label
                                class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                >Property</label
                            ></span
                        >
                        <mat-select
                            class="propertyDropdown"
                            formControlName="property"
                            [disabled]="true"
                        >
                            <mat-option value="">Select</mat-option>
                            <mat-option
                                *ngFor="let property of propertyList"
                                value="{{ property.Id }}"
                            >
                                {{
                                    property?.InspectionAddress?.address1
                                        ? property?.InspectionAddress
                                              ?.address1 + ", "
                                        : ""
                                }}
                                {{
                                    property?.InspectionAddress?.address2
                                        ? property?.InspectionAddress
                                              ?.address2 + ", "
                                        : ""
                                }}
                                {{
                                    property?.InspectionAddress?.city
                                        ? property?.InspectionAddress?.city +
                                          ", "
                                        : ""
                                }}
                                {{
                                    property?.InspectionAddress?.state
                                        ? property?.InspectionAddress?.state +
                                          ", "
                                        : ""
                                }}
                                {{
                                    property?.InspectionAddress?.zipcode
                                        ? property?.InspectionAddress?.zipcode
                                        : ""
                                }}
                            </mat-option>
                        </mat-select>
                        <div class="error" *ngIf="(!add_consultant_liability.controls['property'].valid && add_consultant_liability.controls['property'].touched) || (add_consultant_liability.controls['property'].invalid && submitted)">
                            select property
                          </div>
                    </mat-form-field>
                    <table>
                        <tbody>
                            <!-- <tr>
              <td>
                  <mat-form-field class="example-full-width">
                      <input matInput placeholder="Title*" formControlName="title" name="title">
                    </mat-form-field>
              </td>
              <td>
                  
              </td>
            </tr> -->
                            <tr>
                                <td>
                                    <mat-form-field>
                                        <span
                                            class="mat-form-field-label-wrapper"
                                            ><!----><label
                                                class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                >AGREEMENT DATE</label
                                            ></span
                                        >
                                        <input
                                            matInput
                                            [matDatepicker]="picker"
                                            formControlName="agreementDate"
                                        />
                                        <mat-datepicker-toggle
                                            matSuffix
                                            [for]="picker"
                                        ></mat-datepicker-toggle>
                                        <mat-datepicker
                                            #picker
                                        ></mat-datepicker>

                                        <!-- <input matInput placeholder="agreementDate" formControlName="agreementDate" name="agreementDate"> -->
                                    </mat-form-field>
                                </td>
                                <td>
                                    <mat-form-field>
                                        <span
                                            class="mat-form-field-label-wrapper"
                                            ><!----><label
                                                class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                >Consultant fee</label
                                            ></span
                                        >
                                        <input
                                            matInput
                                            formControlName="consultantFee"
                                            type="number"
                                            class="example-right-align"
                                        />
                                        <span matPrefix>$&nbsp;</span>
                                        <span matSuffix>.00</span>
                                    </mat-form-field>
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <mat-form-field>
                                        <span
                                            class="mat-form-field-label-wrapper"
                                            ><!----><label
                                                class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                >Consutant fee reimbursed</label
                                            ></span
                                        >
                                        <input
                                            matInput
                                            formControlName="consutantFeeReimbursed"
                                            type="number"
                                            class="example-right-align"
                                        />
                                        <span matPrefix>$&nbsp;</span>
                                        <span matSuffix>.00</span>
                                    </mat-form-field>
                                </td>
                                <td>
                                    <mat-form-field>
                                        <span
                                            class="mat-form-field-label-wrapper"
                                            ><!----><label
                                                class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                >Drawing below 100k</label
                                            ></span
                                        >
                                        <input
                                            matInput
                                            formControlName="drawingBelow"
                                            type="number"
                                            class="example-right-align"
                                        />
                                        <span matPrefix>$&nbsp;</span>
                                        <span matSuffix>.00</span>
                                    </mat-form-field>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <mat-form-field>
                                        <span
                                            class="mat-form-field-label-wrapper"
                                            ><!----><label
                                                class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                >Drawing between 100K and
                                                150K</label
                                            ></span
                                        >
                                        <input
                                            matInput
                                            formControlName="drawingBetween"
                                            type="number"
                                            class="example-right-align"
                                        />
                                        <span matPrefix>$&nbsp;</span>
                                        <span matSuffix>.00</span>
                                    </mat-form-field>
                                </td>
                                <td>
                                    <mat-form-field>
                                        <span
                                            class="mat-form-field-label-wrapper"
                                            ><!----><label
                                                class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                >Drawing over 150K</label
                                            ></span
                                        >
                                        <input
                                            matInput
                                            formControlName="drawingOver"
                                            type="number"
                                            class="example-right-align"
                                        />
                                        <span matPrefix>$&nbsp;</span>
                                        <span matSuffix>.00</span>
                                    </mat-form-field>
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <mat-form-field>
                                        <span
                                            class="mat-form-field-label-wrapper"
                                            ><!----><label
                                                class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                >Change Order (L)(1)</label
                                            ></span
                                        >
                                        <input
                                            matInput
                                            formControlName="changeOrder"
                                            type="number"
                                            class="example-right-align"
                                        />
                                        <span matPrefix>$&nbsp;</span>
                                        <span matSuffix>.00</span>
                                    </mat-form-field>
                                </td>
                                <td>
                                    <mat-form-field>
                                        <span
                                            class="mat-form-field-label-wrapper"
                                            ><!----><label
                                                class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                >Draw Requests (Min)</label
                                            ></span
                                        >
                                        <input
                                            matInput
                                            formControlName="drawRequestsMin"
                                            type="number"
                                            class="example-right-align"
                                        />
                                        <span matPrefix>$&nbsp;</span>
                                        <span matSuffix>.00</span>
                                    </mat-form-field>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <mat-form-field>
                                        <span
                                            class="mat-form-field-label-wrapper"
                                            ><!----><label
                                                class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                >Draw Requests (Max)</label
                                            ></span
                                        >
                                        <input
                                            matInput
                                            formControlName="drawRequestsMax"
                                            type="number"
                                            class="example-right-align"
                                        />
                                        <span matPrefix>$&nbsp;</span>
                                        <span matSuffix>.00</span>
                                    </mat-form-field>
                                </td>
                                <td>
                                    <mat-form-field>
                                        <span
                                            class="mat-form-field-label-wrapper"
                                            ><!----><label
                                                class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                >Self Help Draw</label
                                            ></span
                                        >
                                        <input
                                            matInput
                                            formControlName="selfHelpDraw"
                                            type="number"
                                            class="example-right-align"
                                        />
                                        <span matPrefix>$&nbsp;</span>
                                        <span matSuffix>.00</span>
                                    </mat-form-field>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <mat-form-field>
                                        <span
                                            class="mat-form-field-label-wrapper"
                                            ><!----><label
                                                class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                >Change Scope of Work
                                                (Min)</label
                                            ></span
                                        >
                                        <input
                                            matInput
                                            formControlName="changeScopeOfWorkMin"
                                            type="number"
                                            class="example-right-align"
                                        />
                                        <span matPrefix>$&nbsp;</span>
                                        <span matSuffix>.00</span>
                                    </mat-form-field>
                                </td>
                                <td>
                                    <mat-form-field>
                                        <span
                                            class="mat-form-field-label-wrapper"
                                            ><!----><label
                                                class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                >Change Scope of Work
                                                (Max)</label
                                            ></span
                                        >
                                        <input
                                            matInput
                                            formControlName="changeScopeOfWorkMax"
                                            type="number"
                                            class="example-right-align"
                                        />
                                        <span matPrefix>$&nbsp;</span>
                                        <span matSuffix>.00</span>
                                    </mat-form-field>
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <mat-form-field>
                                        <span
                                            class="mat-form-field-label-wrapper"
                                            ><!----><label
                                                class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                >Multi-family dwelling</label
                                            ></span
                                        >
                                        <input
                                            matInput
                                            formControlName="multiFamilyDwelling"
                                            type="number"
                                            class="example-right-align"
                                        />
                                        <span matPrefix>$&nbsp;</span>
                                        <span matSuffix>.00</span>
                                    </mat-form-field>
                                </td>
                                <td>
                                    <mat-form-field>
                                        <span
                                            class="mat-form-field-label-wrapper"
                                            ><!----><label
                                                class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                >Work Plan</label
                                            ></span
                                        >
                                        <input
                                            matInput
                                            formControlName="workPlan"
                                            type="number"
                                            class="example-right-align"
                                        />
                                        <span matPrefix>$&nbsp;</span>
                                        <span matSuffix>.00</span>
                                    </mat-form-field>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </mat-card-content>
                <mat-card-actions>
                    <button mat-raised-button type="submit" color="primary">
                        Save</button
                    >&nbsp; &nbsp;&nbsp;
                    <!-- <button mat-raised-button type="button" color="info">Save as draft</button>&nbsp; &nbsp;&nbsp;
       <button mat-raised-button type="button" color="info">Preview</button> -->
                </mat-card-actions>
            </form>
        </mat-card>
    </div>
</div>
