<h2 mat-dialog-title></h2>
<mat-dialog-content class="mat-typography">
    <ng-container *ngIf="isRevise!='true'">
        <p>
            The bid on repair form on the following page should ONLY be
            completed by the GENERAL CONTRACTOR who will be filling in All
            components noted by the consultant which will include all pricing
            provided by the subcontractors (electrician, plumbler, septic,etc).
        </p>

        <p>Individual subcontractors should not be filling in this form</p>

        <p>
            Please Provide breakdown for material & labor & blend into each line
            item your overhead & profit.
        </p>
    </ng-container>
    <ng-container style="text-align: justify" *ngIf="isRevise=='true'">
        <p>
            A revision has been requested for the estimate you have previously
            submitted. Please revise the estimate to incorporate the comments
            shown below. If you have questions regarding the comments below
            please contact the borrower or consultant. Please see comments
            below.”
        </p>
        <br />
        <p>{{comment}}</p>
    </ng-container>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cancel</button>
    <button
        mat-button
        [mat-dialog-close]="true"
        (click)="redirectOnBid()"
        cdkFocusInitial
    >
        Ok
    </button>
</mat-dialog-actions>
