import { Component } from "@angular/core";

import { FuseTranslationLoaderService } from "@fuse/services/translation-loader.service";

import { locale as english } from "./i18n/en";
import { locale as turkish } from "./i18n/tr";
import { BorrowerService } from "../borrower/borrower.service";
import { SessionService } from "../service/session.service";

@Component({
    selector: "dashboard",
    templateUrl: "./dashboard.component.html",
    styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent {
    /**
     * Constructor
     *
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     */
    userDetail: any = {};
    LoginEmail = "";
    masqueradeUserInfo = "";

    constructor(
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _bservice: BorrowerService,
        private sessionService: SessionService
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, turkish);
    }

    ngOnInit() {
        if (this.sessionService.get("userInfo") != undefined) {
            this.userDetail = JSON.parse(this.sessionService.get("userInfo"));
        }

        if (this.sessionService.get("masqueradeUserInfo") != undefined) {
            this.masqueradeUserInfo = JSON.parse(
                this.sessionService.get("masqueradeUserInfo")
            );
        }
    }
}
