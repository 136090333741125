import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import {
    FormGroup,
    Validators,
    FormControl,
    ReactiveFormsModule,
    FormArray,
    FormBuilder,
} from "@angular/forms";

import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { DataSource } from "@angular/cdk/table";
import { PropertyEditService } from "./property-edit.service";
import { SessionService } from "../service/session.service";
import { Router, ActivatedRoute } from "@angular/router";
import { BorrowerService } from "../borrower/borrower.service";

export interface UserData {
    id: string;
    name: string;
    email: string;
    lastLogin: Date;
}

/** Constants used to fill up our data base. */
const COLORS: string[] = [
    "maroon",
    "red",
    "orange",
    "yellow",
    "olive",
    "green",
    "purple",
    "fuchsia",
    "lime",
    "teal",
    "aqua",
    "blue",
    "navy",
    "black",
    "gray",
];

const NAMES: string[] = [
    "Maia",
    "Asher",
    "Olivia",
    "Atticus",
    "Amelia",
    "Jack",
    "Charlotte",
    "Theodore",
    "Isla",
    "Oliver",
    "Isabella",
    "Jasper",
    "Cora",
    "Levi",
    "Violet",
    "Arthur",
    "Mia",
    "Thomas",
    "Elizabeth",
];

/**
 * @title Data table with sorting, pagination, and filtering.
 */

@Component({
    selector: "app-property-edit",
    templateUrl: "./property-edit.component.html",
    styleUrls: ["./property-edit.component.scss"],
})
export class PropertyEditComponent implements OnInit {
    public editorValue: string = "";
    displayedColumns: string[] = [
        "id",
        "InspectionAddress",
        "Consultant",
        "Borrower",
        "LoanOfficer",
        "LoanType",
        "status",
    ];
    dataSource: MatTableDataSource<UserData>;
    mobnumPattern = "^(d{3})sd{3}-d{4}";
    add_property = new FormGroup({
        email: new FormControl(null, [Validators.required, Validators.email,Validators.maxLength(40),Validators.minLength(5)]),
        fname: new FormControl(null, [Validators.required, Validators.maxLength(30),Validators.minLength(3)]),
        lname: new FormControl(null, [Validators.required, Validators.maxLength(30),Validators.minLength(3)]),
        // phone_no: new FormControl(null, [Validators.pattern(this.mobnumPattern),Validators.maxLength(15),Validators.minLength(8)]),
        phone_no: new FormControl(null, []),
        borrowerId: new FormControl(null),
        gcemail: new FormControl(null, [Validators.email,Validators.maxLength(40),Validators.minLength(5)]),
        gcfname: new FormControl(null,[Validators.maxLength(30),Validators.minLength(3)]),
        gclname: new FormControl(null,[Validators.maxLength(30),Validators.minLength(3)]),
        gcphone_no: new FormControl(null, []),
        gcId: new FormControl(null),
        RTemail: new FormControl(null, [Validators.email,Validators.maxLength(40),Validators.minLength(5)]),
        RTfname: new FormControl(null,[Validators.maxLength(30),Validators.minLength(3)]),
        RTlname: new FormControl(null,[Validators.maxLength(30),Validators.minLength(3)]),
        RTphone_no: new FormControl(null, []),
        RTid: new FormControl(null),
        LPemail: new FormControl(null, [Validators.email,Validators.maxLength(40),Validators.minLength(5)]),
        LPfname: new FormControl(null,[Validators.maxLength(30),Validators.minLength(3)]),
        LPlname: new FormControl(null,[Validators.maxLength(30),Validators.minLength(3)]),
        LPphone_no: new FormControl(null, []),
        LPpassword: new FormControl(Math.random().toString(36).slice(-8)),
        LPid: new FormControl(null),
        statusType: new FormControl(null),
        consultant: new FormControl(null, [Validators.required]),
        loanOfficer: new FormControl(null, [Validators.required]),
        transactionType: new FormControl(null),
        home_borrower: new FormControl(null),
        city: new FormControl(null, [Validators.required]),
        state: new FormControl(null, [Validators.required]),
        zipcode: new FormControl(null, [Validators.maxLength(10),Validators.minLength(3)]),
        address1: new FormControl(null, [Validators.required]),
        address2: new FormControl(null),
        fHACaseNo: new FormControl(null, [Validators.maxLength(20),Validators.minLength(3)]),
        LoanNo: new FormControl(null, [Validators.maxLength(20),Validators.minLength(3)]),
        loanType: new FormControl(null, [Validators.required]),
        realtor: new FormControl(null),
        renovationCompleted: new FormControl(false),
        renovationCompletedDate: new FormControl(null),
        bidProcessStartDate: new FormControl(null),
        inspectionDate: new FormControl(null),
        biddingDueDate: new FormControl(null),
        propertyStatus: new FormControl(null),
        agreementSignedByBo: new FormControl(null),
        lender: new FormControl(null, [Validators.required]),
        loanProcessor: new FormControl(null),
        latitude: new FormControl(null),
        longitude: new FormControl(null),
        id: new FormControl(null),
        LOemail: new FormControl(null, [Validators.email,Validators.maxLength(40),Validators.minLength(5)]),
        LOfname: new FormControl(null,[Validators.maxLength(30),Validators.minLength(3)]),
        LOlname: new FormControl(null,[Validators.maxLength(30),Validators.minLength(3)]),
        LOphone_no: new FormControl(null, []),
        LOpassword: new FormControl(Math.random().toString(36).slice(-8)),
        LOid: new FormControl(null),
        addBemail: new FormControl("", [Validators.email,Validators.maxLength(40),Validators.minLength(5)]),
        addBfname: new FormControl("",[Validators.maxLength(30),Validators.minLength(3)]),
        addBlname: new FormControl("",[Validators.maxLength(30),Validators.minLength(3)]),
        InvitedGeneralContractors: new FormControl([]),
    });

    formGroup: FormGroup;

    additional_borrower: any = [];

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    propertyList: any = {};
    minDate: Date = new Date();
    isList: boolean = false;
    isAdd: boolean = false;
    isEdit: boolean = false;
    submitted: boolean = false;
    PropertyStatusChecked = false;
    PropertyCompletedChecked = false;
    loading = false;
    favoriteSeason: string;
    loan_officer_list: any = {};
    filter_loan_officer_list: any = [];
    filter_loan_officer_list_check = false;
    filter_loan_processor_list_check = false;
    loan_processor_list: any = {};
    filter_loan_processor_list: any = [];
    consultent_list: any = {};
    borrower_list: any = {};
    contractor_list: any = {};
    lender_list: any = {};
    realtor_list: any = {};
    propertyId: any = {};
    displayAddBorrower: boolean = false;
    invitedGeneralContractors: any = [];
    tempinvitedGeneralContractors: any = [];
    showAddLoanOfficer: boolean = false;
    formattedaddress = "";
    options = {
        componentRestrictions: {
            country: ["US"],
        },
    };
    userDetail: any = {};
    status: any = ["Blocked", "Active"];
    roles: any = [
        { Key: "1", Value: "authenticated user" },
        { Key: "2", Value: "Administrator" },
        { Key: "3", Value: "Consultants" },
        { Key: "4", Value: "General Contractors" },
        { Key: "5", Value: "Borrower" },
        { Key: "6", Value: "Loan Officer" },
        { key: "7", Value: "Loan Processor" },
        { key: "8", Value: " Notify user of new account" },
        { key: "9", Value: "Draw Administrator" },
    ];
    maskPhoneFormat: any = '(000) 000-0000';
    constructor(
        private _bservice: PropertyEditService,
        private _boservice: BorrowerService,
        @Inject(Router) private router: Router,
        private sessionService: SessionService,
        @Inject(FormBuilder) private _fb: FormBuilder,
        @Inject(ActivatedRoute) private route: ActivatedRoute
    ) {}

    ngOnInit() {
        const propertyId = this.route.snapshot.paramMap.get("propertyId");
        this.propertyId = propertyId;
        this.getPropertyDetails(propertyId);
        this.propertyList.Items = [];
        this.propertyList.Count = 0;
        this.propertyList.ScannedCount = 0;
        this.propertyList.TotalRecord = 0;
        this.minDate = new Date();
        if (this.sessionService.get("userInfo") !== undefined) {
            this.userDetail = JSON.parse(this.sessionService.get("userInfo"));
        }
    }

    keyDownPhoneFormat($event) {
        var key = $event.keyCode;

        if (key == 91 || (15 < key && key < 19) || (37 <= key && key <= 40)) {
            return;
        }
        this.changePhoneFormat($event);
    }

    changePhoneFormat($event) {
        var getVal = $event.target.value;
        var tel = getVal.replace(/[^0-9]/g, "");
        $event.target.value = this.changeFilter(tel);
    }
    changeFilter(tel) {
        if (!tel) {
            return "";
        }

        var value = tel.toString().trim().replace(/^\+/, "");

        if (value.match(/[^0-9]/)) {
            return tel;
        }

        var country, city, number;

        switch (value.length) {
            case 1:
            case 2:
            case 3:
                city = value;
                break;

            default:
                city = value.slice(0, 3);
                number = value.slice(3);
        }

        if (number) {
            if (number.length > 3) {
                number = number.slice(0, 3) + "-" + number.slice(3, 7);
            } else {
                number = number;
            }

            return ("(" + city + ") " + number).trim();
        } else {
            return "(" + city;
        }
    }

    applyFilter(filterValue) {
        const target = filterValue as HTMLInputElement;
        const targetValue = target.value;
        this.dataSource.filter = targetValue.trim().toLowerCase();

        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    }
    public AddressChange(address: any) {
        let addressFielsLength = address.address_components.length;
        let address1 = "";
        let city = "";
        let state = "";
        let zipcode = "";

        for (let i = 0; i < addressFielsLength; i++) {
            if (address.address_components[i].types[0] == "street_number") {
                address1 = address.address_components[i].long_name;
            }

            if (address.address_components[i].types[0] == "route") {
                address1 =
                    address1 + " " + address.address_components[i].long_name;
            }

            if (
                address.address_components[i].types[0] ==
                    "sublocality_level_1" ||
                address.address_components[i].types[0] == "locality" ||
                address.address_components[i].types[0] == "neighborhood"
            ) {
                city = address.address_components[i].long_name;
            }
            if (
                address.address_components[i].types[0] ==
                "administrative_area_level_1"
            ) {
                state = address.address_components[i].short_name;
            }
            if (address.address_components[i].types[0] == "postal_code") {
                zipcode = address.address_components[i].long_name;
            }
        }

        this.add_property.controls["address1"].setValue(address1);
        this.add_property.controls["city"].setValue(city);
        this.add_property.controls["state"].setValue(state);
        this.add_property.controls["zipcode"].setValue(zipcode);
    }
    initBorrower() {
        // initialize our address
        return [
            this._fb.group({
                email: ["", Validators.required],
                fname: ["", Validators.required],
                lname: ["", Validators.required],
                phone_no: ["", Validators.pattern(this.mobnumPattern)],
                borrowerId: [""],
            }),
        ];
    }

    showAdditionalBorrower() {
        if (this.displayAddBorrower) {
            this.displayAddBorrower = false;
        } else {
            this.displayAddBorrower = true;
        }
    }

    addBorrower() {
        if (
            this.add_property.value.addBemail !== "" &&
            this.add_property.value.addBemail !== null &&
            this.add_property.value.addBfname !== "" &&
            this.add_property.value.addBfname !== null &&
            this.add_property.value.addBlname &&
            this.add_property.value.addBlname != null &&
            this.add_property.value.addBemail.toLowerCase() !==
                this.add_property.value.email.toLowerCase()
        ) {
            this.additional_borrower.push({
                Email: this.add_property.value.addBemail,
                FirstName: this.add_property.value.addBfname,
                LastName: this.add_property.value.addBlname,
            });
            this.resetBorrower();
        }
    }

    resetBorrower() {
        this.add_property.controls["addBemail"].setValue("");
        this.add_property.controls["addBfname"].setValue("");
        this.add_property.controls["addBlname"].setValue("");
    }

    removeBorrower(item) {
        this.additional_borrower = this.additional_borrower.filter(
            (filteritem) => {
                return filteritem.Email !== item.Email;
            }
        );
    }

    showAddForm() {
        this.additional_borrower = [];
        this.additional_borrower = [];
        this.isAdd = true;
        this.isList = false;
    }

    // ValidatePartnerPass () {
    //   const password = this.add_property.controls.password.value;
    //   const repeatPassword = this.add_property.controls.confirmPassword.value;
    //   if (repeatPassword !== password)
    //   {
    //     this.add_property.controls['confirmPassword'].setErrors({ 'incorrect': true });
    //   }
    //   else
    //   {
    //     this.add_property.controls['confirmPassword'].setErrors(null);
    //   }
    // }

    // Save Data
    SaveRecord() {
        this.submitted = true;
        if (this.add_property.invalid) {
            return;
        } else {
            const _this = this;
            _this.submitted = false;
            const formData = _this.add_property.value;
            _this.saveBorrower(function (brrowerItem) {
                _this.saveLoanOfficer(function (loItem) {
                    _this.saveLoanProcessor(function (lpItem) {
                        _this.saveRealtor(function (rItem) {
                            _this.saveGeneralContractor(function (rgctem) {
                                _this.SaveProperty(
                                    brrowerItem,
                                    rgctem,
                                    rItem,
                                    lpItem,
                                    loItem
                                );
                            });
                        });
                    });
                });
            });
        }
    }

    saveLoanOfficer(calback) {
        const formData = this.add_property.value;
        const sendPassword = this.generatePassword();
        if (
            (formData.LOid == null || formData.LOid == "") &&
            formData.loanOfficer == "other"
        ) {
            const lpdata = {
                EmailId: formData.LOemail,
                Password: sendPassword,
                UserType: this.sessionService.userType[4].Value,
                FirstName: formData.LOfname,
                LastName: formData.LOlname,
                Status: "Active",
                Phone: formData.LOphone_no,
                Address1: "Address1",
                Addresss2: "Address2",
                City: "City",
                State: "State",
                Zip: "Zip",
                Country: "Country",
                CreatedBy: this.userDetail.EmailId,
                LenderId: formData.lender,
            };

            this._bservice.AddBorrower(lpdata).subscribe(
                (lpresponse) => {
                    calback(lpresponse.Id);
                },
                (error) => {
                    this._bservice.GetUserByEmail(formData.LOemail).subscribe(
                        (response) => {
                            calback(response.Id);
                        },
                        (error) => {
                            this.sessionService.notifier.notify(
                                "error",
                                error.error.message
                            );
                            this.loading = false;
                        }
                    );
                }
            );
        } else if (
            (formData.LOid != "" || formData.LOid != null) &&
            formData.loanOfficer == "other"
        ) {
            calback(formData.LOid);
        } else {
            calback(formData.loanOfficer);
        }
    }

    saveLoanProcessor(calback) {
        const formData = this.add_property.value;
        const sendPassword = this.generatePassword();
        if (
            (formData.LPid == null || formData.LPid == "") &&
            formData.loanProcessor[0] == "other"
        ) {
            const lpdata = {
                EmailId: formData.LPemail,
                Password: sendPassword,
                UserType: this.sessionService.userType[5].Value,
                FirstName: formData.LPfname,
                LastName: formData.LPlname,
                Status: "Active",
                Phone: formData.LPphone_no,
                Address1: "Address1",
                Addresss2: "Address2",
                City: "City",
                State: "State",
                Zip: "Zip",
                Country: "Country",
                CreatedBy: this.userDetail.EmailId,
                LenderId: formData.lender,
            };

            this._bservice.AddBorrower(lpdata).subscribe(
                (lpresponse) => {
                    calback([lpresponse.Id]);
                },
                (error) => {
                    this._bservice.GetUserByEmail(formData.LPemail).subscribe(
                        (response) => {
                            calback([response.Id]);
                        },
                        (error) => {
                            this.sessionService.notifier.notify(
                                "error",
                                error.error.message
                            );
                            this.loading = false;
                        }
                    );
                }
            );
        } else if (
            (formData.LPid != "" || formData.LPid != null) &&
            formData.loanProcessor[0] == "other"
        ) {
            calback(formData.LPid);
        } else {
            calback(formData.loanProcessor);
        }
    }

    saveRealtor(calback) {
        const formData = this.add_property.value;
        const sendPassword = this.generatePassword();
        if (formData.RTid == null || formData.RTid == "") {
            if (formData.RTemail != null && formData.RTemail != "") {
                const rtdata = {
                    EmailId: formData.RTemail,
                    Password: sendPassword,
                    Status: "Active",
                    UserType: this.sessionService.userType[6].Value,
                    FirstName: formData.RTfname,
                    LastName: formData.RTlname,
                    Phone: formData.RTphone_no,
                    RealtorCompany: "RealtorCompany",
                    Address1: "Address1",
                    Addresss2: "Addresss2",
                    City: "City",
                    State: "State",
                    Zip: "302001",
                    Country: "Country",
                };
                this._bservice.AddBorrower(rtdata).subscribe(
                    (rtresponse) => {
                        calback(rtresponse.Id);
                    },
                    (error) => {
                        this._bservice
                            .GetUserByEmail(formData.RTemail)
                            .subscribe(
                                (response) => {
                                    calback(response.Id);
                                },
                                (error) => {
                                    this.sessionService.notifier.notify(
                                        "error",
                                        error.error.message
                                    );
                                    this.loading = false;
                                }
                            );
                    }
                );
            } else {
                calback(null);
            }
        } else {
            const data = {
                FirstName: formData.RTfname,
                Id: formData.RTid,
                LastName: formData.RTlname,
                Phone: formData.RTphone_no,
            };

            this._boservice.EditBorrower(data).subscribe(
                (response) => {
                    calback(formData.RTid);
                },
                (error) => {
                    this.sessionService.notifier.notify(
                        "error",
                        error.error.message
                    );
                    this.loading = false;
                }
            );
        }
    }

    saveGeneralContractor(calback) {
        const formData = this.add_property.value;
        if (this.tempinvitedGeneralContractors.length == 0) {
            if (formData.gcId == null || formData.gcId == "") {
                if (formData.gcemail != null && formData.gcemail != "") {
                    const sendPassword = this.generatePassword();
                    const data = {
                        EmailId: formData.gcemail,
                        Password: sendPassword,
                        UserType: this.sessionService.userType[3].Value,
                        FirstName: formData.gcfname,
                        LastName: formData.gclname,
                        Status: "Active",
                        Phone: formData.gcphone_no,
                        IDNumber: "",
                        Signature: "",
                        ContractorLicense: "",
                        LeadSafeCertified: "",
                        ServiceAreas: "",
                        GCNetwork: "",
                        CreatedBy: this.userDetail.EmailId,
                        Address1: "",
                        Addresss2: "",
                        City: "",
                        State: "",
                        Zip: "",
                        Country: "",
                    };
                    this._bservice.AddBorrower(data).subscribe(
                        (gcresponse) => {
                            calback(gcresponse.Id);
                        },
                        (error) => {
                            this._bservice
                                .GetUserByEmail(formData.gcemail)
                                .subscribe(
                                    (response) => {
                                        calback(response.Id);
                                    },
                                    (error) => {
                                        this.sessionService.notifier.notify(
                                            "error",
                                            error.error.message
                                        );
                                        this.loading = false;
                                    }
                                );
                        }
                    );
                } else {
                    calback(formData.gcId);
                }
            } else {
                const data = {
                    FirstName: formData.gcfname,
                    Id: formData.gcId,
                    LastName: formData.gclname,
                    Phone: formData.gcphone_no,
                };

                this._boservice.EditBorrower(data).subscribe(
                    (response) => {
                        calback(formData.gcId);
                    },
                    (error) => {
                        this.sessionService.notifier.notify(
                            "error",
                            error.error.message
                        );
                        this.loading = false;
                    }
                );
            }
        } else {
            formData.InvitedGeneralContractors =
                this.tempinvitedGeneralContractors;

            if (formData.InvitedGeneralContractors.length > 0) {
                calback(formData.InvitedGeneralContractors[0]);
            } else {
                calback(null);
            }
        }
    }

    saveBorrower(calback) {
        const formData = this.add_property.value;
        const sendPassword = this.generatePassword();
        if (formData.borrowerId == null || formData.borrowerId == "") {
            if (formData.email != null && formData.email != "") {
                const data = {
                    EmailId: formData.email,
                    Password: sendPassword,
                    UserType: this.sessionService.userType[0].Value,
                    FirstName: formData.fname,
                    LastName: formData.lname,
                    Status: "Active",
                    Phone: formData.phone_no,
                    Address1: "",
                    Addresss2: "",
                    City: "",
                    State: "",
                    Zip: "",
                    Country: "",
                };

                this._bservice.AddBorrower(data).subscribe(
                    (response) => {
                        calback(response.Id);
                    },
                    (error) => {
                        this._bservice.GetUserByEmail(formData.email).subscribe(
                            (response) => {
                                calback(response.Id);
                            },
                            (error) => {
                                this.sessionService.notifier.notify(
                                    "error",
                                    error.error.message
                                );
                                this.loading = false;
                            }
                        );
                    }
                );
            } else {
                calback(formData.borrowerId);
            }
        } else {
            const data = {
                FirstName: formData.fname,
                Id: formData.borrowerId,
                LastName: formData.lname,
                Phone: formData.phone_no,
            };

            this._boservice.EditBorrower(data).subscribe(
                (response) => {
                    calback(formData.borrowerId);
                },
                (error) => {
                    this.sessionService.notifier.notify(
                        "error",
                        error.error.message
                    );
                    this.loading = false;
                }
            );
        }
    }

    SaveProperty(
        borrowerId,
        GeneralContractorId,
        RealtorId,
        loanProcessor,
        loanOfficerId
    ) {
        if (borrowerId == null || borrowerId === "") {
            return;
        } else {
            this.submitted = false;
            const formData = this.add_property.value;
            var invitedGC = [];
            if (GeneralContractorId != null && GeneralContractorId != "") {
                invitedGC = formData.InvitedGeneralContractors;
            } else {
                invitedGC = null;
            }

            if (formData.id == null && !this.isEdit) {
                this.loading = true;
                const currentDate = new Date();
                const InspectionAddress = {
                    city: formData.city,
                    state: formData.state,
                    zipcode: formData.zipcode,
                    address1: formData.address1,
                    address2: formData.address2,
                };

                const data = {
                    Title: "Title-11",
                    ConsultantId: formData.consultant,
                    LoanOfficerId: loanOfficerId,
                    TransactionType: formData.transactionType,
                    BorrowerId: borrowerId,
                    InspectionAddress: InspectionAddress,
                    GeneralContractorId: GeneralContractorId,
                    FHACaseNo: formData.fHACaseNo,
                    BiddingDueDate: formData.biddingDueDate,
                    MetaTags: "MetaTags",
                    AcceptedProposal: formData.agreementSignedByBo,
                    InvitedGeneralContractors: invitedGC,
                    LoanType: formData.loanType,
                    OtherLoanType: "OtherLoanType",
                    RealtorId: RealtorId,
                    GCInviteOption: "GCInviteOption",
                    BiddingProcessStart: "BiddingProcessStart",
                    RenovationCompleted: "RenovationCompleted",
                    RenovationCompletedDate: "RenovationCompletedDate",
                    BidProcessStartDate: formData.bidProcessStartDate,
                    InspectionDate: formData.inspectionDate,
                    Status: "Property File created",
                    LenderId: formData.lender,
                    LoanProcessorId: loanProcessor,
                    Latitude: formData.latitude,
                    Longitude: formData.longitude,
                    CreatedDate: currentDate,
                    CreatedBy: this.userDetail.EmailId,
                    UpdateDate: currentDate,
                    UpdatedBy: this.userDetail.EmailId,
                    Borrowers: this.additional_borrower,
                };
                this._bservice.AddProperty(data).subscribe(
                    (response) => {
                        this.sessionService.notifier.notify(
                            "success",
                            "Property added successfully."
                        );
                        this.isList = true;
                        this.isAdd = false;
                        this.GetPropertyList();

                        this.loading = false;
                        window.scrollTo(0, 0);
                    },
                    (error) => {
                        this.sessionService.notifier.notify(
                            "error",
                            error.error.message
                        );
                        this.loading = false;
                    }
                );
            }
        }
    }

    // Save Data
    UpdateRecord() {
        this.submitted = true;
        if (this.add_property.invalid) {
            return;
        } else {
            const _this = this;
            _this.submitted = false;
            const formData = _this.add_property.value;
            _this.saveBorrower(function (brrowerItem) {
                _this.saveLoanOfficer(function (loItem) {
                    _this.saveLoanProcessor(function (lpItem) {
                        _this.saveRealtor(function (rItem) {
                            _this.saveGeneralContractor(function (rgctem) {
                                _this.UpdateProperty(
                                    brrowerItem,
                                    rgctem,
                                    rItem,
                                    lpItem,
                                    loItem
                                );
                            });
                        });
                    });
                });
            });
        }
    }

    UpdateProperty(
        borrowerId,
        GeneralContractorId,
        RealtorId,
        loanProcessor,
        loanOfficerId
    ) {
        if (borrowerId == null || borrowerId === "") {
            return;
        } else {
            this.submitted = false;
            const formData = this.add_property.value;

            if (GeneralContractorId != null && GeneralContractorId != "") {
                if (formData.InvitedGeneralContractors != null) {
                    if (
                        formData.InvitedGeneralContractors.indexOf(
                            GeneralContractorId
                        ) < 0
                    ) {
                        formData.InvitedGeneralContractors.push(
                            GeneralContractorId
                        );
                    }
                } else {
                    formData.InvitedGeneralContractors = [];
                    formData.InvitedGeneralContractors.push(
                        GeneralContractorId
                    );
                }
            }

            if (formData.id != null) {
                this.loading = true;
                const currentDate = new Date();
                const InspectionAddress = {
                    city: formData.city,
                    state: formData.state,
                    zipcode: formData.zipcode,
                    address1: formData.address1,
                    address2: formData.address2,
                };

                const data = {
                    Id: formData.id,
                    Title: "Title-11",
                    ConsultantId: formData.consultant,
                    LoanOfficerId: loanOfficerId,
                    TransactionType: formData.transactionType,
                    BorrowerId: borrowerId,
                    InspectionAddress: InspectionAddress,
                    GeneralContractorId: GeneralContractorId,
                    FHACaseNo: formData.fHACaseNo,
                    BiddingDueDate: formData.biddingDueDate,
                    MetaTags: "MetaTags",
                    AcceptedProposal: formData.agreementSignedByBo,
                    InvitedGeneralContractors:
                        formData.InvitedGeneralContractors,
                    LoanType: formData.loanType,
                    OtherLoanType: "OtherLoanType",
                    RealtorId: RealtorId,
                    GCInviteOption: "GCInviteOption",
                    BiddingProcessStart: "BiddingProcessStart",
                    RenovationCompleted: "RenovationCompleted",
                    RenovationCompletedDate: "RenovationCompletedDate",
                    BidProcessStartDate: formData.bidProcessStartDate,
                    InspectionDate: formData.inspectionDate,
                    Status: formData.statusType,
                    LenderId: formData.lender,
                    LoanProcessorId: loanProcessor,
                    Latitude: formData.latitude,
                    Longitude: formData.longitude,
                    CreatedDate: currentDate,
                    CreatedBy: this.userDetail.EmailId,
                    UpdateDate: currentDate,
                    UpdatedBy: this.userDetail.EmailId,
                    Borrowers: this.additional_borrower,
                };
                this._bservice.UpdateProperty(data).subscribe(
                    (response) => {
                        var data = {
                            EmailId: "",
                            PropertyId: formData.id,
                            PageSize: 100000,
                            LastEvaluatedKey: 0,
                            UserType: this.sessionService.userType[1].Value,
                        };
                        this._boservice.GetSorDetails(data).subscribe(
                            (response) => {
                                if (
                                    response != null &&
                                    response.Items.length > 0
                                ) {
                                    let propertyData = response.Items[0];
                                    propertyData.LoanNo = formData.LoanNo;
                                    this._boservice
                                        .UpdateSOR(propertyData)
                                        .subscribe(
                                            (response) => {
                                                this.sessionService.notifier.notify(
                                                    "success",
                                                    "Property Successfully Updated."
                                                );
                                                this.isList = true;
                                                this.isAdd = false;
                                                this.isEdit = false;
                                                const container =
                                                    document.querySelector(
                                                        "#container-3"
                                                    );
                                                container.scrollTop = 0;
                                                this.GetPropertyList();
                                                this.getPropertyDetails(
                                                    formData.id
                                                );
                                                this.loading = false;
                                            },
                                            (error) => {
                                                this.sessionService.notifier.notify(
                                                    "error",
                                                    error.error.message
                                                );
                                                this.loading = false;
                                            }
                                        );
                                }
                            },
                            (error) => {}
                        );
                    },
                    (error) => {
                        this.sessionService.notifier.notify(
                            "error",
                            error.error.message
                        );
                        this.loading = false;
                    }
                );
            }
        }
    }

    onBlurLoanProcessor() {
        const loanPrrocessor = this.loan_processor_list.filter((item) => {
            return (
                this.add_property.value.LPemail != null &&
                item.EmailId.toLowerCase() ===
                    this.add_property.value.LPemail.toLowerCase()
            );
        });
        if (loanPrrocessor.length > 0) {
            this.add_property.controls["LPemail"].setValue(
                loanPrrocessor[0].EmailId
            );
            this.add_property.controls["LPfname"].setValue(
                loanPrrocessor[0].FirstName
            );
            this.add_property.controls["LPlname"].setValue(
                loanPrrocessor[0].LastName
            );
            this.add_property.controls["LPphone_no"].setValue(
                loanPrrocessor[0].Phone
            );
            this.add_property.controls["LPid"].setValue(loanPrrocessor[0].Id);

            this.add_property.controls["LPpassword"].setValue(
                loanPrrocessor[0].Password
            );
            return;
        } else {
            this.add_property.controls["LPpassword"].setValue("");
            this.add_property.controls["LPfname"].setValue("");
            this.add_property.controls["LPlname"].setValue("");
            this.add_property.controls["LPphone_no"].setValue("");
            this.add_property.controls["LPid"].setValue(null);
        }
    }
    onLoanProcessorSelection(selectionValue) {
        if (selectionValue.isUserInput == true) {
            var selectedValue: any = [];
            var selectedObject: any = [];
            selectedObject = this.add_property.get("loanProcessor");
            selectedValue = selectedObject.value ? selectedObject.value : [];

            if (
                selectionValue.source.value == "other" &&
                selectionValue.source._selected == true
            ) {
                this.add_property.controls["loanProcessor"].setValue(["other"]);
            } else if (
                (selectionValue.source.value == "" ||
                    selectionValue.source.value == null) &&
                selectionValue.source._selected == true
            ) {
                this.add_property.controls["loanProcessor"].setValue([""]);
            } else {
                if (selectionValue.source._selected == true) {
                    let otherIndex: string = selectedValue.indexOf("other");
                    if (otherIndex != "-1") {
                        selectedValue.splice(otherIndex, 1);
                    }
                    let selectIndex: string = selectedValue.indexOf("");
                    if (selectIndex != "-1") {
                        selectedValue.splice(selectIndex, 1);
                    }
                    let currentIndex: string = selectedValue.indexOf(
                        selectionValue.source.value
                    );
                    if (currentIndex == "-1") {
                        selectedValue.push(selectionValue.source.value);
                    }

                    if (selectedValue) {
                        this.add_property.controls["loanProcessor"].setValue(
                            selectedValue
                        );
                    }
                } else {
                    let currentIndex: string = selectedValue.indexOf(
                        selectionValue.source.value
                    );

                    if (currentIndex != "-1") {
                        selectedValue.splice(currentIndex, 1);
                    }

                    if (selectedValue) {
                        this.add_property.controls["loanProcessor"].setValue(
                            selectedValue
                        );
                    }
                }
            }
        }
        return true;
    }
    onGCSelection(selectionValue) {
        if (selectionValue.checked) {
            let selectedVal = selectionValue.source.value;
            let isExist = false;
            for (
                let i = 0;
                i < this.tempinvitedGeneralContractors.length;
                i++
            ) {
                if (selectedVal == this.tempinvitedGeneralContractors[i]) {
                    isExist = true;
                }
            }
            if (isExist == false) {
                this.tempinvitedGeneralContractors.push(parseInt(selectedVal));
            }
        } else {
            let unselectedVal = selectionValue.source.value;

            for (
                let i = 0;
                i < this.tempinvitedGeneralContractors.length;
                i++
            ) {
                if (unselectedVal == this.tempinvitedGeneralContractors[i]) {
                    this.tempinvitedGeneralContractors.splice(i, 1);
                }
            }
        }

        return true;
    }
    onBlurLoanOfficer() {
        const loanOfficer = this.loan_officer_list.filter((item) => {
            return (
                this.add_property.value.LOemail != null &&
                item.EmailId.toLowerCase() ===
                    this.add_property.value.LOemail.toLowerCase()
            );
        });

        if (loanOfficer.length > 0) {
            this.add_property.controls["LOemail"].setValue(
                loanOfficer[0].EmailId
            );
            this.add_property.controls["LOfname"].setValue(
                loanOfficer[0].FirstName
            );
            this.add_property.controls["LOlname"].setValue(
                loanOfficer[0].LastName
            );
            this.add_property.controls["LOphone_no"].setValue(
                loanOfficer[0].Phone
            );
            this.add_property.controls["LOid"].setValue(loanOfficer[0].Id);

            this.add_property.controls["LOpassword"].setValue(
                loanOfficer[0].Password
            );
            return;
        } else {
            this.add_property.controls["LOpassword"].setValue("");
            this.add_property.controls["LOfname"].setValue("");
            this.add_property.controls["LOlname"].setValue("");
            this.add_property.controls["LOphone_no"].setValue("");
            this.add_property.controls["LOid"].setValue(null);
        }
    }

    onBlurBorrower() {
        const borrowers = this.borrower_list.filter((item) => {
            return (
                this.add_property.value.email != null &&
                item.EmailId.toLowerCase() ===
                    this.add_property.value.email.toLowerCase()
            );
        });

        if (borrowers.length > 0) {
            this.add_property.controls["email"].setValue(borrowers[0].EmailId);
            this.add_property.controls["fname"].setValue(
                borrowers[0].FirstName
            );
            this.add_property.controls["lname"].setValue(borrowers[0].LastName);
            this.add_property.controls["phone_no"].setValue(borrowers[0].Phone);
            this.add_property.controls["borrowerId"].setValue(borrowers[0].Id);
            return;
        } else {
            this.add_property.controls["fname"].setValue("");
            this.add_property.controls["lname"].setValue("");
            this.add_property.controls["phone_no"].setValue("");
            this.add_property.controls["borrowerId"].setValue(null);
        }
    }

    onBlurRealtor() {
        const realtors = this.realtor_list.filter((item) => {
            return (
                this.add_property.value.RTemail != null &&
                item.EmailId.toLowerCase() ===
                    this.add_property.value.RTemail.toLowerCase()
            );
        });

        if (realtors.length > 0) {
            this.add_property.controls["RTemail"].setValue(realtors[0].EmailId);
            this.add_property.controls["RTfname"].setValue(
                realtors[0].FirstName
            );
            this.add_property.controls["RTlname"].setValue(
                realtors[0].LastName
            );
            this.add_property.controls["RTphone_no"].setValue(
                realtors[0].Phone
            );
            this.add_property.controls["RTid"].setValue(realtors[0].Id);
            return;
        } else {
            this.add_property.controls["RTfname"].setValue("");
            this.add_property.controls["RTlname"].setValue("");
            this.add_property.controls["RTphone_no"].setValue("");
            this.add_property.controls["RTid"].setValue(null);
        }
    }

    onBlurGenralContractor() {
        const contractor = this.contractor_list.filter((item) => {
            return (
                this.add_property.value.gcemail != null &&
                item.EmailId.toLowerCase() ===
                    this.add_property.value.gcemail.toLowerCase()
            );
        });

        if (contractor.length > 0) {
            this.add_property.controls["gcemail"].setValue(
                contractor[0].EmailId
            );
            this.add_property.controls["gcfname"].setValue(
                contractor[0].FirstName
            );
            this.add_property.controls["gclname"].setValue(
                contractor[0].LastName
            );
            this.add_property.controls["gcphone_no"].setValue(
                contractor[0].Phone
            );
            this.add_property.controls["gcId"].setValue(contractor[0].Id);
            return;
        } else {
            this.add_property.controls["gcfname"].setValue("");
            this.add_property.controls["gclname"].setValue("");
            this.add_property.controls["gcphone_no"].setValue("");
            this.add_property.controls["gcId"].setValue(null);
        }
    }

    formReset() {
        this.add_property.reset();
        this.add_property.controls["statusType"].setValue("Active");
    }

    GetPropertyList() {
        this.loading = true;
        const data = {
            EmailId: "",
            PageSize: 100000,
            LastEvaluatedKey: 0,
            UserType: this.sessionService.userType[1].Value,
        };
        this._bservice.GetPropertyList(data).subscribe(
            (response) => {
                this.loading = false;
                if (response != null) {
                    this.propertyList.Items = [];
                    const propertyResult = response;

                    if (this.userDetail.UserType === "Borrower") {
                        for (let i = 0; i < propertyResult.Items.length; i++) {
                            if (
                                this.userDetail.Id ===
                                propertyResult.Items[i].BorrowerId
                            ) {
                                this.propertyList.Items.push(
                                    propertyResult.Items[i]
                                );
                                this.propertyList.Count =
                                    this.propertyList.Items.length;
                                this.propertyList.ScannedCount =
                                    this.propertyList.Items.length;
                                this.propertyList.TotalRecord =
                                    this.propertyList.Items.length;
                            }
                        }
                        if (this.propertyList.Items.length === 1) {
                            this.sendPropertyDetails(
                                this.propertyList.Items[0].Id
                            );
                        }
                    } else if (this.userDetail.UserType === "Consultants") {
                        for (let i = 0; i < propertyResult.Items.length; i++) {
                            if (
                                this.userDetail.Id ===
                                propertyResult.Items[i].ConsultantId
                            ) {
                                this.propertyList.Items.push(
                                    propertyResult.Items[i]
                                );
                                this.propertyList.Count =
                                    this.propertyList.Items.length;
                                this.propertyList.ScannedCount =
                                    this.propertyList.Items.length;
                                this.propertyList.TotalRecord =
                                    this.propertyList.Items.length;
                            }
                        }
                    } else {
                        this.propertyList = propertyResult;
                    }
                    this.propertyList.Items.sort(function (a, b) {
                        return a.Id < b.Id ? 1 : -1;
                    });
                    const users = Array.from(
                        { length: this.propertyList.Count },
                        (_, k) => createNewUser(k, this.propertyList)
                    );
                    this.dataSource = new MatTableDataSource(users);
                    this.dataSource.paginator = this.paginator;

                    this.loading = false;
                }
            },
            (error) => {
                this.loading = false;
            }
        );
    }

    GetloanOfficerUserList(calback) {
        const data = {
            EmailId: "",
            PageSize: 100000,
            LastEvaluatedKey: 0,
            UserType: this.sessionService.userType[4].Value,
        };
        this._bservice.GetLoanOfficerUserList(data).subscribe(
            (response) => {
                if (response != null) {
                    this.loan_officer_list = response.Items;
                    calback(this.loan_officer_list);
                }
            },
            (error) => {}
        );
    }

    GetloanProcessorUserList(calback) {
        const data = {
            EmailId: "",
            PageSize: 100000,
            LastEvaluatedKey: 0,
            UserType: this.sessionService.userType[5].Value,
        };
        this._bservice.GetLoanProcessorUserList(data).subscribe(
            (response) => {
                if (response != null) {
                    this.loan_processor_list = response.Items;
                    calback(this.loan_processor_list);
                }
            },
            (error) => {}
        );
    }

    GetConsultentUserList(calback) {
        const data = {
            EmailId: "",
            PageSize: 100000,
            LastEvaluatedKey: 0,
            UserType: this.sessionService.userType[2].Value,
        };
        this._bservice.GetConsultentUserList(data).subscribe(
            (response) => {
                if (response != null) {
                    this.consultent_list = response.Items;
                    calback(this.consultent_list);
                }
            },
            (error) => {}
        );
    }

    GetContractorUserList(calback) {
        const data = {
            EmailId: "",
            PageSize: 100000,
            LastEvaluatedKey: 0,
            UserType: this.sessionService.userType[3].Value,
        };
        this._bservice.GetContractorUserList(data).subscribe(
            (response) => {
                if (response != null) {
                    this.contractor_list = response.Items;
                    calback(this.contractor_list);
                }
            },
            (error) => {}
        );
    }

    GetBorrowerUserList(calback) {
        const data = {
            EmailId: "",
            PageSize: 100000,
            LastEvaluatedKey: 0,
            UserType: this.sessionService.userType[0].Value,
        };
        this._bservice.GetBorrowerUserList(data).subscribe(
            (response) => {
                if (response != null) {
                    this.borrower_list = response.Items;
                    calback(this.borrower_list);
                }
            },
            (error) => {}
        );
    }

    GetRealtorList(calback) {
        const data = {
            EmailId: "",
            PageSize: 100000,
            LastEvaluatedKey: 0,
            UserType: this.sessionService.userType[6].Value,
        };
        this._bservice.GetBorrowerUserList(data).subscribe(
            (response) => {
                if (response != null) {
                    this.realtor_list = response.Items;

                    calback(this.realtor_list);
                }
            },
            (error) => {}
        );
    }

    GetLenderList(calback) {
        const data = {
            EmailId: "",
            PageSize: 100000,
            LastEvaluatedKey: 0,
            UserType: this.sessionService.userType[4].Value,
        };
        this._bservice.GetLenderList(data).subscribe(
            (response) => {
                if (response != null) {
                    response.Items.sort(function (a, b) {
                        if (
                            a.Name.trim().toLowerCase() <
                            b.Name.trim().toLowerCase()
                        ) {
                            return -1;
                        }
                        if (
                            a.Name.trim().toLowerCase() >
                            b.Name.trim().toLowerCase()
                        ) {
                            return 1;
                        }
                        return 0;
                    });
                    this.lender_list = response.Items;

                    calback(this.lender_list);
                }
            },
            (error) => {}
        );
    }
    filterLOLP() {
        var lenderId = this.add_property.value.lender;
        if (lenderId != null && lenderId != "" && lenderId != undefined) {
            this.filter_loan_processor_list = [];

            for (let i = 0; i < this.loan_processor_list.length; i++) {
                if (lenderId == this.loan_processor_list[i].LenderId) {
                    this.filter_loan_processor_list.push(
                        this.loan_processor_list[i]
                    );
                }
            }

            this.filter_loan_officer_list = [];
            for (let i = 0; i < this.loan_officer_list.length; i++) {
                if (lenderId == this.loan_officer_list[i].LenderId) {
                    this.filter_loan_officer_list.push(
                        this.loan_officer_list[i]
                    );
                }
            }

            if (this.userDetail.UserType == "Loan Processor") {
                var selectedValue: any = [];
                selectedValue.push(String(this.userDetail.Id));
            }

            if (this.userDetail.UserType == "Loan Officer") {
                this._bservice.GetUserDetailsList(this.userDetail.Id).subscribe(
                    (LOResponse) => {
                        if (LOResponse != null) {
                            let loanOfficerList = [];
                            loanOfficerList.push(LOResponse);
                            this.filter_loan_officer_list = loanOfficerList;
                        }
                    },
                    (error) => {}
                );
            }
        } else {
            this.filter_loan_officer_list = [];
            this.filter_loan_processor_list = [];
        }
    }

    changePropertyStatus(target) {
        var status = "Inactive";
        if (target.checked) {
            status = "Active";
        } else {
            status = "Inactive";
        }
        var oldStatus = "";
        var newStatus = "";
        if (status == "Active") {
            oldStatus = "Inactive";
            newStatus = "Active";
        } else {
            oldStatus = "Active";
            newStatus = "Inactive";
        }
        const data = {
            Id: this.propertyId,
            PropertyStatus: status,
        };
        this._bservice.UpdatePropertyMainStatus(data).subscribe(
            (response) => {
                if (response != null) {
                    this.sessionService.notifier.notify(
                        "success",
                        "Property Status has been changed from " +
                            oldStatus +
                            " to " +
                            newStatus +
                            "."
                    );
                }
            },
            (error) => {}
        );
    }
    changePropertyStatusCompleted(target) {
        var status = false;
        if (target.checked) {
            status = true;
        } else {
            status = false;
        }
        const data = {
            Id: this.propertyId,
            IsCompleted: status,
        };
        var oldStatus = "";
        var newStatus = "";
        if (status) {
            oldStatus = "Incompleted";
            newStatus = "Completed";
        } else {
            oldStatus = "Completed";
            newStatus = "Incompleted";
        }
        var oldStatus = "";
        var newStatus = "";
        this._bservice.UpdatePropertyCompletedStatus(data).subscribe(
            (response) => {
                if (response != null) {
                    this.sessionService.notifier.notify(
                        "success",
                        "Property Status has been changed from " +
                            oldStatus +
                            " to " +
                            newStatus +
                            "."
                    );
                }
            },
            (error) => {}
        );
    }
    EditUser(item) {
        this.loading = false;
        this.PropertyStatusChecked =
            item.PropertyStatus == "Active" ? true : false;
        this.PropertyCompletedChecked = item.IsCompleted == true ? true : false;
        this.invitedGeneralContractors = [];
        this.tempinvitedGeneralContractors = [];
        let borrowerData: any = {};
        for (let i = 0; i < this.borrower_list.length; i++) {
            if (item.BorrowerId === this.borrower_list[i].Id) {
                borrowerData = this.borrower_list[i];
            }
        }

        let GCData: any = {
            EmailId: "",
            FirstName: "",
            LastName: "",
            Phone: "",
            Id: "",
        };
        for (let i = 0; i < this.contractor_list.length; i++) {
            if (item.GeneralContractorId === this.contractor_list[i].Id) {
                GCData = this.contractor_list[i];
            }
            if (item.InvitedGeneralContractors != null) {
                for (
                    let j = 0;
                    j < item.InvitedGeneralContractors.length;
                    j++
                ) {
                    if (
                        item.InvitedGeneralContractors[j] ===
                        this.contractor_list[i].Id
                    ) {
                        this.invitedGeneralContractors.push(
                            this.contractor_list[i]
                        );
                        this.tempinvitedGeneralContractors.push(
                            this.contractor_list[i].Id
                        );
                    }
                }
            }
        }

        let RTData: any = {
            EmailId: "",
            FirstName: "",
            LastName: "",
            Phone: "",
            Id: "",
        };
        for (let i = 0; i < this.realtor_list.length; i++) {
            if (item.RealtorId === this.realtor_list[i].Id) {
                RTData = this.realtor_list[i];
            }
        }

        if (item.LoanOfficerId != null && item.LoanOfficerId !== "") {
            item.LoanOfficerId = item.LoanOfficerId.toString();
        }
        if (item.LoanProcessorId != null && item.LoanProcessorId !== "") {
            const loanProcessorID: any = item.LoanProcessorId.toString();
            item.LoanProcessorId = loanProcessorID.split(",");
        }
        if (item.ConsultantId != null && item.ConsultantId !== "") {
            item.ConsultantId = item.ConsultantId.toString();
        }

        if (item.LenderId != null && item.LenderId !== "") {
            item.LenderId = item.LenderId.toString();
        }

        var loanNumber = null;
        var data = {
            EmailId: "",
            PropertyId: this.propertyId,
            PageSize: 100000,
            LastEvaluatedKey: 0,
            UserType: this.sessionService.userType[1].Value,
        };
        this._boservice.GetSorDetails(data).subscribe(
            (response) => {
                if (response != null && response.Items.length > 0) {
                    loanNumber = response.Items[0].LoanNo;
                    this.add_property.setValue({
                        email: borrowerData.EmailId,
                        fname: borrowerData.FirstName,
                        lname: borrowerData.LastName,
                        phone_no: borrowerData.Phone,
                        borrowerId: borrowerData.Id,
                        gcemail: GCData.EmailId,
                        gcfname: GCData.FirstName,
                        gclname: GCData.LastName,
                        gcphone_no: GCData.Phone,
                        gcId: GCData.Id,
                        RTemail: RTData.EmailId,
                        RTfname: RTData.FirstName,
                        RTlname: RTData.LastName,
                        RTphone_no: RTData.Phone,
                        RTid: RTData.Id,
                        LPemail: null,
                        LPfname: null,
                        LPlname: null,
                        LPphone_no: null,
                        LPpassword: Math.random().toString(36).slice(-8),
                        LPid: null,
                        statusType: item.Status,
                        consultant: item.ConsultantId,
                        loanOfficer: item.LoanOfficerId,
                        transactionType: item.TransactionType,
                        home_borrower: borrowerData.Id,
                        city: item.InspectionAddress.city,
                        state: item.InspectionAddress.state,
                        zipcode: item.InspectionAddress.zipcode,
                        address1: item.InspectionAddress.address1,
                        address2: item.InspectionAddress.address2,
                        agreementSignedByBo: item.AcceptedProposal,
                        fHACaseNo: item.FHACaseNo,
                        LoanNo: loanNumber,
                        loanType: item.LoanType,
                        realtor: item.RealtorId,
                        renovationCompleted: item.RenovationCompleted,
                        renovationCompletedDate: item.RenovationCompletedDate,
                        bidProcessStartDate: item.BidProcessStartDate,
                        inspectionDate: item.InspectionDate,
                        biddingDueDate: item.BiddingDueDate,
                        propertyStatus: item.Status,
                        lender: item.LenderId,
                        loanProcessor: item.LoanProcessorId,
                        latitude: item.Latitude,
                        longitude: item.Longitude,
                        id: item.Id,
                        LOemail: null,
                        LOfname: null,
                        LOlname: null,
                        LOphone_no: null,
                        LOpassword: Math.random().toString(36).slice(-8),
                        LOid: null,
                        addBemail: "",
                        addBfname: "",
                        addBlname: "",
                        InvitedGeneralContractors:
                            item.InvitedGeneralContractors,
                    });

                    this.additional_borrower =
                        item.Borrowers == null ? [] : item.Borrowers;
                    if (this.additional_borrower.length > 0) {
                        this.displayAddBorrower = true;
                    }

                    this.filterLOLP();
                    this.isEdit = true;
                } else {
                    loanNumber = null;
                    this.add_property.setValue({
                        email: borrowerData.EmailId,
                        fname: borrowerData.FirstName,
                        lname: borrowerData.LastName,
                        phone_no: borrowerData.Phone,
                        borrowerId: borrowerData.Id,
                        gcemail: GCData.EmailId,
                        gcfname: GCData.FirstName,
                        gclname: GCData.LastName,
                        gcphone_no: GCData.Phone,
                        gcId: GCData.Id,
                        RTemail: RTData.EmailId,
                        RTfname: RTData.FirstName,
                        RTlname: RTData.LastName,
                        RTphone_no: RTData.Phone,
                        RTid: RTData.Id,
                        LPemail: null,
                        LPfname: null,
                        LPlname: null,
                        LPphone_no: null,
                        LPpassword: Math.random().toString(36).slice(-8),
                        LPid: null,
                        statusType: item.Status,
                        consultant: item.ConsultantId,
                        loanOfficer: item.LoanOfficerId,
                        transactionType: item.TransactionType,
                        home_borrower: borrowerData.Id,
                        city: item.InspectionAddress.city,
                        state: item.InspectionAddress.state,
                        zipcode: item.InspectionAddress.zipcode,
                        address1: item.InspectionAddress.address1,
                        address2: item.InspectionAddress.address2,
                        agreementSignedByBo: item.AcceptedProposal,
                        fHACaseNo: item.FHACaseNo,
                        LoanNo: loanNumber,
                        loanType: item.LoanType,
                        realtor: item.RealtorId,
                        renovationCompleted: item.RenovationCompleted,
                        renovationCompletedDate: item.RenovationCompletedDate,
                        bidProcessStartDate: item.BidProcessStartDate,
                        inspectionDate: item.InspectionDate,
                        biddingDueDate: item.BiddingDueDate,
                        propertyStatus: item.Status,
                        lender: item.LenderId,
                        loanProcessor: item.LoanProcessorId,
                        latitude: item.Latitude,
                        longitude: item.Longitude,
                        id: item.Id,
                        LOemail: null,
                        LOfname: null,
                        LOlname: null,
                        LOphone_no: null,
                        LOpassword: Math.random().toString(36).slice(-8),
                        LOid: null,
                        addBemail: "",
                        addBfname: "",
                        addBlname: "",
                        InvitedGeneralContractors:
                            item.InvitedGeneralContractors,
                    });

                    this.additional_borrower =
                        item.Borrowers == null ? [] : item.Borrowers;
                    if (this.additional_borrower.length > 0) {
                        this.displayAddBorrower = true;
                    }

                    this.filterLOLP();
                    this.isEdit = true;
                }
            },
            (error) => {}
        );
    }

    generatePassword() {
        const password = Math.random().toString(36).slice(-8);
        return password;
    }

    getPropertyDetails(propertyId) {
        this._bservice.GetPropertyDetails(propertyId).subscribe(
            (response) => {
                if (response != null) {
                    const _this = this;
                    _this.submitted = false;
                    const formData = _this.add_property.value;
                    _this.GetloanOfficerUserList(function (brrowerList) {
                        _this.GetBorrowerUserList(function (loList) {
                            _this.GetloanProcessorUserList(function (lpList) {
                                _this.GetConsultentUserList(function (rList) {
                                    _this.GetContractorUserList(function (
                                        rList
                                    ) {
                                        _this.GetLenderList(function (rgcList) {
                                            _this.GetRealtorList(function (
                                                rgList
                                            ) {
                                                _this.EditUser(response);
                                            });
                                        });
                                    });
                                });
                            });
                        });
                    });
                }
            },
            (error) => {}
        );
    }

    sendPropertyDetails(id) {
        const path = "/property-details/" + id;
        this.router.navigate([path]);
    }
}

function createNewUser(id: number, userList): UserData {
    const name =
        NAMES[Math.round(Math.random() * (NAMES.length - 1))] +
        " " +
        NAMES[Math.round(Math.random() * (NAMES.length - 1))].charAt(0) +
        ".";
    if (userList.Items[id] !== undefined) {
        userList.Items[id].id = id + 1;
        return userList.Items[id];
    }
}
