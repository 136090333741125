import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { HttpClientModule } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule, Routes } from "@angular/router";
import { MatMomentDateModule } from "@angular/material-moment-adapter";

import { TranslateModule } from "@ngx-translate/core";

import { FuseModule } from "@fuse/fuse.module";
import { FuseSharedModule } from "@fuse/shared.module";
import { FuseProgressBarModule } from "@fuse/components/progress-bar/progress-bar.module";
import { FuseSidebarModule } from "@fuse/components/sidebar/sidebar.module";
import { FuseThemeOptionsModule } from "@fuse/components/theme-options/theme-options.module";
import { fuseConfig } from "app/fuse-config";
import { NotifierModule, NotifierOptions } from "angular-notifier";
import { NgxLoadingModule } from "ngx-loading";
import { AppComponent } from "app/app.component";
import { LayoutModule } from "app/layout/layout.module";
import { DashboardModule } from "../app/main/dashboard/dashboard.module";
import { LoginModule } from "../app/main/login/login.module";
import { BorrowerModule } from "../app/main/borrower/borrower.module";
import { AllowableFeesModule } from "../app/main/allowable-fees/allowable-fees.module";
import { ContractorModule } from "../app/main/contractor/contractor.module";
import { ConsultantModule } from "../app/main/consultant/consultant.module";
import { LoanOfficerModule } from "../app/main/loan-officer/loanOfficer.module";
import { LoanProcessorModule } from "../app/main/loan-processor/loanProcessor.module";
import { DrawAdministratorModule } from "../app/main/draw-administrator/drawAdministrator.module";
import { RealtorModule } from "./main/realtor/realtor.module";
import { PropertyModule } from "./main/property/property.module";
import { PropertyGalleryListingModule } from "./main/property-gallery-listing/property-gallery-listing.module";
import { PropertyEditModule } from "./main/property-edit/property-edit.module";
import { LenderModule } from "./main/lender/lender.module";
import { ConfigModule } from "./main/config/config.module";
import { PermissionModule } from "./main/permission/permission.module";
import { SubmittedBidsModule } from "./main/submitted-bids/submitted-bids.module";
import { InitialDrawRequestBoModule } from "./main/initial-draw-request-bo/initial-draw-request-bo.module";
import { InitialDrawRequestCoModule } from "./main/initial-draw-request-co/initial-draw-request-co.module";
import { GcSubmittedBidsModule } from "./main/gc-submitted-bids/gc-submitted-bids.module";
import { RoleModule } from "./main/role/role.module";
import { FormSettingModule } from "../app/main/consultant-liability-form-setting/form-setting.module";
import { ConsultantLiabilityModule } from "../app/main/consultant-liability/consultant-liability.module";
import { FhaConsultantAgreementModule } from "../app/main/fha-consultant-agreement/fha-consultant-agreement.module";
//import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { CKEditorModule } from "ckeditor4-angular";
import { PropertyDetailsModule } from "../app/main/property-details/property-details.module";
import { GeneralContractorInfoModule } from "../app/main/general-contractor-info/general-contractor-info.module";
import { SpecificationRepairReviewModule } from "../app/main/specification-repair-review/specification-repair-review.module";
import { InitialDrawRequestModule } from "../app/main/initial-draw-request/initial-draw-request.module";
import { PermitsAndCertificationsRequiredModule } from "../app/main/permits-and-certifications-required/permits-and-certifications-required.module";
import { ChangeOrderModule } from "../app/main/change-order/change-order.module";
import { PropertyLocationsRepairItemInfoModule } from "../app/main/property-locations-repair-item-info/property-locations-repair-item-info.module";
import { PropertyGalleryModule } from "../app/main/property-gallery/property-gallery.module";
import { CoverSheetModule } from "../app/main/cover-sheet/cover-sheet.module";
import { ConsultantIdentityInterestModule } from "../app/main/consultant-identity-interest/consultant-identity-interest.module";
import { NonHomeInspectionDisclosureModule } from "../app/main/non-home-inspection-disclosure/non-home-inspection-disclosure.module";

import { BorrowerDashboardModule } from "../app/main/borrower-dashboard/borrower-dashboard.module";
import { EmailModule } from "./main/email/email.module";
import { ConstructionDetailModule } from "../app/main/construction-detail/construction-detail.module";
import { ConstructionItemsLibraryModule } from "../app/main/construction-items-library/construction-items-library.module";
import { PropertyInputBidModule } from "../app/main/property-input-bid/property-input-bid.module";
import { SafePipe } from "../app/main/specification-repair-review/specification-repair-review.component";
import {
    LocationStrategy,
    Location,
    PathLocationStrategy,
} from "@angular/common";
import { GooglePlaceModule } from "@yuvarajv/ngx-google-places-autocomplete";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatTableModule } from "@angular/material/table";
import { MatTabsModule } from "@angular/material/tabs";
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';

const appRoutes: Routes = [
    {
        path: "**",
        redirectTo: "login",
    },
    {
        path: "dashboard",
        redirectTo: "dashboard",
    },
];
const customNotifierOptions: NotifierOptions = {
    position: {
        horizontal: {
            position: "right",
            distance: 12,
        },
        vertical: {
            position: "top",
            distance: 12,
            gap: 10,
        },
    },
    theme: "material",
    behaviour: {
        autoHide: 5000,
        onClick: "hide",
        onMouseover: "pauseAutoHide",
        showDismissButton: true,
        stacking: 4,
    },
    animations: {
        enabled: true,
        show: {
            preset: "slide",
            speed: 300,
            easing: "ease",
        },
        hide: {
            preset: "fade",
            speed: 300,
            easing: "ease",
            offset: 50,
        },
        shift: {
            speed: 300,
            easing: "ease",
        },
        overlap: 150,
    },
};

@NgModule({
    declarations: [AppComponent, SafePipe],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        NotifierModule.withConfig(customNotifierOptions),
        NgxLoadingModule.forRoot({}),
        RouterModule.forRoot(appRoutes),
        TranslateModule.forRoot(),
        // Material moment date module
        MatMomentDateModule,
        // Material
        MatButtonModule,
        MatCardModule,
        MatFormFieldModule,
        MatInputModule,
        MatTableModule,
        MatPaginatorModule,
        MatIconModule,
        MatRadioModule,
        MatCheckboxModule,
        MatSelectModule,
        MatDatepickerModule,
        MatTabsModule,
        CKEditorModule,
        MatExpansionModule,
        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,

        // App modules
        LayoutModule,
        DashboardModule,
        LoginModule,
        GooglePlaceModule,
        BorrowerModule,
        ContractorModule,
        ConsultantModule,
        LoanOfficerModule,
        LoanProcessorModule,
        DrawAdministratorModule,
        RealtorModule,
        PropertyModule,
        LenderModule,
        ConfigModule,
        PermissionModule,
        ConsultantLiabilityModule,
        FhaConsultantAgreementModule,
        RoleModule,
        PropertyDetailsModule,
        InitialDrawRequestModule,
        PermitsAndCertificationsRequiredModule,
        ChangeOrderModule,
        PropertyLocationsRepairItemInfoModule,
        SpecificationRepairReviewModule,
        PropertyGalleryModule,
        CoverSheetModule,
        ConsultantIdentityInterestModule,
        NonHomeInspectionDisclosureModule,
        EmailModule,
        BorrowerDashboardModule,
        ConstructionDetailModule,
        ConstructionItemsLibraryModule,
        GeneralContractorInfoModule,
        AllowableFeesModule,
        PropertyEditModule,
        PropertyInputBidModule,
        PropertyGalleryListingModule,
        SubmittedBidsModule,
        GcSubmittedBidsModule,
        InitialDrawRequestBoModule,
        InitialDrawRequestCoModule,
        FormSettingModule,
        NgxMaskDirective,
        NgxMaskPipe
        
    ],
    providers: [
        Location,
        { provide: LocationStrategy, useClass: PathLocationStrategy },
        provideNgxMask()
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
