import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { SessionService } from "../service/session.service";

@Injectable({
    providedIn: "root",
})
export class LoginService {
    constructor(
        private http: HttpClient,
        private _sessionService: SessionService
    ) {}
    baseurl = this._sessionService.endpoint;
    login(data) {
        return this.http.post<any>(this.baseurl + "users/authenticate", data);
    }

    forgotpassword(data) {
        return this.http.post<any>(this.baseurl + "users/forgotpassword", data);
    }
}
