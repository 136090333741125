<ngx-loading class="ngxLoader" 
    [show]="loading"
    [config]="{ backdropBorderRadius: '3px' }"
></ngx-loading>
<div
    class="content p-24 tbl"
    *ngIf="userDetail.UserType != 'General Contractors'"
>
    <h1 class="m-0">Manage Property</h1>
    <a
        style="margin-top: -30px"
        *ngIf="
            isList &&
            userDetail.UserType != 'Borrower' &&
            userDetail.UserType != 'Realtor'
        "
        class="addBorrow"
        href="javascript:void(0)"
        (click)="showAddForm()"
    >
        <mat-icon
            _ngcontent-c102=""
            class="secondary-text s-22 mat-icon material-icons mat-icon-no-color"
            role="img"
            aria-hidden="true"
            >add_circle</mat-icon
        >
        Add New Property</a
    >
    <a
        style="margin-top: -30px"
        *ngIf="isAdd"
        class="addBorrow"
        href="javascript:void(0)"
        (click)="isAdd = false; isList = true; isEdit = false"
    >
        <mat-icon
            _ngcontent-c102=""
            class="secondary-text s-22 mat-icon material-icons mat-icon-no-color"
            role="img"
            aria-hidden="true"
            >view_list</mat-icon
        >
        List Property</a
    >
    <mat-form-field class="searchFilter">
        <span class="mat-form-field-label-wrapper"
            ><!----><label
                class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                >Filter</label
            ></span
        >
        <input
            [hidden]="!isList"
            matInput
            (keyup)="applyFilter($event.target)"
        />
    </mat-form-field>

    <mat-checkbox
        style="float: right; margin-left: 30px; margin-top: 25px"
        class="example-radio-group"
        (change)="changeInactiveChecked($event)"
        [checked]="InactiveChecked"
        >Inactive</mat-checkbox
    >
    &nbsp;&nbsp;&nbsp;&nbsp;
    <mat-checkbox
        style="float: right; margin-top: 25px"
        class="example-radio-group"
        (change)="changeCompletedChecked($event)"
        [checked]="CompletedChecked"
        >Completed</mat-checkbox
    >

    <div class="mat-elevation-z8" [hidden]="!isList">
        <table mat-table [dataSource]="dataSource" matSort>
            <!-- ID Column -->
            <ng-container matColumnDef="id">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    class="padding5"
                    width="5%"
                    mat-sort-header
                >
                    ID
                </th>
                <td mat-cell *matCellDef="let row">
                    <span
                        *ngIf="
                            row.PropertyStatus != 'Inactive' &&
                            row.IsCompleted != true &&
                            userDetail.UserType != 'Borrower'
                        "
                    >
                        {{ row.id }}
                    </span>
                    <span
                        *ngIf="
                            (row.PropertyStatus == 'Inactive' ||
                                row.IsCompleted == true) &&
                            userDetail.UserType != 'Borrower'
                        "
                        class="propertystatus_inactive"
                    >
                        {{ row.id }}
                    </span>
                </td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="InspectionAddress">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    class="padding5"
                    width="25%"
                    mat-sort-header
                >
                    Property Address
                </th>
                <td
                    mat-cell
                    *matCellDef="let row"
                    style="word-wrap: break-word; word-break: break-all"
                >
                    <span
                        *ngIf="
                            row.PropertyStatus != 'Inactive' &&
                            row.IsCompleted != true &&
                            userDetail.UserType != 'Borrower'
                        "
                    >
                        {{
                            row?.InspectionAddress?.address1
                                ? row?.InspectionAddress?.address1 + ", "
                                : ""
                        }}
                        {{
                            row?.InspectionAddress?.address2
                                ? row?.InspectionAddress?.address2 + ", "
                                : ""
                        }}{{
                            row?.InspectionAddress?.city
                                ? row?.InspectionAddress?.city + ", "
                                : ""
                        }}
                        {{
                            row?.InspectionAddress?.state
                                ? row?.InspectionAddress?.state + ", "
                                : ""
                        }}
                        {{ row?.InspectionAddress?.zipcode }}
                    </span>
                    <span
                        *ngIf="
                            (row.PropertyStatus == 'Inactive' ||
                                row.IsCompleted == true) &&
                            userDetail.UserType != 'Borrower'
                        "
                        class="propertystatus_inactive"
                        style="word-wrap: break-word; word-break: break-all"
                    >
                        {{
                            row?.InspectionAddress?.address1
                                ? row?.InspectionAddress?.address1 + ", "
                                : ""
                        }}
                        {{
                            row?.InspectionAddress?.address2
                                ? row?.InspectionAddress?.address2 + ", "
                                : ""
                        }}{{
                            row?.InspectionAddress?.city
                                ? row?.InspectionAddress?.city + ", "
                                : ""
                        }}
                        {{
                            row?.InspectionAddress?.state
                                ? row?.InspectionAddress?.state + ", "
                                : ""
                        }}
                        {{ row?.InspectionAddress?.zipcode }}
                    </span>
                </td>
            </ng-container>

            <ng-container matColumnDef="Consultant">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    width="12%"
                    class="padding5"
                    mat-sort-header
                >
                    Consultant
                </th>
                <td mat-cell *matCellDef="let row">
                    <span
                        *ngIf="
                            row.PropertyStatus != 'Inactive' &&
                            row.IsCompleted != true &&
                            userDetail.UserType != 'Borrower'
                        "
                    >
                        {{ row.ConsultantName }}
                    </span>
                    <span
                        *ngIf="
                            (row.PropertyStatus == 'Inactive' ||
                                row.IsCompleted == true) &&
                            userDetail.UserType != 'Borrower'
                        "
                        class="propertystatus_inactive"
                    >
                        {{ row.ConsultantName }}
                    </span>
                </td>
            </ng-container>

            <ng-container matColumnDef="Borrower">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    class="padding5"
                    width="12%"
                    mat-sort-header
                >
                    Borrower
                </th>
                <td mat-cell *matCellDef="let row">
                    <span
                        *ngIf="
                            row.PropertyStatus != 'Inactive' &&
                            row.IsCompleted != true &&
                            userDetail.UserType != 'Borrower'
                        "
                    >
                        {{ row.BorrowerName }}
                    </span>
                    <span
                        *ngIf="
                            (row.PropertyStatus == 'Inactive' ||
                                row.IsCompleted == true) &&
                            userDetail.UserType != 'Borrower'
                        "
                        class="propertystatus_inactive"
                    >
                        {{ row.BorrowerName }}
                    </span>
                </td>
            </ng-container>

            <ng-container matColumnDef="LoanOfficer">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    class="padding5"
                    width="12%"
                    mat-sort-header
                >
                    Loan Officer
                </th>
                <td mat-cell *matCellDef="let row">
                    <span
                        *ngIf="
                            row.PropertyStatus != 'Inactive' &&
                            row.IsCompleted != true &&
                            userDetail.UserType != 'Borrower'
                        "
                    >
                        {{ row.LoanOfficerName }}
                    </span>
                    <span
                        *ngIf="
                            (row.PropertyStatus == 'Inactive' ||
                                row.IsCompleted == true) &&
                            userDetail.UserType != 'Borrower'
                        "
                        class="propertystatus_inactive"
                    >
                        {{ row.LoanOfficerName }}
                    </span>
                </td>
            </ng-container>

            <!-- Progress Column -->
            <ng-container matColumnDef="LoanType">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    class="padding5"
                    width="10%"
                    mat-sort-header
                >
                    Loan Type
                </th>
                <td mat-cell *matCellDef="let row">
                    <span
                        *ngIf="
                            row.PropertyStatus != 'Inactive' &&
                            row.IsCompleted != true &&
                            userDetail.UserType != 'Borrower'
                        "
                    >
                        {{ row.LoanType }}
                    </span>
                    <span
                        *ngIf="
                            (row.PropertyStatus == 'Inactive' ||
                                row.IsCompleted == true) &&
                            userDetail.UserType != 'Borrower'
                        "
                        class="propertystatus_inactive"
                    >
                        {{ row.LoanType }}
                    </span>
                </td>
            </ng-container>

            <!-- Color Column -->
            <ng-container matColumnDef="status">
                <th
                    mat-header-cell
                    width="10%"
                    class="padding5"
                    *matHeaderCellDef
                    mat-sort-header
                >
                    <span *ngIf="userDetail.UserType != 'Borrower'"
                        >Status</span
                    >
                </th>
                <td mat-cell *matCellDef="let row" [style.color]="row.Status">
                    <span
                        *ngIf="
                            row.PropertyStatus != 'Inactive' &&
                            row.IsCompleted != true &&
                            userDetail.UserType != 'Borrower'
                        "
                        >{{ row.Status }}</span
                    >
                    <span
                        *ngIf="
                            row.PropertyStatus == 'Inactive' &&
                            userDetail.UserType != 'Borrower'
                        "
                        class="propertystatus_inactive"
                        >Inactive</span
                    >
                    <span
                        *ngIf="
                            row.IsCompleted == true &&
                            userDetail.UserType != 'Borrower'
                        "
                        class="propertystatus_inactive"
                        >Completed</span
                    >
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

            <tr
                mat-row
                *matRowDef="let row; columns: displayedColumns"
                style="cursor: pointer"
                (click)="sendPropertyDetails(row.Id)"
            >
                <!-- routerLink="/property-details" -->
            </tr>
        </table>

        <mat-paginator [pageSizeOptions]="[20, 50, 100]"></mat-paginator>
    </div>
    <div class="addPropertyForm mat-elevation-z8" *ngIf="isAdd || isEdit">
        <div class="container-login100">
            <mat-card class="my-form">
                <form
                    [formGroup]="add_property"
                    class="example-form"
                    (ngSubmit)="!isEdit ? SaveRecord() : UpdateRecord()"
                >
                    <mat-card-header>
                        <mat-card-title
                            >{{
                                isEdit ? "Edit" : "Add"
                            }}
                            Property</mat-card-title
                        >
                    </mat-card-header>
                    <mat-card-content>
                        <mat-card>
                            <mat-card-header>
                                <mat-card-title
                                    >Property Address</mat-card-title
                                >
                            </mat-card-header>
                            <mat-card-content>
                                <table>
                                    <tbody>
                                        <!-- <tr>
                       <td>
                          <label>Property Address</label>
                          <input ngx-google-places-autocomplete  class="addressAutoField"  (onAddressChange)="AddressChange($event)"/> 
                       
                       </td>
                    </tr> -->
                                        <tr>
                                            <td>
                                                <mat-form-field
                                                    class="example-full-width"
                                                >
                                                    <span
                                                        class="mat-form-field-label-wrapper"
                                                        ><!----><label
                                                            class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                            >Address 1 *</label
                                                        ></span
                                                    >
                                                    <input
                                                        matInput
                                                        ngx-google-places-autocomplete
                                                        autocomplete="new-password"
                                                        (onAddressChange)="
                                                            AddressChange(
                                                                $event
                                                            )
                                                        "
                                                        formControlName="address1"
                                                    />
                                                    <div
                                                    class="error"
                                                    *ngIf="
                                                        (!add_property.controls[
                                                            'address1'
                                                        ].valid &&
                                                        add_property
                                                                .controls[
                                                                'address1'
                                                            ].touched) ||
                                                        (add_property.controls[
                                                            'address1'
                                                        ].invalid &&
                                                            submitted)
                                                    "
                                                >
                                                Enter valid address
                                                </div>
                                                </mat-form-field>
                                            </td>
                                            <td>
                                                <mat-form-field
                                                    class="example-full-width"
                                                >
                                                    <span
                                                        class="mat-form-field-label-wrapper"
                                                        ><!----><label
                                                            class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                            >Address 2</label
                                                        ></span
                                                    >
                                                    <input
                                                        matInput
                                                        formControlName="address2"
                                                        autocomplete="new-password"
                                                    />
                                                </mat-form-field>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <mat-form-field
                                                    class="example-full-width"
                                                >
                                                    <span
                                                        class="mat-form-field-label-wrapper"
                                                        ><!----><label
                                                            class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                            >City *</label
                                                        ></span
                                                    >
                                                    <input
                                                        matInput
                                                        formControlName="city"
                                                        autocomplete="new-password"
                                                    />
                                                    <div
                                                    class="error"
                                                    *ngIf="
                                                        (!add_property.controls[
                                                            'city'
                                                        ].valid &&
                                                        add_property
                                                                .controls[
                                                                'city'
                                                            ].touched) ||
                                                        (add_property.controls[
                                                            'city'
                                                        ].invalid &&
                                                            submitted)
                                                    "
                                                >
                                                Enter valid city
                                                </div>
                                                </mat-form-field>

                                            </td>
                                            <td>
                                                <mat-form-field
                                                    class="example-full-width"
                                                >
                                                    <span
                                                        class="mat-form-field-label-wrapper"
                                                        ><!----><label
                                                            class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                            >State *</label
                                                        ></span
                                                    >
                                                    <input
                                                        matInput
                                                        formControlName="state"
                                                        autocomplete="new-password"
                                                    />
                                                    <div
                                                    class="error"
                                                    *ngIf="
                                                        (!add_property.controls[
                                                            'state'
                                                        ].valid &&
                                                        add_property
                                                                .controls[
                                                                'state'
                                                            ].touched) ||
                                                        (add_property.controls[
                                                            'state'
                                                        ].invalid &&
                                                            submitted)
                                                    "
                                                >
                                                Enter valid state
                                                </div>
                                                </mat-form-field>
                                            </td>
                                            <td>
                                                <mat-form-field
                                                    class="example-full-width"
                                                >
                                                    <span
                                                        class="mat-form-field-label-wrapper"
                                                        ><!----><label
                                                            class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                            >Zip code</label
                                                        ></span
                                                    >
                                                    <input
                                                        matInput
                                                        formControlName="zipcode"
                                                        autocomplete="new-password"
                                                    />
                                                    <div
                                                    class="error"
                                                    *ngIf="
                                                        (!add_property.controls[
                                                            'zipcode'
                                                        ].valid &&
                                                        add_property
                                                                .controls[
                                                                'zipcode'
                                                            ].touched) ||
                                                        (add_property.controls[
                                                            'zipcode'
                                                        ].invalid &&
                                                            submitted)
                                                    "
                                                >
                                                Enter valid zipcode
                                                </div>
                                                </mat-form-field>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </mat-card-content>
                        </mat-card>
                        <br />
                        <mat-card>
                            <mat-card-header>
                                <mat-card-title>Borrower</mat-card-title>
                            </mat-card-header>
                            <mat-card-content>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <mat-form-field
                                                    class="example-full-width"
                                                >
                                                    <span
                                                        class="mat-form-field-label-wrapper"
                                                        ><!----><label
                                                            class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                            >E-mail *</label
                                                        ></span
                                                    >
                                                    <input
                                                        matInput
                                                        formControlName="email"
                                                        (blur)="
                                                            onBlurBorrower()
                                                        "
                                                        name="username"
                                                    />
                                                    <div
                                                    class="error"
                                                    *ngIf="
                                                        (!add_property.controls[
                                                            'email'
                                                        ].valid &&
                                                        add_property
                                                                .controls[
                                                                'email'
                                                            ].touched) ||
                                                        (add_property.controls[
                                                            'email'
                                                        ].invalid &&
                                                            submitted)
                                                    "
                                                >
                                                Enter valid email
                                                </div>
                                                </mat-form-field>
                                            </td>
                                            <td>
                                                <mat-form-field
                                                    class="example-full-width"
                                                >
                                                    <span
                                                        class="mat-form-field-label-wrapper"
                                                        ><!----><label
                                                            class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                            >First Name *</label
                                                        ></span
                                                    >
                                                    <input
                                                        matInput
                                                        formControlName="fname"
                                                        name="fname"
                                                    />
                                                    <div
                                                    class="error"
                                                    *ngIf="
                                                        (!add_property.controls[
                                                            'fname'
                                                        ].valid &&
                                                        add_property
                                                                .controls[
                                                                'fname'
                                                            ].touched) ||
                                                        (add_property.controls[
                                                            'fname'
                                                        ].invalid &&
                                                            submitted)
                                                    "
                                                >
                                                Enter valid first name
                                                </div>
                                                </mat-form-field>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <mat-form-field
                                                    class="example-full-width"
                                                >
                                                    <span
                                                        class="mat-form-field-label-wrapper"
                                                        ><!----><label
                                                            class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                            >Last Name *</label
                                                        ></span
                                                    >
                                                    <input
                                                        matInput
                                                        formControlName="lname"
                                                        name="lname"
                                                    />
                                                    <div
                                                    class="error"
                                                    *ngIf="
                                                        (!add_property.controls[
                                                            'lname'
                                                        ].valid &&
                                                        add_property
                                                                .controls[
                                                                'lname'
                                                            ].touched) ||
                                                        (add_property.controls[
                                                            'lname'
                                                        ].invalid &&
                                                            submitted)
                                                    "
                                                >
                                                Enter valid last name
                                                </div>
                                                </mat-form-field>
                                            </td>
                                            <td>
                                                <mat-form-field
                                                    class="example-full-width"
                                                >
                                                    <span
                                                        class="mat-form-field-label-wrapper"
                                                        ><!----><label
                                                            class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                            >Phone Number</label
                                                        ></span
                                                    >
                                                    <input
                                                        matInput
                                                        [mask]="maskPhoneFormat" 
                                                        placeholder="(000) 000-0000"
                                                        (keyup)="
                                                            keyDownPhoneFormat(
                                                                $event
                                                            )
                                                        "
                                                        (change)="
                                                            changePhoneFormat(
                                                                $event
                                                            )
                                                        "
                                                        formControlName="phone_no"
                                                        name="phone_no"
                                                    />
                                                    <div
                                                    class="error"
                                                    *ngIf="
                                                        (!add_property.controls[
                                                            'phone_no'
                                                        ].valid &&
                                                        add_property
                                                                .controls[
                                                                'phone_no'
                                                            ].touched) ||
                                                        (add_property.controls[
                                                            'phone_no'
                                                        ].invalid &&
                                                            submitted)
                                                    "
                                                >
                                                <div class="error" *ngIf="add_property?.controls['phone_no']?.errors?.mask">
                                                    Enter valid phone no.
                                                   </div>
                                                </div>
                                                </mat-form-field>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <!-- <button (click)="showAdditionalBorrower()">
                  <span *ngIf="displayAddBorrower"> Hide Additional Borrowers</span>
                  <span *ngIf="!displayAddBorrower"> Add Additional Borrowers</span>
                </button> -->
                            </mat-card-content>
                        </mat-card>
                        <br />
                        <br />

                        <!-- <mat-card *ngIf="displayAddBorrower">
              <mat-card-header>
                <mat-card-title>Additional Borrowers</mat-card-title>
              </mat-card-header>
              <mat-card-content>
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <mat-form-field class="example-full-width">
                          <input matInput placeholder="E-mail *" formControlName="addBemail" name="addBemail">
                        </mat-form-field>
                      </td>
                      <td>
                        <mat-form-field class="example-full-width">
                          <input matInput placeholder="First Name *" formControlName="addBfname" name="addBfname">
                        </mat-form-field>
                      </td>
                      <td>
                        <mat-form-field class="example-full-width">
                          <input matInput placeholder="Last Name *" formControlName="addBlname" name="addBlname">
                        </mat-form-field>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colspan="3">
                        <button type="button" (click)="addBorrower()">Save</button> &nbsp;&nbsp;&nbsp;
                        <button type="button" (click)="resetBorrower()">Reset</button>
                      </td>
                    </tr>
                  </tfoot>
                </table>
                <br>
                <br>
                <div class="mat-elevation-z8" *ngIf="additional_borrower.length>0">
                  <table>
                    <thead>
                      <tr>
                        <th>
                          Email
                        </th>
                        <th>
                          First Name
                        </th>
                        <th>
                          Last Name
                        </th>
                        <th>
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let borrower of additional_borrower ;let i=index">
                        <td>
                          {{borrower.Email}}
                        </td>
                        <td>
                          {{borrower.FirstName}}
                        </td>
                        <td>
                          {{borrower.LastName}}
                        </td>
                        <td>
                          <button (click)="removeBorrower(borrower)">Remove</button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </mat-card-content>
            </mat-card> -->

                        <mat-form-field class="example-full-width">
                            <span class="mat-form-field-label-wrapper"
                                ><!----><label
                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                    >Lender *</label
                                ></span
                            >
                            <mat-select
                                [disabled]="islenderDisabled"
                                formControlName="lender"
                                (selectionChange)="filterLOLP()"
                            >
                                <mat-option value="">Select</mat-option>

                                <mat-option
                                    *ngFor="let lender_value of lender_list"
                                    value="{{ lender_value.Id }}"
                                    >{{ lender_value.Name }}</mat-option
                                >
                            </mat-select>
                            <div
                            class="error"
                            *ngIf="
                                (!add_property.controls[
                                    'lender'
                                ].valid &&
                                add_property
                                        .controls[
                                        'lender'
                                    ].touched) ||
                                (add_property.controls[
                                    'lender'
                                ].invalid &&
                                    submitted)
                            "
                        >
                        Select lender
                        </div>
                        </mat-form-field>

                        <br />
                        <table>
                            <tbody>
                                <tr
                                    *ngIf="
                                        add_property.value['lender'] != '' &&
                                        add_property.value['lender'] != null
                                    "
                                >
                                    <td>
                                        <mat-form-field>
                                            <span
                                                class="mat-form-field-label-wrapper"
                                                ><!----><label
                                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                    >Loan Officer *</label
                                                ></span
                                            >
                                            <mat-select
                                                [disabled]="
                                                    isloanOfficerDisabled
                                                "
                                                formControlName="loanOfficer"
                                            >
                                                <mat-option value=""
                                                    >Select</mat-option
                                                >
                                                <mat-option
                                                    *ngFor="
                                                        let loan_officer_value of filter_loan_officer_list
                                                    "
                                                    value="{{
                                                        loan_officer_value.Id
                                                    }}"
                                                    >{{
                                                        loan_officer_value.FirstName
                                                    }}
                                                    {{
                                                        loan_officer_value.LastName
                                                    }}</mat-option
                                                >
                                                <mat-option value="other"
                                                    >Other</mat-option
                                                >
                                            </mat-select>
                                            <div
                                                                    class="error"
                                                                    *ngIf="
                                                                        (!add_property.controls[
                                                                            'loanOfficer'
                                                                        ].valid &&
                                                                        add_property
                                                                                .controls[
                                                                                'loanOfficer'
                                                                            ].touched) ||
                                                                        (add_property.controls[
                                                                            'loanOfficer'
                                                                        ].invalid &&
                                                                            submitted)
                                                                    "
                                                                >
                                                                Select Loan Officer
                                                                </div>
                                        </mat-form-field>
                                    </td>
                                    <td>&nbsp;</td>
                                </tr>
                                <tr
                                    *ngIf="
                                        add_property.value['loanOfficer'] ==
                                        'other'
                                    "
                                >
                                    <td colspan="2">
                                        <mat-card>
                                            <mat-card-header>
                                                <mat-card-title
                                                    >Loan
                                                    Officer</mat-card-title
                                                >
                                            </mat-card-header>
                                            <mat-card-content>
                                                <table>
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <mat-form-field
                                                                    class="example-full-width"
                                                                >
                                                                    <span
                                                                        class="mat-form-field-label-wrapper"
                                                                        ><!----><label
                                                                            class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                                            >E-mail</label
                                                                        ></span
                                                                    >
                                                                    <input
                                                                        matInput
                                                                        (blur)="
                                                                            onBlurLoanOfficer()
                                                                        "
                                                                        formControlName="LOemail"
                                                                        name="LOemail"
                                                                        required
                                                                    />
                                                                    <div class="error" *ngIf="(!add_property.controls.LOemail.valid && add_property.controls.LOemail.touched) || (add_property.controls.LOemail.invalid && submitted)">
                                  Enter valid email.
                                </div>
                                                                </mat-form-field>
                                                            </td>
                                                            <td>
                                                                <mat-form-field
                                                                    class="example-full-width"
                                                                >
                                                                    <span
                                                                        class="mat-form-field-label-wrapper"
                                                                        ><!----><label
                                                                            class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                                            >First
                                                                            Name</label
                                                                        ></span
                                                                    >
                                                                    <input
                                                                        matInput
                                                                        formControlName="LOfname"
                                                                        name="LOfname"
                                                                        required
                                                                    />
                                                                    <div class="error" *ngIf="(!add_property.controls.LOfname.valid && add_property.controls.LOfname.touched) || (add_property.controls.LOfname.invalid && submitted)">
                                    Enter first name.
                    
                                  </div>
                                                                </mat-form-field>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <mat-form-field
                                                                    class="example-full-width"
                                                                >
                                                                    <span
                                                                        class="mat-form-field-label-wrapper"
                                                                        ><!----><label
                                                                            class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                                            >Last
                                                                            Name</label
                                                                        ></span
                                                                    >
                                                                    <input
                                                                        matInput
                                                                        formControlName="LOlname"
                                                                        name="LOlname"
                                                                    />
                                                                    <div class="error" *ngIf="(!add_property.controls.LOlname.valid && add_property.controls.LOlname.touched) || (add_property.controls.LOlname.invalid && submitted)">
                                    Enter last name.
                    
                                  </div>
                                                                </mat-form-field>
                                                            </td>
                                                            <td>
                                                                <mat-form-field
                                                                    class="example-full-width"
                                                                >
                                                                    <span
                                                                        class="mat-form-field-label-wrapper"
                                                                        ><!----><label
                                                                            class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                                            >Phone
                                                                            Number</label
                                                                        ></span
                                                                    >
                                                                    <input
                                                                        matInput
                                                                        [mask]="maskPhoneFormat" 
                                                                        placeholder="(000) 000-0000"
                                                                        (keyup)="
                                                                            keyDownPhoneFormat(
                                                                                $event
                                                                            )
                                                                        "
                                                                        (change)="
                                                                            changePhoneFormat(
                                                                                $event
                                                                            )
                                                                        "
                                                                        formControlName="LOphone_no"
                                                                        name="LOphone_no"
                                                                    />
                                                                    <div
                                                                    class="error"
                                                                    *ngIf="
                                                                        (!add_property.controls[
                                                                            'LOphone_no'
                                                                        ].valid &&
                                                                        add_property
                                                                                .controls[
                                                                                'LOphone_no'
                                                                            ].touched) ||
                                                                        (add_property.controls[
                                                                            'LOphone_no'
                                                                        ].invalid &&
                                                                            submitted)
                                                                    "
                                                                >
                                                                <div class="error" *ngIf="add_property?.controls['LOphone_no']?.errors?.mask">
                                                                    Enter valid phone no.
                                                                   </div>
                                                                </div>
                                                                </mat-form-field>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </mat-card-content>
                                        </mat-card>
                                    </td>
                                </tr>

                                <tr
                                    *ngIf="
                                        add_property.value['lender'] != '' &&
                                        add_property.value['lender'] != null
                                    "
                                >
                                    <td>
                                        <mat-form-field
                                            class="example-full-width"
                                        >
                                            <span
                                                class="mat-form-field-label-wrapper"
                                                ><!----><label
                                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                    >Loan Processors</label
                                                ></span
                                            >
                                            <mat-select
                                                multiple
                                                formControlName="loanProcessor"
                                            >
                                                <mat-option
                                                    (onSelectionChange)="
                                                        onLoanProcessorSelection(
                                                            $event
                                                        )
                                                    "
                                                    class="notCheckbox"
                                                    value=""
                                                    >Select</mat-option
                                                >
                                                <mat-option
                                                    (onSelectionChange)="
                                                        onLoanProcessorSelection(
                                                            $event
                                                        )
                                                    "
                                                    *ngFor="
                                                        let loan_processor_value of filter_loan_processor_list
                                                    "
                                                    value="{{
                                                        loan_processor_value.Id
                                                    }}"
                                                    >{{
                                                        loan_processor_value.FirstName
                                                    }}
                                                    {{
                                                        loan_processor_value.LastName
                                                    }}</mat-option
                                                >
                                                <mat-option
                                                    (onSelectionChange)="
                                                        onLoanProcessorSelection(
                                                            $event
                                                        )
                                                    "
                                                    class="notCheckbox"
                                                    value="other"
                                                    >Other</mat-option
                                                >
                                            </mat-select>
                                        </mat-form-field>
                                    </td>
                                    <td style="width: 50%">&nbsp;</td>
                                </tr>
                                <tr
                                    *ngIf="
                                        add_property.value['loanProcessor'] ==
                                        'other'
                                    "
                                >
                                    <td colspan="2">
                                        <mat-card>
                                            <mat-card-header>
                                                <mat-card-title
                                                    >Loan
                                                    Processors</mat-card-title
                                                >
                                            </mat-card-header>
                                            <mat-card-content>
                                                <table>
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <mat-form-field
                                                                    class="example-full-width"
                                                                >
                                                                    <span
                                                                        class="mat-form-field-label-wrapper"
                                                                        ><!----><label
                                                                            class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                                            >E-mail</label
                                                                        ></span
                                                                    >
                                                                    <input
                                                                        matInput
                                                                        (blur)="
                                                                            onBlurLoanProcessor()
                                                                        "
                                                                        formControlName="LPemail"
                                                                        name="LPemail"
                                                                        required
                                                                    />
                                                                    <div class="error" *ngIf="(!add_property.controls.LPemail.valid && add_property.controls.LPemail.touched) || (add_property.controls.LPemail.invalid && submitted)">
                                    Enter valid email.
                                  </div>
                                                                </mat-form-field>
                                                            </td>
                                                            <td>
                                                                <mat-form-field
                                                                    class="example-full-width"
                                                                >
                                                                    <span
                                                                        class="mat-form-field-label-wrapper"
                                                                        ><!----><label
                                                                            class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                                            >First
                                                                            Name</label
                                                                        ></span
                                                                    >
                                                                    <input
                                                                        matInput
                                                                        formControlName="LPfname"
                                                                        name="LPfname"
                                                                        required
                                                                    />
                                                                    <div class="error" *ngIf="(!add_property.controls.LPfname.valid && add_property.controls.LPfname.touched) || (add_property.controls.LPfname.invalid && submitted)">
                                      Enter first name.
                      
                                    </div>
                                                                </mat-form-field>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <mat-form-field
                                                                    class="example-full-width"
                                                                >
                                                                    <span
                                                                        class="mat-form-field-label-wrapper"
                                                                        ><!----><label
                                                                            class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                                            >Last
                                                                            Name</label
                                                                        ></span
                                                                    >
                                                                    <input
                                                                        matInput
                                                                        formControlName="LPlname"
                                                                        name="LPlname"
                                                                    />
                                                                    <div class="error" *ngIf="(!add_property.controls.LPlname.valid && add_property.controls.LPlname.touched) || (add_property.controls.LPlname.invalid && submitted)">
                                      Enter last name.
                      
                                    </div>
                                                                </mat-form-field>
                                                            </td>
                                                            <td>
                                                                <mat-form-field
                                                                    class="example-full-width"
                                                                >
                                                                    <span
                                                                        class="mat-form-field-label-wrapper"
                                                                        ><!----><label
                                                                            class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                                            >Phone
                                                                            Number</label
                                                                        ></span
                                                                    >
                                                                    <input
                                                                        matInput
                                                                        [mask]="maskPhoneFormat" 
                                                                        placeholder="(000) 000-0000"
                                                                        (keyup)="
                                                                            keyDownPhoneFormat(
                                                                                $event
                                                                            )
                                                                        "
                                                                        (change)="
                                                                            changePhoneFormat(
                                                                                $event
                                                                            )
                                                                        "
                                                                        formControlName="LPphone_no"
                                                                        name="LPphone_no"
                                                                    />
                                                                    <div
                                                                    class="error"
                                                                    *ngIf="
                                                                        (!add_property.controls[
                                                                            'LPphone_no'
                                                                        ].valid &&
                                                                        add_property
                                                                                .controls[
                                                                                'LPphone_no'
                                                                            ].touched) ||
                                                                        (add_property.controls[
                                                                            'LPphone_no'
                                                                        ].invalid &&
                                                                            submitted)
                                                                    "
                                                                >
                                                                <div class="error" *ngIf="add_property?.controls['LPphone_no']?.errors?.mask">
                                                                    Enter valid phone no.
                                                                   </div>
                                                                </div>
                                                                </mat-form-field>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </mat-card-content>
                                        </mat-card>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <br />

                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <mat-form-field
                                            class="example-full-width"
                                        >
                                            <span
                                                class="mat-form-field-label-wrapper"
                                                ><!----><label
                                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                    >FHA Case No</label
                                                ></span
                                            >
                                            <input
                                                matInput
                                                formControlName="fHACaseNo"
                                                name="fHACaseNo"
                                            />
                                            <div
                                            class="error"
                                            *ngIf="
                                                (!add_property.controls[
                                                    'fHACaseNo'
                                                ].valid &&
                                                add_property
                                                        .controls[
                                                        'fHACaseNo'
                                                    ].touched) ||
                                                (add_property.controls[
                                                    'fHACaseNo'
                                                ].invalid &&
                                                    submitted)
                                            "
                                        >
                                        Enter valid fHACaseNo
                                        </div>
                                        </mat-form-field>
                                    </td>
                                    <td>
                                        <mat-form-field>
                                            <span
                                                class="mat-form-field-label-wrapper"
                                                ><!----><label
                                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                    >Loan Type *</label
                                                ></span
                                            >
                                            <mat-select
                                                formControlName="loanType"
                                            >
                                                <mat-option value="203K"
                                                    >203K</mat-option
                                                >
                                                <mat-option value="Homestyle"
                                                    >Homestyle</mat-option
                                                >
                                                <div
                                            class="error"
                                            *ngIf="
                                                (!add_property.controls[
                                                    'loanType'
                                                ].valid &&
                                                add_property
                                                        .controls[
                                                        'loanType'
                                                    ].touched) ||
                                                (add_property.controls[
                                                    'loanType'
                                                ].invalid &&
                                                    submitted)
                                            "
                                        >
                                        select loanType
                                        </div>
                                            </mat-select>
                                        </mat-form-field>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <mat-form-field>
                                            <span
                                                class="mat-form-field-label-wrapper"
                                                ><!----><label
                                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                    >Consultant *</label
                                                ></span
                                            >
                                            <mat-select
                                                formControlName="consultant"
                                            >
                                                <mat-option value=""
                                                    >Select</mat-option
                                                >
                                                <mat-option
                                                    *ngFor="
                                                        let consultent_value of consultent_list
                                                    "
                                                    value="{{
                                                        consultent_value.Id
                                                    }}"
                                                    >{{
                                                        consultent_value.FirstName
                                                    }}
                                                    {{
                                                        consultent_value.LastName
                                                    }}</mat-option
                                                >
                                            </mat-select>
                                            <div
                                                                    class="error"
                                                                    *ngIf="
                                                                        (!add_property.controls[
                                                                            'consultant'
                                                                        ].valid &&
                                                                        add_property
                                                                                .controls[
                                                                                'consultant'
                                                                            ].touched) ||
                                                                        (add_property.controls[
                                                                            'consultant'
                                                                        ].invalid &&
                                                                            submitted)
                                                                    "
                                                                >
                                                                Select Consultant
                                                                </div>
                                        </mat-form-field>
                                    </td>
                                    <td>
                                        <mat-form-field>
                                            <span
                                                class="mat-form-field-label-wrapper"
                                                ><!----><label
                                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                    >Transaction Type</label
                                                ></span
                                            >
                                            <mat-select
                                                formControlName="transactionType"
                                            >
                                                <mat-option value="Purchase"
                                                    >Purchase</mat-option
                                                >
                                                <mat-option value="Refinance"
                                                    >Refinance</mat-option
                                                >
                                            </mat-select>
                                        </mat-form-field>
                                    </td>
                                </tr>
                                <!-- <tr>
                  <td>
                      <mat-form-field class="example-full-width">
                          <mat-select placeholder="Agreement Signed By LO" formControlName="agreementSignedByBo">
                            <mat-option value="">None</mat-option>
                            <mat-option value="no">No</mat-option>
                            <mat-option value="yes">Yes</mat-option>
                          </mat-select>
                        </mat-form-field>
                  </td>
                  <td>
                    
                    <mat-form-field class="example-full-width">
                      <input matInput placeholder="Latitude" formControlName="latitude" name="latitude">
                    </mat-form-field>
                  </td>
                </tr> -->
                                <!-- <tr>
                  <td>
                    <mat-form-field class="example-full-width">
                      <input matInput placeholder="Longitude" formControlName="longitude" name="longitude">
                    </mat-form-field>
                  </td>
                  <td>

                  </td>
                </tr> -->
                                <tr>
                                    <td>
                                        <span
                                            class="mat-form-field-label-wrapper"
                                            ><!----><label
                                                class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                >Loan No</label
                                            ></span
                                        >
                                        <mat-form-field
                                            class="example-full-width"
                                        >
                                            <input
                                                matInput
                                                formControlName="LoanNo"
                                                name="LoanNo"
                                            />
                                            <div
                                            class="error"
                                            *ngIf="
                                                (!add_property.controls[
                                                    'LoanNo'
                                                ].valid &&
                                                add_property
                                                        .controls[
                                                        'LoanNo'
                                                    ].touched) ||
                                                (add_property.controls[
                                                    'LoanNo'
                                                ].invalid &&
                                                    submitted)
                                            "
                                        >
                                        Enter valid LoanNo
                                        </div>
                                        </mat-form-field>
                                    </td>
                                    <td>&nbsp;</td>
                                </tr>
                            </tbody>
                        </table>

                        <br />
                        <mat-card
                            *ngIf="
                                add_property.value['transactionType'] ==
                                'Purchase'
                            "
                        >
                            <mat-card-header>
                                <mat-card-title>Realtor</mat-card-title>
                            </mat-card-header>
                            <mat-card-content>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <mat-form-field
                                                    class="example-full-width"
                                                >
                                                    <span
                                                        class="mat-form-field-label-wrapper"
                                                        ><!----><label
                                                            class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                            >E-mail</label
                                                        ></span
                                                    >
                                                    <input
                                                        matInput
                                                        formControlName="RTemail"
                                                        (blur)="onBlurRealtor()"
                                                        name="RTemail"
                                                    />
                                                    <div class="error" *ngIf="(!add_property.controls.RTemail.valid && add_property.controls.RTemail.touched) || (add_property.controls.RTemail.invalid && submitted)">
                            Enter valid email.
                          </div>
                                                </mat-form-field>
                                            </td>
                                            <td>
                                                <mat-form-field
                                                    class="example-full-width"
                                                >
                                                    <span
                                                        class="mat-form-field-label-wrapper"
                                                        ><!----><label
                                                            class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                            >First Name</label
                                                        ></span
                                                    >
                                                    <input
                                                        matInput
                                                        formControlName="RTfname"
                                                        name="RTfname"
                                                    />
                                                    <div class="error" *ngIf="(!add_property.controls.RTfname.valid && add_property.controls.RTfname.touched) || (add_property.controls.RTfname.invalid && submitted)">
                              Enter first name.
              
                            </div>
                                                </mat-form-field>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <mat-form-field
                                                    class="example-full-width"
                                                >
                                                    <span
                                                        class="mat-form-field-label-wrapper"
                                                        ><!----><label
                                                            class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                            >Last Name</label
                                                        ></span
                                                    >
                                                    <input
                                                        matInput
                                                        formControlName="RTlname"
                                                        name="RTlname"
                                                    />
                                                    <div class="error" *ngIf="(!add_property.controls.RTlname.valid && add_property.controls.RTlname.touched) || (add_property.controls.RTlname.invalid && submitted)">
                              Enter last name.
              
                            </div>
                                                </mat-form-field>
                                            </td>
                                            <td>
                                                <mat-form-field
                                                    class="example-full-width"
                                                >
                                                    <span
                                                        class="mat-form-field-label-wrapper"
                                                        ><!----><label
                                                            class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                            >Phone Number</label
                                                        ></span
                                                    >
                                                    <input
                                                        matInput
                                                        [mask]="maskPhoneFormat" 
                                                        placeholder="(000) 000-0000"
                                                        (keyup)="
                                                            keyDownPhoneFormat(
                                                                $event
                                                            )
                                                        "
                                                        (change)="
                                                            changePhoneFormat(
                                                                $event
                                                            )
                                                        "
                                                        formControlName="RTphone_no"
                                                        name="RTphone_no"
                                                    />
                                                    <div
                                                    class="error"
                                                    *ngIf="
                                                        (!add_property.controls[
                                                            'RTphone_no'
                                                        ].valid &&
                                                        add_property
                                                                .controls[
                                                                'RTphone_no'
                                                            ].touched) ||
                                                        (add_property.controls[
                                                            'RTphone_no'
                                                        ].invalid &&
                                                            submitted)
                                                    "
                                                >
                                                <div class="error" *ngIf="add_property?.controls['RTphone_no']?.errors?.mask">
                                                    Enter valid phone no.
                                                   </div>
                                                </div>
                                                </mat-form-field>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </mat-card-content>
                        </mat-card>
                        <br />
                        <br />
                        <mat-card>
                            <mat-card-header>
                                <mat-card-title
                                    >General Contractor</mat-card-title
                                >
                            </mat-card-header>
                            <mat-card-content>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <mat-form-field
                                                    class="example-full-width"
                                                >
                                                    <span
                                                        class="mat-form-field-label-wrapper"
                                                        ><!----><label
                                                            class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                            >E-mail</label
                                                        ></span
                                                    >
                                                    <input
                                                        matInput
                                                        formControlName="gcemail"
                                                        (blur)="
                                                            onBlurGenralContractor()
                                                        "
                                                        name="gcusername"
                                                    />
                                                    <div
                                                        class="error"
                                                        *ngIf="
                                                            (!add_property
                                                                .controls[
                                                                'gcemail'
                                                            ].valid &&
                                                                add_property
                                                                    .controls[
                                                                    'gcemail'
                                                                ].touched) ||
                                                            (add_property
                                                                .controls[
                                                                'gcemail'
                                                            ].invalid &&
                                                                submitted)
                                                        "
                                                    >
                                                        Enter valid email.
                                                    </div>
                                                </mat-form-field>
                                            </td>
                                            <td>
                                                <mat-form-field
                                                    class="example-full-width"
                                                >
                                                    <span
                                                        class="mat-form-field-label-wrapper"
                                                        ><!----><label
                                                            class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                            >First Name</label
                                                        ></span
                                                    >
                                                    <input
                                                        matInput
                                                        formControlName="gcfname"
                                                        name="gcfname"
                                                    />
                                                    <div
                                                        class="error"
                                                        *ngIf="
                                                            (!add_property
                                                                .controls[
                                                                'gcfname'
                                                            ].valid &&
                                                                add_property
                                                                    .controls[
                                                                    'gcfname'
                                                                ].touched) ||
                                                            (add_property
                                                                .controls[
                                                                'gcfname'
                                                            ].invalid &&
                                                                submitted)
                                                        "
                                                    >
                                                        Enter first name.
                                                    </div>
                                                </mat-form-field>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <mat-form-field
                                                    class="example-full-width"
                                                >
                                                    <span
                                                        class="mat-form-field-label-wrapper"
                                                        ><!----><label
                                                            class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                            >Last Name</label
                                                        ></span
                                                    >
                                                    <input
                                                        matInput
                                                        formControlName="gclname"
                                                        name="gclname"
                                                    />
                                                    <div
                                                        class="error"
                                                        *ngIf="
                                                            (!add_property
                                                                .controls[
                                                                'gclname'
                                                            ].valid &&
                                                                add_property
                                                                    .controls[
                                                                    'gclname'
                                                                ].touched) ||
                                                            (add_property
                                                                .controls[
                                                                'gclname'
                                                            ].invalid &&
                                                                submitted)
                                                        "
                                                    >
                                                        Enter last name.
                                                    </div>
                                                </mat-form-field>
                                            </td>
                                            <td>
                                                <mat-form-field
                                                    class="example-full-width"
                                                >
                                                    <span
                                                        class="mat-form-field-label-wrapper"
                                                        ><!----><label
                                                            class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                            >Phone Number</label
                                                        ></span
                                                    >
                                                    <input
                                                        matInput
                                                        [mask]="maskPhoneFormat" 
                                                        placeholder="(000) 000-0000"
                                                        (keyup)="
                                                            keyDownPhoneFormat(
                                                                $event
                                                            )
                                                        "
                                                        (change)="
                                                            changePhoneFormat(
                                                                $event
                                                            )
                                                        "
                                                        formControlName="gcphone_no"
                                                        name="gcphone_no"
                                                    />
                                                    <div
                                                    class="error"
                                                    *ngIf="
                                                        (!add_property.controls[
                                                            'gcphone_no'
                                                        ].valid &&
                                                        add_property
                                                                .controls[
                                                                'gcphone_no'
                                                            ].touched) ||
                                                        (add_property.controls[
                                                            'gcphone_no'
                                                        ].invalid &&
                                                            submitted)
                                                    "
                                                >
                                                <div class="error" *ngIf="add_property?.controls['gcphone_no']?.errors?.mask">
                                                    Enter valid phone no.
                                                   </div>
                                                </div>
                                                </mat-form-field>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </mat-card-content>
                        </mat-card>
                    </mat-card-content>
                    <mat-card-actions>
                        <button mat-raised-button type="submit" color="primary">
                            Save</button
                        >&nbsp;&nbsp;&nbsp;
                        <button
                            mat-raised-button
                            (click)="
                                isAdd = false; isList = true; isEdit = false
                            "
                            type="button"
                            color="info"
                        >
                            Back
                        </button>
                        <!-- <button *ngIf="isEdit && userDetail.UserType != 'Borrower'" mat-raised-button type="button" routerLink="/property-details/{{add_property.value['id']}}"
              color="info">Property Details</button> -->
                    </mat-card-actions>
                </form>
            </mat-card>
        </div>
    </div>
</div>

<div
    class="content p-24 tbl"
    *ngIf="userDetail.UserType == 'General Contractors'"
>
    <div
        *ngIf="
            userDetail.isGCFirstLogin == true &&
            userDetail.isIntroPageHidden != true
        "
    >
        <h2>Welcome to A.M. Renovation Consulting Xchange!</h2>
        <br />
        <form
            [formGroup]="add_gcinfo"
            class="example-form"
            (ngSubmit)="SaveGCData()"
        >
            <div
                class="gcStepOne gcStepContainer white-outer"
                *ngIf="gcStepOneShow == true"
            >
                <table class="td-50">
                    <tbody>
                        <tr>
                            <td class="pad-0">
                                <h3>
                                    Before we begin, tell us more about you…
                                </h3>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <mat-form-field>
                                    <span class="mat-form-field-label-wrapper"
                                        ><!----><label
                                            class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                            >First Name</label
                                        ></span
                                    >
                                    <input
                                        matInput
                                        class="gcProfileField"
                                        formControlName="fname"
                                    />
                                    <div
                                    class="error"
                                    *ngIf="
                                        (!add_gcinfo.controls[
                                            'fname'
                                        ].valid &&
                                        add_gcinfo
                                                .controls[
                                                'fname'
                                            ].touched) ||
                                        (add_gcinfo.controls[
                                            'fname'
                                        ].invalid &&
                                            submitted)
                                    "
                                >
                                   Enter valid first name
                                </div>
                                </mat-form-field>
                            </td>
                            <td>
                                <mat-form-field>
                                    <span class="mat-form-field-label-wrapper"
                                        ><!----><label
                                            class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                            >Last Name</label
                                        ></span
                                    >
                                    <input
                                        matInput
                                        class="gcProfileField"
                                        formControlName="lname"
                                    />
                                    <div
                                    class="error"
                                    *ngIf="
                                        (!add_gcinfo.controls[
                                            'lname'
                                        ].valid &&
                                        add_gcinfo
                                                .controls[
                                                'lname'
                                            ].touched) ||
                                        (add_gcinfo.controls[
                                            'lname'
                                        ].invalid &&
                                            submitted)
                                    "
                                >
                                   Enter valid last name
                                </div>
                                </mat-form-field>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <mat-form-field>
                                    <span class="mat-form-field-label-wrapper"
                                        ><!----><label
                                            class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                            >Phone Number</label
                                        ></span
                                    >
                                    <input
                                        matInput
                                        [mask]="maskPhoneFormat" 
                                        placeholder="(000) 000-0000"
                                        class="gcProfileField"
                                        (keyup)="keyDownPhoneFormat($event)"
                                        (change)="changePhoneFormat($event)"
                                        formControlName="phone_no"
                                    />
                                    <div
                                    class="error"
                                    *ngIf="
                                        (!add_gcinfo.controls[
                                            'phone_no'
                                        ].valid &&
                                        add_gcinfo
                                                .controls[
                                                'phone_no'
                                            ].touched) ||
                                        (add_gcinfo.controls[
                                            'phone_no'
                                        ].invalid &&
                                            submitted)
                                    "
                                >
                                <div class="error" *ngIf="add_gcinfo?.controls['phone_no']?.errors?.mask">
                                    Enter valid phone no.
                                   </div>
                                </div>
                                </mat-form-field>
                            </td>
                            <td>
                                <mat-form-field>
                                    <span class="mat-form-field-label-wrapper"
                                        ><!----><label
                                            class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                            >Email Address</label
                                        ></span
                                    >
                                    <input
                                        matInput
                                        class="gcProfileField"
                                        formControlName="email"
                                    />
                                    <div
                                    class="error"
                                    *ngIf="
                                        (!add_gcinfo.controls[
                                            'email'
                                        ].valid &&
                                        add_gcinfo
                                                .controls[
                                                'email'
                                            ].touched) ||
                                        (add_gcinfo.controls[
                                            'email'
                                        ].invalid &&
                                            submitted)
                                    "
                                >
                                   Enter valid email
                                </div>
                                </mat-form-field>
                            </td>
                        </tr>
                        <tr>
                            <td class="pad-0">
                                <h3>Certifications</h3>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <mat-form-field>
                                    <mat-label>
                                        Contractor License #

                                        <span
                                            class="customTooltipText"
                                            matTooltip="Prior to closing, the Mortgagee must ensure that a qualified general or specialized contractor has been hired and, by contract, has agreed to complete the work described in the Work Write-Up for the amount of the Cost Estimate"
                                        >
                                            why we need this ?
                                        </span>
                                    </mat-label>
                                    <input
                                        matInput
                                        class="gcProfileField"
                                        formControlName="license"
                                    />
                                    <div
                                    class="error"
                                    *ngIf="
                                        (!add_gcinfo.controls[
                                            'license'
                                        ].valid &&
                                        add_gcinfo
                                                .controls[
                                                'license'
                                            ].touched) ||
                                        (add_gcinfo.controls[
                                            'license'
                                        ].invalid &&
                                            submitted)
                                    "
                                >
                                   Enter valid license
                                </div>
                                </mat-form-field>
                            </td>
                            <td>
                                <mat-form-field>
                                    <span class="mat-form-field-label-wrapper"
                                        ><!----><label
                                            class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                            >Expiration Date</label
                                        ></span
                                    >
                                    <input
                                        matInput
                                        [matDatepicker]="dp3"
                                        (focus)="dp3.open()"
                                        class="gcProfileField"
                                        formControlName="license_expire"
                                        name="license_expire"
                                    />
                                    <mat-datepicker-toggle
                                        matSuffix
                                        [for]="dp3"
                                    ></mat-datepicker-toggle>
                                    <mat-datepicker
                                        #dp3
                                        disabled="false"
                                    ></mat-datepicker>

                                    <div
                                    class="error"
                                    *ngIf="
                                        (!add_gcinfo.controls[
                                            'license_expire'
                                        ].valid &&
                                        add_gcinfo
                                                .controls[
                                                'license_expire'
                                            ].touched) ||
                                        (add_gcinfo.controls[
                                            'license_expire'
                                        ].invalid &&
                                            submitted)
                                    "
                                >
                                   Enter valid license expire
                                </div>
                                </mat-form-field>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <mat-form-field>
                                    <mat-label>
                                        Lead Safe Certified #
                                        &nbsp;&nbsp;&nbsp;&nbsp;(not required)
                                    </mat-label>

                                    <input
                                        matInput
                                        class="gcProfileField"
                                        formControlName="leadSafe"
                                    />
                                </mat-form-field>
                            </td>
                            <td>
                                <mat-form-field>
                                    <span class="mat-form-field-label-wrapper"
                                        ><!----><label
                                            class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                            >Expiration Date (if
                                            applicable)</label
                                        ></span
                                    >
                                    <input
                                        matInput
                                        [matDatepicker]="dp4"
                                        (focus)="dp4.open()"
                                        class="gcProfileField"
                                        formControlName="leadSafe_expire"
                                        name="leadSafe_expire"
                                    />
                                    <mat-datepicker-toggle
                                        matSuffix
                                        [for]="dp4"
                                    ></mat-datepicker-toggle>
                                    <mat-datepicker
                                        #dp4
                                        disabled="false"
                                    ></mat-datepicker>
                                </mat-form-field>
                            </td>
                        </tr>
                        <tr>
                            <td>&nbsp;</td>
                            <td>
                                <button
                                    mat-raised-button
                                    type="button"
                                    (click)="saveGCStepOne()"
                                    class="gcBtn"
                                    color="primary"
                                >
                                    Continue
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div
                class="gcStepTwo gcStepContainer white-outer"
                *ngIf="gcStepTwoShow == true"
            >
                <table class="td-50">
                    <tbody>
                        <tr>
                            <td class="pad-0">
                                <h3>Introduce us to your company….</h3>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <mat-form-field>
                                    <span class="mat-form-field-label-wrapper"
                                        ><!----><label
                                            class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                            >Company Name</label
                                        ></span
                                    >
                                    <input
                                        matInput
                                        class="gcProfileField"
                                        formControlName="company_name"
                                    />
                                    <div
                                    class="error"
                                    *ngIf="
                                        (!add_gcinfo.controls[
                                            'company_name'
                                        ].valid &&
                                        add_gcinfo
                                                .controls[
                                                'company_name'
                                            ].touched) ||
                                        (add_gcinfo.controls[
                                            'company_name'
                                        ].invalid &&
                                            submitted)
                                    "
                                >
                                   Enter valid company name
                                </div>
                                </mat-form-field>
                            </td>
                            <td>
                                <mat-form-field>
                                    <mat-label>
                                        Service Areas
                                        <span
                                            class="customTooltip"
                                            matTooltip="Tell us where you service. Ex: Boston, Greater Boston, Western Massachusetts, Southern New Hampshire."
                                        >
                                            ?
                                        </span>
                                    </mat-label>

                                    <input
                                        matInput
                                        class="gcProfileField"
                                        formControlName="areas"
                                    />
                                    <div
                                    class="error"
                                    *ngIf="
                                        (!add_gcinfo.controls[
                                            'areas'
                                        ].valid &&
                                        add_gcinfo
                                                .controls[
                                                'areas'
                                            ].touched) ||
                                        (add_gcinfo.controls[
                                            'areas'
                                        ].invalid &&
                                            submitted)
                                    "
                                >
                                   Enter valid areas
                                </div>
                                </mat-form-field>
                            </td>
                        </tr>

                        <tr>
                            <td class="pad-0">
                                <h3>Company Address</h3>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <mat-form-field>
                                    <!-- <input matInput class="gcProfileField" placeholder="Address Line 1" formControlName="company_address1"> -->
                                    <span class="mat-form-field-label-wrapper"
                                        ><!----><label
                                            class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                            >Address Line 1</label
                                        ></span
                                    >
                                    <input
                                        matInput
                                        ngx-google-places-autocomplete
                                        class="gcProfileField"
                                        autocomplete="new-password"
                                        (onAddressChange)="
                                            AddressChange2($event)
                                        "
                                        formControlName="company_address1"
                                        name="company_address1"
                                    />
                                </mat-form-field>
                            </td>
                            <td>
                                <mat-form-field>
                                    <mat-label>
                                        Address Line 2
                                        <span
                                            class="customTooltip"
                                            matTooltip="Apartment\Suite\Other"
                                        >
                                            ?
                                        </span>
                                    </mat-label>

                                    <input
                                        matInput
                                        class="gcProfileField"
                                        autocomplete="new-password"
                                        formControlName="company_address2"
                                    />
                                </mat-form-field>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <mat-form-field>
                                    <span class="mat-form-field-label-wrapper"
                                        ><!----><label
                                            class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                            >City</label
                                        ></span
                                    >
                                    <input
                                        matInput
                                        class="gcProfileField"
                                        autocomplete="new-password"
                                        formControlName="company_city"
                                    />
                                </mat-form-field>
                            </td>
                            <td>
                                <mat-form-field>
                                    <span class="mat-form-field-label-wrapper"
                                        ><!----><label
                                            class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                            >State</label
                                        ></span
                                    >
                                    <input
                                        matInput
                                        class="gcProfileField"
                                        autocomplete="new-password"
                                        formControlName="company_state"
                                    />
                                </mat-form-field>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <mat-form-field>
                                    <span class="mat-form-field-label-wrapper"
                                        ><!----><label
                                            class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                            >Zipcode</label
                                        ></span
                                    >
                                    <input
                                        matInput
                                        class="gcProfileField"
                                        autocomplete="new-password"
                                        formControlName="company_zipcode"
                                    />
                                    <div
                                    class="error"
                                    *ngIf="
                                        (!add_gcinfo.controls[
                                            'company_zipcode'
                                        ].valid &&
                                        add_gcinfo
                                                .controls[
                                                'company_zipcode'
                                            ].touched) ||
                                        (add_gcinfo.controls[
                                            'company_zipcode'
                                        ].invalid &&
                                            submitted)
                                    "
                                >
                                   Enter valid company zipcode
                                </div>
                                </mat-form-field>
                            </td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td>
                                <a
                                    class="gobacklink"
                                    href="javascript:void(0)"
                                    (click)="goToStepOne()"
                                    >&#8592; Go Back</a
                                >
                            </td>
                            <td>
                                <button
                                    mat-raised-button
                                    type="submit"
                                    class="gcBtn"
                                    color="primary"
                                >
                                    Let's Get Started!
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </form>
    </div>

    <div *ngIf="userDetail.isPropertyDetailsHidden != true">
        <div *ngIf="userDetail.isGCFirstLogin == true">
            <div
                class="overLayoutContainer"
                *ngIf="
                    userDetail.ispopupOneShow == true ||
                    userDetail.ispopupTwoShow == true ||
                    userDetail.ispopupThreeShow == true
                "
            ></div>
        </div>
        <div
            *ngIf="
                userDetail.isGCFirstLogin == true &&
                userDetail.ispopupOneShow == true
            "
        >
            <div class="popupContainer popupOne">
                <strong
                    >The Properties Page will be your Dashboard to view all the
                    projects you have open with A.M. Renovation Consulting. We
                    hope to see you more, but you may only have one!<br /><br />

                    From here you will be able to input project estimates,
                    submit draw requests and enter change order forms.</strong
                >
                <br />
                <br />
                <button
                    class="popupNextBtn"
                    type="button"
                    (click)="gotoNextPopup(2)"
                >
                    Next
                </button>
            </div>
        </div>
        <h1 class="m-0">Properties</h1>

        <br />
        <br />
        <div class="mat-elevation-z8 white-outer gcpropertyList">
            <table>
                <thead border="1">
                    <th style="padding: 14px">Property Address</th>
                    <!-- <th>Loan Officer</th> -->
                    <th>Consultant</th>
                    <th>Borrower</th>
                    <!-- <th>Loan Type</th> -->
                    <th>Options</th>
                </thead>
                <tbody>
                    <tr
                        style="padding: 14px"
                        *ngFor="let GCPropertyDetails of GCPropertyList"
                    >
                        <td style="padding: 10px">
                            <div class="proAddressContainer">
                                <span>
                                    {{
                                        GCPropertyDetails?.InspectionAddress
                                            ?.address1
                                            ? GCPropertyDetails
                                                  ?.InspectionAddress
                                                  ?.address1 + ", "
                                            : ""
                                    }}
                                    {{
                                        GCPropertyDetails?.InspectionAddress
                                            ?.address2
                                            ? GCPropertyDetails
                                                  ?.InspectionAddress
                                                  ?.address2 + ", "
                                            : ""
                                    }}
                                    {{
                                        GCPropertyDetails?.InspectionAddress
                                            ?.city
                                            ? GCPropertyDetails
                                                  ?.InspectionAddress?.city +
                                              ", "
                                            : ""
                                    }}
                                    {{
                                        GCPropertyDetails?.InspectionAddress
                                            ?.state
                                            ? GCPropertyDetails
                                                  ?.InspectionAddress?.state +
                                              ", "
                                            : ""
                                    }}
                                    {{
                                        GCPropertyDetails?.InspectionAddress
                                            ?.zipcode
                                    }}
                                </span>
                                <div
                                    *ngIf="
                                        userDetail.isGCFirstLogin == true &&
                                        userDetail.ispopupTwoShow == true
                                    "
                                >
                                    <div class="popupContainer popupTwo">
                                        <div class="focusSection">
                                            {{
                                                GCPropertyDetails
                                                    ?.InspectionAddress
                                                    ?.address1
                                                    ? GCPropertyDetails
                                                          ?.InspectionAddress
                                                          ?.address1 + ", "
                                                    : ""
                                            }}
                                            {{
                                                GCPropertyDetails
                                                    ?.InspectionAddress
                                                    ?.address2
                                                    ? GCPropertyDetails
                                                          ?.InspectionAddress
                                                          ?.address2 + ", "
                                                    : ""
                                            }}
                                            {{
                                                GCPropertyDetails
                                                    ?.InspectionAddress?.city
                                                    ? GCPropertyDetails
                                                          ?.InspectionAddress
                                                          ?.city + ", "
                                                    : ""
                                            }}
                                            {{
                                                GCPropertyDetails
                                                    ?.InspectionAddress?.state
                                                    ? GCPropertyDetails
                                                          ?.InspectionAddress
                                                          ?.state + ", "
                                                    : ""
                                            }}
                                            {{
                                                GCPropertyDetails
                                                    ?.InspectionAddress?.zipcode
                                            }}
                                        </div>
                                        <div class="arrow-up"></div>
                                        <div class="popupContent">
                                            <strong
                                                >When the borrower (homeowner)
                                                has added you as a contractor,
                                                the property address will appear
                                                here.</strong
                                            >
                                            <br />
                                            <br />
                                            <button
                                                class="popupNextBtn"
                                                type="button"
                                                (click)="gotoNextPopup(3)"
                                            >
                                                Next
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- <a *ngIf="GCPropertyDetails.GoogleAlbum && GCPropertyDetails.GoogleAlbum != null" href="{{GCPropertyDetails.GoogleAlbum}}" target="_blank">
              {{(GCPropertyDetails?.InspectionAddress?.address1)?GCPropertyDetails?.InspectionAddress?.address1+', ':''}} {{(GCPropertyDetails?.InspectionAddress?.address2)?GCPropertyDetails?.InspectionAddress?.address2+', ':''}} 
              {{(GCPropertyDetails?.InspectionAddress?.city)?GCPropertyDetails?.InspectionAddress?.city+', ':''}} {{(GCPropertyDetails?.InspectionAddress?.state)?GCPropertyDetails?.InspectionAddress?.state+', ':''}}
              {{GCPropertyDetails?.InspectionAddress?.zipcode}}
            </a> -->
                        </td>
                        <!-- <td align="center">{{GCPropertyDetails.LoanOfficerName}}</td> -->
                        <td align="center">
                            {{ GCPropertyDetails.ConsultantName }}
                        </td>
                        <td align="center">
                            {{ GCPropertyDetails.BorrowerName }}
                        </td>
                        <!-- <td align="center">{{GCPropertyDetails.LoanType}}</td> -->
                        <td align="center">
                            <div class="proAddressContainer">
                                <span
                                    *ngIf="
                                        GCPropertyDetails.Status ==
                                            'Property File created' ||
                                        GCPropertyDetails.Status ==
                                            'DRAFT SOR Sent to Borrower' ||
                                        GCPropertyDetails.Status ==
                                            'Revise Draft'
                                    "
                                >
                                    Estimate process has not <br />started - No
                                    Action Required
                                </span>
                                <div
                                    *ngIf="
                                        userDetail.isGCFirstLogin == true &&
                                        userDetail.ispopupThreeShow == true &&
                                        (GCPropertyDetails.Status ==
                                            'Property File created' ||
                                            GCPropertyDetails.Status ==
                                                'DRAFT SOR Sent to Borrower' ||
                                            GCPropertyDetails.Status ==
                                                'Revise Draft')
                                    "
                                >
                                    <div class="popupContainer popupThree">
                                        <div class="focusSection">
                                            Estimate process has not
                                            <br />started yet - No action
                                            required
                                        </div>
                                        <div class="arrow-up"></div>
                                        <div class="popupContent">
                                            <strong
                                                >The borrower still needs to
                                                accept the consultant’s scope of
                                                work. Once the scope is
                                                accepted, you will be able to
                                                select to input your estimate
                                                here.<br /><br />
                                                In the meantime, would you like
                                                to check out some information
                                                regarding the estimate
                                                process?</strong
                                            >
                                            <br />
                                            <br />
                                            <a
                                                class="popupLeftBtn"
                                                href="javascript:void(0)"
                                                (click)="noThanks()"
                                                >No, Thanks</a
                                            >
                                            <button
                                                class="popupNextBtn"
                                                type="button"
                                                (click)="gotoNextPopup(0)"
                                            >
                                                Yes, Learn More!
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <span
                                    *ngIf="
                                        GCPropertyDetails.isAccepted != true &&
                                        GCPropertyDetails.isReviseFlag !=
                                            true &&
                                        GCPropertyDetails.isAlreadyBid ==
                                            true &&
                                        GCPropertyDetails.Status ==
                                            'Review Bid' &&
                                        GCPropertyDetails.Status !=
                                            'Pre Closing Completed' &&
                                        GCPropertyDetails.SubmittedBids &&
                                        GCPropertyDetails.SubmittedBids.indexOf(
                                            userDetail.Id
                                        ) > -1
                                    "
                                >
                                    Estimate successfully submitted for
                                    Homeowner & Consultant review - No Action
                                    Required<br />
                                </span>
                                <span
                                    *ngIf="
                                        GCPropertyDetails.isAccepted != true &&
                                        GCPropertyDetails.isReviseFlag ==
                                            true &&
                                        GCPropertyDetails.isAlreadyBid ==
                                            true &&
                                        (GCPropertyDetails.Status ==
                                            'Bid Process Started' ||
                                            GCPropertyDetails.Status ==
                                                'Review Bid') &&
                                        GCPropertyDetails.GeneralContractorBids &&
                                        GCPropertyDetails.GeneralContractorBids.indexOf(
                                            userDetail.Id
                                        ) > -1
                                    "
                                >
                                    A revision has been requested for your
                                    estimate - Click “Revise Estimate Here” to
                                    make additional changes.<br />
                                </span>

                                <span
                                    *ngIf="
                                        (GCPropertyDetails.sorDetails
                                            .FinalMailSendDate == '' ||
                                            GCPropertyDetails.sorDetails
                                                .FinalMailSendDate == null ||
                                            GCPropertyDetails.sorDetails
                                                .FinalMailSendDate ==
                                                undefined) &&
                                        GCPropertyDetails.Status ==
                                            'Pre Closing Completed'
                                    "
                                >
                                    Final SOR is being produced by the
                                    Consultant - No Action Required<br />
                                </span>

                                <span
                                    *ngIf="
                                        GCPropertyDetails.sorDetails
                                            .FinalMailSendDate != '' &&
                                        GCPropertyDetails.sorDetails
                                            .FinalMailSendDate != null &&
                                        GCPropertyDetails.sorDetails
                                            .FinalMailSendDate != undefined &&
                                        GCPropertyDetails.Status ==
                                            'Pre Closing Completed'
                                    "
                                >
                                    Final SOR has been submitted and waiting for
                                    loan closure - No Action Required<br />
                                </span>

                                <span
                                    *ngIf="
                                        GCPropertyDetails.Status ==
                                        'Post closing started'
                                    "
                                >
                                    Post Closing process has started - Use the
                                    Draw Request Form to request funds<br />
                                </span>
                                <span
                                    *ngIf="
                                        GCPropertyDetails.Status ==
                                            'Draw request started' &&
                                        (GCPropertyDetails.InitialDraws ==
                                            undefined ||
                                            GCPropertyDetails.InitialDraws ==
                                                null)
                                    "
                                >
                                    Post Closing process has started - Use the
                                    Draw Request Form to request funds<br />
                                </span>
                                <span
                                    *ngIf="
                                        GCPropertyDetails.Status ==
                                        'Final Draw Completed'
                                    "
                                >
                                    Final Draw Completed
                                </span>
                                <span
                                    *ngIf="
                                        GCPropertyDetails.Status ==
                                            'Bid Process Started' &&
                                        (!GCPropertyDetails.GeneralContractorBids ||
                                            GCPropertyDetails.GeneralContractorBids.indexOf(
                                                userDetail.Id
                                            ) < 0)
                                    "
                                >
                                    Would you like to submit an estimate<br />
                                    for this property?
                                </span>

                                <ng-container
                                    *ngIf="
                                        GCPropertyDetails.InitialDraws !=
                                            undefined &&
                                        GCPropertyDetails.InitialDraws.length >
                                            0
                                    "
                                >
                                    <span
                                        *ngIf="
                                            GCPropertyDetails.InitialDraws[
                                                GCPropertyDetails.InitialDraws
                                                    .length - 1
                                            ].Status == 'pending' &&
                                            GCPropertyDetails.InitialDraws[
                                                GCPropertyDetails.InitialDraws
                                                    .length - 1
                                            ].UpdatedBy != userDetail.Id
                                        "
                                        >Draw has been submitted for review -
                                        Use current draw section to review</span
                                    >
                                    <span
                                        *ngIf="
                                            GCPropertyDetails.InitialDraws[
                                                GCPropertyDetails.InitialDraws
                                                    .length - 1
                                            ].Status == 'revised' &&
                                            GCPropertyDetails.InitialDraws[
                                                GCPropertyDetails.InitialDraws
                                                    .length - 1
                                            ].UpdatedBy != userDetail.Id
                                        "
                                        >Draw revision has been requested - Use
                                        current draw section to review</span
                                    >
                                    <span
                                        *ngIf="
                                            GCPropertyDetails.InitialDraws[
                                                GCPropertyDetails.InitialDraws
                                                    .length - 1
                                            ].Status == 'pending' &&
                                            GCPropertyDetails.InitialDraws[
                                                GCPropertyDetails.InitialDraws
                                                    .length - 1
                                            ].UpdatedBy == userDetail.Id
                                        "
                                        >Draw has been submitted for review - No
                                        action at this time</span
                                    >
                                    <span
                                        *ngIf="
                                            GCPropertyDetails.InitialDraws[
                                                GCPropertyDetails.InitialDraws
                                                    .length - 1
                                            ].Status == 'revised' &&
                                            GCPropertyDetails.InitialDraws[
                                                GCPropertyDetails.InitialDraws
                                                    .length - 1
                                            ].UpdatedBy == userDetail.Id
                                        "
                                        >Draw revision has been requested - No
                                        action at this time</span
                                    >

                                    <span
                                        *ngIf="
                                            GCPropertyDetails.InitialDraws[
                                                GCPropertyDetails.InitialDraws
                                                    .length - 1
                                            ].Status == 'approved' &&
                                            GCPropertyDetails.InitialDraws[
                                                GCPropertyDetails.InitialDraws
                                                    .length - 1
                                            ].UpdatedBy !=
                                                GCPropertyDetails.ConsultantId
                                        "
                                        >Draw has been submitted to Consultant
                                        for review - No action at this
                                        time</span
                                    >
                                    <span
                                        *ngIf="
                                            GCPropertyDetails.InitialDraws[
                                                GCPropertyDetails.InitialDraws
                                                    .length - 1
                                            ].Status == 'approved' &&
                                            GCPropertyDetails.InitialDraws[
                                                GCPropertyDetails.InitialDraws
                                                    .length - 1
                                            ].UpdatedBy ==
                                                GCPropertyDetails.ConsultantId
                                        "
                                        >Draw has been performed by Consultant -
                                        View draw in Previous Draw Section</span
                                    >
                                </ng-container>
                                <span
                                    *ngIf="
                                        GCPropertyDetails.Status ==
                                            'Bid Process Started' &&
                                        (!GCPropertyDetails.GeneralContractorBids ||
                                            GCPropertyDetails.GeneralContractorBids.indexOf(
                                                userDetail.Id
                                            ) < 0)
                                    "
                                >
                                    <input
                                        type="radio"
                                        name="bidStatus"
                                        (click)="
                                            checkStatus(
                                                'yes',
                                                GCPropertyDetails
                                            )
                                        "
                                        value="yes"
                                    />
                                    Yes
                                    <input
                                        style="margin: 0 0 10px 15px"
                                        type="radio"
                                        name="bidStatus"
                                        (click)="
                                            checkStatus('no', GCPropertyDetails)
                                        "
                                        value="no"
                                    />
                                    No
                                    <br />
                                </span>
                                <div
                                    *ngIf="
                                        userDetail.isGCFirstLogin == true &&
                                        userDetail.ispopupThreeShow == true &&
                                        GCPropertyDetails.Status ==
                                            'Bid Process Started' &&
                                            (!GCPropertyDetails.GeneralContractorBids ||
                                                GCPropertyDetails.GeneralContractorBids.indexOf(
                                                    userDetail.Id
                                                ) < 0)
                                    "
                                >
                                    <div class="popupContainer popupThree">
                                        <div class="focusSection">
                                            Would you like to submit an estimate
                                            <br />for this property?&nbsp;
                                            &nbsp;&nbsp;<input
                                                type="radio"
                                                name="bidStatus"
                                                (click)="
                                                    checkStatus(
                                                        'yes',
                                                        GCPropertyDetails
                                                    )
                                                "
                                                value="yes"
                                            />
                                            Yes
                                            <input
                                                style="margin: 0 0 10px 15px"
                                                type="radio"
                                                name="bidStatus"
                                                (click)="
                                                    checkStatus(
                                                        'no',
                                                        GCPropertyDetails
                                                    )
                                                "
                                                value="no"
                                            />
                                            No
                                        </div>
                                        <div class="arrow-up"></div>
                                        <div class="popupContent">
                                            <strong
                                                >This is where you will select
                                                if you would like to participate
                                                in the estimate process for this
                                                property.</strong
                                            >
                                            <br />
                                            <br />
                                            <a
                                                class="popupLeftBtn"
                                                href="javascript:void(0)"
                                                (click)="comebackLater()"
                                                >Come Back Later</a
                                            >
                                            <button
                                                class="popupNextBtn"
                                                type="button"
                                                (click)="
                                                    checkStatus(
                                                        'yes',
                                                        GCPropertyDetails
                                                    )
                                                "
                                            >
                                                Start an Estimate
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <!-- routerLink="/property-input-bid/{{GCPropertyDetails.Id}}" -->
                                <a
                                    routerLink="/specification-repair-review/{{
                                        GCPropertyDetails.Id
                                    }}"
                                    *ngIf="
                                        GCPropertyDetails.isReviseFlag !=
                                            true &&
                                        GCPropertyDetails.isAlreadyBid !=
                                            true &&
                                        (GCPropertyDetails.Status ==
                                            'Bid Process Started' ||
                                            GCPropertyDetails.Status ==
                                                'Review Bid') &&
                                        GCPropertyDetails.GeneralContractorBids &&
                                            GCPropertyDetails.GeneralContractorBids.indexOf(
                                                userDetail.Id
                                            ) > -1
                                    "
                                    >Input Estimate Here<br
                                /></a>

                                <a
                                    (click)="
                                        openEditDialog(GCPropertyDetails.Id)
                                    "
                                    *ngIf="
                                        GCPropertyDetails.isAccepted != true &&
                                        GCPropertyDetails.isReviseFlag !=
                                            true &&
                                        GCPropertyDetails.isAlreadyBid ==
                                            true &&
                                        (GCPropertyDetails.Status ==
                                            'Bid Process Started' ||
                                            GCPropertyDetails.Status ==
                                                'Review Bid') &&
                                        GCPropertyDetails.GeneralContractorBids &&
                                            GCPropertyDetails.GeneralContractorBids.indexOf(
                                                userDetail.Id
                                            ) > -1
                                    "
                                    >Edit Estimate Here<br
                                /></a>

                                <a
                                    (click)="
                                        openReviseDialog(
                                            GCPropertyDetails.Id,
                                            GCPropertyDetails.boComment
                                        )
                                    "
                                    *ngIf="
                                        GCPropertyDetails.isAccepted != true &&
                                        GCPropertyDetails.isReviseFlag ==
                                            true &&
                                        GCPropertyDetails.isAlreadyBid ==
                                            true &&
                                        (GCPropertyDetails.Status ==
                                            'Bid Process Started' ||
                                            GCPropertyDetails.Status ==
                                                'Review Bid') &&
                                        GCPropertyDetails.GeneralContractorBids &&
                                            GCPropertyDetails.GeneralContractorBids.indexOf(
                                                userDetail.Id
                                            ) > -1
                                    "
                                    >Revise Estimate Here<br
                                /></a>

                                <strong
                                    class="textLeftAlign"
                                    *ngIf="
                                        GCPropertyDetails.AcceptedProposal ==
                                            userDetail.Id &&
                                        (GCPropertyDetails.Status ==
                                            'Pre Closing Completed' ||
                                            GCPropertyDetails.Status ==
                                                'Final Draw Completed' ||
                                            GCPropertyDetails.Status ==
                                                'Draw request started' ||
                                            GCPropertyDetails.Status ==
                                                'Post closing started')
                                    "
                                    ><br />Forms<br
                                /></strong>

                                <a
                                    class="textLeftAlign"
                                    *ngIf="
                                        GCPropertyDetails.AcceptedProposal ==
                                            userDetail.Id &&
                                        GCPropertyDetails.sorDetails
                                            .FinalMailSendDate != '' &&
                                            GCPropertyDetails.sorDetails
                                                .FinalMailSendDate != null &&
                                            GCPropertyDetails.sorDetails
                                                .FinalMailSendDate !=
                                                undefined &&
                                        (GCPropertyDetails.Status ==
                                            'Pre Closing Completed' ||
                                            GCPropertyDetails.Status ==
                                                'Final Draw Completed' ||
                                            GCPropertyDetails.Status ==
                                                'Draw request started' ||
                                            GCPropertyDetails.Status ==
                                                'Post closing started')
                                    "
                                    (click)="
                                        printFinalSOR(GCPropertyDetails.Id)
                                    "
                                    >Final Specification of Repairs <br
                                /></a>
                                <ng-container
                                    *ngIf="
                                        GCPropertyDetails.AcceptedProposal ==
                                            userDetail.Id &&
                                        (GCPropertyDetails.Status ==
                                            'Post closing started' ||
                                            GCPropertyDetails.Status ==
                                                'Draw request started')
                                    "
                                >
                                    <a
                                        class="textLeftAlign"
                                        routerLink="/initial-draw-request/{{
                                            GCPropertyDetails.Id
                                        }}"
                                        *ngIf="
                                            (GCPropertyDetails.InitialDraws ==
                                                '' ||
                                                GCPropertyDetails.InitialDraws ==
                                                    undefined ||
                                                GCPropertyDetails.InitialDraws ==
                                                    null) &&
                                            (GCPropertyDetails.Status ==
                                                'Bid Process Started' ||
                                                GCPropertyDetails.Status ==
                                                    'Pre Closing Completed' ||
                                                GCPropertyDetails.Status ==
                                                    'Draw request started' ||
                                                GCPropertyDetails.Status ==
                                                    'Post closing started')
                                        "
                                        >Draw Request - Fill this form to
                                        request funds <br
                                    /></a>
                                    <a
                                        class="textLeftAlign"
                                        routerLink="/initial-draw-request/{{
                                            GCPropertyDetails.Id
                                        }}"
                                        *ngIf="
                                            GCPropertyDetails.InitialDraws !=
                                                '' &&
                                            GCPropertyDetails.InitialDraws !=
                                                undefined &&
                                            GCPropertyDetails.InitialDraws !=
                                                null &&
                                            GCPropertyDetails.InitialDraws[
                                                GCPropertyDetails.InitialDraws
                                                    .length - 1
                                            ].Status == 'approved' &&
                                                GCPropertyDetails.InitialDraws[
                                                    GCPropertyDetails
                                                        .InitialDraws.length - 1
                                                ].ConsultantSendSignDate !=
                                                    null &&
                                                GCPropertyDetails.InitialDraws[
                                                    GCPropertyDetails
                                                        .InitialDraws.length - 1
                                                ].ConsultantSendSignDate !=
                                                    undefined &&
                                                GCPropertyDetails.InitialDraws[
                                                    GCPropertyDetails
                                                        .InitialDraws.length - 1
                                                ].ConsultantSendSignDate !=
                                                    '' &&
                                            (GCPropertyDetails.Status ==
                                                'Bid Process Started' ||
                                                GCPropertyDetails.Status ==
                                                    'Pre Closing Completed' ||
                                                GCPropertyDetails.Status ==
                                                    'Draw request started' ||
                                                GCPropertyDetails.Status ==
                                                    'Post closing started')
                                        "
                                        >Draw Request - Fill this form to
                                        request funds<br
                                    /></a>
                                    <a
                                        class="textLeftAlign"
                                        (click)="notAllowDraw()"
                                        *ngIf="
                                            GCPropertyDetails.InitialDraws !=
                                                '' &&
                                            GCPropertyDetails.InitialDraws !=
                                                undefined &&
                                            GCPropertyDetails.InitialDraws !=
                                                null &&
                                            GCPropertyDetails.InitialDraws[
                                                GCPropertyDetails.InitialDraws
                                                    .length - 1
                                            ].Status == 'approved' &&
                                                (GCPropertyDetails.InitialDraws[
                                                    GCPropertyDetails
                                                        .InitialDraws.length - 1
                                                ].ConsultantSendSignDate ==
                                                    null ||
                                                    GCPropertyDetails
                                                        .InitialDraws[
                                                        GCPropertyDetails
                                                            .InitialDraws
                                                            .length - 1
                                                    ].ConsultantSendSignDate ==
                                                        undefined ||
                                                    GCPropertyDetails
                                                        .InitialDraws[
                                                        GCPropertyDetails
                                                            .InitialDraws
                                                            .length - 1
                                                    ].ConsultantSendSignDate ==
                                                        '') &&
                                            (GCPropertyDetails.Status ==
                                                'Bid Process Started' ||
                                                GCPropertyDetails.Status ==
                                                    'Pre Closing Completed' ||
                                                GCPropertyDetails.Status ==
                                                    'Draw request started' ||
                                                GCPropertyDetails.Status ==
                                                    'Post closing started')
                                        "
                                        >Draw Request - Fill this form to
                                        request funds<br
                                    /></a>

                                    <a
                                        class="textLeftAlign"
                                        href="javascript:void(0);"
                                        *ngIf="
                                            (GCPropertyDetails.Status ==
                                                'Bid Process Started' ||
                                                GCPropertyDetails.Status ==
                                                    'Pre Closing Completed') &&
                                            GCPropertyDetails.Status !=
                                                'Draw request started' &&
                                                GCPropertyDetails.Status !=
                                                    'Post closing started' &&
                                                GCPropertyDetails.Status !=
                                                    'Final Draw Completed' &&
                                            GCPropertyDetails.sorDetails
                                                .FinalMailSendDate != null &&
                                                GCPropertyDetails.sorDetails
                                                    .FinalMailSendDate !=
                                                    undefined &&
                                                GCPropertyDetails.sorDetails
                                                    .FinalMailSendDate != ''
                                        "
                                        (click)="
                                            printFinalSOR(GCPropertyDetails.Id)
                                        "
                                        data="19890"
                                        class="agrmnt-signed-by-bo"
                                        >Print final SOR <br
                                    /></a>
                                    <a
                                        class="textLeftAlign"
                                        href="javascript:void(0);"
                                        (click)="
                                            printPdf('assets/pdf/92577.pdf')
                                        "
                                        *ngIf="
                                            GCPropertyDetails.Status ==
                                                'Bid Process Started' ||
                                            GCPropertyDetails.Status ==
                                                'Pre Closing Completed' ||
                                            GCPropertyDetails.Status ==
                                                'Final Draw Completed' ||
                                            GCPropertyDetails.Status ==
                                                'Draw request started' ||
                                            GCPropertyDetails.Status ==
                                                'Post closing started'
                                        "
                                        >Change Order Form</a
                                    >
                                    <a
                                        class="textLeftAlign"
                                        *ngIf="
                                            GCPropertyDetails.Id != '' &&
                                            GCPropertyDetails.Status !=
                                                'Post closing started' &&
                                            GCPropertyDetails.Status !=
                                                'Final Draw Completed' &&
                                            GCPropertyDetails.Status !=
                                                'Draw request started' &&
                                            GCPropertyDetails.SubmittedBids &&
                                            GCPropertyDetails.SubmittedBids.indexOf(
                                                userDetail.Id
                                            ) > -1
                                        "
                                        (click)="
                                            getBorPDF(
                                                GCPropertyDetails.Id,
                                                userDetail.Id
                                            )
                                        "
                                        >Print Estimate to PDF<br
                                    /></a>

                                    <a
                                        class="textLeftAlign"
                                        *ngIf="
                                            GCPropertyDetails.AcceptedProposal ==
                                                userDetail.Id &&
                                            GCPropertyDetails.Status !=
                                                'Post closing started' &&
                                            GCPropertyDetails.Status !=
                                                'Final Draw Completed' &&
                                            GCPropertyDetails.Status !=
                                                'Draw request started'
                                        "
                                        (click)="
                                            GetBlankBORPdf(
                                                GCPropertyDetails.Id,
                                                userDetail.Id
                                            )
                                        "
                                        >Template Scope of Work Form<br
                                    /></a>
                                </ng-container>

                                <ng-container
                                    *ngIf="
                                        GCPropertyDetails.InitialDraws !=
                                            undefined &&
                                        GCPropertyDetails.InitialDraws.length >
                                            0
                                    "
                                >
                                    <strong class="textLeftAlign"
                                        ><br />Current Draws<br
                                    /></strong>
                                    <a
                                        class="textLeftAlign"
                                        *ngIf="
                                            CurrentDrawShow == true &&
                                            GCPropertyDetails.InitialDraws !=
                                                undefined &&
                                            GCPropertyDetails.InitialDraws
                                                .length > 0
                                        "
                                        (click)="
                                            printDrawRequest(
                                                GCPropertyDetails.InitialDraws[
                                                    GCPropertyDetails
                                                        .InitialDraws.length - 1
                                                ].DrawId
                                            )
                                        "
                                    >
                                        Print current draw form<br
                                    /></a>
                                    <strong
                                        class="textLeftAlign"
                                        *ngIf="CurrentDrawShow == false"
                                        >There are no current draws - Use the
                                        form above to request funds<br
                                    /></strong>

                                    <ng-container
                                        *ngFor="
                                            let DrawDetails of GCPropertyDetails.InitialDraws;
                                            let i = index
                                        "
                                    >
                                        <a
                                            class="textLeftAlign"
                                            *ngIf="
                                                DrawDetails.Status ==
                                                    'pending' &&
                                                GCPropertyDetails.InitialDraws[
                                                    i
                                                ].UpdatedBy == userDetail.Id
                                            "
                                            routerLink="/initial-draw-request/{{
                                                GCPropertyDetails.Id
                                            }}"
                                            >Draw #{{ i + 1 }} - Requested by Me
                                            ({{
                                                DrawDetails.GCRequestDate
                                                    | date : "M/d/y h:mm"
                                            }}) <br
                                        /></a>
                                        <a
                                            class="textLeftAlign"
                                            *ngIf="
                                                DrawDetails.Status ==
                                                    'revised' &&
                                                GCPropertyDetails.InitialDraws[
                                                    i
                                                ].UpdatedBy == userDetail.Id
                                            "
                                            routerLink="/initial-draw-request-bo/{{
                                                GCPropertyDetails.Id
                                            }}/{{ DrawDetails.DrawId }}"
                                            >Draw #{{ i + 1 }} - Revision
                                            Requested by Me ({{
                                                DrawDetails.GCRequestDate
                                                    | date : "M/d/y h:mm"
                                            }}) <br
                                        /></a>
                                    </ng-container>
                                    <ng-container
                                        *ngFor="
                                            let DrawDetails of GCPropertyDetails.InitialDraws;
                                            let i = index
                                        "
                                    >
                                        <a
                                            class="textLeftAlign"
                                            *ngIf="
                                                DrawDetails.Status ==
                                                    'pending' &&
                                                GCPropertyDetails.InitialDraws[
                                                    i
                                                ].UpdatedBy != userDetail.Id
                                            "
                                            routerLink="/initial-draw-request-bo/{{
                                                GCPropertyDetails.Id
                                            }}/{{ DrawDetails.DrawId }}"
                                            >Draw #{{ i + 1 }} - Requested by BO
                                            ({{
                                                DrawDetails.GCRequestDate
                                                    | date : "M/d/y h:mm"
                                            }})<br
                                        /></a>
                                        <a
                                            class="textLeftAlign"
                                            *ngIf="
                                                DrawDetails.Status ==
                                                    'revised' &&
                                                GCPropertyDetails.InitialDraws[
                                                    i
                                                ].UpdatedBy != userDetail.Id
                                            "
                                            routerLink="/initial-draw-request/{{
                                                GCPropertyDetails.Id
                                            }}"
                                            >Draw #{{ i + 1 }} - Revision
                                            Requested by Bo ({{
                                                DrawDetails.GCRequestDate
                                                    | date : "M/d/y h:mm"
                                            }}) <br
                                        /></a>
                                    </ng-container>
                                    <strong
                                        class="textLeftAlign"
                                        *ngIf="PreviousDrawShow == true"
                                        ><br />Previous Draws<br
                                    /></strong>
                                    <ng-container
                                        *ngFor="
                                            let DrawDetails of GCPropertyDetails.InitialDraws;
                                            let i = index
                                        "
                                    >
                                        <a
                                            class="textLeftAlign"
                                            *ngIf="
                                                DrawDetails.Status ==
                                                    'approved' &&
                                                GCPropertyDetails.InitialDraws[
                                                    i
                                                ].ConsultantSendSignDate !=
                                                    null &&
                                                GCPropertyDetails.InitialDraws[
                                                    i
                                                ].ConsultantSendSignDate !=
                                                    undefined &&
                                                GCPropertyDetails.InitialDraws[
                                                    i
                                                ].ConsultantSendSignDate != ''
                                            "
                                            (click)="
                                                printDrawRequest(
                                                    DrawDetails.DrawId
                                                )
                                            "
                                            >Draw #{{ i + 1 }} - Inspected on
                                            {{
                                                DrawDetails.ConsultantRequestDate
                                                    | date : "M/d/y"
                                            }}; Submitted on
                                            {{
                                                DrawDetails.ConsultantSendSignDate
                                                    | date : "M/d/y"
                                            }}
                                            <br
                                        /></a>
                                        <br
                                            *ngIf="
                                                PreviousDrawShow == true &&
                                                GCPropertyDetails.InitialDraws
                                                    .length -
                                                    1 ==
                                                    i
                                            "
                                        />
                                    </ng-container>
                                </ng-container>

                                <!-- <a *ngIf="GCPropertyDetails.AcceptedProposal == userDetail.Id">
              Input Bid Here
            </a> -->
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
