import {
    Component,
    OnInit,
    ViewChild,
    Inject,
    ViewEncapsulation,
    EventEmitter,
    Output,
    AfterViewInit,
    Input,
    Pipe,
    PipeTransform,
} from "@angular/core";
import {
    FormGroup,
    Validators,
    FormControl,
    ReactiveFormsModule,
    FormArray,
    FormBuilder,
} from "@angular/forms";
import { MatDialog, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
// import { DataSource } from '@angular/cdk/table';
import { PropertyService } from "./property.service";
import { BorrowerService } from "../borrower/borrower.service";
import { SessionService } from "../service/session.service";

// import { $ } from 'protractor';
// import * as moment from 'moment';
// import { formatDate } from '@angular/common';
import { Router, ActivatedRoute } from "@angular/router";

export interface UserData {
    id: string;
    name: string;
    email: string;
    lastLogin: Date;
}

export interface DialogData {
    propertyId: string;
    isRevise: string;
    comment: string;
}

/** Constants used to fill up our data base. */
const COLORS: string[] = [
    "maroon",
    "red",
    "orange",
    "yellow",
    "olive",
    "green",
    "purple",
    "fuchsia",
    "lime",
    "teal",
    "aqua",
    "blue",
    "navy",
    "black",
    "gray",
];

const NAMES: string[] = [
    "Maia",
    "Asher",
    "Olivia",
    "Atticus",
    "Amelia",
    "Jack",
    "Charlotte",
    "Theodore",
    "Isla",
    "Oliver",
    "Isabella",
    "Jasper",
    "Cora",
    "Levi",
    "Violet",
    "Arthur",
    "Mia",
    "Thomas",
    "Elizabeth",
];

/**
 * @title Data table with sorting, pagination, and filtering.
 */

@Component({
    selector: "app-property",
    templateUrl: "./property.component.html",
    styleUrls: ["./property.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class PropertyComponent implements OnInit {
    baseurl = this.sessionService.endpoint;
    public editorValue: string = "";
    @Output() setAddress: EventEmitter<any> = new EventEmitter();
    displayedColumns: string[] = [
        "id",
        "InspectionAddress",
        "Consultant",
        "Borrower",
        "LoanOfficer",
        "LoanType",
        "status",
    ];
    dataSource: MatTableDataSource<UserData>;
    mobnumPattern = "^(d{3})sd{3}-d{4}";
    add_property = new FormGroup({
        email: new FormControl(null, [Validators.required, Validators.email,Validators.maxLength(40),Validators.minLength(5)]),
        fname: new FormControl(null, [Validators.required, Validators.maxLength(30),Validators.minLength(3)]),
        lname: new FormControl(null, [Validators.required, Validators.maxLength(30),Validators.minLength(3)]),
        // phone_no: new FormControl(null, [Validators.pattern(this.mobnumPattern),Validators.maxLength(15),Validators.minLength(8)]),
        phone_no: new FormControl(null, []),
        borrowerId: new FormControl(null),
        gcemail: new FormControl(null, [Validators.email,Validators.maxLength(40),Validators.minLength(5)]),
        gcfname: new FormControl(null,[Validators.maxLength(30),Validators.minLength(3)]),
        gclname: new FormControl(null,[Validators.maxLength(30),Validators.minLength(3)]),
        gcphone_no: new FormControl(null, []),
        gcId: new FormControl(null),
        RTemail: new FormControl(null, [Validators.email,Validators.maxLength(40),Validators.minLength(5)]),
        RTfname: new FormControl(null,[Validators.maxLength(30),Validators.minLength(3)]),
        RTlname: new FormControl(null,[Validators.maxLength(30),Validators.minLength(3)]),
        RTphone_no: new FormControl(null, []),
        RTid: new FormControl(null),
        LPemail: new FormControl(null, [Validators.email,Validators.maxLength(40),Validators.minLength(5)]),
        LPfname: new FormControl(null,[Validators.maxLength(30),Validators.minLength(3)]),
        LPlname: new FormControl(null,[Validators.maxLength(30),Validators.minLength(3)]),
        LPphone_no: new FormControl(null, []),
        LPpassword: new FormControl(Math.random().toString(36).slice(-8)),
        LPid: new FormControl(null),
        statusType: new FormControl(null),
        consultant: new FormControl(null, [Validators.required]),
        loanOfficer: new FormControl(null, [Validators.required]),
        transactionType: new FormControl(null),
        home_borrower: new FormControl(null),
        city: new FormControl(null, [Validators.required]),
        state: new FormControl(null, [Validators.required]),
        zipcode: new FormControl(null, [Validators.maxLength(10),Validators.minLength(3)]),
        address1: new FormControl(null, [Validators.required]),
        address2: new FormControl(null),
        fHACaseNo: new FormControl(null, [Validators.maxLength(20),Validators.minLength(3)]),
        LoanNo: new FormControl(null, [Validators.maxLength(20),Validators.minLength(3)]),
        loanType: new FormControl(null, [Validators.required]),
        realtor: new FormControl(null),
        renovationCompleted: new FormControl(false),
        renovationCompletedDate: new FormControl(null),
        bidProcessStartDate: new FormControl(null),
        inspectionDate: new FormControl(null),
        biddingDueDate: new FormControl(null),
        propertyStatus: new FormControl(null),
        agreementSignedByBo: new FormControl(null),
        lender: new FormControl(null, [Validators.required]),
        loanProcessor: new FormControl(null),
        latitude: new FormControl(null),
        longitude: new FormControl(null),
        id: new FormControl(null),
        LOemail: new FormControl(null, [Validators.email,Validators.maxLength(40),Validators.minLength(5)]),
        LOfname: new FormControl(null,[Validators.maxLength(30),Validators.minLength(3)]),
        LOlname: new FormControl(null,[Validators.maxLength(30),Validators.minLength(3)]),
        LOphone_no: new FormControl(null, []),
        LOpassword: new FormControl(Math.random().toString(36).slice(-8)),
        LOid: new FormControl(null),
        addBemail: new FormControl("", [Validators.email,Validators.maxLength(40),Validators.minLength(5)]),
        addBfname: new FormControl("",[Validators.maxLength(30),Validators.minLength(3)]),
        addBlname: new FormControl("",[Validators.maxLength(30),Validators.minLength(3)]),
    });

    add_gcinfo = new FormGroup({
        email: new FormControl(null, [Validators.required, Validators.email,Validators.maxLength(40),Validators.minLength(5)]),
        fname: new FormControl(null, [Validators.required, Validators.maxLength(30),Validators.minLength(3)]),
        lname: new FormControl(null, [Validators.required, Validators.maxLength(30),Validators.minLength(3)]),
        phone_no: new FormControl(null, []),
        license: new FormControl(null, [Validators.required,Validators.maxLength(15),Validators.minLength(3)]),
        license_expire: new FormControl(null, [Validators.required]),
        leadSafe: new FormControl(null),
        leadSafe_expire: new FormControl(null),
        company_name: new FormControl(null,[Validators.maxLength(30),Validators.minLength(3)]),
        areas: new FormControl(null, [Validators.required]),
        company_address1: new FormControl(null),
        company_address2: new FormControl(null),
        company_city: new FormControl(null),
        company_state: new FormControl(null),
        company_zipcode: new FormControl(null, [Validators.maxLength(10),Validators.minLength(3)]),
    });

    formGroup: FormGroup;

    additional_borrower: any = [];

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    propertyList: any = {};

    minDate: Date = new Date();
    byAddPropery: boolean = false;
    loanNumber: any = null;
    isList: boolean = true;
    isAdd: boolean = false;
    isEdit: boolean = false;
    submitted: boolean = false;
    islenderDisabled: boolean = false;
    isloanOfficerDisabled: boolean = false;
    InactiveChecked: boolean = false;
    CompletedChecked: boolean = false;
    popupPropertyId = null;
    PreviousDrawShow: boolean = false;
    CurrentDrawShow: boolean = false;
    loading = false;
    favoriteSeason: string;
    loan_officer_list: any = [];
    filter_loan_officer_list: any = [];
    filter_loan_officer_list_check = false;
    filter_loan_processor_list_check = false;
    loan_processor_list: any = [];
    filter_loan_processor_list: any = [];
    consultent_list: any = [];
    borrower_list: any = [];
    contractor_list: any = [];
    lender_list: any = {};
    realtor_list: any = [];
    GCPropertyList: any = [];
    tempGCPropertyList: any = [];
    displayAddBorrower: boolean = false;
    pdfData: any;
    formattedaddress = "";
    propertyRepairConstructionDetail: any;
    options = {
        componentRestrictions: {
            country: ["US"],
        },
    };
    showAddLoanOfficer: boolean = false;
    gcStepOneShow: boolean = false;
    gcStepTwoShow: boolean = false;
    isPropertyDetailsHidden: boolean = false;
    isIntroPageHidden: boolean = false;
    ispopupOneShow: boolean = false;
    ispopupTwoShow: boolean = false;
    ispopupThreeShow: boolean = false;
    userDetail: any = {};

    status: any = ["Blocked", "Active"];
    roles: any = [
        { Key: "1", Value: "authenticated user" },
        { Key: "2", Value: "Administrator" },
        { Key: "3", Value: "Consultants" },
        { Key: "4", Value: "General Contractors" },
        { Key: "5", Value: "Borrower" },
        { Key: "6", Value: "Loan Officer" },
        { key: "7", Value: "Loan Processor" },
        { key: "8", Value: " Notify user of new account" },
        { key: "9", Value: "Draw Administrator" },
    ];
    maskPhoneFormat: any = '(000) 000-0000';
    constructor(
        private _bservice: PropertyService,
        private _boservice: BorrowerService,
        public dialog: MatDialog,
        private sessionService: SessionService,
        @Inject(FormBuilder) private _fb: FormBuilder,
        @Inject(Router) private router: Router,
        @Inject(ActivatedRoute) private route: ActivatedRoute
    ) {}

    ngOnInit() {
        this.propertyList.Items = [];
        this.propertyList.Count = 0;
        this.propertyList.ScannedCount = 0;
        this.propertyList.TotalRecord = 0;
        this.minDate = new Date();
        this.loading = true;
        this.propertyRepairConstructionDetail = {
            PropertyId: 1,
            Title: "Title",
            OccupiedDuringConstruction: "",
            BorrowerName: "",
            InspectionDate: "",
            InspectionAddress: "",
            BorrowerPhone: "",
            FHACase: "",
            LoanNo: "",
            Constructions: [],
            ContingencyReservePercentage: "",
            AllowableFees: "",
            ConsultantComment: "",
            DateofFinalAcceptance: "",
            ConsultantSignature: "",
            ConsultantSignDate: "",
            Consultant: "",
            IdNo: "",
            ApplicantsSignAndDate: "",
            ContractorSignature: "",
            ContractorsSignatureDate: "",
            Approved: "",
            ApprovedDate: "",
            MonthToCompletion: "",
            SorStatus: "",
            MailSend: "",
            MailSendDate: "",
            RevisedStatus: "",
            IsFinal: "",
            CreatedBy: "CreatedBy",
            CreatedDate: "CreatedDate",
            UpdateDate: "UpdateDate",
            UpdatedBy: "UpdatedBy",
        };
        var LastLoginDateTime = this.sessionService.get("LastLoginDateTime");

        if (this.sessionService.get("userInfo") != undefined) {
            this.userDetail = JSON.parse(this.sessionService.get("userInfo"));
        }

        if (
            (this.userDetail.LastLoginDateTime != null &&
                this.userDetail.LastLoginDateTime != "" &&
                this.userDetail.LastLoginDateTime != undefined) ||
            (LastLoginDateTime != null &&
                LastLoginDateTime != "" &&
                LastLoginDateTime != undefined)
        ) {
            this.userDetail.isGCFirstLogin = false;
            this.userDetail.isIntroPageHidden = true;
            this.userDetail.isPropertyDetailsHidden = false;
        } else {
            this.userDetail.isPropertyDetailsHidden = true;
            this.userDetail.isIntroPageHidden = false;
            this.userDetail.isGCFirstLogin = true;
            this.gcStepOneShow = true;

            this.add_gcinfo.controls["fname"].setValue(
                this.userDetail.FirstName
            );
            this.add_gcinfo.controls["lname"].setValue(
                this.userDetail.LastName
            );
            this.add_gcinfo.controls["email"].setValue(this.userDetail.EmailId);
            this.add_gcinfo.controls["phone_no"].setValue(
                this.userDetail.Phone
            );

            this.add_gcinfo.controls["license"].setValue(
                this.userDetail.ContractorLicense
            );
            this.add_gcinfo.controls["license_expire"].setValue(
                this.userDetail.ContractorLicenseExpire
            );
            this.add_gcinfo.controls["leadSafe"].setValue(
                this.userDetail.LeadSafeCertified
            );
            this.add_gcinfo.controls["leadSafe_expire"].setValue(
                this.userDetail.LeadSafeCertifiedExpire
            );

            this.add_gcinfo.controls["company_name"].setValue(
                this.userDetail.CompanyName
            );
            this.add_gcinfo.controls["areas"].setValue(
                this.userDetail.ServiceAreas
            );

            this.add_gcinfo.controls["company_address1"].setValue(
                this.userDetail.CompanyAddress1
            );
            this.add_gcinfo.controls["company_address2"].setValue(
                this.userDetail.CompanyAddress2
            );
            this.add_gcinfo.controls["company_city"].setValue(
                this.userDetail.CompanyCity
            );
            this.add_gcinfo.controls["company_state"].setValue(
                this.userDetail.CompanyState
            );
            this.add_gcinfo.controls["company_zipcode"].setValue(
                this.userDetail.CompanyZipcode
            );
        }

        this.GetloanOfficerUserList();
        this.GetBorrowerUserList();
        this.GetloanProcessorUserList();
        this.GetConsultentUserList();
        this.GetContractorUserList();
        this.GetLenderList();
        this.GetRealtorList();

        var propertyId = this.route.snapshot.paramMap.get("propertyId");
        if (propertyId != null && propertyId != undefined) {
            this.getPropertyDetails(propertyId);
        } else {
            this.GetPropertyList();
        }
    }
    changeCompletedChecked(target) {
        var status = false;
        if (target.checked) {
            status = true;
        } else {
            status = false;
        }
        this.CompletedChecked = status;
        this.GetPropertyList();
    }
    changeInactiveChecked(target) {
        var status = false;
        if (target.checked) {
            status = true;
        } else {
            status = false;
        }
        this.InactiveChecked = status;
        this.GetPropertyList();
    }
    keyDownPhoneFormat($event) {
        var key = $event.keyCode;

        if (key == 91 || (15 < key && key < 19) || (37 <= key && key <= 40)) {
            return;
        }
        this.changePhoneFormat($event);
    }

    changePhoneFormat($event) {
        var getVal = $event.target.value;
        var tel = getVal.replace(/[^0-9]/g, "");
        $event.target.value = this.changeFilter(tel);
    }
    changeFilter(tel) {
        if (!tel) {
            return "";
        }

        var value = tel.toString().trim().replace(/^\+/, "");

        if (value.match(/[^0-9]/)) {
            return tel;
        }

        var country, city, number;

        switch (value.length) {
            case 1:
            case 2:
            case 3:
                city = value;
                break;

            default:
                city = value.slice(0, 3);
                number = value.slice(3);
        }

        if (number) {
            if (number.length > 3) {
                number = number.slice(0, 3) + "-" + number.slice(3, 7);
            } else {
                number = number;
            }

            return ("(" + city + ") " + number).trim();
        } else {
            return "(" + city;
        }
    }

    public AddressChange(address: any) {
        let addressFielsLength = address.address_components.length;
        let address1 = "";
        let city = "";
        let state = "";
        let zipcode = "";

        for (let i = 0; i < addressFielsLength; i++) {
            if (address.address_components[i].types[0] == "street_number") {
                address1 = address.address_components[i].long_name;
            }

            if (address.address_components[i].types[0] == "route") {
                address1 =
                    address1 + " " + address.address_components[i].long_name;
            }

            if (
                address.address_components[i].types[0] ==
                    "sublocality_level_1" ||
                address.address_components[i].types[0] == "locality" ||
                address.address_components[i].types[0] == "neighborhood"
            ) {
                city = address.address_components[i].long_name;
            }
            if (
                address.address_components[i].types[0] ==
                "administrative_area_level_1"
            ) {
                state = address.address_components[i].short_name;
            }
            if (address.address_components[i].types[0] == "postal_code") {
                zipcode = address.address_components[i].long_name;
            }
        }

        this.add_property.controls["address1"].setValue(address1);
        this.add_property.controls["city"].setValue(city);
        this.add_property.controls["state"].setValue(state);
        this.add_property.controls["zipcode"].setValue(zipcode);
    }
    public AddressChange2(address: any) {
        let addressFielsLength = address.address_components.length;
        let address1 = "";
        let city = "";
        let state = "";
        let zipcode = "";

        for (let i = 0; i < addressFielsLength; i++) {
            if (address.address_components[i].types[0] == "street_number") {
                address1 = address.address_components[i].long_name;
            }

            if (address.address_components[i].types[0] == "route") {
                address1 =
                    address1 + " " + address.address_components[i].long_name;
            }

            if (
                address.address_components[i].types[0] ==
                    "sublocality_level_1" ||
                address.address_components[i].types[0] == "locality" ||
                address.address_components[i].types[0] == "neighborhood"
            ) {
                city = address.address_components[i].long_name;
            }
            if (
                address.address_components[i].types[0] ==
                "administrative_area_level_1"
            ) {
                state = address.address_components[i].short_name;
            }
            if (address.address_components[i].types[0] == "postal_code") {
                zipcode = address.address_components[i].long_name;
            }
        }

        this.add_gcinfo.controls["company_address1"].setValue(address1);
        this.add_gcinfo.controls["company_city"].setValue(city);
        this.add_gcinfo.controls["company_state"].setValue(state);
        this.add_gcinfo.controls["company_zipcode"].setValue(zipcode);
    }

    SaveGCData() {
        var formData = this.add_gcinfo.value;
        if (
            formData.areas == "" ||
            formData.areas == null ||
            formData.areas == undefined
        ) {
            this.sessionService.notifier.notify(
                "error",
                "Please enter Service Areas value."
            );
            this.loading = false;
            return;
        } else {
            var data = this.userDetail;

            data.Id = this.userDetail.Id;
            data.EmailId = formData.email;
            data.Password = this.userDetail.Password;
            data.UserType = this.userDetail.UserType;
            data.FirstName = formData.fname;
            data.LastName = formData.lname;
            data.Phone = formData.phone_no;
            data.ContractorLicense = formData.license;
            data.ContractorLicenseExpire = formData.license_expire;
            data.LeadSafeCertified = formData.leadSafe;
            data.LeadSafeCertifiedExpire = formData.leadSafe_expire;
            data.CompanyName = formData.company_name;
            data.ServiceAreas = formData.areas;
            data.CompanyAddress1 = formData.company_address1;
            data.CompanyAddress2 = formData.company_address2;
            data.CompanyCity = formData.company_city;
            data.CompanyState = formData.company_state;
            data.CompanyZipcode = formData.company_zipcode;
            data.UpdatedBy = this.userDetail.EmailId;
            data.LastLoginDateTime = this.userDetail.UpdateDate;

            this._boservice.UpdateBorrower(data).subscribe(
                (response) => {
                    var newData = response.Attributes;
                    this.add_gcinfo.controls["fname"].setValue(
                        newData.FirstName
                    );
                    this.add_gcinfo.controls["lname"].setValue(
                        newData.LastName
                    );
                    this.add_gcinfo.controls["email"].setValue(newData.EmailId);
                    this.add_gcinfo.controls["phone_no"].setValue(
                        newData.Phone
                    );

                    this.add_gcinfo.controls["license"].setValue(
                        newData.ContractorLicense
                    );
                    this.add_gcinfo.controls["license_expire"].setValue(
                        newData.ContractorLicenseExpire
                    );
                    this.add_gcinfo.controls["leadSafe"].setValue(
                        newData.LeadSafeCertified
                    );
                    this.add_gcinfo.controls["leadSafe_expire"].setValue(
                        newData.LeadSafeCertifiedExpire
                    );

                    this.add_gcinfo.controls["company_name"].setValue(
                        newData.CompanyName
                    );
                    this.add_gcinfo.controls["areas"].setValue(
                        newData.ServiceAreas
                    );

                    this.add_gcinfo.controls["company_address1"].setValue(
                        newData.CompanyAddress1
                    );
                    this.add_gcinfo.controls["company_address2"].setValue(
                        newData.CompanyAddress2
                    );
                    this.add_gcinfo.controls["company_city"].setValue(
                        newData.CompanyCity
                    );
                    this.add_gcinfo.controls["company_state"].setValue(
                        newData.CompanyState
                    );
                    this.add_gcinfo.controls["company_zipcode"].setValue(
                        newData.CompanyZipcode
                    );
                    this.sessionService.notifier.notify(
                        "success",
                        "General Contractor Info saved successfully."
                    );
                    this.userDetail.isPropertyDetailsHidden = false;
                    this.userDetail.isIntroPageHidden = true;
                    this.userDetail.ispopupOneShow = true;
                    this.sessionService.set(
                        "LastLoginDateTime",
                        data.LastLoginDateTime
                    );
                    this.loading = false;
                },
                (error) => {
                    this.sessionService.notifier.notify(
                        "error",
                        error.error.message
                    );
                    this.loading = false;
                }
            );
        }
    }
    saveGCStepOne() {
        var formData = this.add_gcinfo.value;
        if (
            formData.fname == "" ||
            formData.fname == null ||
            formData.fname == undefined
        ) {
            this.sessionService.notifier.notify(
                "error",
                "Please enter First Name value."
            );
            this.loading = false;
            return;
        } else if (
            formData.lname == "" ||
            formData.lname == null ||
            formData.lname == undefined
        ) {
            this.sessionService.notifier.notify(
                "error",
                "Please enter Last Name value."
            );
            this.loading = false;
            return;
        } else if (
            formData.email == "" ||
            formData.email == null ||
            formData.email == undefined
        ) {
            this.sessionService.notifier.notify(
                "error",
                "Please enter Email Address value."
            );
            this.loading = false;
            return;
        } else if (
            formData.license == "" ||
            formData.license == null ||
            formData.license == undefined
        ) {
            this.sessionService.notifier.notify(
                "error",
                "Please enter Contractor License value."
            );
            this.loading = false;
            return;
        } else if (
            formData.license_expire == "" ||
            formData.license_expire == null ||
            formData.license_expire == undefined
        ) {
            this.sessionService.notifier.notify(
                "error",
                "Please enter License Expire value."
            );
            this.loading = false;
            return;
        } else {
            this.gcStepOneShow = false;
            this.gcStepTwoShow = true;
        }
    }
    comebackLater() {
        this.userDetail.ispopupOneShow = false;
        this.userDetail.ispopupTwoShow = false;
        this.userDetail.ispopupThreeShow = false;
        this.userDetail.isGCFirstLogin = false;
    }
    noThanks() {
        this.userDetail.ispopupOneShow = false;
        this.userDetail.ispopupTwoShow = false;
        this.userDetail.ispopupThreeShow = false;
        this.userDetail.isGCFirstLogin = false;
    }
    gotoNextPopup(index) {
        if (index == 2) {
            this.userDetail.ispopupOneShow = false;
            this.userDetail.ispopupTwoShow = true;
            this.userDetail.ispopupThreeShow = false;
        } else if (index == 3) {
            this.userDetail.ispopupOneShow = false;
            this.userDetail.ispopupTwoShow = false;
            this.userDetail.ispopupThreeShow = true;
        } else {
            this.userDetail.ispopupOneShow = false;
            this.userDetail.ispopupTwoShow = false;
            this.userDetail.ispopupThreeShow = false;
            this.userDetail.ispopupFourShow = false;
            this.userDetail.isGCFirstLogin = false;
        }
    }
    goToStepOne() {
        this.gcStepTwoShow = false;
        this.gcStepOneShow = true;
    }

    applyFilter(filterValue) {
        const target = filterValue as HTMLInputElement;
        const targetValue = target.value;
        this.dataSource.filter = targetValue.trim().toLowerCase();

        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    }

    initBorrower() {
        // initialize our address
        return [
            this._fb.group({
                email: ["", Validators.required],
                fname: ["", Validators.required],
                lname: ["", Validators.required],
                phone_no: ["", Validators.pattern(this.mobnumPattern)],
                borrowerId: [""],
            }),
        ];
    }

    showAdditionalBorrower() {
        if (this.displayAddBorrower) this.displayAddBorrower = false;
        else this.displayAddBorrower = true;
    }

    addBorrower() {
        if (
            this.add_property.value.addBemail != "" &&
            this.add_property.value.addBemail != null &&
            this.add_property.value.addBfname != "" &&
            this.add_property.value.addBfname != null &&
            this.add_property.value.addBlname &&
            this.add_property.value.addBlname != null &&
            this.add_property.value.addBemail.toLowerCase() !=
                this.add_property.value.email.toLowerCase()
        ) {
            this.additional_borrower.push({
                Email: this.add_property.value.addBemail,
                FirstName: this.add_property.value.addBfname,
                LastName: this.add_property.value.addBlname,
            });
            this.resetBorrower();
        }
    }
    getBorPDF(propertyid, gcId) {
        var data = {
            PropertyId: parseInt(propertyid),
            GeneralContractorId: gcId,
        };

        this._bservice.GetBORPDF(data).subscribe(
            (response) => {
                this.pdfData = this.baseurl + response;
                window.open(this.pdfData, "_blank");
                if (response) {
                }
            },
            (error) => {
                this.sessionService.notifier.notify(
                    "error",
                    error.error.message
                );
                this.loading = false;
            }
        );
    }
    resetBorrower() {
        this.add_property.controls["addBemail"].setValue("");
        this.add_property.controls["addBfname"].setValue("");
        this.add_property.controls["addBlname"].setValue("");
    }

    removeBorrower(item) {
        this.additional_borrower = this.additional_borrower.filter(
            (filteritem) => {
                return filteritem.Email != item.Email;
            }
        );
    }

    showAddForm() {
        this.additional_borrower = [];
        this.additional_borrower = [];
        this.isAdd = true;
        this.isList = false;
        this.isEdit = false;

        this.formReset();
        if (
            this.userDetail.UserType == "Loan Processor" ||
            this.userDetail.UserType == "Loan Officer"
        ) {
            this._bservice
                .GetLenderDetailsList(this.userDetail.LenderId)
                .subscribe((LenderResponse) => {
                    if (LenderResponse != null) {
                        let lenderList = [];
                        lenderList.push(LenderResponse);
                        this.lender_list = lenderList;

                        this.add_property
                            .get("lender")
                            .setValue(this.userDetail.LenderId);
                        this.islenderDisabled = true;

                        for (
                            let i = 0;
                            i < this.loan_processor_list.length;
                            i++
                        ) {
                            if (
                                this.userDetail.LenderId ==
                                this.loan_processor_list[i].LenderId
                            ) {
                                this.filter_loan_processor_list.push(
                                    this.loan_processor_list[i]
                                );
                            }
                        }

                        if (this.userDetail.UserType == "Loan Processor") {
                            var selectedValue: any = [];
                            selectedValue.push(String(this.userDetail.Id));

                            this.add_property.controls[
                                "loanProcessor"
                            ].setValue(selectedValue);

                            for (
                                let i = 0;
                                i < this.loan_officer_list.length;
                                i++
                            ) {
                                if (
                                    this.userDetail.LenderId ==
                                    this.loan_officer_list[i].LenderId
                                ) {
                                    this.filter_loan_officer_list.push(
                                        this.loan_officer_list[i]
                                    );
                                }
                            }
                        }

                        if (this.userDetail.UserType == "Loan Officer") {
                            this._bservice
                                .GetUserDetailsList(this.userDetail.Id)
                                .subscribe(
                                    (LOResponse) => {
                                        if (LOResponse != null) {
                                            let loanOfficerList = [];
                                            loanOfficerList.push(LOResponse);
                                            this.filter_loan_officer_list =
                                                loanOfficerList;
                                            this.add_property.controls[
                                                "loanOfficer"
                                            ].setValue(
                                                String(this.userDetail.Id)
                                            );
                                            this.isloanOfficerDisabled = true;
                                        }
                                    },
                                    (error) => {}
                                );
                        }

                        this.loading = false;
                    }
                });
        }
    }

    // ValidatePartnerPass () {
    //   let password = this.add_property.controls.password.value;
    //   let repeatPassword = this.add_property.controls.confirmPassword.value;
    //   if (repeatPassword !== password)
    //   {
    //     this.add_property.controls["confirmPassword"].setErrors({ 'incorrect': true });
    //   }
    //   else
    //   {
    //     this.add_property.controls["confirmPassword"].setErrors(null);
    //   }
    // }

    //Save Data
    SaveRecord() {
        this.submitted = true;
        if (this.add_property.invalid) {
            return;
        } else {
            const _this = this;
            _this.submitted = false;
            var formData = _this.add_property.value;
            _this.saveBorrower(function (brrowerItem) {
                _this.saveLoanOfficer(function (loItem) {
                    _this.saveLoanProcessor(function (lpItem) {
                        _this.saveRealtor(function (rItem) {
                            _this.saveGeneralContractor(function (rgctem) {
                                _this.SaveProperty(
                                    brrowerItem,
                                    rgctem,
                                    rItem,
                                    lpItem,
                                    loItem
                                );
                            });
                        });
                    });
                });
            });
        }
    }

    saveLoanOfficer(calback) {
        var formData = this.add_property.value;
        var sendPassword = this.generatePassword();
        if (
            (formData.LOid == null || formData.LOid == "") &&
            formData.loanOfficer == "other"
        ) {
            var lpdata = {
                EmailId: formData.LOemail,
                Password: sendPassword,
                UserType: this.sessionService.userType[4].Value,
                FirstName: formData.LOfname,
                LastName: formData.LOlname,
                Status: "Active",
                Phone: formData.LOphone_no,
                Address1: "Address1",
                Addresss2: "Address2",
                City: "City",
                State: "State",
                Zip: "Zip",
                Country: "Country",
                CreatedBy: this.userDetail.EmailId,
                LenderId: formData.lender,
            };

            this._bservice.AddBorrower(lpdata).subscribe(
                (lpresponse) => {
                    calback(lpresponse.Id);
                },
                (error) => {
                    this._bservice.GetUserByEmail(formData.LOemail).subscribe(
                        (response) => {
                            if (response != null) {
                                if (response.UserType == "Loan Officer") {
                                    calback(response.Id);
                                } else {
                                    this.sessionService.notifier.notify(
                                        "error",
                                        "EmailId already used to other user type. Please enter other email id for Loan Officer."
                                    );
                                    this.loading = false;
                                }
                            } else {
                                calback(null);
                            }
                        },
                        (error) => {
                            this.sessionService.notifier.notify(
                                "error",
                                error.error.message
                            );
                            this.loading = false;
                        }
                    );
                }
            );
        } else if (
            formData.LOid != "" &&
            formData.LOid != null &&
            formData.LOid != undefined &&
            formData.loanOfficer == "other"
        ) {
            calback(formData.LOid);
        } else {
            calback(formData.loanOfficer);
        }
    }

    saveLoanProcessor(calback) {
        var formData = this.add_property.value;
        var sendPassword = this.generatePassword();
        if (formData.loanProcessor) {
            if (
                (formData.LPid == null || formData.LPid == "") &&
                formData.loanProcessor[0] == "other"
            ) {
                var lpdata = {
                    EmailId: formData.LPemail,
                    Password: sendPassword,
                    UserType: this.sessionService.userType[5].Value,
                    FirstName: formData.LPfname,
                    LastName: formData.LPlname,
                    Status: "Active",
                    Phone: formData.LPphone_no,
                    Address1: "Address1",
                    Addresss2: "Address2",
                    City: "City",
                    State: "State",
                    Zip: "Zip",
                    Country: "Country",
                    CreatedBy: this.userDetail.EmailId,
                    LenderId: formData.lender,
                };

                this._bservice.AddBorrower(lpdata).subscribe(
                    (lpresponse) => {
                        calback([lpresponse.Id]);
                    },
                    (error) => {
                        this._bservice
                            .GetUserByEmail(formData.LPemail)
                            .subscribe(
                                (response) => {
                                    if (response != null) {
                                        if (
                                            response.UserType ==
                                            "Loan Processor"
                                        ) {
                                            calback([response.Id]);
                                        } else {
                                            this.sessionService.notifier.notify(
                                                "error",
                                                "EmailId already used to other user type. Please enter other email id for Loan Processor."
                                            );
                                            this.loading = false;
                                        }
                                    } else {
                                        calback(null);
                                    }
                                },
                                (error) => {
                                    this.sessionService.notifier.notify(
                                        "error",
                                        error.error.message
                                    );
                                    this.loading = false;
                                }
                            );
                    }
                );
            } else if (
                formData.LPid != "" &&
                formData.LPid != null &&
                formData.LOid != undefined &&
                formData.loanProcessor[0] == "other"
            ) {
                calback([formData.LPid]);
            } else {
                calback(formData.loanProcessor);
            }
        } else {
            calback([""]);
        }
    }

    saveRealtor(calback) {
        var formData = this.add_property.value;
        var sendPassword = this.generatePassword();

        if (formData.RTid == null || formData.RTid == "") {
            if (formData.RTemail != null && formData.RTemail != "") {
                var rtdata = {
                    EmailId: formData.RTemail,
                    Password: sendPassword,
                    Status: "Active",
                    UserType: this.sessionService.userType[6].Value,
                    FirstName: formData.RTfname,
                    LastName: formData.RTlname,
                    Phone: formData.RTphone_no,
                    RealtorCompany: "RealtorCompany",
                    Address1: "Address1",
                    Addresss2: "Addresss2",
                    City: "City",
                    State: "State",
                    Zip: "302001",
                    Country: "Country",
                };
                this._bservice.AddBorrower(rtdata).subscribe(
                    (rtresponse) => {
                        calback(rtresponse.Id);
                    },
                    (error) => {
                        this._bservice
                            .GetUserByEmail(formData.RTemail)
                            .subscribe(
                                (response) => {
                                    if (response != null) {
                                        if (response.UserType == "Realtor") {
                                            calback(response.Id);
                                        } else {
                                            this.sessionService.notifier.notify(
                                                "error",
                                                "EmailId already used to other user type. Please enter other email id for Realtor."
                                            );
                                            this.loading = false;
                                        }
                                    } else {
                                        calback(null);
                                    }
                                },
                                (error) => {
                                    this.sessionService.notifier.notify(
                                        "error",
                                        error.error.message
                                    );
                                    this.loading = false;
                                }
                            );
                    }
                );
            } else {
                calback(null);
            }
        } else {
            calback(formData.RTid);
        }
    }

    saveGeneralContractor(calback) {
        var formData = this.add_property.value;
        var sendPassword = this.generatePassword();
        if (formData.gcId == null || formData.gcId == "") {
            if (formData.gcemail != null && formData.gcemail != "") {
                var data = {
                    EmailId: formData.gcemail,
                    Password: sendPassword,
                    UserType: this.sessionService.userType[3].Value,
                    FirstName: formData.gcfname,
                    LastName: formData.gclname,
                    Status: "Active",
                    Phone: formData.gcphone_no,
                    IDNumber: "123",
                    Signature: "",
                    ContractorLicense: "",
                    LeadSafeCertified: "",
                    ServiceAreas: "",
                    GCNetwork: "",
                    CreatedBy: this.userDetail.EmailId,
                    Address1: "",
                    Addresss2: "",
                    City: "",
                    State: "",
                    Zip: "",
                    Country: "",
                };
                this._bservice.AddBorrower(data).subscribe(
                    (gcresponse) => {
                        calback(gcresponse.Id);
                    },
                    (error) => {
                        this._bservice
                            .GetUserByEmail(formData.gcemail)
                            .subscribe(
                                (response) => {
                                    if (response != null) {
                                        if (
                                            response.UserType ==
                                            "General Contractors"
                                        ) {
                                            calback(response.Id);
                                        } else {
                                            this.sessionService.notifier.notify(
                                                "error",
                                                "EmailId already used to other user type. Please enter other email id for General Contractors."
                                            );
                                            this.loading = false;
                                        }
                                    } else {
                                        calback(null);
                                    }
                                },
                                (error) => {
                                    this.sessionService.notifier.notify(
                                        "error",
                                        error.error.message
                                    );
                                    this.loading = false;
                                }
                            );
                    }
                );
            } else {
                calback(formData.gcId);
            }
        } else {
            calback(formData.gcId);
        }
    }

    saveBorrower(calback) {
        var formData = this.add_property.value;
        var sendPassword = this.generatePassword();
        if (formData.borrowerId == null || formData.borrowerId == "") {
            if (formData.email != null && formData.email != "") {
                var data = {
                    EmailId: formData.email,
                    Password: sendPassword,
                    UserType: this.sessionService.userType[0].Value,
                    FirstName: formData.fname,
                    LastName: formData.lname,
                    Status: "Active",
                    Phone: formData.phone_no,
                    Address1: "",
                    Addresss2: "",
                    City: "",
                    State: "",
                    Zip: "",
                    Country: "",
                };

                this._bservice.AddBorrower(data).subscribe(
                    (response) => {
                        calback(response.Id);
                    },
                    (error) => {
                        this._bservice.GetUserByEmail(formData.email).subscribe(
                            (response) => {
                                if (response != null) {
                                    if (response.UserType == "Borrower") {
                                        calback(response.Id);
                                    } else {
                                        this.sessionService.notifier.notify(
                                            "error",
                                            "EmailId already used to other user type. Please enter other email id for Borrower."
                                        );
                                        this.loading = false;
                                    }
                                } else {
                                    calback(null);
                                }
                            },
                            (error) => {
                                this.sessionService.notifier.notify(
                                    "error",
                                    error.error.message
                                );
                                this.loading = false;
                            }
                        );
                    }
                );
            } else {
                calback(formData.borrowerId);
            }
        } else {
            calback(formData.borrowerId);
        }
    }

    SaveProperty(
        borrowerId,
        GeneralContractorId,
        RealtorId,
        loanProcessor,
        loanOfficerId
    ) {
        if (borrowerId == null || borrowerId == "") {
            return;
        } else {
            this.submitted = false;
            var formData = this.add_property.value;
            var invitedGC = [];
            if (
                GeneralContractorId != null &&
                GeneralContractorId != "" &&
                GeneralContractorId != undefined
            ) {
                invitedGC.push(GeneralContractorId);
            } else {
                invitedGC = null;
            }

            if (formData.id == null && !this.isEdit) {
                this.loading = true;
                var currentDate = new Date();
                var InspectionAddress = {
                    city: formData.city,
                    state: formData.state,
                    zipcode: formData.zipcode,
                    address1: formData.address1,
                    address2: formData.address2,
                };
                var data = {
                    Title: "Title-11",
                    ConsultantId: formData.consultant,
                    LoanOfficerId: loanOfficerId,
                    TransactionType: formData.transactionType,
                    BorrowerId: borrowerId,
                    InspectionAddress: InspectionAddress,
                    GeneralContractorId: GeneralContractorId,
                    FHACaseNo: formData.fHACaseNo,
                    BiddingDueDate: formData.biddingDueDate,
                    MetaTags: "MetaTags",
                    AcceptedProposal: formData.agreementSignedByBo,
                    InvitedGeneralContractors: invitedGC,
                    LoanType: formData.loanType,
                    OtherLoanType: "OtherLoanType",
                    RealtorId: RealtorId,
                    GCInviteOption: "GCInviteOption",
                    BiddingProcessStart: "BiddingProcessStart",
                    RenovationCompleted: "RenovationCompleted",
                    RenovationCompletedDate: "RenovationCompletedDate",
                    BidProcessStartDate: formData.bidProcessStartDate,
                    InspectionDate: formData.inspectionDate,
                    Status: "Property File created",
                    LenderId: formData.lender,
                    LoanProcessorId: loanProcessor,
                    Latitude: formData.latitude,
                    Longitude: formData.longitude,
                    CreatedDate: currentDate,
                    CreatedBy: this.userDetail.EmailId,
                    UpdateDate: currentDate,
                    UpdatedBy: this.userDetail.EmailId,
                    Borrowers: this.additional_borrower,
                };
                this._bservice.AddProperty(data).subscribe(
                    (response) => {
                        this.sessionService.notifier.notify(
                            "success",
                            "Property Successfully Added."
                        );

                        this.isList = true;
                        this.byAddPropery = true;
                        this.loanNumber = formData.LoanNo;
                        this.isAdd = false;
                        this.GetPropertyList();
                        this.GetBorrowerUserList();
                        this.GetContractorUserList();
                        this.GetRealtorList();
                        this.GetloanProcessorUserList();

                        this.loading = false;
                        const container =
                            document.querySelector("#container-3");
                        container.scrollTop = 0;
                    },
                    (error) => {
                        this.sessionService.notifier.notify(
                            "error",
                            error.error.message
                        );
                        this.loading = false;
                    }
                );
            }
        }
    }

    //Save Data
    UpdateRecord() {
        this.submitted = true;
        if (this.add_property.invalid) {
            return;
        } else {
            const _this = this;
            _this.submitted = false;
            var formData = _this.add_property.value;
            _this.saveBorrower(function (brrowerItem) {
                _this.saveLoanOfficer(function (loItem) {
                    _this.saveLoanProcessor(function (lpItem) {
                        _this.saveRealtor(function (rItem) {
                            _this.saveGeneralContractor(function (rgctem) {
                                _this.UpdateProperty(
                                    brrowerItem,
                                    rgctem,
                                    rItem,
                                    lpItem,
                                    loItem
                                );
                            });
                        });
                    });
                });
            });
        }
    }

    UpdateProperty(
        borrowerId,
        GeneralContractorId,
        RealtorId,
        loanProcessor,
        loanOfficerId
    ) {
        if (borrowerId == null || borrowerId == "") {
            return;
        } else {
            this.submitted = false;
            var formData = this.add_property.value;
            if (formData.id != null) {
                this.loading = true;
                var currentDate = new Date();
                var InspectionAddress = {
                    city: formData.city,
                    state: formData.state,
                    zipcode: formData.zipcode,
                    address1: formData.address1,
                    address2: formData.address2,
                };

                var data = {
                    Id: formData.id,
                    Title: "Title-11",
                    ConsultantId: formData.consultant,
                    LoanOfficerId: loanOfficerId,
                    TransactionType: formData.transactionType,
                    BorrowerId: borrowerId,
                    InspectionAddress: InspectionAddress,
                    GeneralContractorId: GeneralContractorId,
                    FHACaseNo: formData.fHACaseNo,
                    BiddingDueDate: formData.biddingDueDate,
                    MetaTags: "MetaTags",
                    AcceptedProposal: formData.agreementSignedByBo,
                    InvitedGeneralContractors: "InvitedGeneralContractors",
                    LoanType: formData.loanType,
                    OtherLoanType: "OtherLoanType",
                    RealtorId: RealtorId,
                    GCInviteOption: "GCInviteOption",
                    BiddingProcessStart: "BiddingProcessStart",
                    RenovationCompleted: "RenovationCompleted",
                    RenovationCompletedDate: "RenovationCompletedDate",
                    BidProcessStartDate: formData.bidProcessStartDate,
                    InspectionDate: formData.inspectionDate,
                    Status: "Property File created",
                    LenderId: formData.lender,
                    LoanProcessorId: loanProcessor,
                    Latitude: formData.latitude,
                    Longitude: formData.longitude,
                    CreatedDate: currentDate,
                    CreatedBy: this.userDetail.EmailId,
                    UpdateDate: currentDate,
                    UpdatedBy: this.userDetail.EmailId,
                    Borrowers: this.additional_borrower,
                };
                this._bservice.UpdateProperty(data).subscribe(
                    (response) => {
                        this.sessionService.notifier.notify(
                            "success",
                            "Property added successfully."
                        );
                        this.isList = true;
                        this.isAdd = false;
                        this.isEdit = false;
                        var elmnt = document.getElementById("container-2");
                        document.querySelector("#container-2").scrollTo(0, 0);
                        elmnt.scrollIntoView();
                        window.scrollTo(0, 0);

                        this.GetPropertyList();
                        this.GetBorrowerUserList();
                        this.GetContractorUserList();
                        this.GetRealtorList();
                        this.GetloanProcessorUserList();
                        this.loading = false;
                    },
                    (error) => {
                        this.sessionService.notifier.notify(
                            "error",
                            error.error.message
                        );
                        this.loading = false;
                    }
                );
            }
        }
    }

    onBlurLoanProcessor() {
        let loanPrrocessor = this.loan_processor_list.filter((item) => {
            return (
                this.add_property.value.LPemail != null &&
                item.EmailId.toLowerCase() ==
                    this.add_property.value.LPemail.toLowerCase()
            );
        });
        if (loanPrrocessor.length > 0) {
            this.add_property.controls["LPemail"].setValue(
                loanPrrocessor[0].EmailId
            );
            this.add_property.controls["LPfname"].setValue(
                loanPrrocessor[0].FirstName
            );
            this.add_property.controls["LPlname"].setValue(
                loanPrrocessor[0].LastName
            );
            this.add_property.controls["LPphone_no"].setValue(
                loanPrrocessor[0].Phone
            );
            this.add_property.controls["LPid"].setValue(loanPrrocessor[0].Id);

            this.add_property.controls["LPpassword"].setValue(
                loanPrrocessor[0].Password
            );
            return;
        } else {
            this.add_property.controls["LPpassword"].setValue("");
            this.add_property.controls["LPfname"].setValue("");
            this.add_property.controls["LPlname"].setValue("");
            this.add_property.controls["LPphone_no"].setValue("");
            this.add_property.controls["LPid"].setValue(null);
        }
    }

    onBlurLoanOfficer() {
        let loanOfficer = this.loan_officer_list.filter((item) => {
            return (
                this.add_property.value.LOemail != null &&
                item.EmailId.toLowerCase() ==
                    this.add_property.value.LOemail.toLowerCase()
            );
        });

        if (loanOfficer.length > 0) {
            this.add_property.controls["LOemail"].setValue(
                loanOfficer[0].EmailId
            );
            this.add_property.controls["LOfname"].setValue(
                loanOfficer[0].FirstName
            );
            this.add_property.controls["LOlname"].setValue(
                loanOfficer[0].LastName
            );
            this.add_property.controls["LOphone_no"].setValue(
                loanOfficer[0].Phone
            );
            this.add_property.controls["LOid"].setValue(loanOfficer[0].Id);

            this.add_property.controls["LOpassword"].setValue(
                loanOfficer[0].Password
            );
            return;
        } else {
            this.add_property.controls["LOpassword"].setValue("");
            this.add_property.controls["LOfname"].setValue("");
            this.add_property.controls["LOlname"].setValue("");
            this.add_property.controls["LOphone_no"].setValue("");
            this.add_property.controls["LOid"].setValue(null);
        }
    }

    onLoanProcessorSelection(selectionValue) {
        if (selectionValue.isUserInput == true) {
            var selectedValue: any = [];
            var selectedObject: any = [];
            selectedObject = this.add_property.get("loanProcessor");
            selectedValue = selectedObject.value ? selectedObject.value : [];

            if (
                selectionValue.source.value == "other" &&
                selectionValue.source._selected == true
            ) {
                this.add_property.controls["loanProcessor"].setValue(["other"]);
            } else if (
                (selectionValue.source.value == "" ||
                    selectionValue.source.value == null) &&
                selectionValue.source._selected == true
            ) {
                this.add_property.controls["loanProcessor"].setValue([""]);
            } else {
                if (selectionValue.source._selected == true) {
                    let otherIndex: string = selectedValue.indexOf("other");
                    if (otherIndex != "-1") {
                        selectedValue.splice(otherIndex, 1);
                    }
                    let selectIndex: string = selectedValue.indexOf("");
                    if (selectIndex != "-1") {
                        selectedValue.splice(selectIndex, 1);
                    }
                    let currentIndex: string = selectedValue.indexOf(
                        selectionValue.source.value
                    );
                    if (currentIndex == "-1") {
                        selectedValue.push(selectionValue.source.value);
                    }

                    if (selectedValue) {
                        this.add_property.controls["loanProcessor"].setValue(
                            selectedValue
                        );
                    }
                } else {
                    let currentIndex: string = selectedValue.indexOf(
                        selectionValue.source.value
                    );

                    if (currentIndex != "-1") {
                        selectedValue.splice(currentIndex, 1);
                    }

                    if (selectedValue) {
                        this.add_property.controls["loanProcessor"].setValue(
                            selectedValue
                        );
                    }
                }
            }
        }
        return true;
    }
    onBlurBorrower() {
        let borrowers = this.borrower_list.filter((item) => {
            return (
                this.add_property.value.email != null &&
                item.EmailId.toLowerCase() ==
                    this.add_property.value.email.toLowerCase()
            );
        });

        if (borrowers.length > 0) {
            this.add_property.controls["email"].setValue(borrowers[0].EmailId);
            this.add_property.controls["fname"].setValue(
                borrowers[0].FirstName
            );
            this.add_property.controls["lname"].setValue(borrowers[0].LastName);
            this.add_property.controls["phone_no"].setValue(borrowers[0].Phone);
            this.add_property.controls["borrowerId"].setValue(borrowers[0].Id);
            return;
        } else {
            this.add_property.controls["fname"].setValue("");
            this.add_property.controls["lname"].setValue("");
            this.add_property.controls["phone_no"].setValue("");
            this.add_property.controls["borrowerId"].setValue(null);
        }
    }

    onBlurRealtor() {
        let realtors = this.realtor_list.filter((item) => {
            return (
                this.add_property.value.RTemail != null &&
                item.EmailId.toLowerCase() ==
                    this.add_property.value.RTemail.toLowerCase()
            );
        });

        if (realtors.length > 0) {
            this.add_property.controls["RTemail"].setValue(realtors[0].EmailId);
            this.add_property.controls["RTfname"].setValue(
                realtors[0].FirstName
            );
            this.add_property.controls["RTlname"].setValue(
                realtors[0].LastName
            );
            this.add_property.controls["RTphone_no"].setValue(
                realtors[0].Phone
            );
            this.add_property.controls["RTid"].setValue(realtors[0].Id);
            return;
        } else {
            this.add_property.controls["RTfname"].setValue("");
            this.add_property.controls["RTlname"].setValue("");
            this.add_property.controls["RTphone_no"].setValue("");
            this.add_property.controls["RTid"].setValue(null);
        }
    }

    onBlurGenralContractor() {
        let contractor = this.contractor_list.filter((item) => {
            return (
                this.add_property.value.gcemail != null &&
                item.EmailId.toLowerCase() ==
                    this.add_property.value.gcemail.toLowerCase()
            );
        });

        if (contractor.length > 0) {
            this.add_property.controls["gcemail"].setValue(
                contractor[0].EmailId
            );
            this.add_property.controls["gcfname"].setValue(
                contractor[0].FirstName
            );
            this.add_property.controls["gclname"].setValue(
                contractor[0].LastName
            );
            this.add_property.controls["gcphone_no"].setValue(
                contractor[0].Phone
            );
            this.add_property.controls["gcId"].setValue(contractor[0].Id);
            return;
        } else {
            this.add_property.controls["gcfname"].setValue("");
            this.add_property.controls["gclname"].setValue("");
            this.add_property.controls["gcphone_no"].setValue("");
            this.add_property.controls["gcId"].setValue(null);
        }
    }

    formReset() {
        this.add_property.reset();
        this.add_property.controls["statusType"].setValue("Active");
    }
    printFinalSOR(Propertyid) {
        this.loading = true;
        this._bservice.PrintFinalSOR(Propertyid).subscribe(
            (response) => {
                if (response) {
                    let path = response;
                    for (let i = 0; i < path.length; i++) {
                        window.open(this.baseurl + path[i], "_blank");
                    }
                    this.loading = false;
                }
            },
            (error) => {
                this.sessionService.notifier.notify(
                    "error",
                    error.error.message
                );
                this.loading = false;
            }
        );
    }
    GetPropertyList() {
        this.loading = true;
        var data = {
            EmailId: "",
            PageSize: 100000,
            LastEvaluatedKey: 0,
            UserType: this.sessionService.userType[1].Value,
        };

        this._bservice.GetPropertyList(data).subscribe(
            (response) => {
                if (response != null) {
                    this.propertyList.Items = [];

                    var propertyResult = response;

                    propertyResult.Items.sort(function (a, b) {
                        return a.Id < b.Id ? 1 : -1;
                    });

                    if (this.userDetail.UserType == "Borrower") {
                        for (let i = 0; i < propertyResult.Items.length; i++) {
                            if (
                                this.userDetail.Id ==
                                propertyResult.Items[i].BorrowerId
                            ) {
                                if (
                                    this.CompletedChecked == true &&
                                    this.InactiveChecked == true
                                ) {
                                    this.propertyList.Items.push(
                                        propertyResult.Items[i]
                                    );
                                    this.propertyList.Count =
                                        this.propertyList.Items.length;
                                    this.propertyList.ScannedCount =
                                        this.propertyList.Items.length;
                                    this.propertyList.TotalRecord =
                                        this.propertyList.Items.length;
                                } else if (
                                    this.CompletedChecked == false &&
                                    this.InactiveChecked == true
                                ) {
                                    if (
                                        propertyResult.Items[i].IsCompleted !=
                                        true
                                    ) {
                                        this.propertyList.Items.push(
                                            propertyResult.Items[i]
                                        );
                                        this.propertyList.Count =
                                            this.propertyList.Items.length;
                                        this.propertyList.ScannedCount =
                                            this.propertyList.Items.length;
                                        this.propertyList.TotalRecord =
                                            this.propertyList.Items.length;
                                    }
                                } else if (
                                    this.CompletedChecked == true &&
                                    this.InactiveChecked == false
                                ) {
                                    if (
                                        propertyResult.Items[i]
                                            .PropertyStatus != "Inactive"
                                    ) {
                                        this.propertyList.Items.push(
                                            propertyResult.Items[i]
                                        );
                                        this.propertyList.Count =
                                            this.propertyList.Items.length;
                                        this.propertyList.ScannedCount =
                                            this.propertyList.Items.length;
                                        this.propertyList.TotalRecord =
                                            this.propertyList.Items.length;
                                    }
                                } else if (
                                    this.CompletedChecked == false &&
                                    this.InactiveChecked == false
                                ) {
                                    if (
                                        propertyResult.Items[i]
                                            .PropertyStatus != "Inactive" &&
                                        propertyResult.Items[i].IsCompleted !=
                                            true
                                    ) {
                                        this.propertyList.Items.push(
                                            propertyResult.Items[i]
                                        );
                                        this.propertyList.Count =
                                            this.propertyList.Items.length;
                                        this.propertyList.ScannedCount =
                                            this.propertyList.Items.length;
                                        this.propertyList.TotalRecord =
                                            this.propertyList.Items.length;
                                    }
                                } else {
                                    this.propertyList.Items.push(
                                        propertyResult.Items[i]
                                    );
                                    this.propertyList.Count =
                                        this.propertyList.Items.length;
                                    this.propertyList.ScannedCount =
                                        this.propertyList.Items.length;
                                    this.propertyList.TotalRecord =
                                        this.propertyList.Items.length;
                                }
                            }
                        }

                        if (
                            this.propertyList.Items.length == 1 &&
                            this.userDetail.Id ==
                                this.propertyList.Items[0].BorrowerId
                        ) {
                            if (
                                this.CompletedChecked == true &&
                                this.InactiveChecked == true
                            ) {
                                this.sendPropertyDetails(
                                    this.propertyList.Items[0].Id
                                );
                            } else if (
                                this.CompletedChecked == false &&
                                this.InactiveChecked == true
                            ) {
                                if (
                                    this.propertyList.Items[0].IsCompleted !=
                                    true
                                ) {
                                    this.sendPropertyDetails(
                                        this.propertyList.Items[0].Id
                                    );
                                }
                            } else if (
                                this.CompletedChecked == true &&
                                this.InactiveChecked == false
                            ) {
                                if (
                                    this.propertyList.Items[0].PropertyStatus !=
                                    "Inactive"
                                ) {
                                    this.sendPropertyDetails(
                                        this.propertyList.Items[0].Id
                                    );
                                }
                            } else if (
                                this.CompletedChecked == false &&
                                this.InactiveChecked == false
                            ) {
                                if (
                                    this.propertyList.Items[0].PropertyStatus !=
                                        "Inactive" &&
                                    this.propertyList.Items[0].IsCompleted !=
                                        true
                                ) {
                                    this.sendPropertyDetails(
                                        this.propertyList.Items[0].Id
                                    );
                                }
                            } else {
                            }
                        }
                    } else if (this.userDetail.UserType == "Consultants") {
                        for (let i = 0; i < propertyResult.Items.length; i++) {
                            if (
                                this.userDetail.Id ==
                                propertyResult.Items[i].ConsultantId
                            ) {
                                if (
                                    this.CompletedChecked == true &&
                                    this.InactiveChecked == true
                                ) {
                                    this.propertyList.Items.push(
                                        propertyResult.Items[i]
                                    );
                                    this.propertyList.Count =
                                        this.propertyList.Items.length;
                                    this.propertyList.ScannedCount =
                                        this.propertyList.Items.length;
                                    this.propertyList.TotalRecord =
                                        this.propertyList.Items.length;
                                } else if (
                                    this.CompletedChecked == false &&
                                    this.InactiveChecked == true
                                ) {
                                    if (
                                        propertyResult.Items[i].IsCompleted !=
                                        true
                                    ) {
                                        this.propertyList.Items.push(
                                            propertyResult.Items[i]
                                        );
                                        this.propertyList.Count =
                                            this.propertyList.Items.length;
                                        this.propertyList.ScannedCount =
                                            this.propertyList.Items.length;
                                        this.propertyList.TotalRecord =
                                            this.propertyList.Items.length;
                                    }
                                } else if (
                                    this.CompletedChecked == true &&
                                    this.InactiveChecked == false
                                ) {
                                    if (
                                        propertyResult.Items[i]
                                            .PropertyStatus != "Inactive"
                                    ) {
                                        this.propertyList.Items.push(
                                            propertyResult.Items[i]
                                        );
                                        this.propertyList.Count =
                                            this.propertyList.Items.length;
                                        this.propertyList.ScannedCount =
                                            this.propertyList.Items.length;
                                        this.propertyList.TotalRecord =
                                            this.propertyList.Items.length;
                                    }
                                } else if (
                                    this.CompletedChecked == false &&
                                    this.InactiveChecked == false
                                ) {
                                    if (
                                        propertyResult.Items[i]
                                            .PropertyStatus != "Inactive" &&
                                        propertyResult.Items[i].IsCompleted !=
                                            true
                                    ) {
                                        this.propertyList.Items.push(
                                            propertyResult.Items[i]
                                        );
                                        this.propertyList.Count =
                                            this.propertyList.Items.length;
                                        this.propertyList.ScannedCount =
                                            this.propertyList.Items.length;
                                        this.propertyList.TotalRecord =
                                            this.propertyList.Items.length;
                                    }
                                } else {
                                    this.propertyList.Items.push(
                                        propertyResult.Items[i]
                                    );
                                    this.propertyList.Count =
                                        this.propertyList.Items.length;
                                    this.propertyList.ScannedCount =
                                        this.propertyList.Items.length;
                                    this.propertyList.TotalRecord =
                                        this.propertyList.Items.length;
                                }
                            }
                        }
                    } else if (this.userDetail.UserType == "Loan Officer") {
                        for (let i = 0; i < propertyResult.Items.length; i++) {
                            if (
                                this.userDetail.Id ==
                                propertyResult.Items[i].LoanOfficerId
                            ) {
                                if (
                                    this.CompletedChecked == true &&
                                    this.InactiveChecked == true
                                ) {
                                    this.propertyList.Items.push(
                                        propertyResult.Items[i]
                                    );
                                    this.propertyList.Count =
                                        this.propertyList.Items.length;
                                    this.propertyList.ScannedCount =
                                        this.propertyList.Items.length;
                                    this.propertyList.TotalRecord =
                                        this.propertyList.Items.length;
                                } else if (
                                    this.CompletedChecked == false &&
                                    this.InactiveChecked == true
                                ) {
                                    if (
                                        propertyResult.Items[i].IsCompleted !=
                                        true
                                    ) {
                                        this.propertyList.Items.push(
                                            propertyResult.Items[i]
                                        );
                                        this.propertyList.Count =
                                            this.propertyList.Items.length;
                                        this.propertyList.ScannedCount =
                                            this.propertyList.Items.length;
                                        this.propertyList.TotalRecord =
                                            this.propertyList.Items.length;
                                    }
                                } else if (
                                    this.CompletedChecked == true &&
                                    this.InactiveChecked == false
                                ) {
                                    if (
                                        propertyResult.Items[i]
                                            .PropertyStatus != "Inactive"
                                    ) {
                                        this.propertyList.Items.push(
                                            propertyResult.Items[i]
                                        );
                                        this.propertyList.Count =
                                            this.propertyList.Items.length;
                                        this.propertyList.ScannedCount =
                                            this.propertyList.Items.length;
                                        this.propertyList.TotalRecord =
                                            this.propertyList.Items.length;
                                    }
                                } else if (
                                    this.CompletedChecked == false &&
                                    this.InactiveChecked == false
                                ) {
                                    if (
                                        propertyResult.Items[i]
                                            .PropertyStatus != "Inactive" &&
                                        propertyResult.Items[i].IsCompleted !=
                                            true
                                    ) {
                                        this.propertyList.Items.push(
                                            propertyResult.Items[i]
                                        );
                                        this.propertyList.Count =
                                            this.propertyList.Items.length;
                                        this.propertyList.ScannedCount =
                                            this.propertyList.Items.length;
                                        this.propertyList.TotalRecord =
                                            this.propertyList.Items.length;
                                    }
                                } else {
                                    this.propertyList.Items.push(
                                        propertyResult.Items[i]
                                    );
                                    this.propertyList.Count =
                                        this.propertyList.Items.length;
                                    this.propertyList.ScannedCount =
                                        this.propertyList.Items.length;
                                    this.propertyList.TotalRecord =
                                        this.propertyList.Items.length;
                                }
                            }
                        }
                    } else if (
                        this.userDetail.UserType == "Draw Administrator"
                    ) {
                        for (let i = 0; i < propertyResult.Items.length; i++) {
                            if (
                                this.userDetail.Id ==
                                propertyResult.Items[i].DrawAdministratorId
                            ) {
                                if (
                                    this.CompletedChecked == true &&
                                    this.InactiveChecked == true
                                ) {
                                    this.propertyList.Items.push(
                                        propertyResult.Items[i]
                                    );
                                    this.propertyList.Count =
                                        this.propertyList.Items.length;
                                    this.propertyList.ScannedCount =
                                        this.propertyList.Items.length;
                                    this.propertyList.TotalRecord =
                                        this.propertyList.Items.length;
                                } else if (
                                    this.CompletedChecked == false &&
                                    this.InactiveChecked == true
                                ) {
                                    if (
                                        propertyResult.Items[i].IsCompleted !=
                                        true
                                    ) {
                                        this.propertyList.Items.push(
                                            propertyResult.Items[i]
                                        );
                                        this.propertyList.Count =
                                            this.propertyList.Items.length;
                                        this.propertyList.ScannedCount =
                                            this.propertyList.Items.length;
                                        this.propertyList.TotalRecord =
                                            this.propertyList.Items.length;
                                    }
                                } else if (
                                    this.CompletedChecked == true &&
                                    this.InactiveChecked == false
                                ) {
                                    if (
                                        propertyResult.Items[i]
                                            .PropertyStatus != "Inactive"
                                    ) {
                                        this.propertyList.Items.push(
                                            propertyResult.Items[i]
                                        );
                                        this.propertyList.Count =
                                            this.propertyList.Items.length;
                                        this.propertyList.ScannedCount =
                                            this.propertyList.Items.length;
                                        this.propertyList.TotalRecord =
                                            this.propertyList.Items.length;
                                    }
                                } else if (
                                    this.CompletedChecked == false &&
                                    this.InactiveChecked == false
                                ) {
                                    if (
                                        propertyResult.Items[i]
                                            .PropertyStatus != "Inactive" &&
                                        propertyResult.Items[i].IsCompleted !=
                                            true
                                    ) {
                                        this.propertyList.Items.push(
                                            propertyResult.Items[i]
                                        );
                                        this.propertyList.Count =
                                            this.propertyList.Items.length;
                                        this.propertyList.ScannedCount =
                                            this.propertyList.Items.length;
                                        this.propertyList.TotalRecord =
                                            this.propertyList.Items.length;
                                    }
                                } else {
                                    this.propertyList.Items.push(
                                        propertyResult.Items[i]
                                    );
                                    this.propertyList.Count =
                                        this.propertyList.Items.length;
                                    this.propertyList.ScannedCount =
                                        this.propertyList.Items.length;
                                    this.propertyList.TotalRecord =
                                        this.propertyList.Items.length;
                                }
                            }
                        }
                    } else if (this.userDetail.UserType == "Loan Processor") {
                        for (let i = 0; i < propertyResult.Items.length; i++) {
                            if (
                                propertyResult.Items[i].LoanProcessorId != "" &&
                                propertyResult.Items[i].LoanProcessorId !=
                                    null &&
                                propertyResult.Items[i].LoanProcessorId !=
                                    undefined
                            ) {
                                var list =
                                    propertyResult.Items[i].LoanProcessorId;

                                if (Array.isArray(list)) {
                                    let uid = this.userDetail.Id.toString();

                                    if (
                                        list.indexOf(this.userDetail.Id) !==
                                            -1 ||
                                        list.indexOf(uid) !== -1
                                    ) {
                                        if (
                                            this.CompletedChecked == true &&
                                            this.InactiveChecked == true
                                        ) {
                                            this.propertyList.Items.push(
                                                propertyResult.Items[i]
                                            );
                                            this.propertyList.Count =
                                                this.propertyList.Items.length;
                                            this.propertyList.ScannedCount =
                                                this.propertyList.Items.length;
                                            this.propertyList.TotalRecord =
                                                this.propertyList.Items.length;
                                        } else if (
                                            this.CompletedChecked == false &&
                                            this.InactiveChecked == true
                                        ) {
                                            if (
                                                propertyResult.Items[i]
                                                    .IsCompleted != true
                                            ) {
                                                this.propertyList.Items.push(
                                                    propertyResult.Items[i]
                                                );
                                                this.propertyList.Count =
                                                    this.propertyList.Items.length;
                                                this.propertyList.ScannedCount =
                                                    this.propertyList.Items.length;
                                                this.propertyList.TotalRecord =
                                                    this.propertyList.Items.length;
                                            }
                                        } else if (
                                            this.CompletedChecked == true &&
                                            this.InactiveChecked == false
                                        ) {
                                            if (
                                                propertyResult.Items[i]
                                                    .PropertyStatus !=
                                                "Inactive"
                                            ) {
                                                this.propertyList.Items.push(
                                                    propertyResult.Items[i]
                                                );
                                                this.propertyList.Count =
                                                    this.propertyList.Items.length;
                                                this.propertyList.ScannedCount =
                                                    this.propertyList.Items.length;
                                                this.propertyList.TotalRecord =
                                                    this.propertyList.Items.length;
                                            }
                                        } else if (
                                            this.CompletedChecked == false &&
                                            this.InactiveChecked == false
                                        ) {
                                            if (
                                                propertyResult.Items[i]
                                                    .PropertyStatus !=
                                                    "Inactive" &&
                                                propertyResult.Items[i]
                                                    .IsCompleted != true
                                            ) {
                                                this.propertyList.Items.push(
                                                    propertyResult.Items[i]
                                                );
                                                this.propertyList.Count =
                                                    this.propertyList.Items.length;
                                                this.propertyList.ScannedCount =
                                                    this.propertyList.Items.length;
                                                this.propertyList.TotalRecord =
                                                    this.propertyList.Items.length;
                                            }
                                        } else {
                                            this.propertyList.Items.push(
                                                propertyResult.Items[i]
                                            );
                                            this.propertyList.Count =
                                                this.propertyList.Items.length;
                                            this.propertyList.ScannedCount =
                                                this.propertyList.Items.length;
                                            this.propertyList.TotalRecord =
                                                this.propertyList.Items.length;
                                        }
                                    }
                                } else {
                                    if (this.userDetail.Id == list) {
                                        if (
                                            this.CompletedChecked == true &&
                                            this.InactiveChecked == true
                                        ) {
                                            this.propertyList.Items.push(
                                                propertyResult.Items[i]
                                            );
                                            this.propertyList.Count =
                                                this.propertyList.Items.length;
                                            this.propertyList.ScannedCount =
                                                this.propertyList.Items.length;
                                            this.propertyList.TotalRecord =
                                                this.propertyList.Items.length;
                                        } else if (
                                            this.CompletedChecked == false &&
                                            this.InactiveChecked == true
                                        ) {
                                            if (
                                                propertyResult.Items[i]
                                                    .IsCompleted != true
                                            ) {
                                                this.propertyList.Items.push(
                                                    propertyResult.Items[i]
                                                );
                                                this.propertyList.Count =
                                                    this.propertyList.Items.length;
                                                this.propertyList.ScannedCount =
                                                    this.propertyList.Items.length;
                                                this.propertyList.TotalRecord =
                                                    this.propertyList.Items.length;
                                            }
                                        } else if (
                                            this.CompletedChecked == true &&
                                            this.InactiveChecked == false
                                        ) {
                                            if (
                                                propertyResult.Items[i]
                                                    .PropertyStatus !=
                                                "Inactive"
                                            ) {
                                                this.propertyList.Items.push(
                                                    propertyResult.Items[i]
                                                );
                                                this.propertyList.Count =
                                                    this.propertyList.Items.length;
                                                this.propertyList.ScannedCount =
                                                    this.propertyList.Items.length;
                                                this.propertyList.TotalRecord =
                                                    this.propertyList.Items.length;
                                            }
                                        } else if (
                                            this.CompletedChecked == false &&
                                            this.InactiveChecked == false
                                        ) {
                                            if (
                                                propertyResult.Items[i]
                                                    .PropertyStatus !=
                                                    "Inactive" &&
                                                propertyResult.Items[i]
                                                    .IsCompleted != true
                                            ) {
                                                this.propertyList.Items.push(
                                                    propertyResult.Items[i]
                                                );
                                                this.propertyList.Count =
                                                    this.propertyList.Items.length;
                                                this.propertyList.ScannedCount =
                                                    this.propertyList.Items.length;
                                                this.propertyList.TotalRecord =
                                                    this.propertyList.Items.length;
                                            }
                                        } else {
                                            this.propertyList.Items.push(
                                                propertyResult.Items[i]
                                            );
                                            this.propertyList.Count =
                                                this.propertyList.Items.length;
                                            this.propertyList.ScannedCount =
                                                this.propertyList.Items.length;
                                            this.propertyList.TotalRecord =
                                                this.propertyList.Items.length;
                                        }
                                    }
                                }
                            }
                        }
                    } else {
                        this.propertyList = propertyResult;
                    }

                    if (this.userDetail.UserType == "General Contractors") {
                        this.tempGCPropertyList = propertyResult.Items.filter(
                            (item) => {
                                if (item.InvitedGeneralContractors != null) {
                                    if (
                                        this.CompletedChecked == true &&
                                        this.InactiveChecked == true
                                    ) {
                                        return (
                                            item.InvitedGeneralContractors.indexOf(
                                                this.userDetail.Id
                                            ) > -1
                                        );
                                    } else if (
                                        this.CompletedChecked == false &&
                                        this.InactiveChecked == true
                                    ) {
                                        if (item.IsCompleted != true) {
                                            return (
                                                item.InvitedGeneralContractors.indexOf(
                                                    this.userDetail.Id
                                                ) > -1
                                            );
                                        }
                                    } else if (
                                        this.CompletedChecked == true &&
                                        this.InactiveChecked == false
                                    ) {
                                        if (item.PropertyStatus != "Inactive") {
                                            return (
                                                item.InvitedGeneralContractors.indexOf(
                                                    this.userDetail.Id
                                                ) > -1
                                            );
                                        }
                                    } else if (
                                        this.CompletedChecked == false &&
                                        this.InactiveChecked == false
                                    ) {
                                        if (
                                            item.PropertyStatus != "Inactive" &&
                                            item.IsCompleted != true
                                        ) {
                                            return (
                                                item.InvitedGeneralContractors.indexOf(
                                                    this.userDetail.Id
                                                ) > -1
                                            );
                                        }
                                    } else {
                                        return (
                                            item.InvitedGeneralContractors.indexOf(
                                                this.userDetail.Id
                                            ) > -1
                                        );
                                    }
                                }
                            }
                        );

                        for (
                            let i = 0;
                            i < this.tempGCPropertyList.length;
                            i++
                        ) {
                            this.tempGCPropertyList[i].isReviseFlag = false;
                            this.tempGCPropertyList[i].isAlreadyBid = false;
                            this.tempGCPropertyList[i].isAccepted = false;
                            this.tempGCPropertyList[i].boComment = "";
                            this.tempGCPropertyList[i].sorDetails = {};
                            this.tempGCPropertyList[i].sorDetails.IsFinal =
                                null;
                            this.tempGCPropertyList[
                                i
                            ].sorDetails.FinalMailSendDate = null;
                            this.tempGCPropertyList[
                                i
                            ].sorDetails.FinalMailSend = null;

                            var data = {
                                EmailId: "",
                                PropertyId: this.tempGCPropertyList[i].Id,
                                PageSize: 100000,
                                LastEvaluatedKey: 0,
                                UserType: this.userDetail.UserType,
                            };
                            this._boservice.GetSorDetails(data).subscribe(
                                (sorResponse) => {
                                    if (sorResponse.Items.length > 0) {
                                        if (
                                            sorResponse.Items[0].IsFinal == null
                                        ) {
                                            sorResponse.Items[0].IsFinal =
                                                false;
                                        }
                                        if (
                                            sorResponse.Items[0]
                                                .FinalMailSend == null
                                        ) {
                                            sorResponse.Items[0].FinalMailSend =
                                                false;
                                        }

                                        if (
                                            sorResponse.Items[0]
                                                .FinalMailSendDate == null
                                        ) {
                                            sorResponse.Items[0].FinalMailSendDate =
                                                "";
                                        }
                                        this.tempGCPropertyList[i].sorDetails =
                                            sorResponse.Items[0];
                                    }

                                    var GCSORdata = {
                                        PropertyId:
                                            this.tempGCPropertyList[i].Id,
                                        GeneralContractorId: this.userDetail.Id,
                                    };

                                    this._boservice
                                        .GetGCSorDetails(GCSORdata)
                                        .subscribe((GCresponse) => {
                                            if (
                                                GCresponse != null &&
                                                GCresponse.Items.length > 0
                                            ) {
                                                if (
                                                    GCresponse.Items[0]
                                                        .ReviseFlag == true
                                                ) {
                                                    this.tempGCPropertyList[
                                                        i
                                                    ].isReviseFlag = true;
                                                    this.tempGCPropertyList[
                                                        i
                                                    ].boComment =
                                                        GCresponse.Items[0].ReviseComment;
                                                }
                                                if (
                                                    GCresponse.Items[0]
                                                        .Status == "Accepted"
                                                ) {
                                                    this.tempGCPropertyList[
                                                        i
                                                    ].isAccepted = true;
                                                }

                                                this.tempGCPropertyList[
                                                    i
                                                ].isAlreadyBid = true;

                                                this.loading = false;
                                            } else {
                                                this.loading = false;
                                            }
                                            if (
                                                this.tempGCPropertyList[i]
                                                    .InitialDraws != null
                                            ) {
                                                if (
                                                    this.tempGCPropertyList[i]
                                                        .InitialDraws[0]
                                                        .DrawId != null &&
                                                    this.tempGCPropertyList[i]
                                                        .InitialDraws[0]
                                                        .DrawId != undefined &&
                                                    this.tempGCPropertyList[i]
                                                        .InitialDraws[0]
                                                        .DrawId != ""
                                                ) {
                                                    this.tempGCPropertyList[
                                                        i
                                                    ].InitialDraws.sort(
                                                        function (a, b) {
                                                            return (
                                                                a.DrawId -
                                                                b.DrawId
                                                            );
                                                        }
                                                    );
                                                    for (
                                                        let j = 0;
                                                        j <
                                                        this.tempGCPropertyList[
                                                            i
                                                        ].InitialDraws.length;
                                                        j++
                                                    ) {
                                                        this.tempGCPropertyList[
                                                            i
                                                        ].InitialDraws[
                                                            j
                                                        ].ConsultantSendSignDate =
                                                            "";
                                                        this.tempGCPropertyList[
                                                            i
                                                        ].InitialDraws[
                                                            j
                                                        ].GCRequestDate = "";
                                                        let drawId =
                                                            this
                                                                .tempGCPropertyList[
                                                                i
                                                            ].InitialDraws[j]
                                                                .DrawId;
                                                        this._boservice
                                                            .GetDrawRequestById(
                                                                drawId
                                                            )
                                                            .subscribe(
                                                                (
                                                                    DrawRequestresponse
                                                                ) => {
                                                                    if (
                                                                        DrawRequestresponse !=
                                                                        null
                                                                    ) {
                                                                        if (
                                                                            this
                                                                                .tempGCPropertyList[
                                                                                i
                                                                            ]
                                                                                .InitialDraws[
                                                                                j
                                                                            ]
                                                                                .Status ==
                                                                                "approved" &&
                                                                            DrawRequestresponse.ConsultantRequestDate !=
                                                                                null &&
                                                                            DrawRequestresponse.ConsultantRequestDate !=
                                                                                undefined &&
                                                                            DrawRequestresponse.ConsultantRequestDate !=
                                                                                ""
                                                                        ) {
                                                                            this.PreviousDrawShow =
                                                                                true;
                                                                        }
                                                                        if (
                                                                            this
                                                                                .tempGCPropertyList[
                                                                                i
                                                                            ]
                                                                                .InitialDraws[
                                                                                j
                                                                            ]
                                                                                .Status ==
                                                                                "pending" ||
                                                                            this
                                                                                .tempGCPropertyList[
                                                                                i
                                                                            ]
                                                                                .InitialDraws[
                                                                                j
                                                                            ]
                                                                                .Status ==
                                                                                "revised" ||
                                                                            DrawRequestresponse.ConsultantRequestDate ==
                                                                                null ||
                                                                            DrawRequestresponse.ConsultantRequestDate ==
                                                                                undefined ||
                                                                            DrawRequestresponse.ConsultantRequestDate ==
                                                                                ""
                                                                        ) {
                                                                            this.CurrentDrawShow =
                                                                                true;
                                                                        }

                                                                        this.tempGCPropertyList[
                                                                            i
                                                                        ].InitialDraws[
                                                                            j
                                                                        ].ConsultantRequestDate =
                                                                            DrawRequestresponse.ConsultantRequestDate;
                                                                        this.tempGCPropertyList[
                                                                            i
                                                                        ].InitialDraws[
                                                                            j
                                                                        ].ConsultantSendSignDate =
                                                                            DrawRequestresponse.ConsultantSendSignDate;
                                                                        this.tempGCPropertyList[
                                                                            i
                                                                        ].InitialDraws[
                                                                            j
                                                                        ].GCRequestDate =
                                                                            DrawRequestresponse.CreatedDate;
                                                                    } else {
                                                                        this.tempGCPropertyList[
                                                                            i
                                                                        ].InitialDraws[
                                                                            j
                                                                        ].ConsultantRequestDate =
                                                                            "";
                                                                        this.tempGCPropertyList[
                                                                            i
                                                                        ].InitialDraws[
                                                                            j
                                                                        ].ConsultantSendSignDate =
                                                                            "";
                                                                        this.tempGCPropertyList[
                                                                            i
                                                                        ].InitialDraws[
                                                                            j
                                                                        ].GCRequestDate =
                                                                            "";
                                                                    }
                                                                },
                                                                (error) => {
                                                                    this.sessionService.notifier.notify(
                                                                        "error",
                                                                        error
                                                                            .error
                                                                            .message
                                                                    );
                                                                    this.loading =
                                                                        false;
                                                                }
                                                            );
                                                    }
                                                }
                                            }
                                        });
                                    this.GCPropertyList.push(
                                        this.tempGCPropertyList[i]
                                    );
                                },
                                (error) => {}
                            );
                        }
                    }

                    for (let i = 0; i < this.propertyList.Count; i++) {
                        let propertyLists = this.propertyList.Items[i];

                        if (typeof propertyLists === "object") {
                            if ("InspectionAddress" in propertyLists) {
                                let address1 = propertyLists.InspectionAddress
                                    .address1
                                    ? propertyLists.InspectionAddress.address1 +
                                      ", "
                                    : "";
                                let address2 = propertyLists.InspectionAddress
                                    .address2
                                    ? propertyLists.InspectionAddress.address2 +
                                      ", "
                                    : "";
                                let city = propertyLists.InspectionAddress.city
                                    ? propertyLists.InspectionAddress.city +
                                      ", "
                                    : "";
                                let state = propertyLists.InspectionAddress
                                    .state
                                    ? propertyLists.InspectionAddress.state +
                                      ", "
                                    : "";
                                let zipcode = propertyLists.InspectionAddress
                                    .zipcode
                                    ? propertyLists.InspectionAddress.zipcode +
                                      ", "
                                    : "";
                                let propertyAddress =
                                    address1 +
                                    address2 +
                                    city +
                                    state +
                                    zipcode;
                                this.propertyList.Items[i].tempPropertyAddress =
                                    propertyAddress;
                            }
                        }
                        var consultantName = this.propertyList.Items[i]
                            .ConsultantName
                            ? this.propertyList.Items[i].ConsultantName
                            : "";
                        if (consultantName == "") {
                            for (
                                let k = 0;
                                k < this.consultent_list.length;
                                k++
                            ) {
                                if (
                                    this.consultent_list[k].Id ==
                                    this.propertyList.Items[i].ConsultantId
                                ) {
                                    this.propertyList.Items[i].ConsultantName =
                                        this.consultent_list[k].FirstName +
                                        " " +
                                        this.consultent_list[k].LastName;
                                }
                            }
                        }
                    }

                    if (this.byAddPropery == true) {
                        var propertyData =
                            this.propertyRepairConstructionDetail;

                        propertyData.LoanNo = this.loanNumber;
                        propertyData.PropertyId = this.propertyList.Items[0].Id;
                        propertyData.AllowableFees = [];
                        this._boservice.AddSOR(propertyData).subscribe(
                            (sresponse) => {
                                this.loanNumber = null;
                                this.byAddPropery = false;
                            },
                            (error) => {
                                this.sessionService.notifier.notify(
                                    "error",
                                    error.error.message
                                );
                            }
                        );
                    }
                    const users = Array.from(
                        { length: this.propertyList.Count },
                        (_, k) => createNewUser(k, this.propertyList)
                    );
                    this.dataSource = new MatTableDataSource(users);
                    this.dataSource.paginator = this.paginator;

                    this.loading = false;
                }
            },
            (error) => {
                this.loading = false;
            }
        );
    }

    GetloanOfficerUserList() {
        this.loading = true;
        var data = {
            EmailId: "",
            PageSize: 100000,
            LastEvaluatedKey: 0,
            UserType: this.sessionService.userType[4].Value,
        };
        this._bservice.GetLoanOfficerUserList(data).subscribe(
            (response) => {
                if (response != null) {
                    for (let i = 0; i < response.Items.length; i++) {
                        if (response.Items[i].UserType == "Loan Officer") {
                            this.loan_officer_list = response.Items;
                        }
                    }
                }
            },
            (error) => {}
        );
    }

    GetloanProcessorUserList() {
        this.loading = true;
        var data = {
            EmailId: "",
            PageSize: 100000,
            LastEvaluatedKey: 0,
            UserType: this.sessionService.userType[5].Value,
        };
        this._bservice.GetLoanProcessorUserList(data).subscribe(
            (response) => {
                if (response != null) {
                    for (let i = 0; i < response.Items.length; i++) {
                        if (response.Items[i].UserType == "Loan Processor") {
                            this.loan_processor_list = response.Items;
                        }
                    }
                }
            },
            (error) => {}
        );
    }

    GetConsultentUserList() {
        this.loading = true;
        var data = {
            EmailId: "",
            PageSize: 100000,
            LastEvaluatedKey: 0,
            UserType: this.sessionService.userType[2].Value,
        };
        this._bservice.GetConsultentUserList(data).subscribe(
            (response) => {
                if (response != null) {
                    for (let i = 0; i < response.Items.length; i++) {
                        if (response.Items[i].UserType == "Consultants") {
                            this.consultent_list.push(response.Items[i]);
                        }
                    }
                    this.consultent_list.sort(function (a, b) {
                        if (
                            a.FirstName.trim().toLowerCase() <
                            b.FirstName.trim().toLowerCase()
                        ) {
                            return -1;
                        }
                        if (
                            a.FirstName.trim().toLowerCase() >
                            b.FirstName.trim().toLowerCase()
                        ) {
                            return 1;
                        }
                        return 0;
                    });
                }
            },
            (error) => {}
        );
    }

    GetContractorUserList() {
        this.loading = true;
        var data = {
            EmailId: "",
            PageSize: 100000,
            LastEvaluatedKey: 0,
            UserType: this.sessionService.userType[3].Value,
        };
        this._bservice.GetContractorUserList(data).subscribe(
            (response) => {
                if (response != null) {
                    for (let i = 0; i < response.Items.length; i++) {
                        if (
                            response.Items[i].UserType == "General Contractors"
                        ) {
                            this.contractor_list = response.Items;
                        }
                    }
                }
            },
            (error) => {}
        );
    }

    GetBorrowerUserList() {
        this.loading = true;
        var data = {
            EmailId: "",
            PageSize: 100000,
            LastEvaluatedKey: 0,
            UserType: this.sessionService.userType[0].Value,
        };
        this._bservice.GetBorrowerUserList(data).subscribe(
            (response) => {
                if (response != null) {
                    for (let i = 0; i < response.Items.length; i++) {
                        if (response.Items[i].UserType == "Borrower") {
                            this.borrower_list = response.Items;
                        }
                    }
                }
            },
            (error) => {}
        );
    }

    GetRealtorList() {
        this.loading = true;
        var data = {
            EmailId: "",
            PageSize: 100000,
            LastEvaluatedKey: 0,
            UserType: this.sessionService.userType[6].Value,
        };
        this._bservice.GetBorrowerUserList(data).subscribe(
            (response) => {
                if (response != null) {
                    for (let i = 0; i < response.Items.length; i++) {
                        if (response.Items[i].UserType == "Realtor") {
                            this.realtor_list = response.Items;
                        }
                    }
                }
            },
            (error) => {}
        );
    }

    GetLenderList() {
        this.loading = true;
        var data = {
            EmailId: "",
            PageSize: 100000,
            LastEvaluatedKey: 0,
            UserType: this.sessionService.userType[4].Value,
        };
        this._bservice.GetLenderList(data).subscribe(
            (response) => {
                if (response != null) {
                    response.Items.sort(function (a, b) {
                        if (
                            a.Name.trim().toLowerCase() <
                            b.Name.trim().toLowerCase()
                        ) {
                            return -1;
                        }
                        if (
                            a.Name.trim().toLowerCase() >
                            b.Name.trim().toLowerCase()
                        ) {
                            return 1;
                        }
                        return 0;
                    });
                    this.lender_list = response.Items;
                }
            },
            (error) => {}
        );
    }

    filterLOLP() {
        var lenderId = this.add_property.value.lender;
        if (lenderId != null && lenderId != "" && lenderId != undefined) {
            this.filter_loan_processor_list = [];

            for (let i = 0; i < this.loan_processor_list.length; i++) {
                if (lenderId == this.loan_processor_list[i].LenderId) {
                    this.filter_loan_processor_list.push(
                        this.loan_processor_list[i]
                    );
                }
            }

            this.filter_loan_officer_list = [];
            for (let i = 0; i < this.loan_officer_list.length; i++) {
                if (lenderId == this.loan_officer_list[i].LenderId) {
                    this.filter_loan_officer_list.push(
                        this.loan_officer_list[i]
                    );
                }
            }

            if (this.userDetail.UserType == "Loan Processor") {
                var selectedValue: any = [];
                selectedValue.push(String(this.userDetail.Id));

                this.add_property.controls["loanProcessor"].setValue(
                    selectedValue
                );
            }

            if (this.userDetail.UserType == "Loan Officer") {
                this._bservice.GetUserDetailsList(this.userDetail.Id).subscribe(
                    (LOResponse) => {
                        if (LOResponse != null) {
                            let loanOfficerList = [];
                            loanOfficerList.push(LOResponse);
                            this.filter_loan_officer_list = loanOfficerList;
                            this.add_property.controls["loanOfficer"].setValue(
                                String(this.userDetail.Id)
                            );
                        }
                    },
                    (error) => {}
                );
            }
        } else {
            this.filter_loan_officer_list = [];
            this.filter_loan_processor_list = [];
        }
    }

    EditUser(item) {
        this.isEdit = true;
        this.isList = false;
        let borrowerData: any = {};
        for (let i = 0; i < this.borrower_list.length; i++) {
            if (item.BorrowerId == this.borrower_list[i].Id) {
                borrowerData = this.borrower_list[i];
            }
        }

        let GCData: any = {
            EmailId: "",
            FirstName: "",
            LastName: "",
            Phone: "",
            Id: "",
        };
        for (let i = 0; i < this.contractor_list.length; i++) {
            if (item.GeneralContractorId == this.contractor_list[i].Id) {
                GCData = this.contractor_list[i];
            }
        }

        let RTData: any = {
            EmailId: "",
            FirstName: "",
            LastName: "",
            Phone: "",
            Id: "",
        };
        for (let i = 0; i < this.realtor_list.length; i++) {
            if (item.RealtorId == this.realtor_list[i].Id) {
                RTData = this.realtor_list[i];
            }
        }

        this.add_property.setValue({
            email: borrowerData.EmailId,
            fname: borrowerData.FirstName,
            lname: borrowerData.LastName,
            phone_no: borrowerData.Phone,
            borrowerId: borrowerData.Id,
            gcemail: GCData.EmailId,
            gcfname: GCData.FirstName,
            gclname: GCData.LastName,
            gcphone_no: GCData.Phone,
            gcId: GCData.Id,
            RTemail: RTData.EmailId,
            RTfname: RTData.FirstName,
            RTlname: RTData.LastName,
            RTphone_no: RTData.Phone,
            RTid: RTData.Id,
            LPemail: null,
            LPfname: null,
            LPlname: null,
            LPphone_no: null,
            LPpassword: null,
            LPid: null,
            statusType: item.Status,
            consultant: item.ConsultantId,
            loanOfficer: item.LoanOfficerId,
            transactionType: item.TransactionType,
            home_borrower: borrowerData.Id,
            city: item.InspectionAddress.city,
            state: item.InspectionAddress.state,
            zipcode: item.InspectionAddress.zipcode,
            address1: item.InspectionAddress.address1,
            address2: item.InspectionAddress.address2,
            agreementSignedByBo: item.AcceptedProposal,
            fHACaseNo: item.FHACaseNo,
            loanType: item.LoanType,
            realtor: item.RealtorId,
            renovationCompleted: item.RenovationCompleted,
            renovationCompletedDate: item.RenovationCompletedDate,
            bidProcessStartDate: item.BidProcessStartDate,
            inspectionDate: item.InspectionDate,
            biddingDueDate: item.BiddingDueDate,
            propertyStatus: item.Status,
            lender: item.LenderId,
            loanProcessor: item.LoanProcessorId,
            latitude: item.Latitude,
            longitude: item.Longitude,
            id: item.Id,
            LOemail: null,
            LOfname: null,
            LOlname: null,
            LOphone_no: null,
            LOpassword: null,
            LOid: null,
            addBemail: "",
            addBfname: "",
            addBlname: "",
            LoanNo: "",
        });
        this.additional_borrower = item.Borrowers == null ? [] : item.Borrowers;
        if (this.additional_borrower.length > 0) {
            this.displayAddBorrower = true;
        }
        this.filterLOLP();
    }

    printDrawRequest(DrawId) {
        var data = { DrawId: DrawId };
        this._bservice.DrawRequestPDF(data).subscribe(
            (response) => {
                var pdfData = this.baseurl + response.PdfPath;
                window.open(pdfData, "_blank");
                if (response) {
                }
            },
            (error) => {
                this.sessionService.notifier.notify(
                    "error",
                    error.error.message
                );
                this.loading = false;
            }
        );
    }

    notAllowDraw() {
        this.sessionService.notifier.notify(
            "error",
            "There is a draw that has already been submitted to the consultant and has been accepted by both parties. If there needs to be a revision please contact your consultant directly."
        );
    }

    sendDrawRequestDocSign(DrawId) {
        this.loading = true;
        var data = { DrawId: DrawId, SendDocSign: true };
        this._bservice.DrawRequestPDF(data).subscribe(
            (response) => {
                if (response.PdfPath == "success") {
                    this.sessionService.notifier.notify(
                        "success",
                        "Draw Request Successfully Send For Document Sign !!"
                    );
                    this.loading = false;
                }
            },
            (error) => {
                this.sessionService.notifier.notify(
                    "error",
                    error.error.message
                );
                this.loading = false;
            }
        );
    }
    getPropertyDetails(propertyId) {
        this.loading = true;
        this._bservice.GetPropertyDetails(propertyId).subscribe(
            (response) => {
                if (response != null) {
                    this.loading = false;
                    this._bservice
                        .GetUserDetailsList(response.BorrowerId)
                        .subscribe(
                            (borrowerResponse) => {
                                if (borrowerResponse != null) {
                                    this.borrower_list = borrowerResponse.Items;
                                    this._bservice
                                        .GetUserDetailsList(
                                            response.ConsultantId
                                        )
                                        .subscribe(
                                            (consultantResponse) => {
                                                if (
                                                    consultantResponse != null
                                                ) {
                                                    this.consultent_list =
                                                        consultantResponse.Items;
                                                    this._bservice
                                                        .GetUserDetailsList(
                                                            response.LoanOfficerId
                                                        )
                                                        .subscribe(
                                                            (LOResponse) => {
                                                                if (
                                                                    LOResponse !=
                                                                    null
                                                                ) {
                                                                    this.loan_officer_list =
                                                                        LOResponse;
                                                                }
                                                            },
                                                            (error) => {}
                                                        );
                                                }
                                            },
                                            (error) => {}
                                        );
                                }
                            },
                            (error) => {}
                        );

                    this._bservice
                        .GetUserDetailsList(response.RealtorId)
                        .subscribe(
                            (RealtorResponse) => {
                                if (RealtorResponse != null) {
                                    this.realtor_list = RealtorResponse.Items;
                                }
                            },
                            (error) => {}
                        );

                    this._bservice
                        .GetLenderDetailsList(response.LenderId)
                        .subscribe(
                            (LenderResponse) => {
                                if (LenderResponse != null) {
                                    this.lender_list = LenderResponse;
                                    this.loading = false;
                                }
                            },
                            (error) => {}
                        );
                }
            },
            (error) => {}
        );
    }

    sendPropertyDetails(id) {
        var path = "/property-details/" + id;
        this.sessionService.onlyThisProperty = true;
        this.router.navigate([path]);
    }

    checkStatus(status, propertyDetails) {
        if (status == "yes") {
            var r = confirm(
                "Are you sure you want to Participate in the Bid on Repair process for this property"
            );
        } else {
            var r = confirm(
                "Are you sure you want to Reject Participate in the Bid on Repair process for this property"
            );
        }

        if (r == true) {
            if (status == "yes") {
                if (
                    propertyDetails.GeneralContractorBids == undefined ||
                    propertyDetails.GeneralContractorBids == ""
                ) {
                    propertyDetails.GeneralContractorBids = [];
                    propertyDetails.GeneralContractorBids.push(
                        this.userDetail.Id
                    );
                } else {
                    propertyDetails.GeneralContractorBids.push(
                        this.userDetail.Id
                    );
                }
                propertyDetails.BidStatus = "yes";
                propertyDetails.BiddingProcessStart = "yes";
                propertyDetails.GCName =
                    this.userDetail.FirstName + " " + this.userDetail.LastName;
                this._bservice
                    .UpdatePropertyBidStatus(propertyDetails)
                    .subscribe(
                        (response) => {
                            this.sessionService.notifier.notify(
                                "success",
                                "You are participating with this property."
                            );
                            this.isList = true;
                            this.isAdd = false;
                            this.isEdit = false;
                            this.userDetail.isGCFirstLogin = false;

                            const container =
                                document.querySelector("#container-3");
                            container.scrollTop = 0;

                            this.GetPropertyList();
                            this.GetBorrowerUserList();
                            this.GetContractorUserList();
                            this.GetRealtorList();
                            this.GetloanProcessorUserList();
                            this.router.navigate([
                                "/property-input-bid/" + propertyDetails.Id,
                            ]);

                            this.loading = false;
                        },
                        (error) => {
                            this.sessionService.notifier.notify(
                                "error",
                                error.error.message
                            );
                            this.loading = false;
                        }
                    );
            } else {
                if (propertyDetails.InvitedGeneralContractors != null) {
                    var index =
                        propertyDetails.InvitedGeneralContractors.indexOf(
                            this.userDetail.Id
                        );
                    if (index > -1) {
                        propertyDetails.InvitedGeneralContractors.splice(
                            index,
                            1
                        );
                    }
                }

                propertyDetails.BidStatus = "no";
                propertyDetails.BiddingProcessStart = "yes";
                propertyDetails.GCName =
                    this.userDetail.FirstName + " " + this.userDetail.LastName;
                this._bservice
                    .UpdatePropertyBidStatus(propertyDetails)
                    .subscribe(
                        (response) => {
                            this.sessionService.notifier.notify(
                                "success",
                                "You are not interested to participate with this property."
                            );
                            this.isList = true;
                            this.isAdd = false;
                            this.isEdit = false;
                            this.userDetail.isGCFirstLogin = false;

                            const container =
                                document.querySelector("#container-3");
                            container.scrollTop = 0;

                            this.GetPropertyList();
                            this.GetBorrowerUserList();
                            this.GetContractorUserList();
                            this.GetRealtorList();
                            this.GetloanProcessorUserList();
                            this.loading = false;
                        },
                        (error) => {
                            this.sessionService.notifier.notify(
                                "error",
                                error.error.message
                            );
                            this.loading = false;
                        }
                    );
            }
        }
    }
    blankBidForm(status, propertyDetails) {
        if (status == "yes") {
            var r = confirm(
                "Are you sure you want to Participate in the Bid on Repair process for this property"
            );
        } else {
            var r = confirm(
                "Are you sure you want to Reject Participate in the Bid on Repair process for this property"
            );
        }

        if (r == true) {
            if (status == "yes") {
                this.router.navigate([
                    "/specification-repair-review/" + propertyDetails.Id,
                ]);
            }
        }
    }

    openDialog(id) {
        const dialogRef = this.dialog.open(DialogContentExampleDialog, {
            data: {
                propertyId: id,
                isRevise: "false",
                comment: "",
            },
        });
    }

    openEditDialog(id) {
        this.router.navigate(["/property-input-bid", id]);
    }

    openReviseDialog(id, boComment) {
        const dialogRef = this.dialog.open(DialogContentExampleDialog, {
            data: {
                propertyId: id,
                isRevise: "true",
                comment: boComment,
            },
        });
    }
    generateFinalSOR(Propertyid) {
        this.loading = true;
        this._bservice.SendFinalSORDocsign(Propertyid).subscribe(
            (response) => {
                this._bservice.GetPropertyDetails(Propertyid).subscribe(
                    (proResponse) => {
                        if (proResponse != null) {
                            let gcId = proResponse.AcceptedProposal;
                            let sendForSign = true;
                            var data = {
                                PropertyId: parseInt(Propertyid),
                                GeneralContractorId: gcId,
                                sendForSign: sendForSign,
                            };

                            this._bservice.GetBORPDF(data).subscribe(
                                (gresponse) => {
                                    if (gresponse) {
                                        this.sessionService.notifier.notify(
                                            "success",
                                            "Send final SOR for digital signature successfully."
                                        );
                                        this.loading = false;
                                    }
                                },
                                (error) => {
                                    this.sessionService.notifier.notify(
                                        "error",
                                        error.error.message
                                    );
                                    this.loading = false;
                                }
                            );
                        }
                    },
                    (error) => {
                        this.sessionService.notifier.notify(
                            "error",
                            error.error.message
                        );
                        this.loading = false;
                    }
                );
            },
            (error) => {
                this.sessionService.notifier.notify(
                    "error",
                    error.error.message
                );
                this.loading = false;
            }
        );
    }
    printPdf(url) {
        window.open(url, "_blank");
    }
    GetBlankBORPdf(propertyId, gcId) {
        var data = {
            PropertyId: propertyId,
            GeneralContractorId: gcId,
        };

        this._bservice.GetBlankBORPDF(data).subscribe(
            (response) => {
                window.open(this.baseurl + response.PdfPath, "_blank");
                this.loading = false;
            },
            (error) => {
                this.sessionService.notifier.notify(
                    "error",
                    error.error.message
                );
                this.loading = false;
            }
        );
    }
    generatePassword() {
        var password = Math.random().toString(36).slice(-8);
        return password;
    }
}

@Component({
    selector: "dialog-content-example-dialog",
    templateUrl: "popup.html",
})
export class DialogContentExampleDialog {
    propertyId: any;
    isRevise: any;
    comment: any;
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
        private router: Router
    ) {
        this.propertyId = this.data.propertyId;
        this.isRevise = this.data.isRevise;
        this.comment = this.data.comment;
    }
    redirectOnBid() {
        this.router.navigate(["/property-input-bid", this.data.propertyId]);
    }
}

function createNewUser(id: number, userList): UserData {
    const name =
        NAMES[Math.round(Math.random() * (NAMES.length - 1))] +
        " " +
        NAMES[Math.round(Math.random() * (NAMES.length - 1))].charAt(0) +
        ".";
    if (userList.Items[id] != undefined) {
        userList.Items[id].id = id + 1;
        return userList.Items[id];
    }
}
