import { Component, OnInit, ViewChild } from "@angular/core";
import {
    FormGroup,
    Validators,
    FormControl,
    ReactiveFormsModule,
    FormArray,
    FormBuilder,
} from "@angular/forms";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { DataSource } from "@angular/cdk/table";
import { BorrowerService } from "../borrower/borrower.service";
import { SessionService } from "../service/session.service";
import { ActivatedRoute, Router } from "@angular/router";
import { PropertyService } from "../property/property.service";

export interface UserData {
    id: string;
    name: string;
    email: string;
    lastLogin: Date;
}

/** Constants used to fill up our data base. */
const COLORS: string[] = [
    "maroon",
    "red",
    "orange",
    "yellow",
    "olive",
    "green",
    "purple",
    "fuchsia",
    "lime",
    "teal",
    "aqua",
    "blue",
    "navy",
    "black",
    "gray",
];
const NAMES: string[] = [
    "Maia",
    "Asher",
    "Olivia",
    "Atticus",
    "Amelia",
    "Jack",
    "Charlotte",
    "Theodore",
    "Isla",
    "Oliver",
    "Isabella",
    "Jasper",
    "Cora",
    "Levi",
    "Violet",
    "Arthur",
    "Mia",
    "Thomas",
    "Elizabeth",
];

/**
 * @title Data table with sorting, pagination, and filtering.
 */

@Component({
    selector: "app-initial-draw-request",
    templateUrl: "./initial-draw-request.component.html",
    styleUrls: ["./initial-draw-request.component.scss"],
})
export class InitialDrawRequestComponent implements OnInit {
    displayedColumns: string[] = [
        "id",
        "RepairType",
        "RepairTotal",
        "Operation",
    ];

    dataSource: MatTableDataSource<UserData>;
    mobnumPattern = "^(d{3})sd{3}-d{4}";
    baseurl = this.sessionService.endpoint;
    public DrawRequestForm: FormGroup;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    isList: boolean = true;
    isAdd: boolean = false;
    isEdit: boolean = false;
    submitted: boolean = false;
    favoriteSeason: string;
    userList: any = {};
    submittedBidList: any = {};
    userDetail: any = {};
    RepairPropertyId: any;
    RepairDrawId: any;
    loading = false;
    GCBidData: any;
    pdfData: any;
    formStatus = false;
    oldDrawStatus: any = "";
    borrowerComment: any = "";
    SubmitBidHeaderList: any = [];
    allowableFeesData: any = {};
    permitCostTypeData: any = {};
    checkConstructionsDetails = false;
    propertyRepairConstructionDetail: any;
    getRepairConstructionList: any = {};
    permitCostonReset: any = [];
    drawRequestBOAction: any = "";
    status: any = ["Blocked", "Active"];
    roles: any = [
        { Key: "1", Value: "authenticated user" },
        { Key: "2", Value: "Administrator" },
        { Key: "3", Value: "Consultants" },
        { Key: "4", Value: "General Contractors" },
        { Key: "5", Value: "Borrower" },
        { Key: "6", Value: "Loan Officer" },
        { key: "7", Value: "Loan Processor" },
        { key: "8", Value: " Notify user of new account" },
        { key: "9", Value: "Draw Administrator" },
    ];
    constructor(
        private _bservice: BorrowerService,
        private route: ActivatedRoute,
        private router: Router,
        private sessionService: SessionService,
        private propertyService: PropertyService,
        private _fb: FormBuilder
    ) {
        // Create 100 users
        // Assign the data to the data source for the table to render
    }

    ngOnInit() {
        var propertyId = this.route.snapshot.paramMap.get("propertyId");

        this.RepairPropertyId = propertyId;

        this.getDrawRequestData(this.RepairPropertyId);

        if (this.sessionService.get("userInfo") != undefined) {
            this.userDetail = JSON.parse(this.sessionService.get("userInfo"));
        }

        this.DrawRequestForm = this._fb.group({
            PropertyId: new FormControl(propertyId),
            GeneralContractorId: new FormControl(this.userDetail.Id),
            ConsultantComment: new FormControl(null),
            BorrowerComment: new FormControl(null),
            GCRequestDate: new FormControl(null),
            ConsultantRequestDate: new FormControl(null),
            DrawStatus: new FormControl("pending"),
            OldDrawStatus: new FormControl(null),
            CreatedBy: new FormControl(null),
            CreatedDate: new FormControl(null),
            UpdateDate: new FormControl(null),
            UpdatedBy: new FormControl(null),
            Id: new FormControl(null),
            TotalGCAmount: new FormControl(null),
            TotalGCPercent: new FormControl(null),
            Constructions: this._fb.array([]),
        });
    }

    applyFilter(filterValue) {
        const target = filterValue as HTMLInputElement;
        const targetValue = target.value;
        this.dataSource.filter = targetValue.trim().toLowerCase();

        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    }

    showAddForm() {
        this.isAdd = true;
        this.isList = false;
    }

    removeZeroVal(index) {
        let currentVal =
            this.DrawRequestForm.controls["Constructions"]["controls"][index]
                .controls.GCAmount.value;
        if (currentVal === 0) {
            this.DrawRequestForm.controls["Constructions"]["controls"][
                index
            ].controls["GCAmount"].setValue("");
        }
    }

    getHeaderList(GCResList) {
        var data = {
            EmailId: "",
            PageSize: 100000,
            LastEvaluatedKey: 0,
            UserType: this.sessionService.userType[3].Value,
        };
        this.SubmitBidHeaderList = [];
        this.SubmitBidHeaderList.push({ id: "", name: "SOR DRAFT" });
        this._bservice.GetUserList(data).subscribe(
            (response) => {
                if (response != null) {
                    response.Items;
                    for (let i = 0; i < GCResList.length; i++) {
                        let GC = response.Items.filter((item) => {
                            return GCResList[i].GeneralContractorId == item.Id;
                        });

                        if (GCResList[i].Status != "Pending") {
                            this.SubmitBidHeaderList.push({
                                id: GC[0].Id,
                                name: GC[0].FirstName + " " + GC[0].LastName,
                            });
                        }
                    }
                }
            },
            (error) => {}
        );
    }

    getBorPDF(gcId) {
        var data = {
            PropertyId: parseInt(this.RepairPropertyId),
            GeneralContractorId: gcId,
        };

        this.propertyService.GetBORPDF(data).subscribe(
            (response) => {
                this.pdfData = this.baseurl + response;
                window.open(this.pdfData, "_blank");
                if (response) {
                }
            },
            (error) => {
                this.sessionService.notifier.notify(
                    "error",
                    error.error.message
                );
                this.loading = false;
            }
        );
    }

    getDrawRequestData(RepairPropertyId) {
        this.loading = true;
        this.propertyService
            .GetPropertyDetails(RepairPropertyId)
            .subscribe((presponse) => {
                if (presponse != null && presponse.AcceptedProposal != null) {
                    var GCSORdata = {
                        PropertyId: RepairPropertyId,
                        GeneralContractorId: presponse.AcceptedProposal,
                    };
                    this._bservice
                        .GetGCSorDetails(GCSORdata)
                        .subscribe((GCresponse) => {
                            let BorItem = GCresponse.Items[0].Constructions;

                            var drawdata = {
                                PropertyId: RepairPropertyId,
                            };
                            this._bservice
                                .GetDrawRequestList(drawdata)
                                .subscribe((DrawRequestresponse) => {
                                    const initialDraw =
                                        DrawRequestresponse.Items;

                                    const constructionRepairItems = [
                                        {
                                            id: 1,
                                            RepairType: "Masonry",
                                            RepairTotal: "0.00",
                                            Operation: "Active",
                                        },
                                        {
                                            id: 2,
                                            RepairType: "Siding",
                                            RepairTotal: "0.00",
                                            Operation: "Active",
                                        },
                                        {
                                            id: 3,
                                            RepairType: "Gutters/Downspouts",
                                            RepairTotal: "0.00",
                                            Operation: "Active",
                                        },
                                        {
                                            id: 4,
                                            RepairType: "Roof",
                                            RepairTotal: "0.00",
                                            Operation: "Active",
                                        },
                                        {
                                            id: 5,
                                            RepairType: "Shutters",
                                            RepairTotal: "0.00",
                                            Operation: "Active",
                                        },
                                        {
                                            id: 6,
                                            RepairType: "Exteriors",
                                            RepairTotal: "0.00",
                                            Operation: "Active",
                                        },
                                        {
                                            id: 7,
                                            RepairType: "Walks",
                                            RepairTotal: "0.00",
                                            Operation: "Active",
                                        },
                                        {
                                            id: 8,
                                            RepairType: "Driveways",
                                            RepairTotal: "0.00",
                                            Operation: "Active",
                                        },
                                        {
                                            id: 9,
                                            RepairType: "Painting (Ext)",
                                            RepairTotal: "0.00",
                                            Operation: "Active",
                                        },
                                        {
                                            id: 10,
                                            RepairType: "Caulking",
                                            RepairTotal: "0.00",
                                            Operation: "Active",
                                        },
                                        {
                                            id: 11,
                                            RepairType: "Fencing",
                                            RepairTotal: "0.00",
                                            Operation: "Active",
                                        },
                                        {
                                            id: 12,
                                            RepairType: "Grading",
                                            RepairTotal: "0.00",
                                            Operation: "Active",
                                        },
                                        {
                                            id: 13,
                                            RepairType: "Windows",
                                            RepairTotal: "0.00",
                                            Operation: "Active",
                                        },
                                        {
                                            id: 14,
                                            RepairType: "Weatherstrip",
                                            RepairTotal: "0.00",
                                            Operation: "Active",
                                        },
                                        {
                                            id: 15,
                                            RepairType: "Doors (Ext)	",
                                            RepairTotal: "0.00",
                                            Operation: "Active",
                                        },
                                        {
                                            id: 16,
                                            RepairType: "Doors (Int)	",
                                            RepairTotal: "0.00",
                                            Operation: "Active",
                                        },
                                        {
                                            id: 17,
                                            RepairType: "Partition Wall",
                                            RepairTotal: "0.00",
                                            Operation: "Active",
                                        },
                                        {
                                            id: 18,
                                            RepairType: "Plaster/Drywall",
                                            RepairTotal: "0.00",
                                            Operation: "Active",
                                        },
                                        {
                                            id: 19,
                                            RepairType: "Decorating",
                                            RepairTotal: "0.00",
                                            Operation: "Active",
                                        },
                                        {
                                            id: 20,
                                            RepairType: "Wood Trim	",
                                            RepairTotal: "0.00",
                                            Operation: "Active",
                                        },
                                        {
                                            id: 21,
                                            RepairType: "Stairs",
                                            RepairTotal: "0.00",
                                            Operation: "Active",
                                        },
                                        {
                                            id: 22,
                                            RepairType: "Closets",
                                            RepairTotal: "0.00",
                                            Operation: "Active",
                                        },
                                        {
                                            id: 23,
                                            RepairType: "Wood Floors",
                                            RepairTotal: "0.00",
                                            Operation: "Active",
                                        },
                                        {
                                            id: 24,
                                            RepairType: "Finished Floors",
                                            RepairTotal: "0.00",
                                            Operation: "Active",
                                        },
                                        {
                                            id: 25,
                                            RepairType: "Ceramic Tile",
                                            RepairTotal: "0.00",
                                            Operation: "Active",
                                        },
                                        {
                                            id: 26,
                                            RepairType: "Bath Accesories",
                                            RepairTotal: "0.00",
                                            Operation: "Active",
                                        },
                                        {
                                            id: 27,
                                            RepairType: "Plumbing",
                                            RepairTotal: "0.00",
                                            Operation: "Active",
                                        },
                                        {
                                            id: 28,
                                            RepairType: "Electrical",
                                            RepairTotal: "0.00",
                                            Operation: "Active",
                                        },
                                        {
                                            id: 29,
                                            RepairType: "Heating",
                                            RepairTotal: "0.00",
                                            Operation: "Active",
                                        },
                                        {
                                            id: 30,
                                            RepairType: "Insulation",
                                            RepairTotal: "0.00",
                                            Operation: "Active",
                                        },
                                        {
                                            id: 31,
                                            RepairType: "Cabinetry",
                                            RepairTotal: "0.00",
                                            Operation: "Active",
                                        },
                                        {
                                            id: 32,
                                            RepairType: "Appliances",
                                            RepairTotal: "0.00",
                                            Operation: "Active",
                                        },
                                        {
                                            id: 33,
                                            RepairType: "Basements",
                                            RepairTotal: "0.00",
                                            Operation: "Active",
                                        },
                                        {
                                            id: 34,
                                            RepairType: "Cleanup",
                                            RepairTotal: "0.00",
                                            Operation: "Active",
                                        },
                                        {
                                            id: 35,
                                            RepairType: "Miscellaneous",
                                            RepairTotal: "0.00",
                                            Operation: "Active",
                                        },
                                        {
                                            id: 36,
                                            RepairType: "Grand Total",
                                            RepairTotal: "0.00",
                                            Operation: "Inactive",
                                        },
                                    ];

                                    const formcontrol = <FormArray>(
                                        this.DrawRequestForm.controls[
                                            "Constructions"
                                        ]
                                    );
                                    var grandTotal = 0;
                                    var grandGCTotal = 0;
                                    var grandPreviewTotal = 0;
                                    constructionRepairItems.map((mainItem) => {
                                        let tableItem = {
                                            RepairType: "",
                                            TotalAmount: 0,
                                            TotalGCAmount: 0,
                                            TotalGCPercent: 0,
                                            GCPreviewsAmount: 0,
                                            GCAmount: 0,
                                            ConsultantAmount: 0,
                                        };
                                        tableItem.RepairType =
                                            mainItem.RepairType;

                                        let borFiltered = BorItem.filter(
                                            (fItem) => {
                                                return (
                                                    fItem.RepairType ==
                                                    mainItem.RepairType
                                                );
                                            }
                                        );

                                        borFiltered.map((borItem) => {
                                            let totalAmount = 0;
                                            borItem.RepairItemDetails.map(
                                                (repairItem) => {
                                                    totalAmount =
                                                        totalAmount +
                                                        parseFloat(
                                                            repairItem.LaborQuantity
                                                        ) *
                                                            parseFloat(
                                                                repairItem.LaborUnitCost
                                                            ) +
                                                        +(
                                                            parseFloat(
                                                                repairItem.MaterialQuantity
                                                            ) *
                                                            parseFloat(
                                                                repairItem.MaterialUnitCost
                                                            )
                                                        );
                                                }
                                            );

                                            tableItem.TotalAmount = totalAmount;
                                            grandTotal =
                                                grandTotal + totalAmount;
                                        });

                                        //initial Draw approved
                                        let previewsAmount = 0;

                                        var initialDrawApproved =
                                            initialDraw.filter((itemDraw) => {
                                                return (
                                                    itemDraw.DrawStatus ==
                                                    "approved"
                                                );
                                            });

                                        initialDrawApproved.map((drawItem) => {
                                            let drawConstruction =
                                                drawItem.Constructions.filter(
                                                    (drawConstruction) => {
                                                        return (
                                                            drawConstruction.RepairType ==
                                                            mainItem.RepairType
                                                        );
                                                    }
                                                );
                                            if (drawConstruction.length > 0) {
                                                if (
                                                    drawConstruction[0]
                                                        .ConsultantAmount >= 0
                                                ) {
                                                    previewsAmount =
                                                        previewsAmount +
                                                        parseFloat(
                                                            drawConstruction[0]
                                                                .ConsultantAmount
                                                        );
                                                } else {
                                                    previewsAmount =
                                                        previewsAmount +
                                                        parseFloat(
                                                            drawConstruction[0]
                                                                .GCAmount
                                                        );
                                                }
                                            }
                                        });

                                        tableItem.GCPreviewsAmount =
                                            previewsAmount;
                                        grandPreviewTotal =
                                            grandPreviewTotal + previewsAmount;

                                        //initial Draw Pending

                                        var initialDrawPending =
                                            initialDraw.filter((itemDraw) => {
                                                return (
                                                    itemDraw.DrawStatus ==
                                                        "pending" ||
                                                    itemDraw.DrawStatus ==
                                                        "revised" ||
                                                    itemDraw.DrawStatus == null
                                                );
                                            });

                                        let gcAmount = 0;
                                        let consultantAmount = 0;

                                        initialDrawPending.map((drawItem) => {
                                            this.DrawRequestForm.controls[
                                                "Id"
                                            ].setValue(drawItem.Id);
                                            this.DrawRequestForm.controls[
                                                "OldDrawStatus"
                                            ].setValue(drawItem.DrawStatus);
                                            this.oldDrawStatus =
                                                drawItem.DrawStatus;
                                            this.borrowerComment =
                                                drawItem.BorrowerComment;
                                            let drawConstruction =
                                                drawItem.Constructions.filter(
                                                    (drawConstruction) => {
                                                        return (
                                                            drawConstruction.RepairType ==
                                                            mainItem.RepairType
                                                        );
                                                    }
                                                );
                                            if (drawConstruction.length > 0) {
                                                gcAmount =
                                                    gcAmount +
                                                    parseFloat(
                                                        drawConstruction[0]
                                                            .GCAmount
                                                    );
                                                consultantAmount =
                                                    consultantAmount +
                                                    parseFloat(
                                                        drawConstruction[0]
                                                            .ConsultantAmount
                                                    );
                                            }
                                        });

                                        grandGCTotal = grandGCTotal + gcAmount;
                                        tableItem.GCAmount = gcAmount;
                                        tableItem.ConsultantAmount =
                                            consultantAmount;

                                        formcontrol.push(
                                            this._fb.group({
                                                RepairType: [
                                                    tableItem.RepairType,
                                                ],
                                                TotalAmount: [
                                                    tableItem.TotalAmount,
                                                ],
                                                TotalGCAmount: [
                                                    tableItem.TotalGCAmount,
                                                ],
                                                TotalGCPercent: [
                                                    tableItem.TotalGCPercent,
                                                ],
                                                GCPreviewsAmount: [
                                                    tableItem.GCPreviewsAmount,
                                                ],
                                                GCAmount: [tableItem.GCAmount],
                                                ConsultantAmount: [
                                                    tableItem.ConsultantAmount,
                                                ],
                                            })
                                        );
                                    });
                                    this.DrawRequestForm.controls[
                                        "Constructions"
                                    ]["controls"][35].controls[
                                        "TotalAmount"
                                    ].setValue(grandTotal);
                                    this.DrawRequestForm.controls[
                                        "Constructions"
                                    ]["controls"][35].controls[
                                        "TotalGCAmount"
                                    ].setValue(grandGCTotal);
                                    this.DrawRequestForm.controls[
                                        "Constructions"
                                    ]["controls"][35].controls[
                                        "GCPreviewsAmount"
                                    ].setValue(grandPreviewTotal);

                                    this.loading = false;
                                });
                        });
                }
            });
    }

    changeAction(action) {
        this.drawRequestBOAction = action;
    }

    cancelAction() {
        this.drawRequestBOAction = "";
    }
    onKeyUpGCAmount(index) {
        let totalAM =
            this.DrawRequestForm.controls["Constructions"]["controls"][index]
                .controls.TotalAmount.value;
        let totalGCP =
            this.DrawRequestForm.controls["Constructions"]["controls"][index]
                .controls.GCPreviewsAmount.value;
        let remainVal = totalAM - totalGCP;
        let myVal =
            this.DrawRequestForm.controls["Constructions"]["controls"][index]
                .controls.GCAmount.value;

        if (remainVal >= myVal) {
            return;
        } else {
            alert(
                "Please enter your value without symbols and commas. The total of column #2 and entered value in column #3 should be less than value shown in column #1."
            );
            return;
        }
    }
    onBlurGCAmount(index) {
        let grandTotal: any = 0.0;
        let totalAmount: any = 0;
        let gcTotalPercent: any = 0;

        if (
            isNaN(
                this.DrawRequestForm.controls["Constructions"]["controls"][
                    index
                ].controls.GCAmount.value
            ) &&
            this.DrawRequestForm.controls["Constructions"]["controls"][index]
                .controls.TotalAmount.value != 0
        ) {
            alert("Value must be a number and should not be blank.");
            this.DrawRequestForm.controls["Constructions"]["controls"][
                index
            ].controls["GCAmount"].setValue(0);
            return;
        } else if (
            this.DrawRequestForm.controls["Constructions"]["controls"][index]
                .controls.GCAmount.value === ""
        ) {
            this.DrawRequestForm.controls["Constructions"]["controls"][
                index
            ].controls["GCAmount"].setValue(0);
            for (
                let i = 0;
                i <
                this.DrawRequestForm.controls["Constructions"]["controls"]
                    .length;
                i++
            ) {
                grandTotal += parseFloat(
                    this.DrawRequestForm.controls["Constructions"]["controls"][
                        i
                    ].controls.GCAmount.value
                );
            }

            totalAmount =
                this.DrawRequestForm.controls["Constructions"]["controls"][35]
                    .controls.TotalAmount.value;

            gcTotalPercent = (grandTotal * 100) / totalAmount;

            this.DrawRequestForm.controls["Constructions"][
                "controls"
            ][35].controls["TotalGCAmount"].setValue(
                parseFloat(grandTotal).toFixed(2)
            );

            this.DrawRequestForm.controls["Constructions"][
                "controls"
            ][35].controls["TotalGCPercent"].setValue(
                parseFloat(gcTotalPercent).toFixed(2)
            );
        } else {
            for (
                let i = 0;
                i <
                this.DrawRequestForm.controls["Constructions"]["controls"]
                    .length;
                i++
            ) {
                grandTotal += parseFloat(
                    this.DrawRequestForm.controls["Constructions"]["controls"][
                        i
                    ].controls.GCAmount.value
                );
            }

            totalAmount =
                this.DrawRequestForm.controls["Constructions"]["controls"][35]
                    .controls.TotalAmount.value;

            gcTotalPercent = (grandTotal * 100) / totalAmount;

            this.DrawRequestForm.controls["Constructions"][
                "controls"
            ][35].controls["TotalGCAmount"].setValue(
                parseFloat(grandTotal).toFixed(2)
            );

            this.DrawRequestForm.controls["Constructions"][
                "controls"
            ][35].controls["TotalGCPercent"].setValue(
                parseFloat(gcTotalPercent).toFixed(2)
            );
        }
    }
    SaveDrawRequest() {
        this.submitted = true;
        if (this.DrawRequestForm.invalid) {
            return;
        } else {
            this.propertyRepairConstructionDetail = "";
            var formData = this.DrawRequestForm.value;

            for (let j = 0; j < formData.Constructions.length; j++) {
                let rtype =
                    this.DrawRequestForm.controls["Constructions"]["controls"][
                        j
                    ].controls.RepairType.value;
                if (rtype == formData.Constructions[j].RepairType) {
                    let totalAM =
                        this.DrawRequestForm.controls["Constructions"][
                            "controls"
                        ][j].controls.TotalAmount.value;
                    let totalGCP =
                        this.DrawRequestForm.controls["Constructions"][
                            "controls"
                        ][j].controls.GCPreviewsAmount.value;
                    let remainVal = totalAM - totalGCP;
                    let myVal =
                        this.DrawRequestForm.controls["Constructions"][
                            "controls"
                        ][j].controls.GCAmount.value;
                    if (isNaN(myVal) || myVal === "") {
                        alert(
                            "Value must be a number for Repair Type '" +
                                formData.Constructions[j].RepairType +
                                "'"
                        );
                        return;
                    } else if (remainVal < myVal) {
                        alert(
                            "Please enter your value without symbols and commas. The total of column #2 and entered value in column #3 should be less than value shown in column #1 for Repair Type '" +
                                formData.Constructions[j].RepairType +
                                "'"
                        );
                        return;
                    }
                }
            }

            this.loading = true;
            this.submitted = false;
            this.oldDrawStatus = formData.OldDrawStatus;

            var ConstructionData = [];

            for (let i = 0; i < formData.Constructions.length; i++) {
                if (formData.Constructions[i].GCAmount > 0) {
                    ConstructionData.push({
                        RepairType: formData.Constructions[i].RepairType,
                        GCAmount: formData.Constructions[i].GCAmount,
                        ConsultantAmount: 0,
                    });
                }
            }
            formData.Constructions = ConstructionData;
            var today = new Date();
            formData.GCRequestDate = today;

            formData.UpdatedBy = this.userDetail.Id;

            if (formData.Id != "" && formData.Id != null) {
                this._bservice.UpdateDrawRequest(formData).subscribe(
                    (drawResponse) => {
                        if (this.oldDrawStatus == "revised") {
                            let newData = [];
                            let newObj = {};
                            this.propertyService
                                .GetPropertyDetails(this.RepairPropertyId)
                                .subscribe(
                                    (proResponse) => {
                                        for (
                                            var i = 0;
                                            i < proResponse.InitialDraws.length;
                                            i++
                                        ) {
                                            if (
                                                proResponse.InitialDraws[i]
                                                    .DrawId == formData.Id
                                            ) {
                                                newObj = {
                                                    DrawId: formData.Id,
                                                    Status: "pending",
                                                    UpdatedBy:
                                                        this.userDetail.Id,
                                                };
                                            } else {
                                                newObj =
                                                    proResponse.InitialDraws[i];
                                            }
                                            newData.push(newObj);
                                        }
                                        proResponse.InitialDraws = newData;

                                        var propertyData = {
                                            Id: proResponse.Id,
                                            InitialDraws:
                                                proResponse.InitialDraws,
                                            UpdateDate: "today",
                                            UpdatedBy: this.userDetail.Id,
                                        };

                                        this.propertyService
                                            .UpdatePropertyDraw(propertyData)
                                            .subscribe(
                                                (response) => {
                                                    var propertyStatusData = {
                                                        Id: parseInt(
                                                            this
                                                                .RepairPropertyId
                                                        ),
                                                        Status: "Draw request started",
                                                        CreatedBy:
                                                            this.userDetail
                                                                .EmailId,
                                                        UpdatedBy:
                                                            this.userDetail.Id,
                                                    };
                                                    this.propertyService
                                                        .UpdatePropertyStatus(
                                                            propertyStatusData
                                                        )
                                                        .subscribe(
                                                            (response) => {
                                                                var DrawEmailsData =
                                                                    {
                                                                        PropertyId:
                                                                            this
                                                                                .RepairPropertyId,
                                                                        Status: "revised",
                                                                        isStatus:
                                                                            "submitted",
                                                                        UserId: this
                                                                            .userDetail
                                                                            .Id,
                                                                        SendBy: this
                                                                            .userDetail
                                                                            .UserType,
                                                                            DrawId: formData.Id,
                                                                    };

                                                                this._bservice
                                                                    .DrawEmails(
                                                                        DrawEmailsData
                                                                    )
                                                                    .subscribe(
                                                                        (
                                                                            response
                                                                        ) => {
                                                                            this.sessionService.notifier.notify(
                                                                                "success",
                                                                                "Draw Request Submitted."
                                                                            );
                                                                            if (
                                                                                this
                                                                                    .userDetail
                                                                                    .UserType ==
                                                                                "Borrower"
                                                                            ) {
                                                                                this.router.navigate(
                                                                                    [
                                                                                        "/property-details/" +
                                                                                            this
                                                                                                .RepairPropertyId,
                                                                                    ]
                                                                                );
                                                                            } else {
                                                                                this.router.navigate(
                                                                                    [
                                                                                        "/properties",
                                                                                    ]
                                                                                );
                                                                            }
                                                                        },
                                                                        (
                                                                            error
                                                                        ) => {
                                                                            this.sessionService.notifier.notify(
                                                                                "error",
                                                                                error
                                                                                    .error
                                                                                    .message
                                                                            );
                                                                            this.loading =
                                                                                false;
                                                                        }
                                                                    );
                                                            },
                                                            (error) => {
                                                                this.sessionService.notifier.notify(
                                                                    "error",
                                                                    error.error
                                                                        .message
                                                                );
                                                            }
                                                        );
                                                },
                                                (error) => {
                                                    this.sessionService.notifier.notify(
                                                        "error",
                                                        error.error.message
                                                    );
                                                    this.loading = false;
                                                }
                                            );
                                    },
                                    (error) => {
                                        this.sessionService.notifier.notify(
                                            "error",
                                            error.error.message
                                        );
                                        this.loading = false;
                                    }
                                );
                        } else {
                            this.sessionService.notifier.notify(
                                "success",
                                "Draw Request Submitted."
                            );
                            if (this.userDetail.UserType == "Borrower") {
                                this.router.navigate([
                                    "/property-details/" +
                                        this.RepairPropertyId,
                                ]);
                            } else {
                                this.router.navigate(["/properties"]);
                            }
                        }
                    },
                    (error) => {
                        this.sessionService.notifier.notify(
                            "error",
                            error.error.message
                        );
                        this.loading = false;
                    }
                );
            } else {
                this._bservice.SaveDrawRequest(formData).subscribe(
                    (drawResponse) => {
                        var DrawEmailsData = {
                            PropertyId: this.RepairPropertyId,
                            Status: "pending",
                            UserId: this.userDetail.Id,
                            SendBy: this.userDetail.UserType,
                            DrawId: drawResponse.Item.Id
                        };

                        this._bservice.DrawEmails(DrawEmailsData).subscribe(
                            (response) => {},
                            (error) => {
                                this.sessionService.notifier.notify(
                                    "error",
                                    error.error.message
                                );
                                this.loading = false;
                            }
                        );

                        this.propertyService
                            .GetPropertyDetails(this.RepairPropertyId)
                            .subscribe(
                                (proResponse) => {
                                    if (proResponse.InitialDraws != undefined) {
                                        proResponse.InitialDraws.push({
                                            DrawId: drawResponse.Item.Id,
                                            Status: "pending",
                                            UpdatedBy: this.userDetail.Id,
                                        });
                                    } else {
                                        proResponse.InitialDraws = [
                                            {
                                                DrawId: drawResponse.Item.Id,
                                                Status: "pending",
                                                UpdatedBy: this.userDetail.Id,
                                            },
                                        ];
                                    }

                                    var propertyData = {
                                        Id: proResponse.Id,
                                        InitialDraws: proResponse.InitialDraws,
                                        UpdateDate: "today",
                                        UpdatedBy: this.userDetail.Id,
                                    };

                                    this.propertyService
                                        .UpdatePropertyDraw(propertyData)
                                        .subscribe(
                                            (response) => {
                                                var propertyStatusData = {
                                                    Id: parseInt(
                                                        this.RepairPropertyId
                                                    ),
                                                    Status: "Draw request started",
                                                    CreatedBy:
                                                        this.userDetail.EmailId,
                                                    UpdatedBy:
                                                        this.userDetail.Id,
                                                };
                                                this.propertyService
                                                    .UpdatePropertyStatus(
                                                        propertyStatusData
                                                    )
                                                    .subscribe(
                                                        (response) => {
                                                            if (
                                                                this.userDetail
                                                                    .UserType ==
                                                                "Borrower"
                                                            ) {
                                                                this.router.navigate(
                                                                    [
                                                                        "/property-details/" +
                                                                            this
                                                                                .RepairPropertyId,
                                                                    ]
                                                                );
                                                            } else {
                                                                this.router.navigate(
                                                                    [
                                                                        "/properties",
                                                                    ]
                                                                );
                                                            }
                                                        },
                                                        (error) => {
                                                            this.sessionService.notifier.notify(
                                                                "error",
                                                                error.error
                                                                    .message
                                                            );
                                                            this.loading =
                                                                false;
                                                        }
                                                    );
                                            },
                                            (error) => {
                                                this.sessionService.notifier.notify(
                                                    "error",
                                                    error.error.message
                                                );
                                                this.loading = false;
                                            }
                                        );
                                },
                                (error) => {
                                    this.sessionService.notifier.notify(
                                        "error",
                                        error.error.message
                                    );
                                    this.loading = false;
                                }
                            );
                    },
                    (error) => {
                        this.sessionService.notifier.notify(
                            "error",
                            error.error.message
                        );
                        this.loading = false;
                    }
                );
            }
        }
    }

    GetFinalSORPDF() {
        this.loading = true;
        var data = {
            PropertyId: this.RepairPropertyId,
            Status: "Final",
        };
        this.propertyService.GetSORPDF(data).subscribe(
            (response) => {
                let url = this.baseurl + response.PdfPath;
                this.loading = false;
                window.open(url, "_blank");
            },
            (error) => {
                this.sessionService.notifier.notify(
                    "error",
                    error.error.message
                );
                this.loading = false;
            }
        );
    }

    acceptBid(bidId) {
        this._bservice.GetGCSorDetailsbyId(bidId).subscribe(
            (GCresponse) => {
                if (GCresponse != null) {
                    GCresponse.Status = "Accepted";
                    this._bservice.UpdateSORForGC(GCresponse).subscribe(
                        (response) => {
                            var propertyStatusData = {
                                Id: parseInt(this.RepairPropertyId),
                                Status: "Review Bid",
                                CreatedBy: this.userDetail.EmailId,
                                UpdatedBy: this.userDetail.EmailId,
                            };
                            this.propertyService
                                .UpdatePropertyStatus(propertyStatusData)
                                .subscribe(
                                    (response) => {
                                        this.sessionService.notifier.notify(
                                            "success",
                                            "Send Bid For Consultant Review Successfully."
                                        );
                                        this.getDrawRequestData(
                                            this.RepairPropertyId
                                        );
                                    },
                                    (error) => {
                                        this.sessionService.notifier.notify(
                                            "error",
                                            error.error.message
                                        );
                                        this.loading = false;
                                    }
                                );
                        },
                        (error) => {
                            this.sessionService.notifier.notify(
                                "error",
                                error.error.message
                            );
                            this.loading = false;
                        }
                    );
                }
            },
            (error) => {}
        );
    }

    reviseBid(bidId) {}
}

/** Builds and returns a new User. */
function createNewUser(id: number, userList): UserData {
    const name =
        NAMES[Math.round(Math.random() * (NAMES.length - 1))] +
        " " +
        NAMES[Math.round(Math.random() * (NAMES.length - 1))].charAt(0) +
        ".";
    if (userList.Items[id] != undefined) {
        userList.Items[id].id = id + 1;
        return userList.Items[id];
    }
}
