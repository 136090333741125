<div class="content p-24 tbl">
    <ngx-loading class="ngxLoader" 
        [show]="loading"
        [config]="{ backdropBorderRadius: '3px' }"
    ></ngx-loading>
    <h1 class="m-0">Permissions</h1>
    <div style="margin: -34px 0 0 0">
        <a class="addBorrow" routerLink="/roles" style="margin: 0px 0px 0 10px"
            ><button mat-raised-button type="button">Roles</button></a
        >
        <a class="addBorrow" href="javascript:void(0)" style="margin: 0px"
            ><button mat-raised-button type="button" color="primary">
                Permissions
            </button></a
        >
    </div>
    <br />
    <!-- <mat-form-field>
  <input [hidden]="!isList" matInput (keyup)="applyFilter($event.target)" placeholder="Filter">
</mat-form-field> -->
    <p style="margin: 35px 0 20px 0; display: block">
        Permissions let you control what users can do and see on your site. You
        can define a specific set of permissions for each role. (See the Roles
        page to create a role). Two important roles to consider are
        Authenticated Users and Administrators. Any permissions granted to the
        Authenticated Users role will be given to any user who can log into your
        site. You can make any role the Administrator role for the site, meaning
        this will be granted all new permissions automatically. You can do this
        on the User Settings page. You should be careful to ensure that only
        trusted users are given this access and level of control of your site.
    </p>
    <a
        *ngIf="!isDescription"
        (click)="showDescription(isDescription)"
        href="javascript:void(0);"
        style="padding: 0 0 20px 0px; display: block"
        >Show descriptions</a
    >
    <a
        *ngIf="isDescription"
        (click)="showDescription(isDescription)"
        href="javascript:void(0);"
        style="padding: 0 0 20px 0px; display: block"
        >Hide descriptions</a
    >
    <div class="mat-elevation-z8" [hidden]="!isList">
        <table mat-table [dataSource]="dataSource" matSort>
            <!-- ID Column -->
            <ng-container matColumnDef="ActionName">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                    style="font-size: 12px"
                >
                    PERMISSION
                </th>
                <td mat-cell *matCellDef="let row">
                    {{ row.ActionName }} <br />
                    <span *ngIf="isDescription">{{ row.Descriptions }}</span>
                </td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="AnonymousUser">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                    style="font-size: 12px"
                >
                    ANONYMOUS USER
                </th>
                <td mat-cell *matCellDef="let row" style="text-align: center">
                    <mat-checkbox
                        [(ngModel)]="row.AnonymousUser"
                        class="example-radio-group"
                    ></mat-checkbox>
                </td>
            </ng-container>
            <ng-container matColumnDef="AuthenticatedUser">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                    style="font-size: 12px"
                >
                    AUTHENTICATED USER
                </th>
                <td mat-cell *matCellDef="let row" style="text-align: center">
                    <mat-checkbox
                        [(ngModel)]="row.AuthenticatedUser"
                        class="example-radio-group"
                    ></mat-checkbox>
                </td>
            </ng-container>
            <!-- Progress Column -->
            <ng-container matColumnDef="Administrator">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                    style="font-size: 12px"
                >
                    ADMINISTRATOR
                </th>
                <td mat-cell *matCellDef="let row" style="text-align: center">
                    <mat-checkbox
                        [(ngModel)]="row.Administrator"
                        class="example-radio-group"
                    ></mat-checkbox>
                </td>
            </ng-container>

            <ng-container matColumnDef="Consultants">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                    style="font-size: 12px"
                >
                    CONSULTANTS
                </th>
                <td mat-cell *matCellDef="let row" style="text-align: center">
                    <mat-checkbox
                        [(ngModel)]="row.Consultants"
                        class="example-radio-group"
                    ></mat-checkbox>
                </td>
            </ng-container>

            <ng-container matColumnDef="GeneralContractors">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                    style="font-size: 12px"
                >
                    GENERAL CONTRACTORS
                </th>
                <td mat-cell *matCellDef="let row" style="text-align: center">
                    <mat-checkbox
                        [(ngModel)]="row.GeneralContractors"
                        class="example-radio-group"
                    ></mat-checkbox>
                </td>
            </ng-container>

            <ng-container matColumnDef="Borrower">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                    style="font-size: 12px"
                >
                    BORROWER
                </th>
                <td mat-cell *matCellDef="let row" style="text-align: center">
                    <mat-checkbox
                        [(ngModel)]="row.Borrower"
                        class="example-radio-group"
                    ></mat-checkbox>
                </td>
            </ng-container>
            <ng-container matColumnDef="LoanOfficer">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                    style="font-size: 12px"
                >
                    LOAN OFFICER
                </th>
                <td mat-cell *matCellDef="let row" style="text-align: center">
                    <mat-checkbox
                        [(ngModel)]="row.LoanOfficer"
                        class="example-radio-group"
                    ></mat-checkbox>
                </td>
            </ng-container>
            <ng-container matColumnDef="LoanProcessor">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                    style="font-size: 12px"
                >
                    LOAN PROCESSOR
                </th>
                <td mat-cell *matCellDef="let row" style="text-align: center">
                    <mat-checkbox
                        [(ngModel)]="row.LoanProcessor"
                        class="example-radio-group"
                    ></mat-checkbox>
                </td>
            </ng-container>
            <ng-container matColumnDef="DrawAdministrator">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                    style="font-size: 12px"
                >
                    DRAW ADMINISTRATOR
                </th>
                <td mat-cell *matCellDef="let row" style="text-align: center">
                    <mat-checkbox
                        [(ngModel)]="row.DrawAdministrator"
                        class="example-radio-group"
                    ></mat-checkbox>
                </td>
            </ng-container>
            <!-- Color Column -->
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr
                mat-row
                *matRowDef="let row; columns: displayedColumns"
                style="cursor: pointer"
            ></tr>
        </table>
    </div>
</div>
