import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { SessionService } from "../service/session.service";

@Injectable({
    providedIn: "root",
})
export class BorrowerService {
    constructor(
        private http: HttpClient,
        private sessionService: SessionService
    ) {}
    baseurl = this.sessionService.endpoint;
    AddBorrower(data) {
        return this.http.post<any>(this.baseurl + "users/create", data, {
            headers: this.sessionService.setTokenHeader(),
        });
    }

    UpdateBorrower(data) {
        return this.http.put<any>(this.baseurl + "users", data, {
            headers: this.sessionService.setTokenHeader(),
        });
    }
    EditBorrower(data) {
        return this.http.put<any>(this.baseurl + "users/edit", data, {
            headers: this.sessionService.setTokenHeader(),
        });
    }
    GetUserList(data) {
        return this.http.post<any>(this.baseurl + "users/list", data, {
            headers: this.sessionService.setTokenHeader(),
        });
    }

    GetUserByEmail(email) {
        return this.http.get<any>(this.baseurl + "users/email/" + email, {
            headers: this.sessionService.setTokenHeader(),
        });
    }

    GetBorrowerDetails(borrowerId) {
        return this.http.get<any>(this.baseurl + "users/" + borrowerId, {
            headers: this.sessionService.setTokenHeader(),
        });
    }

    GetLenderList(data) {
        return this.http.post<any>(this.baseurl + "lenders/list", data, {
            headers: this.sessionService.setTokenHeader(),
        });
    }

    DeleteUser(userid) {
        return this.http.delete<any>(this.baseurl + "users/" + userid, {
            headers: this.sessionService.setTokenHeader(),
        });
    }

    GetSorDetails(data) {
        return this.http.post<any>(this.baseurl + "sor/list", data, {
            headers: this.sessionService.setTokenHeader(),
        });
    }

    GetEmailSetting(data) {
        return this.http.post<any>(this.baseurl + "settings/smtp/list", data, {
            headers: this.sessionService.setTokenHeader(),
        });
    }

    AddEmailSetting(data) {
        return this.http.post<any>(this.baseurl + "settings/smtp", data, {
            headers: this.sessionService.setTokenHeader(),
        });
    }
    UpdateEmailSetting(data) {
        return this.http.put<any>(this.baseurl + "settings/smtp", data, {
            headers: this.sessionService.setTokenHeader(),
        });
    }

    UpdatePropertyStatus(data) {
        return this.http.put<any>(
            this.baseurl + "property/updatestatus",
            data,
            { headers: this.sessionService.setTokenHeader() }
        );
    }

    AddSOR(data) {
        return this.http.post<any>(this.baseurl + "sor", data, {
            headers: this.sessionService.setTokenHeader(),
        });
    }
    UpdateSOR(data) {
        return this.http.put<any>(this.baseurl + "sor", data, {
            headers: this.sessionService.setTokenHeader(),
        });
    }

    AllowableFeesList(data) {
        return this.http.post<any>(
            this.baseurl + "masters/allowablefees/list",
            data,
            { headers: this.sessionService.setTokenHeader() }
        );
    }

    GetPropertyImagesList(data) {
        return this.http.post<any>(this.baseurl + "propertyimages/list", data, {
            headers: this.sessionService.setTokenHeader(),
        });
    }
    GetPropertyDrawImagesList(data) {
        return this.http.post<any>(
            this.baseurl + "propertyimages/drawlist",
            data,
            { headers: this.sessionService.setTokenHeader() }
        );
    }
    AddPropertyImage(data) {
        return this.http.post<any>(this.baseurl + "propertyimages", data, {
            headers: this.sessionService.setTokenHeader(),
        });
    }

    UpdatePropertyImage(data) {
        return this.http.put<any>(this.baseurl + "propertyimages", data, {
            headers: this.sessionService.setTokenHeader(),
        });
    }

    GetGCSorDetails(data) {
        return this.http.post<any>(this.baseurl + "bidonrepair/list", data, {
            headers: this.sessionService.setTokenHeader(),
        });
    }
    GetGCSorDetailsbyId(id) {
        return this.http.get<any>(this.baseurl + "bidonrepair/" + id, {
            headers: this.sessionService.setTokenHeader(),
        });
    }

    AddSORForGC(data) {
        return this.http.post<any>(this.baseurl + "bidonrepair", data, {
            headers: this.sessionService.setTokenHeader(),
        });
    }
    UpdateSORForGC(data) {
        return this.http.put<any>(this.baseurl + "bidonrepair", data, {
            headers: this.sessionService.setTokenHeader(),
        });
    }

    GetDrawRequestList(data) {
        return this.http.post<any>(this.baseurl + "initialdraw/list", data, {
            headers: this.sessionService.setTokenHeader(),
        });
    }

    GetDrawRequestById(data) {
        return this.http.get<any>(this.baseurl + "initialdraw/" + data, {
            headers: this.sessionService.setTokenHeader(),
        });
    }

    SaveDrawRequest(data) {
        return this.http.post<any>(this.baseurl + "initialdraw", data, {
            headers: this.sessionService.setTokenHeader(),
        });
    }

    UpdateDrawRequest(data) {
        return this.http.put<any>(this.baseurl + "initialdraw", data, {
            headers: this.sessionService.setTokenHeader(),
        });
    }

    DrawEmails(data) {
        return this.http.post<any>(
            this.baseurl + "initialdraw/drawemails",
            data,
            { headers: this.sessionService.setTokenHeader() }
        );
    }

    AddSetting(data) {
        return this.http.post<any>(this.baseurl + "settings", data, {
            headers: this.sessionService.setTokenHeader(),
        });
    }

    GetSetting(data) {
        return this.http.get<any>(this.baseurl + "settings", {
            headers: this.sessionService.setTokenHeader(),
        });
    }
}
