import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { SessionService } from "../service/session.service";

@Injectable({
    providedIn: "root",
})
export class LenderService {
    constructor(
        private http: HttpClient,
        private sessionService: SessionService
    ) {}
    baseurl = this.sessionService.endpoint;
    AddBorrower(data) {
        return this.http.post<any>(this.baseurl + "lenders", data, {
            headers: this.sessionService.setTokenHeader(),
        });
    }

    UpdateBorrower(data) {
        return this.http.put<any>(this.baseurl + "lenders", data, {
            headers: this.sessionService.setTokenHeader(),
        });
    }

    GetUserList(data) {
        return this.http.post<any>(this.baseurl + "lenders/list", data, {
            headers: this.sessionService.setTokenHeader(),
        });
    }

    GetLoanOfficerUserList(data) {
        return this.http.post<any>(this.baseurl + "users/list", data, {
            headers: this.sessionService.setTokenHeader(),
        });
    }

    DeleteUser(userid) {
        return this.http.delete<any>(this.baseurl + "lenders/" + userid, {
            headers: this.sessionService.setTokenHeader(),
        });
    }
}
