<div class="content p-24 tbl">
    <ngx-loading class="ngxLoader" 
        [show]="loading"
        [config]="{ backdropBorderRadius: '3px' }"
    ></ngx-loading>
    <h1 class="m-0">Consultant's Identity-Of-Interest Certification</h1>
    <br />
    <div class="container-login100">
        <mat-card class="my-form">
            <form
                [formGroup]="add_loanOfficer"
                class="example-form"
                (ngSubmit)="SaveRecord()"
            >
                <mat-card-content>
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    <mat-form-field class="example-full-width">
                                        <span
                                            class="mat-form-field-label-wrapper"
                                            ><!----><label
                                                class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                >Title*</label
                                            ></span
                                        >
                                        <input
                                            matInput
                                            formControlName="email"
                                            name="username"
                                        />
                                    </mat-form-field>
                                </td>
                                <td>
                                    <mat-form-field class="example-full-width">
                                        <span
                                            class="mat-form-field-label-wrapper"
                                            ><!----><label
                                                class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                >Borrower</label
                                            ></span
                                        >
                                        <input
                                            matInput
                                            formControlName="OMBApprovalNo"
                                            name="username"
                                        />
                                    </mat-form-field>
                                </td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>

                    <label><strong>Property Inspection Address</strong></label>
                    <ckeditor
                        matNativeControl
                        name="editor1"
                        formControlName="editorValue"
                        skin="moono-lisa"
                        language="en"
                    ></ckeditor>
                    <br />

                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    <mat-form-field>
                                        <span
                                            class="mat-form-field-label-wrapper"
                                            ><!----><label
                                                class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                >EXPIRE ON</label
                                            ></span
                                        >
                                        <input
                                            matInput
                                            [matDatepicker]="picker"
                                        />
                                        <mat-datepicker-toggle
                                            matSuffix
                                            [for]="picker"
                                        ></mat-datepicker-toggle>
                                        <mat-datepicker
                                            #picker
                                        ></mat-datepicker>
                                    </mat-form-field>
                                </td>
                                <td>
                                    <mat-form-field class="example-full-width">
                                        <span
                                            class="mat-form-field-label-wrapper"
                                            ><!----><label
                                                class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                >FHA Case No</label
                                            ></span
                                        >
                                        <input
                                            matInput
                                            formControlName="Borower"
                                            name="username"
                                        />
                                    </mat-form-field>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <mat-form-field class="example-full-width">
                                        <span
                                            class="mat-form-field-label-wrapper"
                                            ><!----><label
                                                class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                >Consultant</label
                                            ></span
                                        >
                                        <input
                                            matInput
                                            formControlName="Lender"
                                            name="username"
                                        />
                                    </mat-form-field>
                                </td>
                                <td>
                                    <mat-form-field>
                                        <span
                                            class="mat-form-field-label-wrapper"
                                            ><!----><label
                                                class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                >Property</label
                                            ></span
                                        >
                                        <mat-select formControlName="Property">
                                            <mat-option value=""
                                                >Select</mat-option
                                            >
                                            <mat-option value="RMS"
                                                >1 City Hall Square , Boston,
                                                Massachusetts, 02201</mat-option
                                            >
                                        </mat-select>
                                    </mat-form-field>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <label>
                        <strong
                            >Consultant/Plan Reviewer's Signature</strong
                        > </label
                    ><br />
                    <input type="file" /><br />
                    <p>
                        Files must be less than <strong>100 MB.</strong> <br />
                        Allowed file types: <strong>png gif jpg jpeg.</strong>
                    </p>
                </mat-card-content>
                <mat-card-actions>
                    <button mat-raised-button type="submit" color="primary">
                        Save</button
                    >&nbsp; &nbsp;&nbsp;
                    <button mat-raised-button type="button" color="info">
                        Save as draft</button
                    >&nbsp; &nbsp;&nbsp;
                    <button mat-raised-button type="button" color="info">
                        Preview
                    </button>
                </mat-card-actions>
            </form>
        </mat-card>
    </div>
</div>
