<div class="content p-24 tbl">
    <ngx-loading class="ngxLoader" 
        [show]="loading"
        [config]="{ backdropBorderRadius: '3px' }"
    ></ngx-loading>
    <h1 class="m-0">Form Settings</h1>
    <!-- <a *ngIf="isList" class="addBorrow" href="javascript:void(0)" (click)="isList=false;isAdd=true;isEdit=false;formReset()"><mat-icon _ngcontent-c102="" class="secondary-text s-22 mat-icon material-icons mat-icon-no-color" role="img" aria-hidden="true">add_circle</mat-icon> Add New Loan Officer</a>
    <a *ngIf="isAdd || isEdit" class="addBorrow" href="javascript:void(0)" (click)="isAdd=false;isList=true;isEdit=false"><mat-icon _ngcontent-c102="" class="secondary-text s-22 mat-icon material-icons mat-icon-no-color" role="img" aria-hidden="true">view_list</mat-icon> List Loan Officer</a> -->

    <!-- <mat-form-field>
  <input [hidden]="!isList" matInput (keyup)="applyFilter($event.target)" placeholder="Filter">
</mat-form-field> -->
    <br />
    <mat-card class="example-headers-align">
        <form
            [formGroup]="EmailSetting"
            class="example-form"
            (ngSubmit)="SaveEmailSetting()"
        >
            <mat-card-header>
                <mat-card-title
                    >Consultant Liability Form Settings</mat-card-title
                >
            </mat-card-header>
            <mat-card-content class="Consultant-Liability">
                <table>
                    <tbody>
                        <tr>
                            <td>
                                <mat-form-field>
                                    <span class="mat-form-field-label-wrapper"
                                        ><!----><label
                                            class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                            >Consultant Fee</label
                                        ></span
                                    >
                                    <span class="dollarSign"> $ </span
                                    ><input
                                        matInput
                                        class="consultantLiabilityField"
                                        formControlName="ConsultantFee"
                                    />
                                </mat-form-field>
                            </td>
                            <td>
                                <mat-form-field>
                                    <span class="mat-form-field-label-wrapper"
                                        ><!----><label
                                            class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                            >Per Change Order</label
                                        ></span
                                    >
                                    <span class="dollarSign"> $ </span
                                    ><input
                                        matInput
                                        class="consultantLiabilityField"
                                        formControlName="PerChangeOrder"
                                    />
                                </mat-form-field>
                            </td>
                            <td>
                                <mat-form-field>
                                    <span class="mat-form-field-label-wrapper"
                                        ><!----><label
                                            class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                            >Scope of Work Change (Min)</label
                                        ></span
                                    >
                                    <span class="dollarSign"> $ </span
                                    ><input
                                        matInput
                                        class="consultantLiabilityField"
                                        formControlName="ScopeofWorkChangeMin"
                                    />
                                </mat-form-field>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <mat-form-field>
                                    <span class="mat-form-field-label-wrapper"
                                        ><!----><label
                                            class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                            >Consultant Fee Reimbursed</label
                                        ></span
                                    >
                                    <span class="dollarSign"> $ </span
                                    ><input
                                        matInput
                                        class="consultantLiabilityField"
                                        formControlName="ConsultantFeeReimbursed"
                                    />
                                </mat-form-field>
                            </td>
                            <td>
                                <mat-form-field>
                                    <span class="mat-form-field-label-wrapper"
                                        ><!----><label
                                            class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                            >Draw Request (Min)</label
                                        ></span
                                    >
                                    <span class="dollarSign"> $ </span
                                    ><input
                                        matInput
                                        class="consultantLiabilityField"
                                        formControlName="DrawRequestMin"
                                    />
                                </mat-form-field>
                            </td>
                            <td>
                                <mat-form-field>
                                    <span class="mat-form-field-label-wrapper"
                                        ><!----><label
                                            class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                            >Scope of Work Change (Max)</label
                                        ></span
                                    >
                                    <span class="dollarSign"> $ </span
                                    ><input
                                        matInput
                                        class="consultantLiabilityField"
                                        formControlName="ScopeofWorkChangeMax"
                                    />
                                </mat-form-field>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <mat-form-field>
                                    <span class="mat-form-field-label-wrapper"
                                        ><!----><label
                                            class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                            >Estimate Below 100k</label
                                        ></span
                                    >
                                    <span class="dollarSign"> $ </span
                                    ><input
                                        matInput
                                        class="consultantLiabilityField"
                                        formControlName="EstimateBelow100k"
                                    />
                                </mat-form-field>
                            </td>
                            <td>
                                <mat-form-field>
                                    <span class="mat-form-field-label-wrapper"
                                        ><!----><label
                                            class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                            >Draw Request (Max)</label
                                        ></span
                                    >
                                    <span class="dollarSign"> $ </span
                                    ><input
                                        matInput
                                        class="consultantLiabilityField"
                                        formControlName="DrawRequestMax"
                                    />
                                </mat-form-field>
                            </td>
                            <td>
                                <mat-form-field>
                                    <span class="mat-form-field-label-wrapper"
                                        ><!----><label
                                            class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                            >Multi-Family Dwellin</label
                                        ></span
                                    >
                                    <span class="dollarSign"> $ </span
                                    ><input
                                        matInput
                                        class="consultantLiabilityField"
                                        formControlName="MultiFamilyDwelling"
                                    />
                                </mat-form-field>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <mat-form-field>
                                    <span class="mat-form-field-label-wrapper"
                                        ><!----><label
                                            class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                            >Estimate Between 100k-150k</label
                                        ></span
                                    >
                                    <span class="dollarSign"> $ </span
                                    ><input
                                        matInput
                                        class="consultantLiabilityField"
                                        formControlName="EstimateBetween100k150k"
                                    />
                                </mat-form-field>
                            </td>
                            <td>
                                <mat-form-field>
                                    <span class="mat-form-field-label-wrapper"
                                        ><!----><label
                                            class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                            >Self Help Draw</label
                                        ></span
                                    >
                                    <span class="dollarSign"> $ </span
                                    ><input
                                        matInput
                                        class="consultantLiabilityField"
                                        formControlName="SelfHelpDraw"
                                    />
                                </mat-form-field>
                            </td>
                            <td>
                                <mat-form-field>
                                    <span class="mat-form-field-label-wrapper"
                                        ><!----><label
                                            class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                            >Work Plan</label
                                        ></span
                                    >
                                    <span class="dollarSign"> $ </span
                                    ><input
                                        matInput
                                        class="consultantLiabilityField"
                                        formControlName="WorkPlan"
                                    />
                                </mat-form-field>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <mat-form-field>
                                    <span class="mat-form-field-label-wrapper"
                                        ><!----><label
                                            class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                            >Estimate Above 150k</label
                                        ></span
                                    >
                                    <span class="dollarSign"> $ </span
                                    ><input
                                        matInput
                                        class="consultantLiabilityField"
                                        formControlName="EstimateAbove150k"
                                    />
                                </mat-form-field>
                            </td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                    </tbody>
                </table>
            </mat-card-content>
            <br />
            <mat-card-actions>
                <button mat-raised-button type="submit" color="primary">
                    Save configuration
                </button>
                <button mat-raised-button type="reset" color="primary">
                    Cancel
                </button>
            </mat-card-actions>
        </form>
    </mat-card>
</div>
