export const locale = {
    lang: "en",
    data: {
        NAV: {
            APPLICATIONS: "Applications",
            DASHBOARD: {
                TITLE: "Dashboard",
                BADGE: "25",
            },
        },
    },
};
