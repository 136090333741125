import {
    Component,
    OnInit,
    ViewChild,
    ElementRef,
    HostListener,
    Inject,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { FormGroup, Validators, FormControl } from "@angular/forms";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { DataSource } from "@angular/cdk/table";
import { BorrowerService } from "../borrower/borrower.service";
import { PropertyService } from "../property/property.service";
import { SessionService } from "../service/session.service";
// import * as jsPDF from 'jspdf';
import * as moment from "moment";
import * as AWS from "aws-sdk/global";
import * as S3 from "aws-sdk/clients/s3";
// import { url } from 'inspector';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import {
    DomSanitizer,
    SafeResourceUrl,
    SafeUrl,
} from "@angular/platform-browser";
import { Observable, throwError } from "rxjs";
import { map, catchError, retry } from "rxjs/operators";
import { bool } from "aws-sdk/clients/signer";
import { constant } from "lodash";
export interface UserData {
    id: string;
    name: string;
    email: string;
    lastLogin: Date;
}
const COLORS: string[] = [
    "maroon",
    "red",
    "orange",
    "yellow",
    "olive",
    "green",
    "purple",
    "fuchsia",
    "lime",
    "teal",
    "aqua",
    "blue",
    "navy",
    "black",
    "gray",
];
const NAMES: string[] = [
    "Maia",
    "Asher",
    "Olivia",
    "Atticus",
    "Amelia",
    "Jack",
    "Charlotte",
    "Theodore",
    "Isla",
    "Oliver",
    "Isabella",
    "Jasper",
    "Cora",
    "Levi",
    "Violet",
    "Arthur",
    "Mia",
    "Thomas",
    "Elizabeth",
];

@Component({
    selector: "app-property-details",
    templateUrl: "./property-details.component.html",
    styleUrls: ["./property-details.component.scss"],
})
export class PropertyDetailsComponent implements OnInit {
    public editorValue: string = "";
    baseurl = this.sessionService.endpoint;
    displayedColumns: string[] = ["id", "name", "email", "lstLogin"];
    displayedColumns1: string[] = ["FirstName", "email", "phone", "lstLogin"];
    displayedColumns2: string[] = [
        "FirstName",
        "email",
        "phone",
        "estimateStatus",
    ];
    displayedColumns3: string[] = [
        "FirstName",
        "submissionStatus",
        "boReviewStatus",
        "coReviewStatus",
    ];
    dataSource: MatTableDataSource<UserData>;
    mobnumPattern = "^(d{3})sd{3}-d{4}";
    add_property = new FormGroup({});
    add_drawAdministrator = new FormGroup({
        drawAdministrator: new FormControl(null, [Validators.required]),
        daemail: new FormControl(null, [ Validators.email,Validators.maxLength(40),Validators.minLength(5)]),
        dafname: new FormControl(null, [Validators.maxLength(30),Validators.minLength(3)]),
        dalname: new FormControl(null, [Validators.maxLength(30),Validators.minLength(3)]),
        daphone_no: new FormControl(null,[]),
        daId: new FormControl(null),
        lender: new FormControl(null, [Validators.required]),
    });

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild("content") content: ElementRef;
    @ViewChild("ConsultantAgreement") ConsultantAgreement: ElementRef;
    @ViewChild("ConsultantLiability") ConsultantLiability: ElementRef;
    @ViewChild("IdentityOfInterest") IdentityOfInterest: ElementRef;
    @ViewChild("CertificationLetter") CertificationLetter: ElementRef;
    isList: boolean = true;
    showAdditionalDraw = false;
    showAdditionalOrder = false;
    isAdd: boolean = false;
    isEdit: boolean = false;
    isGClistEmpty: boolean = false;
    submitted: boolean = false;
    showAddFormDA: boolean = false;
    showLetStartAndTopBtn: boolean = false;
    lenderDrawAdminControlDisable: boolean = false;
    selectedIndex: any = 0;
    selectedBorrowerIndex: any = 0;
    favoriteSeason: string;
    propertyId: any = 0;
    loading = false;
    loadingGC = false;
    boTab_0 = { Active: false, tabStatus: "Not Completed" };
    boTab_1 = { Active: false, tabStatus: "Not Completed" };
    boTab_2 = { Active: false, tabStatus: "Not Completed" };
    boTab_3 = { Active: false, tabStatus: "Not Completed" };
    boTab_4 = { Active: false, tabStatus: "Not Completed" };
    boTab_5 = { Active: false, tabStatus: "Not Completed" };
    boTab_6 = { Active: false, tabStatus: "Not Completed" };
    propertyDetails: any = {};
    sorDetails: any = {};
    LoanNumber: any = "";
    userList: any = {};
    GCList: any = {};
    borrowerDetails: any = {};
    consultantDetails: any = {};
    LoanProcessorDetails: any = [];
    LODetails: any = {};
    DADetails: any = {};
    PreviousDrawShow: boolean = false;
    CurrentDrawShow: boolean = false;
    submittedBid = 0;
    GCDetailsList: any = {};
    RealtorDetails: any = {};
    LenderDetails: any = {};
    fhaconsultantagreement: any = {};
    fhaliabilityagreement: any = {};
    add_conAgreement: any;
    userDetail: any = {};
    docType = "SignedDocOrConstrutionPlan";
    propertyDetailsShow: boolean = false;
    DrawRequestList: any = [];
    ChangeOrderList: any = [];
    draw_administrator_list: any = {};
    filter_draw_administrator_list: any = [];
    propertyDocumentList: any = [];
    propertyDrawDocumentList: any = [];
    settings: any;
    isShowMapSection: boolean = false;
    resLoanProcessorIds: any = {};
    propertyImagesData: any[] = [];
    drawImagesData: any[] = [];
    newPropertyImagesData: any = [];
    newDrawImagesData: any = [];
    drawadministrator_list: any = {};
    lender_list: any = {};
    lender_filter_list: any = {};
    signedEnvelopesList: any = [];
    allSignedDoc: any = [];
    url: SafeResourceUrl;
    shopGoToTopBtn: boolean = false;
    hideBackPropBtn: boolean = false;
    copyImagePath: any = "assets/icons/pngIcon/copy.png";
    editImagePath: any = "assets/icons/pngIcon/edit.png";
    deleteImagePath: any = "assets/icons/pngIcon/delete.png";
    maskPhoneFormat: any = '(000) 000-0000';
    constructor(
        @Inject(ActivatedRoute) private route: ActivatedRoute,
        private _bservice: PropertyService,
        private sessionService: SessionService,
        private myElement: ElementRef,
        @Inject(Router) private router: Router,
        private _boservice: BorrowerService,
        private http: HttpClient,
        @Inject(DomSanitizer) private sanitizer: DomSanitizer
    ) {}

    ngOnInit() {
        var propertyId = this.route.snapshot.paramMap.get("propertyId");
        this.propertyId = propertyId;
        this.selectedIndex = this.route.snapshot.paramMap.get("selectedIndex")
            ? this.route.snapshot.paramMap.get("selectedIndex")
            : 0;

        var data = {
            EmailId: "",
            PageSize: 100000,
            LastEvaluatedKey: 0,
            UserType: this.sessionService.userType[4].Value,
        };
        this._boservice.GetSetting(data).subscribe((response) => {
            if (response != null) {
                this.settings = response;
                this.getSignedEnvelopes(propertyId);
            }
        });
        // if(this.sessionService.onlyThisProperty)
        // {
        //     this.hideBackPropBtn = true;
        //     this.sessionService.onlyThisProperty = false;
        // }
        this.getLoanNumber(propertyId);
        this.getPropertyDetails(propertyId);
        this.GetConsultantAgreement(propertyId);
        this.getFhaConsultantAgreementDetails(propertyId);
        this.getFhaConsultantLiabilityDetails(propertyId);
        this.GetDrawRequestList(propertyId);
        this.getPropertyDocument(this.propertyId);
        this.getGCSOR(propertyId);
        this.GetLenderUserList();
        this.GetDrawAdministratorUserList();
        this.filterDA();
        var LastLoginDateTime = this.sessionService.get("LastLoginDateTime");

        if (this.sessionService.get("userInfo") != undefined) {
            this.userDetail = JSON.parse(this.sessionService.get("userInfo"));
            if (
                (this.userDetail.LastLoginDateTime != null &&
                    this.userDetail.LastLoginDateTime != "" &&
                    this.userDetail.LastLoginDateTime != undefined) ||
                (LastLoginDateTime != null &&
                    LastLoginDateTime != "" &&
                    LastLoginDateTime != undefined)
            ) {
                this.userDetail.isUserFirstLogin = false;
            } else {
                this.userDetail.isUserFirstLogin = true;
            }

            if (
                this.userDetail.isUserFirstLogin == true &&
                this.userDetail.UserType == "Borrower"
            ) {
                this.router.navigate(["/property-details", this.propertyId, 0]);
            }
            if (this.userDetail.UserType == "Borrower") {
                this.selectedBorrowerIndex = this.route.snapshot.paramMap.get(
                    "selectedIndex"
                )
                    ? this.route.snapshot.paramMap.get("selectedIndex")
                    : 0;
            }
        }
    }

    editDraftSOR() {
        if (
            confirm(
                "You have selected to make edits to the previously submitted Draft SOR. After you make edits you will need to resend the draft for the borrowers review. The contractor will need to make corrections to their estimate if an estimate has already been submitted. After your edits have been made please use the Resend option to send to the borrower and contractor for review."
            )
        ) {
            this.router.navigate(["/construction-detail", this.propertyId]);
        }
    }

    reloadPage() {
        window.location.reload();
    }

    deleteDrawRequest(drawId, docName, propId) {
        let tempPropertyData = this.propertyDetails;

        if (
            tempPropertyData.InitialDraws != null &&
            tempPropertyData.InitialDraws.length > 0
        ) {
            let tempDrawArray = [];
            let deleteDrawArray: any;
            let lastFlag = false;
            for (let j = 0; j < tempPropertyData.InitialDraws.length; j++) {
                if (j == tempPropertyData.InitialDraws.length - 1) {
                    lastFlag = true;
                }
                if (tempPropertyData.InitialDraws[j].DrawId == drawId) {
                    deleteDrawArray = tempPropertyData.InitialDraws[j];
                    continue;
                }
                let drawItem = {
                    Status: tempPropertyData.InitialDraws[j].Status,
                    UpdatedBy: tempPropertyData.InitialDraws[j].UpdatedBy,
                    DrawId: tempPropertyData.InitialDraws[j].DrawId,
                };

                tempDrawArray.push(drawItem);
            }
            if (lastFlag) {
                tempPropertyData.InitialDraws = tempDrawArray;
                var propertyData = {
                    Id: this.propertyId,
                    InitialDraws: tempDrawArray,
                    UpdateDate: "today",
                    UpdatedBy: this.userDetail.Id,
                };

                if (
                    confirm(
                        "Please confirm you would like to delete this draw. Deleting this draw will permanently remove all data of this draw."
                    )
                ) {
                    if (deleteDrawArray.Status == "approved") {
                        this._bservice.getDrawRequestById(drawId).subscribe(
                            (dresponse) => {
                                this.loading = true;

                                this._bservice
                                    .DrawRequestDelete(drawId)
                                    .subscribe(
                                        (response) => {
                                            setTimeout(() => {
                                                this._bservice
                                                    .UpdatePropertyDraw(
                                                        propertyData
                                                    )
                                                    .subscribe(
                                                        (response1) => {
                                                            setTimeout(() => {
                                                                let req = {
                                                                    propertyId:
                                                                        propId,
                                                                    documentName:
                                                                        docName,
                                                                };
                                                                this._bservice
                                                                    .getSignedDraw(
                                                                        req
                                                                    )
                                                                    .subscribe(
                                                                        (
                                                                            dresponse
                                                                        ) => {
                                                                            if (
                                                                                dresponse !=
                                                                                    null &&
                                                                                dresponse
                                                                                    .Items
                                                                                    .length >
                                                                                    0
                                                                            ) {
                                                                                let drawDocIt =
                                                                                    dresponse
                                                                                        .Items[0]
                                                                                        .Id;

                                                                                this._bservice
                                                                                    .deleteSignedDraw(
                                                                                        drawDocIt
                                                                                    )
                                                                                    .subscribe(
                                                                                        (
                                                                                            rresponse
                                                                                        ) => {
                                                                                            this.sessionService.notifier.notify(
                                                                                                "success",
                                                                                                "Draw Request has been deleted successfully."
                                                                                            );
                                                                                            location.reload();
                                                                                            this.loading =
                                                                                                false;
                                                                                        },
                                                                                        (
                                                                                            error
                                                                                        ) => {
                                                                                            this.sessionService.notifier.notify(
                                                                                                "error",
                                                                                                error
                                                                                                    .error
                                                                                                    .message
                                                                                            );
                                                                                            this.loading =
                                                                                                false;
                                                                                        }
                                                                                    );
                                                                            } else {
                                                                                this.sessionService.notifier.notify(
                                                                                    "success",
                                                                                    "Draw Request has been deleted successfully."
                                                                                );
                                                                                location.reload();
                                                                                this.loading =
                                                                                    false;
                                                                            }
                                                                        },
                                                                        (
                                                                            error
                                                                        ) => {
                                                                            this.sessionService.notifier.notify(
                                                                                "error",
                                                                                error
                                                                                    .error
                                                                                    .message
                                                                            );
                                                                            this.loading =
                                                                                false;
                                                                        }
                                                                    );
                                                            }, 5000);
                                                        },
                                                        (error) => {
                                                            this.sessionService.notifier.notify(
                                                                "error",
                                                                error.error
                                                                    .message
                                                            );
                                                            this.loading =
                                                                false;
                                                        }
                                                    );
                                            }, 5000);
                                        },
                                        (error) => {
                                            this.sessionService.notifier.notify(
                                                "error",
                                                error.error.message
                                            );
                                            this.loading = false;
                                        }
                                    );
                            },
                            (error) => {
                                this.sessionService.notifier.notify(
                                    "error",
                                    error.error.message
                                );
                                this.loading = false;
                            }
                        );
                    } else {
                        this.loading = true;

                        this._bservice.DrawRequestDelete(drawId).subscribe(
                            (response) => {
                                setTimeout(() => {
                                    this._bservice
                                        .UpdatePropertyDraw(propertyData)
                                        .subscribe(
                                            (response1) => {
                                                setTimeout(() => {
                                                    this.sessionService.notifier.notify(
                                                        "success",
                                                        "Draw Request has been deleted successfully."
                                                    );
                                                    location.reload();
                                                    this.loading = false;
                                                }, 5000);
                                            },
                                            (error) => {
                                                this.sessionService.notifier.notify(
                                                    "error",
                                                    error.error.message
                                                );
                                                this.loading = false;
                                            }
                                        );
                                }, 5000);
                            },
                            (error) => {
                                this.sessionService.notifier.notify(
                                    "error",
                                    error.error.message
                                );
                                this.loading = false;
                            }
                        );
                    }
                }
            }
        }
    }

    getLoanNumber(pId) {
        var data = {
            EmailId: "",
            PropertyId: pId,
            PageSize: 100000,
            LastEvaluatedKey: 0,
            UserType: this.sessionService.userType[1].Value,
        };
        this._boservice.GetSorDetails(data).subscribe((response) => {
            if (response != null) {
                if (response.Items.length > 0) {
                    this.LoanNumber = response.Items[0].LoanNo;
                }
            }
        });
    }
    markActiveBorrowerStep(stepIndex) {
        var data = {
            Id: this.propertyId,
            BorrowerActiveStep: stepIndex,
        };
        this._bservice.saveBorrowerActiveStep(data).subscribe((response) => {
            this.userDetail.isUserFirstLogin = false;
            this.changeBorrowerSelectedIndex(stepIndex - 1);
            this.getPropertyDetails(this.propertyId);
        });
    }
    changeBorrowerSelectedIndex(index) {
        this.selectedBorrowerIndex = index;
    }

    keyDownPhoneFormat($event) {
        var key = $event.keyCode;

        if (key == 91 || (15 < key && key < 19) || (37 <= key && key <= 40)) {
            return;
        }
        this.changePhoneFormat($event);
    }
    scrollSmooth(tagElement) {
        let el = document.getElementById(tagElement);
        el.scrollIntoView();
        this.shopGoToTopBtn = true;
    }
    goBackToTop(tagElement) {
        let el = document.getElementById(tagElement);
        el.scrollIntoView();
        this.shopGoToTopBtn = false;
    }
    changePhoneFormat($event) {
        var getVal = $event.target.value;
        var tel = getVal.replace(/[^0-9]/g, "");
        $event.target.value = this.changeFilter(tel);
    }
    changeFilter(tel) {
        if (!tel) {
            return "";
        }

        var value = tel.toString().trim().replace(/^\+/, "");

        if (value.match(/[^0-9]/)) {
            return tel;
        }

        var country, city, number;

        switch (value.length) {
            case 1:
            case 2:
            case 3:
                city = value;
                break;

            default:
                city = value.slice(0, 3);
                number = value.slice(3);
        }

        if (number) {
            if (number.length > 3) {
                number = number.slice(0, 3) + "-" + number.slice(3, 7);
            } else {
                number = number;
            }

            return ("(" + city + ") " + number).trim();
        } else {
            return "(" + city;
        }
    }

    deleteSignedDoc(docName, propId) {
        let req = { propertyId: propId, documentName: docName };
        this._bservice.getSignedDraw(req).subscribe(
            (dresponse) => {
                if (dresponse != null && dresponse.Items.length > 0) {
                    let drawDocIt = dresponse.Items[0].Id;
                    this._bservice.deleteSignedDraw(drawDocIt).subscribe(
                        (rresponse) => {
                            this.sessionService.notifier.notify(
                                "success",
                                "Draw Request has been deleted successfully."
                            );
                            location.reload();
                            this.loading = false;
                        },
                        (error) => {
                            this.sessionService.notifier.notify(
                                "error",
                                error.error.message
                            );
                            this.loading = false;
                        }
                    );
                }
            },
            (error) => {
                this.sessionService.notifier.notify(
                    "error",
                    error.error.message
                );
                this.loading = false;
            }
        );
    }
    getSignedEnvelopes(propertyId) {
        this._bservice.GetPropertySignEnvelopesList(propertyId).subscribe(
            (response) => {
                this.allSignedDoc = [];
                this.signedEnvelopesList = [];

                if (response != null) {
                    if (response.Items.length > 0) {
                        response.Items.sort(function (a, b) {
                            return a.Id - b.Id;
                        });

                        let isAlreadyDocIdPropIdEnvType = [];
                        for (let i = 0; i < response.Items.length; i++) {
                            if (
                                isAlreadyDocIdPropIdEnvType.indexOf(
                                    response.Items[i].PropertyId +
                                        "_" +
                                        response.Items[i].DocumentId +
                                        "_" +
                                        response.Items[i].EnvelopeType +
                                        "_" +
                                        response.Items[i].DocumentName.replace(
                                            " ",
                                            "_"
                                        )
                                ) !== -1
                            ) {
                                continue;
                            }

                            this.allSignedDoc.push(response.Items[i]);
                            if (
                                response.Items[i].EnvelopeStatus == "completed"
                            ) {
                                this.signedEnvelopesList.push(
                                    response.Items[i]
                                );
                                isAlreadyDocIdPropIdEnvType.push(
                                    response.Items[i].PropertyId +
                                        "_" +
                                        response.Items[i].DocumentId +
                                        "_" +
                                        response.Items[i].EnvelopeType +
                                        "_" +
                                        response.Items[i].DocumentName.replace(
                                            " ",
                                            "_"
                                        )
                                );

                                if (response.Items[i].UploadedStatus != 1) {
                                    let envCreatedTime =
                                        response.Items[i].UpdateDate;
                                    let envTimeArray =
                                        envCreatedTime.split(".");
                                    let envIndex = envTimeArray[0];
                                    let envTime = envIndex.replace("T", " ");
                                    envTime = new Date(envTime).getTime();
                                    var today = new Date();
                                    let currentTime = new Date(
                                        new Date().setDate(today.getDate() - 30)
                                    ).getTime();

                                    if (currentTime < envTime) {
                                        setTimeout(() => {
                                            let shouldOpen = false;
                                            this.generateCompletedSignedEnvelope(
                                                response.Items[i],
                                                shouldOpen
                                            );
                                        }, i * 500);
                                    }
                                }
                            } else {
                                isAlreadyDocIdPropIdEnvType.push(
                                    response.Items[i].PropertyId +
                                        "_" +
                                        response.Items[i].DocumentId +
                                        "_" +
                                        response.Items[i].EnvelopeType +
                                        "_" +
                                        response.Items[i].DocumentName.replace(
                                            " ",
                                            "_"
                                        )
                                );
                                let envCreatedTime =
                                    response.Items[i].UpdateDate;
                                let envTimeArray = envCreatedTime.split(".");
                                let envIndex = envTimeArray[0];
                                let envTime = envIndex.replace("T", " ");

                                envTime = new Date(envTime).getTime();

                                var today = new Date();

                                let prevDate = new Date(
                                    new Date().setDate(today.getDate() - 30)
                                );
                                let currentTime = new Date(prevDate).getTime();

                                if (currentTime < envTime) {
                                    setTimeout(() => {
                                        this._bservice
                                            .GetCompletedsignedEnvelope(
                                                response.Items[i]
                                            )
                                            .subscribe((response) => {});
                                    }, i * 500);
                                }
                            }
                        }
                    }

                    this.signedEnvelopesList.sort(function (a, b) {
                        return a.Id - b.Id;
                    });
                }
            },
            (error) => {}
        );
    }

    GetPermitsCertificationPdf(PropertyId) {
        this.loading = true;
        var pData = { PropertyId: PropertyId };
        this._bservice
            .GetPermitCertificationsPDF(pData)
            .subscribe((response) => {
                if (response != null) {
                    window.open(this.baseurl + response, "_blank");
                } else {
                    alert("Pdf Not available.");
                }
                this.loading = false;
            });
    }
    generateCompletedSignedEnvelope(envelopeData, shouldOpen) {
        this._bservice.GetPropertySignDocById(envelopeData.Id).subscribe(
            (response) => {
                if (response != null) {
                    var responseData = response;
                    var url = encodeURI(
                        this.baseurl +
                            "pdf-files/" +
                            envelopeData.PropertyId +
                            "_" +
                            envelopeData.EnvelopeId +
                            "_" +
                            envelopeData.DocumentName +
                            ".pdf"
                    ).replace("#", "%23");

                    let tempFileName = url.split("/");
                    let length = tempFileName.length;
                    const fileName1 = tempFileName[length - 1];
                    let fileName1Array = fileName1.split("_");
                    let length1 = fileName1Array.length;
                    const fileName = fileName1Array[length1 - 1];
                    let EnvelopeUrl =
                        "property-signed-envelope/" +
                        envelopeData.PropertyId +
                        "/envelopes/" +
                        envelopeData.EnvelopeType +
                        "/" +
                        fileName;

                    this.get_s3_signed_url(
                        EnvelopeUrl,
                        shouldOpen,
                        envelopeData
                    );
                }
            },
            (error) => {}
        );
    }

    public getDoc(url: string): Observable<SafeResourceUrl> {
        const headers = new HttpHeaders().set(
            "Access-Control-Allow-Origin",
            "*"
        );
        return this.http.get(url, { responseType: "blob", headers }).pipe(
            map((x) => {
                return x;
            })
        );
    }

    saveToSignedEnvelopesInS3(
        envelopeData,
        fileInput: any,
        fileUrl,
        shouldOpen
    ) {
        const bucketName = this.settings.AwsS3BucketName
            ? this.settings.AwsS3BucketName
            : "";
        const accessKeyId = this.settings.AwsAccessKeyId
            ? this.settings.AwsAccessKeyId
            : "";
        const secretAccessKey = this.settings.AwsSecretAccessKey
            ? this.settings.AwsSecretAccessKey
            : "";
        const region = this.settings.AwsConfigRegion
            ? this.settings.AwsConfigRegion
            : "";

        const bucket = new S3({
            accessKeyId: accessKeyId,
            secretAccessKey: secretAccessKey,
            region: region,
        });

        let tempFileName = fileUrl.split("/");
        let length = tempFileName.length;
        const propertyid = this.propertyId;
        const fileName1 = tempFileName[length - 1];
        let fileName1Array = fileName1.split("_");
        let length1 = fileName1Array.length;
        const fileName = fileName1Array[length1 - 1];

        const params = {
            Bucket: bucketName,
            Key:
                "property-signed-envelope/" +
                propertyid +
                "/envelopes/" +
                envelopeData.EnvelopeType +
                "/" +
                fileName,
            Body: fileInput,
            StorageClass: "STANDARD",
            ACL: "bucket-owner-full-control",
            ContentType: "application/pdf",
        };
        const _this = this;

        bucket.upload(params, function (err: any, data: any) {
            if (err) {
                alert(
                    "There was an error uploading your file: " +
                        JSON.stringify(err)
                );
                console.log("There was an error uploading your file: ", err);
                return false;
            } else {
                _this.updateSignedDocument(
                    envelopeData,
                    fileName,
                    fileUrl,
                    shouldOpen
                );
                return true;
            }
        });
    }
    updateSignedDocument(envelopeData, fileName, fileUrl, shouldOpen) {
        const bucketName = this.settings.AwsS3BucketName
            ? this.settings.AwsS3BucketName
            : "";
        const region = this.settings.AwsConfigRegion
            ? this.settings.AwsConfigRegion
            : "";
        let EnvelopeUrl =
            "property-signed-envelope/" +
            envelopeData.PropertyId +
            "/envelopes/" +
            envelopeData.EnvelopeType +
            "/" +
            fileName;
        envelopeData.UploadedStatus = 1;
        envelopeData.EnvelopeUrl = EnvelopeUrl;

        this._bservice.updatePropertySignEnvelopes(envelopeData).subscribe(
            (response) => {
                this.loading = false;

                this.get_s3_signed_url(EnvelopeUrl, shouldOpen, envelopeData);
            },
            (error) => {}
        );
    }

    filterDA() {
        var lenderId = this.add_drawAdministrator.value.lender;

        if (lenderId != null && lenderId != "" && lenderId != undefined) {
            this.filter_draw_administrator_list = [];

            for (let i = 0; i < this.drawadministrator_list.length; i++) {
                if (lenderId == this.drawadministrator_list[i].LenderId) {
                    this.filter_draw_administrator_list.push(
                        this.drawadministrator_list[i]
                    );
                }
            }

            if (this.userDetail.UserType == "Draw Administrator") {
                this._bservice.GetUserDetailsList(this.userDetail.Id).subscribe(
                    (DAResponse) => {
                        if (DAResponse != null) {
                            let drawAdministratorList = [];
                            drawAdministratorList.push(DAResponse);
                            this.filter_draw_administrator_list =
                                drawAdministratorList;
                            this.add_drawAdministrator.controls[
                                "drawAdministrator"
                            ].setValue(String(this.userDetail.Id));
                        }
                    },
                    (error) => {}
                );
            }
        } else {
            this.filter_draw_administrator_list = [];
        }
    }

    GetDrawAdministratorUserList() {
        this.loading = true;
        var data = {
            EmailId: "",
            PageSize: 100000,
            LastEvaluatedKey: 0,
            UserType: this.sessionService.userType[7].Value,
        };
        this._bservice.GetBorrowerUserList(data).subscribe(
            (response) => {
                if (response != null) {
                    this.drawadministrator_list = response.Items;
                }
            },
            (error) => {}
        );
    }
    GetLenderUserList() {
        this.loading = true;
        var data = {
            EmailId: "",
            PageSize: 100000,
            LastEvaluatedKey: 0,
            UserType: this.sessionService.userType[4].Value,
        };
        this._bservice.GetLenderList(data).subscribe(
            (response) => {
                if (response != null) {
                    response.Items.sort(function (a, b) {
                        if (
                            a.Name.trim().toLowerCase() <
                            b.Name.trim().toLowerCase()
                        ) {
                            return -1;
                        }
                        if (
                            a.Name.trim().toLowerCase() >
                            b.Name.trim().toLowerCase()
                        ) {
                            return 1;
                        }
                        return 0;
                    });
                    this.lender_list = response.Items;
                    this.lender_filter_list = response.Items;
                    this.lender_list.sort(function (a, b) {
                        if (
                            a.Name.trim().toLowerCase() <
                            b.Name.trim().toLowerCase()
                        ) {
                            return -1;
                        }
                        if (
                            a.Name.trim().toLowerCase() >
                            b.Name.trim().toLowerCase()
                        ) {
                            return 1;
                        }
                        return 0;
                    });
                    this.lender_filter_list.sort(function (a, b) {
                        if (
                            a.Name.trim().toLowerCase() <
                            b.Name.trim().toLowerCase()
                        ) {
                            return -1;
                        }
                        if (
                            a.Name.trim().toLowerCase() >
                            b.Name.trim().toLowerCase()
                        ) {
                            return 1;
                        }
                        return 0;
                    });
                    this.loading = false;
                }
            },
            (error) => {}
        );
    }
    onFilterLenders(value) {
        const target = value.target as HTMLInputElement;
        const targetvalue = target.value;
        this.lender_filter_list = this.searchFilterLender(targetvalue);
    }
    searchFilterLender(value: string) {
        let filter = value.toLowerCase();
        return this.lender_list.filter((option) =>
            option.Name.toLowerCase().startsWith(filter)
        );
    }
    hideAddDAForm() {
        this.showAddFormDA = false;
    }

    showAddFormDAFunction(daid = "") {
        this.showAddFormDA = true;
        let drawadministrator = this.drawadministrator_list.filter((item) => {
            return daid != null && item.Id == daid;
        });
        if (daid) {
            this.add_drawAdministrator.controls["daemail"].setValue(
                drawadministrator[0].EmailId
            );
            this.add_drawAdministrator.controls["dafname"].setValue(
                drawadministrator[0].FirstName
            );
            this.add_drawAdministrator.controls["dalname"].setValue(
                drawadministrator[0].LastName
            );
            this.add_drawAdministrator.controls["daphone_no"].setValue(
                drawadministrator[0].Phone
            );
            this.add_drawAdministrator.controls["lender"].setValue(
                drawadministrator[0].LenderId
            );
            this.lenderDrawAdminControlDisable = false;
            this.add_drawAdministrator.controls["daId"].setValue(
                drawadministrator[0].Id
            );
            return;
        } else {
            this.add_drawAdministrator.controls["daemail"].setValue("");
            this.add_drawAdministrator.controls["dafname"].setValue("");
            this.add_drawAdministrator.controls["dalname"].setValue("");
            this.add_drawAdministrator.controls["daphone_no"].setValue("");
            this.add_drawAdministrator.controls["lender"].setValue("");
            this.lenderDrawAdminControlDisable = false;
            this.add_drawAdministrator.controls["daId"].setValue(null);
            return;
        }
    }
    generatePassword() {
        var password = Math.random().toString(36).slice(-8);
        return password;
    }
    SaveDrawAdministratorRecord() {
        var formData = this.add_drawAdministrator.value;
        var sendPassword = this.generatePassword();
        if (
            formData.drawAdministrator != "" &&
            formData.drawAdministrator != null &&
            formData.drawAdministrator != undefined
        ) {
            if (
                (formData.daId == null || formData.daId == "") &&
                formData.drawAdministrator == "other"
            ) {
                if (formData.daemail != null && formData.daemail != "") {
                    var data = {
                        EmailId: formData.daemail,
                        Password: sendPassword,
                        UserType: this.sessionService.userType[7].Value,
                        FirstName: formData.dafname,
                        LastName: formData.dalname,
                        Status: "Active",
                        Phone: formData.daphone_no,
                        Address1: "",
                        Addresss2: "",
                        City: "",
                        State: "",
                        Zip: "",
                        Country: "",
                        CreatedBy: this.userDetail.EmailId,
                        LenderId: formData.lender,
                    };
                    this._bservice.AddBorrower(data).subscribe(
                        (daresponse) => {
                            var pdata = this.propertyDetails;
                            pdata.DrawAdministratorId = daresponse.Id;
                            this._bservice.UpdateProperty(pdata).subscribe(
                                (response1) => {
                                    var dataForMail = {
                                        DrawAdministratorId: daresponse.Id,
                                        Id: pdata.Id,
                                        Status: pdata.Status,
                                    };
                                    this._bservice
                                        .SendAssignmentMailToDa(dataForMail)
                                        .subscribe(
                                            (response) => {},
                                            (error) => {}
                                        );
                                    this.getPropertyDetails(this.propertyId);
                                    this.sessionService.notifier.notify(
                                        "success",
                                        "Draw Administrator added successfully."
                                    );
                                    this.showAddFormDA = false;
                                    this.loading = false;
                                },
                                (error) => {}
                            );
                        },
                        (error) => {
                            this._bservice
                                .GetUserByEmail(formData.daemail)
                                .subscribe(
                                    (response) => {
                                        if (response != null) {
                                            if (
                                                response.UserType ==
                                                "Draw Administrator"
                                            ) {
                                                var pdata =
                                                    this.propertyDetails;

                                                pdata.DrawAdministratorId =
                                                    response.Id;
                                                this._bservice
                                                    .UpdateProperty(pdata)
                                                    .subscribe(
                                                        (response1) => {
                                                            var dataForMail = {
                                                                DrawAdministratorId:
                                                                    response.Id,
                                                                Id: pdata.Id,
                                                                Status: pdata.Status,
                                                            };
                                                            this._bservice
                                                                .SendAssignmentMailToDa(
                                                                    dataForMail
                                                                )
                                                                .subscribe(
                                                                    (
                                                                        response
                                                                    ) => {},
                                                                    (
                                                                        error
                                                                    ) => {}
                                                                );
                                                            this.getPropertyDetails(
                                                                this.propertyId
                                                            );
                                                            this.sessionService.notifier.notify(
                                                                "success",
                                                                "Draw Administrator added successfully."
                                                            );
                                                            this.showAddFormDA =
                                                                false;
                                                            this.loading =
                                                                false;
                                                        },
                                                        (error) => {}
                                                    );
                                            } else {
                                                this.sessionService.notifier.notify(
                                                    "error",
                                                    "EmailId already used to other user type. Please enter other email id for Draw Administrator."
                                                );
                                                this.loading = false;
                                            }
                                        }
                                    },
                                    (error) => {
                                        this.sessionService.notifier.notify(
                                            "error",
                                            error.error.message
                                        );
                                        this.loading = false;
                                    }
                                );
                        }
                    );
                } else {
                    var pdata = this.propertyDetails;
                    pdata.DrawAdministratorId = formData.daId;
                    this._bservice.UpdateProperty(pdata).subscribe(
                        (response1) => {
                            var dataForMail = {
                                DrawAdministratorId: formData.daId,
                                Id: pdata.Id,
                                Status: pdata.Status,
                            };
                            this._bservice
                                .SendAssignmentMailToDa(dataForMail)
                                .subscribe(
                                    (response) => {},
                                    (error) => {}
                                );
                            this.getPropertyDetails(this.propertyId);
                            this.sessionService.notifier.notify(
                                "success",
                                "Draw Administrator added successfully."
                            );
                            this.showAddFormDA = false;
                            this.loading = false;
                        },
                        (error) => {}
                    );
                }
            } else if (
                formData.daId != "" &&
                formData.daId != null &&
                formData.daId != undefined &&
                formData.drawAdministrator == "other"
            ) {
                var pdata = this.propertyDetails;
                pdata.DrawAdministratorId = formData.daId;
                this._bservice.UpdateProperty(pdata).subscribe(
                    (response1) => {
                        var dataForMail = {
                            DrawAdministratorId: formData.daId,
                            Id: pdata.Id,
                            Status: pdata.Status,
                        };
                        this._bservice
                            .SendAssignmentMailToDa(dataForMail)
                            .subscribe(
                                (response) => {},
                                (error) => {}
                            );
                        this.getPropertyDetails(this.propertyId);
                        this.sessionService.notifier.notify(
                            "success",
                            "Draw Administrator added successfully."
                        );
                        this.showAddFormDA = false;
                        this.loading = false;
                    },
                    (error) => {}
                );
            } else {
                var pdata = this.propertyDetails;
                pdata.DrawAdministratorId = formData.drawAdministrator;
                this._bservice.UpdateProperty(pdata).subscribe(
                    (response1) => {
                        var dataForMail = {
                            DrawAdministratorId: pdata.DrawAdministratorId,
                            Id: pdata.Id,
                            Status: pdata.Status,
                        };
                        this._bservice
                            .SendAssignmentMailToDa(dataForMail)
                            .subscribe(
                                (response) => {},
                                (error) => {}
                            );
                        this.getPropertyDetails(this.propertyId);
                        this.sessionService.notifier.notify(
                            "success",
                            "Draw Administrator added successfully."
                        );
                        this.showAddFormDA = false;
                        this.loading = false;
                    },
                    (error) => {}
                );
            }
        } else {
            this.sessionService.notifier.notify(
                "error",
                "Please select Draw Administrator from List !!"
            );

            this.loading = false;
        }
    }
    blankDrawFormPdf(propertyId) {
        this.loading = true;
        var data = { PropertyId: propertyId };
        this._bservice.GetBlankDrawFormPdf(data).subscribe((response) => {
            if (response.PdfPath != null) {
                this.loading = false;
                let pdfUrl = this.baseurl + response.PdfPath;
                window.open(pdfUrl, "_blank");
            }
        });
    }

    editFinalSOR(propertyId, GCId) {
        this.router.navigate(["/edit-final-sor", propertyId, GCId]);
    }
    getPropertyDetails(propertyId) {
        this.loading = true;

        this._bservice.GetPropertyDetails(propertyId).subscribe(
            (response) => {
                if (response != null) {
                    this.propertyDetails = response;

                    if (
                        this.propertyDetails.Status ==
                            "Property File created" &&
                        (this.propertyDetails.BorrowerActiveStep == 1 ||
                            this.propertyDetails.BorrowerActiveStep == null ||
                            this.propertyDetails.BorrowerActiveStep ==
                                undefined ||
                            this.propertyDetails.BorrowerActiveStep == "")
                    ) {
                        this.selectedBorrowerIndex = 0;
                        this.boTab_0 = { Active: true, tabStatus: "Active" };
                        this.boTab_1 = { Active: true, tabStatus: "Pending" };
                        this.boTab_2 = { Active: true, tabStatus: "Pending" };
                        this.boTab_3 = {
                            Active: false,
                            tabStatus: "Not Completed",
                        };
                        this.boTab_4 = {
                            Active: false,
                            tabStatus: "Not Completed",
                        };
                        this.boTab_5 = {
                            Active: false,
                            tabStatus: "Not Completed",
                        };
                        this.boTab_6 = {
                            Active: false,
                            tabStatus: "Not Completed",
                        };
                    } else if (
                        this.propertyDetails.Status ==
                            "DRAFT SOR Sent to Borrower" &&
                        (this.propertyDetails.BorrowerActiveStep == 1 ||
                            this.propertyDetails.BorrowerActiveStep == null ||
                            this.propertyDetails.BorrowerActiveStep ==
                                undefined ||
                            this.propertyDetails.BorrowerActiveStep == "")
                    ) {
                        this.selectedBorrowerIndex = 0;
                        this.boTab_0 = { Active: true, tabStatus: "Active" };
                        this.boTab_1 = { Active: true, tabStatus: "Pending" };
                        this.boTab_2 = { Active: true, tabStatus: "Pending" };
                        this.boTab_3 = { Active: true, tabStatus: "Pending" };
                        this.boTab_4 = {
                            Active: false,
                            tabStatus: "Not Completed",
                        };
                        this.boTab_5 = {
                            Active: false,
                            tabStatus: "Not Completed",
                        };
                        this.boTab_6 = {
                            Active: false,
                            tabStatus: "Not Completed",
                        };
                    } else if (
                        this.propertyDetails.Status == "Revise" &&
                        (this.propertyDetails.BorrowerActiveStep == 1 ||
                            this.propertyDetails.BorrowerActiveStep == null ||
                            this.propertyDetails.BorrowerActiveStep ==
                                undefined ||
                            this.propertyDetails.BorrowerActiveStep == "")
                    ) {
                        this.selectedBorrowerIndex = 0;
                        this.boTab_0 = { Active: true, tabStatus: "Active" };
                        this.boTab_1 = { Active: true, tabStatus: "Pending" };
                        this.boTab_2 = { Active: true, tabStatus: "Pending" };
                        this.boTab_3 = {
                            Active: false,
                            tabStatus: "Not Completed",
                        };
                        this.boTab_4 = {
                            Active: false,
                            tabStatus: "Not Completed",
                        };
                        this.boTab_5 = {
                            Active: false,
                            tabStatus: "Not Completed",
                        };
                        this.boTab_6 = {
                            Active: false,
                            tabStatus: "Not Completed",
                        };
                    } else if (
                        this.propertyDetails.Status ==
                            "Property File created" &&
                        this.propertyDetails.BorrowerActiveStep == 2
                    ) {
                        this.selectedBorrowerIndex = 1;
                        this.boTab_0 = { Active: true, tabStatus: "Completed" };
                        this.boTab_1 = { Active: true, tabStatus: "Active" };
                        this.boTab_2 = { Active: true, tabStatus: "Pending" };
                        this.boTab_3 = {
                            Active: false,
                            tabStatus: "Not Completed",
                        };
                        this.boTab_4 = {
                            Active: false,
                            tabStatus: "Not Completed",
                        };
                        this.boTab_5 = {
                            Active: false,
                            tabStatus: "Not Completed",
                        };
                        this.boTab_6 = {
                            Active: false,
                            tabStatus: "Not Completed",
                        };
                    } else if (
                        this.propertyDetails.Status ==
                            "Property File created" &&
                        this.propertyDetails.BorrowerActiveStep == 2 &&
                        this.propertyDetails.InvitedGeneralContractors ==
                            null &&
                        this.propertyDetails.GeneralContractorId == null
                    ) {
                        this.selectedBorrowerIndex = 1;
                        this.boTab_0 = { Active: true, tabStatus: "Completed" };
                        this.boTab_1 = { Active: true, tabStatus: "Active" };
                        this.boTab_2 = { Active: true, tabStatus: "Pending" };
                        this.boTab_3 = {
                            Active: false,
                            tabStatus: "Not Completed",
                        };
                        this.boTab_4 = {
                            Active: false,
                            tabStatus: "Not Completed",
                        };
                        this.boTab_5 = {
                            Active: false,
                            tabStatus: "Not Completed",
                        };
                        this.boTab_6 = {
                            Active: false,
                            tabStatus: "Not Completed",
                        };
                    } else if (
                        this.propertyDetails.Status ==
                            "DRAFT SOR Sent to Borrower" &&
                        this.propertyDetails.BorrowerActiveStep == 2 &&
                        this.propertyDetails.InvitedGeneralContractors ==
                            null &&
                        this.propertyDetails.GeneralContractorId == null
                    ) {
                        this.selectedBorrowerIndex = 1;
                        this.boTab_0 = { Active: true, tabStatus: "Completed" };
                        this.boTab_1 = { Active: true, tabStatus: "Active" };
                        this.boTab_2 = { Active: true, tabStatus: "Pending" };
                        this.boTab_3 = { Active: true, tabStatus: "Pending" };
                        this.boTab_4 = {
                            Active: false,
                            tabStatus: "Not Completed",
                        };
                        this.boTab_5 = {
                            Active: false,
                            tabStatus: "Not Completed",
                        };
                        this.boTab_6 = {
                            Active: false,
                            tabStatus: "Not Completed",
                        };
                    } else if (
                        this.propertyDetails.Status == "Revise" &&
                        this.propertyDetails.BorrowerActiveStep == 2 &&
                        this.propertyDetails.InvitedGeneralContractors ==
                            null &&
                        this.propertyDetails.GeneralContractorId == null
                    ) {
                        this.selectedBorrowerIndex = 1;
                        this.boTab_0 = { Active: true, tabStatus: "Completed" };
                        this.boTab_1 = { Active: true, tabStatus: "Active" };
                        this.boTab_2 = { Active: true, tabStatus: "Pending" };
                        this.boTab_3 = {
                            Active: false,
                            tabStatus: "Not Completed",
                        };
                        this.boTab_4 = {
                            Active: false,
                            tabStatus: "Not Completed",
                        };
                        this.boTab_5 = {
                            Active: false,
                            tabStatus: "Not Completed",
                        };
                        this.boTab_6 = {
                            Active: false,
                            tabStatus: "Not Completed",
                        };
                    } else if (
                        this.propertyDetails.Status ==
                            "DRAFT SOR Sent to Borrower" &&
                        this.propertyDetails.BorrowerActiveStep == 2 &&
                        (this.propertyDetails.InvitedGeneralContractors !=
                            null ||
                            this.propertyDetails.GeneralContractorId != null)
                    ) {
                        this.selectedBorrowerIndex = 3;
                        this.boTab_0 = { Active: true, tabStatus: "Completed" };
                        this.boTab_1 = { Active: true, tabStatus: "Completed" };
                        this.boTab_2 = { Active: true, tabStatus: "Completed" };
                        this.boTab_3 = { Active: true, tabStatus: "Pending" };
                        this.boTab_4 = {
                            Active: false,
                            tabStatus: "Not Completed",
                        };
                        this.boTab_5 = {
                            Active: false,
                            tabStatus: "Not Completed",
                        };
                        this.boTab_6 = {
                            Active: false,
                            tabStatus: "Not Completed",
                        };
                    } else if (
                        this.propertyDetails.Status == "Revise" &&
                        this.propertyDetails.BorrowerActiveStep == 2 &&
                        (this.propertyDetails.InvitedGeneralContractors !=
                            null ||
                            this.propertyDetails.GeneralContractorId != null)
                    ) {
                        this.selectedBorrowerIndex = 2;
                        this.boTab_0 = { Active: true, tabStatus: "Completed" };
                        this.boTab_1 = { Active: true, tabStatus: "Completed" };
                        this.boTab_2 = { Active: true, tabStatus: "Completed" };
                        this.boTab_3 = {
                            Active: false,
                            tabStatus: "Not Completed",
                        };
                        this.boTab_4 = {
                            Active: false,
                            tabStatus: "Not Completed",
                        };
                        this.boTab_5 = {
                            Active: false,
                            tabStatus: "Not Completed",
                        };
                        this.boTab_6 = {
                            Active: false,
                            tabStatus: "Not Completed",
                        };
                    } else if (
                        this.propertyDetails.Status ==
                            "Property File created" &&
                        this.propertyDetails.BorrowerActiveStep == 3 &&
                        this.propertyDetails.InvitedGeneralContractors ==
                            null &&
                        this.propertyDetails.GeneralContractorId == null
                    ) {
                        this.selectedBorrowerIndex = 2;
                        this.boTab_0 = { Active: true, tabStatus: "Completed" };
                        this.boTab_1 = { Active: true, tabStatus: "Completed" };
                        this.boTab_2 = { Active: true, tabStatus: "Active" };
                        this.boTab_3 = {
                            Active: false,
                            tabStatus: "Not Completed",
                        };
                        this.boTab_4 = {
                            Active: false,
                            tabStatus: "Not Completed",
                        };
                        this.boTab_5 = {
                            Active: false,
                            tabStatus: "Not Completed",
                        };
                        this.boTab_6 = {
                            Active: false,
                            tabStatus: "Not Completed",
                        };
                    } else if (
                        this.propertyDetails.Status ==
                            "DRAFT SOR Sent to Borrower" &&
                        this.propertyDetails.BorrowerActiveStep == 3 &&
                        this.propertyDetails.InvitedGeneralContractors ==
                            null &&
                        this.propertyDetails.GeneralContractorId == null
                    ) {
                        this.selectedBorrowerIndex = 2;
                        this.boTab_0 = { Active: true, tabStatus: "Completed" };
                        this.boTab_1 = { Active: true, tabStatus: "Completed" };
                        this.boTab_2 = { Active: true, tabStatus: "Active" };
                        this.boTab_3 = { Active: true, tabStatus: "Pending" };
                        this.boTab_4 = {
                            Active: false,
                            tabStatus: "Not Completed",
                        };
                        this.boTab_5 = {
                            Active: false,
                            tabStatus: "Not Completed",
                        };
                        this.boTab_6 = {
                            Active: false,
                            tabStatus: "Not Completed",
                        };
                    } else if (
                        this.propertyDetails.Status == "Revise" &&
                        this.propertyDetails.BorrowerActiveStep == 3 &&
                        this.propertyDetails.InvitedGeneralContractors ==
                            null &&
                        this.propertyDetails.GeneralContractorId == null
                    ) {
                        this.selectedBorrowerIndex = 2;
                        this.boTab_0 = { Active: true, tabStatus: "Completed" };
                        this.boTab_1 = { Active: true, tabStatus: "Completed" };
                        this.boTab_2 = { Active: true, tabStatus: "Active" };
                        this.boTab_3 = {
                            Active: false,
                            tabStatus: "Not Completed",
                        };
                        this.boTab_4 = {
                            Active: false,
                            tabStatus: "Not Completed",
                        };
                        this.boTab_5 = {
                            Active: false,
                            tabStatus: "Not Completed",
                        };
                        this.boTab_6 = {
                            Active: false,
                            tabStatus: "Not Completed",
                        };
                    } else if (
                        this.propertyDetails.Status ==
                            "DRAFT SOR Sent to Borrower" &&
                        this.propertyDetails.BorrowerActiveStep == 4 &&
                        this.propertyDetails.InvitedGeneralContractors ==
                            null &&
                        this.propertyDetails.GeneralContractorId == null
                    ) {
                        this.selectedBorrowerIndex = 3;
                        this.boTab_0 = { Active: true, tabStatus: "Completed" };
                        this.boTab_1 = { Active: true, tabStatus: "Completed" };
                        this.boTab_2 = { Active: true, tabStatus: "Pending" };
                        this.boTab_3 = { Active: true, tabStatus: "Active" };
                        this.boTab_4 = {
                            Active: false,
                            tabStatus: "Not Completed",
                        };
                        this.boTab_5 = {
                            Active: false,
                            tabStatus: "Not Completed",
                        };
                        this.boTab_6 = {
                            Active: false,
                            tabStatus: "Not Completed",
                        };
                    } else if (
                        this.propertyDetails.Status == "Revise" &&
                        this.propertyDetails.BorrowerActiveStep == 4 &&
                        this.propertyDetails.InvitedGeneralContractors ==
                            null &&
                        this.propertyDetails.GeneralContractorId == null
                    ) {
                        this.selectedBorrowerIndex = 2;
                        this.boTab_0 = { Active: true, tabStatus: "Completed" };
                        this.boTab_1 = { Active: true, tabStatus: "Completed" };
                        this.boTab_2 = { Active: true, tabStatus: "Active" };
                        this.boTab_3 = {
                            Active: false,
                            tabStatus: "Not Completed",
                        };
                        this.boTab_4 = {
                            Active: false,
                            tabStatus: "Not Completed",
                        };
                        this.boTab_5 = {
                            Active: false,
                            tabStatus: "Not Completed",
                        };
                        this.boTab_6 = {
                            Active: false,
                            tabStatus: "Not Completed",
                        };
                    } else if (
                        this.propertyDetails.Status ==
                            "Property File created" &&
                        this.propertyDetails.BorrowerActiveStep == 3 &&
                        (this.propertyDetails.InvitedGeneralContractors !=
                            null ||
                            this.propertyDetails.GeneralContractorId != null)
                    ) {
                        this.selectedBorrowerIndex = 2;
                        this.boTab_0 = { Active: true, tabStatus: "Completed" };
                        this.boTab_1 = { Active: true, tabStatus: "Completed" };
                        this.boTab_2 = { Active: true, tabStatus: "Active" };
                        this.boTab_3 = {
                            Active: false,
                            tabStatus: "Not Completed",
                        };
                        this.boTab_4 = {
                            Active: false,
                            tabStatus: "Not Completed",
                        };
                        this.boTab_5 = {
                            Active: false,
                            tabStatus: "Not Completed",
                        };
                        this.boTab_6 = {
                            Active: false,
                            tabStatus: "Not Completed",
                        };
                    } else if (
                        this.propertyDetails.Status ==
                            "DRAFT SOR Sent to Borrower" &&
                        (this.propertyDetails.BorrowerActiveStep == 3 ||
                            this.propertyDetails.BorrowerActiveStep == 4) &&
                        (this.propertyDetails.InvitedGeneralContractors !=
                            null ||
                            this.propertyDetails.GeneralContractorId != null)
                    ) {
                        this.selectedBorrowerIndex = 3;
                        this.boTab_0 = { Active: true, tabStatus: "Completed" };
                        this.boTab_1 = { Active: true, tabStatus: "Completed" };
                        this.boTab_2 = { Active: true, tabStatus: "Completed" };
                        this.boTab_3 = { Active: true, tabStatus: "Active" };
                        this.boTab_4 = {
                            Active: false,
                            tabStatus: "Not Completed",
                        };
                        this.boTab_5 = {
                            Active: false,
                            tabStatus: "Not Completed",
                        };
                        this.boTab_6 = {
                            Active: false,
                            tabStatus: "Not Completed",
                        };
                    } else if (
                        this.propertyDetails.Status == "Revise" &&
                        (this.propertyDetails.BorrowerActiveStep == 3 ||
                            this.propertyDetails.BorrowerActiveStep == 4) &&
                        (this.propertyDetails.InvitedGeneralContractors !=
                            null ||
                            this.propertyDetails.GeneralContractorId != null)
                    ) {
                        this.selectedBorrowerIndex = 2;
                        this.boTab_0 = { Active: true, tabStatus: "Completed" };
                        this.boTab_1 = { Active: true, tabStatus: "Completed" };
                        this.boTab_2 = { Active: true, tabStatus: "Completed" };
                        this.boTab_3 = {
                            Active: false,
                            tabStatus: "Not Completed",
                        };
                        this.boTab_4 = {
                            Active: false,
                            tabStatus: "Not Completed",
                        };
                        this.boTab_5 = {
                            Active: false,
                            tabStatus: "Not Completed",
                        };
                        this.boTab_6 = {
                            Active: false,
                            tabStatus: "Not Completed",
                        };
                    } else if (
                        this.propertyDetails.Status == "Bid Process Started" &&
                        (this.propertyDetails.BorrowerActiveStep == 4 ||
                            this.propertyDetails.BorrowerActiveStep == 5)
                    ) {
                        this.selectedBorrowerIndex = 4;
                        this.boTab_0 = { Active: true, tabStatus: "Completed" };
                        this.boTab_1 = { Active: true, tabStatus: "Completed" };
                        this.boTab_2 = { Active: true, tabStatus: "Completed" };
                        this.boTab_3 = { Active: true, tabStatus: "Completed" };
                        this.boTab_4 = { Active: true, tabStatus: "Active" };
                        this.boTab_5 = {
                            Active: false,
                            tabStatus: "Not Completed",
                        };
                        this.boTab_6 = {
                            Active: false,
                            tabStatus: "Not Completed",
                        };
                    } else if (
                        this.propertyDetails.Status == "Send For Review" &&
                        this.propertyDetails.SubmittedBids != null &&
                        this.propertyDetails.BorrowerActiveStep == 5
                    ) {
                        this.selectedBorrowerIndex = 4;
                        this.boTab_0 = { Active: true, tabStatus: "Completed" };
                        this.boTab_1 = { Active: true, tabStatus: "Completed" };
                        this.boTab_2 = { Active: true, tabStatus: "Completed" };
                        this.boTab_3 = { Active: true, tabStatus: "Completed" };
                        this.boTab_4 = { Active: true, tabStatus: "Active" };
                        this.boTab_5 = {
                            Active: false,
                            tabStatus: "Not Completed",
                        };
                        this.boTab_6 = {
                            Active: false,
                            tabStatus: "Not Completed",
                        };
                    } else if (
                        this.propertyDetails.Status == "Review Bid" &&
                        (this.propertyDetails.BorrowerActiveStep == 5 ||
                            this.propertyDetails.BorrowerActiveStep == 6)
                    ) {
                        this.selectedBorrowerIndex = 5;
                        this.boTab_0 = { Active: true, tabStatus: "Completed" };
                        this.boTab_1 = { Active: true, tabStatus: "Completed" };
                        this.boTab_2 = { Active: true, tabStatus: "Completed" };
                        this.boTab_3 = { Active: true, tabStatus: "Completed" };
                        this.boTab_4 = { Active: true, tabStatus: "Completed" };
                        this.boTab_5 = { Active: true, tabStatus: "Active" };
                        this.boTab_6 = {
                            Active: false,
                            tabStatus: "Not Completed",
                        };
                    } else if (
                        this.propertyDetails.Status == "Pre Closing Completed"
                    ) {
                        this.selectedBorrowerIndex = 5;
                        this.boTab_0 = { Active: true, tabStatus: "Completed" };
                        this.boTab_1 = { Active: true, tabStatus: "Completed" };
                        this.boTab_2 = { Active: true, tabStatus: "Completed" };
                        this.boTab_3 = { Active: true, tabStatus: "Completed" };
                        this.boTab_4 = { Active: true, tabStatus: "Completed" };
                        this.boTab_5 = { Active: true, tabStatus: "Completed" };
                        this.boTab_6 = {
                            Active: false,
                            tabStatus: "Not Completed",
                        };
                    } else if (
                        this.propertyDetails.Status == "Draw request started" ||
                        this.propertyDetails.Status == "Post closing started"
                    ) {
                        this.selectedBorrowerIndex = 6;
                        this.boTab_0 = { Active: true, tabStatus: "Completed" };
                        this.boTab_1 = { Active: true, tabStatus: "Completed" };
                        this.boTab_2 = { Active: true, tabStatus: "Completed" };
                        this.boTab_3 = { Active: true, tabStatus: "Completed" };
                        this.boTab_4 = { Active: true, tabStatus: "Completed" };
                        this.boTab_5 = { Active: true, tabStatus: "Completed" };
                        this.boTab_6 = { Active: true, tabStatus: "Active" };
                    } else if (
                        this.propertyDetails.Status == "Final Draw Completed"
                    ) {
                        this.selectedBorrowerIndex = 6;
                        this.boTab_0 = { Active: true, tabStatus: "Completed" };
                        this.boTab_1 = { Active: true, tabStatus: "Completed" };
                        this.boTab_2 = { Active: true, tabStatus: "Completed" };
                        this.boTab_3 = { Active: true, tabStatus: "Completed" };
                        this.boTab_4 = { Active: true, tabStatus: "Completed" };
                        this.boTab_5 = { Active: true, tabStatus: "Completed" };
                        this.boTab_6 = { Active: true, tabStatus: "Completed" };
                    } else {
                        this.selectedBorrowerIndex = 0;
                        this.boTab_0 = { Active: true, tabStatus: "Active" };
                        this.boTab_1 = { Active: true, tabStatus: "Pending" };
                        this.boTab_2 = { Active: true, tabStatus: "Pending" };
                        this.boTab_3 = {
                            Active: false,
                            tabStatus: "Not Completed",
                        };
                        this.boTab_4 = {
                            Active: false,
                            tabStatus: "Not Completed",
                        };
                        this.boTab_5 = {
                            Active: false,
                            tabStatus: "Not Completed",
                        };
                        this.boTab_6 = {
                            Active: false,
                            tabStatus: "Not Completed",
                        };
                    }

                    if (
                        this.propertyDetails.InitialDraws != null &&
                        this.propertyDetails.InitialDraws.length > 0
                    ) {
                        this.propertyDetails.InitialDraws.sort(function (a, b) {
                            return a.DrawId - b.DrawId;
                        });
                        for (
                            let j = 0;
                            j < this.propertyDetails.InitialDraws.length;
                            j++
                        ) {
                            this.propertyDetails.InitialDraws[
                                j
                            ].ConsultantSendSignDate = "";
                            this.propertyDetails.InitialDraws[j].GCRequestDate =
                                "";
                            let drawId =
                                this.propertyDetails.InitialDraws[j].DrawId;
                            this._boservice
                                .GetDrawRequestById(drawId)
                                .subscribe(
                                    (DrawRequestresponse) => {
                                        if (DrawRequestresponse != null) {
                                            if (
                                                this.propertyDetails
                                                    .InitialDraws[j].Status ==
                                                    "approved" &&
                                                DrawRequestresponse.ConsultantRequestDate !=
                                                    null &&
                                                DrawRequestresponse.ConsultantRequestDate !=
                                                    undefined &&
                                                DrawRequestresponse.ConsultantRequestDate !=
                                                    ""
                                            ) {
                                                this.PreviousDrawShow = true;
                                            }

                                            if (
                                                this.propertyDetails
                                                    .InitialDraws[j].Status ==
                                                    "pending" ||
                                                this.propertyDetails
                                                    .InitialDraws[j].Status ==
                                                    "revised" ||
                                                DrawRequestresponse.ConsultantRequestDate ==
                                                    null ||
                                                DrawRequestresponse.ConsultantRequestDate ==
                                                    undefined ||
                                                DrawRequestresponse.ConsultantRequestDate ==
                                                    ""
                                            ) {
                                                this.CurrentDrawShow = true;
                                            }
                                            this.propertyDetails.InitialDraws[
                                                j
                                            ].ConsultantRequestDate =
                                                DrawRequestresponse.ConsultantRequestDate;
                                            this.propertyDetails.InitialDraws[
                                                j
                                            ].ConsultantSendSignDate =
                                                DrawRequestresponse.ConsultantSendSignDate;
                                            this.propertyDetails.InitialDraws[
                                                j
                                            ].GCRequestDate =
                                                DrawRequestresponse.CreatedDate;
                                        } else {
                                            this.propertyDetails.InitialDraws[
                                                j
                                            ].ConsultantRequestDate = "";
                                            this.propertyDetails.InitialDraws[
                                                j
                                            ].ConsultantSendSignDate = "";
                                            this.propertyDetails.InitialDraws[
                                                j
                                            ].GCRequestDate = "";
                                        }
                                    },
                                    (error) => {
                                        this.sessionService.notifier.notify(
                                            "error",
                                            error.error.message
                                        );
                                        this.loading = false;
                                    }
                                );
                        }
                    }

                    this.propertyDetailsShow = true;

                    this.loading = false;
                    if (
                        response.BorrowerId != null &&
                        response.BorrowerId != undefined &&
                        response.BorrowerId != ""
                    ) {
                        this._bservice
                            .GetUserDetailsList(response.BorrowerId)
                            .subscribe(
                                (borrowerResponse) => {
                                    if (borrowerResponse != null) {
                                        this.borrowerDetails = borrowerResponse;

                                        if (
                                            response.ConsultantId != null &&
                                            response.ConsultantId !=
                                                undefined &&
                                            response.ConsultantId != ""
                                        ) {
                                            this._bservice
                                                .GetUserDetailsList(
                                                    response.ConsultantId
                                                )
                                                .subscribe(
                                                    (consultantResponse) => {
                                                        if (
                                                            consultantResponse !=
                                                            null
                                                        ) {
                                                            this.consultantDetails =
                                                                consultantResponse;

                                                            if (
                                                                response.LoanOfficerId !=
                                                                    null &&
                                                                response.LoanOfficerId !=
                                                                    undefined &&
                                                                response.LoanOfficerId !=
                                                                    ""
                                                            ) {
                                                                this._bservice
                                                                    .GetUserDetailsList(
                                                                        response.LoanOfficerId
                                                                    )
                                                                    .subscribe(
                                                                        (
                                                                            LOResponse
                                                                        ) => {
                                                                            if (
                                                                                LOResponse !=
                                                                                null
                                                                            ) {
                                                                                this.LODetails =
                                                                                    LOResponse;

                                                                                this.loading =
                                                                                    false;
                                                                            }
                                                                        },
                                                                        (
                                                                            error
                                                                        ) => {}
                                                                    );
                                                            }
                                                        }
                                                    },
                                                    (error) => {}
                                                );
                                        }
                                    }
                                },
                                (error) => {}
                            );
                    }
                    if (
                        response.RealtorId != null &&
                        response.RealtorId != undefined &&
                        response.RealtorId != ""
                    ) {
                        this._bservice
                            .GetUserDetailsList(response.RealtorId)
                            .subscribe(
                                (RealtorResponse) => {
                                    if (RealtorResponse != null) {
                                        this.RealtorDetails = RealtorResponse;

                                        this.loading = false;
                                    }
                                },
                                (error) => {}
                            );
                    }
                    if (
                        response.DrawAdministratorId != null &&
                        response.DrawAdministratorId != undefined &&
                        response.DrawAdministratorId != ""
                    ) {
                        this._bservice
                            .GetUserDetailsList(response.DrawAdministratorId)
                            .subscribe(
                                (DrawAdministratorResponse) => {
                                    if (DrawAdministratorResponse != null) {
                                        this._bservice
                                            .GetLenderDetailsList(
                                                DrawAdministratorResponse.LenderId
                                            )
                                            .subscribe(
                                                (LenderResponse) => {
                                                    if (
                                                        LenderResponse != null
                                                    ) {
                                                        DrawAdministratorResponse.Lender =
                                                            LenderResponse.Name;
                                                        this.DADetails =
                                                            DrawAdministratorResponse;

                                                        this.loading = false;
                                                    }
                                                },
                                                (error) => {}
                                            );

                                        this.loading = false;
                                    }
                                },
                                (error) => {}
                            );
                    }
                    this.loading = true;

                    var GCdata = {
                        EmailId: "",
                        PageSize: 1000,
                        LastEvaluatedKey: 0,
                        UserType: "General Contractors",
                    };
                    this.loadingGC = true;

                    this._boservice.GetUserList(GCdata).subscribe(
                        (response) => {
                            this.loadingGC = false;

                            if (response != null) {
                                this.GCDetailsList = [];
                                if (
                                    this.propertyDetails
                                        .InvitedGeneralContractors != null
                                ) {
                                    let newLengthUsers = 0;
                                    for (
                                        let i = 0;
                                        i <
                                        this.propertyDetails
                                            .InvitedGeneralContractors.length;
                                        i++
                                    ) {
                                        for (
                                            let j = 0;
                                            j < response.Items.length;
                                            j++
                                        ) {
                                            if (
                                                response.Items[j].Id ==
                                                this.propertyDetails
                                                    .InvitedGeneralContractors[
                                                    i
                                                ]
                                            ) {
                                                newLengthUsers =
                                                    newLengthUsers + 1;
                                            }
                                        }
                                    }
                                    for (
                                        let i = 0;
                                        i <
                                        this.propertyDetails
                                            .InvitedGeneralContractors.length;
                                        i++
                                    ) {
                                        for (
                                            let j = 0;
                                            j < response.Items.length;
                                            j++
                                        ) {
                                            if (
                                                response.Items[j].Id ==
                                                this.propertyDetails
                                                    .InvitedGeneralContractors[
                                                    i
                                                ]
                                            ) {
                                                var GCSORdata = {
                                                    PropertyId:
                                                        this.propertyDetails.Id,
                                                    GeneralContractorId:
                                                        this.propertyDetails
                                                            .InvitedGeneralContractors[
                                                            i
                                                        ],
                                                };
                                                this.loadingGC = true;

                                                this._boservice
                                                    .GetGCSorDetails(GCSORdata)
                                                    .subscribe((GCresponse) => {
                                                        this.loadingGC = false;
                                                        var isReviseFlag =
                                                            false;
                                                        var isCoReviseFlag =
                                                            false;
                                                        var isAccepted = false;
                                                        var isReviewed = false;

                                                        if (
                                                            GCresponse !=
                                                                null &&
                                                            GCresponse.Items
                                                                .length > 0
                                                        ) {
                                                            if (
                                                                response.Items[
                                                                    j
                                                                ].Id ==
                                                                GCresponse
                                                                    .Items[0]
                                                                    .GeneralContractorId
                                                            ) {
                                                                if (
                                                                    GCresponse
                                                                        .Items[0]
                                                                        .ReviseFlag ==
                                                                        true &&
                                                                    GCresponse
                                                                        .Items[0]
                                                                        .Status !=
                                                                        "Reviewed" &&
                                                                    GCresponse
                                                                        .Items[0]
                                                                        .Status !=
                                                                        "Accepted"
                                                                ) {
                                                                    isReviseFlag =
                                                                        true;
                                                                } else if (
                                                                    GCresponse
                                                                        .Items[0]
                                                                        .Status ==
                                                                        "Reviewed" &&
                                                                    GCresponse
                                                                        .Items[0]
                                                                        .ReviseFlag ==
                                                                        true
                                                                ) {
                                                                    isCoReviseFlag =
                                                                        true;
                                                                } else if (
                                                                    GCresponse
                                                                        .Items[0]
                                                                        .Status ==
                                                                    "Reviewed"
                                                                ) {
                                                                    isReviewed =
                                                                        true;
                                                                } else if (
                                                                    GCresponse
                                                                        .Items[0]
                                                                        .Status ==
                                                                    "Accepted"
                                                                ) {
                                                                    isAccepted =
                                                                        true;
                                                                } else {
                                                                    isAccepted =
                                                                        false;
                                                                    isReviewed =
                                                                        false;
                                                                    isCoReviseFlag =
                                                                        false;
                                                                    isReviseFlag =
                                                                        false;
                                                                }

                                                                response.Items[
                                                                    j
                                                                ].estimateStatus =
                                                                    "Submitted";
                                                                response.Items[
                                                                    j
                                                                ].submissionStatus =
                                                                    "Submitted";

                                                                if (
                                                                    isReviseFlag ==
                                                                    true
                                                                ) {
                                                                    response.Items[
                                                                        j
                                                                    ].boReviewStatus =
                                                                        "Revision Requested";
                                                                    response.Items[
                                                                        j
                                                                    ].coReviewStatus =
                                                                        "Not Reviewed";
                                                                } else if (
                                                                    isReviewed ==
                                                                    true
                                                                ) {
                                                                    response.Items[
                                                                        j
                                                                    ].boReviewStatus =
                                                                        "Accepted";
                                                                    response.Items[
                                                                        j
                                                                    ].coReviewStatus =
                                                                        "Not Reviewed";
                                                                } else if (
                                                                    isCoReviseFlag ==
                                                                    true
                                                                ) {
                                                                    response.Items[
                                                                        j
                                                                    ].boReviewStatus =
                                                                        "Accepted";
                                                                    response.Items[
                                                                        j
                                                                    ].coReviewStatus =
                                                                        "Revision Requested";
                                                                } else if (
                                                                    isAccepted ==
                                                                    true
                                                                ) {
                                                                    response.Items[
                                                                        j
                                                                    ].boReviewStatus =
                                                                        "Accepted";
                                                                    response.Items[
                                                                        j
                                                                    ].coReviewStatus =
                                                                        "Accepted";
                                                                } else {
                                                                    response.Items[
                                                                        j
                                                                    ].boReviewStatus =
                                                                        "Not Reviewed";
                                                                    response.Items[
                                                                        j
                                                                    ].coReviewStatus =
                                                                        "Not Reviewed";
                                                                }

                                                                this.GCDetailsList.push(
                                                                    response
                                                                        .Items[
                                                                        j
                                                                    ]
                                                                );
                                                                if (
                                                                    newLengthUsers ==
                                                                    this
                                                                        .GCDetailsList
                                                                        .length
                                                                ) {
                                                                    this.loadingGC =
                                                                        false;

                                                                    this.GetGCUserList();
                                                                }
                                                            }
                                                        } else {
                                                            if (
                                                                response.Items[
                                                                    j
                                                                ].Id ==
                                                                this
                                                                    .propertyDetails
                                                                    .InvitedGeneralContractors[
                                                                    i
                                                                ]
                                                            ) {
                                                                response.Items[
                                                                    j
                                                                ].estimateStatus =
                                                                    "Not Submitted";
                                                                response.Items[
                                                                    j
                                                                ].submissionStatus =
                                                                    "Not Submitted";
                                                                response.Items[
                                                                    j
                                                                ].boReviewStatus =
                                                                    "Not Reviewed";
                                                                response.Items[
                                                                    j
                                                                ].coReviewStatus =
                                                                    "Not Reviewed";

                                                                this.GCDetailsList.push(
                                                                    response
                                                                        .Items[
                                                                        j
                                                                    ]
                                                                );

                                                                if (
                                                                    newLengthUsers ==
                                                                    this
                                                                        .GCDetailsList
                                                                        .length
                                                                ) {
                                                                    this.loadingGC =
                                                                        false;

                                                                    this.GetGCUserList();
                                                                }
                                                            }
                                                        }
                                                    });
                                            }
                                        }
                                    }
                                } else {
                                    this.loadingGC = false;

                                    this.GetGCUserList();
                                }
                            } else {
                                this.loadingGC = false;
                            }
                        },
                        (error) => {}
                    );
                    if (response.LoanProcessorId != null) {
                        this.resLoanProcessorIds = response.LoanProcessorId;

                        var i: number;
                        if (this.resLoanProcessorIds.length > 0) {
                            for (
                                i = 0;
                                i < this.resLoanProcessorIds.length;
                                i++
                            ) {
                                if (
                                    this.resLoanProcessorIds[i] != null &&
                                    this.resLoanProcessorIds[i] != undefined &&
                                    this.resLoanProcessorIds[i] != ""
                                ) {
                                    this._bservice
                                        .GetUserDetailsList(
                                            this.resLoanProcessorIds[i]
                                        )
                                        .subscribe(
                                            (LPResponse) => {
                                                if (LPResponse != null) {
                                                    this.LoanProcessorDetails.push(
                                                        LPResponse
                                                    );

                                                    this.loading = false;
                                                }
                                            },
                                            (error) => {}
                                        );
                                }
                            }
                        } else {
                            if (
                                this.resLoanProcessorIds != null &&
                                this.resLoanProcessorIds != undefined &&
                                this.resLoanProcessorIds != ""
                            ) {
                                this._bservice
                                    .GetUserDetailsList(
                                        this.resLoanProcessorIds
                                    )
                                    .subscribe(
                                        (LPResponse) => {
                                            if (LPResponse != null) {
                                                this.LoanProcessorDetails.push(
                                                    LPResponse
                                                );

                                                this.loading = false;
                                            }
                                        },
                                        (error) => {}
                                    );
                            }
                        }
                    } else {
                        this.LoanProcessorDetails = [];
                    }

                    if (
                        response.LenderId != null &&
                        response.LenderId != undefined &&
                        response.LenderId != ""
                    ) {
                        this._bservice
                            .GetLenderDetailsList(response.LenderId)
                            .subscribe(
                                (LenderResponse) => {
                                    if (LenderResponse != null) {
                                        this.LenderDetails = LenderResponse;

                                        this.loading = false;
                                    }
                                },
                                (error) => {}
                            );
                    }

                    var data = {
                        EmailId: "",
                        PropertyId: propertyId,
                        PageSize: 100000,
                        LastEvaluatedKey: 0,
                        UserType: this.sessionService.userType[1].Value,
                    };
                    this.propertyDetails.sorExist = false;
                    this._boservice.GetSorDetails(data).subscribe(
                        (sorResponse) => {
                            if (sorResponse.Items.length > 0) {
                                if (
                                    sorResponse.Items[0].SorStatus != null &&
                                    sorResponse.Items[0].SorStatus !=
                                        undefined &&
                                    sorResponse.Items[0].SorStatus != ""
                                ) {
                                    this.propertyDetails.sorExist = true;
                                    this.sorDetails = sorResponse.Items[0];
                                }
                            }
                        },
                        (error) => {}
                    );
                }
            },
            (error) => {}
        );
        var data = { PropertyId: propertyId };
        this._boservice.GetPropertyImagesList(data).subscribe(
            (response) => {
                if (response != null) {
                    if (response.Items.length > 0) {
                        var imageData = response.Items[0];
                        this.propertyImagesData = imageData.Images;
                        if (this.propertyImagesData.length > 0) {
                            for (
                                let i = 0;
                                i < this.propertyImagesData.length;
                                i++
                            ) {
                                let img = new Image();

                                let imagewidth: any;
                                let imageheight: any;
                                const _this = this;
                                let imageSrc = _this.propertyImagesData[i];
                                img.src = _this.propertyImagesData[i];
                                img.onload = function (event) {
                                    let loadedImage =
                                        event.currentTarget as HTMLImageElement;

                                    imagewidth = loadedImage.width;
                                    imageheight = loadedImage.height;

                                    let n = _this.propertyImagesData.length;

                                    let imagename = imageSrc
                                        .split("/")
                                        .pop()
                                        .toLowerCase();

                                    let imageDataArray = {
                                        imageSrc: imageSrc,
                                        width: imagewidth,
                                        height: imageheight,
                                        dimensions:
                                            imagewidth + " X " + imageheight,
                                        name: imagename,
                                        size: "105kb",
                                    };

                                    _this.newPropertyImagesData.push(
                                        imageDataArray
                                    );
                                };
                            }
                        }
                    }
                }
            },
            (error) => {}
        );
        this._boservice.GetPropertyDrawImagesList(data).subscribe(
            (response) => {
                if (response != null) {
                    if (response.Items.length > 0) {
                        var imageData = response.Items[0];
                        this.drawImagesData = imageData.Images;
                        if (this.drawImagesData.length > 0) {
                            for (
                                let i = 0;
                                i < this.drawImagesData.length;
                                i++
                            ) {
                                let img = new Image();

                                let imagewidth: any;
                                let imageheight: any;
                                const _this = this;
                                let imageSrc = _this.drawImagesData[i];
                                img.src = _this.drawImagesData[i];
                                img.onload = function (event) {
                                    let loadedImage =
                                        event.currentTarget as HTMLImageElement;

                                    imagewidth = loadedImage.width;
                                    imageheight = loadedImage.height;

                                    let n = _this.drawImagesData.length;

                                    let imagename = imageSrc
                                        .split("/")
                                        .pop()
                                        .toLowerCase();

                                    let imageDataArray = {
                                        imageSrc: imageSrc,
                                        width: imagewidth,
                                        height: imageheight,
                                        dimensions:
                                            imagewidth + " X " + imageheight,
                                        name: imagename,
                                        size: "105kb",
                                    };

                                    _this.newDrawImagesData.push(
                                        imageDataArray
                                    );
                                };
                            }
                        }
                    }
                }
            },
            (error) => {}
        );
    }

    restartEstimateProcess() {
        if (confirm("Are you sure to restart estimate process.")) {
            this.loading = true;
            this._bservice.GetPropertyDetails(this.propertyId).subscribe(
                (pResponse) => {
                    if (pResponse != null) {
                        let pDetails = pResponse;
                        pDetails.Status = "Bid Process Started";
                        pDetails.AcceptedProposal = null;
                        this._bservice.UpdateProperty(pDetails).subscribe(
                            (response) => {
                                var data = {
                                    EmailId: "",
                                    PropertyId: this.propertyId,
                                    PageSize: 100000,
                                    LastEvaluatedKey: 0,
                                    UserType: this.userDetail.UserType,
                                };
                                this._boservice.GetSorDetails(data).subscribe(
                                    (sorResponse) => {
                                        if (sorResponse.Items.length > 0) {
                                            let sorData = sorResponse.Items[0];
                                            sorData.FinalMailSend = null;
                                            sorData.FinalMailSendDate = null;
                                            sorData.IsFinal = null;
                                            this._boservice
                                                .UpdateSOR(sorData)
                                                .subscribe(
                                                    (response) => {
                                                        var GCSORdata = {
                                                            PropertyId:
                                                                this.propertyId,
                                                        };
                                                        this._boservice
                                                            .GetGCSorDetails(
                                                                GCSORdata
                                                            )
                                                            .subscribe(
                                                                (
                                                                    GCresponse
                                                                ) => {
                                                                    if (
                                                                        GCresponse
                                                                            .Items
                                                                            .length >
                                                                        0
                                                                    ) {
                                                                        for (
                                                                            let j = 0;
                                                                            j <
                                                                            GCresponse
                                                                                .Items
                                                                                .length;
                                                                            j++
                                                                        ) {
                                                                            var GCresponseData =
                                                                                GCresponse
                                                                                    .Items[
                                                                                    j
                                                                                ];
                                                                            GCresponseData.Status =
                                                                                "Send For Review";
                                                                            this._boservice
                                                                                .UpdateSORForGC(
                                                                                    GCresponseData
                                                                                )
                                                                                .subscribe(
                                                                                    (
                                                                                        response
                                                                                    ) => {
                                                                                        if (
                                                                                            j ==
                                                                                            GCresponse
                                                                                                .Items
                                                                                                .length -
                                                                                                1
                                                                                        ) {
                                                                                            for (
                                                                                                let k = 0;
                                                                                                k <
                                                                                                this
                                                                                                    .allSignedDoc
                                                                                                    .length;
                                                                                                k++
                                                                                            ) {
                                                                                                if (
                                                                                                    this
                                                                                                        .allSignedDoc[
                                                                                                        k
                                                                                                    ]
                                                                                                        .EnvelopeType ==
                                                                                                        "final_package" ||
                                                                                                    this
                                                                                                        .allSignedDoc[
                                                                                                        k
                                                                                                    ]
                                                                                                        .EnvelopeType ==
                                                                                                        "bid_on_repair" ||
                                                                                                    this
                                                                                                        .allSignedDoc[
                                                                                                        k
                                                                                                    ]
                                                                                                        .EnvelopeType ==
                                                                                                        "initial_draw"
                                                                                                ) {
                                                                                                    let docId =
                                                                                                        this
                                                                                                            .allSignedDoc[
                                                                                                            k
                                                                                                        ]
                                                                                                            .Id;
                                                                                                    this._bservice
                                                                                                        .removeSignEnvelopeById(
                                                                                                            docId
                                                                                                        )
                                                                                                        .subscribe(
                                                                                                            (
                                                                                                                response
                                                                                                            ) => {
                                                                                                                if (
                                                                                                                    k ==
                                                                                                                    this
                                                                                                                        .allSignedDoc
                                                                                                                        .length -
                                                                                                                        1
                                                                                                                ) {
                                                                                                                    this.sessionService.notifier.notify(
                                                                                                                        "success",
                                                                                                                        "Estimate process has been restarted successfully."
                                                                                                                    );
                                                                                                                    this.loading =
                                                                                                                        false;
                                                                                                                    this.getPropertyDetails(
                                                                                                                        this
                                                                                                                            .propertyId
                                                                                                                    );
                                                                                                                }
                                                                                                            },
                                                                                                            (
                                                                                                                error
                                                                                                            ) => {}
                                                                                                        );
                                                                                                } else {
                                                                                                    if (
                                                                                                        k ==
                                                                                                        this
                                                                                                            .allSignedDoc
                                                                                                            .length -
                                                                                                            1
                                                                                                    ) {
                                                                                                        this.sessionService.notifier.notify(
                                                                                                            "success",
                                                                                                            "Estimate process has been restarted successfully."
                                                                                                        );
                                                                                                        this.loading =
                                                                                                            false;
                                                                                                        this.getPropertyDetails(
                                                                                                            this
                                                                                                                .propertyId
                                                                                                        );
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    },
                                                                                    (
                                                                                        error
                                                                                    ) => {}
                                                                                );
                                                                        }
                                                                    }
                                                                },
                                                                (error) => {}
                                                            );
                                                    },
                                                    (error) => {}
                                                );
                                        }
                                    },
                                    (error) => {}
                                );
                            },
                            (error) => {}
                        );
                    }
                },
                (error) => {}
            );
        }
    }

    deleteUser(userid) {
        if (confirm("Are you sure to delete contractor")) {
            this.loading = true;

            this._bservice.GetPropertyDetails(this.propertyId).subscribe(
                (pResponse) => {
                    if (pResponse != null) {
                        var InvitedGeneralContractors =
                            pResponse.InvitedGeneralContractors;
                        if (InvitedGeneralContractors != null) {
                            this.propertyDetails = pResponse;
                            if (InvitedGeneralContractors.length > 0) {
                                let newGCList = [];
                                for (
                                    let i = 0;
                                    i < InvitedGeneralContractors.length;
                                    i++
                                ) {
                                    if (
                                        InvitedGeneralContractors[i] != userid
                                    ) {
                                        newGCList.push(
                                            InvitedGeneralContractors[i]
                                        );
                                    }
                                }
                                if (newGCList.length > 0) {
                                    this.propertyDetails.InvitedGeneralContractors =
                                        newGCList;
                                } else {
                                    this.propertyDetails.InvitedGeneralContractors =
                                        null;
                                }

                                this._bservice
                                    .UpdateProperty(this.propertyDetails)
                                    .subscribe(
                                        (response) => {
                                            this.sessionService.notifier.notify(
                                                "success",
                                                "Contractor deleted successfully."
                                            );
                                            this.isList = true;
                                            this.isAdd = false;
                                            this.isEdit = false;
                                            this.GetGCUserList();
                                            this.loading = false;
                                        },
                                        (error) => {}
                                    );
                            }
                        }
                    }
                },
                (error) => {}
            );

            this.loading = false;

            window.scroll(0, 0);
        }
    }

    GetGCUserList() {
        this.loading = true;

        this.userList.Items = this.GCDetailsList;
        this.userList.Count = this.GCDetailsList.length;
        this.userList.TotalRecord = this.GCDetailsList.length;
        this.userList.ScannedCount = this.GCDetailsList.length;

        this.userList.Items.sort(function (a, b) {
            return a.Id < b.Id ? 1 : -1;
        });

        const users = Array.from({ length: this.userList.Count }, (_, k) =>
            createNewUser(k, this.userList)
        );
        this.dataSource = new MatTableDataSource(users);
        this.dataSource.paginator = this.paginator;

        this.loadingGC = false;
        this.loading = false;
    }

    onBlurDrawAdministrator() {
        let drawadministrator = this.drawadministrator_list.filter((item) => {
            return (
                this.add_drawAdministrator.value.daemail != null &&
                item.EmailId.toLowerCase() ==
                    this.add_drawAdministrator.value.daemail.toLowerCase()
            );
        });

        if (drawadministrator.length > 0) {
            this.add_drawAdministrator.controls["daemail"].setValue(
                drawadministrator[0].EmailId
            );
            this.add_drawAdministrator.controls["dafname"].setValue(
                drawadministrator[0].FirstName
            );
            this.add_drawAdministrator.controls["dalname"].setValue(
                drawadministrator[0].LastName
            );
            this.add_drawAdministrator.controls["daphone_no"].setValue(
                drawadministrator[0].Phone
            );
            this.add_drawAdministrator.controls["lender"].setValue(
                drawadministrator[0].LenderId
            );
            this.add_drawAdministrator.controls["daId"].setValue(
                drawadministrator[0].Id
            );
            return;
        } else {
            this.add_drawAdministrator.controls["dafname"].setValue("");
            this.add_drawAdministrator.controls["dalname"].setValue("");
            this.add_drawAdministrator.controls["daphone_no"].setValue("");
            this.add_drawAdministrator.controls["daId"].setValue(null);
            return;
        }
    }

    removeDAFromProperty() {
        var pdata = this.propertyDetails;
        pdata.DrawAdministratorId = null;
        this._bservice.UpdateProperty(pdata).subscribe(
            (response1) => {
                this.getPropertyDetails(this.propertyId);
                this.sessionService.notifier.notify(
                    "success",
                    "Draw Administrator added successfully."
                );
                this.showAddFormDA = false;
                this.DADetails = {};
                this.loading = false;
            },
            (error) => {}
        );
    }
    showAdditionalDrawFunction() {
        if (this.showAdditionalDraw == true) {
            this.showAdditionalDraw = false;
        } else {
            this.showAdditionalDraw = true;
        }
    }
    showAdditionalOrderFunction() {
        if (this.showAdditionalOrder == true) {
            this.showAdditionalOrder = false;
        } else {
            this.showAdditionalOrder = true;
        }
    }

    getBorPDF(propertyId, gcId) {
        var data = {
            PropertyId: parseInt(propertyId),
            GeneralContractorId: gcId,
        };

        this._bservice.GetBORPDF(data).subscribe(
            (response) => {
                let pdfUrl = this.baseurl + response;
                window.open(pdfUrl, "_blank");
                if (response) {
                }
            },
            (error) => {
                this.sessionService.notifier.notify(
                    "error",
                    error.error.message
                );
                this.loading = false;
            }
        );
    }
    getGCSOR(propertyId) {
        var GCSORdata = {
            PropertyId: propertyId,
        };
        this._boservice.GetGCSorDetails(GCSORdata).subscribe(
            (GCresponse) => {
                if (GCresponse != null) {
                    let gcSubmittedBid = [];
                    for (let i = 0; i < GCresponse.Items.length; i++) {
                        if (GCresponse.Items[i].Status != "Pending") {
                            gcSubmittedBid.push("1");
                        }
                    }
                    this.submittedBid = gcSubmittedBid.length;
                }
            },
            (error) => {}
        );
    }

    getFhaConsultantAgreementDetails(propertyId) {
        var data = {
            PropertyId: propertyId,
        };
        this._bservice.GetFhaConsultantAgreementDetails(data).subscribe(
            (response) => {
                if (response.Items.length > 0) {
                    this.fhaconsultantagreement = response.Items[0];
                } else {
                    var currentDate = new Date();
                    var currentDate2 =
                        moment(currentDate).format("MMMM Do YYYY");
                    this.fhaconsultantagreement = {
                        ConsultantFee: 500,
                        CreatedBy: "admin@gmail.com",
                        CreatedDate: "2019-05-03T17:32:05.690Z",
                        Id: 7,
                        PropertyId: "18",
                        SignatureDateFHA: currentDate2,
                        Title: "  Agreement",
                        UpdateDate: "2019-05-03T17:32:05.690Z",
                        UpdatedBy: "admin@gmail.com",
                    };
                }
            },
            (error) => {
                this.sessionService.notifier.notify(
                    "error",
                    error.error.message
                );
                this.loading = false;
            }
        );
    }

    getFhaConsultantLiabilityDetails(propertyId) {
        var data = {
            PropertyId: propertyId,
        };
        this._bservice.GetFhaConsultantLiabilityDetails(data).subscribe(
            (response) => {
                if (response.Items.length > 0) {
                    this.fhaliabilityagreement = response.Items[0];
                } else {
                    this.fhaliabilityagreement = {
                        AgreementDate: "4/26/2019",
                        ChangeOrder: 204,
                        ChangeScopeWorkMax: 209,
                        ChangeScopeWorkMin: 208,
                        ConsultantFee: 500,
                        ConsultantFeeReimbursed: 200,
                        CreatedBy: "admin@gmail.com",
                        CreatedDate: "2019-05-03T17:58:01.691Z",
                        DrawRequestsMax: 206,
                        DrawRequestsMin: 205,
                        DrawingBelow100k: 201,
                        DrawingBetween100k150k: 202,
                        DrawingOver150k: 203,
                        Id: 6,
                        MultiFamilyDwelling: 300,
                        PropertyId: "18",
                        SelfHelpDraw: 207,
                        Title: " ",
                        UpdateDate: "2019-05-03T17:58:01.691Z",
                        UpdatedBy: "admin@gmail.com",
                        WorkPlan: 301,
                    };
                }
            },
            (error) => {
                this.sessionService.notifier.notify(
                    "error",
                    error.error.message
                );
                this.loading = false;
            }
        );
    }

    GetSORPDF() {
        this.loading = true;
        var data = {
            PropertyId: this.propertyId,
        };
        this._bservice.GetSORPDF(data).subscribe(
            (response) => {
                let url = this.baseurl + response.PdfPath;
                this.loading = false;
                window.open(url, "_blank");
            },
            (error) => {
                this.sessionService.notifier.notify(
                    "error",
                    error.error.message
                );
                this.loading = false;
            }
        );
    }

    GetDrawRequestList(PropertyId) {
        var drawdata = {
            PropertyId: PropertyId,
        };
        this.DrawRequestList = [];
        this._bservice.GetDrawRequestList(drawdata).subscribe(
            (DrawRequestresponse) => {
                if (DrawRequestresponse) {
                    DrawRequestresponse.Items.map((Item) => {
                        let createdBy = Item.GeneralContractorId;
                        this._bservice
                            .GetUserDetailsList(createdBy)
                            .subscribe((userresponse) => {
                                let createdByUserType = "";
                                let approvedByUserType = "";
                                if (userresponse) {
                                    if (userresponse.UserType == "Borrower") {
                                        createdByUserType = "BO";
                                        approvedByUserType = "GC";
                                    } else {
                                        createdByUserType = "GC";
                                        approvedByUserType = "BO";
                                    }
                                }
                                Item.NewGCRequestDate = moment(
                                    Item.GCRequestDate
                                ).format("MM/DD/YYYY");
                                Item.createdByUserType = createdByUserType;
                                Item.approvedByUserType = approvedByUserType;

                                this.DrawRequestList.push(Item);
                                this.DrawRequestList.sort(function (a, b) {
                                    return a.Id - b.Id;
                                });
                            });
                    });
                }
            },
            (error) => {
                this.sessionService.notifier.notify(
                    "error",
                    error.error.message
                );
                this.loading = false;
            }
        );
    }

    // downloadPDF () {
    //   let doc = new jsPDF();
    //   let specialElementHandlers = {
    //     "#editor": function (element, renderer) {
    //       return true;
    //     }
    //   };

    //   let content = this.content.nativeElement;
    //   doc.fromHTML(content.innerHTML, 15, 15, {
    //     'width': 190,
    //     'elementHandlers': specialElementHandlers
    //   });

    //   doc.output('dataurlnewwindow');

    // }

    sorEmailToBorrower(
        Propertyid,
        borrowerEmail,
        borrowerFirstTName,
        borrowerLastTName
    ) {
        var data = {
            EmailId: "",
            PropertyId: Propertyid,
            PageSize: 100000,
            LastEvaluatedKey: 0,
            UserType: this.sessionService.userType[1].Value,
        };

        this._boservice.GetSorDetails(data).subscribe((response) => {
            if (response != null && response.Items.length > 0) {
                this.loading = true;
                var repairTypesEmptyLocation = "";
                for (
                    let i = 0;
                    i < response.Items[0].Constructions.length;
                    i++
                ) {
                    for (
                        let j = 0;
                        j <
                        response.Items[0].Constructions[i].RepairItemDetails
                            .length;
                        j++
                    ) {
                        if (
                            response.Items[0].Constructions[i]
                                .RepairItemDetails[j].Location == "" ||
                            response.Items[0].Constructions[i]
                                .RepairItemDetails[j].Location == null ||
                            response.Items[0].Constructions[i]
                                .RepairItemDetails[j].Location == undefined
                        ) {
                            var indexText = repairTypesEmptyLocation.indexOf(
                                response.Items[0].Constructions[i].RepairType
                            );
                            if (indexText == -1) {
                                if (
                                    repairTypesEmptyLocation == "" ||
                                    repairTypesEmptyLocation == null ||
                                    repairTypesEmptyLocation == undefined
                                ) {
                                    repairTypesEmptyLocation =
                                        response.Items[0].Constructions[i]
                                            .RepairType;
                                } else {
                                    repairTypesEmptyLocation =
                                        repairTypesEmptyLocation +
                                        ", " +
                                        response.Items[0].Constructions[i]
                                            .RepairType;
                                }
                            }
                        }
                    }
                }
                if (
                    repairTypesEmptyLocation != "" &&
                    repairTypesEmptyLocation != null &&
                    repairTypesEmptyLocation != undefined
                ) {
                    this.sessionService.notifier.notify(
                        "error",
                        "Repair item location field should not blank. Please enter the location value in these Repair Types (" +
                            repairTypesEmptyLocation +
                            ") in Specification Of Repair Construction Detail."
                    );
                    this.loading = false;
                } else {
                    var data = {
                        PropertyId: Propertyid,
                        Id: response.Items[0].Id,
                        SorStatus: "sendForReview",
                        resendStatus: false,
                        borrowerEmail: borrowerEmail,
                        borrowerName:
                            borrowerFirstTName + " " + borrowerLastTName,
                    };
                    this._bservice.sendSorEmail(data).subscribe(
                        (response) => {
                            var propertyStatusData = {
                                Id: parseInt(
                                    this.route.snapshot.paramMap.get(
                                        "propertyId"
                                    )
                                ),
                                Status: "DRAFT SOR Sent to Borrower",
                                CreatedBy: this.userDetail.EmailId,
                                UpdatedBy: this.userDetail.EmailId,
                            };
                            this._bservice
                                .UpdatePropertyStatus(propertyStatusData)
                                .subscribe(
                                    (response) => {
                                        this.sessionService.notifier.notify(
                                            "success",
                                            "Send Email to Borrower successfully."
                                        );
                                        this.getPropertyDetails(
                                            this.propertyId
                                        );
                                        this.getFhaConsultantAgreementDetails(
                                            this.propertyId
                                        );
                                        this.getFhaConsultantLiabilityDetails(
                                            this.propertyId
                                        );
                                        this.getGCSOR(this.propertyId);
                                        this.loading = false;
                                    },
                                    (error) => {
                                        this.sessionService.notifier.notify(
                                            "error",
                                            error.error.message
                                        );
                                        this.loading = false;
                                    }
                                );
                        },
                        (error) => {
                            this.sessionService.notifier.notify(
                                "error",
                                error.error.message
                            );
                            this.loading = false;
                        }
                    );
                }
            }
        });
    }

    sorResendEmailToBorrower(
        Propertyid,
        borrowerEmail,
        borrowerFirstTName,
        borrowerLastTName
    ) {
        var data = {
            EmailId: "",
            PropertyId: Propertyid,
            PageSize: 100000,
            LastEvaluatedKey: 0,
            UserType: this.sessionService.userType[1].Value,
        };

        this._boservice.GetSorDetails(data).subscribe((response) => {
            if (response != null && response.Items.length > 0) {
                this.loading = true;
                var repairTypesEmptyLocation = "";
                for (
                    let i = 0;
                    i < response.Items[0].Constructions.length;
                    i++
                ) {
                    for (
                        let j = 0;
                        j <
                        response.Items[0].Constructions[i].RepairItemDetails
                            .length;
                        j++
                    ) {
                        if (
                            response.Items[0].Constructions[i]
                                .RepairItemDetails[j].Location == "" ||
                            response.Items[0].Constructions[i]
                                .RepairItemDetails[j].Location == null ||
                            response.Items[0].Constructions[i]
                                .RepairItemDetails[j].Location == undefined
                        ) {
                            var indexText = repairTypesEmptyLocation.indexOf(
                                response.Items[0].Constructions[i].RepairType
                            );
                            if (indexText == -1) {
                                if (
                                    repairTypesEmptyLocation == "" ||
                                    repairTypesEmptyLocation == null ||
                                    repairTypesEmptyLocation == undefined
                                ) {
                                    repairTypesEmptyLocation =
                                        response.Items[0].Constructions[i]
                                            .RepairType;
                                } else {
                                    repairTypesEmptyLocation =
                                        repairTypesEmptyLocation +
                                        ", " +
                                        response.Items[0].Constructions[i]
                                            .RepairType;
                                }
                            }
                        }
                    }
                }
                if (
                    repairTypesEmptyLocation != "" &&
                    repairTypesEmptyLocation != null &&
                    repairTypesEmptyLocation != undefined
                ) {
                    this.sessionService.notifier.notify(
                        "error",
                        "Repair item location field should not blank. Please enter the location value in these Repair Types (" +
                            repairTypesEmptyLocation +
                            ") in Specification Of Repair Construction Detail."
                    );
                    this.loading = false;
                } else {
                    var data = {
                        PropertyId: Propertyid,
                        Id: response.Items[0].Id,
                        SorStatus: "sendForReview",
                        resendStatus: true,
                        borrowerEmail: borrowerEmail,
                        borrowerName:
                            borrowerFirstTName + " " + borrowerLastTName,
                    };
                    this._bservice.sendSorEmail(data).subscribe(
                        (response) => {
                            var propertyStatusData = {
                                Id: parseInt(
                                    this.route.snapshot.paramMap.get(
                                        "propertyId"
                                    )
                                ),
                                Status: "DRAFT SOR Sent to Borrower",
                                CreatedBy: this.userDetail.EmailId,
                                UpdatedBy: this.userDetail.EmailId,
                            };
                            this._bservice
                                .UpdatePropertyStatus(propertyStatusData)
                                .subscribe(
                                    (response) => {
                                        this.sessionService.notifier.notify(
                                            "success",
                                            "Send Email to Borrower successfully."
                                        );
                                        this.getPropertyDetails(
                                            this.propertyId
                                        );
                                        this.getFhaConsultantAgreementDetails(
                                            this.propertyId
                                        );
                                        this.getFhaConsultantLiabilityDetails(
                                            this.propertyId
                                        );
                                        this.getGCSOR(this.propertyId);
                                        this.loading = false;
                                    },
                                    (error) => {
                                        this.sessionService.notifier.notify(
                                            "error",
                                            error.error.message
                                        );
                                        this.loading = false;
                                    }
                                );
                        },
                        (error) => {
                            this.sessionService.notifier.notify(
                                "error",
                                error.error.message
                            );
                            this.loading = false;
                        }
                    );
                }
            }
        });
    }
    downloadPDFCL() {
        this._bservice.downloadPDFCL(this.propertyId).subscribe(
            (response) => {
                var pdfData = this.baseurl + response;
                window.open(pdfData, "_blank");
                if (response) {
                }
            },
            (error) => {
                this.sessionService.notifier.notify(
                    "error",
                    error.error.message
                );
                this.loading = false;
            }
        );
    }
    downloadPDFCA() {
        this._bservice.downloadPDFCA(this.propertyId).subscribe(
            (response) => {
                var pdfData = this.baseurl + response;
                window.open(pdfData, "_blank");
                if (response) {
                }
            },
            (error) => {
                this.sessionService.notifier.notify(
                    "error",
                    error.error.message
                );
                this.loading = false;
            }
        );
    }
    downloadIdentityOfInterest() {
        this._bservice.downloadIdentityOfInterest(this.propertyId).subscribe(
            (response) => {
                var pdfData = this.baseurl + response;
                window.open(pdfData, "_blank");
                if (response) {
                }
            },
            (error) => {
                this.sessionService.notifier.notify(
                    "error",
                    error.error.message
                );
                this.loading = false;
            }
        );
    }
    downloadCertificationLetter() {
        this._bservice.downloadCertificationLetter(this.propertyId).subscribe(
            (response) => {
                var pdfData = this.baseurl + response;
                window.open(pdfData, "_blank");
                if (response) {
                }
            },
            (error) => {
                this.sessionService.notifier.notify(
                    "error",
                    error.error.message
                );
                this.loading = false;
            }
        );
    }

    printPdf(url) {
        window.open(url, "_blank");
    }

    editProperty() {
        var path = "/edit-properties/" + this.propertyId;
        this.router.navigate([path]);
    }

    downloadPDFNHID() {
        this._bservice.downloadNHID(this.propertyId).subscribe(
            (response) => {
                var pdfData = this.baseurl + response;
                window.open(pdfData, "_blank");
                if (response) {
                }
            },
            (error) => {
                this.sessionService.notifier.notify(
                    "error",
                    error.error.message
                );
                this.loading = false;
            }
        );
    }
    sendNonHomeToBO() {
        this._bservice.sendNonHomeToBO(this.propertyId).subscribe(
            (response) => {
                if (response == "success") {
                    this.sessionService.notifier.notify(
                        "success",
                        "Send Email to Borrower successfully."
                    );
                }
            },
            (error) => {
                this.sessionService.notifier.notify(
                    "error",
                    error.error.message
                );
                this.loading = false;
            }
        );
    }
    downloadZip() {
        this._bservice.downloadZip(this.propertyId).subscribe(
            (response) => {
                var pdfData = this.baseurl + response;
                window.open(pdfData, "_blank");
                if (response) {
                }
            },
            (error) => {
                this.sessionService.notifier.notify(
                    "error",
                    error.error.message
                );
                this.loading = false;
            }
        );
    }

    drawRequestPDF(propertyId) {
        var data = { propertyId: propertyId };
        this._bservice.downloadDrawRequestPDF(data).subscribe(
            (response) => {
                var pdfData = this.baseurl + response;
                window.open(pdfData, "_blank");
                if (response) {
                }
            },
            (error) => {
                this.sessionService.notifier.notify(
                    "error",
                    error.error.message
                );
                this.loading = false;
            }
        );
    }

    printDrawRequest(DrawId) {
        this.loading = true;
        var data = { DrawId: DrawId };
        this._bservice.DrawRequestPDF(data).subscribe(
            (response) => {
                this.loading = false;
                var pdfData = this.baseurl + response.PdfPath;
                window.open(pdfData, "_blank");
                if (response) {
                }
            },
            (error) => {
                this.sessionService.notifier.notify(
                    "error",
                    error.error.message
                );
                this.loading = false;
            }
        );
    }

    notAllowDraw() {
        this.sessionService.notifier.notify(
            "error",
            "There is a draw that has already been submitted to the consultant and has been accepted by both parties. If there needs to be a revision please contact your consultant directly."
        );
    }
    sendDrawRequestDocSign(DrawNumber, DrawId) {
        this.loading = true;
        var data = {
            DrawId: DrawId,
            DrawNumber: DrawNumber,
            SendDocSign: true,
        };
        this._bservice.DrawRequestPDF(data).subscribe(
            (response) => {
                if (response.PdfPath == "success") {
                    this.sessionService.notifier.notify(
                        "success",
                        "Draw Request Successfully Send For Document Sign !!"
                    );
                    this.loading = false;
                }
            },
            (error) => {
                this.sessionService.notifier.notify(
                    "error",
                    error.error.message
                );
                this.loading = false;
            }
        );
    }
    GetConsultantAgreement(propertyId) {
        this.loading = true;
        var currentDate = new Date();
        var data = {
            PropertyId: propertyId,
        };
        this._bservice.GetFhaConsultantAgreementDetails(data).subscribe(
            (response) => {
                if (response != null) {
                    this.add_conAgreement = response.Items[0];
                }
            },
            (error) => {}
        );
    }

    generateFinalSOR(Propertyid) {
        this.loading = true;
        this._bservice.SendFinalSORDocsign(Propertyid).subscribe(
            (response) => {
                let sendForSign = true;
                var data = {
                    PropertyId: parseInt(Propertyid),
                    GeneralContractorId: this.propertyDetails.AcceptedProposal,
                    sendForSign: sendForSign,
                };

                this._bservice.GetBORPDF(data).subscribe(
                    (gresponse) => {
                        if (gresponse) {
                            this.sessionService.notifier.notify(
                                "success",
                                "Send final SOR for digital signature successfully."
                            );
                            this.loading = false;
                        }
                    },
                    (error) => {
                        this.sessionService.notifier.notify(
                            "error",
                            error.error.message
                        );
                        this.loading = false;
                    }
                );
            },
            (error) => {
                this.sessionService.notifier.notify(
                    "error",
                    error.error.message
                );
                this.loading = false;
            }
        );
    }

    startPostClosingProcess(Propertyid) {
        var r = confirm(
            "Please confirm the loan has closed and you would like to start the post closing process. Please note you can’t undo this action once it is performed."
        );

        if (r == true) {
            var propertyStatusData = {
                Id: parseInt(Propertyid),
                Status: "Post closing started",
                CreatedBy: this.userDetail.EmailId,
                UpdatedBy: this.userDetail.EmailId,
            };
            this._bservice.UpdatePropertyStatus(propertyStatusData).subscribe(
                (response) => {
                    this.sessionService.notifier.notify(
                        "success",
                        "Post closing started successfully."
                    );
                    this.getPropertyDetails(this.propertyId);
                    this.loading = false;
                },
                (error) => {
                    this.sessionService.notifier.notify(
                        "error",
                        error.error.message
                    );
                    this.loading = false;
                }
            );
        }
    }
    printFinalSOR(Propertyid) {
        this.loading = true;
        this._bservice.PrintFinalSOR(Propertyid).subscribe(
            (response) => {
                if (response) {
                    let path = response;
                    for (let i = 0; i < path.length; i++) {
                        window.open(this.baseurl + path[i], "_blank");
                    }
                    this.loading = false;
                }
            },
            (error) => {
                this.sessionService.notifier.notify(
                    "error",
                    error.error.message
                );
                this.loading = false;
            }
        );
    }

    sendForDigitalSignature(Propertyid) {
        this.loading = true;
        this._bservice.SendSORForDocsign(Propertyid).subscribe(
            (response) => {
                if (response) {
                    this._bservice
                        .UpdateProperty(this.propertyDetails)
                        .subscribe(
                            (response) => {
                                this.sessionService.notifier.notify(
                                    "success",
                                    "Send for digital signature to BO and CO successfully."
                                );
                                this.loading = false;
                            },
                            (error) => {
                                this.sessionService.notifier.notify(
                                    "error",
                                    error.error.message
                                );
                                this.loading = false;
                            }
                        );
                }
            },
            (error) => {
                this.sessionService.notifier.notify(
                    "error",
                    error.error.message
                );
                this.loading = false;
            }
        );
    }
    DrawOrChangeOrderUpload() {
        this.docType = "DrawOrChangeOrderForms";
    }

    handleInputChange(e: any) {
        this.loading = true;

        for (let i = 0; i < e.target.files.length; i++) {
            var file = e.dataTransfer
                ? e.dataTransfer.files[i]
                : e.target.files[i];

            var pattern = /image-*/;
            var reader = new FileReader();

            this.saveToS3(file);

            reader.onload = this._handleReaderLoaded.bind(this);
            reader.readAsDataURL(file);
        }
        this.loading = false;
    }

    _handleReaderLoaded(e: any) {
        var reader = e.target;
    }

    saveToS3(fileInput: any) {
        const file = fileInput;
        const bucketName = this.settings.AwsS3BucketName
            ? this.settings.AwsS3BucketName
            : "";
        const accessKeyId = this.settings.AwsAccessKeyId
            ? this.settings.AwsAccessKeyId
            : "";
        const secretAccessKey = this.settings.AwsSecretAccessKey
            ? this.settings.AwsSecretAccessKey
            : "";
        const region = this.settings.AwsConfigRegion
            ? this.settings.AwsConfigRegion
            : "";

        const contentType = file.type;
        const bucket = new S3({
            accessKeyId: accessKeyId,
            secretAccessKey: secretAccessKey,
            region: region,
        });
        let tempFileName = file.name;
        var millis = Date.now();
        const propertyid = this.propertyId;
        const fileName =
            "image-" + millis + "." + file.name.split(".").pop().toLowerCase();

        const params = {
            Bucket: bucketName,
            Key: "property-images/" + propertyid + "/" + fileName,
            Body: file,
            StorageClass: "STANDARD",
            ACL: "bucket-owner-full-control",
            ContentType: contentType,
        };
        const _this = this;

        bucket.upload(params, function (err: any, data: any) {
            if (err) {
                alert(
                    "There was an error uploading your file: " +
                        JSON.stringify(err)
                );
                console.log("There was an error uploading your file: ", err);
                return false;
            } else {
                _this.savePropertyDocument(
                    tempFileName,
                    data.Key,
                    _this.docType
                );
                return true;
            }
        });
    }

    savePropertyDocument(fileName, path, docType) {
        this.loading = true;
        var data = {
            PropertyId: parseInt(this.propertyId),
            FilePath: path,
            Title: fileName,
            Type: docType,
            Ext: "Ext",
            CreatedBy: this.userDetail.Id,
            CreatedDate: "CreatedDate",
            UpdateDate: "UpdateDate",
            UpdatedBy: this.userDetail.Id,
        };

        this._bservice.SavePropertyDocument(data).subscribe(
            (response) => {
                this.getPropertyDocument(this.propertyId);
                this.sessionService.notifier.notify(
                    "success",
                    "Property Document Uploaded Successfully."
                );
                this.loading = false;
            },
            (error) => {
                this.sessionService.notifier.notify(
                    "error",
                    error.error.message
                );
                this.loading = false;
            }
        );
    }

    getPropertyDocument(propertyId) {
        var data = {
            PropertyId: parseInt(propertyId),
        };

        this._bservice.GetPropertyDocument(data).subscribe(
            (response) => {
                if (response != null) {
                    let DrawDocumentList = [];
                    let DocumentList = [];
                    this.propertyDrawDocumentList = DrawDocumentList;
                    this.propertyDocumentList = DocumentList;

                    for (let i = 0; i < response.Items.length; i++) {
                        if (
                            response.Items[i].Type == "DrawOrChangeOrderForms"
                        ) {
                            var rsc = response.Items[i].FilePath;
                            this.get_s3_signed_url_new(response.Items[i], rsc);
                        } else {
                            var rsc = response.Items[i].FilePath;
                            this.get_s3_signed_url_new(response.Items[i], rsc);
                        }
                    }
                } else {
                    let DrawDocumentList = [];
                    let DocumentList = [];
                    this.propertyDrawDocumentList = DrawDocumentList;
                    this.propertyDocumentList = DocumentList;
                }
            },
            (error) => {
                this.sessionService.notifier.notify(
                    "error",
                    error.error.message
                );
                this.loading = false;
            }
        );
    }
    get_s3_signed_url(resource, shouldOpen, envData) {
        const bucketName = this.settings.AwsS3BucketName
            ? this.settings.AwsS3BucketName
            : "";
        const accessKeyId = this.settings.AwsAccessKeyId
            ? this.settings.AwsAccessKeyId
            : "";
        const secretAccessKey = this.settings.AwsSecretAccessKey
            ? this.settings.AwsSecretAccessKey
            : "";
        const region = this.settings.AwsConfigRegion
            ? this.settings.AwsConfigRegion
            : "";

        const bucket = new S3({
            accessKeyId: accessKeyId,
            secretAccessKey: secretAccessKey,
            region: region,
        });
        const params = {
            Bucket: bucketName,
            Key: resource,
        };
        const _this = this;

        // _this.loading = true;
        bucket.getSignedUrl(
            "getObject",
            params,
            function (err: any, link: any) {
                if (err) {
                    if (shouldOpen) {
                        var url = encodeURI(
                            _this.baseurl +
                                "pdf-files/" +
                                envData.PropertyId +
                                "_" +
                                envData.EnvelopeId +
                                "_" +
                                envData.DocumentName +
                                ".pdf"
                        ).replace("#", "%23");
                        _this.http.get(url, { responseType: "blob" }).subscribe(
                            (response) => {
                                if (response.type === "application/pdf") {
                                    _this.getDoc(url).subscribe(
                                        (x) => {
                                            let fileInput: any = x;

                                            _this.saveToSignedEnvelopesInS3(
                                                envData,
                                                fileInput,
                                                url,
                                                shouldOpen
                                            );
                                        },
                                        (error) => {
                                            _this.loading = false;
                                        }
                                    );
                                } else {
                                    _this.loading = false;
                                    _this.sessionService.notifier.notify(
                                        "error",
                                        "You can contact with site administrator. This file (Envelope ID : " +
                                            envData.EnvelopeId +
                                            " ) does not exist."
                                    );
                                    let param = {
                                        envelopUrl: link,
                                        envId: envData.EnvelopeId,
                                        DocumentName: envData.DocumentName,
                                    };
                                    _this._bservice
                                        .signedDocError(param)
                                        .subscribe(
                                            (response) => {
                                                if (response == "success") {
                                                }
                                            },
                                            (error) => {
                                                _this.sessionService.notifier.notify(
                                                    "error",
                                                    error.error.message
                                                );
                                                _this.loading = false;
                                            }
                                        );
                                }
                            },
                            (error) => {
                                if (error.status === 200) {
                                    _this.getDoc(url).subscribe(
                                        (x) => {
                                            let fileInput: any = x;

                                            _this.saveToSignedEnvelopesInS3(
                                                envData,
                                                fileInput,
                                                url,
                                                shouldOpen
                                            );
                                        },
                                        (error) => {
                                            _this.loading = false;
                                        }
                                    );
                                } else if (error.status === 404) {
                                    _this.loading = false;
                                    _this.sessionService.notifier.notify(
                                        "error",
                                        "You can contact with site administrator. This file (Envelope ID : " +
                                            envData.EnvelopeId +
                                            " ) does not exist."
                                    );
                                    let param = {
                                        envelopUrl: link,
                                        envId: envData.EnvelopeId,
                                        DocumentName: envData.DocumentName,
                                    };
                                    _this._bservice
                                        .signedDocError(param)
                                        .subscribe(
                                            (response) => {
                                                if (response == "success") {
                                                }
                                            },
                                            (error) => {
                                                _this.sessionService.notifier.notify(
                                                    "error",
                                                    error.error.message
                                                );
                                                _this.loading = false;
                                            }
                                        );
                                } else {
                                    console.error(
                                        "An error occurred while checking the URL:",
                                        error
                                    );
                                }
                            }
                        );

                        alert(
                            "There was an error uploading your file: " +
                                JSON.stringify(err)
                        );
                        console.log(
                            "There was an error uploading your file: ",
                            err
                        );
                    }
                    return false;
                } else {
                    if (shouldOpen) {
                        _this.http
                            .get(link, { responseType: "blob" })
                            .subscribe(
                                (response) => {
                                    if (response.type === "application/pdf") {
                                        window.open(link, "_blank");
                                        _this.loading = false;
                                    } else {
                                        _this._bservice
                                            .GetCompletedsignedEnvelope(envData)
                                            .subscribe(
                                                (response) => {
                                                    setTimeout(() => {
                                                        var url = encodeURI(
                                                            _this.baseurl +
                                                                "pdf-files/" +
                                                                envData.PropertyId +
                                                                "_" +
                                                                envData.EnvelopeId +
                                                                "_" +
                                                                envData.DocumentName +
                                                                ".pdf"
                                                        ).replace("#", "%23");

                                                        _this.http
                                                            .get(url, {
                                                                responseType:
                                                                    "blob",
                                                            })
                                                            .subscribe(
                                                                (response) => {
                                                                    if (
                                                                        response.type ===
                                                                        "application/pdf"
                                                                    ) {
                                                                        _this
                                                                            .getDoc(
                                                                                url
                                                                            )
                                                                            .subscribe(
                                                                                (
                                                                                    x
                                                                                ) => {
                                                                                    let fileInput: any =
                                                                                        x;

                                                                                    _this.saveToSignedEnvelopesInS3(
                                                                                        envData,
                                                                                        fileInput,
                                                                                        url,
                                                                                        shouldOpen
                                                                                    );
                                                                                },
                                                                                (
                                                                                    error
                                                                                ) => {
                                                                                    _this.loading =
                                                                                        false;
                                                                                }
                                                                            );
                                                                    } else {
                                                                        _this.loading =
                                                                            false;
                                                                    }
                                                                },
                                                                (error) => {
                                                                    if (
                                                                        error.status ===
                                                                        200
                                                                    ) {
                                                                        _this
                                                                            .getDoc(
                                                                                url
                                                                            )
                                                                            .subscribe(
                                                                                (
                                                                                    x
                                                                                ) => {
                                                                                    let fileInput: any =
                                                                                        x;

                                                                                    _this.saveToSignedEnvelopesInS3(
                                                                                        envData,
                                                                                        fileInput,
                                                                                        url,
                                                                                        shouldOpen
                                                                                    );
                                                                                },
                                                                                (
                                                                                    error
                                                                                ) => {
                                                                                    _this.loading =
                                                                                        false;
                                                                                }
                                                                            );
                                                                    } else if (
                                                                        error.status ===
                                                                        404
                                                                    ) {
                                                                        _this.loading =
                                                                            false;
                                                                    } else {
                                                                        console.error(
                                                                            "An error occurred while checking the URL:",
                                                                            error
                                                                        );
                                                                    }
                                                                }
                                                            );
                                                    }, 2500);
                                                },
                                                (error) => {
                                                    _this.loading = false;
                                                }
                                            );
                                    }
                                },
                                (error) => {
                                    if (error.status === 200) {
                                        _this.getDoc(url).subscribe(
                                            (x) => {
                                                let fileInput: any = x;

                                                _this.saveToSignedEnvelopesInS3(
                                                    envData,
                                                    fileInput,
                                                    url,
                                                    shouldOpen
                                                );
                                            },
                                            (error) => {
                                                _this.loading = false;
                                            }
                                        );
                                    } else if (error.status === 404) {
                                        _this._bservice
                                            .GetCompletedsignedEnvelope(envData)
                                            .subscribe(
                                                (response) => {
                                                    setTimeout(() => {
                                                        var url = encodeURI(
                                                            _this.baseurl +
                                                                "pdf-files/" +
                                                                envData.PropertyId +
                                                                "_" +
                                                                envData.EnvelopeId +
                                                                "_" +
                                                                envData.DocumentName +
                                                                ".pdf"
                                                        ).replace("#", "%23");

                                                        _this.http
                                                            .get(url, {
                                                                responseType:
                                                                    "blob",
                                                            })
                                                            .subscribe(
                                                                (response) => {
                                                                    if (
                                                                        response.type ===
                                                                        "application/pdf"
                                                                    ) {
                                                                        _this
                                                                            .getDoc(
                                                                                url
                                                                            )
                                                                            .subscribe(
                                                                                (
                                                                                    x
                                                                                ) => {
                                                                                    let fileInput: any =
                                                                                        x;

                                                                                    _this.saveToSignedEnvelopesInS3(
                                                                                        envData,
                                                                                        fileInput,
                                                                                        url,
                                                                                        shouldOpen
                                                                                    );
                                                                                },
                                                                                (
                                                                                    error
                                                                                ) => {
                                                                                    _this.loading =
                                                                                        false;
                                                                                }
                                                                            );
                                                                    } else {
                                                                        _this.loading =
                                                                            false;
                                                                    }
                                                                },
                                                                (error) => {
                                                                    if (
                                                                        error.status ===
                                                                        200
                                                                    ) {
                                                                        _this
                                                                            .getDoc(
                                                                                url
                                                                            )
                                                                            .subscribe(
                                                                                (
                                                                                    x
                                                                                ) => {
                                                                                    let fileInput: any =
                                                                                        x;

                                                                                    _this.saveToSignedEnvelopesInS3(
                                                                                        envData,
                                                                                        fileInput,
                                                                                        url,
                                                                                        shouldOpen
                                                                                    );
                                                                                },
                                                                                (
                                                                                    error
                                                                                ) => {
                                                                                    _this.loading =
                                                                                        false;
                                                                                }
                                                                            );
                                                                    } else if (
                                                                        error.status ===
                                                                        404
                                                                    ) {
                                                                        _this.loading =
                                                                            false;
                                                                    } else {
                                                                        console.error(
                                                                            "An error occurred while checking the URL:",
                                                                            error
                                                                        );
                                                                    }
                                                                }
                                                            );
                                                    }, 2500);
                                                },
                                                (error) => {
                                                    _this.loading = false;
                                                }
                                            );
                                    } else {
                                        console.error(
                                            "An error occurred while checking the URL:",
                                            error
                                        );
                                    }
                                }
                            );
                    } else {
                        if (envData.UploadedStatus != 1) {
                            _this._bservice
                                .GetCompletedsignedEnvelope(envData)
                                .subscribe(
                                    (response) => {
                                        setTimeout(() => {
                                            var url = encodeURI(
                                                _this.baseurl +
                                                    "pdf-files/" +
                                                    envData.PropertyId +
                                                    "_" +
                                                    envData.EnvelopeId +
                                                    "_" +
                                                    envData.DocumentName +
                                                    ".pdf"
                                            ).replace("#", "%23");

                                            _this.http
                                                .get(url, {
                                                    responseType: "blob",
                                                })
                                                .subscribe(
                                                    (response) => {
                                                        if (
                                                            response.type ===
                                                            "application/pdf"
                                                        ) {
                                                            _this
                                                                .getDoc(url)
                                                                .subscribe(
                                                                    (x) => {
                                                                        let fileInput: any =
                                                                            x;
                                                                        _this.saveToSignedEnvelopesInS3(
                                                                            envData,
                                                                            fileInput,
                                                                            url,
                                                                            shouldOpen
                                                                        );
                                                                        _this.loading =
                                                                            false;
                                                                    },
                                                                    (error) => {
                                                                        _this.loading =
                                                                            false;
                                                                    }
                                                                );
                                                        } else {
                                                            _this._bservice
                                                                .GetCompletedsignedEnvelope(
                                                                    envData
                                                                )
                                                                .subscribe(
                                                                    (
                                                                        response
                                                                    ) => {
                                                                        setTimeout(
                                                                            () => {
                                                                                _this.http
                                                                                    .get(
                                                                                        url,
                                                                                        {
                                                                                            responseType:
                                                                                                "blob",
                                                                                        }
                                                                                    )
                                                                                    .subscribe(
                                                                                        (
                                                                                            response
                                                                                        ) => {
                                                                                            if (
                                                                                                response.type ===
                                                                                                "application/pdf"
                                                                                            ) {
                                                                                                _this
                                                                                                    .getDoc(
                                                                                                        url
                                                                                                    )
                                                                                                    .subscribe(
                                                                                                        (
                                                                                                            x
                                                                                                        ) => {
                                                                                                            let fileInput: any =
                                                                                                                x;

                                                                                                            _this.saveToSignedEnvelopesInS3(
                                                                                                                envData,
                                                                                                                fileInput,
                                                                                                                url,
                                                                                                                shouldOpen
                                                                                                            );
                                                                                                            _this.loading =
                                                                                                                false;
                                                                                                        },
                                                                                                        (
                                                                                                            error
                                                                                                        ) => {
                                                                                                            _this.loading =
                                                                                                                false;
                                                                                                        }
                                                                                                    );
                                                                                            } else {
                                                                                                _this.loading =
                                                                                                    false;
                                                                                            }
                                                                                        },
                                                                                        (
                                                                                            error
                                                                                        ) => {
                                                                                            if (
                                                                                                error.status ===
                                                                                                200
                                                                                            ) {
                                                                                                _this
                                                                                                    .getDoc(
                                                                                                        url
                                                                                                    )
                                                                                                    .subscribe(
                                                                                                        (
                                                                                                            x
                                                                                                        ) => {
                                                                                                            let fileInput: any =
                                                                                                                x;
                                                                                                            _this.saveToSignedEnvelopesInS3(
                                                                                                                envData,
                                                                                                                fileInput,
                                                                                                                url,
                                                                                                                shouldOpen
                                                                                                            );
                                                                                                            _this.loading =
                                                                                                                false;
                                                                                                        },
                                                                                                        (
                                                                                                            error
                                                                                                        ) => {
                                                                                                            _this.loading =
                                                                                                                false;
                                                                                                        }
                                                                                                    );
                                                                                            } else if (
                                                                                                error.status ===
                                                                                                404
                                                                                            ) {
                                                                                                _this.loading =
                                                                                                    false;
                                                                                            } else {
                                                                                                console.error(
                                                                                                    "An error occurred while checking the URL:",
                                                                                                    error
                                                                                                );
                                                                                            }
                                                                                        }
                                                                                    );
                                                                            },
                                                                            2500
                                                                        );
                                                                    },
                                                                    (error) => {
                                                                        _this.loading =
                                                                            false;
                                                                    }
                                                                );
                                                        }
                                                    },
                                                    (error) => {
                                                        if (
                                                            error.status === 200
                                                        ) {
                                                            _this
                                                                .getDoc(url)
                                                                .subscribe(
                                                                    (x) => {
                                                                        let fileInput: any =
                                                                            x;
                                                                        _this.saveToSignedEnvelopesInS3(
                                                                            envData,
                                                                            fileInput,
                                                                            url,
                                                                            shouldOpen
                                                                        );
                                                                        _this.loading =
                                                                            false;
                                                                    },
                                                                    (error) => {
                                                                        _this.loading =
                                                                            false;
                                                                    }
                                                                );
                                                        } else if (
                                                            error.status === 404
                                                        ) {
                                                            _this._bservice
                                                                .GetCompletedsignedEnvelope(
                                                                    envData
                                                                )
                                                                .subscribe(
                                                                    (
                                                                        response
                                                                    ) => {
                                                                        setTimeout(
                                                                            () => {
                                                                                _this.http
                                                                                    .get(
                                                                                        url,
                                                                                        {
                                                                                            responseType:
                                                                                                "blob",
                                                                                        }
                                                                                    )
                                                                                    .subscribe(
                                                                                        (
                                                                                            response
                                                                                        ) => {
                                                                                            if (
                                                                                                response.type ===
                                                                                                "application/pdf"
                                                                                            ) {
                                                                                                _this
                                                                                                    .getDoc(
                                                                                                        url
                                                                                                    )
                                                                                                    .subscribe(
                                                                                                        (
                                                                                                            x
                                                                                                        ) => {
                                                                                                            let fileInput: any =
                                                                                                                x;

                                                                                                            _this.saveToSignedEnvelopesInS3(
                                                                                                                envData,
                                                                                                                fileInput,
                                                                                                                url,
                                                                                                                shouldOpen
                                                                                                            );
                                                                                                            _this.loading =
                                                                                                                false;
                                                                                                        },
                                                                                                        (
                                                                                                            error
                                                                                                        ) => {
                                                                                                            _this.loading =
                                                                                                                false;
                                                                                                        }
                                                                                                    );
                                                                                            } else {
                                                                                                _this.loading =
                                                                                                    false;
                                                                                            }
                                                                                        },
                                                                                        (
                                                                                            error
                                                                                        ) => {
                                                                                            if (
                                                                                                error.status ===
                                                                                                200
                                                                                            ) {
                                                                                                _this
                                                                                                    .getDoc(
                                                                                                        url
                                                                                                    )
                                                                                                    .subscribe(
                                                                                                        (
                                                                                                            x
                                                                                                        ) => {
                                                                                                            let fileInput: any =
                                                                                                                x;
                                                                                                            _this.saveToSignedEnvelopesInS3(
                                                                                                                envData,
                                                                                                                fileInput,
                                                                                                                url,
                                                                                                                shouldOpen
                                                                                                            );
                                                                                                            _this.loading =
                                                                                                                false;
                                                                                                        },
                                                                                                        (
                                                                                                            error
                                                                                                        ) => {
                                                                                                            _this.loading =
                                                                                                                false;
                                                                                                        }
                                                                                                    );
                                                                                            } else if (
                                                                                                error.status ===
                                                                                                404
                                                                                            ) {
                                                                                                _this.loading =
                                                                                                    false;
                                                                                            } else {
                                                                                                console.error(
                                                                                                    "An error occurred while checking the URL:",
                                                                                                    error
                                                                                                );
                                                                                            }
                                                                                        }
                                                                                    );
                                                                            },
                                                                            2500
                                                                        );
                                                                    },
                                                                    (error) => {
                                                                        _this.loading =
                                                                            false;
                                                                    }
                                                                );
                                                        } else {
                                                            console.error(
                                                                "An error occurred while checking the URL:",
                                                                error
                                                            );
                                                        }
                                                    }
                                                );
                                        }, 2500);
                                    },
                                    (error) => {}
                                );
                        }
                    }
                }
            }
        );
    }

    get_s3_signed_url_new(data, resource) {
        const bucketName = this.settings.AwsS3BucketName
            ? this.settings.AwsS3BucketName
            : "";
        const accessKeyId = this.settings.AwsAccessKeyId
            ? this.settings.AwsAccessKeyId
            : "";
        const secretAccessKey = this.settings.AwsSecretAccessKey
            ? this.settings.AwsSecretAccessKey
            : "";
        const region = this.settings.AwsConfigRegion
            ? this.settings.AwsConfigRegion
            : "";

        const bucket = new S3({
            accessKeyId: accessKeyId,
            secretAccessKey: secretAccessKey,
            region: region,
        });
        const params = {
            Bucket: bucketName,
            Key: resource,
        };
        const _this = this;
        bucket.getSignedUrl(
            "getObject",
            params,
            function (err: any, link: any) {
                if (err) {
                    alert(
                        "There was an error uploading your file: " +
                            JSON.stringify(err)
                    );
                    console.log(
                        "There was an error uploading your file: ",
                        err
                    );
                    return false;
                } else {
                    if (data.Type == "DrawOrChangeOrderForms") {
                        data.FilePath = link;
                        _this.propertyDrawDocumentList.push(data);
                    } else {
                        data.FilePath = link;
                        _this.propertyDocumentList.push(data);
                    }
                }
            }
        );
    }
    getSignatureKey(accesskey, dateStamp, regionName, serviceName) {
        var crypto = require("crypto-js");
        var kDate = crypto.HmacSHA256(dateStamp, "AWS4" + accesskey);
        var kRegion = crypto.HmacSHA256(regionName, kDate);
        var kService = crypto.HmacSHA256(serviceName, kRegion);
        var kSigning = crypto.HmacSHA256("aws4_request", kService);
        return kSigning;
    }
    deleteUploadedFile(id) {
        var r = confirm("Are you sure? you want to delete this document.");

        if (r == true) {
            this._bservice.DeletePropertyDocument(id).subscribe(
                (response) => {
                    if (response != null) {
                        this.getPropertyDocument(this.propertyId);
                    }
                },
                (error) => {
                    this.sessionService.notifier.notify(
                        "error",
                        error.error.message
                    );
                    this.loading = false;
                }
            );
        }
    }

    downloadPropertyDoc(path) {
        window.open(path, "_blank");
    }

    removeAlbum(propertyId, albumIndex) {
        this._bservice
            .GetPropertyDetails(this.propertyId)
            .subscribe((response) => {
                if (response.GoogleAlbum != null) {
                    let albumDataArray: any = [];
                    let albumData: any = {};
                    for (let i = 0; i < response.GoogleAlbum.length; i++) {
                        if (albumIndex != i) {
                            albumData = {
                                AlbumTitle: response.GoogleAlbum[i].AlbumTitle,
                                AlbumType: response.GoogleAlbum[i].AlbumType,
                                GoogleAlbum:
                                    response.GoogleAlbum[i].GoogleAlbum,
                            };
                            albumDataArray.push(albumData);
                        }
                    }

                    var data = {
                        Id: parseInt(propertyId),
                        GoogleAlbum: albumDataArray,
                        CreatedBy: this.userDetail.EmailId,
                    };

                    this._bservice.UpdatePropertyGoogleAlbum(data).subscribe(
                        (response) => {
                            if (response != null) {
                                this.sessionService.notifier.notify(
                                    "success",
                                    "Property Album Successfully Deleted."
                                );
                                this.getPropertyDetails(propertyId);
                            }
                        },
                        (error) => {}
                    );
                } else {
                    this.sessionService.notifier.notify(
                        "error",
                        "Property Album Does Not Exist."
                    );
                }
            });
    }
    GetBlankBORPdf(propertyId) {
        var data = {
            PropertyId: propertyId,
            GeneralContractorId: 0,
        };

        this._bservice.GetBlankBORPDF(data).subscribe(
            (response) => {
                window.open(this.baseurl + response.PdfPath, "_blank");
                this.loading = false;
            },
            (error) => {
                this.sessionService.notifier.notify(
                    "error",
                    error.error.message
                );
                this.loading = false;
            }
        );
    }

    isArray(val) {
        if (Array.isArray(val)) {
            return true;
        } else {
            return false;
        }
    }
    onScroll($event) {
        if ($event.target.scrollTop >= 800) {
            this.showLetStartAndTopBtn = true;
        } else {
            this.showLetStartAndTopBtn = false;
        }
    }
}

/** Builds and returns a new User. */
function createNewUser(id: number, userList): UserData {
    const name =
        NAMES[Math.round(Math.random() * (NAMES.length - 1))] +
        " " +
        NAMES[Math.round(Math.random() * (NAMES.length - 1))].charAt(0) +
        ".";
    if (userList.Items[id] != undefined) {
        userList.Items[id].id = id + 1;
        return userList.Items[id];
    }
}
