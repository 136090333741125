import {
    Component,
    ElementRef,
    Inject,
    OnInit,
    ViewChild,
} from "@angular/core";
import {
    FormGroup,
    Validators,
    FormControl,
    ReactiveFormsModule,
    FormArray,
    FormBuilder,
} from "@angular/forms";
import { MatInput } from "@angular/material/input";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { DataSource } from "@angular/cdk/table";
import { BorrowerService } from "../borrower/borrower.service";
import { SessionService } from "../service/session.service";
import { ActivatedRoute, Router } from "@angular/router";
import { PropertyService } from "../property/property.service";

export interface UserData {
    id: string;
    name: string;
    email: string;
    lastLogin: Date;
}

/** Constants used to fill up our data base. */
const COLORS: string[] = [
    "maroon",
    "red",
    "orange",
    "yellow",
    "olive",
    "green",
    "purple",
    "fuchsia",
    "lime",
    "teal",
    "aqua",
    "blue",
    "navy",
    "black",
    "gray",
];
const NAMES: string[] = [
    "Maia",
    "Asher",
    "Olivia",
    "Atticus",
    "Amelia",
    "Jack",
    "Charlotte",
    "Theodore",
    "Isla",
    "Oliver",
    "Isabella",
    "Jasper",
    "Cora",
    "Levi",
    "Violet",
    "Arthur",
    "Mia",
    "Thomas",
    "Elizabeth",
];

/**
 * @title Data table with sorting, pagination, and filtering.
 */

@Component({
    selector: "app-gc-submitted-bids",
    templateUrl: "./gc-submitted-bids.component.html",
    styleUrls: ["./gc-submitted-bids.component.scss"],
})
export class GcSubmittedBidsComponent implements OnInit {
    displayedColumns: string[] = [
        "id",
        "RepairType",
        "RepairTotal",
        "Operation",
    ];
    baseurl = this.sessionService.endpoint;
    dataSource: MatTableDataSource<UserData>;
    mobnumPattern = "^(d{3})sd{3}-d{4}";

    review_status = new FormGroup({
        Comment: new FormControl(null, [Validators.maxLength(1000)]),
    });
    public RepairItemForm: FormGroup;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild("input2") input2: ElementRef;
    isList: boolean = true;
    isAdd: boolean = false;
    isEdit: boolean = false;
    submitted: boolean = false;
    favoriteSeason: string;
    userList: any = {};
    activeGCResList: any = [];
    submittedBidList: any = {};
    userDetail: any = {};
    RepairPropertyId: any;
    loading = false;
    pdfData: any;
    GCBidData: any;
    formStatus = false;
    SubmitBidHeaderList: any = [];
    allowableFeesData: any = {};
    permitCostTypeData: any = {};
    checkConstructionsDetails = false;
    propertyRepairConstructionDetail: any;
    getRepairConstructionList: any = {};
    permitCostonReset: any = [];
    GCBidList: any = {};
    reviseComment: boolean = false;
    reviseCommentId: any;
    GcId = 0;
    propertyDetails: any;
    status: any = ["Blocked", "Active"];
    roles: any = [
        { Key: "1", Value: "authenticated user" },
        { Key: "2", Value: "Administrator" },
        { Key: "3", Value: "Consultants" },
        { Key: "4", Value: "General Contractors" },
        { Key: "5", Value: "Borrower" },
        { Key: "6", Value: "Loan Officer" },
        { key: "7", Value: "Loan Processor" },
        { key: "8", Value: " Notify user of new account" },
        { key: "9", Value: "Draw Administrator" },
    ];
    constructor(
        private _bservice: BorrowerService,
        @Inject(ActivatedRoute) private route: ActivatedRoute,
        @Inject(Router) private router: Router,
        private sessionService: SessionService,
        private propertyService: PropertyService,
        @Inject(FormBuilder) private _fb: FormBuilder
    ) {}

    ngOnInit() {
        var propertyId = this.route.snapshot.paramMap.get("propertyId");
        this.RepairPropertyId = propertyId;
        this.getPropertyDetails(this.RepairPropertyId);
        this.getRepairItem(this.RepairPropertyId);
        if (this.sessionService.get("userInfo") != undefined) {
            this.userDetail = JSON.parse(this.sessionService.get("userInfo"));
        }
    }
    getPropertyDetails(propertyId) {
        this.propertyService
            .GetPropertyDetails(propertyId)
            .subscribe((response) => {
                if (response != null) {
                    this.propertyDetails = response;
                }
            });
    }
    getSORPDF() {
        var data = {
            PropertyId: parseInt(this.RepairPropertyId),
        };

        this.propertyService.GetSORPDF(data).subscribe(
            (response) => {
                window.open(this.baseurl + response.PdfPath, "_blank");
            },
            (error) => {
                this.sessionService.notifier.notify(
                    "error",
                    error.error.message
                );
                this.loading = false;
            }
        );
    }

    applyFilter(filterValue) {
        const target = filterValue as HTMLInputElement;
        const targetValue = target.value;
        this.dataSource.filter = targetValue.trim().toLowerCase();

        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    }

    showAddForm() {
        this.isAdd = true;
        this.isList = false;
    }

    getHeaderList(GCResList) {
        var data = {
            EmailId: "",
            PageSize: 100000,
            LastEvaluatedKey: 0,
            UserType: this.sessionService.userType[3].Value,
        };
        this.loading = true;
        this.SubmitBidHeaderList = [];
        this.SubmitBidHeaderList.push({ id: "", name: "SOR DRAFT" });
        this._bservice.GetUserList(data).subscribe(
            (response) => {
                if (response != null) {
                    for (let i = 0; i < GCResList.length; i++) {
                        let GC = response.Items.filter((item) => {
                            return GCResList[i].GeneralContractorId == item.Id;
                        });

                        if (GCResList[i].Status != "Pending" && GC.length > 0) {
                            this.activeGCResList.push(GCResList[i].Id);
                            this.SubmitBidHeaderList.push({
                                id: GC[0].Id,
                                name: GC[0].FirstName + " " + GC[0].LastName,
                            });
                            this.loading = false;
                        }
                    }
                }
            },
            (error) => {}
        );
    }

    getBorPDF(gcId, docSign = false) {
        let sendForSign = false;
        if (docSign) {
            sendForSign = true;
        }
        var data = {
            PropertyId: parseInt(this.RepairPropertyId),
            GeneralContractorId: gcId,
            sendForSign: sendForSign,
        };

        this.propertyService.GetBORPDF(data).subscribe(
            (response) => {
                if (docSign != true) {
                    this.pdfData = this.baseurl + response;
                    window.open(this.pdfData, "_blank");
                    if (response) {
                    }
                }
            },
            (error) => {
                this.sessionService.notifier.notify(
                    "error",
                    error.error.message
                );
                this.loading = false;
            }
        );
    }
    getRepairItem(RepairPropertyId) {
        this.loading = true;
        var data = {
            EmailId: "",
            PropertyId: RepairPropertyId,
            PageSize: 100000,
            LastEvaluatedKey: 0,
            UserType: this.sessionService.userType[1].Value,
        };
        this._bservice.GetSorDetails(data).subscribe(
            (response) => {
                if (response != null) {
                    this.getRepairConstructionList = response.Items[0];

                    if (this.getRepairConstructionList) {
                        this.submittedBidList = [
                            {
                                id: 1,
                                RepairType: "Masonry",
                                RepairTotal: [],
                            },
                            {
                                id: 2,
                                RepairType: "Siding",
                                RepairTotal: [],
                            },
                            {
                                id: 3,
                                RepairType: "Gutters/Downspouts",
                                RepairTotal: [],
                            },
                            {
                                id: 4,
                                RepairType: "Roof",
                                RepairTotal: [],
                            },
                            {
                                id: 5,
                                RepairType: "Shutters",
                                RepairTotal: [],
                            },
                            {
                                id: 6,
                                RepairType: "Exteriors",
                                RepairTotal: [],
                            },
                            {
                                id: 7,
                                RepairType: "Walks",
                                RepairTotal: [],
                            },
                            {
                                id: 8,
                                RepairType: "Driveways",
                                RepairTotal: [],
                            },
                            {
                                id: 9,
                                RepairType: "Painting (Ext)",
                                RepairTotal: [],
                            },
                            {
                                id: 10,
                                RepairType: "Caulking",
                                RepairTotal: [],
                            },
                            {
                                id: 11,
                                RepairType: "Fencing",
                                RepairTotal: [],
                            },
                            {
                                id: 12,
                                RepairType: "Grading",
                                RepairTotal: [],
                            },
                            {
                                id: 13,
                                RepairType: "Windows",
                                RepairTotal: [],
                            },
                            {
                                id: 14,
                                RepairType: "Weatherstrip",
                                RepairTotal: [],
                            },
                            {
                                id: 15,
                                RepairType: "Doors (Ext)	",
                                RepairTotal: [],
                            },
                            {
                                id: 16,
                                RepairType: "Doors (Int)	",
                                RepairTotal: [],
                            },
                            {
                                id: 17,
                                RepairType: "Partition Wall",
                                RepairTotal: [],
                            },
                            {
                                id: 18,
                                RepairType: "Plaster/Drywall",
                                RepairTotal: [],
                            },
                            {
                                id: 19,
                                RepairType: "Decorating",
                                RepairTotal: [],
                            },
                            {
                                id: 20,
                                RepairType: "Wood Trim	",
                                RepairTotal: [],
                            },
                            {
                                id: 21,
                                RepairType: "Stairs",
                                RepairTotal: [],
                            },
                            {
                                id: 22,
                                RepairType: "Closets",
                                RepairTotal: [],
                            },
                            {
                                id: 23,
                                RepairType: "Wood Floors",
                                RepairTotal: [],
                            },
                            {
                                id: 24,
                                RepairType: "Finished Floors",
                                RepairTotal: [],
                            },
                            {
                                id: 25,
                                RepairType: "Ceramic Tile",
                                RepairTotal: [],
                            },
                            {
                                id: 26,
                                RepairType: "Bath Accesories",
                                RepairTotal: [],
                            },
                            {
                                id: 27,
                                RepairType: "Plumbing",
                                RepairTotal: [],
                            },
                            {
                                id: 28,
                                RepairType: "Electrical",
                                RepairTotal: [],
                            },
                            {
                                id: 29,
                                RepairType: "Heating",
                                RepairTotal: [],
                            },
                            {
                                id: 30,
                                RepairType: "Insulation",
                                RepairTotal: [],
                            },
                            {
                                id: 31,
                                RepairType: "Cabinetry",
                                RepairTotal: [],
                            },
                            {
                                id: 32,
                                RepairType: "Appliances",
                                RepairTotal: [],
                            },
                            {
                                id: 33,
                                RepairType: "Basements",
                                RepairTotal: [],
                            },
                            {
                                id: 34,
                                RepairType: "Cleanup",
                                RepairTotal: [],
                            },
                            {
                                id: 35,
                                RepairType: "Miscellaneous",
                                RepairTotal: [],
                            },
                            {
                                id: 36,
                                RepairType: "Grand Total",
                                RepairTotal: [],
                                Operation: "Inactive",
                            },
                            {
                                id: 37,
                                RepairType: "Approval",
                                RepairTotal: [],
                                Operation: "Inactive",
                                Status: "",
                                StatusId: "",
                            },
                        ];

                        var setItem = this.getRepairConstructionList;
                        var GCSORdata = {
                            PropertyId: RepairPropertyId,
                        };
                        this._bservice.GetGCSorDetails(GCSORdata).subscribe(
                            (GCresponse) => {
                                let newResponseArray: any = [];
                                let alreadyGc = [];
                                for (
                                    let i = 0;
                                    i <
                                    this.propertyDetails
                                        .InvitedGeneralContractors.length;
                                    i++
                                ) {
                                    for (
                                        let j = 0;
                                        j < GCresponse.Items.length;
                                        j++
                                    ) {
                                        if (
                                            GCresponse.Items[j]
                                                .GeneralContractorId ==
                                            this.propertyDetails
                                                .InvitedGeneralContractors[i]
                                        ) {
                                            if (
                                                alreadyGc.indexOf(
                                                    GCresponse.Items[j]
                                                        .GeneralContractorId
                                                ) !== -1
                                            ) {
                                                continue;
                                            }
                                            newResponseArray.push(
                                                GCresponse.Items[j]
                                            );
                                            alreadyGc.push(
                                                GCresponse.Items[j]
                                                    .GeneralContractorId
                                            );
                                        }
                                    }
                                }
                                GCresponse.Items = [];
                                for (
                                    let i = 0;
                                    i < newResponseArray.length;
                                    i++
                                ) {
                                    GCresponse.Items.push(newResponseArray[i]);
                                }

                                this.getHeaderList(GCresponse.Items);
                                this.GCBidList = GCresponse.Items;

                                if (GCresponse.Items.length > 0) {
                                    for (
                                        let j = 0;
                                        j < this.submittedBidList.length;
                                        j++
                                    ) {
                                        var subsorTotalCost: number = 0;
                                        for (
                                            let k = 0;
                                            k <
                                            this.getRepairConstructionList
                                                .Constructions.length;
                                            k++
                                        ) {
                                            if (
                                                this.submittedBidList[j]
                                                    .RepairType ==
                                                this.getRepairConstructionList
                                                    .Constructions[k].RepairType
                                            ) {
                                                for (
                                                    let l = 0;
                                                    l <
                                                    this
                                                        .getRepairConstructionList
                                                        .Constructions[k]
                                                        .RepairItemDetails
                                                        .length;
                                                    l++
                                                ) {
                                                    subsorTotalCost +=
                                                        parseFloat(
                                                            this
                                                                .getRepairConstructionList
                                                                .Constructions[
                                                                k
                                                            ].RepairItemDetails[
                                                                l
                                                            ].LaborUnitCost
                                                        ) *
                                                            parseFloat(
                                                                this
                                                                    .getRepairConstructionList
                                                                    .Constructions[
                                                                    k
                                                                ]
                                                                    .RepairItemDetails[
                                                                    l
                                                                ].LaborQuantity
                                                            ) +
                                                        parseFloat(
                                                            this
                                                                .getRepairConstructionList
                                                                .Constructions[
                                                                k
                                                            ].RepairItemDetails[
                                                                l
                                                            ].MaterialUnitCost
                                                        ) *
                                                            parseFloat(
                                                                this
                                                                    .getRepairConstructionList
                                                                    .Constructions[
                                                                    k
                                                                ]
                                                                    .RepairItemDetails[
                                                                    l
                                                                ]
                                                                    .MaterialQuantity
                                                            );
                                                }
                                            }
                                        }
                                        if (j != 35 && j != 36) {
                                            this.submittedBidList[
                                                j
                                            ].RepairTotal.push(subsorTotalCost);
                                        }
                                        if (j == 36) {
                                            this.submittedBidList[
                                                j
                                            ].RepairTotal.push(0);
                                        }
                                        if (j != 35 && j != 36) {
                                            if (
                                                this.submittedBidList[35]
                                                    .RepairTotal[0] == 0 ||
                                                (this.submittedBidList[35]
                                                    .RepairTotal[0] != "" &&
                                                    this.submittedBidList[35]
                                                        .RepairTotal[0] !=
                                                        null &&
                                                    this.submittedBidList[35]
                                                        .RepairTotal[0] !=
                                                        undefined)
                                            ) {
                                                this.submittedBidList[35].RepairTotal[0] =
                                                    this.submittedBidList[35]
                                                        .RepairTotal[0] +
                                                    subsorTotalCost;
                                            } else {
                                                this.submittedBidList[35].RepairTotal.push(
                                                    subsorTotalCost
                                                );
                                            }
                                        }

                                        let m = 0;
                                        for (
                                            let i = 0;
                                            i < GCresponse.Items.length;
                                            i++
                                        ) {
                                            var subTotalCost: number = 0;
                                            setItem = GCresponse.Items[i];
                                            if (setItem.Status == "Reviewed") {
                                                this.submittedBidList[36].Status =
                                                    setItem.Status;
                                                this.submittedBidList[36].StatusId =
                                                    setItem.Id;
                                            }
                                            if (setItem.Status == "Accepted") {
                                                this.submittedBidList[36].Status =
                                                    setItem.Status;
                                                this.submittedBidList[36].StatusId =
                                                    setItem.Id;
                                            }

                                            if (setItem.Status != "Pending") {
                                                this.GcId =
                                                    setItem.GeneralContractorId;

                                                if (
                                                    setItem.Constructions
                                                        .length > 0
                                                ) {
                                                    for (
                                                        let k = 0;
                                                        k <
                                                        setItem.Constructions
                                                            .length;
                                                        k++
                                                    ) {
                                                        if (
                                                            this
                                                                .submittedBidList[
                                                                j
                                                            ].RepairType ==
                                                            setItem
                                                                .Constructions[
                                                                k
                                                            ].RepairType
                                                        ) {
                                                            for (
                                                                let l = 0;
                                                                l <
                                                                setItem
                                                                    .Constructions[
                                                                    k
                                                                ]
                                                                    .RepairItemDetails
                                                                    .length;
                                                                l++
                                                            ) {
                                                                if (
                                                                    setItem
                                                                        .Constructions[
                                                                        k
                                                                    ]
                                                                        .RepairItemDetails[
                                                                        l
                                                                    ]
                                                                        .LaborUnitCost !=
                                                                        "" &&
                                                                    setItem
                                                                        .Constructions[
                                                                        k
                                                                    ]
                                                                        .RepairItemDetails[
                                                                        l
                                                                    ]
                                                                        .LaborUnitCost !=
                                                                        null &&
                                                                    setItem
                                                                        .Constructions[
                                                                        k
                                                                    ]
                                                                        .RepairItemDetails[
                                                                        l
                                                                    ]
                                                                        .LaborUnitCost !=
                                                                        undefined &&
                                                                    setItem
                                                                        .Constructions[
                                                                        k
                                                                    ]
                                                                        .RepairItemDetails[
                                                                        l
                                                                    ]
                                                                        .LaborQuantity !=
                                                                        "" &&
                                                                    setItem
                                                                        .Constructions[
                                                                        k
                                                                    ]
                                                                        .RepairItemDetails[
                                                                        l
                                                                    ]
                                                                        .LaborQuantity !=
                                                                        null &&
                                                                    setItem
                                                                        .Constructions[
                                                                        k
                                                                    ]
                                                                        .RepairItemDetails[
                                                                        l
                                                                    ]
                                                                        .LaborQuantity !=
                                                                        undefined &&
                                                                    setItem
                                                                        .Constructions[
                                                                        k
                                                                    ]
                                                                        .RepairItemDetails[
                                                                        l
                                                                    ]
                                                                        .MaterialUnitCost !=
                                                                        "" &&
                                                                    setItem
                                                                        .Constructions[
                                                                        k
                                                                    ]
                                                                        .RepairItemDetails[
                                                                        l
                                                                    ]
                                                                        .MaterialUnitCost !=
                                                                        null &&
                                                                    setItem
                                                                        .Constructions[
                                                                        k
                                                                    ]
                                                                        .RepairItemDetails[
                                                                        l
                                                                    ]
                                                                        .MaterialUnitCost !=
                                                                        undefined &&
                                                                    setItem
                                                                        .Constructions[
                                                                        k
                                                                    ]
                                                                        .RepairItemDetails[
                                                                        l
                                                                    ]
                                                                        .MaterialQuantity !=
                                                                        "" &&
                                                                    setItem
                                                                        .Constructions[
                                                                        k
                                                                    ]
                                                                        .RepairItemDetails[
                                                                        l
                                                                    ]
                                                                        .MaterialQuantity !=
                                                                        null &&
                                                                    setItem
                                                                        .Constructions[
                                                                        k
                                                                    ]
                                                                        .RepairItemDetails[
                                                                        l
                                                                    ]
                                                                        .MaterialQuantity !=
                                                                        undefined
                                                                ) {
                                                                    subTotalCost +=
                                                                        parseFloat(
                                                                            setItem
                                                                                .Constructions[
                                                                                k
                                                                            ]
                                                                                .RepairItemDetails[
                                                                                l
                                                                            ]
                                                                                .LaborUnitCost
                                                                        ) *
                                                                            parseFloat(
                                                                                setItem
                                                                                    .Constructions[
                                                                                    k
                                                                                ]
                                                                                    .RepairItemDetails[
                                                                                    l
                                                                                ]
                                                                                    .LaborQuantity
                                                                            ) +
                                                                        parseFloat(
                                                                            setItem
                                                                                .Constructions[
                                                                                k
                                                                            ]
                                                                                .RepairItemDetails[
                                                                                l
                                                                            ]
                                                                                .MaterialUnitCost
                                                                        ) *
                                                                            parseFloat(
                                                                                setItem
                                                                                    .Constructions[
                                                                                    k
                                                                                ]
                                                                                    .RepairItemDetails[
                                                                                    l
                                                                                ]
                                                                                    .MaterialQuantity
                                                                            );
                                                                }
                                                            }
                                                        }
                                                    }
                                                }

                                                if (j == 36) {
                                                    this.submittedBidList[
                                                        j
                                                    ].RepairTotal.push(
                                                        setItem.Id
                                                    );
                                                }
                                                if (j != 35 && j != 36) {
                                                    this.submittedBidList[
                                                        j
                                                    ].RepairTotal.push(
                                                        subTotalCost
                                                    );
                                                    if (
                                                        this
                                                            .submittedBidList[35]
                                                            .RepairTotal[
                                                            m + 1
                                                        ] == 0 ||
                                                        (this
                                                            .submittedBidList[35]
                                                            .RepairTotal[
                                                            m + 1
                                                        ] != "" &&
                                                            this
                                                                .submittedBidList[35]
                                                                .RepairTotal[
                                                                m + 1
                                                            ] != null &&
                                                            this
                                                                .submittedBidList[35]
                                                                .RepairTotal[
                                                                m + 1
                                                            ] != undefined)
                                                    ) {
                                                        this.submittedBidList[35].RepairTotal[
                                                            m + 1
                                                        ] =
                                                            this
                                                                .submittedBidList[35]
                                                                .RepairTotal[
                                                                m + 1
                                                            ] + subTotalCost;
                                                    } else {
                                                        this.submittedBidList[35].RepairTotal.push(
                                                            subTotalCost
                                                        );
                                                    }
                                                }
                                                m++;
                                            }
                                        }
                                    }
                                }
                                this.loading = false;
                            },
                            (error) => {}
                        );
                    }
                }
            },
            (error) => {}
        );
    }

    acceptBid(bidId) {
        this._bservice.GetGCSorDetailsbyId(bidId).subscribe(
            (GCresponse) => {
                if (GCresponse != null) {
                    GCresponse.Status = "Accepted";
                    GCresponse.ReviseFlag = "ReviseFlag";
                    GCresponse.ReviseComment = "ReviseComment";
                    GCresponse.propertyStatus = "Pre Closing Completed";
                    this._bservice.UpdateSORForGC(GCresponse).subscribe(
                        (response) => {
                            this.propertyService
                                .GetPropertyDetails(this.RepairPropertyId)
                                .subscribe(
                                    (presponse) => {
                                        if (presponse != null) {
                                            presponse.Status =
                                                "Pre Closing Completed";
                                            presponse.CreatedBy =
                                                this.userDetail.EmailId;
                                            presponse.UpdatedBy =
                                                this.userDetail.EmailId;
                                            presponse.AcceptedProposal =
                                                GCresponse.GeneralContractorId;
                                            presponse.GeneralContractorId =
                                                GCresponse.GeneralContractorId;

                                            this.propertyService
                                                .UpdateProperty(presponse)
                                                .subscribe(
                                                    (response) => {
                                                        this.sessionService.notifier.notify(
                                                            "success",
                                                            "Bid Accepted Successfully."
                                                        );

                                                        this.getRepairItem(
                                                            this
                                                                .RepairPropertyId
                                                        );
                                                        this.router.navigate([
                                                            "/property-details/" +
                                                                this
                                                                    .RepairPropertyId,
                                                            1,
                                                        ]);
                                                    },
                                                    (error) => {
                                                        this.sessionService.notifier.notify(
                                                            "error",
                                                            error.error.message
                                                        );
                                                        this.loading = false;
                                                    }
                                                );
                                        }
                                    },
                                    (error) => {
                                        this.sessionService.notifier.notify(
                                            "error",
                                            error.error.message
                                        );
                                        this.loading = false;
                                    }
                                );
                        },
                        (error) => {
                            this.sessionService.notifier.notify(
                                "error",
                                error.error.message
                            );
                            this.loading = false;
                        }
                    );
                }
            },
            (error) => {}
        );
    }
    reviseBid(bidId) {
        this.reviseComment = true;
        this.reviseCommentId = bidId;
        setTimeout(function () {
            const container = document.querySelector("#container-3");
            container.scrollTop = 5500;
        }, 500);
    }

    cancelReviseBid() {
        var formData = this.review_status.value;
        this.reviseComment = false;
        this.review_status.reset();
    }
    bidReviseStatus() {
        var formData = this.review_status.value;
        this.propertyService
            .GetPropertyDetails(this.RepairPropertyId)
            .subscribe(
                (response) => {
                    if (response != null) {
                        let filterbid = this.GCBidList.filter((item) => {
                            return item.Id == this.reviseCommentId;
                        });
                        let gcId = "";
                        if (filterbid.length > 0) {
                            gcId = filterbid[0].GeneralContractorId;
                        } else {
                            gcId = "";
                        }

                        let comment = "";
                        if (filterbid.length > 0) {
                            comment = formData.Comment;
                        } else {
                            comment = "";
                        }
                        var reviseBidData = {
                            PropertyId: this.RepairPropertyId,
                            GeneralContractorId: gcId,
                            BorrowerId: response.BorrowerId,
                            ConsultantId: null,
                            Comment: comment,
                            SendMailBy: "Consultant",
                        };

                        this.propertyService
                            .SendReviseBidMail(reviseBidData)
                            .subscribe(
                                (response) => {
                                    this.review_status.reset();
                                    this.reviseComment = false;
                                    this.sessionService.notifier.notify(
                                        "success",
                                        "Send mail to borrower and general contractor successfully."
                                    );
                                },
                                (error) => {
                                    this.sessionService.notifier.notify(
                                        "error",
                                        error.error.message
                                    );
                                    this.loading = false;
                                }
                            );
                    }
                },
                (error) => {
                    this.sessionService.notifier.notify(
                        "error",
                        error.error.message
                    );
                    this.loading = false;
                }
            );
    }
}

/** Builds and returns a new User. */
