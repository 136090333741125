<div class="content p-24 tbl">
    <ngx-loading class="ngxLoader" 
        [show]="loading"
        [config]="{ backdropBorderRadius: '3px' }"
    ></ngx-loading>
    <h1 class="m-0">Property Gallery</h1>
    <a
        class="addBorrow"
        href="javascript:void(0)"
        routerLink="/property-details/{{ propertyId }}"
    >
        Back to Property</a
    >
    <br />
    <div class="container-login100">
        <mat-tab-group
            mat-align-tabs="start"
            [(selectedIndex)]="selectedIndex"
            style="clear: both"
        >
            <mat-tab
                *ngIf="isPhotoGalleryActive"
                label="Property Photos Gallery"
            >
                <mat-card class="my-form">
                    <form
                        [formGroup]="add_googlealbum"
                        class="example-form"
                        (ngSubmit)="saveGooglePropertyAlbumRecord()"
                    >
                        <mat-card-content>
                            <mat-form-field class="example-full-width">
                                <span class="mat-form-field-label-wrapper"
                                    ><!----><label
                                        class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                        >Album Title
                                    </label></span
                                >
                                <input
                                    matInput
                                    formControlName="AlbumTitle"
                                    name="AlbumTitle"
                                />
                                <div
                                    class="error"
                                    *ngIf="
                                        (!add_googlealbum.controls[
                                            'AlbumTitle'
                                        ].valid &&
                                        add_googlealbum
                                                .controls[
                                                'AlbumTitle'
                                            ].touched) ||
                                        (add_googlealbum.controls[
                                            'AlbumTitle'
                                        ].invalid &&
                                            submitted)
                                    "
                                >
                                   Enter valid album title
                                </div>
                            </mat-form-field>
                            <mat-form-field class="example-full-width">
                                <span class="mat-form-field-label-wrapper"
                                    ><!----><label
                                        class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                        >Google Album
                                    </label></span
                                >
                                <input
                                    matInput
                                    formControlName="GoogleAlbum"
                                    name="GoogleAlbum"
                                />
                            </mat-form-field>

                            <!-- <div style="border: 2px solid #ccc; padding: 20px; margin: 0px 0px 20px; float: left; width: 100%;" *ngIf="newPropertyImagesData.length>0">
          <h3>Selected Files</h3> 
          
          <ul class="propertyGalleryImageList">
            <li class="propertyGalleryImageSec" *ngFor="let imageData of newPropertyImagesData; let i = index" style="padding: 0 0 15px;" align="center">
              <span>{{imageData.name}} / {{imageData.dimensions}} / {{imageData.size}} </span>
                <img [src]="imageData.imageSrc" class="propertyGalleryImage" >
                <button [disabled]="loading"  (click)="removeImage(i);" type="button" class="imageRemoveBtn btn btn-primary">Remove</button>
            </li>
           
          </ul>
            
         </div>  <label><strong>PICTURES</strong></label><br>
             <input name="file" type="file" accept="image/*" style=" border: 1px solid;margin: 0px 10px 0 0;
             padding: 6px 0px 6px 0px;" (change)="handleInputChange($event)" />
             <button [disabled]="loading" style=" border: 1px solid;
             padding: 9px 6px;" (click)="onSubmit()" type="button" class="btn btn-primary">Upload</button><br>
             <div class="form-group" *ngIf="tempFileName != null">
                 <img [src]="tempFileName" width="200">
             </div> 
              <p>Files must be less than <strong>100 MB.</strong>  <br>
               Allowed file types: <strong>png gif jpg jpeg.</strong> </p><br> -->

                            <!-- <label><strong>Cover Sheet Photo</strong></label><br>
             <div *ngIf="showCoverFile == null || showCoverFile == ''">
                 <input name="coverfile" type="file" accept="image/*" style=" border: 1px solid;margin: 0px 10px 0 0;
                 padding: 6px 0px 6px 0px;" (change)="coverHandleInputChange($event)" />
                 <button [disabled]="loading" style=" border: 1px solid;
                 padding: 9px 6px;" (click)="onCoverSubmit()" type="button" class="btn btn-primary">Upload</button>
                 <p>Files must be less than <strong>100 MB.</strong>  <br>
                   Allowed file types: <strong>png gif jpg jpeg.</strong> </p><br><br>
             </div> -->

                            <!-- <div class="form-group" *ngIf="showCoverFile != null">
                 <img [src]="showCoverFile" width="200">
                 <button [disabled]="loading" style=" border: 1px solid;
                 padding: 9px 6px;" (click)="removeCoverImage();" type="button" class="btn btn-primary">Remove</button>
             </div>  -->

                            <!-- <label><strong>Google Gallery Code</strong> </label>
             <ckeditor matNativeControl name="editor1" formControlName="editorValue" skin="moono-lisa" language="en" ></ckeditor>
               <br><br>
             <mat-expansion-panel [expanded]="true">
               <mat-expansion-panel-header>
                 <mat-panel-title>
                     GOOGLE IMAGE CODE
                 </mat-panel-title>
               </mat-expansion-panel-header>
     
               <ckeditor matNativeControl name="editor1" formControlName="editorValue" skin="moono-lisa" language="en" ></ckeditor>  
           </mat-expansion-panel>
           <br>
           <button mat-raised-button type="button" color="info">Add another item</button>  -->
                        </mat-card-content>
                        <mat-card-actions style="padding-top: 20px">
                            <button
                                mat-raised-button
                                type="submit"
                                color="primary"
                            >
                                Save
                            </button>
                            <button
                                mat-raised-button
                                type="reset"
                                color="primary"
                            >
                                Cancel
                            </button>
                        </mat-card-actions>
                    </form>
                </mat-card>
                <mat-card>
                    <mat-card-content>
                        <div
                            *ngFor="let Album of propertyDetails; let i = index"
                        >
                            <div *ngIf="Album.AlbumType == 'propertyPhotos'">
                                <strong>{{ Album.AlbumTitle }} : </strong>
                                <span>
                                    <a
                                        *ngIf="Album != null"
                                        href="{{ Album.GoogleAlbum }}"
                                        target="_blank"
                                        >View</a
                                    >
                                </span>
                                |
                                <span>
                                    <a
                                        href="javascript:void(0)"
                                        (click)="
                                            getPropertyDetailsForEdit(
                                                propertyId,
                                                i
                                            )
                                        "
                                        >Edit</a
                                    >
                                </span>
                                |
                                <span>
                                    <a
                                        href="javascript:void(0)"
                                        (click)="removeAlbum(propertyId, i)"
                                        >Delete</a
                                    >
                                </span>
                                <br />
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
            </mat-tab>
            <mat-tab
                *ngIf="isDrawGalleryActive"
                label="Draw Inspection Photo Gallery"
            >
                <mat-card class="my-form">
                    <form
                        [formGroup]="add_googledrawalbum"
                        class="example-form"
                        (ngSubmit)="saveGoogleDrawAlbumRecord()"
                    >
                        <mat-card-content>
                            <mat-form-field class="example-full-width">
                                <span class="mat-form-field-label-wrapper"
                                    ><!----><label
                                        class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                        >Album Title
                                    </label></span
                                >
                                <input
                                    matInput
                                    formControlName="AlbumTitle"
                                    name="AlbumTitle"
                                />
                                <div
                                    class="error"
                                    *ngIf="
                                        (!add_googledrawalbum.controls[
                                            'AlbumTitle'
                                        ].valid &&
                                        add_googledrawalbum
                                                .controls[
                                                'AlbumTitle'
                                            ].touched) ||
                                        (add_googledrawalbum.controls[
                                            'AlbumTitle'
                                        ].invalid &&
                                            submitted)
                                    "
                                >
                                   Enter valid draw album title
                                </div>
                            </mat-form-field>
                            <mat-form-field class="example-full-width">
                                <span class="mat-form-field-label-wrapper"
                                    ><!----><label
                                        class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                        >Google Album
                                    </label></span
                                >
                                <input
                                    matInput
                                    formControlName="GoogleAlbum"
                                    name="GoogleAlbum"
                                />
                            </mat-form-field>

                            <!-- <div style="    border: 2px solid #ccc; padding: 20px; margin: 0px 0px 20px; float: left; width: 100%;" *ngIf="newDrawImagesData.length>0">
          <h3>Selected Files</h3> 
          <ul class="propertyGalleryImageList">
            <li class="propertyGalleryImageSec" *ngFor="let imageData of newDrawImagesData; let i = index" style="padding: 0 0 15px;" align="center">
              <span>{{imageData.name}} / {{imageData.dimensions}} / {{imageData.size}} </span>
                <img [src]="imageData.imageSrc" class="propertyGalleryImage" >
                <button [disabled]="loading"  (click)="removeDrawImage(i);" type="button" class="imageRemoveBtn btn btn-primary">Remove</button>
            </li>
           
          </ul>
           
         </div>  <label><strong>PICTURES</strong></label><br>
             <input name="file" type="file" accept="image/*" style=" border: 1px solid;margin: 0px 10px 0 0;
             padding: 6px 0px 6px 0px;" (change)="handleInputChange($event)" />
             <button [disabled]="loading" style=" border: 1px solid;
             padding: 9px 6px;" (click)="onDrawSubmit()" type="button" class="btn btn-primary">Upload</button><br>
             <div class="form-group" *ngIf="tempFileName != null">
                 <img [src]="tempFileName" width="200">
             </div> 
              <p>Files must be less than <strong>100 MB.</strong>  <br>
               Allowed file types: <strong>png gif jpg jpeg.</strong> </p><br> -->

                            <!-- <label><strong>Cover Sheet Photo</strong></label><br>
             <div *ngIf="showCoverFile == null || showCoverFile == ''">
                 <input name="coverfile" type="file" accept="image/*" style=" border: 1px solid;margin: 0px 10px 0 0;
                 padding: 6px 0px 6px 0px;" (change)="coverHandleInputChange($event)" />
                 <button [disabled]="loading" style=" border: 1px solid;
                 padding: 9px 6px;" (click)="onCoverSubmit()" type="button" class="btn btn-primary">Upload</button>
                 <p>Files must be less than <strong>100 MB.</strong>  <br>
                   Allowed file types: <strong>png gif jpg jpeg.</strong> </p><br><br>
             </div>
             
             <div class="form-group" *ngIf="showCoverFile != null">
                 <img [src]="showCoverFile" width="200">
                 <button [disabled]="loading" style=" border: 1px solid;
                 padding: 9px 6px;" (click)="removeCoverImage();" type="button" class="btn btn-primary">Remove</button>
             </div>  -->

                            <!-- <label><strong>Google Gallery Code</strong> </label>
             <ckeditor matNativeControl name="editor1" formControlName="editorValue" skin="moono-lisa" language="en" ></ckeditor>
               <br><br>
             <mat-expansion-panel [expanded]="true">
               <mat-expansion-panel-header>
                 <mat-panel-title>
                     GOOGLE IMAGE CODE
                 </mat-panel-title>
               </mat-expansion-panel-header>
     
               <ckeditor matNativeControl name="editor1" formControlName="editorValue" skin="moono-lisa" language="en" ></ckeditor>  
           </mat-expansion-panel>
           <br>
           <button mat-raised-button type="button" color="info">Add another item</button>  -->
                        </mat-card-content>
                        <mat-card-actions style="padding-top: 20px">
                            <button
                                mat-raised-button
                                type="submit"
                                color="primary"
                            >
                                Save
                            </button>
                            <button
                                mat-raised-button
                                type="reset"
                                color="primary"
                            >
                                Cancel
                            </button>
                        </mat-card-actions>
                    </form>
                </mat-card>
                <mat-card>
                    <mat-card-content>
                        <div
                            *ngFor="let Album of propertyDetails; let i = index"
                        >
                            <div
                                *ngIf="
                                    Album.AlbumType == 'drawInspectionPhotos'
                                "
                            >
                                <strong>{{ Album.AlbumTitle }} : </strong>
                                <span>
                                    <a
                                        *ngIf="Album != null"
                                        href="{{ Album.GoogleAlbum }}"
                                        target="_blank"
                                        >View</a
                                    >
                                </span>
                                |
                                <span>
                                    <a
                                        href="javascript:void(0)"
                                        (click)="
                                            getPropertyDrawDetailsForEdit(
                                                propertyId,
                                                i
                                            )
                                        "
                                        >Edit</a
                                    >
                                </span>
                                |
                                <span>
                                    <a
                                        href="javascript:void(0)"
                                        (click)="removeAlbum(propertyId, i)"
                                        >Delete</a
                                    >
                                </span>
                                <br />
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>
