import { Component, OnInit, ViewChild } from "@angular/core";
import {
    FormGroup,
    Validators,
    FormControl,
    ReactiveFormsModule,
    FormArray,
    FormBuilder,
} from "@angular/forms";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { DataSource } from "@angular/cdk/table";
import { BorrowerService } from "../borrower/borrower.service";
import { SessionService } from "../service/session.service";
import { ActivatedRoute, Router } from "@angular/router";
import { PropertyService } from "../property/property.service";
import { ConstructionItemsLibraryService } from "../construction-items-library/construction-items-library.service";
import { parse } from "url";
import { kebabCase } from "lodash";

export interface UserData {
    id: string;
    name: string;
    email: string;
    lastLogin: Date;
}

/** Constants used to fill up our data base. */
const COLORS: string[] = [
    "maroon",
    "red",
    "orange",
    "yellow",
    "olive",
    "green",
    "purple",
    "fuchsia",
    "lime",
    "teal",
    "aqua",
    "blue",
    "navy",
    "black",
    "gray",
];
const NAMES: string[] = [
    "Maia",
    "Asher",
    "Olivia",
    "Atticus",
    "Amelia",
    "Jack",
    "Charlotte",
    "Theodore",
    "Isla",
    "Oliver",
    "Isabella",
    "Jasper",
    "Cora",
    "Levi",
    "Violet",
    "Arthur",
    "Mia",
    "Thomas",
    "Elizabeth",
];

/**
 * @title Data table with sorting, pagination, and filtering.
 */

@Component({
    selector: "app-construction-items-library",
    templateUrl: "./construction-items-library.component.html",
    styleUrls: ["./construction-items-library.component.scss"],
})
export class ConstructionItemsLibraryComponent implements OnInit {
    displayedColumns: string[] = ["id", "RepairType", "Operation"];
    dataSource: MatTableDataSource<UserData>;
    mobnumPattern = "^(d{3})sd{3}-d{4}";

    public RepairItemForm: FormGroup;
    RepairActivityItemForm: FormGroup;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    isList: boolean = true;
    isAdd: boolean = false;
    isEdit: boolean = false;
    isActAdd: boolean = false;
    isActEdit: boolean = false;
    submitted: boolean = false;
    isPlusShow: boolean = false;
    isMinusShow: boolean = false;
    userList: any = {};
    userDetail: any = {};
    loading = false;
    selectedIndex: any = 0;
    formStatus = false;
    activeReportComponent: String = "";
    propertyRepairConstructionDetail: any;
    checkItemName: any = false;
    checkConstructionsDetails = false;
    reportComponentsList: any = {};
    masterRepairItemsList: any = {};
    repairDetailsList: any = [];
    repairComponentList: any = [];
    activityList: any = [];
    activeItemComponents: any = [];
    searchResult: any = [];
    step = 0;
    isResultEmpty = true;
    searchInputDisabled = false;
    search2InputDisabled = false;
    isEditIndex: any = null;
    isEditComponentIndex: any = null;
    isShowComItem: any;
    isShowSelectedComItem: any;
    copyImagePath: any = "assets/icons/pngIcon/copy.png";
    editImagePath: any = "assets/icons/pngIcon/edit.png";
    deleteImagePath: any = "assets/icons/pngIcon/delete.png";
    selectedMainIndex: any = 0;
    selectedComponentListForGroup: any = [];
    tempSelection: any = [];
    activitySearchResult: any = [];
    tempDeSelection: any = [];
    status: any = ["Blocked", "Active"];
    roles: any = [
        { Key: "1", Value: "authenticated user" },
        { Key: "2", Value: "Administrator" },
        { Key: "3", Value: "Consultants" },
        { Key: "4", Value: "General Contractors" },
        { Key: "5", Value: "Borrower" },
        { Key: "6", Value: "Loan Officer" },
        { key: "7", Value: "Loan Processor" },
        { key: "8", Value: " Notify user of new account" },
        { key: "9", Value: "Draw Administrator" },
    ];
    unitOption: any = [
        { Key: "EA", Value: "Each = EA" },
        { Key: "LF", Value: "Linear foot = LF" },
        { Key: "SF", Value: "Square Foot = SF" },
        { Key: "LS", Value: "Lump Sum = LS" },
        { Key: "SY", Value: "Square Yard = SY" },
        { Key: "*", Value: "* = Required" },
    ];
    constructor(
        private _bservice: BorrowerService,
        private _ciservice: ConstructionItemsLibraryService,
        private route: ActivatedRoute,
        private router: Router,
        private sessionService: SessionService,
        private propertyService: PropertyService,
        private _fb: FormBuilder
    ) {}

    ngOnInit() {
        if (this.sessionService.get("userInfo") != undefined) {
            this.userDetail = JSON.parse(this.sessionService.get("userInfo"));
        }

        this.selectedIndex = 0;
        this.selectedMainIndex = 0;
        this.activeReportComponent = "Masonry";
        this.isPlusShow = true;
        this.RepairItemForm = this._fb.group({
            SendRepairType: new FormControl(null),
            id: new FormControl(null),
            RepairItemDetails: this._fb.array([this.initRepairItem()]),
        });
        this.RepairActivityItemForm = this._fb.group({
            RepairItemName: new FormControl(null),
            id: new FormControl(null),
            ReportComponent: new FormControl(null),
        });
        this.getRepairTypeList();
        this.getRepairItem();
    }

    addRepairItems() {
        // add address to the list
        const control = <FormArray>(
            this.RepairItemForm.controls["RepairItemDetails"]
        );
        control.push(this.initRepairItem());
    }

    RepairList() {
        this.isAdd = false;
        this.isActAdd = false;
        this.isList = true;

        const control = <FormArray>(
            this.RepairItemForm.controls["RepairItemDetails"]
        );

        for (let j = control.length; j >= 0; j--) {
            control.removeAt(j);
            if (j == 0) {
                control.push(this.initRepairItem());
            }
        }
    }
    expandActivityComList(index) {
        if (this.isShowComItem == null || this.isShowComItem != index) {
            this.isShowComItem = index;
        } else {
            this.isShowComItem = null;
        }
    }
    expandSelectedActivityComList(index) {
        if (
            this.isShowSelectedComItem == null ||
            this.isShowSelectedComItem != index
        ) {
            this.isShowSelectedComItem = index;
        } else {
            this.isShowSelectedComItem = null;
        }
    }
    toggleSelection(index, type, reportCompName) {
        if (this.tempSelection.length > 0) {
            var isCheck = true;
            for (let i = 0; i < this.tempSelection.length; i++) {
                if (this.tempSelection[i].RepairType == type) {
                    isCheck = false;
                    var isInnerCheck = true;
                    for (
                        let j = 0;
                        j < this.tempSelection[i].RepairItemDetails.length;
                        j++
                    ) {
                        if (
                            this.tempSelection[i].RepairItemDetails[j] ==
                            reportCompName
                        ) {
                            this.tempSelection[i].RepairItemDetails.splice(
                                j,
                                1
                            );

                            isInnerCheck = false;
                        }
                    }
                    if (isInnerCheck == true) {
                        this.tempSelection[i].RepairItemDetails.push({
                            RepairItemName: reportCompName,
                        });
                    }
                }
            }

            if (isCheck == true) {
                let selectedComponent = {
                    id: index,
                    RepairType: type,
                    RepairItemDetails: [{ RepairItemName: reportCompName }],
                };
                this.tempSelection.push(selectedComponent);
            }
        } else {
            let selectedComponent = {
                id: index,
                RepairType: type,
                RepairItemDetails: [{ RepairItemName: reportCompName }],
            };
            this.tempSelection.push(selectedComponent);
        }
    }
    toggleDeSelection(index, type, reportCompName) {
        if (this.tempDeSelection.length > 0) {
            var isCheck = true;
            for (let i = 0; i < this.tempDeSelection.length; i++) {
                if (this.tempDeSelection[i].RepairType == type) {
                    isCheck = false;
                    var isInnerCheck = true;
                    for (
                        let j = 0;
                        j < this.tempDeSelection[i].RepairItemDetails.length;
                        j++
                    ) {
                        if (
                            this.tempDeSelection[i].RepairItemDetails[j] ==
                            reportCompName
                        ) {
                            this.tempDeSelection[i].RepairItemDetails.splice(
                                j,
                                1
                            );

                            isInnerCheck = false;
                        }
                    }
                    if (isInnerCheck == true) {
                        this.tempDeSelection[i].RepairItemDetails.push({
                            RepairItemName: reportCompName,
                        });
                    }
                }
            }

            if (isCheck == true) {
                let selectedComponent = {
                    id: index,
                    RepairType: type,
                    RepairItemDetails: [{ RepairItemName: reportCompName }],
                };
                this.tempDeSelection.push(selectedComponent);
            }
        } else {
            let selectedComponent = {
                id: index,
                RepairType: type,
                RepairItemDetails: [{ RepairItemName: reportCompName }],
            };
            this.tempDeSelection.push(selectedComponent);
        }
    }
    addInSelectedList() {
        if (this.tempSelection.length > 0) {
            for (let i = 0; i < this.tempSelection.length; i++) {
                var isCheck = true;
                for (
                    let j = 0;
                    j < this.selectedComponentListForGroup.length;
                    j++
                ) {
                    if (
                        this.tempSelection[i].RepairType ==
                        this.selectedComponentListForGroup[j].RepairType
                    ) {
                        isCheck = false;

                        for (
                            let k = 0;
                            k < this.tempSelection[i].RepairItemDetails.length;
                            k++
                        ) {
                            var isInnerCheck = true;
                            for (
                                let l = 0;
                                l <
                                this.selectedComponentListForGroup[j]
                                    .RepairItemDetails.length;
                                l++
                            ) {
                                if (
                                    this.tempSelection[i].RepairItemDetails[k]
                                        .RepairItemName ==
                                    this.selectedComponentListForGroup[j]
                                        .RepairItemDetails[l].RepairItemName
                                ) {
                                    isInnerCheck = false;
                                }
                            }
                            if (isInnerCheck == true) {
                                this.selectedComponentListForGroup[
                                    j
                                ].RepairItemDetails.push(
                                    this.tempSelection[i].RepairItemDetails[k]
                                );
                            }
                        }
                    }
                }

                if (isCheck == true) {
                    this.selectedComponentListForGroup.push(
                        this.tempSelection[i]
                    );
                }
            }
        }
        this.selectedComponentListForGroup.sort(function (a, b) {
            return a.id - b.id;
        });

        this.tempSelection = [];
        this.expandActivityComList(-1);
    }
    removeFromSelectedList() {
        if (this.tempDeSelection.length > 0) {
            for (let i = 0; i < this.tempDeSelection.length; i++) {
                for (
                    let j = 0;
                    j < this.selectedComponentListForGroup.length;
                    j++
                ) {
                    if (
                        this.tempDeSelection[i].RepairType ==
                        this.selectedComponentListForGroup[j].RepairType
                    ) {
                        for (
                            let k = 0;
                            k <
                            this.tempDeSelection[i].RepairItemDetails.length;
                            k++
                        ) {
                            for (
                                let l = 0;
                                l <
                                this.selectedComponentListForGroup[j]
                                    .RepairItemDetails.length;
                                l++
                            ) {
                                if (
                                    this.tempDeSelection[i].RepairItemDetails[k]
                                        .RepairItemName ==
                                    this.selectedComponentListForGroup[j]
                                        .RepairItemDetails[l].RepairItemName
                                ) {
                                    this.selectedComponentListForGroup[
                                        j
                                    ].RepairItemDetails.splice(l, 1);
                                }
                            }
                        }
                    }
                }
            }
        }

        for (let m = 0; m < this.selectedComponentListForGroup.length; m++) {
            if (
                this.selectedComponentListForGroup[m].RepairItemDetails.length <
                1
            ) {
                this.selectedComponentListForGroup.splice(m, 1);
            }
        }
        this.selectedComponentListForGroup.sort(function (a, b) {
            return a.id - b.id;
        });

        this.tempDeSelection = [];
        this.expandSelectedActivityComList(-1);
    }
    removeCompontFromGroup(group, component) {
        var r = confirm(
            "Are you sure, you want to remove this Report Component from Activity Group ?"
        );

        if (r == true) {
            var ciData = { UserType: this.userDetail.UserType };
            this._ciservice.getConsultantConstructionItems(ciData).subscribe(
                (ciresponse) => {
                    if (ciresponse != null && ciresponse.Items.length > 0) {
                        let contructionActivityGroup =
                            ciresponse.Items[0].ActivityGroup;

                        for (
                            let i = 0;
                            i < contructionActivityGroup.length;
                            i++
                        ) {
                            if (
                                contructionActivityGroup[i].RepairItemName ==
                                group
                            ) {
                                for (
                                    let k = 0;
                                    k <
                                    contructionActivityGroup[i].ReportComponent
                                        .length;
                                    k++
                                ) {
                                    for (
                                        let l = 0;
                                        l <
                                        contructionActivityGroup[i]
                                            .ReportComponent[k]
                                            .RepairItemDetails.length;
                                        l++
                                    ) {
                                        if (
                                            contructionActivityGroup[i]
                                                .ReportComponent[k]
                                                .RepairItemDetails[l]
                                                .RepairItemName == component
                                        ) {
                                            contructionActivityGroup[
                                                i
                                            ].ReportComponent[
                                                k
                                            ].RepairItemDetails.splice(l, 1);
                                        }
                                    }
                                }
                            }
                        }

                        ciresponse.Items[0].ActivityGroup =
                            contructionActivityGroup;
                        let item = {
                            Id: ciresponse.Items[0].Id,
                            ActivityGroup: ciresponse.Items[0].ActivityGroup,
                        };

                        let propertyData = item;
                        this._ciservice
                            .UpdateActivityGroupItem(propertyData)
                            .subscribe(
                                (response) => {
                                    this.sessionService.notifier.notify(
                                        "success",
                                        "Report Component Items deleted successfully."
                                    );
                                    this.getRepairItem();

                                    this.loading = false;
                                },
                                (error) => {
                                    this.sessionService.notifier.notify(
                                        "error",
                                        error.error.message
                                    );
                                    this.loading = false;
                                }
                            );

                        this.loading = false;
                    }
                },
                (error) => {
                    this.sessionService.notifier.notify(
                        "error",
                        error.error.message
                    );
                    this.loading = false;
                }
            );
        } else {
            this.loading = false;
        }
    }

    ActivityList() {
        this.isActAdd = false;
        this.isList = true;
        this.isAdd = false;

        this.changeActiveComponent(0, 0, "Masonry");
    }

    ReportComponentList() {
        this.isActAdd = false;
        this.isList = true;
        this.isAdd = false;

        this.changeActiveComponent(1, 0, "Masonry");
    }

    UpdateRepair(type) {
        let setItem = this.RepairItemForm.value;

        this.formReset();

        const controlArray = <FormArray>(
            this.RepairItemForm.get("RepairItemDetails")
        );

        if (controlArray.length > 0) {
            for (let j = controlArray.length - 1; j >= 0; j--) {
                controlArray.removeAt(j);
                if (j == 0) {
                    controlArray.push(this.initRepairItem());
                }
            }
        }

        controlArray.controls[0].get("MaterialUnit").setValue("EA");
        controlArray.controls[0].get("LaborUnit").setValue("EA");

        this.RepairItemForm.controls["SendRepairType"].setValue(type);
        this.RepairItemForm.controls["SendRepairType"].disable();

        this.isAdd = true;
        this.isList = false;
    }

    setStep(index: number) {
        this.step = index;
    }
    initupdateItem(item) {
        // initialize our address
        return this._fb.group({
            RepairItemName: [item.RepairItemName],
            MaterialUnit: [item.MaterialUnit],
            MaterialUnitCost: [item.MaterialUnitCost],
            LaborUnit: [item.LaborUnit],
            LaborUnitCost: [item.LaborUnitCost],
            Summary: [item.Summary],
        });
    }
    deleteRepairItems(index) {
        const control = <FormArray>(
            this.RepairItemForm.controls["RepairItemDetails"]
        );
        control.removeAt(index);
    }
    deleteReportItems(index) {
        const control = <FormArray>(
            this.RepairActivityItemForm.controls["RepairItemDetails"]
        );
        control.removeAt(index);
    }
    initRepairItem() {
        // initialize our address
        return this._fb.group({
            RepairItemName: [""],
            MaterialUnit: [""],
            MaterialUnitCost: [""],
            LaborUnit: [""],
            LaborUnitCost: [""],
            Summary: [""],
        });
    }

    showAddReportComponentForm() {
        this.formReset();
        const control = <FormArray>(
            this.RepairItemForm.controls["RepairItemDetails"]
        );
        for (let j = control.length; j >= 0; j--) {
            control.removeAt(j);
            if (j == 0) {
                control.push(this.initRepairItem());
            }
        }
        this.isAdd = true;

        this.isActAdd = false;
        this.isList = false;
    }

    showAddActivityForm() {
        this.isActAdd = true;

        this.isAdd = false;
        this.isList = true;
        this.isEditIndex = null;
        this.formReset();
        const controlArray = <FormArray>(
            this.RepairActivityItemForm.get("RepairItemDetails")
        );

        this.RepairActivityItemForm.controls["RepairItemName"].setValue("");
        this.selectedComponentListForGroup = [];

        this.repairComponentList = this.repairDetailsList;
        this.repairComponentList.sort(function (a, b) {
            return a.id - b.id;
        });
    }
    applyFilter(filterValue: string) {
        this.dataSource.filter = filterValue.trim().toLowerCase();

        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    }

    changeActiveComponent(mainindex, index, activeRepairType) {
        this.loading = true;

        this.activeReportComponent = activeRepairType;
        this.selectedMainIndex = mainindex;
        this.selectedIndex = index;
        this.isResultEmpty = true;

        // var ciData = { UserType: this.userDetail.UserType };
        // this._ciservice.getConsultantConstructionItems(ciData).subscribe(
        //     (ciresponse) => {
        //         if (ciresponse != null && ciresponse.Items.length > 0) {
        //             this.repairDetailsList =
        //                 ciresponse.Items[0].ReportComponent;
        //             this.activityList = ciresponse.Items[0].ActivityGroup;

                    const control = <FormArray>(
                        this.RepairItemForm.controls["RepairItemDetails"]
                    );

                    for (let j = control.length; j >= 0; j--) {
                        control.removeAt(j);
                    }
                    for (let i = 0; i < this.repairDetailsList.length; i++) {
                        if (
                            activeRepairType ==
                            this.repairDetailsList[i].RepairType
                        ) {
                            if (
                                this.repairDetailsList[i].RepairItemDetails
                                    .length > 0
                            ) {
                                for (
                                    let k = 0;
                                    k <
                                    this.repairDetailsList[i].RepairItemDetails
                                        .length;
                                    k++
                                ) {
                                    var unitFullName = {
                                        EA: "Each = EA",
                                        LF: "Linear foot = LF",
                                        SF: "Square Foot = SF",
                                        LS: "Lump Sum = LS",
                                        SY: "Square Yard = SY",
                                        "*": "* = Required",
                                    };
                                    var mtUnit =
                                        this.repairDetailsList[i]
                                            .RepairItemDetails[k].MaterialUnit;
                                    var lbUnit =
                                        this.repairDetailsList[i]
                                            .RepairItemDetails[k].LaborUnit;
                                    this.repairDetailsList[i].RepairItemDetails[
                                        k
                                    ].MaterialUnitFull = unitFullName[mtUnit];
                                    this.repairDetailsList[i].RepairItemDetails[
                                        k
                                    ].LaborUnitFull = unitFullName[lbUnit];
                                    control.push(
                                        this._fb.group({
                                            RepairItemName: [
                                                this.repairDetailsList[i]
                                                    .RepairItemDetails[k]
                                                    .RepairItemName,
                                            ],
                                            MaterialUnit: [
                                                this.repairDetailsList[i]
                                                    .RepairItemDetails[k]
                                                    .MaterialUnit,
                                            ],
                                            MaterialUnitCost: [
                                                this.repairDetailsList[i]
                                                    .RepairItemDetails[k]
                                                    .MaterialUnitCost,
                                            ],
                                            LaborUnit: [
                                                this.repairDetailsList[i]
                                                    .RepairItemDetails[k]
                                                    .LaborUnit,
                                            ],
                                            LaborUnitCost: [
                                                this.repairDetailsList[i]
                                                    .RepairItemDetails[k]
                                                    .LaborUnitCost,
                                            ],
                                            Summary: [
                                                this.repairDetailsList[i]
                                                    .RepairItemDetails[k]
                                                    .Summary,
                                            ],
                                        })
                                    );
                                }
                            }
                        }
                        for (let j = 0; j < this.userList.Items.length; j++) {
                            if (
                                this.repairDetailsList[i].RepairType ==
                                this.userList.Items[j].RepairType
                            ) {
                                this.repairDetailsList[i].id =
                                    this.userList.Items[j].id;
                                break;
                            }
                        }
                    }

                    this.repairDetailsList.sort(function (a, b) {
                        return a.id - b.id;
                    });

                    this.loading = false;
                // } else {
                //     this.loading = false;
                // }
        //     },
        //     (error) => {
        //         this.sessionService.notifier.notify(
        //             "error",
        //             error.error.message
        //         );
        //         this.loading = false;
        //     }
        // );

        this.RepairItemForm.controls["SendRepairType"].setValue(
            activeRepairType
        );
    }
    filterActivityGroupList(current) {
        let searchValue = current.target.value;
        if (searchValue.length < 3) {
            return false;
        }

        this.loading = true;

        let tempList = [];

        let OldAllVal = this.activityList;
        if (
            searchValue != "" &&
            searchValue != null &&
            searchValue != undefined
        ) {
            for (let i = 0; i < OldAllVal.length; i++) {
                var indx = OldAllVal[i].RepairItemName.toLowerCase().indexOf(
                    searchValue.toLowerCase()
                );
                if (indx > -1) {
                    tempList.push(OldAllVal[i]);
                }
            }

            this.activitySearchResult = tempList;
            this.activitySearchResult.sort(function (a, b) {
                return a.id - b.id;
            });
            this.searchInputDisabled = false;
            this.loading = false;
        } else {
            this.activitySearchResult = OldAllVal;
            this.activitySearchResult.sort(function (a, b) {
                return a.id - b.id;
            });
            this.searchInputDisabled = false;
            this.loading = false;
        }
    }
    filterReportComponentList(current) {
        let searchValue = current.target.value;
        if (searchValue.length < 3) {
            return false;
        }
        this.isResultEmpty = true;

        this.loading = true;
        let tempList = [];

        this.searchResult = [];

        if (
            searchValue != "" &&
            searchValue != null &&
            searchValue != undefined
        ) {
            let OldAllVal = this.repairDetailsList;
            for (let i = 0; i < OldAllVal.length; i++) {
                for (
                    let j = 0;
                    j < OldAllVal[i].RepairItemDetails.length;
                    j++
                ) {
                    var indx = OldAllVal[i].RepairItemDetails[
                        j
                    ].RepairItemName.toLowerCase().indexOf(
                        searchValue.toLowerCase()
                    );
                    if (indx > -1) {
                        let temp = {
                            RepairType: OldAllVal[i].RepairType,
                            RepairDetails: OldAllVal[i].RepairItemDetails[j],
                        };
                        tempList.push(temp);
                    }
                }
            }

            let resultType = [];
            for (let l = 0; l < tempList.length; l++) {
                var indxx = resultType.indexOf(tempList[l].RepairType.trim());
                if (indxx > -1) {
                    for (let k = 0; k < this.searchResult.length; k++) {
                        if (
                            tempList[l].RepairType.trim().toLowerCase() ==
                            this.searchResult[k].RepairType.trim().toLowerCase()
                        ) {
                            this.searchResult[k].RepairItemDetails.push(
                                tempList[l].RepairDetails
                            );
                        }
                    }
                } else {
                    let tempRepairDetails = [];
                    tempRepairDetails.push(tempList[l].RepairDetails);
                    let temp = {
                        RepairType: tempList[l].RepairType,
                        RepairItemDetails: tempRepairDetails,
                    };

                    this.searchResult.push(temp);
                    resultType.push(tempList[l].RepairType.trim());
                }
            }
            if (this.searchResult.length > 0) {
                this.isResultEmpty = false;
                for (let i = 0; i < this.reportComponentsList.length; i++) {
                    this.reportComponentsList[i].isSearchActiveTab = false;
                    for (let j = 0; j < this.searchResult.length; j++) {
                        if (
                            this.searchResult[j].RepairType ==
                            this.reportComponentsList[i].RepairType
                        ) {
                            this.reportComponentsList[i].isSearchActiveTab =
                                true;
                            break;
                        }
                    }
                }
            }

            this.search2InputDisabled = false;
            this.loading = false;
        } else {
            tempList = [];
            this.searchResult = this.repairDetailsList;
            this.isResultEmpty = true;
            this.search2InputDisabled = false;
            this.loading = false;
        }
    }
    getRepairItem() {
        var ciData = { UserType: this.userDetail.UserType };
        this._ciservice.getConsultantConstructionItems(ciData).subscribe(
            (ciresponse) => {
                if (ciresponse != null && ciresponse.Items.length > 0) {
                    this.repairDetailsList =
                        ciresponse.Items[0].ReportComponent;
                    this.activityList = ciresponse.Items[0].ActivityGroup;
                    var unitFullName = {
                        EA: "Each = EA",
                        LF: "Linear foot = LF",
                        SF: "Square Foot = SF",
                        LS: "Lump Sum = LS",
                        SY: "Square Yard = SY",
                        "*": "* = Required",
                    };

                    for (let i = 0; i < this.repairDetailsList.length; i++) {
                        for (
                            let j = 0;
                            j <
                            this.repairDetailsList[i].RepairItemDetails.length;
                            j++
                        ) {
                            var mtUnit =
                                this.repairDetailsList[i].RepairItemDetails[j]
                                    .MaterialUnit;
                            var lbUnit =
                                this.repairDetailsList[i].RepairItemDetails[j]
                                    .LaborUnit;
                            this.repairDetailsList[i].RepairItemDetails[
                                j
                            ].MaterialUnitFull = unitFullName[mtUnit];
                            this.repairDetailsList[i].RepairItemDetails[
                                j
                            ].LaborUnitFull = unitFullName[lbUnit];
                        }
                        for (let k = 0; k < this.userList.Items.length; k++) {
                            if (
                                this.repairDetailsList[i].RepairType ==
                                this.userList.Items[k].RepairType
                            ) {
                                this.repairDetailsList[i].id =
                                    this.userList.Items[k].id;
                                break;
                            }
                        }
                    }

                    this.loading = false;
                }
            },
            (error) => {
                this.sessionService.notifier.notify(
                    "error",
                    error.error.message
                );
                this.loading = false;
            }
        );
    }

    copyActivityGroup(selRepairItemName) {
        this.isActAdd = true;

        this.isAdd = false;
        this.isList = true;
        this.isEditIndex = null;
        this.selectedComponentListForGroup = [];
        for (let i = 0; i < this.activityList.length; i++) {
            if (this.activityList[i].RepairItemName == selRepairItemName) {
                this.RepairActivityItemForm.controls["RepairItemName"].setValue(
                    this.activityList[i].RepairItemName + " Copy"
                );
                for (
                    let j = 0;
                    j < this.activityList[i].ReportComponent.length;
                    j++
                ) {
                    this.selectedComponentListForGroup.push(
                        this.activityList[i].ReportComponent[j]
                    );
                }
            }
        }
        for (let l = 0; l < this.selectedComponentListForGroup.length; l++) {
            for (let k = 0; k < this.userList.Items.length; k++) {
                if (
                    this.selectedComponentListForGroup[l].RepairType ==
                    this.userList.Items[k].RepairType
                ) {
                    this.selectedComponentListForGroup[l].id =
                        this.userList.Items[k].id;
                    break;
                }
            }
        }
        this.selectedComponentListForGroup.sort(function (a, b) {
            return a.id - b.id;
        });

        this.repairComponentList = this.repairDetailsList;

        this.reportComponentsList.sort(function (a, b) {
            return a.id - b.id;
        });
    }

    editActivityGroup(selRepairItemName) {
        this.isActAdd = true;

        this.isAdd = false;
        this.isList = true;
        this.selectedComponentListForGroup = [];
        for (let i = 0; i < this.activityList.length; i++) {
            if (this.activityList[i].RepairItemName == selRepairItemName) {
                this.RepairActivityItemForm.controls["RepairItemName"].setValue(
                    this.activityList[i].RepairItemName
                );
                this.isEditIndex = i;
                for (
                    let j = 0;
                    j < this.activityList[i].ReportComponent.length;
                    j++
                ) {
                    this.selectedComponentListForGroup.push(
                        this.activityList[i].ReportComponent[j]
                    );
                }
            }
        }
        for (let l = 0; l < this.selectedComponentListForGroup.length; l++) {
            for (let k = 0; k < this.userList.Items.length; k++) {
                if (
                    this.selectedComponentListForGroup[l].RepairType ==
                    this.userList.Items[k].RepairType
                ) {
                    this.selectedComponentListForGroup[l].id =
                        this.userList.Items[k].id;
                    break;
                }
            }
        }
        this.selectedComponentListForGroup.sort(function (a, b) {
            return a.id - b.id;
        });
        this.repairComponentList = this.repairDetailsList;
        this.reportComponentsList.sort(function (a, b) {
            return a.id - b.id;
        });
    }
    deleteActivityGroup(group) {
        var r = confirm(
            "Are you sure, you want to remove this Activity Group ?"
        );

        if (r == true) {
            var ciData = { UserType: this.userDetail.UserType };
            this._ciservice.getConsultantConstructionItems(ciData).subscribe(
                (ciresponse) => {
                    if (ciresponse != null && ciresponse.Items.length > 0) {
                        let contructionActivityGroup =
                            ciresponse.Items[0].ActivityGroup;

                        for (
                            let i = 0;
                            i < contructionActivityGroup.length;
                            i++
                        ) {
                            if (
                                contructionActivityGroup[i].RepairItemName ==
                                group
                            ) {
                                contructionActivityGroup.splice(i, 1);
                            }
                        }

                        ciresponse.Items[0].ActivityGroup =
                            contructionActivityGroup;
                        let item = {
                            Id: ciresponse.Items[0].Id,
                            ActivityGroup: ciresponse.Items[0].ActivityGroup,
                        };

                        let propertyData = item;
                        this._ciservice
                            .UpdateActivityGroupItem(propertyData)
                            .subscribe(
                                (response) => {
                                    this.sessionService.notifier.notify(
                                        "success",
                                        "Report Component Items deleted successfully."
                                    );
                                    this.getRepairItem();

                                    this.loading = false;
                                },
                                (error) => {
                                    this.sessionService.notifier.notify(
                                        "error",
                                        error.error.message
                                    );
                                    this.loading = false;
                                }
                            );

                        this.loading = false;
                    }
                },
                (error) => {
                    this.sessionService.notifier.notify(
                        "error",
                        error.error.message
                    );
                    this.loading = false;
                }
            );
        } else {
            this.loading = false;
        }
    }

    copyReportComponentItem(type, selRepairItemName) {
        this.isAdd = true;

        this.isActAdd = false;
        this.isList = false;
        this.isEditComponentIndex = null;
        let repairDetails = {};
        for (let i = 0; i < this.repairDetailsList.length; i++) {
            if (this.repairDetailsList[i].RepairType == type) {
                for (
                    let j = 0;
                    j < this.repairDetailsList[i].RepairItemDetails.length;
                    j++
                ) {
                    if (
                        this.repairDetailsList[i].RepairItemDetails[j]
                            .RepairItemName == selRepairItemName
                    ) {
                        repairDetails = {
                            RepairItemName: [
                                this.repairDetailsList[i].RepairItemDetails[j]
                                    .RepairItemName + " Copy",
                            ],
                            MaterialUnit: [
                                this.repairDetailsList[i].RepairItemDetails[j]
                                    .MaterialUnit,
                            ],
                            MaterialUnitCost: [
                                this.repairDetailsList[i].RepairItemDetails[j]
                                    .MaterialUnitCost,
                            ],
                            LaborUnit: [
                                this.repairDetailsList[i].RepairItemDetails[j]
                                    .LaborUnit,
                            ],
                            LaborUnitCost: [
                                this.repairDetailsList[i].RepairItemDetails[j]
                                    .LaborUnitCost,
                            ],
                            Summary: [
                                this.repairDetailsList[i].RepairItemDetails[j]
                                    .Summary,
                            ],
                        };
                    }
                }
            }
        }

        const control = <FormArray>(
            this.RepairItemForm.controls["RepairItemDetails"]
        );

        for (let j = control.length; j >= 0; j--) {
            control.removeAt(j);
            if (j == 0) {
                control.push(this._fb.group(repairDetails));
            }
        }
    }

    editReportComponentItem(type, selRepairItemName) {
        this.isAdd = true;

        this.isActAdd = false;
        this.isList = false;
        let repairDetails = {};
        this.isEditComponentIndex = null;
        for (let i = 0; i < this.repairDetailsList.length; i++) {
            if (this.repairDetailsList[i].RepairType == type) {
                for (
                    let j = 0;
                    j < this.repairDetailsList[i].RepairItemDetails.length;
                    j++
                ) {
                    if (
                        this.repairDetailsList[i].RepairItemDetails[j]
                            .RepairItemName == selRepairItemName
                    ) {
                        repairDetails = {
                            RepairItemName: [
                                this.repairDetailsList[i].RepairItemDetails[j]
                                    .RepairItemName,
                            ],
                            MaterialUnit: [
                                this.repairDetailsList[i].RepairItemDetails[j]
                                    .MaterialUnit,
                            ],
                            MaterialUnitCost: [
                                this.repairDetailsList[i].RepairItemDetails[j]
                                    .MaterialUnitCost,
                            ],
                            LaborUnit: [
                                this.repairDetailsList[i].RepairItemDetails[j]
                                    .LaborUnit,
                            ],
                            LaborUnitCost: [
                                this.repairDetailsList[i].RepairItemDetails[j]
                                    .LaborUnitCost,
                            ],
                            Summary: [
                                this.repairDetailsList[i].RepairItemDetails[j]
                                    .Summary,
                            ],
                        };

                        this.isEditComponentIndex = j;
                        break;
                    }
                }
            }
        }
        const control = <FormArray>(
            this.RepairItemForm.controls["RepairItemDetails"]
        );

        for (let j = control.length; j >= 0; j--) {
            control.removeAt(j);
            if (j == 0) {
                control.push(this._fb.group(repairDetails));
            }
        }
    }
    expandReportComponentItem(type, selRepairItemName) {
        this.isEditComponentIndex = null;
    }
    deleteReportComponentItem(type, selRepairItemName) {
        var r = confirm(
            "Are you sure, you want to remove this Report Component Item ?"
        );

        if (r == true) {
            this.loading = true;
            var ciData = { UserType: this.userDetail.UserType };
            this._ciservice.getConsultantConstructionItems(ciData).subscribe(
                (ciresponse) => {
                    let currentIndex = 0;
                    if (ciresponse != null && ciresponse.Items.length > 0) {
                        let contructionReportComponent =
                            ciresponse.Items[0].ReportComponent;

                        for (
                            let i = 0;
                            i < contructionReportComponent.length;
                            i++
                        ) {
                            if (
                                contructionReportComponent[i].RepairType == type
                            ) {
                                for (
                                    let j = 0;
                                    j <
                                    contructionReportComponent[i]
                                        .RepairItemDetails.length;
                                    j++
                                ) {
                                    if (
                                        contructionReportComponent[i]
                                            .RepairItemDetails[j]
                                            .RepairItemName == selRepairItemName
                                    ) {
                                        currentIndex = i;
                                        contructionReportComponent[
                                            i
                                        ].RepairItemDetails.splice(j, 1);
                                    }
                                }
                            }
                        }

                        ciresponse.Items[0].ReportComponent =
                            contructionReportComponent;
                        let item = {
                            Id: ciresponse.Items[0].Id,
                            ReportComponent:
                                ciresponse.Items[0].ReportComponent,
                        };
                        let propertyData = item;
                        this._ciservice
                            .UpdateReportComponentItem(propertyData)
                            .subscribe(
                                (response) => {
                                    this.sessionService.notifier.notify(
                                        "success",
                                        "Report Component Item deleted successfully."
                                    );
                                    this.changeActiveComponent(
                                        1,
                                        currentIndex,
                                        type
                                    );

                                    this.loading = false;
                                },
                                (error) => {
                                    this.sessionService.notifier.notify(
                                        "error",
                                        error.error.message
                                    );
                                    this.loading = false;
                                }
                            );

                        this.loading = false;
                    }
                },
                (error) => {
                    this.sessionService.notifier.notify(
                        "error",
                        error.error.message
                    );
                    this.loading = false;
                }
            );
        } else {
            this.loading = false;
        }
    }

    filterSearchComponents(current) {
        let searchValue = current.target.value;
        let tempList = [];

        if (searchValue.length < 3) {
            let OldAllVal = this.repairDetailsList;
            for (let i = 0; i < OldAllVal.length; i++) {
                tempList.push(OldAllVal[i]);
            }
            this.repairComponentList = tempList;
            this.repairComponentList.sort(function (a, b) {
                return a.id - b.id;
            });
            return false;
        }
        this.loading = true;

        this.repairComponentList = [];
        let tempListOld = [];

        if (
            searchValue != "" &&
            searchValue != null &&
            searchValue != undefined
        ) {
            let OldAllVal = this.repairDetailsList;
            for (let i = 0; i < OldAllVal.length; i++) {
                var isTypeCheck = true;
                if (tempListOld.length > 0) {
                    for (let j = 0; j < tempListOld.length; j++) {
                        if (
                            OldAllVal[i].RepairType == tempListOld[j].RepairType
                        ) {
                            isTypeCheck = false;
                            for (
                                let k = 0;
                                k < OldAllVal[i].RepairItemDetails.length;
                                k++
                            ) {
                                var isInnerTypeCheck = true;
                                for (
                                    let l = 0;
                                    l < tempListOld[j].RepairItemDetails.length;
                                    l++
                                ) {
                                    if (
                                        OldAllVal[i].RepairItemDetails[k]
                                            .RepairItemName ==
                                        tempListOld[j].RepairItemDetails[l]
                                            .RepairItemName
                                    ) {
                                        isInnerTypeCheck = false;
                                    }
                                }

                                if (isInnerTypeCheck == true) {
                                    var indx = OldAllVal[i].RepairItemDetails[
                                        k
                                    ].RepairItemName.toLowerCase().indexOf(
                                        searchValue.toLowerCase()
                                    );
                                    if (indx > -1) {
                                        tempListOld[j].RepairItemDetails.push(
                                            OldAllVal[i].RepairItemDetails[k]
                                                .RepairItemName
                                        );
                                    }
                                }
                            }
                        }
                    }
                }
                if (isTypeCheck == true) {
                    for (
                        let k = 0;
                        k < OldAllVal[i].RepairItemDetails.length;
                        k++
                    ) {
                        var indx = OldAllVal[i].RepairItemDetails[
                            k
                        ].RepairItemName.toLowerCase().indexOf(
                            searchValue.toLowerCase()
                        );
                        if (indx > -1) {
                            let repairItem = {
                                id: OldAllVal[i].id,
                                RepairType: OldAllVal[i].RepairType,
                                RepairItemDetails: [
                                    {
                                        RepairItemName:
                                            OldAllVal[i].RepairItemDetails[k]
                                                .RepairItemName,
                                    },
                                ],
                            };

                            tempListOld.push(repairItem);
                        }
                    }
                }
            }

            if (tempListOld.length > 0) {
                let temArray = [];
                for (let i = 0; i < tempListOld.length; i++) {
                    var indx1 = temArray.indexOf(tempListOld[i].RepairType);
                    if (indx1 > -1) {
                        for (
                            let j = 0;
                            j < tempListOld[i].RepairItemDetails.length;
                            j++
                        ) {
                            let RepItem = {
                                RepairItemName:
                                    tempListOld[i].RepairItemDetails[j]
                                        .RepairItemName,
                            };
                            tempList[indx1].RepairItemDetails.push(RepItem);
                        }
                    } else {
                        temArray.push(tempListOld[i].RepairType);
                        tempList.push(tempListOld[i]);
                    }
                }
            }
        } else {
            let OldAllVal = this.repairDetailsList;
            for (let i = 0; i < OldAllVal.length; i++) {
                tempList.push(OldAllVal[i]);
            }
        }

        this.repairComponentList = tempList;
        this.repairComponentList.sort(function (a, b) {
            return a.id - b.id;
        });
        this.loading = false;
    }
    SaveConstructionItem() {
        var formData = this.RepairItemForm.getRawValue();

        if (
            formData.SendRepairType == "" ||
            formData.SendRepairType == null ||
            formData.SendRepairType == undefined
        ) {
            this.sessionService.notifier.notify(
                "error",
                "Please select any Construction Item from list."
            );
            this.loading = false;
            return;
        }

        for (let i = 0; i < formData.RepairItemDetails.length; i++) {
            if (
                formData.RepairItemDetails[i].RepairItemName == "" ||
                formData.RepairItemDetails[i].RepairItemName == null ||
                formData.RepairItemDetails[i].RepairItemName == undefined
            ) {
                this.sessionService.notifier.notify(
                    "error",
                    "Please Fill Repair Item Name."
                );
                this.loading = false;
                return;
            }
        }

        if (
            this.isEditComponentIndex == 0 ||
            (this.isEditComponentIndex != null &&
                this.isEditComponentIndex != "" &&
                this.isEditComponentIndex != undefined)
        ) {
            for (let i = 0; i < this.repairDetailsList.length; i++) {
                for (
                    let j = 0;
                    j < this.repairDetailsList[i].RepairItemDetails.length;
                    j++
                ) {
                    for (
                        let k = 0;
                        k < formData.RepairItemDetails.length;
                        k++
                    ) {
                        if (
                            this.repairDetailsList[i].RepairItemDetails[j]
                                .RepairItemName ==
                                formData.RepairItemDetails[k].RepairItemName &&
                            this.isEditComponentIndex != j
                        ) {
                            this.sessionService.notifier.notify(
                                "error",
                                "This Report Component Item name already exist. Please choose another name."
                            );
                            this.loading = false;
                            return;
                        }
                    }
                }
            }
        }

        this.submitted = true;
        if (this.RepairItemForm.invalid) {
            return;
        } else {
            this.loading = true;
            this.submitted = false;
            this.propertyRepairConstructionDetail = "";

            this.propertyRepairConstructionDetail = {
                ReportComponent: [],
                ActivityGroup: [],
                UserId: "UserId",
                UserType: "UserType",
                CreatedBy: "CreatedBy",
                CreatedDate: "CreatedDate",
                UpdateDate: "UpdateDate",
                UpdatedBy: "UpdatedBy",
            };

            var ciData = { UserType: this.userDetail.UserType };
            this._ciservice.getConsultantConstructionItems(ciData).subscribe(
                (ciresponse) => {
                    if (ciresponse != null && ciresponse.Items.length > 0) {
                        let item = {
                            Id: ciresponse.Items[0].Id,
                            ReportComponent:
                                ciresponse.Items[0].ReportComponent,
                        };
                        this.propertyRepairConstructionDetail = item;

                        if (this.propertyRepairConstructionDetail.Id) {
                            var formData = this.RepairItemForm.getRawValue();

                            var propertyData =
                                this.propertyRepairConstructionDetail;
                            var formData = this.RepairItemForm.getRawValue();

                            let filledRepairItem = [];
                            for (
                                let i = 0;
                                i < formData.RepairItemDetails.length;
                                i++
                            ) {
                                if (
                                    formData.RepairItemDetails[i]
                                        .RepairItemName != "" &&
                                    formData.RepairItemDetails[i]
                                        .RepairItemName != null &&
                                    formData.RepairItemDetails[i]
                                        .MaterialUnit != "" &&
                                    formData.RepairItemDetails[i]
                                        .MaterialUnit != null &&
                                    formData.RepairItemDetails[i]
                                        .MaterialUnitCost != "" &&
                                    formData.RepairItemDetails[i]
                                        .MaterialUnitCost != null &&
                                    formData.RepairItemDetails[i].LaborUnit !=
                                        "" &&
                                    formData.RepairItemDetails[i].LaborUnit !=
                                        null &&
                                    formData.RepairItemDetails[i]
                                        .LaborUnitCost != "" &&
                                    formData.RepairItemDetails[i]
                                        .LaborUnitCost != null
                                ) {
                                    filledRepairItem.push(
                                        formData.RepairItemDetails[i]
                                    );
                                }
                            }
                            if (filledRepairItem.length > 0) {
                                var data = {
                                    RepairType: formData.SendRepairType,
                                    RepairItemDetails: filledRepairItem,
                                };

                                this.checkConstructionsDetails = false;
                                this.checkItemName = false;
                                var itemLength =
                                    propertyData.ReportComponent.length;
                                for (let j = 0; j < itemLength; j++) {
                                    if (
                                        propertyData.ReportComponent[j]
                                            .RepairType ==
                                        formData.SendRepairType
                                    ) {
                                        let propertyDataLength =
                                            propertyData.ReportComponent[j]
                                                .RepairItemDetails.length;
                                        for (
                                            let l = 0;
                                            l < propertyDataLength;
                                            l++
                                        ) {
                                            for (
                                                let k = 0;
                                                k <
                                                formData.RepairItemDetails
                                                    .length;
                                                k++
                                            ) {
                                                if (
                                                    propertyData
                                                        .ReportComponent[j]
                                                        .RepairItemDetails[l]
                                                        .RepairItemName ==
                                                        formData
                                                            .RepairItemDetails[
                                                            k
                                                        ].RepairItemName ||
                                                    l ==
                                                        this
                                                            .isEditComponentIndex
                                                ) {
                                                    propertyData.ReportComponent[
                                                        j
                                                    ].RepairItemDetails[l] =
                                                        formData.RepairItemDetails[
                                                            k
                                                        ];
                                                    this.checkItemName = true;
                                                }
                                            }
                                        }
                                        if (this.checkItemName == false) {
                                            for (
                                                let n = 0;
                                                n <
                                                formData.RepairItemDetails
                                                    .length;
                                                n++
                                            ) {
                                                propertyData.ReportComponent[
                                                    j
                                                ].RepairItemDetails.push(
                                                    formData.RepairItemDetails[
                                                        n
                                                    ]
                                                );
                                            }
                                        }
                                        this.checkConstructionsDetails = true;
                                    }
                                }
                                if (this.checkConstructionsDetails == false) {
                                    propertyData.ReportComponent.push(data);
                                }
                            }

                            this._ciservice
                                .UpdateReportComponentItem(propertyData)
                                .subscribe(
                                    (response) => {
                                        this.sessionService.notifier.notify(
                                            "success",
                                            "Construction Items update successfully."
                                        );
                                        this.isList = true;
                                        this.isAdd = false;

                                        this.isActAdd = false;

                                        for (
                                            let i = 0;
                                            i < this.userList.Items.length;
                                            i++
                                        ) {
                                            if (
                                                formData.SendRepairType ==
                                                this.userList.Items[i]
                                                    .RepairType
                                            ) {
                                                this.selectedIndex = i;
                                                this.selectedMainIndex = 1;
                                                this.changeActiveComponent(
                                                    1,
                                                    i,
                                                    formData.SendRepairType
                                                );
                                                break;
                                            }
                                        }
                                    },
                                    (error) => {
                                        this.sessionService.notifier.notify(
                                            "error",
                                            error.error.message
                                        );
                                        this.loading = false;
                                    }
                                );
                        }
                    } else {
                        var formData = this.RepairItemForm.getRawValue();

                        var propertyData =
                            this.propertyRepairConstructionDetail;

                        propertyData.UserType = this.userDetail.UserType;
                        let filledRepairItem = [];
                        for (
                            let i = 0;
                            i < formData.RepairItemDetails.length;
                            i++
                        ) {
                            if (
                                formData.RepairItemDetails[i].RepairItemName !=
                                    "" &&
                                formData.RepairItemDetails[i].RepairItemName !=
                                    null &&
                                formData.RepairItemDetails[i].MaterialUnit !=
                                    "" &&
                                formData.RepairItemDetails[i].MaterialUnit !=
                                    null &&
                                formData.RepairItemDetails[i]
                                    .MaterialUnitCost != "" &&
                                formData.RepairItemDetails[i]
                                    .MaterialUnitCost != null &&
                                formData.RepairItemDetails[i].LaborUnit != "" &&
                                formData.RepairItemDetails[i].LaborUnit !=
                                    null &&
                                formData.RepairItemDetails[i].LaborUnitCost !=
                                    "" &&
                                formData.RepairItemDetails[i].LaborUnitCost !=
                                    null
                            ) {
                                filledRepairItem.push(
                                    formData.RepairItemDetails[i]
                                );
                            }
                        }
                        if (filledRepairItem.length > 0) {
                            var data = {
                                RepairType: formData.SendRepairType,
                                RepairItemDetails: filledRepairItem,
                            };

                            this.checkConstructionsDetails = false;
                            var itemLength =
                                propertyData.ReportComponent.length;

                            for (let j = 0; j < itemLength; j++) {
                                if (
                                    propertyData.ReportComponent[j]
                                        .RepairType == formData.SendRepairType
                                ) {
                                    propertyData.ReportComponent[j] = data;
                                    this.checkConstructionsDetails = true;
                                }
                            }
                            if (this.checkConstructionsDetails == false) {
                                propertyData.ReportComponent.push(data);
                            }
                        }

                        this._ciservice
                            .AddConstructionList(propertyData)
                            .subscribe(
                                (response) => {
                                    this.sessionService.notifier.notify(
                                        "success",
                                        "Construction Items added successfully."
                                    );
                                    this.isList = true;
                                    this.isAdd = false;

                                    this.isActAdd = false;

                                    for (
                                        let i = 0;
                                        i < this.userList.Items.length;
                                        i++
                                    ) {
                                        if (
                                            formData.SendRepairType ==
                                            this.userList.Items[i].RepairType
                                        ) {
                                            this.selectedIndex = i;
                                            this.selectedMainIndex = 1;
                                            this.changeActiveComponent(
                                                1,
                                                i,
                                                formData.SendRepairType
                                            );
                                            break;
                                        }
                                    }
                                },
                                (error) => {
                                    this.sessionService.notifier.notify(
                                        "error",
                                        error.error.message
                                    );
                                    this.loading = false;
                                }
                            );
                    }
                },
                (error) => {
                    this.sessionService.notifier.notify(
                        "error",
                        error.error.message
                    );
                    this.loading = false;
                }
            );
        }
    }
    SaveActivityItem() {
        var formData = this.RepairActivityItemForm.getRawValue();
        if (
            formData.RepairItemName == "" ||
            formData.RepairItemName == null ||
            formData.RepairItemName == undefined
        ) {
            this.sessionService.notifier.notify(
                "error",
                "Please fill group name."
            );
            this.loading = false;
            return;
        }
        for (let i = 0; i < this.activityList.length; i++) {
            if (
                this.activityList[i].RepairItemName ==
                    formData.RepairItemName &&
                i != this.isEditIndex
            ) {
                this.sessionService.notifier.notify(
                    "error",
                    "This group name already exist. Please choose another name."
                );
                this.loading = false;
                return;
            }
        }

        this.submitted = true;
        if (this.RepairActivityItemForm.invalid) {
            return;
        } else {
            this.loading = true;
            this.submitted = false;
            this.propertyRepairConstructionDetail = "";

            this.propertyRepairConstructionDetail = {
                ReportComponent: [],
                ActivityGroup: [],
                UserId: "UserId",
                UserType: "UserType",
                CreatedBy: "CreatedBy",
                CreatedDate: "CreatedDate",
                UpdateDate: "UpdateDate",
                UpdatedBy: "UpdatedBy",
            };

            var ciData = { UserType: this.userDetail.UserType };
            this._ciservice.getConsultantConstructionItems(ciData).subscribe(
                (ciresponse) => {
                    if (ciresponse != null && ciresponse.Items.length > 0) {
                        let item = {
                            Id: ciresponse.Items[0].Id,
                            ActivityGroup: ciresponse.Items[0].ActivityGroup,
                        };
                        this.propertyRepairConstructionDetail = item;

                        if (this.propertyRepairConstructionDetail.Id) {
                            var propertyData =
                                this.propertyRepairConstructionDetail;

                            let filledRepairItem = [];
                            formData.ReportComponent =
                                this.selectedComponentListForGroup;
                            if (
                                formData.RepairItemName != "" &&
                                formData.RepairItemName != null
                            ) {
                                filledRepairItem.push(formData);
                            }

                            if (filledRepairItem.length > 0) {
                                var data = filledRepairItem;

                                this.checkConstructionsDetails = false;
                                this.checkItemName = false;
                                var itemLength =
                                    propertyData.ActivityGroup.length;
                                for (let j = 0; j < itemLength; j++) {
                                    if (
                                        propertyData.ActivityGroup[j]
                                            .RepairItemName ==
                                            formData.RepairItemName ||
                                        j == this.isEditIndex
                                    ) {
                                        propertyData.ActivityGroup[j] =
                                            formData;
                                        this.checkItemName = true;
                                    }
                                }

                                if (this.checkItemName == false) {
                                    propertyData.ActivityGroup.push(formData);
                                }
                            }

                            this._ciservice
                                .UpdateActivityGroupItem(propertyData)
                                .subscribe(
                                    (response) => {
                                        this.sessionService.notifier.notify(
                                            "success",
                                            "Activity group update successfully."
                                        );
                                        this.isList = true;
                                        this.isAdd = false;

                                        this.isActAdd = false;
                                        this.getRepairItem();
                                        this.ActivityList();
                                        this.loading = false;
                                    },
                                    (error) => {
                                        this.sessionService.notifier.notify(
                                            "error",
                                            error.error.message
                                        );
                                        this.loading = false;
                                    }
                                );
                        }
                    } else {
                        var propertyData =
                            this.propertyRepairConstructionDetail;

                        propertyData.UserType = this.userDetail.UserType;
                        let filledRepairItem = [];
                        formData.ReportComponent =
                            this.selectedComponentListForGroup;
                        if (
                            formData.RepairItemName != "" &&
                            formData.RepairItemName != null
                        ) {
                            filledRepairItem.push(formData);
                        }

                        if (filledRepairItem.length > 0) {
                            var data = filledRepairItem;

                            var itemLength = propertyData.ActivityGroup.length;

                            for (let j = 0; j < itemLength; j++) {
                                propertyData.ActivityGroup[j] = data;
                            }
                        }

                        this._ciservice
                            .AddConstructionList(propertyData)
                            .subscribe(
                                (response) => {
                                    this.sessionService.notifier.notify(
                                        "success",
                                        "Activity group added successfully."
                                    );
                                    this.isList = true;
                                    this.isAdd = false;

                                    this.isActAdd = false;
                                    this.getRepairItem();
                                    this.selectedIndex = 0;
                                    this.selectedMainIndex = 0;
                                    this.loading = false;
                                },
                                (error) => {
                                    this.sessionService.notifier.notify(
                                        "error",
                                        error.error.message
                                    );
                                    this.loading = false;
                                }
                            );
                    }
                },
                (error) => {
                    this.sessionService.notifier.notify(
                        "error",
                        error.error.message
                    );
                    this.loading = false;
                }
            );
        }
    }

    nextPage(event: PageEvent) {}

    expandFunction(i) {
        for (let j = 0; j < this.reportComponentsList.length; j++) {
            if (j == i) {
                this.reportComponentsList[i].isPlusShow = false;
                this.reportComponentsList[i].isMinusShow = true;
            } else {
                this.reportComponentsList[j].isPlusShow = true;
                this.reportComponentsList[j].isMinusShow = false;
            }
        }
    }
    closedFunction(i) {
        this.reportComponentsList[i].isPlusShow = true;
        this.reportComponentsList[i].isMinusShow = false;
    }

    formReset() {
        this.RepairItemForm.reset();
    }

    getRepairTypeList() {
        this.loading = true;

        this.userList.Count = 36;
        this.userList.ScannedCount = 36;
        this.userList.TotalRecord = 36;
        this.userList.Items = [
            {
                id: 1,
                RepairType: "Masonry",
                RepairTotal: "0.00",
                Operation: "Active",
                isPlusShow: true,
                isMinusShow: false,
            },
            {
                id: 2,
                RepairType: "Siding",
                RepairTotal: "0.00",
                Operation: "Active",
                isPlusShow: true,
                isMinusShow: false,
            },
            {
                id: 3,
                RepairType: "Gutters/Downspouts",
                RepairTotal: "0.00",
                Operation: "Active",
                isPlusShow: true,
                isMinusShow: false,
            },
            {
                id: 4,
                RepairType: "Roof",
                RepairTotal: "0.00",
                Operation: "Active",
                isPlusShow: true,
                isMinusShow: false,
            },
            {
                id: 5,
                RepairType: "Shutters",
                RepairTotal: "0.00",
                Operation: "Active",
                isPlusShow: true,
                isMinusShow: false,
            },
            {
                id: 6,
                RepairType: "Exteriors",
                RepairTotal: "0.00",
                Operation: "Active",
                isPlusShow: true,
                isMinusShow: false,
            },
            {
                id: 7,
                RepairType: "Walks",
                RepairTotal: "0.00",
                Operation: "Active",
                isPlusShow: true,
                isMinusShow: false,
            },
            {
                id: 8,
                RepairType: "Driveways",
                RepairTotal: "0.00",
                Operation: "Active",
                isPlusShow: true,
                isMinusShow: false,
            },
            {
                id: 9,
                RepairType: "Painting (Ext)",
                RepairTotal: "0.00",
                Operation: "Active",
                isPlusShow: true,
                isMinusShow: false,
            },
            {
                id: 10,
                RepairType: "Caulking",
                RepairTotal: "0.00",
                Operation: "Active",
                isPlusShow: true,
                isMinusShow: false,
            },
            {
                id: 11,
                RepairType: "Fencing",
                RepairTotal: "0.00",
                Operation: "Active",
                isPlusShow: true,
                isMinusShow: false,
            },
            {
                id: 12,
                RepairType: "Grading",
                RepairTotal: "0.00",
                Operation: "Active",
                isPlusShow: true,
                isMinusShow: false,
            },
            {
                id: 13,
                RepairType: "Windows",
                RepairTotal: "0.00",
                Operation: "Active",
                isPlusShow: true,
                isMinusShow: false,
            },
            {
                id: 14,
                RepairType: "Weatherstrip",
                RepairTotal: "0.00",
                Operation: "Active",
                isPlusShow: true,
                isMinusShow: false,
            },
            {
                id: 15,
                RepairType: "Doors (Ext)	",
                RepairTotal: "0.00",
                Operation: "Active",
                isPlusShow: true,
                isMinusShow: false,
            },
            {
                id: 16,
                RepairType: "Doors (Int)	",
                RepairTotal: "0.00",
                Operation: "Active",
                isPlusShow: true,
                isMinusShow: false,
            },
            {
                id: 17,
                RepairType: "Partition Wall",
                RepairTotal: "0.00",
                Operation: "Active",
                isPlusShow: true,
                isMinusShow: false,
            },
            {
                id: 18,
                RepairType: "Plaster/Drywall",
                RepairTotal: "0.00",
                Operation: "Active",
                isPlusShow: true,
                isMinusShow: false,
            },
            {
                id: 19,
                RepairType: "Decorating",
                RepairTotal: "0.00",
                Operation: "Active",
                isPlusShow: true,
                isMinusShow: false,
            },
            {
                id: 20,
                RepairType: "Wood Trim	",
                RepairTotal: "0.00",
                Operation: "Active",
                isPlusShow: true,
                isMinusShow: false,
            },
            {
                id: 21,
                RepairType: "Stairs",
                RepairTotal: "0.00",
                Operation: "Active",
                isPlusShow: true,
                isMinusShow: false,
            },
            {
                id: 22,
                RepairType: "Closets",
                RepairTotal: "0.00",
                Operation: "Active",
                isPlusShow: true,
                isMinusShow: false,
            },
            {
                id: 23,
                RepairType: "Wood Floors",
                RepairTotal: "0.00",
                Operation: "Active",
                isPlusShow: true,
                isMinusShow: false,
            },
            {
                id: 24,
                RepairType: "Finished Floors",
                RepairTotal: "0.00",
                Operation: "Active",
                isPlusShow: true,
                isMinusShow: false,
            },
            {
                id: 25,
                RepairType: "Ceramic Tile",
                RepairTotal: "0.00",
                Operation: "Active",
                isPlusShow: true,
                isMinusShow: false,
            },
            {
                id: 26,
                RepairType: "Bath Accesories",
                RepairTotal: "0.00",
                Operation: "Active",
                isPlusShow: true,
                isMinusShow: false,
            },
            {
                id: 27,
                RepairType: "Plumbing",
                RepairTotal: "0.00",
                Operation: "Active",
                isPlusShow: true,
                isMinusShow: false,
            },
            {
                id: 28,
                RepairType: "Electrical",
                RepairTotal: "0.00",
                Operation: "Active",
                isPlusShow: true,
                isMinusShow: false,
            },
            {
                id: 29,
                RepairType: "Heating",
                RepairTotal: "0.00",
                Operation: "Active",
                isPlusShow: true,
                isMinusShow: false,
            },
            {
                id: 30,
                RepairType: "Insulation",
                RepairTotal: "0.00",
                Operation: "Active",
                isPlusShow: true,
                isMinusShow: false,
            },
            {
                id: 31,
                RepairType: "Cabinetry",
                RepairTotal: "0.00",
                Operation: "Active",
                isPlusShow: true,
                isMinusShow: false,
            },
            {
                id: 32,
                RepairType: "Appliances",
                RepairTotal: "0.00",
                Operation: "Active",
                isPlusShow: true,
                isMinusShow: false,
            },
            {
                id: 33,
                RepairType: "Basements",
                RepairTotal: "0.00",
                Operation: "Active",
                isPlusShow: true,
                isMinusShow: false,
            },
            {
                id: 34,
                RepairType: "Cleanup",
                RepairTotal: "0.00",
                Operation: "Active",
                isPlusShow: true,
                isMinusShow: false,
            },
            {
                id: 35,
                RepairType: "Miscellaneous",
                RepairTotal: "0.00",
                Operation: "Active",
                isPlusShow: true,
                isMinusShow: false,
            },
        ];

        var ciData = { UserType: this.userDetail.UserType };
        this._ciservice.getConsultantConstructionItems(ciData).subscribe(
            (ciresponse) => {
                if (ciresponse != null && ciresponse.Items.length > 0) {
                    this.repairDetailsList =
                        ciresponse.Items[0].ReportComponent;
                    this.activityList = ciresponse.Items[0].ActivityGroup;
                    for (let i = 0; i < this.repairDetailsList.length; i++) {
                        if (
                            this.repairDetailsList[i].RepairItemDetails.length >
                            0
                        ) {
                            for (
                                let k = 0;
                                k <
                                this.repairDetailsList[i].RepairItemDetails
                                    .length;
                                k++
                            ) {
                                var unitFullName = {
                                    EA: "Each = EA",
                                    LF: "Linear foot = LF",
                                    SF: "Square Foot = SF",
                                    LS: "Lump Sum = LS",
                                    SY: "Square Yard = SY",
                                    "*": "* = Required",
                                };
                                var mtUnit =
                                    this.repairDetailsList[i].RepairItemDetails[
                                        k
                                    ].MaterialUnit;
                                var lbUnit =
                                    this.repairDetailsList[i].RepairItemDetails[
                                        k
                                    ].LaborUnit;
                                this.repairDetailsList[i].RepairItemDetails[
                                    k
                                ].MaterialUnitFull = unitFullName[mtUnit];
                                this.repairDetailsList[i].RepairItemDetails[
                                    k
                                ].LaborUnitFull = unitFullName[lbUnit];
                            }
                        }

                        for (let j = 0; j < this.userList.Items.length; j++) {
                            if (
                                this.repairDetailsList[i].RepairType ==
                                this.userList.Items[j].RepairType
                            ) {
                                this.repairDetailsList[i].id =
                                    this.userList.Items[j].id;
                                break;
                            }
                        }
                    }
                    this.reportComponentsList = this.userList.Items;
                    this.reportComponentsList.sort(function (a, b) {
                        return a.id - b.id;
                    });
                    const users = Array.from(
                        { length: this.userList.Count },
                        (_, k) => createNewUser(k, this.userList)
                    );
                    this.dataSource = new MatTableDataSource(users);
                    this.dataSource.paginator = this.paginator;
                    this.dataSource.sort = this.sort;
                    this.loading = false;
                } else {
                    this.reportComponentsList = this.userList.Items;
                    this.reportComponentsList.sort(function (a, b) {
                        return a.id - b.id;
                    });
                    const users = Array.from(
                        { length: this.userList.Count },
                        (_, k) => createNewUser(k, this.userList)
                    );
                    this.dataSource = new MatTableDataSource(users);
                    this.dataSource.paginator = this.paginator;
                    this.dataSource.sort = this.sort;
                    this.loading = false;
                }
            },
            (error) => {
                this.sessionService.notifier.notify(
                    "error",
                    error.error.message
                );
                this.search2InputDisabled = false;
                this.loading = false;
            }
        );
    }
}

/** Builds and returns a new User. */
function createNewUser(id: number, userList): UserData {
    const name =
        NAMES[Math.round(Math.random() * (NAMES.length - 1))] +
        " " +
        NAMES[Math.round(Math.random() * (NAMES.length - 1))].charAt(0) +
        ".";
    if (userList.Items[id] != undefined) {
        userList.Items[id].id = id + 1;
        return userList.Items[id];
    }
}
