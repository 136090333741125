<div class="content p-24 tbl">
    <ngx-loading class="ngxLoader" 
        [show]="loading"
        [config]="{ backdropBorderRadius: '3px' }"
    ></ngx-loading>
    <h1 class="m-0">Initial Draw Request</h1>
    <!-- <a *ngIf="isList" class="addBorrow" href="javascript:void(0)" (click)="isList=false;isAdd=true;isEdit=false;formReset()"><mat-icon _ngcontent-c102="" class="secondary-text s-22 mat-icon material-icons mat-icon-no-color" role="img" aria-hidden="true">add_circle</mat-icon> Add New Borrower</a> -->
    <!-- <a class="addBorrow" href="javascript:void(0)" routerLink="/properties"> Back to Property</a> -->
    <!-- <mat-form-field>
  <input [hidden]="!isList" matInput (keyup)="applyFilter($event.target)" placeholder="Filter">
</mat-form-field> -->

    <br />
    <!-- <br>
  <p>Please reference the Specification of Repair form <a href="javascript:void(0);">(Link)</a> to property
  fill out the values for Column 3.</p> -->

    <div>
        <mat-card class="my-form">
            <form [formGroup]="DrawRequestForm" class="example-form">
                <mat-card-header>
                    <mat-card-title></mat-card-title>
                </mat-card-header>
                <mat-card-content class="matActions">
                    <table class="drawComponentListTable">
                        <thead>
                            <th align="left" width="15%">Construction Item</th>
                            <th align="center" width="15%">
                                Total Escrow <br />Col. 1
                            </th>
                            <th align="center" width="17%">
                                Previous Draw Totals<br />
                                Col. 2
                            </th>
                            <th align="center" width="6%">%</th>
                            <th align="center" width="17%">
                                Request for This Draw <br />Col. 3
                            </th>
                            <th align="center" width="6%">%</th>
                            <th align="center" width="17%">
                                Adjusted Amounts <br />Col. 4
                            </th>
                            <th align="center" width="7%">%</th>
                        </thead>
                        <tbody>
                            <ng-container formArrayName="Constructions">
                                <ng-container
                                    *ngFor="
                                        let DrawRequestDetail of DrawRequestForm.get(
                                            'Constructions'
                                        )['controls'];
                                        let i = index
                                    "
                                >
                                    <tr [formGroupName]="i">
                                        <td>
                                            <span *ngIf="i != 35"
                                                >{{ i + 1 }}.
                                                {{
                                                    DrawRequestForm.value[
                                                        "Constructions"
                                                    ][i].RepairType
                                                }}</span
                                            >
                                            <strong *ngIf="i == 35">{{
                                                DrawRequestForm.value[
                                                    "Constructions"
                                                ][i].RepairType
                                            }}</strong>
                                        </td>
                                        <td align="center">
                                            <span
                                                *ngIf="
                                                    i != 35 &&
                                                    DrawRequestForm.value[
                                                        'Constructions'
                                                    ][i].TotalAmount == 0
                                                "
                                                >&nbsp;</span
                                            >
                                            <span
                                                *ngIf="
                                                    i != 35 &&
                                                    DrawRequestForm.value[
                                                        'Constructions'
                                                    ][i].TotalAmount != 0
                                                "
                                                >${{
                                                    DrawRequestForm.value[
                                                        "Constructions"
                                                    ][i].TotalAmount
                                                        | number : "1.2-2"
                                                }}</span
                                            >
                                            <strong
                                                *ngIf="
                                                    i == 35 &&
                                                    DrawRequestForm.value[
                                                        'Constructions'
                                                    ][i].TotalAmount == 0
                                                "
                                                >&nbsp;</strong
                                            >
                                            <strong
                                                *ngIf="
                                                    i == 35 &&
                                                    DrawRequestForm.value[
                                                        'Constructions'
                                                    ][i].TotalAmount != 0
                                                "
                                                >${{
                                                    DrawRequestForm.value[
                                                        "Constructions"
                                                    ][i].TotalAmount
                                                        | number : "1.2-2"
                                                }}</strong
                                            >
                                        </td>
                                        <td align="center">
                                            <span
                                                *ngIf="
                                                    i != 35 &&
                                                    DrawRequestForm.value[
                                                        'Constructions'
                                                    ][i].GCPreviewsAmount == 0
                                                "
                                                >&nbsp;</span
                                            >
                                            <span
                                                *ngIf="
                                                    i != 35 &&
                                                    DrawRequestForm.value[
                                                        'Constructions'
                                                    ][i].GCPreviewsAmount != 0
                                                "
                                                >${{
                                                    DrawRequestForm.value[
                                                        "Constructions"
                                                    ][i].GCPreviewsAmount
                                                        | number : "1.2-2"
                                                }}</span
                                            >
                                            <strong
                                                *ngIf="
                                                    i == 35 &&
                                                    DrawRequestForm.value[
                                                        'Constructions'
                                                    ][i].GCPreviewsAmount == 0
                                                "
                                                >&nbsp;</strong
                                            >
                                            <strong
                                                *ngIf="
                                                    i == 35 &&
                                                    DrawRequestForm.value[
                                                        'Constructions'
                                                    ][i].GCPreviewsAmount != 0
                                                "
                                                >${{
                                                    DrawRequestForm.value[
                                                        "Constructions"
                                                    ][i].GCPreviewsAmount
                                                        | number : "1.2-2"
                                                }}</strong
                                            >
                                        </td>
                                        <td align="center">
                                            <ng-container *ngIf="i != 35">
                                                <span
                                                    *ngIf="
                                                        DrawRequestForm.value[
                                                            'Constructions'
                                                        ][i].GCPreviewsAmount ==
                                                        0
                                                    "
                                                    >&nbsp;</span
                                                >
                                                <span
                                                    *ngIf="
                                                        DrawRequestForm.value[
                                                            'Constructions'
                                                        ][i].GCPreviewsAmount !=
                                                        0
                                                    "
                                                    >{{
                                                        (DrawRequestForm.value[
                                                            "Constructions"
                                                        ][i].GCPreviewsAmount *
                                                            100) /
                                                            DrawRequestForm
                                                                .value[
                                                                "Constructions"
                                                            ][i].TotalAmount
                                                            | number : "1.2-2"
                                                    }}%</span
                                                >
                                            </ng-container>
                                            <ng-container *ngIf="i == 35">
                                                <strong
                                                    *ngIf="
                                                        DrawRequestForm.value[
                                                            'Constructions'
                                                        ][i].GCPreviewsAmount ==
                                                        0
                                                    "
                                                    >&nbsp;</strong
                                                >
                                                <strong
                                                    *ngIf="
                                                        DrawRequestForm.value[
                                                            'Constructions'
                                                        ][i].GCPreviewsAmount !=
                                                        0
                                                    "
                                                    >{{
                                                        (DrawRequestForm.value[
                                                            "Constructions"
                                                        ][i].GCPreviewsAmount *
                                                            100) /
                                                            DrawRequestForm
                                                                .value[
                                                                "Constructions"
                                                            ][i].TotalAmount
                                                            | number : "1.2-2"
                                                    }}%</strong
                                                >
                                            </ng-container>
                                        </td>
                                        <td
                                            class="GCAmountInputField"
                                            align="center"
                                        >
                                            <span
                                                *ngIf="
                                                    i != 35 &&
                                                    DrawRequestForm.value[
                                                        'Constructions'
                                                    ][i].TotalAmount == 0
                                                "
                                                >&nbsp;</span
                                            >
                                            <mat-form-field
                                                *ngIf="
                                                    i != 35 &&
                                                    DrawRequestForm.value[
                                                        'Constructions'
                                                    ][i].TotalAmount != 0
                                                "
                                            >
                                                <span
                                                    >$<input
                                                        *ngIf="
                                                            DrawRequestForm
                                                                .value[
                                                                'Constructions'
                                                            ][i].TotalAmount !=
                                                            0
                                                        "
                                                        matInput
                                                        formControlName="GCAmount"
                                                        readonly="true"
                                                /></span>
                                            </mat-form-field>

                                            <span
                                                *ngIf="
                                                    i == 35 &&
                                                    DrawRequestForm.value[
                                                        'Constructions'
                                                    ][i].TotalAmount == 0
                                                "
                                                >&nbsp;</span
                                            >
                                            <mat-form-field
                                                *ngIf="
                                                    i == 35 &&
                                                    DrawRequestForm.value[
                                                        'Constructions'
                                                    ][i].TotalAmount != 0
                                                "
                                            >
                                                <span
                                                    >$<input
                                                        *ngIf="
                                                            DrawRequestForm
                                                                .value[
                                                                'Constructions'
                                                            ][i].TotalAmount !=
                                                            0
                                                        "
                                                        matInput
                                                        formControlName="TotalGCAmount"
                                                        readonly="true"
                                                /></span>
                                            </mat-form-field>
                                        </td>
                                        <td
                                            class="GCAmountInputPerField"
                                            align="center"
                                        >
                                            <ng-container *ngIf="i != 35">
                                                <span
                                                    *ngIf="
                                                        DrawRequestForm.value[
                                                            'Constructions'
                                                        ][i].TotalAmount == 0
                                                    "
                                                    >&nbsp;</span
                                                >
                                                <span
                                                    *ngIf="
                                                        DrawRequestForm.value[
                                                            'Constructions'
                                                        ][i].TotalAmount != 0
                                                    "
                                                    >{{
                                                        (DrawRequestForm.value[
                                                            "Constructions"
                                                        ][i].GCAmount *
                                                            100) /
                                                            DrawRequestForm
                                                                .value[
                                                                "Constructions"
                                                            ][i].TotalAmount
                                                            | number : "1.2-2"
                                                    }}%</span
                                                >
                                            </ng-container>
                                            <ng-container *ngIf="i == 35">
                                                <span
                                                    *ngIf="
                                                        DrawRequestForm.value[
                                                            'Constructions'
                                                        ][i].TotalGCPercent == 0
                                                    "
                                                    >&nbsp;</span
                                                >
                                                <input
                                                    *ngIf="
                                                        DrawRequestForm.value[
                                                            'Constructions'
                                                        ][i].TotalGCPercent != 0
                                                    "
                                                    matInput
                                                    formControlName="TotalGCPercent"
                                                    readonly="true"
                                                />%
                                            </ng-container>
                                        </td>
                                        <td align="center">
                                            <span *ngIf="i != 35">&nbsp;</span>
                                            <strong *ngIf="i == 35"
                                                >&nbsp;</strong
                                            >
                                        </td>
                                        <td align="center">
                                            <span *ngIf="i != 35">&nbsp;</span>
                                            <strong *ngIf="i == 35"
                                                >&nbsp;</strong
                                            >
                                        </td>
                                        <!-- <td align="center">
                        <span *ngIf="i!=35 && DrawRequestForm.value['Constructions'][i].ConsultantAmount==''">&nbsp;</span>
                        <span *ngIf="i!=35 && DrawRequestForm.value['Constructions'][i].ConsultantAmount!=''">${{DrawRequestForm.value['Constructions'][i].ConsultantAmount | number : '1.2-2'}}</span>
                        <strong *ngIf="i==35 && DrawRequestForm.value['Constructions'][i].ConsultantAmount==''">&nbsp;</strong>
                        <strong *ngIf="i==35 && DrawRequestForm.value['Constructions'][i].ConsultantAmount!=''">${{DrawRequestForm.value['Constructions'][i].ConsultantAmount | number : '1.2-2'}}</strong>
                    </td>
                    <td align="center">
                        <ng-container *ngIf="i!=35">
                            <span *ngIf="DrawRequestForm.value['Constructions'][i].ConsultantAmount!=0 ">{{(DrawRequestForm.value['Constructions'][i].ConsultantAmount)*100/DrawRequestForm.value['Constructions'][i].TotalAmount | number : '1.2-2'}}%</span>
                            <span *ngIf="DrawRequestForm.value['Constructions'][i].ConsultantAmount==0 ">&nbsp;</span>
                          
                        </ng-container>
                        <ng-container *ngIf="i==35">
                            <strong *ngIf="DrawRequestForm.value['Constructions'][i].ConsultantAmount!=0 ">{{(DrawRequestForm.value['Constructions'][i].ConsultantAmount)*100/DrawRequestForm.value['Constructions'][i].TotalAmount | number : '1.2-2'}}%</strong>
                            <strong *ngIf="DrawRequestForm.value['Constructions'][i].ConsultantAmount==0 ">&nbsp;</strong>
                          
                        </ng-container>
                      </td> -->
                                    </tr>
                                </ng-container>
                            </ng-container>
                        </tbody>
                    </table>
                    <br />
                    <br />
                    <mat-form-field *ngIf="drawRequestBOAction == 'Revise'">
                        <span class="mat-form-field-label-wrapper"
                            ><!----><label
                                class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                >Comment</label
                            ></span
                        >
                        <textarea
                            matInput
                            formControlName="BorrowerComment"
                        ></textarea>
                    </mat-form-field>
                    <p *ngIf="drawRequestBOAction == 'Accept'">
                        By Proceeding with this submittal, you understand,
                        acknowledge and accept that the site review conducted by
                        A.M. Renovation Consulting is for the sole purpose of
                        confirming that work associated with the funds requested
                        in Column 3 has been completed. You further understand,
                        acknowledge and accept that A.M. Renovation Consulting
                        (including its officers, employees and representatives)
                        <strong> is not associated</strong> with the city or
                        town, and
                        <strong>will not inspect the property</strong> for
                        compliance with applicable building codes.
                    </p>
                    <br />
                    <div class="btnSection">
                    <button
                        mat-raised-button
                        type="button"
                        *ngIf="
                            drawRequestBOAction != 'Accept' &&
                            drawRequestBOAction != 'Revise'
                        "
                        (click)="changeAction('Accept')"
                        color="primary"
                        style="margin: 0 5px 0 0px"
                    >
                        Accept Col 3
                    </button>
                    <button
                        mat-raised-button
                        type="button"
                        *ngIf="
                            drawRequestBOAction != 'Accept' &&
                            drawRequestBOAction != 'Revise'
                        "
                        (click)="changeAction('Revise')"
                        color="primary"
                    >
                        Revise Col 3
                    </button>
                    </div>
                </mat-card-content>
                <mat-card-actions class="matActions" *ngIf="drawRequestBOAction == 'Accept'">
                    <div class="btnSection">
                    <button
                        mat-raised-button
                        type="button"
                        (click)="SaveDrawRequest('approved')"
                        color="primary"
                    >
                        Accept
                    </button>
                    <button
                        mat-raised-button
                        type="button"
                        (click)="cancelAction()"
                        color="primary"
                    >
                        Cancel
                    </button>
                    </div>
                </mat-card-actions>
                <mat-card-actions class="matActions" *ngIf="drawRequestBOAction == 'Revise'">
                    <div class="btnSection">
                    <button
                        mat-raised-button
                        type="button"
                        (click)="SaveDrawRequest('revised')"
                        color="primary"
                    >
                        Revise
                    </button>
                    <button
                        mat-raised-button
                        type="button"
                        (click)="cancelAction()"
                        color="primary"
                    >
                        Cancel
                    </button>
                    </div>
                </mat-card-actions>
            </form>
        </mat-card>
    </div>
</div>
