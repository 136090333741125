import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { GooglePlaceModule } from "@yuvarajv/ngx-google-places-autocomplete";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatTableModule } from "@angular/material/table";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatDialogModule } from "@angular/material/dialog";
import { TranslateModule } from "@ngx-translate/core";
import { CKEditorModule } from "ckeditor4-angular";
import { NgxLoadingModule } from "ngx-loading";
import { FuseSharedModule } from "@fuse/shared.module";
import {
    PropertyComponent,
    DialogContentExampleDialog,
} from "./property.component";
import { NgxMaskDirective, NgxMaskPipe } from "ngx-mask";

const routes = [
    {
        path: "properties",
        component: PropertyComponent,
    },
    {
        path: "edit-properties/:propertyId",
        component: PropertyComponent,
    },
];

@NgModule({
    declarations: [PropertyComponent, DialogContentExampleDialog],
    imports: [
        RouterModule.forChild(routes),
        GooglePlaceModule,
        TranslateModule,
        NgxLoadingModule.forRoot({}),
        FuseSharedModule,
        MatButtonModule,
        MatCardModule,
        MatFormFieldModule,
        MatInputModule,
        MatTableModule,
        MatTooltipModule,
        MatPaginatorModule,
        MatIconModule,
        MatRadioModule,
        MatCheckboxModule,
        MatSelectModule,
        MatDatepickerModule,
        CKEditorModule,
        MatDialogModule,
        NgxMaskDirective,
        NgxMaskPipe
    ],
    exports: [PropertyComponent, DialogContentExampleDialog],
})
export class PropertyModule {}
