<div class="content p-24 tbl">
    <ngx-loading class="ngxLoader" 
        [show]="loading"
        [config]="{ backdropBorderRadius: '3px' }"
    ></ngx-loading>
    <h1 class="m-0">Initial Draw Request</h1>
    <!-- <a *ngIf="isList" class="addBorrow" href="javascript:void(0)" (click)="isList=false;isAdd=true;isEdit=false;formReset()"><mat-icon _ngcontent-c102="" class="secondary-text s-22 mat-icon material-icons mat-icon-no-color" role="img" aria-hidden="true">add_circle</mat-icon> Add New Borrower</a> -->
    <a
        class="addBorrow"
        href="javascript:void(0)"
        routerLink="/property-details/{{ RepairPropertyId }}/1"
    >
        Back to Property</a
    >
    <!-- <mat-form-field>
  <input [hidden]="!isList" matInput (keyup)="applyFilter($event.target)" placeholder="Filter">
</mat-form-field> -->

    <br />
    <br />
    <p>
        Please reference the Specification of Repair form
        <a href="javascript:void(0);" (click)="GetFinalSORPDF()">(Link)</a> to
        property fill out the values for Column 3.
    </p>

    <div>
        <mat-card class="my-form">
            <form
                [formGroup]="DrawRequestForm"
                class="example-form"
                (ngSubmit)="SaveDrawRequest('no')"
            >
                <mat-card-header>
                    <mat-card-title></mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <table class="drawComponentListTable">
                        <thead>
                            <th align="left" width="15%">Construction Item</th>
                            <th align="center" width="15%">
                                Total Escrow <br />Col. 1
                            </th>
                            <th align="center" width="17%">
                                Previous Draw Totals<br />
                                Col. 2
                            </th>
                            <th align="center" width="6%">%</th>
                            <th align="center" width="17%">
                                Request for This Draw <br />Col. 3
                            </th>
                            <th align="center" width="6%">%</th>
                            <th align="center" width="17%">
                                Adjusted Amounts <br />Col. 4
                            </th>
                            <th
                                align="center"
                                style="max-width: 80px"
                                width="80px"
                            >
                                %
                            </th>
                        </thead>
                        <tbody>
                            <ng-container formArrayName="Constructions">
                                <ng-container
                                    *ngFor="
                                        let DrawRequestDetail of DrawRequestForm.get(
                                            'Constructions'
                                        )['controls'];
                                        let i = index
                                    "
                                >
                                    <tr [formGroupName]="i">
                                        <td>
                                            <span *ngIf="i != 35"
                                                >{{ i + 1 }}.
                                                {{
                                                    DrawRequestForm.value[
                                                        "Constructions"
                                                    ][i].RepairType
                                                }}</span
                                            >
                                            <strong *ngIf="i == 35">
                                                {{
                                                    DrawRequestForm.value[
                                                        "Constructions"
                                                    ][i].RepairType
                                                }}</strong
                                            >
                                        </td>
                                        <td align="center">
                                            <span
                                                *ngIf="
                                                    i != 35 &&
                                                    DrawRequestForm.value[
                                                        'Constructions'
                                                    ][i].TotalAmount == 0
                                                "
                                                >&nbsp;</span
                                            >
                                            <span
                                                *ngIf="
                                                    i != 35 &&
                                                    DrawRequestForm.value[
                                                        'Constructions'
                                                    ][i].TotalAmount != 0
                                                "
                                                >${{
                                                    DrawRequestForm.value[
                                                        "Constructions"
                                                    ][i].TotalAmount
                                                        | number : "1.2-2"
                                                }}</span
                                            >
                                            <strong
                                                *ngIf="
                                                    i == 35 &&
                                                    DrawRequestForm.value[
                                                        'Constructions'
                                                    ][i].TotalAmount == 0
                                                "
                                                >&nbsp;</strong
                                            >
                                            <strong
                                                *ngIf="
                                                    i == 35 &&
                                                    DrawRequestForm.value[
                                                        'Constructions'
                                                    ][i].TotalAmount != 0
                                                "
                                                >${{
                                                    DrawRequestForm.value[
                                                        "Constructions"
                                                    ][i].TotalAmount
                                                        | number : "1.2-2"
                                                }}</strong
                                            >
                                        </td>
                                        <td align="center">
                                            <span
                                                *ngIf="
                                                    i != 35 &&
                                                    DrawRequestForm.value[
                                                        'Constructions'
                                                    ][i].GCPreviewsAmount == 0
                                                "
                                                >&nbsp;</span
                                            >
                                            <span
                                                *ngIf="
                                                    i != 35 &&
                                                    DrawRequestForm.value[
                                                        'Constructions'
                                                    ][i].GCPreviewsAmount != 0
                                                "
                                                >${{
                                                    DrawRequestForm.value[
                                                        "Constructions"
                                                    ][i].GCPreviewsAmount
                                                        | number : "1.2-2"
                                                }}</span
                                            >
                                            <strong
                                                *ngIf="
                                                    i == 35 &&
                                                    DrawRequestForm.value[
                                                        'Constructions'
                                                    ][i].GCPreviewsAmount == 0
                                                "
                                                >&nbsp;</strong
                                            >
                                            <strong
                                                *ngIf="
                                                    i == 35 &&
                                                    DrawRequestForm.value[
                                                        'Constructions'
                                                    ][i].GCPreviewsAmount != 0
                                                "
                                                >${{
                                                    DrawRequestForm.value[
                                                        "Constructions"
                                                    ][i].GCPreviewsAmount
                                                        | number : "1.2-2"
                                                }}</strong
                                            >
                                        </td>
                                        <td align="center">
                                            <ng-container *ngIf="i != 35">
                                                <span
                                                    *ngIf="
                                                        DrawRequestForm.value[
                                                            'Constructions'
                                                        ][i].GCPreviewsAmount ==
                                                        0
                                                    "
                                                    >&nbsp;</span
                                                >
                                                <span
                                                    *ngIf="
                                                        DrawRequestForm.value[
                                                            'Constructions'
                                                        ][i].GCPreviewsAmount !=
                                                        0
                                                    "
                                                    >{{
                                                        (DrawRequestForm.value[
                                                            "Constructions"
                                                        ][i].GCPreviewsAmount *
                                                            100) /
                                                            DrawRequestForm
                                                                .value[
                                                                "Constructions"
                                                            ][i].TotalAmount
                                                            | number : "1.2-2"
                                                    }}%</span
                                                >
                                            </ng-container>
                                            <ng-container *ngIf="i == 35">
                                                <strong
                                                    *ngIf="
                                                        DrawRequestForm.value[
                                                            'Constructions'
                                                        ][i].GCPreviewsAmount ==
                                                        0
                                                    "
                                                    >&nbsp;</strong
                                                >
                                                <strong
                                                    *ngIf="
                                                        DrawRequestForm.value[
                                                            'Constructions'
                                                        ][i].GCPreviewsAmount !=
                                                        0
                                                    "
                                                    >{{
                                                        (DrawRequestForm.value[
                                                            "Constructions"
                                                        ][i].GCPreviewsAmount *
                                                            100) /
                                                            DrawRequestForm
                                                                .value[
                                                                "Constructions"
                                                            ][i].TotalAmount
                                                            | number : "1.2-2"
                                                    }}%</strong
                                                >
                                            </ng-container>
                                        </td>
                                        <td align="center">
                                            <span
                                                *ngIf="
                                                    i != 35 &&
                                                    DrawRequestForm.value[
                                                        'Constructions'
                                                    ][i].GCAmount == 0
                                                "
                                                >&nbsp;</span
                                            >
                                            <span
                                                *ngIf="
                                                    i != 35 &&
                                                    DrawRequestForm.value[
                                                        'Constructions'
                                                    ][i].GCAmount != 0
                                                "
                                                >${{
                                                    DrawRequestForm.value[
                                                        "Constructions"
                                                    ][i].GCAmount
                                                        | number : "1.2-2"
                                                }}</span
                                            >
                                            <strong
                                                *ngIf="
                                                    i == 35 &&
                                                    DrawRequestForm.value[
                                                        'Constructions'
                                                    ][i].TotalGCAmount == 0
                                                "
                                                >&nbsp;</strong
                                            >
                                            <strong
                                                *ngIf="
                                                    i == 35 &&
                                                    DrawRequestForm.value[
                                                        'Constructions'
                                                    ][i].TotalGCAmount != 0
                                                "
                                                >${{
                                                    DrawRequestForm.value[
                                                        "Constructions"
                                                    ][i].TotalGCAmount
                                                        | number : "1.2-2"
                                                }}</strong
                                            >
                                        </td>
                                        <td align="center">
                                            <ng-container *ngIf="i != 35">
                                                <span
                                                    *ngIf="
                                                        DrawRequestForm.value[
                                                            'Constructions'
                                                        ][i].GCAmount != 0
                                                    "
                                                    >{{
                                                        (DrawRequestForm.value[
                                                            "Constructions"
                                                        ][i].GCAmount *
                                                            100) /
                                                            DrawRequestForm
                                                                .value[
                                                                "Constructions"
                                                            ][i].TotalAmount
                                                            | number : "1.2-2"
                                                    }}%</span
                                                >
                                                <span
                                                    *ngIf="
                                                        DrawRequestForm.value[
                                                            'Constructions'
                                                        ][i].GCAmount == 0
                                                    "
                                                    >&nbsp;</span
                                                >
                                            </ng-container>
                                            <ng-container *ngIf="i == 35">
                                                <span
                                                    *ngIf="
                                                        DrawRequestForm.value[
                                                            'Constructions'
                                                        ][i].TotalGCAmount != 0
                                                    "
                                                    >{{
                                                        (DrawRequestForm.value[
                                                            "Constructions"
                                                        ][i].TotalGCAmount *
                                                            100) /
                                                            DrawRequestForm
                                                                .value[
                                                                "Constructions"
                                                            ][i].TotalAmount
                                                            | number : "1.2-2"
                                                    }}%</span
                                                >
                                                <span
                                                    *ngIf="
                                                        DrawRequestForm.value[
                                                            'Constructions'
                                                        ][i].TotalGCAmount == 0
                                                    "
                                                    >&nbsp;</span
                                                >
                                            </ng-container>
                                        </td>
                                        <td
                                            class="COAmountInputField"
                                            align="center"
                                        >
                                            <span
                                                *ngIf="
                                                    i != 35 &&
                                                    DrawRequestForm.value[
                                                        'Constructions'
                                                    ][i].TotalAmount == 0
                                                "
                                                >&nbsp;</span
                                            >
                                            <span
                                                *ngIf="
                                                    i != 35 &&
                                                    DrawRequestForm.value[
                                                        'Constructions'
                                                    ][i].TotalAmount != 0 &&
                                                    DrawRequestForm.value[
                                                        'Constructions'
                                                    ][i].GCAmount == 0
                                                "
                                                >&nbsp;</span
                                            >

                                            <ng-container
                                                *ngIf="
                                                    i != 35 &&
                                                    DrawRequestForm.value[
                                                        'Constructions'
                                                    ][i].TotalAmount != 0 &&
                                                    DrawRequestForm.value[
                                                        'Constructions'
                                                    ][i].GCAmount != 0
                                                "
                                            >
                                                <label
                                                    style="
                                                        float: left;
                                                        margin-left: 8%;
                                                    "
                                                    *ngIf="
                                                        DrawRequestForm.value[
                                                            'Constructions'
                                                        ][i].TotalAmount != 0 &&
                                                        DrawRequestForm.value[
                                                            'Constructions'
                                                        ][i]
                                                            .ConsultantAmount ===
                                                            ''
                                                    "
                                                    >Enter Adjusted
                                                    Amounts</label
                                                >
                                                <span
                                                    >$<input
                                                        *ngIf="
                                                            DrawRequestForm
                                                                .value[
                                                                'Constructions'
                                                            ][i].TotalAmount !=
                                                            0
                                                        "
                                                        (blur)="
                                                            onBlurCOAmount()
                                                        "
                                                        (keyup)="
                                                            onKeyUpCOAmount(i)
                                                        "
                                                        matInput
                                                        placeholder="Enter Adjusted Amounts"
                                                        formControlName="ConsultantAmount"
                                                /></span>
                                            </ng-container>

                                            <span
                                                *ngIf="
                                                    i == 35 &&
                                                    DrawRequestForm.value[
                                                        'Constructions'
                                                    ][i].TotalCOAmount == 0
                                                "
                                                >&nbsp;</span
                                            >
                                            <mat-form-field
                                                *ngIf="
                                                    i == 35 &&
                                                    DrawRequestForm.value[
                                                        'Constructions'
                                                    ][i].TotalCOAmount != 0
                                                "
                                            >
                                                <span
                                                    >$<input
                                                        *ngIf="
                                                            DrawRequestForm
                                                                .value[
                                                                'Constructions'
                                                            ][i]
                                                                .TotalCOAmount !=
                                                            0
                                                        "
                                                        matInput
                                                        formControlName="TotalCOAmount"
                                                        readonly="true"
                                                /></span>
                                            </mat-form-field>
                                        </td>
                                        <td
                                            class="COAmountInputPerField"
                                            align="center"
                                        >
                                            <ng-container *ngIf="i != 35">
                                                <span
                                                    *ngIf="
                                                        DrawRequestForm.value[
                                                            'Constructions'
                                                        ][i].TotalAmount == 0 ||
                                                        DrawRequestForm.value[
                                                            'Constructions'
                                                        ][i].TotalAmount == 0
                                                    "
                                                    >&nbsp;</span
                                                >
                                                <span
                                                    *ngIf="
                                                        DrawRequestForm.value[
                                                            'Constructions'
                                                        ][i].TotalAmount != 0 &&
                                                        DrawRequestForm.value[
                                                            'Constructions'
                                                        ][i].TotalAmount != 0
                                                    "
                                                    >{{
                                                        (DrawRequestForm.value[
                                                            "Constructions"
                                                        ][i].ConsultantAmount *
                                                            100) /
                                                            DrawRequestForm
                                                                .value[
                                                                "Constructions"
                                                            ][i].TotalAmount
                                                            | number : "1.2-2"
                                                    }}%</span
                                                >
                                            </ng-container>
                                            <ng-container *ngIf="i == 35">
                                                <span
                                                    *ngIf="
                                                        DrawRequestForm.value[
                                                            'Constructions'
                                                        ][i].TotalCOPercent == 0
                                                    "
                                                    >&nbsp;</span
                                                >
                                                <mat-form-field
                                                    *ngIf="
                                                        DrawRequestForm.value[
                                                            'Constructions'
                                                        ][i].TotalCOPercent != 0
                                                    "
                                                >
                                                    <input
                                                        *ngIf="
                                                            DrawRequestForm
                                                                .value[
                                                                'Constructions'
                                                            ][i]
                                                                .TotalCOPercent !=
                                                            0
                                                        "
                                                        matInput
                                                        formControlName="TotalCOPercent"
                                                        readonly="true"
                                                    />%
                                                </mat-form-field>
                                            </ng-container>
                                        </td>
                                    </tr>
                                </ng-container>
                            </ng-container>
                        </tbody>
                    </table>

                    <ng-container>
                        <strong style="margin-top: 20px; display: flex"
                            >Explaination of statements</strong
                        >
                        <table
                            border="1"
                            border-collapse="collapse"
                            cellpadding="5px"
                        >
                            <thead>
                                <tr>
                                    <th>No</th>
                                    <th>Description</th>
                                    <th style="width: 10%">&nbsp;</th>
                                </tr>
                            </thead>
                            <tbody>
                                <ng-container
                                    *ngFor="
                                        let ExplainationComments of DrawRequestForm.get(
                                            'ExplainationComments'
                                        )['controls'];
                                        let i = index
                                    "
                                    formArrayName="ExplainationComments"
                                >
                                    <ng-container [formGroupName]="i">
                                        <tr
                                            *ngIf="
                                                DrawRequestForm.value[
                                                    'ExplainationComments'
                                                ][i].IsShow == true
                                            "
                                        >
                                            <td style="width: 10%">
                                                <input
                                                    matInput
                                                    placeholder="1"
                                                    formControlName="StatementNumber"
                                                />
                                            </td>
                                            <td>
                                                <textarea
                                                    style="
                                                        width: 100%;
                                                        height: 100px;
                                                    "
                                                    matInput
                                                    placeholder="explain the statements"
                                                    maxlength="165"
                                                    formControlName="StatementDescription"
                                                ></textarea>
                                            </td>
                                            <td>
                                                <span
                                                    class="removeButton"
                                                    (click)="
                                                        removeExplainationComment(
                                                            i
                                                        )
                                                    "
                                                    >Remove</span
                                                >
                                            </td>
                                        </tr>
                                    </ng-container>
                                </ng-container>
                            </tbody>
                        </table>
                        <br />
                        <span
                            class="removeButton"
                            (click)="addNewExplainationComment()"
                            >Add another item</span
                        >
                        <br />
                        <br />
                        <strong>Date of Inspection</strong>
                        <mat-form-field class="datePickField">
                            <input
                                matInput
                                [matDatepicker]="picker"
                                (focus)="picker.open()"
                                formControlName="ConsultantRequestDate"
                            />
                            <mat-datepicker-toggle
                                matSuffix
                                [for]="picker"
                            ></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>
                        <br />
                        <br />
                        <strong
                            >Inspection of On-Site Repairs and/or<br />Improvements
                            Reveals</strong
                        >
                        <ul>
                            <ng-container
                                *ngFor="
                                    let ImprovementsReveals of DrawRequestForm.get(
                                        'ImprovementsReveals'
                                    )['controls'];
                                    let i = index
                                "
                                formArrayName="ImprovementsReveals"
                            >
                                <li [formGroupName]="i">
                                    <mat-checkbox
                                        formControlName="Status"
                                    ></mat-checkbox
                                    >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{
                                        DrawRequestForm.value[
                                            "ImprovementsReveals"
                                        ][i].Title
                                    }}
                                </li>
                            </ng-container>
                        </ul>
                        <strong
                            >Explaination of statements checked above</strong
                        >
                        <ul>
                            <ng-container
                                *ngFor="
                                    let ExplainationStatements of DrawRequestForm.get(
                                        'ExplainationStatements'
                                    )['controls'];
                                    let i = index
                                "
                                formArrayName="ExplainationStatements"
                            >
                                <li [formGroupName]="i">
                                    <mat-checkbox
                                        formControlName="Status"
                                    ></mat-checkbox
                                    >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{
                                        DrawRequestForm.value[
                                            "ExplainationStatements"
                                        ][i].Title
                                    }}
                                </li>
                            </ng-container>
                        </ul>
                        <strong>Inspect by</strong>
                        <ul>
                            <ng-container
                                *ngFor="
                                    let InspectBy of DrawRequestForm.get(
                                        'InspectBy'
                                    )['controls'];
                                    let i = index
                                "
                                formArrayName="InspectBy"
                            >
                                <li [formGroupName]="i">
                                    <mat-checkbox
                                        formControlName="Status"
                                    ></mat-checkbox
                                    >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{
                                        DrawRequestForm.value["InspectBy"][i]
                                            .Title
                                    }}
                                </li>
                            </ng-container>
                        </ul>
                        <strong>Contractor Has Furnished Section</strong>
                        <textarea
                            style="width: 100%; height: 100px"
                            matInput
                            placeholder="See specs of repairs"
                            formControlName="ContractorFurnishedSec"
                            name="ContractorFurnishedSec"
                        ></textarea>
                    </ng-container>
                </mat-card-content>
                <mat-card-actions  class="matActions" >
                    <div class="btnSection">
                    <button mat-raised-button type="submit" color="primary">
                        Save
                    </button>
                    <button
                        (click)="submitDrawRequest('yes')"
                        style="margin-left: 10px"
                        mat-raised-button
                        color="primary"
                    >
                        Submit
                    </button>
                    </div>
                </mat-card-actions>
            </form>
        </mat-card>
    </div>
</div>
