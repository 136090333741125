<div class="content gcContent p-24 tbl">
    <ngx-loading class="ngxLoader" 
        [show]="loading"
        [config]="{ backdropBorderRadius: '3px' }"
    ></ngx-loading>
    <h1 class="m-0">Contractor Project Estimate</h1>
    <!-- <a *ngIf="isList" class="addBorrow" href="javascript:void(0)" (click)="isList=false;isAdd=true;isEdit=false;formReset()"><mat-icon _ngcontent-c102="" class="secondary-text s-22 mat-icon material-icons mat-icon-no-color" role="img" aria-hidden="true">add_circle</mat-icon> Add New Borrower</a> -->
    <a class="addBorrow" href="javascript:void(0)" routerLink="/properties">
        Back to Property</a
    >

    <!-- <mat-form-field>
  <input [hidden]="!isList" matInput (keyup)="applyFilter($event.target)" placeholder="Filter">
</mat-form-field> -->

    <br />

    <!--- New Code Section --->
    <mat-progress-bar
        mode="determinate"
        value="{{ progressValue }}"
    ></mat-progress-bar>
    <div class="progressValue">{{ progressValue | number : "1.0-2" }}%</div>
    <br />

    <mat-tab-group
        mat-align-tabs="start"
        [(selectedIndex)]="selectedIndex"
        style="clear: both"
    >
        <mat-tab>
            <ng-template mat-tab-label>
                <div class="mainmenu_tabContent" (click)="removeAllExpand(0)">
                    <span class="labelText">Introduction</span>
                </div>
            </ng-template>
            <mat-card>
                <h2 style="text-align: center; font-size: 22px">
                    Renovation Estimate Tool
                </h2>
                <br />
                <div class="welcometextContent">
                    <p>
                        Welcome to the Renovatino Estimate Tool! You have been
                        invited by your borrower to provide an estimate for the
                        renovation loan. This tool will assist you with the
                        estimate for the renovation project and ensure it is
                        submitted in the correct format.
                    </p>
                    <br />
                    <div
                        style="
                            width: 100%;
                            text-align: center;
                            font-weight: bold;
                            font-size: 20px;
                            font-style: italic;
                        "
                    >
                        <a href="javascript:void(0)"
                            >Click HERE for Renovation Estimate Tool
                            Walkthrough</a
                        >
                    </div>
                    <br />

                    <p>
                        The lender's Renovation Consultant has produced a Draft
                        Scope of Work for the project which is broken down into
                        various construction components. The components
                        identified in the report reflect a blend of mandatory
                        (Level M) repairs based on the loan program, recommended
                        (Level R) items from the consultant and/or home
                        inspector and work desired (Level D) by the
                        borrower/homeowner.
                    </p>

                    <h2 style="text-decoration: underline">Mandatory (M)</h2>
                    <ul>
                        <li>
                            Construction components labelled level "M" are
                            mandatory.
                        </li>
                        <li>Cannot be removed from the estimate</li>
                        <li>pricing must be provided.</li>
                    </ul>
                    <h2 style="text-decoration: underline">Recommended (R)</h2>
                    <ul>
                        <li>
                            Suggested by the consultant but are not required.
                        </li>
                        <li>
                            Do not need to have pricing provided if no longer
                            desired by borrower.
                        </li>
                    </ul>
                    <h2 style="text-decoration: underline">Desired (D)</h2>
                    <ul>
                        <li>
                            Renovation work desired by the borrower but are not
                            required.
                        </li>
                        <li>
                            Do not need to have pricing provided if no longer
                            desired by borrower.
                        </li>
                        <li>
                            Additional desired items can be added to the
                            estimate using the
                            <span style="font-weight: bold; font-style: italic"
                                >ADDITIONAL</span
                            >
                            section of this tool.
                        </li>
                    </ul>

                    <p>
                        if you would like to review the Draft Scope of Work
                        which you will be providing pricing for, please use this
                        link
                        <a
                            href="javascript:void(0)"
                            (click)="GetBlankBORPdf(RepairPropertyId)"
                            style="font-weight: bold; font-style: italic"
                            >DRAFT SCOPE OF WORK</a
                        >.
                    </p>
                </div>
                <button
                    class="startEstimateBtn"
                    *ngIf="
                        mandatoryFirstItem != '' &&
                        mandatoryFirstItem != null &&
                        mandatoryFirstItem != undefined
                    "
                    (click)="
                        gotoTab(
                            1,
                            mandatoryFirstItem,
                            mandatoryFirstItemRepairName,
                            'M'
                        )
                    "
                >
                    Start Estimate
                </button>
                <button
                    class="startEstimateBtn"
                    *ngIf="
                        mandatoryFirstItem == '' &&
                        recommendedFirstItem != '' &&
                            recommendedFirstItem != null &&
                            recommendedFirstItem != undefined
                    "
                    (click)="
                        gotoTab(
                            2,
                            recommendedFirstItem,
                            recommendedFirstItemRepairName,
                            'R'
                        )
                    "
                >
                    Start Estimate
                </button>
                <button
                    class="startEstimateBtn"
                    *ngIf="
                        mandatoryFirstItem == '' &&
                        recommendedFirstItem == '' &&
                        desiredFirstItem != '' &&
                            desiredFirstItem != null &&
                            desiredFirstItem != undefined
                    "
                    (click)="
                        gotoTab(
                            3,
                            desiredFirstItem,
                            desiredFirstItemRepairName,
                            'D'
                        )
                    "
                >
                    Start Estimate
                </button>
                <!-- <button class="startEstimateBtn" *ngIf="mandatoryFirstItem=='' && recommendedFirstItem=='' && desiredFirstItem=='' && (additionalDesiredFirstItem!='' && additionalDesiredFirstItem!=null && additionalDesiredFirstItem!=undefined)"  (click)="gotoTab(4,additionalDesiredFirstItem,'D')" >Start Estimate </button> -->
                <button
                    class="startEstimateBtn"
                    *ngIf="
                        mandatoryFirstItem == '' &&
                        recommendedFirstItem == '' &&
                        desiredFirstItem == ''
                    "
                    (click)="gotoTab('5')"
                >
                    Start Estimate
                </button>
            </mat-card>
        </mat-tab>

        <mat-tab>
            <ng-template mat-tab-label>
                <div
                    class="mainmenu_tabContent {{ isAllExpandClass }}"
                    (click)="
                        UpdateRepair(
                            mandatoryFirstItem,
                            mandatoryFirstItemRepairName,
                            'M',
                            mandatoryFirstItemLocation
                        )
                    "
                >
                    <mat-icon
                        class="plusIcon example-tab-icon"
                        *ngIf="
                            (isMinusShow == true &&
                                currentClick != 'mandatory' &&
                                mandatoryFirstItem != '') ||
                            (isPlusShow == true &&
                                mandatoryFirstItem != '' &&
                                mandatoryFirstItem != null &&
                                mandatoryFirstItem != undefined)
                        "
                        (click)="expandFunction('mandatory')"
                        >&#43;</mat-icon
                    >
                    <mat-icon
                        class="minusIcon example-tab-icon"
                        *ngIf="
                            (isMinusShow == true && isAllexpand == true) ||
                            (currentClick == 'mandatory' &&
                                isMinusShow == true &&
                                mandatoryFirstItem != '' &&
                                mandatoryFirstItem != null &&
                                mandatoryFirstItem != undefined)
                        "
                        (click)="closedFunction()"
                        >&#8722;</mat-icon
                    >
                    <span class="labelText">Mandatory</span>
                </div>
                <ul
                    class="submenu_tabContent {{ isAllExpandClass }}"
                    *ngIf="isSubTabContentShow == true || isAllexpand == true"
                >
                    <ng-container
                        *ngFor="
                            let mandatoryItem of allRepairItem;
                            let i = index
                        "
                    >
                        <ng-container
                            *ngFor="
                                let mandatoryItemRepair of mandatoryItem.RepairItemDetails;
                                let j = index
                            "
                        >
                            <li
                                *ngIf="
                                    mandatoryItemRepair.Mandatory == true &&
                                    mandatoryItem.Operation != 'Inactive'
                                "
                            >
                                <a
                                    class="itemLink"
                                    *ngIf="
                                        mandatoryItemRepair.isItemCompleted !=
                                        true
                                    "
                                    href="javascript:void(0)"
                                    (click)="
                                        UpdateRepair(
                                            mandatoryItem.RepairType,
                                            mandatoryItemRepair.RepairItemName,
                                            'M',
                                            mandatoryItemRepair.Location
                                        )
                                    "
                                    >Item {{ mandatoryItem.id }}:
                                    {{ mandatoryItem.RepairType }}<br />
                                    ({{
                                        mandatoryItemRepair.RepairItemName
                                    }})</a
                                >
                                <a
                                    class="itemLink isItemCompleted"
                                    *ngIf="
                                        mandatoryItemRepair.isItemCompleted ==
                                        true
                                    "
                                    href="javascript:void(0)"
                                    (click)="
                                        UpdateRepair(
                                            mandatoryItem.RepairType,
                                            mandatoryItemRepair.RepairItemName,
                                            'M',
                                            mandatoryItemRepair.Location
                                        )
                                    "
                                    >Item {{ mandatoryItem.id }}:
                                    {{ mandatoryItem.RepairType }}<br />
                                    ({{
                                        mandatoryItemRepair.RepairItemName
                                    }})</a
                                >
                            </li>
                        </ng-container>
                    </ng-container>
                </ul>
            </ng-template>

            <mat-card>
                <div
                    *ngIf="
                        mandatoryFirstItem != '' &&
                        mandatoryFirstItem != null &&
                        mandatoryFirstItem != undefined
                    "
                    class="expandFormControls container-login100"
                >
                    <form
                        [formGroup]="RepairItemForm"
                        class="example-form"
                        (ngSubmit)="SaveConstructionItem()"
                    >
                        <mat-card-content>
                            <mat-form-field>
                                <span class="mat-form-field-label-wrapper"
                                    ><!----><label
                                        class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                        >Construction Item
                                    </label></span
                                >
                                <select
                                    matNativeControl
                                    formControlName="SendRepairType"
                                    [disabled]="true"
                                >
                                    <option value="">Select</option>
                                    <option
                                        *ngFor="let value of userList.Items"
                                        value="{{ value.RepairType }}"
                                    >
                                        {{ value.id }} {{ value.RepairType }}
                                    </option>
                                </select>
                            </mat-form-field>
                            <h3><strong>Repair Item Details</strong></h3>

                            <div class="RepairItemDetailsList">
                                <div
                                    *ngFor="
                                        let RepairItemDetail of RepairItemForm.get(
                                            'RepairItemDetails'
                                        )['controls'];
                                        let i = index
                                    "
                                    formArrayName="RepairItemDetails"
                                >
                                    <!-- *ngIf="RepairItemForm.value['RepairItemDetails'][i].RepairItemName==mandatoryItemRepair.RepairItemName" -->
                                    <div [formGroupName]="i">
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <mat-form-field
                                                            class="example-full-width"
                                                        >
                                                            <span
                                                                class="mat-form-field-label-wrapper"
                                                                ><!----><label
                                                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                                    >Repair Item
                                                                    Name</label
                                                                ></span
                                                            >
                                                            <input
                                                                matInput
                                                                [attr.disabled]="
                                                                    false
                                                                "
                                                                formControlName="RepairItemName"
                                                                name="RepairItemName"
                                                            />
                                                        </mat-form-field>
                                                    </td>
                                                    <td>
                                                        <mat-form-field
                                                            class="example-full-width"
                                                        >
                                                            <span
                                                                class="mat-form-field-label-wrapper"
                                                                ><!----><label
                                                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                                    >Location
                                                                </label></span
                                                            >
                                                            <input
                                                                matInput
                                                                formControlName="Location"
                                                                name="Location"
                                                            />
                                                        </mat-form-field>
                                                    </td>
                                                    <td>
                                                        <mat-form-field>
                                                            <span
                                                                class="mat-form-field-label-wrapper"
                                                                ><!----><label
                                                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                                    >Level
                                                                </label></span
                                                            >
                                                            <select
                                                                matNativeControl
                                                                formControlName="Level"
                                                            >
                                                                <option
                                                                    value="M"
                                                                    disabled="true"
                                                                >
                                                                    M =
                                                                    Mandatory By
                                                                    HUD
                                                                </option>
                                                                <option
                                                                    value="R"
                                                                >
                                                                    R =
                                                                    Recommended
                                                                    By
                                                                    Consultant/Other
                                                                </option>
                                                                <option
                                                                    value="D"
                                                                >
                                                                    D = Desired
                                                                    By Homeowner
                                                                </option>
                                                            </select>
                                                        </mat-form-field>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <h2
                                                            style="
                                                                font-size: 24px;
                                                            "
                                                        >
                                                            <Strong
                                                                >Material/LS</Strong
                                                            >
                                                        </h2>
                                                    </td>
                                                    <td>&nbsp;</td>
                                                    <td>&nbsp;</td>
                                                    <td>&nbsp;</td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <mat-form-field>
                                                            <span
                                                                class="mat-form-field-label-wrapper"
                                                                ><!----><label
                                                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                                    >UNIT</label
                                                                ></span
                                                            >
                                                            <select
                                                                matNativeControl
                                                                formControlName="MaterialUnit"
                                                            >
                                                                <option
                                                                    value="EA"
                                                                >
                                                                    Each = EA
                                                                </option>
                                                                <option
                                                                    value="LF"
                                                                >
                                                                    Linear foot
                                                                    = LF
                                                                </option>
                                                                <option
                                                                    value="SF"
                                                                >
                                                                    Square Foot
                                                                    = SF
                                                                </option>
                                                                <option
                                                                    value="LS"
                                                                >
                                                                    Lump Sum =
                                                                    LS
                                                                </option>
                                                                <option
                                                                    value="SY"
                                                                >
                                                                    Square Yard
                                                                    = SY
                                                                </option>
                                                                <option
                                                                    value="*"
                                                                >
                                                                    * = Required
                                                                </option>
                                                            </select>
                                                        </mat-form-field>
                                                    </td>
                                                    <td>
                                                        <mat-form-field
                                                            class="example-full-width"
                                                        >
                                                            <span
                                                                class="mat-form-field-label-wrapper"
                                                                ><!----><label
                                                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                                    >Quantity
                                                                </label></span
                                                            >
                                                            <input
                                                                matInput
                                                                (keyup)="
                                                                    forValValidator(
                                                                        RepairItemDetail,
                                                                        $event,
                                                                        'MaterialQuantity'
                                                                    )
                                                                "
                                                                formControlName="MaterialQuantity"
                                                                name="Quantity"
                                                            />
                                                        </mat-form-field>
                                                    </td>
                                                    <td>
                                                        <mat-form-field
                                                            class="example-full-width"
                                                        >
                                                            <span
                                                                class="mat-form-field-label-wrapper"
                                                                ><!----><label
                                                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                                    >Unit Cost
                                                                </label></span
                                                            >
                                                            <input
                                                                matInput
                                                                (keyup)="
                                                                    forValValidator(
                                                                        RepairItemDetail,
                                                                        $event,
                                                                        'MaterialUnitCost'
                                                                    )
                                                                "
                                                                formControlName="MaterialUnitCost"
                                                                name="UnitCost"
                                                            />
                                                        </mat-form-field>
                                                    </td>
                                                    <td>
                                                        = ${{
                                                            RepairItemForm
                                                                .value[
                                                                "RepairItemDetails"
                                                            ][i]
                                                                .MaterialQuantity *
                                                                RepairItemForm
                                                                    .value[
                                                                    "RepairItemDetails"
                                                                ][i]
                                                                    .MaterialUnitCost
                                                        }}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <h2
                                                            style="
                                                                font-size: 24px;
                                                            "
                                                        >
                                                            <Strong
                                                                >Labor</Strong
                                                            >
                                                        </h2>
                                                    </td>
                                                    <td>&nbsp;</td>
                                                    <td>&nbsp;</td>
                                                    <td>&nbsp;</td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <mat-form-field>
                                                            <span
                                                                class="mat-form-field-label-wrapper"
                                                                ><!----><label
                                                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                                    >UNIT
                                                                </label></span
                                                            >
                                                            <select
                                                                matNativeControl
                                                                formControlName="LaborUnit"
                                                            >
                                                                <option
                                                                    value="EA"
                                                                >
                                                                    Each = EA
                                                                </option>
                                                                <option
                                                                    value="LF"
                                                                >
                                                                    Linear foot
                                                                    = LF
                                                                </option>
                                                                <option
                                                                    value="SF"
                                                                >
                                                                    Square Foot
                                                                    = SF
                                                                </option>
                                                                <option
                                                                    value="LS"
                                                                >
                                                                    Lump Sum =
                                                                    LS
                                                                </option>
                                                                <option
                                                                    value="SY"
                                                                >
                                                                    Square Yard
                                                                    = SY
                                                                </option>
                                                                <option
                                                                    value="*"
                                                                >
                                                                    * = Required
                                                                </option>
                                                            </select>
                                                        </mat-form-field>
                                                    </td>
                                                    <td>
                                                        <mat-form-field
                                                            class="example-full-width"
                                                        >
                                                            <span
                                                                class="mat-form-field-label-wrapper"
                                                                ><!----><label
                                                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                                    >Quantity
                                                                </label></span
                                                            >
                                                            <input
                                                                matInput
                                                                (keyup)="
                                                                    forValValidator(
                                                                        RepairItemDetail,
                                                                        $event,
                                                                        'LaborQuantity'
                                                                    )
                                                                "
                                                                formControlName="LaborQuantity"
                                                                name="Quantity"
                                                            />
                                                        </mat-form-field>
                                                    </td>
                                                    <td>
                                                        <mat-form-field
                                                            class="example-full-width"
                                                        >
                                                            <span
                                                                class="mat-form-field-label-wrapper"
                                                                ><!----><label
                                                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                                    >Unit Cost
                                                                </label></span
                                                            >
                                                            <input
                                                                matInput
                                                                (keyup)="
                                                                    forValValidator(
                                                                        RepairItemDetail,
                                                                        $event,
                                                                        'LaborUnitCost'
                                                                    )
                                                                "
                                                                formControlName="LaborUnitCost"
                                                                name="UnitCost"
                                                            />
                                                        </mat-form-field>
                                                    </td>
                                                    <td>
                                                        = ${{
                                                            RepairItemForm
                                                                .value[
                                                                "RepairItemDetails"
                                                            ][i].LaborQuantity *
                                                                RepairItemForm
                                                                    .value[
                                                                    "RepairItemDetails"
                                                                ][i]
                                                                    .LaborUnitCost
                                                        }}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td>&nbsp;</td>
                                                    <td>
                                                        <h2
                                                            style="
                                                                font-size: 24px;
                                                                text-align: right;
                                                            "
                                                        >
                                                            <Strong
                                                                >Item Total
                                                                =</Strong
                                                            >
                                                        </h2>
                                                    </td>
                                                    <td>
                                                        <h2
                                                            style="
                                                                font-size: 24px;
                                                            "
                                                        >
                                                            <Strong>
                                                                ${{
                                                                    RepairItemForm
                                                                        .value[
                                                                        "RepairItemDetails"
                                                                    ][i]
                                                                        .MaterialQuantity *
                                                                        RepairItemForm
                                                                            .value[
                                                                            "RepairItemDetails"
                                                                        ][i]
                                                                            .MaterialUnitCost +
                                                                        RepairItemForm
                                                                            .value[
                                                                            "RepairItemDetails"
                                                                        ][i]
                                                                            .LaborQuantity *
                                                                            RepairItemForm
                                                                                .value[
                                                                                "RepairItemDetails"
                                                                            ][i]
                                                                                .LaborUnitCost
                                                                }}</Strong
                                                            >
                                                        </h2>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        <mat-form-field
                                            class="example-full-width"
                                        >
                                            <span
                                                class="mat-form-field-label-wrapper"
                                                ><!----><label
                                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                    >Summary</label
                                                ></span
                                            >
                                            <textarea
                                                matInput
                                                rows="8"
                                                formControlName="Summary"
                                                name="Summary"
                                            ></textarea>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </mat-card-content>
                        <div class="footerRepairItemsContainer">
                            <div class="leftSection">
                                <a
                                    *ngIf="previousItemType != ''"
                                    class="gobacklink btn1"
                                    href="javascript:void(0)"
                                    (click)="
                                        UpdateRepair(
                                            previousItemType,
                                            previousItemName,
                                            'M',
                                            previousItemLocation
                                        )
                                    "
                                    >&#8592; Go Back</a
                                >
                                <a
                                    *ngIf="previousItemType == ''"
                                    class="gobacklink btn2"
                                    href="javascript:void(0)"
                                    (click)="goPreviousTab(0)"
                                    >&#8592; Go Back</a
                                >
                            </div>
                            <ng-container
                                *ngFor="
                                    let allItem of allRepairItem;
                                    let i = index
                                "
                            >
                                <div
                                    class="middleSection"
                                    *ngIf="allItem.Operation == 'Inactive'"
                                >
                                    <strong>Project Running Estimate</strong> =
                                    ${{ allItem.RepairTotal }}
                                </div>
                            </ng-container>
                            <div class="rightSection">
                                <a
                                    *ngIf="nextItemType != ''"
                                    class="gonextlink btn1"
                                    (click)="
                                        goNextItem(
                                            nextItemType,
                                            nextItemName,
                                            'M'
                                        )
                                    "
                                    >Next Item
                                </a>
                                <a
                                    *ngIf="
                                        nextItemType == '' &&
                                        recommendedFirstItem != '' &&
                                            recommendedFirstItem != null &&
                                            recommendedFirstItem != undefined
                                    "
                                    class="gonextlink btn2"
                                    (click)="
                                        goNextTab(
                                            2,
                                            recommendedFirstItem,
                                            recommendedFirstItemRepairName,
                                            'R'
                                        )
                                    "
                                    >Next Item</a
                                >
                                <a
                                    *ngIf="
                                        nextItemType == '' &&
                                        recommendedFirstItem == '' &&
                                        desiredFirstItem != '' &&
                                            desiredFirstItem != null &&
                                            desiredFirstItem != undefined
                                    "
                                    class="gonextlink btn3"
                                    (click)="
                                        goNextTab(
                                            3,
                                            desiredFirstItem,
                                            desiredFirstItemRepairName,
                                            'D'
                                        )
                                    "
                                    >Next Item
                                </a>
                                <!-- <a *ngIf="nextItemType=='' && recommendedFirstItem=='' && desiredFirstItem=='' && (additionalDesiredFirstItem!='' && additionalDesiredFirstItem!=null && additionalDesiredFirstItem!=undefined)" class="gonextlink" (click)="goNextTab(4,additionalDesiredFirstItem,'D')" >Next Item </a> -->
                                <a
                                    *ngIf="
                                        nextItemType == '' &&
                                        recommendedFirstItem == '' &&
                                        desiredFirstItem == ''
                                    "
                                    class="gonextlink btn4"
                                    (click)="goNextTab(4)"
                                    >Next Item
                                </a>
                            </div>
                        </div>
                    </form>
                </div>
            </mat-card>
        </mat-tab>

        <mat-tab>
            <ng-template mat-tab-label>
                <div
                    class="mainmenu_tabContent {{ isAllExpandClass }}"
                    (click)="
                        UpdateRepair(
                            recommendedFirstItem,
                            recommendedFirstItemRepairName,
                            'R',
                            recommendedFirstItemLocation
                        )
                    "
                >
                    <mat-icon
                        class="plusIcon example-tab-icon"
                        *ngIf="
                            (isMinusShow == true &&
                                currentClick != 'recommended' &&
                                recommendedFirstItem != '') ||
                            (isPlusShow == true &&
                                recommendedFirstItem != '' &&
                                recommendedFirstItem != null &&
                                recommendedFirstItem != undefined)
                        "
                        (click)="expandFunction('recommended')"
                        >&#43;</mat-icon
                    >
                    <mat-icon
                        class="minusIcon example-tab-icon"
                        *ngIf="
                            (isMinusShow == true && isAllexpand == true) ||
                            (currentClick == 'recommended' &&
                                isMinusShow == true &&
                                recommendedFirstItem != '' &&
                                recommendedFirstItem != null &&
                                recommendedFirstItem != undefined)
                        "
                        (click)="closedFunction()"
                        >&#8722;</mat-icon
                    >
                    <span class="labelText">Recommended</span>
                </div>

                <ul
                    class="submenu_tabContent {{ isAllExpandClass }}"
                    *ngIf="isSubTabContentShow == true || isAllexpand == true"
                >
                    <ng-container
                        *ngFor="
                            let recommendedItem of allRepairItem;
                            let i = index
                        "
                    >
                        <ng-container
                            *ngFor="
                                let recommendedItemRepair of recommendedItem.RepairItemDetails;
                                let j = index
                            "
                        >
                            <li
                                *ngIf="
                                    recommendedItemRepair.Recommended == true &&
                                    recommendedItem.Operation != 'Inactive'
                                "
                            >
                                <a
                                    class="itemLink"
                                    *ngIf="
                                        recommendedItemRepair.isItemCompleted !=
                                        true
                                    "
                                    href="javascript:void(0)"
                                    (click)="
                                        UpdateRepair(
                                            recommendedItem.RepairType,
                                            recommendedItemRepair.RepairItemName,
                                            'R',
                                            recommendedItemRepair.Location
                                        )
                                    "
                                    >Item {{ recommendedItem.id }}:
                                    {{ recommendedItem.RepairType }}<br />
                                    ({{
                                        recommendedItemRepair.RepairItemName
                                    }})</a
                                >
                                <a
                                    class="itemLink isItemCompleted"
                                    *ngIf="
                                        recommendedItemRepair.isItemCompleted ==
                                        true
                                    "
                                    href="javascript:void(0)"
                                    (click)="
                                        UpdateRepair(
                                            recommendedItem.RepairType,
                                            recommendedItemRepair.RepairItemName,
                                            'R',
                                            recommendedItemRepair.Location
                                        )
                                    "
                                    >Item {{ recommendedItem.id }}:
                                    {{ recommendedItem.RepairType }} <br />({{
                                        recommendedItemRepair.RepairItemName
                                    }})</a
                                >
                            </li>
                        </ng-container>
                    </ng-container>
                </ul>
            </ng-template>

            <mat-card>
                <div
                    *ngIf="
                        recommendedFirstItem != '' &&
                        recommendedFirstItem != null &&
                        recommendedFirstItem != undefined
                    "
                    class="expandFormControls container-login100"
                >
                    <form
                        [formGroup]="RepairItemForm"
                        class="example-form"
                        (ngSubmit)="SaveConstructionItem()"
                    >
                        <mat-card-content>
                            <mat-form-field>
                                <span class="mat-form-field-label-wrapper"
                                    ><!----><label
                                        class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                        >Construction Item
                                    </label></span
                                >
                                <select
                                    matNativeControl
                                    formControlName="SendRepairType"
                                    [disabled]="true"
                                >
                                    <option value="">Select</option>
                                    <option
                                        *ngFor="let value of userList.Items"
                                        value="{{ value.RepairType }}"
                                    >
                                        {{ value.id }} {{ value.RepairType }}
                                    </option>
                                </select>
                            </mat-form-field>
                            <h3><strong>Repair Item Details</strong></h3>

                            <div class="RepairItemDetailsList">
                                <div
                                    *ngFor="
                                        let RepairItemDetail of RepairItemForm.get(
                                            'RepairItemDetails'
                                        )['controls'];
                                        let i = index
                                    "
                                    formArrayName="RepairItemDetails"
                                >
                                    <div [formGroupName]="i">
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <mat-form-field
                                                            class="example-full-width"
                                                        >
                                                            <span
                                                                class="mat-form-field-label-wrapper"
                                                                ><!----><label
                                                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                                    >Repair Item
                                                                    Name
                                                                </label></span
                                                            >
                                                            <input
                                                                matInput
                                                                [attr.disabled]="
                                                                    false
                                                                "
                                                                formControlName="RepairItemName"
                                                                name="RepairItemName"
                                                            />
                                                        </mat-form-field>
                                                    </td>
                                                    <td>
                                                        <mat-form-field
                                                            class="example-full-width"
                                                        >
                                                            <span
                                                                class="mat-form-field-label-wrapper"
                                                                ><!----><label
                                                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                                    >Location
                                                                </label></span
                                                            >
                                                            <input
                                                                matInput
                                                                formControlName="Location"
                                                                name="Location"
                                                            />
                                                        </mat-form-field>
                                                    </td>
                                                    <td>
                                                        <mat-form-field>
                                                            <span
                                                                class="mat-form-field-label-wrapper"
                                                                ><!----><label
                                                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                                    >Level
                                                                </label></span
                                                            >
                                                            <select
                                                                matNativeControl
                                                                formControlName="Level"
                                                            >
                                                                <option
                                                                    value="M"
                                                                    disabled="true"
                                                                >
                                                                    M =
                                                                    Mandatory By
                                                                    HUD
                                                                </option>
                                                                <option
                                                                    value="R"
                                                                >
                                                                    R =
                                                                    Recommended
                                                                    By
                                                                    Consultant/Other
                                                                </option>
                                                                <option
                                                                    value="D"
                                                                >
                                                                    D = Desired
                                                                    By Homeowner
                                                                </option>
                                                            </select>
                                                        </mat-form-field>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <h2
                                                            style="
                                                                font-size: 24px;
                                                            "
                                                        >
                                                            <Strong
                                                                >Material/LS</Strong
                                                            >
                                                        </h2>
                                                    </td>
                                                    <td>&nbsp;</td>
                                                    <td>&nbsp;</td>
                                                    <td>&nbsp;</td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <mat-form-field>
                                                            <span
                                                                class="mat-form-field-label-wrapper"
                                                                ><!----><label
                                                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                                    >UNIT
                                                                </label></span
                                                            >
                                                            <select
                                                                matNativeControl
                                                                formControlName="MaterialUnit"
                                                            >
                                                                <option
                                                                    value="EA"
                                                                >
                                                                    Each = EA
                                                                </option>
                                                                <option
                                                                    value="LF"
                                                                >
                                                                    Linear foot
                                                                    = LF
                                                                </option>
                                                                <option
                                                                    value="SF"
                                                                >
                                                                    Square Foot
                                                                    = SF
                                                                </option>
                                                                <option
                                                                    value="LS"
                                                                >
                                                                    Lump Sum =
                                                                    LS
                                                                </option>
                                                                <option
                                                                    value="SY"
                                                                >
                                                                    Square Yard
                                                                    = SY
                                                                </option>
                                                                <option
                                                                    value="*"
                                                                >
                                                                    * = Required
                                                                </option>
                                                            </select>
                                                        </mat-form-field>
                                                    </td>
                                                    <td>
                                                        <mat-form-field
                                                            class="example-full-width"
                                                        >
                                                            <span
                                                                class="mat-form-field-label-wrapper"
                                                                ><!----><label
                                                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                                    >Quantity</label
                                                                ></span
                                                            >
                                                            <input
                                                                matInput
                                                                (keyup)="
                                                                    forValValidator(
                                                                        RepairItemDetail,
                                                                        $event,
                                                                        'MaterialQuantity'
                                                                    )
                                                                "
                                                                formControlName="MaterialQuantity"
                                                                name="Quantity"
                                                            />
                                                        </mat-form-field>
                                                    </td>
                                                    <td>
                                                        <mat-form-field
                                                            class="example-full-width"
                                                        >
                                                            <span
                                                                class="mat-form-field-label-wrapper"
                                                                ><!----><label
                                                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                                    >Unit Cost
                                                                </label></span
                                                            >
                                                            <input
                                                                matInput
                                                                (keyup)="
                                                                    forValValidator(
                                                                        RepairItemDetail,
                                                                        $event,
                                                                        'MaterialUnitCost'
                                                                    )
                                                                "
                                                                formControlName="MaterialUnitCost"
                                                                name="UnitCost"
                                                            />
                                                        </mat-form-field>
                                                    </td>
                                                    <td>
                                                        = ${{
                                                            RepairItemForm
                                                                .value[
                                                                "RepairItemDetails"
                                                            ][i]
                                                                .MaterialQuantity *
                                                                RepairItemForm
                                                                    .value[
                                                                    "RepairItemDetails"
                                                                ][i]
                                                                    .MaterialUnitCost
                                                        }}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <h2
                                                            style="
                                                                font-size: 24px;
                                                            "
                                                        >
                                                            <Strong
                                                                >Labor</Strong
                                                            >
                                                        </h2>
                                                    </td>
                                                    <td>&nbsp;</td>
                                                    <td>&nbsp;</td>
                                                    <td>&nbsp;</td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <mat-form-field>
                                                            <span
                                                                class="mat-form-field-label-wrapper"
                                                                ><!----><label
                                                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                                    >UNIT</label
                                                                ></span
                                                            >
                                                            <select
                                                                matNativeControl
                                                                formControlName="LaborUnit"
                                                            >
                                                                <option
                                                                    value="EA"
                                                                >
                                                                    Each = EA
                                                                </option>
                                                                <option
                                                                    value="LF"
                                                                >
                                                                    Linear foot
                                                                    = LF
                                                                </option>
                                                                <option
                                                                    value="SF"
                                                                >
                                                                    Square Foot
                                                                    = SF
                                                                </option>
                                                                <option
                                                                    value="LS"
                                                                >
                                                                    Lump Sum =
                                                                    LS
                                                                </option>
                                                                <option
                                                                    value="SY"
                                                                >
                                                                    Square Yard
                                                                    = SY
                                                                </option>
                                                                <option
                                                                    value="*"
                                                                >
                                                                    * = Required
                                                                </option>
                                                            </select>
                                                        </mat-form-field>
                                                    </td>
                                                    <td>
                                                        <mat-form-field
                                                            class="example-full-width"
                                                        >
                                                            <span
                                                                class="mat-form-field-label-wrapper"
                                                                ><!----><label
                                                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                                    >Quantity
                                                                </label></span
                                                            >
                                                            <input
                                                                matInput
                                                                (keyup)="
                                                                    forValValidator(
                                                                        RepairItemDetail,
                                                                        $event,
                                                                        'LaborQuantity'
                                                                    )
                                                                "
                                                                formControlName="LaborQuantity"
                                                                name="Quantity"
                                                            />
                                                        </mat-form-field>
                                                    </td>
                                                    <td>
                                                        <mat-form-field
                                                            class="example-full-width"
                                                        >
                                                            <span
                                                                class="mat-form-field-label-wrapper"
                                                                ><!----><label
                                                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                                    >Unit Cost
                                                                </label></span
                                                            >
                                                            <input
                                                                matInput
                                                                (keyup)="
                                                                    forValValidator(
                                                                        RepairItemDetail,
                                                                        $event,
                                                                        'LaborUnitCost'
                                                                    )
                                                                "
                                                                formControlName="LaborUnitCost"
                                                                name="UnitCost"
                                                            />
                                                        </mat-form-field>
                                                    </td>
                                                    <td>
                                                        = ${{
                                                            RepairItemForm
                                                                .value[
                                                                "RepairItemDetails"
                                                            ][i].LaborQuantity *
                                                                RepairItemForm
                                                                    .value[
                                                                    "RepairItemDetails"
                                                                ][i]
                                                                    .LaborUnitCost
                                                        }}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td>&nbsp;</td>
                                                    <td>
                                                        <h2
                                                            style="
                                                                font-size: 24px;
                                                                text-align: right;
                                                            "
                                                        >
                                                            <Strong
                                                                >Item Total
                                                                =</Strong
                                                            >
                                                        </h2>
                                                    </td>
                                                    <td>
                                                        <h2
                                                            style="
                                                                font-size: 24px;
                                                            "
                                                        >
                                                            <Strong>
                                                                ${{
                                                                    RepairItemForm
                                                                        .value[
                                                                        "RepairItemDetails"
                                                                    ][i]
                                                                        .MaterialQuantity *
                                                                        RepairItemForm
                                                                            .value[
                                                                            "RepairItemDetails"
                                                                        ][i]
                                                                            .MaterialUnitCost +
                                                                        RepairItemForm
                                                                            .value[
                                                                            "RepairItemDetails"
                                                                        ][i]
                                                                            .LaborQuantity *
                                                                            RepairItemForm
                                                                                .value[
                                                                                "RepairItemDetails"
                                                                            ][i]
                                                                                .LaborUnitCost
                                                                }}</Strong
                                                            >
                                                        </h2>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        <mat-form-field
                                            class="example-full-width"
                                        >
                                            <span
                                                class="mat-form-field-label-wrapper"
                                                ><!----><label
                                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                    >Summary</label
                                                ></span
                                            >
                                            <textarea
                                                matInput
                                                rows="8"
                                                formControlName="Summary"
                                                name="Summary"
                                            ></textarea>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </mat-card-content>
                        <div class="footerRepairItemsContainer">
                            <div class="leftSection">
                                <a
                                    *ngIf="previousItemType != ''"
                                    class="gobacklink btn1"
                                    href="javascript:void(0)"
                                    (click)="
                                        UpdateRepair(
                                            previousItemType,
                                            previousItemName,
                                            'R',
                                            previousItemLocation
                                        )
                                    "
                                    >&#8592; Go Back</a
                                >
                                <a
                                    *ngIf="
                                        previousItemType == '' &&
                                        mandatoryFirstItem != '' &&
                                            mandatoryFirstItem != null &&
                                            mandatoryFirstItem != undefined
                                    "
                                    class="gobacklink btn2"
                                    href="javascript:void(0)"
                                    (click)="
                                        goPreviousTab(
                                            1,
                                            mandatoryFirstItem,
                                            mandatoryFirstItemRepairName,
                                            'M'
                                        )
                                    "
                                    >&#8592; Go Back</a
                                >
                                <a
                                    *ngIf="
                                        previousItemType == '' &&
                                        mandatoryFirstItem == ''
                                    "
                                    class="gobacklink btn3"
                                    href="javascript:void(0)"
                                    (click)="goPreviousTab(0)"
                                    >&#8592; Go Back</a
                                >
                            </div>
                            <ng-container
                                *ngFor="
                                    let allItem of allRepairItem;
                                    let i = index
                                "
                            >
                                <div
                                    class="middleSection"
                                    *ngIf="allItem.Operation == 'Inactive'"
                                >
                                    <strong>Project Running Estimate</strong> =
                                    ${{ allItem.RepairTotal }}
                                </div>
                            </ng-container>
                            <div class="rightSection">
                                <a
                                    *ngIf="nextItemType != ''"
                                    class="gonextlink btn1"
                                    (click)="
                                        goNextItem(
                                            nextItemType,
                                            nextItemName,
                                            'R'
                                        )
                                    "
                                    >Next Item</a
                                >
                                <a
                                    *ngIf="
                                        nextItemType == '' &&
                                        desiredFirstItem != '' &&
                                            desiredFirstItem != null &&
                                            desiredFirstItem != undefined
                                    "
                                    class="gonextlink btn2"
                                    (click)="
                                        goNextTab(
                                            3,
                                            desiredFirstItem,
                                            desiredFirstItemRepairName,
                                            'D'
                                        )
                                    "
                                    >Next Item</a
                                >
                                <!-- <a *ngIf="nextItemType=='' && desiredFirstItem=='' && (additionalDesiredFirstItem!='' && additionalDesiredFirstItem!=null && additionalDesiredFirstItem!=undefined)"class="gonextlink" (click)="goNextTab(4,additionalDesiredFirstItem,'D')" >Next Item</a> -->
                                <a
                                    *ngIf="
                                        nextItemType == '' &&
                                        desiredFirstItem == ''
                                    "
                                    class="gonextlink btn3"
                                    (click)="goNextTab(4)"
                                    >Next Item</a
                                >
                            </div>
                        </div>
                    </form>
                </div>
            </mat-card>
        </mat-tab>

        <mat-tab>
            <ng-template mat-tab-label>
                <div
                    class="mainmenu_tabContent {{ isAllExpandClass }}"
                    (click)="
                        UpdateRepair(
                            desiredFirstItem,
                            desiredFirstItemRepairName,
                            'D',
                            desiredFirstItemLocation
                        )
                    "
                >
                    <mat-icon
                        class="plusIcon example-tab-icon"
                        *ngIf="
                            (isMinusShow == true &&
                                currentClick != 'desired' &&
                                desiredFirstItem != '') ||
                            (isPlusShow == true &&
                                desiredFirstItem != '' &&
                                desiredFirstItem != null &&
                                desiredFirstItem != undefined)
                        "
                        (click)="expandFunction('desired')"
                        >&#43;</mat-icon
                    >
                    <mat-icon
                        class="minusIcon example-tab-icon"
                        *ngIf="
                            (isMinusShow == true && isAllexpand == true) ||
                            (currentClick == 'desired' &&
                                isMinusShow == true &&
                                desiredFirstItem != '' &&
                                desiredFirstItem != null &&
                                desiredFirstItem != undefined)
                        "
                        (click)="closedFunction()"
                        >&#8722;</mat-icon
                    >
                    <span class="labelText">Desired</span>
                </div>
                <ul
                    class="submenu_tabContent {{ isAllExpandClass }}"
                    *ngIf="isSubTabContentShow == true || isAllexpand == true"
                >
                    <ng-container
                        *ngFor="let desiredItem of allRepairItem; let i = index"
                    >
                        <ng-container
                            *ngFor="
                                let desiredItemRepair of desiredItem.RepairItemDetails;
                                let j = index
                            "
                        >
                            <li
                                *ngIf="
                                    desiredItemRepair.Desired == true &&
                                    desiredItemRepair.isAdditional != true &&
                                    desiredItem.Operation != 'Inactive'
                                "
                            >
                                <a
                                    class="itemLink"
                                    *ngIf="
                                        desiredItemRepair.isItemCompleted !=
                                        true
                                    "
                                    href="javascript:void(0)"
                                    (click)="
                                        UpdateRepair(
                                            desiredItem.RepairType,
                                            desiredItemRepair.RepairItemName,
                                            'D',
                                            desiredItemRepair.Location
                                        )
                                    "
                                    >Item {{ desiredItem.id }}:
                                    {{ desiredItem.RepairType }} <br />({{
                                        desiredItemRepair.RepairItemName
                                    }})</a
                                >
                                <a
                                    class="itemLink isItemCompleted"
                                    *ngIf="
                                        desiredItemRepair.isItemCompleted ==
                                        true
                                    "
                                    href="javascript:void(0)"
                                    (click)="
                                        UpdateRepair(
                                            desiredItem.RepairType,
                                            desiredItemRepair.RepairItemName,
                                            'D',
                                            desiredItemRepair.Location
                                        )
                                    "
                                    >Item {{ desiredItem.id }}:
                                    {{ desiredItem.RepairType }} <br />({{
                                        desiredItemRepair.RepairItemName
                                    }})</a
                                >
                            </li>
                        </ng-container>
                    </ng-container>
                </ul>
            </ng-template>

            <mat-card>
                <div
                    *ngIf="
                        desiredFirstItem != '' &&
                        desiredFirstItem != null &&
                        desiredFirstItem != undefined
                    "
                    class="expandFormControls container-login100"
                >
                    <form
                        [formGroup]="RepairItemForm"
                        class="example-form"
                        (ngSubmit)="SaveConstructionItem()"
                    >
                        <mat-card-content>
                            <mat-form-field>
                                <span class="mat-form-field-label-wrapper"
                                    ><!----><label
                                        class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                        >Construction Item
                                    </label></span
                                >
                                <select
                                    matNativeControl
                                    formControlName="SendRepairType"
                                    [disabled]="true"
                                >
                                    <option value="">Select</option>
                                    <option
                                        *ngFor="let value of userList.Items"
                                        value="{{ value.RepairType }}"
                                    >
                                        {{ value.id }} {{ value.RepairType }}
                                    </option>
                                </select>
                            </mat-form-field>
                            <h3><strong>Repair Item Details</strong></h3>

                            <div class="RepairItemDetailsList">
                                <div
                                    *ngFor="
                                        let RepairItemDetail of RepairItemForm.get(
                                            'RepairItemDetails'
                                        )['controls'];
                                        let i = index
                                    "
                                    formArrayName="RepairItemDetails"
                                >
                                    <div [formGroupName]="i">
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <mat-form-field
                                                            class="example-full-width"
                                                        >
                                                            <span
                                                                class="mat-form-field-label-wrapper"
                                                                ><!----><label
                                                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                                    >Repair Item
                                                                    Name</label
                                                                ></span
                                                            >
                                                            <input
                                                                matInput
                                                                [attr.disabled]="
                                                                    false
                                                                "
                                                                formControlName="RepairItemName"
                                                                name="RepairItemName"
                                                            />
                                                        </mat-form-field>
                                                    </td>
                                                    <td>
                                                        <mat-form-field
                                                            class="example-full-width"
                                                        >
                                                            <span
                                                                class="mat-form-field-label-wrapper"
                                                                ><!----><label
                                                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                                    >Location
                                                                </label></span
                                                            >
                                                            <input
                                                                matInput
                                                                formControlName="Location"
                                                                name="Location"
                                                            />
                                                        </mat-form-field>
                                                    </td>
                                                    <td>
                                                        <mat-form-field>
                                                            <span
                                                                class="mat-form-field-label-wrapper"
                                                                ><!----><label
                                                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                                    >Level</label
                                                                ></span
                                                            >
                                                            <select
                                                                matNativeControl
                                                                formControlName="Level"
                                                            >
                                                                <option
                                                                    value="M"
                                                                    disabled="true"
                                                                >
                                                                    M =
                                                                    Mandatory By
                                                                    HUD
                                                                </option>
                                                                <option
                                                                    value="R"
                                                                >
                                                                    R =
                                                                    Recommended
                                                                    By
                                                                    Consultant/Other
                                                                </option>
                                                                <option
                                                                    value="D"
                                                                >
                                                                    D = Desired
                                                                    By Homeowner
                                                                </option>
                                                            </select>
                                                        </mat-form-field>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <h2
                                                            style="
                                                                font-size: 24px;
                                                            "
                                                        >
                                                            <Strong
                                                                >Material/LS</Strong
                                                            >
                                                        </h2>
                                                    </td>
                                                    <td>&nbsp;</td>
                                                    <td>&nbsp;</td>
                                                    <td>&nbsp;</td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <mat-form-field>
                                                            <span
                                                                class="mat-form-field-label-wrapper"
                                                                ><!----><label
                                                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                                    >UNIT</label
                                                                ></span
                                                            >
                                                            <select
                                                                matNativeControl
                                                                formControlName="MaterialUnit"
                                                            >
                                                                <option
                                                                    value="EA"
                                                                >
                                                                    Each = EA
                                                                </option>
                                                                <option
                                                                    value="LF"
                                                                >
                                                                    Linear foot
                                                                    = LF
                                                                </option>
                                                                <option
                                                                    value="SF"
                                                                >
                                                                    Square Foot
                                                                    = SF
                                                                </option>
                                                                <option
                                                                    value="LS"
                                                                >
                                                                    Lump Sum =
                                                                    LS
                                                                </option>
                                                                <option
                                                                    value="SY"
                                                                >
                                                                    Square Yard
                                                                    = SY
                                                                </option>
                                                                <option
                                                                    value="*"
                                                                >
                                                                    * = Required
                                                                </option>
                                                            </select>
                                                        </mat-form-field>
                                                    </td>
                                                    <td>
                                                        <mat-form-field
                                                            class="example-full-width"
                                                        >
                                                            <span
                                                                class="mat-form-field-label-wrapper"
                                                                ><!----><label
                                                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                                    >Quantity
                                                                </label></span
                                                            >
                                                            <input
                                                                matInput
                                                                (keyup)="
                                                                    forValValidator(
                                                                        RepairItemDetail,
                                                                        $event,
                                                                        'MaterialQuantity'
                                                                    )
                                                                "
                                                                formControlName="MaterialQuantity"
                                                                name="Quantity"
                                                            />
                                                        </mat-form-field>
                                                    </td>
                                                    <td>
                                                        <mat-form-field
                                                            class="example-full-width"
                                                        >
                                                            <span
                                                                class="mat-form-field-label-wrapper"
                                                                ><!----><label
                                                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                                    >Unit
                                                                    Cost</label
                                                                ></span
                                                            >
                                                            <input
                                                                matInput
                                                                (keyup)="
                                                                    forValValidator(
                                                                        RepairItemDetail,
                                                                        $event,
                                                                        'MaterialUnitCost'
                                                                    )
                                                                "
                                                                formControlName="MaterialUnitCost"
                                                                name="UnitCost"
                                                            />
                                                        </mat-form-field>
                                                    </td>
                                                    <td>
                                                        = ${{
                                                            RepairItemForm
                                                                .value[
                                                                "RepairItemDetails"
                                                            ][i]
                                                                .MaterialQuantity *
                                                                RepairItemForm
                                                                    .value[
                                                                    "RepairItemDetails"
                                                                ][i]
                                                                    .MaterialUnitCost
                                                        }}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <h2
                                                            style="
                                                                font-size: 24px;
                                                            "
                                                        >
                                                            <Strong
                                                                >Labor</Strong
                                                            >
                                                        </h2>
                                                    </td>
                                                    <td>&nbsp;</td>
                                                    <td>&nbsp;</td>
                                                    <td>&nbsp;</td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <mat-form-field>
                                                            <span
                                                                class="mat-form-field-label-wrapper"
                                                                ><!----><label
                                                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                                    >UNIT
                                                                </label></span
                                                            >
                                                            <select
                                                                matNativeControl
                                                                formControlName="LaborUnit"
                                                            >
                                                                <option
                                                                    value="EA"
                                                                >
                                                                    Each = EA
                                                                </option>
                                                                <option
                                                                    value="LF"
                                                                >
                                                                    Linear foot
                                                                    = LF
                                                                </option>
                                                                <option
                                                                    value="SF"
                                                                >
                                                                    Square Foot
                                                                    = SF
                                                                </option>
                                                                <option
                                                                    value="LS"
                                                                >
                                                                    Lump Sum =
                                                                    LS
                                                                </option>
                                                                <option
                                                                    value="SY"
                                                                >
                                                                    Square Yard
                                                                    = SY
                                                                </option>
                                                                <option
                                                                    value="*"
                                                                >
                                                                    * = Required
                                                                </option>
                                                            </select>
                                                        </mat-form-field>
                                                    </td>
                                                    <td>
                                                        <mat-form-field
                                                            class="example-full-width"
                                                        >
                                                            <span
                                                                class="mat-form-field-label-wrapper"
                                                                ><!----><label
                                                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                                    >Quantity
                                                                </label></span
                                                            >
                                                            <input
                                                                matInput
                                                                (keyup)="
                                                                    forValValidator(
                                                                        RepairItemDetail,
                                                                        $event,
                                                                        'LaborQuantity'
                                                                    )
                                                                "
                                                                formControlName="LaborQuantity"
                                                                name="Quantity"
                                                            />
                                                        </mat-form-field>
                                                    </td>
                                                    <td>
                                                        <mat-form-field
                                                            class="example-full-width"
                                                        >
                                                            <span
                                                                class="mat-form-field-label-wrapper"
                                                                ><!----><label
                                                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                                    >Unit Cost
                                                                </label></span
                                                            >
                                                            <input
                                                                matInput
                                                                (keyup)="
                                                                    forValValidator(
                                                                        RepairItemDetail,
                                                                        $event,
                                                                        'LaborUnitCost'
                                                                    )
                                                                "
                                                                formControlName="LaborUnitCost"
                                                                name="UnitCost"
                                                            />
                                                        </mat-form-field>
                                                    </td>
                                                    <td>
                                                        = ${{
                                                            RepairItemForm
                                                                .value[
                                                                "RepairItemDetails"
                                                            ][i].LaborQuantity *
                                                                RepairItemForm
                                                                    .value[
                                                                    "RepairItemDetails"
                                                                ][i]
                                                                    .LaborUnitCost
                                                        }}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td>&nbsp;</td>
                                                    <td>
                                                        <h2
                                                            style="
                                                                font-size: 24px;
                                                                text-align: right;
                                                            "
                                                        >
                                                            <Strong
                                                                >Item Total
                                                                =</Strong
                                                            >
                                                        </h2>
                                                    </td>
                                                    <td>
                                                        <h2
                                                            style="
                                                                font-size: 24px;
                                                            "
                                                        >
                                                            <Strong>
                                                                ${{
                                                                    RepairItemForm
                                                                        .value[
                                                                        "RepairItemDetails"
                                                                    ][i]
                                                                        .MaterialQuantity *
                                                                        RepairItemForm
                                                                            .value[
                                                                            "RepairItemDetails"
                                                                        ][i]
                                                                            .MaterialUnitCost +
                                                                        RepairItemForm
                                                                            .value[
                                                                            "RepairItemDetails"
                                                                        ][i]
                                                                            .LaborQuantity *
                                                                            RepairItemForm
                                                                                .value[
                                                                                "RepairItemDetails"
                                                                            ][i]
                                                                                .LaborUnitCost
                                                                }}</Strong
                                                            >
                                                        </h2>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        <mat-form-field
                                            class="example-full-width"
                                        >
                                            <span
                                                class="mat-form-field-label-wrapper"
                                                ><!----><label
                                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                    >Summary
                                                </label></span
                                            >
                                            <textarea
                                                matInput
                                                rows="8"
                                                formControlName="Summary"
                                                name="Summary"
                                            ></textarea>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </mat-card-content>
                        <div class="footerRepairItemsContainer">
                            <div class="leftSection">
                                <a
                                    *ngIf="previousItemType != ''"
                                    class="gobacklink btn1"
                                    href="javascript:void(0)"
                                    (click)="
                                        UpdateRepair(
                                            previousItemType,
                                            previousItemName,
                                            'D',
                                            previousItemLocation
                                        )
                                    "
                                    >&#8592; Go Back</a
                                >
                                <a
                                    *ngIf="
                                        previousItemType == '' &&
                                        recommendedFirstItem != '' &&
                                            recommendedFirstItem != null &&
                                            recommendedFirstItem != undefined
                                    "
                                    class="gobacklink btn2"
                                    href="javascript:void(0)"
                                    (click)="
                                        goPreviousTab(
                                            2,
                                            recommendedFirstItem,
                                            recommendedFirstItemRepairName,
                                            'R'
                                        )
                                    "
                                    >&#8592; Go Back</a
                                >
                                <a
                                    *ngIf="
                                        previousItemType == '' &&
                                        recommendedFirstItem == '' &&
                                        mandatoryFirstItem != '' &&
                                            mandatoryFirstItem != null &&
                                            mandatoryFirstItem != undefined
                                    "
                                    class="gobacklink btn3"
                                    href="javascript:void(0)"
                                    (click)="
                                        goPreviousTab(
                                            1,
                                            mandatoryFirstItem,
                                            mandatoryFirstItemRepairName,
                                            'M'
                                        )
                                    "
                                    >&#8592; Go Back</a
                                >
                                <a
                                    *ngIf="
                                        previousItemType == '' &&
                                        recommendedFirstItem == '' &&
                                        mandatoryFirstItem == ''
                                    "
                                    class="gobacklink btn4"
                                    href="javascript:void(0)"
                                    (click)="goPreviousTab(0)"
                                    >&#8592; Go Back</a
                                >
                            </div>
                            <ng-container
                                *ngFor="
                                    let allItem of allRepairItem;
                                    let i = index
                                "
                            >
                                <div
                                    class="middleSection"
                                    *ngIf="allItem.Operation == 'Inactive'"
                                >
                                    <strong>Project Running Estimate</strong> =
                                    ${{ allItem.RepairTotal }}
                                </div>
                            </ng-container>
                            <div class="rightSection">
                                <a
                                    *ngIf="nextItemType != ''"
                                    class="gonextlink btn1"
                                    (click)="
                                        goNextItem(
                                            nextItemType,
                                            nextItemName,
                                            'D'
                                        )
                                    "
                                    >Next Item</a
                                >
                                <!-- <a *ngIf="nextItemType=='' && (additionalDesiredFirstItem!='' && additionalDesiredFirstItem!=null && additionalDesiredFirstItem!=undefined)"class="gonextlink" (click)="goNextTab(4,additionalDesiredFirstItem,'D')" >Next Item</a> -->
                                <a
                                    *ngIf="nextItemType == ''"
                                    class="gonextlink btn2"
                                    (click)="goNextTab(4)"
                                    >Next Item</a
                                >
                            </div>
                        </div>
                    </form>
                </div>
            </mat-card>
        </mat-tab>

        <mat-tab>
            <ng-template mat-tab-label>
                <div
                    class="mainmenu_tabContent {{ isAllExpandClass }}"
                    (click)="
                        UpdateRepair(
                            additionalDesiredFirstItem,
                            additionalDesiredFirstItemRepairName,
                            'D',
                            additionalDesiredFirstItemLocation
                        )
                    "
                >
                    <mat-icon
                        class="plusIcon example-tab-icon"
                        *ngIf="
                            (isMinusShow == true &&
                                currentClick != 'additional' &&
                                additionalDesiredFirstItem != '') ||
                            (isPlusShow == true &&
                                additionalDesiredFirstItem != '' &&
                                additionalDesiredFirstItem != null &&
                                additionalDesiredFirstItem != undefined)
                        "
                        (click)="expandFunction('additional')"
                        >&#43;</mat-icon
                    >
                    <mat-icon
                        class="minusIcon example-tab-icon"
                        *ngIf="
                            (isMinusShow == true && isAllexpand == true) ||
                            (currentClick == 'additional' &&
                                isMinusShow == true &&
                                additionalDesiredFirstItem != '' &&
                                additionalDesiredFirstItem != null &&
                                additionalDesiredFirstItem != undefined)
                        "
                        (click)="closedFunction()"
                        >&#8722;</mat-icon
                    >
                    <span class="labelText">Additional</span>
                </div>
                <ul
                    class="submenu_tabContent {{ isAllExpandClass }}"
                    *ngIf="isSubTabContentShow == true || isAllexpand == true"
                >
                    <ng-container
                        *ngFor="let desiredItem of allRepairItem; let i = index"
                    >
                        <ng-container
                            *ngFor="
                                let desiredItemRepair of desiredItem.RepairItemDetails;
                                let j = index
                            "
                        >
                            <li
                                *ngIf="
                                    desiredItemRepair.Desired == true &&
                                    desiredItemRepair.isAdditional == true &&
                                    desiredItem.Operation != 'Inactive'
                                "
                            >
                                <a
                                    class="itemLink"
                                    *ngIf="
                                        desiredItemRepair.isItemCompleted !=
                                        true
                                    "
                                    href="javascript:void(0)"
                                    (click)="
                                        UpdateRepair(
                                            desiredItem.RepairType,
                                            desiredItemRepair.RepairItemName,
                                            'D',
                                            desiredItemRepair.Location
                                        )
                                    "
                                    >Item {{ desiredItem.id }}:
                                    {{ desiredItem.RepairType }}<br />
                                    ({{ desiredItemRepair.RepairItemName }})</a
                                >
                                <a
                                    class="itemLink isItemCompleted"
                                    *ngIf="
                                        desiredItemRepair.isItemCompleted ==
                                        true
                                    "
                                    href="javascript:void(0)"
                                    (click)="
                                        UpdateRepair(
                                            desiredItem.RepairType,
                                            desiredItemRepair.RepairItemName,
                                            'D',
                                            desiredItemRepair.Location
                                        )
                                    "
                                    >Item {{ desiredItem.id }}:
                                    {{ desiredItem.RepairType }} <br />({{
                                        desiredItemRepair.RepairItemName
                                    }})</a
                                >
                            </li>
                        </ng-container>
                    </ng-container>
                </ul>
            </ng-template>

            <mat-card class="rightSideCardContent">
                <h2 style="text-decoration: underline">
                    Additional Construction Items
                </h2>
                <p>
                    Additional items added using this section would include any
                    items that were discussed with you and the homeowner that
                    may have not been discussed with the consultant and were
                    therefore not included on the previous screens. Use the
                    search bar below to find where to add your construction
                    item.
                </p>
                <table
                    style="
                        width: 100%;
                        float: left;
                        margin-top: 10px;
                        border: none;
                    "
                >
                    <tbody>
                        <tr>
                            <td style="width: 50%">
                                <div class="searchRecord">
                                    Search<br /><input
                                        type="text"
                                        class="searchComponent"
                                        #searchComponentField
                                        name="searchComponent"
                                        (keyup)="filterReportComponents($event)"
                                    /><span
                                        class="material-icons refrestBtn"
                                        (click)="refreshSearchComponents()"
                                        >refresh</span
                                    ><span style="color: #aaa"
                                        >Examples: Framing, Plumbing, Wood
                                        Floors, etc.</span
                                    >
                                </div>
                            </td>
                            <td>
                                <p>
                                    If you do not have any additional items you
                                    can always use the button below to review
                                    your estimate before proceeding to the
                                    permits necessary for the construction.
                                </p>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <ng-container *ngIf="searchResult != ''">
                    <ul class="searchResultList">
                        <li
                            *ngFor="
                                let resultItem of searchResult;
                                let i = index
                            "
                        >
                            <ng-container *ngFor="let value of userList.Items">
                                <div
                                    *ngIf="
                                        value.RepairType ==
                                        resultItem.RepairType
                                    "
                                    class="itemTitle"
                                >
                                    {{ value.id }}. {{ value.RepairType
                                    }}<a
                                        (click)="
                                            addItemToAdditional(
                                                value.RepairType
                                            )
                                        "
                                        >Add Item to this section</a
                                    >
                                </div>
                            </ng-container>
                            <mat-accordion class="subItemsResult" multi="false">
                                <mat-expansion-panel
                                    *ngFor="
                                        let item of resultItem.RepairItemDetails;
                                        let j = index
                                    "
                                >
                                    <mat-expansion-panel-header>
                                        <mat-panel-title
                                            class="reportComponentTitle"
                                        >
                                            <span>{{
                                                item.RepairItemName
                                            }}</span>

                                            <div class="actionBtn">
                                                View details
                                            </div>
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>
                                    <div class="resultItemSummary">
                                        {{ item.Summary }}
                                    </div>
                                </mat-expansion-panel>
                            </mat-accordion>
                        </li>
                    </ul>
                </ng-container>

                <div class="footerRepairItemsContainer">
                    <div class="rightSection" style="float: right">
                        <a class="gonextlink" (click)="goNextToAddTab(5, 'EPC')"
                            >Enter Permit Costs</a
                        >
                    </div>
                </div>
            </mat-card>
            <mat-card
                *ngIf="
                    additionalDesiredFirstItem != '' &&
                    additionalDesiredFirstItem != null &&
                    additionalDesiredFirstItem != undefined
                "
                class="additionalItemsListCard"
            >
                <mat-accordion multi="false">
                    <ng-container
                        *ngFor="let desiredItem of allRepairItem; let i = index"
                    >
                        <ng-container
                            *ngFor="
                                let desiredItemRepair of desiredItem.RepairItemDetails;
                                let j = index
                            "
                        >
                            <ng-container
                                *ngIf="
                                    desiredItemRepair.Desired == true &&
                                    desiredItemRepair.isAdditional == true &&
                                    desiredItem.Operation != 'Inactive'
                                "
                            >
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title
                                            class="reportComponentTitle"
                                            (click)="
                                                UpdateRepair(
                                                    desiredItem.RepairType,
                                                    desiredItemRepair.RepairItemName,
                                                    'D',
                                                    desiredItemRepair.Location
                                                )
                                            "
                                        >
                                            <span
                                                >{{ desiredItem.id }}:
                                                {{ desiredItem.RepairType }} ({{
                                                    desiredItemRepair.RepairItemName
                                                }})</span
                                            >

                                            <div class="actionBtn">
                                                View details
                                            </div>
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>
                                    <div class="resultItemSummary">
                                        <div
                                            class="expandFormControls container-login100"
                                        >
                                            <form
                                                [formGroup]="RepairItemForm"
                                                class="example-form"
                                                (ngSubmit)="
                                                    SaveAdditionalConstructionItem()
                                                "
                                            >
                                                <mat-card-content>
                                                    <mat-form-field>
                                                        <span
                                                            class="mat-form-field-label-wrapper"
                                                            ><!----><label
                                                                class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                                >Construction
                                                                Item
                                                            </label></span
                                                        >
                                                        <select
                                                            matNativeControl
                                                            formControlName="SendRepairType"
                                                            [disabled]="true"
                                                        >
                                                            <option value="">
                                                                Select
                                                            </option>
                                                            <option
                                                                *ngFor="
                                                                    let value of userList.Items
                                                                "
                                                                value="{{
                                                                    desiredItem.RepairType
                                                                }}"
                                                            >
                                                                {{
                                                                    desiredItem.id
                                                                }}
                                                                {{
                                                                    desiredItem.RepairType
                                                                }}
                                                            </option>
                                                        </select>
                                                    </mat-form-field>
                                                    <h3>
                                                        <strong
                                                            >Repair Item
                                                            Details</strong
                                                        >
                                                    </h3>

                                                    <div
                                                        class="RepairItemDetailsList"
                                                    >
                                                        <div
                                                            *ngFor="
                                                                let RepairItemDetail of RepairItemForm.get(
                                                                    'RepairItemDetails'
                                                                )['controls'];
                                                                let i = index
                                                            "
                                                            formArrayName="RepairItemDetails"
                                                        >
                                                            <div
                                                                [formGroupName]="
                                                                    i
                                                                "
                                                            >
                                                                <!-- {{RepairItemForm.value['RepairItemDetails'][i].RepairItemName}} --- {{desiredItemRepair.RepairItemName}} -->
                                                                <!-- *ngIf="RepairItemForm.value['RepairItemDetails'][i].RepairItemName==desiredItemRepair.RepairItemName" -->
                                                                <ng-container>
                                                                    <table>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td>
                                                                                    <mat-form-field
                                                                                        class="example-full-width"
                                                                                    >
                                                                                        <span
                                                                                            class="mat-form-field-label-wrapper"
                                                                                            ><!----><label
                                                                                                class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                                                                >Repair
                                                                                                Item
                                                                                                Name
                                                                                            </label></span
                                                                                        >
                                                                                        <input
                                                                                            matInput
                                                                                            [attr.disabled]="
                                                                                                false
                                                                                            "
                                                                                            formControlName="RepairItemName"
                                                                                            name="RepairItemName"
                                                                                        />
                                                                                    </mat-form-field>
                                                                                </td>
                                                                                <td>
                                                                                    <mat-form-field
                                                                                        class="example-full-width"
                                                                                    >
                                                                                        <span
                                                                                            class="mat-form-field-label-wrapper"
                                                                                            ><!----><label
                                                                                                class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                                                                >Location
                                                                                            </label></span
                                                                                        >
                                                                                        <input
                                                                                            matInput
                                                                                            formControlName="Location"
                                                                                            name="Location"
                                                                                        />
                                                                                    </mat-form-field>
                                                                                </td>
                                                                                <td>
                                                                                    <mat-form-field>
                                                                                        <span
                                                                                            class="mat-form-field-label-wrapper"
                                                                                            ><!----><label
                                                                                                class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                                                                >Level
                                                                                            </label></span
                                                                                        >
                                                                                        <select
                                                                                            matNativeControl
                                                                                            formControlName="Level"
                                                                                        >
                                                                                            <option
                                                                                                value="M"
                                                                                                disabled="true"
                                                                                            >
                                                                                                M
                                                                                                =
                                                                                                Mandatory
                                                                                                By
                                                                                                HUD
                                                                                            </option>
                                                                                            <option
                                                                                                value="R"
                                                                                            >
                                                                                                R
                                                                                                =
                                                                                                Recommended
                                                                                                By
                                                                                                Consultant/Other
                                                                                            </option>
                                                                                            <option
                                                                                                value="D"
                                                                                            >
                                                                                                D
                                                                                                =
                                                                                                Desired
                                                                                                By
                                                                                                Homeowner
                                                                                            </option>
                                                                                        </select>
                                                                                    </mat-form-field>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                    <table>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td>
                                                                                    <h2
                                                                                        style="
                                                                                            font-size: 24px;
                                                                                        "
                                                                                    >
                                                                                        <Strong
                                                                                            >Material/LS</Strong
                                                                                        >
                                                                                    </h2>
                                                                                </td>
                                                                                <td>
                                                                                    &nbsp;
                                                                                </td>
                                                                                <td>
                                                                                    &nbsp;
                                                                                </td>
                                                                                <td>
                                                                                    &nbsp;
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>
                                                                                    <mat-form-field>
                                                                                        <span
                                                                                            class="mat-form-field-label-wrapper"
                                                                                            ><!----><label
                                                                                                class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                                                                >UNIT
                                                                                            </label></span
                                                                                        >
                                                                                        <select
                                                                                            matNativeControl
                                                                                            formControlName="MaterialUnit"
                                                                                        >
                                                                                            <option
                                                                                                value="EA"
                                                                                            >
                                                                                                Each
                                                                                                =
                                                                                                EA
                                                                                            </option>
                                                                                            <option
                                                                                                value="LF"
                                                                                            >
                                                                                                Linear
                                                                                                foot
                                                                                                =
                                                                                                LF
                                                                                            </option>
                                                                                            <option
                                                                                                value="SF"
                                                                                            >
                                                                                                Square
                                                                                                Foot
                                                                                                =
                                                                                                SF
                                                                                            </option>
                                                                                            <option
                                                                                                value="LS"
                                                                                            >
                                                                                                Lump
                                                                                                Sum
                                                                                                =
                                                                                                LS
                                                                                            </option>
                                                                                            <option
                                                                                                value="SY"
                                                                                            >
                                                                                                Square
                                                                                                Yard
                                                                                                =
                                                                                                SY
                                                                                            </option>
                                                                                            <option
                                                                                                value="*"
                                                                                            >
                                                                                                *
                                                                                                =
                                                                                                Required
                                                                                            </option>
                                                                                        </select>
                                                                                    </mat-form-field>
                                                                                </td>
                                                                                <td>
                                                                                    <mat-form-field
                                                                                        class="example-full-width"
                                                                                    >
                                                                                        <span
                                                                                            class="mat-form-field-label-wrapper"
                                                                                            ><!----><label
                                                                                                class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                                                                >Enter
                                                                                                Quantity
                                                                                                Here</label
                                                                                            ></span
                                                                                        >
                                                                                        <input
                                                                                            matInput
                                                                                            (keyup)="
                                                                                                forValValidator(
                                                                                                    RepairItemDetail,
                                                                                                    $event,
                                                                                                    'MaterialQuantity'
                                                                                                )
                                                                                            "
                                                                                            formControlName="MaterialQuantity"
                                                                                            name="Quantity"
                                                                                        />
                                                                                    </mat-form-field>
                                                                                </td>
                                                                                <td>
                                                                                    <mat-form-field
                                                                                        class="example-full-width"
                                                                                    >
                                                                                        <span
                                                                                            class="mat-form-field-label-wrapper"
                                                                                            ><!----><label
                                                                                                class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                                                                >Enter
                                                                                                Cost
                                                                                                Here
                                                                                            </label></span
                                                                                        >
                                                                                        <input
                                                                                            matInput
                                                                                            (keyup)="
                                                                                                forValValidator(
                                                                                                    RepairItemDetail,
                                                                                                    $event,
                                                                                                    'MaterialUnitCost'
                                                                                                )
                                                                                            "
                                                                                            formControlName="MaterialUnitCost"
                                                                                            name="UnitCost"
                                                                                        />
                                                                                    </mat-form-field>
                                                                                </td>
                                                                                <td>
                                                                                    =
                                                                                    ${{
                                                                                        RepairItemForm
                                                                                            .value[
                                                                                            "RepairItemDetails"
                                                                                        ][
                                                                                            i
                                                                                        ]
                                                                                            .MaterialQuantity *
                                                                                            RepairItemForm
                                                                                                .value[
                                                                                                "RepairItemDetails"
                                                                                            ][
                                                                                                i
                                                                                            ]
                                                                                                .MaterialUnitCost
                                                                                    }}
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>
                                                                                    <h2
                                                                                        style="
                                                                                            font-size: 24px;
                                                                                        "
                                                                                    >
                                                                                        <Strong
                                                                                            >Labor</Strong
                                                                                        >
                                                                                    </h2>
                                                                                </td>
                                                                                <td>
                                                                                    &nbsp;
                                                                                </td>
                                                                                <td>
                                                                                    &nbsp;
                                                                                </td>
                                                                                <td>
                                                                                    &nbsp;
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>
                                                                                    <mat-form-field>
                                                                                        <span
                                                                                            class="mat-form-field-label-wrapper"
                                                                                            ><!----><label
                                                                                                class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                                                                >UNIT
                                                                                            </label></span
                                                                                        >
                                                                                        <select
                                                                                            matNativeControl
                                                                                            formControlName="LaborUnit"
                                                                                        >
                                                                                            <option
                                                                                                value="EA"
                                                                                            >
                                                                                                Each
                                                                                                =
                                                                                                EA
                                                                                            </option>
                                                                                            <option
                                                                                                value="LF"
                                                                                            >
                                                                                                Linear
                                                                                                foot
                                                                                                =
                                                                                                LF
                                                                                            </option>
                                                                                            <option
                                                                                                value="SF"
                                                                                            >
                                                                                                Square
                                                                                                Foot
                                                                                                =
                                                                                                SF
                                                                                            </option>
                                                                                            <option
                                                                                                value="LS"
                                                                                            >
                                                                                                Lump
                                                                                                Sum
                                                                                                =
                                                                                                LS
                                                                                            </option>
                                                                                            <option
                                                                                                value="SY"
                                                                                            >
                                                                                                Square
                                                                                                Yard
                                                                                                =
                                                                                                SY
                                                                                            </option>
                                                                                            <option
                                                                                                value="*"
                                                                                            >
                                                                                                *
                                                                                                =
                                                                                                Required
                                                                                            </option>
                                                                                        </select>
                                                                                    </mat-form-field>
                                                                                </td>
                                                                                <td>
                                                                                    <mat-form-field
                                                                                        class="example-full-width"
                                                                                    >
                                                                                        <span
                                                                                            class="mat-form-field-label-wrapper"
                                                                                            ><!----><label
                                                                                                class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                                                                >Enter
                                                                                                Quantity
                                                                                                Here</label
                                                                                            ></span
                                                                                        >
                                                                                        <input
                                                                                            matInput
                                                                                            (keyup)="
                                                                                                forValValidator(
                                                                                                    RepairItemDetail,
                                                                                                    $event,
                                                                                                    'LaborQuantity'
                                                                                                )
                                                                                            "
                                                                                            formControlName="LaborQuantity"
                                                                                            name="Quantity"
                                                                                        />
                                                                                    </mat-form-field>
                                                                                </td>
                                                                                <td>
                                                                                    <mat-form-field
                                                                                        class="example-full-width"
                                                                                    >
                                                                                        <span
                                                                                            class="mat-form-field-label-wrapper"
                                                                                            ><!----><label
                                                                                                class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                                                                >Enter
                                                                                                Cost
                                                                                                Here</label
                                                                                            ></span
                                                                                        >
                                                                                        <input
                                                                                            matInput
                                                                                            (keyup)="
                                                                                                forValValidator(
                                                                                                    RepairItemDetail,
                                                                                                    $event,
                                                                                                    'LaborUnitCost'
                                                                                                )
                                                                                            "
                                                                                            formControlName="LaborUnitCost"
                                                                                            name="UnitCost"
                                                                                        />
                                                                                    </mat-form-field>
                                                                                </td>
                                                                                <td>
                                                                                    =
                                                                                    ${{
                                                                                        RepairItemForm
                                                                                            .value[
                                                                                            "RepairItemDetails"
                                                                                        ][
                                                                                            i
                                                                                        ]
                                                                                            .LaborQuantity *
                                                                                            RepairItemForm
                                                                                                .value[
                                                                                                "RepairItemDetails"
                                                                                            ][
                                                                                                i
                                                                                            ]
                                                                                                .LaborUnitCost
                                                                                    }}
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>
                                                                                    &nbsp;
                                                                                </td>
                                                                                <td>
                                                                                    &nbsp;
                                                                                </td>
                                                                                <td>
                                                                                    <h2
                                                                                        style="
                                                                                            font-size: 24px;
                                                                                            text-align: right;
                                                                                        "
                                                                                    >
                                                                                        <Strong
                                                                                            >Item
                                                                                            Total
                                                                                            =</Strong
                                                                                        >
                                                                                    </h2>
                                                                                </td>
                                                                                <td>
                                                                                    <h2
                                                                                        style="
                                                                                            font-size: 24px;
                                                                                        "
                                                                                    >
                                                                                        <Strong>
                                                                                            ${{
                                                                                                RepairItemForm
                                                                                                    .value[
                                                                                                    "RepairItemDetails"
                                                                                                ][
                                                                                                    i
                                                                                                ]
                                                                                                    .MaterialQuantity *
                                                                                                    RepairItemForm
                                                                                                        .value[
                                                                                                        "RepairItemDetails"
                                                                                                    ][
                                                                                                        i
                                                                                                    ]
                                                                                                        .MaterialUnitCost +
                                                                                                    RepairItemForm
                                                                                                        .value[
                                                                                                        "RepairItemDetails"
                                                                                                    ][
                                                                                                        i
                                                                                                    ]
                                                                                                        .LaborQuantity *
                                                                                                        RepairItemForm
                                                                                                            .value[
                                                                                                            "RepairItemDetails"
                                                                                                        ][
                                                                                                            i
                                                                                                        ]
                                                                                                            .LaborUnitCost
                                                                                            }}</Strong
                                                                                        >
                                                                                    </h2>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>

                                                                    <mat-form-field
                                                                        class="example-full-width"
                                                                    >
                                                                        <span
                                                                            class="mat-form-field-label-wrapper"
                                                                            ><!----><label
                                                                                class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                                                >Summary</label
                                                                            ></span
                                                                        >
                                                                        <textarea
                                                                            matInput
                                                                            rows="8"
                                                                            formControlName="Summary"
                                                                            name="Summary"
                                                                        ></textarea>
                                                                    </mat-form-field>
                                                                </ng-container>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </mat-card-content>
                                                <div
                                                    class="footerRepairItemsContainer"
                                                >
                                                    <div class="leftSection">
                                                        &nbsp;
                                                    </div>
                                                    <ng-container
                                                        *ngFor="
                                                            let allItem of allRepairItem;
                                                            let i = index
                                                        "
                                                    >
                                                        <div
                                                            class="middleSection"
                                                            *ngIf="
                                                                allItem.Operation ==
                                                                'Inactive'
                                                            "
                                                        >
                                                            <strong
                                                                >Project Running
                                                                Estimate</strong
                                                            >
                                                            = ${{
                                                                allItem.RepairTotal
                                                            }}
                                                        </div>
                                                    </ng-container>
                                                    <div class="rightSection">
                                                        <a
                                                            class="gonextlink"
                                                            (click)="
                                                                saveAdditionalItem()
                                                            "
                                                            >Save Item</a
                                                        >
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </mat-expansion-panel>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </mat-accordion>
            </mat-card>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                <div class="mainmenu_tabContent" (click)="removeAllExpand(5)">
                    <span class="labelText">Permit Costs</span>
                </div>
            </ng-template>
            <mat-card>
                <form
                    [formGroup]="RepairItemForm"
                    class="example-form mb-2"
                    (ngSubmit)="submitInputBid()"
                >
                    <h3 class="mt-0">
                        <strong>Permits/Submit Estimate</strong>
                    </h3>
                    <table class="permitCostTable">
                        <thead>
                            <th>Select</th>
                            <th>Permit Type</th>
                            <th>Permit Cost</th>
                        </thead>
                        <tbody *ngIf="formStatus">
                            <ng-container
                                *ngFor="
                                    let AllowableFeesItemDetail of RepairItemForm.get(
                                        'PermitCost'
                                    )['controls'];
                                    let i = index
                                "
                                formArrayName="PermitCost"
                            >
                                <tr [formGroupName]="i">
                                    <td align="center">
                                        <mat-checkbox
                                            formControlName="Status"
                                        ></mat-checkbox>
                                    </td>
                                    <td align="center">
                                        {{
                                            RepairItemForm.value["PermitCost"][
                                                i
                                            ].PermitType
                                        }}
                                    </td>
                                    <td align="center">
                                        <mat-form-field>
                                            <input
                                                matInput
                                                placeholder=" "
                                                formControlName="PermitCost"
                                            />
                                        </mat-form-field>
                                    </td>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>
                    <input type="hidden" formControlName="ConsultantsComment" />

                    <div class="footerRepairItemsContainer">
                        <div class="leftSection">
                            <a
                                *ngIf="
                                    additionalDesiredFirstItem != '' &&
                                    additionalDesiredFirstItem != null &&
                                    additionalDesiredFirstItem != undefined
                                "
                                class="gobacklink"
                                href="javascript:void(0)"
                                (click)="
                                    goPreviousTab(
                                        4,
                                        additionalDesiredFirstItem,
                                        additionalDesiredFirstItemRepairName,
                                        'D'
                                    )
                                "
                                >&#8592; Go Back</a
                            >
                            <a
                                *ngIf="
                                    additionalDesiredFirstItem == '' &&
                                    desiredFirstItem != '' &&
                                    desiredFirstItem != null &&
                                    desiredFirstItem != undefined
                                "
                                class="gobacklink"
                                href="javascript:void(0)"
                                (click)="
                                    goPreviousTab(
                                        3,
                                        desiredFirstItem,
                                        desiredFirstItemRepairName,
                                        'D'
                                    )
                                "
                                >&#8592; Go Back</a
                            >
                            <a
                                *ngIf="
                                    additionalDesiredFirstItem == '' &&
                                    desiredFirstItem == '' &&
                                    recommendedFirstItem != '' &&
                                        recommendedFirstItem != null &&
                                        recommendedFirstItem != undefined
                                "
                                class="gobacklink"
                                href="javascript:void(0)"
                                (click)="
                                    goPreviousTab(
                                        2,
                                        recommendedFirstItem,
                                        recommendedFirstItemRepairName,
                                        'R'
                                    )
                                "
                                >&#8592; Go Back</a
                            >
                            <a
                                *ngIf="
                                    additionalDesiredFirstItem == '' &&
                                    desiredFirstItem == '' &&
                                    recommendedFirstItem == '' &&
                                    mandatoryFirstItem != '' &&
                                        mandatoryFirstItem != null &&
                                        mandatoryFirstItem != undefined
                                "
                                class="gobacklink"
                                href="javascript:void(0)"
                                (click)="
                                    goPreviousTab(
                                        1,
                                        mandatoryFirstItem,
                                        mandatoryFirstItemRepairName,
                                        'M'
                                    )
                                "
                                >&#8592; Go Back</a
                            >
                            <a
                                *ngIf="
                                    additionalDesiredFirstItem == '' &&
                                    desiredFirstItem == '' &&
                                    recommendedFirstItem == '' &&
                                    mandatoryFirstItem == ''
                                "
                                class="gobacklink"
                                href="javascript:void(0)"
                                (click)="goPreviousTab(0)"
                                >&#8592; Go Back</a
                            >
                        </div>
                        <ng-container
                            *ngFor="let allItem of allRepairItem; let i = index"
                        >
                            <div
                                class="middleSection"
                                *ngIf="allItem.Operation == 'Inactive'"
                            >
                                <strong>Project Running Estimate</strong> = ${{
                                    allItem.RepairTotal
                                }}
                            </div>
                        </ng-container>
                        <div class="rightSection">
                            <a
                                class="gonextlink"
                                (click)="reviewEstimateGenerate()"
                                >Review Estimate</a
                            >
                        </div>
                    </div>
                </form>
            </mat-card>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                <div
                    class="mainmenu_tabContent"
                    (click)="reviewEstimateGenerate()"
                >
                    <span class="labelText">Review / Submit Estimate</span>
                </div>
            </ng-template>
            <mat-card>
                <form
                    [formGroup]="RepairItemForm"
                    class="example-form mb-2"
                    (ngSubmit)="submitInputBid()"
                >
                    <h3 class="mt-0">
                        <strong>Review / Submit Project Estimate</strong>
                    </h3>
                    <br />
                    <span style="line-height: 43px"
                        >Click on the
                        <span style="color: #8ec18e">GREEN</span> expanded items
                        on the left if you would like to revise an item in your
                        estimate. After your revision has been made, use the
                        <img
                            [src]="saveBtnImagePath"
                            matTooltip="To save the revision"
                            style="margin-top: 0px"
                        />
                        to save the revision. You can then select the “Review /
                        Submit Estimate” tab to come back to this page.</span
                    >
                    <div class="pdf-right" *ngIf="pdfdataShow">
                        <embed
                            [src]="pdfData"
                            id="pdfViewer"
                            type="application/pdf"
                            width="100%"
                            height="500px"
                        />
                    </div>

                    <div class="footerRepairItemsContainer">
                        <div class="leftSection">&nbsp;</div>
                        <div class="middleSection">&nbsp;</div>

                        <div class="rightSection">
                            <button
                                mat-raised-button
                                class="gonextlink"
                                type="submit"
                            >
                                Submit Estimate
                            </button>
                        </div>
                    </div>
                </form>
            </mat-card>
        </mat-tab>
    </mat-tab-group>

    <!---End New Code Section --->
    <!--- Old Code -->
    <!-- <div [hidden]="!isList">
    <p><strong>Please fill all the Construction items highlighted below. Type 0 on labor and materials for
      construction items that you would like to remove from your Bid on Repairs.</strong></p>
    
    <p><strong>Components filled in with "0" will be removed by the consultant and will not be included in the final
        renovation report (Specification of Repairs-SOR).</strong></p>
  
    <p><strong>Under each category you'll have the option to add new construction items by clicking
        the "Add Item" button.</strong></p>
  </div>
  
  <div [hidden]="!isList">
    <mat-expansion-panel [expanded]="true">
        
      <mat-expansion-panel-header>
        <mat-panel-title>
          Construction Components
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="mat-elevation-z8">
        <table mat-table [dataSource]="dataSource" matSort>

          
          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> S. No. </th>
            <td mat-cell *matCellDef="let row">
              <span *ngIf="row.Operation!='Inactive' && row.style != 'bold'">{{row.id}}</span>
              <span *ngIf="row.Operation!='Inactive' && row.style == 'bold'"><strong>{{row.id}}</strong></span>
              <span *ngIf="row.Operation=='Inactive'">#</span>
            </td>
          </ng-container>

          
          <ng-container matColumnDef="RepairType">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Repair Type </th>
            <td mat-cell *matCellDef="let row"> 
              <span *ngIf="row.Operation!='Inactive' && row.style != 'bold'">{{row.RepairType}}</span>
              <strong *ngIf="row.Operation!='Inactive' && row.style == 'bold'">{{row.RepairType}}</strong>
              <strong *ngIf="row.Operation=='Inactive'">{{row.RepairType}}</strong>
            </td>
          </ng-container>
          <ng-container matColumnDef="RepairTotal">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Repair Total </th>
            <td mat-cell *matCellDef="let row">
              <span *ngIf="row.Operation!='Inactive' && row.style != 'bold'">{{row.RepairTotal}}</span>  
              <strong *ngIf="row.Operation!='Inactive' && row.style == 'bold'">{{row.RepairTotal}}</strong>
              <strong *ngIf="row.Operation=='Inactive'">{{row.RepairTotal}}</strong>
            </td>
          </ng-container>
          
          <ng-container matColumnDef="Operation">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Operation </th>
            <td mat-cell *matCellDef="let row">
              <a href="javascript:void(0)" *ngIf="row.Operation!='Inactive' && row.style == 'bold'" (click)="UpdateRepair(row.RepairType,'Mandatory')"><strong>Fill {{row.RepairType}} </strong></a>
              <span *ngIf="row.Operation!='Inactive' && row.Mandatory"><strong>(Mandatory)</strong></span>
              <a href="javascript:void(0)" *ngIf="row.Operation!='Inactive' && row.style != 'bold'" (click)="UpdateRepair(row.RepairType,null)"> Fill {{row.RepairType}} </a>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;" style="cursor:pointer">
          </tr>
        </table>

        <mat-paginator (page)="nextPage($event)" [pageSizeOptions]="[50, 100]"></mat-paginator>
      </div>
    </mat-expansion-panel>  
  </div>
    
  <br>
  <br>-->

    <!--<form [formGroup]="RepairItemForm" class="example-form" (ngSubmit)="submitInputBid()" [hidden]="!isList" >
   
    
  <mat-expansion-panel [expanded]="true">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Permit Cost Type
      </mat-panel-title>
    </mat-expansion-panel-header>
    <table>
      <thead>
        <th>Select</th>
        <th>Permit Type</th>
        <th>Permit Cost</th>
      </thead>
      <tbody *ngIf="formStatus">

        <ng-container *ngFor="let AllowableFeesItemDetail of RepairItemForm.controls.PermitCost.controls; let i=index;" formArrayName="PermitCost" >
         
            <tr [formGroupName]="i">
              
              <td align="center" >

                <mat-checkbox formControlName="Status"  ></mat-checkbox>
              </td>
              <td align="center">
                {{RepairItemForm.value['PermitCost'][i].PermitType}}
                
              </td>
              <td align="center">
                <mat-form-field>
                  <input matInput placeholder=" " formControlName="PermitCost" >
                </mat-form-field>
              </td>
            </tr>
          </ng-container>
        
      </tbody>
    </table>
  </mat-expansion-panel>
  <br>
  <br>
  <br>
  
  <button mat-raised-button  type="submit" color="primary">Submit</button>
  
</form>-->

    <!-- <div [hidden]="!isList">
      <mat-card class="my-form">
        
          <mat-card-header>
            <mat-card-title></mat-card-title>
          </mat-card-header>
          <mat-card-content>
            
  
              
              <br>
            
          </mat-card-content>
          <mat-card-actions>
            
  
          </mat-card-actions>
        
      </mat-card>
    </div> -->
    <div
        class="overLayer additionalInputForm mat-elevation-z8"
        *ngIf="isAdd || isEdit"
    >
        <div class="container-login100">
            <mat-card class="my-form">
                <form
                    [formGroup]="RepairItemForm"
                    class="example-form"
                    (ngSubmit)="SaveAdditionalConstructionItem()"
                >
                    <mat-card-header>
                        <mat-card-title></mat-card-title>
                    </mat-card-header>
                    <mat-card-content>
                        <mat-form-field>
                            <span class="mat-form-field-label-wrapper"
                                ><!----><label
                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                    >Construction Item
                                </label></span
                            >
                            <select
                                matNativeControl
                                formControlName="SendRepairType"
                                [disabled]="true"
                            >
                                <option value="">Select</option>
                                <option
                                    *ngFor="let value of userList.Items"
                                    value="{{ value.RepairType }}"
                                >
                                    {{ value.id }} {{ value.RepairType }}
                                </option>
                            </select>
                        </mat-form-field>
                        <mat-card>
                            <mat-card-header>
                                <mat-card-title
                                    >Repair Item Details</mat-card-title
                                >
                            </mat-card-header>
                            <div>
                                <div
                                    *ngFor="
                                        let RepairItemDetail of RepairItemForm.get(
                                            'RepairItemDetails'
                                        )['controls'];
                                        let i = index
                                    "
                                    formArrayName="RepairItemDetails"
                                >
                                    <div [formGroupName]="i">
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <mat-form-field
                                                            class="example-full-width"
                                                        >
                                                            <span
                                                                class="mat-form-field-label-wrapper"
                                                                ><!----><label
                                                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                                    >Repair Item
                                                                    Name
                                                                </label></span
                                                            >
                                                            <input
                                                                matInput
                                                                [attr.disabled]="
                                                                    false
                                                                "
                                                                formControlName="RepairItemName"
                                                                name="RepairItemName"
                                                            />
                                                        </mat-form-field>
                                                    </td>
                                                    <td>
                                                        <mat-form-field
                                                            class="example-full-width"
                                                        >
                                                            <span
                                                                class="mat-form-field-label-wrapper"
                                                                ><!----><label
                                                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                                    >Location</label
                                                                ></span
                                                            >
                                                            <input
                                                                matInput
                                                                formControlName="Location"
                                                                name="Location"
                                                            />
                                                        </mat-form-field>
                                                    </td>
                                                    <td>
                                                        <mat-form-field>
                                                            <span
                                                                class="mat-form-field-label-wrapper"
                                                                ><!----><label
                                                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                                    >Level</label
                                                                ></span
                                                            >
                                                            <select
                                                                matNativeControl
                                                                formControlName="Level"
                                                            >
                                                                <option
                                                                    value="M"
                                                                    disabled="true"
                                                                >
                                                                    M =
                                                                    Mandatory By
                                                                    HUD
                                                                </option>
                                                                <option
                                                                    value="R"
                                                                >
                                                                    R =
                                                                    Recommended
                                                                    By
                                                                    Consultant/Other
                                                                </option>
                                                                <option
                                                                    value="D"
                                                                >
                                                                    D = Desired
                                                                    By Homeowner
                                                                </option>
                                                            </select>
                                                        </mat-form-field>
                                                    </td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <h2
                                                            style="
                                                                font-size: 24px;
                                                            "
                                                        >
                                                            <Strong
                                                                >Material/LS</Strong
                                                            >
                                                        </h2>
                                                    </td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <mat-form-field>
                                                            <span
                                                                class="mat-form-field-label-wrapper"
                                                                ><!----><label
                                                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                                    >UNIT
                                                                </label></span
                                                            >
                                                            <select
                                                                matNativeControl
                                                                formControlName="MaterialUnit"
                                                            >
                                                                <option
                                                                    value="EA"
                                                                >
                                                                    Each = EA
                                                                </option>
                                                                <option
                                                                    value="LF"
                                                                >
                                                                    Linear foot
                                                                    = LF
                                                                </option>
                                                                <option
                                                                    value="SF"
                                                                >
                                                                    Square Foot
                                                                    = SF
                                                                </option>
                                                                <option
                                                                    value="LS"
                                                                >
                                                                    Lump Sum =
                                                                    LS
                                                                </option>
                                                                <option
                                                                    value="SY"
                                                                >
                                                                    Square Yard
                                                                    = SY
                                                                </option>
                                                                <option
                                                                    value="*"
                                                                >
                                                                    * = Required
                                                                </option>
                                                            </select>
                                                        </mat-form-field>
                                                    </td>
                                                    <td>
                                                        <mat-form-field
                                                            class="example-full-width"
                                                        >
                                                            <span
                                                                class="mat-form-field-label-wrapper"
                                                                ><!----><label
                                                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                                    >Enter
                                                                    Quantity
                                                                    Here</label
                                                                ></span
                                                            >
                                                            <input
                                                                matInput
                                                                (keyup)="
                                                                    forValValidator(
                                                                        RepairItemDetail,
                                                                        $event,
                                                                        'MaterialQuantity'
                                                                    )
                                                                "
                                                                formControlName="MaterialQuantity"
                                                                name="Quantity"
                                                            />
                                                        </mat-form-field>
                                                    </td>
                                                    <td>
                                                        <mat-form-field
                                                            class="example-full-width"
                                                        >
                                                            <span
                                                                class="mat-form-field-label-wrapper"
                                                                ><!----><label
                                                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                                    >Enter Cost
                                                                    Here</label
                                                                ></span
                                                            >
                                                            <input
                                                                matInput
                                                                (keyup)="
                                                                    forValValidator(
                                                                        RepairItemDetail,
                                                                        $event,
                                                                        'MaterialUnitCost'
                                                                    )
                                                                "
                                                                formControlName="MaterialUnitCost"
                                                                name="UnitCost"
                                                            />
                                                        </mat-form-field>
                                                    </td>
                                                    <td>
                                                        = ${{
                                                            RepairItemForm
                                                                .value[
                                                                "RepairItemDetails"
                                                            ][i]
                                                                .MaterialQuantity *
                                                                RepairItemForm
                                                                    .value[
                                                                    "RepairItemDetails"
                                                                ][i]
                                                                    .MaterialUnitCost
                                                        }}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <h2
                                                            style="
                                                                font-size: 24px;
                                                            "
                                                        >
                                                            <Strong
                                                                >Labor</Strong
                                                            >
                                                        </h2>
                                                    </td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <mat-form-field>
                                                            <span
                                                                class="mat-form-field-label-wrapper"
                                                                ><!----><label
                                                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                                    >UNIT
                                                                </label></span
                                                            >
                                                            <select
                                                                matNativeControl
                                                                formControlName="LaborUnit"
                                                            >
                                                                <option
                                                                    value="EA"
                                                                >
                                                                    Each = EA
                                                                </option>
                                                                <option
                                                                    value="LF"
                                                                >
                                                                    Linear foot
                                                                    = LF
                                                                </option>
                                                                <option
                                                                    value="SF"
                                                                >
                                                                    Square Foot
                                                                    = SF
                                                                </option>
                                                                <option
                                                                    value="LS"
                                                                >
                                                                    Lump Sum =
                                                                    LS
                                                                </option>
                                                                <option
                                                                    value="SY"
                                                                >
                                                                    Square Yard
                                                                    = SY
                                                                </option>
                                                                <option
                                                                    value="*"
                                                                >
                                                                    * = Required
                                                                </option>
                                                            </select>
                                                        </mat-form-field>
                                                    </td>
                                                    <td>
                                                        <mat-form-field
                                                            class="example-full-width"
                                                        >
                                                            <span
                                                                class="mat-form-field-label-wrapper"
                                                                ><!----><label
                                                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                                    >Enter
                                                                    Quantity
                                                                    Here</label
                                                                ></span
                                                            >
                                                            <input
                                                                matInput
                                                                (keyup)="
                                                                    forValValidator(
                                                                        RepairItemDetail,
                                                                        $event,
                                                                        'LaborQuantity'
                                                                    )
                                                                "
                                                                formControlName="LaborQuantity"
                                                                name="Quantity"
                                                            />
                                                        </mat-form-field>
                                                    </td>
                                                    <td>
                                                        <mat-form-field
                                                            class="example-full-width"
                                                        >
                                                            <span
                                                                class="mat-form-field-label-wrapper"
                                                                ><!----><label
                                                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                                    >Enter Cost
                                                                    Here</label
                                                                ></span
                                                            >
                                                            <input
                                                                matInput
                                                                (keyup)="
                                                                    forValValidator(
                                                                        RepairItemDetail,
                                                                        $event,
                                                                        'LaborUnitCost'
                                                                    )
                                                                "
                                                                formControlName="LaborUnitCost"
                                                                name="UnitCost"
                                                            />
                                                        </mat-form-field>
                                                    </td>
                                                    <td>
                                                        = ${{
                                                            RepairItemForm
                                                                .value[
                                                                "RepairItemDetails"
                                                            ][i].LaborQuantity *
                                                                RepairItemForm
                                                                    .value[
                                                                    "RepairItemDetails"
                                                                ][i]
                                                                    .LaborUnitCost
                                                        }}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td>&nbsp;</td>
                                                    <td>
                                                        <h2
                                                            style="
                                                                font-size: 24px;
                                                                text-align: right;
                                                            "
                                                        >
                                                            <Strong
                                                                >Item Total
                                                                =</Strong
                                                            >
                                                        </h2>
                                                    </td>
                                                    <td>
                                                        <h2
                                                            style="
                                                                font-size: 24px;
                                                            "
                                                        >
                                                            <Strong>
                                                                ${{
                                                                    RepairItemForm
                                                                        .value[
                                                                        "RepairItemDetails"
                                                                    ][i]
                                                                        .MaterialQuantity *
                                                                        RepairItemForm
                                                                            .value[
                                                                            "RepairItemDetails"
                                                                        ][i]
                                                                            .MaterialUnitCost +
                                                                        RepairItemForm
                                                                            .value[
                                                                            "RepairItemDetails"
                                                                        ][i]
                                                                            .LaborQuantity *
                                                                            RepairItemForm
                                                                                .value[
                                                                                "RepairItemDetails"
                                                                            ][i]
                                                                                .LaborUnitCost
                                                                }}</Strong
                                                            >
                                                        </h2>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <br />
                                        <mat-form-field
                                            class="example-full-width"
                                        >
                                            <span
                                                class="mat-form-field-label-wrapper"
                                                ><!----><label
                                                    class="mat-form-field-label ng-tns-c35-17 ng-star-inserted"
                                                    >Summary</label
                                                ></span
                                            >
                                            <textarea
                                                matInput
                                                rows="8"
                                                formControlName="Summary"
                                                name="Summary"
                                            ></textarea>
                                        </mat-form-field>
                                        <button
                                            style="
                                                float: right;
                                                margin: 0px 5px;
                                            "
                                            *ngIf="i != 0"
                                            (click)="deleteRepairItems(i)"
                                        >
                                            Remove
                                        </button>
                                    </div>
                                </div>
                                <button
                                    type="button"
                                    class="addAdditionalRepairItemBtn"
                                    (click)="addAdditionalRepairItems()"
                                >
                                    Add another item
                                </button>
                            </div>
                        </mat-card>
                    </mat-card-content>
                    <mat-card-actions>
                        <button mat-raised-button type="submit" color="primary">
                            {{ isEdit ? "Save" : "Save" }}</button
                        >&nbsp; &nbsp;&nbsp;
                        <button
                            mat-raised-button
                            (click)="backFromAddItemSection()"
                            type="button"
                            color="info"
                        >
                            Back
                        </button>
                    </mat-card-actions>
                </form>
            </mat-card>
        </div>
    </div>
</div>
