import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { FormGroup, Validators, FormControl } from "@angular/forms";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { DataSource } from "@angular/cdk/table";
import { ActivatedRoute, Router } from "@angular/router";
import { BorrowerService } from "../borrower/borrower.service";
import { PropertyService } from "../property/property.service";
import { SessionService } from "../service/session.service";
import * as AWS from "aws-sdk/global";
import * as S3 from "aws-sdk/clients/s3";

export interface UserData {
    id: string;
    name: string;
    email: string;
    lastLogin: Date;
}

/** Constants used to fill up our data base. */
const COLORS: string[] = [
    "maroon",
    "red",
    "orange",
    "yellow",
    "olive",
    "green",
    "purple",
    "fuchsia",
    "lime",
    "teal",
    "aqua",
    "blue",
    "navy",
    "black",
    "gray",
];
const NAMES: string[] = [
    "Maia",
    "Asher",
    "Olivia",
    "Atticus",
    "Amelia",
    "Jack",
    "Charlotte",
    "Theodore",
    "Isla",
    "Oliver",
    "Isabella",
    "Jasper",
    "Cora",
    "Levi",
    "Violet",
    "Arthur",
    "Mia",
    "Thomas",
    "Elizabeth",
];

/**
 * @title Data table with sorting, pagination, and filtering.
 */

@Component({
    selector: "app-property-gallery",
    templateUrl: "./property-gallery.component.html",
    styleUrls: ["./property-gallery.component.scss"],
})
export class PropertyGalleryComponent implements OnInit {
    displayedColumns: string[] = [
        "ActionName",
        "AnonymousUser",
        "AuthenticatedUser",
        "Administrator",
        "Consultants",
        "GeneralContractors",
        "Borrower",
        "LoanOfficer",
        "LoanProcessor",
    ];
    dataSource: MatTableDataSource<UserData>;
    mobnumPattern = "^(d{3})sd{3}-d{4}";
    add_googlealbum = new FormGroup({
        GoogleAlbum: new FormControl(null),
        AlbumTitle: new FormControl(null, [Validators.maxLength(100),Validators.minLength(3)]),
    });
    add_googledrawalbum = new FormGroup({
        GoogleAlbum: new FormControl(null),
        AlbumTitle: new FormControl(null, [Validators.maxLength(100),Validators.minLength(3)]),
    });

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    isList: boolean = true;
    settings: any;
    isAdd: boolean = false;
    isEdit: boolean = false;
    submitted: boolean = false;
    isDrawGalleryActive: boolean = false;
    isPhotoGalleryActive: boolean = false;
    favoriteSeason: string;
    userDetail: any = {};
    userList: any = {};
    propertyId: any = 0;
    selectedIndex: any = 0;
    albumId: any;
    drawalbumId: any;
    albumType = "propertyPhotos";
    loading = false;
    s3ImagePath: string;
    s3CoverImagePath: string;
    selectedFile: any;
    propertyDetails: any;
    coverFileName: any;
    showCoverFile: any;
    propertyImageDataId = null;
    drawImageDataId = null;
    tempFileName: string;
    newPropertyImagesData: any = [];
    newDrawImagesData: any = [];
    propertyImagesData: any[] = [];
    drawImagesData: any[] = [];

    roles: any = [
        { Key: "1", Value: "authenticated user" },
        { Key: "2", Value: "Administrator" },
        { Key: "3", Value: "Consultants" },
        { Key: "4", Value: "General Contractors" },
        { Key: "5", Value: "Borrower" },
        { Key: "6", Value: "Loan Officer" },
        { key: "7", Value: "Loan Processor" },
        { key: "8", Value: " Notify user of new account" },
        { key: "9", Value: "Draw Administrator" },
    ];
    constructor(
        @Inject(ActivatedRoute) private route: ActivatedRoute,
        private _bservice: BorrowerService,
        private _pservice: PropertyService,
        private sessionService: SessionService
    ) {}

    ngOnInit() {
        var propertyId = this.route.snapshot.paramMap.get("propertyId");
        var albumId = this.route.snapshot.paramMap.get("albumId");
        this.selectedIndex = this.route.snapshot.paramMap.get("selectedIndex")
            ? this.route.snapshot.paramMap.get("selectedIndex")
            : 0;
        this.propertyId = propertyId;

        if (this.selectedIndex == 1) {
            this.drawalbumId = albumId;
            this.isDrawGalleryActive = true;
        } else if (this.selectedIndex == 0) {
            this.albumId = albumId;
            this.isPhotoGalleryActive = true;
        } else {
            this.albumId = albumId;
            this.isPhotoGalleryActive = true;
            this.isDrawGalleryActive = true;
        }

        var data = {
            EmailId: "",
            PageSize: 100000,
            LastEvaluatedKey: 0,
            UserType: this.sessionService.userType[4].Value,
        };
        this._bservice.GetSetting(data).subscribe((response) => {
            if (response != null) {
                this.settings = response;
            }
        });

        if (this.sessionService.get("userInfo") != undefined) {
            this.userDetail = JSON.parse(this.sessionService.get("userInfo"));
        }
        this.getPropertyDrawDetails(propertyId);
        this.getPropertyDetails(propertyId);
        this.getPropertyImage(propertyId);
        this.getPropertyDrawImage(propertyId);
    }

    applyFilter(filterValue: string) {
        this.dataSource.filter = filterValue.trim().toLowerCase();

        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    }
    removeAlbum(propertyId, albumIndex) {
        this._pservice
            .GetPropertyDetails(this.propertyId)
            .subscribe((response) => {
                if (response.GoogleAlbum != null) {
                    let albumDataArray: any = [];
                    let albumData: any = {};
                    for (let i = 0; i < response.GoogleAlbum.length; i++) {
                        if (albumIndex != i) {
                            albumData = {
                                AlbumTitle: response.GoogleAlbum[i].AlbumTitle,
                                AlbumType: response.GoogleAlbum[i].AlbumType,
                                GoogleAlbum:
                                    response.GoogleAlbum[i].GoogleAlbum,
                            };
                            albumDataArray.push(albumData);
                        }
                    }

                    var data = {
                        Id: parseInt(propertyId),
                        GoogleAlbum: albumDataArray,
                        CreatedBy: this.userDetail.EmailId,
                    };

                    this._pservice.UpdatePropertyGoogleAlbum(data).subscribe(
                        (response) => {
                            if (response != null) {
                                this.sessionService.notifier.notify(
                                    "success",
                                    "Property Album Successfully Deleted."
                                );
                                this.getPropertyDetails(propertyId);
                            }
                        },
                        (error) => {}
                    );
                } else {
                    this.sessionService.notifier.notify(
                        "error",
                        "Property Album Does Not Exist."
                    );
                }
            });
    }
    getPropertyDetailsForEdit(propertyId, albumId) {
        if (albumId != null) {
            this.albumId = albumId;

            this._pservice.GetPropertyDetails(propertyId).subscribe(
                (response) => {
                    if (response != null) {
                        this.propertyDetails = response.GoogleAlbum;
                        if (response.GoogleAlbum != null) {
                            for (
                                let i = 0;
                                i < response.GoogleAlbum.length;
                                i++
                            ) {
                                if (i == albumId) {
                                    this.add_googlealbum.controls[
                                        "AlbumTitle"
                                    ].setValue(
                                        response.GoogleAlbum[i].AlbumTitle
                                    );
                                    this.add_googlealbum.controls[
                                        "GoogleAlbum"
                                    ].setValue(
                                        response.GoogleAlbum[i].GoogleAlbum
                                    );
                                }
                            }
                        }
                    }
                },
                (error) => {}
            );
        } else {
            this._pservice.GetPropertyDetails(propertyId).subscribe(
                (response) => {
                    if (response != null) {
                        this.propertyDetails = response.GoogleAlbum;
                    }
                },
                (error) => {}
            );
        }
    }
    getPropertyDetails(propertyId) {
        if (this.albumId != null) {
            this._pservice.GetPropertyDetails(propertyId).subscribe(
                (response) => {
                    if (response != null) {
                        this.propertyDetails = response.GoogleAlbum;
                        if (response.GoogleAlbum != null) {
                            for (
                                let i = 0;
                                i < response.GoogleAlbum.length;
                                i++
                            ) {
                                if (i == this.albumId) {
                                    this.add_googlealbum.controls[
                                        "AlbumTitle"
                                    ].setValue(
                                        response.GoogleAlbum[i].AlbumTitle
                                    );
                                    this.add_googlealbum.controls[
                                        "GoogleAlbum"
                                    ].setValue(
                                        response.GoogleAlbum[i].GoogleAlbum
                                    );
                                }
                            }
                        }
                    }
                },
                (error) => {}
            );
        } else {
            this._pservice.GetPropertyDetails(propertyId).subscribe(
                (response) => {
                    if (response != null) {
                        this.propertyDetails = response.GoogleAlbum;
                    }
                },
                (error) => {}
            );
        }
    }
    getPropertyDrawDetailsForEdit(propertyId, albumId) {
        if (albumId != null) {
            this.drawalbumId = albumId;

            this._pservice.GetPropertyDetails(propertyId).subscribe(
                (response) => {
                    if (response != null) {
                        this.propertyDetails = response.GoogleAlbum;
                        if (response.GoogleAlbum != null) {
                            for (
                                let i = 0;
                                i < response.GoogleAlbum.length;
                                i++
                            ) {
                                if (i == albumId) {
                                    this.add_googledrawalbum.controls[
                                        "AlbumTitle"
                                    ].setValue(
                                        response.GoogleAlbum[i].AlbumTitle
                                    );
                                    this.add_googledrawalbum.controls[
                                        "GoogleAlbum"
                                    ].setValue(
                                        response.GoogleAlbum[i].GoogleAlbum
                                    );
                                }
                            }
                        }
                    }
                },
                (error) => {}
            );
        } else {
            this._pservice.GetPropertyDetails(propertyId).subscribe(
                (response) => {
                    if (response != null) {
                        this.propertyDetails = response.GoogleAlbum;
                    }
                },
                (error) => {}
            );
        }
    }
    getPropertyDrawDetails(propertyId) {
        if (this.drawalbumId != null) {
            this._pservice.GetPropertyDetails(propertyId).subscribe(
                (response) => {
                    if (response != null) {
                        this.propertyDetails = response.GoogleAlbum;
                        if (response.GoogleAlbum != null) {
                            for (
                                let i = 0;
                                i < response.GoogleAlbum.length;
                                i++
                            ) {
                                if (i == this.drawalbumId) {
                                    this.add_googledrawalbum.controls[
                                        "AlbumTitle"
                                    ].setValue(
                                        response.GoogleAlbum[i].AlbumTitle
                                    );
                                    this.add_googledrawalbum.controls[
                                        "GoogleAlbum"
                                    ].setValue(
                                        response.GoogleAlbum[i].GoogleAlbum
                                    );
                                }
                            }
                        }
                    }
                },
                (error) => {}
            );
        } else {
            this._pservice.GetPropertyDetails(propertyId).subscribe(
                (response) => {
                    if (response != null) {
                        this.propertyDetails = response.GoogleAlbum;
                    }
                },
                (error) => {}
            );
        }
    }
    saveGoogleDrawAlbumRecord() {
        this.albumType = "drawInspectionPhotos";
        var activeTab = 1;
        this._pservice
            .GetPropertyDetails(this.propertyId)
            .subscribe((response) => {
                if (response.GoogleAlbum != null) {
                    var formData = this.add_googledrawalbum.value;
                    let albumData: any = {};
                    if (
                        formData.AlbumTitle == "" ||
                        formData.AlbumTitle == null ||
                        formData.AlbumTitle == undefined
                    ) {
                        this.sessionService.notifier.notify(
                            "error",
                            "Album title should not be blank."
                        );
                        return;
                    } else if (
                        formData.GoogleAlbum == "" ||
                        formData.GoogleAlbum == null ||
                        formData.GoogleAlbum == undefined
                    ) {
                        this.sessionService.notifier.notify(
                            "error",
                            "Album Link should not be blank."
                        );
                        return;
                    }
                    albumData = {
                        AlbumTitle: formData.AlbumTitle,
                        AlbumType: this.albumType,
                        GoogleAlbum: formData.GoogleAlbum,
                    };

                    let checked = true;

                    if (this.drawalbumId != null) {
                        checked = false;
                        response.GoogleAlbum[this.drawalbumId].AlbumTitle =
                            formData.AlbumTitle;

                        response.GoogleAlbum[this.drawalbumId].GoogleAlbum =
                            formData.GoogleAlbum;
                    } else {
                        for (let i = 0; i < response.GoogleAlbum.length; i++) {
                            if (
                                response.GoogleAlbum[i].AlbumTitle ==
                                formData.AlbumTitle
                            ) {
                                checked = false;
                                response.GoogleAlbum[i].AlbumTitle =
                                    formData.AlbumTitle;

                                response.GoogleAlbum[i].GoogleAlbum =
                                    formData.GoogleAlbum;
                            }
                        }
                    }

                    if (checked) {
                        response.GoogleAlbum.push(albumData);
                    }

                    var data = {
                        Id: parseInt(this.propertyId),
                        GoogleAlbum: response.GoogleAlbum,
                        CreatedBy: this.userDetail.EmailId,
                    };
                    this._pservice.UpdatePropertyGoogleAlbum(data).subscribe(
                        (response) => {
                            if (response != null) {
                                this.sessionService.notifier.notify(
                                    "success",
                                    "Property Successfully Updated."
                                );
                                this.getPropertyDetails(this.propertyId);
                            }
                        },
                        (error) => {}
                    );
                } else {
                    var formData = this.add_googledrawalbum.value;
                    let albumData: any = {};
                    let albumDataArray: any = [];
                    albumData = {
                        AlbumTitle: formData.AlbumTitle,
                        AlbumType: this.albumType,
                        GoogleAlbum: formData.GoogleAlbum,
                    };

                    albumDataArray.push(albumData);
                    var data = {
                        Id: parseInt(this.propertyId),
                        GoogleAlbum: albumDataArray,
                        CreatedBy: this.userDetail.EmailId,
                    };
                    this._pservice.UpdatePropertyGoogleAlbum(data).subscribe(
                        (response) => {
                            if (response != null) {
                                this.sessionService.notifier.notify(
                                    "success",
                                    "Property Successfully Updated."
                                );

                                this.getPropertyDetails(this.propertyId);
                            }
                        },
                        (error) => {}
                    );
                }
            });
    }
    saveGooglePropertyAlbumRecord() {
        this.albumType = "propertyPhotos";
        var activeTab = 0;
        this._pservice
            .GetPropertyDetails(this.propertyId)
            .subscribe((response) => {
                if (response.GoogleAlbum != null) {
                    var formData = this.add_googlealbum.value;
                    let albumData: any = {};
                    if (
                        formData.AlbumTitle == "" ||
                        formData.AlbumTitle == null ||
                        formData.AlbumTitle == undefined
                    ) {
                        this.sessionService.notifier.notify(
                            "error",
                            "Album title should not be blank."
                        );
                        return;
                    } else if (
                        formData.GoogleAlbum == "" ||
                        formData.GoogleAlbum == null ||
                        formData.GoogleAlbum == undefined
                    ) {
                        this.sessionService.notifier.notify(
                            "error",
                            "Album Link should not be blank."
                        );
                        return;
                    }
                    albumData = {
                        AlbumTitle: formData.AlbumTitle,
                        AlbumType: this.albumType,
                        GoogleAlbum: formData.GoogleAlbum,
                    };

                    let checked = true;

                    if (this.albumId != null) {
                        checked = false;
                        response.GoogleAlbum[this.albumId].AlbumTitle =
                            formData.AlbumTitle;

                        response.GoogleAlbum[this.albumId].GoogleAlbum =
                            formData.GoogleAlbum;
                    } else {
                        for (let i = 0; i < response.GoogleAlbum.length; i++) {
                            if (
                                response.GoogleAlbum[i].AlbumTitle ==
                                formData.AlbumTitle
                            ) {
                                checked = false;
                                response.GoogleAlbum[i].AlbumTitle =
                                    formData.AlbumTitle;

                                response.GoogleAlbum[i].GoogleAlbum =
                                    formData.GoogleAlbum;
                            }
                        }
                    }

                    if (checked) {
                        response.GoogleAlbum.push(albumData);
                    }

                    var data = {
                        Id: parseInt(this.propertyId),
                        GoogleAlbum: response.GoogleAlbum,
                        CreatedBy: this.userDetail.EmailId,
                    };
                    this._pservice.UpdatePropertyGoogleAlbum(data).subscribe(
                        (response) => {
                            if (response != null) {
                                this.sessionService.notifier.notify(
                                    "success",
                                    "Property Successfully Updated."
                                );
                                this.getPropertyDetails(this.propertyId);
                            }
                        },
                        (error) => {}
                    );
                } else {
                    var formData = this.add_googlealbum.value;
                    let albumData: any = {};
                    let albumDataArray: any = [];
                    albumData = {
                        AlbumTitle: formData.AlbumTitle,
                        AlbumType: this.albumType,
                        GoogleAlbum: formData.GoogleAlbum,
                    };

                    albumDataArray.push(albumData);
                    var data = {
                        Id: parseInt(this.propertyId),
                        GoogleAlbum: albumDataArray,
                        CreatedBy: this.userDetail.EmailId,
                    };
                    this._pservice.UpdatePropertyGoogleAlbum(data).subscribe(
                        (response) => {
                            if (response != null) {
                                this.sessionService.notifier.notify(
                                    "success",
                                    "Property Successfully Updated."
                                );

                                this.getPropertyDetails(this.propertyId);
                            }
                        },
                        (error) => {}
                    );
                }
            });
    }

    handleInputChange(e: any) {
        var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];

        var pattern = /image-*/;
        var reader = new FileReader();

        //Validation
        if (!file.type.match(pattern)) {
            return false;
        }

        if (file.size > 1000000) {
            return false;
        }

        this.selectedFile = file;

        reader.onload = this._handleReaderLoaded.bind(this);
        reader.readAsDataURL(file);
    }

    _handleReaderLoaded(e: any) {
        var reader = e.target;

        this.tempFileName = reader.result;
    }

    saveToS3(fileInput: any) {
        const file = fileInput;
        const bucketName = this.settings.AwsS3BucketName
            ? this.settings.AwsS3BucketName
            : "";
        const accessKeyId = this.settings.AwsAccessKeyId
            ? this.settings.AwsAccessKeyId
            : "";
        const secretAccessKey = this.settings.AwsSecretAccessKey
            ? this.settings.AwsSecretAccessKey
            : "";
        const region = this.settings.AwsConfigRegion
            ? this.settings.AwsConfigRegion
            : "";

        const contentType = file.type;
        const bucket = new S3({
            accessKeyId: accessKeyId,
            secretAccessKey: secretAccessKey,
            region: region,
        });

        var millis = Date.now();
        const propertyid = this.propertyId;
        const fileName =
            "image-" + millis + "." + file.name.split(".").pop().toLowerCase();

        const params = {
            Bucket: bucketName,
            Key: "property-images/" + propertyid + "/" + fileName,
            Body: file,
            StorageClass: "STANDARD",
            ACL: "bucket-owner-full-control",
            ContentType: contentType,
        };
        const _this = this;
        bucket.upload(params, function (err: any, data: any) {
            if (err) {
                alert(
                    "There was an error uploading your file: " +
                        JSON.stringify(err)
                );
                console.log("There was an error uploading your file: ", err);
                return false;
            } else {
                _this.s3ImagePath = data.Location;

                if (_this.propertyImagesData == undefined) {
                    _this.propertyImagesData = [];
                    _this.propertyImagesData.push(_this.s3ImagePath);
                } else {
                    _this.propertyImagesData.push(_this.s3ImagePath);
                }
                this.add_googlealbum.controls["file"].setValue("");
                this.sessionService.notifier.notify(
                    "success",
                    "Property Gallery Photo uploaded successfully."
                );
                _this.loading = false;
                return true;
            }
        });
    }
    saveDrawToS3(fileInput: any) {
        const file = fileInput;
        const bucketName = this.settings.AwsS3BucketName
            ? this.settings.AwsS3BucketName
            : "";
        const accessKeyId = this.settings.AwsAccessKeyId
            ? this.settings.AwsAccessKeyId
            : "";
        const secretAccessKey = this.settings.AwsSecretAccessKey
            ? this.settings.AwsSecretAccessKey
            : "";
        const region = this.settings.AwsConfigRegion
            ? this.settings.AwsConfigRegion
            : "";

        const contentType = file.type;
        const bucket = new S3({
            accessKeyId: accessKeyId,
            secretAccessKey: secretAccessKey,
            region: region,
        });

        var millis = Date.now();
        const propertyid = this.propertyId;
        const fileName =
            "image-" + millis + "." + file.name.split(".").pop().toLowerCase();

        const params = {
            Bucket: bucketName,
            Key:
                "property-images/" +
                propertyid +
                "/draw-inspection-photos/" +
                fileName,
            Body: file,
            StorageClass: "STANDARD",
            ACL: "bucket-owner-full-control",
            ContentType: contentType,
        };
        const _this = this;
        bucket.upload(params, function (err: any, data: any) {
            if (err) {
                alert(
                    "There was an error uploading your file: " +
                        JSON.stringify(err)
                );
                console.log("There was an error uploading your file: ", err);
                return false;
            } else {
                _this.s3ImagePath = data.Location;

                if (_this.drawImagesData == undefined) {
                    _this.drawImagesData = [];
                    _this.drawImagesData.push(_this.s3ImagePath);
                } else {
                    _this.drawImagesData.push(_this.s3ImagePath);
                }
                this.add_googlealbum.controls["file"].setValue("");
                this.sessionService.notifier.notify(
                    "success",
                    "Property Draw Inspection Photo uploaded successfully."
                );

                _this.loading = false;
                return true;
            }
        });
    }
    onSubmit() {
        this.loading = true;
        this.submitted = true;

        if (this.selectedFile != null) {
            //Save to S3
            this.saveToS3(this.selectedFile);
        } else {
            alert("Please Select File !");
            this.loading = false;
        }
    }
    onDrawSubmit() {
        this.loading = true;
        this.submitted = true;

        if (this.selectedFile != null) {
            //Save to S3
            this.saveDrawToS3(this.selectedFile);
        } else {
            alert("Please Select File !");
            this.loading = false;
        }
    }
    removeImage(index) {
        this.propertyImagesData.splice(index, 1);
        this.sessionService.notifier.notify(
            "success",
            "Property Gallery Photo removed successfully."
        );
    }
    removeDrawImage(index) {
        this.drawImagesData.splice(index, 1);
        this.sessionService.notifier.notify(
            "success",
            "Property Draw Inspection Photo removed successfully."
        );
    }

    coverHandleInputChange(e: any) {
        var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];

        var pattern = /image-*/;
        var reader = new FileReader();

        if (!file.type.match(pattern)) {
            return false;
        }

        if (file.size > 1000000) {
            return false;
        }

        this.coverFileName = file;

        reader.onload = this._handleCoverReaderLoaded.bind(this);
        reader.readAsDataURL(file);
    }

    _handleCoverReaderLoaded(e: any) {
        var reader = e.target;

        this.tempFileName = reader.result;
    }

    saveTocoverS3(fileInput: any) {
        const file = fileInput;
        const bucketName = this.settings.AwsS3BucketName;
        const accessKeyId = this.settings.AwsAccessKeyId;
        const secretAccessKey = this.settings.AwsSecretAccessKey;
        const region = this.settings.AwsConfigRegion;

        const contentType = file.type;
        const bucket = new S3({
            accessKeyId: accessKeyId,
            secretAccessKey: secretAccessKey,
            region: region,
        });

        var millis = Date.now();
        const propertyid = this.propertyId;
        const fileName =
            "image-" + millis + "." + file.name.split(".").pop().toLowerCase();

        const params = {
            Bucket: bucketName,
            Key: "property-images/" + propertyid + "/" + fileName,
            Body: file,
            StorageClass: "STANDARD",
            ACL: "bucket-owner-full-control",
            ContentType: contentType,
        };
        const _this = this;
        bucket.upload(params, function (err: any, data: any) {
            if (err) {
                alert(
                    "There was an error uploading your file: " +
                        JSON.stringify(err)
                );
                console.log("There was an error uploading your file: ", err);
                return false;
            } else {
                _this.s3CoverImagePath = data.Location;

                _this.showCoverFile = _this.s3CoverImagePath;

                _this.loading = false;
                return true;
            }
        });
    }

    onCoverSubmit() {
        this.loading = true;
        this.submitted = true;

        if (this.coverFileName != null) {
            //Save to S3
            this.saveTocoverS3(this.coverFileName);
        } else {
            alert("Please Select File !");
            this.loading = false;
        }
    }

    removeCoverImage(index) {
        this.showCoverFile = null;
    }

    getPropertyImage(propertyId) {
        var data = { PropertyId: propertyId };
        this._bservice.GetPropertyImagesList(data).subscribe(
            (response) => {
                if (response != null) {
                    if (response.Items.length > 0) {
                        var imageData = response.Items[0];
                        this.propertyImagesData = imageData.Images;

                        this.showCoverFile = imageData.CoverPhoto;
                        this.propertyImageDataId = imageData.Id;
                        if (this.propertyImagesData.length > 0) {
                            for (
                                let i = 0;
                                i < this.propertyImagesData.length;
                                i++
                            ) {
                                let img = new Image();

                                let imagewidth: any;
                                let imageheight: any;
                                const _this = this;
                                let imageSrc = _this.propertyImagesData[i];
                                img.src = _this.propertyImagesData[i];
                                img.onload = function (event) {
                                    let loadedImage =
                                        event.currentTarget as HTMLImageElement;

                                    imagewidth = loadedImage.width;
                                    imageheight = loadedImage.height;

                                    let n = _this.propertyImagesData.length;

                                    let imagename = imageSrc
                                        .split("/")
                                        .pop()
                                        .toLowerCase();

                                    let imageDataArray = {
                                        imageSrc: imageSrc,
                                        width: imagewidth,
                                        height: imageheight,
                                        dimensions:
                                            imagewidth + " X " + imageheight,
                                        name: imagename,
                                        size: "105kb",
                                    };

                                    _this.newPropertyImagesData.push(
                                        imageDataArray
                                    );
                                };
                            }
                        }
                    } else {
                        this.propertyImageDataId = null;
                    }
                }
            },
            (error) => {}
        );
    }
    getPropertyDrawImage(propertyId) {
        var data = { PropertyId: propertyId };
        this._bservice.GetPropertyDrawImagesList(data).subscribe(
            (response) => {
                if (response != null) {
                    if (response.Items.length > 0) {
                        var imageData = response.Items[0];
                        this.drawImagesData = imageData.Images;

                        this.showCoverFile = imageData.CoverPhoto;
                        this.drawImageDataId = imageData.Id;
                        if (this.drawImagesData.length > 0) {
                            for (
                                let i = 0;
                                i < this.drawImagesData.length;
                                i++
                            ) {
                                let img = new Image();

                                let imagewidth: any;
                                let imageheight: any;
                                const _this = this;
                                let imageSrc = _this.drawImagesData[i];
                                img.src = _this.drawImagesData[i];
                                img.onload = function (event) {
                                    let loadedImage =
                                        event.currentTarget as HTMLImageElement;

                                    imagewidth = loadedImage.width;
                                    imageheight = loadedImage.height;

                                    let n = _this.drawImagesData.length;

                                    let imagename = imageSrc
                                        .split("/")
                                        .pop()
                                        .toLowerCase();

                                    let imageDataArray = {
                                        imageSrc: imageSrc,
                                        width: imagewidth,
                                        height: imageheight,
                                        dimensions:
                                            imagewidth + " X " + imageheight,
                                        name: imagename,
                                        size: "105kb",
                                    };

                                    _this.newDrawImagesData.push(
                                        imageDataArray
                                    );
                                };
                            }
                        }
                    } else {
                        this.drawImageDataId = null;
                    }
                }
            },
            (error) => {}
        );
    }
    saveRecord() {
        this.loading = true;
        if (this.propertyImageDataId == null) {
            if (this.propertyImagesData.length > 0) {
                var currentdate = new Date();
                var data = {
                    PropertyId: this.propertyId,
                    CoverPhoto: this.showCoverFile,
                    Type: "property",
                    Images: this.propertyImagesData,
                    CreatedBy: this.userDetail.EmailId,
                    CreatedDate: currentdate,
                    UpdateDate: currentdate,
                    UpdatedBy: this.userDetail.EmailId,
                };

                this._bservice.AddPropertyImage(data).subscribe(
                    (response) => {
                        this.sessionService.notifier.notify(
                            "success",
                            "Property Gallery added successfully."
                        );
                        this.getPropertyImage(this.propertyId);
                        this.loading = false;
                    },
                    (error) => {
                        this.sessionService.notifier.notify(
                            "error",
                            error.error.message
                        );
                        this.loading = false;
                    }
                );
            } else {
                this.loading = false;
                alert("Please Select at least one Image!");
            }
        } else {
            if (this.propertyImagesData.length > 0) {
                var currentdate = new Date();
                var udata = {
                    Id: this.propertyImageDataId,
                    PropertyId: this.propertyId,
                    CoverPhoto: this.showCoverFile,
                    Type: "property",
                    Images: this.propertyImagesData,
                    CreatedBy: this.userDetail.EmailId,
                    CreatedDate: currentdate,
                    UpdateDate: currentdate,
                    UpdatedBy: this.userDetail.EmailId,
                };

                this._bservice.UpdatePropertyImage(udata).subscribe(
                    (response) => {
                        this.sessionService.notifier.notify(
                            "success",
                            "Property Gallery Updated successfully."
                        );
                        this.getPropertyImage(this.propertyId);
                        this.loading = false;
                    },
                    (error) => {
                        this.sessionService.notifier.notify(
                            "error",
                            error.error.message
                        );
                        this.loading = false;
                    }
                );
            } else {
                alert("Please Select at least one Image!");
            }
        }
    }

    saveDrawRecord() {
        this.loading = true;
        if (this.drawImageDataId == null) {
            if (this.drawImagesData.length > 0) {
                var currentdate = new Date();
                var data = {
                    PropertyId: this.propertyId,
                    CoverPhoto: this.showCoverFile,
                    Type: "draw",
                    Images: this.drawImagesData,
                    CreatedBy: this.userDetail.EmailId,
                    CreatedDate: currentdate,
                    UpdateDate: currentdate,
                    UpdatedBy: this.userDetail.EmailId,
                };

                this._bservice.AddPropertyImage(data).subscribe(
                    (response) => {
                        this.sessionService.notifier.notify(
                            "success",
                            "Property Draw Gallery added successfully."
                        );
                        this.getPropertyDrawImage(this.propertyId);
                        this.loading = false;
                    },
                    (error) => {
                        this.sessionService.notifier.notify(
                            "error",
                            error.error.message
                        );
                        this.loading = false;
                    }
                );
            } else {
                this.loading = false;
                alert("Please Select at least one Image!");
            }
        } else {
            if (this.drawImagesData.length > 0) {
                var currentdate = new Date();
                var udata = {
                    Id: this.drawImageDataId,
                    PropertyId: this.propertyId,
                    CoverPhoto: this.showCoverFile,
                    Type: "draw",
                    Images: this.drawImagesData,
                    CreatedBy: this.userDetail.EmailId,
                    CreatedDate: currentdate,
                    UpdateDate: currentdate,
                    UpdatedBy: this.userDetail.EmailId,
                };

                this._bservice.UpdatePropertyImage(udata).subscribe(
                    (response) => {
                        this.sessionService.notifier.notify(
                            "success",
                            "Property Draw Gallery Updated successfully."
                        );
                        this.getPropertyDrawImage(this.propertyId);
                        this.loading = false;
                    },
                    (error) => {
                        this.sessionService.notifier.notify(
                            "error",
                            error.error.message
                        );
                        this.loading = false;
                    }
                );
            } else {
                alert("Please Select at least one Image!");
            }
        }
    }
}

/** Builds and returns a new User. */
