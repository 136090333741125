import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { FormGroup, Validators, FormControl } from "@angular/forms";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { BorrowerService } from "../borrower/borrower.service";
import { SessionService } from "../service/session.service";
import { PropertyService } from "../property/property.service";
import { Router } from "@angular/router";

export interface UserData {
    id: string;
    name: string;
    email: string;
    lastLogin: Date;
}

/** Constants used to fill up our data base. */
const COLORS: string[] = [
    "maroon",
    "red",
    "orange",
    "yellow",
    "olive",
    "green",
    "purple",
    "fuchsia",
    "lime",
    "teal",
    "aqua",
    "blue",
    "navy",
    "black",
    "gray",
];
const NAMES: string[] = [
    "Maia",
    "Asher",
    "Olivia",
    "Atticus",
    "Amelia",
    "Jack",
    "Charlotte",
    "Theodore",
    "Isla",
    "Oliver",
    "Isabella",
    "Jasper",
    "Cora",
    "Levi",
    "Violet",
    "Arthur",
    "Mia",
    "Thomas",
    "Elizabeth",
];

/**
 * @title Data table with sorting, pagination, and filtering.
 */

@Component({
    selector: "app-non-home-inspection-disclosure",
    templateUrl: "./non-home-inspection-disclosure.component.html",
    styleUrls: ["./non-home-inspection-disclosure.component.scss"],
})
export class NonHomeInspectionDisclosureComponent implements OnInit {
    displayedColumns: string[] = [
        "ActionName",
        "AnonymousUser",
        "AuthenticatedUser",
        "Administrator",
        "Consultants",
        "GeneralContractors",
        "Borrower",
        "LoanOfficer",
        "LoanProcessor",
    ];
    dataSource: MatTableDataSource<UserData>;
    mobnumPattern = "^(d{3})sd{3}-d{4}";

    add_home_ins_disclosure = new FormGroup({
        title: new FormControl(null, [Validators.required,Validators.maxLength(100),Validators.minLength(3)]),
        property: new FormControl(null, [Validators.required]),
        id: new FormControl(null),
    });

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    isList: boolean = true;
    isAdd: boolean = false;
    isEdit: boolean = false;
    submitted: boolean = false;
    favoriteSeason: string;
    userDetail: any = {};
    userList: any = {};
    propertyList: any = {};
    loading = false;
    status: any = ["Blocked", "Active"];
    roles: any = [
        { Key: "1", Value: "authenticated user" },
        { Key: "2", Value: "Administrator" },
        { Key: "3", Value: "Consultants" },
        { Key: "4", Value: "General Contractors" },
        { Key: "5", Value: "Borrower" },
        { Key: "6", Value: "Loan Officer" },
        { key: "7", Value: "Loan Processor" },
        { key: "8", Value: " Notify user of new account" },
        { key: "9", Value: "Draw Administrator" },
    ];
    constructor(
        private _bservice: BorrowerService,
        private sessionService: SessionService,
        private propertyService: PropertyService,
        @Inject(Router) private router: Router
    ) {}

    ngOnInit() {
        if (this.sessionService.get("userInfo") != undefined) {
            this.userDetail = JSON.parse(this.sessionService.get("userInfo"));
        }
        this.isAdd = true;
        this.isList = false;
        this.isEdit = false;
        this.GetPropertyList();
    }

    applyFilter(filterValue: string) {
        this.dataSource.filter = filterValue.trim().toLowerCase();

        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    }

    SaveRecord() {
        if (this.add_home_ins_disclosure.invalid) {
            return;
        } else {
            this.submitted = false;
            var formData = this.add_home_ins_disclosure.value;
            var currentDate = new Date();
            if (formData.id == null && !this.isEdit) {
                this.loading = true;
                var data = {
                    PropertyId: formData.property,
                    Title: formData.title,
                    AgreementDate: "4/26/2019",
                    // ConsultantFee: formData.consultantFee,
                    // ConsultantFeeReimbursed: formData.consutantFeeReimbursed,
                    // DrawingBelow100k: formData.drawingBelow,
                    // DrawingBetween100k150k: formData.drawingBetween,
                    // DrawingOver150k: formData.drawingOver,
                    // ChangeOrder: formData.changeOrder,
                    // DrawRequestsMin: formData.drawRequestsMin,
                    // DrawRequestsMax: formData.drawRequestsMax,
                    // SelfHelpDraw: formData.selfHelpDraw,
                    // ChangeScopeWorkMin: formData.changeScopeOfWorkMin,
                    // ChangeScopeWorkMax: formData.changeScopeOfWorkMax,
                    // MultiFamilyDwelling: formData.multiFamilyDwelling,
                    // WorkPlan: formData.workPlan,
                    CreatedBy: this.userDetail.EmailId,
                    CreatedDate: currentDate,
                    UpdateDate: currentDate,
                    UpdatedBy: this.userDetail.EmailId,
                };

                this.propertyService.AddHomeInsDisclosure(data).subscribe(
                    (response) => {
                        this.sessionService.notifier.notify(
                            "success",
                            "Add successfully."
                        );
                        this.isList = true;
                        this.isAdd = false;

                        this.formReset();
                        this.loading = false;
                        this.router.navigate(["/properties"]);
                    },
                    (error) => {
                        this.sessionService.notifier.notify(
                            "error",
                            error.error.message
                        );
                        this.loading = false;
                    }
                );
            } else {
                this.loading = true;
                var data1 = {
                    PropertyId: formData.property,
                    Title: formData.title,
                    // AgreementDate: formData.agreementDate,
                    // ConsultantFee: formData.consultantFee,
                    // ConsultantFeeReimbursed: formData.consutantFeeReimbursed,
                    // DrawingBelow100k: formData.drawingBelow,
                    // DrawingBetween100k150k: formData.drawingBetween,
                    // DrawingOver150k: formData.drawingOver,
                    // ChangeOrder: formData.changeOrder,
                    // DrawRequestsMin: formData.drawRequestsMin,
                    // DrawRequestsMax: formData.drawRequestsMax,
                    // SelfHelpDraw: formData.selfHelpDraw,
                    // ChangeScopeWorkMin: formData.changeScopeOfWorkMin,
                    // ChangeScopeWorkMax: formData.changeScopeOfWorkMax,
                    // MultiFamilyDwelling: formData.multiFamilyDwelling,
                    // WorkPlan: formData.workPlan,
                    CreatedBy: this.userDetail.EmailId,
                    CreatedDate: currentDate,
                    UpdateDate: currentDate,
                    UpdatedBy: this.userDetail.EmailId,
                };
                this.propertyService.UpdateHomeInsDisclosure(data1).subscribe(
                    (response) => {
                        this.sessionService.notifier.notify(
                            "success",
                            "Update successfully."
                        );

                        this.isList = true;
                        this.isAdd = false;
                        this.isEdit = false;

                        this.router.navigate(["/properties"]);
                        this.loading = false;
                    },
                    (error) => {
                        this.sessionService.notifier.notify(
                            "error",
                            error.error.message
                        );
                        this.loading = false;
                    }
                );
            }
        }
        window.scroll(0, 0);
    }

    nextPage(event: PageEvent) {}

    GetPropertyList() {
        this.loading = true;
        var data = {
            EmailId: "",
            PageSize: 100000,
            LastEvaluatedKey: 0,
            UserType: this.sessionService.userType[1].Value,
        };
        this.propertyService.GetPropertyList(data).subscribe(
            (response) => {
                if (response != null) {
                    this.propertyList = response.Items;
                    this.loading = false;
                }
            },
            (error) => {}
        );
    }

    GetUserList() {
        this.loading = true;
        var data = {
            EmailId: "",
            PageSize: 100000,
            LastEvaluatedKey: 0,
            UserType: this.sessionService.userType[4].Value,
        };
        this._bservice.GetUserList(data).subscribe(
            (response) => {
                if (response != null) {
                    var setPermissionData = {
                        Count: 40,
                        ScannedCount: 40,
                        TotalRecord: 40,
                        Items: [],
                    };
                    this.userList = setPermissionData;
                    const users = Array.from(
                        { length: this.userList.Count },
                        (_, k) => createNewUser(k, this.userList)
                    );
                    this.dataSource = new MatTableDataSource(users);
                    this.dataSource.paginator = this.paginator;
                    this.dataSource.sort = this.sort;
                    this.loading = false;
                }
            },
            (error) => {}
        );
    }

    formReset() {
        this.add_home_ins_disclosure.reset();
    }
}

/** Builds and returns a new User. */
function createNewUser(id: number, userList): UserData {
    const name =
        NAMES[Math.round(Math.random() * (NAMES.length - 1))] +
        " " +
        NAMES[Math.round(Math.random() * (NAMES.length - 1))].charAt(0) +
        ".";
    if (userList.Items[id] != undefined) {
        userList.Items[id].id = id + 1;
        return userList.Items[id];
    }
}
