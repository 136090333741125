import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatTableModule } from "@angular/material/table";
import { MatTabsModule } from "@angular/material/tabs";
import { MatListModule } from "@angular/material/list";
import { TranslateModule } from "@ngx-translate/core";
import { CKEditorModule } from "ckeditor4-angular";
import { NgxLoadingModule } from "ngx-loading";

import { FuseSharedModule } from "@fuse/shared.module";
import { PropertyDetailsComponent } from "./property-details.component";
import { NgxMaskDirective, NgxMaskPipe } from "ngx-mask";

const routes = [
    {
        path: "property-details/:propertyId",
        component: PropertyDetailsComponent,
    },
    {
        path: "property-details/:propertyId/:selectedIndex",
        component: PropertyDetailsComponent,
    },
];

@NgModule({
    declarations: [PropertyDetailsComponent],
    imports: [
        RouterModule.forChild(routes),

        TranslateModule,

        FuseSharedModule,
        MatButtonModule,
        MatCardModule,
        MatFormFieldModule,
        MatInputModule,
        MatTableModule,
        MatPaginatorModule,
        MatIconModule,
        MatRadioModule,
        MatCheckboxModule,
        MatSelectModule,
        MatDatepickerModule,
        CKEditorModule,
        MatListModule,
        MatTabsModule,
        NgxLoadingModule,
        NgxMaskDirective,
        NgxMaskPipe
    ],
    exports: [PropertyDetailsComponent],
})
export class PropertyDetailsModule {}
