import { Injectable } from "@angular/core";
import { JwtHelperService } from "@auth0/angular-jwt";
import { SessionService } from "../../service/session.service";

@Injectable({
    providedIn: "root",
})
export class AuthService {
    constructor(public sessionService: SessionService) {}
    jwtHelper = new JwtHelperService();
    public isAuthenticated(): boolean {
        const token: string = this.sessionService.get("userName");

        if (token != null) {
            return true;
        } else {
            return false;
        }
    }
}
