import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { SessionService } from "../service/session.service";

@Injectable({
    providedIn: "root",
})
export class PropertyEditService {
    constructor(
        private http: HttpClient,
        private sessionService: SessionService
    ) {}
    baseurl = this.sessionService.endpoint;
    AddBorrower(data) {
        return this.http.post<any>(this.baseurl + "users/create", data, {
            headers: this.sessionService.setTokenHeader(),
        });
    }

    UpdateBorrower(data) {
        return this.http.put<any>(this.baseurl + "lenders", data, {
            headers: this.sessionService.setTokenHeader(),
        });
    }

    AddConsultantLiability(data) {
        return this.http.post<any>(this.baseurl + "consultantliability", data, {
            headers: this.sessionService.setTokenHeader(),
        });
    }

    UpdateConsultantLiability(data) {
        return this.http.put<any>(this.baseurl + "consultantliability", data, {
            headers: this.sessionService.setTokenHeader(),
        });
    }

    AddConsultantAgreement(data) {
        return this.http.post<any>(
            this.baseurl + "fhaconsultantagreement",
            data,
            { headers: this.sessionService.setTokenHeader() }
        );
    }

    UpdateConsultantAgreement(data) {
        return this.http.put<any>(
            this.baseurl + "fhaconsultantagreement",
            data,
            { headers: this.sessionService.setTokenHeader() }
        );
    }

    AddHomeInsDisclosure(data) {
        return this.http.post<any>(
            this.baseurl + "fhaconsultantagreement",
            data,
            { headers: this.sessionService.setTokenHeader() }
        );
    }

    UpdateHomeInsDisclosure(data) {
        return this.http.put<any>(
            this.baseurl + "fhaconsultantagreement",
            data,
            { headers: this.sessionService.setTokenHeader() }
        );
    }

    GetUserList(data) {
        return this.http.post<any>(this.baseurl + "lenders/list", data, {
            headers: this.sessionService.setTokenHeader(),
        });
    }

    GetPropertyList(data) {
        return this.http.post<any>(this.baseurl + "property/list", data, {
            headers: this.sessionService.setTokenHeader(),
        });
    }

    GetPropertyDetails(PID) {
        return this.http.get<any>(this.baseurl + "property/" + PID, {
            headers: this.sessionService.setTokenHeader(),
        });
    }

    AddProperty(data) {
        return this.http.post<any>(this.baseurl + "property", data, {
            headers: this.sessionService.setTokenHeader(),
        });
    }

    sendSorEmail(data) {
        return this.http.post<any>(this.baseurl + "sor/sendforreview", data, {
            headers: this.sessionService.setTokenHeader(),
        });
    }

    UpdateProperty(data) {
        return this.http.put<any>(this.baseurl + "property", data, {
            headers: this.sessionService.setTokenHeader(),
        });
    }

    GetLoanOfficerUserList(data) {
        return this.http.post<any>(this.baseurl + "users/list", data, {
            headers: this.sessionService.setTokenHeader(),
        });
    }

    GetLoanProcessorUserList(data) {
        return this.http.post<any>(this.baseurl + "users/list", data, {
            headers: this.sessionService.setTokenHeader(),
        });
    }

    GetConsultentUserList(data) {
        return this.http.post<any>(this.baseurl + "users/list", data, {
            headers: this.sessionService.setTokenHeader(),
        });
    }

    GetContractorUserList(data) {
        return this.http.post<any>(this.baseurl + "users/list", data, {
            headers: this.sessionService.setTokenHeader(),
        });
    }

    GetBorrowerUserList(data) {
        return this.http.post<any>(this.baseurl + "users/list", data, {
            headers: this.sessionService.setTokenHeader(),
        });
    }

    GetUserDetailsList(userId) {
        return this.http.get<any>(this.baseurl + "users/" + userId, {
            headers: this.sessionService.setTokenHeader(),
        });
    }

    GetLenderList(data) {
        return this.http.post<any>(this.baseurl + "lenders/list", data, {
            headers: this.sessionService.setTokenHeader(),
        });
    }

    DeleteUser(userid) {
        return this.http.delete<any>(this.baseurl + "lenders/" + userid, {
            headers: this.sessionService.setTokenHeader(),
        });
    }

    UpdatePropertyStatus(data) {
        return this.http.put<any>(
            this.baseurl + "property/updatestatus",
            data,
            { headers: this.sessionService.setTokenHeader() }
        );
    }
    UpdatePropertyMainStatus(data) {
        return this.http.put<any>(
            this.baseurl + "property/updatepropertystatus",
            data,
            { headers: this.sessionService.setTokenHeader() }
        );
    }
    UpdatePropertyCompletedStatus(data) {
        return this.http.put<any>(
            this.baseurl + "property/updatepropertycompletedstatus",
            data,
            { headers: this.sessionService.setTokenHeader() }
        );
    }
    GetSORPDF(data) {
        return this.http.post<any>(this.baseurl + "sor/generatepdf", data, {
            headers: this.sessionService.setTokenHeader(),
        });
    }

    GetLenderDetailsList(data) {
        return this.http.get<any>(this.baseurl + "lenders/" + data, {
            headers: this.sessionService.setTokenHeader(),
        });
    }

    GetFhaConsultantAgreementDetails(data) {
        return this.http.post<any>(
            this.baseurl + "fhaconsultantagreement/list",
            data,
            { headers: this.sessionService.setTokenHeader() }
        );
    }

    GetFhaConsultantLiabilityDetails(data) {
        return this.http.post<any>(
            this.baseurl + "consultantliability/list",
            data,
            { headers: this.sessionService.setTokenHeader() }
        );
    }
    GetUserByEmail(email) {
        return this.http.get<any>(this.baseurl + "users/email/" + email, {
            headers: this.sessionService.setTokenHeader(),
        });
    }
}
